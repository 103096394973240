/* eslint-disable @typescript-eslint/member-ordering */

import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewContainerRef,
  EventEmitter,
  OnChanges
} from '@angular/core';
import {
  ComponentDataSubscriptionWithRelations,
  ComponentDefinition,
  Page,
  PageCommentWithRelations
} from '@rappider/rappider-sdk';
import {
  ContentTree,
  ContentTreeComponent,
  ContentTreeContainer,
  ContentTreeItem,
  ContentTreeItemType,
  LayoutOutlet,
  renderedPageAnimationOptions,
  DraggedContentTreeItemDroppedEvent,
  ContentEditorDevices,
  AddComponentWithPositionInterface
} from '../../models';

import { ContentEditorService, DataTransformService, RenderComponentService } from '@rappider/services';
import { ButtonComponentConfig, DropdownMenuComponentConfig, DropdownMenuItem, IconType } from '@rappider/rappider-components/utils';
import { cloneDeep } from 'lodash';
import { TreeService } from 'libs/shared/src/lib/services/tree-service/tree.service';
import { ContentTreeRendererEditorSize } from '../../models/content-tree-renderer-editor-size.interface';
import { SetActiveContentTreeItem } from 'libs/content-editor/src/lib/state/content-editor.actions';
import { Store } from '@ngrx/store';
import { Validators } from '@angular/forms';

@Component({
  selector: 'rappider-content-tree-renderer',
  templateUrl: './content-tree-renderer.component.html',
  styleUrls: ['./content-tree-renderer.component.scss']
})
export class ContentTreeRendererComponent implements OnInit, OnChanges {
  // #region Component Variables

  @ViewChildren('componentContainer', { read: ViewContainerRef })
  containers: QueryList<ViewContainerRef>;

  @Input() activeContentTreeItem: any;
  @Input() contentTree: ContentTree;
  @Input() componentDefinitions: ComponentDefinition[];
  @Input() containerTitleBarButtons: ButtonComponentConfig[];
  @Input() componentTitleBarButtons: ButtonComponentConfig[];
  @Input() containerTitleBarDropdownConfig: DropdownMenuComponentConfig;
  @Input() componentTitleBarDropdownConfig: DropdownMenuComponentConfig;
  @Input() hasClipboardData: boolean;
  @Input() isRulerVisible = false;
  @Input() readonly: boolean;
  @Input() wrappedWithLayout: boolean;
  @Input() page: Page;
  @Input() themeData: Record<string, any>;
  @Input() allCommentsOnPage: PageCommentWithRelations[];
  @Input() isLayout? = false;
  @Input() hasLayout? = false;

  /**
   * visibility function for container title bar buttons
   *
   * @type {Function}
   * @memberof ContentTreeRendererComponent
   */
  @Input() containerTitleBarButtonVisibilityFunction:
    (button: ButtonComponentConfig, contentTreeItem: ContentTreeItem) => boolean = () => true;
  /**
   * visibility function for component title bar buttons
   *
   * @type {Function}
   * @memberof ContentTreeRendererComponent
   */
  @Input() componentTitleBarButtonVisibilityFunction:
    (button: ButtonComponentConfig, contentTreeItem: ContentTreeItem) => boolean = () => true;
  /**
   * visibility function for container title bar dropdown items
   *
   * @type {Function}
   * @memberof ContentTreeRendererComponent
   */
  @Input() containerTitleBarDropdownItemVisibilityFunction:
    (item: DropdownMenuItem, contentTreeItem: ContentTreeItem) => boolean = () => true;
  /**
   * visibility function for component title bar dropdown items
   *
   * @type {Function}
   * @memberof ContentTreeRendererComponent
   */
  @Input() componentTitleBarDropdownItemVisibilityFunction:
    (item: DropdownMenuItem, contentTreeItem: ContentTreeItem) => boolean = () => true;

  @Output() copyToClipboard = new EventEmitter<ContentTreeContainer>();
  @Output() pasteFromClipboard = new EventEmitter<ContentTreeContainer>();
  @Output() activeContentTreeItemChange = new EventEmitter<string>();
  @Output() draggedContentTreeItemDropped = new EventEmitter<DraggedContentTreeItemDroppedEvent>();
  @Output() addPageContainer = new EventEmitter<string>();
  @Output() addComponentInPageContainer = new EventEmitter<string>();
  @Output() addElementInPageContainer = new EventEmitter<string>();
  @Output() addComponentWithPosition = new EventEmitter<AddComponentWithPositionInterface>();
  @Output() titleBarButtonClick = new EventEmitter<{ button: ButtonComponentConfig; contentTreeItem: ContentTreeItem }>();
  @Output() titleBarDataSubscriptionItemControllerClick =
    new EventEmitter<{ key: string; dataSubscription: ComponentDataSubscriptionWithRelations }>();
  @Output() titleBarDropdownItemClick = new EventEmitter<{ item: DropdownMenuItem; contentTreeItem: ContentTreeItem }>();
  @Output() previewModeChange = new EventEmitter<boolean>();
  @Output() clickToCommentIcon = new EventEmitter();

  ContentEditorDevices = ContentEditorDevices;
  selectedDevice = ContentEditorDevices.DesktopFullWidth;
  componentMapping: Record<string, any>[];
  animationOptions = renderedPageAnimationOptions;
  editorSize: ContentTreeRendererEditorSize = {
    scale: 1
  };

  /* hovered page container id */
  hoveredPageContentId: string = null;
  hoveredParentContainerId: string = null;
  /* id of the page container on which the component is dragged over */
  dragOveredPageContainerId: string;
  /**
   * dragMode is true when a component is being dragged
   *
   * @memberof RenderedPageComponent
   */
  dragMode = false;
  /**
   * The content that is being dragged
   *
   * @type {string}
   * @memberof RenderedPageComponent
   */
  draggingContent: ContentTreeItem;
  focusedContainerId: string;

  /* title bar delete button icon config */
  titleBarDeleteButtonIcon = {
    name: 'fas fa-plus',
    type: IconType.FontAwesome
  };

  /* content type enum */
  ContentTreeItemType = ContentTreeItemType;

  defaultResizeOptions = {
    box: 'content-box',
    scroll: false,
    offsetSize: false,
    debounce: { scroll: 50, resize: 0 },
    emitInZone: true,
    emitInitialResult: false,
  };

  commentPendingOrAllToggleIcon = {
    type: 'FONT_AWESOME',
    name: 'fa-light fa-comment-lines'
  };

  /* add-container-before icon */
  addContainerBeforeIcon = {
    name: 'fa-kit fa-regular-square-dashed-circle-arrow-right fa-flip-horizontal fa-lg',
    type: IconType.FontAwesome,
    color: 'var(--text-and-icon-color)'
  };

  /* add-container-after icon */
  addContainerAfterIcon = {
    name: 'fa-kit fa-regular-square-dashed-circle-arrow-right fa-lg',
    type: IconType.FontAwesome,
    color: 'var(--text-and-icon-color)'
  };

  /* add-component-before icon */
  addComponentBeforeIcon = {
    name: 'fa-kit fa-sharp-regular-puzzle-piece-simple-circle-arrow-right fa-flip-horizontal  fa-lg',
    type: IconType.FontAwesome,
    color: 'var(--text-and-icon-color)'
  };

  /* add-component-after icon */
  addComponentAfterIcon = {
    name: 'fa-kit fa-sharp-regular-puzzle-piece-simple-circle-arrow-right  fa-lg',
    type: IconType.FontAwesome,
    color: 'var(--text-and-icon-color)'
  };

  sortableOptions = {
    group: 'content',
    onEnd: (event: any) => {
      console.log(event); // Handle drop event
    },
  };

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    /* TODO: When you copy and paste a text, it is possible to paste a container that is already in the memory.
    * we will find a better way to solve this problem
    */
    if (this.activeContentTreeItem?.type === ContentTreeItemType.Container && this.focusedContainerId) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        this.copyToClipboard.emit(this.activeContentTreeItem);
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        this.pasteFromClipboard.emit(this.activeContentTreeItem);
      }
    }
  }

  // #endregion

  // #region Constructor & Component Lifecycle

  constructor(
    private renderComponentService: RenderComponentService,
    private changeDetector: ChangeDetectorRef,
    private contentEditorService: ContentEditorService,
    private dataTransformService: DataTransformService,
    private treeService: TreeService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.checkPageComponentsToTriggerRender();
    this.subscribeToEditorResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contentTree || changes.componentDefinitions) {
      /* If there is a change in the tree or componentDefinitions, render changed components */
      this.checkPageComponentsToTriggerRender();
    }
    if (changes.themeData && this.themeData) {
      this.applyTheme();
    }
  }

  // #endregion

  // #region Mouse enter & leave functions

  onMouseOverThePageContent(event: Event, pageContentId: string, parentContainerId: string, hoveredContentType: ContentTreeItemType) {
    event.stopPropagation();
    if (this.hoveredPageContentId !== pageContentId) {
      this.hoveredPageContentId = pageContentId;
    }
    if (hoveredContentType === ContentTreeItemType.Component) {
      this.hoveredParentContainerId = parentContainerId;
    } else {
      this.hoveredParentContainerId = null;
    }
  }

  onMouseLeaveThePageContent(event: Event, pageContentId: string) {
    event.stopPropagation();
    if (this.hoveredPageContentId === pageContentId) {
      this.hoveredPageContentId = null;
    }
  }

  isPageContentHovered(pageContentId: string) {
    return (
      this.hoveredPageContentId &&
      pageContentId &&
      pageContentId === this.hoveredPageContentId
    );
  }

  // #endregion End of mouse enter & leave functions

  // #region Page Container

  onAddPageContainer(event: Event, pageContainer: ContentTreeItem) {
    event.stopPropagation();
    this.activeContentTreeItemChange.emit(pageContainer.id);
    this.addPageContainer.emit(pageContainer.id);
  }

  selectPageContainer(event, content: ContentTreeItem) {
    event?.stopPropagation();
    this.activeContentTreeItemChange.emit(content.id);
    this.onContainerFocusChange(content.id);
  }

  onContainerFocusChange(containerId: string) {
    this.focusedContainerId = containerId;
  }

  isSelectedPageContainer(pageContainerId: string) {
    return (
      this.activeContentTreeItem?.id &&
      pageContainerId &&
      pageContainerId === this.activeContentTreeItem?.id
    );
  }

  setPageContainerClasses(content: any, containerId: string): string {
    const classNames = [
      content.cssClassName,
      ...content.cssClasses
    ];

    if (this.checkPageContainerOverflow(containerId)) {
      classNames.push('overflow-warning-border');
    }
    if (this.isSelectedPageContainer(containerId)) {
      classNames.push('selected-page-container');
    }
    if (content?.isMainContainer) {
      classNames.push('main-container-wrapper');
    }
    if (content?.children && content?.children?.length) {
      classNames.push('page-container page-container-with-child');
    } else {
      classNames.push('page-container page-container-without-child');
    }
    if (!content?.autoColumnWidth) {
      classNames.push(`col-${content.columnWidth}`);
    }
    if (this.readonly) {
      classNames.push('readonly-item');
    }

    return classNames.join(' ');
  }

  checkPageContainerOverflow(elementId: string) {
    const element = document.getElementById(elementId);
    // return element?.offsetWidth < element?.scrollWidth;
    // fixme
    return false;
  }

  setPageContainerLayoutSettings(pageContainer) {
    return {
      'display': pageContainer?.displayMode,
      'flex-direction': pageContainer?.flexOptions?.flexDirection,
      'flex-wrap': pageContainer?.flexOptions?.flexWrap,
      'justify-content': pageContainer?.flexOptions?.flexJustifyContent,
      'align-items': pageContainer?.flexOptions?.flexAlignItems,
      'align-content': pageContainer?.flexOptions?.flexAlignContent
    };
  }

  setPageContainerCustomClasses(pageContainer: ContentTreeContainer) {
    return pageContainer?.cssClasses.join(' ');
  }

  isPageContainerTitleBarVisible(content: ContentTreeItem) {
    return (!this.dragMode && this.isSelectedPageContainer(content.id)) || this.isPageContentHovered(content.id);
  }

  // #endregion End of Page Containers

  // #region Page Component Functions

  onClickAddComponentInPageContainer(event: Event, pageContainer: ContentTreeItem) {
    event?.stopPropagation();
    /* set active content tree item for add component inside */
    this.activeContentTreeItemChange.emit(pageContainer.id);
    this.addComponentInPageContainer.emit(pageContainer.id);
  }

  onClickAddElementInPageContainer(event: Event, pageContainer: ContentTreeItem) {
    event?.stopPropagation();
    /* set active content tree item for add component inside */
    this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId: pageContainer.id }));
    this.addElementInPageContainer.emit(pageContainer.id);
  }

  isPageComponentTitleBarVisible(content: ContentTreeComponent) {
    return !this.dragMode
      && (this.isPageContentHovered(content?.component?.id)
        || this.isSelectedComponent(content?.component?.id));
  }

  /**
   * set active content tree item
   *
   * @param {Event} event
   * @param {*} content
   * @memberof RenderedPageComponent
   */
  selectComponent(event: Event, content: ContentTreeComponent) {
    const existingComponentMapping = this.componentMapping.find(c => content.componentId === c.componentId);
    event?.stopPropagation();
    this.activeContentTreeItemChange.emit(content.id);
  }

  isSelectedComponent(componentId: string): boolean {
    return componentId && this.activeContentTreeItem?.componentId === componentId;
  }

  // #endregion End of Page Component Functions

  // #region Page Component Render

  /* this function avoids re-rendering the tree unnecessarily, ngFOR only re-render contents when 'content.id' change */
  trackContent(index, content) {
    return content.id;
  }

  checkPageComponentsToTriggerRender() {
    /* timout for wait view */
    setTimeout(() => {
      const oldComponentMapping = this.componentMapping;
      this.componentMapping = [];
      this.mapComponentsInTree(this.contentTree?.[0]);
      const componentMappingDiff = this.dataTransformService.compareArrays(oldComponentMapping, this.componentMapping, 'componentId', true);
      componentMappingDiff?.createdArray?.forEach(createdComponent => {
        this.renderComponent(createdComponent);
      });
      componentMappingDiff?.updatedArray?.forEach(updatedComponent => {
        this.renderComponent(updatedComponent.item);
      });
    }, 100);
  }



   transformValidators(form: any): any {
    if (!form || !form.component || !form.component.inputs || !Array.isArray(form?.component?.inputs?.config?.items)) {
      return form;
    }

    form?.component?.inputs?.config?.items.forEach((item: any) => {
      if (Array.isArray(item.validators)) {
        item.validators.forEach((validator: any, index: number) => {
          if (typeof validator.type === "string") {
            if (validator.type.startsWith("Validators.")) {
              const validatorName = validator.type.split("(")[0].split(".")[1];

              if (Validators[validatorName]) {
                if (validator.type.includes("(")) {
                  const param = validator.type.match(/\(([^)]+)\)/);
                  if (param) {
                    item.validators[index].type = Validators[validatorName](+param[1]);
                  }
                } else {
                  item.validators[index].type = Validators[validatorName];
                }
              }
            }
          }
        });
      }
    });

    return form;
  }


  mapComponentsInTree(node: Record<string, any>, parentPageComponentContentId?: string) {
    if (node?.type === ContentTreeItemType.Component) {
      node = this.transformValidators(node);
      const componentDefinition = this.componentDefinitions?.find(componentDefinition => componentDefinition.id === node?.component?.componentDefinitionId);
      /* map children components of system generated component */
      if (componentDefinition?.isSystemGenerated === true) {
        this.mapComponentsInTree(componentDefinition.contentTreeItem, node.id);
      } else if (parentPageComponentContentId) {
        const systemGeneratedComponentContent = this.treeService.findNodeInTree(this.contentTree[0], parentPageComponentContentId, 'id', ['children']);
        const inputFieldName = systemGeneratedComponentContent?.component?.componentDefinition?.contentTreeItem?.fieldNameToComponentDefinitionIds?.find(element => element.contentId === node.id)?.fieldName;
        this.componentMapping.push({
          ...node,
          componentDefinition: componentDefinition,
          /* if the component belongs to systemGeneratedComponent, build nodeToRootPath field by the systemGeneratedComponent's content id */
          nodeToRootPath: this.treeService.findNodeToRootPath(this.contentTree[0], parentPageComponentContentId, 'id', ['children']),
          parentPageComponentContentId: parentPageComponentContentId,
          /* if the component belongs to systemGeneratedComponent, add this field for detect inputs changes.
           * Because we save child component inputs to systemGeneratedComponent(parent)
           */
          inputs: systemGeneratedComponentContent.component.inputs[inputFieldName]
        });
      } else {
        this.componentMapping.push({
          ...node,
          componentDefinition: componentDefinition,
          nodeToRootPath: this.treeService.findNodeToRootPath(this.contentTree[0], node.id, 'id', ['children'])
        });
      }
    } else if (node?.children?.length) {
      node.children.forEach(child => {
        this.mapComponentsInTree(child, parentPageComponentContentId);
      });
    }
  }

  renderComponent(content) {
    const elementContainer = this.containers?.toArray()?.find(container => container.element.nativeElement.id === content.componentId);
    const component = content.component;

    if (elementContainer && component?.componentDefinition) {
      let componentDisplayedInputs;
      /* if component is belongs to the system generated component, set inputs from system generated component  */
      if (content.parentPageComponentContentId) {
        /* we use tree for render system generated component, find root component from contentTree */
        const systemGeneratedComponent = this.contentEditorService
          .findContentTreeItemInContentTree(content.parentPageComponentContentId, cloneDeep(this.contentTree[0]));
        /* insert component definition into parent component */
        systemGeneratedComponent.component['componentDefinition'] = this.componentDefinitions?.find(
          componentDefinition => componentDefinition.id === systemGeneratedComponent.component.componentDefinitionId);
        const contentId = elementContainer?.element?.nativeElement?.attributes?.content_id?.value;
        /* find existing field name for child component in fieldNameToComponentDefinitionIds mapping */
        const inputFieldName = systemGeneratedComponent?.component?.componentDefinition?.contentTreeItem?.fieldNameToComponentDefinitionIds?.find(
          element => element.contentId === contentId)?.fieldName;

        /* assign default inputs to deleted fields cause of data subscription */
        componentDisplayedInputs = {
          ...systemGeneratedComponent?.component?.componentDefinition?.defaultInputs?.[inputFieldName],
          ...systemGeneratedComponent?.component?.inputs?.[inputFieldName],
        };
      } else {
        /* assign default inputs to deleted fields cause of data subscription */
        componentDisplayedInputs = {
          ...component.componentDefinition.defaultInputs,
          ...component.inputs
        };
      }
      elementContainer.clear();
      /* render and add component to container ref */
      this.renderComponentService.renderComponentAndAddToContainer(
        component.componentDefinition.className,
        elementContainer,
        componentDisplayedInputs
      );
    }

    return true;
  }

  // #endregion End of Page Component Render

  // #region Layout Outlet Container Functions

  setOutletContainerClasses(content: LayoutOutlet) {
    const classes = ['outlet-container'];

    if (this.isSelectedPageContainer(content.id)) {
      classes.push('selected-outlet-container');
    }

    return classes;
  }

  // #endregion Layout Outler Container Functions

  // #region Drag drop functions

  onDragStart(event, draggingContent: ContentTreeItem) {
    event.stopPropagation();
    /* set drag mode as true when drag start */
    this.dragMode = true;
    /* set dragging content when drag start */
    this.draggingContent = draggingContent;
    /* detach the angular change detector because of the performance issues  */
    this.changeDetector.detach();
  }

  onDragEnd(event) {
    /* reattach the angular change detector when the drag ends, it is detached on drag start  */
    this.dragMode = false;
    this.changeDetector.reattach();
    event.stopPropagation();
  }

  onDrop(event, pageContainer: ContentTreeContainer) {
    event.preventDefault();
    this.dragMode = false;
    /* reattach the angular change detector when the dragged ite is dropped, detached on drag start  */
    this.changeDetector.reattach();
    /*
     * Run drop logic only if the drop target is flagged as component drop zone and
     * dragging content id is not equal to dropped page container id
    */
    if (event.target?.dataset?.componentDropzone && this.draggingContent.id !== pageContainer.id) {
      this.hoveredPageContentId = pageContainer.id;
      this.draggedContentTreeItemDropped.emit(
        { targetContentTreeItem: this.draggingContent, targetPageContainer: pageContainer, targetIndex: 0 });
    }
    event.stopPropagation();
  }

  onDragOver(event, pageContainerId?: string) {
    event.preventDefault();

    if (event.target?.dataset?.componentDropzone && this.hoveredPageContentId !== pageContainerId) {
      this.hoveredPageContentId = pageContainerId;
      this.dragOveredPageContainerId = pageContainerId;
      /* trigger the detectChanges so that hovered dragOvered page ids are set */
      this.changeDetector.detectChanges();
    }
    event.stopPropagation();
  }

  onDragLeave(event, pageContainerId: string) {
    event.preventDefault();
    if (event.target?.dataset?.componentDropzone) {
    }
    event.stopPropagation();
  }

  onDragEnter(event, pageContainerId: string) {
    event.preventDefault();
    if (event.target?.dataset?.componentDropzone && this.hoveredPageContentId !== pageContainerId) {
      this.hoveredPageContentId = pageContainerId;
      this.dragOveredPageContainerId = pageContainerId;
      /* trigger the detectChanges so that hovered dragOvered page ids are set */
      this.changeDetector.detectChanges();
    }
    event.stopPropagation();
  }
  // #endregion

  // #region Editor Resize

  /**
   * subscribes to editor element resize events.
   * update editorSize variable if editorSizes (width/height) are not equals
   * blockSize => height
   * inlineSize => width
   *
   * @memberof ContentTreeRendererComponent
   */
  subscribeToEditorResize() {
    const intervalRef = setInterval(() => {
      const editorContainerElement: any = document.querySelector('#editor-container');
      if (editorContainerElement) {
        const resizeObserver = new ResizeObserver((e) => {
          if (this.editorSize.width !== e?.[0].borderBoxSize[0].inlineSize ||
            this.editorSize.height !== e?.[0].borderBoxSize[0].blockSize
          ) {
            this.editorSize = {
              ...this.editorSize,
              width: e?.[0].borderBoxSize[0].inlineSize,
              height: e?.[0].borderBoxSize[0].blockSize
            };
          }
        });
        resizeObserver.observe(editorContainerElement);
        clearInterval(intervalRef);
      }
    }, 100);
  }

  onEditorSizeChange(changes: ContentTreeRendererEditorSize) {
    const el: any = document.querySelector('#editor-container');

    this.editorSize = {
      ...this.editorSize,
      ...changes
    };
    el.style.width = `${this.editorSize.width}px`;
    el.style.height = `${this.editorSize.height}px`;
    el.style.transform = `scale(${this.editorSize.scale})`;
  }

  onResizeEnd(event): void {
    this.onEditorSizeChange({ width: Math.round(event.rectangle.width), height: Math.round(event.rectangle.height) });
  }

  onFullScreen() {
    const el: any = document.querySelector('#editor-container');

    el.style.width = '98%';
    el.style.height = '98%';
  }

  // #endregion Editor Resize

  // #region Other Functions

  onTitleBarButtonClick(button: ButtonComponentConfig, content: ContentTreeItem) {
    this.activeContentTreeItemChange.emit(content.id);
    this.titleBarButtonClick.emit({ button: button, contentTreeItem: content });
  }

  onTitleBarDataSubscriptionItemControllerClick(output: { key: string; dataSubscription: ComponentDataSubscriptionWithRelations }) {
    this.titleBarDataSubscriptionItemControllerClick.emit(output);
  }

  onDropdownItemClick(item: DropdownMenuItem, content: ContentTreeItem) {
    this.activeContentTreeItemChange.emit(content.id);
    this.titleBarDropdownItemClick.emit({ item: item, contentTreeItem: content });
  }

  onPreviewModeChange(mode: boolean) {
    this.previewModeChange.emit(mode);
  }

  // #endregion Other Functions

  // #region Theme

  applyTheme() {
    const interval = setInterval(() => {
      const element = document.getElementById('project-theme');
      if (this.themeData && element) {
        clearInterval(interval);
        // set project theme
        for (const themeItem in this.themeData) {
          if (themeItem) {
            element.style.setProperty(themeItem, this.themeData[themeItem]);
          }
        }
        if (this.themeData.customTheme) {
          // set custom theme
          Object.entries(this.themeData.customTheme).forEach(([key, value]: any) => {
            if (key && value) {
              element.style.setProperty(key, value);
            }
          });
        }
      }
    }, 100
    );
  }

  // #endregion Theme

  onSelectedDeviceChange(selectedDevice) {
    this.selectedDevice = selectedDevice;
  }

  componentHasComments(contentTreeItemId) {
    return this.allCommentsOnPage?.some(comment => comment.contentTreeItemId === contentTreeItemId);
  }

  onClickToCommentIcon() {
    this.clickToCommentIcon.emit();
  }

  onAddComponentWithPosition(
    event: Event, contentTreeItem: ContentTreeItem, position: 'before' | 'after', type: 'component' | 'container') {
    event?.stopPropagation();
    // emit the position and action so that we can create it when the user selects the component
    this.addComponentWithPosition.emit({ position: position, referenceContentTreeItem: contentTreeItem, type: type });
  }

}


