import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { WorkflowStepFunctionCreateComponent } from './components/workflow-step-function-create/workflow-step-function-create.component';
import { WorkflowStepFunctionDetailComponent } from './components/workflow-step-function-detail/workflow-step-function-detail.component';
import { WorkflowStepFunctionEditComponent } from './components/workflow-step-function-edit/workflow-step-function-edit.component';
import { WorkflowStepFunctionListComponent } from './components/workflow-step-function-list/workflow-step-function-list.component';
import {
  RappiderListGridModule,
  RappiderTitleModule,
  RappiderEditFormModule,
  RappiderHeadingModule,
  RappiderTextModule,
  RappiderTitleBarModule,
  RappiderTitleToolbarModule,
  RappiderSelectModule,
  RappiderCodeEditorModule,
  RappiderRadioGroupModule,
  RappiderTextboxModule,
  RappiderCrudDetailModule,
  RappiderSpinModule,
  RappiderModalModule,
  RappiderButtonModule,
  RappiderCrudViewModule,
  RappiderMonacoEditorModule
} from '@rappider/rappider-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFunctionModule } from 'libs/custom-function/src/lib/custom-function.module';
/* internal modules */
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { AuthGuard } from '@rappider/authentication/guards';
import { ActiveProjectGuard, VerifyPersonGuard } from '@rappider/shared/guards';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { WorkflowStepFunctionFormComponent } from './components/workflow-step-function-form/workflow-step-function-form.component';
import { WorkflowStepFunctionDataTransformationComponent } from './components/workflow-step-function-data-transformation/workflow-step-function-data-transformation.component';
import { DataTransformationModule } from '@rappider/data-transformation';

const routes: Routes = [
  {
    path: PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.CHILDREN.WORKFLOW_STEP_FUNCTION_LIST.URL,
    component: WorkflowStepFunctionListComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.CHILDREN.WORKFLOW_STEP_FUNCTION_CREATE.URL,
    component: WorkflowStepFunctionCreateComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.CHILDREN.WORKFLOW_STEP_FUNCTION_EDIT.URL}/:id`,
    component: WorkflowStepFunctionEditComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.CHILDREN.WORKFLOW_STEP_FUNCTION_DETAIL.URL}/:id`,
    component: WorkflowStepFunctionDetailComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  }
];

@NgModule({
  declarations: [
    WorkflowStepFunctionCreateComponent,
    WorkflowStepFunctionDetailComponent,
    WorkflowStepFunctionEditComponent,
    WorkflowStepFunctionListComponent,
    WorkflowStepFunctionFormComponent,
    WorkflowStepFunctionDataTransformationComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzButtonModule,
    RappiderSelectModule,
    RappiderCodeEditorModule,
    RappiderRadioGroupModule,
    RappiderTextboxModule,
    RappiderListGridModule,
    RappiderTitleModule,
    RappiderEditFormModule,
    RappiderHeadingModule,
    RappiderTextModule,
    RappiderTitleBarModule,
    RappiderTitleToolbarModule,
    RappiderCrudDetailModule,
    RappiderSpinModule,
    RappiderModalModule,
    CustomFunctionModule,
    RappiderButtonModule,
    RappiderCrudViewModule,
    NzCollapseModule,
    RappiderMonacoEditorModule,
    DataTransformationModule
  ],
  exports: [
    WorkflowStepFunctionCreateComponent,
    WorkflowStepFunctionDetailComponent,
    WorkflowStepFunctionEditComponent,
    WorkflowStepFunctionListComponent,
    WorkflowStepFunctionFormComponent,
    WorkflowStepFunctionDataTransformationComponent
  ]
})
export class WorkflowStepFunctionModule { }
