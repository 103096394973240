<footer class="footer">
    <div class="footer-left">
        <!-- Active Project -->
        <div class="footer-element">
            <button *ngIf="activeProject"
                    class="active-project-button"
                    nz-button
                    nz-dropdown
                    [nzDropdownMenu]="menu"
                    nzPlacement="topLeft"
                    (click)="navigateToProjectDetailPage()">
                <i class="fa-regular fa-warehouse mb-1"></i>
                <h6>{{activeProject.name}}</h6>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                        <ng-container *ngFor="let project of projects">
                            <li nz-menu-item
                                (click)="onProjectSelect(project)">{{project.name}}</li>
                        </ng-container>
                    </ul>
                </nz-dropdown-menu>
            </button>
        </div>
        <!-- Content editor page -->
        <div *ngIf="contentEditorActivePage"
             class="footer-element footer-element-transparent">
            <i class="fa-light fa-file-code"></i>
            <h6>
                {{contentEditorActivePage?.title}}
            </h6>
        </div>
        <!-- Content editor selected content tree -->
        <div *ngIf="activeNodeToRootPath?.length"
             class="footer-element footer-element-transparent">
            <ng-container *ngTemplateOutlet="contentEditorSelectedContentTreeTemplate"></ng-container>
        </div>
    </div>
    <div class="footer-right">
        <div *ngIf="processInfoStatus !== ProcessInfoStatus.Completed && projectCreationStatus"
             class="process-info">
            <rappider-spin [spinning]="true"
                           [size]="'small'"></rappider-spin>
            <h6>Project creation is in progress...</h6>
        </div>
        <div *ngIf="projectDownloadStatus"
             class="process-info">
            <rappider-spin [spinning]="true"
                           [size]="'small'"></rappider-spin>
            <h6>Project download is in progress...</h6>
        </div>
        <div *ngIf="projectGenerateStatus"
             class="process-info">
            <rappider-spin [spinning]="true"
                           [size]="'small'"></rappider-spin>
            <h6>Project generate code is in progress...</h6>
        </div>
        <div *ngIf="projectViewCodeStatus"
             class="process-info">
            <rappider-spin [spinning]="true"
                           [size]="'small'"></rappider-spin>
            <h6>Loading source code...</h6>
        </div>
        <div *ngIf="projectAiStatus"
             class="process-info">
            <rappider-spin [spinning]="true"
                           [size]="'small'"></rappider-spin>
            <h6>Rapider AI is in progress...</h6>
        </div>

        <!-- Content Editor Clipboard -->
        <div *ngIf="clipboardData && isClipboardDataVisible"
             class="footer-element footer-element-transparent"
             nzTooltipTitle="Copied Template"
             nzTooltipPlacement="top"
             nz-tooltip>
            <i class="fa-light fa-clipboard"></i>
            <h6>
                {{clipboardData.metadata?.pageTitle}} / {{clipboardData.metadata?.containerTitle}}
            </h6>
        </div>
        <!-- Theme -->
        <div *ngIf="selectedTheme"
             class="footer-element"
             (click)="changeSelectedTheme()">
            <i *ngIf="selectedTheme === 'dark'"
               class="fa-solid fa-moon"></i>
            <i *ngIf="selectedTheme === 'light'"
               class="fa-regular fa-sun-bright"></i>
        </div>
    </div>
</footer>


<!-- Templates -->

<ng-template #contentEditorSelectedContentTreeTemplate>
    <nz-breadcrumb nzSeparator=">">
        <ng-container *ngIf="activeNodeToRootPath?.length < 5;else breadcrumbWithDropdown">
            <ng-container *ngFor="let node of activeNodeToRootPath">
                <nz-breadcrumb-item (click)="setActiveContentTreeItem(node)">
                    <ng-container
                                  *ngTemplateOutlet="contentTreeItemBreadcrumbTitle;context:{node: node}"></ng-container>
                </nz-breadcrumb-item>
            </ng-container>
        </ng-container>
        <ng-template #breadcrumbWithDropdown>
            <nz-breadcrumb-item (click)="setActiveContentTreeItem(activeNodeToRootPath[0])">
                <ng-container
                              *ngTemplateOutlet="contentTreeItemBreadcrumbTitle;context:{node: activeNodeToRootPath[0]}"></ng-container>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item nz-dropdown
                                [nzDropdownMenu]="breadcrumbDropdownMenu">
                <i class="fa-regular fa-ellipsis"></i>
            </nz-breadcrumb-item>
            <nz-breadcrumb-item
                                (click)="setActiveContentTreeItem(activeNodeToRootPath[activeNodeToRootPath.length - 1])">
                <ng-container
                              *ngTemplateOutlet="contentTreeItemBreadcrumbTitle;context:{node: activeNodeToRootPath[activeNodeToRootPath.length - 1]}"></ng-container>
            </nz-breadcrumb-item>
            <nz-dropdown-menu #breadcrumbDropdownMenu>
                <ul nz-menu
                    nzSelectable>
                    <ng-container *ngFor="let node of activeNodeToRootPath; let i = index">
                        <li *ngIf="i !== 0 && i !== activeNodeToRootPath?.length - 1"
                            nz-menu-item
                            (click)="setActiveContentTreeItem(node)">
                            <ng-container
                                          *ngTemplateOutlet="contentTreeItemBreadcrumbTitle;context:{node: node}"></ng-container>
                        </li>
                    </ng-container>
                </ul>
            </nz-dropdown-menu>
        </ng-template>
    </nz-breadcrumb>
</ng-template>

<ng-template #contentTreeItemBreadcrumbTitle
             let-node="node">
    <ng-container *ngIf="node.type === ContentTreeItemType.Component">
        {{node?.component?.title || 'Component'}}
    </ng-container>
    <ng-container *ngIf="node.type === ContentTreeItemType.Container">
        {{node?.title || 'Container'}}
    </ng-container>
</ng-template>
