import { Component, EventEmitter, Input, OnChanges, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ComponentDefinitionInterface } from '@rappider/api-sdk';
import { CategorizedData } from '@rappider/models';
import { IconComponentConfig, IconType, InputGroupComponentConfig } from '@rappider/rappider-components/utils';
import { ComponentDefinitionWithRelations, ContainerTemplateWithRelations } from '@rappider/rappider-sdk';
import { ComponentDefinitionService, RenderComponentService, SearchService } from '@rappider/services';
import { CategorizedComponentDefinitionGroup } from '@rappider/shared/interfaces';
import { CopyTemplateToPageContainer } from '../../../state/container-template-state/container-template.actions';
import { CreateComponent } from '../../../state/content-editor.actions';
import { v4 } from 'uuid';
import { ContentTreeContainer, ContentTreeItem, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';

@Component({
  selector: 'rappider-component-menu',
  templateUrl: './component-menu.component.html',
  styleUrls: ['./component-menu.component.scss']
})
export class ComponentMenuComponent implements OnChanges {

  /**
   * Container ref for render component view dynamically
   *
   * @type {ViewContainerRef}
   * @memberof ComponentMenuComponent
   */
  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer: ViewContainerRef;

  @Input() componentDefinitions: ComponentDefinitionWithRelations[];
  /* flag for when component is used in modal  */
  @Input() isComponentUsingInModal = false;
  @Input() activeContentTreeItem: ContentTreeItem;
  @Input() containerTemplates: ContainerTemplateWithRelations[];

  @Output() componentAdded = new EventEmitter<void>();

  categorizedComponentDefinitions: CategorizedComponentDefinitionGroup[];
  hoveredComponentDefinitionId: string;

  /* searchOptions for fuse */
  searchOptions = {
    threshold: 0.2,
    keys: [
      'title',
      'tags',
      'mainCategory.title'
    ]
  };
  /* groupped search results */
  matchedComponentDefinitionGroups: CategorizedData[];
  searchText: string;

  inputGroupConfig: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'CONTENT_EDITOR_MODULE.COMPONENT_MENU_COMPONENT.SEARCH_COMPONENTS'
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome
    }
  };

  addComponentButtonIcon: IconComponentConfig = {
    name: 'fas fa-plus',
    type: IconType.FontAwesome,
  };

  ContentEditorContentType = ContentTreeItemType;
  areThereAnyActiveSearch = false;
  searchHistory: string[] = [];

  constructor(
    private renderComponentService: RenderComponentService,
    private searchService: SearchService,
    private componentDefinitionService: ComponentDefinitionService,
    private translateService: TranslateService,
    private store: Store<any>
  ) { }

  ngOnChanges(): void {
    this.setCategorizedComponentDefinitions();
  }

  setCategorizedComponentDefinitions() {
    this.categorizedComponentDefinitions = this.componentDefinitionService
      .categorizeComponentDefinitions(this.componentDefinitions)?.filter(componentDefinition => componentDefinition.name !== 'elements');
  }

  onAddComponentDefinitionButtonClick(componentDefinition: ComponentDefinitionInterface) {
    this.store.dispatch(new CreateComponent({
      componentDefinitionId: componentDefinition.id,
      title: componentDefinition.title,
      inputs: componentDefinition.defaultInputs
    }));
    /* emit for add component modal */
    this.componentAdded.emit();
  }

  onSearchTextChange() {
    this.areThereAnyActiveSearch = true;
    const searchId = v4();
    this.searchHistory.push(searchId);
    setTimeout(() => {
      if (this.searchHistory[this.searchHistory.length - 1] === searchId) {
        const results = this.searchService.searchAndGroupByOptions(this.componentDefinitions, this.searchOptions, this.searchText);
        this.matchedComponentDefinitionGroups = [
          {
            data: results.title,
            title: `${this.translateService.instant('CONTENT_EDITOR_MODULE.COMPONENT_MENU_COMPONENT.COMPONENT_NAME')}
            (${results.title.length})`
          },
          {
            data: results.tags,
            title: `${this.translateService.instant('CONTENT_EDITOR_MODULE.COMPONENT_MENU_COMPONENT.TAGS')}
            (${results.tags.length})`
          },
          {
            data: results['mainCategory.title'],
            title: `${this.translateService.instant('CONTENT_EDITOR_MODULE.COMPONENT_MENU_COMPONENT.CATEGORY_NAME')}
            (${results['mainCategory.title'].length})`
          }
        ];
        this.areThereAnyActiveSearch = false;
      }
    }, 1500);
  }

  getContentTreeItemAsPageContainer(contentTreeItem: ContentTreeItem): ContentTreeContainer {
    return <ContentTreeContainer>contentTreeItem;
  }

  // #region popover and render component

  /**
   * Runs when popover visibility change
   * if visibility is true finds inputs and pass to renderComponentDynamically fn
   *
   * @param {boolean} visibility
   * @param {ComponentDefinitionInterface} componentDefinition
   * @memberof ComponentMenuComponent
   */
  onPopoverVisibilityChange(visibility: boolean, componentDefinition: ComponentDefinitionInterface) {
    if (visibility) {
      /* To reach the #componentContainer template, #popoverContentTemplate must have been created.
       * wait 1ms for the #popoverContentTemplate template to be created
       */
      setTimeout(() => this.renderComponentDynamically(componentDefinition), 1);
    }
  }

  /**
   * Renders component dynamically in #componentContainer
   *
   * @param {ComponentDefinitionInterface} componentDefinition
   * @memberof ComponentMenuComponent
   */
  renderComponentDynamically(componentDefinition: ComponentDefinitionInterface) {
    if (this.componentContainer) {
      this.componentContainer.clear();
      this.renderComponentService.renderComponentAndAddToContainer(
        componentDefinition.className,
        this.componentContainer,
        componentDefinition.defaultInputs
      );
    }
  }

  // #endregion popover and render component

  onCopyTemplateToPageButtonClick(containerTemplate: ContainerTemplateWithRelations) {
    this.store.dispatch(CopyTemplateToPageContainer({
      payload: { containerTemplateId: containerTemplate.id }
    }));
    /* emit for add component modal */
    this.componentAdded.emit();
  }

  mouseOver(componentDefinition) {
    this.hoveredComponentDefinitionId = componentDefinition.id;
  }

  mouseOut() {
    this.hoveredComponentDefinitionId = undefined;
  }
}
