<!-- <section> -->
<rappider-spin [spinning]="!projectId">
  <ng-container *ngIf="projectId">
    <!-- project information -->
    <!-- <ng-template #megaMenuTemplate>
      <div class="mega-menu-container">
        <button nz-button
                nz-dropdown
                nzTrigger="click"
                (click)="onMegaMenuClose()">
          <rappider-icon [name]="'fas fa-close'"></rappider-icon> Close
        </button>
      <section>
        <div class="feature-card-container row">
          <div *ngFor="let feature of features; let i = index"
               class="feature-card  col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="feature-card-content">
              <i [class]="feature.icon"></i>
              <h5 class="feature-title">
                {{feature.title}}
              </h5>
              <p class="feature-description">
                {{feature.description}}
              </p>
              <div class="feature-links">
                <ng-container *ngFor="let subFeature of feature.subFeatures">
                  <a *ngIf="subFeature?.isVisible !== false && subFeature?.title !== 'Invite Users'; else inviteUserTemplate"
                     class="feature-link"
                     [routerLink]="subFeature.path">{{subFeature.title}}</a>
                  <ng-template #inviteUserTemplate>
                    <a *ngIf="canSeeAllSubFeature && subFeature?.isVisible !== false"
                       class="feature-link"
                       [routerLink]="subFeature.path">{{subFeature.title}}</a>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </ng-template> -->
  </ng-container>
</rappider-spin>
<!-- </section> -->
