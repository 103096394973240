/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

/* angular imports */
import { createAction, props } from '@ngrx/store';
import { ProjectDesignTask, NewProjectDesignTask } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetProjectDesignTasks = '[ProjectDesignTask] GetProjectDesignTasks',
  GetProjectDesignTasksSuccessful = '[ProjectDesignTask] GetProjectDesignTasksSuccessful',
  GetProjectDesignTasksFailure = '[ProjectDesignTask] GetProjectDesignTasksFailure',
  CreateProjectDesignTask = '[ProjectDesignTask] CreateProjectDesignTask',
  CreateProjectDesignTaskSuccessful = '[ProjectDesignTask] CreateProjectDesignTaskSuccessful',
  CreateProjectDesignTaskFailure = '[ProjectDesignTask] CreateProjectDesignTaskFailure',
  UpdateProjectDesignTask = '[ProjectDesignTask] UpdateProjectDesignTask',
  UpdateProjectDesignTaskSuccessful = '[ProjectDesignTask] UpdateProjectDesignTaskSuccessful',
  UpdateProjectDesignTaskFailure = '[ProjectDesignTask] UpdateProjectDesignTaskFailure',
  DeleteProjectDesignTask = '[ProjectDesignTask] DeleteProjectDesignTask',
  DeleteProjectDesignTaskSuccessful = '[ProjectDesignTask] DeleteProjectDesignTaskSuccessful',
  DeleteProjectDesignTaskFailure = '[ProjectDesignTask] DeleteProjectDesignTaskFailure',
  NextStep = '[ProjectDesignTask] NextStep',
  NextStepWithTimer = '[ProjectDesignTask] NextStepWithTimer',
  CheckStepStatusWithTimer = '[ProjectDesignTask] CheckStepStatusWithTimer',
  ErrorAction = '[ProjectDesignTask] ErrorAction',
  ResetStateToInitial = '[ProjectDesignTask] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[ProjectDesignTask] ResetStateToInitialChangeProject',
  ImproveProjectDescription = '[ProjectDesignTask] ImproveProjectDescription',
  ImproveProjectDescriptionSuccessful = '[ProjectDesignTask] ImproveProjectDescriptionSuccessful',
  TriggerProjectOrchestration = '[ProjectDesignTask] TriggerProjectOrchestration',
  TriggerProjectOrchestrationSuccessful = '[ProjectDesignTask] TriggerProjectOrchestrationSuccessful',
}

/* actions */

export const GetProjectDesignTasks = createAction(ActionTypes.GetProjectDesignTasks);

export const GetProjectDesignTasksSuccessful = createAction(
  ActionTypes.GetProjectDesignTasksSuccessful,
  props<{ payload: { projectDesignTasks:  ProjectDesignTask[] } }>()
);

export const GetProjectDesignTasksFailure = createAction(ActionTypes.GetProjectDesignTasksFailure);

export const CreateProjectDesignTask = createAction(
  ActionTypes.CreateProjectDesignTask,
  props<{ payload: { projectDesignTask: NewProjectDesignTask } }>()
);

export const CreateProjectDesignTaskSuccessful = createAction(
  ActionTypes.CreateProjectDesignTaskSuccessful,
  props<{ payload: { projectDesignTask: ProjectDesignTask } }>()
);

export const CreateProjectDesignTaskFailure = createAction(ActionTypes.CreateProjectDesignTaskFailure);

export const UpdateProjectDesignTask = createAction(
  ActionTypes.UpdateProjectDesignTask,
  props<{ payload: { projectDesignTaskId: string; projectDesignTask: Partial<ProjectDesignTask> } }>()
);

export const UpdateProjectDesignTaskSuccessful = createAction(
  ActionTypes.UpdateProjectDesignTaskSuccessful,
  props<{ payload: { projectDesignTaskId: string; projectDesignTask: Partial<ProjectDesignTask> } }>()
);

export const UpdateProjectDesignTaskFailure = createAction(ActionTypes.UpdateProjectDesignTaskFailure);

export const DeleteProjectDesignTask = createAction(
  ActionTypes.DeleteProjectDesignTask,
  props<{ payload: { projectDesignTaskId: string } }>()
);

export const DeleteProjectDesignTaskSuccessful = createAction(
  ActionTypes.DeleteProjectDesignTaskSuccessful,
  props<{ payload: { projectDesignTaskId: string } }>()
);

export const NextStep = createAction(
  ActionTypes.NextStep,
  props<{ payload: { activeProjectId: string } }>()
);

export const NextStepWithTimer = createAction(
  ActionTypes.NextStepWithTimer,
  props<{ payload: { activeProjectId: string; delay: number } }>()
);

export const CheckStepStatusWithTimer = createAction(
  ActionTypes.NextStepWithTimer,
  props<{ payload: { activeProjectId: string } }>()
);


export const ImproveProjectDescription = createAction(
  ActionTypes.ImproveProjectDescription,
);

export const ImproveProjectDescriptionSuccessful = createAction(
  ActionTypes.ImproveProjectDescriptionSuccessful,
  props<{ payload: { projectId: string; improvedDescription: string } }>()
);

export const TriggerProjectOrchestration = createAction(
  ActionTypes.TriggerProjectOrchestration,
);

export const TriggerProjectOrchestrationSuccessful = createAction(
  ActionTypes.TriggerProjectOrchestrationSuccessful,
  props<{ payload: { projectId: string } }>()
);

export const DeleteProjectDesignTaskFailure = createAction(ActionTypes.DeleteProjectDesignTaskFailure);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);
