import { ItemPerRow, HeadingType, SelectMode, MenuMode } from '@rappider/rappider-components/utils';
import { PreviewGridType } from 'libs/component-definition/src/lib/components/component-definitions-preview/model/preview-grid-type.enum';

export const snippetsCardsConfig = {
    itemCountPerRow: ItemPerRow.Four,
    showTitleOnImage: false,
    showDescriptionOnImage: false,
    showCheckMarkOnCard: false,
    title: {
        mainTitle: {
            content: 'Building Blocks Library',
            type: HeadingType.H2
        }
    },
    paginationConfig: {
        pageIndex: 1,
        total: 3,
        pageSize: 6,
        showPagination: true
    },
    selectConfig: {
        options: [],
        settings: {
            mode: SelectMode.Multiple,
            maxTagCount: 1
        },
        placeholder: 'Filter Tags',
        visibility: true
    },
    items: [],
    menuConfig: {
        mode: MenuMode.Vertical,
        items: []
    },
    buttons: [
        {
            key: PreviewGridType.Row,
            icon: {
                name: 'fas fa-grip-lines'
            }
        },
        {
            key: PreviewGridType.TwoColumns,
            icon: {
                name: 'fas fa-line-columns'
            }
        },
        {
            key: PreviewGridType.FourColumns,
            icon: {
                name: 'fa-solid fa-columns-3'
            }
        }
    ]
};
