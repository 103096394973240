import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rappider-base-features',
  templateUrl: './base-features.component.html',
  styleUrls: ['./base-features.component.scss']
})
export class BaseFeaturesComponent implements OnInit {

  ngOnInit(): void {
    console.log('Component initialized.');
  }

}
