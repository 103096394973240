<div *ngIf="!activeProjectModelId"
     class="endpoint-list-header">
  <rappider-internal-title-toolbar [mainTitle]="mainTitle"
                                   [options]="titleBreadcrumb"
                                   [displayToolbar]="displayToolbar"
                                   [displayToolbarBackButton]="displayToolbarBackButton"></rappider-internal-title-toolbar>
  <div class="searchCollapse">
    <rappider-input-group [(ngModel)]="searchText"
                          [textbox]="endpointListSearchBarComponentConfig.textbox"
                          [suffixIcon]="endpointListSearchBarComponentConfig.suffixIcon"
                          (ngModelChange)="onSearchTextChange()"></rappider-input-group>

    <div class="collapseToggle">
      <rappider-button [text]="changeViewText | translate"
                       [disabled]="!openAPIDefinition"
                       [tooltipText]="openAPIDefinition ? null : 'Please wait while we prepare your API specifications. The Swagger view will be available shortly for your convenience' | translate"
                       (confirm)="changeView()"></rappider-button>
      <rappider-button [text]="collapseAndExpandButtons.expandButton.text"
                       [type]="collapseAndExpandButtons.expandButton.type"
                       [key]="collapseAndExpandButtons.expandButton.key"
                       [icon]="collapseAndExpandButtons.expandButton.icon"
                       (confirm)="collapseOrExpandAll(collapseAndExpandButtons.expandButton.key)">
      </rappider-button>
      <rappider-button [text]="collapseAndExpandButtons.collapseButton.text"
                       [type]="collapseAndExpandButtons.collapseButton.type"
                       [key]="collapseAndExpandButtons.collapseButton.key"
                       [icon]="collapseAndExpandButtons.collapseButton.icon"
                       (confirm)="collapseOrExpandAll(collapseAndExpandButtons.collapseButton.key)">
      </rappider-button>
    </div>
  </div>
</div>

<ng-container *ngIf="gridView">
  <ng-container *ngIf="!activeProjectModelId; else projectModelEndpointListTemplate">
    <nz-collapse [nzAccordion]="false">
      <nz-collapse-panel *ngFor="let data of searchText ? searchResults : projectModelListData"
                         [nzHeader]="data.name"
                         [(nzActive)]="panels[data?.id]"
                         [nzExtra]="collapseHeaderTemplate">
        <ng-container [ngTemplateOutlet]="data.name === userControllerClassName ? userDataModelTemplate : projectModelEndpointListTemplate"
                      [ngTemplateOutletContext]="{endpoints:data.children}"></ng-container>

        <ng-template #collapseHeaderTemplate>
          <div (mouseover)="changeText=true"
               (mouseout)="changeText=false">
            <rappider-button [text]="addEndpointButtonConfig.text | translate"
                             [icon]="addEndpointButtonConfig.icon"
                             [size]="addEndpointButtonConfig.size"
                             (click)="navigateToCreateEndpointPage(data?.id)"></rappider-button>
          </div>
        </ng-template>
      </nz-collapse-panel>
    </nz-collapse>
  </ng-container>

  <ng-template #projectModelEndpointListTemplate
               let-endpoints="endpoints">
    <rappider-model-endpoint-list #projectModelEndpointList
                                  [projectModelEndpoints]="activeProjectModelId ? activeProjectModel?.children : endpoints"
                                  [activeProjectModelId]="activeProjectModelId"
                                  [activeProject]="activeProject"
                                  [isLoading]="isLoading"></rappider-model-endpoint-list>
  </ng-template>

  <ng-template #userDataModelTemplate>
    <div class="info-message">
      <i class="fa-solid fa-circle-info"></i>
      <p>{{'You should use Show Swagger button to see endpoints in this model.' | translate}}</p>
    </div>
  </ng-template>
</ng-container>

<div class="swagger-wrapper"
     [ngStyle]="{'display': !gridView ? 'block' : 'none' }"
     id="swagger-ui"></div>
