import { ButtonComponentConfig, ButtonSize, IconType } from '@rappider/rappider-components/utils';

export const PreDTButtonConfig: ButtonComponentConfig = {
  text: 'DATA_TRANSFORMATION_MODULE.PRE_DATA_TRANSFORMATION',
  icon: {
    name: 'fal fa-database',
    type: IconType.FontAwesome
  },
  block: true
};

export const PostDTButtonConfig: ButtonComponentConfig = {
  text: 'DATA_TRANSFORMATION_MODULE.POST_DATA_TRANSFORMATION',
  icon: {
    name: 'fad fa-database',
    type: IconType.FontAwesome
  },
  block: true
};
