<div class="main-layout">
  <!-- HEADER AREA -->
  <div *ngIf="false"
       [ngClass]="{'home-pricing-active': !isHomePricingVisible, 'home-pricing': isHomePricingVisible}"
       (click)="navigateToPayment()">
    <div class="top-button-wrapper">
      <div class="buttons">
        <rappider-icon class="limited-time-offer-icon"
                       [name]="homePricingConfigApp.limitedTimeOfferIcon.name"
                       [color]="homePricingConfigApp.limitedTimeOfferIcon.color"
                       [type]="homePricingConfigApp.limitedTimeOfferIcon.type"
                       [size]="homePricingConfigApp.limitedTimeOfferIcon.size"></rappider-icon>
        <rappider-label class="limited-time-offer-label"
                        [content]="homePricingConfigApp.limitedTimeOfferLabel.content"
                        [colorSettings]="homePricingConfigApp.limitedTimeOfferLabel.colorSettings"
                        [typography]="homePricingConfigApp.limitedTimeOfferLabel.typography"></rappider-label>
      </div>
    </div>
    <rappider-button class="pricing-close-button"
                     [icon]="homePricingConfigApp.pricingCloseButtonIcon"
                     [size]="homePricingConfigApp.pricingCloseButtonIcon.size"
                     [customColorSettings]="homePricingConfigApp.pricingCloseButtonIcon.customColorSettings"
                     [borderSettings]="homePricingConfigApp.pricingCloseButtonIcon.borderSettings"
                     (click)="pricingCloseButtonClick()"></rappider-button>
  </div>
  <div *ngIf="isHeaderVisible"
       [ngClass]="{'header-active': !isHomePricingVisible, 'header-layout': isHomePricingVisible}">
    <rappider-header [activePath]="activePath"
                     [routePageTitle]="routePageTitle"
                     [showPageTitle]="showPageTitle"
                     [theme]="selectedTheme"
                     [layoutMode]="layoutMode"
                     [leftContainerSize]="splitterLeftContainerSize"
                     (menuToggled)="onMenuToggled($event)"
                     (lastActivePath)="activePath"
                     (resetLayout)="onResetLayout()"
                     (displayBlueprintPanelClicked)="onDisplayBlueprintPanelClicked()"
                     (displayCodePanelClicked)="onDisplayCodePanelClicked()"
                     (displayAllPanelsClicked)="onDisplayAllPanelsClicked()"
                     (displayDesignPanelClicked)="onDisplayDesignPanelClicked()"
                     (rightSidebarToggleButtonClick)="onRightSidebarToggle($event)"></rappider-header>
  </div>

  <div class="content-layout">
    <!-- TODO: Use the width from the styles -->
    <div class="left-side-container"
         [ngClass]="{'left-side-container-active': isHomePricingVisible}"
         *ngIf="isMenuVisible "
         [style.width]="isLayoutsVisible ? (isWorkSpaceMenuCollapsed ? '50px' : '279px') : '0px'">
      <div class="menu-container"
           [style.width]="isLayoutsVisible ? (isWorkSpaceMenuCollapsed ? '50px' : '279px') : '0px'">
        <!-- Workspace menu -->
        <div class="workspace-menu"
             [ngStyle]="{'justify-content': activeProject ? 'space-between' : 'flex-end'}">
          <rappider-menu *ngIf="workSpaceMenu.items?.length && isPersonVerified && activeProject"
                         [isDisabled]="!activeProject"
                         [isCollapsed]="isWorkSpaceMenuCollapsed"
                         [config]="workSpaceMenu"
                         [showLabels]="!isWorkSpaceMenuCollapsed"
                         [paddingValue]="rappiderThemeSettings.workspaceMenuPadding"
                         (menuItemClick)="workSpaceMenuItemClicked($event)"></rappider-menu>
          <rappider-menu *ngIf="workSpaceFooterMenu.items?.length && isPersonVerified"
                         [isCollapsed]="isWorkSpaceMenuCollapsed"
                         [config]="workSpaceFooterMenu"
                         [showLabels]="!isWorkSpaceMenuCollapsed"
                         [paddingValue]="rappiderThemeSettings.workspaceMenuPadding"
                         (menuItemClick)="workSpaceMenuItemClicked($event)"></rappider-menu>
        </div>
        <!-- Pages menu -->
        <div *ngIf="pagesMenu"
             class="pages-menu">
          <rappider-menu *ngIf="pagesMenu?.items?.length"
                         [config]="pagesMenu"></rappider-menu>
        </div>
      </div>
    </div>
    <!-- Inner content area (without side menu) -->
    <div class="inner-layout layout"
         [ngClass]="{'inner-layout-max-height' : !isLayoutsVisible}"
         [style.padding-left]="isLayoutsVisible ? (isWorkSpaceMenuCollapsed ? '50px' : '279px') : '0px'">
      <div class="inner-content-wrapper layout"
           [ngClass]="{'p-0': noInnerContentPadding}">

        <!-- PERSON VERIFICATION ALERT  -->
        <div *ngIf="isAlertVisible && activePerson && !activePerson.isVerified && PERSON_VERIFICATION_ALERT_CONFIG"
             class="mb-3">
          <rappider-alert [type]="PERSON_VERIFICATION_ALERT_CONFIG.type"
                          [title]="PERSON_VERIFICATION_ALERT_CONFIG.title"
                          [description]="PERSON_VERIFICATION_ALERT_CONFIG.description"
                          [showIcon]="PERSON_VERIFICATION_ALERT_CONFIG.showIcon"
                          [closeable]="PERSON_VERIFICATION_ALERT_CONFIG.closeable"
                          [actionConfig]="PERSON_VERIFICATION_ALERT_CONFIG.actionConfig"
                          [actionConfigPlacement]="PERSON_VERIFICATION_ALERT_CONFIG.actionConfigPlacement"
                          (actionClick)="resendVerifyEmailOrSms()">
          </rappider-alert>
        </div>
        <!-- END OF PERSON VERIFICATION ALERT  -->

        <as-split unit="percent"
                  direction="horizontal"
                  [gutterSize]="(layoutMode && layoutMode !== LayoutMode.Hidden) ? (splitterLeftContainerSize ? 7 : 0) : 0"
                  gutterAriaLabel="divider"
                  (dragEnd)="splitDragEnds($event)"
                  #split="asSplit">
          <div *asSplitGutter
               class="custom-settings-area-gutter"
               [style.display]="layoutMode ? (splitterLeftContainerSize ? 'block' : 'none') : 'none'">
            <div asSplitGutterDragHandle
                 class="custom-settings-area-gutter"></div>
          </div>
          <as-split-area [size]="splitterLeftContainerSize">
            <div class="splitter-left-container"
                 *ngIf="layoutMode && activePerson">
              <div class="splitter-left-content-container">
                <ng-container *ngIf="layoutMode && layoutMode !== LayoutMode.Hidden">
                  <!-- BLUE PRINT NAVIGATOR: PROJECT DESIGN COMPONENT -->
                  <rappider-project-design [displayWizardOnIntroduction]="!displayWizardModal"
                                           [isLoading]="!projectDesignTasksLoaded"
                                           [wizardStepsConfigs]="wizardStepsConfigs"
                                           [activeWizardStep]="activeWizardStep"
                                           [displayWizard]="displayWizard"
                                           (collapseButtonClicked)="onBlueprintManagerCollapseButtonClicked()"
                                           (layoutModeChanged)="setLayoutMode($event)"
                                           (displayCodePanelClicked)="onDisplayCodePanelClicked()"
                                           (displayDesignPanelClicked)="onDisplayDesignPanelClicked()"></rappider-project-design>
                </ng-container>
              </div>
            </div>

          </as-split-area>
          <as-split-area [size]="splitterRightContainerSize">
            <as-split direction="vertical"
                      unit="percent"
                      restrictMove="true"
                      [gutterSize]="(layoutMode && layoutMode !== LayoutMode.Hidden) ? (splitterLeftContainerSize ? 7 : 0) : 0"
                      gutterAriaLabel="divider"
                      (dragEnd)="verticalSplitDragEnds($event)">
              <as-split-area [size]="splitterSourceCodeContainerSize">
                <ng-container
                              *ngIf="activeProject?.id && activePerson && layoutMode && layoutMode !== LayoutMode.Hidden">
                  <rappider-source-code-editor></rappider-source-code-editor>
                </ng-container>
              </as-split-area>
              <as-split-area [size]="splitterRouterOutletContainerSize"
                             [ngClass]="{'splitter-outlet-container': activePerson, 'relative-splitter': !isRelativeSplitter}">
                <router-outlet (activate)="onRouteChange()"></router-outlet>
              </as-split-area>
            </as-split>
          </as-split-area>
        </as-split>


      </div>

    </div>
    <!-- <div *ngIf="rightSidebarVisibility && activePerson"
         class="right-sidebar"
         [@sidebarToggleAnimation]>
      <rappider-right-sidebar [visibility]="rightSidebarVisibility"
                              [activeTabName]="activeRightSidebarTabName"></rappider-right-sidebar>
    </div> -->

  </div>
  <!-- Footer is at the bottom of the main layout  -->
  <div *ngIf="isFooterVisible"
       class="footer-layout">
    <rappider-footer [activeProject]="activeProject"
                     [selectedTheme]="selectedTheme"></rappider-footer>
  </div>
</div>

<rappider-app-search *ngIf="appSearchVisibility && activeProject"></rappider-app-search>
<rappider-global-error></rappider-global-error>


<nz-modal [nzVisible]="activeProject?.id && activePerson && layoutMode && layoutMode !== LayoutMode.Hidden && displayWizardModal"
          [nzOkText]="wizardStepsConfigs?.length ? wizardStepsConfigs[activeWizardStep]?.introductionNextButtonTitle ?? 'Next' : ''"
          [nzFooter]="wizardFooter"
          [nzCancelText]="null"
          [nzTitle]="wizardTitle"
          [nzWidth]="'80%'"
          nzClassName="wizard-modal"
          (nzOnCancel)="onWizardModalCancelled()">

  <!-- footer next button [id]="ProjectDesignSettingButtons.WizardNextButton" -->

  <ng-container *nzModalContent>
    <rappider-wizard-rabbit [activeProjectId]="activeProject?.id"
                            [wizardStepsConfigs]="wizardStepsConfigs"
                            [activeWizardStep]="activeWizardStep"
                            [displayRabbitOnHeader]="true"
                            (typingsCompleted)="onWizardModalTypingsCompleted($event)"></rappider-wizard-rabbit>
  </ng-container>

  <ng-template #wizardTitle>
    <div>
      <h2>
        <span *ngIf="activeWizardStep">
          {{(+activeWizardStep) + '. '}}
        </span>
        <span>
          {{wizardStepsConfigs[activeWizardStep]?.title || 'Rapider AI App Builder
          Wizard'}}
        </span>
      </h2>
    </div>
  </ng-template>

  <ng-template #wizardFooter>
    <div class="wizard-footer">
      <div class="footer-left">
      </div>
      <div class="footer-right">

        <button id="wizardNextButton"
                nz-button
                nzType="primary"
                [nzLoading]="true"
                (click)="onWizardModalNextButtonClicked()">{{wizardStepsConfigs[activeWizardStep]?.introductionNextButtonTitle
          ?? 'Next'}}</button>
      </div>
    </div>
  </ng-template>
</nz-modal>
