import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, throwError } from 'rxjs';
import { BreadcrumbOption, HeadingComponentConfig, TimelineItem } from '@rappider/rappider-components/utils';
import { GitActionWorflowRunDetailResponseDto, Project, ProjectWithRelations } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { LivePreviewBuild, LivePreviewInformation, LivePreviewInformationStatus } from '@rappider/shared/interfaces';
import { titleBarActionButtonsConfig } from './utils/title-bar-action-buttons.config';
import { deployErrorAlertConfig } from './utils/deploy-error-alert.config';
import {
  GetGitActionWorkflowRunDetails,
  GetLivePreviewInformation,
  StopLivePreviewInformationPolling
} from '../../states/deploy-management/deploy-management.actions';
import { DeployManagementState } from '../../states/deploy-management/deploy-management.reducer';
import { DeployStatus } from './utils/deploy-status.enum';
import { TimelineItemColor } from 'libs/components/src/lib/utils/timeline/timeline-item-color.enum';
import { RunJobConclusion } from './utils/run-job-conclusion.enum';
import { projectDeploymentAnimation } from './utils/project-deployment-animation';
import { RunJobStatus } from './utils/run-job-status.enum';
import { formatDistanceToNow } from 'date-fns';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'rappider-live-preview',
  templateUrl: './live-preview.component.html',
  styleUrls: ['./live-preview.component.scss']
})
export class LivePreviewComponent implements OnInit, OnDestroy {

  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'Live Preview',
    type: defaultToolbarTitleHeadingSize
  };

  /* breadcrumb title of the page */
  title: string[] | BreadcrumbOption[];
  subscriptions: Subscription[] = [];
  activeProject: ProjectWithRelations;
  backendURL: string;
  frontendURL: string;
  refreshIframeFlag = false;
  initialUpdateBreadcrumb = false;
  frontendURLWithoutTimeStamp: string;
  livePreviewInformation: LivePreviewInformation;
  /**
   * The deployment status indicates whether the initiation of the deployment process
   * was successful or if an error occurred during its commencement;
   * it does not imply the completion or non-completion of the deployment itself.
  *
  * @type {DeployStatus}
  * @memberof LivePreviewComponent
  */
  deployStatus: DeployStatus;
  displayedBuild: LivePreviewBuild;
  isDeploymentLogVisible = true;

  isIFrameContentLoaded = false;
  iframeVisibility = false;

  titleBarActionButtons = titleBarActionButtonsConfig;
  deployErrorAlert = deployErrorAlertConfig;
  projectDeploymentAnimation = projectDeploymentAnimation;
  timelineItems: TimelineItem[];

  gitActionWorkflowRunDetails: GitActionWorflowRunDetailResponseDto;

  /**
   * timer of the git action workflow run details information getter
   *
   * @type {*}
   * @memberof LivePreviewComponent
   */
  getGitActionWorkflowRunDetailsTimer: any;

  /* enum definitions */
  LivePreviewInformationStatus = LivePreviewInformationStatus;
  DeployStatus = DeployStatus;

  /* third party libs */
  moment = moment;

  refreshFlag = false;

  constructor(
    private store: Store<{ deployManagement: DeployManagementState; activeProject: Project }>,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.stopGettingGitActionWorkflowRunDetails();
    this.store.dispatch(StopLivePreviewInformationPolling());
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToLivePreviewInformation(),
      this.subscribeToDeploymentProcessStatus(),
      this.subscribeToActiveProject(),
      this.subscribeToRunDetails(),
    ];
  }

  getLivePreviewInformation() {
    this.store.dispatch(GetLivePreviewInformation({ continuePollingAfterSuccessAndFailure: true }));
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.getLivePreviewInformation();
        this.setLivePreviewURLs(activeProject.id);
      }
      this.setPageTitle(activeProject);
    });
  }

  subscribeToDeploymentProcessStatus() {
    return this.store.select(state => state.deployManagement.deployStatus).subscribe(status => {
      this.deployStatus = status;
    });
  }

  subscribeToLivePreviewInformation() {
    return this.store.select(state => state.deployManagement?.livePreviewInformation)
      .subscribe(livePreviewInformation => {
        const currentDisplayedBuild = this.displayedBuild;

        this.livePreviewInformation = livePreviewInformation;
        this.displayedBuild = livePreviewInformation?.latest_build;

        if (currentDisplayedBuild?.run_id !== livePreviewInformation?.latest_build?.run_id) {
          /* reset tree of job details */
          this.timelineItems = [];
          /* start reaching job details */
          this.startGettingGitActionWorkflowRunDetails();
        } else if (
          currentDisplayedBuild?.status !== LivePreviewInformationStatus.InProgress &&
          this.gitActionWorkflowRunDetails?.status === RunJobStatus.Completed
        ) {
          /* stop reaching job details */
          this.stopGettingGitActionWorkflowRunDetails();
        }

        if (this.displayedBuild) {
          this.updateBreadcrumb(this.displayedBuild);
        }
      });
  }

  updateBreadcrumb(displayedBuild?: LivePreviewBuild) {
    if (!this.deployStatus && !this.initialUpdateBreadcrumb && displayedBuild.status === LivePreviewInformationStatus.Success) {
      /* Update Breadcrumb */
      if (this.title && displayedBuild.end_time) {
        this.title[2] = {
          label: `Previewing the build on ${moment(displayedBuild.end_time).calendar()}`,
        };
      }
      this.initialUpdateBreadcrumb = true;
    } else {
      // if deployStatus & displayedBuild.status exist and success,
      // also refreshCheck is false, then refresh the iframe
      if (
        displayedBuild.status === LivePreviewInformationStatus.Success &&
        !this.refreshIframeFlag
      ) {
        if (
          this.title &&
          displayedBuild.end_time &&
          displayedBuild.build_logs.backend &&
          displayedBuild.build_logs.frontend
        ) {
          // update breadcrumb
          this.title[2] = {
            label: `Previewing the build on ${moment(displayedBuild.end_time).calendar()}`,
          };

          // update url
          const frontendURL = new URL(`https://${this.activeProject.id}.app.rappider.com`);
          this.frontendURLWithoutTimeStamp = frontendURL.href;
          frontendURL.searchParams.append('timestamp', new Date().getTime().toString());
          this.frontendURL = frontendURL.href;

          // Update iframe src after 5 seconds
          setTimeout(() => {
            // src is updated to refresh iframe
            this.iframe.nativeElement.src = this.frontendURL;
          }, 5000);

          // We set it to true to avoid it from refreshing repeatedly.
          this.refreshIframeFlag = true;

          this.cdr.detectChanges();
        }
      } else if (displayedBuild.status === LivePreviewInformationStatus.InProgress) {
        // in progress condition
        this.refreshIframeFlag = false;
      }
    }
  }

  onIFrameContentLoaded() {
    this.isIFrameContentLoaded = true;
    this.cdr.detectChanges();
    // this.getIFrameStatus();
  }

  getIFrameStatus() {
    if (this.iframe) {
      console.log(this.iframe);
      const headers = new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      });

      this.http.get(this.frontendURLWithoutTimeStamp,
        { headers: headers, withCredentials: true }).pipe(
          catchError(error => {
            console.log(error);
            return throwError(error);
          })
        ).subscribe(response => {
          console.log(response);
        });
    } else {
      console.log('Iframe not ready yet, waiting...');
    }
  }

  // showLastDeployedBuild() {
  //   this.displayedBuild = this.livePreviewInformation.deployed_build;
  //   this.timelineItems = [];
  //   this.getGitActionWorkflowRunDetails();
  //   this.store.dispatch(StopLivePreviewInformationPolling());
  //   this.stopGettingGitActionWorkflowRunDetails();
  //   this.iframeVisibility = true;
  // }

  subscribeToRunDetails() {
    return this.store.select(state => state.deployManagement.gitActionWorkflowRunDetails)
      .subscribe(gitActionWorkflowRunDetails => {
        this.gitActionWorkflowRunDetails = gitActionWorkflowRunDetails;
        this.timelineItems = gitActionWorkflowRunDetails?.jobs?.map(job => ({
          label: job.startedAt
            ? moment(job.startedAt).local().format('h:mm a')
            : '',
          color: this.getColorByRunJobConclusion(job.conclusion as RunJobConclusion),
          title: job.name,
          subtitle: job.conclusion,
          paragraph: job.startedAt
            ? moment(job?.startedAt).calendar()
            : '',
          icon: {
            name: this.getIconByRunJobConclusion(job.conclusion as RunJobConclusion),
            color: this.getColorByRunJobConclusion(job.conclusion as RunJobConclusion),
            size: '16px'
          },
        })) ?? [];
      });
  }

  getIconByRunJobConclusion(conclusion: RunJobConclusion) {
    switch (conclusion) {
      case RunJobConclusion.Queued:
        return 'fa-solid fa-spinner-third fa-spin';
      case RunJobConclusion.InProgress:
        return 'fa-solid fa-spinner-third fa-spin';
      case RunJobConclusion.Skipped:
        return 'fa-solid fa-ban';
      case RunJobConclusion.Cancelled:
        return 'fa-regular fa-hexagon-exclamation';
      case RunJobConclusion.Success:
        return 'fa-solid fa-circle-check';
      case RunJobConclusion.Failure:
        return 'fa-solid fa-circle-xmark';
    }
  }

  getColorByRunJobConclusion(conclusion: RunJobConclusion) {
    switch (conclusion) {
      case RunJobConclusion.Queued: {
        return TimelineItemColor.Gray;
      }
      case RunJobConclusion.InProgress: {
        return TimelineItemColor.Orange;
      }
      case RunJobConclusion.Skipped: {
        return TimelineItemColor.Gray;
      }
      case RunJobConclusion.Cancelled: {
        return TimelineItemColor.Gray;
      }
      case RunJobConclusion.Success: {
        return TimelineItemColor.LimeGreen;
      }
      case RunJobConclusion.Failure: {
        return TimelineItemColor.OrangeRed;
      }
      default:
        return;
    }
  }

  setPageTitle(activeProject: ProjectWithRelations) {
    this.title = [
      {
        label: activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
      },
      {
        label: 'Live Preview',
      }
    ];
  }

  toggleDeploymentLogVisibility() {
    this.isDeploymentLogVisible = !this.isDeploymentLogVisible;
  }

  setDeploymentTime(date) {
    if (date) {
      return formatDistanceToNow(new Date(date), { addSuffix: true });
    } else {
      return '-';
    }
  }

  onTitleBarActionButtonClick(action) {
    if (action.key === 'deploymentLog') {
      const deploymentButton = this.titleBarActionButtons.find(button => button.key === 'deploymentLog');
      deploymentButton.icon = {
        ...deploymentButton.icon,
        name: this.isDeploymentLogVisible ? 'menu-fold' : 'menu-unfold'
      };
      this.toggleDeploymentLogVisibility();
    } else if (action.key === 'openInNewTab') {
      window.open(this.frontendURL, '_blank');
    }
  }

  setLivePreviewURLs(activeProjectId: string) {
    /* set timestamp */
    const timestamp = new Date().getTime().toString();

    /* define as url object */
    const frontendURL = new URL(`https://${activeProjectId}.app.rappider.com`);
    this.frontendURLWithoutTimeStamp = frontendURL.href;
    /* set timestamp query param */
    frontendURL.searchParams.append('timestamp', new Date().getTime().toString());
    /* set frontend url */
    this.frontendURL = frontendURL.href;

    /* define as url object */
    const backendURL = new URL(`https://${activeProjectId}.api.rappider.com`);
    /* set timestamp query param */
    backendURL.searchParams.append('timestamp', timestamp);
    /* set backend url */
    this.backendURL = backendURL.href;
  }

  setDeployStatusIcon(livePreviewStatus: LivePreviewInformationStatus) {
    const defaultIcon = 'fa-duotone fa-gear fa-spin';
    let icon: string;
    let text: string;

    if (livePreviewStatus === LivePreviewInformationStatus.Success) {
      icon = 'fa-duotone fa-check';
      text = 'Completed';
    } else if (livePreviewStatus === LivePreviewInformationStatus.InProgress) {
      icon = defaultIcon;
      text = 'In Progress';
    } else if (livePreviewStatus === LivePreviewInformationStatus.Fail) {
      icon = 'fa-duotone fa-x';
      text = 'Failed';
    } else if (livePreviewStatus === LivePreviewInformationStatus.Cancelled) {
      icon = 'fa-regular fa-hexagon-exclamation';
      text = 'Cancelled';
    } else {
      icon = defaultIcon;
      text = 'Loading';
    }

    return { icon, text };
  }

  startGettingGitActionWorkflowRunDetails() {
    if (!this.getGitActionWorkflowRunDetailsTimer) {
      this.getGitActionWorkflowRunDetails();
      this.getGitActionWorkflowRunDetailsTimer = setInterval(() => {
        this.getGitActionWorkflowRunDetails();
      }, 8000);
    }
  }

  stopGettingGitActionWorkflowRunDetails() {
    clearInterval(this.getGitActionWorkflowRunDetailsTimer);
    this.getGitActionWorkflowRunDetailsTimer = null;
  }

  getGitActionWorkflowRunDetails() {
    if (this.displayedBuild?.run_id) {
      this.store.dispatch(GetGitActionWorkflowRunDetails({ payload: { id: this.displayedBuild.run_id } }));
    }
  }

}
