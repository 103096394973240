import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren, ViewContainerRef } from '@angular/core';
import { ComponentWithRelations, ComponentDefinitionWithRelations } from '@rappider/rappider-sdk';
import { RenderComponentService } from '@rappider/services';
import { ContentTree, ContentTreeItemType } from '../../models';

@Component({
  selector: 'rappider-system-generated-component-renderer',
  templateUrl: './system-generated-component-renderer.component.html',
  styleUrls: ['./system-generated-component-renderer.component.scss']
})
export class SystemGeneratedComponentRendererComponent implements OnInit, OnChanges {
  @ViewChildren('componentContainer', { read: ViewContainerRef })
  containers: QueryList<ViewContainerRef>;

  @Input() contentTree: ContentTree;
  @Input() componentDefinitions: ComponentDefinitionWithRelations[];
  @Input() systemGeneratedComponentDefinition: ComponentDefinitionWithRelations;
  /* if you dont want to render component with default inputs pass your inputs */
  @Input() customInputs: Record<string, any>;

  componentsInTree: ComponentWithRelations[];
  ContentTreeItemType = ContentTreeItemType;

  constructor(
    private renderComponentService: RenderComponentService
  ) { }

  ngOnInit(): void {
    this.triggerRenderComponents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contentTree || changes.componentDefinitions || changes.customInputs) {
      /* If there is a change in the tree or componentDefinitions, render all components */
      this.triggerRenderComponents();
    }
  }

  /**
   * Trigger to render all components
   * wait 100ms for containers init
   *
   * @memberof RenderedPageComponent
   */
  triggerRenderComponents() {
    const delay = 100;
    setTimeout(() => {
      if (this.contentTree && this.contentTree?.length) {
        this.componentsInTree = [];
        this.findComponentsInTree(this.contentTree[0]);
        this.renderAllComponents();
      }
    }, delay);
  }

  /**
   * Renders all components in the component tree and adds to the container
   *
   * @memberof RenderedPageComponent
   */
  renderAllComponents() {
    /* delete previous renders */
    this.clearDynamicComponentContainer();
    /* if there is item(s) in pageComponent list  */
    if (this.componentsInTree?.length) {
      if (this.systemGeneratedComponentDefinition?.isIterative) {
        this.customInputs?.items?.forEach((input, iterativeIndex) => {
          /* Render each component in the list */
          this.componentsInTree.forEach(component => {
            const container = this.containers.toArray().find(container => container.element.nativeElement.id === `${component.id}/${iterativeIndex}`);
            const contentId = container?.element?.nativeElement?.attributes?.content_id?.value;
            const inputFieldName = this.systemGeneratedComponentDefinition?.contentTreeItem?.fieldNameToComponentDefinitionIds
              ?.find(element => element.contentId === contentId)?.fieldName;

            this.renderComponentService.renderComponentAndAddToContainer(
              component.componentDefinition.className,
              container,
              input?.[inputFieldName]
            );
          });
        });
      } else {
        /* Render each component in the list */
        this.componentsInTree.forEach(component => {
          const container = this.containers.toArray().find(container => container.element.nativeElement.id === component.id);
          const contentId = container?.element?.nativeElement?.attributes?.content_id?.value;
          const inputFieldName = this.systemGeneratedComponentDefinition?.contentTreeItem?.fieldNameToComponentDefinitionIds
            ?.find(element => element.contentId === contentId)?.fieldName;
          const inputs = this.customInputs ? this.customInputs[inputFieldName] : this.systemGeneratedComponentDefinition?.defaultInputs?.[inputFieldName];

          this.renderComponentService.renderComponentAndAddToContainer(
            component.componentDefinition.className,
            container,
            inputs
          );
        });
      }
    }
  }

  /**
 * Clears render container
 *
 * @memberof RenderedPageComponent
 */
  clearDynamicComponentContainer() {
    this.containers?.toArray().forEach((c) => c.clear());
  }

  /**
   * find recursively components in tree
   *
   * @param {*} root
   * @memberof RenderedPageComponent
   */
  findComponentsInTree(root: Record<string, any>) {
    if (root.type === ContentTreeItemType.Component) {
      const componentDefinition = this.componentDefinitions?.find(componentDefinition => componentDefinition.id === root.component.componentDefinitionId);
      this.componentsInTree.push({ ...root.component, componentDefinition: componentDefinition });
    } else {
      if (root?.children && root?.children?.length) {
        root.children.forEach(child => {
          this.findComponentsInTree(child);
        });
      }
    }
  }

  setPageContainerClasses(content: Record<string, any>): string {
    const classNames = [
      content.cssClassName,
      ...content.cssClasses
    ];

    if (content?.isMainContainer) {
      classNames.push('main-container-wrapper');
    }
    if (content?.children && content?.children?.length) {
      classNames.push('page-container page-container-with-child');
    } else {
      classNames.push('page-container page-container-without-child');
    }
    if (!content?.autoColumnWidth) {
      classNames.push(`col-${content.columnWidth}`);
    }

    return classNames.join(' ');
  }

  setPageContainerLayoutSettings(pageContainer) {
    return {
      'display': pageContainer?.displayMode,
      'flex-direction': pageContainer?.flexOptions?.flexDirection,
      'flex-wrap': pageContainer?.flexOptions?.flexWrap,
      'justify-content': pageContainer?.flexOptions?.flexJustifyContent,
      'align-items': pageContainer?.flexOptions?.flexAlignItems,
      'align-content': pageContainer?.flexOptions?.flexAlignContent
    };
  }

}
