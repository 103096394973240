import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { CrudFormSelectItem, DropdownMenuItem } from '@rappider/rappider-components/utils';
import { CommentWithRelations, NewPageComment, Page, PageCommentWithRelations, PageVariable, PersonWithRelations } from '@rappider/rappider-sdk';
import { CategoryService, NotificationService } from '@rappider/services';
import { CreatePageComment, DeletePageComment, UpdatePageComment } from 'libs/comment/src/lib/state/page-comment/page-comment.actions';
import { cloneDeep } from 'lodash';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { CONTAINER_CSS_SETTINGS_FORM_CONFIG } from '../../models/container-css-setting-form-config';
import { CONTAINER_SETTINGS_DROPDOWN_MENU_CONFIG, CONTAINER_SETTINGS_EDIT_TITLE_BUTTON_CONFIG, containerSettingsDropdownItemsVisibilityFunction } from '../../models/container-settings-dropdown-menu.config';
import { SAVE_CONTAINER_AS_COMPONENT_FORM_CONFIG } from '../../models/save-container-as-component-form-config';
import { UpdatePageContainerProperties } from '../../state/content-editor.actions';
import { ContentTree, ContentTreeContainer } from 'libs/content-tree-renderer/src/lib/models';
import { CommentWrapperComponent } from 'libs/comment/src/lib/components/comment-wrapper/comment-wrapper.component';
import { TreeService } from 'libs/shared/src/lib/services/tree-service/tree.service';
import { ContentEditorSettingsSection } from '../../constants/enums';

@Component({
  selector: 'rappider-container-settings',
  templateUrl: './container-settings.component.html',
  styleUrls: ['./container-settings.component.scss']
})
export class ContainerSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('saveComponentAsTemplateModalTemplate', { static: false }) saveComponentAsTemplateModalTemplate: TemplateRef<any>;
  @ViewChild('commentWrapperComponentRef') commentWrapperComponent: CommentWrapperComponent;

  @Input() pageContainerNode: ContentTreeContainer;
  @Input() page: Page;
  @Input() activePerson: PersonWithRelations;
  @Input() comments: CommentWithRelations[];
  @Input() pageComments: PageCommentWithRelations[];
  @Input() projectPeopleData: PersonWithRelations[];
  @Input() commentsLoading: boolean;
  @Input() contentTree: ContentTree;
  @Input() visiblePanelKeys?: string[] = [];
  @Input() allPanelsActive?: boolean = false;
  @Input() activeCommentId: string;

  /* comment header see all comments button action */
  @Output() seeAllCommentsClick = new EventEmitter();
  @Output() dropdownMenuItemClick = new EventEmitter<{ item: DropdownMenuItem; contentTreeItem: any }>();

  saveContainerAsComponentModalRef: NzModalRef;

  ContentEditorSettingsSection = ContentEditorSettingsSection;

  containerCssSettingsFormConfig = cloneDeep(CONTAINER_CSS_SETTINGS_FORM_CONFIG);
  containerSettingsDropdownMenuConfig = cloneDeep(CONTAINER_SETTINGS_DROPDOWN_MENU_CONFIG);
  containerSettingsEditTitleButtonConfig = cloneDeep(CONTAINER_SETTINGS_EDIT_TITLE_BUTTON_CONFIG);
  saveContainerAsComponentFormConfig = cloneDeep(SAVE_CONTAINER_AS_COMPONENT_FORM_CONFIG);


  dropdownItemVisibilityFunction = containerSettingsDropdownItemsVisibilityFunction;

  subscriptions: Subscription[];
  selectedTabIndex = 0;
  containerTitleEditMode = false;
  editedContainerTitle: string;

  pageVariables: PageVariable[];

  commentsFilteredByActiveTreeItemId: CommentWithRelations[] = [];

  constructor(
    private store: Store<any>,
    private modalService: NzModalService,
    private categoryService: CategoryService,
    private notificationService: NotificationService,
    private treeService: TreeService
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.subscribeToCategories(),
      this.subscribeToPageVariables()
    ];
    this.setActiveTreeItemComments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.containerSettingsDropdownMenuConfig.items.forEach(item => {
      item.visible = this.dropdownItemVisibilityFunction(item, this.pageContainerNode);
    });

    /* filter comments when content tree active items changes */
    if (changes.activeContentTreeItem) {
      this.setActiveTreeItemComments();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToPageVariables() {
    return this.store.select(
      createSelector(
        state => <any[]>state.pageVariables.data,
        state => <string>state.contentEditor?.page?.id,
        (pageVariables: any[], pageId: string) => pageVariables?.filter(pageVariable => pageVariable.pageId === pageId)
      )
    ).subscribe((pageVariables: any[]) => {
      if (pageVariables?.length) {
        this.pageVariables = pageVariables;
      }
    });
  }

  subscribeToCategories() {
    const categoryType = 'COMPONENT_DEFINITION';
    return this.categoryService.getCategoriesByTypeAsKeyValue(categoryType).subscribe(categoryOptions => {
      const categoryConfigItem = <CrudFormSelectItem>this.saveContainerAsComponentFormConfig.items.find(
        item => item.fieldName === 'categoryId'
      );
      const subCategoryConfigItem = <CrudFormSelectItem>this.saveContainerAsComponentFormConfig.items.find(
        item => item.fieldName === 'subCategoryIds'
      );
      categoryConfigItem.options = categoryOptions;
      subCategoryConfigItem.options = categoryOptions;

      this.saveContainerAsComponentFormConfig = { ...this.saveContainerAsComponentFormConfig };
    });
  }

  onValueChange(pageContainer: ContentTreeContainer) {
    this.store.dispatch(new UpdatePageContainerProperties({
      pageContainerId: pageContainer.id,
      flexOptions: pageContainer.flexOptions,
      cssClassName: pageContainer.cssClassName,
      title: pageContainer.title,
      columnWidth: pageContainer.columnWidth,
      autoColumnWidth: pageContainer.autoColumnWidth,
      displayMode: pageContainer.displayMode,
      isIterative: pageContainer.isIterative,
      iterativeDataConfig: pageContainer.iterativeDataConfig
    }));
  }

  onContainerCssSettingsChange(containerCssSettings) {
    this.store.dispatch(new UpdatePageContainerProperties({
      pageContainerId: this.pageContainerNode.id,
      cssClasses: containerCssSettings?.cssClasses || []
    }));
  }

  onContainerSettingsDropdownMenuItemClick(data) {
    this.dropdownMenuItemClick.emit({ item: data, contentTreeItem: this.pageContainerNode });
  }

  onEditContainerTitleButtonClick() {
    this.containerTitleEditMode = true;
    this.editedContainerTitle = cloneDeep(this.pageContainerNode.title);
  }

  onClickSeeAllComments() {
    this.seeAllCommentsClick.emit();
  }

  onCreateComment(comment: NewPageComment) {
    this.store.dispatch(CreatePageComment({ pageComment: comment }));
  }

  onEditComment(comment: CommentWithRelations) {
    this.store.dispatch(UpdatePageComment({ commentId: comment.id, comment }));
  }

  onDeleteComment(comment: PageCommentWithRelations) {
    const activePageComment = this.pageComments.find(pageComment => pageComment.commentId === comment.id);
    this.store.dispatch(DeletePageComment({ pageCommentId: activePageComment.id, commentId: activePageComment.comment.id }));
  }

  selectedTabIndexChange(index: number) {
    this.selectedTabIndex = index;
  }

  onCancelEditContainerTitle() {
    this.containerTitleEditMode = false;
    this.editedContainerTitle = this.pageContainerNode.title;
  }

  onContainerTitleSave() {
    this.containerTitleEditMode = false;
    this.store.dispatch(new UpdatePageContainerProperties({
      pageContainerId: this.pageContainerNode.id,
      title: this.editedContainerTitle
    }));
    this.pageContainerNode.title = cloneDeep(this.editedContainerTitle);
  }

  setActiveTreeItemComments() {
    if (this.pageComments.length && this.pageContainerNode?.id) {
      this.commentsFilteredByActiveTreeItemId = this.pageComments?.filter(comment =>
        comment.contentTreeItemId === this.pageContainerNode.id)?.map(pageComment =>
          <CommentWithRelations>pageComment.comment) || [];
    } else {
      this.commentsFilteredByActiveTreeItemId = [];
    }
  }

}
