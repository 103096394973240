import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommentPartial, CommentWithRelations, PersonWithRelations } from '@rappider/rappider-sdk';
import { SearchService } from '@rappider/services';
import { cloneDeep } from 'lodash';
import { CommentStatus } from '../../utils/comment-status.enum';
import { searchBarConfig } from '../../utils/comment-wrapper-search-bar.config';
import { emptyCommentData } from '../../utils/empty-comment-data';
import { Store } from '@ngrx/store';
import { DeleteReply, ReplyComment, UpdateComment, UpdateReply } from '../../state/comment/comment.actions';
import { AlertConfig, AlertTypes, ButtonComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import { CommentDisplayStatus } from '../../utils/comment-display-status.enum';

@Component({
  selector: 'rappider-comment-wrapper',
  templateUrl: './comment-wrapper.component.html',
  styleUrls: ['./comment-wrapper.component.scss']
})
export class CommentWrapperComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() comments: CommentWithRelations[] = [];
  @Input() peopleData: PersonWithRelations[];
  @Input() activePerson: PersonWithRelations;
  @Input() isLoading = true;
  @Input() scrollingCommentId;
  @Input() isInitialEmptyCommentActive = true;
  @Input() isDisplayingAllComments: boolean;

  @Output() clickCard = new EventEmitter();
  @Output() createComment = new EventEmitter<any>();
  @Output() editComment = new EventEmitter<CommentPartial>();
  @Output() deleteComment = new EventEmitter<any>();
  @Output() cancelComment = new EventEmitter<any>();

  peopleKeyValueData: KeyValue<string, string>[];
  mentionKeyValueData: KeyValue<string, string>[];
  searchText: string;
  commentSearchResults: CommentWithRelations[] = [];
  displayedComments: CommentWithRelations[] = [];
  isAddNewCommentModeActive = false;
  activeCommentsMenu = CommentDisplayStatus.Pending;

  searchBarConfig = searchBarConfig;
  CommentStatus = CommentStatus;

  commentSettingsButton: ButtonComponentConfig = {
    key: CommentDisplayStatus.Pending,
    icon: {
      name: 'fa-regular fa-filter',
    },
    tooltipText: 'Show Pending Comments'
  };

  /* searchOptions for fuse */
  searchOptions = {
    threshold: 0.2,
    keys: [
      'assignedTo.firstName',
      'assignedTo.lastName',
      'content',
      'createdBy',
      'status'
    ]
  };

  alertComponentConfig: AlertConfig = {
    type: AlertTypes.Warning,
    showIcon: true,
    closeable: false,
    title: {
      content: 'Comments Not Found',
      type: HeadingType.H5
    }
  };

  constructor(
    private searchService: SearchService,
    private store: Store<any>
  ) { }
  ngAfterViewInit(): void {
    this.scrollToComment();
  }

  ngOnInit(): void {
    this.commentSearchResults = this.comments ? cloneDeep(this.comments) : [];
    this.filterCommentsByStatus();
    if (this.isInitialEmptyCommentActive && !this.isDisplayingAllComments && !this.comments?.length) {
      this.onAddcomment();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.comments) {
      this.isAddNewCommentModeActive = false;
      this.setAssignedPerson();
    }
    if (changes.activePerson) {
      this.activePerson = { ...this.activePerson };
    }
    if (changes.peopleData) {
      this.setPeopleKeyValueData();
      this.setMentionData();
    }
    if (changes.scrollingCommentId) {
      this.scrollToComment();
    }
  }

  setPeopleKeyValueData() {
    this.peopleKeyValueData = this.peopleData?.map(person => ({
      key: `${person.firstName} ${person.lastName}`,
      value: person.id
    }));

  }

  setMentionData() {
    this.mentionKeyValueData = this.peopleData?.map(person => ({
      key: `${person.firstName} ${person.lastName}`,
      value: person.email
    }));
  }

  setAssignedPerson() {
    this.commentSearchResults = this.comments?.map(comment => ({
      ...comment,
      assignedTo: this.peopleData?.find(person => person.id === comment?.assignedToPersonId) ?? null
    }));
    this.filterCommentsByStatus();
  }

  onSearchtextChange(searchText: string) {
    const results = this.searchService.searchByOptions(this.comments, this.searchOptions, searchText);
    if (searchText) {
      this.commentSearchResults = results.map(data => data.item);
      this.filterCommentsByStatus();
    } else {
      this.commentSearchResults = this.comments;
      this.filterCommentsByStatus();
    }
  }

  onAddcomment() {
    if (!this.commentSearchResults.some(comment => comment.id === '')) {
      this.commentSearchResults = [
        {
          ...emptyCommentData,
          createdBy: `${this.activePerson?.firstName} ${this.activePerson?.lastName}`,
          category: null
        } as any,
        ...this.commentSearchResults
      ];
      this.filterCommentsByStatus();
    }
    this.isAddNewCommentModeActive = true;
  }

  onSaveComment(comment, commentId: string, createdById: string) {
    if (createdById) {
      comment = {
        ...comment,
        id: commentId
      };

      this.editComment.emit(comment);
    } else {
      this.createComment.emit(comment);
    }
    this.isAddNewCommentModeActive = false;
  }

  onCancelComment(comment: any, index: number) {
    if (comment.createdById) {
      this.cancelComment.emit();
    } else {
      this.commentSearchResults = this.commentSearchResults.filter((comment, i) => i !== index);
      this.filterCommentsByStatus();
    }
    this.isAddNewCommentModeActive = false;
  }

  onDeleteComment(comment) {
    this.deleteComment.emit(comment);
  }

  onAssignToPerson(personId: string, comment: CommentWithRelations) {
    comment.assignedTo = this.peopleData.find(person => person.id === personId);
  }

  onClickCommentCard(comment) {
    this.clickCard.emit(comment);
  }

  markCommentAsResolved(comment) {
    const commentPartial: CommentPartial = {
      status: CommentStatus.completed
    };
    this.store.dispatch(UpdateComment({ id: comment.id, comment: commentPartial }));
  }

  onReplyComment(replyContent: string, parentCommentId: string) {
    this.store.dispatch(ReplyComment({ parentCommentId: parentCommentId, reply: { content: replyContent } }));
  }

  onEditReply(data) {
    this.store.dispatch(UpdateReply({ replyId: data.replyId, parentCommentId: data.parentCommentId, reply: { content: data.content } }));
  }

  onDeleteReply(replyId: string, parentCommentId: string) {
    this.store.dispatch(DeleteReply({ parentCommentId, replyId }));
  }

  scrollToComment() {
    if (this.scrollingCommentId) {
      const el = document.getElementById(this.scrollingCommentId);
      el.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }

  filterCommentsByStatus() {
    if (this.activeCommentsMenu === CommentDisplayStatus.Completed) {
      this.displayedComments = this.commentSearchResults;
      this.commentSettingsButton.tooltipText = 'Show Pending Comments';
      this.commentSettingsButton.icon.name = 'fa-regular fa-filter';
    } else {
      this.displayedComments = this.commentSearchResults?.filter(comment => comment.status !== CommentStatus.completed);
      this.commentSettingsButton.tooltipText = 'Show All Comments';
      this.commentSettingsButton.icon.name = 'fa-regular fa-filter-slash';
    }
  }

  onCommentSettingsButtonClick() {
    if (this.activeCommentsMenu === CommentDisplayStatus.Completed) {
      this.activeCommentsMenu = CommentDisplayStatus.Pending;
      this.filterCommentsByStatus();

    } else if (this.activeCommentsMenu === CommentDisplayStatus.Pending) {
      this.activeCommentsMenu = CommentDisplayStatus.Completed;
      this.filterCommentsByStatus();
    }
  }

}
