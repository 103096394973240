/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

/* angular imports */
import { createAction, props } from '@ngrx/store';
import { ProjectSourceCode, ProjectSourceFile } from './project-source-code.interfaces';
import { CustomCode, NewCustomCode } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetProjectSourceCode = '[ProjectSourceCode] GetProjectSourceCode',
  GetProjectSourceCodeAnimated = '[ProjectSourceCode] GetProjectSourceCodeAnimated',
  StopAnimatedSourceCode = '[ProjectSourceCode] StopAnimatedSourceCode',
  GetProjectSourceCodeSuccessful = '[ProjectSourceCode] GetProjectSourceCodeSuccessful',
  GetProjectSourceCodeFailure = '[ProjectSourceCode] GetProjectSourceCodeFailure',
  GetProjectSourceFiles = '[ProjectSourceCode] GetProjectSourceFiles',
  GetProjectSourceFilesSuccessful = '[ProjectSourceCode] GetProjectSourceFilesSuccessful',
  GetProjectSourceFilesFailure = '[ProjectSourceCode] GetProjectSourceFilesFailure',
  GetProjectSourceCodes = '[ProjectSourceCode] GetProjectSourceCodes',
  GetProjectSourceCodesSuccessful = '[ProjectSourceCode] GetProjectSourceCodesSuccessful',
  GetProjectSourceCodesFailure = '[ProjectSourceCode] GetProjectSourceCodesFailure',
  GetProjectSourceFileCustomCodes = '[ProjectSourceCode] GetProjectSourceFileCustomCodes',
  GetProjectSourceFileCustomCodesSuccessful = '[ProjectSourceCode] GetProjectSourceFileCustomCodesSuccessful',
  GetProjectSourceFileCustomCodesFailure = '[ProjectSourceCode] GetProjectSourceFileCustomCodesFailure',
  UpsertProjectSourceFileCustomCodes = '[ProjectSourceCode] UpsertProjectSourceFileCustomCodes',
  UpsertProjectSourceFileCustomCodesSuccessful = '[ProjectSourceCode] UpsertProjectSourceFileCustomCodesSuccessful',
  UpsertProjectSourceFileCustomCodesFailure = '[ProjectSourceCode] UpsertProjectSourceFileCustomCodesFailure',
  DeleteProjectSourceFileCustomCodes = '[ProjectSourceCode] DeleteProjectSourceFileCustomCodes',
  DeleteProjectSourceFileCustomCodesSuccessful = '[ProjectSourceCode] DeleteProjectSourceFileCustomCodesSuccessful',
  DeleteProjectSourceFileCustomCodesFailure = '[ProjectSourceCode] DeleteProjectSourceFileCustomCodesFailure',
  ErrorAction = '[ProjectSourceCode] ErrorAction',
  ResetStateToInitial = '[ProjectSourceCode] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[ProjectSourceCode] ResetStateToInitialChangeProject',
  DownloadProjectSourceFile = '[ProjectSourceCode] DownloadProjectSourceFile',
  DownloadProjectSourceFileSuccesful = '[ProjectSourceCode] DownloadProjectSourceFileSuccesful',
  DownloadProjectSourceFileFailure = '[ProjectSourceCode] DownloadProjectSourceFileFailure',
  GenerateCode = '[ProjectSourceCode] GenerateCode',
  GenerateCodeSuccessful = '[ProjectSourceCode] GenerateCodeSuccessful',
  GenerateCodeFailure = '[ProjectSourceCode] GenerateCodeFailure',
  Deploy = '[ProjectSourceCode] Deploy',
  DeploySuccessful = '[ProjectSourceCode] DeploySuccessful',
  DeployFailure = '[ProjectSourceCode] DeployFailure',
  MonitorApplication = '[ProjectSourceCode] MonitorApplication',
  MonitorApplicationSuccessful = '[ProjectSourceCode] MonitorApplicationSuccessful',
  MonitorApplicationFailure = '[ProjectSourceCode] MonitorApplicationFailure',
  StartApplication = '[ProjectSourceCode] StartApplication',
  StartApplicationSuccessful = '[ProjectSourceCode] StartApplicationSuccessful',
  StartApplicationFailure = '[ProjectSourceCode] StartApplicationFailure',
  StopApplication = '[ProjectSourceCode] StopApplication',
  StopApplicationSuccessful = '[ProjectSourceCode] StopApplicationSuccessful',
  StopApplicationFailure = '[ProjectSourceCode] StopApplicationFailure'
}

/* actions */
/* Get single source code */
export const GetProjectSourceCode = createAction(
  ActionTypes.GetProjectSourceCode,
  props<{ payload: { sourceFilePath: string } }>()
);

export const GetProjectSourceCodeAnimated = createAction(
  ActionTypes.GetProjectSourceCodeAnimated,
  props<{ payload: { sourceFilePath: string } }>()
);

export const StopAnimatedSourceCode = createAction(
  ActionTypes.StopAnimatedSourceCode
);


export const GetProjectSourceCodeSuccessful = createAction(
  ActionTypes.GetProjectSourceCodeSuccessful,
  props<{ payload: { projectSourceCode: ProjectSourceCode } }>()
);

export const GetProjectSourceCodeFailure = createAction(ActionTypes.GetProjectSourceCodeFailure);

/* Get single source code */
export const GetProjectSourceFiles = createAction(
  ActionTypes.GetProjectSourceFiles
);

export const GetProjectSourceFilesSuccessful = createAction(
  ActionTypes.GetProjectSourceFilesSuccessful,
  props<{ payload: { projectSourceFiles: ProjectSourceFile[]; flattenedSourceFiles: any[] } }>()
);

export const GetProjectSourceFilesFailure = createAction(ActionTypes.GetProjectSourceFilesFailure);

/* Get multiple source codes */
export const GetProjectSourceCodes = createAction(
  ActionTypes.GetProjectSourceCodes,
  props<{ payload: { projectSourceCodes: ProjectSourceCode[] } }>()
);

export const GetProjectSourceCodesSuccessful = createAction(
  ActionTypes.GetProjectSourceCodesSuccessful,
  props<{ payload: { projectSourceCodes: ProjectSourceCode[] } }>()
);

export const GetProjectSourceCodesFailure = createAction(ActionTypes.GetProjectSourceCodesFailure);

/* Get source codes by specified file path */
export const GetProjectSourceFileCustomCodes = createAction(
  ActionTypes.GetProjectSourceFileCustomCodes,
  props<{ payload: { sourceFilePath: string } }>()
);

export const GetProjectSourceFileCustomCodesSuccessful = createAction(
  ActionTypes.GetProjectSourceFileCustomCodesSuccessful,
  props<{ payload: { customCodes: CustomCode[] } }>()
);

export const GetProjectSourceFileCustomCodesFailure = createAction(ActionTypes.GetProjectSourceFileCustomCodesFailure);

export const UpsertProjectSourceFileCustomCodes = createAction(
  ActionTypes.UpsertProjectSourceFileCustomCodes,
  props<{ payload: { customCodes: (NewCustomCode | CustomCode)[] } }>()
);

export const UpsertProjectSourceFileCustomCodesSuccessful = createAction(
  ActionTypes.UpsertProjectSourceFileCustomCodesSuccessful,
  props<{ payload: { createdCustomCodes: CustomCode[] } }>()
);

export const UpsertProjectSourceFileCustomCodesFailure = createAction(ActionTypes.UpsertProjectSourceFileCustomCodesFailure);

export const DeleteProjectSourceFileCustomCodes = createAction(
  ActionTypes.DeleteProjectSourceFileCustomCodes,
  props<{ payload: { customCodeIds: string[] } }>()
);

export const DeleteProjectSourceFileCustomCodesSuccessful = createAction(ActionTypes.DeleteProjectSourceFileCustomCodesSuccessful);

export const DeleteProjectSourceFileCustomCodesFailure = createAction(ActionTypes.DeleteProjectSourceFileCustomCodesFailure);

export const DownloadProjectSourceFile = createAction(ActionTypes.DownloadProjectSourceFile);

export const DownloadProjectSourceFileSuccesful = createAction(ActionTypes.DownloadProjectSourceFileSuccesful);

export const DownloadProjectSourceFileFailure = createAction(ActionTypes.DownloadProjectSourceFileFailure);

export const GenerateCode = createAction(
  ActionTypes.GenerateCode,
  props<{ payload: { projectVersionId?: string } }>()
);

export const GenerateCodeSuccessful = createAction(ActionTypes.GenerateCodeSuccessful);

export const GenerateCodeFailure = createAction(ActionTypes.GenerateCodeFailure);

export const Deploy = createAction(ActionTypes.Deploy);

export const DeploySuccessful = createAction(
  ActionTypes.DeploySuccessful,
  props<{ payload: { deployInfo: any } }>()
);

export const DeployFailure = createAction(ActionTypes.DeployFailure);

export const MonitorApplication = createAction(ActionTypes.MonitorApplication);

export const MonitorApplicationSuccessful = createAction(
  ActionTypes.MonitorApplicationSuccessful,
  props<{ payload: { monitorApplicationInfo: any } }>()
);

export const MonitorApplicationFailure = createAction(ActionTypes.MonitorApplicationFailure);

export const StartApplication = createAction(ActionTypes.StartApplication);

export const StartApplicationSuccessful = createAction(
  ActionTypes.StartApplicationSuccessful,
  props<{ payload: { startApplicationInfo: any } }>()
);

export const StartApplicationFailure = createAction(ActionTypes.StartApplicationFailure);

export const StopApplication = createAction(ActionTypes.StopApplication);

export const StopApplicationSuccessful = createAction(
  ActionTypes.StopApplicationSuccessful,
  props<{ payload: { stopApplicationInfo: any } }>()
);

export const StopApplicationFailure = createAction(ActionTypes.StopApplicationFailure);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);
