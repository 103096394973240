import { Injectable } from '@angular/core';
import { ProjectDesignTask } from '@rappider/rappider-sdk';
import { cloneDeep, orderBy } from 'lodash';
import { WIZARD_STEPS_CONFIG, WizardStepConfig, WizardStepStatus } from '../configs/wizard-steps';

@Injectable({
  providedIn: 'root'
})
export class ProjectDesignService {

  constructor() { }

  calculateWizardStepConfigs(projectDesignTasks: ProjectDesignTask[]): { activeStepIndex: number; wizardStepConfig: WizardStepConfig[] } {
    let activeWizardStepIndex = -1;
    const wizardSteps: WizardStepConfig[] = cloneDeep(WIZARD_STEPS_CONFIG);
    if (projectDesignTasks?.length) {
      activeWizardStepIndex = 0;
      orderBy(projectDesignTasks, 'index', 'asc').forEach((projectDesignTask: ProjectDesignTask) => {
        if (projectDesignTask?.identifier) {
          const stepIndex = wizardSteps.findIndex(stepConfig =>
            stepConfig.projectDesignTaskIdentifier === projectDesignTask.identifier);
          if (stepIndex > -1) {
            wizardSteps[stepIndex].status = projectDesignTask.status as WizardStepStatus;
            if(projectDesignTask.status === WizardStepStatus.Completed.toString()) {
              activeWizardStepIndex = stepIndex + 1; // + 1 more step as the current step is completed
            } else if (wizardSteps[stepIndex].status !== WizardStepStatus.NotStarted.toString()) {
              activeWizardStepIndex = stepIndex;
            }
          }
        }
      });
      // if the active step's status is 'NotStarted', set is as "introduction"
      if(wizardSteps[activeWizardStepIndex]?.status === WizardStepStatus.NotStarted.toString()) {
        wizardSteps[activeWizardStepIndex].status = WizardStepStatus.Introduction;
      }
      return {
        activeStepIndex: activeWizardStepIndex,
        wizardStepConfig: wizardSteps
      };
    } else {
      // If no project design tasks are available, return the welcome step
      return {
        activeStepIndex: wizardSteps.length + 1,
        wizardStepConfig: wizardSteps.map((step: WizardStepConfig) => <WizardStepConfig>({
          ...step,
          status: WizardStepStatus.ScaffoldedFromTemplate
        }))
      };
    }
  }

}
