/* TODO: tum url/path stringler enumlara toplansa daha temiz olur
 * ModulePaths: Routerdaki Module path ler
 * ChildPaths: Routerdaki child path ler
 * FullPaths: Routerdaki full path ler (module + / + child)
 */

export enum ModulePaths {
  Project = 'projects',
  Pages = 'pages',
  Modules = 'modules',
  ComponentBrowser = 'component-browser',
  Tenant = 'tenant',
  ContentEditor = 'content-editor',
  TemplateBrowser = 'template-browser',
  WorkflowTemplate = 'workflow-template',
  PageTemplates = 'page-templates',
  WorkflowStepFunction = 'workflow-step-function',
  DatabaseDiagramEditor = 'db-diagram-editor',
  AI = 'ai',
  AdminDashboard = 'admin-dashboard',
  ClientComment = 'client-comment',
  Categories = 'categories'
};


export enum ChildPaths {
  ComponentBrowserElements = 'elements',
  ComponentBrowserCategory = 'category',
  ComponentSearchResult = 'component-search-result',
  ComponentDefinitionDetail = 'component-definition-detail',
  ComponentDefinitionCreate = 'create-component-definition',
  ComponentDefinitionEdit = 'edit-component-definition',
  ComponenDefinitionUpdate = 'ui-data-update-function',
  ComponentDefinitionStepFunctions = 'ui-step-functions',
  ElementSearchResult = 'element-search-result'
};

export enum FullPaths {
  ProjectList = '/projects/list',
  ProjectSettings = '/projects/project-settings',
  EnvironmentVariables = '/projects/environment-variables',
  DataModels = '/projects/model-list',
  DataSchemas = '/projects/data-schema-list',
  Pages = '/pages/list',
  Modules = '/modules/list',
  Layouts = '/pages/layouts',
  ThemeList = '/projects/theme-list',
  TemplateBrowser = '/template-browser/list',
  PageTemplates = '/page-templates/list',
  Workflows = '/projects/ui-data-store-list',
  UIDataStores = '/projects/ui-data-store-list',
  UIDataStoreUpdateFunctions = '/projects/ui-data-update-function',
  Events = '/projects/ui-data-events',
  UIStepFunctions = '/projects/ui-step-functions',
  ServerStepFunctions = '/workflow-step-function/list',
  MessageTemplates = '/projects/message-template-list',
  ProjectPackages = '/projects/package-list',
  EmbeddedScripts = '/projects/script-list',
  CSStyleCustumization = '/projects/css-style-customization',
  Versions = '/projects/versions',
  ProjectMembers = '/projects/members',
  ProjectTestMembers = '/projects/test-members',
  ProjectRoles = '/projects/project-roles',
  InviteUsers = '/projects/user-invitations',
  DiagramEditor = '/ui-workflow-diagram',
  OpenApi = '/projects/openapi',
  ProjectDocument = '/projects/project-document',
  Endpoint = '/projects/endpoints',
  AgileProjectManagement = '/project-management',
  ProjectDesign = '/project-design/:id',
  WorkflowTemplate = '/workflow-template/list',
  AgileProjectManagementSprints = '/project-management/sprints',
  AgileProjectManagementEpics = '/project-management/epics',
  AgileProjectManagementLabels = '/project-management/labels',
  WorkflowFunctions = '/custom-function/workflow-function',
  DatabaseDiagram = '/db-diagram-editor',
  WorkflowDiagram = '/workflow-diagram',
  ClientComments = '/client-comment',
  Categories = '/categories/list',
  Assets = '/file-management/explorer'
};

export const PAGE_DEFINITIONS = {
  ADMIN_DASHBOARD: {
    NAME: 'Admin Dashboard',
    PAGE_TITLE: 'Admin Dashboard',
    URL: ModulePaths.AdminDashboard,
    CHILDREN: {
      PROJECTS: {
        NAME: 'Projects',
        PAGE_TITLE: 'Projects',
        URL: 'projects'
      },
      PROJECT_DETAIL: {
        NAME: 'Project Detail',
        PAGE_TITLE: 'Project Detail',
        URL: 'project-detail'
      },
      PROJECT_EDIT: {
        NAME: 'Project Edit',
        PAGE_TITLE: 'Project Edit',
        URL: 'project-edit'
      },
      PERSON_MANAGEMENT: {
        NAME: 'Person Management',
        PAGE_TITLE: 'Person Management',
        URL: 'person-management'
      },
      ROLES: {
        NAME: 'Roles',
        PAGE_TITLE: 'Roles',
        URL: 'roles'
      },
      PERMISSIONS_CRUD: {
        NAME: 'Permissions Crud',
        PAGE_TITLE: 'Permissions Crud',
        URL: 'permissions-crud'
      },
      ROLES_CRUD: {
        NAME: 'Roles Crud',
        PAGE_TITLE: 'Roles Crud',
        URL: 'roles-crud'
      },
      COMMENT_EDIT: {
        NAME: 'Comment Edit',
        PAGE_TITLE: 'Comment Edit',
        URL: 'comment-edit'
      }
    }
  },
  AI: {
    NAME: 'Rappider AI',
    PAGE_TITLE: '',
    URL: ModulePaths.AI,
    CHILDREN: {
      ASSISTANT: {
        NAME: 'Assistant',
        PAGE_TITLE: '',
        URL: 'assistant'
      }
    }
  },
  COMPONENT_BROWSER: {
    NAME: 'Component Browser',
    PAGE_TITLE: 'Component Browser',
    URL: ModulePaths.ComponentBrowser,
    CHILDREN: {
      ELEMENT: {
        NAME: 'Elements',
        PAGE_TITLE: 'Elements',
        URL: ChildPaths.ComponentBrowserElements,
      },
      CATEGORY: {
        NAME: 'Category',
        PAGE_TITLE: 'Category',
        URL: ChildPaths.ComponentBrowserCategory,
      },
      COMPONENT_SEARCH_RESULT: {
        NAME: 'Component Search Result',
        PAGE_TITLE: 'Component Search Result',
        URL: ChildPaths.ComponentSearchResult,
      },
      COMPONENT_DEFINITION_DETAIL: {
        NAME: 'Component Definition Detail',
        PAGE_TITLE: 'Component Definition Detail',
        URL: ChildPaths.ComponentDefinitionDetail,
      },
      COMPONENT_DEFINITION_CREATE: {
        NAME: 'Component Definition Create',
        PAGE_TITLE: 'Component Definition Create',
        URL: ChildPaths.ComponentDefinitionCreate,
      },
      COMPONENT_DEFINITION_EDIT: {
        NAME: 'Component Definition Edit',
        PAGE_TITLE: 'Component Definition Edit',
        URL: ChildPaths.ComponentDefinitionEdit
      },
      COMPONENT_DEFINITION_STEP_FUNCTIONS: {
        NAME: 'Component Definition Step Functions',
        PAGE_TITLE: 'Component Definition Step Functions',
        URL: ChildPaths.ComponentDefinitionStepFunctions
      }
    }
  },
  DATABASE_DIAGRAM_EDITOR: {
    NAME: 'Database Diagram Editor',
    PAGE_TITLE: 'Database Diagram Editor',
    URL: ModulePaths.DatabaseDiagramEditor
  },
  ELEMENT_BROWSER: {
    NAME: 'Elements',
    PAGE_TITLE: 'Elements',
    URL: ChildPaths.ComponentBrowserElements,
    CHILDREN: {
      ELEMENT_SEARCH_RESULT: {
        NAME: 'Element Search Result',
        PAGE_TITLE: 'Element Search Result',
        URL: ChildPaths.ElementSearchResult,
      },
    }
  },
  TEMPLATE_BROWSER: {
    NAME: 'Template-browser',
    PAGE_TITLE: 'template-browser',
    URL: ModulePaths.TemplateBrowser,
    CHILDREN: {
      TEMPLATE_LIST: {
        NAME: 'Template List',
        PAGE_TITLE: 'test',
        URL: 'list'
      }
    }
  },
  WORKFLOW_TEMPLATE: {
    NAME: 'Workflow-template',
    PAGE_TITLE: 'Workflow-template',
    URL: ModulePaths.WorkflowTemplate,
    CHILDREN: {
      WORKFLOW_TEMPLATE_LIST: {
        NAME: 'Workflow Template',
        PAGE_TITLE: 'workflow-templates',
        URL: 'list'
      }
    }
  },
  PAGE_TEMPLATES: {
    NAME: 'Page-templates',
    PAGE_TITLE: 'page-templates',
    URL: ModulePaths.PageTemplates,
    CHILDREN: {
      PAGE_TEMPLATES_LIST: {
        NAME: 'Page Templates',
        PAGE_TITLE: 'page-templates',
        URL: 'list'
      }
    }
  },
  CATEGORIES: {
    NAME: 'Categories',
    PAGE_TITLE: 'Categories',
    URL: ModulePaths.Categories,
    CHILDREN: {
      CATEGORY_LIST: {
        NAME: 'Categories',
        PAGE_TITLE: 'categories',
        URL: 'list'
      },
      CATEGORY_EDIT: {
        NAME: 'Category Edit',
        PAGE_TITLE: 'category-edit',
        URL: 'edit'
      },
      CATEGORY_CREATE: {
        NAME: 'Category Create',
        PAGE_TITLE: 'category-create',
        URL: 'create'
      }
    }
  },
  TENANT: {
    NAME: 'Tenant',
    PAGE_TITLE: 'Tenant',
    URL: ModulePaths.Tenant,
    CHILDREN: {
      TENANT_CREATE: {
        NAME: 'Tenant Create',
        PAGE_TITLE: 'TENANT_MODULE.TENANT_CREATE_COMPONENT.TENANT_CREATE',
        URL: 'create',
      },
      TENANT_DETAIL: {
        NAME: 'Tenant Detail',
        PAGE_TITLE: 'TENANT_MODULE.TENANT_DETAIL_COMPONENT.TENANT_DETAIL',
        URL: 'detail',
      },
      TENANT_EDIT: {
        NAME: 'Tenant Edit',
        PAGE_TITLE: 'TENANT_MODULE.TENANT_EDIT_COMPONENT.TENANT_EDIT',
        URL: 'edit',
      },
      TENANT_LIST: {
        NAME: 'Tenant List',
        PAGE_TITLE: 'TENANT_MODULE.TENANT_LIST_COMPONENT.TENANT_LIST',
        URL: 'list',
      },
    },
  },
  PAGES: {
    NAME: 'Pages',
    PAGE_TITLE: 'Pages',
    URL: ModulePaths.Pages,
    CHILDREN: {
      PAGES_LIST: {
        NAME: 'List',
        PAGE_TITLE: 'PAGES_MODULE.PAGE_LIST_COMPONENT.PAGE_LIST',
        URL: 'list',
      },
      PAGES_CREATE: {
        NAME: 'Create',
        PAGE_TITLE: 'PAGES_MODULE.PAGE_CREATE_COMPONENT.PAGE_CREATE',
        URL: 'create',
      },
      PAGES_DETAIL: {
        NAME: 'Detail',
        PAGE_TITLE: 'PAGES_MODULE.PAGE_DETAIL_COMPONENT.PAGE_DETAIL',
        URL: 'detail',
      },
      PAGES_EDIT: {
        NAME: 'Edit',
        PAGE_TITLE: 'PAGES_MODULE.PAGE_EDIT_COMPONENT.PAGE_EDIT',
        URL: 'edit',
      },
      PAGE_LAYOUTS_LIST: {
        NAME: 'Layouts',
        PAGE_TITLE: 'PAGES_MODULE.PAGE_LAYOUT_LIST_COMPONENT.PAGE_LAYOUT_LIST',
        URL: 'layouts',
      },
      PAGE_LAYOUTS_CREATE: {
        NAME: 'Create Layout',
        PAGE_TITLE:
          'PAGES_MODULE.PAGE_LAYOUT_CREATE_COMPONENT.PAGE_LAYOUT_CREATE',
        URL: 'layouts-create',
      },
      PAGE_LAYOUTS_EDIT: {
        NAME: 'Edit Layout',
        PAGE_TITLE: 'PAGES_MODULE.PAGE_LAYOUT_EDIT_COMPONENT.PAGE_LAYOUT_EDIT',
        URL: 'layouts-edit',
      },
    },
  },
  MODULE: {
    NAME: 'Module',
    PAGE_TITLE: 'Module',
    URL: ModulePaths.Modules,
    CHILDREN: {
      MODULE_LIST: {
        NAME: 'Module List',
        PAGE_TITLE: 'MODULE_MODULE.MODULE_LIST',
        URL: 'list',
      },
      MODULE_CREATE: {
        NAME: 'Module Create',
        PAGE_TITLE: 'MODULE_MODULE.MODULE_CREATE',
        URL: 'create',
      },
      MODULE_EDIT: {
        NAME: 'Module Edit',
        PAGE_TITLE: 'MODULE_MODULE.MODULE_EDIT',
        URL: 'edit',
      },
      MODULE_DETAIL: {
        NAME: 'Module Detail',
        PAGE_TITLE: 'MODULE_MODULE.MODULE_DETAIL',
        URL: 'detail',
      },
    },
  },
  CONTENT_EDITOR: {
    NAME: 'Content Editor',
    PAGE_TITLE: 'Content Editor',
    URL: ModulePaths.ContentEditor,
  },
  PROJECTS: {
    NAME: 'Projects',
    PAGE_TITLE: 'Projects',
    URL: ModulePaths.Project,
    CHILDREN: {
      PROJECTS_LIST: {
        NAME: 'List',
        PAGE_TITLE: 'PROJECT_MODULE.PROJECT_LIST_COMPONENT.PROJECT_LIST',
        URL: 'list',
      },
      PROJECTS_LIVE_PREVIEW: {
        NAME: 'Live Preview',
        PAGE_TITLE: 'Live Preview',
        URL: 'live-preview'
      },
      PROJECTS_CREATE: {
        NAME: 'Create',
        PAGE_TITLE: 'PROJECT_MODULE.PROJECT_CREATE_COMPONENT.PROJECT_CREATE',
        URL: 'create',
      },
      PROJECTS_DETAIL: {
        NAME: 'Detail',
        PAGE_TITLE: 'PROJECT_MODULE.PROJECT_DETAIL_COMPONENT.PROJECT_DETAIL',
        URL: 'detail',
      },
      PROJECTS_EDIT: {
        NAME: 'Edit',
        PAGE_TITLE: 'PROJECT_MODULE.PROJECT_EDIT_COMPONENT.PROJECT_EDIT',
        URL: 'edit',
      },
      PROJECTS_SETTINGS: {
        NAME: 'Project Settings',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.PROJECT_SETTINGS',
        URL: 'project-settings',
      },
      PROJECTS_ENVIRONMENT_VARIABLES: {
        NAME: 'Environment Variables',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.PROJECT_ENVIRONMENT_VARIABLES',
        URL: 'environment-variables',
      },
      PROJECTS_MESSAGE_TEMPLATE_LIST: {
        NAME: 'Message Template List',
        PAGE_TITLE: 'Message Template List',
        URL: 'message-template-list',
      },
      PROJECTS_MESSAGE_TEMPLATE: {
        NAME: 'Message Template',
        PAGE_TITLE: 'Message Template',
        URL: 'message-template',
      },
      PROJECTS_EDIT_MESSAGE_TEMPLATE: {
        NAME: 'Edit Message Template',
        PAGE_TITLE: 'Edit Message Template',
        URL: 'edit-message-template',
      },
      PROJECTS_MEMBER_LIST: {
        NAME: 'Member List',
        PAGE_TITLE: 'Member List',
        URL: 'members',
      },
      PROJECTS_ROLE_LIST: {
        NAME: 'Role List',
        PAGE_TITLE: 'Role List',
        URL: 'project-roles',
      },
      PROJECTS_VERSION_LIST: {
        NAME: 'Version List',
        PAGE_TITLE: 'Version List',
        URL: 'versions',
      },
      PROJECT_VERSION_CREATE: {
        NAME: 'Create Version',
        PAGE_TITLE: 'Create Version',
        URL: 'version-create',
      },
      PROJECTS_EXPORT: {
        NAME: 'Project Export',
        PAGE_TITLE: 'Project Export',
        URL: 'export',
      },
      PROJECTS_VERSION_DETAIL: {
        NAME: 'PROJECT_VERSION_DETAIL',
        PAGE_TITLE: 'Version Detail',
        URL: 'version-detail',
      },
      PROJECTS_TEST_MEMBER_LIST: {
        NAME: 'Test Member List',
        PAGE_TITLE: 'Test Member List',
        URL: 'test-members',
      },
      PROJECTS_THEME_SETTINGS: {
        NAME: 'Project Theme Settings',
        PAGE_TITLE: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.THEME_SETTINGS',
        URL: 'theme-settings',
      },
      PROJECTS_THEME_CREATE: {
        NAME: 'Project Theme Create',
        PAGE_TITLE: 'Project Theme Create',
        URL: 'create-theme',
      },
      PROJECTS_THEME_EDIT: {
        NAME: 'Edit Project Theme',
        PAGE_TITLE: 'Edit Project Theme',
        URL: 'edit-theme',
      },
      PROJECTS_THEME_LIST: {
        NAME: 'Project Theme List',
        PAGE_TITLE: 'Project Theme List',
        URL: 'theme-list',
      },
      ADD_PROJECT_SCRIPT: {
        NAME: 'Add Project Script',
        PAGE_TITLE:
          'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.ADD_PROJECT_SCRIPT',
        URL: 'add-project-script',
      },
      EDIT_PROJECT_SCRIPT: {
        NAME: 'Edit Project Script',
        PAGE_TITLE:
          'PROJECT_MODULE.ADD_PROJECT_SCRIPT_COMPONENT.EDIT_PROJECT_SCRIPT',
        URL: 'edit-project-script',
      },
      PROJECT_SCRIPT_LIST: {
        NAME: 'Project Script List',
        PAGE_TITLE: 'PROJECT_MODULE.PROJECT_SCRIPT_LIST_COMPONENT.SCRIPT_LIST',
        URL: 'script-list',
      },
      PROJECTS_USER_INVITATIONS: {
        NAME: 'User Invitations',
        PAGE_TITLE: 'User Invitations',
        URL: 'user-invitations',
      },
      DATA_FIELD_CREATE: {
        NAME: 'Project Model Data Field Create',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.PROJECT_MODEL_DATA_FIELD_ADD',
        URL: 'model-data-field-create',
      },
      DATA_FIELD_LIST: {
        NAME: 'Project Model Data Field List',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.PROJECT_MODEL_DATA_FIELD_LIST',
        URL: 'model-data-field-list',
      },
      DATA_FIELD_EDIT: {
        NAME: 'Project Model Data Field Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_EDIT_COMPONENT.PROJECT_MODEL_DATA_FIELD_EDIT',
        URL: 'model-data-field-edit',
      },
      PROJECT_MODEL_LIST: {
        NAME: 'Project Model List',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.PROJECT_MODEL_LIST',
        URL: 'model-list',
      },
      PROJECT_MODEL_CREATE: {
        NAME: 'Project Model Create',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_MODEL_CREATE_COMPONENT.ADD_PROJECT_MODEL',
        URL: 'model-create',
      },
      PROJECT_MODEL_EDIT: {
        NAME: 'Project Model Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_MODEL_EDIT_COMPONENT.EDIT_PROJECT_MODEL',
        URL: 'model-edit',
      },
      PROJECT_MODEL_RELATION_LIST: {
        NAME: 'Project Model Relation List',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.MODEL_RELATION_LIST',
        URL: 'model-relation-list',
      },
      PROJECT_MODEL_RELATION_CREATE: {
        NAME: 'Project Model Relation Create',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_MODEL_RELATION_CREATE_COMPONENT.ADD_MODEL_RELATION',
        URL: 'model-relation-add',
      },
      PROJECT_MODEL_RELATION_EDIT: {
        NAME: 'Project Model Relation Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_MODEL_RELATION_EDIT_COMPONENT.EDIT_MODEL_RELATION',
        URL: 'model-relation-edit',
      },
      UI_DATA_STORE_CREATE: {
        NAME: 'UI Data Store Create',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_DATA_STORE_CREATE_COMPONENT.UI_DATA_STORE_ADD',
        URL: 'ui-data-store-create',
      },
      UI_DATA_STORE_LIST: {
        NAME: 'UI Data Store List',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
        URL: 'ui-data-store-list',
      },
      UI_STEP_FUNCTIONS: {
        NAME: 'UI Step Functions',
        PAGE_TITLE: 'PROJECT_MODULE.UI_STEP_FUNCTIONS_COMPONENT.UI_STEP_FUNCTIONS',
        URL: 'ui-step-functions'
      },
      UI_DATA_STORE_EDIT: {
        NAME: 'UI Data Store Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_DATA_STORE_EDIT_COMPONENT.EDIT_UI_DATA_STORE',
        URL: 'ui-data-store-edit',
      },
      UI_DATA_STORE_DETAIL: {
        NAME: 'UI Data Store Detail',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_DATA_STORE_DETAIL_COMPONENT.UI_DATA_STORE_DETAIL',
        URL: 'ui-data-store-detail',
      },
      DATA_SCHEMA_CREATE: {
        NAME: 'Data Schema Create',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_SCHEMA_CREATE_COMPONENT.ADD_DATA_SCHEMA',
        URL: 'data-schema-create',
      },
      DATA_SCHEMA_LIST: {
        NAME: 'Data Schema List',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_SCHEMA_LIST_COMPONENT.DATA_SCHEMA_LIST',
        URL: 'data-schema-list',
      },
      DATA_SCHEMA_EDIT: {
        NAME: 'Data Schema Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_SCHEMA_EDIT_COMPONENT.EDIT_DATA_SCHEMA',
        URL: 'data-schema-edit',
      },
      DATA_SCHEMA_ELEMENTS_LIST: {
        NAME: 'Data Schema Elements',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_SCHEMA_ELEMENT_LIST_COMPONENT.DATA_SCHEMA_ELEMENTS',
        URL: 'data-schema-element-list',
      },
      DATA_SCHEMA_ELEMENT_CREATE: {
        NAME: 'Data Schema Element Create',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_SCHEMA_ELEMENT_CREATE_COMPONENT.ADD_DATA_SCHEMA_ELEMENT',
        URL: 'data-schema-element-create',
      },
      UI_DATA_STORE_FIELDS: {
        NAME: 'Ui Data Store Fields',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_DATA_STORE_FIELD_LIST_COMPONENT.UI_DATA_STORE_FIELDS',
        URL: 'ui-data-store-fields',
      },
      UI_DATA_STORE_FIELD_CREATE: {
        NAME: 'Ui Data Store Field Create',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_DATA_STORE_FIELD_CREATE_COMPONENT.UI_DATA_STORE_FIELD_CREATE',
        URL: 'ui-data-store-field-create',
      },
      UI_DATA_STORE_FIELD_EDIT: {
        NAME: 'Ui Data Store Field Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_DATA_STORE_FIELD_EDIT_COMPONENT.UI_DATA_STORE_FIELD_EDIT',
        URL: 'ui-data-store-field-edit',
      },
      UI_DATA_EVENTS: {
        NAME: 'UI Data Events',
        PAGE_TITLE: 'PROJECT_MODULE.UI_DATA_EVENTS_COMPONENT.UI_DATA_EVENTS',
        URL: 'ui-data-events',
      },
      DATA_EVENT_CREATE: {
        NAME: 'Data Event Create',
        PAGE_TITLE: 'PROJECT_MODULE.DATA_EVENT_CREATE_COMPONENT.ADD_DATA_EVENT',
        URL: 'data-event-add',
      },
      DATA_EVENT_EDIT: {
        NAME: 'Data Event Edit',
        PAGE_TITLE: 'PROJECT_MODULE.DATA_EVENT_EDIT_COMPONENT.EDIT_DATA_EVENT',
        URL: 'data-event-edit',
      },
      DATA_EVENT_PAYLOAD: {
        NAME: 'Data Event Payload',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_EVENT_PAYLOAD_COMPONENT.ADD_DATA_EVENT_PAYLOAD',
        URL: 'data-event-payload',
      },
      PROJECT_PACKAGE_LIST: {
        NAME: 'Project Package List',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_LIST',
        URL: 'package-list',
      },
      PROJECT_PACKAGE_CREATE: {
        NAME: 'Project Package Create',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_PACKAGE_CREATE_COMPONENT.PROJECT_PACKAGE_CREATE',
        URL: 'package-create',
      },
      PROJECT_PACKAGE_EDIT: {
        NAME: 'Project Package Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_PACKAGE_EDIT_COMPONENT.PROJECT_PACKAGE_EDIT',
        URL: 'package-edit',
      },
      DATA_EVENT_DETAIL: {
        NAME: 'Data Event Detail',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_EVENT_DETAIL_COMPONENT.DATA_EVENT_DETAIL',
        URL: 'data-event-detail',
      },
      UI_DATA_UPDATE_FUNCTION_CREATE: {
        NAME: 'UI Data Update Function Create',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_CREATE_COMPONENT.CREATE_UI_DATA_EVENT_UPDATE_FUNCTION',
        URL: 'create-data-update-function',
      },
      UI_DATA_UPDATE_FUNCTION: {
        NAME: 'UI Data Update Function',
        PAGE_TITLE: 'PROJECT_MODULE.UI_DATA_EVENT_UPDATE_FUNCTION_COMPONENT.UI_DATA_EVENT_UPDATE_FUNCTION',
        URL: 'ui-data-update-function'
      },
      CREATE_DATA_MAPPING_PAGE: {
        NAME: 'Create Data Mapping',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_MAPPING_CREATE_COMPONENT.CREATE_DATA_MAPPING',
        URL: 'create-data-mapping',
      },
      EDIT_CODE: {
        NAME: 'Edit Code',
        PAGE_TITLE: 'PROJECT_MODULE.DATA_MAPPINGS_COMPONENT.EDIT_CODE',
        URL: 'edit-code',
      },
      UI_STEP_FUNCTION_CREATE: {
        NAME: 'Create UI Step Function',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.CREATE_UI_STEP_FUNCTION',
        URL: 'create-ui-step-function',
      },
      UI_STEP_FUNCTION_UPDATE: {
        NAME: 'Update UI Step Function',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.UPDATE_UI_STEP_FUNCTION',
        URL: 'update-ui-step-function',
      },
      UI_STEP_FUNCTION_DETAIL: {
        NAME: 'UI Step Function Detail',
        PAGE_TITLE:
          'PROJECT_MODULE.UI_STEP_FUNCTION_DETAIL_COMPONENT.UI_STEP_FUNCTION_DETAILS',
        URL: 'ui-step-function-detail',
      },
      CUSTOM_ENDPOINT_LIST: {
        NAME: 'Custom Endpoints',
        PAGE_TITLE: 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.CUSTOM_ENDPOINTS',
        URL: 'custom-endpoints',
      },
      CUSTOM_ENDPOINT_CREATE: {
        NAME: 'Custom Endpoint Create',
        PAGE_TITLE:
          'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.CUSTOM_ENDPOINT_CREATE',
        URL: 'custom-endpoint-create',
      },
      CUSTOM_ENDPOINT_EDIT: {
        NAME: 'Custom Endpoint Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.CUSTOM_ENDPOINTS_EDIT',
        URL: 'custom-endpoint-edit',
      },
      ENDPOINT_DETAIL: {
        NAME: 'Endpoint Detail',
        PAGE_TITLE:
          'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.CUSTOM_ENDPOINTS_EDIT',
        URL: 'endpoint-detail',
      },
      DATA_SCHEMA_DATA_DEFINITION_LIST: {
        NAME: 'Data Schema Data Definitions',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_SCHEMA_DATA_DEFINITION_LIST.DATA_SCHEMA_DATA_DEFINITIONS',
        URL: 'data-schema-data-definitions',
      },
      DATA_SCHEMA_DATA_DEFINITION_CREATE: {
        NAME: 'Data Schema Data Definition Create',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_SCHEMA_DATA_DEFINITION_CREATE.CREATE_DATA_SCHEMA_DATA_DEFINITION',
        URL: 'data-schema-data-definition-create',
      },
      DATA_SCHEMA_DATA_DEFINITION_EDIT: {
        NAME: 'Data Schema Data Definition Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.DATA_SCHEMA_DATA_DEFINITION_EDIT.EDIT_DATA_SCHEMA_DATA_DEFINITION',
        URL: 'data-schema-data-definition-edit',
      },
      CSS_STYLE_CUSTOMIZATION: {
        NAME: 'CSS Style Customization',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_CSS_STYLE_CUSTOMIZATION_COMPONENT.EDIT_CSS_STYLE_CUSTOMIZATION',
        URL: 'css-style-customization',
      },
      OPENAPI_LIST: {
        NAME: 'OpenAPIs',
        PAGE_TITLE:
          'PROJECT_MODULE.OPENAPI_MODULE.OPENAPI_LIST_COMPONENT.OPENAPI_LIST',
        URL: 'openapi',
      },
      OPENAPI_ENDPOINT_LIST: {
        NAME: 'Endpoints',
        PAGE_TITLE:
          'PROJECT_MODULE.OPENAPI_MODULE.OPENAPI_ENDPOINT_LIST_COMPONENT.ENDPOINTS',
        URL: 'openapi-endpoints',
      },
      PROJECT_DOCUMENT_LIST: {
        NAME: 'Project Documents',
        PAGE_TITLE: 'PROJECT_MODULE.PROJECT_DOCUMENT_MODULE.PROJECT_DOCUMENTS',
        URL: 'project-document',
      },
      PROJECT_DOCUMENT_CREATE: {
        NAME: 'Add Project Document',
        PAGE_TITLE:
          'PROJECT_MODULE.PROJECT_DOCUMENT_MODULE.ADD_PROJECT_DOCUMENT',
        URL: 'add-project-document',
      },
      PROJECT_DOCUMENT_EDIT: {
        NAME: 'Project Document Edit',
        PAGE_TITLE: 'PROJECT_MODULE.PROJECT_DOCUMENT_MODULE.PROJECT_DOCUMENT_EDIT',
        URL: 'project-document-edit'
      },
      PROJECT_ENDPOINT_LIST: {
        NAME: 'Project Endpoint List',
        PAGE_TITLE: 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.ENDPOINTS',
        URL: 'endpoints'
      }
    }
  },
  AUTH: {
    NAME: 'Auth',
    PAGE_TITLE: 'Auth',
    URL: 'auth',
    CHILDREN: {
      PERSON_SELECTION: {
        NAME: 'Person Selection',
        PAGE_TITLE:
          'AUTHENTICATION_MODULE.PERSON_SELECTION_COMPONENT.PERSON_LIST',
        URL: 'person-selection',
      },
      ACCOUNT_VERIFICATION: {
        NAME: 'Account Verification',
        PAGE_TITLE:
          'AUTHENTICATION_MODULE.ACCOUNT_VERIFICATION_COMPONENT.ACCOUNT_VERIFICATION',
        URL: 'account-verification',
      },
      VERIFY_PHONE_NUMBER: {
        NAME: 'Verifiy Phone Number',
        PAGE_TITLE: 'Verifiy Phone Number',
        URL: 'verify-phone-number',
      },
      LOGIN: {
        NAME: 'Login',
        PAGE_TITLE: 'Login',
        URL: 'login',
      },
      REGISTER: {
        NAME: 'Register',
        PAGE_TITLE: 'Register',
        URL: 'register',
      },
      FORGOT_PASSWORD: {
        NAME: 'Forgot Password',
        PAGE_TITLE: 'Forgot Password',
        URL: 'forgot-password',
      },
      FACEBOOK_LOGIN: {
        NAME: 'Facebook Login',
        PAGE_TITLE: 'Facebook Login',
        URL: 'facebook',
      },
      GOOGLE_LOGIN: {
        NAME: 'Google Login',
        PAGE_TITLE: 'Google Login',
        URL: 'google',
      },
      GITHUB_LOGIN: {
        NAME: 'GitHub Login',
        PAGE_TITLE: 'GitHub Login',
        URL: 'github'
      },
      APPLE_LOGIN: {
        NAME: 'Apple Login',
        PAGE_TITLE: 'Apple Login',
        URL: 'apple'
      },
      FORGOT_PASSWORD_SUCCESS: {
        NAME: 'Forgot Password Success',
        PAGE_TITLE: 'Forgot Password Success',
        URL: 'forgot-password-success',
      },
      CHANGE_PASSWORD_SUCCESS: {
        NAME: 'Change Password Success',
        PAGE_TITLE: 'Change Password Success',
        URL: 'change-password-success',
      },
    },
  },
  SHOW_CASE: {
    NAME: 'showcase',
    PAGE_TITLE: 'Showcase',
    URL: 'showcase',
  },
  ERROR: {
    NAME: 'Error',
    PAGE_TITLE: 'Error',
    URL: 'error',
    CHILDREN: {
      NOT_FOUND: {
        NAME: 'Not Found',
        PAGE_TITLE: 'Not Found',
        URL: '404',
      },
    },
  },
  PROFILE_PAGE: {
    NAME: 'Profile Page',
    PAGE_TITLE: 'Profile Page',
    URL: 'profile',
    CHILDREN: {
      ONBOARDING_PAGE: {
        NAME: 'Onboarding Page',
        PAGE_TITLE: 'Onboarding Page',
        URL: 'onboarding',
      },
      CHANGE_PASSWORD: {
        NAME: 'Change Password',
        PAGE_TITLE: 'CHANGE PASSWORD',
        URL: 'change-password',
      },
      PERSON_LIST: {
        NAME: 'Account List',
        PAGE_TITLE: 'Account List',
        URL: 'account-list',
      },
      PROJECT_LIST: {
        NAME: 'Project List',
        PAGE_TITLE: 'Account List',
        URL: 'project-list',
      },
    },
  },
  FILE_MANAGEMENT: {
    NAME: 'File Management',
    PAGE_TITLE: 'FILE_MANAGEMENT',
    URL: 'file-management',
    CHILDREN: {
      EXPLORER: {
        NAME: 'File Explorer',
        PAGE_TITLE: 'FILE_EXPLORER',
        URL: 'explorer',
      },
    },
  },
  CUSTOM_FUNCTION: {
    NAME: 'Custom Function',
    PAGE_TITLE: 'CUSTOM_FUNCTION',
    URL: 'custom-function',
  },
  WORKFLOW_FUNCTION: {
    NAME: 'Workflow Function',
    PAGE_TITLE: 'WORKFLOW_FUNCTION',
    URL: 'workflow-function',
    CHILDREN: {
      WORKFLOW_FUNCTION_EDIT: {
        NAME: 'Workflow Function Edit',
        PAGE_TITLE: 'Edit Workflow Function',
        URL: 'edit'
      }
    }
  },
  WORKFLOW_STEP_FUNCTION: {
    URL: ModulePaths.WorkflowStepFunction,
    CHILDREN: {
      WORKFLOW_STEP_FUNCTION_LIST: {
        NAME: 'Workflow Step Function List',
        PAGE_TITLE:
          'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.WORKFLOW_STEP_FUNCTION_LIST',
        URL: 'list',
      },
      WORKFLOW_STEP_FUNCTION_CREATE: {
        NAME: 'Workflow Step Function Create',
        PAGE_TITLE:
          'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_COMPONENT.WORKFLOW_STEP_FUNCTION_CREATE',
        URL: 'create',
      },
      WORKFLOW_STEP_FUNCTION_EDIT: {
        NAME: 'Workflow Step Function Edit',
        PAGE_TITLE:
          'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_EDIT_COMPONENT.WORKFLOW_STEP_FUNCTION_EDIT',
        URL: 'edit',
      },
      WORKFLOW_STEP_FUNCTION_DETAIL: {
        NAME: 'Workflow Step Function Detail',
        PAGE_TITLE:
          'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_DETAIL_COMPONENT.WORKFLOW_STEP_FUNCTION_DETAIL',
        URL: 'detail',
      }
    }
  }
};

export const DUPLICATE_PAGE_TITLE_SUFFIX = ' - Copy';

export const DUPLICATE_PAGE_DESCRIPTION_PREFIX = '[Duplicated] ';
