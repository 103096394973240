<rappider-modal *ngIf="isEditModalVisible"
                title="
								Edit
								Form
								Config"
                [visible]="isEditModalVisible"
                [bodyStyle]="modalBodyStyle"
                [fullScreen]="true"
                okText="Save Form Config"
                cancelText="Cancel"
                (okClick)="onFormConfigSave()"
                (cancelClick)="onCancel()">
  <div *ngIf="tempValue"
       class="form-builder">
    <div class="col-4 pt-2">
      <rappider-crud-view-edit-form [config]="tempValue"></rappider-crud-view-edit-form>
    </div>
    <div class="col-8">
      <nz-tabset>
        <nz-tab nzTitle="Items">
          <div class="form-control-area">
            <rappider-form-builder-input [(ngModel)]="tempValue.items"
                                         (ngModelChange)="onItemsChange()">
            </rappider-form-builder-input>
          </div>
        </nz-tab>
        <nz-tab nzTitle="Form Settings">
          <div class="form-control-area">
            <div class="label">
              <span>
                <b>
                  Form Layout:
                </b>
              </span>
            </div>
            <rappider-select [options]="formLayoutOptions"
                             [(ngModel)]="tempValue.formLayout"
                             [settings]="SINGLE_SELECT_SETTINGS"
                             (ngModelChange)="onItemsChange()"></rappider-select>
          </div>
          <div class="form-control-area">
            <div class="label">
              <span>
                <b>
                  Input Change Reaction:
                </b>
              </span>
            </div>
            <rappider-select [options]="inputChangeReactions"
                             [(ngModel)]="tempValue.inputChangeReaction"
                             [settings]="SINGLE_SELECT_SETTINGS"
                             (ngModelChange)="onItemsChange()"></rappider-select>
          </div>
        </nz-tab>
        <nz-tab nzTitle="Submit Button Settings">
          <div class="form-control-area">
            <div class="label">
              <span>
                <b>
                  Text:
                </b>
              </span>
            </div>
            <rappider-textbox [(ngModel)]="tempValue.submitButton.text"
                              (ngModelChange)="onItemsChange()"></rappider-textbox>
          </div>
          <div class="form-control-area">
            <div class="label">
              <span>
                <b>
                  Size:
                </b>
              </span>
            </div>
            <rappider-select [(ngModel)]="tempValue.submitButton.size"
                             [options]="buttonSizeSelectOptions"
                             (ngModelChange)="onItemsChange()"></rappider-select>
          </div>
          <div class="form-control-area">
            <div class="label">
              <span>
                <b>
                  Type:
                </b>
              </span>
            </div>
            <rappider-select [(ngModel)]="tempValue.submitButton.type"
                             [options]="buttonTypeSelectOptions"
                             (ngModelChange)="onItemsChange()"></rappider-select>
          </div>
          <div class="form-control-area">
            <div class="label">
              <span>
                <b>
                  Block:
                </b>
              </span>
            </div>
            <rappider-switch [(ngModel)]="tempValue.submitButton.block"
                             (ngModelChange)="onItemsChange()"></rappider-switch>
          </div>
          <div class="form-control-area">
            <div class="label">
              <span>
                <b>
                  Visible:
                </b>
              </span>
            </div>
            <rappider-switch [(ngModel)]="tempValue.submitButton.visible"
                             (ngModelChange)="onItemsChange()"></rappider-switch>
          </div>
        </nz-tab>
      </nz-tabset>


    </div>
  </div>
</rappider-modal>

<rappider-button text="Edit Form Config"
                 (click)="openEditModal()"></rappider-button>