import { Injectable } from '@angular/core';
import { IndexDBService } from '../indexdb/indexdb.service';
import { CachedRemoteData, StoreKeys, SyncRemoteDataService } from '../sync/sync-remote-data.service';

// Models
import {
  DataSchemaWithRelations, ImproveProjectDescriptionByAiResponseDto, Project,
  ProjectControllerService, ProjectModelControllerService, ProjectModelField,
  ProjectModelRelation, ProjectModelWithRelations, ProjectModel,
  ScopeOfWork, ProjectModelRelationControllerService,
  GenerateDataModelRelationsFromExampleDataDto, PageWithRelations, ProjectTheme,
  ComponentDefinitionWithRelations, UiDataStore, Page, MessageTemplate,
  GenerateDataModelFromExampleDataRequestDto, GenerateScopeOfWorkByAiResponseDto,
  ScopeOfWorkControllerService
} from '@rappider/rappider-sdk';

@Injectable({
  providedIn: 'root'
})
export class CachedRemoteDataService {
  activeProject: Project | undefined;

  constructor(
    private indexDBService: IndexDBService,
    private syncRemoteDataService: SyncRemoteDataService,
  ) { }

  set activeProjectId(projectId: string | undefined) {
    this.activeProjectId = projectId;
  }

  get activeProjectId() {
    return this.activeProjectId;
  }

  getCachedData(storeKey: StoreKeys, projectId: string, isSharedForAllProjects?: boolean) {
    return this.indexDBService.getCachedRemoteData(storeKey, isSharedForAllProjects ? '_shared' : projectId).then(
      (cachedRemoteData: CachedRemoteData) => {
        console.log('Cached remote data:', cachedRemoteData);
        if (cachedRemoteData) {
          return cachedRemoteData;
        } else {
          // If no cached data found in indexdb, sync it from the server
          this.syncRemoteDataService.syncRemoteDataItemWithKey(projectId, storeKey);
          return null;
        }
      }
    );
  }



}
