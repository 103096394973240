import { HeaderMenuItemVisibilities } from '@rappider/shared/interfaces';
import * as AppActions from './app.actions';
import { logToConsole } from '@rappider/shared-functions';
import { EditorMode } from '@rappider/shared/definitions';

export interface AppState {
  loading: boolean;
  editorMode: EditorMode;
  headerMenuItemVisibilities: HeaderMenuItemVisibilities;
  pageTitle: string;
  appSearch: any;
  rightSidebarTabName: string;
  rightSidebarVisibility: boolean;
  logActionsToConsole: boolean;
}

export const initialState: AppState = {
  loading: false,
  editorMode: EditorMode.Design,
  headerMenuItemVisibilities: {
    globalStyle: false,
    theme: false,
    templateSelector: false
  },
  pageTitle: null,
  appSearch: {
    visibility: false,
    searchResults: []
  },
  rightSidebarTabName: null,
  rightSidebarVisibility: false,
  logActionsToConsole: true // logs the actions using on-action.meta-reducer.ts
};

export function appReducer(
  state: AppState = initialState,
  action: AppActions.Actions
): AppState {
  logToConsole([action, state], action.type);
  switch (action.type) {

    case AppActions.ActionTypes.LoadAppSuccessful: {
      return {
        ...state,
        loading: false
      };
    }

    case AppActions.ActionTypes.ChangeHeaderMenuItemVisibilities: {
      const obj = {};
      action.payload.forEach(item => {
        obj[item.itemName] = item.visibility;
      });
      return {
        ...state,
        headerMenuItemVisibilities: {
          ...state.headerMenuItemVisibilities,
          ...obj
        }
      };
    }

    case AppActions.ActionTypes.ChangeEditorMode: {
      return {
        ...state,
        editorMode: action.payload.editorMode || EditorMode.Design
      };
    }

    case AppActions.ActionTypes.ChangePageTitle: {
      return {
        ...state,
        pageTitle: action.payload.pageTitle
      };
    }

    case AppActions.ActionTypes.ChangeAppSearchVisibility: {
      return {
        ...state,
        appSearch: {
          ...state.appSearch,
          visibility: !state.appSearch.visibility
        }
      };
    }

    case AppActions.ActionTypes.AppSearchSuccessful: {
      return {
        ...state,
        appSearch: {
          ...state.appSearch,
          searchResults: action.payload.searchResults
        }
      };
    }

    case AppActions.ActionTypes.SetRightSidebarTabName:
      return {
        ...state,
        rightSidebarTabName: action.payload.rightSidebarTabName
      };

    case AppActions.ActionTypes.SetRightSidebarVisibility:
      return {
        ...state,
        rightSidebarVisibility: action.payload.rightSidebarVisibility
      };

    default: {
      return state;
    }

  }
}
