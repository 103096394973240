<ng-container *ngIf="!isCommentEditModeActive; else editContentTemplate">
  <div class="comment-content">
    <p [innerHTML]="preview"></p>
  </div>
</ng-container>

<!-- Edit Comment Content Template -->
<ng-template #editContentTemplate>
  <div class="edit-content-area">
    <!-- Editable Content -->
    <nz-mention [nzSuggestions]="mentionData"
                [nzValueWith]="valueWith"
                [nzNotFoundContent]="'Person not found' | translate">
      <textarea rappiderClickStopPropagation
                nz-input
                rows="2"
                [(ngModel)]="content"
                [placeholder]="'Add your comment or mention people with @' | translate"
                nzMentionTrigger
                (ngModelChange)="renderPreView()"></textarea>
      <ng-container *nzMentionSuggestion="let person">
        <div class="mention-dropdown-item">
          <span class="mention-dropdown-name">{{ person.key }}</span><br>
          <span class="mention-dropdown-email">{{ person.value }}</span>
        </div>
      </ng-container>
    </nz-mention>

    <!-- Edit Content Actions Area -->
    <div class="edit-content-actions-area flex">
      <rappider-button rappiderClickStopPropagation
                       [text]="saveContentButtonConfig.text"
                       [size]="saveContentButtonConfig.size"
                       [type]="saveContentButtonConfig.type"
                       (click)="onSaveContentButtonClick()"></rappider-button>
      <rappider-button rappiderClickStopPropagation
                       [text]="cancelButtonConfig.text"
                       [size]="cancelButtonConfig.size"
                       (click)="onCancelContentButtonClick()"></rappider-button>
    </div>
  </div>
</ng-template>
