<div *ngIf="activePerson"
     class="header">
  <div class="logo"
       [ngClass]="{'menu-collapsed': isMenuCollapsed}">
    <a (click)="navigateToProjectListPage()">
      <div class="image-container">
        <img class="logo-image"
             src="assets/img/logo/rappider-logo.jpg"
             alt="Rapider AI">
      </div>
      <div *ngIf="!isMenuCollapsed"
           class="logo-brand-name noselect">Rapider AI</div>
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="foldMenu()">
            <i nz-icon
              nz-tooltip
              nzTooltipTitle="Display Side Menu"
              nzTooltipPlacement="bottom"
              [nzType]="isMenuCollapsed? 'menu-unfold':'menu-fold'"
              theme="outline"></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="foldMenu()">
          <i nz-icon
             nz-tooltip
             nzTooltipTitle="Display Side Menu"
             nzTooltipPlacement="bottom"
             [nzType]="isMenuCollapsed? 'menu-fold': 'menu-unfold'"
             theme="outline"></i>
        </a>
      </li>
      <ng-container *ngIf="layoutMode !== 'hidden' && !leftContainerSize">
        <div joyrideStep="completeWizardFourthStep"
             title="Display Side Menu"
             text="You can toggle the design side menu to show or hide your design options and low-code feature navigation"
             (prev)="onDisplayBlueprintPanelClicked()"
             (done)="onDisplayBlueprintPanelClicked()">
          <li (click)="onDisplayBlueprintPanelClicked()"
              nz-tooltip
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Display BluePrint Panel">
            <a>
              <i class="fa-regular fa-sidebar"></i>
            </a>
          </li>
        </div>
      </ng-container>

      <!-- PROJECT NAME -->
      <li *ngIf="activeProject && showPageTitle"
          class="project-name">
        <a [routerLink]="'projects/detail/'+activeProject.id">
          <h4>
            {{activeProject?.name}}
          </h4>
        </a>
      </li>
      <!-- END OF PROJECT NAME -->
      <!-- PAGE TITLE -->
      <li *ngIf="activeProject?.name && (routePageTitle || pageTitle) && showPageTitle"
          class="page-title">
        <h5>
          <span style="font-size: larger;"> / </span> &nbsp; {{routePageTitle || pageTitle}}
        </h5>
        <!-- <rappider-heading [content]="pageTitle"
                            type="h5"></rappider-heading> -->
      </li>
      <!-- PAGE TITLE -->
    </ul>
    <ul class="nav-right">
      <rappider-button *ngIf="headerButtonVisibility"
                       [text]="headerButtons.upgradeButton.text"
                       [icon]="headerButtons.upgradeButton.icon"
                       [customColorSettings]="headerButtons.upgradeButton.customColorSettings"
                       [borderSettings]="headerButtons.upgradeButton.borderSettings"
                       [size]="headerButtons.upgradeButton.size"
                       (click)="navigateToPayment()"></rappider-button>
      <!-- Content Editor Save Button -->
      <rappider-button *ngIf="activePage && !isContentTreeChangesSaved"
                       [text]="isContentTreeSaving ? 'Saving...' : 'Save Changes'"
                       [loading]="isContentTreeSaving"
                       type="primary"
                       size="extra-small"
                       (click)="onContentEditorSaveChangesButtonClick()"></rappider-button>

      <!-- Deploying Info Text -->
      <li *ngIf="deployStatus === DeployStatus.InProgress || deployStatus === DeployStatus.Queued">
        <div class="wrapper">
          <div>
            <h6 class="text">
              <span>{{'Deployment in Progress' | translate}}</span>
            </h6>
          </div>
        </div>
      </li>

      <ng-container *ngIf="layoutMode !== 'hidden'">
        <div joyrideStep="completeWizardSecondStep"
             title="Display Code Panel"
             text="Click this button anytime to open the project code editor and view or edit your project’s code.">
          <li (click)="onDisplayCodePanelClicked()"
              nz-tooltip
              nzTooltipPlacement="bottom"
              nzTooltipTitle="Display Code Panel">
            <a>
              <i class="fa-regular fa-rectangle-code"></i>
            </a>
          </li>
        </div>
        <li (click)="onDisplayDesignPanelClicked()"
            nz-tooltip
            nzTooltipPlacement="bottom"
            nzTooltipTitle="Display Design Panel">
          <a>
            <i class="fa-regular fa-pen-ruler"></i>
          </a>
        </li>

        <li (click)="onDisplayAllPanelsClicked()"
            nz-tooltip
            nzTooltipPlacement="bottom"
            nzTooltipTitle="TPM View: Display All Panels">
          <a>
            <i class="fa-regular fa-table-columns"></i>
          </a>
        </li>
      </ng-container>

      <!-- Live Preview Deploy Button -->
      <!-- <li *ngIf="activeProject && pages"
          (click)="!!isPagesInReview ? pageStatusModalVisibilityChange() : onDeployButtonClick()"
          [class.disabled]="deployLoading">
        <a nz-tooltip
           [nzTooltipTitle]="deployTitle"
           nzTooltipPlacement="bottom">
          <i class="fa-regular fa-play"></i>
        </a>
        <ng-template #deployTitle>
          <span>Preview {{ activeProject.name }}</span>
        </ng-template>
      </li> -->

      <!-- Toggle Guidance Right Drawer -->
      <!-- <li (click)="onRightSidebarTogglerButtonClick(AppRightSidebarTab.Guidance)">
        <a>
          <i class="fa-regular fa-question"></i>
        </a>
      </li> -->

      <!-- Source Code View -->
      <!-- <li *ngIf="activeProject">
        <a nz-tooltip
           [nzTooltipTitle]="codeViewSwitchSettings?.tooltipText"
           nzTooltipPlacement="bottom"
           (click)="onCodeViewClicked()">
          <i class="fa-sharp fa-regular fa-gear-code"></i>
        </a>
      </li> -->

      <!-- Search -->
      <li *ngIf="activeProject"
          (click)="openAppSearch()">
        <a nz-tooltip
           nzTooltipTitle="Search (⌘ + ⇧ + f)"
           nzTooltipPlacement="bottom">
          <i class="fa-regular fa-magnifying-glass"></i>
        </a>
      </li>

      <!-- Notifications Button TODO: Needs to be implemented -->
      <li>
        <rappider-notification [unreadNotificationCount]="unreadNotificationCount"
                               [maxVisibleNotifications]="environment.maxVisibleNotifications"
                               [notificationList]="notificationList"
                               [emptyNotificationAnimation]="emptyNotificationAnimation"
                               (selectNotification)="onSelectNotification($event)"></rappider-notification>
      </li>

      <!-- User Drop Down Button -->
      <li>

        <a nz-dropdown
           [nzDropdownMenu]="profile"
           [nzTrigger]="'click'"
           [nzPlacement]="'bottomRight'">
          <i nz-icon
             nzType="user"
             theme="outline"></i>
        </a>
        <nz-dropdown-menu #profile="nzDropdownMenu">
          <ul nz-menu
              class="profile-menu">
            <li class="p-h-20 p-b-15 m-b-10 border-bottom p-t-15">
              <div class="d-flex m-r-50">
                <nz-avatar nzSize="large"
                           [nzIcon]="userProfilePictureUrl ? null: 'user'"
                           [nzSrc]="userProfilePictureUrl || null"></nz-avatar>
                <div class="m-l-10">
                  <p class="m-b-0 font-weight-semibold">
                    {{personFullName}}
                  </p>
                  <p class="m-b-0 opacity-07">
                    {{tenant?.name || ''}}
                  </p>
                </div>
              </div>
            </li>
            <li nz-menu-item
                routerLink="/profile">
              <a class="profile-menu-item">
                <i class="fa-regular fa-user"></i>&nbsp;
                {{'SHARED.PROFILE' | translate }}</a>
            </li>

            <li nz-menu-item
                *ngIf="!isSocialMediaUser"
                routerLink="/profile/change-password">
              <a class="profile-menu-item">
                <i class="fa-regular fa-key"></i>&nbsp;&nbsp;{{'Change Password' | translate}}
              </a>
            </li>

            <!-- #Accounts

            <li nz-menu-item
                routerLink="/profile/account-list">
              <a class="profile-menu-item">
                <i class="fa-regular fa-file-user"></i>&nbsp;&nbsp;{{'Accounts' | translate}}
              </a>
            </li>
          -->

            <li *ngIf="adminDashboardVisibility"
                nz-menu-item
                routerLink="/admin-dashboard">
              <a class="profile-menu-item">
                <i class="fa-regular fa-screwdriver-wrench"></i>&nbsp;&nbsp;{{'Admin Dashboard' | translate}}
              </a>
            </li>

            <li nz-menu-divider></li>
            <li nz-menu-item
                (click)="logout()">

              <a class="profile-menu-item">
                <i class="fa-thin fa-right-from-bracket"></i>&nbsp;&nbsp;
                {{'AUTHENTICATION_MODULE.LOGOUT' | translate}}</a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>
      <!-- Zendesk Widget Opener -->
      <li>
        <a class="support-menu-item"
           nz-tooltip
           nzTooltipTitle="Support"
           id="customZendeskButton">
          <i class="fa-regular fa-circle-question"></i></a>
      </li>

      <ng-container *ngIf="isUserLoading">
        <nz-skeleton-element nzType="avatar"
                             [nzActive]="true"
                             nzShape="square"
                             nzSize="small"
                             style="width: 36px; "></nz-skeleton-element>
        <nz-skeleton-element nzType="input"
                             [nzActive]="true"
                             nzSize="small"
                             style="width: 120px;"></nz-skeleton-element>
      </ng-container>

      <!-- Toggle AI Assistant Right Drawer -->
      <!-- <li (click)="onRightSidebarTogglerButtonClick(AppRightSidebarTab.Chat)">
        <a>
          <i class="fa-light fa-message-bot"></i>
        </a>
      </li> -->
    </ul>
  </div>
</div>
