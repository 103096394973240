<ng-container *ngIf="contentTree">
    <!-- Main page container -->
    <ng-container *ngIf="systemGeneratedComponentDefinition?.isIterative; else nonIterativeTemplate">
        <ng-container *ngFor="let input of customInputs?.items; let index = index">
            <ng-container *ngTemplateOutlet="
                pageContainerTemplate;
                context: { contents: contentTree, iterativeIndex: index }
              ">
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #nonIterativeTemplate>
        <ng-container *ngTemplateOutlet="
            pageContainerTemplate;
            context: { contents: contentTree }
          ">
        </ng-container>
    </ng-template>
</ng-container>

<!-- TEMPLATES -->

<!-- Page container template -->
<ng-template #pageContainerTemplate
             let-contents="contents"
             let-iterativeIndex="iterativeIndex">
    <ng-container *ngFor="let content of contents; let index = index">
        <ng-container *ngIf="content.type === ContentTreeItemType.Container">
            <div [class]="setPageContainerClasses(content)"
                 [ngStyle]="setPageContainerLayoutSettings(content)"
                 [id]="content.id">
                <ng-container *ngIf="content.children?.length">
                    <ng-container *ngTemplateOutlet="
                            pageContainerTemplate;
                            context: { contents: content.children, iterativeIndex: iterativeIndex }">
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <!-- Components in the container -->
        <!-- COMPONENTS -->
        <ng-container *ngIf="content.type === ContentTreeItemType.Component">
            <ng-container *ngTemplateOutlet="componentsInPageContainerTemplate;
                          context: { content: content, index: index, iterativeIndex: iterativeIndex }">
            </ng-container>
        </ng-container>

    </ng-container>
</ng-template>

<ng-template #componentsInPageContainerTemplate
             let-index="index"
             let-content="content"
             let-iterativeIndex="iterativeIndex">
    <div [class]="'component-container ' + content?.component?.cssClasses?.join(' ')">
        <ng-container *ngIf="content?.component?.componentDefinition?.isSystemGenerated === true">
            <ng-container *ngTemplateOutlet="
                            pageContainerTemplate;
                            context: { contents: [content?.component?.componentDefinition?.contentTreeItem] }">
            </ng-container>
        </ng-container>
        <ng-container *ngIf="content?.component?.componentDefinition?.isSystemGenerated !== true">
            <div #componentContainer
                 [id]="(iterativeIndex || iterativeIndex === 0) ? content?.component.id + '/' + iterativeIndex :content?.component.id"
                 [attr.content_id]="content.id">
            </div>
        </ng-container>
    </div>
</ng-template>
