// key is file extension (.ts, .html, .scss, ...)
// value is language (typescript for .ts, html for .html)

export const supportedLanguages: { [key: string]: string } = {
  'html': 'html',
  'scss': 'scss',
  'ts': 'typescript',
  'css': 'css',
  'md': 'markdown',
  'json': 'json',
  'xml': 'xml',
  'yaml': 'yaml',
  'yml': 'yaml',
  'js': 'javascript',
  'jsx': 'javascript',
  'tsx': 'typescript',
  'py': 'python',
  'sh': 'shell',
  'sql': 'sql',
  'java': 'java',
  'c': 'c',
  'cpp': 'cpp',
};
