import { DropdownMenuComponentConfig, DropdownMenuItem, DropdownMenuLabelMode, DropdownMenuPlacement, DropdownMenuTriggerType, IconType } from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const containerTitleBarDropdownConfig: DropdownMenuComponentConfig = {
  icon: {
    name: 'fa-regular fa-caret-down',
    type: IconType.FontAwesome,
    color: '#343a40',
    size: '15px'
  },
  labelMode: DropdownMenuLabelMode.StaticLabel,
  placement: DropdownMenuPlacement.BottomLeft,
  triggerType: DropdownMenuTriggerType.Click,
  items: [
    {
      data: 'add-container-visibility',
      label: 'Container Visibility',
      icon: {
        name: 'fa-regular fa-eye',
        type: IconType.FontAwesome,
        color: 'var(--text-color)'
      }
    },
    {
      data: 'edit-container-visibility',
      label: 'Container Visibility',
      icon: {
        name: 'fa-regular fa-eye',
        type: IconType.FontAwesome,
        color: 'var(--text-color)'
      }
    },
    {
      data: 'add-comment-to-container',
      label: 'Add comment',
      icon: {
        name: 'fa-kit fa-regular-comment-circle-plus',
        type: IconType.FontAwesome,
        color: 'var(--text-color)'
      }
    },
    {
      data: 'copy-to-clipboard',
      label: 'Copy template to clipboard',
      icon: {
        name: 'copy',
        type: IconType.NgZorro,
        color: 'var(--text-color)'
      }
    },
    {
      data: 'paste-from-clipboard',
      label: 'Paste copied template',
      icon: {
        name: 'far fa-paste',
        type: IconType.FontAwesome,
        color: 'var(--text-color)'
      }
    },
    {
      data: 'delete-container',
      label: 'CONTENT_EDITOR_MODULE.DELETE_PAGE_CONTAINER_COMPONENT.DELETE_CONTAINER',
      icon: {
        name: 'far fa-trash',
        type: IconType.FontAwesome,
        color: 'var(--text-color)'
      }
    },
    {
      data: 'clear-page',
      label: 'Clear Page',
      icon: {
        name: 'fa-regular fa-broom-wide',
        type: IconType.FontAwesome,
        color: 'var(--text-color)'
      },
      popconfirmTitle: 'Proceeding with this action will reset your entire page, and this action is irreversible. Are you certain you want to proceed?',
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ]
};

export function containerTitleBarDropdownItemsVisibilityFunction(item: DropdownMenuItem, contentTreeItem, hasClipboardData: boolean) {
  if (item.data === 'delete-container' && contentTreeItem.isMainContainer) {
    return false;
  } else if (item.data === 'clear-page' && !contentTreeItem.isMainContainer) {
    return false;
  } else if (item.data === 'add-container-visibility' && contentTreeItem.isMainContainer) {
    return false;
  } else if (item.data === 'paste-from-clipboard' && !hasClipboardData) {
    return false;
  } else if (item.data === 'add-container-visibility' && contentTreeItem.visibilityCondition) {
    return false;
  } else if (item.data === 'edit-container-visibility' && !contentTreeItem.visibilityCondition) {
    return false;
  } else {
    return true;
  }
}
