import { createAction, createReducer, on } from '@ngrx/store';
import { PageWithRelations } from '@rappider/rappider-sdk';
import { StateErrorService } from 'libs/shared/src/lib/services/state-error-service/state-error-service';

import * as AdminPagesActions from './admin-page.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'adminPage';

/* state interface */
export interface AdminPageState {
  data: PageWithRelations[];
  isLoading: boolean;
  isLoaded: boolean;
  error: any;
}

/* initial values */
export const initialState: AdminPageState = {
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(AdminPagesActions.GetAdminPagesByFilter, (state, action) => ({
    ...state,
    isLoading: true,
    isLoaded: false
  })),
  on(AdminPagesActions.GetAdminPagesByFilterSuccessful, (state, action) => ({
    ...state,
    data: action.payload.pages,
    isLoading: false,
    isLoaded: true
  })),
  on(AdminPagesActions.GetAdminPagesByFilterFailure, (state, action) => ({
    ...StateErrorService.handleFailure(state, action),
    isLoaded: true
  })),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, (state) => ({
    ...initialState,
    data: state.data,
    isLoading: state.isLoading,
    isLoaded: state.isLoaded
  })),
  on(Logout, () => (initialState))
);
