import { createSelector } from '@ngrx/store';
import { orderBy } from 'lodash';

export const getUIDataStoreWithEvents = createSelector(
  state => state['uiDataStore']?.data,
  state => state['uiDataEvent']?.data,
  (uiDataStores: any[], uiDataEvents: any[]) => uiDataStores.map(uiDataStore => ({
    ...uiDataStore,
    events: orderBy(uiDataEvents.filter(uiDataEvent => uiDataEvent.uiDataStoreId === uiDataStore.id), 'index', 'asc')
  }))
);
