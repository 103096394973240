import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonType } from '../../utils/button';
import { ProgressStatus } from '../../utils/progress';
import { IconType } from '../../utils/icon';
import { ProjectExportProcessStatus } from 'libs/shared/src/lib/definitions/project-export/project-export-process-status.enum';
import { successfulLottieAnimation } from 'libs/shared/src/lib/definitions/lottie-animations/successful-lottie-animation';
import { failureLottieAnimation } from 'libs/shared/src/lib/definitions/lottie-animations/failure-lottie-animation';
import { ProjectExportWithRelations } from 'libs/rappider-sdk/src/lib/models/project-export-with-relations';
import { ProjectWithRelations } from 'libs/rappider-sdk/src/lib/models/project-with-relations';
import { ProjectExportProcesses } from 'libs/shared/src/lib/definitions/project-export/project-export-processes';
import { ProjectExportStatus } from 'libs/shared/src/lib/definitions/project-export/project-export-status.enum';

@Component({
  selector: 'rappider-progress-steps',
  templateUrl: './progress-steps.component.html',
  styleUrls: ['./progress-steps.component.scss']
})
export class RappiderProgressSteps implements OnChanges {

  @Input() progressData: ProjectExportWithRelations | ProjectWithRelations;
  @Input() showInfo = true;
  @Input() strokeWidth = 30;
  @Input() showText = true;
  @Input() isProgressContinue = true;

  @Output() status = new EventEmitter<string>();

  displayedSteps = [];
  currentMessage: string;
  currentStatus: string;
  progressInnerText: string;
  progressStatus: ProgressStatus;
  currentProcessAnimation: {
    options: {
      path: string;
    };
    height: string;
    width: string;
  };

  isExportDetailsCollapsed = false;
  exportDetailsTogglerButton = {
    text: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.HIDE_EXPORT_DETAILS',
    type: ButtonType.Default,
    icon: {
      name: 'up',
      type: IconType.NgZorro
    }
  };

  ProjectExportProcessStatus = ProjectExportProcessStatus;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.progressData && changes.progressData.currentValue?.processes) {
      this.displayedSteps = this.progressData.processes?.map((process, index) => {
        switch (process.status) {

          case ProjectExportProcessStatus.Processing: {
            this.currentMessage = process?.message;
            this.currentStatus = process?.status;
            const animation = (index === (this.progressData.processes.length - 1))
            ? {
              options: {
                path: 'assets/animations/frontend-exporting.json'
              },
              height: '100%',
              width: '100%'
            }
            : successfulLottieAnimation;
            this.currentProcessAnimation = animation;
            return {
              status: process?.status,
              message: process?.message,
              animation
            };
          }

          case ProjectExportProcessStatus.Successful: {
            this.currentMessage = process?.message;
            this.currentStatus = process?.status;
            return {
              status: process?.status,
              message: process?.message,
              animation: successfulLottieAnimation
            };
          }

          case ProjectExportProcessStatus.Failure: {
            this.currentMessage = process?.message;
            this.currentStatus = process?.status;
            return {
              status: process?.status,
              message: process?.message,
              animation: {
                ...failureLottieAnimation,
                height: '80%',
                width: '80%'
              }
            };
          }

        }
      });
      this.checkStatus();
    }
  }

  trackProcess(process) {
    return process.status;
  }

  onProjectExportDetailsTogglerClick() {
    this.isExportDetailsCollapsed = !this.isExportDetailsCollapsed;
    if (this.isExportDetailsCollapsed) {
      this.exportDetailsTogglerButton.text = 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.SHOW_EXPORT_DETAILS';
      this.exportDetailsTogglerButton.icon.name = 'down';
    } else {
      this.exportDetailsTogglerButton.text = 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.HIDE_EXPORT_DETAILS';
      this.exportDetailsTogglerButton.icon.name = 'up';
    }
  }

  handleExportDetailsHeight() {
    return this.isExportDetailsCollapsed ? '0px' : 'unset';
  }

  checkStatus() {
    if (this.progressData) {
      this.progressData.percent = Math.round((this.progressData.completedStepCount / this.progressData.totalStepCount) * 100);
    }
    switch (this.progressData.status) {
      case ProjectExportStatus.Successful:
        this.progressStatus = ProgressStatus.Success;
        this.progressData = {
          ...this.progressData,
          statusCheck: 'success',
        };
        break;
      case ProjectExportStatus.PartiallySuccessful:
        this.progressStatus = ProgressStatus.Success;
        this.progressData = {
          ...this.progressData,
          statusCheck: 'success',
        };
        break;
      case ProjectExportStatus.Exporting:
        this.progressStatus = ProgressStatus.Active;
        this.progressData = {
          ...this.progressData,
          statusCheck: 'processing',
        };
        break;
      case ProjectExportStatus.Failure:
        this.progressStatus = ProgressStatus.Exception;
        this.progressData = {
          ...this.progressData,
          statusCheck: this.progressData?.percent < 100 ? 'error' : 'success',
        };
      case ProjectExportProcessStatus.Processing:
        this.progressStatus = ProgressStatus.Active;
        this.progressData = {
          ...this.progressData,
          statusCheck: 'processing',
        };
        break;
      default:
        break;
    }
    if (this.progressData.status !== ProjectExportProcessStatus.Processing || this.progressData?.percent === 100) {
      this.showText = true;
      this.progressInnerText = this.progressData?.status === 'successful'
        ? 'Successfully Completed' : 'Partially Completed';
    } else {
      this.showText = false;
      this.progressInnerText = 'Processing';
    }
    this.status.emit(this.progressData?.statusCheck);
  }

}
