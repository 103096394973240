import { Action } from '@ngrx/store';
import { UiDataUpdateFunctionWithRelations, UiDataUpdateFunction, NewUiDataUpdateFunction, UiDataUpdateFunctionPartial } from '@rappider/rappider-sdk';
import { ErrorActionPayload } from '@rappider/shared/interfaces';

export enum ActionTypes {
  /* Ui Data Update Functions Actions */
  GetUIDataUpdateFunctions = '[UIDataUpdateFunction] GetUIDataUpdateFunctions',
  GetUIDataUpdateFunctionsSuccessful = '[UIDataUpdateFunction] GetUIDataUpdateFunctionsSuccessful',
  GetUIDataUpdateFunctionsFailure = '[UIDataUpdateFunction] GetUIDataUpdateFunctionsFailure',
  GetUIDataUpdateFunctionsAccordingToUIDataStoreId = '[UIDataUpdateFunction] GetUIDataUpdateFunctionsAccordingToUIDataStoreId',
  GetUIDataUpdateFunctionsAccordingToUIDataStoreIdSuccessful = '[UIDataUpdateFunction] GetUIDataUpdateFunctionsAccordingToUIDataStoreIdSuccessful',
  GetUIDataUpdateFunctionsAccordingToUIDataStoreIdFailure = '[UIDataUpdateFunction] GetUIDataUpdateFunctionsAccordingToUIDataStoreIdFailure',
  CreateUIDataUpdateFunction = '[UIDataUpdateFunction] CreateUIDataUpdateFunction',
  CreateUIDataUpdateFunctionSuccessful = '[UIDataUpdateFunction] CreateUIDataUpdateFunctionSuccessful',
  CreateUIDataUpdateFunctionFailure = '[UIDataUpdateFunction] CreateUIDataUpdateFunctionFailure',
  UpdateUIDataUpdateFunction = '[UIDataUpdateFunction] UpdateUIDataUpdateFunction',
  UpdateUIDataUpdateFunctionSuccessful = '[UIDataUpdateFunction] UpdateUIDataUpdateFunctionSuccessful',
  UpdateUIDataUpdateFunctionFailure = '[UIDataUpdateFunction] UpdateUIDataUpdateFunctionFailure',
  DeleteUIDataUpdateFunction = '[UIDataUpdateFunction] DeleteUIDataUpdateFunction',
  DeleteUIDataUpdateFunctionSuccessful = '[UIDataUpdateFunction] DeleteUIDataUpdateFunctionSuccessful',
  DeleteUIDataUpdateFunctionFailure = '[UIDataUpdateFunction] DeleteUIDataUpdateFunctionFailure',
  BulkCreateUIDataUpdateFunction = '[UIDataUpdateFunction] BulkCreateUIDataUpdateFunction',
  BulkCreateUIDataUpdateFunctionSuccessful = '[UIDataUpdateFunction] BulkCreateUIDataUpdateFunctionSuccessful',
  BulkCreateUIDataUpdateFunctionFailure = '[UIDataUpdateFunction] BulkCreateUIDataUpdateFunctionFailure',
}


export class GetUIDataUpdateFunctions implements Action {
  readonly type = ActionTypes.GetUIDataUpdateFunctions;
}


export class GetUIDataUpdateFunctionsSuccessful implements Action {
  readonly type = ActionTypes.GetUIDataUpdateFunctionsSuccessful;
  constructor(public payload: { uiDataUpdateFunctions: UiDataUpdateFunctionWithRelations[] }) { }
}

export class GetUIDataUpdateFunctionsFailure implements Action {
  readonly type = ActionTypes.GetUIDataUpdateFunctionsFailure;
  constructor(public payload: ErrorActionPayload) { }
}

export class GetUIDataUpdateFunctionsAccordingToUIDataStoreId implements Action {
  readonly type = ActionTypes.GetUIDataUpdateFunctionsAccordingToUIDataStoreId;
  constructor(public payload: { uiDataStoreId: string }) { }
}

export class GetUIDataUpdateFunctionsAccordingToUIDataStoreIdSuccessful implements Action {
  readonly type = ActionTypes.GetUIDataUpdateFunctionsAccordingToUIDataStoreIdSuccessful;
  constructor(public payload: { uiDataUpdateFunctions: UiDataUpdateFunctionWithRelations[] }) { }
}

export class GetUIDataUpdateFunctionsAccordingToUIDataStoreIdFailure implements Action {
  readonly type = ActionTypes.GetUIDataUpdateFunctionsAccordingToUIDataStoreIdFailure;
  constructor(public payload: ErrorActionPayload) { }
}

export class CreateUIDataUpdateFunction implements Action {
  readonly type = ActionTypes.CreateUIDataUpdateFunction;
  constructor(public payload: {
    uiDataUpdateFunction: NewUiDataUpdateFunction;
    activeUIDataStoreId: string;
  }) { }
}

export class CreateUIDataUpdateFunctionSuccessful implements Action {
  readonly type = ActionTypes.CreateUIDataUpdateFunctionSuccessful;
  constructor(public payload: { uiDataUpdateFunction: UiDataUpdateFunction }) { }
}

export class CreateUIDataUpdateFunctionFailure implements Action {
  readonly type = ActionTypes.CreateUIDataUpdateFunctionFailure;
  constructor(public payload: ErrorActionPayload) { }
}

export class UpdateUIDataUpdateFunction implements Action {
  readonly type = ActionTypes.UpdateUIDataUpdateFunction;
  constructor(public payload: {
    id: string; uiDataUpdateFunction: UiDataUpdateFunctionPartial;
  }) { }
}

export class UpdateUIDataUpdateFunctionSuccessful implements Action {
  readonly type = ActionTypes.UpdateUIDataUpdateFunctionSuccessful;
  constructor(public payload: { id: string; uiDataUpdateFunction: UiDataUpdateFunctionPartial }) { }
}

export class UpdateUIDataUpdateFunctionFailure implements Action {
  readonly type = ActionTypes.UpdateUIDataUpdateFunctionFailure;
  constructor(public payload: ErrorActionPayload) { }
}

export class DeleteUIDataUpdateFunction implements Action {
  readonly type = ActionTypes.DeleteUIDataUpdateFunction;
  constructor(public payload: { dataEventName: string; id: string }) { }
}

export class DeleteUIDataUpdateFunctionSuccessful implements Action {
  readonly type = ActionTypes.DeleteUIDataUpdateFunctionSuccessful;
  constructor(public payload: { id: string }) { }
}

export class DeleteUIDataUpdateFunctionFailure implements Action {
  readonly type = ActionTypes.DeleteUIDataUpdateFunctionFailure;
  constructor(public payload: ErrorActionPayload) { }
}

export class BulkCreateUIDataUpdateFunction implements Action {
  readonly type = ActionTypes.BulkCreateUIDataUpdateFunction;
  constructor(public payload: { uiDataUpdateFunctions: Array<NewUiDataUpdateFunction> }) { }
}

export class BulkCreateUIDataUpdateFunctionSuccessful implements Action {
  readonly type = ActionTypes.BulkCreateUIDataUpdateFunctionSuccessful;
  constructor(public payload: {
    uiDataUpdateFunctions: UiDataUpdateFunction[];
  }) { }
}

export class BulkCreateUIDataUpdateFunctionFailure implements Action {
  readonly type = ActionTypes.BulkCreateUIDataUpdateFunctionFailure;
  constructor(public payload: ErrorActionPayload) { }
}

export type Actions =
  GetUIDataUpdateFunctions
  | GetUIDataUpdateFunctionsSuccessful
  | GetUIDataUpdateFunctionsFailure
  | GetUIDataUpdateFunctionsAccordingToUIDataStoreId
  | GetUIDataUpdateFunctionsAccordingToUIDataStoreIdSuccessful
  | GetUIDataUpdateFunctionsAccordingToUIDataStoreIdFailure
  | CreateUIDataUpdateFunction
  | CreateUIDataUpdateFunctionSuccessful
  | CreateUIDataUpdateFunctionFailure
  | UpdateUIDataUpdateFunction
  | UpdateUIDataUpdateFunctionSuccessful
  | UpdateUIDataUpdateFunctionFailure
  | DeleteUIDataUpdateFunction
  | DeleteUIDataUpdateFunctionSuccessful
  | DeleteUIDataUpdateFunctionFailure
  | BulkCreateUIDataUpdateFunction
  | BulkCreateUIDataUpdateFunctionSuccessful
  | BulkCreateUIDataUpdateFunctionFailure;

