<rappider-modal [(visible)]="modalVisibility"
                [title]="modalTitleTemplate"
                [fullScreen]="true"
                [footer]="footerTmpl"
                className="data-subscription-modal"
                (visibleChange)="onModalVisibilityChange()">
  <div class="main-wrapper">
    <div class="left-side ds-section-border-right">
      <div class="data-source-section ds-section-border-right">
        <div class="data-source-selector-wrapper ds-section-border-bottom p-3">
          <div class="data-source-list-header">
            <h6>{{'Select Data Source' | translate}}</h6>
          </div>
          <div class="data-source-selector"
               joyrideStep="firstStep"
               [title]="'SubscriptionFirstStepTitle' | translate"
               [text]="'SubscriptionFirstStepContent' | translate">
            <rappider-ui-data-store-field-tree-selector [(ngModel)]="dataSources"
                                                        (ngModelChange)="onDataSourceSelectionChange()"></rappider-ui-data-store-field-tree-selector>
          </div>
        </div>
        <div class="data-source-list p-3">
          <div *ngIf="dataSources?.length"
               class="data-source-list-header">
            <h6>{{'Data Sources' | translate}}</h6>
          </div>
          <div class="data-source-wrapper">
            <section *ngFor="let dataSource of dataSources; let index = index"
                     class="data-source-item"
                     (click)="onDataSourceItemClicked(dataSource)">
              {{dataSource.variableName}}
              <rappider-button [type]="removeDataSourceButtonConfig.type"
                               [icon]="removeDataSourceButtonConfig.icon"
                               [size]="removeDataSourceButtonConfig.size"
                               (confirm)="onDataSourceRemoved(dataSource)"></rappider-button>
            </section>
          </div>
        </div>
      </div>
      <div class="source-target-schemas">
        <div *ngIf="monacoEditorVisibility"
             class="source-schema-sample ds-section-border-bottom p-3"
             joyrideStep="secondStep"
             [title]="'SubscriptionSecondStepTitle' | translate"
             [text]="'SubscriptionSecondStepContent' | translate">
          <h6 class="p-3">{{'Source Schema Sample' | translate}} </h6>
          <div class="source-schema-sample-editor">
            <rappider-monaco-code-editor [ngModel]="exampleJson"
                                         [options]="exampleJsonOptions"
                                         [autoFormat]="true"
                                         [borderSettings]="{border: 'none'}"
                                         [showSelectedKeyPath]="true"
                                         [loading]="exampleJsonLoading"></rappider-monaco-code-editor>
          </div>
        </div>
        <div *ngIf="monacoEditorVisibility"
             class="target-schema-tree-wrapper p-3"
             joyrideStep="thirdStep"
             [title]="'SubscriptionThirdStepTitle' | translate"
             [text]="'SubscriptionThirdStepContent' | translate">
          <div class="section-title-bar">
            <h6 class="p-3">{{displayTargetSchemaSample ? 'Target Schema Sample' :'Target Schema Tree'
              | translate}}
            </h6>
            <rappider-button size="small"
                             [text]="displayTargetSchemaSample ? 'Display Tree': 'Display Sample'"
                             (click)="onClickTargetSchemaSwitcher()"></rappider-button>
          </div>
          <ng-container *ngIf="displayTargetSchemaSample; else targetSchemaTreeTemplate">
            <div class="target-schema-sample-editor">
              <rappider-monaco-code-editor [ngModel]="targetSchemaSample"
                                           [options]="exampleJsonOptions"
                                           [borderSettings]="{border: 'none'}"
                                           [autoFormat]="true"
                                           [showSelectedKeyPath]="true"
                                           [loading]="targetSchemaSampleLoading"></rappider-monaco-code-editor>
            </div>
          </ng-container>
          <ng-template #targetSchemaTreeTemplate>
            <rappider-spin [spinning]="targetTreeLoading">
              <nz-tree nzBlockNode
                       [nzData]="targetTree"
                       [nzTreeTemplate]="nzTreeTemplate"></nz-tree>
              <ng-template #nzTreeTemplate
                           let-node
                           let-origin="origin">
                {{node.title}}
                <span *ngIf="origin?.data?.type !== 'object' && origin?.data?.type !== 'array' && origin?.data?.type"
                      class="target-tree-node-type">
                  : {{origin?.data?.type}}
                </span>
                <span *ngIf="origin?.data?.type === 'array'"
                      class="target-tree-node-type">
                  []
                </span>
              </ng-template>
            </rappider-spin>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="right-side">
      <div *ngIf="monacoEditorVisibility"
           class="jsonata-config-section-wrapper ds-section-border-bottom p-3"
           joyrideStep="fourthStep"
           [title]="'SubscriptionFourthStepTitle' | translate"
           [text]="'SubscriptionFourthStepContent' | translate">
        <div class="section-title-bar">
          <h6 class="p-3">Jsonata Config</h6>
          <div class="jsonata-config-buttons">
            <rappider-button size="small"
                             text="AI Content Config"
                             (click)="onClickAIContentConfig()"></rappider-button>
            <rappider-button size="small"
                             text="Generate Subscription"
                             [loading]="generateDataSubscriptionLoading"
                             [tooltipText]="!isAIContentConfigExist ? 'Please add AI config to generate subscription': ''"
                             [disabled]="!isAIContentConfigExist"
                             (click)="generateDataSubscriptionWithAI()"></rappider-button>
            <rappider-button size="small"
                             text="Import Sample"
                             (click)="onClickImportSample()"></rappider-button>
          </div>
        </div>
        <div class="jsonata-monaco-wrapper">
          <rappider-jsonata-editor [(ngModel)]="jsonataConfig"
                                   (ngModelChange)="setPreviewOfJsonataConfig()"></rappider-jsonata-editor>
        </div>
      </div>
      <div *ngIf="monacoEditorVisibility"
           class="output-section p-3"
           joyrideStep="fifthStep"
           [title]="'SubscriptionFifthStepTitle' | translate"
           [text]="'SubscriptionFifthStepContent' | translate">
        <div class="output-section-header">
          <i *ngIf="jsonataPreviewError"
             class="fa-light fa-circle-exclamation"></i>
          <h6>Output</h6>
        </div>
        <div class="inner-output">
          <rappider-monaco-code-editor *ngIf="!jsonataPreviewLoading"
                                       [ngModel]="jsonataPreview"
                                       [autoFormat]="true"
                                       [options]="exampleJsonOptions"
                                       [borderSettings]="{border: 'none'}"></rappider-monaco-code-editor>
        </div>
      </div>
    </div>
  </div>
</rappider-modal>

<ng-template #footerTmpl>
  <div class="subscription-modal-footer-wrapper">
    <div *ngIf="noDataSourceError"
         class="subscription-error">
      {{'Please choose at least one data source to add subscription' | translate}}
    </div>
    <div class="modal-footer-buttons">
      <rappider-button text="Cancel"
                       type="default"
                       [loading]="modalLoading"
                       (confirm)="onModalClosed()"></rappider-button>
      <rappider-button text="Save"
                       type="primary"
                       [loading]="modalLoading"
                       (confirm)="onDataSubscriptionSaved()"></rappider-button>
    </div>
  </div>
</ng-template>

<ng-template #modalTitleTemplate>
  <div class="modal-title">
    <span> {{ modalTitle }} </span>
    <span class="info-icon"
          nz-icon
          title="Show Help"
          nzType="info-circle"
          (click)="showSteps()"></span>
  </div>
</ng-template>