import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AvatarComponentConfig } from '../../utils/avatar/avatar-component-config.interface';
import { ButtonComponentConfig } from '../../utils/button/button-component-config.interface';
import { AvatarPlacement } from '../../utils/card-one/card-one-avatar-placement.enum';
import { CardOneButtonOutput } from '../../utils/card-one/card-one-button-output.interface';
import { CardOneImagePosition } from '../../utils/card-one/card-one-image-position.enum';
import { DividerComponentConfig } from '../../utils/divider';
import { HeadingComponentConfig } from '../../utils/heading/heading-component-config.interface';
import { IconComponentConfig, IconType } from '../../utils/icon';
import { ImageComponentConfig } from '../../utils/image/image-component-config.interface';
import { ParagraphComponentConfig } from '../../utils/paragraph/paragraph-component-config.interface';
import { TagComponentConfig } from '../../utils/tag';
import { ActionBehavior } from '../../utils/action-utils';
import { CardDisplayMode } from '../../utils/card-one/card-one-displayMode.enum';

@Component({
  selector: 'rappider-card-one',
  templateUrl: './card-one.component.html',
  styleUrls: ['./card-one.component.scss']
})
export class RappiderCardOneComponent implements OnInit, OnChanges {

  /* data to emit */
  @Input() data: any;
  @Input() imagePosition: CardOneImagePosition;
  @Input() image: ImageComponentConfig;
  @Input() imageTags: TagComponentConfig[];
  @Input() imageButtons: ButtonComponentConfig[];
  @Input() imageButtonPlacement: 'horizontal' | 'vertical';
  @Input() rate: number;
  @Input() titles: HeadingComponentConfig[];
  @Input() currency: string;
  @Input() finalPrice: number;
  @Input() price: number;
  @Input() additionalTags: TagComponentConfig[];
  @Input() additionalButtons: ButtonComponentConfig[];
  @Input() descriptions: ParagraphComponentConfig[];
  @Input() avatar: AvatarComponentConfig;
  @Input() avatarButton: ButtonComponentConfig;
  @Input() avatarPlacement: AvatarPlacement;
  @Input() isSelected: boolean;
  @Input() selectedCardIcon: IconComponentConfig;
  @Input() divider: DividerComponentConfig;
  @Input() showTitleOnImage: boolean;
  @Input() showDescriptionOnImage: boolean;
  @Input() showPopover: boolean;
  @Input() showPopoverImage: ImageComponentConfig;
  @Input() displayMode: CardDisplayMode;

  @Output() cardClick = new EventEmitter();
  @Output() imageButtonClick = new EventEmitter<CardOneButtonOutput>();
  @Output() additionalButtonClick = new EventEmitter<CardOneButtonOutput>();

  AvatarPlacement = AvatarPlacement;

  ngOnInit(): void {
    this.initDefaults();
  }

  ngOnChanges(): void {
    this.initDefaults();
  }

  initDefaults() {
    if (!this.selectedCardIcon) {
      this.selectedCardIcon = {
        name: 'fa-solid fa-circle-check',
        type: IconType.FontAwesome,
        color: 'var(--success-color)'
      };
    }
    if (this.avatarPlacement == null) {
      this.avatarPlacement = AvatarPlacement.Top;
    }
    if (this.isSelected == null) {
      this.isSelected = false;
    }
  }

  onCardClick() {
    this.cardClick.emit({ data: this.data });
  }

  onImageButtonClick(button: ButtonComponentConfig, event) {
    event.stopPropagation();
    this.imageButtonClick.emit({ button: button, data: this.data });
  }

  onAdditionalButtonClick(button: ButtonComponentConfig, event) {
    event.stopPropagation();
    if (button.behavior === ActionBehavior.ExternalRoute) {
      window.open(button.redirectUrl, button.redirectMode);
    } else {
      this.additionalButtonClick.emit({ button: button, data: this.data });
    }
  }

  setImagePositionClass(): string {
    switch (this.imagePosition) {
      case CardOneImagePosition.Top:
        return 'image-position-top';
      case CardOneImagePosition.Right:
        return 'image-position-right';
      case CardOneImagePosition.Left:
        return 'image-position-left';
      case CardOneImagePosition.Bottom:
        return 'image-position-bottom';
      default:
        return '';
    }
  }

  setCardDisplayModeClass(): string {
    switch (this.displayMode) {
      case CardDisplayMode.Flex:
        return 'card-description-wrapper-flex';
      case CardDisplayMode.Block:
        return 'card-description-wrapper-block';
      default:
        return '';
    }
  }
}
