import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ActionBehavior,
  ButtonComponentConfig,
  ButtonType,
  DropdownMenuItem,
  HeadingType,
} from '@rappider/rappider-components/utils';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProjectSourceCode } from '../../project-source-code-data-source/project-source-code.interfaces';
import { MonacoEditorRegion } from 'libs/components/src/lib/components/monaco-editor/monaco-editor.types';
import { DeleteProjectSourceFileCustomCodes, DownloadProjectSourceFile, GetProjectSourceFiles, UpsertProjectSourceFileCustomCodes } from '../../project-source-code-data-source/project-source-code.actions';
import { CustomCode, NewCustomCode } from '@rappider/rappider-sdk';
import { getSourceCodeLoading } from './utils/source-code-loading.selector';
import { FlatNode } from '../project-source-files/project-source-files.component';
import { supportedLanguages } from './utils/supported-languages';
import { TITLEBAR_ACTION_BUTTON_CONFIG } from './utils/titlebar-action-button.config';
import { SourceCodeButtonItemAction } from './utils/source-code-button-item-action.enum';
import { NotificationService } from '@rappider/services';
import { CodeEditorComponent } from 'libs/components/src/lib/components/monaco-editor/code-editor.component';

@Component({
  selector: 'rappider-source-code-editor',
  templateUrl: './source-code-editor.component.html',
  styleUrls: ['./source-code-editor.component.scss']
})
export class SourceCodeEditorComponent implements OnInit, OnDestroy {
  @ViewChild('editorRef', { static: true }) editorComponent!: CodeEditorComponent;

  @Input() language? = 'typescript'; /* TODO: FIXME: get language value by using enum */
  @Input() initialCode?: string;

  subscriptions: Subscription[] = [];

  activeProjectFileSourceCode?: ProjectSourceCode | null = null;

  titleBarBreadcrumbOptions: string[] = [];
  titleBarDefaultBreadcrumbOptions = ['Source Code'];

  editorOptions = {
    theme: 'vs-dark',
    language: this.language,
    automaticLayout: true,
    fontSize: '13px',
    readOnly: false,  // Set to false initially to allow modifications later
    hasCustomCode: true,
    minimap: {
      enabled: false
    }
  };
  code = '// Select a source file to view its content.';

  codeSplitterLeftContainerSize = 20;
  codeSplitterRightContainerSize = 80;
  isCodeEditorLeftContainerVisible = true;


  /**
   * stores custom-codes belong to the displaying file
   *
   * @type {CustomCode[]}
   * @memberof SourceCodeEditorComponent
   */
  remoteProjectFileCustomCodes: CustomCode[] = [];

  displayEditor = false;

  HeadingType = HeadingType;

  codeViewSwitchSettings = null;

  titleBarActionButtonConfig: ButtonComponentConfig[] = TITLEBAR_ACTION_BUTTON_CONFIG;

  titleBarActionButtonSaveCustomCodeConfig: ButtonComponentConfig[] = [
    {
      key: 'save-custom-code',
      text: 'Save Custom Code Changes',
      type: ButtonType.Default,
      icon: { name: 'fa-regular fa-save' },
      behavior: ActionBehavior.Emit
    }
  ];

  panels = [
    {
      active: true,
      disabled: false,
      name: 'EXPLORER',
      childPanel: []
    }
  ];

  customCodeRegions: MonacoEditorRegion[] = [];

  leftSideContainerWidth = 300;

  isLoading = false;

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    if (this.initialCode) {
      this.code = this.initialCode;
    }
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProjectFileSourceCode(),
      this.subscribeToLoadingStatus(),
      this.subscribeActiveProjectFileCustomCodes(),
      this.subscribeToSourceCodeLoading(),
      this.subscribeToDownloadCodeLoading()
    ];
  }

  subscribeToActiveProjectFileSourceCode() {
    return this.store.select(state => state.projectSourceCode?.activeProjectFileSourceCode)
      .subscribe((activeProjectFileSourceCode: ProjectSourceCode) => {
        if (activeProjectFileSourceCode?.code != null) {
          this.activeProjectFileSourceCode = activeProjectFileSourceCode;
          this.code = activeProjectFileSourceCode.code;
        }
        this.titleBarBreadcrumbOptions = [
          ...this.titleBarDefaultBreadcrumbOptions,
          ...(this.activeProjectFileSourceCode?.fullPath?.split('/') || [])
        ];
      });
  }

  subscribeToLoadingStatus() {
    return this.store.select(state => state.projectSourceCode?.isLoading)
      .subscribe(isLoading => this.isLoading = isLoading);
  }

  subscribeToSourceCodeLoading() {
    return this.store.select(<any>getSourceCodeLoading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
      this.titleBarActionButtonConfig = this.titleBarActionButtonConfig.map(config => ({
        ...config,
        loading: this.isLoading
      }));
      // to hide save custom code changes button after success
      if (!isLoading) {
        this.titleBarActionButtonConfig = [...TITLEBAR_ACTION_BUTTON_CONFIG];
      }
    });
  }

  subscribeToDownloadCodeLoading() {
    return this.store.select(state => state.projectSourceCode?.isProjectDownloadLoading).subscribe((isProjectDownloadLoading: boolean) => {
      const downloadButton = this.titleBarActionButtonConfig.find(button => button.key === SourceCodeButtonItemAction.DownloadSourceFile);
      if (downloadButton) {
        downloadButton.loading = isProjectDownloadLoading;
      }
    });
  }

  subscribeActiveProjectFileCustomCodes() {
    return this.store.select(state => state.projectSourceCode?.activeProjectFileCustomCodes)
      .subscribe(activeProjectFileCustomCodes => this.remoteProjectFileCustomCodes = activeProjectFileCustomCodes);
  }

  onLeftSideResized(event: any) {
    this.leftSideContainerWidth = event.width;
    // refresh monaco editor
    this.code = (' ' + this.code).slice(1);
  }

  onCustomCodeRegionsEmitted(regions: MonacoEditorRegion[]) {
    this.customCodeRegions = regions;
    if (regions.length > 0) {
      this.titleBarActionButtonConfig = [...this.titleBarActionButtonSaveCustomCodeConfig];
    } else {
      this.titleBarActionButtonConfig = [...TITLEBAR_ACTION_BUTTON_CONFIG];
    }
  }

  onTitleBarActionButtonClick(button: ButtonComponentConfig) {
    switch (button.key) {

      case SourceCodeButtonItemAction.SaveCustomCode:
        this.saveCustomCodeChanges();
        break;

      case SourceCodeButtonItemAction.DownloadFile:
        this.performDownloadFileAction();
        break;

      case SourceCodeButtonItemAction.DownloadSourceFile:
        this.performDownloadProjectSourceFileAction();
        break;

      case SourceCodeButtonItemAction.Find:
        this.performFindAction();
        break;

      /* case SourceCodeButtonItemAction.Reload:
        this.performReloadAction();
        break;
      */

      default:
        break;
    }
  }


  saveCustomCodeChanges() {
    // this.titleBarActionButtonSaveCustomCodeConfig[0].loading = true;
    // setTimeout(() => {
    //   this.titleBarActionButtonConfig = [];
    // }, 2000);

    const sourceCodePaths = this.activeProjectFileSourceCode?.fullPath?.split('/');

    if (this.customCodeRegions?.length) {

      /* map all of the custom codes in the file (does not matter if changed or not) */
      const localCustomCodes = this.customCodeRegions
        ?.map(customCodeRegion => ({
          /* customCodeRegion?.key => e.g.: project-model__constructor__start */
          featureKey: customCodeRegion?.key ? customCodeRegion.key.split('__')[0] : '', // e.g.: project-model
          section: customCodeRegion?.key ? customCodeRegion.key.split('__')[1] : '', // e.g.: constructor
          position: customCodeRegion?.key ? customCodeRegion.key.split('__')[2] : '', // e.g.: start
          framework: sourceCodePaths?.length ? sourceCodePaths[0] : '', // e.g.: angular
          filePath: <string>this.activeProjectFileSourceCode?.fullPath,
          code: customCodeRegion.content.trim()
        })) ?? [];

      /* created/changed custom codes */
      const upsertingCustomCodes: (NewCustomCode | CustomCode)[] = [];
      /* deleted custom code ids */
      const deletingCustomCodeIds: string[] = [];

      /* calculate created/changed/deleted custom codes */
      localCustomCodes.forEach(localCustomCode => {
        const remoteCustomCode = this.remoteProjectFileCustomCodes.find(remoteProjectFileCustomCode =>
          remoteProjectFileCustomCode.featureKey === localCustomCode.featureKey &&
          remoteProjectFileCustomCode.section === localCustomCode.section &&
          remoteProjectFileCustomCode.position === localCustomCode.position &&
          remoteProjectFileCustomCode.framework === localCustomCode.framework
        );

        if (remoteCustomCode) {
          /* remote custom code exists but deleted in local */
          if (!localCustomCode.code && remoteCustomCode.code) {
            deletingCustomCodeIds.push(remoteCustomCode.id);
            /* remote custom code exists but the code is changed in local */
          } else if (localCustomCode.code !== remoteCustomCode.code) {
            upsertingCustomCodes.push(localCustomCode);
          }
        } else {
          /* remote custom code does not exist but coded in local */
          if (localCustomCode.code) {
            upsertingCustomCodes.push(localCustomCode);
          }
        }
      });

      /* upsert created/changed custom-codes */
      this.store.dispatch(UpsertProjectSourceFileCustomCodes({ payload: { customCodes: upsertingCustomCodes } }));
      /* delete custom codes by ids */
      this.store.dispatch(DeleteProjectSourceFileCustomCodes({ payload: { customCodeIds: deletingCustomCodeIds } }));
    }
  }

  onPatchChanged(event: FlatNode) {
    const fileName = event.name;
    const fileExtension = fileName?.split('.').pop();

    if (fileExtension && supportedLanguages[fileExtension]) {
      this.editorOptions = {
        ...this.editorOptions,
        language: supportedLanguages[fileExtension]
      };
    } else {
      this.editorOptions = {
        ...this.editorOptions,
        language: 'typescript'
      };
    }
  }

  performFindAction() {
    const editorInstance = this.editorComponent._editorComponent._editor;
    editorInstance.focus();
    editorInstance.trigger('keyboard', 'actions.find', null);
  }

  /* The reload process can be executed without generating code. This also creates issues; the reload action should be paused for now. */
  /*
  performReloadAction() {
    try {
      this.isLoading = true;
      this.editorOptions = {
        ...this.editorOptions,
        readOnly: true
      };

      const reloadButton = this.titleBarActionButtonConfig.find(
        button => button.key === SourceCodeButtonItemAction.Reload
      );
      if (reloadButton) {
        reloadButton.loading = true;
      }

      this.store.dispatch(GetProjectSourceFiles());

      const subscription = this.store.select(state => state.projectSourceCode?.isLoading)
        .subscribe(isLoading => {
          if (!isLoading) {
            this.isLoading = false;
            this.editorOptions = {
              ...this.editorOptions,
              readOnly: false
            };

            if (reloadButton) {
              reloadButton.loading = false;
            }

            this.notificationService.createNotification(
              'success',
              'Success',
              'Source code reloaded successfully.'
            );

            subscription.unsubscribe();
          }
        });

    } catch (error) {
      this.handleReloadError();
    }
  }


  handleReloadError() {
    this.isLoading = false;
    this.editorOptions = {
      ...this.editorOptions,
      readOnly: false
    };

    const reloadButton = this.titleBarActionButtonConfig.find(
      button => button.key === SourceCodeButtonItemAction.Reload
    );
    if (reloadButton) {
      reloadButton.loading = false;
    }

    this.notificationService.createNotification(
      'error',
      'Error',
      'Failed to reload source code.'
    );
  }
 */

  performDownloadFileAction() {
    const rawFileContent = this.activeProjectFileSourceCode?.code || '';
    const fileName = this.activeProjectFileSourceCode?.fullPath || 'rawFile.txt';
    const blob = new Blob([rawFileContent], { type: 'application/typescript;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const fileDownloadLink = document.createElement('a');
    fileDownloadLink.href = url;
    fileDownloadLink.download = fileName;
    fileDownloadLink.click();
    window.URL.revokeObjectURL(url);
  }

  performDownloadProjectSourceFileAction() {
    this.store.dispatch(DownloadProjectSourceFile());
  }

  splitDragEnds(event: any) {
    console.log(event);
  }

}
