import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationService } from '@rappider/services';
import { PageType } from '@rappider/shared/interfaces';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { NewPage, Page, PageControllerService, PageWithRelations } from '@rappider/rappider-sdk';

import * as LayoutActions from './layout.actions';
import * as MetadataActions from 'libs/shared/src/lib/states/metadata/metadata.actions';

@Injectable()
export class LayoutEffects {

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private pageApi: PageControllerService,
    private notificationService: NotificationService
  ) { }


  createLayout$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.CreateLayout>(
      LayoutActions.ActionTypes.CreateLayout
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson),
      this.store.select(state => state.activeProject.data)
    ),
    mergeMap(([action, person]) => {
      const body: NewPage = {
        ...action.payload.layout,
        type: PageType.Layout,
        metadata: action.payload.layout.metadata || []
      };
      return this.pageApi.create({ body }).pipe(
        mergeMap(layout => {
          const result: any[] = [new LayoutActions.CreateLayoutSuccessful({ layout: layout })];
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_LAYOUT_CREATED'
          );
          result.push(new Navigate({ url: PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_LIST_PATH }));
          return result;
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_LAYOUT_COULDNOT_CREATED'
          );
          return [
            new LayoutActions.CreateLayoutFailure({ error: error, key: 'CreateLayoutFailure', timestamp: Date.now() })
          ];
        })
      );
    })
  ));


  getLayouts$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.GetLayouts>(
      LayoutActions.ActionTypes.GetLayouts
    ),
    mergeMap(() => {
      const filter = {
        where: {
          type: PageType.Layout
        },
        include: [
          {
            relation: 'components',
          }
        ],
        order: ['createdDate DESC'],
      };
      return this.pageApi.find({ filter }).pipe(
        map((layouts: PageWithRelations[]) => new LayoutActions.GetLayoutsSuccessful({ layouts: layouts }),
        ), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_LAYOUT_COULDNOT_LOADED'
          );
          return [
            new LayoutActions.GetLayoutsFailure({ error: error, key: 'GetLayoutsFailure', timestamp: Date.now() })
          ];
        })
      );
    })
  ));

  getLayoutById$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.GetLayoutById>(
      LayoutActions.ActionTypes.GetLayoutById
    ),
    mergeMap((action) => {
      const params = {
        id: action.payload.layoutId,
        filter: {
          include: [
            {
              relation: 'components'
            }
          ]
        }
      };
      return this.pageApi.findById(params).pipe(
        map((layouts: Page) => new LayoutActions.GetLayoutByIdSuccessful({ layout: layouts }),
        ), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_LAYOUT_COULDNOT_LOADED'
          );
          return [
            new LayoutActions.GetLayoutsFailure({ error: error, key: 'GetLayoutByIdFailure', timestamp: Date.now() })
          ];
        })
      );
    })
  ));

  updateLayout$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.UpdateLayout>(
      LayoutActions.ActionTypes.UpdateLayout
    ),
    mergeMap((action) => {
      const params = {
        id: action.payload.layoutId,
        body: action.payload.layout
      };
      return this.pageApi.updateById(params).pipe(
        concatMap((layout: Page) => {
          const result = [];
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_LAYOUT_UPDATED'
          );
          result.push(new Navigate({ url: PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_LIST_PATH }));
          result.push(new LayoutActions.UpdateLayoutSuccessful({ layout }));
          return result;
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_LAYOUT_COULDNOT_UPDATED'
          );
          return [
            new LayoutActions.UpdateLayoutFailure({ error: error, key: 'UpdateLayoutFailure', timestamp: Date.now() })
          ];
        })
      );
    })
  ));


  deleteLayout$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.DeleteLayout>
      (LayoutActions.ActionTypes.DeleteLayout),
    mergeMap((action) => {
      const layoutId = { id: action.payload.layoutId };

      return this.pageApi.deleteById(layoutId).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_LAYOUT_DELETED'
          );
          return new LayoutActions.DeleteLayoutSuccessful({ layoutId: action.payload.layoutId });
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PAGES_MODULE.NOTIFICATIONS.PAGE_LAYOUT_COULDNOT_DELETED'
          );
          return [
            new LayoutActions.DeleteLayoutFailure({ error: error, key: 'DeleteLayoutFailure', timestamp: Date.now() })
          ];
        })
      );
    })
  ));


  changeDefaultLayout$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.ChangeDefaultLayout>
      (LayoutActions.ActionTypes.ChangeDefaultLayout),
    mergeMap((action) => {
      const layoutId = action.payload.layoutId;

      return this.pageApi.changeDefaultLayout({ layoutId: layoutId }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PAGES_MODULE.NOTIFICATIONS.DEFAULT_PAGE_LAYOUT_CHANGED'
          );
          return new LayoutActions.ChangeDefaultLayoutSuccessful();
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PAGES_MODULE.NOTIFICATIONS.DEFAULT_PAGE_LAYOUT_COULDNOT_CHANGED'
          );
          return [
            new LayoutActions.ChangeDefaultLayoutFailure({ error: error, key: 'ChangeDefaultLayoutFailure', timestamp: Date.now() })
          ];
        })
      );
    })
  ));


  changeDefaultLayoutSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.ChangeDefaultLayoutSuccessful>
      (LayoutActions.ActionTypes.ChangeDefaultLayoutSuccessful),
    map(() => new LayoutActions.GetLayouts()
    )
  ));
}
