/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

/* angular imports */
import { createAction, props } from '@ngrx/store';
import { ProjectCodeGeneration } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetProjectCodeGenerations = '[ProjectCodeGeneration] GetProjectCodeGenerations',
  GetProjectCodeGenerationsSuccessful = '[ProjectCodeGeneration] GetProjectCodeGenerationsSuccessful',
  GetProjectCodeGenerationsFailure = '[ProjectCodeGeneration] GetProjectCodeGenerationsFailure',
  ErrorAction = '[ProjectCodeGeneration] ErrorAction',
  ResetStateToInitial = '[ProjectCodeGeneration] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[ProjectCodeGeneration] ResetStateToInitialChangeProject'
}

/* actions */

export const GetProjectCodeGenerations = createAction(ActionTypes.GetProjectCodeGenerations);

export const GetProjectCodeGenerationsSuccessful = createAction(
  ActionTypes.GetProjectCodeGenerationsSuccessful,
  props<{ payload: { projectCodeGenerations:  ProjectCodeGeneration[] } }>()
);

export const GetProjectCodeGenerationsFailure = createAction(ActionTypes.GetProjectCodeGenerationsFailure);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);
