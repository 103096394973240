import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  UpdateEnvironmentVariable,
  DeleteEnvironmentVariable,
  CreateEnvironmentVariable,
  ActiveEnvironmentChange,
} from '../../states/environment-variables-state/environment-variable.actions';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG, ENVIRONMENT_VARIABLES_CONFIG } from '@rappider/shared/configs';
import { ActionResponse, AlertTypes, BreadcrumbOption, CrudFormInputTemplateItem, HeadingComponentConfig, HeadingType, PropertyType, TextComponentConfig } from '@rappider/rappider-components/utils';
import { toLower } from 'lodash';
import { EnvironmentVariable, ProjectWithRelations } from '@rappider/rappider-sdk';
import { DEFAULT_ENVIRONMENT_VARIABLE_MODAL_DATA } from './utils/default-create-environment-variable-modal-data';
import { EnvironmentVariablesComponentMode } from './utils/environment-variables-component-mode.enum';

@Component({
  selector: 'rappider-project-environment-variables',
  templateUrl: './project-environment-variables.component.html',
  styleUrls: ['./project-environment-variables.component.scss']
})
export class ProjectEnvironmentVariablesComponent implements OnInit, OnDestroy {
  CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG = CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG;
  ENVIRONMENT_VARIABLES_CONFIG = ENVIRONMENT_VARIABLES_CONFIG;

  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.PROJECT_ENVIRONMENT_VARIABLES',
    type: defaultToolbarTitleHeadingSize
  };
  /* default data for create modal */
  createEnvironmentVariableDefaultData = {
    type: PropertyType.String,
    exposeToFrontend: false,
    exposeToBackend: false,
    isSensitive: false
  };
  /* environment variable modal variables */
  environmentVariableModal = DEFAULT_ENVIRONMENT_VARIABLE_MODAL_DATA;

  subscriptions: Subscription[] = [];
  title: string | string[] | BreadcrumbOption[];
  environmentVariablesData: EnvironmentVariable[];
  grouppedEnvironmentVariables: { key: string; variables: EnvironmentVariable[] }[];
  displayedEnvironmentVariables: EnvironmentVariable[];
  environments: string[];
  activeProject: ProjectWithRelations;
  loading: boolean;
  /* Active environment key index for tabset */
  selectedIndex = 0;
  /* active environment tab */
  activeEnvironment: string;
  environmentVariableComponentMode: EnvironmentVariablesComponentMode;
  displayToolbar = false;
  displayToolbarBackButton = false;
  selectedEnvironmentVariable: EnvironmentVariable;
  isExposeValid = true;

  environmentVariablesFormExposeFieldAlertConfig = {
    type: AlertTypes.Error,
    title: <HeadingComponentConfig>{
      content: 'Field Selection Required',
      type: HeadingType.H6
    },
    description: <TextComponentConfig>{
      text: 'You cant create a environment variable without exposing it to UI or Backend'
    },
    closeable: false,
    showIcon: true
  };

  constructor(
    private store: Store<any>,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  /**
   *  Subscribing all Data
   *
   * @memberof ProjectEnvironmentVariablesComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToEnvironments(),
      this.subscribeToActiveEnvironment(),
      this.subscribeToEnvironmentVariables(),
      this.subscribeToEnvironmentVariableLoading()
    ];
  }

  /**
   * Subscribe active project
   *
   * @returns
   * @memberof ProjectEnvironmentVariablesComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (activeProject) {
        this.activeProject = activeProject;
      }
      this.setTitle();
    });
  }

  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_ENVIRONMENT_VARIABLES.PAGE_TITLE
      }
    ];
  }

  /**
   * Subscribe Environment Variables
   *
   * @return {*}
   * @memberof ProjectEnvironmentVariablesComponent
   */
  subscribeToEnvironmentVariables() {
    return this.store.select(state => state.environmentVariable?.data).subscribe((environmentVariables: EnvironmentVariable[]) => {
      this.environmentVariablesData = environmentVariables ?? [];
      if (this.environmentVariablesData?.length) {
        this.environmentVariablesData = this.environmentVariablesData.map(environmentVariable => ({
          ...environmentVariable,
          type: environmentVariable?.type
        }));
        this.grouppedEnvironmentVariables = this.environmentVariablesData?.reduce((acc, curr) => {
          const variablesGroup = acc.find(item => item.key === curr.environmentKey)?.variables;
          if (variablesGroup) {
            variablesGroup.push(curr);
          } else {
            acc.push({ key: curr.environmentKey, variables: [curr] });
          }
          return acc;
        }, []);
      }
      this.setActiveEnvironment(this.activeEnvironment);
    });
  }

  /**
   * subscribes to environments: DEV, QA, PROD...
   *
   * @return {*}
   * @memberof ProjectEnvironmentVariablesComponent
   */
  subscribeToEnvironments() {
    return this.store.select(state => state.environmentVariable?.environments).subscribe((environments: string[]) => {
      this.environments = environments;
    });
  }

  /**
   * subscribes to active environment to get the data from the tab that we're currently on
   *
   * @return {*}
   * @memberof ProjectEnvironmentVariablesComponent
   */
  subscribeToActiveEnvironment() {
    return this.store.select(state => state.environmentVariable?.activeEnvironment).subscribe((activeEnvironment: string) => {
      this.activeEnvironment = activeEnvironment;
      this.selectedIndex = this.environments?.findIndex(environmentKey => environmentKey === activeEnvironment);
      // added to fix -> ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
      // Previous value: 'undefined'. Current value: '1'..
      this.cdr.detectChanges();
    });
  }

  /**
   * * BUG: ExpressionChangedAfterItHasBeenCheckedError:
   * * Expression has changed after it was checked. Previous value: 'false'. Current value: 'true'.
   *
   * @return {*}
   * @memberof ProjectEnvironmentVariablesComponent
   */
  subscribeToEnvironmentVariableLoading() {
    return this.store.select(state => state.environmentVariable?.loading).subscribe((loading) => {
      this.loading = loading;
    });
  }

  /**
   * Delete environment Variable
   *
   * @param {*} environmentVariable
   * @memberof ProjectEnvironmentVariablesComponent
   */
  deleteEnvironmentVariable(environmentVariableId: string) {
    if (environmentVariableId) {
      this.store.dispatch(new DeleteEnvironmentVariable({ environmentVariableId: environmentVariableId }));
    }
  }

  setActiveEnvironment(environment: string) {
    this.displayedEnvironmentVariables = this.grouppedEnvironmentVariables?.find(group => group.key === environment)?.variables;
    this.store.dispatch(new ActiveEnvironmentChange({ environment: environment }));
  }

  /**
   * Open Create Environment Variable Modal
   *
   * @param {*} data
   * @memberof ProjectEnvironmentVariablesComponent
   */
  openCreateEnvironmentVariableModal(environmentKey: string) {
    this.environmentVariableComponentMode = EnvironmentVariablesComponentMode.Create;
    this.environmentVariableModal.data = {
      ...this.environmentVariableModal.data,
      type: PropertyType.String,
      exposeToFrontend: false,
      exposeToBackend: false,
      isSensitive: false
    };
    this.environmentVariableModal.visible = true;
    this.environmentVariableModal.environment = environmentKey;
  }

  /**
   * Reset create environment variable modal and close create environment variable user modal
   *
   *
   * @memberof ProjectEnvironmentVariablesComponent
   */
  closeEnvironmentVariableModal() {
    this.environmentVariableModal.visible = false;
    this.environmentVariableModal.isSubmitted = false;
    this.environmentVariableModal.data = {
      key: null,
      type: null,
      value: null,
      exposeToFrontend: null,
      exposeToBackend: null,
      isSensitive: null
    };
    this.setValueInputAreaByType(PropertyType.String);
    this.environmentVariableComponentMode = EnvironmentVariablesComponentMode.Create;
    this.isExposeValid = true;
  }

  /**
   * Submit form
   *
   * @memberof ProjectEnvironmentVariablesComponent
   */
  onEnvironmentVariableModalSubmit() {
    this.environmentVariableModal.isSubmitted = true;
    if (this.environmentVariableModal.isValid && (this.environmentVariableModal.data.exposeToBackend || this.environmentVariableModal.data.exposeToFrontend)) {
      if (this.environmentVariableComponentMode === EnvironmentVariablesComponentMode.Create && this.environmentVariableModal.isSubmitted && this.environmentVariableModal.environment) {
        this.store.dispatch(new CreateEnvironmentVariable({
          environmentKey: this.environmentVariableModal.environment,
          environmentVariable: {
            ...this.environmentVariableModal.data,
            value: (this.environmentVariableModal.data.type === PropertyType.Array || this.environmentVariableModal.data.type === PropertyType.Object)
              ? JSON.parse(this.environmentVariableModal.data.value) : this.environmentVariableModal.data.value
          }
        }));
        this.closeEnvironmentVariableModal();
      } else if (this.environmentVariableComponentMode === EnvironmentVariablesComponentMode.Edit && this.environmentVariableModal.isSubmitted) {
        if (JSON.stringify(this.selectedEnvironmentVariable) !== JSON.stringify(this.environmentVariableModal.data)) {
          this.environmentVariableModal.data = {
            ...this.environmentVariableModal.data,
            isSensitive: this.environmentVariableModal.data?.isSensitive ?? false
          };
          this.store.dispatch(new UpdateEnvironmentVariable({
            environmentVariableId: this.environmentVariableModal.editingEnvironmentVariableId,
            environmentVariable: {
              ...this.environmentVariableModal.data,
              value: (this.environmentVariableModal.data.type === PropertyType.Array || this.environmentVariableModal.data.type === PropertyType.Object)
                ? JSON.parse(this.environmentVariableModal.data.value) : this.environmentVariableModal.data.value
            }
          }));
        }
        this.closeEnvironmentVariableModal();
      }
    } else if (!(this.environmentVariableModal.data.exposeToBackend || this.environmentVariableModal.data.exposeToFrontend)) {
      this.isExposeValid = false;
    }
  }

  /**
   * Assign changed data
   *
   * @param {*} data
   * @memberof ProjectEnvironmentVariablesComponent
   */
  onEnvironmentVariableModalDataChange(data: any) {
    if (data.type !== this.environmentVariableModal.data.type) {
      this.environmentVariableModal.data = data;
      this.environmentVariableModal.data.value = null;
      this.setValueInputAreaByType(data.type);
    } else {
      this.environmentVariableModal.data = data;
    }
  }

  /**
 * Open Edit Environment Variable
 *
 * @param {*} data
 * @memberof ProjectEnvironmentVariablesComponent
 */
  onColumnActionClick(actionResponse: ActionResponse) {
    if (actionResponse.action.name === 'EDIT_ENVIRONMENT_VARIABLE') {
      this.environmentVariableComponentMode = EnvironmentVariablesComponentMode.Edit;
      const variableType = actionResponse.data?.type;

      this.environmentVariableModal.data = {
        ...actionResponse?.data,
        value: (variableType === PropertyType.Object || variableType === PropertyType.Array)
          ? JSON.stringify(actionResponse.data?.value)
          : actionResponse.data?.value,
        type: variableType
      };

      this.checkConfigInputDisableControl();

      this.environmentVariableModal.editingEnvironmentVariableId = actionResponse.data.id;
      this.environmentVariableModal.visible = true;
      this.setValueInputAreaByType(variableType);
      this.selectedEnvironmentVariable = actionResponse.data;
    }
    if (actionResponse.action.name === 'DELETE_ITEM') {
      this.deleteEnvironmentVariable(actionResponse.data.id);
    }
  }

  checkConfigInputDisableControl() {
    if (this.environmentVariableModal.data.isSensitive) {
      this.CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG.items = this.CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG.items.map(item => {
        if (item.fieldName !== 'isSensitive') {
          return {
            ...item,
            config: {
              disabled: true
            },
            disabled: true
          };
        } else {
          return item;
        }
      });
    } else {
      this.CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG.items = this.CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG.items.map(item => {
        return {
          ...item,
          disabled: false
        }
      });
    }

    this.CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG = { ...this.CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG };
  }

  /**
   * set 'value' form item's input type by property type
   *
   * @param {string} propertyType
   * @memberof ProjectEnvironmentVariablesComponent
   */
  setValueInputAreaByType(propertyType: string) {
    const valueFormItem = <CrudFormInputTemplateItem>
      this.CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG.items.find(item => item.fieldName === 'value');

    valueFormItem.typeAndFormat = {
      type: toLower(propertyType),
      format: null
    };

    this.CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG = { ...this.CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG };
  }

  onEnvironmentModalDataValidityChange(isValid: boolean) {
    this.environmentVariableModal.isValid = isValid;
  }
}
