import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RappiderOverflowListComponent } from './overflow-list.component';
import { RappiderDropdownMenuModule } from '../dropdown-menu/dropdown-menu.module';
import { RappiderTextboxModule } from '../textbox/textbox.module';

@NgModule({
  declarations: [RappiderOverflowListComponent],
  imports: [
    CommonModule,
    FormsModule,
    RappiderDropdownMenuModule,
    RappiderTextboxModule
  ],
  exports: [RappiderOverflowListComponent]
})
export class RappiderOverflowListModule { }
