import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { NzTreeModule } from 'ng-zorro-antd/tree';


import {
  RappiderInputGroupModule,
  RappiderMonacoEditorModule, RappiderSpinModule, RappiderTitleToolbarModule, RappiderTreeGridModule
} from '@rappider/rappider-components';

/* Components */
import { SourceCodeEditorComponent } from './components/source-code-editor/source-code-editor.component';
import { FormsModule } from '@angular/forms';
import { ProjectSourceFilesComponent } from './components/project-source-files/project-source-files.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { SharedModule } from '@rappider/shared';
import { ProjectSourceCodeDataStoreModule } from './project-source-code-data-source/project-source-code-data-store.module';
import { DownOutline, RightOutline } from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

import { AngularSplitModule } from 'angular-split';

// const routes: Routes = [
//   {
//     path: '',
//     component: SourceCodeEditorComponent,
//     data: { noInnerContentPadding: true }
//   },
// ];

const monacoEditorConfig = {
  baseUrl: 'assets',
  defaultOptions: { scrollBeyondLastLine: false },
  onMonacoLoad: () => {
    console.log((<any>window).monaco);
  }
};

const icons: IconDefinition[] = [DownOutline, RightOutline];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ProjectSourceCodeDataStoreModule,
    RappiderTitleToolbarModule,
    // RouterModule.forChild(routes),
    RappiderMonacoEditorModule.forRoot(monacoEditorConfig),
    NzTreeModule,
    NzDropDownModule,
    NzCollapseModule,
    RappiderTreeGridModule,
    NzTreeViewModule,
    NzIconModule.forChild(icons),
    SharedModule,
    RappiderSpinModule,
    RappiderInputGroupModule,
    AngularSplitModule,
    NzSkeletonModule
  ],
  declarations: [
    SourceCodeEditorComponent,
    ProjectSourceFilesComponent
  ],
  exports: [
    SourceCodeEditorComponent
  ]
})
export class ProjectSourceCodeModule { }
