import { Component } from '@angular/core';
import { HeadingType, IconType, TextAlign, FontWeight, TextMode, ButtonType, ButtonShape, ButtonSize } from '@rappider/rappider-components/utils';
import { successfulLottieAnimation } from 'libs/shared/src/lib/definitions/lottie-animations/successful-lottie-animation';
import { JoyrideService } from 'ngx-joyride';

@Component({
  selector: 'rappider-complete-wizard',
  templateUrl: './complete-wizard.component.html',
  styleUrls: ['./complete-wizard.component.scss']
})
export class CompleteWizardComponent {

  constructor(private readonly joyrideService: JoyrideService) { }

  SuccessAnimation = successfulLottieAnimation;

  title = {
    content: 'Your App is Ready to Launch! 🚀',
    typography: {
      fontSize: 'var(--h2-font-size)',
      fontWeight: FontWeight.Bold,
      textAlign: TextAlign.Center
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };

  description = {
    content: 'You\'ve just built a fully-functional application in minutes that would have taken weeks with traditional development.',
    typography: {
      fontSize: 'var(--h4-font-size)',
      fontWeight: FontWeight.Normal,
      textAlign: TextAlign.Center
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };

  mainText = {
    content: `
    Congratulations on creating your first Rapider app!
    <br>
    Your source code is now ready to download, customize, and deploy. Here's what you can do next:
    <br>
    <br>
    <strong>Download Your Source Code</strong> - Get complete access to your application's codebase with zero vendor lock-in
    <br>
    <strong>Customize Further</strong> - Use our low-code platform to add more features and refine your application
    <br>
    <strong>Deploy Instantly</strong> - Launch your application to a production environment with just a few clicks`,
    textMode: TextMode.Html,
    typography: {
      fontSize: 'var(--main-font-size)',
      fontWeight: FontWeight.Normal,
      textAlign: TextAlign.Center
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };

  shareTitle = {
    content: 'Share Your Success & Get 1 Month Free! 📣',
    typography: {
      fontSize: 'var(--h3-font-size)',
      fontWeight: FontWeight.Bold,
      textAlign: TextAlign.Center
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };

  shareText = {
    content: `Share your achievement on X or LinkedIn, tag @rapiderai (X) or /rapiderai (LinkedIn), and get 1 MONTH FREE on PRO subscription plan!
    <br> Your journey from idea to production just became 10x faster - let your network know about it.`,
    textMode: TextMode.Html,
    typography: {
      fontSize: 'var(--main-font-size)',
      fontWeight: FontWeight.Normal,
      textAlign: TextAlign.Center
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };

  socialLinks = [
    {
      icon: {
        name: 'fab fa-x-twitter',
        type: IconType.FontAwesome,
        color: '#ffffff'
      },
      text: 'Share on X',
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        'I just built a complete application in minutes with @rapiderai! 🚀 Check out how you can build enterprise-grade applications 10x faster: https://rapider.ai #LowCode #AI #Development'
      )}`,
      type: ButtonType.Primary,
      customCssClass: 'blinking-outline-shake'
    },
    {
      icon: {
        name: 'fab fa-linkedin',
        type: IconType.FontAwesome,
        color: '#ffffff'
      },
      text: 'Share on LinkedIn',
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        'https://rapider.ai'
      )}&title=${encodeURIComponent(
        'Building Enterprise Applications with Rapider.ai'
      )}&summary=${encodeURIComponent(
        'I just built a complete application in minutes with Rapider.ai! Check out how you can build enterprise-grade applications 10x faster.'
      )}`,
      type: ButtonType.Primary,
      customCssClass: 'blinking-outline-shake'
    }
  ];

  exploreTitle = {
    content: 'See What You Can Build Next',
    typography: {
      fontSize: 'var(--h3-font-size)',
      fontWeight: FontWeight.Bold,
      textAlign: TextAlign.Center
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };

  exploreText = {
    content: `Click here to see how to use your code and explore all the possibilities with Rapider.
    <br> Our platform combines AI-generated initial code with powerful low-code tools to make enterprise-grade development accessible to everyone.`,
    textMode: TextMode.Html,
    typography: {
      fontSize: 'var(--main-font-size)',
      fontWeight: FontWeight.Normal,
      textAlign: TextAlign.Center
    },
    colorSettings: {
      color: 'var(--link-color)'
    }
  };

  exploreButton = {
    text: 'Explore Rapider'
  };

  onSocialLinkClick(button: any) {
    if (button?.url) {
      window.open(button.url, '_blank');
    }
  }

  onExploreButtonClick() {
    console.log('Explore rappider');
  }

  showSteps() {
    this.joyrideService.startTour(
      { steps: ['completeWizardFirstStep', 'completeWizardSecondStep', 'completeWizardThirdStep', 'completeWizardFourthStep'] }
    );

  }
}
