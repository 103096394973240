import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CreatePageVariableFromAiResponseRequestDto, OpenAiPageVariableRequestDto, PageVariable, PageVariableControllerService } from '@rappider/rappider-sdk';
import * as PageVariablesActions from './page-variables.actions';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class PageVariableEffects {

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private pageVariableApi: PageVariableControllerService
  ) { }

  getPageVariables$ = createEffect(() => this.actions$.pipe(
    ofType(PageVariablesActions.GetPageVariables),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectId]) => {
      if (!activeProjectId) {
        return [];
      }
      const params = {
        filter: {
          where: {
            projectId: activeProjectId
          }
        }
      };
      return this.pageVariableApi.find(params).pipe(
        mergeMap(pageVariables => [PageVariablesActions.GetPageVariablesSuccessful({ payload: { pageVariables } })]),
        catchError(error => [PageVariablesActions.Error({ payload: { error } })])
      );
    })
  ));

  createPageVariable$ = createEffect(() => this.actions$.pipe(
    ofType(PageVariablesActions.CreatePageVariable),
    withLatestFrom(
      this.store.select(state => state.contentEditor?.page?.id)
    ),
    mergeMap(([action, pageId]) => {
      const params = {
        body: {
          ...action.payload.pageVariable,
          pageId: pageId
        }
      };
      return this.pageVariableApi.create(params).pipe(
        mergeMap(pageVariable => [PageVariablesActions.CreatePageVariableSuccessful({ payload: { pageVariable } })]),
        catchError(error => [PageVariablesActions.Error({ payload: { error } })])
      );
    })
  ));

  createPageVariableWithAI$ = createEffect(() => this.actions$.pipe(
    ofType(PageVariablesActions.CreatePageVariableWithAI),
    withLatestFrom(
      this.store.select(state => state.contentEditor?.page?.id)
    ),
    mergeMap(([action, pageId]) => {
      const params: { body: OpenAiPageVariableRequestDto } = {
        body: {
          message: action.payload.pageVariableDescription,
          pageId: pageId
        }
      };
      return this.pageVariableApi.generateByAI(params).pipe(
        mergeMap((pageVariableResponse: CreatePageVariableFromAiResponseRequestDto) =>
          this.pageVariableApi.generateFromAIResponse({ body: pageVariableResponse }).pipe(
            mergeMap((pageVariable: PageVariable) => {
              console.log('pageVariable', pageVariable);
              return [PageVariablesActions.CreatePageVariableWithAISuccessful({ payload: { pageVariable } })];
            }),
            catchError(error => [PageVariablesActions.Error({ payload: { error } })])
          )),
        catchError(error => [PageVariablesActions.Error({ payload: { error } })])
      );
    })
  ));

  updatePageVariables$ = createEffect(() => this.actions$.pipe(
    ofType(PageVariablesActions.UpdatePageVariables),
    mergeMap((action) => {
      const { pageVariable, pageVariableId }: any = action.payload;
      const params = {
        id: pageVariableId,
        body: pageVariable
      };
      return this.pageVariableApi.updateById(params).pipe(
        mergeMap(() => [PageVariablesActions.UpdatePageVariablesSuccessful({ payload: { pageVariable, pageVariableId } })]),
        catchError(error => [PageVariablesActions.Error({ payload: { error } })])
      );
    })
  ));

  deletePageVariable$ = createEffect(() => this.actions$.pipe(
    ofType(PageVariablesActions.DeletePageVariable),
    mergeMap((action) => {
      const params = {
        id: action.payload.pageVariableId
      };
      return this.pageVariableApi.deleteById(params).pipe(
        mergeMap(() => [PageVariablesActions.DeletePageVariableSuccessful({ payload: { pageVariableId: action.payload.pageVariableId } })]),
        catchError(error => [PageVariablesActions.Error({ payload: { error } })])
      );
    })
  ));

}
