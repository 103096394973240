/* eslint-disable no-shadow */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { concatMap, delay, from, mergeMap, Observable, of, tap } from 'rxjs';
import { includes, Many, orderBy, isEqual } from 'lodash';

// SDK
import {
  ComponentDefinitionControllerService,
  CategoryControllerService,
  ProjectControllerService,
  PersonProjectControllerService,
  EnvironmentVariableControllerService,
  MessageTemplateControllerService,
  ProjectSettingControllerService,
  ProjectVersionControllerService,
  ProjectExportControllerService,
  PersonInvitationControllerService,
  ProjectThemeControllerService,
  UiDataStoreControllerService,
  UiDataEventControllerService,
  UiDataUpdateFunctionControllerService,
  UiWorkflowStepFunctionControllerService,
  WorkflowEventControllerService,
  WorkflowStepFunctionControllerService,
  ContainerTemplateControllerService,
  CustomFunctionDefinitionControllerService,
  DbDiagramNodeControllerService,
  PageControllerService,
  ProjectFolderControllerService,
  ModuleControllerService,
  PersonControllerService,
  RoleMappingControllerService,
  CommentControllerService,
  PageCommentControllerService,
  OpenApiControllerService,
  ProjectExternalScriptControllerService,
  ProjectPackageControllerService,
  UiDataSelectorControllerService,
  PageVariableControllerService,
  DataSchemaControllerService,
  ProjectModelControllerService,
  ProjectModelRelationControllerService,
  ProjectModelFieldControllerService,
  ScopeOfWorkControllerService,
  ProcessInfoControllerService,
  ProjectCodeGenerationControllerService,
  ProjectDesignTaskControllerService,
  ClientLocalStateControllerService,
  ProjectDeployControllerService,
  ApplicationBuilderControllerService
} from '@rappider/rappider-sdk';


// IndexDB
import { IndexDBService } from '../indexdb/indexdb.service';

// State
import {
  GetPersonProjectsSuccessful,
  GetPublicProjectsSuccessful,
  SetProjects
} from 'libs/project/src/lib/states/projects-state/project.actions';
import {
  GetEnvironmentVariablesSuccessful,
  SetEnvironments
} from 'libs/project/src/lib/states/environment-variables-state/environment-variable.actions';
import { GetMessageTemplatesSuccessful } from 'libs/project/src/lib/states/message-template-state/message-template.actions';
import { SetProjectSettings } from 'libs/project/src/lib/states/project-settings-state/project-setting.actions';
import { GetProjectMembersSuccessful } from 'libs/project/src/lib/states/project-member-state/project-member.actions';
import { SetActiveProjectWithoutAWorkflow, SetRootFolder } from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import { GetCategoriesSuccessful } from '../../states/category/category.actions';
import { LoadComponentDefinitionsSuccess } from 'libs/component-definition/src/lib/state/component-definition.actions';
import { SetProjectVersions } from 'libs/project/src/lib/states/project-versions-state/project-version.actions';
import { SetProjectExports } from 'libs/project/src/lib/states/project-export-state/project-export.actions';
import { GetInvitedUsersSuccessful } from 'libs/project/src/lib/states/user-invitations-state/user-invitation.actions';
import { GetProjectThemesSuccessful } from 'libs/project/src/lib/states/project-theme-state/project-theme.actions';
import { SetUIDataStores } from 'libs/project/src/lib/states/ui-data-store/ui-data-store.actions';
import { GetTemplateUIDataEventsSuccessful, SetUIDataEvents } from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import { GetUIDataUpdateFunctionsSuccessful } from 'libs/project/src/lib/states/ui-data-update-functions/ui-data-update-function.actions';
import {
  GetTemplateUIStepFunctionsSuccessful,
  GetUIWorkflowStepFunctionsSuccessful
} from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.actions';
import { GetWorkflowEventSuccessful } from 'libs/project/src/lib/states/workflow-event/workflow-event.actions';
import { GetRappiderWorkflowStepFunctionsSuccessful, GetWorkflowStepFunctionSuccessful } from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.actions';
import { GetContainerTemplatesSuccessful } from 'libs/content-editor/src/lib/state/container-template-state/container-template.actions';
import {
  GetCustomFunctionsSuccessful,
  GetRappiderCustomFunctionsSuccessful
} from 'libs/custom-function/src/lib/state/custom-function.actions';
import { GetDBDiagramNodesSuccessful } from 'libs/db-diagram/src/lib/state/db-diagram.actions';
// utils
import { GetUIStepFunctionWithDetailsFilter } from
  'libs/project/src/lib/states/ui-step-functions/utils/get-ui-step-function-with-details-filter';
import { GetTemplatePagesSuccessful, SetPages } from 'libs/pages/src/lib/state/page-state/page.actions';
import { GetModulesSuccessful } from 'libs/module/src/lib/state/module.actions';
import { GetLayoutsSuccessful } from 'libs/pages/src/lib/state/layout-state/layout.actions';
import { PageType } from '@rappider/shared/interfaces';
import { SetPersonProjectRoles } from 'libs/authentication/src/lib/state/authentication.actions';
import { GetPersonAuthoritiesSuccessful } from '../../states/person-authority/person-authority.actions';
import { GetCommentsSuccessful } from 'libs/comment/src/lib/state/comment/comment.actions';
import { GetPageCommentsSuccessful } from 'libs/comment/src/lib/state/page-comment/page-comment.actions';
import { GetOpenAPIsWithRelationsSuccessful } from 'libs/project/src/lib/states/openapi-state/openapi.actions';
import { SetProjectScripts } from 'libs/project/src/lib/states/project-script-state/project-script.actions';
import { SetProjectPackages } from 'libs/project/src/lib/states/project-package-state/project-package.actions';
import { GetUIDataSelectorsSuccessful } from 'libs/ui-data-selector/src/lib/state/ui-data-selector.actions';
import { GetPageVariablesSuccessful } from 'libs/content-editor/src/lib/state/page-variables-state/page-variables.actions';
import { GetProjectDataSchemasSuccessful, GetPublicDataSchemasSuccessful } from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import { DataSchemaCategory } from 'libs/project/src/lib/modules/data-schema/models/data-schema-category.enum';
import { GetProjectModelsSuccessful } from 'libs/project/src/lib/states/project-model-state/project-model.actions';
import { SetProjectRoles } from 'libs/project/src/lib/states/project-roles-state/project-role.actions';
import { GetAllProjectModelRelationsSuccessful } from 'libs/project/src/lib/states/project-model-relation-state/project-model-relation.actions';
import { GetProjectModelFieldsSuccessful } from 'libs/project-model-field/src/lib/state/project-model-field.actions';
import { GetScopeOfWorksSuccessful } from 'libs/project-design/src/lib/state/scope-of-work/scope-of-work.actions';
import { DeployProjectSuccessful } from 'libs/project/src/lib/states/deploy-management/deploy-management.actions';
import { MonitorApplicationSuccessful } from 'libs/project-source-code/src/lib/project-source-code-data-source/project-source-code.actions';
import { GetProcessInfosSuccessful } from '../../states/process-info/process-info.actions';
import { GetProjectCodeGenerationsSuccessful } from '../../states/project-code-generation/project-code-generation.actions';
import { GetProjectDesignTasksSuccessful } from '../../../../../project-design/src/lib/state/project-design-task/project-design-task.actions';
import { GetClientLocalStatesSuccessful } from '../../states/client-local-state/client-local-state.actions';


export enum StoreKeys {
  ActiveProject = 'activeProject',
  Category = 'category',
  ClientLocalState = 'clientLocalState',
  Comment = 'comment',
  ComponentDefinition = 'componentDefinition',
  ContainerTemplate = 'containerTemplate',
  CustomFunction = 'customFunction',
  DbDiagramState = 'dbDiagramState',
  Environment = 'environment',
  EnvironmentVariable = 'environmentVariable',
  Layout = 'layout',
  MessageTemplate = 'messageTemplate',
  Module = 'module',
  OpenAPI = 'openAPI',
  Page = 'page',
  PageComment = 'pageComment',
  PageVariable = 'pageVariable',
  PersonAuthority = 'personAuthority',
  PersonProject = 'personProject',
  ProcessInfo = 'processInfo',
  Project = 'project',
  ProjectCodeGeneration = 'projectCodeGeneration',
  ProjectDataSchema = 'projectDataSchema',
  PublicDataSchema = 'publicDataSchema',
  ProjectDesignTask = 'projectDesignTask',
  ProjectExport = 'projectExport',
  ProjectMember = 'projectMember',
  ProjectModel = 'projectModel',
  ProjectModelField = 'projectModelField',
  ProjectModelRelation = 'projectModelRelation',
  ProjectPackage = 'projectPackage',
  ProjectRole = 'projectRole',
  ProjectScript = 'projectScript',
  ProjectSetting = 'projectSetting',
  ProjectTheme = 'projectTheme',
  ProjectVersion = 'projectVersion',
  PublicProject = 'publicProject',
  Roles = 'roles',
  RootFolder = 'rootFolder',
  ScopeOfWork = 'scopeOfWork',
  TemplatePage = 'templatePage',
  TemplateWorkflowFunctions = 'templateWorkflowFunctions',
  UIDataEvent = 'uiDataEvent',
  UIDataEventTemplate = 'uiDataEventTemplate',
  UIDataSelector = 'uiDataSelector',
  UIDataStore = 'uiDataStore',
  UIDataUpdateFunction = 'uiDataUpdateFunction',
  UIWorkflowStepFunction = 'uiWorkflowStepFunction',
  UIWorkflowStepFunctionTemplate = 'uiWorkflowStepFunctionTemplate',
  UserInvitation = 'userInvitation',
  WorkflowEvent = 'workflowEvent',
  WorkflowStepFunction = 'workflowStepFunction',
  WorkflowStepFunctionTemplate = 'workflowStepFunctionTemplate',
  DeployProject = 'deployProject',
  MonitorApplication = 'monitorApplication'
}


export enum UpdateStateReasons {
  NewData = 'newData',
  UpdateData = 'updateData',
  HydrateCachedData = 'hydrateCachedData',
  EmptyData = 'emptyData',
  ValidateData = 'validateData'
}

export interface SyncDataConfig {
  syncDataKey: string;
  storeKey: StoreKeys;
  actionToHydrateStore: any;
  apiFunctionToFetchData: (params: any) => Observable<any | any[]>;
  isRemoteDataArray: boolean;
  orderFields?: string[];
  orderDirections?: Many<boolean | 'asc' | 'desc'>;
  apiFetchFilter: (latestSyncTimestamp: string, projectId?: string) => object;
  dataValidateFilter: (latestSyncTimestamp: string, projectId?: string) => object;
  useSyncTimestampFilter?: boolean;
  isSharedForAllProjects?: boolean;
  priority?: number;
}


export interface CachedRemoteData {
  _lastSyncTimestamp: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class SyncRemoteDataService {

  syncingProjectId: string;

  // Cached remote data
  public cachedRemoteData: Record<string, any> = {};

  // Array of all data we need to sync
  public syncDataConfig: SyncDataConfig[] = [];
  // Shared data is the data we need to load for all projects
  public syncSharedDataConfig: SyncDataConfig[] = [];
  // Priority data is the data we need to load as soon as a project is selected
  public syncPriorityDataConfig: SyncDataConfig[] = [];
  // Secondary data is the data we can load after the priority data
  public syncSecondaryDataConfig: SyncDataConfig[] = [];

  displaySyncLogs = false;

  /* if this is set for a StoreKey, it stops at the sync functions */
  debugSyncForStoreKey; // = StoreKeys.DataSchema;


  constructor(
    private store: Store<any>,
    private indexDBService: IndexDBService,
    private projectControllerService: ProjectControllerService,
    private categoryControllerService: CategoryControllerService,
    private personProjectControllerService: PersonProjectControllerService,
    private componentDefinitionControllerService: ComponentDefinitionControllerService,
    private environmentVariableControllerService: EnvironmentVariableControllerService,
    private messageTemplateControllerService: MessageTemplateControllerService,
    private projectSettingControllerService: ProjectSettingControllerService,
    private projectMemberControllerService: ProjectControllerService,
    private projectVersionControllerService: ProjectVersionControllerService,
    private projectExportControllerService: ProjectExportControllerService,
    private projectCodeGenerationControllerService: ProjectCodeGenerationControllerService,
    private personInvitationControllerService: PersonInvitationControllerService,
    private projectThemeControllerService: ProjectThemeControllerService,
    private uiDataStoreControllerService: UiDataStoreControllerService,
    private uiDataEventControllerService: UiDataEventControllerService,
    private uiDataUpdateFunctionControllerService: UiDataUpdateFunctionControllerService,
    private uiWorkflowStepFunctionControllerService: UiWorkflowStepFunctionControllerService,
    private workflowEventControllerService: WorkflowEventControllerService,
    private workflowStepFunctionControllerService: WorkflowStepFunctionControllerService,
    private containerTemplateControllerService: ContainerTemplateControllerService,
    private customFunctionDefinitionControllerService: CustomFunctionDefinitionControllerService,
    private dbDiagramNodeControllerService: DbDiagramNodeControllerService,
    private pageControllerService: PageControllerService,
    private projectFolderControllerService: ProjectFolderControllerService,
    private moduleControllerService: ModuleControllerService,
    private personControllerService: PersonControllerService,
    private roleMappingControllerService: RoleMappingControllerService,
    private commentControllerService: CommentControllerService,
    private pageCommentControllerService: PageCommentControllerService,
    private openApiControllerService: OpenApiControllerService,
    private projectExternalScriptControllerService: ProjectExternalScriptControllerService,
    private projectPackageControllerService: ProjectPackageControllerService,
    private uiDataSelectorControllerService: UiDataSelectorControllerService,
    private pageVariableControllerService: PageVariableControllerService,
    private dataSchemaControllerService: DataSchemaControllerService,
    private projectModelControllerService: ProjectModelControllerService,
    private projectModelRelationControllerService: ProjectModelRelationControllerService,
    private projectModelFieldControllerService: ProjectModelFieldControllerService,
    private scopeOfWorkControllerService: ScopeOfWorkControllerService,
    private projectDeployControllerService: ProjectDeployControllerService,
    private applicationBuilderApi: ApplicationBuilderControllerService,
    private processInfoControllerService: ProcessInfoControllerService,
    private projectDesignTaskControllerService: ProjectDesignTaskControllerService,
    private clientLocalStateControllerService: ClientLocalStateControllerService
  ) {

    this.initSyncDataConfig();
  }

  /* This function triggers when we want to reset project data as a new project is selected */
  public resetSyncForANewProject(newProjectId: string) {
    this.resetProjectRelatedData(newProjectId);
    this.syncingProjectId = newProjectId;
  }

  /**
   * Sync shared remote data, which is shared for all projects
   * Triggered on ActiveProjectActions.SelectActiveProjectSuccessful
   * @param {string} projectId
   * @memberof SyncRemoteDataService
   */
  public syncSharedRemoteData() {
    // projectId is not required for shared data as we use `_shared` key for shared data
    this.syncRemoteData('', this.syncSharedDataConfig);
  }

  /**
   * Sync priority remote data, which is required to be fetched when a project is activated
   * Triggered on ActiveProjectActions.SelectActiveProjectSuccessful
   * @param {string} projectId
   * @memberof SyncRemoteDataService
   */
  public syncPriorityRemoteData(projectId: string) {
    if (!this.syncingProjectId) {
      this.syncingProjectId = projectId;
    }
    this.syncRemoteData(projectId, this.syncPriorityDataConfig);
  }


  /**
   * Sync secondary remote data, which is not required to be fetched when a project is activated
   *
   * @param {string} projectId
   * @memberof SyncRemoteDataService
   */
  public syncSecondaryRemoteData(projectId: string) {
    if (!this.syncingProjectId) {
      this.syncingProjectId = projectId;
    }

    const delayBetweenSyncItems = 200; // 200 ms

    from(this.syncSecondaryDataConfig).pipe(
      concatMap(config =>
        of(config).pipe(
          delay(delayBetweenSyncItems),
          mergeMap(config =>
            this.syncRemoteDataItemWithConfig(
              projectId,
              config.storeKey,
              config.actionToHydrateStore,
              config.apiFunctionToFetchData,
              config.isRemoteDataArray,
              config.orderFields,
              config.orderDirections,
              config.apiFetchFilter,
              config.dataValidateFilter,
              config.isSharedForAllProjects
            )
          )
        )
      )
    ).subscribe();
  }

  public syncRemoteDataItemWithKey(projectId, storeKey: StoreKeys) {
    this.log('Syncing remote data item ...', storeKey);
    const config = this.syncDataConfig.find(c => c.storeKey === storeKey);
    if (config) {
      return this.syncRemoteDataItemWithConfig(
        projectId,
        config.storeKey,
        config.actionToHydrateStore,
        config.apiFunctionToFetchData,
        config.isRemoteDataArray,
        config.orderFields,
        config.orderDirections,
        config.apiFetchFilter,
        config.dataValidateFilter,
        config.isSharedForAllProjects
      );
    } else {
      console.log('No sync config found for store key:', storeKey);
      return new Promise<void>(() => null);
    }
  }


  private initSyncDataConfig() {
    this.syncDataConfig = [
      // Active Project
      {
        syncDataKey: 'activeProject',
        storeKey: StoreKeys.ActiveProject,
        actionToHydrateStore: (data) => new SetActiveProjectWithoutAWorkflow({ project: data }),
        apiFunctionToFetchData: (params) => this.projectControllerService.findById(params),
        isRemoteDataArray: false,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (_) => ({ include: ['people', 'tenant'] }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 1
      },
      // Categories
      {
        syncDataKey: 'category',
        storeKey: StoreKeys.Category,
        actionToHydrateStore: (data) => GetCategoriesSuccessful({ payload: { categories: data } }),
        apiFunctionToFetchData: (params) => this.categoryControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 1
      },
      // Component Definitions
      {
        syncDataKey: 'componentDefinition',
        storeKey: StoreKeys.ComponentDefinition,
        actionToHydrateStore: (data) => new LoadComponentDefinitionsSuccess({ componentDefinitions: data }),
        apiFunctionToFetchData: (params) => this.componentDefinitionControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['title'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          include: ['inputDefinitions', 'outputDefinitions', 'samples', 'mainCategory', 'subCategories']
        } : {
          include: ['inputDefinitions', 'outputDefinitions', 'samples', 'mainCategory', 'subCategories']
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        isSharedForAllProjects: true
      },
      // Projects || AS projects changes with multiple users on the same machine, we need a better way to sync this data
      // {
      //   syncDataKey: 'project',
      //   storeKey: StoreKeys.Project,
      //   actionToHydrateStore: (data) => new SetProjects({ projects: data }),
      //   apiFunctionToFetchData: (params) => this.projectControllerService.find(params),
      //   isRemoteDataArray: true,
      //   orderFields: ['name'],
      //   orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
      //   apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
      //     // where: {
      //     //   or: [
      //     //     { createdDate: { gt: latestSyncTimestamp } },
      //     //     { updatedDate: { gt: latestSyncTimestamp } },
      //     //   ],
      //     // }
      //   } : {}),
      //   isSharedForAllProjects: true,
      //   priority: 1
      // },
      // Person Projects - DISABLED as there are multiple users on the same machine. TODO: Implement a better way to sync this data
      // {
      //   syncDataKey: 'personProject',
      //   storeKey: StoreKeys.PersonProject,
      //   actionToHydrateStore: (data) => new GetPersonProjectsSuccessful({ personProjects: data }),
      //   apiFunctionToFetchData: (params) => this.personProjectControllerService.find(params),
      //   isRemoteDataArray: true,
      //   orderFields: ['name'],
      //   orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
      //   apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
      //     where: {
      //       or: [
      //         { createdDate: { gt: latestSyncTimestamp } },
      //         { updatedDate: { gt: latestSyncTimestamp } },
      //       ],
      //     }
      //   } : {}),
      //   priority: 1,
      //   isSharedForAllProjects: true
      // },
      // Public Projects
      {
        syncDataKey: 'publicProject',
        storeKey: StoreKeys.PublicProject,
        actionToHydrateStore: (data) => new GetPublicProjectsSuccessful({ publicProjects: data }),
        apiFunctionToFetchData: (params) => this.projectControllerService.findPublicProjects(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        isSharedForAllProjects: true
      },
      // page - PageControllerService - SetPages
      {
        syncDataKey: 'page',
        storeKey: StoreKeys.Page,
        actionToHydrateStore: (data) => new SetPages({ pages: data }),
        apiFunctionToFetchData: (params) => this.pageControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            and: [
              {
                or: [
                  { createdDate: { gt: latestSyncTimestamp } },
                  { updatedDate: { gt: latestSyncTimestamp } },
                ],
              },
              {
                type: PageType.Page,
              }
            ],
          },
          fields: {
            contentTree: false
          },
          include: [
            {
              relation: 'categories',
              scope: {
                fields: {
                  id: true,
                  title: true
                }
              }
            }
          ],
          order: ['name ASC'],
        } :
          {
            where: {
              type: PageType.Page,
            },
            fields: {
              contentTree: false
            },
            include: [
              {
                relation: 'categories',
                scope: {
                  fields: {
                    id: true,
                    title: true
                  }
                }
              }
            ],
            order: ['name ASC'],
          }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({
          where: {
            type: PageType.Page,
          },
          fields: ['id']
        }),
        priority: 2
      },
      // processInfo - processInfoControllerService.find - GetProcessInfosSuccessful({ payload: { processInfos: processInfos } }),
      {
        syncDataKey: 'processInfo',
        storeKey: StoreKeys.ProcessInfo,
        actionToHydrateStore: (data) => GetProcessInfosSuccessful({ payload: { processInfos: data } }),
        apiFunctionToFetchData: (params) => this.processInfoControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            and: [
              { projectId: projectId },
              {
                or: [
                  { createdDate: { gt: latestSyncTimestamp } },
                  { updatedDate: { gt: latestSyncTimestamp } },
                ],
              }
            ],
          }
        } : {
          where: {
            projectId: projectId
          }
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // environmentVariable
      {
        syncDataKey: 'environmentVariable',
        storeKey: StoreKeys.EnvironmentVariable,
        actionToHydrateStore: (data) => new GetEnvironmentVariablesSuccessful({ environmentVariables: data }),
        apiFunctionToFetchData: (params) => this.environmentVariableControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['key'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // environment - SetEnvironments
      {
        syncDataKey: 'environment',
        storeKey: StoreKeys.Environment,
        actionToHydrateStore: (data) => new SetEnvironments({ environments: data }),
        apiFunctionToFetchData: (params) => this.environmentVariableControllerService.getEnvironmentKeys(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // projectRole - SetProjectRoles - ProjectControllerService
      {
        syncDataKey: 'projectRole',
        storeKey: StoreKeys.ProjectRole,
        actionToHydrateStore: (data) => new SetProjectRoles({ projectRoles: data }),
        apiFunctionToFetchData: (params) => this.projectControllerService.findProjectRoles(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 1
      },
      // messageTemplate
      {
        syncDataKey: 'messageTemplate',
        storeKey: StoreKeys.MessageTemplate,
        actionToHydrateStore: (data) => new GetMessageTemplatesSuccessful({ messageTemplates: data }),
        apiFunctionToFetchData: (params) => this.messageTemplateControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['title'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 3
      },
      // projectSetting
      {
        syncDataKey: 'projectSetting',
        storeKey: StoreKeys.ProjectSetting,
        actionToHydrateStore: (data) => new SetProjectSettings({ projectSettings: data }),
        apiFunctionToFetchData: (params) => this.projectSettingControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // projectMember
      {
        syncDataKey: 'projectMember',
        storeKey: StoreKeys.ProjectMember,
        actionToHydrateStore: (data) => new GetProjectMembersSuccessful({ projectMembers: data }),
        apiFunctionToFetchData: (params) => this.projectMemberControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 3
      },
      // projectVersion
      {
        syncDataKey: 'projectVersion',
        storeKey: StoreKeys.ProjectVersion,
        actionToHydrateStore: (data) => new SetProjectVersions({ projectVersions: data }),
        apiFunctionToFetchData: (params) => this.projectVersionControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // projectExport
      {
        syncDataKey: 'projectExport',
        storeKey: StoreKeys.ProjectExport,
        actionToHydrateStore: (data) => new SetProjectExports({ projectExports: data }),
        apiFunctionToFetchData: (params) => this.projectExportControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 3
      },
      // projectCodeGeneration - GetProjectCodeGenerationsSuccessful({ payload: { projectCodeGenerations: projectCodeGenerations } })
      {
        syncDataKey: 'projectCodeGeneration',
        storeKey: StoreKeys.ProjectCodeGeneration,
        actionToHydrateStore: (data) => GetProjectCodeGenerationsSuccessful({ payload: { projectCodeGenerations: data } }),
        apiFunctionToFetchData: (params) => this.projectCodeGenerationControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 3
      },
      // userInvitation
      {
        syncDataKey: 'userInvitation',
        storeKey: StoreKeys.UserInvitation,
        actionToHydrateStore: (data) => GetInvitedUsersSuccessful({ payload: { invitedUsers: data } }),
        apiFunctionToFetchData: (params) => this.personInvitationControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 3
      },
      // projectTheme
      {
        syncDataKey: 'projectTheme',
        storeKey: StoreKeys.ProjectTheme,
        actionToHydrateStore: (data) => new GetProjectThemesSuccessful({ projectThemes: data }),
        apiFunctionToFetchData: (params) => this.projectThemeControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // projectModel - GetProjectModelsSuccessful - ProjectModelControllerService
      {
        syncDataKey: 'projectModel',
        storeKey: StoreKeys.ProjectModel,
        actionToHydrateStore: (data) => new GetProjectModelsSuccessful({ projectModels: data }),
        apiFunctionToFetchData: (params) => this.projectModelControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          include: [
            'relations',
            'generatedUIDataStore',
          ]
        } : {
          include: [
            'relations',
            'generatedUIDataStore',
          ]
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 1
      },
      // projectModelRelation - GetAllProjectModelRelationsSuccessful - ProjectModelRelationControllerService
      {
        syncDataKey: 'projectModelRelation',
        storeKey: StoreKeys.ProjectModelRelation,
        actionToHydrateStore: (data) => new GetAllProjectModelRelationsSuccessful({ projectModelRelations: data }),
        apiFunctionToFetchData: (params) => this.projectModelRelationControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          include: ['sourceModel', 'targetModel', 'throughModel']
        } : { include: ['sourceModel', 'targetModel', 'throughModel'] }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // projectModelField - GetProjectModelFieldsSuccessful - ProjectModelFieldControllerService
      {
        syncDataKey: 'projectModelField',
        storeKey: StoreKeys.ProjectModelField,
        actionToHydrateStore: (data) => GetProjectModelFieldsSuccessful({ payload: { projectModelFields: data } }),
        apiFunctionToFetchData: (params) => this.projectModelFieldControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // uiDataStore
      {
        syncDataKey: 'uiDataStore',
        storeKey: StoreKeys.UIDataStore,
        actionToHydrateStore: (data) => new SetUIDataStores({ uiDataStores: data }),
        apiFunctionToFetchData: (params) => this.uiDataStoreControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          order: ['name ASC'],
          include: [
            {
              relation: 'schema',
              scope: {
                include: [
                  {
                    relation: 'fields',
                    scope: { order: 'index ASC' }
                  }
                ]
              }
            },
            {
              relation: 'events'
            }
          ]
        } : {
          order: ['name ASC'],
          include: [
            {
              relation: 'schema',
              scope: {
                include: [
                  {
                    relation: 'fields',
                    scope: { order: 'index ASC' }
                  }
                ]
              }
            },
            {
              relation: 'events'
            }
          ]
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // uiDataEvent
      {
        syncDataKey: 'uiDataEvent',
        storeKey: StoreKeys.UIDataEvent,
        actionToHydrateStore: (data) => new SetUIDataEvents({ uiDataEvents: data }),
        apiFunctionToFetchData: (params) => this.uiDataEventControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          },
          order: ['name ASC'],
          include: [
            {
              relation: 'payload',
              scope: {
                order: ['name ASC'],
                include: [{ relation: 'fields' }]
              }
            },
            {
              relation: 'uiDataUpdateFunction'
            }
          ]
        } : {
          order: ['name ASC'],
          include: [
            {
              relation: 'payload',
              scope: {
                order: ['name ASC'],
                include: [{ relation: 'fields' }]
              }
            },
            {
              relation: 'uiDataUpdateFunction'
            }
          ]
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // uiDataEventTemplate - GetTemplateUIDataEventsSuccessful - uiDataEventControllerService.findStarterTemplateEvents()
      {
        syncDataKey: 'uiDataEventTemplate',
        storeKey: StoreKeys.UIDataEventTemplate,
        actionToHydrateStore: (data) => new GetTemplateUIDataEventsSuccessful({ uiDataEvents: data }),
        apiFunctionToFetchData: (params) => this.uiDataEventControllerService.findStarterTemplateEvents(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          },
          order: ['name ASC'],
        } : { order: ['name ASC'] }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        isSharedForAllProjects: true
      },
      // uiDataUpdateFunction
      {
        syncDataKey: 'uiDataUpdateFunction',
        storeKey: StoreKeys.UIDataUpdateFunction,
        actionToHydrateStore: (data) => new GetUIDataUpdateFunctionsSuccessful({ uiDataUpdateFunctions: data }),
        apiFunctionToFetchData: (params) => this.uiDataUpdateFunctionControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // uiWorkflowStepFunction
      {
        syncDataKey: 'uiWorkflowStepFunction',
        storeKey: StoreKeys.UIWorkflowStepFunction,
        actionToHydrateStore: (data) => new GetUIWorkflowStepFunctionsSuccessful({ uiWorkflowStepFunctions: data }),
        apiFunctionToFetchData: (params) => this.uiWorkflowStepFunctionControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          include: GetUIStepFunctionWithDetailsFilter
        } : {
          include: GetUIStepFunctionWithDetailsFilter
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // uiWorkflowStepFunctionTemplate - GetTemplateUIStepFunctionsSuccessful
      {
        syncDataKey: 'uiWorkflowStepFunctionTemplate',
        storeKey: StoreKeys.UIWorkflowStepFunctionTemplate,
        actionToHydrateStore: (data) => new GetTemplateUIStepFunctionsSuccessful({ uiWorkflowStepFunctions: data }),
        apiFunctionToFetchData: (params) => this.uiWorkflowStepFunctionControllerService.findServices(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          include: GetUIStepFunctionWithDetailsFilter
        } : {
          include: GetUIStepFunctionWithDetailsFilter
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        isSharedForAllProjects: true
      },
      // Backend Workflow Event
      {
        syncDataKey: 'workflowEvent',
        storeKey: StoreKeys.WorkflowEvent,
        actionToHydrateStore: (data) => GetWorkflowEventSuccessful({ payload: { workflowEvents: data } }),
        apiFunctionToFetchData: (params) => this.workflowEventControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // Backend Workflow Step Function
      {
        syncDataKey: 'workflowStepFunction',
        storeKey: StoreKeys.WorkflowStepFunction,
        actionToHydrateStore: (data) => GetWorkflowStepFunctionSuccessful({ payload: { workflowStepFunctions: data } }),
        apiFunctionToFetchData: (params) => this.workflowStepFunctionControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          include: ['subscribedEvents', 'publishedEventsOnSuccess', 'publishedEventsOnFailure']
        } : {
          include: ['subscribedEvents', 'publishedEventsOnSuccess', 'publishedEventsOnFailure']
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // workflowStepFunctionTemplate -  GetRappiderWorkflowStepFunctionsSuccessful - workflowStepFunctionControllerService.findServices
      {
        syncDataKey: 'workflowStepFunctionTemplate',
        storeKey: StoreKeys.WorkflowStepFunctionTemplate,
        actionToHydrateStore: (data) => GetRappiderWorkflowStepFunctionsSuccessful({ payload: { workflowStepFunctions: data } }),
        apiFunctionToFetchData: (params) => this.workflowStepFunctionControllerService.findServices(params),
        isRemoteDataArray: true,
        orderFields: ['serviceName', 'functionName'],
        orderDirections: ['asc', 'asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          },
          include: ['subscribedEvents', 'publishedEventsOnSuccess', 'publishedEventsOnFailure']
        } : {
          include: ['subscribedEvents', 'publishedEventsOnSuccess', 'publishedEventsOnFailure']
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        isSharedForAllProjects: true
      },
      // containerTemplate
      {
        syncDataKey: 'containerTemplate',
        storeKey: StoreKeys.ContainerTemplate,
        actionToHydrateStore: (data) => GetContainerTemplatesSuccessful({ payload: { containerTemplates: data } }),
        apiFunctionToFetchData: (params) => this.containerTemplateControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            and: [
              { isPublic: true }, // do not get templates for the project
              {
                or: [
                  { createdDate: { gt: latestSyncTimestamp } },
                  { updatedDate: { gt: latestSyncTimestamp } },
                ]
              },
            ]
          },
        } : {
          where: { isPublic: true }, // do not get templates for the project
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ where: { isPublic: true }, fields: ['id'] }),
        isSharedForAllProjects: true
      },
      // templateWorkflowFunctions - custom functions templates on rapider project
      {
        syncDataKey: 'templateWorkflowFunctions',
        storeKey: StoreKeys.TemplateWorkflowFunctions,
        actionToHydrateStore: (data) => GetRappiderCustomFunctionsSuccessful({ payload: { customFunctions: data } }),
        apiFunctionToFetchData: (params) => this.customFunctionDefinitionControllerService.findTemplateFunctions(params),
        isRemoteDataArray: true,
        orderFields: ['serviceName', 'functionName'],
        orderDirections: ['asc', 'asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          order: [
            'serviceName ASC',
            'functionName ASC'
          ]
        } : {
          order: [
            'serviceName ASC',
            'functionName ASC'
          ]
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        isSharedForAllProjects: true
      },

      // customFunction - GetCustomFunctionsSuccessful
      {
        syncDataKey: 'customFunction',
        storeKey: StoreKeys.CustomFunction,
        actionToHydrateStore: (data) => GetCustomFunctionsSuccessful({ customFunctions: data }),
        apiFunctionToFetchData: (params) => this.customFunctionDefinitionControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['serviceName', 'functionName'],
        orderDirections: ['asc', 'asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          order: [
            'serviceName ASC',
            'functionName ASC'
          ]
        } : {
          order: [
            'serviceName ASC',
            'functionName ASC'
          ]
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // dbDiagramState
      // TODO: remove  where: { projectId: projectId } after API filters by the token
      {
        syncDataKey: 'dbDiagramState',
        storeKey: StoreKeys.DbDiagramState,
        actionToHydrateStore: (data) => GetDBDiagramNodesSuccessful({ payload: { dbDiagramNodes: data } }),
        apiFunctionToFetchData: (params) => this.dbDiagramNodeControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // templatePages - GetTemplatePagesSuccessful
      {
        syncDataKey: 'templatePage',
        storeKey: StoreKeys.TemplatePage,
        actionToHydrateStore: (data) => new GetTemplatePagesSuccessful({ templatePages: data }),
        apiFunctionToFetchData: (params) => this.pageControllerService.findTemplates(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          fields: {
            id: true,
            tags: true,
            title: true,
            thumbnailImageUrl: true,
            type: true
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        isSharedForAllProjects: true
      },
      // rootFolder
      {
        syncDataKey: 'rootFolder',
        storeKey: StoreKeys.RootFolder,
        actionToHydrateStore: (data) => new SetRootFolder({ rootFolder: data }),
        apiFunctionToFetchData: (params) => this.projectFolderControllerService.getRootFolder(params),
        isRemoteDataArray: false,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => ({
          include: [
            {
              relation: 'subFolders'
            },
          ]
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 1
      },
      // module
      {
        syncDataKey: 'module',
        storeKey: StoreKeys.Module,
        actionToHydrateStore: (data) => GetModulesSuccessful({ payload: { modules: data } }),
        apiFunctionToFetchData: (params) => this.moduleControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // layout
      {
        syncDataKey: 'layout',
        storeKey: StoreKeys.Layout,
        actionToHydrateStore: (data) => new GetLayoutsSuccessful({ layouts: data }),
        apiFunctionToFetchData: (params) => this.pageControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            and: [
              {
                type: PageType.Layout
              },
              {
                or: [
                  { createdDate: { gt: latestSyncTimestamp } },
                  { updatedDate: { gt: latestSyncTimestamp } },
                ],
              }
            ]
          },
          include: [
            {
              relation: 'components',
            }
          ],
          order: ['createdDate DESC'],
        } : {
          where: {
            type: PageType.Layout
          },
          include: [
            {
              relation: 'components',
            }
          ],
          order: ['createdDate DESC'],
        }),
        dataValidateFilter: (_, __) => ({
          fields: ['id'],
          where: {
            type: PageType.Layout
          }
        }),
        priority: 2
      },
      // roles
      {
        syncDataKey: 'roles',
        storeKey: StoreKeys.Roles,
        actionToHydrateStore: (data) => new SetPersonProjectRoles({ roles: data }),
        apiFunctionToFetchData: (params) => this.personControllerService.getProjectRoles(params),
        isRemoteDataArray: true,
        orderFields: ['title'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 1
      },
      // personAuthority - GetPersonAuthoritiesSuccessful
      {
        syncDataKey: 'personAuthority',
        storeKey: StoreKeys.PersonAuthority,
        actionToHydrateStore: (data) => GetPersonAuthoritiesSuccessful({ personAuthorities: data }),
        apiFunctionToFetchData: (params) => this.roleMappingControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['title'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          include: ['role']
        } : {
          include: ['role']
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 1
      },
      // comment - GetCommentsSuccessful
      {
        syncDataKey: 'comment',
        storeKey: StoreKeys.Comment,
        actionToHydrateStore: (data) => GetCommentsSuccessful({ comments: data }),
        apiFunctionToFetchData: (params) => this.commentControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
          include: [
            {
              relation: 'replies',
              scope: {
                order: 'createdDate ASC'
              }
            }
          ],
        } : {
          include: [
            {
              relation: 'replies',
              scope: {
                order: 'createdDate ASC'
              }
            }
          ],
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // pageComment - GetPageCommentsSuccessful
      {
        syncDataKey: 'pageComment',
        storeKey: StoreKeys.PageComment,
        actionToHydrateStore: (data) => GetPageCommentsSuccessful({ pageComments: data }),
        apiFunctionToFetchData: (params) => this.pageCommentControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { createdDate: { gt: latestSyncTimestamp } },
              { updatedDate: { gt: latestSyncTimestamp } },
            ],
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // openAPI - GetOpenAPIsWithRelationsSuccessful
      {
        syncDataKey: 'openAPI',
        storeKey: StoreKeys.OpenAPI,
        actionToHydrateStore: (data) => GetOpenAPIsWithRelationsSuccessful({ payload: { openApiData: data } }),
        apiFunctionToFetchData: (params) => this.openApiControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          },
          include: ['endpoints']
        } : {
          include: ['endpoints']
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 3
      },
      // projectScript - projectExternalScriptControllerService - SetProjectScripts
      {
        syncDataKey: 'projectScript',
        storeKey: StoreKeys.ProjectScript,
        actionToHydrateStore: (data) => new SetProjectScripts({ projectScripts: data }),
        apiFunctionToFetchData: (params) => this.projectExternalScriptControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          },
          include: ['projectFile']
        } : {
          include: ['projectFile']
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 3
      },
      // projectPackage - ProjectPackageControllerService - SetProjectPackages
      {
        syncDataKey: 'projectPackage',
        storeKey: StoreKeys.ProjectPackage,
        actionToHydrateStore: (data) => new SetProjectPackages({ projectPackages: data }),
        apiFunctionToFetchData: (params) => this.projectPackageControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          },
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 3
      },
      // uiDataSelector - UiDataSelectorControllerService - GetUIDataSelectorsSuccessful
      {
        syncDataKey: 'uiDataSelector',
        storeKey: StoreKeys.UIDataSelector,
        actionToHydrateStore: (data) => GetUIDataSelectorsSuccessful({ payload: { uiDataSelectors: data } }),
        apiFunctionToFetchData: (params) => this.uiDataSelectorControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          },
          include: ['uiDataStoreFieldSelectors']
        } : {
          include: ['uiDataStoreFieldSelectors']
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // pageVariables - PageVariableControllerService - GetPageVariablesSuccessful
      {
        syncDataKey: 'pageVariable',
        storeKey: StoreKeys.PageVariable,
        actionToHydrateStore: (data) => GetPageVariablesSuccessful({ payload: { pageVariables: data } }),
        apiFunctionToFetchData: (params) => this.pageVariableControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // dataSchema - DataSchemaControllerService - SetDataSchemas - shared data schemas
      // export enum DataSchemaCategory {
      //   ComponentConfig = 'component-config', /* data-schema for component inputs */                     ==> shared
      //   ComponentIOType = 'component-io-type', /* data-schema for component input and output */          ==> shared
      //   UserGenerated = 'user-generated', /* user generated data-schema */                               ==> specific to project
      //   ProjectModel = 'project-model', /* data-schema for project model ( fields duplicated ) */        ==> specific to project
      //   UiDataStoreState = 'ui-data-store-state', /* data-schema for ui-data-store's state interface */  ==> specific to project
      //   UiDataEventPayload = 'ui-data-event-payload' /* data-schema for event's payload interface */     ==> specific to project
      //   EndpointRequest = 'endpoint-request' /* data-schema for event's payload interface */,            ==> specific to project
      //   EndpointResponse = 'endpoint-response' /* data-schema for event's payload interface */,          ==> specific to project
      //   TemplateSchema = 'template-schema' /* data-schema for message template's input */                ==> specific to project
      {
        syncDataKey: 'publicDataSchema',
        storeKey: StoreKeys.PublicDataSchema,
        actionToHydrateStore: (data) => new GetPublicDataSchemasSuccessful({ publicDataSchemas: data }),
        apiFunctionToFetchData: (params) => this.dataSchemaControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            and: [
              {
                or: [
                  { 'createdDate': { 'gt': latestSyncTimestamp } },
                  { 'updatedDate': { 'gt': latestSyncTimestamp } }
                ]
              },
              {
                or: [
                  { isPrimitive: true },
                  { category: DataSchemaCategory.ComponentConfig }, // shared data schema
                  { category: DataSchemaCategory.ComponentIOType } // shared data schema
                ],
              }
            ],
          },
          include: [
            {
              relation: 'fields',
              scope: {
                order: 'index ASC',
                include: [
                  {
                    relation: 'uiDataSelectorEnumData'
                  }
                ]
              }
            },
            {
              relation: 'enumData'
            }
          ],
        } : {
          where: {
            or: [
              { isPrimitive: true },
              { category: DataSchemaCategory.ComponentConfig }, // shared data schema
              { category: DataSchemaCategory.ComponentIOType } // shared data schema
            ],
          },
          include: [
            {
              relation: 'fields',
              scope: {
                order: 'index ASC',
                include: [
                  {
                    relation: 'uiDataSelectorEnumData'
                  }
                ]
              }
            },
            {
              relation: 'enumData'
            }
          ],
        }),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({
          fields: ['id'],
          where: {
            or: [
              { isPrimitive: true },
              { category: DataSchemaCategory.ComponentConfig }, // shared data schema
              { category: DataSchemaCategory.ComponentIOType } // shared data schema
            ],
          }
        }),
        isSharedForAllProjects: true
      },
      // export enum DataSchemaCategory {
      //   ComponentConfig = 'component-config', /* data-schema for component inputs */                     ==> shared
      //   ComponentIOType = 'component-io-type', /* data-schema for component input and output */          ==> shared
      //   UserGenerated = 'user-generated', /* user generated data-schema */                               ==> specific to project
      //   ProjectModel = 'project-model', /* data-schema for project model ( fields duplicated ) */        ==> specific to project
      //   UiDataStoreState = 'ui-data-store-state', /* data-schema for ui-data-store's state interface */  ==> specific to project
      //   UiDataEventPayload = 'ui-data-event-payload' /* data-schema for event's payload interface */     ==> specific to project
      //   EndpointRequest = 'endpoint-request' /* data-schema for event's payload interface */,            ==> specific to project
      //   EndpointResponse = 'endpoint-response' /* data-schema for event's payload interface */,          ==> specific to project
      //   TemplateSchema = 'template-schema' /* data-schema for message template's input */                ==> specific to project
      {
        syncDataKey: 'projectDataSchemas',
        storeKey: StoreKeys.ProjectDataSchema,
        actionToHydrateStore: (data) => new GetProjectDataSchemasSuccessful({ projectDataSchemas: data }),
        apiFunctionToFetchData: (params) => this.dataSchemaControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['name'],
        orderDirections: ['asc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string, projectId: string) => (latestSyncTimestamp ? {
          where: {
            and: [
              {
                or: [
                  { 'createdDate': { 'gt': latestSyncTimestamp } },
                  { 'updatedDate': { 'gt': latestSyncTimestamp } }
                ]
              },
              {
                or: [
                  { category: DataSchemaCategory.UserGenerated }, // specific to project
                  { category: DataSchemaCategory.ProjectModel }, // specific to project
                  { category: DataSchemaCategory.UiDataStoreState }, // specific to project
                  { category: DataSchemaCategory.UiDataEventPayload }, // specific to project
                  { category: DataSchemaCategory.EndpointRequest }, // specific to project
                  { category: DataSchemaCategory.EndpointResponse }, // specific to project
                  { category: DataSchemaCategory.TemplateSchema } // specific to project
                ],
              },
              { projectId: projectId }
            ],
          },
          include: [
            {
              relation: 'fields',
              scope: {
                order: 'index ASC',
                include: [
                  {
                    relation: 'uiDataSelectorEnumData'
                  }
                ]
              }
            },
            {
              relation: 'enumData'
            }
          ],
        } : {
          where: {
            and: [
              {
                or: [
                  { category: DataSchemaCategory.UserGenerated }, // specific to project
                  { category: DataSchemaCategory.ProjectModel }, // specific to project
                  { category: DataSchemaCategory.UiDataStoreState }, // specific to project
                  { category: DataSchemaCategory.UiDataEventPayload }, // specific to project
                  { category: DataSchemaCategory.EndpointRequest }, // specific to project
                  { category: DataSchemaCategory.EndpointResponse }, // specific to project
                  { category: DataSchemaCategory.TemplateSchema } // specific to project
                ],
              },
              { projectId: projectId }
            ],
          },
          include: [
            {
              relation: 'fields',
              scope: {
                order: 'index ASC',
                include: [
                  {
                    relation: 'uiDataSelectorEnumData'
                  }
                ]
              }
            },
            {
              relation: 'enumData'
            }
          ],
        }),
        priority: 2,
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({
          fields: ['id'],
          where: {
            and: [
              {
                or: [
                  { category: DataSchemaCategory.UserGenerated }, // specific to project
                  { category: DataSchemaCategory.ProjectModel }, // specific to project
                  { category: DataSchemaCategory.UiDataStoreState }, // specific to project
                  { category: DataSchemaCategory.UiDataEventPayload }, // specific to project
                  { category: DataSchemaCategory.EndpointRequest }, // specific to project
                  { category: DataSchemaCategory.EndpointResponse }, // specific to project
                  { category: DataSchemaCategory.TemplateSchema } // specific to project
                ],
              },
              { projectId: projectId }
            ]
          }
        }),
        isSharedForAllProjects: false
      },
      // scopeOfWork - ScopeOfWorkControllerService - GetScopeOfWorksSuccessful
      {
        syncDataKey: 'scopeOfWork',
        storeKey: StoreKeys.ScopeOfWork,
        actionToHydrateStore: (data) => GetScopeOfWorksSuccessful({ payload: { scopeOfWorks: data } }),
        apiFunctionToFetchData: (params) => this.scopeOfWorkControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // deployProject  - ProjectDeployControllerService - DeployProjectSuccessful
      {
        syncDataKey: 'deployProject',
        storeKey: StoreKeys.DeployProject,
        actionToHydrateStore: (data) => DeployProjectSuccessful({ deployInfo: data }),
        apiFunctionToFetchData: (params) => this.projectDeployControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // monitorApplication  - ProjectDeployControllerService - MonitorApplicationSuccessful
      {
        syncDataKey: 'monitorApplication',
        storeKey: StoreKeys.MonitorApplication,
        actionToHydrateStore: (data) => MonitorApplicationSuccessful({ payload: { monitorApplicationInfo: data } }),
        apiFunctionToFetchData: (params) => this.applicationBuilderApi.monitorApplication(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      },
      // projectDesignTask - ProjectDesignTaskControllerService - GetProjectDesignTasksSuccessful
      {
        syncDataKey: 'projectDesignTask',
        storeKey: StoreKeys.ProjectDesignTask,
        actionToHydrateStore: (data) => GetProjectDesignTasksSuccessful({ payload: { projectDesignTasks: data } }),
        apiFunctionToFetchData: (params) => this.projectDesignTaskControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2

      },
      // clientLocalState - ClientLocalStateControllerService - GetClientLocalStatesSuccessful
      {
        syncDataKey: 'clientLocalState',
        storeKey: StoreKeys.ClientLocalState,
        actionToHydrateStore: (data) => GetClientLocalStatesSuccessful({ payload: { clientLocalStates: data } }),
        apiFunctionToFetchData: (params) => this.clientLocalStateControllerService.find(params),
        isRemoteDataArray: true,
        orderFields: ['createdDate'],
        orderDirections: ['desc'] as Many<boolean | 'asc' | 'desc'>,
        apiFetchFilter: (latestSyncTimestamp: string) => (latestSyncTimestamp ? {
          where: {
            or: [
              { 'createdDate': { 'gt': latestSyncTimestamp } },
              { 'updatedDate': { 'gt': latestSyncTimestamp } }
            ]
          }
        } : {}),
        dataValidateFilter: (latestSyncTimestamp: string, projectId: string) => ({ fields: ['id'] }),
        priority: 2
      }
    ];

    this.syncSharedDataConfig = this.syncDataConfig.filter(config => config.isSharedForAllProjects);
    // this.log('Shared data config:', this.syncSharedDataConfig);
    // Priority data config; data that is shared for all projects and has priority 1
    this.syncPriorityDataConfig = this.syncDataConfig.filter(config => config.priority === 1 && !config.isSharedForAllProjects);
    // this.log('Priority data config:', this.syncPriorityDataConfig);
    // Secondary data config; data that is not shared for all projects and has priority greater than 1
    this.syncSecondaryDataConfig = this.syncDataConfig.filter(config => config.priority !== 1 && !config.isSharedForAllProjects);
    // this.log('Secondary data config:', this.syncSecondaryDataConfig);
  }

  private syncRemoteData(projectId: string, syncItemsConfig: SyncDataConfig[]) {
    this.log('Syncing remote data for projectId: ', projectId);
    this.validateProject(projectId);
    if (syncItemsConfig?.length) {
      syncItemsConfig.forEach(config => {
        this.syncRemoteDataItemWithConfig(
          projectId,
          config.storeKey,
          config.actionToHydrateStore,
          config.apiFunctionToFetchData,
          config.isRemoteDataArray,
          config.orderFields,
          config.orderDirections,
          config.apiFetchFilter,
          config.dataValidateFilter,
          config.isSharedForAllProjects
        );
      });
    }
  }

  private validateProject(projectId: string) {
    if (this.syncingProjectId && projectId && this.syncingProjectId !== projectId) {
      // LOG
      this.log('-- SYNC Project ID mismatch --');
      this.log('syncingProjectId:', this.syncingProjectId);
      this.log('requested projectId:', projectId);
      console.log('Sync Project ID has changed, resetting the project related data...');
      this.log('-- -----------------');

      // reset project related data
      this.resetSyncForANewProject(projectId);
    }
  }

  private resetProjectRelatedData(projectId: string) {
    this.syncDataConfig.forEach(config => {
      // reset sync state for each store key
      if (!config.isSharedForAllProjects) {
        this.log('Resetting sync state for store key:', config.storeKey);
        this.cachedRemoteData[config.storeKey] = null;
      }
    });
  }

  private syncRemoteDataItemWithConfig(
    projectId: string,
    storeKey: StoreKeys,
    actionToHydrateStore: any,
    apiFunctionToFetchData: (params: any) => Observable<any | any[]>,
    isArray: boolean = false, // Flag to differentiate between array and object sync
    orderFields: string[] = ['createdDate'], // Default order fields for array sync
    orderDirections: Many<boolean | 'asc' | 'desc'> = ['desc'], // Default order directions for array sync
    apiFetchFilter: (latestSyncTimestamp: string, projectId?: string) => object, // filter for the api call
    dataValidateFilter: (latestSyncTimestamp: string, projectId?: string) => object, // filter for the data validation
    isSharedForAllProjects: boolean = false // Flag to differentiate between project-specific and shared data
  ) {
    this.log(`Starting synchronization for ${isArray ? 'array' : 'object'} data...`, storeKey);

    if (storeKey === this.debugSyncForStoreKey) {
      debugger;
    }

    // Retrieve the most recent cached remote data  for the given project and store key
    return this.indexDBService.getCachedRemoteData(storeKey, isSharedForAllProjects ? '_shared' : projectId).then(
      (cachedRemoteData: CachedRemoteData) => {
        if (storeKey === this.debugSyncForStoreKey) {
          debugger;
        }
        this.log('=> Syncing remote data for store key:', storeKey);
        this.log('Cached remote data:', cachedRemoteData);

        const latestSyncTimestamp = cachedRemoteData?._lastSyncTimestamp; // || '1970-01-01T00:00:00.000Z';

        // Prepare query parameters to fetch records created or updated after the last sync timestamp
        const syncParams: any = {
          id: projectId
        };

        if (apiFetchFilter) {
          syncParams.filter = apiFetchFilter(latestSyncTimestamp, projectId);
        }

        // Fetch data from the remote server based on the sync parameters
        apiFunctionToFetchData(syncParams).subscribe((remoteData: any | any[]) => {
          if (storeKey === this.debugSyncForStoreKey) {
            debugger;
          }
          if (isArray) {
            // Handle array synchronization
            const remoteArrayData = remoteData as any[];
            if (remoteArrayData?.length) {
              // ### REMOTE DATA HAS NEWER OR UPDATED ITEMS
              this.log('Fetched newer remote array data:', remoteArrayData);

              /* Merged data array from new remote items and locally cached not-new remote items (saved in indexDB) */
              let mergedData = [];

              if (cachedRemoteData?.data?.length) {
                const updatedAndCreatedItemIds = remoteArrayData.map(item => item.id);
                const filteredCachedData = cachedRemoteData.data.filter(item =>
                  !updatedAndCreatedItemIds.includes(item.id)
                );

                if (orderFields?.length && orderDirections) {
                  mergedData = orderBy([...filteredCachedData, ...remoteArrayData], orderFields, orderDirections);
                } else {
                  mergedData = [...remoteArrayData, ...filteredCachedData];
                }
              } else {
                mergedData = remoteArrayData;
              }

              if (mergedData.length) {
                // save new remove data + cached remote data (as merged data) to indexDB
                try {
                  this.indexDBService.saveRemoteData(storeKey, isSharedForAllProjects ? '_shared' : projectId, mergedData);
                } catch (error) {
                  console.log('Error saving remote data to indexDB:', error);
                }
                this.updateStates(projectId, storeKey, mergedData, UpdateStateReasons.NewData, actionToHydrateStore);
              }
            } else {
              // ### REMOTE DATA DOESNT HAVE NEWER OR UPDATED ITEMS
              this.log('No newer remote array data found. Using cached data.');
              if (cachedRemoteData?.data) {
                // if the state / NGRX state is not hydrated, hydrate it with the cached data
                if (!isEqual(this.cachedRemoteData[storeKey], cachedRemoteData?.data || [])) {
                  this.updateStates(projectId, storeKey, cachedRemoteData?.data || [], UpdateStateReasons.HydrateCachedData, actionToHydrateStore);
                }
              } else {
                this.log('No cached data found for store key AND remote data is empty:', storeKey);
                // if the store is not empty, update the store with an empty array
                if (!isEqual(this.cachedRemoteData[storeKey], remoteArrayData || [])) {
                  this.updateStates(projectId, storeKey, remoteArrayData || [], UpdateStateReasons.EmptyData, actionToHydrateStore);
                }
              }
            }

            // after syncing the data, validate the data; remove deleted data
            if (dataValidateFilter) {
              const validationParams: object = {
                filter: {
                  ...dataValidateFilter(latestSyncTimestamp, projectId)
                }
              };

              if (storeKey === this.debugSyncForStoreKey) {
                debugger;
              }

              apiFunctionToFetchData(validationParams).subscribe((validEntities: { id: string }[]) => {
                if (validEntities?.length) {
                  if (storeKey === this.debugSyncForStoreKey) {
                    debugger;
                  }
                  const validIds = validEntities.map(i => i.id);
                  if (this.cachedRemoteData[storeKey]?.length) {
                    // find if there are any items in the cached data that are not in the validIds
                    const invalidData = this.cachedRemoteData[storeKey].filter(item => !validIds.includes(item.id));
                    if (invalidData.length) {
                      console.log('*** Invalid data found:', invalidData);
                      // remove invalid data from the cached data
                      const validData = this.cachedRemoteData[storeKey].filter(item => validIds.includes(item.id));

                      /* AFTER REMOVING the invalid/deleted data, the count of cached data should be equal to the count of the valid Ids */
                      if (validData.length !== validIds.length) {
                        console.log('*** Invalid array data found after removing invalid data:', invalidData);
                        this.indexDBService.deleteCachedRemoteData(storeKey, isSharedForAllProjects ? '_shared' : projectId);
                      } else {
                        try {
                          this.indexDBService.saveRemoteData(storeKey, isSharedForAllProjects ? '_shared' : projectId, validData);
                        } catch (error) {
                          console.log('Error saving remote data to indexDB:', error);
                        }
                        this.updateStates(projectId, storeKey, validData, UpdateStateReasons.ValidateData, actionToHydrateStore);
                      }
                    }
                  }
                }
              });
            }

          } else {
            // Handle object synchronization
            const remoteObjectData = remoteData as any;
            if (remoteObjectData?.id) {
              this.log('Fetched newer remote object data:', remoteObjectData);

              try {
                this.indexDBService.saveRemoteData(storeKey, isSharedForAllProjects ? '_shared' : projectId, remoteObjectData);
              } catch (error) {
                console.log('Error saving remote data to indexDB:', error);
              }

              // if cachedRemoteData is different from remoteObjectData, update the store
              if (!isEqual(this.cachedRemoteData[storeKey], remoteObjectData)) {
                this.updateStates(projectId, storeKey, remoteObjectData, UpdateStateReasons.NewData, actionToHydrateStore);
              }

            } else {
              this.log('No remote object data found. THIS IS A BUG or AN ERROR');
            }
          }
        });
      }
    ).catch(error => {
      console.log('=> Error on Syncing remote data for store key:', storeKey, error);

      if (storeKey === this.debugSyncForStoreKey) {
        debugger;
      }

      // Prepare query parameters to fetch records created or updated after the last sync timestamp
      const syncParams: any = {
        id: projectId
      };

      if (apiFetchFilter) {
        syncParams.filter = apiFetchFilter('', projectId);
      }

      // Fetch data from the API as the cached data is not available
      apiFunctionToFetchData(syncParams).subscribe((remoteData: any | any[]) => {
        if (remoteData) {
          if (storeKey === this.debugSyncForStoreKey) {
            debugger;
          }

          // try to save new remove data to indexDB
          try {
            this.indexDBService.saveRemoteData(storeKey, isSharedForAllProjects ? '_shared' : projectId, remoteData);
          } catch (error) {
            console.log('Error saving remote data to indexDB:', error);
          }
          // update the store
          this.updateStates(projectId, storeKey, remoteData, UpdateStateReasons.NewData, actionToHydrateStore);
        }
      });
    });
  }

  private updateStates(projectId: string, storeKey: StoreKeys, data: any, updateReason: UpdateStateReasons, ngrxActionToHydrateStore?: any) {
    this.validateProject(projectId);
    if (ngrxActionToHydrateStore) {
      this.store.dispatch(ngrxActionToHydrateStore(data));
    }
    // Update the cached remote data in the service
    this.cachedRemoteData[storeKey] = data;
    this.log('Updated cached remote data:', this.cachedRemoteData);
  }

  /**
   * This function will load the data that is cachd in the indexDB and hydrate (update) the UI NGRX store with the data.
   *
   * @param {string} projectId
   * @param {StoreKeys} storeKey
   * @param {*} actionToHydrateStore
   * @param {((params: any) => Observable<any | any[]>)} apiFunctionToFetchData
   * @param {boolean} [isArray=false]
   * @param {string[]} [orderFields=['createdDate']]
   * @param {(Many<boolean | 'asc' | 'desc'>)} [orderDirections=['desc']]
   * @param {(latestSyncTimestamp: string, projectId?: string) => any} apiFetchFilter
   * @param {boolean} [isSharedForAllProjects=false]
   * @memberof SyncRemoteDataService
   */
  private hydrateCachedRemoteData(
    projectId: string,
    storeKey: StoreKeys,
    actionToHydrateStore: any,
    apiFunctionToFetchData?: (params: any) => Observable<any | any[]>,
    isArray: boolean = false, // Flag to differentiate between array and object sync
    orderFields: string[] = ['createdDate'], // Default order fields for array sync
    orderDirections: Many<boolean | 'asc' | 'desc'> = ['desc'], // Default order directions for array sync
    apiFetchFilter?: (latestSyncTimestamp: string, projectId?: string) => object, // filter for the api call
    dataValidateFilter?: (latestSyncTimestamp: string, projectId?: string) => object, // filter for the data validation
    isSharedForAllProjects: boolean = false // Flag to differentiate between project-specific and shared data
  ) {
    this.log(`Starting hydration for ${isArray ? 'array' : 'object'} data...`, storeKey);

    // Retrieve the most recent cached remote data  for the given project and store key
    this.indexDBService.getCachedRemoteData(storeKey, isSharedForAllProjects ? '_shared' : projectId).then(
      (cachedRemoteData: CachedRemoteData) => {
        this.log('Cached remote data:', cachedRemoteData);
        this.updateStates(projectId, storeKey, cachedRemoteData?.data, UpdateStateReasons.HydrateCachedData, actionToHydrateStore);
        // this.store.dispatch(actionToHydrateStore(cachedRemoteData?.data));
      }
    ).catch(error => {
      console.log('Error fetching cached data or syncing:', error);
    });
  }

  private log(...args: unknown[]) {
    if (this.displaySyncLogs) {
      console.log(`:: [Sync Log | ${this.syncingProjectId}]: `, ...args, '--');
    }
  }

}
