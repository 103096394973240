import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cancelContentButtonConfig, saveContentButtonConfig } from '../../utils/comment-display-buttons.config';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { KeyValue } from '@angular/common';
import { PersonWithRelations } from '@rappider/rappider-sdk';
import { CommentMentionService } from '../../utils/services/comment-mention.service';
import { NotificationService } from '@rappider/services';

@Component({
  selector: 'rappider-comment-content',
  templateUrl: './comment-content.component.html',
  styleUrls: ['./comment-content.component.scss']
})
export class CommentContentComponent implements OnInit {
  /* Inputs */
  @Input() isCommentEditModeActive: boolean;
  @Input() content: string; // content of the comment
  @Input() mentionData: KeyValue<string, string>[] = []; // key value data for mentions select options - person name & person mail
  @Input() assignedTo: PersonWithRelations;
  /* Outputs */
  @Output() saveComment = new EventEmitter();
  @Output() cancelComment = new EventEmitter();
  @Output() isCommentEditModeActiveChange = new EventEmitter<boolean>();

  preview: SafeHtml;
  initialCommentContent: string;

  /* Configs */
  saveContentButtonConfig = saveContentButtonConfig;
  cancelButtonConfig = cancelContentButtonConfig;

  /* mapping function for displayed mention values */
  valueWith = (data: KeyValue<string, string>): string => data.value;

  constructor(
    private sanitizer: DomSanitizer,
    private commentMentionService: CommentMentionService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.renderPreView();
    this.initialCommentContent = this.content;
  }

  renderPreView(): void {
    if (this.content) {
      const regex = this.commentMentionService.getRegExp('@');
      const previewValue = this.content.replace(
        regex,
        match => ` @<a class="mention-text" target="_blank">${this.mentionData.find(d => d.value === match.trim().substring(1))?.key}</a>`
      );
      this.preview = this.sanitizer.bypassSecurityTrustHtml(previewValue);
    }
  }

  toggleCommentEditMode() {
    this.isCommentEditModeActive = !this.isCommentEditModeActive;
    this.isCommentEditModeActiveChange.emit(this.isCommentEditModeActive);
  }

  onSaveContentButtonClick() {
    if (this.content) {
      const commentData = {
        content: this.content,
        assignedToPersonId: this.assignedTo?.id
      };
      this.toggleCommentEditMode();
      this.saveComment.emit(commentData);
    } else {
      this.notificationService.createNotification(
        'error',
        'Error',
        'Please enter a comment'
      );
    }

  }

  onCancelContentButtonClick() {
    this.content = this.initialCommentContent;
    this.renderPreView();
    this.toggleCommentEditMode();
    this.cancelComment.emit();
  }

}
