import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ProjectInterface } from '@rappider/api-sdk';
import { PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { orderBy } from 'lodash';
import { PROJECT_LIST_CONFIG_WITH_CARD, PROJECT_LIST_CONFIG } from '@rappider/shared/configs';
import { ABSTRACT_IMAGES } from '@rappider/shared/data';
import { ActionResponse, AlertTypes, ButtonComponentConfig, ButtonType, HeadingComponentConfig, HeadingType, IconType, InputGroupComponentConfig } from '@rappider/rappider-components/utils';
import { DeleteProject, FavoritePersonProject, UnFavoritePersonProject } from '../../states/projects-state/project.actions';
import { ClearActiveProject, GetActiveProject } from '../../states/active-project-state/active-project.actions';
import { Router } from '@angular/router';
import { Person, Project, ProjectWithRelations } from '@rappider/rappider-sdk';
import { Navigate } from '@rappider/shared';
import { ProjectAction } from './utils/project-action.enum';
import { ProjectFilter } from './utils/project-filter.enum';
import { ProjectFilterOptions } from './utils/project-filter-options';
import { getPersonWithProjects } from './utils/get-person-with-projects.selector';

@Component({
  selector: 'rappider-projects',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnDestroy {

  isLoading = false;
  isLoaded = false;
  subscriptions: Subscription[];
  person: Person;
  personProjects: (ProjectWithRelations & { isFavorited: boolean })[];
  filteredPersonProjects: (ProjectWithRelations & { isFavorited: boolean })[];
  projectListConfig = PROJECT_LIST_CONFIG;
  projectListWithCard = PROJECT_LIST_CONFIG_WITH_CARD;
  abstractImages = ABSTRACT_IMAGES;
  displayToolbar = false;
  displayToolbarBackButton = false;
  canCreateProjects = false;

  displayedProjects: (ProjectWithRelations & { isFavorited: boolean })[];
  selectedCategoryName: string;

  ProjectFilterOptions = ProjectFilterOptions;

  mainTitle: HeadingComponentConfig = {
    content: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_LIST.PAGE_TITLE,
    type: HeadingType.H2
  };

  addNewProjectButton: ButtonComponentConfig = {
    key: 'create-project',
    text: 'Create New',
    disabled: true,
    icon: {
      name: 'fas fa-plus',
      type: IconType.FontAwesome
    },
    type: ButtonType.Primary
  };
  activeProject: Project;
  loadingActiveProjectId: string;
  lottieConfig = {
    options: {
      autoplay: true,
      loop: true,
      path: 'https://lottie.host/63b3e56a-3b88-4037-be84-2b2fceb88b90/O98MCCVRAX.json'
    },
    height: '200px'
  };
  isStarredProjectsSelected: boolean;
  projectListAlertConfig = {
    type: AlertTypes.Warning,
    title: <HeadingComponentConfig>{
      content: 'You dont have any starred projects',
      type: HeadingType.H6
    },
    closeable: false,
    showIcon: true
  };
  projectListAlertConfigBySearch: any;

  cardListSearchText: string;

  cardSearchInputConfig: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'Search Projects'
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome
    }
  };

  constructor(
    private store: Store<any>,
    private router: Router
  ) { }

  ngOnInit() {
    this.selectedCategoryName = localStorage.getItem('selectedCategoryName');
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToPersonAndProjects(),
      this.subscribeToLoading(),
      this.subscribeToActiveProject(),
      this.subscribeToGetActiveProjectLoadingId(),
      this.subscribeToChangeFavorite()
    ];
  }

  subscribeToPersonAndProjects() {
    return this.store.select(<any>getPersonWithProjects).subscribe(getPersonWithProjects => {
      this.person = getPersonWithProjects.person;
      this.personProjects = getPersonWithProjects.projects
      this.isLoaded = getPersonWithProjects.isLoaded;

      if (this.person && this.personProjects) {
        this.canCreateProjects = this.personProjects?.filter(project =>
          project.createdById === this.person.id)?.length >= this.person?.maxProjectQuota ? false : true;
        this.addNewProjectButton = {
          ...this.addNewProjectButton,
          disabled: !this.canCreateProjects || !this.person.isVerified,
          tooltipText: this.getTooltipText()
        };

        const sortedProjects = orderBy(this.personProjects, ['createdDate'], 'desc');
        this.filteredPersonProjects = sortedProjects;
        this.displayedProjects = sortedProjects;
        this.initFilter();
      }
    })
  }

  subscribeToLoading() {
    return this.store.select(state => state.project?.loading).subscribe((loading: boolean) => {
      this.isLoading = loading;
    });
  }

  subscribeToGetActiveProjectLoadingId() {
    return this.store.select(state => state.project?.loadingActiveProjectId).subscribe((id: string) => {
      this.loadingActiveProjectId = id;
    });
  }

  subscribeToChangeFavorite() {
    return this.store.select(state => state.project?.changeFavorite).subscribe((changeFavorite: boolean) => {
      if (changeFavorite) {
        this.onProjectFilterChange();
      }
    });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
    });
  }

  getProjectListCardConfig(project: ProjectInterface, index: number) {
    return {
      image: {
        source: project.imagePlaceHolderUrl || this.abstractImages[index % this.abstractImages.length],
        customSizeSettings: {
          width: '100%',
          height: '8rem'
        },
      },
      title: {
        type: HeadingType.H2,
        content: project.name
      }
    };
  }

  /**
   * set active project
   *
   * @param {ActionResponse} actionResponse
   * @memberof ProjectListComponent
   */
  onActiveProjectSelect(project: ProjectInterface) {
    if (project.id) {
      if (this.activeProject?.id !== project.id) {
        this.store.dispatch(new GetActiveProject({ projectId: project.id, navigateToProjectDetail: true }));
      } else if (this.activeProject) {
        this.router.navigateByUrl(`${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject.id}`);
      }
    }
  }

  onDeleteProject(actionResponse: ActionResponse) {
    const projectId = actionResponse.data.id;
    if (projectId) {
      this.store.dispatch(new DeleteProject({ projectId: actionResponse.data.id }));
    }
  }

  createProject() {
    if (this.person?.isVerified) {
      this.store.dispatch(new ClearActiveProject());
      this.router.navigateByUrl(PATH_DEFINITIONS.PROJECTS.PROJECT_CREATE_PATH);
    }
  }

  onProjectAction(action, project) {
    switch (action.button.key) {
      case ProjectAction.Delete:
        this.store.dispatch(new DeleteProject({ projectId: project.id }));
        break;
      case ProjectAction.Edit:
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_EDIT_PATH}/${project.id}` }));
        break;
      case ProjectAction.Favorite:
        this.store.dispatch(new FavoritePersonProject({ projectId: project.id, isFavorited: true }));
        break;
      case ProjectAction.Unfavorite:
        this.store.dispatch(new UnFavoritePersonProject({ projectId: project.id, isFavorited: false }));
        break;
      default:
        break;
    }
  }

  initFilter() {
    if (!this.selectedCategoryName) {
      this.displayedProjects = this.filteredPersonProjects ?? [];
    } else {
      this.filterProjects();
    }
  }

  onProjectFilterChange() {
    localStorage.setItem('selectedCategoryName', this.selectedCategoryName);
    this.filterProjects();
  }

  private filterProjects() {
    switch (this.selectedCategoryName) {
      case ProjectFilter.All: {
        this.displayedProjects = this.filteredPersonProjects;
        this.isStarredProjectsSelected = false;
        break;
      }
      case ProjectFilter.Starred: {
        this.displayedProjects = this.filteredPersonProjects?.filter((filteredProject) => filteredProject.isFavorited);
        this.isStarredProjectsSelected = true;
        break;
      }
      case ProjectFilter.OwnedByMe: {
        this.displayedProjects = this.filteredPersonProjects?.filter(item => item.createdById === this.person.id);
        this.isStarredProjectsSelected = false;
        break;
      }
      case ProjectFilter.Shared: {
        this.displayedProjects = this.filteredPersonProjects?.filter(item => item.createdById !== this.person.id);
        this.isStarredProjectsSelected = false;
        break;
      }
      default: {
        break;
      }
    }
  }

  getSecondActionButton(project: Project) {
    if (project?.isFavorited) {
      return this.projectListWithCard.secondActionButton = {
        key: ProjectAction.Unfavorite,
        icon: {
          name: 'fa-solid far fa-star',
          type: IconType.FontAwesome,
          color: 'var(--primary-color)'
        },
        popconfirmTitle: 'Unfavorite Project',
        type: ButtonType.Link,
        emitWithoutPopconfirm: false
      };
    } else {
      return this.projectListWithCard.secondActionButton = {
        key: ProjectAction.Favorite,
        icon: {
          name: 'fa-light far fa-star',
          type: IconType.FontAwesome,
          color: null,
        },
        popconfirmTitle: 'Favorite Project',
        type: ButtonType.Link,
        emitWithoutPopconfirm: false
      };
    }
  }

  getTooltipText(): string {
    return !this.person?.isVerified ? 'Please verify your email address or phone number to create a new project.'
      : !this.canCreateProjects ? 'Please contact Rappider AI sales team to create more projects.'
        : null;
  }

  onActionButtonClick(action: ButtonComponentConfig) {
    if (action.key === 'create-project') {
      this.createProject();
    }
  }

  get filteredProjects() {
    if (
      this.cardListSearchText && this.cardListSearchText.trim() &&
      this.displayedProjects?.length &&
      this.projectListConfig
    ) {
      let filteredData;
      if (this.projectListConfig.multipleSearchFields && this.projectListConfig.multipleSearchFields.length > 0) {
        filteredData = this.displayedProjects.filter(item =>
          this.projectListConfig.multipleSearchFields.some(field => item[field]?.toLowerCase().includes(this.cardListSearchText.trim().toLowerCase()))
        );
      } else if (this.projectListConfig.defaultSearchField) {
        filteredData = this.displayedProjects.filter(item =>
          item[this.projectListConfig.defaultSearchField]?.toLowerCase().includes(this.cardListSearchText.trim().toLowerCase())
        );
      }
      if (!filteredData.length) {
        this.projectListAlertConfigBySearch = {
          type: AlertTypes.Info,
          title: <HeadingComponentConfig>{
            content: `No projects match your search criteria: ${this.cardListSearchText}`,
            type: HeadingType.H6
          },
          closeable: false,
          showIcon: true
        };
      } else {
        this.projectListAlertConfigBySearch = null;
      }
      return filteredData || [];
    }
    return this.displayedProjects || [];
  }
}

