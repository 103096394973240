import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModelRelationState, projectModelRelationFeatureKey } from './project-model-relation.reducer';

// Feature selector to select the state slice
export const selectProjectModelRelationState = createFeatureSelector<ProjectModelRelationState>(
  projectModelRelationFeatureKey
);

// Selector to get the 'data' property from the state
export const selectProjectModelRelations = createSelector(
  selectProjectModelRelationState,
  (state: ProjectModelRelationState) => state.data
);

// Selector to get the 'loading' property from the state
export const selectProjectModelRelationLoading = createSelector(
  selectProjectModelRelationState,
  (state: ProjectModelRelationState) => state.loading
);

// Selector to get the 'lastAction' property from the state
export const selectProjectModelRelationLastAction = createSelector(
  selectProjectModelRelationState,
  (state: ProjectModelRelationState) => state.lastAction
);

// Selector to get the 'error' property from the state
export const selectProjectModelRelationError = createSelector(
  selectProjectModelRelationState,
  (state: ProjectModelRelationState) => state.error
);

// Selector to get a specific project model relation by id
export const selectProjectModelRelationById = (id: string) => createSelector(
  selectProjectModelRelations,
  (projectModelRelations) => projectModelRelations.find(relation => relation.id === id)
);

