import { createAction, createReducer, on } from '@ngrx/store';
import { PersonWithRelations } from '@rappider/rappider-sdk';
import { StateErrorService } from 'libs/shared/src/lib/services/state-error-service/state-error-service';

import * as PersonActions from './person.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { orderBy } from 'lodash';

/* state key */
export const featureKey = 'person';

/* state interface */
export interface PersonState {
  data: PersonWithRelations[];
  isLoading: boolean;
  isLoaded: boolean;
  error: any;
  personCount: number;
  changeRoleModalVisibility: boolean;
}

/* initial values */
export const initialState: PersonState = {
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
  personCount: null,
  changeRoleModalVisibility: false
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(PersonActions.GetPeopleByAdmin, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false
  })),
  on(PersonActions.GetPeopleByAdminSuccessful, (state, action) => ({
    ...state,
    data: action.payload.people.filter(people => people.firstName || people.lastName || people.email),
    isLoading: false,
    isLoaded: true
  })),
  on(PersonActions.GetUpdatedPersonSuccessful, (state, action) => ({
    ...state,
    data: orderBy(
      [
        ...state.data.filter(person => person.id !== action.updatedPerson.id),
        action.updatedPerson
      ], 'createdDate', 'desc'),
    isLoading: false,
    isLoaded: true
  })),
  on(PersonActions.GetPersonFailure, (state, action) => ({
    ...StateErrorService.handleFailure(state, action),
    isLoaded: true
  })),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, (state) => ({
    ...initialState,
    data: state.data,
    isLoading: state.isLoading,
    isLoaded: state.isLoaded
  })),
  on(PersonActions.ChangeRolesByAdmin, (state, action) => ({
    ...state,
    isLoading: true,
    isLoaded: false
  })),
  on(PersonActions.ChangeRolesByAdminSuccessful, (state, action) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    changeRoleModalVisibility: false
  })),
  on(PersonActions.ChangeRolesByAdminFailure, (state, action) => ({
    ...StateErrorService.handleFailure(state, action),
    isLoaded: true
  })),
  on(PersonActions.ChangePersonModalVisibility, (state, action) => ({
    ...state,
    changeRoleModalVisibility: action.visibility
  })),
  on(PersonActions.GetPeopleCountSuccessful, (state, action) => ({
    ...state,
    personCount: action.count,
    isLoading: false
  })),
  on(PersonActions.GetPeopleCount, (state) => ({
    ...state,
    isLoading: true
  })),

  on(Logout, () => (initialState))
);
