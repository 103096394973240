import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Environment */
import { environment } from '../../environments/environment';

/* State */
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducer, initialState } from './app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';

/* Authentication State */

import * as fromAuthentication from 'libs/authentication/src/lib/state/authentication.reducer';

import { AuthenticationEffects } from 'libs/authentication/src/lib/state/authentication.effects';

/* ComponentDefinition State */

import * as fromComponentDefinition from 'libs/component-definition/src/lib/state/component-definition.reducer';

import { ComponentDefinitionEffects } from 'libs/component-definition/src/lib/state/component-definition.effects';
/* Categories State */
import * as fromCategory from 'libs/shared/src/lib/states/category/category.reducer';
import { CategoryEffects } from 'libs/shared/src/lib/states/category/category.effects';

/* Projects State */

import * as fromProjects from 'libs/project/src/lib/states/projects-state/project.reducer';

import { ProjectEffects } from 'libs/project/src/lib/states/projects-state/project.effects';

/* Error State */
import * as fromError from 'libs/error/src/lib/state/global-error/global-error.reducer';

/* Active Project State */

import * as fromActiveProject from 'libs/project/src/lib/states/active-project-state/active-project.reducer';

import { ActiveProjectEffects } from 'libs/project/src/lib/states/active-project-state/active-project.effects';

/* Environment Variables State */

import * as fromEnvironmentVariable from 'libs/project/src/lib/states/environment-variables-state/environment-variable.reducer';

import { EnvironmentVariableEffects } from 'libs/project/src/lib/states/environment-variables-state/environment-variable.effects';

/* Project Roles State */

import * as fromProjectRole from 'libs/project/src/lib/states/project-roles-state/project-role.reducer';

import { ProjectRoleEffects } from 'libs/project/src/lib/states/project-roles-state/project-role.effects';

/* Message Templates State */

import * as fromMessageTemplates from 'libs/project/src/lib/states/message-template-state/message-template.reducer';

import { MessageTemplateEffects } from 'libs/project/src/lib/states/message-template-state/message-template.effects';

/* Project Setting State */

import * as fromProjectSetting from 'libs/project/src/lib/states/project-settings-state/project-setting.reducer';

import { ProjectSettingEffects } from 'libs/project/src/lib/states/project-settings-state/project-setting.effects';

/* Project Setting State */

import * as fromProjectMember from 'libs/project/src/lib/states/project-member-state/project-member.reducer';

import { ProjectMemberEffects } from 'libs/project/src/lib/states/project-member-state/project-member.effects';

/* Project Version State */

import * as fromProjectVersion from 'libs/project/src/lib/states/project-versions-state/project-version.reducer';

import { ProjectVersionEffects } from 'libs/project/src/lib/states/project-versions-state/project-version.effects';

/* Project Export State */

import * as fromProjectExport from 'libs/project/src/lib/states/project-export-state/project-export.reducer';

import { ProjectExportEffects } from 'libs/project/src/lib/states/project-export-state/project-export.effects';

/* User Invitation State */

import * as fromUserInvitation from 'libs/project/src/lib/states/user-invitations-state/user-invitation.reducer';

import { UserInvitationEffects } from 'libs/project/src/lib/states/user-invitations-state/user-invitation.effects';

/* Project Theme State */

import * as fromProjectTheme from 'libs/project/src/lib/states/project-theme-state/project-theme.reducer';

import { ProjectThemeEffects } from 'libs/project/src/lib/states/project-theme-state/project-theme.effects';

/* UI Data Store State */

import * as fromUIDataStore from 'libs/project/src/lib/states/ui-data-store/ui-data-store.reducer';

import { UIDataStoreEffects } from 'libs/project/src/lib/states/ui-data-store/ui-data-store.effects';

/* UI Data Event State */

import * as fromUIDataEvent from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.reducer';

import { UIDataEventEffects } from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.effects';

/* UI Data Update Function State */

import * as fromUIDataUpdateFunction from 'libs/project/src/lib/states/ui-data-update-functions/ui-data-update-function.reducer';

import { UIDataUpdateFunctionEffects } from 'libs/project/src/lib/states/ui-data-update-functions/ui-data-update-function.effects';

/* UI Workflow Step Function State */

import * as fromUIWorkflowStepFunction from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.reducer';

import { UIWorkflowStepFunctionEffects } from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.effects';

/*  Data Schema State */

import * as fromDataSchemaStore from 'libs/project/src/lib/states/data-schema/data-schema.reducer';

import { DataSchemaEffects } from 'libs/project/src/lib/states/data-schema/data-schema.effects';

/*  Project Script State */

import * as fromProjectScript from 'libs/project/src/lib/states/project-script-state/project-script.reducer';

import { ProjectScriptEffects } from 'libs/project/src/lib/states/project-script-state/project-script.effects';

/* Page State */

import * as fromPage from 'libs/pages/src/lib/state/page-state/page.reducer';

import { PageEffects } from 'libs/pages/src/lib/state/page-state/page.effects';

/* Project Models State */

import * as fromProjectModel from 'libs/project/src/lib/states/project-model-state/project-model.reducer';

import { ProjectModelEffects } from 'libs/project/src/lib/states/project-model-state/project-model.effects';

/* Project Model Relation State */

import * as fromProjectModelRelation from 'libs/project/src/lib/states/project-model-relation-state/project-model-relation.reducer';

import { ProjectModelRelationEffects } from 'libs/project/src/lib/states/project-model-relation-state/project-model-relation.effects';

/* Layout State */

import * as fromLayout from 'libs/pages/src/lib/state/layout-state/layout.reducer';

import { LayoutEffects } from 'libs/pages/src/lib/state/layout-state/layout.effects';

/* Router State */

import * as fromRouter from 'libs/shared/src/lib/states/router/router.reducer';

import { RouterEffects } from 'libs/shared/src/lib/states/router/router.effects';

/* Metadata State */
import { MetadataEffects } from 'libs/shared/src/lib/states/metadata/metadata.effects';

/* Project Package State */

import * as fromProjectPackage from 'libs/project/src/lib/states/project-package-state/project-package.reducer';

import { ProjectPackageEffects } from 'libs/project/src/lib/states/project-package-state/project-package.effects';

/* Guidance State */

import * as fromGuidance from 'libs/guidance/src/lib/state/guidance.reducer';

import { GuidanceEffects } from 'libs/guidance/src/lib/state/guidance.effects';

/* Module State */

import * as fromModule from 'libs/module/src/lib/state/module.reducer';

import { ModuleEffects } from 'libs/module/src/lib/state/module.effects';

/* Project Requirements State */

import * as fromProjectRequirements from 'libs/project/src/lib/states/project-requirements/project-requirements.reducer';

import { ProjectRequirementsEffects } from 'libs/project/src/lib/states/project-requirements/project-requirements.effects';

/* External API State */

import * as fromOpenAPI from 'libs/project/src/lib/states/openapi-state/openapi.reducer';

import { OpenAPIEffects } from 'libs/project/src/lib/states/openapi-state/openapi.effects';

/* Container Template State */

import * as fromContainerTemplate from 'libs/content-editor/src/lib/state/container-template-state/container-template.reducer';

import { ContainerTemplateEffects } from 'libs/content-editor/src/lib/state/container-template-state/container-template.effects';

/* Project Document State */

import * as fromProjectDocument from 'libs/project/src/lib/states/project-document-state/project-document.reducer';

import { ProjectDocumentEffects } from 'libs/project/src/lib/states/project-document-state/project-document.effects';

/* Project Model Endpoint State */

import * as fromProjectModelEndpoint from 'libs/project/src/lib/states/project-model-endpoint-state/project-model-endpoint.reducer';

import { ProjectModelEndpointEffects } from 'libs/project/src/lib/states/project-model-endpoint-state/project-model-endpoint.effects';
/* Person Authority State */

import * as fromPersonAuthority from 'libs/shared/src/lib/states/person-authority/person-authority.reducer';

import { PersonAuthorityEffects } from 'libs/shared/src/lib/states/person-authority/person-authority.effects';

/* Comment State */
import * as fromComment from 'libs/comment/src/lib/state/comment/comment.reducer';
import { CommentEffects } from 'libs/comment/src/lib/state/comment/comment.effects';

/* Page Comment State */
import * as fromPageComment from 'libs/comment/src/lib/state/page-comment/page-comment.reducer';
import { PageCommentEffects } from 'libs/comment/src/lib/state/page-comment/page-comment.effects';

/* Diagram State */
import * as fromDiagramState from 'libs/diagram-editor/src/lib/state/diagram-state/diagram.reducer';
import { DiagramEffects } from 'libs/diagram-editor/src/lib/state/diagram-state/diagram.effects';

/* Content Editor State */
import * as fromContentEditor from 'libs/content-editor/src/lib/state/content-editor.reducer';
import { ContentEditorEffects } from 'libs/content-editor/src/lib/state/content-editor.effects';

/* Diagram Comment State */
import * as fromDiagramCommentState from 'libs/comment/src/lib/state/diagram-comment/diagram-comment.reducer';
import { DiagramCommentEffects } from 'libs/comment/src/lib/state/diagram-comment/diagram-comment.effects';

/* DB Diagram State */
import * as fromDBDiagramReducer from 'libs/db-diagram/src/lib/state/db-diagram.reducer';
import { DBDiagramEffects } from 'libs/db-diagram/src/lib/state/db-diagram.effects';

/* Project Model Field State */
import * as fromProjectModelFieldState from 'libs/project-model-field/src/lib/state/project-model-field.reducer';
import { ProjectModelFieldEffects } from 'libs/project-model-field/src/lib/state/project-model-field.effects';

/* Custom Function State */
import * as fromCustomFunctionState from 'libs/custom-function/src/lib/state/custom-function.reducer';
import { CustomFunctionEffects } from 'libs/custom-function/src/lib/state/custom-function.effects';

/* Workflow Event State */
import * as fromWorkflowEventState from 'libs/project/src/lib/states/workflow-event/workflow-event.reducer';
import { WorkflowEventEffects } from 'libs/project/src/lib/states/workflow-event/workflow-event.effects';

/* Workflow Step Function State */
import * as fromWorkflowStepFunctionState from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.reducer';
import { WorkflowStepFunctionEffects } from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.effects';

/* Workflow Step Function Subscribed Event State */
import * as fromWorkflowStepFunctionSubscribedEventState from 'libs/project/src/lib/states/workflow-step-function-subscribed-event-state/workflow-step-function-subscribed-event.reducer';
import { WorkflowStepFunctionSubscribedEventEffects } from 'libs/project/src/lib/states/workflow-step-function-subscribed-event-state/workflow-step-function-subscribed-event.effects';

/* Workflow Step Function Published Event On Success State */
import * as fromWorkflowStepFunctionPublishedEventOnSuccessState from 'libs/project/src/lib/states/workflow-step-function-published-event-on-success-state/workflow-step-function-published-event-on-success.reducer';
import { WorkflowStepFunctionPublishedEventOnSuccessEffects } from 'libs/project/src/lib/states/workflow-step-function-published-event-on-success-state/workflow-step-function-published-event-on-success.effects';

/* Workflow Step Function Published Event On Failure State */
import * as fromWorkflowStepFunctionPublishedEventOnFailureState from 'libs/project/src/lib/states/workflow-step-function-published-event-on-failure-state/workflow-step-function-published-event-on-failure.reducer';
import { WorkflowStepFunctionPublishedEventOnFailureEffects } from 'libs/project/src/lib/states/workflow-step-function-published-event-on-failure-state/workflow-step-function-published-event-on-failure.effects';

/* Deploy Management State */
import { DeployManagementEffects } from 'libs/project/src/lib/states/deploy-management/deploy-management.effects';
import * as fromDeployManagementState from 'libs/project/src/lib/states/deploy-management/deploy-management.reducer';

/* WF Diagram State */
import * as fromWFDiagramReducer from 'libs/workflow-diagram/src/lib/state/wf-diagram.reducer';
import { WFDiagramEffects } from 'libs/workflow-diagram/src/lib/state/wf-diagram.effects';

/* Notification State */
import * as notificationReducer from 'libs/shared/src/lib/states/notification/notification.reducer';
import { NotificationEffects } from 'libs/shared/src/lib/states/notification/notification.effects';

/* AIAssistant State */
import * as aiAssistantReducer from 'libs/ai-integration/src/lib/state/ai-assistant.reducer';
import { AIAssistantEffects } from 'libs/ai-integration/src/lib/state/ai-assistant.effects';

/* Process Info State */
import * as processInfoReducer from 'libs/shared/src/lib/states/process-info/process-info.reducer';
import { ProcessInfoEffects } from 'libs/shared/src/lib/states/process-info/process-info.effects';

/* UI Data Selector State */
import * as fromUIDataSelectorState from 'libs/ui-data-selector/src/lib/state/ui-data-selector.reducer';
import { UIDataSelectorEffects } from 'libs/ui-data-selector/src/lib/state/ui-data-selector.effects';

/* Page Variable State */
import * as fromPageVariableState from 'libs/content-editor/src/lib/state/page-variables-state/page-variables.reducer';
import { PageVariableEffects } from 'libs/content-editor/src/lib/state/page-variables-state/page-variables.effects';

/* Scope Of Work  State */
import * as fromScopeOfWorkState from 'libs/project-design/src/lib/state/scope-of-work/scope-of-work.reducer';
import { ScopeOfWorkEffects } from 'libs/project-design/src/lib/state/scope-of-work/scope-of-work.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /* app state */
    StoreModule.forRoot(
      { app: appReducer },
      { initialState: { app: initialState } }
    ),
    !environment.production ?
      StoreDevtoolsModule.instrument({
        maxAge: 20, // Retains last 150 states
        logOnly: environment.production, // Restrict extension to log-only mode
      }) : [],
    EffectsModule.forRoot([AppEffects]),

    /* error state */
    StoreModule.forFeature(
      fromError.errorFeatureKey,
      fromError.reducer,
      { initialState: fromError.initialState }
    ),

    /* authentication state */
    StoreModule.forFeature(
      fromAuthentication.authenticationFeatureKey,
      fromAuthentication.reducer,
      { initialState: fromAuthentication.initialState }
    ),
    EffectsModule.forFeature([AuthenticationEffects]),

    /* Categories State */
    StoreModule.forFeature(fromCategory.featureKey,
      fromCategory.reducer,
      { initialState: fromCategory.initialState }
    ),
    EffectsModule.forFeature([CategoryEffects]),

    /* component definition state */
    StoreModule.forFeature(
      fromComponentDefinition.componentDefinitionFeatureKey,
      fromComponentDefinition.reducer,
      { initialState: fromComponentDefinition.initialState }
    ),
    EffectsModule.forFeature([ComponentDefinitionEffects]),

    /* projects state */
    StoreModule.forFeature(
      fromProjects.projectFeatureKey,
      fromProjects.reducer,
      { initialState: fromProjects.initialState }
    ),
    EffectsModule.forFeature([ProjectEffects]),

    /* active project state */
    StoreModule.forFeature(
      fromActiveProject.activeProjectFeatureKey,
      fromActiveProject.reducer,
      { initialState: fromActiveProject.initialState }
    ),
    EffectsModule.forFeature([ActiveProjectEffects]),

    /* environment variables state */
    StoreModule.forFeature(
      fromEnvironmentVariable.environmentVariableFeatureKey,
      fromEnvironmentVariable.reducer,
      { initialState: fromEnvironmentVariable.initialState }
    ),
    EffectsModule.forFeature([EnvironmentVariableEffects]),

    /* project roles state */
    StoreModule.forFeature(
      fromProjectRole.ProjectRoleFeatureKey,
      fromProjectRole.reducer,
      { initialState: fromProjectRole.initialState }
    ),
    EffectsModule.forFeature([ProjectRoleEffects]),

    /* message templates state */
    StoreModule.forFeature(
      fromMessageTemplates.messageTemplateFeatureKey,
      fromMessageTemplates.reducer,
      { initialState: fromMessageTemplates.initialState }
    ),
    EffectsModule.forFeature([MessageTemplateEffects]),

    /* message templates state */
    StoreModule.forFeature(
      fromProjectSetting.projectSettingFeatureKey,
      fromProjectSetting.reducer,
      { initialState: fromProjectSetting.initialState }
    ),
    EffectsModule.forFeature([ProjectSettingEffects]),

    /* project members state */
    StoreModule.forFeature(
      fromProjectMember.ProjectMemberFeatureKey,
      fromProjectMember.reducer,
      { initialState: fromProjectMember.initialState }
    ),
    EffectsModule.forFeature([ProjectMemberEffects]),

    /* project versions state */
    StoreModule.forFeature(
      fromProjectVersion.projectVersionFeatureKey,
      fromProjectVersion.reducer,
      { initialState: fromProjectVersion.initialState }
    ),
    EffectsModule.forFeature([ProjectVersionEffects]),

    /* project export state */
    StoreModule.forFeature(
      fromProjectExport.ProjectExportFeatureKey,
      fromProjectExport.reducer,
      { initialState: fromProjectExport.initialState }
    ),
    EffectsModule.forFeature([ProjectExportEffects]),

    /* user invitation state */
    StoreModule.forFeature(
      fromUserInvitation.userInvitationFeatureKey,
      fromUserInvitation.reducer,
      { initialState: fromUserInvitation.initialState }
    ),
    EffectsModule.forFeature([UserInvitationEffects]),

    /* project theme state */
    StoreModule.forFeature(
      fromProjectTheme.projectThemeFeatureKey,
      fromProjectTheme.reducer,
      { initialState: fromProjectTheme.initialState }
    ),
    EffectsModule.forFeature([ProjectThemeEffects]),

    /* UIDataStore state */
    StoreModule.forFeature(
      fromUIDataStore.uiDataStoreFeatureKey,
      fromUIDataStore.reducer,
      { initialState: fromUIDataStore.initialState }
    ),
    EffectsModule.forFeature([UIDataStoreEffects]),

    /* UIDataEvent state */
    StoreModule.forFeature(
      fromUIDataEvent.uiDataEventFeatureKey,
      fromUIDataEvent.reducer,
      { initialState: fromUIDataEvent.initialState }
    ),
    EffectsModule.forFeature([UIDataEventEffects]),

    /* UI Data Update Function state */
    StoreModule.forFeature(
      fromUIDataUpdateFunction.uiDataUpdateFunctionFeatureKey,
      fromUIDataUpdateFunction.reducer,
      { initialState: fromUIDataUpdateFunction.initialState }
    ),
    EffectsModule.forFeature([UIDataUpdateFunctionEffects]),

    /* UI Workflow Step Function state */
    StoreModule.forFeature(
      fromUIWorkflowStepFunction.uiWorkflowStepFunctionFeatureKey,
      fromUIWorkflowStepFunction.reducer,
      { initialState: fromUIWorkflowStepFunction.initialState }
    ),
    EffectsModule.forFeature([UIWorkflowStepFunctionEffects]),

    /* DataSchema state */
    StoreModule.forFeature(
      fromDataSchemaStore.dataSchemaFeatureKey,
      fromDataSchemaStore.reducer,
      { initialState: fromDataSchemaStore.initialState }
    ),
    EffectsModule.forFeature([DataSchemaEffects]),

    /* Project Script state */
    StoreModule.forFeature(
      fromProjectScript.projectScriptFeatureKey,
      fromProjectScript.reducer,
      { initialState: fromProjectScript.initialState }
    ),
    EffectsModule.forFeature([ProjectScriptEffects]),

    /* page state */
    StoreModule.forFeature(
      fromPage.pageFeatureKey,
      fromPage.reducer,
      { initialState: fromPage.initialState }
    ),
    EffectsModule.forFeature([PageEffects]),

    /* layout state */
    StoreModule.forFeature(
      fromLayout.layoutFeatureKey,
      fromLayout.reducer,
      { initialState: fromLayout.initialState }
    ),
    EffectsModule.forFeature([LayoutEffects]),

    /* project package state */
    StoreModule.forFeature(
      fromProjectPackage.projectPackageFeatureKey,
      fromProjectPackage.reducer,
      { initialState: fromProjectPackage.initialState }
    ),
    EffectsModule.forFeature([ProjectPackageEffects]),

    /* project models state */
    StoreModule.forFeature(
      fromProjectModel.projectModelFeatureKey,
      fromProjectModel.projectModelReducer,
      { initialState: fromProjectModel.projectModelInitialState }
    ),
    EffectsModule.forFeature([ProjectModelEffects]),

    /* project relation models state */
    StoreModule.forFeature(
      fromProjectModelRelation.projectModelRelationFeatureKey,
      fromProjectModelRelation.projectModelRelationReducer,
      { initialState: fromProjectModelRelation.projectModelRelationInitialState }
    ),
    EffectsModule.forFeature([ProjectModelRelationEffects]),

    /* project relation models state */
    StoreModule.forFeature(
      fromGuidance.guidanceFeatureKey,
      fromGuidance.reducer,
      { initialState: fromGuidance.initialState }
    ),
    EffectsModule.forFeature([GuidanceEffects]),

    /* router state */
    StoreModule.forFeature(
      fromRouter.routerFeatureKey,
      fromRouter.reducer,
      { initialState: fromRouter.initialState }
    ),
    EffectsModule.forFeature([RouterEffects]),

    /* module state */
    StoreModule.forFeature(
      fromModule.featureKey,
      fromModule.reducer,
      { initialState: fromModule.initialState }
    ),
    EffectsModule.forFeature([ModuleEffects]),

    /* project requirements state */
    StoreModule.forFeature(
      fromProjectRequirements.featureKey,
      fromProjectRequirements.reducer,
      { initialState: fromProjectRequirements.initialState }
    ),
    EffectsModule.forFeature([ProjectRequirementsEffects]),

    /* external API state */
    StoreModule.forFeature(
      fromOpenAPI.featureKey,
      fromOpenAPI.reducer,
      { initialState: fromOpenAPI.initialState }
    ),
    EffectsModule.forFeature([OpenAPIEffects]),

    /* Container Template state */
    StoreModule.forFeature(
      fromContainerTemplate.featureKey,
      fromContainerTemplate.reducer,
      { initialState: fromContainerTemplate.initialState }
    ),
    EffectsModule.forFeature([ContainerTemplateEffects]),

    /* Project Document state */
    StoreModule.forFeature(
      fromProjectDocument.featureKey,
      fromProjectDocument.reducer,
      { initialState: fromProjectDocument.initialState }
    ),
    EffectsModule.forFeature([ProjectDocumentEffects]),

    /* Project Model Endpoint state */
    StoreModule.forFeature(
      fromProjectModelEndpoint.featureKey,
      fromProjectModelEndpoint.reducer,
      { initialState: fromProjectModelEndpoint.initialState }
    ),
    EffectsModule.forFeature([ProjectModelEndpointEffects]),

    /* Person Authority state */
    StoreModule.forFeature(
      fromPersonAuthority.featureKey,
      fromPersonAuthority.reducer,
      { initialState: fromPersonAuthority.initialState }
    ),
    EffectsModule.forFeature([PersonAuthorityEffects]),

    /* Comment State */
    StoreModule.forFeature(
      fromComment.featureKey,
      fromComment.reducer,
      { initialState: fromComment.initialState }
    ),
    EffectsModule.forFeature([CommentEffects]),

    /* Page Comment State */
    StoreModule.forFeature(
      fromPageComment.featureKey,
      fromPageComment.reducer,
      { initialState: fromPageComment.initialState }
    ),
    EffectsModule.forFeature([PageCommentEffects]),

    /* Content Editor State */
    StoreModule.forFeature(
      fromContentEditor.contentEditorFeatureKey,
      fromContentEditor.reducer,
      { initialState: fromContentEditor.initialState }
    ),
    EffectsModule.forFeature([ContentEditorEffects]),

    /* Diagram Comment State */
    StoreModule.forFeature(
      fromDiagramCommentState.featureKey,
      fromDiagramCommentState.reducer,
      { initialState: fromDiagramCommentState.initialState }
    ),
    EffectsModule.forFeature([DiagramCommentEffects]),

    /* metadata state */
    EffectsModule.forFeature([MetadataEffects]),

    /* db diagram state */
    StoreModule.forFeature(
      fromDBDiagramReducer.featureKey,
      fromDBDiagramReducer.reducer,
      { initialState: fromDBDiagramReducer.initialState }
    ),
    EffectsModule.forFeature([DBDiagramEffects]),

    /* project model field state */
    StoreModule.forFeature(
      fromProjectModelFieldState.featureKey,
      fromProjectModelFieldState.reducer,
      { initialState: fromProjectModelFieldState.initialState }
    ),
    EffectsModule.forFeature([ProjectModelFieldEffects]),

    /* Diagram state */
    StoreModule.forFeature(
      fromDiagramState.diagramFeatureKey,
      fromDiagramState.reducer,
      { initialState: fromDiagramState.initialState }
    ),
    EffectsModule.forFeature([DiagramEffects]),

    /* Custom Function state */
    StoreModule.forFeature(
      fromCustomFunctionState.featureKey,
      fromCustomFunctionState.reducer,
      { initialState: fromCustomFunctionState.initialState }
    ),
    EffectsModule.forFeature([CustomFunctionEffects]),

    /* Workflow Event state */
    StoreModule.forFeature(
      fromWorkflowEventState.featureKey,
      fromWorkflowEventState.reducer,
      { initialState: fromWorkflowEventState.initialState }
    ),
    EffectsModule.forFeature([WorkflowEventEffects]),

    /* Workflow Step Function state */
    StoreModule.forFeature(
      fromWorkflowStepFunctionState.featureKey,
      fromWorkflowStepFunctionState.reducer,
      { initialState: fromWorkflowStepFunctionState.initialState }
    ),
    EffectsModule.forFeature([WorkflowStepFunctionEffects]),

    /* Workflow Step Function Subscribed Event state */
    StoreModule.forFeature(
      fromWorkflowStepFunctionSubscribedEventState.featureKey,
      fromWorkflowStepFunctionSubscribedEventState.reducer,
      { initialState: fromWorkflowStepFunctionSubscribedEventState.initialState }
    ),
    EffectsModule.forFeature([WorkflowStepFunctionSubscribedEventEffects]),

    /* Workflow Step Function Published Event On Success state */
    StoreModule.forFeature(
      fromWorkflowStepFunctionPublishedEventOnSuccessState.featureKey,
      fromWorkflowStepFunctionPublishedEventOnSuccessState.reducer,
      { initialState: fromWorkflowStepFunctionPublishedEventOnSuccessState.initialState }
    ),
    EffectsModule.forFeature([WorkflowStepFunctionPublishedEventOnSuccessEffects]),

    /* deploy management state */
    StoreModule.forFeature(
      fromDeployManagementState.deployManagementFeatureKey,
      fromDeployManagementState.reducer,
      { initialState: fromDeployManagementState.initialState }
    ),
    EffectsModule.forFeature([DeployManagementEffects]),

    /* Workflow Step Function Published Event On Failure state */
    StoreModule.forFeature(
      fromWorkflowStepFunctionPublishedEventOnFailureState.featureKey,
      fromWorkflowStepFunctionPublishedEventOnFailureState.reducer,
      { initialState: fromWorkflowStepFunctionPublishedEventOnFailureState.initialState }
    ),
    EffectsModule.forFeature([WorkflowStepFunctionPublishedEventOnFailureEffects]),

    /* WF Diagram state */
    StoreModule.forFeature(
      fromWFDiagramReducer.featureKey,
      fromWFDiagramReducer.reducer,
      { initialState: fromWFDiagramReducer.initialState }
    ),
    EffectsModule.forFeature([WFDiagramEffects]),

    /* Notification state */
    StoreModule.forFeature(
      notificationReducer.featureKey,
      notificationReducer.reducer,
      { initialState: notificationReducer.initialState }
    ),
    EffectsModule.forFeature([NotificationEffects]),

    /* AIAssistant state */
    StoreModule.forFeature(
      aiAssistantReducer.featureKey,
      aiAssistantReducer.reducer,
      { initialState: aiAssistantReducer.initialState }
    ),
    EffectsModule.forFeature([AIAssistantEffects]),

    /* Process Info state */
    StoreModule.forFeature(
      processInfoReducer.featureKey,
      processInfoReducer.reducer,
      { initialState: processInfoReducer.initialState }
    ),
    EffectsModule.forFeature([ProcessInfoEffects]),

    /* UI Data Selector state */
    StoreModule.forFeature(
      fromUIDataSelectorState.featureKey,
      fromUIDataSelectorState.reducer,
      { initialState: fromUIDataSelectorState.initialState }
    ),
    EffectsModule.forFeature([UIDataSelectorEffects]),

    /* Page Variable state */
    StoreModule.forFeature(
      fromPageVariableState.featureKey,
      fromPageVariableState.reducer,
      { initialState: fromPageVariableState.initialState }
    ),
    EffectsModule.forFeature([PageVariableEffects]),

    /* Scope of work state */
    StoreModule.forFeature(
      fromScopeOfWorkState.featureKey,
      fromScopeOfWorkState.reducer,
      { initialState: fromScopeOfWorkState.initialState }
    ),
    EffectsModule.forFeature([ScopeOfWorkEffects]),
  ]
})
export class AppStateModule { }
