<div *ngIf="activePerson"
     class="header">
  <div class="logo"
       [ngClass]="{'menu-collapsed': isMenuCollapsed}">
    <a (click)="navigateToProjectDetailPage()">
      <img class="logo-image"
           src="assets/img/logo/rappider-logo.svg"
           alt="Rapider AI">
      <div *ngIf="!isMenuCollapsed"
           class="logo-brand-name noselect">Rapider AI</div>
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="foldMenu()">
          <i nz-icon
             [nzType]="isMenuCollapsed? 'menu-unfold':'menu-fold'"
             theme="outline"></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="foldMenu()">
          <i nz-icon
             [nzType]="isMenuCollapsed? 'menu-fold': 'menu-unfold'"
             theme="outline"></i>
        </a>
      </li>
      <!-- PROJECT NAME -->
      <li *ngIf="activeProject && showPageTitle"
          class="project-name">
        <a [routerLink]="'projects/detail/'+activeProject.id">
          <h4>
            {{activeProject?.name}}
          </h4>
        </a>
      </li>
      <!-- END OF PROJECT NAME -->
      <!-- PAGE TITLE -->
      <li *ngIf="activeProject?.name && (routePageTitle || pageTitle) && showPageTitle"
          class="page-title">
        <h5>
          <span style="font-size: larger;"> / </span> &nbsp; {{routePageTitle || pageTitle}}
        </h5>
        <!-- <rappider-heading [content]="pageTitle"
                            type="h5"></rappider-heading> -->
      </li>
      <!-- PAGE TITLE -->
    </ul>
    <ul class="nav-right">

      <!-- Content Editor Save Button -->
      <rappider-button *ngIf="activePage && !isContentTreeChangesSaved"
                       [text]="isContentTreeSaving ? 'Saving...' : 'Save Changes'"
                       [loading]="isContentTreeSaving"
                       type="primary"
                       size="extra-small"
                       (click)="onContentEditorSaveChangesButtonClick()"></rappider-button>

      <!-- Deploying Info Text -->
      <li *ngIf="deployStatus === DeployStatus.Deploying">
        <div class="wrapper">
          <div>
            <h6 class="text">
              <span>{{'Deployment in Progress' | translate}}</span>
            </h6>
          </div>
        </div>
      </li>

      <!-- Live Preview Deploy Button -->
      <li *ngIf="activeProject && pages"
          (click)="!!isPagesInReview ? pageStatusModalVisibilityChange() : onDeployButtonClick()"
          [class.disabled]="deployLoading">
        <a nz-tooltip
           [nzTooltipTitle]="deployTitle"
           nzTooltipPlacement="bottom">
          <i class="fa-regular fa-play"></i>
        </a>
        <ng-template #deployTitle>
          <span>Preview {{ activeProject.name }}</span>
        </ng-template>
      </li>

      <!-- Toggle Guidance Right Drawer -->
      <!-- <li (click)="onRightSidebarTogglerButtonClick(AppRightSidebarTab.Guidance)">
        <a>
          <i class="fa-regular fa-question"></i>
        </a>
      </li> -->

      <!-- Source Code View -->
      <li *ngIf="activeProject">
        <a nz-tooltip
           [nzTooltipTitle]="codeViewSwitchSettings?.tooltipText"
           nzTooltipPlacement="bottom"
           (click)="onCodeViewClicked()">
          <i class="fa-sharp fa-regular fa-gear-code"></i>
        </a>
      </li>

      <!-- Search -->
      <li *ngIf="activeProject"
          (click)="openAppSearch()">
        <a nz-tooltip
           nzTooltipTitle="Search (⌘ + ⇧ + f)"
           nzTooltipPlacement="bottom">
          <i class="fa-regular fa-magnifying-glass"></i>
        </a>
      </li>

      <!-- Notifications Button TODO: Needs to be implemented -->
      <li>
        <rappider-notification [unreadNotificationCount]="unreadNotificationCount"
                               [maxVisibleNotifications]="environment.maxVisibleNotifications"
                               [notificationList]="notificationList"
                               [emptyNotificationAnimation]="emptyNotificationAnimation"
                               (selectNotification)="onSelectNotification($event)"></rappider-notification>
      </li>

      <!-- User Drop Down Button -->
      <li>

        <a nz-dropdown
           [nzDropdownMenu]="profile"
           [nzTrigger]="'click'"
           [nzPlacement]="'bottomRight'">
          <i nz-icon
             nzType="user"
             theme="outline"></i>
        </a>
        <nz-dropdown-menu #profile="nzDropdownMenu">
          <ul nz-menu
              class="profile-menu">
            <li class="p-h-20 p-b-15 m-b-10 border-bottom p-t-15">
              <div class="d-flex m-r-50">
                <nz-avatar nzSize="large"
                           [nzIcon]="userProfilePictureUrl ? null: 'user'"
                           [nzSrc]="userProfilePictureUrl || null"></nz-avatar>
                <div class="m-l-10">
                  <p class="m-b-0 font-weight-semibold">
                    {{personFullName}}
                  </p>
                  <p class="m-b-0 opacity-07">
                    {{tenant?.name || ''}}
                  </p>
                </div>
              </div>
            </li>
            <li nz-menu-item
                routerLink="/profile">
              <a class="profile-menu-item">
                <i class="fa-regular fa-user"></i>&nbsp;
                {{'SHARED.PROFILE' | translate }}</a>
            </li>

            <li nz-menu-item
                *ngIf="!isSocialMediaUser"
                routerLink="/profile/change-password">
              <a class="profile-menu-item">
                <i class="fa-regular fa-key"></i>&nbsp;&nbsp;{{'Change Password' | translate}}
              </a>
            </li>

            <li nz-menu-item
                routerLink="/profile/account-list">
              <a class="profile-menu-item">
                <i class="fa-regular fa-file-user"></i>&nbsp;&nbsp;{{'Accounts' | translate}}
              </a>
            </li>

            <li *ngIf="adminDashboardVisibility"
                nz-menu-item
                routerLink="/admin-dashboard">
              <a class="profile-menu-item">
                <i class="fa-regular fa-screwdriver-wrench"></i>&nbsp;&nbsp;{{'Admin Dashboard' | translate}}
              </a>
            </li>

            <li nz-menu-divider></li>
            <li nz-menu-item
                (click)="logout()">

              <a class="profile-menu-item">
                <i class="fa-thin fa-right-from-bracket"></i>&nbsp;&nbsp;
                {{'AUTHENTICATION_MODULE.LOGOUT' | translate}}</a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>
      <ng-container *ngIf="isUserLoading">
        <nz-skeleton-element nzType="avatar"
                             [nzActive]="true"
                             nzShape="square"
                             nzSize="small"
                             style="width: 36px; "></nz-skeleton-element>
        <nz-skeleton-element nzType="input"
                             [nzActive]="true"
                             nzSize="small"
                             style="width: 120px;"></nz-skeleton-element>
      </ng-container>

      <!-- Toggle AI Assistant Right Drawer -->
      <li (click)="onRightSidebarTogglerButtonClick(AppRightSidebarTab.Chat)">
        <a>
          <i class="fa-light fa-message-bot"></i>
        </a>
      </li>
    </ul>
  </div>
</div>

<rappider-modal [(visible)]="pageStatusModalVisibility"
                [title]="pageStatusModalConfig.title"
                [footer]="pageStatusModalConfig.footer"
                (okClick)="pageStatusModalVisibilityChange()">
  <h6>We are in the process of meticulously reviewing the user interface (UI) of this project. As soon as our review is
    finalized and all pages are optimally prepared, we will immediately inform you, granting you access to preview the
    project in its entirety.</h6>
</rappider-modal>