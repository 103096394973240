<rappider-table *ngIf="transformedData; else noValue"
                [class]="animateTable ? 'animated-table' : ''"
                [config]="tableConfig"
                [data]="transformedData"></rappider-table>

<ng-template #noValue>
  <pre>{{projectModelRelations | json}}</pre>ß
  <div class="no-value">
    <nz-skeleton [nzActive]="true"
                 [nzParagraph]="false"></nz-skeleton>
  </div>
</ng-template>
