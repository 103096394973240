import { DataSchemaField, DataSchemaWithRelations } from '@rappider/rappider-sdk';
import { LastProcessedAction } from '@rappider/shared/interfaces';
import { orderBy } from 'lodash';

import * as DataSchemaActions from './data-schema.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as ProjectModelActions from 'libs/project/src/lib/states/project-model-state/project-model.actions';

export const dataSchemaFeatureKey = 'dataSchema';

export interface DataSchemaState {
  data: DataSchemaWithRelations[] | undefined;
  publicDataSchemas: DataSchemaWithRelations[] | undefined;
  projectDataSchemas: DataSchemaWithRelations[] | undefined;
  loading: boolean;
  isPublicDataSchemasLoaded: boolean;
  isProjectDataSchemasLoaded: boolean;
  isLoaded: boolean;
  error: {
    timestamp?: number;
    key?: string;
    error?: any;
  };
  lastProcessedAction: LastProcessedAction;
  isFieldsSaved: boolean;
  notCreatedFields: DataSchemaField[];
  notUpdatedFields: DataSchemaField[];
}

export const initialState: DataSchemaState = {
  data: undefined,
  publicDataSchemas: undefined,
  projectDataSchemas: undefined,
  loading: true,
  isPublicDataSchemasLoaded: false,
  isProjectDataSchemasLoaded: false,
  isLoaded: false,
  error: {},
  lastProcessedAction: {
    success: null,
    action: null,
    message: null,
    timestamp: null,
    data: null
  },
  isFieldsSaved: false,
  notCreatedFields: [],
  notUpdatedFields: []
};

export function reducer(
  state: DataSchemaState = initialState,
  action: DataSchemaActions.Actions | AuthenticationActions.Actions | ProjectModelActions.Actions
): DataSchemaState {
  switch (action.type) {

    case DataSchemaActions.ActionTypes.GetDataSchemas:
      return {
        ...state,
        loading: true,
        isLoaded: false
      };

    case DataSchemaActions.ActionTypes.GetPublicDataSchemasSuccessful: {
      console.log('action.payload.dataSchemas length', action?.payload?.publicDataSchemas?.length);
      console.log('state.data length', state?.data?.length);
      return {
        ...state,
        // merge incoming data schemas with existing data schemas, but remove duplicates by id
        publicDataSchemas: [...action.payload?.publicDataSchemas ?? []],
        isPublicDataSchemasLoaded: true,
        data: [
          ...state.projectDataSchemas ?? [],
          ...action.payload.publicDataSchemas ?? []
        ],
        loading: !(state.isPublicDataSchemasLoaded && state.isProjectDataSchemasLoaded),
        isLoaded: state.isPublicDataSchemasLoaded && state.isProjectDataSchemasLoaded,
      };
    }

    case DataSchemaActions.ActionTypes.GetProjectDataSchemasSuccessful: {
      return {
        ...state,
        // merge incoming data schemas with existing data schemas, but remove duplicates by id
        projectDataSchemas: [...action.payload?.projectDataSchemas ?? []],
        isProjectDataSchemasLoaded: true,
        data: [
          ...state.publicDataSchemas ?? [],
          ...action.payload.projectDataSchemas ?? []
        ],
        loading: !(state.isPublicDataSchemasLoaded && state.isProjectDataSchemasLoaded),
        isLoaded: state.isPublicDataSchemasLoaded && state.isProjectDataSchemasLoaded,
      };
    }

    case DataSchemaActions.ActionTypes.GetDataSchemaByIdSuccessful: {
      const filteredDataSchemas = state.data.filter(dataSchema => dataSchema.id !== action.payload.dataSchema.id);
      const updatedDataSchemas = [
        ...filteredDataSchemas,
        action.payload.dataSchema
      ];
      return {
        ...state,
        data: orderBy(updatedDataSchemas, 'name', 'asc') || [],
        loading: false,
        isLoaded: true
      };
    }

    case DataSchemaActions.ActionTypes.GetDataSchemaByIdFailure:
      return {
        ...state,
        error: {
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        },
        loading: false
      };

    case DataSchemaActions.ActionTypes.SetLoadingState:
      return {
        ...state,
        loading: action.payload.loading
      };

    case DataSchemaActions.ActionTypes.GetDataSchemasByUIDataStoreIdSuccessful: {
      const newDataSchemas = action.payload.dataSchemas.filter(newDataSchema => {
        const isDuplicate = state.data.some(existingDataSchema => existingDataSchema.id === newDataSchema.id);
        return !isDuplicate;
      });
      return {
        ...state,
        data: [
          ...state.data,
          ...newDataSchemas,
        ],
        loading: false,
      };
    }

    case DataSchemaActions.ActionTypes.GetDataSchemasByUIDataStoreIdFailure:
      return {
        ...state,
        error: {
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        },
        loading: false
      };

    case DataSchemaActions.ActionTypes.ErrorAction:
      return {
        ...state,
        error: {
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        },
        loading: false
      };

    case DataSchemaActions.ActionTypes.CreateDataSchemaSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.createdDataSchema
        ],
        loading: false
      };
    case DataSchemaActions.ActionTypes.CreateDataSchema:
      return {
        ...state,
        loading: true
      };
    case DataSchemaActions.ActionTypes.CreateDataSchemaByAdmin:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.UpdateDataSchemaSuccessful:
      // get updating data schema from state
      // eslint-disable-next-line no-case-declarations
      const updatingDataSchema = state.data.find(dataSchema => dataSchema.id === action.payload.dataSchema.id) || {};
      // merge updating data schema data with the payload in order to not lose the schema data
      // eslint-disable-next-line no-case-declarations
      const updatedDataSchema = {
        ...updatingDataSchema,
        ...action.payload.dataSchema
      };
      // remove old data schema and add the updated data schema
      return {
        ...state,
        data: [
          ...state.data.filter(dataSchema => dataSchema.id !== action.payload.dataSchema.id),
          updatedDataSchema
        ]
      };

    case DataSchemaActions.ActionTypes.CreateDataSchemaEnumDataSuccessful:
      // get data schema from state
      // eslint-disable-next-line no-case-declarations
      const creatingDataSchemaEnumData = state.data.find(dataSchema => dataSchema.id === action.payload.dataSchemaId);
      // set enum data to existing data schema
      // eslint-disable-next-line no-case-declarations
      const createdDataSchemaEnumData = {
        ...creatingDataSchemaEnumData,
        enumData: action.payload.createdEnumData
      };
      // remove old data schema and add the updated data schema
      return {
        ...state,
        data: [
          ...state.data.filter(dataSchema => dataSchema.id !== action.payload.dataSchemaId),
          createdDataSchemaEnumData
        ]
      };

    case DataSchemaActions.ActionTypes.UpdateDataSchemaEnumDataSuccessful:
      // get data schema from state
      // eslint-disable-next-line no-case-declarations
      const updatingDataSchemaEnumData = state.data.find(dataSchema => dataSchema.id === action.payload.dataSchemaId);
      // update enum data to existing data schema
      // eslint-disable-next-line no-case-declarations
      const updatedDataSchemaEnumData = {
        ...updatingDataSchemaEnumData,
        enumData: action.payload.updatedEnumData
      };
      // remove old data schema and add the updated data schema
      return {
        ...state,
        data: [
          ...state.data.filter(dataSchema => dataSchema.id !== action.payload.dataSchemaId),
          updatedDataSchemaEnumData
        ]
      };

    case DataSchemaActions.ActionTypes.ChangeLastProcessedAction:
      return {
        ...state,
        lastProcessedAction: action.payload.lastProcessedAction
      };

    case DataSchemaActions.ActionTypes.ResetLastProcessedAction:
      return {
        ...state,
        lastProcessedAction: {
          action: null,
          success: null,
          timestamp: null,
          data: null,
          message: null
        }
      };

    case DataSchemaActions.ActionTypes.BulkUpdateDataSchemaFieldUISettings:
      return {
        ...state,
        loading: true,
        isFieldsSaved: false
      };

    case DataSchemaActions.ActionTypes.BulkUpdateDataSchemaFieldUISettingsSuccessful: {
      /* find parent data schema id of updating data schema fields */
      const parentDataSchemaId = action.payload?.dataSchemaFields[0]?.parentDataSchemaId;
      /* find parent data schema from all data schemas */
      const dataSchema = state.data.find(dataSchema => dataSchema.id === parentDataSchemaId);
      /* get updating data schema field ids */
      const updatingDataSchemaFieldIds = action.payload.dataSchemaFields.map(dataSchema => dataSchema.id);
      /* replace data schema's fields with updated data schema fields */
      const updatedDataSchema = {
        ...dataSchema,
        fields: [
          ...action.payload.dataSchemaFields,
          ...(dataSchema?.fields ?? []).filter(field => !updatingDataSchemaFieldIds.includes(field.id))
        ]
      };
      /* sort data by index */
      updatedDataSchema.fields = orderBy(updatedDataSchema.fields, 'index', 'asc');

      return {
        ...state,
        data: [
          ...state.data.filter(dataSchema => dataSchema.id !== updatedDataSchema.id),
          updatedDataSchema
        ],
        loading: false,
        isFieldsSaved: true
      };
    }

    case ProjectModelActions.ActionTypes.CreateCrudPagesForProjectModelSuccessful:
      return {
        ...state,
        isFieldsSaved: false
      };

    case DataSchemaActions.ActionTypes.BulkUpdateDataSchemaFieldUISettingsFailure:
      return {
        ...state,
        loading: false,
        error: { error: action.payload.error, key: action.payload.key, timestamp: action.payload.timestamp }
      };

    case DataSchemaActions.ActionTypes.CreateUIDataSelectorEnumData:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.CreateUIDataSelectorEnumDataSuccessful:
      return {
        ...state,
        loading: false
      };

    case DataSchemaActions.ActionTypes.CreateUIDataSelectorEnumDataFailure:
      return {
        ...state,
        loading: false,
        error: { error: action.payload.error, key: action.payload.key, timestamp: action.payload.timestamp }
      };

    case DataSchemaActions.ActionTypes.UpdateUIDataSelectorEnumData:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.UpdateUIDataSelectorEnumDataSuccessful:
      return {
        ...state,
        loading: false
      };

    case DataSchemaActions.ActionTypes.UpdateUIDataSelectorEnumDataFailure:
      return {
        ...state,
        loading: false,
        error: { error: action.payload.error, key: action.payload.key, timestamp: action.payload.timestamp }
      };

    case DataSchemaActions.ActionTypes.CreateAllDataFields:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.CreateAllDataFieldsByAdmin:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.CreateAllDataFieldsSuccessful: {
      const parentDataSchema = { ...state.data.find(dataSchema => dataSchema.id === action.payload.parentDataSchemaId) };

      parentDataSchema.fields = [
        ...parentDataSchema.fields ?? [],
        ...action.payload.dataSchemaFields
      ];

      return {
        ...state,
        data: [
          parentDataSchema,
          ...state.data.filter(dataSchema => dataSchema.id !== parentDataSchema.id)
        ],
        loading: false
      };
    }

    case DataSchemaActions.ActionTypes.UpdateAllDataFields:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.UpdateAllDataFieldsByAdmin:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.UpdateAllDataFieldsSuccessful: {
      const parentDataSchema = { ...state.data.find(dataSchema => dataSchema.id === action.payload.parentDataSchemaId) };

      parentDataSchema.fields = parentDataSchema?.fields.map(field => {
        const updatedField = action.payload.updatedDataSchemaFields.find(dsField => dsField.id === field.id);
        if (updatedField) {
          return {
            ...field,
            ...updatedField.data
          };
        } else {
          return field;
        }
      });

      return {
        ...state,
        data: [
          ...state.data.filter(dataSchema => dataSchema.id !== parentDataSchema.id),
          parentDataSchema
        ],
        loading: false
      };
    }

    case DataSchemaActions.ActionTypes.DeleteAllDataFields:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.DeleteAllDataFieldsByAdmin:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.DeleteAllDataFieldsSuccessful: {
      const parentDataSchema = { ...state.data.find(dataSchema => dataSchema.id === action.payload.parentDataSchemaId) };
      const deletedDataSchemaFieldIds = action.payload.dataSchemaFieldIds;

      if (parentDataSchema.fields?.length) {
        parentDataSchema.fields = parentDataSchema.fields?.filter(dataSchemaField => !deletedDataSchemaFieldIds.includes(dataSchemaField.id));
      } else {
        parentDataSchema.fields = [];
      }

      return {
        ...state,
        data: [
          ...state.data.filter(dataSchema => dataSchema.id !== parentDataSchema.id),
          parentDataSchema
        ],
        loading: false
      };
    }

    case DataSchemaActions.ActionTypes.RemoveDataSchemaFieldByProjectModelField: {
      const deletedProjectModelField = action.payload.projectModelField;
      const dataSchemas = state.data.map(dataSchema => ({
        ...dataSchema,
        fields: dataSchema.fields?.filter(field => field.id !== deletedProjectModelField.relationedFieldId)
      }));

      return {
        ...state,
        data: dataSchemas
      };
    }

    case DataSchemaActions.ActionTypes.DeleteDataSchema:
      return {
        ...state,
        loading: true
      };

    case DataSchemaActions.ActionTypes.DeleteDataSchemaSuccessful:
      return {
        ...state,
        data: state.data.filter(dataSchema => dataSchema.id !== action.payload.dataSchemaId),
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful:
      return initialState;

    default:
      return state;
  }
}
