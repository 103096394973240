import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModelState, projectModelFeatureKey } from './project-model.reducer';

// Feature selector to select the entire project model state
export const selectProjectModelState = createFeatureSelector<ProjectModelState>(projectModelFeatureKey);

// Selector to get the project model data
export const selectProjectModels = createSelector(
  selectProjectModelState,
  (state: ProjectModelState) => state.data
);

// Selector to check if the project model data is loading
export const selectProjectModelLoading = createSelector(
  selectProjectModelState,
  (state: ProjectModelState) => state.loading
);

// Selector to get the CRUD pages loading flag
export const selectIsCrudPagesLoading = createSelector(
  selectProjectModelState,
  (state: ProjectModelState) => state.isCrudPagesLoading
);

// Selector to get the visibility of the CreateProjectModelsFromJSONModal
export const selectCreateProjectModelsFromJSONModalVisibility = createSelector(
  selectProjectModelState,
  (state: ProjectModelState) => state.createProjectModelsFromJSONModalVisibility
);

// Selector to get the list of project models currently generating a data store
export const selectGeneratingDataStoreModelIds = createSelector(
  selectProjectModelState,
  (state: ProjectModelState) => state.generatingDataStoreModelIds
);

// Selector to get the error object
export const selectProjectModelError = createSelector(
  selectProjectModelState,
  (state: ProjectModelState) => state.error
);

// Selector to get the IDs of the project models being deleted
export const selectDeletingProjectModelIds = createSelector(
  selectProjectModelState,
  (state: ProjectModelState) => state.deletingProjectModelIds
);

// Selector to check if the project models have been loaded
export const selectIsProjectModelsLoaded = createSelector(
  selectProjectModelState,
  (state: ProjectModelState) => state.isLoaded
);

// Selector to get a specific project model by ID
export const selectProjectModelById = (id: string) => createSelector(
  selectProjectModels,
  (projectModels) => projectModels.find(projectModel => projectModel.id === id)
);

// Selector to get the fields of a specific project model by ID
export const selectProjectModelFieldsById = (id: string) => createSelector(
  selectProjectModelById(id),
  (projectModel) => projectModel?.fields ?? []
);
