import { ButtonComponentConfig } from '../../../../../../components/src/lib/utils';
import { IconType } from '../../../../../../shared/src/lib/definitions';

export const DiagramSettingsDropdownConfig: ButtonComponentConfig = {
  icon: {
    name: 'plus',
    type: IconType.NgZorro
  },
  text: 'SHARED.ADD'
};
