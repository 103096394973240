import { DropdownMenuComponentConfig, DropdownMenuPlacement, DropdownType, IconType } from '@rappider/rappider-components/utils';

export const THEME_SETTING_BUTTONS_CONFIG: DropdownMenuComponentConfig = {
  icon: {
    name: 'fa light fa-ellipsis',
    type: IconType.FontAwesome
  },
  items: [
    {
      label: 'Reset Theme as Default',
      key: 'resetThemeAsDefault',
      popconfirmTitle: 'Are you sure to reset theme as detault? You will lose all your theme data.',
      icon: {
        name: 'fa-regular fa-rotate'
      }
    },
    {
      label: 'Delete Theme',
      key: 'deleteTheme',
      icon: {
        name: 'fa-regular fa-trash'
      },
      popconfirmTitle: 'Are you sure you want to delete this project theme?',
      popconfirmOkDanger: true,
      popconfirmOkText: 'Delete',
    }
  ],
  placement: DropdownMenuPlacement.BottomRight,
  dropdownType: DropdownType.Button
};
