import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ButtonComponentConfig, DropdownMenuItem, IconComponentConfig, IconType, InputGroupComponentConfig } from '@rappider/rappider-components/utils';
import { cloneDeep } from 'lodash';
import { NzFormatBeforeDropEvent, NzFormatEmitEvent, NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Observable, of } from 'rxjs';
import { CreatePageContainer, DeleteComponent, MoveContentTreeItem } from '../../../state/content-editor.actions';
import { isDragEventValid } from './utils/is-drag-event-valid.function';
import { ContentTree, ContentTreeItem, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';

@Component({
  selector: 'rappider-content-tree',
  templateUrl: './content-tree.component.html',
  styleUrls: ['./content-tree.component.scss']
})
export class ContentTreeComponent {

  @Input() contentTree: ContentTree;
  @Input() activeContentTreeItem: ContentTreeItem;

  @Output() selectedNodeChanged = new EventEmitter<ContentTreeItem>();
  @Output() containerDeleteModalVisibility = new EventEmitter<boolean>();

  selectedNodeKeys: string[] = [];
  dataSource: ContentTree;
  searchValue: string;
  inputGroupConfig: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'CONTENT_EDITOR_MODULE.CONTENT_TREE_COMPONENT.SEARCH_IN_TREE'
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome
    }
  };

  // dropdownMenuItems for Container
  actionButtonForContainer: DropdownMenuItem[] = [
    {
      label: 'Move Up',
      key: 'move-up',
    },
    {
      label: 'Move Down',
      key: 'move-down',
    },
    {
      label: 'Add Container',
      key: 'add-container',
    },
    {
      label: 'Delete',
      key: 'delete',
      popconfirmOkDanger: true,
      popconfirmTitle: 'Are you sure to delete this container'
    }
  ];

  // dropdownMenuItems for Component
  actionButtonForComponent: DropdownMenuItem[] = [
    {
      label: 'Move Up',
      key: 'move-up',
    },
    {
      label: 'Move Down',
      key: 'move-down',
    },
    {
      label: 'Delete',
      key: 'delete',
      popconfirmOkDanger: true,
      popconfirmTitle: 'Are you sure to delete this component'
    }
  ];

  dropdownIconConfig: IconComponentConfig = {
    name: 'ellipsis',
    type: IconType.NgZorro
  };

  constructor(
    private store: Store<any>,
    private cdr: ChangeDetectorRef) { }

  ngOnChanges() {
    if (this.activeContentTreeItem) {
      this.selectedNodeKeys = [this.activeContentTreeItem.id];
    }
    if (this.contentTree) {
      this.dataSource = cloneDeep(this.contentTree);
      this.buildNodes(this.dataSource[0]);
    }
  }


  /**
   * add required properties to all nodes in tree
   *
   * @param {*} node
   * @memberof ContentTreeComponent
   */
  buildNodes(node: any): void {
    node.key = node.id;
    node.isLeaf = !node?.children;
    node.expanded = true;

    if (node.type === ContentTreeItemType.Component) {
      node.title = node?.component?.title;
      node.name = node?.component?.name;
    }

    // calculateAction buttons length
    node.actionButtonsLength = this.calculateActionButtonsLength(node);

    if (node?.children?.length) {
      node.children.forEach(child => {
        this.buildNodes(child);
      });
    }
  }

  calculateActionButtonsLength(node: any): number {
    if (node.isLeaf) {
      return 3; // Leaf nodes have 3 action buttons
    } else {
      return node.isMainContainer ? 1 : 4; // Non-leaf nodes have 1 or 4 action buttons based on isMainContainer
    }
  }


  /**
   * BE wants the targetPageContainerId where the dragged node was dropped.
   * NG-ZORRO calculates dropPosition and targetNode.
   * There is 3 situation:
   * 1- dropPosition is -1, it means dragged node is moved before targetNode.
   * 2- dropPosition is 0, it means dragged node is moved inside targetNode.
   * 3- dropPosition is 1, it means dragged node is moved behind targetNode.
   *
   * this function find targetPageContainer for BE with NG-ZORRO's dragEvent
   *
   * @param {NzFormatEmitEvent} dragEvent
   * @memberof ContentTreeComponent
   */
  calculateAndDispatchDropEvent(dragEvent: NzFormatEmitEvent) {
    const dragNode = dragEvent.dragNode;
    const targetNode = dragEvent.node;
    /* position to drop (-1: before the target node, 0: inside the target node, 1: behind the target node)  */
    /* BUG: fare imleci 'leaf' olan bir düğümün üzerindeyken bırakıldığında
      beforeDrop pozisyonu 1 olarak hesaplıyor
      treeService pozisyonu 0 olarak hesaplıyor
      bu durumda dragEvent valid olmadığından aksiyon atılmıyor fakat dragEvent beforeDrop() fonksiyonundan geçiyor.
      Bu yüzden conflict oluşuyor
     */
    const dropPosition = dragNode.treeService.calcDropPosition(<DragEvent>dragEvent.event);
    /* target page container id */
    let targetPageContainer: NzTreeNodeOptions;
    /* new index for dragged node */
    let targetIndex: number;

    /* check drag event validity */
    if (isDragEventValid(targetNode, dropPosition)) {
      /*
       * BE wants the targetPageContainer where the dragged node was dropped.
       * if drop position 0 (0 is meaning dragged node is inside the target node), targetPageContainer is dragEvent.node
       *
       * in other conditions (1 and -1) targetPageContainer is parent node.
       */
      if (dropPosition === 0) {
        targetPageContainer = targetNode.origin;
        targetIndex = 0;
      } else {
        targetPageContainer = targetNode.parentNode.origin;
        targetIndex = targetNode.parentNode.children.findIndex(child => child.key === targetNode.key) + dropPosition;
      }

      /* Dispatch action */
      this.store.dispatch(new MoveContentTreeItem({
        targetContentTreeItem: <ContentTreeItem><unknown>dragNode.origin,
        targetPageContainer: <ContentTreeItem><unknown>targetPageContainer,
        targetIndex: targetIndex
      }));
    }
  }

  beforeDrop(arg: NzFormatBeforeDropEvent): Observable<boolean> {
    /* dont drop inside component && before/after main-container */
    if (isDragEventValid(arg.node, arg.pos)) {
      return of(true);
    } else {
      return of(false);
    }
  }

  onClickNode(event: NzFormatEmitEvent) {
    const node = event.node;
    if (node) {
      const selectedNode = <any>cloneDeep(node.origin);
      this.selectedNodeKeys = [selectedNode.key];
      this.selectedNodeChanged.emit(selectedNode);
    }
  }

  onDropdownMenuItemClick(item: DropdownMenuItem, node: any) {

    switch (item.key) {
      case 'move-up':
        this.moveUp(node);
        break;
      case 'move-down':
        this.moveDown(node);
        break;
      case 'add-container':
        this.createPageContainer(node);
        break;
      case 'delete':
        this.deleteNode(node);
        break;
      default:
        break;
    }
  }

  expandContainer(data: any): void {

    if (data instanceof NzTreeNode) {
      data.isExpanded = !data.isExpanded;
    } else {
      const node = data.origin;
      if (node) {
        data.isExpanded = !data.isExpanded;
      }
    }

    // Manually trigger change detection
    this.cdr.detectChanges();
  }

  moveUp(node: NzTreeNode) {
    const parentNode = node.parentNode;

    if (parentNode) {
      const index = parentNode.children.findIndex(child => child.key === node.key);

      if (index > 0) {
        // Swap nodes
        const temp = parentNode.children[index - 1];
        parentNode.children[index - 1] = node;
        parentNode.children[index] = temp;

        // Dispatch action
        this.dispatchMoveAction(node, parentNode.origin, index - 1);
      } else if (index === 0 && parentNode.parentNode) {
        // Move node to parent's parent level
        const grandParentNode = parentNode.parentNode;
        const parentIndex = grandParentNode.children.findIndex(child => child.key === parentNode.key);

        parentNode.children.splice(index, 1);
        grandParentNode.children.splice(parentIndex, 0, node);

        node.parentNode = grandParentNode;

        // Dispatch action
        this.dispatchMoveAction(node, grandParentNode.origin, parentIndex);
      }
    }
  }

  moveDown(node: NzTreeNode) {
    const parentNode = node.parentNode;

    if (parentNode) {
      const index = parentNode.children.findIndex(child => child.key === node.key);

      if (index < parentNode.children.length - 1) {
        // Swap nodes
        const temp = parentNode.children[index + 1];
        parentNode.children[index + 1] = node;
        parentNode.children[index] = temp;

        // Dispatch action
        this.dispatchMoveAction(node, parentNode.origin, index + 1);
      } else if (index === parentNode.children.length - 1 && parentNode.parentNode) {
        // Move node to parent's parent level
        const grandParentNode = parentNode.parentNode;
        const parentIndex = grandParentNode.children.findIndex(child => child.key === parentNode.key);

        parentNode.children.splice(index, 1);
        grandParentNode.children.splice(parentIndex + 1, 0, node);

        node.parentNode = grandParentNode;

        // Dispatch action
        this.dispatchMoveAction(node, grandParentNode.origin, parentIndex + 1);
      }
    }
  }

  dispatchMoveAction(dragNode: NzTreeNode, targetPageContainer: NzTreeNodeOptions, targetIndex: number) {
    this.store.dispatch(new MoveContentTreeItem({
      targetContentTreeItem: <ContentTreeItem><unknown>dragNode.origin,
      targetPageContainer: <ContentTreeItem><unknown>targetPageContainer,
      targetIndex: targetIndex
    }));
  }

  createPageContainer(node: NzTreeNode) {
    const componentId = node.key;
    if (componentId) {
      this.store.dispatch(new CreatePageContainer({ pageContainerId: componentId }));
    }
  }

  deleteNode(node: NzTreeNode) {
    if (node.origin.type === 'component') {
      const componentId = node.key;
      if (componentId) {
        this.store.dispatch(new DeleteComponent({ componentId: componentId }));
      }
    } else if (node.origin.type === 'container') {
      this.containerDeleteModalVisibility.emit(true);
    }
  }

}
