<!-- Ruler -->
<rappider-content-tree-renderer-controller *ngIf="!wrappedWithLayout"
                                           [page]="page"
                                           [editorSize]="editorSize"
                                           [(rulerVisibility)]="isRulerVisible"
                                           (selectedDeviceChange)="onSelectedDeviceChange($event)"
                                           (editorSizeChange)="onEditorSizeChange($event)"
                                           (fullScreen)="onFullScreen()"
                                           (previewModeChange)="onPreviewModeChange($event)"></rappider-content-tree-renderer-controller>
<div *ngIf="!!isRulerVisible"
     class="content-ruler"></div>
<div [class]="isLayout ? 'layout-outer-container content-tree-wrapper' : hasLayout ? 'outer-container' : 'content-tree-wrapper outer-container'"
     [style]="selectedDevice === ContentEditorDevices.DesktopFullWidth ? { 'display': 'block !important' } : {'display': 'flex !important'}">

    <div id="device-frame-container"
         [class]="selectedDevice === ContentEditorDevices.Iphone14Pro ? 'iphone-frame' : 'desktop-frame'">

        <!-- editor-container is the container of the page/rendered tree container, use this container to show a device bg-->
        <div id="editor-container"
             mwlResizable
             [style.overflow-y]="selectedDevice === ContentEditorDevices.Iphone14Pro ? 'scroll' : null"
             [style.overflow-x]="selectedDevice === ContentEditorDevices.Iphone14Pro ? 'hidden' : null"
             [enableGhostResize]="true"
             (resizeEnd)="onResizeEnd($event)">
            <div *ngIf="!wrappedWithLayout && selectedDevice === ContentEditorDevices.Responsive"
                 class="resize-handle-right"
                 mwlResizeHandle
                 [resizeEdges]="{ right: true }">
                <i class="fa-light fa-bars fa-rotate-90"></i>
            </div>
            <div *ngIf="!wrappedWithLayout && selectedDevice === ContentEditorDevices.Responsive"
                 class="resize-handle-bottom"
                 mwlResizeHandle
                 [resizeEdges]="{ bottom: true }">
                <i class="fa-light fa-bars"></i>
            </div>
            <div id="project-theme"
                 class="project-theme-wrapper"
                 [style.padding]="selectedDevice === ContentEditorDevices.Iphone14Pro ? '0' : null">
                <ng-container *ngIf="contentTree">
                    <!-- Main page container -->
                    <ng-container *ngTemplateOutlet="
                                     pageContainerTemplate;
                                     context: { contents: contentTree }">
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- TEMPLATES -->

<!-- Page container template -->
<ng-template #pageContainerTemplate
             let-contents="contents"
             let-parentContainerId="parentContainerId"
             let-elementClass="elementClass"
             let-elementStyle="elementStyle">
    <!-- Sortable wrapper to drag components -->
    <div class="sortablejs-wrapper-drag-drop-container"
         [id]="'drop_'+parentContainerId"
         [sortablejs]="contents"
         [ngStyle]="elementStyle"
        class="width-100"
         [sortablejsOptions]="sortableOptions">
        <ng-container *ngFor="let content of contents;let index = index;trackBy: trackContent;">
            <ng-container *ngIf="content.type === ContentTreeItemType.Container">
                <div [class]="setPageContainerClasses(content, content.id)"
                     [ngStyle]="setPageContainerLayoutSettings(content)"
                     [id]="content.id"
                     (click)="selectPageContainer($event, content)"
                     (blur)="onContainerFocusChange(null)"
                     tabindex="1"
                     (mouseover)="onMouseOverThePageContent($event, content.id, null, ContentTreeItemType.Container)"
                     (mouseleave)="onMouseLeaveThePageContent($event, content.id)">
                    <!-- Title bar for the page container -->
                    <rappider-content-tree-item-title-bar *ngIf="!readonly"
                                                          [isVisible]="isPageContainerTitleBarVisible(content)"
                                                          [title]="content.title"
                                                          [contentTreeItem]="content"
                                                          [isContentAtTop]="content?.isMainContainer"
                                                          [isHovered]="(hoveredPageContentId === content?.id) && !(isSelectedPageContainer(content?.id))"
                                                          [buttons]="containerTitleBarButtons"
                                                          [hasClipboardData]="hasClipboardData"
                                                          [buttonVisibilityFunction]="containerTitleBarButtonVisibilityFunction"
                                                          [contentTreeItemType]="ContentTreeItemType.Container"
                                                          [dropdownConfig]="containerTitleBarDropdownConfig"
                                                          [dropdownItemVisibilityFunction]="containerTitleBarDropdownItemVisibilityFunction"
                                                          (buttonClick)="onTitleBarButtonClick($event, content)"
                                                          (dropdownItemClick)="onDropdownItemClick($event, content)">
                    </rappider-content-tree-item-title-bar>
                    <ng-container
                                  *ngIf="content.children && content.children.length > 0; else containerWithoutChildTemplate">
                        <ng-container *ngTemplateOutlet="
                                pageContainerTemplate;
                                context: { contents: content.children, parentContainerId: content.id, elementClass: setPageContainerClasses(content, content.id), elementStyle: setPageContainerLayoutSettings(content)   }">
                        </ng-container>
                    </ng-container>
                    <ng-template #containerWithoutChildTemplate>
                        <div class="container-without-child">
                            <div class="animation">
                                <rappider-lottie-player [options]="animationOptions[index > 4 ? index - (index-4): index]"
                                                        [width]="'100%'"
                                                        [height]="'100%'"></rappider-lottie-player>
                            </div>
                            <h4>Empty Page Container</h4>
                            <h6>
                                Add new <a (click)="onClickAddElementInPageContainer($event, content)">Element</a>,
                                <a (click)="onClickAddComponentInPageContainer($event, content)">Snippet</a>
                                or <a (click)="onAddPageContainer($event ,content)">Page Container</a> Inside
                            </h6>
                        </div>
                    </ng-template>
                    <div *ngIf="!readonly && (hoveredPageContentId === content.id || hoveredParentContainerId === content.id) && content.children?.length"
                         class="container-bottom-controller">
                        <nz-button-group>
                            <button nz-button
                                    nz-tooltip="Add an element at the bottom of {{content?.title || 'this container'}}"
                                    nzSize="small"
                                    nzType="primary"
                                    (click)="onClickAddElementInPageContainer($event, content)">
                                <i class="fa-kit fa-light-puzzle-piece-simple-circle-plus"></i>
                                &nbsp;
                                Add Element
                            </button>
                            <button nz-button
                                    nzSize="small"
                                    nzType="primary"
                                    nz-tooltip="Add a snippet at the bottom of {{content?.title || 'this container'}}"
                                    (click)="onClickAddComponentInPageContainer($event, content)">
                                <i class="fa-kit fa-regular-puzzle-piece-circle-plus"></i>
                                &nbsp;
                                Add Snippet
                            </button>
                            <button nz-button
                                    nzSize="small"
                                    nzType="primary"
                                    nz-tooltip="Add a child container at the bottom of {{content?.title || 'this container'}}"
                                    (click)="onAddPageContainer($event ,content)">
                                <i class="fa-regular fa-square-dashed-circle-plus"></i>
                                &nbsp;
                                Add Container
                            </button>
                        </nz-button-group>
                    </div>
                </div>
            </ng-container>
            <!-- Components in the container -->
            <!-- COMPONENTS -->
            <ng-container *ngIf="content.type === ContentTreeItemType.Component">
                <ng-container
                              *ngTemplateOutlet="componentsInPageContainerTemplate; context: { content: content, index: index, parentContainerId: parentContainerId }">
                </ng-container>
            </ng-container>

            <!-- Outlet Container template for layout mode -->
            <ng-container *ngIf="content.type === ContentTreeItemType.Outlet">
                <ng-container *ngTemplateOutlet="outletContainerTemplate; context: { content: content }">
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

</ng-template>

<ng-template #componentsInPageContainerTemplate
             let-index="index"
             let-content="content"
             let-parentContainerId="parentContainerId">
    <div *ngIf="isSelectedComponent(content?.component?.id)"
         class="before-element-actions-container">
        <rappider-button tooltipText="Add a container before this component"
                         tooltipPlacement="top"
                         [icon]="addContainerBeforeIcon"
                         (confirm)="onAddComponentWithPosition($event, content?.component, 'before', 'container')"></rappider-button>

        <rappider-button tooltipText="Add a component before this component"
                         tooltipPlacement="top"
                         [icon]="addComponentBeforeIcon"
                         (confirm)="onAddComponentWithPosition($event, content?.component, 'before', 'component')"></rappider-button>
    </div>
    <div [class]="'component-container ' + content?.component?.cssClasses?.join(' ')"
         [ngClass]="{
          'selected-component': isSelectedComponent(content?.component?.id ?? null),
          'readonly-item': readonly
        }"
         (mouseover)="onMouseOverThePageContent($event,content?.component?.id, parentContainerId, ContentTreeItemType.Component)"
         (mouseleave)="onMouseLeaveThePageContent($event,content?.component?.id)"
         (click)="selectComponent($event, content)">
        <!-- Title bar for the component -->
        <rappider-content-tree-item-title-bar *ngIf="!readonly"
                                              [isVisible]="isPageComponentTitleBarVisible(content)"
                                              [title]="content.component?.title"
                                              [contentTreeItem]="content"
                                              [isContentAtTop]="false"
                                              [isHovered]="(hoveredPageContentId === content?.component?.id) && !(isSelectedComponent(content?.component?.id))"
                                              [buttons]="componentTitleBarButtons"
                                              [hasClipboardData]="hasClipboardData"
                                              [contentTreeItemType]="ContentTreeItemType.Component"
                                              [buttonVisibilityFunction]="componentTitleBarButtonVisibilityFunction"
                                              [dropdownConfig]="componentTitleBarDropdownConfig"
                                              [dropdownItemVisibilityFunction]="componentTitleBarDropdownItemVisibilityFunction"
                                              (buttonClick)="onTitleBarButtonClick($event, content)"
                                              (dataSubscriptionItemControllerClick)="onTitleBarDataSubscriptionItemControllerClick($event)"
                                              (dropdownItemClick)="onDropdownItemClick($event, content)">
        </rappider-content-tree-item-title-bar>
        <div *ngIf="componentHasComments(content?.id)"
             class="component-comment-icon">
            <rappider-button [icon]="commentPendingOrAllToggleIcon"
                             (click)="onClickToCommentIcon()"></rappider-button>
        </div>
        <ng-container *ngIf="content?.component?.componentDefinition?.isSystemGenerated === true">
            <rappider-system-generated-component-renderer [contentTree]="[content?.component?.componentDefinition?.contentTreeItem]"
                                                          [customInputs]="content?.component?.inputs"
                                                          [componentDefinitions]="componentDefinitions"
                                                          [systemGeneratedComponentDefinition]="content?.component?.componentDefinition"></rappider-system-generated-component-renderer>
        </ng-container>
        <ng-container *ngIf="content?.component?.componentDefinition?.isSystemGenerated !== true">
            <div #componentContainer
                 [id]="content?.component?.id"
                 [attr.content_id]="content.id">
            </div>
        </ng-container>
    </div>
    <div *ngIf="isSelectedComponent(content?.component?.id)"
         class="after-element-actions-container">
        <rappider-button tooltipText="Add a container after this component"
                         tooltipPlacement="bottom"
                         [icon]="addContainerAfterIcon"
                         (confirm)="onAddComponentWithPosition($event, content?.component, 'after', 'container')"></rappider-button>
        <rappider-button tooltipText="Add a component after this component"
                         tooltipPlacement="bottom"
                         [icon]="addComponentAfterIcon"
                         (confirm)="onAddComponentWithPosition($event, content?.component, 'after', 'component')"></rappider-button>
    </div>
</ng-template>

<!-- Outlet Container template for layout mode -->
<ng-template #outletContainerTemplate
             let-content="content">
    <!-- use ngContentRef for detect content -->
    <div #ngContentRef>
        <ng-content></ng-content>
    </div>
    <div *ngIf="!ngContentRef.children?.length"
         [ngClass]="setOutletContainerClasses(content)"
         (click)="selectPageContainer($event, content)"
         (mouseover)="onMouseOverThePageContent($event, content.id, null, ContentTreeItemType.Outlet)"
         (mouseleave)="onMouseLeaveThePageContent($event, content.id)">
        <!-- Title bar for the outlet container -->
        <rappider-content-tree-item-title-bar *ngIf="!readonly"
                                              [isVisible]="isPageContainerTitleBarVisible(content)"
                                              [title]="content.title"
                                              [contentTreeItem]="content"
                                              [isContentAtTop]="content?.isMainContainer"
                                              [buttons]="containerTitleBarButtons"
                                              [hasClipboardData]="hasClipboardData"
                                              [buttonVisibilityFunction]="containerTitleBarButtonVisibilityFunction"
                                              [contentTreeItemType]="ContentTreeItemType.Container"
                                              [dropdownConfig]="containerTitleBarDropdownConfig"
                                              [dropdownItemVisibilityFunction]="containerTitleBarDropdownItemVisibilityFunction"
                                              (buttonClick)="onTitleBarButtonClick($event, content)"
                                              (dropdownItemClick)="onDropdownItemClick($event, content)">
        </rappider-content-tree-item-title-bar>
        <div class="outlet-container-placeholder-image-wrapper">
            <img src="assets/img/content-editor/outlet-container.svg">
        </div>
        <h4>
            {{'Outlet Container' | translate}}
        </h4>
        <h6>
            {{'Outlet Container for page content' | translate}}
        </h6>
    </div>
</ng-template>
