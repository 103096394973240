import { InputGroupComponentConfig, IconType } from '@rappider/rappider-components/utils';

export const SearchBarConfig: InputGroupComponentConfig = {
  textbox: {
    placeholder: 'Search'
  },
  suffixIcon: {
    name: 'fas fa-search',
    type: IconType.FontAwesome
  }
};
