import { Validators } from '@angular/forms';
import { CrudViewFormItemType, CrudFormConfig, CrudFormTextBoxItem, CrudFormSelectItem, CrudFormTreeSelectItem, InputSize, SelectMode, CrudFormStringArrayItem, ButtonType } from '@rappider/rappider-components/utils';
import { KEBAB_CASE_REGEX, PASCAL_CASE_ONLY_LETTER_REGEX, TITLE_CASE_REGEX } from '../../definitions/regex';

export const ADD_OR_EDIT_COMPONENT_DEFINITION_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'SHARED.TITLE',
      fieldName: 'title',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Default,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(TITLE_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_TITLE_CASE_MESSAGE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.CLASS_NAME',
      fieldName: 'className',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Default,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PASCAL_CASE_ONLY_LETTER_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_PASCAL_CASE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Default,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.MODULE_NAME',
      fieldName: 'moduleName',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Default,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PASCAL_CASE_ONLY_LETTER_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_PASCAL_CASE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.SELECTOR',
      fieldName: 'selector',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Default,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(KEBAB_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_KEBAB_CASE'
        }
      ]
    },
    <CrudFormTreeSelectItem>{
      title: 'SHARED.MAIN_CATEGORY',
      fieldName: 'categoryId',
      type: CrudViewFormItemType.TreeSelect,
      size: InputSize.Default,
      defaultExpandAll: false,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'SHARED.OTHER_CATEGORIES',
      fieldName: 'subCategoryIds',
      type: CrudViewFormItemType.Select,
      size: InputSize.Default,
      settings: {
        searchable: true,
        mode: SelectMode.Multiple,
        maxTagCount: 3
      }
    },
    <CrudFormSelectItem>{
      title: 'SHARED.TAGS',
      fieldName: 'tags',
      type: CrudViewFormItemType.TagInput,
      size: InputSize.Default,
      settings: {
        searchable: true
      }
    },
    <CrudFormStringArrayItem>{
      title: 'SHARED.PREVIEW_IMG_URLS',
      fieldName: 'previewImgUrls',
      type: CrudViewFormItemType.StringArray,
    },
    <CrudFormTextBoxItem>{
      title: 'Preview Icon Url',
      fieldName: 'previewIconUrl',
      type: CrudViewFormItemType.TextBox,
    },
    <CrudFormTextBoxItem>{
      title: 'Package Name',
      fieldName: 'packageName',
      type: CrudViewFormItemType.TextBox,
    },
    <CrudFormTextBoxItem>{
      title: 'Import Path',
      fieldName: 'importPath',
      type: CrudViewFormItemType.TextBox,
    },
    {
      title: 'COMPONENT_BROWSER_MODULE.COMPONENT_DEFINITION_DETAIL_PAGE_COMPONENT.DISPLAY_IN_CATEGORY_SHOWCASE',
      fieldName: 'displayInCategoryShowcase',
      type: CrudViewFormItemType.CheckBox
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    visible: true,
    text: 'SHARED.SAVE',
    size: 'default',
    type: ButtonType.Primary
  }
};
