/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

/* angular imports */
import { createAction, props } from '@ngrx/store';
import { ClientLocalState, NewClientLocalState } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetClientLocalStates = '[ClientLocalState] GetClientLocalStates',
  GetClientLocalStatesSuccessful = '[ClientLocalState] GetClientLocalStatesSuccessful',
  GetClientLocalStatesFailure = '[ClientLocalState] GetClientLocalStatesFailure',
  CreateClientLocalState = '[ClientLocalState] CreateClientLocalState',
  CreateClientLocalStateSuccessful = '[ClientLocalState] CreateClientLocalStateSuccessful',
  CreateClientLocalStateFailure = '[ClientLocalState] CreateClientLocalStateFailure',
  UpdateClientLocalState = '[ClientLocalState] UpdateClientLocalState',
  UpdateClientLocalStateSuccessful = '[ClientLocalState] UpdateClientLocalStateSuccessful',
  UpdateClientLocalStateFailure = '[ClientLocalState] UpdateClientLocalStateFailure',
  DeleteClientLocalState = '[ClientLocalState] DeleteClientLocalState',
  DeleteClientLocalStateSuccessful = '[ClientLocalState] DeleteClientLocalStateSuccessful',
  DeleteClientLocalStateFailure = '[ClientLocalState] DeleteClientLocalStateFailure',
  ErrorAction = '[ClientLocalState] ErrorAction',
  ResetStateToInitial = '[ClientLocalState] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[ClientLocalState] ResetStateToInitialChangeProject'
}

/* actions */

export const GetClientLocalStates = createAction(ActionTypes.GetClientLocalStates);

export const GetClientLocalStatesSuccessful = createAction(
  ActionTypes.GetClientLocalStatesSuccessful,
  props<{ payload: { clientLocalStates:  ClientLocalState[] } }>()
);

export const GetClientLocalStatesFailure = createAction(ActionTypes.GetClientLocalStatesFailure);

export const CreateClientLocalState = createAction(
  ActionTypes.CreateClientLocalState,
  props<{ payload: { clientLocalState: NewClientLocalState } }>()
);

export const CreateClientLocalStateSuccessful = createAction(
  ActionTypes.CreateClientLocalStateSuccessful,
  props<{ payload: { clientLocalState: ClientLocalState } }>()
);

export const CreateClientLocalStateFailure = createAction(ActionTypes.CreateClientLocalStateFailure);

export const UpdateClientLocalState = createAction(
  ActionTypes.UpdateClientLocalState,
  props<{ payload: { clientLocalStateId: string; clientLocalState: Partial<ClientLocalState> } }>()
);

export const UpdateClientLocalStateSuccessful = createAction(
  ActionTypes.UpdateClientLocalStateSuccessful,
  props<{ payload: { clientLocalStateId: string; clientLocalState: Partial<ClientLocalState> } }>()
);

export const UpdateClientLocalStateFailure = createAction(ActionTypes.UpdateClientLocalStateFailure);

export const DeleteClientLocalState = createAction(
  ActionTypes.DeleteClientLocalState,
  props<{ payload: { clientLocalStateId: string } }>()
);

export const DeleteClientLocalStateSuccessful = createAction(
  ActionTypes.DeleteClientLocalStateSuccessful,
  props<{ payload: { clientLocalStateId: string } }>()
);

export const DeleteClientLocalStateFailure = createAction(ActionTypes.DeleteClientLocalStateFailure);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);
