import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderGoogleMapsComponent } from './google-maps.component';
import { GoogleMapsModule } from '@angular/google-maps';


@NgModule({
  declarations: [
    RappiderGoogleMapsComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule
  ],
  exports: [
    RappiderGoogleMapsComponent
  ]
})
export class RappiderGoogleMapsModule { }
