<ng-container *ngIf="mask">
  <input nz-input
         [(ngModel)]="value"
         [autocomplete]="autocomplete || 'off'"
         [mask]="mask"
         [showMaskTyped]="showMaskTyped"
         [type]="type"
         [placeholder]="placeholder || '' | translate"
         [ngClass]="cssClass"
         [ngStyle]="combinedStyles"
         [disabled]="!!disabled"
         [nzSize]="size"
         [dropSpecialCharacters]="dropSpecialCharacters"
         [style.border]="borderSettings?.border"
         [style.border-top]="borderSettings?.borderTop + ' ' + borderSettings?.borderStyle"
         [style.border-right]="borderSettings?.borderRight + ' ' + borderSettings?.borderStyle"
         [style.border-bottom]="borderSettings?.borderBottom + ' ' + borderSettings?.borderStyle"
         [style.border-left]="borderSettings?.borderLeft + ' ' + borderSettings?.borderStyle"
         [style.border-radius]="borderSettings?.borderRadius"
         [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
         [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
         [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
         [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
         [style.border-color]="borderSettings?.borderColor"
         [style.border-style]="borderSettings?.borderStyle"
         [style.width]="sizeSettings?.width"
         [style.height]="sizeSettings?.height"
         [style.min-width]="sizeSettings?.minWidth"
         [style.min-height]="sizeSettings?.minHeight"
         [style.max-width]="sizeSettings?.maxWidth"
         [style.max-height]="sizeSettings?.maxHeight"
         [style.color]="colorSettings?.color"
         [style.background-color]="colorSettings?.backgroundColor"
         [style.boxShadow]="boxShadowSettings?.boxShadow"
         [style.padding]="paddingSettings?.all"
         [style.margin]="marginSettings?.all"
         [autofocus]="autoFocus"
         [id]="elementId || ''"
         (blur)="onBlur()"
         (focus)="onFocus()"
         (keydown.enter)="onEnterClick()" />
</ng-container>
<ng-container *ngIf="!mask">
  <input *ngIf="!mentionSupported"
         nz-input
         [(ngModel)]="value"
         [autocomplete]="autocomplete || 'off'"
         [type]="type"
         [placeholder]="placeholder || '' | translate"
         [ngClass]="cssClass"
         [ngStyle]="combinedStyles"
         [disabled]="!!disabled"
         [nzSize]="size"
         [style.border]="borderSettings?.border"
         [style.border-top]="borderSettings?.borderTop"
         [style.border-right]="borderSettings?.borderRight"
         [style.border-bottom]="borderSettings?.borderBottom"
         [style.border-left]="borderSettings?.borderLeft"
         [style.border-radius]="borderSettings?.borderRadius"
         [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
         [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
         [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
         [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
         [style.border-color]="borderSettings?.borderColor"
         [style.border-style]="borderSettings?.borderStyle"
         [style.width]="sizeSettings?.width"
         [style.height]="sizeSettings?.height"
         [style.min-width]="sizeSettings?.minWidth"
         [style.min-height]="sizeSettings?.minHeight"
         [style.max-width]="sizeSettings?.maxWidth"
         [style.max-height]="sizeSettings?.maxHeight"
         [style.color]="colorSettings?.color"
         [style.background-color]="colorSettings?.backgroundColor"
         [style.boxShadow]="boxShadowSettings?.boxShadow"
         [style.padding]="paddingSettings?.all"
         [style.margin]="marginSettings?.all"
         [autofocus]="autoFocus"
         [id]="elementId || ''"
         (blur)="onBlur()"
         (keydown.enter)="onEnterClick()" />
  <nz-mention *ngIf="mentionSupported"
              [nzSuggestions]="mentionValues"
              [nzValueWith]="valueWith"
              [nzPrefix]="mentionPrefix">
    <input nz-input
           nzMentionTrigger
           [(ngModel)]="value"
           [autocomplete]="autocomplete || 'off'"
           [type]="type"
           [placeholder]="placeholder || '' | translate"
           [ngClass]="cssClass"
           [ngStyle]="combinedStyles"
           [disabled]="!!disabled"
           [nzSize]="size"
           [style.border]="borderSettings?.border"
           [style.border-top]="borderSettings?.borderTop"
           [style.border-right]="borderSettings?.borderRight"
           [style.border-bottom]="borderSettings?.borderBottom"
           [style.border-left]="borderSettings?.borderLeft"
           [style.border-radius]="borderSettings?.borderRadius"
           [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
           [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
           [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
           [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
           [style.border-color]="borderSettings?.borderColor"
           [style.border-style]="borderSettings?.borderStyle"
           [style.width]="sizeSettings?.width"
           [style.height]="sizeSettings?.height"
           [style.min-width]="sizeSettings?.minWidth"
           [style.min-height]="sizeSettings?.minHeight"
           [style.max-width]="sizeSettings?.maxWidth"
           [style.max-height]="sizeSettings?.maxHeight"
           [style.color]="colorSettings?.color"
           [style.background-color]="colorSettings?.backgroundColor"
           [style.boxShadow]="boxShadowSettings?.boxShadow"
           [style.padding]="paddingSettings?.all"
           [style.margin]="marginSettings?.all"
           [autofocus]="autoFocus"
           [id]="elementId || ''"
           (blur)="onBlur()"
           (keydown.enter)="onEnterClick()" />
  </nz-mention>
</ng-container>
