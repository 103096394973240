import { Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Cell, Graph, Node } from '@antv/x6';
import { Store, createSelector } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  CommentWithRelations,
  CustomFunctionDefinition,
  DiagramCommentWithRelations,
  NewDiagramComment,
  PersonWithRelations,
  ProjectWithRelations,
  WorkflowEventPartial,
  WorkflowEventWithRelations,
  WorkflowStepFunction,
  WorkflowStepFunctionPublishedEventOnFailure,
  WorkflowStepFunctionPublishedEventOnSuccess,
  WorkflowStepFunctionSubscribedEvent,
  WorkflowStepFunctionWithRelations
} from '@rappider/rappider-sdk';
import { isEveryDataFetched } from '../../utils/workflow-function-event.selector';
import dagre from 'dagre';
import * as WorkflowEventActions from 'libs/project/src/lib/states/workflow-event/workflow-event.actions';
import * as WorkflowStepFunctionActions from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.actions';
import { DataTransformService, NotificationService } from '@rappider/services';
import { v4 } from 'uuid';
import { HTML } from '@antv/x6/lib/shape';
import { AlertConfig, AlertTypes, ButtonComponentConfig, FormLayout, HeadingComponentConfig, HeadingType, IconComponentConfig, IconType } from '@rappider/rappider-components/utils';
import { trigger, transition, style, animate } from '@angular/animations';
import { EventMode } from 'libs/workflow-event/src/lib/utils/event-mode.enum';
import { StepFunctionMode } from 'libs/workflow-event/src/lib/utils/step-function-mode.enum';
import { WorkflowEvent } from '@rappider/api-sdk';
import { UpdateFormPanelVisibility } from '../../state/wf-diagram.actions';
import { WorkflowStepFunctionDataTransformationComponentMode } from 'libs/workflow-step-function/src/lib/models/workflow-step-function-data-transformation-component-mode.enum';
import { GetPostDataTransformationData, GetPreDataTransformationData } from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.actions';
import { PreDataTransformationData, PostDataTransformationData } from '@rappider/shared/interfaces';
import { WorkflowFunctionTypeOptions } from 'libs/workflow-step-function/src/lib/components/workflow-step-function-form/utils/workflow-functions-options.enum';
import { getWorkflowFunctionsAndTemplates } from 'libs/custom-function/src/lib/components/custom-function-crud-modal-wrapper/utils/get-custom-functions-and-templates-selector';
import { CommentCategory } from 'libs/comment/src/lib/utils/comment-category.enum';
import { CreateDiagramComment, DeleteDiagramComment, UpdateDiagramComment } from 'libs/comment/src/lib/state/diagram-comment/diagram-comment.actions';

import { register } from '@antv/x6-angular-shape';
import { GraphService } from '../../utils/graph.service';

@Component({
  selector: 'rappider-diagram-wrapper',
  templateUrl: './diagram-wrapper.component.html',
  styleUrls: ['./diagram-wrapper.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)', opacity: 0 }),
            animate('150ms', style({ transform: 'translateX(0)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ transform: 'translateX(0)', opacity: 1 }),
            animate('150ms', style({ transform: 'translateX(100%)', opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class DiagramWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('commentsTemplate', { static: true }) commentsTemplate: TemplateRef<void>;

  subscriptions!: Subscription[];
  workflowEvents!: WorkflowEventWithRelations[];
  workflowStepFunctions!: WorkflowStepFunctionWithRelations[];
  graph!: Graph;
  isEveryDataFetched!: boolean;
  subscribedEvents!: WorkflowStepFunctionSubscribedEvent[];
  publishedEventsOnSuccess!: WorkflowStepFunctionPublishedEventOnSuccess[];
  publishedEventsOnFailure!: WorkflowStepFunctionPublishedEventOnFailure[];
  isDiagramBuilt = false;
  xStart = 100;
  yStart = 25;
  starterEventIds!: string[];
  lineHeight = 150;
  columnWidth = 100;
  columnMargin = 70;
  finalLineNumber = 0;
  menuVisibility = false;
  placeholderEventData?: { id: string; stepFunctionId: string; tempId: string; tempEdgeId: string; type: 'success' | 'failure' | 'subscribe' };
  placeholderStepFunctionData?: { id: string; tempId: string; tempEdgeId: string };
  isAddEventMenuVisible = false;
  isAddStepFunctionMenuVisible = false;
  lastResetRequestId!: string;
  lastEnteredNodeId!: string;
  initialStepFunctionData!: Record<string, unknown>;
  formLayout = FormLayout.Vertical;
  eventMode: EventMode;
  stepFunctionMode: StepFunctionMode;
  editingWorkflowEvent: WorkflowEvent;
  editingWorkflowStepFunction: WorkflowStepFunction;
  StepFunctionMode = StepFunctionMode;
  eventFormSubmitButtonLoading: boolean;
  stepFunctionFormSubmitButtonLoading: boolean;
  zoomButtonVisibility = false;
  customFunctions: CustomFunctionDefinition[];
  workflowFunctions: CustomFunctionDefinition[];
  templateWorkflowFunctions: CustomFunctionDefinition[];
  selectedFunctionMode: string;
  allDiagramNodes: Node[];

  activeProject: ProjectWithRelations;
  activePerson: PersonWithRelations;
  displayedComments: CommentWithRelations[];
  comments: CommentWithRelations[];
  isCommentsLoading: boolean;
  isCommentsNodeVisible: boolean;

  dataTransformationModalVisibility = false;
  isDataTransformationLoading = false;
  dataTransformationId: string;
  sourceSchema: any;
  targetSchema: any;
  DataTransformationComponentMode = WorkflowStepFunctionDataTransformationComponentMode;

  /**
   * active cell that current comment belongs
   *
   * @type {Cell}
   * @memberof DiagramWrapperComponent
   */
  activeCommentCell: Cell;
  /**
   * active node that current comment belongs
   *
   * @type {Node}
   * @memberof DiagramWrapperComponent
   */
  activeCommentNode: Node;

  workflowStepFunctionFormHeading: HeadingComponentConfig = {
    content: 'Workflow Step Function',
    type: HeadingType.H4
  };

  closeIcon: IconComponentConfig = {
    name: 'fa-solid fa-xmark',
    color: 'var(--text-color)'
  };

  preDTButtonConfig: ButtonComponentConfig = {
    text: 'DATA_TRANSFORMATION_MODULE.PRE_DATA_TRANSFORMATION',
    icon: {
      name: 'fal fa-database',
      type: IconType.FontAwesome
    },
    block: true
  };

  postDTButtonConfig: ButtonComponentConfig = {
    text: 'DATA_TRANSFORMATION_MODULE.POST_DATA_TRANSFORMATION',
    icon: {
      name: 'fad fa-database',
      type: IconType.FontAwesome
    },
    block: true
  };

  infoAlertConfig: AlertConfig = {
    type: AlertTypes.Info,
    title: {
      content: 'No Data',
      type: HeadingType.H5
    },
    showIcon: false,
    closeable: false
  };

  commands = [
    {
      key: '%50',
      label: '%50',
    },
    {
      key: '%75',
      label: '%75',
    },
    {
      key: '%100',
      label: '%100',
    },
    {
      key: '%150',
      label: '%150',
    },
    {
      key: '%200',
      label: '%200',
    },
    {
      key: 'zoomToFit',
      label: 'Fit to Screen',
    },
    {
      key: 'zoomIn',
      label: 'Zoom In (0.2)',
    },
    {
      key: 'zoomOut',
      label: 'Zoom Out (-0.2)',
    }
  ];

  constructor(
    private store: Store<any>,
    private dataTransformService: DataTransformService,
    private notificationService: NotificationService,
    private injector: Injector,
    private graphService: GraphService
  ) { }

  ngOnInit(): void {
    this.initializeDiagram();
    this.listenEvents();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActivePerson(),
      this.subscribeToWorkflowEvents(),
      this.subscribeToWorkflowFunctionsAndTemplates(),
      this.subscribeToWorkflowStepFunctions(),
      this.subscribeToWorkflowStepFunctionSubscribedEvents(),
      this.subscribeToWorkflowStepFunctionPublishedEventsOnSuccess(),
      this.subscribeToWorkflowStepFunctionPublishedEventsOnFailure(),
      this.subscribeToLastCreatedPlaceholderId(),
      this.subscribeToWorkflowFetchStatus(),
      this.subscribeToMenuVisibility(),
      this.subscribeToEventFormSubmitLoading(),
      this.subscribeToStepFunctionFormSubmitLoading(),
      this.subscribeToDataTransformationLoading(),
      this.subscribeToPreDataTransformation(),
      this.subscribeToPostDataTransformation(),
      this.subscribeToActiveProject(),
      this.subscribeToCustomFunction(),
      this.subscribeToWFDiagramComments(),
      this.subscribeToCommentsLoading()
    ];
  }

  // #region Diagram Initialization

  initializeDiagram() {
    const container = document.getElementById('diagram-container');
    if (container) {
      this.graph = new Graph({
        container: container,
        connecting: {
          router: 'manhattan',
        },
        grid: true,
        panning: true,
        mousewheel: true
      });
      if (this.graph) {
        this.graphService.registerCustomTools();
        this.graphService.registerWorkflowEvent();
        this.graphService.registerWorkflowStepFunction();
        this.graphService.registerEdge();
      }
    }
  }
  // #endregion Diagram Initialization

  // #region Diagram Functions
  buildDiagram() {
    this.findStarterEvents();
    const subscribedEvents = this.subscribedEvents;
    this.starterEventIds?.sort(function compare(firstEventId, secondEventId) {
      const isFirstEventHasSubscribedStepFunction = subscribedEvents.some(item => item.workflowEventId === firstEventId);
      const isSecondEventHasSubscribedStepFunction = subscribedEvents.some(item => item.workflowEventId === secondEventId);
      if (isFirstEventHasSubscribedStepFunction && !isSecondEventHasSubscribedStepFunction) {
        return -1;
      }
      if (!isFirstEventHasSubscribedStepFunction && isSecondEventHasSubscribedStepFunction) {
        return 1;
      }
      return 0;
    });

    this.addWorkflowEvents(this.starterEventIds);

    this.publishedEventsOnSuccess?.forEach(relation => this.renderPublishedEdge(relation, 'success'));
    this.publishedEventsOnFailure?.forEach(relation => this.renderPublishedEdge(relation, 'failure'));
    this.subscribedEvents?.forEach(relation => this.renderSubscribedEdge(relation));
    this.resetCells();
    this.isDiagramBuilt = true;
    this.allDiagramNodes = this.graph.getNodes();
    // add comment button to nodes that already have comments
    this.showCommentButtonOnNodeWithComments(this.workflowEvents, this.allDiagramNodes);
    this.showCommentButtonOnNodeWithComments(this.workflowStepFunctions, this.allDiagramNodes);
  }

  showCommentButtonOnNodeWithComments(workflowItems, nodes) {
    workflowItems?.forEach(workflowItem => {
      if (workflowItem?.comments?.length) {
        nodes?.forEach(node => {
          if (node.id === workflowItem.id) {
            this.addCommentButtonToActiveEntitylNode(node);
          }
        });
      }
    });
  }

  resetCells() {
    const lastResetRequestId = v4();
    setTimeout(() => {
      if (lastResetRequestId === this.lastResetRequestId) {
        this.graph.resetCells([...this.graph.getNodes(), ...this.graph.getEdges()]);
        this.layout();
      }
    }, 0);
    this.lastResetRequestId = lastResetRequestId;
  }

  findStarterEvents() {
    this.starterEventIds = this.workflowEvents?.reduce((acc, value) => {
      if ([...this.publishedEventsOnFailure, ...this.publishedEventsOnSuccess].every(item => item.workflowEventId !== value.id)) {
        acc.push(value.id as string);
      }
      return acc;
    }, [] as string[]);
  }

  addWorkflowEvents(eventIds: string[]) {
    if (eventIds?.length) {
      eventIds.forEach(id => {
        if (this.getPublishedEvents()?.some(item => item.workflowEventId === id)) {
          this.buildNodeFromEventId(id, 'published');
        } else {
          this.buildNodeFromEventId(id);
        }
      });
    }
  }

  buildNodeFromEventId(eventId: string, type: 'subscribed' | 'published' = 'subscribed', publisherStepFunctionIds?: string[]) {
    const event = this.workflowEvents.find(event => event.id === eventId);
    if (event) {
      const listeningStepFunctionIDs = this.subscribedEvents.filter(item => item.workflowEventId === eventId)?.map(item => item.workflowStepFunctionId);
      const sharedEvents = type === 'subscribed'
        ? this.subscribedEvents?.filter(item => listeningStepFunctionIDs.includes(item.workflowStepFunctionId) && item.workflowEventId !== eventId)
        : this.getPublishedEvents()?.filter((item, index) => publisherStepFunctionIds?.includes(item.workflowStepFunctionId as string) && item.workflowEventId !== eventId);
      const eventCount = 1 + (sharedEvents?.length ?? 0);
      if (eventCount <= 1) {
        const isSuccess = this.publishedEventsOnSuccess.some(item => item.workflowEventId === eventId);
        this.graph.addNode(
          {
            shape: type === 'subscribed' ? 'workflowEvent' : (isSuccess ? 'workflowEventSuccess' : 'workflowEventFailure'),
            id: event.id,
            label: event.name,
            size: {
              width: 50,
              height: 50
            }
          }
        );
      } else {
        const totalEvents = [...sharedEvents.map(item => item.workflowEventId), eventId];
        const heightPerEvent = this.lineHeight / totalEvents.length;
        const eventMargin = heightPerEvent - 50;
        totalEvents.forEach((eventId, index) => {
          const renderEvent = this.workflowEvents.find(event => event.id === eventId);
          if (renderEvent) {
            const isSuccess = this.publishedEventsOnSuccess.some(item => item.workflowEventId === renderEvent.id);
            this.graph.addNode(
              {
                shape: type === 'subscribed' ? 'workflowEvent' : (isSuccess ? 'workflowEventSuccess' : 'workflowEventFailure'),
                id: renderEvent.id,
                label: renderEvent.name,
                size: {
                  width: 50,
                  height: 50
                }
              }
            );
          }
        });
      }
      if (listeningStepFunctionIDs?.length) {
        this.buildNodeFromStepFunction(listeningStepFunctionIDs as string[]);
      }
    }
  }

  buildNodeFromStepFunction(stepFunctionIds: string[]) {
    const stepFunctions = this.workflowStepFunctions.filter(stepFunction => stepFunctionIds.includes(stepFunction.id));
    if (stepFunctions?.length) {
      stepFunctions.forEach((stepFunction, index) => {
        this.graph.addNode(
          {
            shape: 'workflowStepFunction',
            id: stepFunction.id,
            width: 150,
            height: 75,
            attrs: {
              label: {
                text: stepFunction.name,
                refY: '100%',
                refY2: 13,
                textAnchor: 'middle',
                textVerticalAnchor: 'top',
              },
            },
          });
        const publishedEventIds = this.getPublishedEvents()?.filter(item => stepFunctionIds.includes(item.workflowStepFunctionId as string)).map(item => item.workflowEventId);
        if (publishedEventIds?.length) {
          publishedEventIds.forEach(id => this.buildNodeFromEventId(id as string, 'published', stepFunctionIds));
        }
      });
    }
  }
  // #endregion Diagram Functions

  // #region Subscriptions

  subscribeToCustomFunction() {
    return this.store.select(state => state.customFunction?.data).subscribe((customFunctions: CustomFunctionDefinition[]) => {
      if (customFunctions) {
        this.customFunctions = customFunctions;
      }
    });
  }

  subscribeToWorkflowFetchStatus() {
    return this.store.select(<any>isEveryDataFetched).subscribe((isEveryDataFetched) => {
      if (isEveryDataFetched && !this.isDiagramBuilt) {
        this.buildDiagram();
      }
      this.isEveryDataFetched = isEveryDataFetched;
    });
  }

  subscribeToWorkflowEvents() {
    return this.store.select(createSelector(
      state => <WorkflowEventWithRelations[]>state.workflowEvent.data,
      state => <any[]>state.diagramComment.data,
      (
        workflowEvents: WorkflowEventWithRelations[],
        nodeComments: any[]
      ) => ({
        workflowEvents: workflowEvents,
        nodeComments: nodeComments
      })
    )).subscribe(data => {
      if (data.workflowEvents && this.isDiagramBuilt) {
        const changes = this.dataTransformService.compareArrays(this.workflowEvents, data.workflowEvents, 'id', true);
        this.workflowEvents = data.workflowEvents?.map(workflowEvent => ({
          ...workflowEvent,
          comments: data.nodeComments?.filter(comment => comment?.relatedEntityId === workflowEvent.id) || []
        }));
        if (changes?.createdArray?.length) {
          this.addWorkflowEvents(changes.createdArray.map(item => item.id));
          this.closeRightBar();
          this.layout();
        } else if (changes?.updatedArray?.length) {
          this.updateNode(changes.updatedArray.map(i => i.item));
        }
      } else {
        this.workflowEvents = data.workflowEvents?.map(workflowEvent => ({
          ...workflowEvent,
          comments: data.nodeComments?.filter(comment => comment?.relatedEntityId === workflowEvent.id) || []
        }));
      }
    });
  }

  subscribeToWorkflowFunctionsAndTemplates() {
    return this.store.select(<any>getWorkflowFunctionsAndTemplates).subscribe(data => {
      if (data) {
        this.workflowFunctions = data.workflowFunctions;
        this.templateWorkflowFunctions = data.templateWorkflowFunctions;
      }
    });
  }

  subscribeToWorkflowStepFunctions() {
    return this.store.select(createSelector(
      state => <WorkflowStepFunctionWithRelations[]>state.workflowStepFunction.data,
      state => <any[]>state.diagramComment.data,
      (
        workflowStepFunctions: WorkflowStepFunctionWithRelations[],
        nodeComments: any[]
      ) => ({
        workflowStepFunctions: workflowStepFunctions,
        nodeComments: nodeComments
      })
    )).subscribe(data => {
      if (data.workflowStepFunctions && this.isDiagramBuilt) {
        const changes = this.dataTransformService.compareArrays(this.workflowStepFunctions, data.workflowStepFunctions, 'id', true);
        this.workflowStepFunctions = data.workflowStepFunctions?.map(workflowStepFunction => ({
          ...workflowStepFunction,
          comments: data.nodeComments?.filter(comment => comment?.relatedEntityId === workflowStepFunction.id) || []
        }));
        if (changes?.createdArray?.length) {
          this.buildNodeFromStepFunction(changes.createdArray.map(item => item.id));
          this.closeRightBar();
          this.layout();
        } else if (changes?.updatedArray?.length) {
          this.updateNode(changes.updatedArray.map(updatedItem => updatedItem.item));
          this.layout();
        }
      } else {
        this.workflowStepFunctions = data.workflowStepFunctions?.map(workflowStepFunction => ({
          ...workflowStepFunction,
          comments: data.nodeComments?.filter(comment => comment?.relatedEntityId === workflowStepFunction.id) || []
        }));
      }
    });
  }

  updateNode(updatedArray) {
    const nodes = this.graph.getNodes();
    updatedArray?.forEach(updatedItem => {
      const node: any = nodes.find(node => node.id === updatedItem.id);
      node.label = updatedItem?.name;
    });
  }

  subscribeToWorkflowStepFunctionSubscribedEvents() {
    return this.store.select(state => state.workflowStepFunctionSubscribedEvent.data).subscribe(workflowStepFunctionSubscribedEvents => {
      if (workflowStepFunctionSubscribedEvents && this.isDiagramBuilt) {
        const changes = this.dataTransformService.compareArrays(this.subscribedEvents, workflowStepFunctionSubscribedEvents);
        this.subscribedEvents = workflowStepFunctionSubscribedEvents;
        if (changes.createdArray?.length && this.isDiagramBuilt) {
          changes.createdArray.forEach(item => this.renderSubscribedEdge(item));
          this.layout();
        } else if (changes?.deletedArray?.length) {
          changes.deletedArray.forEach((item: any) => this.graph.removeEdge(item.id));
          this.layout();
        }
      } else {
        this.subscribedEvents = workflowStepFunctionSubscribedEvents;
      }
    });
  }

  subscribeToWorkflowStepFunctionPublishedEventsOnSuccess() {
    return this.store.select(state => state.workflowStepFunctionPublishedEventOnSuccess.data).subscribe(publishedOnSuccess => {
      const changes = this.dataTransformService.compareArrays(this.publishedEventsOnSuccess, publishedOnSuccess);
      this.publishedEventsOnSuccess = publishedOnSuccess;
      if (changes.createdArray?.length && this.isDiagramBuilt) {
        changes.createdArray.forEach(item => this.renderPublishedEdge(item, 'success'));
        this.layout();
      } else if (changes?.deletedArray?.length) {
        changes.deletedArray.forEach((item: any) => this.updateEventType(item));
      }
    });
  }

  subscribeToWorkflowStepFunctionPublishedEventsOnFailure() {
    return this.store.select(state => state.workflowStepFunctionPublishedEventOnFailure.data).subscribe(publishedOnFailure => {
      const changes = this.dataTransformService.compareArrays(this.publishedEventsOnFailure, publishedOnFailure);
      this.publishedEventsOnFailure = publishedOnFailure;
      if (changes.createdArray?.length && this.isDiagramBuilt) {
        changes.createdArray.forEach(item => this.renderPublishedEdge(item, 'failure'));
        this.layout();
      } else if (changes?.deletedArray?.length) {
        changes.deletedArray.forEach((item: any) => this.updateEventType(item));
      }
    });
  }

  subscribeToLastCreatedPlaceholderId() {
    return this.store.select(state => state.wfDiagramState.lastCreatedPlaceholderDataId).subscribe(id => {
      if (id) {
        if (id === this.placeholderEventData?.id) {
          this.removePlaceholderEvent();
          this.layout();
        } else if (id === this.placeholderStepFunctionData?.id) {
          this.removePlaceholderStepFunction();
          this.closeAddStepFunctionBar();
          this.layout();
        }
      }
    });
  }

  subscribeToMenuVisibility() {
    return this.store.select(state => state.wfDiagramState.formPanelVisibility).subscribe(panelVisibility => this.menuVisibility = panelVisibility);
  }

  subscribeToEventFormSubmitLoading() {
    return this.store.select(state => state.workflowEvent?.isLoading).subscribe(loading => this.eventFormSubmitButtonLoading = loading);
  }

  subscribeToStepFunctionFormSubmitLoading() {
    return this.store.select(state => state.workflowStepFunction?.isLoading).subscribe(loading => this.stepFunctionFormSubmitButtonLoading = loading);
  }

  subscribeToDataTransformationLoading() {
    return this.store.select(state => state.workflowStepFunction?.isDataTransformationLoading).subscribe(isLoading => {
      this.isDataTransformationLoading = isLoading;
    });
  }

  subscribeToPreDataTransformation() {
    return this.store.select(state => state.workflowStepFunction?.preDataTransformationData).subscribe((preDT: PreDataTransformationData) => {
      this.dataTransformationId = preDT?.preDataTransformationId;
      this.sourceSchema = preDT?.preSourceJsonSchema;
      this.targetSchema = preDT?.preTargetJsonSchema;
    });
  }

  subscribeToPostDataTransformation() {
    return this.store.select(state => state.workflowStepFunction?.postDataTransformationData).subscribe((postDT: PostDataTransformationData) => {
      this.dataTransformationId = postDT?.postDataTransformationId;
      this.sourceSchema = postDT?.postSourceJsonSchema;
      this.targetSchema = postDT?.postTargetJsonSchema;
    });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(project => {
      this.activeProject = project;
    });
  }

  subscribeToActivePerson() {
    return this.store.select(state => state.auth?.activePerson).subscribe((activePerson: PersonWithRelations) => {
      this.activePerson = activePerson;
    });
  }

  subscribeToWFDiagramComments() {
    return this.store.select(createSelector(
      state => <CommentWithRelations[]>state.comment?.data,
      state => <DiagramCommentWithRelations[]>state.diagramComment?.data,
      (
        comments: CommentWithRelations[],
        diagramComments: DiagramCommentWithRelations[]
      ) => {
        const wfDiagramComments = comments?.filter(comment =>
          comment.category === CommentCategory.BEWorkflowEvent ||
          comment.category === CommentCategory.BEWorkflowStepFunction);
        const wfDiagramCommentsWithRelations = wfDiagramComments?.map(comment => ({
          ...comment,
          details: diagramComments?.find(c => c.commentId === comment.id)
        }));
        return wfDiagramCommentsWithRelations;
      }
    )).subscribe(data => {
      this.comments = data;
      this.filterCommentsByActiveEntityId();
      this.setActiveCommentCellData();
    });
  }

  subscribeToCommentsLoading() {
    return this.store.select(createSelector(
      state => state.comment?.isLoading,
      state => state.diagramComment?.isLoading,
      (commentsLoading, diagramCommentsLoading) => commentsLoading || diagramCommentsLoading
    )).subscribe((isLoading: boolean) => {
      this.isCommentsLoading = isLoading;

      this.setActiveCommentCellLoading();
    });
  }

  // #endregion Subscriptions

  updateEventType(item) {
    this.graph.removeEdge(item.id);
    this.graph.removeNode(item.workflowEventId);
    this.buildNodeFromEventId(item.workflowEventId, 'subscribed');
    this.layout();
  }

  closeRightBar() {
    this.isAddEventMenuVisible = false;
    this.menuVisibility = false;
    this.updateFormPanelVisibility(false);
    this.isAddStepFunctionMenuVisible = false;
  }

  openAddStepFunctionBar() {
    this.menuVisibility = this.isAddStepFunctionMenuVisible = true;
    this.updateFormPanelVisibility(true);
  }

  openAddEventBar() {
    this.menuVisibility = this.isAddEventMenuVisible = true;
    this.updateFormPanelVisibility(true);
  }

  onCreateStepFunctionButtonClick() {
    this.stepFunctionMode = StepFunctionMode.Create;
    this.initialStepFunctionData = {
      subscribedEventIds: null,
    };
    this.openAddStepFunctionBar();
    this.addPlaceholderStepFunction();
    this.isAddEventMenuVisible = false;
  }

  onCreateWorkflowEventButtonClick() {
    this.eventMode = EventMode.Create;
    this.openAddEventBar();
    this.isAddStepFunctionMenuVisible = false;
    this.addPlaceholderEvent('subscribe');
  }

  closeAddStepFunctionBar() {
    this.menuVisibility = this.isAddStepFunctionMenuVisible = false;
    this.updateFormPanelVisibility(false);
  }

  closeAddEventBar() {
    this.menuVisibility = this.isAddEventMenuVisible = false;
    this.updateFormPanelVisibility(false);
  }

  getPublishedEvents() {
    return [...(this.publishedEventsOnFailure ?? []), ...this.publishedEventsOnSuccess ?? []];
  }

  renderPublishedEdge(relation: WorkflowStepFunctionPublishedEventOnSuccess | WorkflowStepFunctionPublishedEventOnFailure, type: 'success' | 'failure') {
    const sourceNodeId = relation.workflowStepFunctionId;
    const targetNodeId = relation.workflowEventId;

    if (sourceNodeId && targetNodeId) {
      const sourceNode = this.graph.getCellById(sourceNodeId);
      const targetNode = this.graph.getCellById(targetNodeId);

      if (sourceNode && targetNode) {
        if (this.isDiagramBuilt) {
          targetNode.setAttrByPath(['body', 'stroke'], (type === 'success' ? '#1DD109' : '#FF0000'));
          targetNode.setData({ shape: type === 'success' ? 'workflowEventSuccess' : 'workflowEventFailure' });
          targetNode.setPropByPath('shape', type === 'success' ? 'workflowEventSuccess' : 'workflowEventFailure');
        }

        this.graph.addEdge(
          {
            id: relation.id,
            source: sourceNodeId,
            target: targetNodeId,
            shape: type === 'failure' ? 'failure-edge' : 'success-edge'
          }
        );
      }
    }
  }

  renderSubscribedEdge(relation: WorkflowStepFunctionSubscribedEvent) {
    const sourceNodeId = relation.workflowEventId;
    const targetNodeId = relation.workflowStepFunctionId;

    if (sourceNodeId && targetNodeId) {
      const sourceNode = this.graph.getCellById(sourceNodeId);
      const targetNode = this.graph.getCellById(targetNodeId);

      if (sourceNode && targetNode) {
        this.graph.addEdge(
          {
            id: relation.id,
            source: sourceNodeId,
            target: targetNodeId,
            shape: 'edge'
          }
        );
      }
    }
  }

  layout() {
    const dir = 'LR'; // LR RL TB BT
    const nodes = this.graph.getNodes();
    const edges = this.graph.getEdges();
    const g = new dagre.graphlib.Graph();
    g.setGraph({ rankdir: dir, nodesep: 16, ranksep: 16 });
    g.setDefaultEdgeLabel(() => ({}));

    const width = 260;
    const height = 90;
    nodes.forEach((node) => {
      g.setNode(node.id, { width, height });
    });

    edges.forEach((edge) => {
      const source = edge.getSource() as any;
      const target = edge.getTarget() as any;
      g.setEdge(source.cell, target.cell);
    });

    dagre.layout(g);

    g.nodes().forEach((id) => {
      const node = this.graph.getCellById(id) as any;
      if (node) {
        const pos = g.node(id);
        node.position(pos.x, pos.y);
      }
    });

    edges.forEach((edge) => {
      const source = edge.getSourceNode();
      const target = edge.getTargetNode();
      if (source && target) {
        const sourceBBox = source.getBBox();
        const targetBBox = target.getBBox();

        if ((dir === 'LR' || dir === 'RL') && sourceBBox.y !== targetBBox.y) {
          const gap =
            dir === 'LR'
              ? targetBBox.x - sourceBBox.x - sourceBBox.width
              : -sourceBBox.x + targetBBox.x + targetBBox.width;
          const fix = dir === 'LR' ? sourceBBox.width : 0;
          const x = sourceBBox.x + fix + gap / 2;
          edge.setVertices([
            { x, y: sourceBBox.center.y },
            { x, y: targetBBox.center.y }
          ]);
        } else {
          edge.setVertices([]);
        }
      }
    });
  }

  listenEvents() {
    this.listenAddSuccessNode();
    this.listenAddFailureNode();
    this.onMouseEnterNode();
    this.onNodeClick();
    this.onBlankClick();
  }

  /**
 * function called when clicked blank areas of the graph
 *
 * @memberof DbDiagramWrapperComponent
 */
  onBlankClick() {
    this.graph.on('blank:click', (args: any) => {
      this.resetGraphCommentComponentsState();
    });
  }

  onNodeClick() {
    this.graph.on('node:click', (args: { node: Node }) => {
      const nodeData = (args?.node as any)?.store?.data;
      if (!nodeData.shape.toLowerCase().includes('placeholder')) {
        if (nodeData.shape === 'workflowStepFunction') {
          const editingWorkflowStepFunction = this.workflowStepFunctions?.find(workflowStepFunction => workflowStepFunction.id === args.node.id);
          const isRappiderWorkflowService = this.templateWorkflowFunctions?.some(templateWorkflowFunction => templateWorkflowFunction.id === editingWorkflowStepFunction?.customFunctionDefinitionId);
          let workflowFunctionOrService: string;
          if (isRappiderWorkflowService) {
            workflowFunctionOrService = WorkflowFunctionTypeOptions.RappiderServices;
          } else {
            workflowFunctionOrService = WorkflowFunctionTypeOptions.WorkflowFunctions;
          }
          this.editingWorkflowStepFunction = {
            ...editingWorkflowStepFunction,
            subscribedEventIds: editingWorkflowStepFunction?.subscribedEvents?.map(item => item.id),
            publishedEventsOnSuccess: editingWorkflowStepFunction?.publishedEventsOnSuccess?.map(item => item.id),
            publishedEventsOnFailure: editingWorkflowStepFunction?.publishedEventsOnFailure?.map(item => item.id),
            customOrEndpoint: editingWorkflowStepFunction?.customOrEndpoint,
            projectModel: editingWorkflowStepFunction?.projectModel,
            endpointId: editingWorkflowStepFunction?.endpointId,
            customCode: editingWorkflowStepFunction?.customCode,
            mode: workflowFunctionOrService
          };
          this.stepFunctionMode = StepFunctionMode.Edit;
          this.openAddStepFunctionBar();
          this.isAddEventMenuVisible = false;
          this.removePlaceholderEvent();
          this.removePlaceholderStepFunction();
          this.addCommentButtonToActiveEntitylNode(args.node);
          this.activeCommentNode = args.node;
        } else if (nodeData.shape.toLowerCase().includes('event')) {
          this.editingWorkflowEvent = this.workflowEvents.find(workflowEvent => workflowEvent.id === args.node.id);
          this.eventMode = EventMode.Edit;
          this.removePlaceholderEvent();
          this.openAddEventBar();
          this.isAddStepFunctionMenuVisible = false;
          this.removePlaceholderStepFunction();
          this.addCommentButtonToActiveEntitylNode(args.node);
          this.activeCommentNode = args.node;
        }
      }
    });
  }

  listenAddSuccessNode() {
    this.graph.on('node:add-success', (args: { node: Node; e: Event }) => {
      args.e.stopPropagation();
      args.e.preventDefault();
      const uiStepFunctionId = (args.node as any)?.store?.data.id;
      this.eventMode = EventMode.Create;
      this.openAddEventBar();
      this.isAddStepFunctionMenuVisible = false;
      this.addPlaceholderEvent('success', uiStepFunctionId);
    });
  }

  listenAddFailureNode() {
    this.graph.on('node:add-failure', (args: { node: Node; e: Event }) => {
      args.e.stopPropagation();
      args.e.preventDefault();
      const uiStepFunctionId = (args.node as any)?.store?.data.id;
      this.eventMode = EventMode.Create;
      this.openAddEventBar();
      this.isAddStepFunctionMenuVisible = false;
      this.addPlaceholderEvent('failure', uiStepFunctionId);
    });
  }

  onMouseEnterNode() {
    this.graph.on('node:click', (args: { node: HTML }) => {
      const store = (args.node as any)?.store;

      if (store?.data?.shape !== 'comments') {
        if (store?.data?.shape !== 'workflowStepFunction' && !store?.data?.shape?.includes('Placeholder')) {
          this.addAddStepFunctionButtonToNode(args.node);
          this.setActiveElement(args.node);
        } else {
          this.setActiveElement(args.node);
        }
      }
    });
  }

  setActiveElement(node: Node) {
    if (this.lastEnteredNodeId) {
      const oldNode = this.graph.getNodes()?.find(node => node.id === this.lastEnteredNodeId);
      const oldNodeItem = this.findOldNodeType(oldNode);

      if (oldNode && !oldNodeItem?.comments?.length) {
        oldNode?.removeTools();
      }
      if (oldNode && oldNodeItem?.comments?.length) {
        oldNode?.removeTool('button');
        oldNode?.removeTool('boundary');
      }
    }
    this.lastEnteredNodeId = node.id;
    node.addTools({
      name: 'boundary',
      args: {
        padding: 10,
        attrs: {
          fill: '#7c68fc',
          stroke: '#9254de',
          strokeWidth: 0.5,
          fillOpacity: 0.05
        }
      }
    });
  }

  findOldNodeType(oldNode) {
    const isOldNodeWorkflowEvent = this.workflowEvents?.find(workflowEvent => workflowEvent.id === oldNode.id);
    const isOldNodeWorkflowStepFunction = this.workflowStepFunctions?.find(workflowStepFunction => workflowStepFunction.id === oldNode.id);

    if (isOldNodeWorkflowEvent) {
      return isOldNodeWorkflowEvent;
    }
    if (isOldNodeWorkflowStepFunction) {
      return isOldNodeWorkflowStepFunction;
    }
  }

  removeAddButtonFromNode(node: Node) {
    node.removeTool('button');
  }

  addAddStepFunctionButtonToNode(node: Node) {
    if (node) {
      node.addTools({
        name: 'button',
        args: {
          markup: [
            {
              tagName: 'g',
              attrs: {
                class: 'btn add',
              },
              children: [
                {
                  tagName: 'circle',
                  attrs: {
                    class: 'add',
                    r: 10,
                    fill: 'transparent',
                    stroke: '#1890ff',
                    strokeWidth: 1,
                    event
                  },
                },
                {
                  tagName: 'text',
                  textContent: '+',
                  attrs: {
                    class: 'add',
                    fontSize: 20,
                    fontWeight: 800,
                    textAnchor: 'middle',
                    fill: '#1890ff',
                    fontFamily: 'Times New Roman',
                    y: '0.3em'
                  }
                },
              ],
            },
          ],
          offset: { x: 15, y: 25 },
          x: '100%',
          and: '0',
          onClick: (config: { cell: Cell }) => {
            this.stepFunctionMode = StepFunctionMode.Create;
            this.isAddEventMenuVisible = false;
            this.addStepFunction(config?.cell.id);
          }
        }
      });
    }
  }

  addStepFunction(eventId: string) {
    this.addPlaceholderStepFunction(eventId);
    this.initialStepFunctionData = {
      subscribedEventIds: [eventId]
    };
    this.openAddStepFunctionBar();
  }

  onCreateWorkflowEvent(workflowEvent: any) {
    try {
      const temp = {
        ...workflowEvent,
        inputDataSample: workflowEvent.inputDataSample ? JSON.parse(workflowEvent.inputDataSample) : null
      };

      this.store.dispatch(WorkflowEventActions.CreateWorkflowEvent({
        payload: {
          workflowEvent: temp,
          placeholderData: this.placeholderEventData
            ? {
              id: this.placeholderEventData.id,
              eventId: this.placeholderEventData?.tempId,
              stepFunctionId: this.placeholderEventData.stepFunctionId,
              type: this.placeholderEventData.type
            }
            : undefined
        }
      }));
    } catch (error) {
      this.notificationService.createNotification(
        'warning',
        'Warning',
        'JSON format is wrong. Please enter a valid JSON'
      );
    }

  }

  addPlaceholderEvent(type: 'success' | 'failure' | 'subscribe', stepFunctionId?: string) {
    if (this.placeholderEventData) {
      this.removePlaceholderEvent();
    }
    if (this.placeholderStepFunctionData) {
      this.removePlaceholderStepFunction();
    }
    const tempId = v4();
    const placeholderEvent = this.graph.addNode(
      {
        shape: type === 'success' ? 'workflowEventSuccessPlaceholder' : (type === 'subscribe' ? 'workflowEventPlaceholder' : 'workflowEventFailurePlaceholder'),
        id: tempId,
        label: type === 'success' ? 'New Success Event' : (type === 'subscribe' ? 'New Event' : 'New Failure Event'),
        size: {
          width: 50,
          height: 50
        }
      }
    );
    const tempEdgeId = v4();
    if (stepFunctionId) {
      this.graph.addEdge(
        {
          id: tempEdgeId,
          source: stepFunctionId,
          target: tempId,
          shape: type === 'failure' ? 'failure-edge' : 'success-edge',
        }
      );
    }
    this.layout();
    this.graph.centerCell(placeholderEvent);
    const tempUUID = v4();
    this.placeholderEventData = {
      id: tempUUID,
      tempEdgeId: tempEdgeId,
      tempId: tempId,
      stepFunctionId: stepFunctionId,
      type: type
    };
    this.isAddEventMenuVisible = true;
  }

  addPlaceholderStepFunction(eventId?: string) {
    if (this.placeholderStepFunctionData) {
      this.removePlaceholderStepFunction();
    }
    if (this.placeholderEventData) {
      this.removePlaceholderEvent();
    }
    const tempId = v4();
    const placeholderStepFunction = this.graph.addNode(
      {
        shape: 'workflowStepFunctionPlaceholder',
        id: tempId,
        width: 150,
        height: 75,
        attrs: {
          label: {
            text: 'New Step Function',
            refY: '100%',
            refY2: 13,
            textAnchor: 'middle',
            textVerticalAnchor: 'top',
          },
        },
      }
    );
    const tempEdgeId = v4();
    if (eventId) {
      this.graph.addEdge(
        {
          id: tempEdgeId,
          source: eventId,
          target: tempId,
          shape: 'edge',
        }
      );
    }
    this.layout();
    this.graph.centerCell(placeholderStepFunction);
    const tempUUID = v4();
    this.placeholderStepFunctionData = {
      id: tempUUID,
      tempEdgeId: tempEdgeId,
      tempId: tempId,
    };
    this.isAddStepFunctionMenuVisible = true;
  }

  removePlaceholderEvent() {
    if (this.placeholderEventData) {
      this.graph.removeNode(this.placeholderEventData.tempId);
      this.graph.removeEdge(this.placeholderEventData.tempEdgeId);
      this.placeholderEventData = undefined;
    }
    this.isAddEventMenuVisible = false;
  }

  removePlaceholderStepFunction() {
    if (this.placeholderStepFunctionData) {
      this.graph.removeNode(this.placeholderStepFunctionData.tempId);
      this.graph.removeEdge(this.placeholderStepFunctionData.tempEdgeId);
      this.placeholderStepFunctionData = undefined;
    }
  }

  onStepFunctionFormSubmit(stepFunction: any) {
    const params = {
      workflowStepFunction: {
        name: stepFunction.name,
        endpointId: stepFunction.endpointId || null,
        customFunctionDefinitionId: stepFunction?.customFunctionDefinitionId,
        customOrEndpoint: stepFunction?.customOrEndpoint,
        customCode: stepFunction?.customCode,
        projectModel: stepFunction?.projectModel
      },
      publishedEventOnFailureIds: stepFunction.publishedEventsOnFailure || [],
      publishedEventOnSuccessIds: stepFunction.publishedEventsOnSuccess || [],
      subscribedEventIds: stepFunction.subscribedEvents || []
    } as any;
    if (this.stepFunctionMode === StepFunctionMode.Create) {
      this.store.dispatch(WorkflowStepFunctionActions.CreateWorkflowStepFunction({ payload: { workflowStepFunction: params, placeholderDataId: this.placeholderStepFunctionData?.id } }));
    } else {
      this.store.dispatch(WorkflowStepFunctionActions.UpdateWorkflowStepFunction({ payload: { workflowStepFunctionId: this.editingWorkflowStepFunction.id, workflowStepFunctionBody: params } }));
    }
  }

  onStepFunctionNameChangeOnCreate(name: string) {
    const placeholderNode = this.graph.getNodes()?.find(node => node.id === this.placeholderStepFunctionData?.tempId);
    placeholderNode?.setAttrByPath(['label', 'text'], name);
  }

  onEventNameChangeOnCreate(name: string) {
    const placeholderNode = this.graph.getNodes()?.find(node => node.id === this.placeholderEventData?.tempId);
    placeholderNode?.setAttrByPath(['text', 'text'], name);
  }

  onMenuVisibilityChange() {
    this.removePlaceholderStepFunction();
    this.removePlaceholderEvent();
  }

  menuVisibilityChange() {
    this.menuVisibility = !this.menuVisibility;
    this.removePlaceholderStepFunction();
    this.removePlaceholderEvent();
  }

  onEditWorkflowEvent(workflowEvent: { workflowEventId: string; workflowEventBody?: WorkflowEventPartial }) {
    try {
      const temp = {
        ...workflowEvent.workflowEventBody,
        inputDataSample: workflowEvent?.workflowEventBody?.inputDataSample ? JSON?.parse(workflowEvent?.workflowEventBody?.inputDataSample) : null
      };

      this.store.dispatch(WorkflowEventActions.UpdateWorkflowEvent({
        payload: {
          workflowEventId: workflowEvent.workflowEventId,
          workflowEventBody: temp
        }
      }));
    } catch (error) {
      this.notificationService.createNotification(
        'warning',
        'Warning',
        'JSON format is wrong. Please enter a valid JSON'
      );
    }

  }

  updateFormPanelVisibility(visibility: boolean) {
    this.store.dispatch(UpdateFormPanelVisibility({ payload: { visibility: visibility } }));
  }

  onDataTransformationButtonClick(
    componentMode: WorkflowStepFunctionDataTransformationComponentMode,
    workflowStepFunction: WorkflowStepFunctionWithRelations
  ) {
    const workflowStepFunctionWithRelations = this.workflowStepFunctions.find(wfs => wfs.id === workflowStepFunction.id);
    const selectedCustomFunction = this.selectedFunctionMode === WorkflowFunctionTypeOptions.RappiderServices ?
      this.templateWorkflowFunctions?.find(templateWorkflowFunction => templateWorkflowFunction.id === workflowStepFunctionWithRelations.customFunctionDefinitionId)
      : this.customFunctions.find(customFunction => customFunction.id === workflowStepFunctionWithRelations?.customFunctionDefinitionId);
    if (componentMode === WorkflowStepFunctionDataTransformationComponentMode.PreDataTransformation) {
      if (workflowStepFunctionWithRelations?.subscribedEvents?.length && selectedCustomFunction?.requestJSONSample) {
        this.toggleDataTransformationModalVisibility();
        this.store.dispatch(GetPreDataTransformationData({ payload: { workflowStepFunction: workflowStepFunctionWithRelations } }));
      } else {
        if (!workflowStepFunctionWithRelations?.subscribedEvents?.length && !selectedCustomFunction?.requestJSONSample) {
          this.notificationService.createNotification(
            'warning',
            'Warning',
            'Pre Data transformations cannot be applied to step functions for don\'t have subscribed events and custom functions that do not have a request JSON example.'
          );
        } else if (!selectedCustomFunction?.requestJSONSample) {
          this.notificationService.createNotification(
            'warning',
            'Warning',
            'Pre Data transformations cannot be applied to step functions for custom functions that do not have a request JSON example.'
          );
        } else if (!workflowStepFunctionWithRelations?.subscribedEvents?.length) {
          this.notificationService.createNotification(
            'warning',
            'Warning',
            'Pre Data transformations cannot be applied to step functions that don\'t have subscribed events.'
          );
        }
      }
    } else if (componentMode === WorkflowStepFunctionDataTransformationComponentMode.PostDataTransformation) {
      if (workflowStepFunctionWithRelations.publishedEventsOnSuccess?.length && selectedCustomFunction?.responseJSONSample) {
        this.toggleDataTransformationModalVisibility();
        this.store.dispatch(GetPostDataTransformationData({ payload: { workflowStepFunction: workflowStepFunctionWithRelations } }));
      } else {
        if (!workflowStepFunctionWithRelations.publishedEventsOnSuccess?.length && !selectedCustomFunction?.responseJSONSample) {
          this.notificationService.createNotification(
            'warning',
            'Warning',
            'Post Data transformations cannot be applied to step functions for don\'t have published events and custom functions that do not have a response JSON example.'
          );
        } else if (!selectedCustomFunction?.responseJSONSample) {
          this.notificationService.createNotification(
            'warning',
            'Warning',
            'Post Data transformations cannot be applied to step functions for custom functions that do not have a response JSON example.'
          );
        } else if (!workflowStepFunctionWithRelations.publishedEventsOnSuccess?.length) {
          this.notificationService.createNotification(
            'warning',
            'Warning',
            'Post Data transformations cannot be applied to step functions that don\'t have published events.'
          );
        }
      }
    }
  }

  onModeChange(selectedMode: string) {
    this.selectedFunctionMode = selectedMode;
  }

  toggleDataTransformationModalVisibility() {
    this.dataTransformationModalVisibility = !this.dataTransformationModalVisibility;
  }

  displayNoData() {
    return !this.workflowStepFunctions?.filter(workflowStepFunction => workflowStepFunction.projectId === this.activeProject?.id)?.length && !this.workflowEvents?.length;
  }

  transform(command: string) {
    switch (command) {
      case '%50':
        this.graph.zoomTo(1);
        this.graph.zoom(-0.4);
        break;
      case '%75':
        this.graph.zoomTo(1);
        this.graph.zoom(-0.2);
        break;
      case '%100':
        this.graph.zoomTo(1);
        break;
      case '%150':
        this.graph.zoomTo(1.5);
        break;
      case '%200':
        this.graph.zoomTo(2);
        break;
      case 'zoomIn':
        this.graph.zoom(0.2);
        break;
      case 'zoomOut':
        this.graph.zoom(-0.2);
        break;
      case 'zoomToFit':
        this.graph.zoomToFit();
        break;
      default:
        break;
    }
  }

  zoomButtonVisibilityChange() {
    this.zoomButtonVisibility = !this.zoomButtonVisibility;
  }

  /**
   * adds comment button to node
   *
   * @param {Node} node
   * @memberof DbDiagramWrapperComponent
   */
  addCommentButtonToActiveEntitylNode(node: any) {
    if (!!this.activeCommentNode && this.activeCommentNode?.id !== node.id) {
      const lastActiveWorkflowEvent: any = this.workflowEvents?.find(workflowEvent => workflowEvent.id === this.activeCommentNode?.id);
      const lastActiveWorkflowStepFunction: any = this.workflowStepFunctions?.find(workflowStepFunction => workflowStepFunction.id === this.activeCommentNode?.id);
      const isLastActiveItemWorkflowEvent = this.workflowEvents?.some(event => event.id === this.activeCommentNode?.id);
      const isLastActiveItemWorkflowStepFunction = this.workflowStepFunctions?.some(stepFunction => stepFunction.id === this.activeCommentNode?.id);

      if (isLastActiveItemWorkflowEvent && !lastActiveWorkflowEvent?.comments?.length) {
        // remove previous active node's commenet button
        this.removeCommentButtonFromNode(this.activeCommentNode);
        // remove previous active node's comment component to prevent duplication
        this.removeCommentComponentNodeFromCell(this.activeCommentCell);
      }
      if (isLastActiveItemWorkflowStepFunction && !lastActiveWorkflowStepFunction?.comments?.length) {
        // remove previous active node's commenet button
        this.removeCommentButtonFromNode(this.activeCommentNode);
        // remove previous active node's comment component to prevent duplication
        this.removeCommentComponentNodeFromCell(this.activeCommentCell);
      }
    }
    // set new active comment node
    this.activeCommentNode = node;

    this.isCommentsNodeVisible = false;

    node.addTools({
      name: 'commentButton',
      args: {
        markup: [
          {
            tagName: 'image',
            selector: 'image',
            attrs: {
              'xlink:href':
                'assets/icons/comment-lines-regular.svg',
              width: 30,
              height: 30,
              x: -32,
              y: -10,
              cursor: 'pointer'
            }
          },
        ],
        x: '',
        y: '100%',
        offset: node?.store?.data?.shape === 'workflowStepFunction' ? { x: 182, y: -92 } : { x: 75, y: -65 },
        // handle comment component on clicking comment button here
        onClick: (config: { cell: Cell }) => {
          if (!this.isCommentsNodeVisible) {
            this.isCommentsNodeVisible = true;
            this.filterCommentsByActiveEntityId();
            if (this.displayedComments && this.lastEnteredNodeId === config.cell.id) {
              return this.addCommentComponentNodeToCell(config.cell);
            } else {
              this.setActiveElement(config.cell as any);
              this.lastEnteredNodeId = config.cell.id;
              this.filterCommentsByActiveEntityId();
              return this.addCommentComponentNodeToCell(config.cell);
            }
          } else {
            this.isCommentsNodeVisible = false;
            return this.removeCommentComponentNodeFromCell(config.cell);
          }
        }
      },
    });
    this.activeCommentNode = null;
  }

  /**
   * removes comment button from the node
   *
   * @param {Node} node
   * @memberof DbDiagramWrapperComponent
   */
  removeCommentButtonFromNode(node: Node) {
    node?.removeTool('commentButton');
  }

  /**
   *
   *
   * @param {Cell} cell
   * @memberof DbDiagramWrapperComponent
   */
  addCommentComponentNodeToCell(cell: any): void {
    if (!!this.activeCommentCell && this.activeCommentCell?.id !== cell.id) {
      // remove current comment component node if another node is clicked
      this.removeCommentComponentNodeFromCell(this.activeCommentCell);
    }

    const position = (cell as Cell).getProp<{ x: number; y: number }>('position');

    register({
      shape: 'comments',
      width: 300,
      height: 20,
      content: this.commentsTemplate,
      injector: this.injector,
    });

    const commentNode = this.graph.addNode({
      shape: 'comments',
      x: cell?.store?.data?.shape === 'workflowStepFunction' ? position.x + 165 : position.x + 58,
      y: cell?.store?.data?.shape === 'workflowStepFunction' ? position.y - 10 : position.y - 8,

      data: {
        ngArguments: {
          activePerson: this.activePerson,
          peopleData: this.activeProject?.people,
          comments: this.displayedComments,
          isLoading: this.isCommentsLoading
        },
      },
    });
    cell.addChild(commentNode);
    this.activeCommentCell = cell;
  }

  /**
   * remove comment component from the cell
   *
   * @param {Cell} cell
   * @memberof DbDiagramWrapperComponent
   */
  removeCommentComponentNodeFromCell(cell: Cell): void {
    const commentNode = cell?.getChildAt(0);
    cell?.removeChild(commentNode);
  }

  resetGraphCommentComponentsState() {
    const activeCommentNodeComments = this.comments?.filter(comment => comment.details?.relatedEntityId === this.activeCommentNode?.id);
    if (!activeCommentNodeComments?.length) {
      this.removeCommentButtonFromNode(this.activeCommentNode);
    }
    this.removeCommentComponentNodeFromCell(this.activeCommentCell);
    this.activeCommentCell = null;
    this.activeCommentNode = null;
  }

  setActiveCommentCellData() {
    if (this.activeCommentCell) {
      this.activeCommentCell.children[0].data.ngArguments.comments = this.displayedComments;
    } else {
      return;
    }
  }

  setActiveCommentCellLoading() {
    if (this.activeCommentCell) {
      this.activeCommentCell.children[0].data.ngArguments.isLoading = this.isCommentsLoading;
    } else {
      return;
    }
  }

  onCreateComment(comment) {
    if (this.activeCommentCell?.id && !!comment) {
      const nodeShape = (this.activeCommentCell as any)?.store?.data?.shape;

      const newComment: NewDiagramComment = {
        category: nodeShape === 'workflowStepFunction' ? CommentCategory.BEWorkflowStepFunction : CommentCategory.BEWorkflowEvent,
        content: comment.content,
        assignedToPersonId: comment.assignedToPersonId,
        relatedEntityId: this.activeCommentCell?.id
      };
      this.store.dispatch(CreateDiagramComment({ comment: newComment }));
    }
  }

  onEditComment(comment) {
    const { id, ...body } = comment;
    if (comment) {
      this.store.dispatch(UpdateDiagramComment({ commentId: id, comment: body }));
    } else {
      return;
    }
  }

  onDeleteComment(comment) {
    if (comment.id && comment.details.id) {
      this.store.dispatch(DeleteDiagramComment({ commentId: comment.id, diagrmaCommentId: comment.details.id }));
    } else {
      return;
    }
  }

  filterCommentsByActiveEntityId() {
    if (this.lastEnteredNodeId) {
      this.displayedComments = this.comments?.filter(c => c.details?.relatedEntityId === this.lastEnteredNodeId);
    } else {
      return;
    }
  }
}
