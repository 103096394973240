export enum ContentEditorSettingsSection {
  ComponentSettings = 'component-settings',
  InputVariations = 'input-variations',
  ComponentOutputs = 'component-outputs',
  ComponentComments = 'component-comments',
  ContainerComments = 'container-comments',
  CssClasses = 'css-classes',
  ContainerDisplayMode = 'container-display-mode',
  ContainerType = 'container-type',
  ContainerListDataSource = 'container-list-data-source',
  Pages = 'pages',
  Layouts = 'layouts',
  ComponentTree = 'component-tree',
  PageVariables = 'page-variables',
  PageLifecycle = 'page-lifecycle',
  CustomPageStyles = 'custom-page-styles',
}
