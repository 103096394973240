<div class="text-area">
  <textarea *ngIf="!minLength"
            class="default-height"
            [id]="elementId"
            nz-input
            [(ngModel)]="value"
            [ngClass]="cssClass"
            [ngStyle]="cssStyle || borderStyles"
            [placeholder]="placeholder || '' | translate"
            [nzAutosize]="autosize ? { minRows: minRows, maxRows: maxRows } : false"
            [style.border-top]="borderSettings?.borderTop + ' ' + borderSettings?.borderStyle"
            [style.border-right]="borderSettings?.borderRight + ' ' + borderSettings?.borderStyle"
            [style.border-bottom]="borderSettings?.borderBottom + ' ' + borderSettings?.borderStyle"
            [style.border-left]="borderSettings?.borderLeft + ' ' + borderSettings?.borderStyle"
            [style.border-radius]="borderSettings?.borderRadius"
            [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
            [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
            [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
            [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
            [style.border-color]="borderSettings?.borderColor"
            [style.border-style]="borderSettings?.borderStyle"
            [style.width]="customSizeSettings?.width"
            [style.height]="customSizeSettings?.height"
            [style.min-width]="customSizeSettings?.minWidth"
            [style.min-height]="customSizeSettings?.minHeight"
            [style.max-width]="customSizeSettings?.maxWidth"
            [style.max-height]="customSizeSettings?.maxHeight"
            [style.color]="customColorSettings?.color"
            [style.background-color]="customColorSettings?.backgroundColor"
            [style.boxShadow]="shadowSettings?.boxShadow"
            [style.padding]="paddingSettings?.all"
            [style.margin]="marginSettings?.all"
            [style.font-size]="typographySettings?.fontSize"
            [style.font-weight]="typographySettings?.fontWeight"
            [style.font-style]="typographySettings?.fontStyle"
            [style.text-align]="typographySettings?.textAlign"
            [style.text-shadow]="typographySettings?.textShadow"
            [style.font-family]="typographySettings?.fontFamily"
            [style.font-stretch]="typographySettings?.fontStretch"
            [style.text-indent]="typographySettings?.textIndent"
            [style.letter-spacing]="typographySettings?.letterSpacing"
            [style.line-height]="typographySettings?.lineHeight"
            [style.word-spacing]="typographySettings?.wordSpacing"
            [style.text-decoration]="typographySettings?.textDecoration"
            [style.text-transform]="typographySettings?.textTransform"
            [style.user-select]="typographySettings?.userSelect"
            (blur)="onBlur()"></textarea>

  <nz-textarea-count *ngIf="minLength > 0"
                     [nzMaxCharacterCount]="minLength">
    <textarea nz-input
              [id]="elementId"
              [(ngModel)]="value"
              [ngClass]="cssClass"
              [ngStyle]="cssStyle || borderStyles"
              [placeholder]="placeholder || '' | translate"
              [nzAutosize]="autosize ? { minRows: minRows, maxRows: maxRows } : false"
              [style.border]="borderSettings?.border"
              [style.border-top]="borderSettings?.borderTop + ' ' + borderSettings?.borderStyle"
              [style.border-right]="borderSettings?.borderRight + ' ' + borderSettings?.borderStyle"
              [style.border-bottom]="borderSettings?.borderBottom + ' ' + borderSettings?.borderStyle"
              [style.border-left]="borderSettings?.borderLeft + ' ' + borderSettings?.borderStyle"
              [style.border-radius]="borderSettings?.borderRadius"
              [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
              [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
              [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
              [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
              [style.border-color]="borderSettings?.borderColor"
              [style.border-style]="borderSettings?.borderStyle"
              [style.width]="customSizeSettings?.width"
              [style.height]="customSizeSettings?.height"
              [style.min-width]="customSizeSettings?.minWidth"
              [style.min-height]="customSizeSettings?.minHeight"
              [style.max-width]="customSizeSettings?.maxWidth"
              [style.max-height]="customSizeSettings?.maxHeight"
              [style.color]="customColorSettings?.color"
              [style.background-color]="customColorSettings?.backgroundColor"
              [style.boxShadow]="shadowSettings?.boxShadow"
              [style.padding]="paddingSettings?.all"
              [style.margin]="marginSettings?.all"
              [style.font-size]="typographySettings?.fontSize"
              [style.font-weight]="typographySettings?.fontWeight"
              [style.font-style]="typographySettings?.fontStyle"
              [style.text-align]="typographySettings?.textAlign"
              [style.text-shadow]="typographySettings?.textShadow"
              [style.font-family]="typographySettings?.fontFamily"
              [style.font-stretch]="typographySettings?.fontStretch"
              [style.text-indent]="typographySettings?.textIndent"
              [style.letter-spacing]="typographySettings?.letterSpacing"
              [style.line-height]="typographySettings?.lineHeight"
              [style.word-spacing]="typographySettings?.wordSpacing"
              [style.text-decoration]="typographySettings?.textDecoration"
              [style.text-transform]="typographySettings?.textTransform"
              [style.user-select]="typographySettings?.userSelect"
              (blur)="onBlur()"></textarea>
  </nz-textarea-count>
</div>