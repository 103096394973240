<div class="close-panel">
  <i class="fa-solid fa-xmark"
     (click)="closeSettingsPanel()"></i>
</div>

<nz-tabset nzType="card"
           [(nzSelectedIndex)]="formPanelTabIndex"
           (nzSelectedIndexChange)="formPanelSelectedTabIndexChange($event)">

  <nz-tab [nzTitle]="itemSettingsIconTemplate">
    <ng-template #itemSettingsIconTemplate>
      <ng-container *ngTemplateOutlet="tabIcon;context:{
                icon: 'fa-regular fa-gear',
                title: formPanelTabIndex === 0 ? 'Settings' : '',
                tooltip: 'Settings' | translate
              }"></ng-container>
    </ng-template>

    <section class="diagram-settings-wrapper">
      <!-- UI DATA EVENT -->
      <ng-container *ngIf="diagramSettingsMode?.activeItemType === DiagramItemType.UIDataEvent
                    || diagramSettingsMode?.activeItemType === DiagramItemType.UIScheduledDataEvent">
        <ng-container *ngIf="diagramSettingsMode?.activeAction === DiagramActionType.Create">
          <rappider-data-event-create [uiDataStoreId]="uiDataStoreId"
                                      [formLayout]="formLayout"
                                      [navigateAfterCreate]="false"
                                      [displayBreadcrumb]="false"
                                      [showUIDataStoreSelection]="showUIDataStoreSelectionForUIDataEvents"
                                      (lastCreatedUIDataEvent)="onWorkflowItemCreate($event)"></rappider-data-event-create>
        </ng-container>

        <ng-container *ngIf="diagramSettingsMode?.activeAction === DiagramActionType.Edit">
          <rappider-data-event-edit [titleBarActionButtons]="AddWorkflowButtonConfig"
                                    [addUiStepFunctionButton]="AddUiStepFunctionButtonConfig"
                                    [formLayout]="formLayout"
                                    [uiDataEventId]="uiDataEventId"
                                    [navigateAfterUpdate]="false"
                                    [displayBreadcrumb]="false"
                                    (addWorkflow)="onAddWorkflow()"
                                    (addUiStepFunctionToDataEvent)="onAddUiStepFunctionToDataEvent()"></rappider-data-event-edit>

          <section>
            <div class="details-button">
              <nz-table *ngIf="[DiagramItemType.UIDataEvent, DiagramItemType.UIScheduledDataEvent]?.includes(activeItem?.type) "
                        #payloadTable
                        nzTitle="Payload"
                        nzNoResult="There is no payload for this event."
                        [nzFrontPagination]="false"
                        [nzData]="activeItem?.item?.payload?.fields ?? []">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of payloadTable.data">
                    <td>{{ data?.name }}</td>
                    <td>{{ data?.type?.name }}</td>
                  </tr>
                </tbody>
              </nz-table>
              <rappider-button [text]="UIDataEventDetailsButtonConfig.text"
                               [block]="UIDataEventDetailsButtonConfig.block"
                               (click)="onSeeDataEventDetailsClick()"></rappider-button>
            </div>
            <div class="delete-button">
              <rappider-button [text]="DiagramItemDeleteButtonConfig.text"
                               [block]="DiagramItemDeleteButtonConfig.block"
                               [colorType]="DiagramItemDeleteButtonConfig.colorType"
                               [popconfirmTitle]="DiagramItemDeleteButtonConfig.popconfirmTitle"
                               [icon]="DiagramItemDeleteButtonConfig.icon"
                               [emitWithoutPopconfirm]="false"
                               (confirm)="onConfirmDelete()"></rappider-button>
            </div>
          </section>
        </ng-container>
      </ng-container>
      <!-- /UI DATA EVENT -->

      <!-- UI DATA EVENT DETAIL MODAL -->
      <nz-modal [(nzVisible)]="isDataEventDetailModalVisible"
                [nzFooter]="null"
                nzTitle="Data Event Details"
                nzWidth="80%"
                (nzOnCancel)="toggleDataEventDetailModalVisibility()">
        <ng-container *nzModalContent>
          <rappider-data-event-detail [dataEventId]="uiDataEventId"
                                      [navigateAfterCreate]="false"></rappider-data-event-detail>
        </ng-container>
      </nz-modal>
      <!-- /UI DATA EVENT DETAIL MODAL -->

      <!-- UI STEP FUNCTION -->
      <ng-container *ngIf="diagramSettingsMode?.activeItemType === DiagramItemType.UIStepFunction
                    || diagramSettingsMode?.activeItemType === DiagramItemType.UIStepFunctionWithRelations">
        <ng-container *ngIf="diagramSettingsMode?.activeAction === DiagramActionType.Create">
          <rappider-ui-step-function-create [uiDataStoreId]="uiDataStoreId"
                                            [subscribedEventId]="uiDataEventId"
                                            [navigateAfterCreate]="false"
                                            [formLayout]="formLayout"
                                            [displayBreadcrumb]="false"
                                            [loading]="loading"
                                            [uiDataStore]="activeUIDataStore"
                                            [showUIDataStoreSelection]="showUIDataStoreSelectionForUIStepFunctions"
                                            [isSubmitButtonFloat]="true"
                                            (lastCreatedStepFunctionData)="onWorkflowItemCreate($event)">
          </rappider-ui-step-function-create>
        </ng-container>

        <ng-container *ngIf="diagramSettingsMode?.activeAction === DiagramActionType.Edit">
          <rappider-ui-step-function-edit [uiStepFunctionId]="uiStepFunctionId"
                                          [navigateAfterUpdate]="false"
                                          [formLayout]="formLayout"
                                          [displayBreadcrumb]="false"
                                          [isSubmitButtonFloat]="true"
                                          [addDataEventButtonVisibility]="true"
                                          (addDataEventToUiStepFunction)="onAddDataEventToUiStepFunction()"></rappider-ui-step-function-edit>

          <section>
            <div class="dt-area">
              <div class="dt-buttons">
                <rappider-button class="pre-data-transformation"
                                 [text]="PreDTButtonConfig.text"
                                 [icon]="PreDTButtonConfig.icon"
                                 [size]="PreDTButtonConfig.size"
                                 [block]="PreDTButtonConfig.block"
                                 (click)="onPreDTButtonClick(activeItem)"></rappider-button>

                <rappider-button class="post-data-transformation"
                                 [text]="PostDTButtonConfig.text"
                                 [icon]="PostDTButtonConfig.icon"
                                 [size]="PostDTButtonConfig.size"
                                 [block]="PostDTButtonConfig.block"
                                 (click)="onPostDTButtonClick(activeItem)"></rappider-button>
              </div>

              <div class="dt-warning-text">
                {{ 'DIAGRAM_EDITOR_MODULE.DT_WARNING_TEXT' | translate }}
              </div>
            </div>

            <div class="delete-button">
              <rappider-button [text]="DiagramItemDeleteButtonConfig.text"
                               [block]="DiagramItemDeleteButtonConfig.block"
                               [colorType]="DiagramItemDeleteButtonConfig.colorType"
                               [popconfirmTitle]="DiagramItemDeleteButtonConfig.popconfirmTitle"
                               [icon]="DiagramItemDeleteButtonConfig.icon"
                               [emitWithoutPopconfirm]="false"
                               (confirm)="onConfirmDelete()"></rappider-button>
            </div>
          </section>
        </ng-container>
      </ng-container>
      <!-- /UI STEP FUNCTION -->
    </section>
  </nz-tab>

  <!-- COMMENTS TAB -->
  <nz-tab *ngIf="diagramSettingsMode?.activeAction === DiagramActionType.Edit"
          [nzTitle]="commentsTemplate">
    <ng-template #commentsTemplate>
      <ng-container *ngTemplateOutlet="tabIcon;context:{
                icon: 'fa-regular fa-comments',
                title: formPanelTabIndex === 1 ? 'Comments' : '',
                tooltip: 'Comments' | translate
              }"></ng-container>
    </ng-template>
    <section class="diagram-settings-wrapper">
      <div class="comments-filter section">
        <p class="mb-2">{{ 'Filter Comments' | translate }}</p>
        <rappider-select [ngModel]="selectedFilters"
                         [options]="allNodes"
                         [settings]="commentsFilterSelectConfig.settings"
                         [placeholder]="commentsFilterSelectConfig.placeholder"
                         (ngModelChange)="commentsFilterChanges($event)"></rappider-select>
      </div>

      <div class="comments">
        <rappider-comment-wrapper [activePerson]="activePerson"
                                  [comments]="comments"
                                  [peopleData]="projectPeopleData"
                                  [isLoading]="commentsLoading"
                                  [scrollingCommentId]="activeCommentId"
                                  (createComment)="onCreateComment($event)"
                                  (editComment)="onEditComment($event)"
                                  (deleteComment)="onDeleteComment($event)"></rappider-comment-wrapper>
      </div>
    </section>
  </nz-tab>
</nz-tabset>
<!-- /COMMENTS TAB -->

<rappider-loading-notification [loading]="isDataTransformationsLoading"></rappider-loading-notification>

<!-- ------------------------------------------------------------------------------------------ -->
<!-- DT MODALS -->
<rappider-modal [(visible)]="!isDataTransformationsLoading && preDataTransformationId && isPreDataTransformationModalVisible"
                [title]="'DATA_TRANSFORMATION_MODULE.PRE_DATA_TRANSFORMATION' | translate"
                [fullScreen]="true"
                (cancelClick)="handlePreDataTransformationModalVisibility(false)"
                (okClick)="handlePreDataTransformationModalVisibility(false)">
  <rappider-data-transformation *ngIf="!isDataTransformationsLoading && preDataTransformationId && isPreDataTransformationModalVisible"
                                [dataTransformationId]="preDataTransformationId"
                                [schema]="preSourceJsonSchema"
                                [targetSchema]="preTargetJsonSchema"></rappider-data-transformation>
</rappider-modal>

<rappider-modal [(visible)]="!isDataTransformationsLoading && postDataTransformationId && isPostDataTransformationModalVisible"
                [title]="'DATA_TRANSFORMATION_MODULE.POST_DATA_TRANSFORMATION' | translate"
                [fullScreen]="true"
                (cancelClick)="handlePostDataTransformationModalVisibility(false)"
                (okClick)="handlePostDataTransformationModalVisibility(false)">
  <rappider-data-transformation *ngIf="!isDataTransformationsLoading && postDataTransformationId && isPostDataTransformationModalVisible"
                                [dataTransformationId]="postDataTransformationId"
                                [schema]="postSourceJsonSchema"
                                [targetSchema]="postTargetJsonSchema"></rappider-data-transformation>
</rappider-modal>
<!-- /DT MODALS -->
<!-- ------------------------------------------------------------------------------------------ -->

<!-- ------------------------------------------------------------------------------------------ -->
<!-- TAB ICON TEMPLATE -->
<ng-template #tabIcon
             let-icon="icon"
             let-title="title"
             let-tooltip="tooltip">
  <div class="tab-icon d-flex"
       [nzTooltipTitle]="tooltip"
       nzTooltipPlacement="bottom"
       nz-tooltip>
    <i [ngClass]="icon"></i>
    <p *ngIf="title">{{ title }}</p>
  </div>
</ng-template>
<!-- / TAB ICON TEMPLATE -->
<!-- ------------------------------------------------------------------------------------------ -->
