/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { Project } from '@rappider/rappider-sdk';

export enum ActionTypes {
  SelectActiveProject = '[ActiveProject] SelectActiveProject',
  SelectActiveProjectFailure = '[ActiveProject] SelectActiveProjectFailure',
  SelectActiveProjectSuccessful = '[ActiveProject] SelectActiveProjectSuccessful',
  SetActiveProjectWithoutAWorkflow = '[ActiveProject] SetActiveProjectWithoutAWorkflow',
  ActiveProjectError = '[ActiveProject] ActiveProjectError',
  ClearActiveProject = '[ActiveProject] ClearActiveProject',
  GetRootFolder = '[ActiveProject] GetRootFolder',
  SetRootFolder = '[ActiveProject] SetRootFolder',
  UpdateClientState = '[ActiveProject] UpdateClientState',
  UpdateClientStateSuccessful = '[ActiveProject] UpdateClientStateSuccessful',
  UpdateActiveProject = '[ActiveProject] UpdateActiveProject',
  UpdateActiveProjectSuccessful = '[ActiveProject] UpdateActiveProjectSuccessful',
}

export class SelectActiveProject implements Action {
  readonly type = ActionTypes.SelectActiveProject;
  constructor(public payload: {
    projectId: string;
    navigateToProjectDetail?: boolean;
    navigateAIAssistantPage?: boolean;
  }) { }
}

export class SelectActiveProjectFailure implements Action {
  readonly type = ActionTypes.SelectActiveProjectFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class SelectActiveProjectSuccessful implements Action {
  readonly type = ActionTypes.SelectActiveProjectSuccessful;
  constructor(public payload: { project: Project }) { }
}


/**
 * This action is used to set the active project without a workflow.
 * This means that there will be no triggered actions or effect functions after setting the active project.
 *
 * @export
 * @class SetActiveProjectWithoutAWorkflow
 * @implements {Action}
 */
export class SetActiveProjectWithoutAWorkflow implements Action {
  readonly type = ActionTypes.SetActiveProjectWithoutAWorkflow;
  constructor(public payload: { project: Project }) { }
}

export class ClearActiveProject implements Action {
  readonly type = ActionTypes.ClearActiveProject;
}

export class ActiveProjectError implements Action {
  readonly type = ActionTypes.ActiveProjectError;
  constructor(public payload: { errorOn: string; error: any }) { }
}

export class GetRootFolder implements Action {
  readonly type = ActionTypes.GetRootFolder;
}

export class SetRootFolder implements Action {
  readonly type = ActionTypes.SetRootFolder;
  constructor(public payload: { rootFolder: any }) { }
}

export class UpdateClientState implements Action {
  readonly type = ActionTypes.UpdateClientState;
  constructor(public payload: { clientState: any }) { }
}

export class UpdateClientStateSuccessful implements Action {
  readonly type = ActionTypes.UpdateClientStateSuccessful;
  constructor(public payload: { project: Project }) { }
}

export type Actions =
  SelectActiveProject
  | SelectActiveProjectFailure
  | SelectActiveProjectSuccessful
  | SetActiveProjectWithoutAWorkflow
  | ActiveProjectError
  | ClearActiveProject
  | GetRootFolder
  | SetRootFolder
  | UpdateClientState
  | UpdateClientStateSuccessful
  ;
