<!-- DEFAULT TEMPLATE -->
<ng-container *ngIf="type === DataSchemaFieldRowType.Default ">
  <div class="table">
    <nz-table #basicTable
              [nzData]="data"
              [nzFooter]="footerTemplate"
              nzFrontPagination=false>
      <thead>
        <tr>
          <th *ngIf="!hideDragable"></th>
          <th>{{ 'SHARED.TITLE' | translate }}</th>
          <th>{{ 'SHARED.NAME' | translate }}</th>
          <th *ngIf="dataSchemaPageType === DataSchemaFieldRowPages.InputDefinition">
            {{'SHARED.UI_SELECTOR' | translate}}
          </th>
          <th>{{ 'SHARED.TYPE' | translate }}</th>
          <th>{{ 'SHARED.IS_ARRAY' | translate}}</th>
          <th *ngIf="hasDefault">{{ 'SHARED.INITIAL_VALUE' | translate}}</th>
          <th>{{ 'SHARED.DESCRIPTION' | translate}}</th>
          <th *ngIf="!deleteButtonField"></th>
        </tr>
      </thead>
      <tbody cdkDropList
             (cdkDropListDropped)="drop($event)">
        <tr *ngFor="let item of basicTable.data; let i = index"
            cdkDrag
            cdkDragLockAxis="y">
          <td *ngIf="!hideDragable"
              cdkDragHandle>
            <span>
              <i class="fas fa-bars"></i>
            </span>
          </td>
          <!-- DATA SCHEMA FIELD -->
          <td>
            <input class="title"
                   nz-input
                   [(ngModel)]="item.title"
                   [disabled]="item.isUpdatable === false"
                   [pattern]="TITLE_CASE_REGEX"
                   #title="ngModel">
            <div *ngIf="showError(i, 'title')">
              <small class="error-area">
                *{{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.TITLE_CANT_BE_BLANK' | translate
                }}
              </small>
            </div>
            <div *ngIf="title.errors?.pattern">
              <small class="error-area">
                *{{
                'ERRORS.PATTERN_MESSAGES.MUST_BE_TITLE_CASE_MESSAGE' | translate
                }}
              </small>
            </div>
            <div *ngIf="isNameDuplicated(item,i)">
              <small class="error-area">
                *{{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.CANT_CREATE_SAME_TITLE' | translate
                }}
              </small>
            </div>
          </td>
          <td>
            <input class="name"
                   nz-input
                   [(ngModel)]="item.name"
                   [disabled]="item.isUpdatable === false"
                   [pattern]="DATA_FIELD_NAME_REGEX"
                   #name="ngModel">
            <div *ngIf="showError(i, 'name')">
              <small class="error-area">
                *{{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.NAME_CANT_BE_BLANK' | translate
                }}
              </small>
            </div>
            <div *ngIf="name.errors?.pattern">
              <small class="error-area">
                *{{
                'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER' | translate
                }}
              </small>
            </div>
            <div *ngIf="isNameDuplicated(item,i)">
              <small class="error-area">
                *{{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.CANT_CREATE_SAME_NAME' | translate
                }}
              </small>
            </div>
          </td>
          <td *ngIf="dataSchemaPageType === DataSchemaFieldRowPages.InputDefinition">
            <rappider-select [(ngModel)]="item.uiDataSelector"
                             [disabled]="item.isUpdatable === false"
                             [options]="defaultUIDataSelectors"
                             [settings]="selectSettings"></rappider-select>
          </td>
          <td>
            <rappider-data-schema-selectbox [disabled]="item.isUpdatable === false"
                                            [dataSchemaElementRow]="item"
                                            [activeProjectId]="activeProjectId"
                                            [crudByAdmin]="crudByAdmin"
                                            [typeOptions]="typeOptions"
                                            [selectSettings]="selectSettings"
                                            [dataSchemaFieldPageType]="dataSchemaPageType"
                                            (searchText)="onSearchTextChange($event)"
                                            (valueChange)="onTypeChange(i)"
                                            (modalSaveOrCancel)="onModalSaveOrCancel()"
                                            (dataSchemaCreated)="onDataSchemaCreated($event, i)">
            </rappider-data-schema-selectbox>
            <div *ngIf="showError(i, 'type')">
              <small class="error-area">
                *
                {{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.TYPE_CANT_BE_BLANK' | translate
                }}
              </small>
            </div>
          </td>
          <td>
            <rappider-select [(ngModel)]="item.isArray"
                             [disabled]="item.isUpdatable === false"
                             [placeholder]="'SHARED.IS_ARRAY'"
                             [options]="isArrayOptions">
            </rappider-select>
          </td>
          <td *ngIf="hasDefault"
              class="default-section">
            <div class="col-11">
              <!-- Not Array Template -->
              <ng-container *ngIf="!isCodemirrorVisible(item); else arrayTmpl">
                <rappider-textbox *ngIf="compareFieldTypeAndComponentType(item, PrimitiveDataSchemas.String)"
                                  [(ngModel)]="item.default"
                                  [disabled]="item.isUpdatable === false"
                                  [placeholder]="'SHARED.INITIAL_VALUE'"></rappider-textbox>
                <ng-container *ngIf="compareFieldTypeAndComponentType(item, PrimitiveDataSchemas.Boolean)">
                  <rappider-checkbox [disabled]="item.isUpdatable === false"
                                     [(ngModel)]="item.default"></rappider-checkbox> &nbsp; {{ item.default }}
                </ng-container>

                <rappider-number-input *ngIf="compareFieldTypeAndComponentType(item, PrimitiveDataSchemas.Number)"
                                       [(ngModel)]="item.default"
                                       [disabled]="item.isUpdatable === false">
                </rappider-number-input>
              </ng-container>
              <!-- Array Template-->
              <ng-template #arrayTmpl>
                <rappider-button nz-popover
                                 nzPopoverPlacement="right"
                                 [disabled]="item.isUpdatable === false"
                                 [text]="'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.EDIT_INITIAL_VALUE'"
                                 [nzPopoverContent]="popoverTmpl"
                                 (click)="openDefaultModal(i)">
                </rappider-button>

                <ng-template #popoverTmpl>
                  <pre *ngIf="item.default"
                       class="popover-content"> <code>{{ item.default | json }}</code></pre>
                  <pre *ngIf="!item.default"
                       class="popover-content">{{ 'No initial value' | translate }}</pre>
                </ng-template>
              </ng-template>
            </div>
            <div class="clear-default col"
                 nz-tooltip
                 [nzTooltipTitle]="'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.SET_INITIAL_VALUE_AS_NULL' | translate">
              <rappider-icon name="close-circle"
                             type="NG_ZORRO"
                             (click)="clearDefault(item)"></rappider-icon>
            </div>
          </td>
          <td>
            <rappider-textbox [(ngModel)]="item.description"
                              [disabled]="item.isUpdatable === false"
                              [placeholder]="'SHARED.DESCRIPTION'"></rappider-textbox>
          </td>
          <td *ngIf="(item.isDeletable || item.isDeletable === undefined) && isDeleteButtonVisible">
            <button nz-button
                    (click)="deleteItem(i)">
              <i class="far fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div *ngIf="isLoading"
         class="loading">
      <span nz-icon
            nzType="loading"
            nzTheme="outline"></span>
      <span> {{'We are fetching the data, please wait...' | translate}}</span>
    </div>
  </div>

  <ng-template #footerTemplate>
    <div *ngIf="isAddButtonVisible"
         class="button-area">
      <rappider-button [icon]="{name: 'fas fa-plus add-icon'}"
                       [text]="addButtonText | translate"
                       (click)="submit()"></rappider-button>
    </div>
  </ng-template>

  <div *ngIf="!hideSaveAndCancelButtons"
       class="form-button-group">
    <button nz-button
            nz-popconfirm
            [nzPopconfirmTitle]="'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.UNSAVED_CANCEL' | translate"
            nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="onCancel()"> {{ cancelButtonText | translate }} </button>

    <button nz-button
            nzType="primary"
            [nzLoading]="buttonLoading"
            (click)="onSave()"> {{ 'SHARED.SAVE' | translate }} </button>
  </div>
</ng-container>
<!-- /DEFAULT TEMPLATE -->

<!-- Output Definition -->
<ng-container *ngIf="type === DataSchemaFieldRowType.OutputDefinition">
  <div class="table">
    <nz-table #basicTable
              [nzData]="data"
              [nzFooter]="footerTemplate"
              nzFrontPagination=false>
      <thead>
        <tr>
          <th></th>
          <th>{{ 'SHARED.TITLE' | translate }}</th>
          <th>
            {{ 'SHARED.FIELD_NAME' | translate }}
          </th>
          <th>{{ 'SHARED.TYPE' | translate }}</th>
          <th>{{ 'SHARED.IS_ARRAY' | translate}}</th>
          <th>{{ 'SHARED.DESCRIPTION' | translate}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody cdkDropList
             (cdkDropListDropped)="drop($event)">
        <tr *ngFor="let item of basicTable.data; let i = index"
            cdkDrag
            cdkDragLockAxis="y">
          <td cdkDragHandle>
            <span>
              <i class="fas fa-bars"></i>
            </span>
          </td>
          <td>
            <input nz-input
                   [(ngModel)]="item.title">
          </td>
          <td>
            <input nz-input
                   [(ngModel)]="item.fieldName"
                   [pattern]="DATA_FIELD_NAME_REGEX"
                   #name="ngModel">
            <div *ngIf="showError(i, 'name')">
              <small class="error-area"> *{{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.NAME_CANT_BE_BLANK' | translate
                }}</small>
            </div>
            <div *ngIf="name.errors?.pattern">
              <small class="error-area">
                *{{
                'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER' | translate
                }}
              </small>
            </div>
            <div *ngIf="isNameDuplicated(item,i,'fieldName')">
              <small class="error-area">
                *{{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.CANT_CREATE_SAME_NAME' | translate
                }}
              </small>
            </div>
          </td>
          <td>
            <ng-container *ngIf="item.uiDataSelector === 'component'; else noComponent">
              <rappider-data-schema-selectbox [dataSchemaElementRow]="item"
                                              [activeProjectId]="activeProjectId"
                                              [crudByAdmin]="crudByAdmin"
                                              [typeOptions]="typeOptionsForComponentSelector"
                                              [selectSettings]="selectSettings"
                                              [dataSchemaFieldPageType]="dataSchemaPageType"
                                              (searchText)="onSearchTextChange($event)"
                                              (valueChange)="onTypeChange(i)"
                                              (dataSchemaCreated)="onDataSchemaCreated($event, i)">
              </rappider-data-schema-selectbox>
            </ng-container>
            <ng-template #noComponent>
              <rappider-data-schema-selectbox [dataSchemaElementRow]="item"
                                              [activeProjectId]="activeProjectId"
                                              [crudByAdmin]="crudByAdmin"
                                              [typeOptions]="typeOptions"
                                              [selectSettings]="selectSettings"
                                              [dataSchemaFieldPageType]="dataSchemaPageType"
                                              (searchText)="onSearchTextChange($event)"
                                              (valueChange)="onTypeChange(i)"
                                              (dataSchemaCreated)="onDataSchemaCreated($event, i)">
              </rappider-data-schema-selectbox>
            </ng-template>
            <div *ngIf="showError(i, 'type')">
              <small class="error-area">
                *
                {{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.TYPE_CANT_BE_BLANK' | translate
                }}
              </small>
            </div>
          </td>
          <td>
            <rappider-select [(ngModel)]="item.isArray"
                             [placeholder]="'SHARED.IS_ARRAY'"
                             [options]="isArrayOptions">
            </rappider-select>
          </td>
          <td>
            <rappider-textbox [(ngModel)]="item.description"
                              [placeholder]="'SHARED.DESCRIPTION'"></rappider-textbox>
          </td>
          <td>
            <button nz-button
                    (click)="deleteItem(i)">
              <i class="far fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div *ngIf="isLoading"
         class="loading">
      <span nz-icon
            nzType="loading"
            nzTheme="outline"></span>
      <span> {{'We are fetching the data, please wait...' | translate}}</span>
    </div>
  </div>
  <ng-template #footerTemplate>
    <div *ngIf="isAddButtonVisible"
         class="button-area">
      <button nz-button
              (click)="submit()">
        <i class="fas fa-plus add-icon"></i>
        {{ addButtonText | translate }}
      </button>
    </div>
  </ng-template>

  <section>
    <div class="form-button-group">
      <button nz-button
              nzDanger
              nz-popconfirm
              [nzPopconfirmTitle]="'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.UNSAVED_CANCEL' | translate"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="onCancel()"> {{ cancelButtonText | translate }} </button>

      <button nz-button
              nzType="primary"
              [nzLoading]="buttonLoading"
              (click)="onSave()"> {{ 'SHARED.SAVE' | translate }} </button>
    </div>
  </section>
</ng-container>
<!-- /Output Definition -->

<!-- Input Definition -->
<ng-container *ngIf="type === DataSchemaFieldRowType.InputDefinition">
  <div class="input-table-wrapper"
       cdkDropList
       (cdkDropListDropped)="drop($event)">
    <section *ngFor="let item of data; let i = index"
             class="input-table"
             cdkDrag
             cdkDragLockAxis="y">

      <div class="drag-placeholder"
           *cdkDragPlaceholder></div>

      <div class="drag"
           cdkDragHandle>
        <span>
          <i class="fas fa-bars"></i>
        </span>
      </div>

      <div class="item-wrapper">
        <div class="row mb-2">

          <div class="col">
            <h6>{{ 'SHARED.CATEGORY' | translate }}</h6>
            <input nz-input
                   [(ngModel)]="item.category">
          </div>

          <div class="col">
            <h6>{{ 'SHARED.TITLE' | translate }}</h6>
            <input class="col"
                   nz-input
                   [(ngModel)]="item.title">
          </div>

          <div class="col">
            <h6>{{ 'SHARED.FIELD_NAME' | translate }}</h6>
            <input class="col"
                   nz-input
                   [(ngModel)]="item.fieldName"
                   [pattern]="DATA_FIELD_NAME_REGEX"
                   #name="ngModel">
            <div *ngIf="showError(i, 'name')">
              <small class="error-area"> *{{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.NAME_CANT_BE_BLANK' | translate
                }}</small>
            </div>
            <div *ngIf="name.errors?.pattern">
              <small class="error-area">
                *{{
                'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER' | translate
                }}
              </small>
            </div>
            <div *ngIf="isNameDuplicated(item,i,'fieldName')">
              <small class="error-area">
                *{{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.CANT_CREATE_SAME_NAME' |
                translate
                }}
              </small>
            </div>
          </div>

          <div class="col">
            <h6>{{ 'SHARED.COMPONENT_TYPE' | translate }}</h6>
            <rappider-select [(ngModel)]="item.uiDataSelector"
                             [options]="defaultUIDataSelectors"
                             [settings]="selectSettings"></rappider-select>
          </div>

        </div>

        <div class="row mb-2">

          <div class="col">
            <h6>{{ 'SHARED.TYPE' | translate }}</h6>
            <rappider-data-schema-selectbox [dataSchemaElementRow]="item"
                                            [activeProjectId]="activeProjectId"
                                            [typeOptions]="typeOptions"
                                            [crudByAdmin]="crudByAdmin"
                                            [selectSettings]="selectSettings"
                                            [dataSchemaFieldPageType]="dataSchemaPageType"
                                            (searchText)="onSearchTextChange($event)"
                                            (valueChange)="onTypeChange(i)"
                                            (dataSchemaCreated)="onDataSchemaCreated($event, i)">
            </rappider-data-schema-selectbox>
            <div *ngIf="showError(i, 'type')">
              <small class="error-area">
                *
                {{
                'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.TYPE_CANT_BE_BLANK' | translate
                }}
              </small>
            </div>
          </div>

          <div class="col">
            <h6>{{ 'SHARED.IS_ARRAY' | translate }}</h6>
            <rappider-select [(ngModel)]="item.isArray"
                             [placeholder]="'SHARED.IS_ARRAY'"
                             [options]="isArrayOptions">
            </rappider-select>
          </div>

          <div class="col-6">
            <h6>{{ 'SHARED.DESCRIPTION' | translate }}</h6>
            <rappider-textarea [(ngModel)]="item.description"
                               [placeholder]="'SHARED.DESCRIPTION'"
                               [minRows]="4"
                               [borderSettings]="item?.borderSettings"
                               [customColorSettings]="item?.customColorSettings"
                               [customSizeSettings]="item?.customSizeSettings"
                               [shadowSettings]="item?.shadowSettings"
                               [marginSettings]="item?.marginSettings"
                               [paddingSettings]="item?.paddingSettings"></rappider-textarea>
          </div>

        </div>
      </div>

      <div class="delete-button">
        <button nz-button
                (click)="deleteItem(i)">
          <i class="far fa-trash"></i>
        </button>
      </div>
    </section>
    <div *ngIf="isAddButtonVisible"
         class="add-button">
      <button nz-button
              (click)="submit()">
        <i class="fas fa-plus add-icon"></i>
        {{ addButtonText | translate }}
      </button>
    </div>
  </div>

  <ng-template #footerTemplate>
    <div *ngIf="isAddButtonVisible"
         class="button-area">
      <button nz-button
              (click)="submit()">
        <i class="fas fa-plus add-icon"></i>
        {{ addButtonText | translate }}
      </button>
    </div>
  </ng-template>

  <div class="form-button-group">
    <button nz-button
            nz-popconfirm
            [nzPopconfirmTitle]="'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.UNSAVED_CANCEL' | translate"
            nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="onCancel()"> {{ cancelButtonText | translate }} </button>

    <button nz-button
            nzType="primary"
            [nzLoading]="buttonLoading"
            (click)="onSave()"> {{ 'SHARED.SAVE' | translate }} </button>
  </div>
</ng-container>
<!-- Input Definition -->

<!-- modal template for initial value code mirror -->
<ng-template #initialValueModalTemplate>
  <rappider-spin [spinning]="!isDefaultCodemirrorVisible">
    <rappider-code-editor *ngIf="isDefaultCodemirrorVisible"
                          [(ngModel)]="localCodemirrorValue"
                          [settings]="codeMirrorSettings"></rappider-code-editor>
  </rappider-spin>
</ng-template>