import { DiagramItemType } from '../../diagram-item-type';

export const DiagramUIStepFunctionWithRelationsItemConfig = {
  type: DiagramItemType.UIStepFunctionWithRelations,
  defaultText: DiagramItemType.UIStepFunctionWithRelations,
  baseType: 'ellipse',
  defaultWidth: 3,
  defaultHeight: 5,
  allowEditText: false
};
