<section class="toolbar">
  <div class="left-side">
    <rappider-button-list [buttons]="DiagramButtonConfig"
                          [direction]="'horizontal'"
                          [gap]="'10px'"
                          (buttonClick)="onButtonClick($event)">
    </rappider-button-list>
  </div>

  <div class="right-side">
    <rappider-icon [name]="WorkflowDiagramInfoIcon.name"
                   [color]="WorkflowDiagramInfoIcon.color"
                   (click)="infoModalVisibility()"></rappider-icon>
    <rappider-icon [name]="menuVisibility ? MenuUnfoldIcon.name : MenuFoldIcon.name"
                   [type]="MenuFoldIcon.type"
                   (click)="onChangeMenuVisibility()"></rappider-icon>
  </div>
</section>
<rappider-modal [visible]="infoModalVisible"
                [title]="WorkflowDiagramInfoModalConfig.title"
                [okText]="WorkflowDiagramInfoModalConfig.okText"
                [cancelText]="WorkflowDiagramInfoModalConfig.cancelText"
                [footer]="WorkflowDiagramInfoModalConfig.footer"
                (cancelClick)="infoModalVisibility()">
  <nz-collapse nzGhost>
    <nz-collapse-panel *ngFor="let InfoModalContent of WorkflowDiagramInfoModalContents"
                       [nzHeader]="InfoModalContent.name">
      <p>{{ InfoModalContent.content }}</p>
    </nz-collapse-panel>
  </nz-collapse>
</rappider-modal>
