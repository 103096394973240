import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rappider-scope-of-work',
  templateUrl: './scope-of-work.component.html',
  styleUrls: ['./scope-of-work.component.scss']
})
export class ScopeOfWorkComponent implements OnInit {

  ngOnInit(): void {
    console.log('Component initialized.');
  }

}
