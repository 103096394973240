import { Component, Input, } from '@angular/core';
import {
  AvatarSize, HeadingComponentConfig, HeadingType, ImageComponentConfig,
  TextComponentConfig
} from '@rappider/rappider-components/utils';
import { StepStatus } from 'libs/components/src/lib/utils/steps/step-status.enum';
import { THEME_FORM_CONFIG } from '../../../utils/example-theme-form.config';

@Component({
  selector: 'rappider-example-template-one',
  templateUrl: './example-template-one.component.html',
  styleUrls: ['./example-template-one.component.scss']
})
export class ExampleTemplateOneComponent {

  @Input() showTooltip = true;

  THEME_FORM_CONFIG = THEME_FORM_CONFIG;

  current = 0;
  formData: any;

  heading: HeadingComponentConfig = {
    content: 'LOREM',
    type: HeadingType.H1
  };

  headingConfigs: { [key: string]: HeadingComponentConfig } = {
    steps: { content: 'Steps', type: HeadingType.H2 },
    listGrid: { content: 'List Grid', type: HeadingType.H2 },
    form: { content: 'Form', type: HeadingType.H2 }
  };

  paragraph: TextComponentConfig = {
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis pulvinar lorem in facilisis viverra. Aenean a nulla sed.'
  };

  imageComponentConfig: ImageComponentConfig = {
    source: 'https://images.unsplash.com/photo-1599009434802-ca1dd09895e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    customSizeSettings: {
      width: '75%',
      height: '100%'
    }
  };

  stepsComponentConfig = {
    steps: [
      { title: '' },
      { title: '' },
      { title: '' }
    ],
  };

  cardFourComponentConfigs = [
    {
      avatar: {
        size: AvatarSize.Large,
        icon: {
          name: 'fa-solid fa-cat',
          type: 'FONT_AWESOME'
        }
      },
      title: 'Ide tortor ute eros neque mollis to'
    },
    {
      avatar: {
        size: AvatarSize.Large,
        icon: {
          name: 'fa-solid fa-cat',
          type: 'FONT_AWESOME'
        }
      },
      title: 'Sed to lacus orci, tincidunt  dui vel porta'
    },
    {
      avatar: {
        size: AvatarSize.Large,
        icon: {
          name: 'fa-solid fa-cat',
          type: 'FONT_AWESOME'
        }
      },
      title: ' Proin faucibus leo non aliquet volutpat'
    }
  ];

  listGridComponentConfig = {
    data: [
      {
        name: 'Jane Doe',
        age: 20,
        address: 'London No. 1 Lake Park'
      },
      {
        name: 'Sarah Holland',
        age: 21,
        address: 'London No. 1 Lake Park'
      },
      {
        name: 'John Lawrence',
        age: 22,
        address: 'London No. 1 Lake Park'
      },
      {
        name: 'Jacop Gomez',
        age: 23,
        address: 'London No. 1 Lake Park'
      }
    ],
    config: {
      columns: [
        {
          title: 'Name',
          fieldName: 'name',
          type: 'text'
        },
        {
          title: 'Age',
          fieldName: 'age',
          type: 'number'
        },
        {
          title: 'Address',
          fieldName: 'address',
          type: 'text'
        }
      ],
      showPagination: true,
      pageSize: 2,
      nzFrontPagination: true
    }
  };

  commentComponentConfig = [
    {
      id: '1',
      author: 'Jane Doe',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
      likeCount: 26,
      dislikeCount: 3,
      isLiked: false,
      isDisliked: false,
      children: [
        {
          id: '2',
          author: 'Joe Doe',
          content: 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.',
          likeCount: 36,
          dislikeCount: 6,
          isLiked: false,
          isDisliked: false,
          children: []
        }
      ]
    }
  ];


  increaseStep() {
    this.stepsComponentConfig.steps = this.stepsComponentConfig.steps.map((step, index) => {
      if (index === this.current) {
        return {
          ...step,
          status: StepStatus.Finish
        };
      } else if (index === this.current + 1) {
        return {
          ...step,
          status: StepStatus.Process
        };
      } else {
        return step;
      }
    });
    this.current++;
  }

  decreaseStep() {
    this.stepsComponentConfig.steps = this.stepsComponentConfig.steps.map((step, index) => {
      if (index === this.current) {
        return {
          ...step,
          status: StepStatus.Wait
        };
      } else if (index === this.current - 1) {
        return {
          ...step,
          status: StepStatus.Process
        };
      } else {
        return step;
      }
    }
    );
    this.current--;
  }
}
