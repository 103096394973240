import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProjectExportInterface, ProjectInterface, ProjectVersionApi, ProjectVersionInterface } from '@rappider/api-sdk';
import { ActionResponse, BreadcrumbOption, ButtonComponentConfig, ButtonType, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { ProjectExport, ProjectExportWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { RouterStateService, StringUtilityService } from '@rappider/services';
import { LAST_PROJECT_EXPORT_LIST_CONFIG, PROJECT_EXPORT_LIST_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, IconType, PATH_DEFINITIONS, ProjectExportStatus } from '@rappider/shared/definitions';
import { combineLatest, Subscription } from 'rxjs';
import { LoadModule, DeleteProjectVersionId } from '../../states/project-export-state/project-export.actions';
import { GetProjectVersions } from '../../states/project-versions-state/project-version.actions';
import { orderBy } from 'lodash';

@Component({
  selector: 'rappider-project-version-detail',
  templateUrl: './project-version-detail.component.html',
  styleUrls: ['./project-version-detail.component.scss']
})
export class ProjectVersionDetailComponent implements OnInit, OnDestroy {

  /* exported version list grid configs */
  PROJECT_EXPORT_LIST_CONFIG = PROJECT_EXPORT_LIST_CONFIG;
  LAST_PROJECT_EXPORT_LIST_CONFIG = LAST_PROJECT_EXPORT_LIST_CONFIG;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* component title */
  title: string | string[] | BreadcrumbOption[];
  /* active project data */
  activeProject: ProjectWithRelations;
  /* project version informations */
  projectVersionId: string; /* from url */
  projectVersion: ProjectVersionInterface; /* getting project version by id */
  projectVersions: ProjectVersionInterface[];
  /* latest project export */
  latestProjectExport: ProjectExportWithRelations | ProjectWithRelations;
  /* project export list */
  projectExports: ProjectExportWithRelations[];
  /* Subscriptions */
  subscriptions: Subscription[];
  /* button icon */
  projectExportPageButton: ButtonComponentConfig = {
    text: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.EXPORT_PROJECT',
    type: ButtonType.Default,
    icon: {
      name: 'far fa-file-export'
    }
  };
  isExportDetailsCollapsed = false;
  exportDetailsTogglerButton = {
    text: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.HIDE_EXPORT_DETAILS',
    type: ButtonType.Default,
    icon: {
      name: 'up',
      type: IconType.NgZorro
    }
  };
  displayToolbar = false;
  displayToolbarBackButton = false;
  exportStatusFlag = false;
  status: string;

  // If there is no project version Id on the URL; we will set this flag so that we can navigate to the last project version
  invalidProjectVersionIdOnURL = false;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private projectVersionApi: ProjectVersionApi,
    private routerStateService: RouterStateService
  ) { }

  ngOnInit() {
    this.subscriptions = [];
    this.getProjectVersionIdFromURL();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /* getting project version id from url params */
  getProjectVersionIdFromURL() {
    this.projectVersionId = this.activatedRoute.snapshot.params.projectVersionId;
    if (!this.projectVersionId) {
      this.invalidProjectVersionIdOnURL = true;
    }
    this.subscribeToData();
  }

  /**
   * Subscribe to all data
   *
   * @memberof ProjectVersionListComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectExportsData(),
      this.subscribeToProjectVersions()
    ];
  }

  /**
   * get project exports data field
   *
   * @param {string} projectId
   * @memberof ProjectVersionListComponent
   */
  subscribeToProjectExportsData() {
    return combineLatest([
      this.store.select(state => state.projectCodeGeneration?.data),
      this.activatedRoute.params
    ]).subscribe(([data, activeRoute]) => {
      if (data) {
        this.projectExports = orderBy(data?.filter((projectExport) => projectExport.projectVersionId === activeRoute.projectVersionId)
          .map((exportData, index: number) => ({
            ...exportData,
            versionNumber: this.projectVersions?.find(projectVersion => projectVersion.id === exportData.projectVersionId)?.versionNumber,
            isLatestExport: index === 0, /* TODO: fix this field when pagination added to this component */
            appType: StringUtilityService.toPascalCase(this.activeProject.appType),
            frontendFramework: StringUtilityService.toPascalCase(this.activeProject.frontendFramework),
            backendFramework: StringUtilityService.toPascalCase(this.activeProject.backendFramework)
          })), 'createdDate', 'desc');
        /* get latest project exports */
        this.latestProjectExport = {
          ...this.projectExports[0],
          completedStepCount: this.projectExports[0]?.status === 'successful' ? 4 : 2,
          totalStepCount: 4,
        }; /* TODO: fix this variable when pagination added to this component */
      } else {
        this.projectExports = [];
        this.latestProjectExport = null;
      }
    });
  }

  /**
   * get active project from state and get project versions by active project id
   *
   * @return {*}
   * @memberof ProjectVersionListComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
      if (activeProject) {
        this.getProjectVersionById(this.projectVersionId);
        this.getProjectExports();
        this.getProjectVersions();
      }
    });
  }

  /**
 * get project versions data from state
 *
 * @return {*}
 * @memberof ProjectVersionListComponent
 */
  subscribeToProjectVersions() {
    return this.store.select(state => state.projectVersion.data).subscribe((projectVersions: ProjectVersionInterface[]) => {
      if (projectVersions) {
        this.projectVersions = projectVersions;
        this.getProjectVersion();
      }
    });
  }

  getProjectVersion() {
    if (this.projectVersions && this.projectVersionId) {
      this.projectVersion = this.projectVersions.find(projectVersion => projectVersion.id === this.projectVersionId);
      this.compareTheVersions();
    } else if (this.invalidProjectVersionIdOnURL && this.projectVersions?.length) {
      const lastProjectVersion = this.getLastProjectVersion();
      this.projectVersion = lastProjectVersion;
      this.projectVersionId = lastProjectVersion.id;
      this.routerStateService.navigate(
        `${PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_DETAIL}/${this.projectVersionId}`
      );
      this.compareTheVersions();
    }
  }

  compareTheVersions() {
    if (this.projectVersions && this.projectVersion) {
      const lastProjectVersion = this.getLastProjectVersion();
      if (lastProjectVersion.createdDate === this.projectVersion.createdDate) {
        this.exportStatusFlag = false;
      } else {
        this.exportStatusFlag = true;
      }
    } else {
      // sometimes version is undefined, so we should check this condition
      this.exportStatusFlag = true;
    }
  }

  getLastProjectVersion() {
    const sortedVersions = this.projectVersions.slice().sort((a, b) =>
      new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
    );
    return sortedVersions[sortedVersions.length - 1];
  }

  getProjectVersions() {
    this.store.dispatch(new GetProjectVersions());
  }

  /**
   * Get Project Exports
   *
   * @memberof ProjectVersionListComponent
   */
  getProjectExports() {
    this.store.dispatch(new LoadModule());
  }

  getProjectVersionById(projectVersionId: string) {
    this.projectVersionApi.findById(projectVersionId).subscribe((projectVersion: ProjectVersionInterface) => {
      this.mainTitle = {
        content: 'PROJECT_MODULE.PROJECT_VERSIONS_COMPONENT.PROJECT_VERSIONS',
        type: defaultToolbarTitleHeadingSize
      };
      this.title = [
        {
          label: this.activeProject.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
        },
        {
          label: 'PROJECT_MODULE.PROJECT_VERSIONS_COMPONENT.PROJECT_VERSIONS',
          redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_LIST
        },
        {
          label: projectVersion.versionNumber
        }
      ];
    });
  }

  onNavigateToProjectExportPage() {
    const exportPageURL = `${PATH_DEFINITIONS.PROJECTS.PROJECT_EXPORT}/${this.projectVersionId}`;
    this.routerStateService.navigate(exportPageURL);
  }

  onStatus(status) {
    this.status = status;
  }

}
