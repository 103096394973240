export enum Permission {
  ReadCategories = 'category:read',
  CreateCategory = 'category:create',
  EditCategory = 'category:edit',
  DeleteCategory = 'category:delete',

  ReadComments = 'comment:read',
  CreateComment = 'comment:create',
  EditComment = 'comment:edit',
  DeleteComment = 'comment:delete',

  ReadProjects = 'project:read',
  CreateProject = 'project:create',
  EditProject = 'project:edit',
  DeleteProject = 'project:delete',

  ReadMessageTemplates = 'message-template:read',
  CreateMessageTemplate = 'message-template:create',
  EditMessageTemplate = 'message-template:edit',
  DeleteMessageTemplate = 'message-template:delete',

  ReadDataSchemas = 'data-schema:read',
  CreateDataSchema = 'data-schema:create',
  EditDataSchema = 'data-schema:edit',
  DeleteDataSchema = 'data-schema:delete',

  ReadPages = 'page:read',
  CreatePage = 'page:create',
  EditPage = 'page:edit',
  DeletePage = 'page:delete',

  ReadLayouts = 'layout:read',
  CreateLayout = 'layout:create',
  EditLayout = 'layout:edit',
  DeleteLayout = 'layout:delete',

  ReadModules = 'module:read',
  CreateModule = 'module:create',
  EditModule = 'module:edit',
  DeleteModule = 'module:delete',

  ReadEnvironmentVariables = 'environment-variable:read',
  CreateEnvironmentVariable = 'environment-variable:create',
  EditEnvironmentVariable = 'environment-variable:edit',
  DeleteEnvironmentVariable = 'environment-variable:delete',

  ReadDataModels = 'data-model:read',
  CreateDataModel = 'data-model:create',
  EditDataModel = 'data-model:edit',
  DeleteDataModel = 'data-model:delete',

  ReadEndpoints = 'endpoint:read',
  CreateEndpoint = 'endpoint:create',
  EditEndpoint = 'endpoint:edit',
  DeleteEndpoint = 'endpoint:delete',

  ReadUIDataStores = 'ui-data-store:read',
  CreateUIDataStore = 'ui-data-store:create',
  EditUIDataStore = 'ui-data-store:edit',
  DeleteUIDataStore = 'ui-data-store:delete',

  ReadProjectSettings = 'project-setting:read',
  CreateProjectSetting = 'project-setting:create',
  EditProjectSetting = 'project-setting:edit',
  DeleteProjectSetting = 'project-setting:delete',

  ReadProjectPackages = 'project-package:read',
  CreateProjectPackages = 'project-package:create',
  EditProjectPackages = 'project-package:edit',
  DeleteProjectPackages = 'project-package:delete',

  ReadScopeOfWorks = 'scope-of-work:read',
  CreateScopeOfWork = 'scope-of-work:create',
  EditScopeOfWork = 'scope-of-work:edit',
  DeleteScopeOfWork = 'scope-of-work:delete',

  ReadProjectFiles = 'project-file:read',
  CreateProjectFile = 'project-file:create',
  EditProjectFile = 'project-file:edit',
  DeleteProjectFile = 'project-file:delete',

  ReadProjectFolders = 'project-folder:read',
  CreateProjectFolder = 'project-folder:create',
  EditProjectFolder = 'project-folder:edit',
  DeleteProjectFolder = 'project-folder:delete',

  ReadExternalScripts = 'external-script:read',
  CreateExternalScript = 'external-script:create',
  EditExternalScript = 'external-script:edit',
  DeleteExternalScript = 'external-script:delete',

  ReadProjectRoles = 'project-role:read',
  CreateProjectRole = 'project-role:create',
  EditProjectRole = 'project-role:edit',
  DeleteProjectRole = 'project-role:delete',

  ReadProjectThemes = 'project-theme:read',
  CreateProjectTheme = 'project-theme:create',
  EditProjectTheme = 'project-theme:edit',
  DeleteProjectTheme = 'project-theme:delete',

  ReadUIDataEvents = 'ui-data-event:read',
  CreateUIDataEvent = 'ui-data-event:create',
  EditUIDataEvent = 'ui-data-event:edit',
  DeleteUIDataEvent = 'ui-data-event:delete',

  ReadUIDataUpdateFunctions = 'ui-data-update-function:read',
  CreateUIDataUpdateFunction = 'ui-data-update-function:create',
  EditUIDataUpdateFunction = 'ui-data-update-function:edit',
  DeleteUIDataUpdateFunction = 'ui-data-update-function:delete',

  ReadUIWorkflowStepFunctions = 'ui-workflow-step-function:read',
  CreateUIWorkflowStepFunction = 'ui-workflow-step-function:create',
  EditUIWorkflowStepFunction = 'ui-workflow-step-function:edit',
  DeleteUIWorkflowStepFunction = 'ui-workflow-step-function:delete',

  ReadWorkflowStepFunctions = 'workflow-step-function:read',
  CreateWorkflowStepFunction = 'workflow-step-function:create',
  EditWorkflowStepFunction = 'workflow-step-function:edit',
  DeleteWorkflowStepFunction = 'workflow-step-function:delete',

  ReadSnippetDefinition = 'snippet-definition:read',
  CreateSnippetDefinition = 'snippet-definition:create',
  EditSnippetDefinition = 'snippet-definition:edit',
  DeleteSnippetDefinition = 'snippet-definition:delete',

  ReadWorkflowEvents = 'workflow-event:read',
  CreateWorkflowEvent = 'workflow-event:create',
  EditWorkflowEvent = 'workflow-event:edit',
  DeleteWorkflowEvent = 'workflow-event:delete',

  ReadProjectVersion = 'project-version:read',
  CreateProjectVersion = 'project-version:create',

  ReadUserInvitations = 'user-invitation:read',
  CreateUserInvitation = 'user-invitation:create',
  CancelUserInvitation = 'user-invitation:cancel',

  DeployProject = 'project:deploy',
  BuildProject = 'project:build', /* old feature: export */

  Admin = 'admin',
}
