import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NewProjectModelRelation, ProjectModel, ProjectModelWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { BreadcrumbOption, CrudFormSelectItem, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { EditFormService, NotificationService } from '@rappider/services';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { KeyValue } from '@angular/common';
import { CreateProjectModelRelation } from 'libs/project/src/lib/states/project-model-relation-state/project-model-relation.actions';
import { PROJECT_MODEL_RELATION_CREATE_CONFIG } from '../../utils/project-model-relation-create.config';
import { THROUGH_MODEL_FORM_ITEM } from '../../utils/through-model-form-item.config';
import { ProjectModelRelationCreateOrEditFieldName } from '../../utils/project-model-relation-form-field-name.enum';
import { ProjectModelRelationType } from '@rappider/shared/configs';
import { nameExistsValidator } from 'libs/shared/src/lib/functions/name-exists-validator';
import { getProjectModelsWithFields } from '../../utils/get-project-model-with-fields';

@Component({
  selector: 'rappider-project-model-relation-create',
  templateUrl: './project-model-relation-create.component.html',
  styleUrls: ['./project-model-relation-create.component.scss']
})
export class ProjectModelRelationCreateComponent implements OnInit, OnDestroy {
  /* edit form config */
  PROJECT_MODEL_RELATION_CREATE_CONFIG = PROJECT_MODEL_RELATION_CREATE_CONFIG;
  /* through model form item */
  THROUGH_MODEL_FORM_ITEM = THROUGH_MODEL_FORM_ITEM;

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.PROJECT_MODEL_RELATION_CREATE_COMPONENT.ADD_MODEL_RELATION',
    type: defaultToolbarTitleHeadingSize
  };
  /* page breadcrumb */
  title: string | string[] | BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* project model name */
  projectModelName: string;
  /* source model id */
  sourceModelId: string;
  /* select options for project models */
  projectModelSelectOptions: { key: string; value: string }[] = [];
  /* source  model */
  sourceModel: ProjectModel;
  /* project models */
  projectModels: ProjectModelWithRelations[];
  /* selet options for keyTo and keyFrom form items */
  keyToAndKeyFromSelectOptions: KeyValue<string, string>[];
  /* default data for create */
  defaultProjectModelRelationData: NewProjectModelRelation = {
    name: '',
    type: ProjectModelRelationType.HasMany,
    sourceModelId: '',
    keyFromId: '',
    targetModelId: '',
    keyToId: ''
  };
  /* project model ids mapped to project model field key-values */
  projectModelFieldMapping = new Map<string, KeyValue<string, string>[]>();
  /*  */
  activeRelationType: ProjectModelRelationType = ProjectModelRelationType.BelongsTo;

  isSubmitButtonLoading: boolean;
  displayToolbar = false;
  displayToolbarBackButton = false;
  fieldsAndRelations: string[];

  isProjectModelsLoading: boolean;
  isProjectModelsLoaded: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private editFormService: EditFormService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getSourceModelIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getSourceModelIdFromUrl() {
    this.sourceModelId = this.activatedRoute.snapshot.params['projectModelId'];
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModels(),
      this.subscribeToProjectModelRelationLoading(),
      this.subscribeToProjectModelLoading(),
      this.subscribeToProjectModelLoaded()
    ];
  }

  /**
   * subscribe to active project to get project id and project name
   *
   * @return {*}
   * @memberof ProjectModelRelationCreateComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        this.activeProject = activeProject;
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToProjectModels() {
    return this.store.select(<any>getProjectModelsWithFields).subscribe((data) => {
      this.projectModels = data?.projectModels;
      this.getSourceModelBySourceModelId();
      const activeProjectModel = data?.projectModels?.find(projectModel => projectModel.id === this.sourceModelId);
      const activeProjectModelFields = activeProjectModel?.fields?.map(field => field?.name);
      const activeProjectModelRelations = data?.projectModelRelations?.filter(relation => relation?.sourceModelId === activeProjectModel?.id).map(relation => relation?.name);
      if (activeProjectModelFields || activeProjectModelRelations) {
        const activeProjectModelFieldsAndRelations = [
          ...activeProjectModelFields || [],
          ...activeProjectModelRelations || []
        ];
        this.fieldsAndRelations = activeProjectModelFieldsAndRelations;
      }
      this.projectModelSelectOptions = data?.projectModels?.map((projectModel: ProjectModel) => ({
        key: projectModel?.name,
        value: projectModel?.id
      })) ?? [];

      data?.projectModels?.forEach(projectModel => {
        this.projectModelFieldMapping.set(projectModel?.id, projectModel?.fields?.map(field => ({ key: field.name, value: field.id })));
      });

      this.setSourceAndTargetModelSelectOptions();
      this.setInitialData();
    });
  }

  subscribeToProjectModelRelationLoading() {
    return this.store.select(state => state.projectModelRelation?.loading).subscribe((loading: boolean) => {
      this.isSubmitButtonLoading = loading;
    });
  }

  subscribeToProjectModelLoading() {
    return this.store.select(state => state.projectModel?.loading).subscribe((loading: boolean) => {
      this.isProjectModelsLoading = loading;
    });
  }

  subscribeToProjectModelLoaded() {
    return this.store.select(state => state.projectModel.isLoaded).subscribe(isLoaded => {
      this.isProjectModelsLoaded = isLoaded;
    });
  }

  /**
   * set page title
   *
   * @memberof ProjectModelRelationCreateComponent
   */
  setTitle() {
    if (this.activeProject && this.sourceModel) {
      this.title = [
        {
          label: this.activeProject.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
        },
        {
          label: this.sourceModel.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${this.sourceModel?.id}`, queryParams: { tab: 'fields' }
        },
        {
          label: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_RELATION_CREATE.PAGE_TITLE
        }
      ];
    } else {
      this.title = [
        PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_RELATION_CREATE.PAGE_TITLE
      ];
    }
  }

  getSourceModelBySourceModelId() {
    this.sourceModel = this.projectModels?.find(projectModel => projectModel.id === this.sourceModelId);
    this.setTitle();
  }

  setInitialData() {
    this.defaultProjectModelRelationData = {
      ...this.defaultProjectModelRelationData,
      type: this.activeRelationType,
      sourceModelId: this.sourceModel?.id
    };
    this.setKeyFromSelectOptions(this.sourceModel?.id);
  }

  setSourceAndTargetModelSelectOptions() {
    /* gets the target model select options */
    const sourceModelFormItem = <CrudFormSelectItem>(
      this.PROJECT_MODEL_RELATION_CREATE_CONFIG.items.find(
        item => item.fieldName === ProjectModelRelationCreateOrEditFieldName.SourceModelId
      )
    );
    /* set target model form item options */
    sourceModelFormItem.options = [...this.projectModelSelectOptions];

    /* gets the target model select options */
    const targetModelFormItem = <CrudFormSelectItem>(
      this.PROJECT_MODEL_RELATION_CREATE_CONFIG.items.find(
        item => item.fieldName === ProjectModelRelationCreateOrEditFieldName.TargetModelId
      )
    );
    /* set target model form item options */
    targetModelFormItem.options = [...this.projectModelSelectOptions];
  }

  onFieldValueChange(data: any) {
    if (data.type) {
      this.activeRelationType = data.type;
      this.setCreateConfigByRelationType();
    } else if (data.throughModelId) {
      this.setThroughModelFieldsSelectOptions(data.throughModelId);
    } else if (data.sourceModelId) {
      /* if type is not hasManyThrough set key from select options */
      if (this.activeRelationType !== ProjectModelRelationType.HasManyThrough) {
        this.setKeyFromSelectOptions(data.sourceModelId);
      }
    } else if (data.targetModelId) {
      /* if type is not hasManyThrough set key to select options */
      if (this.activeRelationType !== ProjectModelRelationType.HasManyThrough) {
        this.setKeyToSelectOptions(data.targetModelId);
      }
    }
  }

  setKeyFromSelectOptions(sourceModelId: string) {
    const keyFromFormItem = <CrudFormSelectItem>(
      this.PROJECT_MODEL_RELATION_CREATE_CONFIG.items.find(
        item => item.fieldName === ProjectModelRelationCreateOrEditFieldName.KeyFromId
      )
    );
    keyFromFormItem.options = this.projectModelFieldMapping.get(sourceModelId);
    this.PROJECT_MODEL_RELATION_CREATE_CONFIG = { ...this.PROJECT_MODEL_RELATION_CREATE_CONFIG };
  }

  setKeyToSelectOptions(targetModelId: string) {
    const keyToFormItem = <CrudFormSelectItem>(
      this.PROJECT_MODEL_RELATION_CREATE_CONFIG.items.find(
        item => item.fieldName === ProjectModelRelationCreateOrEditFieldName.KeyToId
      )
    );
    keyToFormItem.options = this.projectModelFieldMapping.get(targetModelId);
    this.PROJECT_MODEL_RELATION_CREATE_CONFIG = { ...this.PROJECT_MODEL_RELATION_CREATE_CONFIG };
  }

  setThroughModelFieldsSelectOptions(throughModelId: string) {
    this.setKeyToSelectOptions(throughModelId);
    this.setKeyFromSelectOptions(throughModelId);
  }

  setCreateConfigByRelationType() {
    if (this.activeRelationType === ProjectModelRelationType.HasManyThrough) {
      /* set through model's select options */
      const throughModelSelectOptions = this.projectModelSelectOptions;
      this.THROUGH_MODEL_FORM_ITEM.options = throughModelSelectOptions;

      /* add through models to config */
      this.PROJECT_MODEL_RELATION_CREATE_CONFIG = this.editFormService.addNewItemToConfig(
        this.THROUGH_MODEL_FORM_ITEM,
        this.PROJECT_MODEL_RELATION_CREATE_CONFIG
      );

      this.PROJECT_MODEL_RELATION_CREATE_CONFIG = {
        ...this.PROJECT_MODEL_RELATION_CREATE_CONFIG
      };
    } else {
      /* remove through model form item from config */
      this.PROJECT_MODEL_RELATION_CREATE_CONFIG = this.editFormService.deleteItemFromConfigByFieldName(
        THROUGH_MODEL_FORM_ITEM.fieldName,
        this.PROJECT_MODEL_RELATION_CREATE_CONFIG
      );
    }
  }

  /**
   * project model relation create form submit function
   *
   * @param {ProjectModelRelationInterface} projectModelRelation
   * @memberof ProjectModelRelationCreateComponent
   */
  onFormSubmit(projectModelRelation: NewProjectModelRelation) {
    if (this.fieldsAndRelations.includes(projectModelRelation.name)) {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'Name Already Exists Field Or Relation'
      );
    } else {
      this.store.dispatch(new CreateProjectModelRelation({ projectModelRelation: projectModelRelation, sourceModelId: this.sourceModelId, relationedTypeId: this.sourceModel.relationedTypeId, navigateAfterCreate: true }));
    }
  }

}
