import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, featureKey } from './client-local-state.reducer';

// Select the feature state
export const selectClientLocalState = createFeatureSelector<State>(featureKey);

// Select data
export const selectClientLocalStates = createSelector(
  selectClientLocalState,
  (state) => state.data
);

// Select loading state
export const selectClientLocalStateLoading = createSelector(
  selectClientLocalState,
  (state) => state.isLoading
);

// Select loaded state
export const selectClientLocalStateLoaded = createSelector(
  selectClientLocalState,
  (state) => state.loaded
);

// Select error state
export const selectClientLocalStateError = createSelector(
  selectClientLocalState,
  (state) => state.error
);

// Select a single client local state by ID
export const selectClientLocalStateById = (id: string) =>
  createSelector(selectClientLocalStates, (data) =>
    data ? data.find((clientLocalState) => clientLocalState.id === id) : null
  );
