import { Action } from '@ngrx/store';
import { StoreKeys } from '@rappider/services';
import { EditorMode } from '@rappider/shared/definitions';

export enum ActionTypes {
  LoadApp = '[APP] LoadApp',
  LoadAppSuccessful = '[APP] LoadAppSuccessful',
  ChangeEditorMode = '[APP] ChangeEditorMode',
  LoadAppFailure = '[APP] LoadAppFailure',
  ChangeHeaderMenuItemVisibilities = '[APP] ChangeHeaderMenuItemVisibilities',
  ChangePageTitle = '[APP] ChangePageTitle',
  ChangeAppSearchVisibility = '[APP] ChangeAppSearchVisibility',
  AppSearch = '[APP] AppSearch',
  AppSearchSuccessful = '[APP] AppSearchSuccessful',
  GetRightSidebarTabName = '[APP] GetRightSidebarTabName',
  SetRightSidebarTabName = '[APP] SetRightSidebarTabName',
  GetRightSidebarVisibility = '[APP] GetRightSidebarVisibility',
  SetRightSidebarVisibility = '[APP] SetRightSidebarVisibility',
  ToggleRightSidebarVisibility = '[APP] ToggleRightSidebarVisibility',
  SyncResetRemoteData = '[APP] SyncResetRemoteData',
  SyncPriorityRemoteData = '[APP] SyncPriorityRemoteData',
  SyncSecondaryRemoteData = '[APP] SyncSecondaryRemoteData',
  SyncSharedRemoteData = '[APP] SyncSharedRemoteData',
  SyncRemoteDataWithKey = '[APP] SyncRemoteDataWithKey',
}

export class LoadApp implements Action {
  readonly type = ActionTypes.LoadApp;
}

export class LoadAppSuccessful implements Action {
  readonly type = ActionTypes.LoadAppSuccessful;
}

export class LoadAppFailure implements Action {
  readonly type = ActionTypes.LoadAppFailure;
}

export class ChangeEditorMode implements Action {
  readonly type = ActionTypes.ChangeEditorMode;
  constructor(public payload: { editorMode: EditorMode }) { }
}

export class ChangeHeaderMenuItemVisibilities implements Action {
  readonly type = ActionTypes.ChangeHeaderMenuItemVisibilities;
  constructor(public payload: { itemName: string; visibility: boolean }[]) { }
}

export class ChangePageTitle implements Action {
  readonly type = ActionTypes.ChangePageTitle;
  constructor(public payload: { pageTitle: string }) { }
}

export class ChangeAppSearchVisibility implements Action {
  readonly type = ActionTypes.ChangeAppSearchVisibility;
}

export class AppSearch implements Action {
  readonly type = ActionTypes.AppSearch;
  constructor(public payload: { searchText: string }) { }
}

export class AppSearchSuccessful implements Action {
  readonly type = ActionTypes.AppSearchSuccessful;
  constructor(public payload: { searchResults: any[] }) { }
}

export class GetRightSidebarTabName implements Action {
  readonly type = ActionTypes.GetRightSidebarTabName;
}

export class SetRightSidebarTabName implements Action {
  readonly type = ActionTypes.SetRightSidebarTabName;
  constructor(public payload: { rightSidebarTabName: string }) { }
}

export class GetRightSidebarVisibility implements Action {
  readonly type = ActionTypes.GetRightSidebarVisibility;
}

export class SetRightSidebarVisibility implements Action {
  readonly type = ActionTypes.SetRightSidebarVisibility;
  constructor(public payload: { rightSidebarVisibility: boolean }) { }
}

export class ToggleRightSidebarVisibility implements Action {
  readonly type = ActionTypes.ToggleRightSidebarVisibility;
}

export class SyncResetRemoteData implements Action {
  readonly type = ActionTypes.SyncResetRemoteData;
  constructor(public payload: { selectedProjectId: string }) { }
}

export class SyncPriorityRemoteData implements Action {
  readonly type = ActionTypes.SyncPriorityRemoteData;
  constructor(public payload: { projectId: string }) { }
}

export class SyncSecondaryRemoteData implements Action {
  readonly type = ActionTypes.SyncSecondaryRemoteData;
  constructor(public payload: { projectId: string }) { }
}

export class SyncRemoteDataWithKey implements Action {
  readonly type = ActionTypes.SyncRemoteDataWithKey;
  constructor(public payload: { projectId: string; storeKey: StoreKeys }) { }
}


export class SyncSharedRemoteData implements Action {
  readonly type = ActionTypes.SyncSharedRemoteData;
}
export type Actions =
  LoadApp
  | LoadAppSuccessful
  | LoadAppFailure
  | ChangeHeaderMenuItemVisibilities
  | ChangeEditorMode
  | ChangePageTitle
  | ChangeAppSearchVisibility
  | AppSearch
  | AppSearchSuccessful
  | GetRightSidebarTabName
  | SetRightSidebarTabName
  | GetRightSidebarVisibility
  | SetRightSidebarVisibility
  | ToggleRightSidebarVisibility
  | SyncResetRemoteData
  | SyncPriorityRemoteData
  | SyncSecondaryRemoteData
  | SyncSharedRemoteData
  ;

