import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, CrudFormLabelFunctionItem, CrudFormSelectItem, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import {
  CustomFunctionDefinitionWithRelations,
  ProjectModel,
  ProjectModelEndpoint,
  ProjectModelEndpointControllerService,
  ProjectModelEndpointParamPartial,
  ProjectModelEndpointQueryParamPartial,
  ProjectModelEndpointWithRelations,
  ProjectModelWithRelations
} from '@rappider/rappider-sdk';
import {
  CUSTOM_ENDPOINT_CREATE_OR_EDIT_CONFIG,
  CustomEndpointCreateOrEditFieldName,
  MOCK_RESPONSE_FORM_ITEM
} from '@rappider/shared/configs';
import { CustomEndpointParamsService, DataTransformService, JsonValidationService, NotificationService } from '@rappider/services';
import { defaultToolbarTitleHeadingSize, HTTP_METHOD_KEY_VALUES, PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { StringTransformService } from 'libs/shared/src/lib/services/string-transform-service/string-transform.service';
import { Subscription } from 'rxjs';
import { UpdateProjectModelEndpoint, UpdateProjectModelEndpointAndCustomFunction } from 'libs/project/src/lib/states/project-model-endpoint-state/project-model-endpoint.actions';

@Component({
  selector: 'rappider-custom-endpoint-edit',
  templateUrl: './custom-endpoint-edit.component.html',
  styleUrls: ['./custom-endpoint-edit.component.scss']
})
export class CustomEndpointEditComponent implements OnInit, OnDestroy {
  /* edit config */
  CUSTOM_ENDPOINT_EDIT_CONFIG = CUSTOM_ENDPOINT_CREATE_OR_EDIT_CONFIG;
  /* mock response form item */
  MOCK_RESPONSE_FORM_ITEM = MOCK_RESPONSE_FORM_ITEM;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectInterface;
  /* http methods */
  HTTP_METHOD_KEY_VALUES = HTTP_METHOD_KEY_VALUES;
  /* custom endpoint id */
  customEndpointId: string;
  /* project model id */
  projectModelId: string;
  /* editing custom endpoint  */
  editingCustomEndpoint: any;
  compareCustomEndpoint: any;
  /* string data schema type id */
  stringDataSchemaId: string;
  /* endpoints */
  endpoints: ProjectModelEndpointWithRelations[];
  projectModels: ProjectModel[];
  /* project model that endpoint belongs to */
  projectModel: ProjectModel;
  customFunctionId: string;
  customFunction: any;

  isSubmitButtonLoading = false;
  displayToolbar = false;
  displayToolbarBackButton = false;
  isEnableSubmitButton = false;

  visibleFields = [
    'serviceName',
    'functionName',
    'programmingLanguage',
    'packages',
    'DEV',
    'QA',
    'PROD',
    'environmentVariables',
    'code',
    'requestJSONSample',
    'responseJSONSample',
    'submit-button'
  ];

  constructor(
    private store: Store<any>,
    private projectModelEndpointApi: ProjectModelEndpointControllerService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private stringTransformService: StringTransformService,
    private jsonValidationService: JsonValidationService,
    private dataTransformService: DataTransformService,
    private paramsService: CustomEndpointParamsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getCustomEndpointIdFromUrl();
    this.setFormConfig();
    this.subscribeToData();
    this.setHttpMethodSelectOptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModels(),
      this.subscribeToProjectModelEndpoints(),
      this.subscribeToSubmitButtonLoading()
    ];
  }

  getCustomEndpointIdFromUrl() {
    this.customEndpointId = this.activatedRoute.snapshot.params['id'];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.activeProject = activeProject;
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToProjectModels() {
    return this.store.select(state => state.projectModel.data).subscribe(projectModels => {
      if (projectModels?.length > 0) {
        this.projectModels = projectModels;
      }
    });
  }

  subscribeToProjectModelEndpoints() {
    return this.store.select(state => state.projectModelEndpoint?.data).subscribe((endpoints: ProjectModelEndpoint[]) => {
      if (endpoints?.length > 0) {
        this.endpoints = endpoints;
        this.getCustomEndpointById();
      }
    });
  }

  subscribeToSubmitButtonLoading() {
    return this.store.select(state => state.projectModelEndpoint?.isLoading).subscribe((isLoading: boolean) => {
      this.isSubmitButtonLoading = isLoading;
    });
  }

  /**
   * set page title
   *
   * @memberof CustomEndpointEditComponent
   */
  setTitle() {
    this.mainTitle = {
      content: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.CUSTOM_ENDPOINTS_EDIT',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProject.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.CUSTOM_ENDPOINTS_EDIT',
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_ENDPOINT_LIST}`
      },
      {
        label: this.projectModel?.name,
      },
      {
        label: this.editingCustomEndpoint?.name
      }
    ];
  }

  /**
   * sets http method select item's options
   *
   * @memberof CustomEndpointEditComponent
   */
  setHttpMethodSelectOptions() {
    const httpMethodFormItem = <CrudFormSelectItem>(
      this.CUSTOM_ENDPOINT_EDIT_CONFIG.items.find(
        item => item.fieldName === CustomEndpointCreateOrEditFieldName.Method
      )
    );
    /* set target model form item options */
    httpMethodFormItem.options = this.HTTP_METHOD_KEY_VALUES;
  }

  /**
   * gets custom endpoint by endpoint id
   *
   * @memberof CustomEndpointEditComponent
   */
  getCustomEndpointById() {
    const customEndpoint = this.endpoints.find(customEndpoint => customEndpoint.id === this.customEndpointId);
    this.editingCustomEndpoint = {
      ...customEndpoint,
      mockResponse: JSON.stringify(customEndpoint?.mockResponse),
      responseData: JSON.stringify(customEndpoint?.responseData),
      requestData: JSON.stringify(customEndpoint?.requestData),
      params: customEndpoint?.params ? customEndpoint?.params?.map(param => ({
        id: param.id,
        name: param?.name ? param?.name : null,
        typeId: null
      })) : null,
      queryParams: customEndpoint?.queryParams ? customEndpoint?.queryParams?.map(queryParam => ({
        id: queryParam.id,
        name: queryParam?.name ? queryParam?.name : null,
        typeId: null
      })) : null,
    };
    this.compareCustomEndpoint = {
      ...customEndpoint,
      mockResponse: JSON.stringify(customEndpoint?.mockResponse),
      responseData: JSON.stringify(customEndpoint?.responseData),
      requestData: JSON.stringify(customEndpoint?.requestData),
      params: customEndpoint?.params ? customEndpoint?.params?.map(param => ({
        id: param.id,
        name: param?.name ? param?.name : null,
        typeId: null
      })) : null,
      queryParams: customEndpoint?.queryParams ? customEndpoint?.queryParams?.map(queryParam => ({
        id: queryParam.id,
        name: queryParam?.name ? queryParam?.name : null,
        typeId: null
      })) : null,
    };
    this.projectModel = this.projectModels.find(projectModel => projectModel.id === customEndpoint.modelId);
    this.customFunctionId = this.editingCustomEndpoint.customFunctionDefinitionId;
    if (this.customFunctionId) {
      this.subscribeToCustomFunctions();
    }
    this.isEnableSubmitButton = this.editingCustomEndpoint.name.trim() && this.editingCustomEndpoint.path.trim() && this.editingCustomEndpoint.method ? true : false;
    this.setMockResponseFormItem(customEndpoint?.isMocked);
    this.setTitle();
  }

  subscribeToCustomFunctions() {
    return this.store.select(state => state.customFunction?.data).subscribe((customFunctions: CustomFunctionDefinitionWithRelations[]) => {
      if (customFunctions) {
        const editedCustomFunction = customFunctions.find(item => item.id === this.customFunctionId);
        this.customFunction = {
          ...editedCustomFunction,
          requestJSONSample: this.editingCustomEndpoint.requestData,
          responseJSONSample: this.editingCustomEndpoint.responseData,
          mockResponse: this.editingCustomEndpoint.mockResponse,
        };
      }
    });
  }

  onCustomEndpointFormValueChange(projectModelEndpoint: ProjectModelEndpointWithRelations) {
    this.editingCustomEndpoint = {
      ...this.editingCustomEndpoint,
      ...projectModelEndpoint
    };
    if (projectModelEndpoint.isMocked !== undefined) {
      this.setMockResponseFormItem(projectModelEndpoint.isMocked);
    }
    if (projectModelEndpoint.name) {
      this.customFunction = {
        ...this.customFunction,
        functionName: projectModelEndpoint.name,
      };
      this.isEnableSubmitButton = this.editingCustomEndpoint?.name.trim() && this.editingCustomEndpoint?.path.trim() && this.editingCustomEndpoint.method ? true : false;
    }
  }

  setDataSchemaStringId(customEndpoint: ProjectModelEndpointWithRelations) {
    if (customEndpoint.params) {
      /* get string data schema id from endpoint param data if endpoint has param */
      return this.paramsService.getTypeIdsOfParams(customEndpoint.params)[0];
    } else if (customEndpoint.queryParams) {
      /* get string data schema id from endpoint query param data if endpoint has query param */
      return this.paramsService.getTypeIdsOfParams(customEndpoint.queryParams)[0];
    } else {
      return '';
    }
  }

  /**
   * sets mock response form item on config depending on isMocked property
   *
   * @param {boolean} isMocked
   * @memberof CustomEndpointEditComponent
   */
  setMockResponseFormItem(isMocked: boolean) {
    if (isMocked) {
      this.visibleFields.push('mockResponse');
    } else if (isMocked === false) {
      this.visibleFields = this.visibleFields.filter(field => field !== 'mockResponse');
    }
    this.visibleFields = [
      ...this.visibleFields
    ];
  }

  /**
   * returns custom endpoints name as kebab-case to set path
   *
   * @param {ProjectModelEndpointWithRelations} customEndpoint
   * @return {*}
   * @memberof CustomEndpointEditComponent
   */
  setPathOfRequestBody(customEndpoint: ProjectModelEndpointWithRelations) {
    return this.stringTransformService.getKebabCaseFromText(customEndpoint.name);
  }

  validateMockResponse(customEndpoint: ProjectModelEndpointWithRelations) {
    return this.jsonValidationService.validateStringifiedJson(customEndpoint.mockResponse, true);
  }

  validateResponseData(customEndpoint: ProjectModelEndpointWithRelations) {
    return this.jsonValidationService.validateStringifiedJson(JSON.stringify(customEndpoint.responseJSONSample), true);
  }

  validateRequestData(customEndpoint: ProjectModelEndpoint) {
    return this.jsonValidationService.validateStringifiedJson(JSON.stringify(customEndpoint.requestJSONSample), true);
  }

  /**
   * compares params and query params arrays and returns created updated and deleted elements
   *
   * @param {*} customEndpoint
   * @return {*}
   * @memberof CustomEndpointEditComponent
   */
  compareParamsAndQueryParams(customEndpoint: any) {
    const comparedParams = this.dataTransformService.compareArrays(this.compareCustomEndpoint.params, customEndpoint.params);
    const comparedQueryParams = this.dataTransformService.compareArrays(this.compareCustomEndpoint.queryParams, customEndpoint.queryParams);
    /* map param and query param objects to string array */
    const deletedParamIds = comparedParams.deletedArray?.map((param: ProjectModelEndpointParamPartial) => param.id);
    const deletedQueryParamIds = comparedQueryParams.deletedArray?.map((param: ProjectModelEndpointQueryParamPartial) => param.id);

    return {
      createdParams: this.paramsService
        .setParamTypeIds(comparedParams.createdArray, this.stringDataSchemaId) as ProjectModelEndpointParamPartial,
      updatedParams: comparedParams.updatedArray as ProjectModelEndpointParamPartial,
      deletedParams: deletedParamIds,

      createdQueryParams: this.paramsService
        .setParamTypeIds(comparedQueryParams.createdArray, this.stringDataSchemaId) as ProjectModelEndpointQueryParamPartial,
      updatedQueryParams: comparedQueryParams.updatedArray as ProjectModelEndpointQueryParamPartial,
      deletedQueryParams: deletedQueryParamIds
    };
  }

  /**
   * prepares the request body for creating endpoint by setting path
   * validating json inputs and deleting unused fields
   *
   * @param {ProjectModelEndpoint} customEndpoint
   * @memberof CustomEndpointEditComponent
   */
  onCustomEndpointUpdate(customFunction: any) {
    if (!this.editingCustomEndpoint.path) {
      this.editingCustomEndpoint.path = this.setPathOfRequestBody(this.editingCustomEndpoint);
    }

    if (
      this.validateResponseData(customFunction).isJsonValid &&
      this.validateRequestData(customFunction).isJsonValid
    ) {

      const endpointRequestBody = {
        endpoint: {
          ...this.editingCustomEndpoint,
          mockResponse: this.validateMockResponse(customFunction).dataAsJson || null,
          responseData: this.validateResponseData(customFunction).dataAsJson || null,
          requestData: this.validateRequestData(customFunction).dataAsJson || null
        },
        ...this.compareParamsAndQueryParams(this.editingCustomEndpoint)
      };

      /* delete unused fields from request body */
      delete endpointRequestBody.endpoint.params;
      delete endpointRequestBody.endpoint.queryParams;
      delete endpointRequestBody.endpoint.pathPreview;
      if (!this.editingCustomEndpoint.isMocked) {
        delete endpointRequestBody.endpoint.mockResponse;
      }
      if (endpointRequestBody.endpoint.customFunctionDefinition) {
        delete endpointRequestBody.endpoint.customFunctionDefinition;
      }

      const customFunctionDefinition = {
        ...this.editingCustomEndpoint.customFunctionDefinition,
        ...customFunction
      };

      if (customFunctionDefinition.mockResponse) {
        delete customFunctionDefinition.mockResponse;
      }

      endpointRequestBody.createdParams?.forEach(item => {
        delete item['id'];
      });
      endpointRequestBody.createdQueryParams?.forEach(item => {
        delete item['id'];
      });

      this.updateCustomEndpoint(endpointRequestBody, customFunctionDefinition);
    } else {
      this.notificationService.createNotification(
        'error',
        this.editingCustomEndpoint.name,
        'ERRORS.WRONG_JSON_FORMAT'
      );
    }
  }

  /**
   * sends create request
   *
   * @param {*} customEndpoint
   * @memberof CustomEndpointEditComponent
   */
  updateCustomEndpoint(endpointRequestBody: any, customFunctionRequestBody: any) {
    this.store.dispatch(UpdateProjectModelEndpointAndCustomFunction({
      payload: {
        endpoint: {
          ...endpointRequestBody
        },
        customFunctionDefinition: {
          ...customFunctionRequestBody
        }
      }
    }));
  }

  setFormConfig() {
    const labelFunctionItem = <CrudFormLabelFunctionItem>
      this.CUSTOM_ENDPOINT_EDIT_CONFIG.items.find(item => item.fieldName === 'pathPreview');
    labelFunctionItem.functionToDisplay = (configItem, data) => {
      const params = this.paramsService.getParamNames(data.params);
      const queryParams = this.paramsService.getParamNames(data.queryParams);
      const basePath = this.projectModels?.find(projectModel => projectModel.id === this.endpoints?.find(customEndpoint => customEndpoint.id === this.customEndpointId)?.modelId)?.basePath;
      return this.paramsService.setPathPreview(`${basePath ? basePath + '/' : ''}${data?.path ?? ''}`, params, queryParams);
    };
  }

}
