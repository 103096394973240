import { createSelector } from '@ngrx/store';
import { UiDataEventWithRelations, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { DiagramTreeService } from '../../../../../../../libs/diagram-editor/src/lib/utils/services/diagram-tree.service';

const diagramTreeService = new DiagramTreeService();

export const uiWorkflowTemplatePageSelector = createSelector(
  state => state['uiWorkflowStepFunction']?.templateUIStepFunctions,
  state => state['uiDataEvent']?.templateUIDataEvents,
  (
    templateUIStepFunctions: UiWorkflowStepFunctionWithRelations[],
    templateUIDataEvents: UiDataEventWithRelations[]
  ) => {
    if (templateUIDataEvents?.length && templateUIStepFunctions?.length) {
      const starterEvents = diagramTreeService.findStarterEvents(templateUIDataEvents, templateUIStepFunctions);
      return starterEvents;
    } else {
      return [];
    }
  }
);
