import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, authenticationFeatureKey } from './authentication.reducer';

// Feature Selector
export const selectAuthFeature = createFeatureSelector<AuthState>(authenticationFeatureKey);

// Individual Selectors
export const selectUser = createSelector(selectAuthFeature, (state: AuthState) => state.user);
export const selectActivePerson = createSelector(selectAuthFeature, (state: AuthState) => state.activePerson);
export const selectTenant = createSelector(selectAuthFeature, (state: AuthState) => state.tenant);
export const selectRoles = createSelector(selectAuthFeature, (state: AuthState) => state.roles);
export const selectIsPersonLoading = createSelector(selectAuthFeature, (state: AuthState) => state.isPersonLoading);
export const selectIsUserLoading = createSelector(selectAuthFeature, (state: AuthState) => state.isUserLoading);
export const selectLastProcessedAction = createSelector(selectAuthFeature, (state: AuthState) => state.lastProcessedAction);
export const selectIsLoading = createSelector(selectAuthFeature, (state: AuthState) => state.isLoading);
export const selectError = createSelector(selectAuthFeature, (state: AuthState) => state.error);
export const selectIsEmailVerified = createSelector(selectAuthFeature, (state: AuthState) => state.isEmailVerified);
export const selectPermissions = createSelector(selectAuthFeature, (state: AuthState) => state.permissions);
export const selectIsPermissionLoaded = createSelector(selectAuthFeature, (state: AuthState) => state.isPermissionLoaded);

// Compound Selectors (Example: Select permissions and loading state together)
export const selectPermissionsAndLoading = createSelector(
  selectPermissions,
  selectIsLoading,
  (permissions, isLoading) => ({ permissions, isLoading })
);
