import { createAction, createReducer, on } from '@ngrx/store';
import { CommentWithRelations } from '@rappider/rappider-sdk';
import * as AdminCommentActions from './admin-comment.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { orderBy, cloneDeep } from 'lodash';

import * as PersonActions from '../person-state/person.actions';

/* state key */
export const featureKey = 'adminComment';

/* state interface */
export interface State {
  data: CommentWithRelations[] | null;
  isLoading: boolean;
}

export const initialState: State = {
  data: null,
  isLoading: false,
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,

  /* Init Action */
  on(PersonActions.InitAdminDashboard, (state) => ({
    ...state,
    isLoading: true
  })),

  /* get */
  on(AdminCommentActions.GetAdminComments, (state) => ({
    ...state,
    isLoading: true
  })),
  on(AdminCommentActions.GetAdminCommentsSuccessful, (state, action) => ({
    ...state,
    data: action.comments,
    isLoading: false
  })),
  on(AdminCommentActions.GetAdminCommentsFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.error,
      key: action.key,
      date: action.date
    }
  })),

  /* delete */
  on(AdminCommentActions.DeleteAdminComment, (state) => ({
    ...state,
    isLoading: true
  })),
  on(AdminCommentActions.DeleteAdminCommentSuccessful, (state, action) => ({
    ...state,
    data: state.data.filter(comment => comment.id !== action.commentId),
    isLoading: false
  })),
  on(AdminCommentActions.DeleteAdminCommentFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  /* update */
  on(AdminCommentActions.UpdateAdminComment, (state) => ({
    ...state,
    isLoading: true
  })),
  on(AdminCommentActions.UpdateAdminCommentSuccessful, (state, action) => {
    const updatedComment = {
      ...cloneDeep(state.data.find(comment => comment.id === action.comment?.id)),
      ...action.comment
    };
    return {
      ...state,
      data: orderBy([
        ...state.data.filter(comment => comment.id !== action.comment?.id),
        updatedComment
      ], 'createdDate', 'asc'),
      isLoading: false
    };
  }),
  on(AdminCommentActions.UpdateAdminCommentFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
