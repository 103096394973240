/* eslint-disable @typescript-eslint/naming-convention */

import { API_ENDPOINTS_ACTION_BUTTONS, DATA_MODELS_AND_DATABASE_ACTION_BUTTONS, DEPLOY_ACTION_BUTTONS, DESIGN_SYSTEM_ACTION_BUTTONS, GENERATE_PROJECT_CODE_ACTION_BUTTONS, MESSAGE_TEMPLATES_ACTION_BUTTONS, PROJECT_DESCRIPTION_ACTION_BUTTONS, PROJECT_INFO_ACTION_BUTTONS, PROJECT_MEMBERS_ACTION_BUTTONS, SCOPE_OF_WORK_CONFIG_ACTION_BUTTONS, SERVER_SIDE_BUSINESS_PROCESSES_ACTION_BUTTONS, SETTINGS_VARIABLES_AND_ENVIRONMENTS_ACTION_BUTTONS, THEME_ACTION_BUTTONS, UI_BUSINESS_PROCESSES_ACTION_BUTTONS, UI_PAGE_LAYOUTS_ACTION_BUTTONS, UI_PAGES_ACTION_BUTTONS } from './action-button-config';
import { EMPTY_CRUD_CONFIG, PROJECT_DESCRIPTION_CONFIG, PROJECT_INFO_CONFIG, PROJECT_MEMBERS_CONFIG, SCOPE_OF_WORK_CONFIG } from './crud-configs';

import { ButtonComponentConfig, CrudFormConfig } from '@rappider/rappider-components/utils';


export interface CollapsePanelConfig {
  [key: string]: {
    crudForm: CrudFormConfig;
    actionButtons: ButtonComponentConfig[];
  };
}

export enum CollapsePanels {
  Welcome = 'welcome',
  ProjectTitle = 'projectTitle',
  ProjectDescription = 'projectDescription',
  ProjectInformation = 'projectInformation',
  ProjectMembers = 'projectMembers',
  ScopeOfWork = 'scopeOfWork',
  DataModelsAndDatabase = 'dataModelsAndDatabase',
  APIEndpoints = 'apiEndpoints',
  UIPages = 'uiPages',
  UIPageLayouts = 'uiPageLayouts',
  Theme = 'theme',
  DesignSystem = 'designSystem',
  UIBusinessProcesses = 'uiBusinessProcesses',
  MessageTemplates = 'messageTemplates',
  ServerSideBusinessProcesses = 'serverSideBusinessProcesses',
  SettingsVariablesAndEnvironments = 'settingsVariablesAndEnvironments',
  GenerateProjectCode = 'generateProjectCode',
  Deploy = 'deploy',
  Server = 'server',
  ShareApplication = 'shareApplication',
  // VersionsAndDeployments = 'versionsAndDeployments',
  // EnjoyYourProject = 'enjoyYourProject'
}

export const COLLAPSE_PANEL_CONFIGS: CollapsePanelConfig = {
  [CollapsePanels.Welcome]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: []
  },
  [CollapsePanels.ProjectTitle]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: []
  },
  [CollapsePanels.ProjectDescription]: {
    crudForm: PROJECT_DESCRIPTION_CONFIG,
    actionButtons: PROJECT_DESCRIPTION_ACTION_BUTTONS
  },
  [CollapsePanels.ProjectInformation]: {
    crudForm: PROJECT_INFO_CONFIG,
    actionButtons: PROJECT_INFO_ACTION_BUTTONS
  },
  [CollapsePanels.ProjectMembers]: {
    crudForm: PROJECT_MEMBERS_CONFIG,
    actionButtons: PROJECT_MEMBERS_ACTION_BUTTONS
  },
  [CollapsePanels.ScopeOfWork]: {
    crudForm: SCOPE_OF_WORK_CONFIG,
    actionButtons: SCOPE_OF_WORK_CONFIG_ACTION_BUTTONS
  },
  [CollapsePanels.DataModelsAndDatabase]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: DATA_MODELS_AND_DATABASE_ACTION_BUTTONS
  },
  [CollapsePanels.APIEndpoints]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: API_ENDPOINTS_ACTION_BUTTONS
  },
  [CollapsePanels.UIPages]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons:  UI_PAGES_ACTION_BUTTONS
  },
  [CollapsePanels.UIPageLayouts]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: UI_PAGE_LAYOUTS_ACTION_BUTTONS
  },
  [CollapsePanels.Theme]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: THEME_ACTION_BUTTONS
  },
  [CollapsePanels.DesignSystem]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: DESIGN_SYSTEM_ACTION_BUTTONS
  },
  [CollapsePanels.UIBusinessProcesses]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: UI_BUSINESS_PROCESSES_ACTION_BUTTONS
  },
  [CollapsePanels.MessageTemplates]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: MESSAGE_TEMPLATES_ACTION_BUTTONS
  },
  [CollapsePanels.ServerSideBusinessProcesses]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: SERVER_SIDE_BUSINESS_PROCESSES_ACTION_BUTTONS
  },
  [CollapsePanels.SettingsVariablesAndEnvironments]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: SETTINGS_VARIABLES_AND_ENVIRONMENTS_ACTION_BUTTONS
  },
  [CollapsePanels.GenerateProjectCode]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: GENERATE_PROJECT_CODE_ACTION_BUTTONS
  },
  [CollapsePanels.Deploy]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: []
  },
  [CollapsePanels.ShareApplication]: {
    crudForm: EMPTY_CRUD_CONFIG,
    actionButtons: []
  },
  // [CollapsePanels.VersionsAndDeployments]: {
  //   crudForm: EMPTY_CRUD_CONFIG,
  //   actionButtons: []
  // },
  // [CollapsePanels.EnjoyYourProject]: {
  //   crudForm: EMPTY_CRUD_CONFIG,
  //   actionButtons: []
  // }
};
