import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ContainerTemplateControllerService, ContainerTemplateCopyToPageContainerDto, ContainerTemplateCreateDto } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as ContainerTemplateActions from './container-template.actions';
import * as ContentEditorActions from './../content-editor.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
@Injectable()
export class ContainerTemplateEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private containerTemplateApi: ContainerTemplateControllerService,
    private notificationService: NotificationService
  ) { }

  getContainerTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ContainerTemplateActions.GetContainerTemplates,
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        const params = {
          filter: {
            where: {
              or: [
                { projectId: activeProjectId },
                { isPublic: true }
              ]
            }
          }
        };
        return this.containerTemplateApi.find(params).pipe(
          map((containerTemplates) => ContainerTemplateActions.GetContainerTemplatesSuccessful({
            payload: { containerTemplates }
          })),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.COULDNT_LOAD',
              'SHARED.COULDNT_LOAD'
            );
            return [
              ContainerTemplateActions.ErrorAction({
                payload: {
                  error,
                  key: 'GetContainerTemplates',
                  timestamp: Date.now()
                }
              })
            ];
          })
        );
      })
    )
  );

  createContainerTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ContainerTemplateActions.CreateContainerTemplate
      ),
      withLatestFrom(
        this.store.select(state => state.containerTemplate?.data)
      ),
      mergeMap(([action, containerTemplates]) => {
        const body = action.payload.containerTemplate;

        return this.containerTemplateApi.create({ body }).pipe(
          map(createdContainerTemplate => {
            const newContainerTemplates = [
              ...containerTemplates,
              createdContainerTemplate
            ];

            this.notificationService.createNotification(
              'success',
              action.payload.containerTemplate.name,
              'SHARED.SUCCESSFULLY_CREATED'
            );

            return ContainerTemplateActions.CreateContainerTemplateSuccessful({ payload: { containerTemplates: newContainerTemplates } });
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              action.payload.containerTemplate.name,
              'SHARED.COULDNT_CREATED'
            );
            return [
              ContainerTemplateActions.ErrorAction({
                payload: {
                  error,
                  key: 'CreateContainerTemplates',
                  timestamp: Date.now()
                }
              })
            ];
          })
        );
      })
    )
  );

  copyTemplateToPageContainer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ContainerTemplateActions.CopyTemplateToPageContainer
      ),
      withLatestFrom(
        this.store.select(state => state.contentEditor?.page),
        this.store.select(state => state.contentEditor?.activeContentTreeItemId),
      ),
      mergeMap(([action, activePage, activeContentTreeItemId]) => {
        const body: ContainerTemplateCopyToPageContainerDto = {
          containerTemplateId: action.payload.containerTemplateId,
          pageContainerId: activeContentTreeItemId,
          pageId: activePage.id
        };

        return this.containerTemplateApi.copyToPageContainer({ body }).pipe(
          mergeMap(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESSFULLY_CREATED',
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return [
              ContainerTemplateActions.CopyTemplateToPageContainerSuccessful(),
              new ContentEditorActions.LoadPageWithComponents({ pageId: activePage.id })
            ];
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.COULDNT_CREATED',
              'SHARED.COULDNT_CREATED'
            );
            return [
              ContainerTemplateActions.ErrorAction({
                payload: {
                  error,
                  key: 'CopyTemplateToPageContainer',
                  timestamp: Date.now()
                }
              })
            ];
          })
        );
      })
    )
  );

}
