<div *ngIf="!isTemplateSelected" class="project-create-wrapper">
  <div class="cards-with-preview">
    <div class="cards-wrapper" >
      <rappider-cards [items]="publicProjectsCardsConfig.items"
                      [isLoading]="publicProjectsCardsConfig.publicProjectsIsLoading"
                      [itemCountPerRow]="publicProjectsCardsConfig.itemCountPerRow"
                      [showTitleOnImage]="publicProjectsCardsConfig.showTitleOnImage"
                      [showDescriptionOnImage]="publicProjectsCardsConfig.showDescriptionOnImage"
                      [selectConfig]="publicProjectsCardsConfig.selectConfig"
                      [paginationConfig]="publicProjectsCardsConfig.paginationConfig"
                      [showCheckMarkOnCard]="publicProjectsCardsConfig.showCheckMarkOnCard"
                      [isDynamicPagination]="publicProjectsCardsConfig.isDynamicPagination"
                      (cardClick)="onCardClick($event)"
                      (pageIndexChange)="onPageIndexChange($event)"
                      (imageButtonClick)="onImageButtonClick($event)"
                      (tagFilterChange)="onTagFilterChange($event)"
                      (searchTextChange)="onSearchTextChange($event)"></rappider-cards>
    </div>
  </div>
</div>
<section *ngIf="isTemplateSelected" class="project-create-form-wrapper">
  <div class="container">
    <rappider-crud-view-edit-form #projectCreateForm
                                  [style.display]="createProjectProcess ? 'none': 'block'"
                                  [config]="PROJECT_CREATE_FORM_CONFIG"
                                  [data]="projectFormData"
                                  [submitButtonLoading]="isLoading"
                                  [slideMode]="true"
                                  (fieldValueChange)="onFieldValueChange($event)"
                                  (formSubmit)="createProject($event)">
    </rappider-crud-view-edit-form>
  </div>
  <div *ngIf="createProjectProcess"
       class="loading">
    <h5>We are creating your project; this process may take 1-2 minutes..</h5>
    <rappider-lottie-player [options]="lottieConfig.options"
                            [height]="lottieConfig.height"></rappider-lottie-player>
  </div>
</section>
<rappider-modal [visible]="modalVisibility"
                [title]="modalTitle"
                [closeIconVisibility]="true"
                [okText]="modalOkText"
                [cancelText]="modalCancelText"
                [fullScreen]="true"
                (cancelClick)="modalCancelClick()"
                (okClick)="modalOkClick()">
                <div class="title-wrapper">
                  <rappider-paragraph [content]="selectedTemplatePreview?.name"
                                      [typography]="selectedTemplateNameConfig.typography"
                                      [colorSettings]="selectedTemplateNameConfig.colorSettings">
                  </rappider-paragraph>
                </div>
                <div class="preview-wrapper">
                  <div class="preview-title">
                    <rappider-paragraph [content]="selectedTemplatePreview?.description"
                                        [typography]="selectedTemplateDescriptionConfig.typography"
                                        [colorSettings]="selectedTemplateDescriptionConfig.colorSettings">
                    </rappider-paragraph>
                    <rappider-divider class="rappider-divider"></rappider-divider>
                  </div>
                  <div class="preview-content-wrapper">
                    <div *ngFor="let thumbnail of selectedTemplatePreview?.thumbnails" class="preview-content">
                      <rappider-image [source]="thumbnail.url"></rappider-image>
                      <div class="paragraphs">
                        <rappider-paragraph *ngIf="thumbnail.title"
                                            [content]="thumbnail.title"
                                            [typography]="thumbnailTitleConfig.typography"
                                            [colorSettings]="thumbnailTitleConfig.colorSettings">
                        </rappider-paragraph>
                        <rappider-paragraph *ngIf="thumbnail.description"
                                            [content]="thumbnail.description"
                                            [typography]="thumbnailDescriptionConfig.typography"
                                            [colorSettings]="thumbnailDescriptionConfig.colorSettings">
                        </rappider-paragraph>
                      </div>
                    </div>
                  </div>
                </div>
</rappider-modal>
