import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as ClientLocalStateActions from './client-local-state.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { selectActiveProjectId } from 'libs/project/src/lib/states/active-project-state/active-project.selectors';

import { NotificationService } from '@rappider/services';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';

import { ClientLocalState } from '@rappider/rappider-sdk';

/* Named import */
import { ClientLocalStateControllerService as ApiService } from '@rappider/rappider-sdk';

export const navigatePathAfterCreatingInstance = '';
export const navigatePathAfterUpdatingInstance = '';

@Injectable()
export class ClientLocalStateEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  getClientLocalStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientLocalStateActions.GetClientLocalStates),
      withLatestFrom(
        this.store.select(selectActiveProjectId)
      ),
      mergeMap(([action, activeProjectId]) => {
        /* fail if there is no active project */
        if (!activeProjectId) {
          return [
            ClientLocalStateActions.GetClientLocalStatesFailure()
          ];
        }
        /* set a filter */
        const filter = {
        };
        /* get the ClientLocalStates */
        return this.apiService.find({ filter }).pipe(
          mergeMap(clientLocalStates => ([
            ClientLocalStateActions.GetClientLocalStatesSuccessful({ payload: { clientLocalStates: clientLocalStates } })
          ])),
          catchError((error) => [
            ClientLocalStateActions.GetClientLocalStatesFailure()
          ])
        );
      })
    )
  );

  createClientLocalState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientLocalStateActions.CreateClientLocalState),
      withLatestFrom(
        this.store.select(selectActiveProjectId)
      ),
      mergeMap(([action, activeProjectId]) => {
        const params = {
          body: {
            ...action.payload.clientLocalState,
            projectId: activeProjectId
          }
        };

        return this.apiService.create(params).pipe(
          map((clientLocalState) => {
            console.log('clientLocalState success', clientLocalState);
            // this.notificationService.createNotification(
            //   'success',
            //   params.body?.key,
            //   'SHARED.SUCCESSFULLY_CREATED'
            // );
            return ClientLocalStateActions.CreateClientLocalStateSuccessful({ payload: { clientLocalState: clientLocalState } });
          }),
          catchError((error) => {
            console.error('clientLocalState error', error);
            // this.notificationService.createNotification(
            //   'error',
            //   params.body?.key,
            //   'SHARED.COULDNT_CREATED'
            // );
            return [
              ClientLocalStateActions.ErrorAction({ payload: { error: error, key: 'CreateClientLocalState', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  createClientLocalStateSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientLocalStateActions.CreateClientLocalStateSuccessful),
      map(action =>
        new Navigate({ url: navigatePathAfterCreatingInstance })
      )
    )
  );

  updateClientLocalState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientLocalStateActions.UpdateClientLocalState),
      mergeMap(action => {
        const params = {
          id: action.payload.clientLocalStateId,
          body: action.payload.clientLocalState
        };

        return this.apiService.updateById(params).pipe(
          map((clientLocalState) => {
            console.log('clientLocalState success', clientLocalState);
            // this.notificationService.createNotification(
            //   'success',
            //   params.body.name,
            //   'SHARED.SUCCESSFULLY_UPDATED'
            // );
            return ClientLocalStateActions.UpdateClientLocalStateSuccessful({ payload: { clientLocalStateId: action.payload.clientLocalStateId, clientLocalState: clientLocalState } });
          }
          ),
          catchError((error) => {
            console.error('clientLocalState error', error);
            // this.notificationService.createNotification(
            //   'error',
            //   params.body.name,
            //   'SHARED.COULDNT_UPDATED'
            // );
            return [
              ClientLocalStateActions.ErrorAction({ payload: { error: error, key: 'UpdateClientLocalState', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  updateClientLocalStateSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientLocalStateActions.UpdateClientLocalStateSuccessful),
      mergeMap(action => [
        new Navigate({ url: navigatePathAfterCreatingInstance }),
        ClientLocalStateActions.GetClientLocalStates()
      ])
    )
  );

  deleteClientLocalState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientLocalStateActions.DeleteClientLocalState),
      withLatestFrom(
        this.store.select(state => <ClientLocalState[]>state.clientLocalState?.data)
      ),
      mergeMap(([action, clientLocalStates]) => {
        const params = { id: action.payload.clientLocalStateId };
        const deletedClientLocalState = clientLocalStates.find(clientLocalState => clientLocalState.id === action.payload.clientLocalStateId);

        return this.apiService.deleteById(params).pipe(
          map(() => {
            console.log('clientLocalState success', deletedClientLocalState);
            // this.notificationService.createNotification(
            //   'success',
            //   deletedClientLocalState?.title || 'ClientLocalState',
            //   'SHARED.SUCCESSFULLY_DELETED'
            // );
            return ClientLocalStateActions.GetClientLocalStates();
          }),
          catchError((error) => {
            console.error('clientLocalState error', error);
            // this.notificationService.createNotification(
            //   'error',
            //   deletedClientLocalState.title || 'ClientLocalState',
            //   'SHARED.COULDNT_DELETED'
            // );
            return [
              ClientLocalStateActions.ErrorAction({ payload: { error: error, key: 'DeleteClientLocalState', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  /* Old actions can not listen on new reducers. So we use this effect temp. */
  resetStateToInitial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.Logout>(AuthenticationActions.ActionTypes.Logout),
      mergeMap(action => [
        ClientLocalStateActions.ResetStateToInitial()
      ])
    )
  );

  resetStateToInitialChangeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.UpdateAuthenticationTokenWithProjectIdSuccessful>(
        AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful),
      mergeMap(action => [
        ClientLocalStateActions.ResetStateToInitial()
      ])
    )
  );
}
