/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

/* angular imports */
import { createAction, props } from '@ngrx/store';
import { ScopeOfWork, ScopeOfWorkPartial, NewScopeOfWork } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetScopeOfWorks = '[ScopeOfWork] GetScopeOfWorks',
  GetScopeOfWorksSuccessful = '[ScopeOfWork] GetScopeOfWorksSuccessful',
  GetScopeOfWorksFailure = '[ScopeOfWork] GetScopeOfWorksFailure',
  CreateScopeOfWork = '[ScopeOfWork] CreateScopeOfWork',
  CreateScopeOfWorkSuccessful = '[ScopeOfWork] CreateScopeOfWorkSuccessful',
  CreateScopeOfWorkFailure = '[ScopeOfWork] CreateScopeOfWorkFailure',
  UpdateScopeOfWork = '[ScopeOfWork] UpdateScopeOfWork',
  UpdateScopeOfWorkSuccessful = '[ScopeOfWork] UpdateScopeOfWorkSuccessful',
  UpdateScopeOfWorkFailure = '[ScopeOfWork] UpdateScopeOfWorkFailure',
  DeleteScopeOfWork = '[ScopeOfWork] DeleteScopeOfWork',
  DeleteScopeOfWorkSuccessful = '[ScopeOfWork] DeleteScopeOfWorkSuccessful',
  DeleteScopeOfWorkFailure = '[ScopeOfWork] DeleteScopeOfWorkFailure',
  ErrorAction = '[ScopeOfWork] ErrorAction',
  ResetStateToInitial = '[ScopeOfWork] ResetStateToInitial',
  ResetStateToInitialChangeProject = '[ScopeOfWork] ResetStateToInitialChangeProject'
}

/* actions */

export const GetScopeOfWorks = createAction(ActionTypes.GetScopeOfWorks);

export const GetScopeOfWorksSuccessful = createAction(
  ActionTypes.GetScopeOfWorksSuccessful,
  props<{ payload: { scopeOfWorks:  ScopeOfWork[] } }>()
);

export const GetScopeOfWorksFailure = createAction(ActionTypes.GetScopeOfWorksFailure);

export const CreateScopeOfWork = createAction(
  ActionTypes.CreateScopeOfWork,
  props<{ payload: { scopeOfWork: NewScopeOfWork } }>()
);

export const CreateScopeOfWorkSuccessful = createAction(
  ActionTypes.CreateScopeOfWorkSuccessful,
  props<{ payload: { scopeOfWork: ScopeOfWork } }>()
);

export const CreateScopeOfWorkFailure = createAction(ActionTypes.CreateScopeOfWorkFailure);

export const UpdateScopeOfWork = createAction(
  ActionTypes.UpdateScopeOfWork,
  props<{ payload: { scopeOfWorkId: string; scopeOfWork: ScopeOfWorkPartial } }>()
);

export const UpdateScopeOfWorkSuccessful = createAction(
  ActionTypes.UpdateScopeOfWorkSuccessful,
  props<{ payload: { scopeOfWorkId: string; scopeOfWork: ScopeOfWorkPartial } }>()
);

export const UpdateScopeOfWorkFailure = createAction(ActionTypes.UpdateScopeOfWorkFailure);

export const DeleteScopeOfWork = createAction(
  ActionTypes.DeleteScopeOfWork,
  props<{ payload: { scopeOfWorkId: string } }>()
);

export const DeleteScopeOfWorkSuccessful = createAction(
  ActionTypes.DeleteScopeOfWorkSuccessful,
  props<{ payload: { scopeOfWorkId: string } }>()
);

export const DeleteScopeOfWorkFailure = createAction(ActionTypes.DeleteScopeOfWorkFailure);

export const ResetStateToInitial = createAction(ActionTypes.ResetStateToInitial);

export const ErrorAction = createAction(
  ActionTypes.ErrorAction,
  props<{ payload: { error: any; key: string; timeStamp: number } }>()
);
