/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CopyProjectRequestDto } from '../models/copy-project-request-dto';
import { CreateGitBranchRequestDto } from '../models/create-git-branch-request-dto';

@Injectable({
  providedIn: 'root',
})
export class DeveloperUtilityControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation developerUtilityControllerRemoveUnusedComponentsFromPage
   */
  static readonly DeveloperUtilityControllerRemoveUnusedComponentsFromPagePath = '/dev/utils/clean-page-component/{pageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUnusedComponentsFromPage()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUnusedComponentsFromPage$Response(params: {
    pageId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeveloperUtilityControllerService.DeveloperUtilityControllerRemoveUnusedComponentsFromPagePath, 'patch');
    if (params) {
      rb.path('pageId', params.pageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeUnusedComponentsFromPage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUnusedComponentsFromPage(params: {
    pageId: string;
  }): Observable<void> {

    return this.removeUnusedComponentsFromPage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation developerUtilityControllerCopyProjectFromSnapshot
   */
  static readonly DeveloperUtilityControllerCopyProjectFromSnapshotPath = '/dev/utils/copy-project-from-snapshot';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyProjectFromSnapshot()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyProjectFromSnapshot$Response(params?: {
    body?: CopyProjectRequestDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeveloperUtilityControllerService.DeveloperUtilityControllerCopyProjectFromSnapshotPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyProjectFromSnapshot$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyProjectFromSnapshot(params?: {
    body?: CopyProjectRequestDto
  }): Observable<void> {

    return this.copyProjectFromSnapshot$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation developerUtilityControllerCreateGitBranch
   */
  static readonly DeveloperUtilityControllerCreateGitBranchPath = '/dev/utils/create-git-branch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createGitBranch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createGitBranch$Response(params?: {
    body?: CreateGitBranchRequestDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeveloperUtilityControllerService.DeveloperUtilityControllerCreateGitBranchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createGitBranch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createGitBranch(params?: {
    body?: CreateGitBranchRequestDto
  }): Observable<void> {

    return this.createGitBranch$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation developerUtilityControllerCreateProjectSnapshotToCopy
   */
  static readonly DeveloperUtilityControllerCreateProjectSnapshotToCopyPath = '/dev/utils/create-project-snapshot-to-copy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProjectSnapshotToCopy()` instead.
   *
   * This method doesn't expect any request body.
   */
  createProjectSnapshotToCopy$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeveloperUtilityControllerService.DeveloperUtilityControllerCreateProjectSnapshotToCopyPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createProjectSnapshotToCopy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createProjectSnapshotToCopy(params?: {
  }): Observable<void> {

    return this.createProjectSnapshotToCopy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
