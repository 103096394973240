import { ButtonComponentConfig, ButtonSize, IconComponentConfig, IconType } from '@rappider/rappider-components/utils';

export const CreateUIDataEventButtonConfig: ButtonComponentConfig = {
  text: 'Create UI Data Event',
  size: ButtonSize.Small,
  icon: <IconComponentConfig>{
    name: 'fa-solid fa-scrubber',
    type: IconType.FontAwesome
  }
};
