import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewProjectModelFieldComponent } from './new-project-model-field.component';
import { RappiderHeadingModule, RappiderDividerModule, RappiderButtonModule, RappiderIconModule, RappiderListGridModule } from '@rappider/rappider-components';
import { NewProjectModelFieldFormModule } from '../new-project-model-field-form/new-project-model-field-form.module';
import { UpdateProjectModelFieldFormModule } from '../update-project-model-field-form/update-project-model-field-form.module';
import { TranslateModule } from '@ngx-translate/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@NgModule({
  declarations: [
    NewProjectModelFieldComponent
  ],
  imports: [
    CommonModule,
    RappiderHeadingModule,
    RappiderDividerModule,
    RappiderButtonModule,
    NewProjectModelFieldFormModule,
    UpdateProjectModelFieldFormModule,
    TranslateModule,
    RappiderIconModule,
    NzToolTipModule,
    DragDropModule,
    NzSkeletonModule,
    RappiderListGridModule
  ],
  exports: [
    NewProjectModelFieldComponent
  ]
})
export class NewProjectModelFieldModule { }
