import { KeyValue } from '@angular/common';
import { CommentStatus } from './comment-status.enum';

export const commentStatusOptions: KeyValue<string, CommentStatus>[] = [
  {
    key: 'SHARED.TODO',
    value: CommentStatus.todo
  },
  {
    key: 'SHARED.COMPLETED',
    value: CommentStatus.completed
  }
];
