import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CreateUiDataSelectorRequestDto, UiDataSelectorWithRelations, UpdateUiDataSelectorRequestDto } from '@rappider/rappider-sdk';
import { CreateUIDataSelector, UpdateUIDataSelector } from '../../state/ui-data-selector.actions';
import { DataTransformService } from '@rappider/services';

@Component({
  selector: 'rappider-ui-data-selector-select',
  templateUrl: './ui-data-selector-select.component.html',
  styleUrls: ['./ui-data-selector-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => UiDataSelectorSelectComponent),
      multi: true
    }
  ]
})
export class UiDataSelectorSelectComponent implements ControlValueAccessor {

  @Input() editingUiDataSelector?: UiDataSelectorWithRelations;
  @Input() createDataSelectorModalVisibility = false;
  @Input() editDataSelectorModalVisibility = false;

  @Output() createModalVisibilityChange = new EventEmitter<boolean>();
  @Output() editModalVisibilityChange = new EventEmitter<boolean>();

  /* cva value (ngModel) */
  _value: string | undefined;

  get value(): string | undefined {
    return this._value;
  }

  set value(value: string | undefined) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(
    private store: Store<any>,
    private dataTransformService: DataTransformService
  ) { }

  writeValue(value: string): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSave(payload: any) {
    const createdUIDataSelector: CreateUiDataSelectorRequestDto = {
      name: payload.uiDataSelectorName,
      jsonataConfig: payload.jsonataConfig,
      uiDataStoreFieldSelectors: payload.uiDataStoreFieldSelectors.map((uiDataFieldSelector: any) => ({
        uiDataStoreId: uiDataFieldSelector.uiDataStore.id,
        uiDataStoreSchemaFieldIds: uiDataFieldSelector.uiDataStoreSchemaFields.map((uiDataStoreSchemaField: any) => uiDataStoreSchemaField.id),
        variableName: uiDataFieldSelector.variableName
      }))
    };

    this.store.dispatch(CreateUIDataSelector({ payload: { uiDataSelector: createdUIDataSelector } }));
    this.createDataSelectorModalVisibility = false;
    this.createModalVisibilityChange.emit(this.createDataSelectorModalVisibility);
  }

  onEdit(payload: any) {
    if (this.editingUiDataSelector) {
      const changes = this.dataTransformService.compareArrays(this.editingUiDataSelector.uiDataStoreFieldSelectors as any, payload.uiDataStoreFieldSelectors, 'variableName', true);
      const uiDataSelector: UpdateUiDataSelectorRequestDto = {
        name: payload.uiDataSelectorName,
        jsonataConfig: payload.jsonataConfig,
        createdUIDataStoreFieldSelectors: changes.createdArray.map(created => ({
          uiDataStoreId: created.uiDataStore.id,
          uiDataStoreSchemaFieldIds: created.uiDataStoreSchemaFields.map((uiDataStoreSchemaField: any) => uiDataStoreSchemaField.id),
          variableName: created.variableName
        })),
        deletedUIDataStoreFieldSelectorIds: changes.deletedArray.map((deleted: any) => deleted.id),
      };

      this.store.dispatch(UpdateUIDataSelector({ payload: { uiDataSelector: uiDataSelector, uiDataSelectorId: this.editingUiDataSelector.id } }));
      this.editDataSelectorModalVisibility = false;
      this.editModalVisibilityChange.emit(this.editDataSelectorModalVisibility);
    }
  }

  onCancel(mode: string) {
    if (mode === 'create') {
      this.createDataSelectorModalVisibility = false;
      this.createModalVisibilityChange.emit(this.createDataSelectorModalVisibility);
    } else {
      this.editDataSelectorModalVisibility = false;
      this.editModalVisibilityChange.emit(this.editDataSelectorModalVisibility);
    }
  }

}
