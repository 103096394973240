/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FileFolderDto } from '../models/file-folder-dto';
import { SourceCodeCommitRequestDto } from '../models/source-code-commit-request-dto';
import { SourceCodeResponseDto } from '../models/source-code-response-dto';

@Injectable({
  providedIn: 'root',
})
export class SourceCodeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation sourceCodeControllerCreate
   */
  static readonly SourceCodeControllerCreatePath = '/source-code/commit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: SourceCodeCommitRequestDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SourceCodeControllerService.SourceCodeControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: SourceCodeCommitRequestDto
  }): Observable<void> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sourceCodeControllerGetComponentDefinitionDirectoryEntries
   */
  static readonly SourceCodeControllerGetComponentDefinitionDirectoryEntriesPath = '/source-code/component-definition-dirents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getComponentDefinitionDirectoryEntries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComponentDefinitionDirectoryEntries$Response(params?: {
  }): Observable<StrictHttpResponse<FileFolderDto>> {

    const rb = new RequestBuilder(this.rootUrl, SourceCodeControllerService.SourceCodeControllerGetComponentDefinitionDirectoryEntriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileFolderDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getComponentDefinitionDirectoryEntries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getComponentDefinitionDirectoryEntries(params?: {
  }): Observable<FileFolderDto> {

    return this.getComponentDefinitionDirectoryEntries$Response(params).pipe(
      map((r: StrictHttpResponse<FileFolderDto>) => r.body as FileFolderDto)
    );
  }

  /**
   * Path part for operation sourceCodeControllerGetDirectoryEntries
   */
  static readonly SourceCodeControllerGetDirectoryEntriesPath = '/source-code/dirents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDirectoryEntries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectoryEntries$Response(params?: {
  }): Observable<StrictHttpResponse<FileFolderDto>> {

    const rb = new RequestBuilder(this.rootUrl, SourceCodeControllerService.SourceCodeControllerGetDirectoryEntriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileFolderDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDirectoryEntries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDirectoryEntries(params?: {
  }): Observable<FileFolderDto> {

    return this.getDirectoryEntries$Response(params).pipe(
      map((r: StrictHttpResponse<FileFolderDto>) => r.body as FileFolderDto)
    );
  }

  /**
   * Path part for operation sourceCodeControllerGetFileContent
   */
  static readonly SourceCodeControllerGetFileContentPath = '/source-code/file-content';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFileContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFileContent$Response(params?: {
    filePath?: string;
  }): Observable<StrictHttpResponse<SourceCodeResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, SourceCodeControllerService.SourceCodeControllerGetFileContentPath, 'get');
    if (params) {
      rb.query('filePath', params.filePath, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SourceCodeResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFileContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFileContent(params?: {
    filePath?: string;
  }): Observable<SourceCodeResponseDto> {

    return this.getFileContent$Response(params).pipe(
      map((r: StrictHttpResponse<SourceCodeResponseDto>) => r.body as SourceCodeResponseDto)
    );
  }

}
