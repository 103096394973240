<nz-collapse>
  <nz-collapse-panel [nzHeader]="'Initial Actions'"
                     [nzExtra]="addInitEventTemplate">
    <ng-container [ngTemplateOutlet]="eventCards"
                  [ngTemplateOutletContext]="{data: {events: activePage.uiDataEventsOnInit, type: PageLifecycleType.Init}}">
    </ng-container>
  </nz-collapse-panel>

  <nz-collapse-panel [nzHeader]="'Destroy Actions'"
                     [nzExtra]="addDestroyEventTemplate">
    <ng-container [ngTemplateOutlet]="eventCards"
                  [ngTemplateOutletContext]="{data: {events: activePage.uiDataEventsOnDestroy, type: PageLifecycleType.Destroy}}">
    </ng-container>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #eventCards
             let-data='data'>
  <ng-container *ngIf="data.events?.length; else noEventsTemplate">
    <section *ngFor="let event of data.events"
             class="event-card">
      <div class="event-name">{{ event.name }}</div>
      <rappider-button [icon]="{name: 'fa-light fa-trash'}"
                       [tooltipText]="'Remove Event'"
                       (confirm)="onRemoveEvent(event.id, data.type)"></rappider-button>
    </section>
  </ng-container>
</ng-template>

<ng-template #addInitEventTemplate>
  <button nz-button
          nz-tooltip="Add Event"
          (click)="onAddEventButtonClick($event, PageLifecycleType.Init)">
    <span class="fa-light fa-plus"></span>
  </button>
</ng-template>

<ng-template #addDestroyEventTemplate>
  <button nz-button
          nz-tooltip="Add Event"
          (click)="onAddEventButtonClick($event, PageLifecycleType.Destroy)">
    <span class="fa-light fa-plus"></span>
  </button>
</ng-template>

<ng-template #noEventsTemplate>
  {{'There are currently no added events.' | translate}}
</ng-template>

<nz-modal [(nzVisible)]="isEventsModalVisible"
          [nzTitle]="eventsModalTitle"
          [nzOkText]="'Save'"
          (nzOnOk)="onAddEvents()"
          (nzOnCancel)="toggleEventsModalVisibility()">
  <ng-container *nzModalContent>
    <div class="modal-body">
      <div>
        <rappider-label [content]="'UI Data Store:'"></rappider-label>
        <rappider-select [options]="uiDataStoreSelectOptions"
                         (valueChange)="onUiDataStoreSelect($event)"></rappider-select>
      </div>
      <div>
        <rappider-label [content]="'Events:'"></rappider-label>
        <rappider-select [options]="uiDataEventSelectOptions"
                         [settings]="uiDataEventSelectSettings"
                         (valueChange)="onSelectUIDataEvents($event)"></rappider-select>
      </div>
    </div>
  </ng-container>
</nz-modal>
