<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<section>

  <nz-tabset nzType="card"
             [nzSelectedIndex]="2"
             nzAnimated="true"
             nzTabPosition="top"
             nzSize="default"
             class="card-container">
    <nz-tab [nzTitle]="'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.OWNERS' | translate">
      <rappider-list-grid [config]="PROJECT_OWNERS_CONFIG"
                          [data]="owners"
                          [loading]="isLoading"></rappider-list-grid>
    </nz-tab>
    <nz-tab [nzTitle]="'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.MANAGERS' | translate">
      <rappider-list-grid [config]="PROJECT_MANAGERS_CONFIG"
                          [data]="managers"
                          [loading]="isLoading"
                          (columnActionClick)="onItemClick($event)"></rappider-list-grid>
    </nz-tab>
    <nz-tab [nzTitle]="'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.MEMBERS' | translate">
      <rappider-list-grid [config]="PROJECT_MEMBERS_CONFIG"
                          [data]="members"
                          [loading]="isLoading"
                          (columnActionClick)="onItemClick($event)"></rappider-list-grid>
    </nz-tab>
  </nz-tabset>
  <rappider-member-roles [rolesModalConfig]="rolesModalConfig"
                         [activeProject]="activeProject"
                         (changeRole)="onRoleChange($event)"></rappider-member-roles>
</section>
