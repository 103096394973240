import * as deepmerge from 'deepmerge';
import { validateStateKeys } from './sync-utils';
import { config } from './models';
import { ActionReducer } from '@ngrx/store';

const INIT_ACTION = '@ngrx/store/init';
const UPDATE_ACTION = '@ngrx/store/update-reducers';

// this function rehydrates the application state from the storage
// Reads the keys from the storage and hydra and returns the state
export const rehydrateApplicationState = (
    keys: string[],
    storage: Storage | undefined
): Record<string, any> => keys.reduce((acc: Record<string, any>, key: string) => {
    if (storage) {
        const stateSlice = storage.getItem(key);
        if (stateSlice) {
            let parsedSlice: any = stateSlice;
            if (stateSlice === 'null' || /^[{[]/.test(stateSlice.charAt(0))) {
                parsedSlice = JSON.parse(stateSlice);
            }
            return { ...acc, [key]: parsedSlice };
        }
    }
    return acc;
}, {});


// this function syncs the state to the storage
export const syncStateUpdate = (
    state: Record<string, any>,
    keys: string[],
    storage: Storage | undefined
): void => {
    keys.forEach((key: string) => {
        const stateSlice = state[key];

        if (stateSlice !== undefined && storage) {
            try {
                storage.setItem(
                    key,
                    typeof stateSlice === 'string' ? stateSlice : JSON.stringify(stateSlice)
                );
            } catch (e) {
                console.log('Unable to save state to localStorage:', e);
            }
        } else if (stateSlice === undefined && storage) {
            try {
                storage.removeItem(key);
            } catch (e) {
                console.log(`Exception on removing/cleaning undefined '${key}' state`, e);
            }
        }
    });
};


// this function returns the storageSync meta-reducer
export function storageSync<T>(reducer: ActionReducer<T>): ActionReducer<T> {
    const stateKeys = validateStateKeys(config.keys);
    const rehydratedState = rehydrateApplicationState(stateKeys, config.storage);

    return (state: T | undefined, action: { type: string }): T => {
        let nextState: T = state ? { ...state } : reducer(state, action);

        if (action.type === INIT_ACTION || action.type === UPDATE_ACTION) {
            const overwriteMerge = (destinationArray: any[], sourceArray: any[]) => sourceArray;
            const options: deepmerge.Options = {
                arrayMerge: overwriteMerge,
            };
            nextState = deepmerge(nextState, rehydratedState, options) as T;
        }

        nextState = reducer(nextState, action);

        if (action.type !== INIT_ACTION) {
            syncStateUpdate(nextState, stateKeys, config.storage);
        }

        return nextState;
    };
}
