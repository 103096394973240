import { TextBoxType } from "./textbox-type.enum";

export const textboxTypeOptions = [
  {
    key: 'Text',
    value: TextBoxType.Text
  },
  {
    key: 'Password',
    value: TextBoxType.Password
  },
  {
    key: 'Email',
    value: TextBoxType.Email
  }
];
