import { Component, OnInit } from '@angular/core';
import { BreadcrumbOption } from 'libs/components/src/lib/utils/breadcrumb';
import { HeadingComponentConfig } from 'libs/components/src/lib/utils/heading';
import { PersonWithRelations, ProjectWithRelations } from 'libs/rappider-sdk/src/lib/models';
import { defaultToolbarTitleHeadingSize } from 'libs/shared/src/lib/definitions/default-toolbar-title-heading-size';
import { PAGE_DEFINITIONS } from 'libs/shared/src/lib/definitions/page-definition';
import { PATH_DEFINITIONS } from 'libs/shared/src/lib/definitions/path-definition';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PROJECT_MEMBERS_CONFIG } from './config/project-members-config';
import { cloneDeep } from 'lodash';
import { Authority } from 'libs/shared/src/lib/definitions/authority.enum';
import { Authorities } from 'libs/shared/src/lib/configs/project';
import { UnassignPersonFromProject } from 'libs/project/src/lib/states/project-member-state/project-member.actions';

@Component({
  selector: 'rappider-project-members',
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.scss']
})
export class ProjectMembersComponent implements OnInit {

  PROJECT_MEMBERS_CONFIG = PROJECT_MEMBERS_CONFIG;

  mainTitle: HeadingComponentConfig = {
    content: 'Admin Dashboard',
    type: defaultToolbarTitleHeadingSize
  };
  title: BreadcrumbOption[] = [];
  selectedProject: ProjectWithRelations;
  projectMembers: PersonWithRelations[];
  subscriptions: Subscription[] = [];
  members = [];
  owners = [];
  managers = [];
  loading = false;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToSelectedProject(),
      this.subscribeToProjectMembers(),
      this.subscribeToProjectMembersLoading()
    ];
  }

  subscribeToSelectedProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        this.selectedProject = activeProject;
        this.setTitle();
      }
    });
  }

  subscribeToProjectMembers() {
    return this.store.select(state => state.projectMember?.data).subscribe((projectMembers: PersonWithRelations[]) => {
      if (projectMembers) {
        this.projectMembers = projectMembers;
        this.getMembers();
      }
    });
  }

  subscribeToProjectMembersLoading() {
    return this.store.select(state => state.projectMember?.loading).subscribe(loading => {
      this.loading = loading;
    });
  }

  setTitle() {
    this.title = [
      {
        label: 'Admin Dashboard',
        redirectUrl: PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL
      },
      {
        label: 'Projects',
        redirectUrl: PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECTS
      },
      {
        label: 'Project Detail',
        redirectUrl: `${PATH_DEFINITIONS.ADMIN_DASHBOARD.PROJECT_DETAIL}/${this.selectedProject?.id}`
      },
      {
        label: this.selectedProject?.name
      },
      {
        label: 'Project Members'
      }
    ];
  }

  getMembers() {
    if (this.projectMembers) {
      this.owners = this.projectMembers
        .filter(member => member?.authorities?.some(authority => authority?.name === Authority.ProjectOwner));
      this.managers = this.projectMembers
        .filter(member => member?.authorities?.some(authority => authority?.name === Authority.ProjectManager));

      this.members = cloneDeep(this.projectMembers)?.map(member => {
        if (member?.authorities?.some(auth => auth?.name === Authorities.ProjectOwner)) {
          return {
            ...member,
            roles: 'Project Owner'
          };
        } else if (member.authorities?.some(auth => auth?.name === Authorities.ProjectManager)) {
          return {
            ...member,
            roles: 'Project Manager'
          };
        } else {
          if (member?.roles?.length > 0) {
            const roleNames = member?.roles?.map(role => role.title).join(', ');
            return {
              ...member,
              roles: roleNames
            };
          } else {
            return {
              ...member,
              roles: 'Project Member'
            };
          }
        }
      });
      this.members = [...this.members];
    }
  }

  onColumnActionClick(event) {
    this.store.dispatch(new UnassignPersonFromProject({ personId: event?.data?.id }));
  }
}
