import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PersonInterface } from '@rappider/api-sdk';
import { NotificationService, StringUtilityService } from '@rappider/services';
import { Category, NewProject, Project, ProjectWithRelations } from '@rappider/rappider-sdk';
import { PROJECT_CREATE_FORM_CONFIG } from '@rappider/shared/configs';
import { CrudFormRadioGroupItem, CrudFormSwitchItem, CrudFormTextAreaItem, TextMode, HeadingType, SelectComponentConfig, SelectMode, IconType, NgZorroIconTheme, SelectableOption } from '@rappider/rappider-components/utils';
import { getTenantProjects } from '@rappider/shared';
import { projectNameValidator } from '@rappider/shared/validators';
import { CustomProjectWithAI } from 'libs/project/src/lib/components/project-list/utils/create-with-ai-template';
import { ProjectCreateType } from '../project-list/utils/project-create-type.enum';
import { BlankTemplate } from '../project-list/utils/blank-template';
import { publicProjectsCardsConfig, publicProjectsTopSectionCardsConfig } from '../project-environment-variables/utils/public-projects-cards-config';
import { CreateProject, GetPublicProjects, GetPublicProjectsPagination } from '../../states/projects-state/project.actions';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { getPublicProjectWithCategoriesSelector } from './utils/get-public-project-categories-selector';

@Component({
  selector: 'rappider-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit, OnDestroy {

  PROJECT_CREATE_FORM_CONFIG = PROJECT_CREATE_FORM_CONFIG;
  subscriptions: Subscription[] = [];
  activePerson: PersonInterface;
  /* Project Form Data */
  projectFormData: Partial<Project> = {
    name: null,
    key: null,
    metadata: null,
    isCreatedByAI: true
  };
  /* loading state of the project */
  isLoading: boolean;
  lottieConfig = {
    options: {
      autoplay: true,
      loop: true,
      path: 'https://lottie.host/1a64066a-5ce0-41d1-a592-98bdbbc12755/8BhZMRWOgR.json'
    },
    height: '200px'
  };
  createProjectProcess = false;
  projectNames = [];
  publicProjects: ProjectWithRelations[] = [];
  publicProjectTags: Category[];
  publicProjectCategories: SelectableOption[];
  selectedTemplateProjectId: string;
  ProjectCreateType = ProjectCreateType;
  isTemplateSelected: boolean;
  publicProjectsCardsConfig = publicProjectsCardsConfig;
  publicProjectsTopSectionCardsConfig = publicProjectsTopSectionCardsConfig;
  hasAuthenticationModule: boolean;
  hasPaymentModule: boolean;
  isCreatedByAI: boolean;
  selectedTemplate: ProjectWithRelations;
  pageIndex = 1;
  originalCardsConfig;
  topSectionHeight: string;
  bottomSectionHeight: string;
  selectConfig: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Multiple,
      maxTagCount: 1
    },
    placeholder: 'Filter Tags'
  };
  timer;
  searchText: string;
  categoryIds: string[];
  activeUserRole: string;
  modalVisibility = false;
  modalTitle = 'Preview';
  modalOkText = 'Build with template';
  modalCancelText = 'Close';
  selectedTemplatePreview: ProjectWithRelations;
  selectedTemplateNameConfig = {
    typography: {
      fontSize: 'var(--h3-font-size)',
      fontWeight: 'bold'
    },
    colorSettings: {
      color: 'var(--text-color)',
      backgroundColor: 'var(--component-inner-background-color)'
    }
  };
  selectedTemplateDescriptionConfig = {
    typography: {
      fontSize: 'var(--h4-font-size)'
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };
  thumbnailTitleConfig = {
    typography: {
      fontSize: 'var(--h5-font-size)',
      fontWeight: 'bold'
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };
  thumbnailDescriptionConfig = {
    typography: {
      fontSize: 'var(--h6-font-size)'
    },
    colorSettings: {
      color: 'var(--text-color)'
    }
  };

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getSectionsHeight()
    this.subscribeToData();
  }

  /**
   * onDestroy
   *
   * @memberof ProjectCreateComponent
   */
  ngOnDestroy(): void {
    /* Unsubscribes all subscriptions */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(new GetPublicProjectsPagination({ pageIndex: 1, pageSize: 8 }));
  }

  /**
   *  Subscribing all Data
   *
   * @memberof ProjectCreateComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToServiceActivatedRouteQueryParams(),
      this.subscribeToPerson(),
      this.subscribeToProjectLoading(),
      this.subscribeToProjectErrors(),
      this.subscribeToProjects(),
      this.subscribeToPublicProjectWithCategories(),
      this.subscribeToPublicProjectsLoading(),
      this.subscribeToPagination()
    ];
  }

  subscribeToServiceActivatedRouteQueryParams() {
    return this.activatedRoute.queryParams.subscribe(queryParam => {
      if (queryParam?.selectedTemplateId) {
        this.onCardClick(queryParam?.selectedTemplateId, true)
      }
    });
  }

  /**
   * This func. subscribe person
   *
   * @returns
   * @memberof ProjectCreateComponent
   */
  subscribeToPerson() {
    /* Subscribe to store */
    return this.store.select(state => state.auth?.activePerson).subscribe(activePerson => {
      this.activePerson = activePerson;
    });
  }

  subscribeToProjectLoading() {
    return this.store.select(state => state.project?.loading).subscribe(loading => {
      this.isLoading = loading;
    });
  }

  subscribeToProjectErrors() {
    return this.store.select(state => state.project.lastError).subscribe(error => {
      if (this.createProjectProcess && error.errorKey === 'CreateProject') {
        this.createProjectProcess = false;
      }
    });
  }

  subscribeToProjects() {
    return this.store.select(<any>getTenantProjects).subscribe(projects => {
      if (projects?.length) {
        this.projectNames = projects.map(project => project.name);
        this.handleProjectNameValidator();
      }
    });
  }

  subscribeToPublicProjectsLoading() {
    return this.store.select(state => state.project?.isPublicProjectsLoading).subscribe((isPublicProjectsLoading: boolean) => {
      this.publicProjectsCardsConfig.publicProjectsIsLoading = isPublicProjectsLoading;
    });
  }

  handleProjectNameValidator() {
    const formItem = this.PROJECT_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'name');
    const validator = formItem.validators.find(validator => validator.errorKey === 'projectNameExists');
    if (!validator) {
      formItem.validators.push({
        type: projectNameValidator(this.projectNames),
        errorKey: 'projectNameExists',
        errorMessage: 'Project name already exists'
      });
    } else {
      validator.type = projectNameValidator(this.projectNames);
    }
    this.PROJECT_CREATE_FORM_CONFIG = { ...this.PROJECT_CREATE_FORM_CONFIG };
  }

  createProject(project: NewProject) {
    if (this.activePerson) {
      const projectData = {
        ...project,
        hasAuthenticationModule: this.hasAuthenticationModule ?? project.hasAuthenticationModule,
        hasPaymentModule: this.hasPaymentModule ?? project.hasPaymentModule,
        isCreatedByAI: this.isCreatedByAI ?? project.isCreatedByAI,
        selectedTemplateProjectId: this.selectedTemplateProjectId
      };
      if (this.selectedTemplateProjectId === this.ProjectCreateType.Blank || this.selectedTemplateProjectId === this.ProjectCreateType.AI) {
        delete projectData.selectedTemplateProjectId;
      }
      this.store.dispatch(new CreateProject({ project: projectData }));
      this.createProjectProcess = true;
    }
  }

  onFieldValueChange(fieldValue: { [key: string]: string }) {
    if (fieldValue.name) {
      this.projectFormData.key = StringUtilityService.toKebabCase(fieldValue.name);
      this.projectFormData = { ...this.projectFormData, ...fieldValue };
    }
    if (fieldValue.isCreatedByAI !== undefined) {
      const fieldConfig = this.PROJECT_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'isCreatedByAI') as CrudFormSwitchItem;
      if (fieldValue.isCreatedByAI) {
        fieldConfig.text = { text: 'Build my project with Rapider AI' };
      } else {
        fieldConfig.text = { text: 'Create a blank project, I will build it from scratch' };
      }
      this.PROJECT_CREATE_FORM_CONFIG = { ...this.PROJECT_CREATE_FORM_CONFIG };
    }
  }

  subscribeToPublicProjectWithCategories() {
    return this.store.select(getPublicProjectWithCategoriesSelector).subscribe((publicProjectsWithCategories: { publicProjects: ProjectWithRelations[], projectCategoryWithIds: SelectableOption[] }) => {
      if (publicProjectsWithCategories) {
        this.publicProjects = publicProjectsWithCategories.publicProjects;
        this.publicProjectCategories = publicProjectsWithCategories.projectCategoryWithIds;
        publicProjectsCardsConfig.selectConfig.options = publicProjectsWithCategories.projectCategoryWithIds;
        this.buildCardOneConfigByTemplateData();
      }
    });
  }

  buildCardOneConfigByTemplateData() {
    this.publicProjectsCardsConfig.items = this.publicProjects?.map(template => ({
      data: template,
      image: {
        source: template?.thumbnails?.length > 0 ? template.thumbnails[0].url : 'assets/img/page-templates/profile-template1-thumbnail.png'
      },
      titles: [
        {
          type: HeadingType.H4,
          content: template.name
        }
      ],
      descriptions: [
        {
          typography: {
            fontSize: 'var(--subtext-font-size)'
          },
          content: template.description
        }
      ],
      imageTags: [{}],
      imageButtons: [{
        icon: {
          name: 'eye',
          type: IconType.NgZorro,
          theme: NgZorroIconTheme.Outline
        },
        customColorSettings: {
          backgroundColor: 'var(--section-background-color)',
          color: 'var(--text-color)'
        },
        shadowSettings: {
          boxShadow: '0 0 0 var(--section-background-color)'
        },
        size: 'small'
      }],
      additionalTags: template.categoryIds?.map(categoryId => {
        const categoryText = this.publicProjectCategories.find(category => category.value === categoryId)?.key
        if (categoryText) {
          return {
            text: {
              textMode: TextMode.Text,
              text: categoryText
            }
          }
        } else {
          return {}
        }
      })
    }));
    this.originalCardsConfig = this.publicProjectsCardsConfig.items;
  }

  onCardClick(selectedTemplateId, isTemplateSelected?) {
    const defaultTemplateCheck = [BlankTemplate, CustomProjectWithAI].some(template => template.id === selectedTemplateId)
    if (defaultTemplateCheck || isTemplateSelected) {
      this.isTemplateSelected = true;
    } else {
      this.router.navigate([`/projects/preview`, selectedTemplateId]);
    }
    this.publicProjectsCardsConfig.items = this.originalCardsConfig
    this.selectedTemplate = [BlankTemplate, CustomProjectWithAI, ...this.publicProjects].find(publicProject => publicProject.id === selectedTemplateId);
    this.resetForm();
    this.selectedTemplateProjectId = selectedTemplateId;
    const authenticationModuleForm = this.PROJECT_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'hasAuthenticationModule') as CrudFormRadioGroupItem;
    const paymentModuleForm = this.PROJECT_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'hasPaymentModule') as CrudFormRadioGroupItem;
    const createdByAIForm = this.PROJECT_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'isCreatedByAI') as CrudFormSwitchItem;
    const description = this.PROJECT_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'description') as CrudFormTextAreaItem;
    if (selectedTemplateId === ProjectCreateType.Blank) {
      createdByAIForm.visible = false;
      this.isCreatedByAI = false;
      description.labelDescription = 'Please provide a comprehensive description of your project and ensure your description is detailed, addressing all facets of your vision, goals, and any specific functionalities or features you have in mind.';
      description.validators = [];
      description.minLength = 0;
    } else if (selectedTemplateId === ProjectCreateType.AI) {
      createdByAIForm.visible = false;
      this.isCreatedByAI = true;
      description.labelDescription = 'Your description should be more than 500 characters in length. Please provide a comprehensive description of your project and ensure your description is detailed, addressing all facets of your vision, goals, and any specific functionalities or features you have in mind.';
      description.validators = [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.minLength(500),
          errorKey: 'minlength',
          errorMessage: 'Description must be at least 500 character long.'
        }
      ];
      description.minLength = 500;
    } else {
      const selectedTemplate = this.publicProjects.find(publicProject => publicProject.id === selectedTemplateId);
      this.hasAuthenticationModule = selectedTemplate?.hasAuthenticationModule;
      this.hasPaymentModule = selectedTemplate?.hasPaymentModule;
      this.isCreatedByAI = false;
      authenticationModuleForm.visible = false;
      authenticationModuleForm.validators = [];
      paymentModuleForm.visible = false;
      paymentModuleForm.validators = [];
      createdByAIForm.visible = false;
      description.labelDescription = 'Please provide a comprehensive description of your project and ensure your description is detailed, addressing all facets of your vision, goals, and any specific functionalities or features you have in mind.';
      description.validators = [];
      description.minLength = 0;
    }
    this.PROJECT_CREATE_FORM_CONFIG = { ...this.PROJECT_CREATE_FORM_CONFIG };
  }

  resetForm() {
    const authenticationModuleForm = this.PROJECT_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'hasAuthenticationModule') as CrudFormRadioGroupItem;
    const paymentModuleForm = this.PROJECT_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'hasPaymentModule') as CrudFormRadioGroupItem;
    const createdByAIForm = this.PROJECT_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'isCreatedByAI') as CrudFormSwitchItem;
    authenticationModuleForm.visible = true;
    authenticationModuleForm.validators = [
      {
        type: Validators.required,
        errorKey: 'required',
        errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
      }
    ];
    paymentModuleForm.visible = true;
    paymentModuleForm.validators = [
      {
        type: Validators.required,
        errorKey: 'required',
        errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
      }
    ];
    createdByAIForm.visible = true;
  }

  cardsClick() {
    if (this.selectedTemplateProjectId) {
      this.isTemplateSelected = true;
    } else {
      this.notificationService.createNotification(
        'error',
        'Error',
        'Please select a project'
      );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.getSectionsHeight();
  }

  getSectionsHeight() {
    this.topSectionHeight = `${Math.round(window.innerHeight / 7.4)}px`
    this.bottomSectionHeight = `calc(100vh - 300px - ${this.topSectionHeight})`;
  }

  subscribeToPagination() {
    return this.store.select(state => state.project.publicProjectsCount).subscribe((publicProjectsCount: number) => {
      this.publicProjectsCardsConfig.paginationConfig.total = publicProjectsCount;
    });
  }

  onPageIndexChange(pageIndex: number) {
    this.store.dispatch(new GetPublicProjects({ pageIndex, pageSize: 8, searchText: this.searchText, categoryIds: this.categoryIds, isDynamicPagination: true }));
  }

  onTagFilterChange(categoryIds) {
    this.categoryIds = categoryIds;
    this.store.dispatch(new GetPublicProjectsPagination({ pageIndex: 1, pageSize: 8, searchText: this.searchText, categoryIds }));
  }

  onSearchTextChange(searchText: string) {
    if (searchText || searchText === '') {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.searchText !== searchText) {
          this.searchText = searchText;
          this.store.dispatch(new GetPublicProjectsPagination({ pageIndex: 1, pageSize: 8, searchText, categoryIds: this.categoryIds }));
          this.publicProjectsCardsConfig.paginationConfig.pageIndex = 1;
        }
      }, 500);
    }
  }

  onImageButtonClick(output) {
    this.selectedTemplatePreview = output.imageButtonData?.item?.data;
    this.modalVisibility = true;
  }

  modalOkClick() {
    this.onCardClick(this.selectedTemplatePreview.id, true);
    this.modalVisibility = false;
  }

  modalCancelClick() {
    this.modalVisibility = false;
  }
}
