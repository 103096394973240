import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rappider-data-models-diagram',
  templateUrl: './data-models-diagram.component.html',
  styleUrls: ['./data-models-diagram.component.scss']
})
export class DataModelsDiagramComponent implements OnInit {

  ngOnInit(): void {
    console.log('Component initialized.');
  }

}
