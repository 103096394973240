import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DataSchemaControllerService, UiDataSelectorWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import * as jsonata from 'jsonata';
import { JsonSchemaService } from 'libs/components/src/lib/services';
import { UiDataStoreFieldSelectorValue } from 'libs/data-subscription/src/lib/components/ui-data-store-field-tree-selector/ui-data-store-field-selector-value.interface';
import { cloneDeep } from 'lodash';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';

@Component({
  selector: 'rappider-ui-data-selector',
  templateUrl: './ui-data-selector.component.html',
  styleUrls: ['./ui-data-selector.component.scss']
})
export class UiDataSelectorComponent implements OnChanges {

  @Input() uiDataSelector?: UiDataSelectorWithRelations;

  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter<void>();

  dataSources: UiDataStoreFieldSelectorValue[] = [];
  uiDataSelectorName = '';
  isExistError = false;

  /* sample json variables */
  sampleJsonLoading = false;
  sampleJsonOptions = {
    language: 'json',
    automaticLayout: true,
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 }
  };

  /* script output variables */
  scriptOutput?: string;
  scriptOutputOptions = {
    language: 'json',
    automaticLayout: true,
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 }
  };

  jsonataPreviewLoading = false;
  jsonataPreview = null;
  jsonataPreviewError = false;

  jsonataConfig = '';

  private _sampleJson = '';

  get sampleJson(): string {
    return this._sampleJson;
  }

  set sampleJson(value: string) {
    if (value !== this._sampleJson) {
      this._sampleJson = value;
      this.setPreviewOfJsonataConfig();
    }
  }

  constructor(
    private dataSchemaApi: DataSchemaControllerService,
    private jsonSchemaService: JsonSchemaService,
    private notificationService: NotificationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    /* edit mode */
    if (changes.uiDataSelector && changes.uiDataSelector.currentValue && this.uiDataSelector?.uiDataStoreFieldSelectors?.length) {
      this.jsonataConfig = this.uiDataSelector.jsonataConfig || '';
      this.uiDataSelectorName = this.uiDataSelector.name;
      this.dataSources = this.uiDataSelector.uiDataStoreFieldSelectors?.map((fieldSelector: any) => ({
        variableName: fieldSelector.variableName,
        uiDataStore: fieldSelector.uiDataStore,
        uiDataStoreSchemaFields: fieldSelector.uiDataStoreSchemaFields
      }));
      this.generateSampleJson();
    }
  }

  fieldTreeSelectorChange() {
    this.generateSampleJson();
  }

  onUiDataSelectorNameChange(value: string) {
    if (value) {
      this.isExistError = false;
    } else {
      this.isExistError = true;
    }
  }

  generateSampleJson() {
    this.sampleJson = '';  // Use an empty string instead of null
    this.dataSources.forEach(dataSource => {
      const dataSchemaId = dataSource?.uiDataStoreSchemaFields?.length
        ? dataSource.uiDataStoreSchemaFields[dataSource.uiDataStoreSchemaFields.length - 1]?.typeId
        : dataSource.uiDataStore.dataSchemaId;

      if (!dataSchemaId) {
        console.log('Data schema ID is undefined');
        return;
      }

      this.sampleJsonLoading = true;
      this.dataSchemaApi.getJSONSchemasByIds({ dataSchemaId: [dataSchemaId] }).subscribe(jsonSchemas => {
        const jsonSchema = jsonSchemas?.[0]?.jsonSchema;
        const isArray = dataSource.uiDataStoreSchemaFields?.[dataSource.uiDataStoreSchemaFields.length - 1]?.isArray;

        if (jsonSchema) {
          this.jsonSchemaService.generateJsonSampleFromJsonSchema(jsonSchema).then(sampleData => {
            let exampleJsonObject: any = this.sampleJson ? JSON.parse(this.sampleJson) : {};
            exampleJsonObject = {
              ...exampleJsonObject,
              [dataSource.variableName]: isArray ? [sampleData] : sampleData
            };
            this.sampleJson = JSON.stringify(exampleJsonObject);
          });
        }
      });
      this.sampleJsonLoading = false;
    });
  }

  async setPreviewOfJsonataConfig() {
    if (this.jsonataConfig && this.jsonataConfig !== '') {
      try {
        this.jsonataPreviewLoading = true;
        const exampleJsonObject = JSON.parse(this.sampleJson);
        this.scriptOutput = await jsonata(this.jsonataConfig).evaluate(exampleJsonObject);
        if (this.scriptOutput == null) {
          this.scriptOutput = 'Invalid Script. Check the key that entered from Source Schema Sample or Enter data in JSON Format';
        } else {
          this.scriptOutput = JSON.stringify(this.scriptOutput);
        }
        this.jsonataPreviewError = false;
        this.jsonataPreviewLoading = false;
      } catch (e: any) {
        this.scriptOutput = e?.message || 'An unknown error occurred';
        this.jsonataPreviewError = true;
        this.jsonataPreviewLoading = false;
      }
    } else {
      this.scriptOutput = 'Enter data in JSON Format or Copy/Paste a key from Source Schema Sample to see Output';
    }
  }

  onSave() {
    if (this.uiDataSelectorName.trim() === '' || !isNonEmptyString(this.jsonataConfig)) {
      const errorMessage = this.uiDataSelectorName.trim() === '' && !isNonEmptyString(this.jsonataConfig)
        ? 'Fill all the required fields'
        : this.uiDataSelectorName.trim() === '' ? 'Enter a selector name'
          : 'Enter Jsonata config';

      this.isExistError = this.uiDataSelectorName.trim() === '';
      this.notificationService.createNotification(
        'error',
        'Error',
        errorMessage
      );
      return;
    }
    const dataToEmit = {
      uiDataSelectorName: this.uiDataSelectorName,
      uiDataStoreFieldSelectors: this.dataSources,
      jsonataConfig: this.jsonataConfig
    };
    this.save.emit(dataToEmit);
  }

  onCancel() {
    this.cancel.emit();
  }

}
