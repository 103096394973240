<rappider-title-toolbar [mainTitle]="mainTitle"
                        [displayToolbar]="false"
                        [displayToolbar]="displayToolbar"
                        [titleBarActionButtons]="[addNewProjectButton]"
                        [displayToolbarBackButton]="displayToolbarBackButton"
                        (titleBarActionButtonClick)="onActionButtonClick($event)">
</rappider-title-toolbar>

<!-- Custom toolbar -->
<section class="filter-and-create-project-area">
  <div class="cards-search-container">
    <rappider-input-group [(ngModel)]="cardListSearchText"
                          [textbox]="cardSearchInputConfig.textbox"
                          [suffixIcon]="cardSearchInputConfig.suffixIcon"></rappider-input-group>
  </div>

  <nz-select class="filter-project-select"
             [(ngModel)]="selectedCategoryName"
             [nzMaxTagCount]="0"
             [nzPlaceHolder]="colSelectPlaceHolder"
             [nzMaxTagPlaceholder]="colSelectPlaceHolder"
             nzMode="default"
             (ngModelChange)="onProjectFilterChange()">
    <nz-option *ngFor="let filter of ProjectFilterOptions"
               [nzLabel]="filter.key | translate"
               [nzValue]="filter.value"></nz-option>
  </nz-select>
  <ng-template #colSelectPlaceHolder
               let-selectedList>
    <div class="filter-project-placeholder">
      <div>
        <i class="far fa-line-columns"></i>
      </div>
      <div>
        &nbsp;{{'Filter Project'|translate}}
      </div>
    </div>
  </ng-template>
</section>

<section class="project-list-container">
  <rappider-spin [spinning]="isLoading || loadingActiveProjectId || !isLoaded">
    <div *ngIf="displayedProjects?.length; else noProjectTmpl">
      <div *ngIf="filteredProjects?.length; else noFilteredProjectTmpl"
           class="cards-container row">
        <div *ngFor="let project of filteredProjects; let i = index"
             class="project-card col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <rappider-card-ccs [data]="project"
                             [image]="getProjectListCardConfig(project, i).image"
                             [title]="getProjectListCardConfig(project, i).title"
                             [subtitles]="[{
                              content: (('created by' | translate) + ' ' + project.createdBy +
                              '<br> on ' + (project.createdDate | date:'longDate')),
                              type: 'h6'
                             }]"
                             [description]="project.description"
                             [secondActionButton]="getSecondActionButton(project)"
                             [thirdActionButton]="projectListWithCard.thirdActionButton"
                             (actionClick)="onProjectAction($event, project)"
                             (itemSelect)="onActiveProjectSelect(project)">
          </rappider-card-ccs>
        </div>
      </div>
    </div>
  </rappider-spin>
</section>

<ng-template #noFilteredProjectTmpl>
  <div *ngIf="isLoaded">
    <rappider-alert [type]="projectListAlertConfigBySearch.type"
                    [title]="projectListAlertConfigBySearch.title"
                    [closeable]="projectListAlertConfigBySearch.closeable"
                    [showIcon]="projectListAlertConfigBySearch.showIcon"></rappider-alert>
  </div>
</ng-template>

<ng-template #noProjectTmpl>
  <div *ngIf="!isStarredProjectsSelected && selectedCategoryName !== ProjectFilterOptions[3].value && isLoaded; else alertTemplate"
       class="no-project-wrapper">
    <div class="no-project"
         [class.disabled]="!person?.isVerified"
         [nz-tooltip]="getTooltipText() | translate"
         (click)="createProject()">
      <rappider-lottie-player [options]="lottieConfig.options"
                              [height]="lottieConfig.height"></rappider-lottie-player>
      <rappider-text [text]="'PROJECT_MODULE.PROJECT_LIST_COMPONENT.NO_PROJECT' | translate"></rappider-text>
    </div>
  </div>
</ng-template>

<ng-template #alertTemplate>
  <div *ngIf="isLoaded">
    <rappider-alert [type]="isStarredProjectsSelected ? projectListAlertConfig.type : sharedProjectsAlertConfig.type"
                    [title]="isStarredProjectsSelected ? projectListAlertConfig.title : sharedProjectsAlertConfig.title"
                    [closeable]="isStarredProjectsSelected ? projectListAlertConfig.closeable : sharedProjectsAlertConfig.closeable"
                    [showIcon]="isStarredProjectsSelected ? projectListAlertConfig.showIcon : sharedProjectsAlertConfig.showIcon">
    </rappider-alert>
  </div>
</ng-template>

<rappider-modal [(visible)]="modalVisibility"
                [okDanger]="true"
                [okDisabled]="confirmationText !== deleteProject?.name"
                [okLoading]="deleteModalOkLoading"
                [title]="'Are you sure you want to delete this project?'"
                [okText]="'SHARED.DELETE'| translate"
                [cancelText]="'SHARED.CANCEL'| translate"
                (cancelClick)="onCancelModal()"
                (okClick)="onDeleteProject()">
  <div class="modal-content">
    <div class="content-row">
      <rappider-label [content]="'Project Name:'"
                      [typography]="{fontWeight:'bold'}"></rappider-label>
      <rappider-paragraph [content]="deleteProject?.name">
      </rappider-paragraph>
    </div>
    <div class="content-row">
      <rappider-label [content]="'Created By:'"
                      [typography]="{fontWeight:'bold'}"></rappider-label>
      <rappider-paragraph [content]="deleteProject?.createdBy">
      </rappider-paragraph>
    </div>
    <div class="content-row">
      <rappider-label [content]="'Created Date:'"
                      [typography]="{fontWeight:'bold'}"></rappider-label>
      <rappider-paragraph [content]="deleteProject?.createdDate | date:'longDate'">
      </rappider-paragraph>
    </div>
  </div>
  <div class="modal-warning-text">
    <rappider-alert [type]="deleteProjectConfig?.type"
                    [title]="deleteProjectConfig?.title"
                    [description]="deleteProjectConfig?.description"
                    [closeable]="deleteProjectConfig?.closeable"
                    [showIcon]="deleteProjectConfig?.showIcon"></rappider-alert>
  </div>

  <div class="modal-delete-text">
    <rappider-text [content]="deleteProjectConfirmationText.content"
                   [textMode]="deleteProjectConfirmationText.textMode">
    </rappider-text>
    <rappider-textbox [(ngModel)]="confirmationText"
                      [placeholder]="'Type project name to confirm'">
    </rappider-textbox>
  </div>
</rappider-modal>
