import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudViewFormItemType } from '@rappider/rappider-components/utils';

export const PROJECT_THEME_CREATE_FORM_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewFormItemType.TextArea,
  }
]
};


