import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, delay, map, mergeMap, retry, withLatestFrom } from 'rxjs/operators';
import * as ProjectDesignTaskActions from './project-design-task.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as ClientLocalStateActions from 'libs/shared/src/lib/states/client-local-state/client-local-state.actions';
import * as ProjectActions from 'libs/project/src/lib/states/projects-state/project.actions';

import { NotificationService, StoreKeys } from '@rappider/services';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';

import { ProjectControllerService, ProjectDesignTask } from '@rappider/rappider-sdk';

/* Named import */
import { ProjectDesignTaskControllerService as ApiService } from '@rappider/rappider-sdk';
import { selectProcessInfoById } from 'libs/shared/src/lib/states/process-info/process-info.selectors';
import { ProcessInfoIdentifier } from '@rappider/shared/definitions';
import { selectClientLocalStateLoaded, selectClientLocalStates } from 'libs/shared/src/lib/states/client-local-state/client-local-state.selectors';
import { selectProjectDesignTasks, selectProjectDesignTasksCompleted } from './project-design-task.selectors';
import { ProjectDesignService } from '../../services/project-design.service';
import { WizardStepStatus } from '../../configs/wizard-steps';
import { orderBy } from 'lodash';
import { ProjectDesignTaskIdentifier, ProjectDesignTaskStatus } from './project-design-task.interface';
import { SyncRemoteDataWithKey } from 'apps/rappider/src/app/state/app.actions';
import { selectActiveProject, selectActiveProjectId } from 'libs/project/src/lib/states/active-project-state/active-project.selectors';
import { ClientLocalStateKeys } from 'libs/shared/src/lib/states/client-local-state/client-local-state.interface';
import { EMPTY, of } from 'rxjs';

export const navigatePathAfterCreatingInstance = '';
export const navigatePathAfterUpdatingInstance = '';

@Injectable()
export class ProjectDesignTaskEffects {

  checkStepStatusWithTimerIntervalId: any;
  checkStepStatusWithTimerIntervalDuration = 5000;

  /* step index that saved on the last status check */
  lastStatusCheckStepIndex = 0;
  /* step status on the last status check  */
  lastStatusCheckStepStatus: WizardStepStatus | undefined;
  /* project id that saved on the last status check */
  lastStatusCheckProjectId = '';
  /* step timer counter that set on the last status check */
  statusCheckStepTimerCounter = 0;
  /* max number of status check to skip the step if teh conditions to move next is not   */
  maxStatusCheckToSkipStep = 4;

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<any>,
    private notificationService: NotificationService,
    private projectDesignService: ProjectDesignService,
    private projectControllerService: ProjectControllerService,
    private processInfoControllerService: ProjectControllerService
  ) { }


  setTriggerForNextSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.GetProjectDesignTasksSuccessful),
      withLatestFrom(
        this.store.select(selectActiveProjectId),
      ),
      delay(1000),
      mergeMap(([action, activeProjectId]) => {

        if (activeProjectId && !this.checkStepStatusWithTimerIntervalId) {
          clearInterval(this.checkStepStatusWithTimerIntervalId);
          this.checkStepStatusWithTimerIntervalId = setInterval(() => {
            this.store.dispatch(ProjectDesignTaskActions.CheckStepStatusWithTimer({ payload: { activeProjectId: activeProjectId } }));
          }, this.checkStepStatusWithTimerIntervalDuration);
        }
        return of({ type: 'NO_ACTION' });
      }))
  );


  checkStepStatusWithTimer$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectDesignTaskActions.CheckStepStatusWithTimer),
    withLatestFrom(
      this.store.select(selectActiveProject),
      this.store.select(selectClientLocalStateLoaded),
      this.store.select(selectClientLocalStates),
      this.store.select(selectProjectDesignTasks),
      this.store.select(selectProjectDesignTasksCompleted),
      this.store.select(selectProcessInfoById(ProcessInfoIdentifier.AIOrchestration)),
    ),
    mergeMap(([action, activeProject, clientLocalStatesLoaded, clientLocalStates, projectDesignTasks, projectDesignTasksCompleted, aiOrchestrationProcessInfo]) => {
      console.log('* [ProjectDesignTaskActions.CheckStepStatusWithTimer]: ', action, new Date().toISOString(), clientLocalStates, projectDesignTasks, aiOrchestrationProcessInfo);
      if (action.payload?.activeProjectId) {

        const { activeStepIndex, wizardStepConfig } = this.projectDesignService.calculateWizardStepConfigs(projectDesignTasks ?? []);

        if (activeStepIndex > 0 && activeStepIndex <= wizardStepConfig.length) {
          const activeStep = wizardStepConfig[activeStepIndex];

          // check the current step conditions and move to the next step if needed
          if (activeStep?.status === WizardStepStatus.InProgress || activeStep?.status === WizardStepStatus.Introduction) {
            if (
              this.lastStatusCheckStepIndex === activeStepIndex &&
              this.lastStatusCheckStepStatus === activeStep.status &&
              this.lastStatusCheckProjectId === action.payload.activeProjectId
            ) {
              this.statusCheckStepTimerCounter = this.statusCheckStepTimerCounter + 1;
              console.log('* [ProjectDesignTaskActions.CheckStepStatusWithTimer]: statusCheckStepTimerCounter: ', this.statusCheckStepTimerCounter);
            } else {
              this.statusCheckStepTimerCounter = 0;
            }

            if (this.statusCheckStepTimerCounter >= this.maxStatusCheckToSkipStep) {
              this.store.dispatch(ProjectDesignTaskActions.NextStep({ payload: { activeProjectId: action.payload.activeProjectId } }));
            }


            if (clientLocalStatesLoaded && activeStep.status === WizardStepStatus.InProgress) {
              /*  */
              switch (activeStep.projectDesignTaskIdentifier) {

                /* IMPROVE PROJECT DESCRIPTION */
                case ProjectDesignTaskIdentifier.ImproveProjectDescription: {
                  // if project description is not improved: improve it; otherwise move to the next step
                  const isProjectDescriptionImprovedObject = clientLocalStates?.find(cl =>
                    cl?.key === ClientLocalStateKeys.AiStatusProjectDescriptionImproved.toString());
                  const isProjectDescriptionImproved = isProjectDescriptionImprovedObject?.value ?? false;
                  if (isProjectDescriptionImproved) {
                    this.store.dispatch(ProjectDesignTaskActions.NextStep({
                      payload: { activeProjectId: action.payload.activeProjectId }
                    }));
                  } else {
                    if (!projectDesignTasksCompleted.includes(ProjectDesignTaskIdentifier.ImproveProjectDescription)) {
                      this.store.dispatch(ProjectDesignTaskActions.ImproveProjectDescription());
                    }
                  }
                  break;
                }
                /* UPDATE PROJECT INFORMATION; Check AI-STATUS-PROJECT-ORCHESTRATION-FOR-ENTITIES-TRIGGERED */
                case ProjectDesignTaskIdentifier.UpdateProjectInformation: {
                  // if project information is not updated: update it; otherwise move to the next step
                  const isAiStatusProjectOrchestrationForEntitiesTriggeredObject = clientLocalStates?.find(cl =>
                    cl?.key === ClientLocalStateKeys.AiStatusProjectOrchestrationForEntitiesTriggered.toString());
                  const isAiStatusProjectOrchestrationForEntitiesTriggered = isAiStatusProjectOrchestrationForEntitiesTriggeredObject?.value ?? false;
                  if (isAiStatusProjectOrchestrationForEntitiesTriggered) {
                    this.store.dispatch(ProjectDesignTaskActions.NextStep({
                      payload: { activeProjectId: action.payload.activeProjectId }
                    }));
                  } else {
                    if (!projectDesignTasksCompleted.includes(ProjectDesignTaskIdentifier.UpdateProjectInformation)) {
                      this.store.dispatch(ProjectDesignTaskActions.TriggerProjectOrchestration());
                    }
                  }
                  break;
                }

                /*  */
                default:
                  break;
              }
            }

            this.lastStatusCheckProjectId = action.payload?.activeProjectId;
            this.lastStatusCheckStepIndex = activeStepIndex;
            this.lastStatusCheckStepStatus = activeStep.status;

          }
        }
      }
      return of({ type: 'NO_ACTION' });
    })
  ), { dispatch: false });


  nextStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.NextStep),
      withLatestFrom(
        this.store.select(selectClientLocalStates),
        this.store.select(selectProjectDesignTasks),
        this.store.select(selectProcessInfoById(ProcessInfoIdentifier.AIOrchestration)),
      ),
      mergeMap(([action, clientLocalStates, projectDesignTasks, aiOrchestrationProcessInfo]) => {
        const actions: Action[] = [];
        const { activeStepIndex, wizardStepConfig } = this.projectDesignService.calculateWizardStepConfigs(projectDesignTasks ?? []);

        if (activeStepIndex <= wizardStepConfig.length &&
          wizardStepConfig[wizardStepConfig.length - 1].status !== WizardStepStatus.Completed) {

          // if the step is Welcome / 0 ; move to the next step
          if (activeStepIndex === 0) {
            const clientLocalStatePayload = {
              payload: {
                clientLocalState: {
                  key: ClientLocalStateKeys.WelcomeStepDisplayed,
                  value: true
                }
              }
            };

            // save welcomeStepDisplayed as true into client local state
            actions.push(ClientLocalStateActions.CreateClientLocalState(clientLocalStatePayload));

            // find the first projectDesignTask and set it as in progress
            const firstProjectDesignTask = orderBy(projectDesignTasks, ['index'], ['asc'])[0];
            if (firstProjectDesignTask) {
              const projectDesignTaskUpdatePayload = {
                payload: {
                  projectDesignTaskId: firstProjectDesignTask.id,
                  projectDesignTask: { status: ProjectDesignTaskStatus.Introduction } as Partial<ProjectDesignTask>
                }
              };
              actions.push(ProjectDesignTaskActions.UpdateProjectDesignTask(projectDesignTaskUpdatePayload));
            }
          } else {
            // if the step is not Welcome / 0 ; move to the next step
            const activeProjectDesignTask = orderBy(projectDesignTasks, ['index'], ['asc'])?.find(task =>
              task.status !== ProjectDesignTaskStatus.Completed);
            if (activeProjectDesignTask) {
              const nextStatusForActiveProjectDesignTask = (
                activeProjectDesignTask.status.toString() === ProjectDesignTaskStatus.InProgress.toString()) ?
                ProjectDesignTaskStatus.Completed : ProjectDesignTaskStatus.InProgress;
              const projectDesignTaskUpdatePayload = {
                payload: {
                  projectDesignTaskId: activeProjectDesignTask.id,
                  projectDesignTask: { status: nextStatusForActiveProjectDesignTask } as Partial<ProjectDesignTask>
                }
              };
              actions.push(ProjectDesignTaskActions.UpdateProjectDesignTask(projectDesignTaskUpdatePayload));

              // // if the step is in progress, dispatch next step with timer
              // if (
              //   activeStepIndex <= wizardStepConfig.length &&
              //   wizardStepConfig[activeStepIndex].status === WizardStepStatus.InProgress
              // ) {
              //   const delayMs = wizardStepConfig[activeStepIndex].autoSkipDurationOnPlanning || 10000;
              //   actions.push(ProjectDesignTaskActions.NextStepWithTimer({
              //     payload: {
              //       activeProjectId: action.payload.activeProjectId, delay: delayMs
              //     }
              //   }));
              // }
            }
          }
        }
        actions.push(ProjectDesignTaskActions.GetProjectDesignTasks());
        return actions;
      })
    )
  );

  getProjectDesignTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.GetProjectDesignTasks),
      withLatestFrom(
        this.store.select(selectActiveProjectId)
      ),
      delay(1000),
      mergeMap(([action, activeProjectId]) => {
        /* fail if there is no active project */
        if (!activeProjectId) {
          return [
            ProjectDesignTaskActions.GetProjectDesignTasksFailure()
          ];
        } else {
          return [new SyncRemoteDataWithKey({ projectId: activeProjectId, storeKey: StoreKeys.ProjectDesignTask })];
        }
      })
    )
  );

  createProjectDesignTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.CreateProjectDesignTask),
      withLatestFrom(
        this.store.select(selectActiveProjectId)
      ),
      mergeMap(([action, activeProjectId]) => {
        const params = {
          body: {
            ...action.payload.projectDesignTask,
            projectId: activeProjectId
          }
        };

        return this.apiService.create(params).pipe(
          map((projectDesignTask) => {
            console.log('projectDesignTask success: ', projectDesignTask);
            // this.notificationService.createNotification(
            //   'success',
            //   params.body?.identifier || 'Project Design Task',
            //   'SHARED.SUCCESSFULLY_CREATED'
            // );
            return ProjectDesignTaskActions.CreateProjectDesignTaskSuccessful({ payload: { projectDesignTask: projectDesignTask } });
          }),
          catchError((error) => {
            console.log('error: ', error);
            // this.notificationService.createNotification(
            //   'error',
            //   params.body?.identifier || 'Project Design Task',
            //   'SHARED.COULDNT_CREATED'
            // );
            return [
              ProjectDesignTaskActions.CreateProjectDesignTaskFailure(),
              ProjectDesignTaskActions.ErrorAction({ payload: { error: error, key: 'CreateProjectDesignTask', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  createProjectDesignTaskSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.CreateProjectDesignTaskSuccessful),
      map(action =>
        new Navigate({ url: navigatePathAfterCreatingInstance })
      )
    )
  );

  updateProjectDesignTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.UpdateProjectDesignTask),
      mergeMap(action => {
        const params = {
          id: action.payload.projectDesignTaskId,
          body: action.payload.projectDesignTask
        };

        return this.apiService.updateById(params).pipe(
          map((projectDesignTask) => {
            console.log('projectDesignTask success: ', projectDesignTask);
            // this.notificationService.createNotification(
            //   'success',
            //   params.body.name,
            //   'SHARED.SUCCESSFULLY_UPDATED'
            // );
            return ProjectDesignTaskActions.UpdateProjectDesignTaskSuccessful({ payload: { projectDesignTaskId: action.payload.projectDesignTaskId, projectDesignTask: projectDesignTask } });
          }
          ),
          catchError((error) => {
            console.log('error: ', error);
            // this.notificationService.createNotification(
            //   'error',
            //   params.body.name,
            //   'SHARED.COULDNT_UPDATED'
            // );
            return [
              ProjectDesignTaskActions.UpdateProjectDesignTaskFailure(),
              ProjectDesignTaskActions.ErrorAction({ payload: { error: error, key: 'UpdateProjectDesignTask', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  updateProjectDesignTaskSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.UpdateProjectDesignTaskSuccessful),
      mergeMap(action => [
        new Navigate({ url: navigatePathAfterCreatingInstance }),
        ProjectDesignTaskActions.GetProjectDesignTasks()
      ])
    )
  );

  /* Old actions can not listen on new reducers. So we use this effect temp. */
  resetStateToInitial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.Logout>(AuthenticationActions.ActionTypes.Logout),
      mergeMap(_ => [
        ProjectDesignTaskActions.ResetStateToInitial()
      ])
    )
  );

  resetStateToInitialChangeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.UpdateAuthenticationTokenWithProjectIdSuccessful>(
        AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful),
      mergeMap(_ => [
        ProjectDesignTaskActions.ResetStateToInitial()
      ])
    )
  );

  improveProjectDescription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.ImproveProjectDescription),
      withLatestFrom(
        this.store.select(selectActiveProject),
      ),
      mergeMap(([action, activeProject]) => {
        if (activeProject?.description) {
          const params = {
            body: {
              message: activeProject?.description
            }
          };

          return this.projectControllerService.generateByAI(params).pipe(
            map((response) => {
              if (response.description) {
                this.notificationService.createNotification(
                  'success',
                  'Project Description Improved with AI',
                  'Project Description Improved with AI'
                );
                return ProjectDesignTaskActions.ImproveProjectDescriptionSuccessful(
                  {
                    payload: {
                      projectId: activeProject.id,
                      improvedDescription: response.description
                    }
                  }
                );
              }

              return ProjectDesignTaskActions.ErrorAction({
                payload: { error: 'Project Description Could Not Be Improved with AI', key: 'ImproveProjectDescription', timeStamp: Date.now() }
              });
            }),
            catchError((error) => {
              this.notificationService.createNotification(
                'error',
                'Error with AI',
                'Project Description Could Not Be Improved with AI'
              );
              return [
                ProjectDesignTaskActions.ErrorAction({ payload: { error: error, key: 'ImproveProjectDescription', timeStamp: Date.now() } })
              ];
            })
          );
        }
        return of(ProjectDesignTaskActions.ErrorAction(
          { payload: { error: 'No active project description', key: 'ImproveProjectDescription', timeStamp: Date.now() } }
        ));
      })
    )
  );


  improveProjectDescriptionSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.ImproveProjectDescriptionSuccessful),
      mergeMap(action => [
        new ProjectActions.UpdateProject({
          project: {
            description: action.payload.improvedDescription
          },
          projectId: action.payload.projectId,
          redirectToProjectDetail: false,
          notificationMessageOnSuccess: 'Project Description Improved with AI',
          notificationMessageOnFailure: 'Project Description Could Not Be Improved with AI'
        }),
        ClientLocalStateActions.CreateClientLocalState({
          payload: {
            clientLocalState: {
              key: ClientLocalStateKeys.AiStatusProjectDescriptionImproved,
              value: true
            }
          }
        })
      ])
    )
  );

  triggerProjectOrchestration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.TriggerProjectOrchestration),
      withLatestFrom(
        this.store.select(selectActiveProject),
      ),
      mergeMap(([action, activeProject]) => {
        if (activeProject?.id && activeProject?.isCreatedByAI) {
          const aiOrchestrationProcessInfoFilter = {
            where: {
              identifier: ProcessInfoIdentifier.AIOrchestration,
              projectId: activeProject.id
            }
          };

          return this.processInfoControllerService.find({ filter: aiOrchestrationProcessInfoFilter }).pipe(
            mergeMap(aiOrchestrationProcessInfos => {
              const aiOrchestrationProcessInfo = aiOrchestrationProcessInfos?.find(pi =>
                pi.identifier === ProcessInfoIdentifier.AIOrchestration);

              if (!aiOrchestrationProcessInfo?.status) {
                return this.projectControllerService.orchestration(activeProject?.id).pipe(
                  retry(2),
                  map(response => {
                    console.log('*** AI ** Entity Generation Started by orchestration:', response);
                    return ProjectDesignTaskActions.TriggerProjectOrchestrationSuccessful({ payload: { projectId: activeProject.id } });
                  }),
                  catchError(error => {
                    console.error('AI Entity Generation failed after all retry attempts:', error);
                    return of(ProjectDesignTaskActions.ErrorAction({
                      payload: { error: error.message, key: 'TriggerProjectOrchestration', timeStamp: Date.now() }
                    }));
                  })
                );
              }
              return EMPTY;
            }),
            catchError(error => {
              console.error('Process info fetch failed:', error);
              return of(ProjectDesignTaskActions.ErrorAction({
                payload: { error: 'No active project', key: 'TriggerProjectOrchestration', timeStamp: Date.now() }
              }));
            })
          );
        }
        return EMPTY;
      })
    )
  );

  triggerProjectOrchestrationSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectDesignTaskActions.TriggerProjectOrchestrationSuccessful),
      mergeMap(action => [
        ClientLocalStateActions.CreateClientLocalState({
          payload: {
            clientLocalState: {
              key: ClientLocalStateKeys.AiStatusProjectOrchestrationForEntitiesTriggered,
              value: true
            }
          }
        })
      ])
    )
  );


}
