<div class="project-model-field-container">
  <section class="project-model-name">
    <!-- <div *ngIf="mode !== 'read'">
      <rappider-button [icon]="backButtonConfig.icon!"
                       [size]="backButtonConfig.size!"
                       (click)="setModeToRead()"></rappider-button>
    </div> -->

    <div *ngIf="projectModel"
         class="name-section">
      <rappider-heading [content]="mode === ComponentMode.Create ? projectModel.name + ' / New field' : projectModel.name"
                        [type]="projectModelHeadingType"></rappider-heading>
      <div>
        <rappider-button *ngIf="projectModel.isUpdatable"
                         class="edit-icon"
                         nz-tooltip
                         [nzTooltipTitle]="'Edit Project Model'"
                         [size]="editButtonConfig.size"
                         [icon]="editButtonConfig.icon"
                         (click)="onClickProjectModelUpdate()"></rappider-button>
      </div>
    </div>

    <rappider-heading *ngIf="!projectModel"
                      [content]="'Please select a model from left side' | translate"
                      [type]="projectModelHeadingType"></rappider-heading>
  </section>

  <div *ngIf="mode === ComponentMode.Read"
       [@inOutAnimation]
       class="field-wrapper">
    <nz-skeleton [nzLoading]="projectModelFieldsLoading"
                 [nzActive]="true">
      <rappider-list-grid [config]="NEW_PROJECT_DATA_FIELD_LIST_CONFIG"
                          [data]="projectModel?.fields"
                          (orderChange)="onOrderChange($event)"
                          (columnActionClick)="onItemClick($event)"></rappider-list-grid>
    </nz-skeleton>

    <div *ngIf="projectModel"
         class="new-field-button">
      <rappider-button text="New Field"
                       [type]="newButtonType"
                       (click)="onNewFieldClick()"></rappider-button>
    </div>
  </div>

  <div *ngIf="mode === ComponentMode.Create"
       [@inOutAnimation]
       class="new-field-wrapper">
    <rappider-new-project-model-field-form [loading]="loading"
                                           [submitButtonLoading]="submitButtonLoading"
                                           (newProjectModelFieldClick)="onNewProjectModelFieldClick($event)"></rappider-new-project-model-field-form>
  </div>

  <div *ngIf="mode === ComponentMode.Update"
       [@inOutAnimation]
       class="new-field-wrapper">
    <rappider-update-project-model-field-form [projectModelField]="activeProjectModelField"
                                              [submitButtonLoading]="submitButtonLoading"
                                              (updateProjectModelField)="onUpdateProjectModelField($event)"></rappider-update-project-model-field-form>
  </div>
</div>
