import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { orderBy } from 'lodash';
import {
  LoadModule,
  CreateProjectRole,
  UpdateProjectRole,
  DeleteProjectRole,
} from '../../states/project-roles-state/project-role.actions';
import { RappiderEditFormComponent } from '@rappider/rappider-components';
import {
  ProjectRoleInterface,
  ProjectInterface,
} from '@rappider/api-sdk';
import {
  PROJECT_ROLES_CONFIG,
  CREATE_NEW_ROLE_BUTTON_CONFIG,
  EDIT_ROLE_BUTTON_CONFIG,
  CREATE_OR_EDIT_PROJECT_ROLES_CONFIG,
  ALLOWED_USERS_FOR_INVITE,
} from '@rappider/shared/configs';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ProjectService } from '@rappider/services';
import { BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Person, ProjectRoleWithRelations } from '@rappider/rappider-sdk';
@Component({
  selector: 'rappider-project-roles',
  templateUrl: './project-roles.component.html',
  styleUrls: ['./project-roles.component.scss'],
})
export class ProjectRolesComponent implements OnInit, OnDestroy {
  @ViewChild('createNewRoleForm') createNewRoleFormComponent: RappiderEditFormComponent;
  @ViewChild('editRoleForm') editRoleFormComponent: RappiderEditFormComponent;

  createOrEditProjectRolesConfig = CREATE_OR_EDIT_PROJECT_ROLES_CONFIG;
  projectRolesConfig = PROJECT_ROLES_CONFIG;
  subscriptions: Subscription[];
  mainTitle: HeadingComponentConfig;
  title: string | string[] | BreadcrumbOption[];
  roles: ProjectRoleInterface[];
  activeProjectId: string;
  activeProject: ProjectInterface;
  activePerson: Person;
  createNewRoleModalVisible = false;
  editRoleModalVisible = false;
  editedRole: ProjectRoleInterface;
  projectUsers: Person[];
  loading: boolean;
  displayToolbar = false;
  displayToolbarBackButton = false;
  constructor(
    private store: Store<any>,
    private modal: NzModalService,
    private translateService: TranslateService,
    private projectService: ProjectService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.subscribeToData();
  }

  /**
   *
   *
   * @memberof ProjectListComponent
   */
  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   *
   *
   * @memberof ProjectListComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectRoles(),
      this.subscribeToProjectRolesLoadingState()
    ];
  }

  getProjectRoles() {
    this.store.dispatch(new LoadModule());
  }

  getAuthorities() {
    this.store
      .select((state) => state.personAuthority.data)
      .subscribe((authorities) => {
        this.setListGridConfig(authorities);
      });
  }

  subscribeToProjectRolesLoadingState() {
    return this.store.select((state) => state.projectRole.loading).subscribe((isLoading: boolean) => {
      this.loading = isLoading;
    });
  }

  /**
   * Subscribe active project
   *
   * @returns
   * @memberof ProjectEnvironmentVariablesComponent
   */
  subscribeToActiveProject() {
    return this.store
      .select((state) => state.activeProject.data)
      .subscribe((activeProject) => {
        if (activeProject) {
          this.activeProject = activeProject;
          this.mainTitle = {
            content: 'PROJECT_MODULE.PROJECT_ROLES_COMPONENT.PROJECT_ROLES',
            type: defaultToolbarTitleHeadingSize
          };
          this.title = [
            {
              label: activeProject.name,
              redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
            },
            {
              label: 'PROJECT_MODULE.PROJECT_ROLES_COMPONENT.PROJECT_ROLES'
            }
          ];
          this.activeProjectId = activeProject.id;
          this.getProjectMembersWithRoles();
          this.getProjectRoles();
          this.getAuthorities();
        }
      });
  }

  /**
   * Get project members by project id
   *
   * @param {string} projectId
   * @memberof ProjectRolesComponent
   */
  getProjectMembersWithRoles() {
    this.projectService.getProjectMembersWithRoles().subscribe((members) => {
      this.projectUsers = members ?? [];
    });
  }

  /**
   * Get project roles by project id
   *
   * @param {string} projectId
   * @memberof ProjectRolesComponent
   */
  subscribeToProjectRoles() {
    return this.store
      .select((state) => state.projectRole)
      .subscribe((roleState) => {
        if (roleState?.data) {
          this.roles = orderBy(roleState?.data, 'title', 'asc');
        } else {
          this.roles = [];
        }
        this.loading = roleState?.loading;
      });
  }

  /**
   *  TODO: conditions will change
   * Set list grid config for manager, etc
   *
   * @param {ProjectRoleWithRelations[]} projectRoles
   * @memberof ProjectRolesComponent
   */
  setListGridConfig(personAuthoritires: any) {
    const allowedProjectRoles = personAuthoritires?.some((personAuthData) =>
      ALLOWED_USERS_FOR_INVITE.includes(personAuthData?.role?.name)
    );
    if (allowedProjectRoles) {
      this.projectRolesConfig = {
        ...this.projectRolesConfig,
        listActions: [
          ...this.projectRolesConfig.listActions,
          CREATE_NEW_ROLE_BUTTON_CONFIG,
        ],
        itemActions: [
          ...this.projectRolesConfig.itemActions,
          ...EDIT_ROLE_BUTTON_CONFIG,
        ],
      };
    }
  }

  /**
   * When the edit or delete button is pressed, we learn which action will take place.
   *
   * @param {*} data
   * @memberof ProjectRolesComponent
   */
  editOrDeleteButtonClick(data: any) {
    if (data.action.name === 'EDIT_ROLE') {
      this.onEditRoleButtonClick(data);
    } else if (data.action.name === 'DELETE_ROLE') {
      this.showDeleteConfirm(data.data);
    }
  }

  /** Start Create new role Area */
  /**
   * Open Create new role modal
   *
   * @param {*} data
   * @memberof ProjectRolesComponent
   */
  onCreateNewRoleButtonClick(data: any) {
    this.createNewRoleModalVisible = true;
  }

  /**
   * Reset new role modal and cloe new role modal
   *
   * @memberof ProjectRolesComponent
   */
  onCreateNewCancelButtonClick() {
    this.createNewRoleModalVisible = false;
    this.createNewRoleFormComponent.resetForm();
  }

  /**
   * Submit form
   *
   * @memberof ProjectRolesComponent
   */
  createNewRoleSubmit() {
    this.createNewRoleFormComponent.submit();
  }

  /**
   *  Create new role
   *
   * @param {*} data
   * @memberof ProjectRolesComponent
   */
  onSaveNewRoleButtonClick(data: any) {
    const isDuplicate = this.roles.some((role) => role.title === data.title);
    if (isDuplicate) {
      this.notificationService.createNotification(
        'error',
        'Error',
        'PROJECT_MODULE.PROJECT_ROLES_COMPONENT.PROJECT_ROLE_TITLE_ERROR'
      );
      return;
    }
    this.store.dispatch(new CreateProjectRole({ projectRole: data }));
    this.onCreateNewCancelButtonClick();
  }
  /** End Create new role Area */

  /** Start edit role Area */
  /**
   * Open Edit role modal
   *
   * @param {*} role
   * @memberof ProjectRolesComponent
   */
  onEditRoleButtonClick(role: any) {
    this.editRoleModalVisible = true;
    this.editedRole = role.data;
  }

  /**
   * Submit form
   *
   * @memberof ProjectRolesComponent
   */
  onEditRoleSubmit() {
    this.editRoleFormComponent.submit();
  }

  /**
   * Edit role
   *
   * @param {*} data
   * @memberof ProjectRolesComponent
   */
  onEditRoleSaveButtonClick(data: any) {
    const isDuplicate = this.roles.some(
      (role) => role.title === data.title && role.id !== this.editedRole.id
    );
    if (isDuplicate) {
      this.notificationService.createNotification(
        'error',
        'Error',
        'PROJECT_MODULE.PROJECT_ROLES_COMPONENT.PROJECT_ROLE_TITLE_ERROR'
      );
      return;
    }
    if (this.activeProjectId) {
      this.store.dispatch(
        new UpdateProjectRole({
          projectRole: data,
          projectRoleId: this.editedRole.id,
        })
      );
      this.onEditRoleCancelButtonClick();
    }
  }

  /**
   * Cloe edit role modal
   *
   * @memberof ProjectRolesComponent
   */
  onEditRoleCancelButtonClick() {
    this.editRoleModalVisible = false;
    this.editRoleFormComponent.resetForm();
  }

  /**
   * delete project role
   *
   * @param {*} data
   * @memberof ProjectRolesComponent
   */
  deleteProjectRole(data: any) {
    this.store.dispatch(new DeleteProjectRole({ projectRoleId: data.id }));
  }

  /**
   * Delete role modal
   *
   * @param {*} data
   * @memberof ProjectRolesComponent
   */
  showDeleteConfirm(deletingRole: ProjectRoleInterface): void {
    const roleHasUser = this.projectUsers.some(user => user.roles?.some(role => role.title.includes(deletingRole.title)));
    this.modal.confirm({
      nzTitle: roleHasUser
        ? this.translateService.instant('PROJECT_MODULE.PROJECT_ROLES_COMPONENT.ROLE_HAS_EXISTING_USERS')
        : this.translateService.instant('PROJECT_MODULE.PROJECT_ROLES_COMPONENT.ARE_YOU_SURE_DELETE_THIS_ROLE'),
      nzContent: roleHasUser
        ? this.translateService.instant('PROJECT_MODULE.PROJECT_ROLES_COMPONENT.THIS_ROLE_HAS_EXISTING_USERS_DESCRIPTON')
        : '',
      nzOkText: this.translateService.instant('SHARED.DELETE'),
      nzOkDanger: true,
      nzOkDisabled: roleHasUser,
      nzOnOk: () => this.deleteProjectRole(deletingRole),
      nzCancelText: this.translateService.instant('SHARED.CANCEL'),
    });
  }
}
