<section class="showroom-title">
  <div>
    <h2>
      Design System / UI Components
    </h2>
    <p>
      This is a curated collection of reusable UI components that form the foundation of your application's design
      system. These components ensure a consistent look and feel across your entire application, following best
      practices for usability and accessibility.
    </p>
    <p>
      You can also directly use these components within your application pages, as they have already been integrated
      into your UI code. By leveraging these pre-built components, you can accelerate development, maintain design
      consistency, and easily update your application's visual identity in one place.
    </p>
  </div>
</section>
<div class="showroom-section"
     id="componentShowroom">
  <ng-container>
    <ng-container *ngFor="let showroomComponent of showroomComponents; let index = index">
      <div class="section-title"
           [id]="showroomComponent?.id">
        <h3 class="root-font-color">{{showroomComponent?.title}}</h3>
        <p class="root-font-color">{{showroomComponent?.description}}</p>
        <br>
      </div>
      <section class="section">
        <ng-template #showcaseContainer></ng-template>
      </section>
    </ng-container>
  </ng-container>
  <hr>

</div>
