<div class="theme-container">
  <rappider-title-toolbar *ngIf="titleToolbarConfig"
                          [mainTitle]="titleToolbarConfig.mainTitle"
                          [options]="titleToolbarConfig.options">
  </rappider-title-toolbar>
  <rappider-spin [(spinning)]="loading">

    <div class="row"
         *ngIf="leftTitleToolbarMainTitle ">
      <div [class]="previewVisibility ? 'col-6': 'col-12'">
        <section class="theme-settings-title-bar">
          <div class="theme-settings-title"
               [innerHTML]="leftTitleToolbarMainTitle | translate"></div>
          <div class="theme-form-buttons">

            <rappider-button [icon]="setActiveThemeButtonConfig.icon"
                             [tooltipText]="setActiveThemeButtonConfig.tooltipText"
                             (click)="onSetActiveThemeButtonClick()"></rappider-button>

            <rappider-dropdown-menu [icon]="THEME_SETTING_BUTTONS_CONFIG.icon"
                                    [items]="THEME_SETTING_BUTTONS_CONFIG.items"
                                    [dropdownType]="THEME_SETTING_BUTTONS_CONFIG.dropdownType"
                                    [placement]="THEME_SETTING_BUTTONS_CONFIG.placement"
                                    (menuItemClick)="onMenuItemClick($event)"></rappider-dropdown-menu>
          </div>
        </section>
      </div>
      <div *ngIf="previewVisibility"
           class="col-6 theme-preview-title-bar-container">
        <section class="theme-preview-title-bar">
          <div class="theme-preview-title"
               [innerHTML]="'Theme Preview' | translate"></div>
          <rappider-dropdown-menu *ngIf="false"
                                  [items]="componentCategoryDropDownSettings.items"
                                  [label]="selectedComponentCategoryTitle | translate"
                                  [triggerType]="'click'"
                                  [placement]="componentCategoryDropDownSettings.placement"
                                  [labelMode]="componentCategoryDropDownSettings.labelMode"
                                  (menuItemClick)="onComponentCategoryDropDownClick($event)"></rappider-dropdown-menu>
        </section>
      </div>
    </div>

    <div class="row theme-content">
      <!-- THEME FORM -->
      <div class="theme-options"
           [ngClass]="previewVisibility ? 'col-6' : 'col-12'">
        <section #themeConfig class="theme-options-form">
          <rappider-crud-view-edit-form [config]="THEME_FORM_CONFIG"
                                        [data]="formData"
                                        [focusFirstInput]="false"
                                        (fieldValueChange)="onFieldChange($event)"
                                        (fieldLinkStateChange)="onFieldLinkStateChange($event)"
                                        (formValueChange)="saveUpdatedThemeData($event)">
          </rappider-crud-view-edit-form>
        </section>
      </div>
      <!-- THEME FORM -->
      <!-- SHOWCASE COMPONENTS -->
      <div *ngIf="previewVisibility"
           class="col-6 theme-preview"
           id="theme-preview">
        <section *ngIf="!loading"
                 class="showcase-section">

          <ng-container>
            <ng-container *ngFor="let component of showcaseComponents; let index = index">
              <div class="section-title">{{component?.componentDefinition?.title}}</div>
              <section class="section">
                <ng-template #showcaseContainer></ng-template>
              </section>
            </ng-container>
          </ng-container>
          <hr>


          <ng-container *ngIf="selectedComponentCategoryKeyForThemePreview === 'example-template'">
            <rappider-example-template-one></rappider-example-template-one>
          </ng-container>
          <ng-container *ngIf="selectedComponentCategoryKeyForThemePreview === 'example-template-two'">
            <rappider-example-template-two></rappider-example-template-two>
          </ng-container>
        </section>
      </div>
      <!-- SHOWCASE COMPONENTS -->
    </div>

  </rappider-spin>
</div>
