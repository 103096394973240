export enum IntroductionNextButton {
  LetsGo = 'Lets Go!',
  ProjectDescription = 'Improve Project Description',
  ProjectInformation = 'Review Project Information',
  ProjectMembers = 'Let\'s Invite Others',
  ScopeOfWork = 'Generate Scope of Work',
  DataModelsAndDatabase = 'Generate Data Models and Database',
  APIEndpoints = 'Generate API Endpoints',
  DesignSystem = 'Generate My Design System',
  Theme = 'Generate Client Application Theme',
  UIPageLayouts = 'Generate Client Application Layouts',
  UIPages = 'Generate Client Application Pages',
  UIBusinessProcesses = 'Generate Client Application (UI) Logic',
  MessageTemplates = 'Generate Message & Document Templates',
  ServerSideBusinessProcesses = 'Generate Workflows & Micro Services',
  SettingsVariablesAndEnvironments = 'Generate Environments, Settings and Env. Variables',
  GenerateProjectCode = 'Deliver Project Code',
  Deploy = 'Deploy'
}
