<nz-input-group class="mb-2"
                [nzSuffix]="suffixIcon">
  <input type="text"
         nz-input
         placeholder="Search"
         [(ngModel)]="searchValue"
         (ngModelChange)="searchValueChange()" />
</nz-input-group>
<ng-template #suffixIcon>
  <span nz-icon
        nzType="search"></span>
</ng-template>
<div class="field-tree-selector-wrapper">
  <i *ngIf="!isModuleLoaded">Loading...</i>
  <nz-tree #nzTreeComponent
           *ngIf="isModuleLoaded"
           nzMultiple
           nzAsyncData
           [nzData]="searchValue ? searchResult: tree"
           [nzSearchValue]="searchValue"
           [nzSelectedKeys]="selectedNodes"
           (nzExpandChange)="onNodeExpandChange($event)"
           (nzClick)="onSelectedKeysChange()"></nz-tree>
</div>
