<div>
  <section class="container-title">
    <ng-container *ngIf="!containerTitleEditMode">
      <rappider-heading type="h5"
                        [content]="pageContainerNode?.title">
      </rappider-heading>

      <div class="container-buttons-group">
        <rappider-button [icon]="containerSettingsEditTitleButtonConfig.icon"
                         [tooltipText]="containerSettingsEditTitleButtonConfig.tooltipText"
                         (click)="onEditContainerTitleButtonClick()">
        </rappider-button>

        <rappider-dropdown-menu [icon]="containerSettingsDropdownMenuConfig.icon"
                                [items]="containerSettingsDropdownMenuConfig.items"
                                (menuItemClick)="onContainerSettingsDropdownMenuItemClick($event)">
        </rappider-dropdown-menu>
      </div>
    </ng-container>
    <ng-container *ngIf="containerTitleEditMode">
      <nz-input-group [nzSuffix]="editComponentTitleButtonsTemplate">
        <input nz-input
               [(ngModel)]="editedContainerTitle"
               (keydown.escape)="onCancelEditContainerTitle()"
               (keydown.enter)="onContainerTitleSave()" />
      </nz-input-group>
      <ng-template #editComponentTitleButtonsTemplate>
        <i class="fa-regular fa-xmark container-title-cancel-button"
           (click)="onCancelEditContainerTitle()"></i>
        <i class="fa-regular fa-check container-title-save-button"
           (click)="onContainerTitleSave()"></i>
      </ng-template>
    </ng-container>
  </section>



  <rappider-container-layout-settings [ngModel]="pageContainerNode"
                                      [pageVariables]="pageVariables"
                                      [allPanelsActive]="allPanelsActive"
                                      [visiblePanelKeys]="visiblePanelKeys"
                                      (ngModelChange)="onValueChange($event)">
  </rappider-container-layout-settings>


  <nz-collapse class="css-classes-input-collapse-wrapper">
    <nz-collapse-panel [nzHeader]="'Css Classes' | translate"
                       [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.CssClasses)">
      <rappider-crud-view-edit-form [config]="containerCssSettingsFormConfig"
                                    [data]="pageContainerNode"
                                    (formValueChange)="onContainerCssSettingsChange($event)">
      </rappider-crud-view-edit-form>
    </nz-collapse-panel>
  </nz-collapse>
  <nz-collapse class="css-classes-input-collapse-wrapper">
    <nz-collapse-panel [nzHeader]="'Container Comments' | translate"
                       [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.ContainerComments)">
      <div class="comments">
        <rappider-comment-wrapper #commentWrapperComponentRef
                                  [comments]="commentsFilteredByActiveTreeItemId"
                                  [activePerson]="activePerson"
                                  [peopleData]="projectPeopleData"
                                  [isLoading]="commentsLoading"
                                  [scrollingCommentId]="activeCommentId"
                                  [isDisplayingAllComments]="true"
                                  (createComment)="onCreateComment($event)"
                                  (editComment)="onEditComment($event)"
                                  (deleteComment)="onDeleteComment($event)"></rappider-comment-wrapper>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</div>
