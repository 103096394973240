/* eslint-disable @typescript-eslint/naming-convention */
import { MenuActionBehavior, Menu, MenuMode, MenuConfig } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';
import { IconType } from '@rappider/rappider-components/utils';
import { FullPaths, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';

export const PROJECT_OWNER_HOME_PAGE_MENU_ITEMS: Menu = {
  label: 'PROJECT_MODULE.PROJECT_USER_INVITATION_COMPONENT.USER_INVITATION_PAGE',
  actionBehavior: MenuActionBehavior.Router,
  redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_USER_INVITATIONS}`
};

export const PAGES_MENU: MenuConfig = {
  items: [
    {
      label: 'Project Settings',
      key: 'active-project',
      icon: {
        name: 'code',
        type: IconType.NgZorro
      },
      isExpanded: true,
      children: [
        {
          label: 'PROJECT_MODULE.PROJECT_SETTINGS_COMPONENT.PROJECT_SETTINGS',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_SETTINGS_PATH}`
        },
        {
          label: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.PROJECT_ENVIRONMENT_VARIABLES',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_ENVIRONMENT_VARIABLES}`
        },
        {
          label: 'PROJECT_MODULE.PROJECT_MESSAGE_TEMPLATE_LIST_COMPONENT.MESSAGE_TEMPLATE_LIST',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MESSAGE_TEMPLATE_LIST}`
        },
        {
          label: 'PROJECT_MODULE.PROJECT_SCRIPT_LIST_COMPONENT.SCRIPT_LIST',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_SCRIPT_LIST}`
        },
        PROJECT_OWNER_HOME_PAGE_MENU_ITEMS
      ]
    },
    {
      label: 'SHARED.CONTENT',
      key: 'content',
      icon: {
        name: 'layout',
        type: IconType.NgZorro
      },
      isExpanded: true,
      children: [
        {
          label: 'PAGES_MODULE.PAGE_LIST_COMPONENT.PAGE_LIST',
          key: 'pages',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PAGES.PAGE_LIST_PATH}`
        },
        {
          label: 'PAGES_MODULE.PAGE_LIST_COMPONENT.ADD_PAGE',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PAGES.PAGE_CREATE_PATH}`
        },
        {
          label: 'MODULE_MODULE.MODULE_LIST',
          key: 'modules',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.MODULE.MODULE_LIST_PATH}`
        },
        {
          label: 'MODULE_MODULE.ADD_MODULE',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.MODULE.MODULE_CREATE_PATH}`
        },
        {
          label: 'PAGES_MODULE.PAGE_LAYOUT_LIST_COMPONENT.PAGE_LAYOUT_LIST',
          actionBehavior: MenuActionBehavior.Router,
          key: 'layouts',
          redirectUrl: `${PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_LIST_PATH}`
        },
        {
          label: 'PAGES_MODULE.PAGE_LAYOUT_LIST_COMPONENT.ADD_PAGE_LAYOUT',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PAGES.PAGE_LAYOUT_CREATE_PATH}`
        }
      ]
    },
    {
      label: 'SHARED.PROJECTS',
      key: 'projects',
      icon: {
        name: 'book',
        type: IconType.NgZorro
      },
      isExpanded: true,
      children: [
        {
          label: 'PROJECT_MODULE.PROJECT_VERSIONS_COMPONENT.PROJECT_VERSIONS',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_LIST}`
        },
        {
          label: 'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.PROJECT_MODEL_LIST',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_LIST
        },
        {
          label: 'PROJECT_MODULE.UI_DATA_STORE_LIST_COMPONENT.UI_DATA_STORE_LIST',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_LIST
        },
        {
          label: 'PROJECT_MODULE.DATA_SCHEMA_LIST_COMPONENT.DATA_SCHEMA_LIST',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.PROJECTS.DATA_SCHEMA_LIST
        },
        {
          label: 'PROJECT_MODULE.PROJECT_PACKAGE_LIST_COMPONENT.PROJECT_PACKAGE_LIST',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_PACKAGE_LIST
        },
        {
          label: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.WORKFLOW_STEP_FUNCTION_LIST',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_LIST
        }
      ]
    },
    {
      label: 'User Management',
      icon: {
        name: 'user',
        type: IconType.NgZorro
      },
      isExpanded: true,
      children: [
        {
          label: 'Project Members',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MEMBER_LIST}`
        },
        {
          label: 'PROJECT_MODULE.PROJECT_ROLES_COMPONENT.PROJECT_ROLES',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_ROLE_LIST}`
        },
      ]
    },
    {
      label: 'SHARED.SETTINGS',
      icon: {
        name: 'setting',
        type: IconType.NgZorro
      },
      isExpanded: true,
      children: [
        {
          label: 'SHARED.PROFILE',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.PROFILE.PROFILE_PATH
        },
        {
          label: 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PROJECT_THEME',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME}`
        },

      ]
    },
    {
      label: 'SHARED.SAMPLES',
      icon: {
        name: 'like',
        type: IconType.NgZorro
      },
      isExpanded: true,
      children: [
        {
          label: 'Crud Grid',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: '/crud-view/samples/crud-grid'
        },
        {
          label: 'Registration Forms',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: '/crud-view/samples/registration-form'
        }
      ]
    },
    {
      label: 'SHARED.OTHER',
      icon: {
        name: 'pushpin',
        type: IconType.NgZorro
      },
      isExpanded: true,
      children: [
        {
          label: 'TENANT_MODULE.TENANT_LIST_COMPONENT.TENANT_LIST',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.TENANTS.TENANT_LIST_PATH}`
        },
        {
          label: 'Showcase',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: `${PATH_DEFINITIONS.OTHER.SHOWCASE_PAGE_PATH}`
        },
      ]
    },
  ],
  mode: MenuMode.Inline
};


export const PROJECTS_PAGES_MENU: MenuConfig = {
  items: [
    {
      label: 'SHARED.ALL_PROJECTS',
      actionBehavior: MenuActionBehavior.Router,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH}`
    },
    {
      label: 'PROJECT_MODULE.PROJECT_CREATE_COMPONENT.PROJECT_CREATE',
      actionBehavior: MenuActionBehavior.Router,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_CREATE_PATH}`
    }
  ],
  mode: MenuMode.Inline
};


export const WORKSPACE_MENU: MenuConfig = {
  items: [
    {
      label: 'All Projects',
      key: 'projects',
      icon: {
        name: 'fa-thin fa-warehouse',
      },
      tooltip: 'All Projects',
      actionBehavior: MenuActionBehavior.Router,
      redirectUrl: FullPaths.ProjectList
    },
    // {
    //   label: 'Design View',
    //   key: 'design-view',
    //   icon: {
    //     name: 'fa-regular fa-compass-drafting',
    //   },
    //   tooltip: 'Design View',
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.ProjectDesign
    // },
    // {
    //   label: 'Code View',
    //   key: 'code-view',
    //   icon: {
    //     name: 'fa-sharp fa-regular fa-files',
    //   },
    //   tooltip: 'Code View',
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.SourceCode
    // },
    // {
    //   label: 'TPM View',
    //   key: 'tpm-view',
    //   icon: {
    //     name: 'fa-sharp fa-regular fa-thin fa-table-columns',
    //   },
    //   tooltip: 'Technical Project Manager View',
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.SourceCode
    // },
    {
      label: 'Deploy & Preview (Upgrade your plan)',
      key: 'deploy-and-preview',
      icon: {
        name: 'fa-regular fa-play',
      },
      disabled: true,
      tooltip: 'Deploy & Previews (Upgrade your plan)',
      actionBehavior: MenuActionBehavior.NoAction,
      redirectUrl: FullPaths.LivePreview
    },
    // {
    //   label: 'Data Models',
    //   tooltip: 'Data Models',
    //   key: 'data-models',
    //   icon: {
    //     name: 'fa-light fa-database'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.DatabaseDiagram
    // },
    // {
    //   label: 'API Endpoints',
    //   tooltip: 'API Endpoints',
    //   key: 'api-endpoints',
    //   icon: {
    //     name: 'fa-light fa-server'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.Endpoint
    // },
    // {
    //   label: 'User Interface | Pages',
    //   tooltip: 'User Interface | Pages',
    //   key: 'user-interface-pages',
    //   icon: {
    //     name: 'fa-light fa-browsers'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.Pages
    // },
    // {
    //   label: 'User Interface | Theme',
    //   tooltip: 'User Interface | Theme',
    //   key: 'user-interface-theme',
    //   icon: {
    //     name: 'fa-light fa-palette'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.ThemeList
    // },
    // {
    //   label: 'UI Business Process',
    //   tooltip: 'UI Business Process',
    //   key: 'ui-business-process',
    //   icon: {
    //     name: 'fa-sharp fa-light fa-arrow-progress'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.DiagramEditor
    // },
    // {
    //   label: 'UI Data Stores',
    //   tooltip: 'UI Data Stores',
    //   key: 'ui-data-stores',
    //   icon: {
    //     name: 'fa-light fa-cabinet-filing'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.UIDataStores
    // },
    // {
    //   label: 'Server Side Workflows',
    //   tooltip: 'Server Side Workflows',
    //   key: 'server-side-workflows',
    //   icon: {
    //     name: 'fa-light fa-diagram-project'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.WorkflowDiagram
    // },
    // {
    //   label: 'Project Settings',
    //   tooltip: 'Project Settings',
    //   key: 'project-settings',
    //   icon: {
    //     name: 'fa-light fa-toolbox'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.ProjectSettings
    // },
    // {
    //   label: 'Environment Variables',
    //   tooltip: 'Environment Variables',
    //   key: 'environment-variables',
    //   icon: {
    //     name: 'fa-light fa-wrench'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.EnvironmentVariables
    // },
    // {
    //   label: 'Project Packages',
    //   tooltip: 'Project Packages',
    //   key: 'project-packages',
    //   icon: {
    //     name: 'fa-light fa-cubes'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.ProjectPackages
    // },
    // {
    //   label: 'Versions',
    //   tooltip: 'Versions',
    //   key: 'versions',
    //   icon: {
    //     name: 'fa-sharp fa-light fa-code-branch'
    //   },
    //   actionBehavior: MenuActionBehavior.Emit,
    //   redirectUrl: FullPaths.Versions
    // }
  ],
  mode: MenuMode.Inline
};

export const WORKSPACE_MENU_DROPDOWN: MenuConfig = {
  items: [
    {
      label: 'Project Home',
      key: 'project-home',
      icon: {
        name: 'fa-thin fa-home',
      },
      tooltip: 'Project Home',
      actionBehavior: MenuActionBehavior.Emit,
      redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_ROOT
    },
    {
      label: 'Workspace Menu',
      key: 'workspace-menu',
      icon: {
        name: 'fa-thin fa-light fa-list-ul'
      },
      children: [
        {
          label: 'Data Models',
          tooltip: 'Data Models',
          key: 'data-models',
          showLabel: true,
          icon: {
            name: 'fa-light fa-database'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.DatabaseDiagram
        },
        {
          label: 'API Endpoints',
          tooltip: 'API Endpoints',
          key: 'api-endpoints',
          showLabel: true,
          icon: {
            name: 'fa-light fa-server'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.Endpoint
        },
        {
          label: 'User Interface | Pages',
          tooltip: 'User Interface | Pages',
          key: 'user-interface-pages',
          showLabel: true,
          icon: {
            name: 'fa-light fa-browsers'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.Pages
        },
        {
          label: 'User Interface | Theme',
          tooltip: 'User Interface | Theme',
          key: 'user-interface-theme',
          showLabel: true,
          icon: {
            name: 'fa-light fa-palette'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.ThemeList
        },
        {
          label: 'UI Business Process',
          tooltip: 'UI Business Process',
          key: 'ui-business-process',
          showLabel: true,
          icon: {
            name: 'fa-sharp fa-light fa-arrow-progress'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.DiagramEditor
        },
        {
          label: 'UI Data Stores',
          tooltip: 'UI Data Stores',
          key: 'ui-data-stores',
          showLabel: true,
          icon: {
            name: 'fa-light fa-cabinet-filing'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.UIDataStores
        },
        {
          label: 'Server Side Workflows',
          tooltip: 'Server Side Workflows',
          key: 'server-side-workflows',
          showLabel: true,
          icon: {
            name: 'fa-light fa-diagram-project'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.WorkflowDiagram
        },
        {
          label: 'Project Settings',
          tooltip: 'Project Settings',
          key: 'project-settings',
          showLabel: true,
          icon: {
            name: 'fa-light fa-toolbox'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.ProjectSettings
        },
        {
          label: 'Environment Variables',
          tooltip: 'Environment Variables',
          key: 'environment-variables',
          showLabel: true,
          icon: {
            name: 'fa-light fa-wrench'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.EnvironmentVariables
        },
        {
          label: 'Project Packages',
          tooltip: 'Project Packages',
          key: 'project-packages',
          showLabel: true,
          icon: {
            name: 'fa-light fa-cubes'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.ProjectPackages
        },
        {
          label: 'Versions',
          tooltip: 'Versions',
          key: 'versions',
          showLabel: true,
          icon: {
            name: 'fa-sharp fa-light fa-code-branch'
          },
          actionBehavior: MenuActionBehavior.Emit,
          redirectUrl: FullPaths.Versions
        }
      ]
    }
  ],
  mode: MenuMode.Vertical
};

export const WORKSPACE_FOOTER_MENU: MenuConfig = {
  items: [
    {
      label: 'Library',
      tooltip: 'Library',
      key: 'library',
      icon: {
        name: 'fa-thin fa-light fa-circle-bookmark', // <i class="fa-sharp fa-light fa-circle-bookmark"></i>
      },
      children: [
        {
          label: 'UI Elements',
          key: 'ui-elements',
          showLabel: true,
          icon: {
            name: 'fa-thin fa-puzzle-piece-simple'
          },
          tooltip: 'UI Elements',
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_BROWSER_ELEMENTS_PATH
        },
        {
          label: 'UI Components',
          tooltip: 'UI Components',
          key: 'ui-components',
          showLabel: true,
          icon: {
            name: 'fa-thin fa-puzzle'
          },
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_BROWSER_PATH
        },
        // {
        //   label: 'UI Container Templates',
        //   key: 'ui-container-templates',
        //   icon: {
        //     name: 'fa-thin fa-files'
        //   },
        //   tooltip: 'Container Templates',
        //   actionBehavior: MenuActionBehavior.Emit,
        //   redirectUrl: PATH_DEFINITIONS.TEMPLATE_BROWSER.TEMPLATE_BROWSER_LIST_PATH
        // },
        {
          label: 'UI Page Templates',
          tooltip: 'UI Page Templates',
          key: 'ui-page-templates',
          showLabel: true,
          icon: {
            name: 'fa-thin fa-files'
          },
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.PAGE_TEMPLATES.PAGE_TEMPLATES_LIST_PATH
        },
        {
          label: 'Workflow Templates',
          tooltip: 'Workflow Templates',
          key: 'workflow-templates',
          showLabel: true,
          icon: {
            name: 'fa-light fa-diagram-project'
          },
          actionBehavior: MenuActionBehavior.Router,
          redirectUrl: PATH_DEFINITIONS.WORKFLOW_TEMPLATE.WORKFLOW_TEMPLATE_PATH
        },
        // {
        //   label: 'Template Projects',
        //   key: 'template-projects',
        //   icon: {
        //     name: 'fa-thin fa-rectangle-vertical-history'
        //   },
        //   tooltip: 'Template Projects',
        //   actionBehavior: MenuActionBehavior.Emit,
        //   redirectUrl: '/'
        // },
      ]
    },
    {
      label: 'Size',
      tooltip: 'Size',
      key: 'size',
      icon: {
        name: 'fa-regular fa-text-size',
      },
      children: [
        {
          label: 'Large',
          key: 'size-large',
          showLabel: true,
          icon: {
            name: 'fa-regular fa-text-size'
          },
          tooltip: 'Large Size',
          actionBehavior: MenuActionBehavior.Emit
        },
        {
          label: 'Medium',
          key: 'size-medium',
          showLabel: true,
          icon: {
            name: 'fa-regular fa-text-size'
          },
          tooltip: 'Medium Size',
          actionBehavior: MenuActionBehavior.Emit
        },
        {
          label: 'Small',
          key: 'size-small',
          showLabel: true,
          icon: {
            name: 'fa-regular fa-text-size'
          },
          tooltip: 'Small Size',
          actionBehavior: MenuActionBehavior.Emit
        }
      ]
    },
    {
      label: 'Language',
      key: 'language',
      icon: {
        name: 'fa-thin fa-globe',
      },
      children: [
        {
          label: 'English',
          key: 'english',
          actionBehavior: MenuActionBehavior.Emit,
          icon: {
            name: 'assets/svg-flags/gb.svg',
            type: IconType.ImportIcon,
            size: '25px'
          },
          showLabel: true
        },
        // {
        //   label: 'Turkish',
        //   key: 'turkish',
        //   actionBehavior: MenuActionBehavior.Emit,
        //   icon: {
        //     name: 'assets/svg-flags/tr.svg',
        //     type: IconType.ImportIcon,
        //     size: '25px'
        //   },
        //   showLabel: true
        // },
      ]
    },
    {
      label: 'Settings',
      key: 'settings',
      icon: {
        name: 'fa-thin fa-gear',
      },
      tooltip: 'Settings',
      actionBehavior: MenuActionBehavior.Router,
      redirectUrl: PATH_DEFINITIONS.PROFILE.PROFILE_PATH
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: {
        name: 'fa-regular fa-right-from-bracket'
      },
      tooltip: 'Logout',
      actionBehavior: MenuActionBehavior.Emit,
      redirectUrl: 'logout'
    },
  ],
  mode: MenuMode.Vertical
};

/* maps the sub menu (page menu) for the active workspace
 * (activated by clicking workspace menu item) */
export const WORKSPACE_MENU_ITEM_PAGE_MENU_MAP = {
  projects: null,
  components: null,
  assets: null,
  settings: null,
  develop: null,
  other: PAGES_MENU
};

export const WORKSPACE_MENU_DEVELOP_HOME_MENU_ITEM = {
  label: 'Project Home',
  key: 'project-home',
  icon: {
    name: 'fa-thin fa-home',
  },
  tooltip: 'Project Home',
  actionBehavior: MenuActionBehavior.Emit,
  redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_ROOT
};

export const OTHER_FEATURES_MENU_ITEM = {
  label: 'Other',
  tooltip: 'Other',
  key: 'other-features',
  icon: {
    name: 'fa-light fa-xl fa-ellipsis-stroke',
  },
  actionBehavior: MenuActionBehavior.Emit
};

