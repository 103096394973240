import { createSelector } from '@ngrx/store';

export const isEveryDataFetched = createSelector(
  (state: any) => state['workflowEvent']?.isDataFetched,
  (state: any) => state['workflowStepFunction']?.isDataFetched,
  (state: any) => state['workflowStepFunctionPublishedEventOnFailure']?.isDataFetched,
  (state: any) => state['workflowStepFunctionPublishedEventOnSuccess']?.isDataFetched,
  (state: any) => state['workflowStepFunctionSubscribedEvent']?.isDataFetched,
  (state: any) => state['diagramComment']?.isLoaded,
  (
    isWorkflowsFetched: boolean,
    isWorkflowStepFunctionsFetched: boolean,
    isPublishedEventsOnFailureFetched: boolean,
    isPublishedEventsOnSuccessFetched: boolean,
    isSubscribedEventsFetched: boolean,
    isDiagramCommentsFetched: boolean,
  ) => isWorkflowsFetched && isWorkflowStepFunctionsFetched && isPublishedEventsOnFailureFetched && isPublishedEventsOnSuccessFetched && isSubscribedEventsFetched && isDiagramCommentsFetched
);
