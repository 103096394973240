/* eslint-disable @typescript-eslint/naming-convention */
import {
  ButtonType,
  CrudFormConfig, CrudFormMonacoCodeEditorItem,
  CrudFormSelectItem, CrudViewFormItemType,
  FormLayout,
  HeadingType
} from '@rappider/rappider-components/utils';

export const PROJECT_DESCRIPTION_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormMonacoCodeEditorItem>{
      section: 'Project Description',
      title: '',
      type: CrudViewFormItemType.MarkDown,
      fieldName: 'description',
      showRequiredSign: false,
      editorOptions:
      {
        theme: 'vs',
        language: 'markdown',
        lineNumbers: { renderType: 0 },
        minimap: {
          enabled: false,
        },
        wordWrap: 'on',
        wrappingStrategy: 'advanced',
      },
      sizeSettings: {
        minHeight: '500px'
      },
      typeInRealTime: false
    },
  ],
  formLayout: FormLayout.Vertical,
  sections: [
    {
      title: 'Project Description',
      key: 'projectDescription',
      columns: 1,
      titleType: HeadingType.H3,
    }
  ],
  showInCollapsedSections: false,
  submitButton: {
    type: ButtonType.Primary
  },
  cancelButton: {
    type: ButtonType.Default
  },
};

export const PROJECT_INFO_CONFIG: CrudFormConfig = {
  items: [
    {
      section: 'Project Information',
      title: 'Project Id',
      fieldName: 'id',
      type: CrudViewFormItemType.Label
    },
    {
      section: 'Project Information',
      title: 'Created By',
      fieldName: 'createdBy',
      type: CrudViewFormItemType.Label
    },
    {
      section: 'Project Information',
      title: 'Created Date',
      fieldName: 'createdDate',
      type: CrudViewFormItemType.Label,
      displayFormatFunction: (value: string) => (new Date(value).toLocaleDateString())
    },
    {
      section: 'Project Information',
      title: 'Authentication?',
      fieldName: 'hasAuthenticationModule',
      type: CrudViewFormItemType.CheckBox
    },
    {
      section: 'Project Information',
      title: 'Application Type',
      fieldName: 'appType',
      type: CrudViewFormItemType.Select
    },
    {
      section: 'Project Information',
      title: 'FrontEnd Programming Language & Framework',
      fieldName: 'frontendFramework',
      type: CrudViewFormItemType.Label,
      displayFormatFunction: (value: string) => (value === 'angular' ? 'Typescript & Angular' : '')
    },
    {
      section: 'Project Information',
      title: 'Backend Programming Language & Framework',
      fieldName: 'backendFramework',
      type: CrudViewFormItemType.Label,
      displayFormatFunction: (value: string) => (value === 'loopback4' ? 'NodeJS & Loopback' : '')
    },
    {
      section: 'Project Information',
      title: 'Database',
      fieldName: 'database',
      type: CrudViewFormItemType.Label,
      displayFormatFunction: (value: any) => ('MongoDB')
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    type: ButtonType.Primary
  },
  formLayout: FormLayout.Vertical,
  sections: [
    {
      title: 'Project Information',
      key: 'projectInformation',
      columns: 2
    }
  ],
  showInCollapsedSections: true
};

export const PROJECT_MEMBERS_CONFIG: CrudFormConfig = {
  items: [
    {
      title: '',
      fieldName: 'people',
      type: CrudViewFormItemType.Table,
      settings: {
        showHeaders: true,
        items: [
          {
            title: 'First Name',
            fieldName: 'firstName'
          },
          {
            title: 'Last Name',
            fieldName: 'lastName'
          },
          {
            title: 'Email',
            fieldName: 'email'
          }
        ]
      }
    },
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    type: ButtonType.Primary
  },
  formLayout: FormLayout.Vertical,
  sections: [
    {
      title: 'Project Members',
      key: 'projectInformation',
      columns: 1
    }
  ],
  showInCollapsedSections: true
};

export const SCOPE_OF_WORK_CONFIG = {
  items: [
    <CrudFormMonacoCodeEditorItem>{
      section: 'Scope Of Work',
      title: '',
      type: CrudViewFormItemType.MarkDown,
      fieldName: 'content',
      showRequiredSign: false,
      editorOptions:
      {
        theme: 'vs',
        language: 'markdown',
        lineNumbers: { renderType: 0 },
        minimap: {
          enabled: false,
        },
      },
      sizeSettings: {
        minHeight: '400px'
      }
    },
  ],
  formLayout: FormLayout.Vertical,
  sections: [
    {
      title: 'Scope Of Work',
      key: 'scopeOfWork',
      columns: 1
    }
  ],
  showInCollapsedSections: false,
  submitButton: {
    type: ButtonType.Primary
  },
  cancelButton: {
    type: ButtonType.Default
  },
};

export const EMPTY_CRUD_CONFIG: CrudFormConfig = {
  items: [],
  formLayout: FormLayout.Vertical,
  sections: [],
  showInCollapsedSections: false,
  submitButton: {
    type: ButtonType.Primary
  },
  cancelButton: {
    type: ButtonType.Default
  },
};
