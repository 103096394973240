import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, tap, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { BrowserStorageManagementService, SyncRemoteDataService } from '@rappider/services';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import {
  ProjectControllerService, ProjectFolderControllerService,
  ProjectFolderWithRelations, ProjectWithRelations
} from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

import * as ActiveProjectActions from './active-project.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { UserRoles } from 'libs/shared/src/lib/guards/admin-guard/admin-dashboard-roles.enum';
import { SyncPriorityRemoteData, SyncResetRemoteData, SyncSecondaryRemoteData } from 'apps/rappider/src/app/state/app.actions';
import { selectActiveProjectId } from './active-project.selectors';

@Injectable()
export class ActiveProjectEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private projectApi: ProjectControllerService,
    private browserStorageManagementService: BrowserStorageManagementService,
    private projectFolderApi: ProjectFolderControllerService,
    private syncRemoteDataService: SyncRemoteDataService
  ) { }


  selectActiveProject$ = createEffect(() => this.actions$.pipe(
    ofType<ActiveProjectActions.SelectActiveProject>(
      ActiveProjectActions.ActionTypes.SelectActiveProject
    ),
    withLatestFrom(
      this.store.select(state => state.auth.user?.roleMapping?.role),
      this.store.select(selectActiveProjectId)
    ),
    mergeMap(([action, role, currentActiveProjectId]) => {
      const isAdmin = role?.name === UserRoles.Admin;
      if (currentActiveProjectId === action.payload.projectId) {
        // we are trying to select the same project, no need to do anything
        return EMPTY;
      } else {
        if (isAdmin) {
          const filter = {
            where: {
              id: action.payload.projectId
            },
            include: ['people', 'tenant']
          };
          return this.projectApi.findByAdmin({ filter }).pipe(
            mergeMap((projects: ProjectWithRelations[]) => ([

              // resets the sync project data
              new SyncResetRemoteData({ selectedProjectId: action.payload?.projectId }),

              new AuthenticationActions.UpdateAuthenticationTokenWithProjectId(
                {
                  project: projects?.find(project => project?.id === action.payload?.projectId),
                  navigateToProjectDetail: action.payload.navigateToProjectDetail,
                  navigateAIAssistantPage: action.payload.navigateAIAssistantPage,
                  isAdmin: isAdmin
                }
              ),
              new AuthenticationActions.GetPermissions()
            ])),
            catchError(error => [
              new ActiveProjectActions.ActiveProjectError({
                errorOn: ActiveProjectActions.ActionTypes.SelectActiveProject,
                error: error
              })
            ])
          );
        } else {
          const params = {
            id: action.payload.projectId,
            filter: {
              include: ['people', 'tenant']
            }
          };
          return this.projectApi.findById(params).pipe(
            mergeMap((project: ProjectWithRelations) => ([
              new AuthenticationActions.UpdateAuthenticationTokenWithProjectId(
                {
                  project: project,
                  navigateToProjectDetail: action.payload.navigateToProjectDetail,
                  navigateAIAssistantPage: action.payload.navigateAIAssistantPage
                }
              ),
              new AuthenticationActions.GetPermissions()
            ])),
            catchError(error => [
              new ActiveProjectActions.ActiveProjectError({
                errorOn: ActiveProjectActions.ActionTypes.SelectActiveProject,
                error: error
              })
            ])
          );
        }
      }
    })
  ));

  /**
   * Set Active Project, Handles setting active project in local storage and sync remote data
   *
   * @memberof ActiveProjectEffects
   */
  $prepareActiveProject = createEffect(() => this.actions$.pipe(
    ofType<ActiveProjectActions.SelectActiveProjectSuccessful>(
      ActiveProjectActions.ActionTypes.SelectActiveProjectSuccessful
    ),
    mergeMap((action) => {
      const activeProject = action.payload?.project;
      const actions: Action[] = [];
      if (activeProject) {
        /* set active project id in local storage */
        this.browserStorageManagementService.setLocalStorageItem('activeProjectId', activeProject.id);

        if (activeProject?.id) {
          // Syncs the (priority) data that is required for the active project
          actions.push(
            new SyncPriorityRemoteData({ projectId: activeProject?.id }),
            new SyncSecondaryRemoteData({ projectId: activeProject?.id })
          );
        }
        return actions;
      } else {
        /* remove active project id from local storage */
        this.browserStorageManagementService.removeLocalStorageItem('activeProjectId');
        return actions;
      }
    })));


  $getRootFolder = createEffect(() => this.actions$.pipe(
    ofType<ActiveProjectActions.GetRootFolder>(
      ActiveProjectActions.ActionTypes.GetRootFolder
    ),
    switchMap((action) => {
      const params = {
        filter: {
          include: [
            {
              relation: 'subFolders'
            },
            // {
            //   relation: 'files'
            // }
          ]
        }
      };
      return this.projectFolderApi.getRootFolder(params).pipe(
        map((rootFolder: ProjectFolderWithRelations) =>
          new ActiveProjectActions.SetRootFolder({
            rootFolder: rootFolder
          })
        ),
        catchError(error =>
          of(new ActiveProjectActions.ActiveProjectError({
            errorOn: ActiveProjectActions.ActionTypes.GetRootFolder,
            error: error
          }))
        )
      );
    })
  ));

}

