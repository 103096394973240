<rappider-modal [(visible)]="createDataSelectorModalVisibility"
                [footer]="null"
                title="Create Selector"
                (cancelClick)="onCancel('create')"
                [fullScreen]="true">
  <rappider-ui-data-selector *ngIf="createDataSelectorModalVisibility"
                             (cancel)="onCancel('create')"
                             (save)="onSave($event)"></rappider-ui-data-selector>
</rappider-modal>

<rappider-modal [(visible)]="editDataSelectorModalVisibility"
                [footer]="null"
                [title]="'Edit Selector | ' + editingUiDataSelector?.name"
                (cancelClick)="onCancel('edit')"
                [fullScreen]="true">
  <rappider-ui-data-selector *ngIf="editDataSelectorModalVisibility"
                             [uiDataSelector]="editingUiDataSelector"
                             (cancel)="onCancel('edit')"
                             (save)="onEdit($event)"></rappider-ui-data-selector>
</rappider-modal>