import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudFormSelectItem, CrudFormSwitchItem, CrudFormTextBoxItem, CrudViewFormItemType, InputSize, SelectMode } from '@rappider/rappider-components/utils';

export const SAVE_CONTAINER_AS_COMPONENT_FORM_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'SHARED.TITLE',
      fieldName: 'title',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Large
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewFormItemType.TextBox,
      size: InputSize.Large
    },
    <CrudFormSelectItem>{
      title: 'SHARED.MAIN_CATEGORY',
      fieldName: 'categoryId',
      type: CrudViewFormItemType.Select,
      size: InputSize.Large,
      settings: {
        searchable: true
      },
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'SHARED.OTHER_CATEGORIES',
      fieldName: 'subCategoryIds',
      type: CrudViewFormItemType.Select,
      size: InputSize.Large,
      settings: {
        searchable: true,
        mode: SelectMode.Multiple,
        maxTagCount: 3
      }
    },
    <CrudFormSelectItem>{
      title: 'SHARED.TAGS',
      fieldName: 'tags',
      type: CrudViewFormItemType.TagInput,
      size: InputSize.Large,
      default: [],
      settings: {
        searchable: true
      }
    },
    <CrudFormSwitchItem>{
      title: 'Iterative',
      fieldName: 'isIterative',
      type: CrudViewFormItemType.Switch
    }
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    visible: true
  }
};
