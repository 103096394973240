import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, activeProjectFeatureKey } from './active-project.reducer';

// Select the feature slice from the store
export const selectActiveProjectState = createFeatureSelector<State>(activeProjectFeatureKey);

// Selectors
export const selectActiveProject = createSelector(
  selectActiveProjectState,
  (state: State) => state.data
);

export const selectRootFolder = createSelector(
  selectActiveProjectState,
  (state: State) => state.rootFolder
);

export const selectActiveProjectThemeId = createSelector(
  selectActiveProjectState,
  (state: State) => state.data?.activeProjectThemeId
);

export const selectActiveProjectId = createSelector(
  selectActiveProjectState,
  (state: State) => state.data?.id
);

export const selectIsActiveProjectAvailable = createSelector(
  selectActiveProjectState,
  (state: State) => !!state.data
);
