<section class="complete-wizard-container">
  <!-- Success Animation -->
  <div class="animation-section">
    <rappider-lottie-player [options]="SuccessAnimation.options"
                            [height]="SuccessAnimation.height"
                            [width]="SuccessAnimation.width"
                            class="success-animation">
    </rappider-lottie-player>
  </div>

  <!-- Title and Description -->
  <div class="content-section">
    <rappider-text [text]="title.content"
                   [typography]="title.typography"
                   [colorSettings]="title.colorSettings">
    </rappider-text>

    <rappider-text [text]="description.content"
                   [typography]="description.typography"
                   [colorSettings]="description.colorSettings">
    </rappider-text>

    <rappider-text [content]="mainText.content"
                   [textMode]="mainText.textMode"
                   [typography]="mainText.typography"
                   [colorSettings]="mainText.colorSettings">
    </rappider-text>
  </div>

  <!-- Share Section -->
  <div class="share-section">
    <rappider-text [text]="shareTitle.content"
                   [typography]="shareTitle.typography"
                   [colorSettings]="shareTitle.colorSettings">
    </rappider-text>

    <rappider-text [content]="shareText.content"
                   [textMode]="shareText.textMode"
                   [typography]="shareText.typography"
                   [colorSettings]="shareText.colorSettings">
    </rappider-text>

    <rappider-link-list [buttons]="socialLinks"
                        class="social-links"
                        (itemClick)="onSocialLinkClick($event)">
    </rappider-link-list>
  </div>

  <!-- Explore Section -->
  <div class="explore-section">
    <rappider-text [text]="exploreTitle.content"
                   [typography]="exploreTitle.typography"
                   [colorSettings]="exploreTitle.colorSettings">
    </rappider-text>

    <rappider-text [content]="exploreText.content"
                   [textMode]="exploreText.textMode"
                   [typography]="exploreText.typography"
                   [colorSettings]="exploreText.colorSettings">
    </rappider-text>

    <rappider-button [text]="exploreButton.text"
                     (click)="onExploreButtonClick()"
                     class="explore-button">
    </rappider-button>
  </div>
</section>
