import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import {
  ContentEditorDestroy,
  CopyContainer,
  CreateComponent,
  DeleteComponent,
  GetTemplateById,
  GetTemplateByIdSuccessful,
  LoadPageWithComponents,
  SetActiveContentTreeItem,
  UpdateComponent,
  UpdateContentTreeOfPage
} from '../../state/content-editor.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash';
import { BrowserStorageManagementService, ComponentDefinitionService, ContentEditorService, DomManagementService, NotificationService, RouterStateService } from '@rappider/services';
import { RemoveQueryParam, getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';
import { ActionBehavior, AvatarPlacement, ButtonComponentConfig, ButtonSize, CrudFormItemMenuItem, DropdownMenuItem, HeadingType, ItemPerRow, TextMode } from '@rappider/rappider-components/utils';
import { ComponentDataSubscriptionModalMode } from '../../models/component-data-subscription-modal-mode.enum';
import { ContentEditorDataSubscriptionModal } from '../../models/content-editor-data-subscription-modal.interface';
import { v4 } from 'uuid';
import {
  CommentWithRelations,
  ComponentDataSubscriptionControllerService,
  ComponentDataSubscriptionWithRelations,
  ComponentDefinitionWithRelations,
  ContainerTemplateWithRelations,
  DataSchemaWithRelations,
  NewPageComment,
  Page,
  PageCommentWithRelations,
  PageWithRelations,
  PersonWithRelations,
  Project,
  ProjectFileControllerService,
  UiDataEventWithRelations,
  UiDataStore
} from '@rappider/rappider-sdk';
import {
  ProjectTheme,
  ProjectExternalScriptInterface,
} from '@rappider/api-sdk';
import {
  ExternalScriptType,
  FullPaths,
  PATH_DEFINITIONS,
  ProjectScriptTypeOptionsEnum,
} from '@rappider/shared/definitions';
import { GetContainerTemplates } from '../../state/container-template-state/container-template.actions';
import { TreeService } from 'libs/shared/src/lib/services/tree-service/tree.service';
import { CreatePageComment, DeletePageComment, UpdatePageComment } from 'libs/comment/src/lib/state/page-comment/page-comment.actions';
import { getPageCommentsWithDetails } from 'libs/comment/src/lib/state/page-comment/selectors/get-page-comments-with-details.selector';
import { commentAndPageCommentLoading } from 'libs/comment/src/lib/state/page-comment/selectors/comment-and-page-comments-loading.selector';
import { ContentEditorClipboardData } from '../../models/content-editor-clipboard-data.interface';
// import { containerTitleBarButtonVisibilityFunction, containerTitleBarButtons } from '../../models/container-title-bar-buttons';
import { componentTitleBarButtonVisibilityFunction, componentTitleBarButtons } from '../../models/component-title-bar-buttons';
import { ContentTree, ContentTreeContainer, ContentTreeItem, ContentTreeItemType, AddComponentWithPositionInterface } from 'libs/content-tree-renderer/src/lib/models';
import { CopyActiveContentTemplateToLocalStorage } from '../../state/content-editor.actions';
import { PasteTemplateFromLocalStorage } from '../../state/content-editor.actions';
import { MoveContentTreeItem } from '../../state/content-editor.actions';
import { CreatePageContainer } from '../../state/content-editor.actions';
import { DeletePageContainer } from '../../state/content-editor.actions';
import { contentTreeAutoSaveTime } from '../../models/content-tree-auto-save-time';
import { isContentTreeChangesSaved } from '../../selectors/is-content-tree-changes-saved.selector';
import { UpdateComponentDataSubscriptionDtoPartial } from 'libs/rappider-sdk/src/lib/models/update-component-data-subscription-dto-partial';
import { ContentEditorRightSidebarMenuComponent } from '../content-editor-left-sidebar-menu/content-editor-right-sidebar-menu.component';
import { ContainerRemoveStrategy } from '../../models/container-remove-strategy.enum';
import { containerTitleBarDropdownConfig, containerTitleBarDropdownItemsVisibilityFunction } from '../../models/container-title-bar-dropdown-config';
import { componentTitleBarDropdownConfig, componentTitleBarDropdownItemsVisibilityFunction } from '../../models/component-title-bar-dropdown-config';
import { contentTreeWithRelations } from '../../selectors/content-tree-with-details.selector';
import { activeContentTreeItemSelector } from '../../selectors/active-content-tree-item.selector';
import { getActiveLayoutForPage } from '../../selectors/layout-with-details.selector';
import { CommentCategory } from 'libs/comment/src/lib/utils/comment-category.enum';
import { mergeComponentsAndDefinitionsToContentTree } from '../../state/content-editor-helper-functions';
import { ChangeActiveProjectWithNotification } from 'libs/shared/src/lib/states/notification/notification.actions';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';
import { snippetsCardsConfig } from '../../utils/snippets-cards-config';
import { MenuActionBehavior } from 'libs/components/src/lib/utils/menu-two';
import { PreviewGridType } from 'libs/component-definition/src/lib/components/component-definitions-preview/model/preview-grid-type.enum';
import { ThemeMode } from 'libs/shared/src/lib/models/theme';

@Component({
  selector: 'rappider-content-editor',
  templateUrl: './content-editor.page.component.html',
  styleUrls: ['./content-editor.page.component.scss'],
})
export class ContentEditorComponent implements OnInit, OnDestroy {
  @ViewChild('loadingNotificationTemplate', { static: false }) loadingNotificationTemplate: any;
  @ViewChild('rightSidebarMenuComponentRef') rightSidebarMenuComponent: ContentEditorRightSidebarMenuComponent;

  pageId: string; /* active page id */
  activeProject: Project; /* active project id */
  page: PageWithRelations; /* active page */
  layout: Page;
  uiDataStores: UiDataStore[]; /* ui data stores of the active project */
  containerTemplates: ContainerTemplateWithRelations[];
  activePerson: PersonWithRelations;

  /**
   * Displayed comments of the page
   *
   * @type {CommentWithRelations[]}
   * @memberof ContentEditorComponent
   */
  pageComments: PageCommentWithRelations[];
  // TODO: Should be removed as this is duplicate of pageComments
  allCommentsOnPage: PageCommentWithRelations[];

  // TODO: define the usage of activePageComments
  activePageComments: PageCommentWithRelations[];
  projectPeopleData: PersonWithRelations[];
  commentsLoading: boolean;
  uiDataEvents: UiDataEventWithRelations[];
  activeCommentId: string;
  CommentCategory = CommentCategory;
  isCommentActive = false;
  isClickedCommentIcon = false;

  activeContentTreeItem: any; /* selected content tree item id */
  ContentEditorContentType = ContentTreeItemType; /* content type enum */
  contentTree: ContentTree;
  lastSavedContentTree: ContentTree;
  componentDefinitions: ComponentDefinitionWithRelations[]; /* all component definitions */
  isComponentDefinitionsLoading: boolean;
  isContentEditorLoading: boolean;
  isCopyContainerTemplateLoading: boolean;

  dataSubscriptionModal: ContentEditorDataSubscriptionModal = {
    title: null,
    /* modal visibility */
    isVisible: false,
    /* selected ui data store's data schema to be shown to be selected a field
      from itself to be able to create component data subscription */
    activeUIDataStore: null,
    /* selected ui data store's data schema to be shown to be selected a field
      from itself to be able to create component data subscription */
    activeDataSchema: null,
    inputDefinition: null
  };
  ComponentDataSubscriptionModalMode = ComponentDataSubscriptionModalMode;

  aiContentConfigModal = {
    isVisible: false,
    title: null,
    inputDefinition: null,
    data: null
  };

  subscriptions: Subscription[];
  initSubscription: Subscription;

  leftSidebarVisibility = true;

  projectTheme: ProjectTheme;
  /* selected ui data store's data schema id */
  activeDataSchemaId: string;
  /* data schemas */
  dataSchemas: DataSchemaWithRelations[];

  projectScripts: ProjectExternalScriptInterface[];
  activeNodeToRootPath;

  clipboardData: ContentEditorClipboardData;

  /* content tree item title bar configs & functions */
  // containerTitleBarButtons = containerTitleBarButtons;
  componentTitleBarButtons = componentTitleBarButtons;
  componentTitleBarButtonVisibilityFunction = componentTitleBarButtonVisibilityFunction;
  // containerTitleBarButtonVisibilityFunction = containerTitleBarButtonVisibilityFunction;
  componentTitleBarDropdownConfig = componentTitleBarDropdownConfig;
  containerTitleBarDropdownConfig = containerTitleBarDropdownConfig;
  containerTitleBarDropdownItemsVisibilityFunction = containerTitleBarDropdownItemsVisibilityFunction;
  componentTitleBarDropdownItemsVisibilityFunction = componentTitleBarDropdownItemsVisibilityFunction;

  deletePageContainerModalVisibility = false;
  addComponentModalVisibility = false;
  addElementModalVisibility = false;
  visibilityConditionModalVisibility = false;

  addComponentDrawerVisibility = false;

  isDataSchemaLoading = false;

  isContentTreeChangesSaved: boolean;
  isSaveChangesAlertDisplayed = false;

  rightSidebarVisibility = true;
  rightSidebarSize = 50;
  rightSidebarMinSize = 50;
  activeCommentType: string;
  preferredTheme: ThemeMode;
  previewMode = false;

  selectedElementOnTitlebar: { button: ButtonComponentConfig, content: ContentTreeItem };

  snippetsCardsConfig = snippetsCardsConfig;
  elements;
  snippets;
  templatePages;
  componentsAndSnippetsModalVisibility;

  allPanelsActive = true;
  @HostListener('window:beforeunload', ['$event'])
  showAlertMessageWhenClosingTab($event) {
    if (this.isContentTreeChangesSaved === false) {
      $event.returnValue = 'Changes you make may not be saved. Please save the changes before leaving the page.';
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private routerStateService: RouterStateService,
    private notificationService: NotificationService,
    private componentDataSubscriptionApi: ComponentDataSubscriptionControllerService,
    private contentEditorService: ContentEditorService,
    private treeService: TreeService,
    private domManagementService: DomManagementService,
    private projectFileApi: ProjectFileControllerService,
    private browserStorageManagementService: BrowserStorageManagementService,
    public router: Router,
    private componentDefinitionService: ComponentDefinitionService
  ) { }

  ngOnInit() {
    const projectId = this.activatedRoute?.snapshot?.queryParams?.projectId;
    const commentId = this.activatedRoute?.snapshot?.queryParams?.activeCommentId;
    const pageId = this.activatedRoute?.snapshot?.params?.pageId;
    this.initSubscription = this.store.select((state) =>
      state.notification?.changeActiveProjectWithNotification?.activeProjectLoadedThroughNotification)
      .subscribe((activeProjectLoadedThroughNotification) => {
        if (activeProjectLoadedThroughNotification) {
          this.getPageIdByUrl();
        }
      });
    if (projectId !== this.browserStorageManagementService.getLocalStorageItem('activeProjectId') && projectId) {
      this.store.dispatch(ChangeActiveProjectWithNotification(
        {
          changeActiveProjectWithNotification: {
            commentType: 'page', pageId, commentId, projectId, isProjectChange: true,
            isProjectChangeWithoutRefresh: false, activeProjectLoadedThroughNotification: false
          }
        }));
    } else {
      this.getPageIdByUrl();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
    this.initSubscription.unsubscribe();
    this.store.dispatch(new ContentEditorDestroy({
      pageId: this.pageId,
      contentTree: this.contentTree,
      lastSavedContentTree: this.lastSavedContentTree
    }));
    this.removeStyleTags();
  }

  getPageIdByUrl() {
    this.activatedRoute.params.subscribe(params => {
      if (params.pageId) {
        this.pageId = params.pageId;
        this.getData();
      }
    });
  }

  getData() {
    if (this.pageId) {
      this.store.dispatch(new LoadPageWithComponents({ pageId: this.pageId }));
      this.subscribeToData();
    }
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToActivePerson(),
      this.subscribeToContentTree(),
      this.subscribeToContentTreeWithComments(),
      this.subscribeToActiveContentTreeItem(),
      this.subscribeToComponentDefinitions(),
      this.subscribeToStateRouteQueryParams(),
      this.subscribeToServiceActivatedRouteQueryParams(),
      this.subscribeToUIDataStores(),
      this.subscribeToPage(),
      this.subscribeToComponentDefinitionsLoadingState(),
      this.subscribeToContentEditorLoadingState(),
      this.subscribeToProjectTheme(),
      this.subscribeToDataSchemas(),
      this.subscribeToProjectScripts(),
      this.subscribeToCopyContainerTemplatesLoading(),
      this.subscribeToContainerTemplates(),
      this.subscribeToCommentsAndPageCommentsLoading(),
      this.subscribeToActiveCommentType(),
      this.subscribeToClipboard(),
      this.subscribeToDataSchemaLoadingState(),
      this.subscribeToContentTreeChangesSavedStatus(),
      this.subscribeToLastSavedContentTree(),
      this.subscribeToUiDataEvents(),
      this.subscribeToPageTemplatesWithTags(),
      this.subscribeToPreferredTheme(),
      this.subscribeToCopiedTemplate()
    ];
  }

  // #region Subscriptions

  subscribeToStateRouteQueryParams() {
    return this.store.select(state => state.router.queryParams)
      .subscribe(q => {
        if (q?.activeCommentId) {
          this.allPanelsActive = true;
          this.activeCommentId = null;
          setTimeout(() => {
            this.activeCommentId = q?.activeCommentId;
            const contentTreeItemId = this.activePageComments?.find(p => p.commentId === this.activeCommentId)?.contentTreeItemId;
            this.rightSidebarVisibility = true;
            this.rightSidebarMenuComponent.showSidebar(this.rightSidebarVisibility);
            if (this.activeContentTreeItem?.id !== contentTreeItemId) {
              this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId }));
            } else {
              this.isCommentActive = true;
            }
          }, 100);
        }
        if (q?.targetPageId) {
          this.onPageSelected(q?.targetPageId);
          this.store.dispatch(new RemoveQueryParam({ key: 'targetPageId' }));
        }
      });
  }

  subscribeToServiceActivatedRouteQueryParams() {
    return this.activatedRoute.queryParams.subscribe(q => {
      if (q?.activeCommentId) {
        this.allPanelsActive = true;
        this.activeCommentId = null;
        setTimeout(() => {
          this.activeCommentId = q?.activeCommentId;
          const contentTreeItemId = this.activePageComments?.find(p => p.commentId === this.activeCommentId)?.contentTreeItemId;
          if (this.activeContentTreeItem?.id !== contentTreeItemId) {
            this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId }));
          }
        }, 100);
      }
    });
  }

  subscribeToActiveProject() {
    return this.store
      .select((state) => state.activeProject.data)
      .subscribe((activeProject: Project) => {
        if (activeProject) {
          this.activeProject = activeProject;
          /* get container templates for component menu */
          this.store.dispatch(GetContainerTemplates());
          this.appendGlobalStyleToGlobalStyleElement();
        }
      });
  }

  subscribeToActivePerson() {
    return this.store.select(state => state.auth?.activePerson).subscribe((activePerson: PersonWithRelations) => {
      this.activePerson = activePerson;
    });
  }

  subscribeToActiveCommentType() {
    return this.store.select(state => state.comment?.activeCommentType).subscribe((activeCommentType: string) => {
      this.activeCommentType = activeCommentType;
    });
  }

  setActiveContentTreeItemByActiveCommentId() {
    if (this.activeCommentId && this.contentTree && this.pageComments) {
      const contentTreeItemId = this.pageComments?.find(c => c.commentId === this.activeCommentId)?.contentTreeItemId;
      if (contentTreeItemId && this.activeContentTreeItem?.id !== contentTreeItemId) {
        this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId }));
      }
    }
  }

  subscribeToCommentsAndPageCommentsLoading() {
    return this.store.select(<any>commentAndPageCommentLoading).subscribe((isLoading: boolean) => {
      this.commentsLoading = isLoading;
    });
  }

  subscribeToPageTemplatesWithTags() {
    return this.store.select(state => state.page?.templatePages).subscribe((templatePages) => {
      if (templatePages) {
        this.templatePages = templatePages;
      }
    });
  }

  subscribeToPreferredTheme() {
    return this.store.select(state => state.auth?.activePerson?.preferredTheme).subscribe(preferredTheme => {
      if (preferredTheme === ThemeMode.Light) {
        this.preferredTheme = ThemeMode.Light;
      } else {
        this.preferredTheme = ThemeMode.Dark;
      }
    });
  }

  subscribeToCopiedTemplate() {
    return this.store.select(state => state.contentEditor?.copiedTemplate).subscribe(template => {
      if (template) {
        const containerId = (this.page.contentTree.find((p: any) => p.isMainContainer === true) as { id: string }).id;
        let componentsIds = template.components?.map(component => ({
          sourceComponentId: component.id,
          targetComponentId: v4()
        }))
        function replaceComponentIds(data, replacements) {
          const updatedData = JSON.parse(JSON.stringify(data));
          const replacementMap = new Map(
            replacements?.map(({ sourceComponentId, targetComponentId }) => [sourceComponentId, targetComponentId])
          );

          function updateContentTree(node) {
            if (!node) return;

            if (node.componentId && replacementMap.has(node.componentId)) {
              node.componentId = replacementMap.get(node.componentId);
            }

            if (node.type === "container") {
              node.id = v4();
            }

            if (node.type === "component") {
              node.id = v4();
            }

            if (Array.isArray(node.children)) {
              node.children.forEach(updateContentTree);
            }
          }

          function updateComponents(node) {
            if (!node) return;

            if (node.id && replacementMap.has(node.id)) {
              node.id = replacementMap.get(node.id);
            }

            if (node.type === "container") {
              node.id = v4();
            }

            if (Array.isArray(node.children)) {
              node.children.forEach(updateComponents);
            }
          }

          if (Array.isArray(updatedData.contentTree)) {
            updatedData.contentTree.forEach(updateContentTree);
          }

          if (Array.isArray(updatedData.components)) {
            updatedData.components.forEach(updateComponents);
          }

          return updatedData;
        }
        const updatedData = replaceComponentIds(template, componentsIds);
        this.store.dispatch(new CopyContainer({
          targetPageId: this.pageId,
          sourcePageId: template.id,
          targetContainerId: this.activeContentTreeItem?.id ? this.activeContentTreeItem.id : containerId,
          componentPairs: componentsIds,
          components: updatedData?.components
        }));
        this.store.dispatch(new CreatePageContainer({
          pageContainerId: this.activeContentTreeItem?.id ? this.activeContentTreeItem.id : containerId
          , pageContainerData: updatedData
        }));
        this.store.dispatch(new GetTemplateByIdSuccessful({ template: null }));
      }
    });
  }

  subscribeToContentTree() {
    return this.store.select(<any>contentTreeWithRelations)
      .subscribe((contentTree: ContentTree) => {
        if (contentTree?.length) {
          this.contentTree = cloneDeep(contentTree);
          // update content tree item if active content tree item exists and type is component (for data subscription changes etc.)
          if (this.activeContentTreeItem?.id) {
            this.updateActiveContentTreeItemWhenContentTreeUpdated();
          }
          this.setActiveContentTreeItemByActiveCommentId();
        }
      });
  }

  subscribeToContentTreeWithComments() {
    return this.store.select(createSelector(
      <any>getPageCommentsWithDetails,
      state => <any>state['contentEditor'].page?.contentTree,
      state => <any>state['contentEditor'].page?.components,
      state => <any>state['componentDefinition']?.data,
      state => <any>state['contentEditor']?.activeContentTreeItemId,
      state => <any>state['comment']?.activeCommentType,
      state => <any>state['contentEditor']?.page?.id,
      (
        pageComments: any, contentTree: any, components: any,
        componentDefinitions: any, activeContentTreeItemId: any,
        activeCommentType: any, activePageId: any
      ) => {
        if (contentTree) {
          /* if the page has no component, return just contentTree */
          if (components?.length) {
            if (componentDefinitions?.length) {
              const pageCommentWithDetails = {
                pageComments,
                activeContentTreeItemId,
                activeCommentType,
                activePageId,
                mergeComponents: mergeComponentsAndDefinitionsToContentTree(contentTree[0], componentDefinitions, components)
              };
              return pageCommentWithDetails;
            }
          } else {
            const pageCommentWithDetails = {
              pageComments,
              activeContentTreeItemId,
              activeCommentType,
              activePageId,
              mergeComponents: contentTree
            };
            return pageCommentWithDetails;
          }
        }
      }
    )).subscribe((pageCommentWithDetails) => {
      this.allCommentsOnPage = pageCommentWithDetails?.pageComments?.filter(c => c.pageId === this.pageId) || [];
      this.activePageComments = pageCommentWithDetails?.pageComments?.filter(c => c.pageId === this.pageId) || [];

      /* Map the pageComments by filtering all comments that belongs to the current page
       * and add contentTreeItem notation as additionalContent
       */
      this.pageComments = this.activePageComments?.map(pageComment => {
        const contentTreeItem = this.treeService?.findNodeInTree(
          pageCommentWithDetails?.mergeComponents?.[0], pageComment.contentTreeItemId, 'id', ['children']);
        return {
          ...pageComment,
          comment: {
            ...pageComment.comment,
            additionalContent: `Commented on: ${contentTreeItem?.title || contentTreeItem?.component?.title}` ?? null
          }
        };
      });

      if (this.contentTree?.length && this.pageComments?.length) {
        this.setActiveContentTreeItemByActiveCommentId();
      }
    });
  }

  subscribeToActiveContentTreeItem() {
    return this.store.select(<any>activeContentTreeItemSelector)
      .subscribe((activeContentTreeItem: any) => {
        this.activeContentTreeItem = cloneDeep(activeContentTreeItem);
        this.calculateActiveNodeToRootPath();
      });
  }

  subscribeToDataSchemas() {
    return this.store.select(<any>getDataSchemasWithDetailsSelector).subscribe((dataSchemas: DataSchemaWithRelations[]) => {
      this.dataSchemas = dataSchemas;
      const activeDataSchema = dataSchemas?.find(dataSchema => dataSchema.id === this.activeDataSchemaId);
      if (activeDataSchema) {
        this.dataSubscriptionModal.activeDataSchema = activeDataSchema;
      }
    });
  }

  subscribeToUIDataStores() {
    return this.store
      .select((state) => state.uiDataStore?.data)
      .subscribe((uiDataStores: UiDataStore[]) => {
        this.uiDataStores = uiDataStores;
      });
  }

  subscribeToPage() {
    return this.store
      .select((state) => state.contentEditor?.page)
      .subscribe((page: PageWithRelations) => {
        this.page = cloneDeep(page);
        if (page) {
          this.setContentAreaCssStyle();
        }
        this.subscribeToPageLayout();
      });
  }

  subscribeToComponentDefinitions() {
    return this.store
      .select(<any>getComponentDefinitionsWithDetailsSelector)
      .subscribe((componentDefinitions: ComponentDefinitionWithRelations[]) => {
        this.componentDefinitions = cloneDeep(componentDefinitions);
        this.elements = this.componentDefinitionService
          .categorizeComponentDefinitions(this.componentDefinitions)?.filter(componentDefinition => componentDefinition.name === 'elements').flatMap(c => c.data).map(c => c.componentDefinition);
        this.snippets = this.componentDefinitionService
          .categorizeComponentDefinitions(this.componentDefinitions)?.filter(componentDefinition => componentDefinition.name !== 'elements').flatMap(c => c.data).map(c => c.componentDefinition);
        if (this.componentDefinitionService.categorizeComponentDefinitions(this.componentDefinitions)?.length) {
          this.snippetsCardsConfig.menuConfig.items = [{ label: 'All Categories', key: 'all-categories', actionBehavior: MenuActionBehavior.Emit }, ...this.componentDefinitionService.categorizeComponentDefinitions(this.componentDefinitions)?.map(componentDefinition => ({
            label: componentDefinition.title,
            key: componentDefinition.name,
            actionBehavior: MenuActionBehavior.Emit
          }))];
        }

      });
  }

  mapDataToItems(modalDataType, filteredItemsByCategory?) {
    this.snippetsCardsConfig.itemCountPerRow = modalDataType === 'elements' ? ItemPerRow.Four : ItemPerRow.Four;
    this.snippetsCardsConfig.paginationConfig = modalDataType === 'elements' ? { pageIndex: 1, total: 4, pageSize: 8, showPagination: true } : { pageIndex: 1, total: 4, pageSize: 8, showPagination: true };
    this.snippetsCardsConfig.selectConfig.options = modalDataType === 'elements' ? [...new Set(this.elements.flatMap(element => element.tags))].map(tag => ({ key: tag, value: tag })) : [...new Set(this.snippets.flatMap(snippet => snippet.tags))].map(tag => ({ key: tag, value: tag }));
    this.snippetsCardsConfig.items = (filteredItemsByCategory ? filteredItemsByCategory : modalDataType === 'elements' ? this.elements : [...(this.snippets ?? []), ...(this.templatePages ?? [])])?.map(snippet => ({
      data: snippet,
      image: {
        source: snippet?.previewImgUrls?.[0] || null
      },
      titles: [
        {
          type: HeadingType.H4,
          content: snippet.title
        }
      ],
      imageTags: [{}],
      descriptions: snippet.description,
      useDescriptionIfNoImage: snippet?.previewImgUrls?.[0] ? false : true,
      additionalTags: snippet.tags?.map(tag => ({
        text: {
          textMode: TextMode.Text,
          text: tag
        }
      })),
    }));
    if (modalDataType === 'elements') {
      this.snippetsCardsConfig.menuConfig.items = [];
      this.snippetsCardsConfig.selectConfig.visibility = true;
    } else {
      this.snippetsCardsConfig.selectConfig.visibility = false;
      this.snippetsCardsConfig.menuConfig.items = [{ label: 'All Categories', key: 'all-categories', actionBehavior: MenuActionBehavior.Emit }, ...this.componentDefinitionService.categorizeComponentDefinitions(this.componentDefinitions)?.map(componentDefinition => ({
        label: componentDefinition.title,
        key: componentDefinition.name,
        actionBehavior: MenuActionBehavior.Emit
      }))];
    }
    function toggleImageSources(dataArray, preferredTheme) {
      if (preferredTheme === "light") {
        return dataArray;
      }

      return dataArray.map(item => {
        if (item.image && item.image.source) {
          item.image.source = item.image.source.replace(/light(\.(png|jpg|jpeg))$/i, "dark$1");
        }
        return item;
      });
    }
    this.snippetsCardsConfig.items = toggleImageSources(this.snippetsCardsConfig.items, this.preferredTheme);
    this.snippetsCardsConfig = { ...this.snippetsCardsConfig };
  }

  subscribeToComponentDefinitionsLoadingState() {
    return this.store
      .select(state => state.componentDefinition.isComponentDefinitionsLoading)
      .subscribe((isComponentDefinitionsLoading: boolean) => {
        this.isComponentDefinitionsLoading = isComponentDefinitionsLoading;
      });
  }

  subscribeToContentEditorLoadingState() {
    return this.store
      .select(state => state.contentEditor?.loading)
      .subscribe((contentEditorLoading: boolean) => {
        this.isContentEditorLoading = contentEditorLoading;
      });
  }

  subscribeToProjectTheme() {
    return this.store
      .select(state => state.projectTheme?.data)
      .subscribe((projectThemes: ProjectTheme[]) => {
        if (projectThemes?.length) {
          const activeProjectTheme = projectThemes.find(projectTheme => projectTheme.id === this.activeProject.activeProjectThemeId);
          this.projectTheme = {
            ...(activeProjectTheme?.theme ?? {}),
            ...(activeProjectTheme?.customTheme ?? {})
          };
        }
      });
  }

  subscribeToProjectScripts() {
    return this.store
      .select(state => state.projectScript?.data)
      .subscribe((projectScripts: ProjectExternalScriptInterface[]) => {
        this.projectScripts = projectScripts;
        this.getExternalStyleFilesContent();
      });
  }

  subscribeToContainerTemplates() {
    return this.store
      .select(state => state.containerTemplate?.data)
      .subscribe((templates: ContainerTemplateWithRelations[]) => {
        this.containerTemplates = templates ?? [];
      });
  }

  subscribeToCopyContainerTemplatesLoading() {
    return this.store
      .select(state => state.containerTemplate?.copyContainerTemplateLoading)
      .subscribe((loading: boolean) => {
        this.isCopyContainerTemplateLoading = loading;
      });
  }

  subscribeToClipboard() {
    return this.store
      .select(state => state.contentEditor?.clipboard)
      .subscribe((clipboard: ContentEditorClipboardData) => {
        this.clipboardData = clipboard;
      });
  }

  subscribeToDataSchemaLoadingState() {
    return this.store
      .select(state => state.dataSchema?.loading)
      .subscribe((loading: boolean) => {
        this.isDataSchemaLoading = loading;
      });
  }

  subscribeToContentTreeChangesSavedStatus() {
    return this.store
      .select(isContentTreeChangesSaved)
      .subscribe((isContentTreeChangesSaved: boolean) => {
        this.isContentTreeChangesSaved = isContentTreeChangesSaved;
        if (isContentTreeChangesSaved === false) {
          setTimeout(() => this.store.dispatch(new UpdateContentTreeOfPage()), contentTreeAutoSaveTime);
        }
      });
  }

  subscribeToPageLayout() {
    return this.store.select(<any>getActiveLayoutForPage).subscribe((layout: PageWithRelations) => {
      this.layout = layout;
      if (this.layout) {
        this.setContentAreaCssStyle();
      }
    }
    );
  }

  subscribeToLastSavedContentTree() {
    return this.store
      .select(state => state.contentEditor?.lastSavedContentTree)
      .subscribe(lastSavedContentTree => {
        this.lastSavedContentTree = lastSavedContentTree;
      });
  }

  subscribeToUiDataEvents() {
    return this.store.select(state => state.uiDataEvent?.data).subscribe((uiDataEvents: UiDataEventWithRelations[]) => {
      this.uiDataEvents = uiDataEvents;
    });
  }

  // #endregion End of Subscriptions

  // #region Data Subscription

  getActiveDataSchemaWithDetailsById(dataSchemaId: string) {
    if (dataSchemaId) {
      const activeSchema = this.dataSchemas.find(schema => schema.id === dataSchemaId);
      if (activeSchema) {
        this.dataSubscriptionModal.activeDataSchema = activeSchema;
      }
    }
  }

  resetComponentDataSubscriptionModal() {
    this.dataSubscriptionModal.activeUIDataStore = null;
    this.dataSubscriptionModal.activeDataSchema = null;
    this.dataSubscriptionModal.updatingComponentDataSubscription = null;
    this.dataSubscriptionModal.inputDefinition = null;
    this.dataSubscriptionModal.targetDataSchemaFields = null;
    this.dataSubscriptionModal.isLoading = false;
  }

  onDataSubscriptionModalVisibilityChange(isVisible: boolean) {
    this.dataSubscriptionModal.isVisible = isVisible;
    if (!isVisible) {
      this.resetComponentDataSubscriptionModal();
      this.activeDataSchemaId = null;
    }
  }

  onComponentSettingsSelectedUIDataStoreChange(
    uiDataStore: UiDataStore
  ) {
    this.dataSubscriptionModal.activeUIDataStore = uiDataStore;
    this.dataSubscriptionModal.activeDataSchema = null;
    this.activeDataSchemaId = uiDataStore?.dataSchemaId;
    this.getActiveDataSchemaWithDetailsById(this.activeDataSchemaId);
  }

  onComponentDataSubscriptionSubmitted(componentDataSubscriptionPayload) {
    /* set loading as active */
    this.dataSubscriptionModal.isLoading = true;
    if (this.dataSubscriptionModal.mode === ComponentDataSubscriptionModalMode.Create) {
      this.createDataSubscription(componentDataSubscriptionPayload);
    } else if (this.dataSubscriptionModal.mode === ComponentDataSubscriptionModalMode.Update) {
      this.updateDataSubscription(componentDataSubscriptionPayload);
    }
  }

  createDataSubscription(componentDataSubscriptionPayload) {
    this.componentDataSubscriptionApi.advancedCreate({ body: componentDataSubscriptionPayload })
      .subscribe(() => {
        /* load page with components after adding data subscription to the active component */
        this.store.dispatch(new LoadPageWithComponents({ pageId: this.pageId, }));
        /* close component data subscription add modal */
        this.onDataSubscriptionModalVisibilityChange(false);
        /* show success notification */
        this.notificationService.createNotification(
          'success',
          'Success',
          'The data subscription has been created successfully.'
        );
      },
        (error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'There was an error while creating data subscription.'
          );
          this.dataSubscriptionModal.isLoading = false;
        });
  }

  updateDataSubscription(componentDataSubscriptionPayload) {
    const initialArray = this.dataSubscriptionModal.updatingComponentDataSubscription.uiDataStoreFieldSelectors.map(e => ({
      uiDataStoreId: e.uiDataStoreId,
      uiDataStoreSchemaFieldIds: e.uiDataStoreSchemaFieldIds,
      variableName: e.variableName
    }));
    const currentArray = componentDataSubscriptionPayload.uiDataStoreFieldSelectors;
    const created = currentArray.filter(currentArrayItem => !initialArray.some(initialArrayItem => isEqual(initialArrayItem, currentArrayItem)));
    const deleted = initialArray.filter(initialArrayItem => !currentArray.some(currentArrayItem => isEqual(initialArrayItem, currentArrayItem)));
    const deletedIds = deleted?.map(d => this.dataSubscriptionModal.updatingComponentDataSubscription.uiDataStoreFieldSelectors.find(e => isEqual(e.uiDataStoreSchemaFieldIds, d.uiDataStoreSchemaFieldIds) && e.uiDataStoreId === d.uiDataStoreId)?.id) || [];
    const body: UpdateComponentDataSubscriptionDtoPartial = {
      componentId: componentDataSubscriptionPayload.componentId,
      componentInputDefinitionId: componentDataSubscriptionPayload.componentInputDefinitionId,
      componentInputDefinitionNestedFieldIds: componentDataSubscriptionPayload.componentInputDefinitionNestedFieldIds,
      jsonataConfig: componentDataSubscriptionPayload.jsonataConfig,
      createdUIDataStoreFieldSelectors: created,
      deletedUIDataStoreFieldSelectorIds: deletedIds
    };

    this.componentDataSubscriptionApi.advancedUpdateById({ id: this.dataSubscriptionModal.updatingComponentDataSubscription.id, body: body })
      .subscribe(() => {
        /* load page with components after adding data subscription to the active component */
        this.store.dispatch(new LoadPageWithComponents({ pageId: this.pageId, }));
        /* close component data subscription add modal */
        this.onDataSubscriptionModalVisibilityChange(false);
        /* show success notification */
        this.notificationService.createNotification(
          'success',
          'Success',
          'The data subscription has been updated successfully.'
        );
      },
        (error) => {
          this.notificationService.createNotification(
            'error',
            'Error',
            'There was an error while updating data subscription.'
          );
          this.dataSubscriptionModal.isLoading = false;
        });

  }

  onComponentDataSubscriptionModalOpenForCreate() {
    this.onDataSubscriptionModalVisibilityChange(true);
    this.dataSubscriptionModal.mode = ComponentDataSubscriptionModalMode.Create;
    this.dataSubscriptionModal.title = `Add Data Subscription | ${this.activeContentTreeItem.component.componentDefinition.title}.${this.dataSubscriptionModal.inputDefinition.title}${this.dataSubscriptionModal.targetDataSchemaFields?.length ? '.' + this.dataSubscriptionModal.targetDataSchemaFields.map(dsf => dsf.name).join('.') : ''}`;
  }

  onComponentDataSubscriptionModalOpenForEdit() {
    this.onDataSubscriptionModalVisibilityChange(true);
    this.dataSubscriptionModal.mode = ComponentDataSubscriptionModalMode.Update;
    this.dataSubscriptionModal.title = `Edit Data Subscription | ${this.activeContentTreeItem.component.componentDefinition.title}.${this.dataSubscriptionModal.inputDefinition.title}${this.dataSubscriptionModal.targetDataSchemaFields?.length ? '.' + this.dataSubscriptionModal.targetDataSchemaFields.map(dsf => dsf.name).join('.') : ''}`;;
  }

  onComponentDataSubscriptionDelete(
    deletingComponentDataSubscriptionId: string
  ) {
    if (deletingComponentDataSubscriptionId) {
      this.componentDataSubscriptionApi
        .deleteById({ id: deletingComponentDataSubscriptionId })
        .subscribe(
          (response) => {
            /* show success notification */
            this.notificationService.createNotification(
              'success',
              'Success',
              'The data subscription deleted successfully.'
            );
            /* load pages with components to update active component data */
            this.store.dispatch(new LoadPageWithComponents({ pageId: this.pageId, }));
          },
          (error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              'There was an error while deleting the data subscription.'
            );
          }
        );
    }
  }

  onTitleBarDataSubscriptionItemControllerClick(output: { key: string; dataSubscription: ComponentDataSubscriptionWithRelations }) {
    if (output.key === 'delete') {
      this.onComponentDataSubscriptionDelete(output.dataSubscription.id);
    } else if (output.key === 'edit') {
      /* set input definition value */
      this.dataSubscriptionModal.inputDefinition = output.dataSubscription.inputDefinition;
      /* set data schema fields of target input definition  */
      this.dataSubscriptionModal.targetDataSchemaFields = output.dataSubscription.componentInputDefinitionNestedFields;
      this.dataSubscriptionModal.updatingComponentDataSubscription = output.dataSubscription;
      this.onComponentDataSubscriptionModalOpenForEdit(); /* open modal */
    }
  }

  // #endregion End of Data Subscription

  // #region AI Content Config

  openAIContentConfigModal(labelMenuItem) {
    this.aiContentConfigModal.isVisible = true;
    this.aiContentConfigModal.title = 'AI Content Config';
    this.aiContentConfigModal.inputDefinition = labelMenuItem.data.inputDefinition;
    const existingAIContentConfig = this.activeContentTreeItem.component?.aiContentConfig?.find(aiContentConfig =>
      JSON.stringify(aiContentConfig.inputDefinition) === JSON.stringify(this.aiContentConfigModal.inputDefinition)
    );
    this.aiContentConfigModal.data = existingAIContentConfig ? {
      aiUpdateType: existingAIContentConfig.aiUpdateType,
      description: existingAIContentConfig.description
    } : null;
  }

  aiContentConfigModalVisibilityChange(visibility: boolean) {
    this.aiContentConfigModal.isVisible = visibility;
    if (!visibility) {
      this.aiContentConfigModal = {
        data: null,
        inputDefinition: null,
        isVisible: false,
        title: null
      };
    }
  }

  aiContentConfigFormSubmit(formValue) {
    const aiContentConfigs = this.activeContentTreeItem.component.aiContentConfig;
    this.store.dispatch(new UpdateComponent({
      component: {
        id: this.activeContentTreeItem.component.id,
        aiContentConfig: [
          ...(
            aiContentConfigs?.filter(aiContentConfig =>
              JSON.stringify(aiContentConfig.inputDefinition) !== JSON.stringify(this.aiContentConfigModal.inputDefinition)
            ) || []
          ),
          {
            ...formValue,
            inputDefinition: this.aiContentConfigModal.inputDefinition
          }
        ]
      }
    }));
    this.aiContentConfigModal.isVisible = false;
  }

  onAddAIContentConfigClickInDataSubscriptionModal() {
    this.aiContentConfigModal.isVisible = true;
    this.aiContentConfigModal.title = 'AI Content Config';
    const schemaFields = this.dataSubscriptionModal.targetDataSchemaFields;
    const inputDefinition = this.dataSubscriptionModal.inputDefinition;
    this.aiContentConfigModal.inputDefinition = {
      id: inputDefinition.id,
      childDataSchemaFieldIds: schemaFields.map(field => field.id) || [],
      targetNodeFieldType: schemaFields?.length ? schemaFields[0].type.name : inputDefinition.type.type
    };
    const existingAIContentConfig = this.activeContentTreeItem.component?.aiContentConfig?.find(aiContentConfig =>
      JSON.stringify(aiContentConfig.inputDefinition) === JSON.stringify(this.aiContentConfigModal.inputDefinition)
    );

    this.aiContentConfigModal.data = existingAIContentConfig ? {
      aiUpdateType: existingAIContentConfig.aiUpdateType,
      description: existingAIContentConfig.description
    } : null;
  }

  // #endregion AI Content Config

  onComponentSettingsLabelMenuItemClick(labelMenuItem: CrudFormItemMenuItem) {
    if (labelMenuItem.name === 'add-data-subscription') {
      /* set input definition value */
      this.dataSubscriptionModal.inputDefinition = labelMenuItem.data.inputDefinition;
      /* set data schema fields of target input definition  */
      this.dataSubscriptionModal.targetDataSchemaFields = labelMenuItem.data.targetDataSchemaFields;
      this.onComponentDataSubscriptionModalOpenForCreate(); /* open modal */
    } else if (labelMenuItem.name === 'remove-data-subscription') {
      this.onComponentDataSubscriptionDelete(labelMenuItem.data.componentDataSubscriptionId);
    } else if (labelMenuItem.name === 'edit-data-subscription') {
      /* set input definition value */
      this.dataSubscriptionModal.inputDefinition = labelMenuItem.data.inputDefinition;
      /* set data schema fields of target input definition  */
      this.dataSubscriptionModal.targetDataSchemaFields = labelMenuItem.data.targetDataSchemaFields;
      this.dataSubscriptionModal.updatingComponentDataSubscription = labelMenuItem.data.dataSubscription;
      this.onComponentDataSubscriptionModalOpenForEdit(); /* open modal */
    } else if (labelMenuItem.name === 'ai-content-config') {
      this.openAIContentConfigModal(labelMenuItem);
    }
  }

  routeToHomepage() {
    this.routerStateService.navigate(PATH_DEFINITIONS.HOME_PAGE.HOME_PAGE_PATH);
  }

  onLeftSidebarVisibilityChange(visibility: boolean) {
    this.leftSidebarVisibility = visibility;
  }

  updateActiveContentTreeItemWhenContentTreeUpdated() {
    const contentTreeItem = this.contentEditorService
      .findContentTreeItemInContentTree(this.activeContentTreeItem?.id, this.contentTree[0]);
    if (contentTreeItem && contentTreeItem.type === this.ContentEditorContentType.Component && this.activeContentTreeItem?.id !== contentTreeItem?.id) {
      this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId: contentTreeItem.id }));
    }
  }

  /* triggered by the content editor menu */
  onNavigateBackToProject() {
    // const projecHomePageUrl = this.activeProject
    //   ? `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
    //   : PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH;
    this.routerStateService.navigate(FullPaths.Pages);
  }

  calculateActiveNodeToRootPath() {
    if (this.activeContentTreeItem) {
      /*
       * If the selected content is system generated,first find the path of the system generated component.
       * Then find the path again in the tree of the system generated component, and combine these two paths.
       */
      if (this.activeContentTreeItem.parentPageComponentContentId) {
        const parentPageComponent = this.treeService.findNodeInTree(this.contentTree[0], this.activeContentTreeItem.parentPageComponentContentId, 'id', ['children']);
        this.activeNodeToRootPath = this.treeService.findNodeToRootPath(this.contentTree[0], parentPageComponent.id, 'id', ['children'], true)?.reverse();
        this.activeNodeToRootPath.push(...<any>this.treeService.findNodeToRootPath(parentPageComponent.component.componentDefinition.contentTreeItem, this.activeContentTreeItem.id, 'id', ['children'], true)?.reverse());
      } else {
        this.activeNodeToRootPath = this.treeService.findNodeToRootPath(this.contentTree[0], this.activeContentTreeItem.id, 'id', ['children'], true)?.reverse();
      }
    } else {
      this.activeNodeToRootPath = [];
    }
  }

  onClickCommentCard(comment: CommentWithRelations) {
    const pageComment = this.pageComments.find(pageComment => pageComment.commentId === comment.id);

    if (pageComment?.contentTreeItemId && pageComment?.contentTreeItemId !== this.activeContentTreeItem?.id) {
      this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId: pageComment.contentTreeItemId }));
    }
  }

  onCreateComment(comment: NewPageComment) {
    this.store.dispatch(CreatePageComment({ pageComment: comment }));
  }

  onEditComment(comment: CommentWithRelations) {
    this.store.dispatch(UpdatePageComment({ commentId: comment.id, comment }));
  }

  onDeleteComment(comment: PageCommentWithRelations) {
    const activePageComment = this.pageComments.find(pageComment => pageComment.commentId === comment.id);
    this.store.dispatch(DeletePageComment({ pageCommentId: activePageComment.id, commentId: activePageComment.comment.id }));
  }

  copyToClipboard(event: ContentTreeContainer) {
    this.store.dispatch(new CopyActiveContentTemplateToLocalStorage());
  }

  pasteFromClipboard(event: ContentTreeContainer) {
    this.store.dispatch(new PasteTemplateFromLocalStorage());
  }

  activeContentTreeItemChange(event: string) {
    this.rightSidebarVisibility = true;
    this.rightSidebarMenuComponent.showSidebar(this.rightSidebarVisibility);

    const resetSettingsTab = () => {
      setTimeout(() => {
        this.rightSidebarMenuComponent.componentSettingsComponent?.selectedTabIndexChange(0);
      }, 100);
    };
    if (this.activeContentTreeItem?.id !== event) {
      this.activeCommentId = null;
      this.store.dispatch(new RemoveQueryParam({ key: 'activeCommentId' }));
      this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId: event }));
      resetSettingsTab();
    } else if (this.activeContentTreeItem?.id === event) {
      this.activeCommentId = null;
      resetSettingsTab();
    }
    if (this.isClickedCommentIcon) {
      setTimeout(() => {
        // TODO: Focus to comments
        this.rightSidebarMenuComponent.componentSettingsComponent.selectedTabIndexChange(3);
      }, 100);
      this.isClickedCommentIcon = false;
    }
  }

  draggedContentTreeItemDropped(event) {
    this.store.dispatch(new MoveContentTreeItem({ targetContentTreeItem: event.targetContentTreeItem, targetIndex: event.targetIndex, targetPageContainer: event.targetPageContainer }));
  }

  addPageContainer(pageContainerId: string) {
    this.store.dispatch(new CreatePageContainer({ pageContainerId: this.activeContentTreeItem.id }));
  }

  addComponentInPageContainer(pageContainerId: string) {
    this.addComponentModalVisibility = true;
  }

  addElementInPageContainer() {
    this.rightSidebarMenuComponent.isAddComponentDrawerVisible = true;
    this.rightSidebarMenuComponent.onVisibilityChange(true);
    this.addComponentDrawerVisibility = true;
  }

  onAddElementModalVisibilityChange() {
    this.addElementModalVisibility = true;
  }

  contentTreeItemTitleBarDropdownItemClick(event: { item: DropdownMenuItem; contentTreeItem: ContentTreeItem }) {
    switch (event.item.data) {
      case 'delete-container':
        if (this.activeContentTreeItem.type === ContentTreeItemType.Container) {
          if (this.activeContentTreeItem.children?.length) {
            this.deletePageContainerModalVisibility = true;
          } else {
            this.store.dispatch(new DeletePageContainer({
              pageContainerId: this.activeContentTreeItem.id,
              removeStrategy: ContainerRemoveStrategy.All
            }));
          }
        }
        break;
      case 'clear-page':
        if (this.activeContentTreeItem.isMainContainer) {
          this.store.dispatch(new DeletePageContainer({
            pageContainerId: this.activeContentTreeItem.id,
            removeStrategy: ContainerRemoveStrategy.OnlyChildren
          }));
        }
        break;
      case 'create-container':
        this.store.dispatch(new CreatePageContainer({ pageContainerId: this.activeContentTreeItem.id }));
        break;
      case 'create-component':
        this.addComponentModalVisibility = true;
        break;
      case 'paste-from-clipboard':
        this.store.dispatch(new PasteTemplateFromLocalStorage());
        break;
      case 'copy-to-clipboard':
        this.store.dispatch(new CopyActiveContentTemplateToLocalStorage());
        break;
      case 'add-container-visibility':
        this.visibilityConditionModalVisibility = true;
        break;
      case 'delete-component':
        this.store.dispatch(new DeleteComponent({ componentId: this.activeContentTreeItem.id }));
        break;
      case 'add-comment-to-container':
        this.rightSidebarMenuComponent.containerSettingsComponent.commentWrapperComponent.onAddcomment();
        this.rightSidebarMenuComponent.containerSettingsComponent.selectedTabIndexChange(2);
        break;
      case 'add-comment-to-component':
        // RightsidebarMenuComponent needs 100ms to render and apply the tab effect for the component's usage.
        setTimeout(() => {
          this.rightSidebarMenuComponent.componentSettingsComponent.commentWrapperComponent.onAddcomment();
          this.rightSidebarMenuComponent.componentSettingsComponent.selectedTabIndexChange(3);
        }, 100);
        break;
    }
  }

  contentTreeItemTitleBarButtonClick(event: { button: ButtonComponentConfig; contentTreeItem: ContentTreeItem }) {
    this.selectedElementOnTitlebar = { button: event.button, content: event.contentTreeItem };
    switch (event.button.key) {
      case 'container-visibility':
        this.visibilityConditionModalVisibility = true;
        break;
      case 'see-container-comments':
        this.rightSidebarMenuComponent.containerSettingsComponent.commentWrapperComponent.onAddcomment();
        this.rightSidebarMenuComponent.containerSettingsComponent.selectedTabIndexChange(2);
        break;
      case 'see-component-comments':
        this.rightSidebarMenuComponent.componentSettingsComponent.commentWrapperComponent.onAddcomment();
        this.rightSidebarMenuComponent.componentSettingsComponent.selectedTabIndexChange(3);
        break;
    }
  }

  removeStyleTags() {
    document.getElementById('external-styles')?.remove();
    document.getElementById('page-styles')?.remove();
    document.getElementById('global-styles')?.remove();
  }

  // #region Page Styles


  /**
   * this function clear css style of the content area and
   * re-set content area css style by page css style and layout css style if exists
   */
  setContentAreaCssStyle() {
    document.getElementById('page-styles')?.remove();
    this.domManagementService.appendStyleTagToHead('page-styles');
    const pageStylesElement = document.getElementById('page-styles');
    let cssStyle = '';
    if (this.layout) {
      cssStyle += (this.layout.cssStyle ?? '') + '\n';
    }
    cssStyle += (this.page.cssStyle ?? '');
    pageStylesElement.appendChild(document.createTextNode(cssStyle));
  }

  // #endregion Page Styles

  // #region Global Styles

  createGlobalStyleElement() {
    document.getElementById('global-styles')?.remove();
    this.domManagementService.appendStyleTagToHead('global-styles');
  }

  appendGlobalStyleToGlobalStyleElement() {
    this.createGlobalStyleElement();
    const globalStyleElement = document.getElementById('global-styles');
    globalStyleElement?.appendChild(document.createTextNode(this.activeProject.cssStyle));
  }

  // #endregion Global Styles

  // #region External Styles

  /**
   * creates style tag dynamically for external styles
   *
   * @memberof RenderedPageComponent
   */
  createExternalStyleElement() {
    document.getElementById('external-styles')?.remove();
    this.domManagementService.appendStyleTagToHead('external-styles');
  }

  getExternalStyleFilesContent() {
    this.createExternalStyleElement();
    this.projectScripts?.forEach(projectScript => {
      if (projectScript.type === ExternalScriptType.Style) {
        if (projectScript.sourceType === ProjectScriptTypeOptionsEnum.File) {
          this.projectFileApi.getFileContent({ projectFileId: projectScript.projectFileId }).subscribe((content: any) => {
            this.appendExternalStyleToExternalStyleElement(content);
          });
        } else if (projectScript.sourceType === ProjectScriptTypeOptionsEnum.Custom) {
          this.appendExternalStyleToExternalStyleElement(projectScript.content);
        }
      }
    });
  }

  appendExternalStyleToExternalStyleElement(externalStyle) {
    const externalStyleElement = document.getElementById('external-styles');
    externalStyleElement?.appendChild(document.createTextNode(externalStyle));
  }

  // #endregion External Styles

  onPreviewModeChange(mode: boolean) {
    this.previewMode = mode;
    if (this.previewMode) {
      this.rightSidebarVisibility = false;
    } else {
      this.rightSidebarVisibility = true;
      this.rightSidebarMenuComponent.showSidebar(this.rightSidebarVisibility);
    }
  }

  onSidebarSizeChange(newSize: number, newMinSize: number) {
    this.rightSidebarSize = newSize;
    this.rightSidebarMinSize = newMinSize;
  }

  onClickToCommentIcon() {
    this.isClickedCommentIcon = true;
  }

  // If you choose a page from the sidebar menu to change the page, its corresponding pageId is passed to this function.
  // This function works similar to onDestroy function. Additionally, it adds the targetPageId information to the payload along with onDestroy.
  onPageSelected(pageId) {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
    this.store.dispatch(new ContentEditorDestroy({
      pageId: this.pageId,
      contentTree: this.contentTree,
      lastSavedContentTree: this.lastSavedContentTree,
      targetPageId: pageId
    }));
    this.removeStyleTags();
  }

  onIsCommentActiveEmit(isCommentActive) {
    this.isCommentActive = isCommentActive;
  }

  onDropdownMenuItemClick(event: { item: DropdownMenuItem; contentTreeItem: ContentTreeItem }) {
    this.contentTreeItemTitleBarDropdownItemClick(event);
  }

  onContainerDeleteModalVisibility(visibility: boolean) {
    if (this.activeContentTreeItem.type === ContentTreeItemType.Container) {
      if (this.activeContentTreeItem.children?.length) {
        this.deletePageContainerModalVisibility = visibility;
      } else {
        this.store.dispatch(new DeletePageContainer({
          pageContainerId: this.activeContentTreeItem.id,
          removeStrategy: ContainerRemoveStrategy.All
        }));
      }
    }
  }

  splitDragEnds(event) {
    console.log('splitDragEnds');

  }

  addComponentDrawerClosed() {
    this.addComponentDrawerVisibility = false;
  }

  onAddElementDrawerVisibilityChanged(visibility: boolean) {
    this.addComponentDrawerVisibility = false;
    this.mapDataToItems('elements');
    this.componentsAndSnippetsModalVisibility = true;
  }

  onAddElementDrawerClosed() {
    this.closeAddComponentDrawer();
  }

  onAddComponentButtonClicked(modalDataType) {
    this.addComponentDrawerVisibility = true;
  }

  onAddComponentAndSnippetsButtonClicked(modalDataType) {
    this.mapDataToItems(modalDataType);
  }

  closeAddComponentDrawer() {
    this.addComponentDrawerVisibility = false;
  }

  onAddComponentWithPosition(addComponentWithPosition: AddComponentWithPositionInterface) {
    // TODO: Implement the action  @alifuatcalik
    // this.store.dispatch(new AddComponentWithPosition(addComponentWithPosition));
    this.addComponentDrawerVisibility = true;
  }

  onSnippetSelectClick(cardData) {
    if (cardData?.data?.data?.type === 'page') {
      this.store.dispatch(new GetTemplateById({ id: cardData.item.data.id }));
    } else {
      this.store.dispatch(new CreateComponent({
        componentDefinitionId: cardData.item.data.id,
        title: cardData.item.data.title,
        inputs: cardData.item.data.defaultInputs
      }));
    }
    this.componentsAndSnippetsModalVisibility = false;
  }

  onComponentsAndSnippetsModalVisibility(visible) {
    this.componentsAndSnippetsModalVisibility = visible;
  }

  onFilterMenuItemClick(menuItem) {
    const filteredItemsByCategory = this.componentDefinitionService
      .categorizeComponentDefinitions(this.componentDefinitions)?.filter(componentDefinition => menuItem.key === 'all-categories' ? componentDefinition.name !== 'elements' : componentDefinition.name === menuItem.key).flatMap(c => c.data).map(c => c.componentDefinition);
    this.mapDataToItems('snippets', filteredItemsByCategory);
  }

  onTopButtonsClick(buttons) {
    if (buttons.key === PreviewGridType.Row) {
      this.snippetsCardsConfig.itemCountPerRow = ItemPerRow.One;
      this.snippetsCardsConfig.paginationConfig = { pageIndex: 1, total: 2, pageSize: 2, showPagination: true };
    } else if (buttons.key === PreviewGridType.TwoColumns) {
      this.snippetsCardsConfig.itemCountPerRow = ItemPerRow.Two;
      this.snippetsCardsConfig.paginationConfig = { pageIndex: 1, total: 2, pageSize: 4, showPagination: true };
    } else if (buttons.key === PreviewGridType.FourColumns) {
      this.snippetsCardsConfig.itemCountPerRow = ItemPerRow.Four;
      this.snippetsCardsConfig.paginationConfig = { pageIndex: 1, total: 2, pageSize: 8, showPagination: true };
    }
  }

  allPanelsActiveEmit(output) {
    this.allPanelsActive = output;
  }

}

