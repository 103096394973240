import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { IconType } from '@rappider/rappider-components/utils';
import { DeletePageContainer } from 'libs/content-editor/src/lib/state/content-editor.actions';
import { ContentTreeContainer } from 'libs/content-tree-renderer/src/lib/models';
import { ContainerRemoveStrategy } from '../../models/container-remove-strategy.enum';

@Component({
  selector: 'rappider-delete-page-container',
  templateUrl: './delete-page-container.component.html',
  styleUrls: ['./delete-page-container.component.scss']
})
export class DeletePageContainerComponent {

  @Input() pageContainer: ContentTreeContainer;
  @Input() deletePageContainerModalVisibility = false;

  @Output() deletePageContainerModalVisibilityChange = new EventEmitter<boolean>();

  ContainerRemoveStrategy = ContainerRemoveStrategy;

  constructor(private store: Store<any>) { }

  openDeletePageContainerModal() {
    this.deletePageContainerModalVisibility = true;
    this.deletePageContainerModalVisibilityChange.emit(this.deletePageContainerModalVisibility);
  }

  closeDeletePageContainerModal() {
    this.deletePageContainerModalVisibility = false;
    this.deletePageContainerModalVisibilityChange.emit(this.deletePageContainerModalVisibility);
  }

  deleteContainer(removeStrategy: ContainerRemoveStrategy) {
    this.store.dispatch(new DeletePageContainer({
      pageContainerId: this.pageContainer.id,
      removeStrategy: removeStrategy
    }));
    this.closeDeletePageContainerModal();
  }

  onModalVisibilityChange(visibility: boolean) {
    this.deletePageContainerModalVisibilityChange.emit(visibility);
  }

}
