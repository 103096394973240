import { Component, Input } from '@angular/core';
import { WorkflowStepFunctionDataTransformationComponentMode } from '../../models/workflow-step-function-data-transformation-component-mode.enum';

@Component({
  selector: 'rappider-workflow-step-function-data-transformation',
  templateUrl: './workflow-step-function-data-transformation.component.html',
  styleUrls: ['./workflow-step-function-data-transformation.component.scss']
})
export class WorkflowStepFunctionDataTransformationComponent {
  @Input() isLoading: boolean;
  @Input() dataTransformationId: string;
  @Input() sourceSchema: any;
  @Input() targetSchema: any;
}
