/* eslint-disable no-empty-function */
/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { MetadataInterface } from '@rappider/api-sdk';
import { NewPage, Page, PagePartial, PageWithRelations } from '@rappider/rappider-sdk';

export enum ActionTypes {
  CreateLayout = '[Layout] CreateLayout',
  CreateLayoutSuccessful = '[Layout] CreateLayoutSuccessful',
  CreateLayoutFailure = '[Layout] CreateLayoutFailure',
  GetLayouts = '[Layout] GetLayouts',
  GetLayoutsSuccessful = '[Layout] GetLayoutsSuccessful',
  GetLayoutsFailure = '[Layout] GetLayoutsFailure',
  GetLayoutById = '[Layout] GetLayoutById',
  GetLayoutByIdSuccessful = '[Layout] GetLayoutByIdSuccessful',
  UpdateLayout = '[Layout] UpdateLayout',
  UpdateLayoutSuccessful = '[Layout] UpdateLayoutSuccessful',
  UpdateLayoutFailure = '[Layout] UpdateLayoutFailure',
  UpdateContentTreeOfLayout = '[Layout] UpdateContentTreeOfLayout',
  DeleteLayout = '[Layout] DeleteLayout',
  DeleteLayoutSuccessful = '[Layout] DeleteLayoutSuccessful',
  DeleteLayoutFailure = '[Layout] DeleteLayoutFailure',
  ChangeDefaultLayout = '[Layout] ChangeDefaultLayout',
  ChangeDefaultLayoutSuccessful = '[Layout] ChangeDefaultLayoutSuccessful',
  ChangeDefaultLayoutFailure = '[Layout] ChangeDefaultLayoutFailure',
}

export class CreateLayout implements Action {
  readonly type = ActionTypes.CreateLayout;
  constructor(public payload: { layout: NewPage }) { }
}

export class CreateLayoutSuccessful implements Action {
  readonly type = ActionTypes.CreateLayoutSuccessful;
  constructor(public payload: { layout: Page }) { }
}

export class CreateLayoutFailure implements Action {
  readonly type = ActionTypes.CreateLayoutFailure;
  constructor(public payload: { error: any; key: string; timestamp: any }) { }
}

export class GetLayouts implements Action {
  readonly type = ActionTypes.GetLayouts;
}

export class GetLayoutsSuccessful implements Action {
  readonly type = ActionTypes.GetLayoutsSuccessful;
  constructor(public payload: { layouts: PageWithRelations[] }) { }
}

export class GetLayoutsFailure implements Action {
  readonly type = ActionTypes.GetLayoutsFailure;
  constructor(public payload: { error: any; key: string; timestamp: any }) { }
}

export class GetLayoutById implements Action {
  readonly type = ActionTypes.GetLayoutById;
  constructor(public payload: { layoutId: string }) { }
}

export class GetLayoutByIdSuccessful implements Action {
  readonly type = ActionTypes.GetLayoutByIdSuccessful;
  constructor(public payload: { layout: PageWithRelations }) { }
}

export class UpdateLayout implements Action {
  readonly type = ActionTypes.UpdateLayout;
  constructor(public payload: {
    layout: PagePartial;
    layoutId: string;
  }) { }
}

export class UpdateLayoutSuccessful implements Action {
  readonly type = ActionTypes.UpdateLayoutSuccessful;
  constructor(public payload: { layout: Page }) { }
}

export class UpdateContentTreeOfLayout implements Action {
  readonly type = ActionTypes.UpdateContentTreeOfLayout;
  constructor(public payload: {
    layoutId: string;
    contentTree: any;
  }) { }
}

export class UpdateLayoutFailure implements Action {
  readonly type = ActionTypes.UpdateLayoutFailure;
  constructor(public payload: { error: any; key: string; timestamp: any }) { }
}

export class DeleteLayout implements Action {
  readonly type = ActionTypes.DeleteLayout;
  constructor(public payload: { layoutId: string }) { }
}

export class DeleteLayoutSuccessful implements Action {
  readonly type = ActionTypes.DeleteLayoutSuccessful;
  constructor(public payload: { layoutId: string }) { }
}

export class DeleteLayoutFailure implements Action {
  readonly type = ActionTypes.DeleteLayoutFailure;
  constructor(public payload: { error: any; key: string; timestamp: any }) { }
}

export class ChangeDefaultLayout implements Action {
  readonly type = ActionTypes.ChangeDefaultLayout;
  constructor(public payload: { layoutId: string }) { }
}

export class ChangeDefaultLayoutSuccessful implements Action {
  readonly type = ActionTypes.ChangeDefaultLayoutSuccessful;
}

export class ChangeDefaultLayoutFailure implements Action {
  readonly type = ActionTypes.ChangeDefaultLayoutFailure;
  constructor(public payload: { error: any; key: string; timestamp: any }) { }
}

export type Actions =
  CreateLayout
  | CreateLayoutSuccessful
  | CreateLayoutFailure
  | GetLayouts
  | GetLayoutsSuccessful
  | GetLayoutsFailure
  | GetLayoutById
  | GetLayoutByIdSuccessful
  | UpdateLayout
  | UpdateLayoutSuccessful
  | UpdateLayoutFailure
  | UpdateContentTreeOfLayout
  | DeleteLayout
  | DeleteLayoutSuccessful
  | DeleteLayoutFailure
  | ChangeDefaultLayout
  | ChangeDefaultLayoutSuccessful
  | ChangeDefaultLayoutFailure
  ;
