import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  alignContentHorizontal,
  alignContentHorizontalReverse,
  alignContentVertical,
  alignContentVerticalReverse,
  alignItemsHorizantal,
  alignItemsHorizantalReverse,
  alignItemsVertical,
  alignItemsVerticalReverse,
  containerSettingsConfig,
  containerType,
  justifyContentHorizantal,
  justifyContentHorizantalReverse,
  justifyContentVertical,
  justifyContentVerticalReverse
} from '../container-settings-config';
import { ContentTreeContainer, DefaultFlexOptions, FlexDirectionOption, FlexWrapOption, defaultContainerWidth } from 'libs/content-tree-renderer/src/lib/models';
import { ButtonComponentConfig } from '@rappider/rappider-components/utils';
import { PageVariable, UpdateContainerIterativeDataConfigDto } from '@rappider/rappider-sdk';
import { LIST_DATA_SOURCE_CONFIG } from './config/container-list-data-source.config';
import { NotificationService } from '@rappider/services';
import { ContentEditorSettingsSection } from '../../../constants/enums';

@Component({
  selector: 'rappider-container-layout-settings',
  templateUrl: './container-layout-settings.component.html',
  styleUrls: ['./container-layout-settings.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ContainerLayoutSettingsComponent),
      multi: true
    }
  ]
})
export class ContainerLayoutSettingsComponent implements OnChanges, ControlValueAccessor {

  @Input() pageVariables: PageVariable[];
  @Input() allPanelsActive?: boolean = false;
  @Input() visiblePanelKeys?: string[] = [];

  ContentEditorSettingsSection = ContentEditorSettingsSection;

  settingConfigs = containerSettingsConfig;
  /**
   * To handle the wrap-reverse checkbox disabled state
   *
   * @memberof ContainerLayoutSettingsComponent
   */
  isWrapReverseCheckboxDisabled = false;
  /**
   * using for handle flex-wrap checkbox value
   *
   * @type {string}
   * @memberof ContainerLayoutSettingsComponent
   */
  localFlexWrap: string;
  /**
   * using for handle wrap-reverse checkbox value
   *
   * @type {boolean}
   * @memberof ContainerLayoutSettingsComponent
   */
  localWrapReverse: boolean;
  /**
   * using for handle flex-direction value
   *
   * @type {string}
   * @memberof ContainerLayoutSettingsComponent
   */
  localFlexDirection: string;
  /**
   * using for handle flex-direction-reverse checkbox value
   *
   * @type {boolean}
   * @memberof ContainerLayoutSettingsComponent
   */
  localDirectionReverse: boolean;

  containerType = containerType;
  containerTypeValue: string;

  LIST_DATA_SOURCE_CONFIG = LIST_DATA_SOURCE_CONFIG;

  listDataSourceModalVisibility = false;
  listDataSourceButton: ButtonComponentConfig = {
    key: 'list-data-source',
    text: 'List Data Source',
  };
  iterativeContainerFormData: UpdateContainerIterativeDataConfigDto;

  _value: ContentTreeContainer;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageVariables && changes.pageVariables.currentValue) {
      this.setGridDataForListDataSourceModal();
    }
  }

  get value(): ContentTreeContainer {
    return this._value;
  }

  set value(value: ContentTreeContainer) {
    this.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  setValue(value) {
    this._value = {
      ...value,
      displayMode: value?.displayMode,
      flexOptions: {
        flexAlignContent: value?.flexOptions?.flexAlignContent || DefaultFlexOptions.flexAlignContent,
        flexAlignItems: value?.flexOptions?.flexAlignItems || DefaultFlexOptions.flexAlignItems,
        flexDirection: value?.flexOptions?.flexDirection || DefaultFlexOptions.flexDirection,
        flexJustifyContent: value?.flexOptions?.flexJustifyContent || DefaultFlexOptions.flexJustifyContent,
        flexWrap: value?.flexOptions?.flexWrap || DefaultFlexOptions.flexWrap
      },
      columnWidth: value?.columnWidth || defaultContainerWidth,
      isIterative: value?.isIterative,
      iterativeDataConfig: value?.iterativeDataConfig
    };
    this.setLocalFlexDirectionAndLocalDirectionReverseValue();
    this.setLocalFlexWrapAndLocalWrapReverseValue();
    this.setFlexOptionIcons();
    this.setContainerType();
    this.setIterativeContainerFormData();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value): void {
    this.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * If flex-direction includes reverse, it splits the flex-direction value into two parts to
   * manage the checkbox and radio buttons. (direction => row | col && reverse => true | false)
   *
   * @memberof ContainerLayoutSettingsComponent
   */
  setLocalFlexDirectionAndLocalDirectionReverseValue() {
    const flexDirection = this.value?.flexOptions?.flexDirection;
    if (flexDirection?.includes('reverse')) {
      this.localDirectionReverse = true;
      this.localFlexDirection = flexDirection?.split('-')[0];
    } else {
      this.localDirectionReverse = false;
      this.localFlexDirection = flexDirection;
    }
  }

  setContainerType() {
    if (this.value?.isIterative) {
      this.containerTypeValue = 'list-container';
    } else {
      this.containerTypeValue = 'container';
    }
  }

  setIterativeContainerFormData() {
    if (this.value.iterativeDataConfig) {
      this.iterativeContainerFormData = this.value.iterativeDataConfig;
    } else {
      this.iterativeContainerFormData = null;
    }
  }

  /**
   * If flex-wrap includes reverse, it splits the flex-wrap value into two parts to
   * manage the checkbox and radio buttons. (wrap => wrap | nowrap && reverse => true | false)
   *
   * @memberof ContainerLayoutSettingsComponent
   */
  setLocalFlexWrapAndLocalWrapReverseValue() {
    const flexWrap = this.value?.flexOptions?.flexWrap;
    if (flexWrap === FlexWrapOption.WrapReverse) {
      this.localWrapReverse = true;
      this.isWrapReverseCheckboxDisabled = false;
      this.localFlexWrap = flexWrap?.split('-')[0];
    } else if (flexWrap === FlexWrapOption.Nowrap) {
      /** If the flex wrap value is nowrap,
       * the wrap-reverse checkbox is blocked because reverse cannot be performed,
       * and the localWrapReverse value is set to false.
       */
      this.localWrapReverse = false;
      this.isWrapReverseCheckboxDisabled = true;
      this.localFlexWrap = flexWrap;
    } else {
      this.localWrapReverse = false;
      this.isWrapReverseCheckboxDisabled = false;
      this.localFlexWrap = flexWrap;
    }
  }

  /**
   * Flex-reverse information that was previously fragmented to manage checkbox and radio components,
   * added back to flex-direction.
   *
   * @memberof ContainerLayoutSettingsComponent
   */
  onLocalDirectionValueChange() {
    this.value.flexOptions = {
      ...this.value.flexOptions,
      flexDirection: <FlexDirectionOption>(this.localDirectionReverse ? this.localFlexDirection + '-reverse' : this.localFlexDirection)
    };
    this.triggerChangeDetection();
  }

  onLocalFlexWrapAndWrapReverseValueChange() {
    /* disable wrap-reverse checkbox if localFlexWrap value is nowrap */
    if (this.localFlexWrap === FlexWrapOption.Nowrap) {
      this.localWrapReverse = false;
      this.isWrapReverseCheckboxDisabled = true;
    } else {
      this.isWrapReverseCheckboxDisabled = false;
    }

    /*
     * set flex wrap value by wrap-reverse data
     * set flex align content value as null if wrap is null
     */
    this.value.flexOptions = {
      ...this.value.flexOptions,
      flexWrap: <FlexWrapOption>(this.localWrapReverse ? this.localFlexWrap + '-reverse' : this.localFlexWrap),
      flexAlignContent: this.localFlexWrap === FlexWrapOption.Nowrap ? null : this.value.flexOptions.flexAlignContent
    };

    this.triggerChangeDetection();
  }


  triggerChangeDetection() {
    this.value = { ...this.value };
  }

  /**
   * set flex icons by direction/reverse status
   *
   * @memberof ContainerLayoutSettingsComponent
   */
  setFlexOptionIcons() {
    switch (this.value.flexOptions.flexDirection) {
      case FlexDirectionOption.Horizantal: {
        this.settingConfigs.alignItems = alignItemsHorizantal;
        this.settingConfigs.justifyContent = justifyContentHorizantal;
        if (this.value.flexOptions.flexWrap === FlexWrapOption.WrapReverse) {
          this.settingConfigs.alignContent = alignContentHorizontalReverse;
        } else {
          this.settingConfigs.alignContent = alignContentHorizontal;
        }
        break;
      }
      case FlexDirectionOption.HorizantalReverse: {
        this.settingConfigs.alignItems = alignItemsHorizantalReverse;
        this.settingConfigs.justifyContent = justifyContentHorizantalReverse;
        if (this.value.flexOptions.flexWrap === FlexWrapOption.WrapReverse) {
          this.settingConfigs.alignContent = alignContentHorizontalReverse;
        } else {
          this.settingConfigs.alignContent = alignContentHorizontal;
        }
        break;
      }
      case FlexDirectionOption.Vertical: {
        this.settingConfigs.alignItems = alignItemsVertical;
        this.settingConfigs.justifyContent = justifyContentVertical;
        if (this.value.flexOptions.flexWrap === FlexWrapOption.WrapReverse) {
          this.settingConfigs.alignContent = alignContentVerticalReverse;
        } else {
          this.settingConfigs.alignContent = alignContentVertical;
        }
        break;
      }
      case FlexDirectionOption.VerticalReverse: {
        this.settingConfigs.alignItems = alignItemsVerticalReverse;
        this.settingConfigs.justifyContent = justifyContentVerticalReverse;
        if (this.value.flexOptions.flexWrap === FlexWrapOption.WrapReverse) {
          this.settingConfigs.alignContent = alignContentVerticalReverse;
        } else {
          this.settingConfigs.alignContent = alignContentVertical;
        }
        break;
      }
      default:
        break;
    };
  }

  setGridDataForListDataSourceModal() {
    const selectItem = this.LIST_DATA_SOURCE_CONFIG.items.find(item => item.fieldName === 'pageDataVariableId');
    selectItem.options = this.pageVariables.map(pageVariable => ({
      key: pageVariable.variableName,
      value: pageVariable.id
    }));
    selectItem.index = 0;
    this.LIST_DATA_SOURCE_CONFIG = {
      ...this.LIST_DATA_SOURCE_CONFIG,
      items: [
        ...this.LIST_DATA_SOURCE_CONFIG.items.filter(item => item.fieldName !== 'pageDataVariableId'),
        selectItem
      ]
    };
  }

  onContainerTypeChange(type: string) {
    this.value.isIterative = type === 'list-container' ? true : false;
    this.triggerChangeDetection();
  }

  onListDataSourceButtonClick() {
    this.setIterativeContainerFormData();
    this.listDataSourceModalVisibility = true;
  }

  onListDataSourceModalCancel() {
    this.listDataSourceModalVisibility = false;
    this.iterativeContainerFormData = null;
  }

  onListDataSourceModalOk() {
    if (this.iterativeContainerFormData && this.iterativeContainerFormData.pageDataVariableId != null) {
      this.value.iterativeDataConfig = {
        ...this.iterativeContainerFormData,
        dataItemVariableName: this.iterativeContainerFormData.dataItemVariableName || 'dataItem'
      };
      this.listDataSourceModalVisibility = false;
      this.triggerChangeDetection();
    } else {
      this.notificationService.createNotification(
        'error',
        'Error',
        'You should select Page Variable'
      );
    }
  }

  onIterativeContainerDataFormChange(formData: UpdateContainerIterativeDataConfigDto) {
    this.iterativeContainerFormData = {
      pageDataVariableId: formData.pageDataVariableId,
      dataItemVariableName: formData.dataItemVariableName
    };
  }
}
