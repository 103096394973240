import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[rappiderArrowHighlight]'
})
export class ArrowHighlightDirective implements AfterViewInit, OnDestroy {
  @Input() arrowColor?: string;

  private arrowElement: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    console.log(el);
  }

  ngAfterViewInit() {
    if (this.el?.nativeElement) {
      this.addArrow();
    }
  }

  ngOnDestroy() {
    this.removeArrow();
  }

  addArrow() {
    // Create the arrow container dynamically
    this.arrowElement = this.renderer.createElement('div');
    this.renderer.addClass(this.arrowElement, 'arrow-highlight');

    // Add the Font Awesome arrow icon
    const iconElement = this.renderer.createElement('i');
    this.renderer.addClass(iconElement, 'fa-solid');
    this.renderer.addClass(iconElement, 'fa-down');
    this.renderer.appendChild(this.arrowElement, iconElement);

    // Append the arrow container to the body
    this.renderer.appendChild(document.body, this.arrowElement);


    // Position the arrow next to the highlighted element
    const rect = this.el.nativeElement.getBoundingClientRect();
    this.renderer.setStyle(this.arrowElement, 'position', 'absolute');
    this.renderer.setStyle(this.arrowElement, 'top', `${rect.top - 50}px`); // Adjust as needed
    this.renderer.setStyle(this.arrowElement, 'left', `${rect.left + rect.width / 2 - 10}px`);
    if (this.arrowColor) {
      this.renderer.setStyle(this.arrowElement, 'color', this.arrowColor);
    }
  }

  removeArrow() {
    if (this.arrowElement) {
      this.renderer.removeChild(document.body, this.arrowElement);
    }
  }
}
