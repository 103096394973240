<!-- WIZARD -->
<div class="wizard-container">

  <!-- CARD CONTAINER -->
  <nz-card class="wizard-card-container">

    <ng-container *ngIf="lastRenderedProjectId ? lastRenderedProjectId === activeProjectId : activeProjectId">
      <!-- BODY -->
      <div class="wizard-body">

        <!-- HEADER -->
        <div class="wizard-header">

          <!-- RABBIT -->
          <div *ngIf="displayRabbitOnHeader"
               class="rabbit-container">
            <img src="/assets/wr/wr-welcome.gif"
                 alt="Rabbit"
                 class="rabbit">
          </div>

          <!-- HEADER CONTENT -->
          <div class="wizard-header-content">
            <div class="wizard-header-content">
              <div class="wizard-content-markdown-container larger-font">
                <rappider-markdown-viewer [typeInRealTime]="true"
                                          (realtimeTypingCompleted)="onHeaderRealtimeTypingCompleted($event)"
                                          [markdownText]="wizardStepsConfigs[activeWizardStep]?.introduction"></rappider-markdown-viewer>
                <!-- [customMarkdownClass]="'no-padding'" -->
              </div>
            </div>
          </div>

        </div>

        <!-- CONTENT -->
        <div class="wizard-content">
          <div class="wizard-content-markdown-container">
            <ng-container *ngIf="displayExtraContent[this.activeWizardStep]">
              <rappider-markdown-viewer [typeInRealTime]="true"
                                        [typingSpeed]="1"
                                        (realtimeTypingCompleted)="onExtraContentRealtimeTypingCompleted($event)"
                                        [markdownText]="wizardStepsConfigs[activeWizardStep]?.introductionExtra"></rappider-markdown-viewer>
              <!-- [customMarkdownClass]="'no-padding'" -->
            </ng-container>
          </div>
        </div>

      </div>

      <!-- FOOTER - Hidden since we're auto-progressing after typing -->
      <!-- <div class="wizard-footer">
        <div class="footer-left">
        </div>
        <div class="footer-right">
          <button [id]="ProjectDesignSettingButtons.WizardNextButton"
                  nz-button
                  nzType="primary"
                  (click)="onWizardNextStepButtonClicked()">
                  {{wizardStepsConfigs[activeWizardStep]?.introductionNextButtonTitle ?? 'Next'}}
          </button>
        </div>
      </div> -->
    </ng-container>

    <nz-skeleton *ngIf="lastRenderedProjectId ? lastRenderedProjectId !== activeProjectId : !activeProjectId"
                 [nzActive]="true"
                 [nzAvatar]="true"
                 [nzParagraph]="{rows: 8}"></nz-skeleton>
  </nz-card>
</div>
