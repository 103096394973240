import { TreeService } from 'libs/shared/src/lib/services/tree-service/tree.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ComponentDefinition, ComponentOutputEvent, ComponentOutputEventPartial, UpdateContainerPropertiesDto } from '@rappider/rappider-sdk';
import { v4 } from 'uuid';
import { defaultContainer } from 'libs/content-tree-renderer/src/lib/models/default-container';
import { ContentTreeItem, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';
import { ContainerRemoveStrategy } from '../models/container-remove-strategy.enum';
import { cloneDeep } from 'lodash';
import { CopyContainer } from './content-editor.actions';

const treeService = new TreeService();

export function moveContentTreeItem(contentTree, targetPageContainerId: string, targetContentTreeItemId: string, targetIndex: number) {
  const targetPageContainer = treeService.findNodeInTree(contentTree[0], targetPageContainerId, 'id', ['children'], false);
  const targetContentTreeItem = treeService.findNodeInTree(contentTree[0], targetContentTreeItemId, 'id', ['children'], true);
  targetPageContainer.children.push(targetContentTreeItem.node);
  const index = targetContentTreeItem.parentNode.children.map(e => e.id).indexOf(targetContentTreeItemId);
  targetContentTreeItem.parentNode.children.splice(index, 1);
  moveItemInArray(targetPageContainer.children, targetPageContainer.children.length - 1, targetIndex);
}

export function getChildComponentIds(contentTree, nodeId): string[] {
  const componentIds = [];
  const node = treeService.findNodeInTree(contentTree[0], nodeId, 'id', ['children'], false);
  function findComponentIdsRecursively(node) {
    node.children?.forEach(childNode => {
      if (childNode.type === ContentTreeItemType.Component) {
        componentIds.push(childNode.componentId);
      } else if (childNode.children?.length) {
        childNode.children.forEach(child => findComponentIdsRecursively(child));
      }
    });
  }
  if (node?.children?.length) {
    findComponentIdsRecursively(node);
  }
  return componentIds;
}

export function deletePageContainer(contentTree, pageContainerId: string, removeStrategy: ContainerRemoveStrategy) {
  const pageContainerWithParentNode = treeService.findNodeInTree(contentTree[0], pageContainerId, 'id', ['children'], true);
  const parentNode = pageContainerWithParentNode.parentNode;
  const nodeToRemove = pageContainerWithParentNode.node;
  const index = parentNode?.children?.map(e => e.id)?.indexOf(pageContainerId);

  let componentIdsToRemove: string[] = [];

  if (removeStrategy === ContainerRemoveStrategy.MoveChildrenToParent) {
    // Place the children in the position of the removed container
    if (index !== -1) {
      // Use splice to insert the children at the current position
      parentNode.children.splice(index, 1, ...nodeToRemove.children);
    }
  } else if (removeStrategy === ContainerRemoveStrategy.All) {
    // Remove the container and all of its children
    componentIdsToRemove = getChildComponentIds(contentTree, pageContainerId);
    parentNode.children.splice(index, 1);
  } else if (removeStrategy === ContainerRemoveStrategy.OnlyChildren) {
    // Remove only the children, keep the container
    componentIdsToRemove = getChildComponentIds(contentTree, pageContainerId);
    nodeToRemove.children = [];
  }

  return componentIdsToRemove;
}

export function updatePageContainerProperties(contentTree, pageContainerId: string, properties: UpdateContainerPropertiesDto) {
  const updatedContainer = treeService.findNodeInTree(contentTree[0], pageContainerId, 'id', ['children'], false);
  Object.assign(updatedContainer, properties);
}

export function createPageContainer(contentTree, pageContainerId: string, pageContainerData) {
  let containerIndex = 0;
  let isContainerNameExist;
  do {
    containerIndex++;
    isContainerNameExist = treeService.findNodeInTree(contentTree[0], `Container ${containerIndex}`, 'title', ['children'], false);
  }
  while (isContainerNameExist);
  let emptyPageContainer;
  if (pageContainerData) {
    const updatedPageContainerData = {
      ...pageContainerData.contentTree[0],
      title: pageContainerData.title,
      isMainContainer: false
    };

    emptyPageContainer = updatedPageContainerData;
  } else {
    emptyPageContainer = {
        id: v4(),
        title: `Container ${containerIndex}`,
        cssClassName: `container-${containerIndex}`,
        ...defaultContainer
    }
  }
  const pageContainer = treeService.findNodeInTree(contentTree[0], pageContainerId, 'id', ['children'], false);
  pageContainer.children?.push(emptyPageContainer);
}

export function deleteComponent(contentTree, deletedComponentId: string) {
  const parentNode = treeService.findNodeInTree(contentTree[0], deletedComponentId, 'id', ['children'], true)?.parentNode;
  const index = parentNode.children.map(e => e.id).indexOf(deletedComponentId);
  parentNode.children.splice(index, 1);
}

export function addCreatedComponentContentToTree(contentTree, pageContainerId: string, createdComponent: any) {
  const parentNode = treeService.findNodeInTree(contentTree[0], pageContainerId, 'id', ['children'], false);
  const componentClone = cloneDeep(createdComponent);
  delete componentClone.component;
  parentNode.children.push(componentClone);
}

export function mergeComponentsAndDefinitionsToContentTree(root: any, componentDefinitions: ComponentDefinition[], components: Component[]) {
  const tree = [cloneDeep(root)];
  function merge(node) {
    if (node?.type === 'component') {
      const componentsWithComponentDefinition = components.map(component => ({
        ...component,
        componentDefinition: componentDefinitions.find(componentDefinition => componentDefinition.id === component.componentDefinitionId)
      }));
      node['component'] = componentsWithComponentDefinition.find(c => c.id === node.componentId);
    }
    if (node?.children?.length) {
      node.children = node.children.map(child => merge(child));
    }
    return node;
  }

  merge(tree[0]);
  return tree;
}

export function removePageContainerAndInsertNewPageComponent(contentTree, pageContainerId: string, component: Component, componentDefinition: ComponentDefinition) {
  const parentNode = treeService.findNodeInTree(contentTree[0], pageContainerId, 'id', ['children'], true)?.parentNode;
  const index = parentNode.children.map(e => e.id).indexOf(pageContainerId);
  const newContentTreeItem = {
    id: v4(),
    componentId: component.id,
    type: 'component',
    component: {
      ...component,
      componentDefinitionId: componentDefinition.id,
      componentDefinition: componentDefinition
    }
  };
  parentNode.children.splice(index, 1, newContentTreeItem);
}

export function mergeVisibilityConditionToContainer(contentTree, pageContainerId: string, visibilityCondition) {
  const updatedContainer = treeService.findNodeInTree(contentTree[0], pageContainerId, 'id', ['children'], false);
  Object.assign(updatedContainer, { visibilityCondition: visibilityCondition });
}

export function deleteVisibilityConditionToContainer(contentTree, pageContainerId: string) {
  const container = treeService.findNodeInTree(contentTree[0], pageContainerId, 'id', ['children'], false);
  delete container.visibilityCondition;
}

/**
 * Removes Component || Component Definition fields from tree.
 *
 * In previous versions we were saving component data in the tree,
 * but due to performance and data size issues we decided to use only references,
 * so we remove these fields before saving the tree.
 * Actually there are no additional fields in the contentTree in State,
 * but we use this function for migration and extra control.
 *
 * @export
 * @param {any[]} contentTree
 * @return {*}
 */
export function removeRelationalFieldsOfContentTree(contentTree: any[]) {
  const tree = cloneDeep(contentTree);
  function remove(node) {
    if (node.type === ContentTreeItemType.Component) {
      delete node.component;
    } else {
      if (node.children?.length) {
        node.children.forEach(child => remove(child));
      }
    }
  }

  remove(tree[0]);
  return tree;
}
