// comment-animation.service.ts
import { Injectable } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class CommentAnimationService {
  static hideShow = trigger('hideShow', [
    state('show', style({
      opacity: 1,
    })),
    state('hide', style({
      opacity: 0,
      // display: 'none'
    })),
    transition('show => hide', [
      animate('0.2s')
    ]),
    transition('hide => show', [
      animate('0.1s')
    ]),
  ]);
}
