<div class="element-menu-header">
  <div class="component-search">
    <rappider-input-group [(ngModel)]="searchText"
                          [textbox]="inputGroupConfig.textbox"
                          [suffixIcon]="inputGroupConfig.suffixIcon"
                          (ngModelChange)="onSearchTextChange()"></rappider-input-group>
  </div>

  <div *ngIf="!isComponentUsingInModal"
       class="expand">
    <rappider-button [icon]="{name: 'fa-solid fa-arrow-up-right-from-square'}"
                     (click)="addEventElementModalVisibilityChange(true)"></rappider-button>
  </div>
</div>

<div>
  <ng-container *ngIf="!searchText; else componentSearchResultTemplate">
    <div *ngFor="let element of elements"
         [class]="isComponentUsingInModal ? 'modal-element-menu' : 'element-menu'">
      <ng-container *ngFor="let elementData of element.data">
        <ng-container *ngTemplateOutlet="elementTemplate;
          context:{$implicit: elementData.componentDefinition} ">
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #componentSearchResultTemplate>
  <div [class]="isComponentUsingInModal? 'modal-search-result' : 'search-result'">
    <ng-container *ngFor="let element of elementsBySearch.data"
                  [class]="isComponentUsingInModal ? 'modal-element' : 'element'">
      <ng-container *ngTemplateOutlet="elementTemplate;
        context:{$implicit: element.componentDefinition} ">
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- ELEMENT TEMPLATE -->

<ng-template #elementTemplate
             let-componentDefinition>
  <section [class]="isComponentUsingInModal ? 'modal-element-definition element-section' : 'element-definition element-section'"
           (mouseover)="mouseOver(componentDefinition)"
           (mouseleave)="mouseOut()"
           (click)="onAddComponentDefinitionButtonClick(componentDefinition)"
           nz-tooltip
           nzTooltipPlacement="top"
           nzTooltipTitle="Component Preview">
    <div *ngIf="hoveredComponentDefinitionId === componentDefinition.id && showPopover"
         class="element-preview-popover"
         nz-popover
         nzPopoverTrigger="hover"
         nzPopoverPlacement="right"
         nzPopoverOverlayClassName="popover-overlay"
         [nzPopoverContent]="popoverContentTemplate"
         (nzPopoverVisibleChange)="onPopoverVisibilityChange($event,componentDefinition)">
      <i class="fa-regular fa-eye"></i>
    </div>
    <div [class]="isComponentUsingInModal ? 'modal-element-icon' : 'element-icon'"
         [style.mask-image]="getIconForElement(componentDefinition.previewIconUrl)"
         [style.-webkit-mask-image]="getIconForElement(componentDefinition.previewIconUrl)">
    </div>
    <div [class]="isComponentUsingInModal ? 'modal-component-title-section' : 'component-title-section'">
      <rappider-text [text]="componentDefinition.title">
      </rappider-text>
    </div>
  </section>
</ng-template>

<ng-template #popoverContentTemplate>
  <div *ngIf="!isComponentUsingInModal">
    <span *ngIf="activeContentTreeItem?.type === ContentEditorContentType.Container"
          [class]="isComponentUsingInModal ? 'modal-add-component-inside-information' : 'add-component-inside-information'">
      Add Element Inside <b>{{getContentTreeItemAsPageContainer(activeContentTreeItem).title}}</b>
    </span>
    <h6>
      Element Preview
    </h6>
    <hr>
  </div>
  <ng-container #componentContainer></ng-container>
</ng-template>

<!-- /ELEMENT TEMPLATE -->
