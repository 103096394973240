import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Module, ModulePartial } from '@rappider/rappider-sdk';
import { StringUtilityService } from '@rappider/services';
import { MODULE_CREATE_OR_EDIT_CONFIG } from '@rappider/shared/configs';
import { Subscription } from 'rxjs';
import { CreateModule } from '../../state/module.actions';

@Component({
  selector: 'rappider-module-create',
  templateUrl: './module-create.component.html',
  styleUrls: ['./module-create.component.scss']
})
export class ModuleCreateComponent implements OnInit, OnDestroy {
  MODULE_CREATE_CONFIG = MODULE_CREATE_OR_EDIT_CONFIG;
  /* module form data */
  moduleFormData: ModulePartial = {
    title: null,
    name: null,
    slug: null
  };
  /* submit button loading state */
  submitButtonLoading = false;
  subscriptions: Subscription[];

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToModuleLoading()
    ];
  }

  subscribeToModuleLoading() {
    return this.store.select(state => state.module?.isLoading).subscribe((loading: boolean) => {
      this.submitButtonLoading = loading;
    });
  }

  onFieldValueChange(fieldValue: { [key: string]: string }) {
    if (fieldValue.title) {
      this.moduleFormData.slug = StringUtilityService.toKebabCase(fieldValue.title);
      this.moduleFormData.name = StringUtilityService.toPascalCase(fieldValue.title);
      this.moduleFormData = { ...this.moduleFormData, ...fieldValue };
    }
    this.moduleFormData = { ...this.moduleFormData, ...fieldValue };
  }

  onCreateModuleFormSubmit(module: Module) {
    this.submitButtonLoading = true;
    this.store.dispatch(CreateModule({ payload: { module: module } }));
  }

}
