import { createSelector } from '@ngrx/store';
import { CommentWithRelations, PageCommentWithRelations, PersonWithRelations } from '@rappider/rappider-sdk';
import { CommentCategory } from 'libs/comment/src/lib/utils/comment-category.enum';

/*
  filters page comments by activeContentTreeItem and maps comments to page comments
*/
export const getPageCommentsWithDetails = createSelector(
  state => state['comment']?.data,
  state => state['pageComment']?.data,
  state => state['activeProject']?.people,
  (
    comments: CommentWithRelations[],
    pageComments: PageCommentWithRelations[],
    projectPeople: PersonWithRelations[],
  ) => {
    const pageCommentWithRelations = pageComments?.map(pageComment => {
      const comment = comments?.find(comment => comment.id === pageComment.commentId);
      return {
        ...pageComment,
        comment: {
          ...comment,
          assignedTo: projectPeople?.find(person => person.id === comment?.assignedToPersonId)
        }
      };
    });
    return pageCommentWithRelations;
  }
);
