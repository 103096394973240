import { createSelector } from '@ngrx/store';
import { DataSchemaField, DataSchemaWithRelations, UiDataSelectorWithRelations, UiDataStore } from '@rappider/rappider-sdk';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

export const getUIDataSelectors = createSelector(
  state => state['uiDataStore']?.data,
  state => state['uiDataSelector']?.data,
  getDataSchemasWithDetailsSelector,
  (uiDataStores: UiDataStore[], uiDataSelectors: UiDataSelectorWithRelations[], dataSchemas: DataSchemaWithRelations[]): UiDataSelectorWithRelations[] => {
    if (uiDataStores?.length && uiDataSelectors?.length && dataSchemas?.length) {
      return uiDataSelectors.map((uiDataSelector: UiDataSelectorWithRelations) => ({
          ...uiDataSelector,
          uiDataStoreFieldSelectors: uiDataSelector?.uiDataStoreFieldSelectors?.map(fieldSelector => {
            const uiDataStore = uiDataStores.find(uiDataStore => uiDataStore.id === fieldSelector.uiDataStoreId);
            const fields = [];
            fieldSelector.uiDataStoreSchemaFieldIds.forEach((fieldId, index) => {
              let field;
              if (index === 0) {
                const schema = dataSchemas.find(dataSchema => dataSchema.id === uiDataStore.dataSchemaId);
                field = schema.fields.find(field => field.id === fieldId);
              } else {
                field = fields[fields.length - 1].type.fields.find(field => field.id === fieldId);
              }
              fields.push(field);
            });
            return {
              ...fieldSelector,
              uiDataStore: uiDataStore,
              uiDataStoreSchemaFields: fields
            };
          }) ?? []
        }));
    }
  }
);

// index 0 ise schema içerisinden field'ı bul
// index 0'dan büyük ise field array'i içerisinden bul
