import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap, map, withLatestFrom } from 'rxjs/operators';
import { NotificationService, PaginationService } from '@rappider/services';
import * as ProjectMemberActions from './project-member.actions';
import * as ActiveProjectActions from '../active-project-state/active-project.actions';
import { PersonProjectControllerService, PersonProjectRoleControllerService, ProjectControllerService, RoleMappingControllerService } from '@rappider/rappider-sdk';


@Injectable()
export class ProjectMemberEffects {
  constructor(
    private actions$: Actions,
    private projectApi: ProjectControllerService,
    private personProjectApi: PersonProjectControllerService,
    private store: Store<any>,
    private notificationService: NotificationService,
    private paginationService: PaginationService,
    private roleMappingApi: RoleMappingControllerService,
    private personProjectRoleControllerService: PersonProjectRoleControllerService
  ) { }


  loadModule$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectMemberActions.LoadModule>(
      ProjectMemberActions.ActionTypes.LoadModule,
    ),
    mergeMap(() => [new ProjectMemberActions.GetProjectMembers()])
  ));


  getProjectMembers$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectMemberActions.GetProjectMembers>(
      ProjectMemberActions.ActionTypes.GetProjectMembers
    ),
    mergeMap((action) => {
      const filter = {
        order: ['firstName ASC']
      };
      return this.projectApi.findProjectMembersWithTheirRoles({ filter }).pipe(
        mergeMap((projectMembers: any[]) => {
          if (projectMembers) {
            return [
              new ProjectMemberActions.GetProjectMembersSuccessful({ projectMembers: projectMembers })
            ];
          } else {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_COULDNOT_CREATED'
            );
            return [new ProjectMemberActions.GetProjectMembersFailure({ error: 'Project role could not loaded', key: 'GetProjectMembersFailure', timestamp: Date.now() })];
          }
        })
      );
    }), catchError(error => [
      new ProjectMemberActions.ProjectMemberError({ errorOn: 'GetProjectMembers', error: error }),
    ])
  ));


  assignManager$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectMemberActions.AssignManager>(
      ProjectMemberActions.ActionTypes.AssignManager),
    withLatestFrom(
      this.store.select(state => state.activeProject.data)
    ),
    mergeMap(([action, activeProject]) => {
      const body = {
        personId: action.payload.personId,
        projectId: activeProject.id
      };

      return this.roleMappingApi.assignProjectManagerAuthorityToPerson({ body }).pipe(
        map((roleMapping) => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'Assigned as Project Manager'
          );
          return new ProjectMemberActions.FindPersonAuthoritiesForAssign({ personId: action.payload.personId });
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.COULD_NOT_ASSING_AS_MANAGER'
          );
          return [new ProjectMemberActions.AssignManagerFailure({ errorOn: 'AssignManager', error: error })];
        })
      );
    })
  ));


  unassignManager$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectMemberActions.UnassignManager>(
      ProjectMemberActions.ActionTypes.UnassignManager),
    withLatestFrom(
      this.store.select(state => state.activeProject.data)
    ),
    mergeMap(([action, activeProject]) => {
      const body = {
        personId: action.payload.personId,
        projectId: activeProject.id
      };

      return this.roleMappingApi.unassignProjectManagerAuthorityFromPerson({ body }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'Unassigned as Project Manager'
          );
          return new ProjectMemberActions.UnassignManagerSuccessful({
            personId: action.payload.personId
          });
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.COULD_NOT_UNASSING_AS_MANAGER'
          );
          return [new ProjectMemberActions.UnassignManagerFailure({ errorOn: 'UnassignManager', error: error })];
        })
      );
    })
  ));


  createRoles = createEffect(() => this.actions$.pipe(
    ofType<ProjectMemberActions.CreateRoles>(
      ProjectMemberActions.ActionTypes.CreateRoles
    ),
    withLatestFrom(
      this.store.select(state => state.projectRole?.data)
    ),
    mergeMap(([action, roles]) => {
      const body = action.payload.body;
      const personRoles = roles;
      const addedRolesToPerson = personRoles.filter(role => body.personProjectRoles.some(res => res.projectRoleId === role.id));

      return this.personProjectRoleControllerService.bulkCreate({ body: body }).pipe(
        map((result: any) => {
          const resultData = result.data;

          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'Role Assigned Successfully'
          );
          return new ProjectMemberActions.CreateRolesSuccessful({ result: resultData, roles: addedRolesToPerson });
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.COULD_NOT_SET_ROLES'
          );
          return [new ProjectMemberActions.CreateRolesFailure({ errorOn: 'CreateRoles', error: error })];
        }
        )
      );
    })
  ));


  deleteRoles = createEffect(() => this.actions$.pipe(
    ofType<ProjectMemberActions.DeleteRoles>(
      ProjectMemberActions.ActionTypes.DeleteRoles
    ),
    mergeMap((action) => {
      const body = action.payload.body;

      return this.personProjectRoleControllerService.bulkDelete({ body: body }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'Role Deleted Successfully'
          );
          return new ProjectMemberActions.DeleteRolesSuccessful({ result: body });
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.COULD_NOT_DELETE_ROLES'
          );
          return [new ProjectMemberActions.DeleteRolesFailure({ errorOn: 'DeleteRoles', error: error })];
        }
        )
      );
    })
  ));


  findPersonAuthoritiesForAssign = createEffect(() => this.actions$.pipe(
    ofType
      <ProjectMemberActions.FindPersonAuthoritiesForAssign>(
        ProjectMemberActions.ActionTypes.FindPersonAuthoritiesForAssign
      ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id),
      this.store.select(state => state.activeProject.data?.people),
    ),
    mergeMap(([action, activeProjectId, people]) => {
      const filter = {
        where: { projectId: activeProjectId },
        include: ['role']
      };

      return this.roleMappingApi.find({ filter }).pipe(
        map((roleMapping) => {
          const personAuthorities = roleMapping.map(item => ({
            ...item,
            person: people.find(person => person.id === item.principalId)
          }));
          return new ProjectMemberActions.AssignManagerSuccessful({ personAuthorities, personId: action.payload.personId });
        }), catchError(error => [
          new ProjectMemberActions.AssignManagerFailure({ errorOn: 'AssignManager', error: error })
        ])
      );
    })
  ));


  UnassignPersonFromProject$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectMemberActions.UnassignPersonFromProject>(
      ProjectMemberActions.ActionTypes.UnassignPersonFromProject),
    mergeMap((action) => {
      const personId = action.payload.personId;

      return this.personProjectApi.unassignPersonFromProject({ personId: personId })?.pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESS',
            'The person has been successfully removed from the project.'
          );
          return new ProjectMemberActions.UnassignPersonFromProjectSuccessful({
            personId: action?.payload?.personId
          });
        }), catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'The person could not be removed from the project.'
          );
          return [new ProjectMemberActions.UnassignPersonFromProjectFailure({ errorOn: 'UnassignPerson', error: error })];
        })
      );
    })
  ));

}
