import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudFormSelectItem, CrudViewFormItemType } from '@rappider/rappider-components/utils';

export const LIST_DATA_SOURCE_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormSelectItem>{
      title: 'Page Variable',
      fieldName: 'pageDataVariableId',
      type: CrudViewFormItemType.Select,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    {
      title: 'Data Item Variable Name',
      fieldName: 'dataItemVariableName',
      type: CrudViewFormItemType.TextBox,
      description: 'You can change the variable name',
      default: 'dataItem'
    },
  ],
  submitButton: { visible: false }
};
