import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, featureKey } from './project-source-code.reducer';

// Feature selector
export const selectProjectSourceCodeState = createFeatureSelector<State>(featureKey);

// Individual property selectors
export const selectData = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.data
);

export const selectActiveProjectFileSourceCode = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.activeProjectFileSourceCode
);

export const selectActiveProjectFileCustomCodes = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.activeProjectFileCustomCodes
);

export const selectProjectSourceFiles = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.projectSourceFiles
);

export const selectIsLoading = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.isLoading
);

export const selectError = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.error
);

export const selectIsUpsertProcessLoading = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.isUpsertProcessLoading
);

export const selectIsDeleteProcessLoading = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.isDeleteProcessLoading
);

export const selectIsProjectDownloadLoading = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.isProjectDownloadLoading
);

export const selectIsProjectGenerateCodeLoading = createSelector(
  selectProjectSourceCodeState,
  (state: State) => state.isProjectGenerateCodeLoading
);
