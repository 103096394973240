import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiDataSelectorComponent } from './components/ui-data-selector/ui-data-selector.component';
import { AngularSplitModule } from 'angular-split';
import { RappiderJsonataEditorModule, RappiderModalModule, RappiderMonacoEditorModule, RappiderSelectModule } from '@rappider/rappider-components';
import { DataSubscriptionModule } from '@rappider/data-subscription';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { UiDataSelectorSelectComponent } from './components/ui-data-selector-select/ui-data-selector-select.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RappiderInputErrorModule } from '../../../components/src/lib/components/input-error/input-error.module';

@NgModule({
  declarations: [
    UiDataSelectorComponent,
    UiDataSelectorSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSplitModule,
    RappiderMonacoEditorModule,
    DataSubscriptionModule,
    RappiderJsonataEditorModule,
    NzInputModule,
    NzButtonModule,
    RappiderSelectModule,
    RappiderModalModule,
    RappiderInputErrorModule
  ],
  exports: [
    UiDataSelectorComponent,
    UiDataSelectorSelectComponent
  ],
})
export class UiDataSelectorModule { }
