import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ContentTreeRendererEditorSize } from '../../models/content-tree-renderer-editor-size.interface';
import { IconType, SwitchComponentConfig, SwitchSize, TextComponentConfig } from '@rappider/rappider-components/utils';
import { Page } from '@rappider/rappider-sdk';
import { Store } from '@ngrx/store';
import { ContentEditorDevices } from '../../models';

@Component({
  selector: 'rappider-content-tree-renderer-controller',
  templateUrl: './content-tree-renderer-controller.component.html',
  styleUrls: ['./content-tree-renderer-controller.component.scss']
})
export class ContentTreeRendererControllerComponent implements OnChanges {

  @Input() editorSize: ContentTreeRendererEditorSize;
  @Input() rulerVisibility: boolean;
  @Input() page: Page;

  @Output() editorSizeChange = new EventEmitter<ContentTreeRendererEditorSize>();
  @Output() rulerVisibilityChange = new EventEmitter<boolean>();
  @Output() previewModeChange = new EventEmitter<boolean>();
  @Output() fullScreen = new EventEmitter<void>();
  @Output() selectedDeviceChange = new EventEmitter();

  activePageId: string;
  selectedDevice = ContentEditorDevices.DesktopFullWidth;
  previewMode = false;
  devices = [
    {
      label: 'Desktop',
      value: ContentEditorDevices.DesktopFullWidth,
      width: null,
      height: null
    },
    // {
    //   label: 'Responsive',
    //   value: ContentEditorDevices.Responsive,
    //   width: null,
    //   height: null
    // },
    {
      label: 'iPhone 14 Pro',
      value: ContentEditorDevices.Iphone14Pro,
      width: 420,
      height: 800
    }
  ];

  scaleOptions = [
    {
      label: '25%',
      value: 0.25
    },
    {
      label: '50%',
      value: 0.50
    },
    {
      label: '100%',
      value: 1
    },
  ];

  rulerVisibilityConfig: SwitchComponentConfig = {
    size: SwitchSize.Small,
    icon: {
      name: 'fa-regular fa-ruler',
      type: IconType.FontAwesome
    },
    tooltipText: 'Ruler',
    textPosition: 'left'
  };

  previewModeSwitchConfig: SwitchComponentConfig = {
    size: SwitchSize.Small,
    icon: {
      name: 'fa-regular fa-eye',
      type: IconType.FontAwesome
    },
    tooltipText: 'Preview',
    textPosition: 'left'
  };

  borderVisibilityText: TextComponentConfig = {
    text: 'Show Borders'
  };

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToActivePageId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editorSize) {
      if (!this.devices.some(device => device.width === this.editorSize.width && device.height === this.editorSize.height)) {
        this.editorSize = {
          width: Math.floor(this.editorSize?.width),
          height: Math.floor(this.editorSize?.height),
          scale: 1
        };
      }
    }
  }

  subscribeToActivePageId() {
    return this.store.select(state => state.contentEditor?.page?.id).subscribe(activePageId => {
      this.activePageId = activePageId;
    });
  }

  onEditorSizeChange() {
    this.editorSizeChange.emit(this.editorSize);
  }

  onDeviceChange(selectedDevice) {
    if (selectedDevice === ContentEditorDevices.Responsive || selectedDevice === ContentEditorDevices.DesktopFullWidth) {
      this.fullScreen.emit();
    } else {
      const existingDevice = this.devices.find(device => device.value === selectedDevice);
      this.editorSize = {
        width: Math.floor(existingDevice.width) || Math.floor(this.editorSize.width),
        height: Math.floor(existingDevice.height) || Math.floor(this.editorSize.height),
        scale: 1
      };
      this.onEditorSizeChange();
    }
    this.selectedDeviceChange.emit(selectedDevice);
  }

  onRulerVisibilityChange() {
    this.rulerVisibilityChange.emit(this.rulerVisibility);
  }

  onFitToScreenClick() {
    this.fullScreen.emit();
  }

  onPreviewModeChange() {
    this.previewModeChange.emit(this.previewMode);
  }

}
