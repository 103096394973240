import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Project } from '@rappider/rappider-sdk';
import { StartApplication, StopApplication } from 'libs/project-source-code/src/lib/project-source-code-data-source/project-source-code.actions';
import { DeployManagementState } from 'libs/project/src/lib/states/deploy-management/deploy-management.reducer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-server-page',
  templateUrl: './server-page.component.html',
  styleUrls: ['./server-page.component.scss']
})
export class ServerPageComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  applicationInfo: any;
  backendInfo: any;
  frontendInfo: any;
  switchValue = false;

  constructor(
    private store: Store<{ deployManagement: DeployManagementState; activeProject: Project, projectVersion: any }>,
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToApplicationInfo()
    ];
  }

  subscribeToApplicationInfo() {
    return this.store.select(state => state.deployManagement.applicationInfo).subscribe((applicationInfo: any) => {
      if (applicationInfo?.length) {
        this.backendInfo = applicationInfo?.find(appInfo => appInfo.Image.toLowerCase().includes('backend'));
        this.frontendInfo = applicationInfo?.find(appInfo => appInfo.Image.toLowerCase().includes('frontend'));
        if (applicationInfo[0].State === 'exited') {
          this.switchValue = false;
        } else {
          this.switchValue = true;
        }
      }
    });
  }

  onSwitchToggled(switchValue) {
    if (switchValue) {
      this.store.dispatch(StartApplication());
    } else {
      this.store.dispatch(StopApplication());
    }
  }

}
