import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as ModuleActions from './module.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as GlobalErrorActions from 'libs/error/src/lib/state/global-error/global-error.action';
import { GetPages } from 'libs/pages/src/lib/state/page-state/page.actions';

import { ModuleControllerService } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ModuleEffects {
  constructor(
    private actions$: Actions,
    private moduleApi: ModuleControllerService,
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  getModules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleActions.GetModules),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        /* pass if active project does not exist */
        if (!activeProjectId) {
          return [
            ModuleActions.GetModulesFailure()
          ];
        }
        /* set filter */
        const filter = {
          where: {
            projectId: activeProjectId
          }
        };
        /* get modules */
        return this.moduleApi.find({ filter }).pipe(
          mergeMap(modules => ([
            ModuleActions.GetModulesSuccessful({ payload: { modules } })
          ])),
          catchError((error) => [
            ModuleActions.GetModulesFailure()
          ])
        );
      })
    )
  );

  getModuleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleActions.GetModuleById),
      mergeMap((action) =>
        /* get module by id */
        this.moduleApi.findById({ id: action.payload.moduleId }).pipe(
          map(module => (ModuleActions.GetModuleByIdSuccessful({ payload: { module } }))),
          catchError((error) => [
            ModuleActions.GetModuleByIdFailure({ payload: error })
          ])
        )
      )
    )
  );

  createModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleActions.CreateModule),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        const params = {
          body: {
            ...action.payload.module,
            projectId: activeProjectId
          }
        };

        return this.moduleApi.create(params).pipe(
          map((module) => {
            this.notificationService.createNotification(
              'success',
              params.body.name + ' Module',
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return ModuleActions.CreateModuleSuccessful({ payload: { module: module } });
          }),
          catchError((error: HttpErrorResponse) => [
            ModuleActions.CreateModuleFailure(),
            GlobalErrorActions.GlobalErrorAction({
              payload: {
                errorHeader: params.body.name + ' Module could not created',
                errorText: error?.error?.error?.message,
                key: 'CreateModule',
                timeStamp: Date.now()
              }
            })
          ])
        );
      })
    )
  );

  createModuleSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleActions.CreateModuleSuccessful),
      map(action =>
        new Navigate({ url: PATH_DEFINITIONS.MODULE.MODULE_LIST_PATH })
      )
    )
  );

  updateModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleActions.UpdateModule),
      mergeMap(action => {
        const params = {
          id: action.payload.moduleId,
          body: action.payload.module
        };

        return this.moduleApi.updateById(params).pipe(
          map((module) => {
            this.notificationService.createNotification(
              'success',
              params.body.name + ' Module',
              'SHARED.SUCCESSFULLY_UPDATED'
            );
            return ModuleActions.UpdateModuleSuccessful({ payload: { moduleId: action.payload.moduleId, module: action.payload.module } });
          }
          ),
          catchError((error: HttpErrorResponse) => {
            this.notificationService.createNotification(
              'error',
              params.body.name + ' Module could not updated',
              error?.error?.error?.message
            );
            return [
              ModuleActions.UpdateModuleFailure(),
              ModuleActions.ErrorAction({ payload: { error: error, key: 'UpdateModule', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  updateModuleSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleActions.UpdateModuleSuccessful),
      mergeMap(action => [
        new Navigate({ url: PATH_DEFINITIONS.MODULE.MODULE_LIST_PATH }),
        ModuleActions.GetModules()
      ])
    )
  );

  deleteModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleActions.DeleteModule),
      withLatestFrom(
        this.store.select(state => state.module?.data),
        this.store.select(state => state.projectSetting?.data)
      ),
      mergeMap(([action, modules, projectSettings]) => {
        const params = { id: action.payload.moduleId };
        const deletedModule = modules.find(module => module.id === action.payload.moduleId);
        const defaultModuleId = projectSettings.find(setting => setting.key === 'defaultModuleId')?.value;

        if (deletedModule.id === defaultModuleId) {
          this.notificationService.createNotification('error', 'SHARED.ERROR', 'MODULE_MODULE.CANNOT_DELETE_DEFAULT_MODULE');
          return of(ModuleActions.ErrorAction({ payload: { error: 'Cannot delete default module', key: 'DeleteModule', timeStamp: Date.now() } }));
        }
        return this.moduleApi.deleteById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              deletedModule.name + ' Module',
              'SHARED.SUCCESSFULLY_DELETED'
            );
            return ModuleActions.GetModules();
          }),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              deletedModule.name + ' Module could not deleted',
              'SHARED.COULDNT_DELETED'
            );
            return [
              ModuleActions.ErrorAction({ payload: { error: error, key: 'DeleteModule', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

}
