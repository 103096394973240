import { createSelector } from '@ngrx/store';
import { CommentWithRelations, DiagramCommentWithRelations } from '@rappider/rappider-sdk';

export const getDiagramCommentsWithDetails = createSelector(
  state => state['comment']?.data,
  state => state['comment']?.isLoaded,
  state => state['diagramComment']?.data,
  state => state['diagramComment']?.isLoaded,
  state => state['uiWorkflowStepFunction']?.isLoaded,
  (
    comments: CommentWithRelations[],
    isCommentsLoaded: boolean,
    diagramComments: DiagramCommentWithRelations[],
    isDiagramCommentsLoaded: boolean,
    isUiWorkflowStepFunctionLoaded: boolean
  ) => {
    if (isDiagramCommentsLoaded && isCommentsLoaded && isUiWorkflowStepFunctionLoaded) {
      return diagramComments?.map(diagramComment => ({
        ...diagramComment,
        comment: comments?.find(comment => comment.id === diagramComment.commentId)
      }));
    } else {
      return [];
    }
  }
);
