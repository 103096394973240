/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GitActionWorkflowHistoryItemResponseDto } from '../models/git-action-workflow-history-item-response-dto';
import { ProjectCodeGeneration } from '../models/project-code-generation';
import { ProjectDeploy } from '../models/project-deploy';

@Injectable({
  providedIn: 'root',
})
export class ApplicationBuilderControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation applicationBuilderControllerDeleteApplication
   */
  static readonly ApplicationBuilderControllerDeleteApplicationPath = '/admin/application-builder/delete-application/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplication$Response(params: {
    projectId: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerDeleteApplicationPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplication(params: {
    projectId: string;
  }): Observable<any> {

    return this.deleteApplication$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation applicationBuilderControllerMonitorAllApplications
   */
  static readonly ApplicationBuilderControllerMonitorAllApplicationsPath = '/admin/application-builder/monitor-applications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monitorAllApplications()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorAllApplications$Response(params?: {
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerMonitorAllApplicationsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monitorAllApplications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorAllApplications(params?: {
  }): Observable<any> {

    return this.monitorAllApplications$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation applicationBuilderControllerDeploy
   */
  static readonly ApplicationBuilderControllerDeployPath = '/application-builder/deploy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deploy()` instead.
   *
   * This method doesn't expect any request body.
   */
  deploy$Response(params?: {
    branch?: string;
  }): Observable<StrictHttpResponse<ProjectDeploy>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerDeployPath, 'get');
    if (params) {
      rb.query('branch', params.branch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDeploy>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deploy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deploy(params?: {
    branch?: string;
  }): Observable<ProjectDeploy> {

    return this.deploy$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectDeploy>) => r.body as ProjectDeploy)
    );
  }

  /**
   * Path part for operation applicationBuilderControllerDownloadCode
   */
  static readonly ApplicationBuilderControllerDownloadCodePath = '/application-builder/download-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadCode$Response(params: {
    versionNumber: string;
  }): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerDownloadCodePath, 'get');
    if (params) {
      rb.query('versionNumber', params.versionNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadCode(params: {
    versionNumber: string;
  }): Observable<Blob> {

    return this.downloadCode$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation applicationBuilderControllerGenerateCode
   */
  static readonly ApplicationBuilderControllerGenerateCodePath = '/application-builder/generate-code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateCode$Response(params?: {
  }): Observable<StrictHttpResponse<ProjectCodeGeneration>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerGenerateCodePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectCodeGeneration>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateCode(params?: {
  }): Observable<ProjectCodeGeneration> {

    return this.generateCode$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectCodeGeneration>) => r.body as ProjectCodeGeneration)
    );
  }

  /**
   * Path part for operation applicationBuilderControllerGetQueueInformationByRunId
   */
  static readonly ApplicationBuilderControllerGetQueueInformationByRunIdPath = '/application-builder/git-action-queue-information/{runId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQueueInformationByRunId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQueueInformationByRunId$Response(params: {
    runId: string;
  }): Observable<StrictHttpResponse<Array<GitActionWorkflowHistoryItemResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerGetQueueInformationByRunIdPath, 'get');
    if (params) {
      rb.path('runId', params.runId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GitActionWorkflowHistoryItemResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQueueInformationByRunId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQueueInformationByRunId(params: {
    runId: string;
  }): Observable<Array<GitActionWorkflowHistoryItemResponseDto>> {

    return this.getQueueInformationByRunId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GitActionWorkflowHistoryItemResponseDto>>) => r.body as Array<GitActionWorkflowHistoryItemResponseDto>)
    );
  }

  /**
   * Path part for operation applicationBuilderControllerGetGitActionWorkflowHistory
   */
  static readonly ApplicationBuilderControllerGetGitActionWorkflowHistoryPath = '/application-builder/git-action-workflow-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGitActionWorkflowHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGitActionWorkflowHistory$Response(params?: {
    limit?: number;
    status?: string;
  }): Observable<StrictHttpResponse<Array<GitActionWorkflowHistoryItemResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerGetGitActionWorkflowHistoryPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GitActionWorkflowHistoryItemResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGitActionWorkflowHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGitActionWorkflowHistory(params?: {
    limit?: number;
    status?: string;
  }): Observable<Array<GitActionWorkflowHistoryItemResponseDto>> {

    return this.getGitActionWorkflowHistory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GitActionWorkflowHistoryItemResponseDto>>) => r.body as Array<GitActionWorkflowHistoryItemResponseDto>)
    );
  }

  /**
   * Path part for operation applicationBuilderControllerMonitorApplication
   */
  static readonly ApplicationBuilderControllerMonitorApplicationPath = '/application-builder/monitor-application';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `monitorApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorApplication$Response(params?: {
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerMonitorApplicationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `monitorApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  monitorApplication(params?: {
  }): Observable<any> {

    return this.monitorApplication$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation applicationBuilderControllerStartApplication
   */
  static readonly ApplicationBuilderControllerStartApplicationPath = '/application-builder/start-application';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  startApplication$Response(params?: {
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerStartApplicationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startApplication(params?: {
  }): Observable<any> {

    return this.startApplication$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation applicationBuilderControllerStopApplication
   */
  static readonly ApplicationBuilderControllerStopApplicationPath = '/application-builder/stop-application';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  stopApplication$Response(params?: {
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationBuilderControllerService.ApplicationBuilderControllerStopApplicationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stopApplication(params?: {
  }): Observable<any> {

    return this.stopApplication$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
