import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Steps } from '../../utils/steps/steps.interface';
import { StepStatus } from '../../utils/steps/step-status.enum';
import { StepsDirection } from '../../utils/steps/step-direction.enum';
import { StepsLabelPlacement } from '../../utils/steps/step-label-placement.enum';
import { StepsSize } from '../../utils/steps/step-size.enum';
import { StepsType } from '../../utils/steps/steps-type.enum';


@Component({
  selector: 'rappider-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class RappiderStepsComponent implements OnInit, OnChanges {

  /* INPUTS */
  @Input() steps: Steps[];

  @Input() stepsType: StepsType;
  @Input() current: number;
  @Input() stepsDirection: StepsDirection;
  @Input() labelPlacement: StepsLabelPlacement;
  @Input() progressDot: boolean;
  @Input() stepsSize: StepsSize;
  @Input() stepsStatus: StepStatus;
  @Input() startIndex: number;

  /* OUTPUTS */
  @Output() indexChange = new EventEmitter<number>();

  ngOnInit() {
    this.initDefaults();
  }

  ngOnChanges() {
    this.initDefaults();
  }

  initDefaults() {
    if (this.stepsType == null) {
      this.stepsType = StepsType.Default;
    }
    if (this.current == null) {
      this.current = 0;
    }
    if (this.stepsDirection == null) {
      this.stepsDirection = StepsDirection.Horizontal;
    }
    if (this.labelPlacement == null) {
      this.labelPlacement = StepsLabelPlacement.Horizontal;
    }
    if (this.progressDot == null) {
      this.progressDot = false;
    }
    if (this.stepsSize == null) {
      this.stepsSize = StepsSize.Default;
    }
    if (this.stepsStatus == null) {
      this.stepsStatus = StepStatus.Process
    }
    if (this.startIndex == null) {
      this.startIndex = 0;
    }
  }

  onStepIndexChange(index: number) {
    this.indexChange.emit(index);
  }

}
