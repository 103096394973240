<div class="settings-button-container">
  <div *ngFor="let button of buttons; let i = index">
    <ng-container *ngIf="isExpanded || i < 2">
      <button nz-button
              [id]="button?.key"
              nzSize="small"
              [class]="'settings-button' + button?.customCssClass ? ' ' + button.customCssClass : ''"
              [nzTooltipTitle]="button?.tooltipText"
              [nzTooltipPlacement]="button?.tooltipPlacement || ['rightTop', 'right']"
              nz-tooltip
              [disabled]="button?.disabled"
              (click)="onButtonClicked(button?.key!)">
        <i [class]="button?.icon?.name || 'fa-duotone fa-solid fa-sparkles'"></i>
      </button>
    </ng-container>
  </div>
</div>
