import { ButtonComponentConfig, ButtonSize, ButtonType, DropdownMenuComponentConfig, IconType, MenuActionBehavior } from '@rappider/rappider-components/utils';

export const saveContentButtonConfig: ButtonComponentConfig = {
  text: 'Save',
  size: ButtonSize.Small,
  type: ButtonType.Primary
};

export const cancelContentButtonConfig: ButtonComponentConfig = {
  text: 'Cancel',
  size: ButtonSize.Small
};

export const commentActionsDropdownConfig: DropdownMenuComponentConfig = {
  icon: {
    name: 'ellipsis',
    type: IconType.NgZorro
  },
  items: [
    {
      label: 'SHARED.EDIT',
      key: 'edit',
      icon: {
        name: 'edit',
        type: IconType.NgZorro
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    {
      label: 'SHARED.DELETE',
      key: 'delete',
      icon: {
        name: 'delete',
        type: IconType.NgZorro
      },
      actionBehavior: MenuActionBehavior.Emit
    }
  ]
};
