import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Person, PersonControllerService, RoleMappingControllerService } from '@rappider/rappider-sdk';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as PersonActions from './person.actions';
import { PaginationService } from '@rappider/services';
import { Count } from 'libs/rappider-sdk/src/lib/models/loopback/count';

@Injectable()
export class PersonEffects {
  constructor(
    private actions$: Actions,
    private personApi: PersonControllerService,
    private roleMappingApi: RoleMappingControllerService,
    private paginationService: PaginationService
  ) { }


  getPersonById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PersonActions.ChangeRolesByAdminSuccessful,
      ),
      mergeMap((action) => {
        const filter = {
          fields: ['id', 'firstName', 'middleName', 'lastName', 'email', 'createdDate'],
          include: [
            {
              relation: 'roleMappings',
              scope: {
                include: [
                  {
                    relation: 'role'
                  }
                ],
                fields: ['id', 'projectId', 'roleId', 'principalId'],
                where: {
                  or: [
                    {
                      projectId: {
                        exists: false
                      }
                    },
                    {
                      projectId: {
                        eq: null
                      }
                    }
                  ]
                }
              }
            }
          ],
          where: {
            id: action.personId
          }
        };
        return this.personApi.findByAdmin({ filter }).pipe(
          map((people: Person[]) => {
            if (people.length > 0) {
              return PersonActions.GetUpdatedPersonSuccessful({ updatedPerson: people[0] });
            }
          }),
          catchError((error) => [
            PersonActions.GetPersonFailure({ payload: { error, key: 'GetPersonFailure', timestamp: Date.now() } })
          ])
        );
      })
    )
  );

  getPeopleByAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PersonActions.GetPeopleByAdmin,
        PersonActions.GetPeopleCountSuccessful,
      ),
      mergeMap((action) => {
        const filter = {
          fields: ['id', 'firstName', 'middleName', 'lastName', 'email', 'createdDate'],
          skip: this.paginationService.
            getSkippedSizeByPagination(action.pageIndex, action.pageSize),
          limit: action.pageSize,
          include: [
            {
              relation: 'roleMappings',
              scope: {
                include: [
                  {
                    relation: 'role'
                  }
                ],
                fields: ['id', 'projectId', 'roleId', 'principalId'],
                where: {
                  or: [
                    {
                      projectId: {
                        exists: false
                      }
                    },
                    {
                      projectId: {
                        eq: null
                      }
                    }
                  ]
                }
              }
            }
          ],
          order: ['createdDate DESC']
        } as any;
        if (action.searchText && action.searchText !== '') {
          filter.where = {
            or: [
              { firstName: { regexp: `/${action.searchText}/i` } },
              { lastName: { regexp: `/${action.searchText}/i` } },
              { email: { regexp: `/${action.searchText}/i` } }
            ]
          };
        }
        return this.personApi.findByAdmin({ filter }).pipe(
          map((people: Person[]) =>
            PersonActions.GetPeopleByAdminSuccessful({ payload: { people } })
          ),
          catchError((error) => [
            PersonActions.GetPersonFailure({ payload: { error, key: 'GetPersonFailure', timestamp: Date.now() } })
          ])
        );
      })
    )
  );

  getPeopleCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PersonActions.GetPeopleCount,
      ),
      switchMap((action) => {
        const params = {
          where: {
            or: [
              { firstName: { regexp: `/${action.searchText}/i` } },
              { lastName: { regexp: `/${action.searchText}/i` } },
              { email: { regexp: `/${action.searchText}/i` } }
            ]
          }
        };
        return this.personApi.countByAdmin(params).pipe(
          map((person: Count) => PersonActions.GetPeopleCountSuccessful(
            {
              pageIndex: action.pageIndex,
              pageSize: action.pageSize,
              searchText: action.searchText,
              count: person.count
            }
          )),
          catchError((error) =>
            [
              PersonActions.GetPeopleCountFailure({ error: error, key: 'GetPeopleCountFailure' })
            ]
          )
        );
      }
      )
    )
  );

  changeRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PersonActions.ChangeRolesByAdmin
      ),
      mergeMap((action) =>
        this.roleMappingApi.updatePersonRoleAssignmentsByAdmin({ personId: action.personId, body: { roleIds: action.roleIds } }).pipe(
          map(() => PersonActions.ChangeRolesByAdminSuccessful({ personId: action.personId, roleIds: action.roleIds })
          ),
          catchError((error) => [
            PersonActions.ChangeRolesByAdminFailure({ payload: { error, key: 'ChangeRolesFailure', timestamp: Date.now() } })
          ])
        )
      )
    )
  );

}
