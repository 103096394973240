import { CrudFormConfig, CrudViewFormItemType, FormLayout } from '@rappider/rappider-components/utils';

export const THEME_FORM_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'First Name',
      fieldName: 'firstName',
      type: CrudViewFormItemType.TextBox,
      placeholder: 'Enter your first name',
      showRequiredSign: true,
    },
    {
      title: 'Last Name',
      fieldName: 'lastName',
      type: CrudViewFormItemType.TextBox,
      placeholder: 'Enter your last name',
      showRequiredSign: true,
    },
    {
      title: 'Dropdown',
      fieldName: 'dropdown',
      type: CrudViewFormItemType.Select,
      placeholder: 'Select an option'
    },
    {
      title: 'Checkbox',
      fieldName: 'checkbox',
      type: CrudViewFormItemType.CheckBox,
      placeholder: 'Check if applicable'
    }
  ],
  formLayout: FormLayout.Vertical
};
