import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzCascaderModule } from 'ng-zorro-antd/cascader'; // NG-ZORRO Cascader Modülü
import { RappiderCascaderComponent } from './cascader.component';

@NgModule({
  declarations: [
    RappiderCascaderComponent
  ],
  imports: [
    CommonModule,
    NzCascaderModule
  ],
  exports: [
    RappiderCascaderComponent
  ]
})
export class RappiderCascaderModule { }
