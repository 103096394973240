import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProjectModelFieldActions from './project-model-field.actions';
import * as ProjectModelActions from 'libs/project/src/lib/states/project-model-state/project-model.actions';
import * as DataSchemaActions from 'libs/project/src/lib/states/data-schema/data-schema.actions';

import { NotificationService } from '@rappider/services';

/* Named import */
import { ProjectModelFieldControllerService as ProjectModelFieldApi, UpdateMultipleProjectModelField } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { Router } from '@angular/router';

@Injectable()
export class ProjectModelFieldEffects {
  constructor(
    private actions$: Actions,
    private projectModelFieldApi: ProjectModelFieldApi,
    private store: Store<any>,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  getProjectModelFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ProjectModelActions.GetProjectModelsSuccessful>(
        ProjectModelActions.ActionTypes.GetProjectModelsSuccessful
      ),
      mergeMap((action) => this.projectModelFieldApi.find().pipe(
        mergeMap(projectModelFields => ([
          ProjectModelFieldActions.GetProjectModelFieldsSuccessful({ payload: { projectModelFields: projectModelFields } })
        ])),
        catchError((error) => [
          ProjectModelFieldActions.GetProjectModelFieldsFailure({ error, key: 'GetProjectModelFields', timeStamp: Date.now() })
        ])
      )
      )
    )
  );

  getProjectModelFieldsByModelId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelFieldActions.GetProjectModelFieldsByModelId
      ),
      mergeMap((action) => {

        /* set a filter */
        const filter = {
          where: { projectModelId: action.payload.projectModelId }
        };
        /* get the ProjectModelFields */
        return this.projectModelFieldApi.find({ filter }).pipe(
          map(projectModelFields => (
            ProjectModelFieldActions.GetProjectModelFieldsByModelIdSuccessful({ payload: { projectModelFields, projectModelId: action.payload.projectModelId } })
          )),
          catchError((error) => [
            ProjectModelFieldActions.GetProjectModelFieldsByModelIdFailure({ payload: { error: error, key: 'GetProjectModelFieldsByModelIdFailure', timeStamp: Date.now() } })
          ])
        );
      })
    )
  );

  deleteProjectModelFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectModelFieldActions.DeleteProjectModelField),
      mergeMap(action => this.projectModelFieldApi.deleteById({ id: action.payload.projectModelFieldId }).pipe(
        mergeMap(() => {
          this.notificationService.createNotification(
            'success',
            'Project Model Data Field',
            'SHARED.SUCCESSFULLY_DELETED'
          );
          return [
            ProjectModelFieldActions.DeleteProjectModelFieldSuccessful({ payload: action.payload }),
            new DataSchemaActions.GetDataSchemaById({ id: action.payload.relationedTypeId })
          ];
        }
        ),
        catchError((error) => [
          ProjectModelFieldActions.DeleteProjectModelFieldFailure()
        ])
      ))
    )
  );

  createProjectModelFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectModelFieldActions.CreateProjectModelField),
      mergeMap(action =>
        this.projectModelFieldApi.create({ body: action.payload.projectModelField }).pipe(
          mergeMap(projectModelField => {
            this.notificationService.createNotification(
              'success',
              projectModelField.name,
              'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.PROJECT_MODEL_DATA_FIELD_SUCCESSFULY_CREATED'
            );
            if (action.payload.navigateAfterCreate) {
              this.router.navigate(
                [`${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${projectModelField.projectModelId}`],
                { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.FIELDS_TAB }
              );
            }
            return [
              ProjectModelFieldActions.CreateProjectModelFieldSuccessful({ payload: { projectModelField } }),
              new DataSchemaActions.GetDataSchemaById({ id: action.payload.relationedTypeId })
            ];
          }
          ),
          catchError((error) => [
            ProjectModelFieldActions.CreateProjectModelFieldFailure()
          ])
        )
      )
    )
  );

  updateProjectModelField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelFieldActions.UpdateProjectModelField
      ),
      mergeMap((action) => this.projectModelFieldApi.updateById({ id: action.payload.projectModelFieldId, body: action.payload.projectModelField }).pipe(
        map((projectModelField) => (
          ProjectModelFieldActions.UpdateProjectModelFieldSuccessful({ payload: { projectModelFieldId: projectModelField.id, projectModelField, projectModelId: action.payload.projectModelId } })
        )),
        catchError((error) => [
          ProjectModelFieldActions.UpdateProjectModelFieldFailure()
        ])
      )
      )
    )
  );

  bulkUpdateProjectModelFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelFieldActions.BulkUpdateProjectModelFields
      ),
      mergeMap((action => {
        const params = {
          body: <UpdateMultipleProjectModelField>{
            updatedProjectModelFields: action.payload.projectModelFields
          }
        };

        return this.projectModelFieldApi.customBulkUpdate(params).pipe(
          map((projectModelFields: any) => ProjectModelFieldActions.BulkUpdateProjectModelFieldsSuccessful({
            payload: {
              updatedProjectModelFields: projectModelFields.updated
            }
          })), catchError((error) => [
            ProjectModelFieldActions.BulkUpdateProjectModelFieldsFailure({
              payload: {
                error: error,
                key: 'BulkUpdateProjectModelFields',
                timeStamp: Date.now()
              }
            })
          ])
        );
      }))
    )
  );

}

