import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { CreateUIDataEventButtonConfig } from '../../utils/create-ui-data-event-button.config';
import { CreateUIStepFunctionButtonConfig } from '../../utils/create-ui-step-function-button.config';
import { DiagramSettingsDropdownConfig } from '../../utils/definitions/diagram-editor-toolbar/diagram-settings-dropdown.config';
import { DiagramActionType } from '../../utils/diagram-action-type';
import { DiagramItemType } from '../../utils/diagram-item-type';
import { DiagramSettingsMode } from '../../utils/diagram-settings-mode';
import { WorkflowItemInDiagram } from '../../utils/workflow-item-in-diagram.interface';
import { ButtonSize } from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-diagram-editor-toolbar',
  templateUrl: './diagram-editor-toolbar.component.html',
  styleUrls: ['./diagram-editor-toolbar.component.scss']
})
export class DiagramEditorToolbarComponent {

  @Input() activeUIDataStore: UiDataStoreWithRelations;
  @Input() activeItem: WorkflowItemInDiagram;
  @Input() menuVisibility: boolean;
  @Input() showEventsWithProcess: boolean;
  @Input() formPanelVisibility: boolean;

  @Output() diagramSettingsModeChange = new EventEmitter<DiagramSettingsMode>();
  @Output() menuVisibilityChange = new EventEmitter<boolean>();
  @Output() showEventsWithProcessClick = new EventEmitter();
  @Output() changeModalVisibility = new EventEmitter<string>();
  @Output() formPanelVisibilityChange = new EventEmitter<boolean>();

  CreateUIDataEventButtonConfig = CreateUIDataEventButtonConfig;
  CreateUIStepFunctionButtonConfig = CreateUIStepFunctionButtonConfig;
  DiagramSettingsDropdownConfig = DiagramSettingsDropdownConfig;
  ButtonSize = ButtonSize.Small;

  /**
  * emits diagram editor mode to create UI data event
  *
  * @memberof DiagramEditorComponent
  */
  onCreateUIDataEventButtonClick() {
    this.formPanelVisibility = true;
    const mode: DiagramSettingsMode = {
      activeItemType: DiagramItemType.UIDataEvent,
      activeAction: DiagramActionType.Create
    };
    this.diagramSettingsModeChange.emit(mode);
    this.formPanelVisibilityChange.emit(this.formPanelVisibility);
  }

  /**
   * emits diagram editor mode to create UI step function
   *
   * @memberof DiagramEditorComponent
   */
  onCreateUIStepFunctionButtonClick() {
    const mode: DiagramSettingsMode = {
      activeItemType: DiagramItemType.UIStepFunction,
      activeAction: DiagramActionType.Create
    };
    this.formPanelVisibility = true;
    this.diagramSettingsModeChange.emit(mode);
    this.formPanelVisibilityChange.emit(this.formPanelVisibility);
  }

  onCreateUIWorkflowFunctionButtonClick() {
    this.changeModalVisibility.emit();
  }

  setActiveUIDataStoreAndActiveItemName() {
    if (this.activeUIDataStore?.name && this.activeItem?.item?.name) {
      return `${this.activeUIDataStore?.name} | ${this.activeItem?.item?.name}`;
    } else {
      return '';
    }
  }

  changeMenuVisibility() {
    this.menuVisibility = !this.menuVisibility;
    this.menuVisibilityChange.emit(this.menuVisibility);
  }

  onShowEventsWithProcess() {
    this.showEventsWithProcessClick.emit();
  }
}
