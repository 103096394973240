import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DataSchemaState, dataSchemaFeatureKey } from './data-schema.reducer';

// Feature selector to select the entire data schema state
export const selectDataSchemaState = createFeatureSelector<DataSchemaState>(dataSchemaFeatureKey);

// Selector to get all data schemas
export const selectDataSchemas = createSelector(
  selectDataSchemaState,
  (state: DataSchemaState) => state.data
);

// Selector to check if data schemas are loading
export const selectDataSchemaLoading = createSelector(
  selectDataSchemaState,
  (state: DataSchemaState) => state.loading
);

// Selector to check if data schemas have been fully loaded
export const selectIsDataSchemaLoaded = createSelector(
  selectDataSchemaState,
  (state: DataSchemaState) => state.isLoaded
);

// Selector to get the error object
export const selectDataSchemaError = createSelector(
  selectDataSchemaState,
  (state: DataSchemaState) => state.error
);

// Selector to get the last processed action
export const selectLastProcessedAction = createSelector(
  selectDataSchemaState,
  (state: DataSchemaState) => state.lastProcessedAction
);

// Selector to check if fields have been saved
export const selectIsFieldsSaved = createSelector(
  selectDataSchemaState,
  (state: DataSchemaState) => state.isFieldsSaved
);

// Selector to get the list of fields that were not created
export const selectNotCreatedFields = createSelector(
  selectDataSchemaState,
  (state: DataSchemaState) => state.notCreatedFields
);

// Selector to get the list of fields that were not updated
export const selectNotUpdatedFields = createSelector(
  selectDataSchemaState,
  (state: DataSchemaState) => state.notUpdatedFields
);

// Selector to get a specific data schema by ID
export const selectDataSchemaById = (id: string) => createSelector(
  selectDataSchemas,
  (dataSchemas) => dataSchemas.find(dataSchema => dataSchema.id === id)
);

// Selector to get fields of a specific data schema by ID
export const selectFieldsByDataSchemaId = (id: string) => createSelector(
  selectDataSchemaById(id),
  (dataSchema) => dataSchema?.fields ?? []
);

// Selector to get a specific field by schema ID and field ID
export const selectFieldById = (schemaId: string, fieldId: string) => createSelector(
  selectFieldsByDataSchemaId(schemaId),
  (fields) => fields.find(field => field.id === fieldId)
);
