export enum TimelineItemColor {
  Gray = 'gray',
  Disabled = 'gray',
  Orange = 'orange',
  Warning = 'orange',
  InProgress = 'orange',
  OrangeRed = 'orangered',
  Error = 'orangered',
  LimeGreen = 'limegreen',
  Success = 'limegreen'
}
