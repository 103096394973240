import { ItemPerRow } from 'libs/components/src/lib/utils/card-one-list';
import { HeadingType } from 'libs/components/src/lib/utils/heading';
import { ModalComponentConfig } from 'libs/components/src/lib/utils/modal';
import { SelectMode } from 'libs/components/src/lib/utils/select';

export const DiagramWrapperCards = {
  cardsConfig: {
    itemCountPerRow: ItemPerRow.Three,
    showTitleOnImage: false,
    showDescriptionOnImage: false,
    showCheckMarkOnCard: true,
    paginationConfig: {
      pageIndex: 1,
      total: 3,
      pageSize: 6,
      showPagination: true,
      nzFrontPagination: true
    },
    selectConfig: {
      options: [],
      settings: {
        mode: SelectMode.Multiple,
        maxTagCount: 1
      },
      placeholder: 'Filter Tags'
    },
    items: []
  },
  modalConfig: {
    title: 'Select Template',
    okText: 'Select',
    fullScreen: true
  } as ModalComponentConfig,
  selectConfig: {
    options: [],
    settings: {
      mode: SelectMode.Single
    },
    placeholder: 'Select UI Data Store'
  }
};
