import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, featureKey } from './project-design-task.reducer';
import { ProjectDesignTask } from '@rappider/rappider-sdk';

// Feature Selector
export const selectProjectDesignTaskState = createFeatureSelector<State>(featureKey);

// Selectors
export const selectProjectDesignTasks = createSelector(
  selectProjectDesignTaskState,
  (state) => state.data as ProjectDesignTask[] | undefined
);

export const selectIsLoading = createSelector(
  selectProjectDesignTaskState,
  (state) => state.isLoading
);

export const selectIsLoaded = createSelector(
  selectProjectDesignTaskState,
  (state) => state.isLoaded
);


export const selectProjectDesignTasksCompleted = createSelector(
  selectProjectDesignTaskState,
  (state) => state.tasksCompleted
);

export const selectError = createSelector(
  selectProjectDesignTaskState,
  (state) => state.error
);

export const selectProjectDesignTaskById = (id: string) =>
  createSelector(selectProjectDesignTasks, (tasks) =>
    tasks ? tasks.find((task) => task.id === id) : null
  );
