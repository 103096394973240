import { IconType, MenuMode, MenuConfig, MenuActionBehavior } from '@rappider/rappider-components/utils';
import { ContentEditorRightSidebarMenuTabKey } from './content-editor-right-sidebar-menu-tab-key.enum';

export const SIDEBAR_SIZES = {
  EXPANDED: {
    size: 500,
    minSize: 400
  },
  COLLAPSED: {
    size: 50,
    minSize: 50
  }
};

export const RIGHT_SIDEBAR_MENU_CONFIG: MenuConfig = {
  items: [
    {
      label: '',
      key: ContentEditorRightSidebarMenuTabKey.Sidebar,
      icon: {
        name: 'menu-fold',
        type: IconType.NgZorro
      },
      actionBehavior: MenuActionBehavior.Emit,
    },
    {
      label: '',
      tooltip: 'Add Component',
      key: ContentEditorRightSidebarMenuTabKey.AddComponent,
      selected: true,
      icon: {
        name: 'fa-regular fa-cube',
        type: IconType.FontAwesome,
        color: '#1890ff'
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    {
      label: '',
      tooltip: 'Building Blocks Library',
      key: ContentEditorRightSidebarMenuTabKey.AddComponentAndSnippets,
      selected: true,
      icon: {
        name: 'fa-regular fa-cubes',
        type: IconType.FontAwesome,
        color: '#1890ff'
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    // {
    //   label: '',
    //   tooltip: 'Add Component',
    //   key: ContentEditorRightSidebarMenuTabKey.AddComponent,
    //   icon: {
    //     name: 'fa-regular fa-puzzle-piece-simple',
    //     type: IconType.FontAwesome
    //   },
    //   actionBehavior: MenuActionBehavior.Emit
    // },
    // {
    //   label: '',
    //   tooltip: 'Content Tree',
    //   key: ContentEditorRightSidebarMenuTabKey.ContentTree,
    //   icon: {
    //     name: 'fa-light fa-list-tree',
    //     type: IconType.FontAwesome
    //   },
    //   actionBehavior: MenuActionBehavior.Emit
    // },
    // {
    //   label: '',
    //   tooltip: 'Page Variables',
    //   key: ContentEditorRightSidebarMenuTabKey.PageVariables,
    //   icon: {
    //     name: 'fa-regular fa-file-lines',
    //     type: IconType.FontAwesome
    //   },
    //   actionBehavior: MenuActionBehavior.Emit
    // },
    // {
    //   label: '',
    //   tooltip: 'Settings',
    //   key: ContentEditorRightSidebarMenuTabKey.Settings,
    //   icon: {
    //     name: 'fa-regular fa-sliders',
    //     type: IconType.FontAwesome
    //   },
    //   actionBehavior: MenuActionBehavior.Emit
    // },
    // {
    //   label: '',
    //   tooltip: 'Page Lifecycle',
    //   key: ContentEditorRightSidebarMenuTabKey.PageLifecycle,
    //   icon: {
    //     name: 'fa-sharp fa-light fa-circle-bolt',
    //     type: IconType.FontAwesome
    //   },
    //   actionBehavior: MenuActionBehavior.Emit
    // },
    // {
    //   label: '',
    //   tooltip: 'Comments',
    //   key: ContentEditorRightSidebarMenuTabKey.Comments,
    //   icon: {
    //     name: 'fa-light fa-comments',
    //     type: IconType.FontAwesome
    //   },
    //   actionBehavior: MenuActionBehavior.Emit
    // },
    // {
    //   label: '',
    //   tooltip: 'Pages',
    //   key: ContentEditorRightSidebarMenuTabKey.Pages,
    //   icon: {
    //     name: 'fa-light fa-files',
    //     type: IconType.FontAwesome
    //   },
    //   actionBehavior: MenuActionBehavior.Emit
    // },
    // {
    //   label: '',
    //   tooltip: 'Layouts',
    //   key: ContentEditorRightSidebarMenuTabKey.Layouts,
    //   icon: {
    //     name: 'fa-light fa-table-layout',
    //     type: IconType.FontAwesome
    //   },
    //   actionBehavior: MenuActionBehavior.Emit
    // }
  ],
  mode: MenuMode.Inline
};
