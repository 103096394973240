import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzCardModule } from 'ng-zorro-antd/card';

import { RappiderPostItComponent } from './post-it.component';

@NgModule({
  declarations: [
    RappiderPostItComponent
  ],
  imports: [
    CommonModule,
    NzCardModule,
    FormsModule
  ],
  exports: [
    RappiderPostItComponent
  ]
})
export class RappiderPostItModule { }


export enum PostItColor {
  Yellow = 'yellow',
  Pink = 'pink',
  Blue = 'blue',
  Green = 'green',
}

export enum PostItSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

