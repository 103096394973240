<div class="source-files-container">
  <div class="search-bar-container">
    <div class="search-area">
      <rappider-input-group class="input-group"
                            [(ngModel)]="searchWord"
                            [suffixIcon]="searchTextBoxConfig.suffixIcon"
                            [textbox]="searchTextBoxConfig.textbox"
                            (ngModelChange)="onSearchFiles($event)">
      </rappider-input-group>
    </div>
  </div>

  <div class="source-files-tree-container">
    <nz-skeleton *ngIf="!projectSourceFilesTreeData.length"
                 [nzActive]="true"
                 [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    <nz-tree *ngIf="projectSourceFilesTreeData.length"
             nzBlockNode
             nzShowIcon
             [nzData]="projectSourceFilesTreeData"
             [nzExpandedIcon]="nodeTemplate"
             [nzSearchValue]="searchWord"
             (nzClick)="onTreeNodeClicked($event)"></nz-tree>

    <ng-template #nodeTemplate
                 let-node
                 let-origin="origin">
      <ng-container *ngIf="origin.isLeaf">
        <i class="fa-light fa-file-code"></i>
      </ng-container>

      <ng-container *ngIf="!origin.isLeaf">
        <i nz-icon
           class="folder-node-icon"
           [nzType]="node?.isExpanded ? 'down' : 'right'"
           nzTheme="outline"></i>
      </ng-container>
    </ng-template>

  </div>
</div>
