import {
    CodeMirrorMode,
    CodeMirrorTheme,
    CrudFormCodeMirrorItem,
    CrudFormConfig,
    CrudFormConfigInputChangeReaction,
    CrudFormValueEmitMode,
    CrudViewFormItemType,
    FormLayout
} from '@rappider/rappider-components/utils';

export const CONTAINER_CSS_SETTINGS_FORM_CONFIG: CrudFormConfig = {
    formLayout: FormLayout.Vertical,
    itemSettings: {
        inputComponentSize: { xs: 24 },
        labelComponentSize: { xs: 24 },
    },
    inputChangeReaction: CrudFormConfigInputChangeReaction.Blur,
    submitButton: {
        visible: false
    },
    formValueEmitMode: CrudFormValueEmitMode.ReturnAllValues,
    items: [
        {
            fieldName: 'cssClasses',
            type: CrudViewFormItemType.TagInput
        }
    ]
};
