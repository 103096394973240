import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as DeployManagementActions from './deploy-management.actions';
import { catchError, concatMap, delay, map, mergeMap, repeatWhen, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DevopsManagementControllerService, DevopsManagementDeployResponseDto, GenerateCodePreviewResponseDto, GitActionWorkflowRunDetailResponseDto, GitInfoControllerService, SourceCodeControllerService } from '@rappider/rappider-sdk';
import { JsonValidationService, NotificationService } from '@rappider/services';
import { environment } from '@environment';
import { LivePreviewInformationStatus } from '@rappider/shared/interfaces';
import { Navigate } from '@rappider/shared';
import { of } from 'rxjs';

@Injectable()
export class DeployManagementEffects {
  private livePreviewInformationTryCount = 0;
  private continuePollingAfterSuccessAndFailure = false;

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private devopsManagementControllerService: DevopsManagementControllerService,
    private sourceCodeControllerService: SourceCodeControllerService,
    private jsonValidationService: JsonValidationService,
    private gitInfoControllerService: GitInfoControllerService,
    private store: Store<any>
  ) { }

  deployProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeployManagementActions.DeployProject),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        /* navigate to live preview page */
        this.store.dispatch(new Navigate({ url: `projects/live-preview/${activeProjectId}` }));
        /* call deploy api */
        return this.devopsManagementControllerService.deploy().pipe(
          mergeMap((response: DevopsManagementDeployResponseDto) => [
            DeployManagementActions.DeployProjectSuccessful({ backendURL: response.backendURL, frontendURL: response.frontendURL }),
          ]),
          catchError(error => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_DEPLOYED'
            );
            return [
              DeployManagementActions.DeployProjectFailure({ error, key: 'DeployProject', timeStamp: Date.now() })
            ];
          })
        );
      })
    )
  );

  getGitActionWorkflowRunDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeployManagementActions.GetGitActionWorkflowRunDetails),
      mergeMap((action) => this.gitInfoControllerService.getGitActionWorkflowRunByRunId({ runId: action.payload.id }).pipe(
        map((runDetails: GitActionWorkflowRunDetailResponseDto) => DeployManagementActions.GetGitActionWorkflowRunDetailsSuccessful({ runDetails }))
      )),
      catchError(error => [
        DeployManagementActions.GetGitActionWorkflowRunDetailsFailure({
          payload: {
            key: DeployManagementActions.ActionTypes.GetGitActionWorkflowRunDetails,
            error: error.message,
            timeStamp: new Date().getTime()
          }
        })
      ])
    )
  );

  getLivePreviewInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeployManagementActions.GetLivePreviewInformation),
      concatMap((action) => {
        this.continuePollingAfterSuccessAndFailure = action.continuePollingAfterSuccessAndFailure;

        return this.sourceCodeControllerService.getFileContent({ filePath: environment.livePreviewInformationFilePath, fromRemote: true }).pipe(
          mergeMap((res: GenerateCodePreviewResponseDto) => {
            const infoJson = res ? res.code : {};
            const parsedData = this.jsonValidationService.validateStringifiedJson(infoJson, true).dataAsJson;
            return [DeployManagementActions.GetLivePreviewInformationSuccessful({ data: parsedData })];
          }),
          delay(environment.delayTimeForDeployManagementInformationApiCall),
          catchError(error => of(
            DeployManagementActions.GetLivePreviewInformationFailure({
              error,
              key: 'GetLivePreviewInformation',
              timeStamp: Date.now()
            }),
            DeployManagementActions.StopLivePreviewInformationPolling()
          ).pipe(
            delay(environment.delayTimeForDeployManagementInformationApiCall)
          ))
        );
      }),
      switchMap(action => this.handleLivePreviewInformationAction(action)),
      // stop polling
      takeUntil(this.actions$.pipe(ofType(DeployManagementActions.StopLivePreviewInformationPolling))),
      // continue polling
      repeatWhen((action) => action.pipe(tap(() => { })))
    )
  );

  private handleLivePreviewInformationAction(action: any): Action[] {
    if (
      this.continuePollingAfterSuccessAndFailure &&
      action.type !== DeployManagementActions.ActionTypes.GetLivePreviewInformationFailure
    ) {
      return [
        DeployManagementActions.SetLivePreviewInformation({ data: action.data }),
        DeployManagementActions.GetLivePreviewInformation({
          continuePollingAfterSuccessAndFailure: this.continuePollingAfterSuccessAndFailure
        })
      ];
    } else {
      if (action.type === DeployManagementActions.ActionTypes.GetLivePreviewInformationSuccessful) {
        return this.handleLivePreviewData(action);
      } else if (action.type === DeployManagementActions.ActionTypes.GetLivePreviewInformationFailure) {
        return this.handleLivePreviewInformationFailure();
      }
    }
  }

  private handleLivePreviewData(action: any): Action[] {
    const { data } = action;

    if (data?.latest_build?.status === LivePreviewInformationStatus.InProgress) {
      return [
        DeployManagementActions.SetLivePreviewInformation({ data }),
        DeployManagementActions.GetLivePreviewInformation({
          continuePollingAfterSuccessAndFailure: this.continuePollingAfterSuccessAndFailure
        })
      ];
    } else if (data?.latest_build?.status === LivePreviewInformationStatus.Success) {
      return [
        DeployManagementActions.SetLivePreviewInformation({ data }),
        DeployManagementActions.StopLivePreviewInformationPolling()
      ];
    } else {
      return [
        DeployManagementActions.SetLivePreviewInformation({ data }),
        DeployManagementActions.StopLivePreviewInformationPolling()
      ];
    }
  }

  private handleLivePreviewInformationFailure(): Action[] {
    // Check if the maximum number of API calls has not been exceeded
    if (this.livePreviewInformationTryCount < environment.maxNumberOfApiCallsForDeployInformation) {
      this.livePreviewInformationTryCount++;
      // Recursive call to fetch live preview information
      return [DeployManagementActions.GetLivePreviewInformation({
        continuePollingAfterSuccessAndFailure: this.continuePollingAfterSuccessAndFailure
      })];
    } else {
      // Stop polling and create an error notification
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_DEPLOYED'
      );
      return [DeployManagementActions.StopLivePreviewInformationPolling()];
    }
  }
}
