/* eslint-disable no-case-declarations */
import { ProjectModelWithRelations } from '@rappider/rappider-sdk';
import * as ProjectModelActions from './project-model.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { orderBy } from 'lodash';

export const projectModelFeatureKey = 'projectModel';

export interface ProjectModelState {
  data: ProjectModelWithRelations[];
  loading: boolean;
  isCrudPagesLoading: boolean; /* flag for loading state of create crud pages */
  createProjectModelsFromJSONModalVisibility: boolean;
  generatingDataStoreModelIds: Array<string>;
  error: {
    error?: any;
    timestamp?: number;
    key?: string;
    projectModelId?: string;
  };
  deletingProjectModelIds: string[];
  isLoaded: boolean;
}

export const projectModelInitialState: ProjectModelState = {
  data: [],
  loading: true,
  isCrudPagesLoading: false,
  createProjectModelsFromJSONModalVisibility: false,
  generatingDataStoreModelIds: [],
  error: null,
  deletingProjectModelIds: [],
  isLoaded: false
};

export function projectModelReducer(
  state: ProjectModelState = projectModelInitialState,
  action: ProjectModelActions.Actions | AuthenticationActions.Actions
): ProjectModelState {
  switch (action.type) {
    case ProjectModelActions.ActionTypes.CreateProjectModel:
      return {
        ...state,
        loading: true
      };

    case ProjectModelActions.ActionTypes.GetProjectModelsSuccessful:
      return {
        ...state,
        data: orderBy(action.payload.projectModels, 'createdDate', 'asc'),
        loading: false,
        isLoaded: true
      };

    case ProjectModelActions.ActionTypes.GetProjectModelWithModelIdSuccessful:
      return {
        ...state,
        data: orderBy([
          ...state.data.filter(projectModel => projectModel.id !== action.payload.projectModel.id),
          action.payload.projectModel
        ], 'createdDate', 'asc'),
        loading: false,
        isLoaded: true
      };

    case ProjectModelActions.ActionTypes.CreateProjectModelSuccessful:
      return {
        ...state,
        data: orderBy([
          ...state.data,
          action.payload.projectModel
        ], 'createdDate', 'asc'),
        loading: false
      };

    case ProjectModelActions.ActionTypes.UpdateProjectModel:
      return {
        ...state,
        loading: true
      };

    case ProjectModelActions.ActionTypes.UpdateProjectModelSuccessful:
      const projectModels = orderBy([
        ...state.data.filter(projectModel => projectModel.id !== action.payload.id),
        action.payload.projectModel
      ], 'name', 'asc');

      return {
        ...state,
        data: projectModels,
        loading: false
      };

    case ProjectModelActions.ActionTypes.UpdateProjectModelWithRelations: {
      const updatedProjectModelsWithRelations = state.data.map((model) => {
        const relatedRelation = action.payload.projectModelRelations.find(
          (relation) => relation.sourceModelId === model.id
        );

        if (relatedRelation) {
          const relationAlreadyExists = model?.relations?.some((existingRelation) =>
            existingRelation.keyFromId === relatedRelation.keyFromId &&
            existingRelation.keyToId === relatedRelation.keyToId &&
            existingRelation.sourceModelId === relatedRelation.sourceModelId &&
            existingRelation.targetModelId === relatedRelation.targetModelId
          );

          if (!relationAlreadyExists) {
            return {
              ...model,
              relations: model?.relations?.length > 0 ? [...model.relations, relatedRelation] : [relatedRelation],
            };
          }
        }

        return model;
      });

      return {
        ...state,
        data: updatedProjectModelsWithRelations
      };
    };

    case ProjectModelActions.ActionTypes.DeleteProjectModelSuccessful:
      const newData = state.data.filter(projectModel => projectModel.id !== action.payload.id);
      return {
        ...state,
        data: newData,
        loading: false,
        deletingProjectModelIds: state.deletingProjectModelIds.filter(id => id !== action.payload.id)
      };

    case ProjectModelActions.ActionTypes.DeleteProjectModel:
      return {
        ...state,
        deletingProjectModelIds: [...state.deletingProjectModelIds, action.payload.id],
        loading: true
      };

    case ProjectModelActions.ActionTypes.CreateProjectModelFieldSuccessful:
      const createdFieldProjectModel = state.data
        .find(projectModel => projectModel.id === action.payload.createdProjectModelField.projectModelId);
      const createdFieldNewProjectModel = {
        ...createdFieldProjectModel,
        fields: orderBy([
          ...createdFieldProjectModel.fields,
          action.payload.createdProjectModelField
        ], 'index', 'asc')
      };
      const stateDataWithoutCreatedFieldProjectModel = state.data.filter(projectModel => projectModel.id !== createdFieldProjectModel.id);
      return {
        ...state,
        data: [
          ...stateDataWithoutCreatedFieldProjectModel,
          createdFieldNewProjectModel
        ],
        loading: false
      };

    case ProjectModelActions.ActionTypes.UpdateProjectModelFieldSuccessful:
      return {
        ...state,
        loading: false
      };

    case ProjectModelActions.ActionTypes.DeleteProjectModelFieldSuccessful:
      const deletedFieldProjectModel = state.data
        .find(projectModel => projectModel.id === action.payload.projectModelId);
      const deletedFieldNewProjectModel = {
        ...deletedFieldProjectModel,
        fields: deletedFieldProjectModel.fields.filter(field => field.id !== action.payload.deletedProjectModelFieldId)
      };
      const stateDataWithoutDeletedFieldProjectModel = state.data.filter(projectModel => projectModel.id !== deletedFieldProjectModel.id);
      return {
        ...state,
        data: [
          ...stateDataWithoutDeletedFieldProjectModel,
          deletedFieldNewProjectModel
        ],
        loading: false
      };

    case ProjectModelActions.ActionTypes.CreateProjectModelsFromJSON:
      return {
        ...state,
        loading: true
      };

    case ProjectModelActions.ActionTypes.CreateProjectModelsFromJSONSuccessful:
      return {
        ...state,
        loading: false
      };

    case ProjectModelActions.ActionTypes.ToggleCreateProjectModelsFromJSONModal:
      return {
        ...state,
        createProjectModelsFromJSONModalVisibility: !state.createProjectModelsFromJSONModalVisibility
      };

    case ProjectModelActions.ActionTypes.CreateCrudPagesForProjectModel:
      return {
        ...state,
        isCrudPagesLoading: true
      };

    case ProjectModelActions.ActionTypes.CreateCrudPagesForProjectModelSuccessful:
      return {
        ...state,
        isCrudPagesLoading: false,
        data: state.data.map(projectModel =>
          projectModel.id === action.payload.projectModel.id
            ? { ...projectModel, ...action.payload.projectModel }
            : projectModel
        )
      };

    case ProjectModelActions.ActionTypes.CreateCrudPagesForProjectModelFailure:
      return {
        ...state,
        isCrudPagesLoading: false,
        error: {
          error: action.payload.error,
          timestamp: action.payload.timestamp,
          key: action.payload.key
        },
      };

    case ProjectModelActions.ActionTypes.ErrorAction:
      return {
        ...state,
        error: {
          ...state.error,
          error: action.payload.error,
          timestamp: action.payload.timestamp,
          key: action.payload.key
        },
        loading: false
      };

    case ProjectModelActions.ActionTypes.GenerateDataStore:
      return {
        ...state,
        generatingDataStoreModelIds: [...state.generatingDataStoreModelIds, action.payload.projectModelId]
      };

    case ProjectModelActions.ActionTypes.GenerateDataStoreSuccessful: {
      const updatedProjectModel = {
        ...state.data.find(projectModel => projectModel.id === action.payload.projectModelId),
      };
      updatedProjectModel.generatedUIDataStore = action.payload.generatedUIDataStore;
      updatedProjectModel.generateUIDataStore = true;
      const filteredGenerateDataStoreModelIds = state.generatingDataStoreModelIds.filter(generateDataStore => !generateDataStore.includes(action.payload.projectModelId));
      return {
        ...state,
        data: [
          ...state.data.filter(projectModel => projectModel.id !== action.payload.projectModelId),
          updatedProjectModel
        ],
        generatingDataStoreModelIds: filteredGenerateDataStoreModelIds
      };
    }

    case ProjectModelActions.ActionTypes.GenerateDataStoreFailure:
      const filteredGenerateDataStoreModelIds = state.generatingDataStoreModelIds.filter(generateDataStore => !generateDataStore.includes(action.payload.projectModelId));
      return {
        ...state,
        isCrudPagesLoading: false,
        error: {
          error: action.payload.error,
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          projectModelId: action.payload.projectModelId
        },
        generatingDataStoreModelIds: filteredGenerateDataStoreModelIds
      };

    case ProjectModelActions.ActionTypes.CreateProjectModelsFromPostgreMetadata:
      return {
        ...state,
        loading: true
      };

    case ProjectModelActions.ActionTypes.CreateProjectModelsFromPostgreMetadataSuccessful:
      return {
        ...state,
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return projectModelInitialState;

    case AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful:
      return projectModelInitialState;

    default:
      return state;
  }
}
