<div *ngIf="isVisible"
     class="content-tree-item-title-bar"
     [ngClass]="{'at-top' : isContentAtTop }">
  <div class="title-bar-container">
    <!-- Title ve Dropdown Grubu -->
    <div class="title-group"
         [style.background-color]="isHovered ? contentTreeItemTitleBarHoverColor : contentTreeItemTitleBarColor">
      <div class="title">
        {{ title }}
      </div>
      <div *ngIf="dropdownConfig && !isHovered"
           class="dropdown-wrapper">
        <rappider-dropdown-menu [icon]="dropdownConfig.icon"
                                [items]="dropdownConfig.items"
                                [label]="dropdownConfig.label"
                                [labelMode]="dropdownConfig.labelMode"
                                [placement]="dropdownConfig.placement"
                                [triggerType]="dropdownConfig.triggerType"
                                (menuItemClick)="onMenuItemClick($event)">
        </rappider-dropdown-menu>
      </div>
      <!-- <ng-container *ngIf="contentTreeItem.type === ContentTreeItemType.Component">
				<div *ngIf="contentTreeItem?.component?.dataSubscriptions?.length"
						 class="display-data-subscriptions"
						 nz-popover
						 nzPopoverTitle="Data Subscriptions"
						 nzPopoverTrigger="click"
						 [nzPopoverOverlayStyle]="{'max-width': '500px'}"
						 nz-tooltip
						 nzTooltipTitle="Click to display data subscriptions"
						 [nzPopoverContent]="componentDataSubscriptionsTemplate">
					&nbsp;
					<i class="fa-regular fa-database"></i>
				</div>
			</ng-container> -->
    </div>

    <!-- Butonlar Grubu -->
    <div *ngIf="isRightSideVisible && !isHovered"
         class="buttons-group"
         [style.background-color]="isHovered ? contentTreeItemTitleBarHoverColor : contentTreeItemTitleBarColor">
      <ng-container *ngFor="let button of buttons">
        <div *ngIf="buttonVisibilityFunction(button, contentTreeItem, hasClipboardData)"
             class="button">
          <rappider-button [icon]="button.icon"
                           [tooltipText]="button.tooltipText"
                           [popconfirmTitle]="button.popconfirmTitle"
                           [emitWithoutPopconfirm]="button.emitWithoutPopconfirm"
                           [popconfirmCancelText]="button.popconfirmCancelText"
                           [popconfirmOkText]="button.popconfirmCancelText"
                           [popconfirmOkDanger]="button.popconfirmOkDanger"
                           (confirm)="onButtonClick(button)">
          </rappider-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>


<ng-template #componentDataSubscriptionsTemplate>
  <ng-container *ngIf="contentTreeItem.type === ContentTreeItemType.Component">
    <section *ngFor="let dataSubscription of contentTreeItem?.component?.dataSubscriptions"
             class="data-subscription-item">
      <div class="data-subscription-item-preview">
        {{dataSubscription.inputDefinition.title}} = {{dataSubscription.jsonataConfig}}
      </div>
      <div class="data-subscription-item-controllers">
        <i class="fa-regular fa-pen-to-square"
           (click)="onDataSubscriptionItemControllerClick('edit', dataSubscription)"></i>
        <i class="fa-regular fa-trash"
           (click)="onDataSubscriptionItemControllerClick('delete', dataSubscription)"></i>
      </div>
    </section>
  </ng-container>
</ng-template>
