<dx-diagram #diagram
            id="diagram"
            class="diagram-container"
            keyExpr="id"
            [zoomLevel]="DiagramEditorConfig.zoomLevel"
            [simpleView]="DiagramEditorConfig.simpleView"
            [readOnly]="true"
            (onSelectionChanged)="onSelectionChanged($event)"
            (onItemClick)="onItemClick($event)">
  <dxo-nodes [dataSource]="dataSource"
             [textExpr]="DiagramNodesConfig.textExpr"
             [itemsExpr]="DiagramNodesConfig.itemsExpr"
             [textStyleExpr]="customizeTextStyle"
             [autoSizeEnabled]="DiagramNodesConfig.autoSizeEnabled">
    <dxo-auto-layout [type]="DiagramAutoLayoutConfig.type"
                     [orientation]="DiagramAutoLayoutConfig.orientation"></dxo-auto-layout>
  </dxo-nodes>

  <svg *dxTemplate="let item of 'uiStepFunctionWithRelationsTemplate'"
       class="template">
    <rect class="rectangle-item"
          x="0"
          y="40%"
          width="288"
          height="100"></rect>
    <g *ngIf="item.dataItem.uiDataStoreSubscriptions?.length">
      <text class="template-name"
            x="30%"
            y="6%">
        {{ 'PROJECT_MODULE.SUBSCRIBE_TO_UI_DATA_STORE_COMPONENT.UI_DATA_STORE_SUBSCRIPTIONS' | translate }}
      </text>
      <ng-container *ngFor="let subscriptionData of item.dataItem.uiDataStoreSubscriptions; let i = index">
        <text *ngIf="i < 3; else ellipsisTemplate"
              [attr.x]="'65%'"
              [attr.y]="setUIDataStoreSubscriptionPosition(i)">
          {{subscriptionData.uiDataStore?.name}}.{{setUIDataStoreSubscriptions(subscriptionData.dataSchemaTreeField?.nodes)}}
        </text>
      </ng-container>
      <ng-template #ellipsisTemplate>
        <text style="font-size: 2.143rem"
              x="65%"
              y="38%">
          ...
        </text>
      </ng-template>
      <image href="assets/icons/database-icon.svg"
             height="45"
             width="45"
             x="20%"
             y="8%"></image>
      <line x1="80"
            y1="85"
            x2="80"
            y2="190"
            stroke="black"
            stroke-dasharray="5, 5" />
    </g>
    <g *ngIf="item.dataItem.endpoint">
      <line x1="80"
            y1="295"
            x2="80"
            y2="390"
            stroke="black"
            stroke-dasharray="5, 5" />
      <image href="assets/icons/api-icon.png"
             height="40"
             width="40"
             x="21%"
             y="83%"></image>
      <text class="template-name"
            x="27%"
            y="95%">
        {{ findProjectModelNameById(item.dataItem?.endpoint?.modelId) }}.{{ item.dataItem.endpoint?.name }}
      </text>
    </g>
    <g *ngIf="item.dataItem?.comments?.length || activeItem?.item?.id === item.dataItem?.id">
      <image href="assets/icons/comment-lines-regular.svg"
             class="comment-icon"
             height="30"
             width="30"
             x="85%"
             y="32%"
             (click)="navigatePanelCommentsTab()"></image>
    </g>
    <g *ngIf="item.dataItem.workflowServiceId">
      <line x1="80"
            y1="295"
            x2="80"
            y2="390"
            stroke="black"
            stroke-dasharray="5, 5" />
      <image href="assets/icons/light-window-gear.svg"
             height="55"
             width="55"
             x="21%"
             y="80%"></image>
      <text class="template-name"
            x="29%"
            y="96%">
        {{ findWorkflowStepFunctionById(item.dataItem.workflowServiceId)?.name }}
      </text>
    </g>
    <g *ngIf="item.dataItem.workflowStepFunctionId">
      <line x1="80"
            y1="295"
            x2="80"
            y2="390"
            stroke="black"
            stroke-dasharray="5, 5" />
      <image href="assets/icons/light-server-gear.svg"
             height="55"
             width="55"
             x="21%"
             y="80%"></image>
      <text class="template-name"
            x="29%"
            y="96%">
        {{ findWorkflowStepFunctionById(item.dataItem.workflowStepFunctionId)?.name }}
      </text>
    </g>
    <g *ngIf="item.dataItem.workflowEventId">
      <line x1="80"
            y1="295"
            x2="80"
            y2="390"
            stroke="black"
            stroke-dasharray="5, 5" />
      <image href="assets/icons/light-circle-gear.svg"
             height="55"
             width="55"
             x="21%"
             y="80%"></image>
      <text class="template-name"
            x="29%"
            y="96%">
        {{ findWorkflowEventFunctionById(item.dataItem.workflowEventId)?.name }}
      </text>
    </g>
  </svg>

  <svg *dxTemplate="let item of 'uiEventTemplate'">
    <g *ngIf="item.dataItem?.comments?.length || activeItem?.item?.id === item.dataItem?.id">
      <image href="assets/icons/comment-lines-regular.svg"
             class="comment-icon"
             height="30"
             width="30"
             x="65%"
             y="32%"
             (click)="navigatePanelCommentsTab()"></image>
    </g>
  </svg>

  <svg *dxTemplate="let item of 'uiStepFunctionTemplate'"
       class="template">
    <rect class="rectangle-item"
          x="0"
          y="24%"
          width="288"
          height="100"></rect>
    <!-- COMMENT ICON FOR UI EVENT NODE -->
    <g *ngIf="item.dataItem?.comments?.length || activeItem?.item?.id === item.dataItem?.id">
      <image href="assets/icons/comment-lines-regular.svg"
             class="comment-icon"
             height="30"
             width="30"
             x="85%"
             y="10"
             (click)="navigatePanelCommentsTab()"></image>
    </g>
  </svg>

  <!-- STANDARD UI DATA EVENT ITEM -->
  <dxi-custom-shape [type]="DiagramUIDataEventItemConfig.type"
                    [defaultText]="DiagramUIDataEventItemConfig.defaultText"
                    [backgroundImageUrl]="DiagramUIDataEventItemConfig.backgroundImageUrl"
                    [defaultWidth]="DiagramUIDataEventItemConfig.defaultWidth"
                    [defaultHeight]="DiagramUIDataEventItemConfig.defaultHeight"
                    [backgroundImageWidth]="DiagramUIDataEventItemConfig.backgroundImageWidth"
                    [backgroundImageHeight]="DiagramUIDataEventItemConfig.backgroundImageHeight"
                    [backgroundImageLeft]="DiagramUIDataEventItemConfig.backgroundImageLeft"
                    [backgroundImageTop]="DiagramUIDataEventItemConfig.backgroundImageTop"
                    [textTop]="DiagramUIDataEventItemConfig.textTop"
                    template="uiEventTemplate">
    <dxi-connection-point [x]="DiagramUIDataEventItemConfig.connectionPoints.top.x"
                          [y]="DiagramUIDataEventItemConfig.connectionPoints.top.y"></dxi-connection-point>
    <dxi-connection-point [x]="DiagramUIDataEventItemConfig.connectionPoints.right.x"
                          [y]="DiagramUIDataEventItemConfig.connectionPoints.right.y"></dxi-connection-point>
    <dxi-connection-point [x]="DiagramUIDataEventItemConfig.connectionPoints.bottom.x"
                          [y]="DiagramUIDataEventItemConfig.connectionPoints.bottom.y"></dxi-connection-point>
    <dxi-connection-point [x]="DiagramUIDataEventItemConfig.connectionPoints.left.x"
                          [y]="DiagramUIDataEventItemConfig.connectionPoints.left.y"></dxi-connection-point>
  </dxi-custom-shape>

  <!-- TIMER UI DATA EVENT ITEM -->
  <dxi-custom-shape [type]="DiagramUIScheduledDataEventItemConfig.type"
                    [defaultText]="DiagramUIScheduledDataEventItemConfig.defaultText"
                    [backgroundImageUrl]="DiagramUIScheduledDataEventItemConfig.backgroundImageUrl"
                    [defaultWidth]="DiagramUIScheduledDataEventItemConfig.defaultWidth"
                    [defaultHeight]="DiagramUIScheduledDataEventItemConfig.defaultHeight"
                    [backgroundImageWidth]="DiagramUIScheduledDataEventItemConfig.backgroundImageWidth"
                    [backgroundImageHeight]="DiagramUIScheduledDataEventItemConfig.backgroundImageHeight"
                    [backgroundImageLeft]="DiagramUIScheduledDataEventItemConfig.backgroundImageLeft"
                    [backgroundImageTop]="DiagramUIScheduledDataEventItemConfig.backgroundImageTop"
                    [textTop]="DiagramUIScheduledDataEventItemConfig.textTop">
    <dxi-connection-point [x]="DiagramUIScheduledDataEventItemConfig.connectionPoints.top.x"
                          [y]="DiagramUIScheduledDataEventItemConfig.connectionPoints.top.y"></dxi-connection-point>
    <dxi-connection-point [x]="DiagramUIScheduledDataEventItemConfig.connectionPoints.right.x"
                          [y]="DiagramUIScheduledDataEventItemConfig.connectionPoints.right.y"></dxi-connection-point>
    <dxi-connection-point [x]="DiagramUIScheduledDataEventItemConfig.connectionPoints.bottom.x"
                          [y]="DiagramUIScheduledDataEventItemConfig.connectionPoints.bottom.y"></dxi-connection-point>
    <dxi-connection-point [x]="DiagramUIScheduledDataEventItemConfig.connectionPoints.left.x"
                          [y]="DiagramUIScheduledDataEventItemConfig.connectionPoints.left.y"></dxi-connection-point>
  </dxi-custom-shape>

  <!-- UI STEP FUNCTION WITH RELATIONS ITEM -->
  <dxi-custom-shape [type]="DiagramUIStepFunctionWithRelationsItemConfig.type"
                    [baseType]="DiagramUIStepFunctionWithRelationsItemConfig.baseType"
                    [allowEditText]="DiagramUIStepFunctionWithRelationsItemConfig.allowEditText"
                    [defaultWidth]="DiagramUIStepFunctionWithRelationsItemConfig.defaultWidth"
                    [defaultHeight]="DiagramUIStepFunctionWithRelationsItemConfig.defaultHeight"
                    template="uiStepFunctionWithRelationsTemplate">
  </dxi-custom-shape>

  <!-- UI STEP FUNCTION ITEM -->
  <dxi-custom-shape [type]="DiagramUIStepFunctionItemConfig.type"
                    [defaultText]="DiagramUIStepFunctionItemConfig.defaultText"
                    [baseType]="DiagramUIStepFunctionItemConfig.baseType"
                    [allowEditText]="DiagramUIStepFunctionItemConfig.allowEditText"
                    [defaultWidth]="DiagramUIStepFunctionItemConfig.defaultWidth"
                    [defaultHeight]="DiagramUIStepFunctionItemConfig.defaultHeight"
                    template="uiStepFunctionTemplate">
  </dxi-custom-shape>

  <!-- EXCLUSIVE SYMBOL (CONDITION) -->
  <dxi-custom-shape [type]="ExclusiveConditionItemConfig.type"
                    [backgroundImageUrl]="ExclusiveConditionItemConfig.backgroundImageUrl"
                    [defaultWidth]="ExclusiveConditionItemConfig.defaultWidth"
                    [defaultHeight]="ExclusiveConditionItemConfig.defaultHeight"
                    [backgroundImageWidth]="ExclusiveConditionItemConfig.backgroundImageWidth"
                    [backgroundImageHeight]="ExclusiveConditionItemConfig.backgroundImageHeight"
                    [backgroundImageLeft]="ExclusiveConditionItemConfig.backgroundImageLeft"
                    [backgroundImageTop]="ExclusiveConditionItemConfig.backgroundImageTop">
    <dxi-connection-point [x]="ExclusiveConditionItemConfig.connectionPoints.top.x"
                          [y]="ExclusiveConditionItemConfig.connectionPoints.top.y"></dxi-connection-point>
    <dxi-connection-point [x]="ExclusiveConditionItemConfig.connectionPoints.right.x"
                          [y]="ExclusiveConditionItemConfig.connectionPoints.right.y"></dxi-connection-point>
    <dxi-connection-point [x]="ExclusiveConditionItemConfig.connectionPoints.bottom.x"
                          [y]="ExclusiveConditionItemConfig.connectionPoints.bottom.y"></dxi-connection-point>
    <dxi-connection-point [x]="ExclusiveConditionItemConfig.connectionPoints.left.x"
                          [y]="ExclusiveConditionItemConfig.connectionPoints.left.y"></dxi-connection-point>
  </dxi-custom-shape>

  <!-- The Diagram UI component allows you to customize its UI tools with the following properties. -->
  <!-- https://js.devexpress.com/Documentation/Guide/UI_Components/Diagram/Diagram_Tools/?search=dxo-context-toolbox -->
  <dxo-context-menu [enabled]="true"
                    [commands]='["bringToFront", "sendToBack", "lock", "unlock"]'>
  </dxo-context-menu>

  <dxo-view-toolbar [visible]="true">
    <dxi-command name="zoomLevel"></dxi-command>
    <dxi-command name="export"
                 icon="export"
                 [items]='["exportSvg","exportPng","exportJpg"]'></dxi-command>
  </dxo-view-toolbar>

  <dxo-toolbox visibility="false"></dxo-toolbox>
  <dxo-history-toolbar [visible]="false"></dxo-history-toolbar>

  <!-- ------------------------------------------------------------------------------------ -->
</dx-diagram>
