import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store, createSelector } from '@ngrx/store';
import { CreatePage, GetTemplatePages, GetTemplatePagesPagination } from '../../state/page-state/page.actions';
import { pageConfigFieldNames, PAGE_CREATE_FORM_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PAGE_DEFINITIONS, PATH_DEFINITIONS, PAGE_PATH_REGEX } from '@rappider/shared/definitions';
import { BreadcrumbOption, CrudFormButtonItem, CrudFormSelectCardItem, CrudFormSelectItem, HeadingComponentConfig, HeadingType, TextComponentConfig, TextMode } from '@rappider/rappider-components/utils';
import { NotificationService, StringUtilityService } from '@rappider/services';
import { StringTransformService } from 'libs/shared/src/lib/services/string-transform-service/string-transform.service';
import { Category, Module, NewPage, Page, PagePartial, PageWithRelations, ProjectSetting, ProjectWithRelations } from '@rappider/rappider-sdk';
import { BlankTemplate } from 'libs/components/src/lib/components/card-selector/utils/blank-template';
import { SelectedDataBehaviour } from 'libs/components/src/lib/components/card-selector/utils/selected-data-behaviour.enum';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';
import { getCreationInformationFromEntity } from '@rappider/shared-functions';
import { nameExistsValidator } from 'libs/shared/src/lib/functions/name-exists-validator';
import { Validators } from '@angular/forms';
import { getTemplatePagesWithTags } from 'libs/page-templates/src/lib/components/page-templates/utils/get-template-pages-with-tags-selector';
@Component({
  selector: 'rappider-page-create',
  templateUrl: './page-create.component.html',
  styleUrls: ['./page-create.component.scss']
})
export class PageCreateComponent implements OnInit, OnDestroy {

  templatePages: PageWithRelations[];
  /* page create edit form config */
  PAGE_CREATE_FORM_CONFIG = PAGE_CREATE_FORM_CONFIG;
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_CREATE.PAGE_TITLE,
    type: defaultToolbarTitleHeadingSize
  };
  /* component breadcrumb title */
  title: string | string[] | BreadcrumbOption[];
  /* active project information from state */
  activeProject: ProjectWithRelations;
  /* layout options */
  layoutOptions: { key: string; value: string }[];
  /* module options */
  moduleOptions: { key: string; value: string }[];
  /* categories */
  categories: Category[];
  /* selected template */
  selectedTemplate: Page;
  /* default create data for page */
  pageCreateData: any = {
    name: null,
    isAuthenticationRequired: false,
    isActive: true,
    moduleId: '',
    categoryIds: []
  };

  displayToolbar = false;
  displayToolbarBackButton = false;
  isSelectTemplateModalVisible = false;

  subscriptions: Subscription[];
  submitButtonLoading = false;
  selectedTemplateDataBehaviour = SelectedDataBehaviour.Emit;
  selectedModuleId: string;
  selectedPageTemplate: Page;
  selectedTemplatePageId: string;
  pages: Page[];
  isPageCreateFormSubmitted: boolean;
  pageSlugs: string[];
  hasNameChanged = false;
  timer;
  searchText: string;
  pageIndex: number;
  templatePagesCount: number;
  isDynamicPagination = false;
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private store: Store<any>,
    private notificationService: NotificationService,
    private stringTransformService: StringTransformService,
    // eslint-disable-next-line no-empty-function
    private formatDatePipe: FormatDatePipe
  ) { }

  /**
   * onDestroy
   *
   * @memberof PageCreateComponent
   */
  ngOnDestroy(): void {
    /* Unsubscribes all subscriptions */
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(new GetTemplatePages({ pageIndex: 1, pageSize: 6, searchText: '', isDynamicPagination: true }));
  }

  ngOnInit() {
    this.store.dispatch(new GetTemplatePages({ pageIndex: 1, pageSize: 6, searchText: '', isDynamicPagination: false }));
    const initialPageCreateFormConfig = this.PAGE_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'selectedTemplatePage') as CrudFormSelectCardItem;
    initialPageCreateFormConfig.buttonConfig.text = 'Select Template';
    initialPageCreateFormConfig.textConfig = { text: '' };
    this.resetPageCreateFormConfigValidators();
    this.subscribeToData();
  }

  /**
   *  Subscribing all Data
   *
   * @memberof PageCreateComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToPageTemplatesWithTags(),
      this.subscribeToPagination(),
      this.subscribeToActiveProject(),
      this.subscribeToModulesAndPages(),
      this.subscribeToLayouts(),
      this.subscribeToSubmitButtonLoading(),
      this.subscribeToCategories()
    ];
  }

  /**
   * Subscribes to active project from state
   *
   * @returns
   * @memberof PageCreateComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      this.activeProject = activeProject;
      this.setTitle();
    });
  }

  subscribeToCategories() {
    return this.store.select(state => state.category?.data).subscribe((categories: Category[]) => {
      this.categories = categories?.filter(category => category.projectId === this.activeProject.id && category.type === 'page') ?? [];
      this.setCategoryOptions();
    });
  }

  subscribeToPageTemplatesWithTags() {
    return this.store.select(<any>getTemplatePagesWithTags).subscribe((templatePagesWithTags) => {
      if (templatePagesWithTags) {
        this.templatePages = templatePagesWithTags.templatePages;
        const initialPageCreateFormConfigForTags = this.PAGE_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'selectedTemplatePage') as CrudFormSelectCardItem;
        initialPageCreateFormConfigForTags.cardsConfig.selectConfig.options = templatePagesWithTags.templatePagesTags.map(tag => ({
          key: tag,
          value: tag
        }));
        this.buildCardOneConfigByTemplateData();
      }
    });
  }

  subscribeToPagination() {
    return this.store.select(state => state.page.templatePagesCount).subscribe((count: number) => {
      if (count) {
        this.templatePagesCount = count;
        const initialPageCreateFormConfigForCount = this.PAGE_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'selectedTemplatePage') as CrudFormSelectCardItem;
        initialPageCreateFormConfigForCount.cardsConfig.paginationConfig.total = count;
      }
    });
  }

  /**
 * subscribe to layouts from state
 *
 * @memberof PageCreateComponent
 */
  subscribeToLayouts() {
    return this.store.select(state => state.layout?.data).subscribe((layouts: PageWithRelations[]) => {
      this.layoutOptions = layouts?.map((layout: PageWithRelations) => ({
        key: layout.title,
        value: layout.id
      })) ?? [];
      /* set layout options */
      this.setLayoutOptions();
    });
  }

  subscribeToModulesAndPages() {
    return this.store.select(createSelector(
      state => <Module[]>state['module']?.data,
      state => <Page[]>state['page']?.data,
      state => <ProjectSetting[]>state['projectSetting']?.data,
      (modules: Module[], pages: Page[], projectSettings: ProjectSetting[]) => {
        const pagesModulesListData = {
          modules: modules,
          pages: pages,
          projectSettings: projectSettings
        };
        return pagesModulesListData;
      }
    )).subscribe(data => {
      this.moduleOptions = data.modules?.map(module => ({
        key: module.title,
        value: module.id
      })) ?? [];
      const defaultModuleId = data.projectSettings?.find(projectSetting => projectSetting.key === 'defaultModuleId')?.value;
      if (data.modules && defaultModuleId) {
        const defaultModule = data.modules.find(m => m.id === defaultModuleId);
        if (defaultModule) {
          this.setDefaultModuleInDefaultPageData(defaultModule);
        }
      }
      this.setModuleOptions();
      this.pages = data.pages;
      this.changeModule();
    });
  }

  subscribeToSubmitButtonLoading() {
    return this.store.select(state => state.page?.submitButtonLoading).subscribe((loading: boolean) => {
      this.submitButtonLoading = loading;
    });
  }

  setCategoryOptions() {
    const categoryOptions = <CrudFormSelectItem>this.PAGE_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'categoryIds');

    categoryOptions.options = this.categories?.map(category => ({
      key: category?.title,
      value: category?.id
    })) ?? [];

    this.PAGE_CREATE_FORM_CONFIG = { ...this.PAGE_CREATE_FORM_CONFIG };
  }

  changeModule() {
    const allPagesByModule = this.pages?.filter(page => page.moduleId === this.selectedModuleId);
    const pagesSlugs = allPagesByModule?.map(pageSlug => pageSlug?.slug);
    this.pageSlugs = pagesSlugs;
    this.updatePageEditFormConfigValidators();
  }

  setTitle() {
    if (this.activeProject) {
      /* set component title */
      this.title = [
        {
          label: this.activeProject?.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
        },
        {
          label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_LIST.PAGE_TITLE,
          redirectUrl: PATH_DEFINITIONS.PAGES.PAGE_LIST_PATH
        },
        {
          label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_CREATE.PAGE_TITLE
        }
      ];
    } else {
      this.title = PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_CREATE.PAGE_TITLE;
    }
  }

  /**
   * set select options at page config
   *
   * @memberof PageCreateComponent
   */
  setLayoutOptions() {
    const layoutsFormItem = this.PAGE_CREATE_FORM_CONFIG.items.find(
      item => item.fieldName === pageConfigFieldNames.layoutId
    ) as CrudFormSelectItem;
    layoutsFormItem.options = this.layoutOptions;
  }

  /**
   * sets module key values in selectbox item in config
   *
   * @memberof PageCreateComponent
   */
  setModuleOptions() {
    const moduleFormItem = <CrudFormSelectItem>(
      this.PAGE_CREATE_FORM_CONFIG.items.find(
        item => item.fieldName === pageConfigFieldNames.moduleId
      )
    );
    moduleFormItem.options = this.moduleOptions;
    this.PAGE_CREATE_FORM_CONFIG = { ...this.PAGE_CREATE_FORM_CONFIG };
  }

  /**
   * sets deafult module as main module
   * if main module does not exists takes the first module as default
   *
   * @param {Module[]} modules
   * @memberof PageCreateComponent
   */
  setDefaultModuleInDefaultPageData(module: Module) {
    this.pageCreateData.moduleId = module.id;
    this.pageCreateData = { ...this.pageCreateData };
    this.selectedModuleId = module.id;

  }

  onCreatePageFieldValueChange(page: Page) {
    if (this.isDynamicPagination) {
      if (page.selectedTemplatePage.searchText) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if (this.searchText !== page.selectedTemplatePage.searchText) {
            this.searchText = page.selectedTemplatePage.searchText;
            this.store.dispatch(new GetTemplatePagesPagination({ pageIndex: 1, pageSize: 6, searchText: page.selectedTemplatePage.searchText }));
            this.pageIndex = 1;
          }
        }, 1000);
      }
      if (page.selectedTemplatePage.pageIndex) {
        this.store.dispatch(new GetTemplatePages({ pageIndex: page.selectedTemplatePage.pageIndex, pageSize: 6, searchText: '', isDynamicPagination: false }));
      }

      if (page.selectedTemplatePage.tags) {
        this.store.dispatch(new GetTemplatePagesPagination({ pageIndex: 1, pageSize: 6, searchText: this.searchText, tags: page.selectedTemplatePage.tags }));
      }
    }
    this.pageCreateData = {
      ...this.pageCreateData,
      ...page
    };

    if (page?.moduleId) {
      this.selectedModuleId = page?.moduleId;
      this.resetPageCreateFormConfigValidators();
      this.changeModule();
      this.isPageCreateFormSubmitted = false;
    }

    if (page.name) {
      this.pageCreateData = {
        ...this.pageCreateData,
        name: StringUtilityService.toPascalCase(page.name)
      };
      this.hasNameChanged = true;
    }

    if (page.title) {
      this.pageCreateData = {
        ...this.pageCreateData,
        name: !this.hasNameChanged ? StringUtilityService.toPascalCase(page.title) : this.pageCreateData.name,
        slug: page.title ? this.stringTransformService.getKebabCaseFromText(page.title) : ''
      };
    } else if (page.title === '') {
      this.pageCreateData.name = !this.hasNameChanged ? '' : this.pageCreateData.name;
    }
    if (page?.selectedTemplatePage?.cardId || page?.selectedTemplatePage?.cardId === null) {
      this.pageCreateData.selectedTemplatePage = page?.selectedTemplatePage?.cardId;
      this.selectedPageTemplate = this.templatePages.find(selectedTemplatePage => selectedTemplatePage.id === page?.selectedTemplatePage?.cardId);
      this.selectedTemplatePageId = page?.selectedTemplatePage?.cardId;
      const updatedPageCreateFormConfig = this.PAGE_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'selectedTemplatePage') as CrudFormSelectCardItem;
      updatedPageCreateFormConfig.buttonConfig.text = 'Change';
      updatedPageCreateFormConfig.textConfig = {} as TextComponentConfig;
      updatedPageCreateFormConfig.textConfig.text = page?.selectedTemplatePage?.cardId ? this.selectedPageTemplate.title : BlankTemplate.titles[0].content;
      this.PAGE_CREATE_FORM_CONFIG = { ...this.PAGE_CREATE_FORM_CONFIG };
    }
  }

  /**
   * Fires when save button clicked
   *
   * @param {PageInterface} page
   * @memberof PageCreateComponent
   */
  createPage(page: NewPage) {
    if (this.activeProject && page) {
      this.dispatchCreateAction(page);
    } else {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'PAGES_MODULE.NOTIFICATIONS.PAGE_COULDNOT_CREATED'
      );
    }
  }

  dispatchCreateAction(pageData) {
    const page = {
      ...pageData,
      templatePageId: this.selectedTemplatePageId || null,
      isPublic: !pageData.isAuthenticationRequired
    };
    delete page.selectedTemplatePage;
    delete page.isAuthenticationRequired;
    this.store.dispatch(new CreatePage({ page }));
    this.setSelectTemplateButtonText('PAGES_MODULE.PAGE_CREATE_COMPONENT.SELECT_TEMPLATE');
  }

  matchPathToFormat(path: string) {
    return path.toLowerCase().replace(/[^\w ]+/g, '-');
  }

  onCreatePageFormButtonClick(data) {
    if (data.action.fieldName === 'selectedTemplatePage') {
      this.isSelectTemplateModalVisible = true;
    }
  }

  onSelectTemplate(template: Page) {
    this.selectedTemplate = template;
    this.pageCreateData = {
      ...this.pageCreateData,
      selectTemplate: template
    };
    this.setSelectTemplateButtonText(template.title);
  }

  setSelectTemplateButtonText(templateTitle: string) {
    const selectTemplateButton = this.PAGE_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'selectedTemplatePage') as CrudFormButtonItem;
    selectTemplateButton.text = templateTitle ?? BlankTemplate.titles[0].content;
    this.PAGE_CREATE_FORM_CONFIG = { ...this.PAGE_CREATE_FORM_CONFIG };
  }

  buildCardOneConfigByTemplateData() {
    const pageCreateForm = this.PAGE_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'selectedTemplatePage') as any;
    pageCreateForm.cardsConfig = {
      ...pageCreateForm.cardsConfig,
      items: [BlankTemplate, ...this.templatePages.map(template => ({
        data: template,
        image: {
          source: template.thumbnailImageUrl || 'assets/img/abstracts/elizabeth-lies-ZWPerNlqUu0-unsplash.jpg'
        },
        titles: [
          {
            type: HeadingType.H4,
            content: template.title
          }
        ],
        descriptions: [
          {
            content: template.description
          },
          {
            content: getCreationInformationFromEntity(template, this.formatDatePipe.transform(template.createdDate))
          }
        ],
        additionalTags: template.tags?.map(tag => ({
          text: {
            textMode: TextMode.Text,
            text: tag
          }
        }))
      }))]
    };
    this.PAGE_CREATE_FORM_CONFIG = { ...this.PAGE_CREATE_FORM_CONFIG };
  }

  updatePageEditFormConfigValidators() {
    const updatedCreateFormConfig = this.PAGE_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'slug');
    if (!updatedCreateFormConfig.validators.some(validator => validator.errorKey === 'nameExists')) {
      updatedCreateFormConfig.validators.push(
        {
          type: nameExistsValidator(this.pageSlugs),
          errorKey: 'nameExists',
          errorMessage: 'Name Already Exists Page Path'
        }
      );
    }
    updatedCreateFormConfig.validators.find(validator => validator.errorKey === 'nameExists').type = nameExistsValidator(this.pageSlugs);
    this.PAGE_CREATE_FORM_CONFIG = { ...this.PAGE_CREATE_FORM_CONFIG };
  }

  resetPageCreateFormConfigValidators() {
    const resetCreateFormConfig = this.PAGE_CREATE_FORM_CONFIG.items.find(item => item.fieldName === 'slug');
    resetCreateFormConfig.validators = [
      {
        type: Validators.pattern(PAGE_PATH_REGEX),
        errorKey: 'pattern',
        errorMessage: 'PAGES_MODULE.PAGE_CREATE_COMPONENT.PAGE_SLUG_ERROR'
      }
    ];
    this.PAGE_CREATE_FORM_CONFIG = { ...this.PAGE_CREATE_FORM_CONFIG };
  }

  submitButtonClick(newPageData) {
    if (this.pageSlugs.includes(newPageData.slug)) {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'Name Already Exists Page Path'
      );
    }
  }

}
