import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderTransferComponent } from './transfer.component';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    RappiderTransferComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzTransferModule,
    TranslateModule
  ],
  exports: [
    RappiderTransferComponent
  ]
})
export class RappiderTransferModule { }
