import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'rappider-message-template-display',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './message-template.component.html',
  styleUrls: ['./message-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageTemplateComponent {
  @Input() messageTemplate: any | undefined = undefined;

}
