import { ButtonComponentConfig, ButtonShape, ButtonSize } from '@rappider/rappider-components/utils';

export const commentStatusButtonConfig: ButtonComponentConfig = {
  icon: {
    name: 'fas fa-check',
    color: '#1890ff'
  },
  size: ButtonSize.Small,
  shape: ButtonShape.Circle
};
