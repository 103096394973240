/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { Category, Metadata, NewProject, PersonProjectWithRelations, Project, ProjectWithRelations } from '@rappider/rappider-sdk';
import { Pagination, StateError } from '@rappider/shared/interfaces';
import { NzNotificationPlacement } from 'ng-zorro-antd/notification';

export enum ActionTypes {
  LoadModule = '[Project] LoadModule',
  SetPagination = '[Project] SetPagination',
  SetPaginationFailure = '[Project] SetPaginationFailure',
  GetTotalCount = '[Project] GetTotalCount',
  GetProjects = '[Project] GetProjects',
  GetPersonProjectsSuccessful = '[Project] GetPersonProjectsSuccessful',
  GetProjectsFailure = '[Project] GetProjectsFailure',

  SyncProjects = '[Project] SyncProjects',
  SyncProjectsSuccessful = '[Project] SyncProjectsSuccessful',
  SyncProjectsFailure = '[Project] SyncProjectsFailure',

  SetProjects = '[Project] SetProjects',
  CreateProject = '[Project] CreateProject',
  CreateProjectSuccessful = '[Project] CreateProjectSuccessful',
  CreateProjectFailure = '[Project] CreateProjectFailure',
  UpdateProject = '[ActiveProject] UpdateProject',
  UpdateProjectSuccessful = '[ActiveProject] UpdateProjectSuccessful',
  UpdateProjectFailure = '[ActiveProject] UpdateProjectFailure',
  DeleteProject = '[Project] DeleteProject',
  DeleteProjectSuccessful = '[Project] DeleteProjectSuccessful',
  DeleteProjectFailure = '[Project] DeleteProjectFailure',
  SetAsActiveProjectTheme = '[ProjectTheme] SetAsActiveProjectTheme',
  SetAsActiveProjectThemeSuccessful = '[ProjectTheme] SetAsActiveProjectThemeSuccessful',
  ProjectError = '[Project] ProjectError',
  FavoritePersonProject = '[Project] FavoritePersonProject',
  FavoritePersonProjectSuccessful = '[Project] FavoritePersonProjectSuccessful',
  FavoritePersonProjectFailure = '[Project] FavoritePersonProjectFailure',
  UnFavoritePersonProject = '[Project] UnFavoritePersonProject',
  UnFavoritePersonProjectSuccessful = '[Project] UnFavoritePersonProjectSuccessful',
  UnFavoritePersonProjectFailure = '[Project] UnFavoritePersonProjectFailure',
  GetPersonProjectByProjectId = '[Project] GetPersonProjectByProjectId',
  GetPersonProjectByProjectIdSuccessful = '[Project] GetPersonProjectByProjectIdSuccessful',
  GetPersonProjectByProjectIdFailure = '[Project] GetPersonProjectByProjectIdFailure',
  GetPublicProjects = '[Project] GetPublicProjects',
  GetPublicProjectsSuccessful = '[Project] GetPublicProjectsSuccessful',
  GetPublicProjectsFailure = '[Project] GetPublicProjectsFailure',
  GetPublicProjectsCategoriesSuccessful = '[Project] GetPublicProjectsCategoriesSuccessful',
  GetPublicProjectsPagination = '[Project] GetPublicProjectsPagination',
  GetPublicProjectsPaginationSuccessful = '[Project] GetPublicProjectsPaginationSuccessful',
  GetPublicProjectsCategories = '[Project] GetPublicProjectsCategories',
  GetPublicProjectsCategoryIds = '[Project] GetPublicProjectsCategoryIds',
  AddCategoryToPublicProjectCategories = '[Project] AddCategoryToPublicProjectCategories',
  RemoveCategoryFromPublicProjectCategories = '[Project] RemoveCategoryFromPublicProjectCategories',
  UpdateCategoryFromPublicProjectCategories = '[Project] UpdateCategoryFromPublicProjectCategories'
}

export class LoadModule implements Action {
  readonly type = ActionTypes.LoadModule;
}

export class GetProjects implements Action {
  readonly type = ActionTypes.GetProjects;
}

export class GetProjectsFailure implements Action {
  readonly type = ActionTypes.GetProjectsFailure;
  constructor(public payload: StateError) { }
}

export class SyncProjects implements Action {
  readonly type = ActionTypes.SyncProjects;
}

export class SyncProjectsSuccessful implements Action {
  readonly type = ActionTypes.SyncProjectsSuccessful;
  constructor(public payload: { projects: Project[] }) { }
}

export class SyncProjectsFailure implements Action {
  readonly type = ActionTypes.SyncProjectsFailure;
  constructor(public payload: StateError) { }
}

/**
 * sets pagination and gets data after the action if specified
 *
 * @export
 * @class SetPagination
 * @implements {Action}
 */
export class SetPagination implements Action {
  readonly type = ActionTypes.SetPagination;
  constructor(public payload: { pagination: Pagination; getDataAfter?: boolean }) { }
}

export class SetPaginationFailure implements Action {
  readonly type = ActionTypes.SetPaginationFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class DeleteProjectSuccessful implements Action {
  readonly type = ActionTypes.DeleteProjectSuccessful;
  constructor(public payload: { projectId: string }) { }
}

export class DeleteProjectFailure implements Action {
  readonly type = ActionTypes.DeleteProjectFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class GetTotalCount implements Action {
  readonly type = ActionTypes.GetTotalCount;
}

export class SetProjects implements Action {
  readonly type = ActionTypes.SetProjects;
  constructor(public payload: { projects: Project[] }) { }
}

export class GetPersonProjectsSuccessful implements Action {
  readonly type = ActionTypes.GetPersonProjectsSuccessful;
  constructor(public payload: { personProjects: PersonProjectWithRelations[] }) { }
}

export class CreateProject implements Action {
  readonly type = ActionTypes.CreateProject;
  constructor(public payload: { project: NewProject }) { }
}

export class CreateProjectSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectSuccessful;
  constructor(public payload: { project: Project }) { }
}

export class GetPersonProjectByProjectId implements Action {
  readonly type = ActionTypes.GetPersonProjectByProjectId;
  constructor(public payload: { projectId: string }) { }
}

export class GetPersonProjectByProjectIdSuccessful implements Action {
  readonly type = ActionTypes.GetPersonProjectByProjectIdSuccessful;
  constructor(public payload: { personProject: PersonProjectWithRelations }) { }
}

export class GetPersonProjectByProjectIdFailure implements Action {
  readonly type = ActionTypes.GetPersonProjectByProjectIdFailure;
  constructor(public payload: { error: any }) { }
}

export class GetPublicProjects implements Action {
  readonly type = ActionTypes.GetPublicProjects;
  constructor(public payload: { pageIndex: number; pageSize: number; searchText?: string; categoryIds?: string[]; isDynamicPagination?: boolean }) { }
}

export class GetPublicProjectsSuccessful implements Action {
  readonly type = ActionTypes.GetPublicProjectsSuccessful;
  constructor(public payload: { publicProjects: ProjectWithRelations[] }) { }
}

export class GetPublicProjectsFailure implements Action {
  readonly type = ActionTypes.GetPublicProjectsFailure;
  constructor(public payload: StateError) { }
}

export class GetPublicProjectsCategoriesSuccessful implements Action {
  readonly type = ActionTypes.GetPublicProjectsCategoriesSuccessful;
  constructor(public payload: { publicProjectsCategories: Category[] }) { }
}

export class AddCategoryToPublicProjectCategories implements Action {
  readonly type = ActionTypes.AddCategoryToPublicProjectCategories;
  constructor(public payload: { projectCategory: Category }) { }
}

export class RemoveCategoryFromPublicProjectCategories implements Action {
  readonly type = ActionTypes.RemoveCategoryFromPublicProjectCategories;
  constructor(public payload: { projectCategory: Category }) { }
}

export class UpdateCategoryFromPublicProjectCategories implements Action {
  readonly type = ActionTypes.UpdateCategoryFromPublicProjectCategories;
  constructor(public payload: { projectCategory: Category }) { }
}

export class CreateProjectFailure implements Action {
  readonly type = ActionTypes.CreateProjectFailure;
}

export class UpdateProject implements Action {
  readonly type = ActionTypes.UpdateProject;
  constructor(public payload: {
    projectId: string;
    project: Partial<Project>;
    metadataCRUD?: {
      newMetadataItems?: Omit<Metadata, 'id'>[];
      updatedMetadataItems?: Partial<Metadata>[];
      deletedMetadataIds?: string[];
    };
    redirectToProjectDetail?: boolean;
    notificationMessageOnSuccess?: string;
    notificationMessageOnFailure?: string;
    notificationPlacement?: NzNotificationPlacement;
  }) { }
}

export class UpdateProjectSuccessful implements Action {
  readonly type = ActionTypes.UpdateProjectSuccessful;
  constructor(public payload: { project: Partial<ProjectWithRelations>; projectId: string }) { }
}

export class UpdateProjectFailure implements Action {
  readonly type = ActionTypes.UpdateProjectFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class DeleteProject implements Action {
  readonly type = ActionTypes.DeleteProject;
  constructor(public payload: { projectId: string }) { }
}

export class SetAsActiveProjectTheme implements Action {
  readonly type = ActionTypes.SetAsActiveProjectTheme;
  constructor(public payload: { projectThemeId: string; activeProjectId: string }) { }
}

export class SetAsActiveProjectThemeSuccessful implements Action {
  readonly type = ActionTypes.SetAsActiveProjectThemeSuccessful;
  constructor(public payload: { projectThemeId: string; activeProjectId: string }) { }
}

export class ProjectError implements Action {
  readonly type = ActionTypes.ProjectError;
  constructor(public payload: { errorOn: string; error?: unknown }) { }
}

export class FavoritePersonProject implements Action {
  readonly type = ActionTypes.FavoritePersonProject;
  constructor(public payload: { projectId: string; isFavorited: boolean }) { }
}

export class FavoritePersonProjectSuccessful implements Action {
  readonly type = ActionTypes.FavoritePersonProjectSuccessful;
  constructor(public payload: { projectId: string; isFavorited: boolean }) { }
}

export class FavoritePersonProjectFailure implements Action {
  readonly type = ActionTypes.FavoritePersonProjectFailure;
  constructor(public payload: StateError) { }
}

export class UnFavoritePersonProject implements Action {
  readonly type = ActionTypes.UnFavoritePersonProject;
  constructor(public payload: { projectId: string; isFavorited: boolean }) { }
}

export class UnFavoritePersonProjectSuccessful implements Action {
  readonly type = ActionTypes.UnFavoritePersonProjectSuccessful;
  constructor(public payload: { projectId: string; isFavorited: boolean }) { }
}

export class UnFavoritePersonProjectFailure implements Action {
  readonly type = ActionTypes.UnFavoritePersonProjectFailure;
  constructor(public payload: StateError) { }
}

export class GetPublicProjectsPagination implements Action {
  readonly type = ActionTypes.GetPublicProjectsPagination;
  constructor(public payload: { pageIndex?: number; pageSize?: number; searchText?: string; categoryIds?: string[] }) { }
}
export class GetPublicProjectsPaginationSuccessful implements Action {
  readonly type = ActionTypes.GetPublicProjectsPaginationSuccessful;
  constructor(public payload: { pageIndex: number; pageSize: number; searchText?: string; count?: number; publicProjectCategoryIds?: string[]; categoryIds?: string[]; isDynamicPagination?: boolean }) { }
}

export class GetPublicProjectsCategories implements Action {
  readonly type = ActionTypes.GetPublicProjectsCategories;
}

export class GetPublicProjectsCategoryIds implements Action {
  readonly type = ActionTypes.GetPublicProjectsCategoryIds;
  constructor(public payload: { publicProjectCategoryIds: string[] }) { }
}

export type Actions =
  LoadModule
  | SetPagination
  | SetPaginationFailure
  | GetTotalCount
  | GetProjects
  | GetPersonProjectsSuccessful
  | GetProjectsFailure
  | SyncProjects
  | SyncProjectsSuccessful
  | SyncProjectsFailure
  | SetProjects
  | CreateProject
  | CreateProjectSuccessful
  | CreateProjectFailure
  | UpdateProject
  | UpdateProjectSuccessful
  | UpdateProjectFailure
  | DeleteProject
  | SetAsActiveProjectTheme
  | SetAsActiveProjectThemeSuccessful
  | ProjectError
  | FavoritePersonProject
  | FavoritePersonProjectSuccessful
  | FavoritePersonProjectFailure
  | UnFavoritePersonProject
  | UnFavoritePersonProjectSuccessful
  | UnFavoritePersonProjectFailure
  | DeleteProjectSuccessful
  | DeleteProjectFailure
  | GetPersonProjectByProjectId
  | GetPersonProjectByProjectIdSuccessful
  | GetPersonProjectByProjectIdFailure
  | GetPublicProjects
  | GetPublicProjectsSuccessful
  | GetPublicProjectsFailure
  | GetPublicProjectsCategoriesSuccessful
  | GetPublicProjectsPagination
  | GetPublicProjectsPaginationSuccessful
  | GetPublicProjectsCategories
  | GetPublicProjectsCategoryIds
  | AddCategoryToPublicProjectCategories
  | RemoveCategoryFromPublicProjectCategories
  | UpdateCategoryFromPublicProjectCategories
  ;
