/* Angular */
import { createReducer, on } from '@ngrx/store';

/* Model Interface */
import { Category } from '@rappider/rappider-sdk';

/* Actions */
import * as CategoryActions from './category.actions';
import { orderBy } from 'lodash';

/* State key */
export const featureKey = 'category';

/* State interface */
export interface State {
  data: Category[] | null;
  isLoading: boolean;
  error: any;
}

/* Initial values */
export const initialState: State = {
  data: null,
  isLoading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(CategoryActions.GetCategories, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(CategoryActions.GetCategoriesSuccessful, (state, action) => ({
    ...state,
    data: action.payload.categories,
    isLoading: false
  })),
  on(CategoryActions.CreateCategory, (state) => ({
    ...state,
    isLoading: true
  })),
  on(CategoryActions.CreateCategorySuccessful, (state, action) => ({
    ...state,
    data: orderBy([...state.data, action.payload.category], 'name', 'asc'),
    isLoading: false
  })),
  on(CategoryActions.CreateCategoryFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(CategoryActions.UpdateCategory, (state) => ({
    ...state,
    isLoading: true
  })),
  on(CategoryActions.UpdateCategorySuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(c => c.id !== action.payload.categoryId),
      action.payload.category
    ],
    isLoading: false
  })),
  on(CategoryActions.UpdateCategoryFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(CategoryActions.DeleteCategory, (state) => ({
    ...state,
    isLoading: true
  })),
  on(CategoryActions.DeleteCategorySuccessful, (state, action) => ({
    ...state,
    data: state.data.filter(item => action.payload.categoryId !== item.id),
    isLoading: false
  })),
  on(CategoryActions.DeleteCategoryFailure, (state) => ({
    ...state,
    isLoading: false
  })),

  on(CategoryActions.ResetStateToInitial, () => initialState),

  on(CategoryActions.ResetStateToInitialChangeProject, () => initialState)
);
