import { Component, Input, OnInit } from '@angular/core';
import { ButtonComponentConfig, CrudFormConfig } from '@rappider/rappider-components/utils';
import { Project } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit {

  @Input() projectData: Project | undefined;
  @Input() projectFrontPropertiesConfig: CrudFormConfig | undefined;

  editMode = true;


  actionButtons: ButtonComponentConfig[] = [
    {
      key: 'edit',
      icon: {
        name: 'fas fa-grip-lines'
      }
    },
    {
      key: 'delete',
      icon: {
        name: 'fas fa-line-columns'
      }
    },
    {
      key: 'save',
      icon: {
        name: 'fa-solid fa-columns-3'
      }
    },
  ];

  ngOnInit(): void {
    console.log('Component initialized.');
  }

  onActionButtonClicked(action: ButtonComponentConfig) {
    console.log('Action button clicked:', action?.key);
  }

}
