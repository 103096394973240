import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionResponse } from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-data-model-list',
  templateUrl: './data-model-list.component.html',
  styleUrls: ['./data-model-list.component.scss']
})
export class DataModelListComponent implements OnInit {

  @Input() dataModels: any[] | null | undefined = null;
  @Input() animateTable = false;
  @Input() enableRowPointer: boolean;

  @Output() rowActionClick = new EventEmitter<ActionResponse>();


  tableConfig = {
    showHeaders: true,
    items: [
      {
        title: 'Model Name',
        fieldName: 'name'
      },
      {
        title: 'Database Table Name',
        fieldName: 'name'
      },
      {
        title: 'Description',
        fieldName: 'description'
      }
    ]
  };

  constructor() { }

  ngOnInit(): void {
    console.log('Component initialized.');
  }

    onRowActionClick(data: ActionResponse) {
      this.rowActionClick.emit(data);
    }

}
