import { Component } from '@angular/core';

@Component({
  selector: 'rappider-project-members-wrapper',
  templateUrl: './project-members-wrapper.component.html',
  styleUrls: ['./project-members-wrapper.component.scss']
})
export class ProjectMembersWrapperComponent {

}
