import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { addEndpointButtonConfig, endpointListSearchOptions, endpointListSearchBarComponentConfig } from '@rappider/shared/configs';
import { Subscription } from 'rxjs';
import { ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { ActivatedRoute } from '@angular/router';
import { AlertConfig, AlertTypes, BreadcrumbOption, ButtonType, HeadingComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import { EndpointListService, SearchService } from '@rappider/services';
import { groupBy } from 'lodash';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { AddQueryParam, Navigate } from '@rappider/shared';
import { customEndpointListDataSelector } from './custom-endpoint-list-data.selector';
import { ChangeViewText } from './enums/change-view-text.enum';
import SwaggerUI from 'swagger-ui';
import * as ProjectModelEndpointActions from 'libs/project/src/lib/states/project-model-endpoint-state/project-model-endpoint.actions';
import { userControllerClassName } from './utils/endpoint-variables';

@Component({
  selector: 'rappider-custom-endpoint-list',
  templateUrl: './custom-endpoint-list.component.html',
  styleUrls: ['./custom-endpoint-list.component.scss']
})
export class CustomEndpointListComponent implements OnInit, OnDestroy {
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: ProjectInterface;
  /* active project model id */
  activeProjectModelId: string;
  /*  */
  searchText: string;
  /* main title of the page */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.ENDPOINTS',
    type: defaultToolbarTitleHeadingSize
  };
  /* page breadcrumb */
  titleBreadcrumb: BreadcrumbOption[];
  searchResults: ProjectModelWithRelations[] = [];
  projectModels: ProjectModelWithRelations[];
  projectModelListData: ProjectModelWithRelations[];
  activeProjectModel: ProjectModelWithRelations;
  /* project models loading state */
  isLoading: boolean;
  changeText: boolean;
  /**
   *if this variable  is true, then we're showing the table for endpoints.
   * If it's false then we're showing swagger.
   *
   * @memberof CustomEndpointListComponent
   */
  gridView = true;
  /* Variable that holds the state of the panels */
  panels = {};

  endpointListSearchOptions = endpointListSearchOptions;
  endpointListSearchBarComponentConfig = endpointListSearchBarComponentConfig;
  addEndpointButtonConfig = addEndpointButtonConfig;
  changeViewText = ChangeViewText.ShowSwagger;

  displayToolbar = false;
  displayToolbarBackButton = false;

  swaggerUI: SwaggerUI;
  userControllerClassName = userControllerClassName;

  userDataModelAlertConfig: AlertConfig = {
    type: AlertTypes.Info,
    showIcon: true,
    closeable: false,
    title: {
      content: 'Title Area',
      type: HeadingType.H4
    },
    description: {
      text: 'Description Area'
    }
  };


  /* Collapse Button and Expand Button Config */
  collapseAndExpandButtons = {
    collapseButton: {
      text: 'Collapse All',
      key: 'collapseAll',
      type: ButtonType.Default,
      icon: {
        name: 'fas fa-minus'
      }
    },
    expandButton: {
      text: 'Expand All',
      key: 'expandAll',
      type: ButtonType.Default,
      icon: {
        name: 'fas fa-plus'
      }
    },
  };

  openAPIDefinition: Record<string, unknown>;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private endpointListService: EndpointListService
  ) { }

  ngOnInit(): void {
    this.getProjectModelIdFromUrl();
    this.subscribeToData();
    /**
     * since this can be changed while user navigates throughout the app, we're dispatching this action every time this component renders
     * to update the data
     * */
    this.store.dispatch(ProjectModelEndpointActions.GetOpenAPIDefinition());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModelEndpointsLoading(),
      this.subscribeToProjectModelEndpoints(),
      this.subscribeToOpenAPIDefinition()
    ];
  }

  getProjectModelIdFromUrl() {
    this.activeProjectModelId = this.activatedRoute.snapshot.params['projectModelId'];
  }

  collapseOrExpandAll(key: string) {
    if (key === this.collapseAndExpandButtons.collapseButton.key) {
      this.collapseAll();
    } else if (key === this.collapseAndExpandButtons.expandButton.key) {
      this.expandAll();
    }
  }

  collapseAll() {
    Object.keys(this.panels).forEach((key) => (this.panels[key] = false));
  }

  expandAll() {
    Object.keys(this.panels).forEach((key) => (this.panels[key] = true));
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.setTitle();
      } else {
        this.activeProject = null;
      }
    });
  }

  setTitle() {
    if (!this.activeProjectModelId) {
      this.titleBreadcrumb = [
        {
          label: this.activeProject?.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
        },
        {
          label: 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.ENDPOINTS',
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_ENDPOINT_LIST}`
        }
      ];
    }
  }

  subscribeToProjectModelEndpoints() {
    return this.store.select(<any>customEndpointListDataSelector).subscribe((projectModelsWithEndpoints: ProjectModelWithRelations[]) => {
      if (projectModelsWithEndpoints.length) {
        this.projectModelListData = projectModelsWithEndpoints;
        this.setTagColorByEndpointMethod();
        this.projectModelListData.forEach(model => {
          this.panels[model.id] = true;
        });
      }
    });
  }

  subscribeToOpenAPIDefinition() {
    return this.store.select(state => state.projectModelEndpoint.openAPIDefinition).subscribe((openAPIDefinition: Record<string, unknown>) => {
      this.openAPIDefinition = openAPIDefinition;
      if (openAPIDefinition) {
        this.initSwaggerUI(this.searchText);
      }
    });
  }

  subscribeToProjectModelEndpointsLoading() {
    return this.store.select(state => state.projectModelEndpoint?.isLoading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  setTagColorByEndpointMethod() {
    this.projectModelListData = this.projectModelListData.map(projectModelListData => ({
      ...projectModelListData,
      children: projectModelListData.children.map((child: any) => this.endpointListService.setProjectModelEndpointTagColorByMethodName(child))
    })).sort((a, b) => {
      const pathA = a.children.map(child => child.path.toLowerCase()).join('');
      const pathB = b.children.map(child => child.path.toLowerCase()).join('');
      if (pathA < pathB) {
        return -1;
      }
      if (pathA > pathB) {
        return 1;
      }
      return 0;
    });
    this.activeProjectModel = this.projectModelListData.find(model => model.id === this.activeProjectModelId);
  }

  collapse(mapOfExpandedProjectModels: any[], data: any, expanded: boolean): void {
    if (!expanded) {
      if (data?.children?.length) {
        data.children.forEach(d => {
          const target = mapOfExpandedProjectModels.find(a => a.key === d.key);
          target.expand = false;
          this.collapse(mapOfExpandedProjectModels, target, false);
        });
      } else {
        return;
      }
    }
  }

  onSearchTextChange() {
    this.initSwaggerUI(this.searchText);
    const searchedEnpoints = this.projectModelListData.map(projectModel => {
      const response = this.searchService?.searchByOptions(projectModel.children, this.endpointListSearchOptions, this.searchText);
      return response.map(response => response.item);
    })?.flat();
    const endpointsGroupedByModelId = groupBy(searchedEnpoints, 'modelId');

    this.searchResults = this.projectModelListData.map(projectModel => ({
      ...projectModel,
      children: endpointsGroupedByModelId[projectModel.id]
    })).filter(searchResult => searchResult?.children?.length > 0);
  }

  /**
  * navigate to the create custom endpoint page
  *
  * @memberof ModelEndpointListComponent
  */
  navigateToCreateEndpointPage(projectModelId: string) {
    if (!this.activeProjectModelId) {
      this.store.dispatch(new AddQueryParam({ key: 'redirectURL', value: 'projects/endpoints' }));
    }
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.CUSTOM_ENDPOINT_CREATE}/${projectModelId}` }));
  }

  changeView() {
    this.gridView = !this.gridView;
    if (!this.gridView) {
      this.changeViewText = ChangeViewText.ShowTable;
    } else {
      this.changeViewText = ChangeViewText.ShowSwagger;
    }
  }

  caseInsensitiveAdvanceFilterPlugin() {
    return {
      fn: {
        opsFilter: function (taggedOps, phrase) {
          phrase = phrase.toLowerCase();
          const filteredActions = taggedOps.map((tagObj) => {
            tagObj._root.entries[1][1] = tagObj._root.entries[1][1].filter((operationObj) => {
              const op = JSON.parse(JSON.stringify(operationObj));
              let summary = '';
              let description = '';
              if (typeof op.operation.summary !== 'undefined') {
                summary = JSON.stringify(op.operation.summary).toLowerCase();
              }
              if (typeof op.operation.description !== 'undefined') {
                description = JSON.stringify(op.operation.description).toLowerCase();
              }
              if ((op.path.toLowerCase().indexOf(phrase) === -1)
                && (summary.indexOf(phrase) === -1)
                && (description.indexOf(phrase) === -1)
              ) {
                return false;
              } else {
                return true;
              }
            });
            return tagObj;
          });
          return filteredActions.filter((tagObj) =>
            (tagObj._root.entries[1][1].size > 0)
          );
        }
      }
    };
  };

  initSwaggerUI(searchText) {
    this.swaggerUI = SwaggerUI({
      spec: this.openAPIDefinition,
      domNode: document.getElementById('swagger-ui'),
      filter: searchText || '',
      plugins: [
        this.caseInsensitiveAdvanceFilterPlugin
      ],
    });
  }

}
