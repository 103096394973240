import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, delay, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, from, of } from 'rxjs';

import * as ProjectSourceCodeActions from './project-source-code.actions';

import { ProjectSourceCode, ProjectSourceFile } from './project-source-code.interfaces';

/* sdk imports */
import { ApplicationBuilderControllerService, CustomCode, CustomCodeControllerService } from '@rappider/rappider-sdk';
import { SourceCodeControllerService } from '@rappider/rappider-sdk';
import { flattenDeep } from 'lodash';
import { NotificationService } from '@rappider/services';
import { flattenAndFilterAnimatedSourceFiles } from './project-source-code-animate-utility';
import { fil } from 'date-fns/locale';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';

export const navigatePathAfterCreatingInstance = '';
export const navigatePathAfterUpdatingInstance = '';

@Injectable()
export class ProjectSourceCodeEffects {

  animateProjectSourceFilesCodeGeneration = true;

  constructor(
    private actions$: Actions,
    private sourceCodeApi: SourceCodeControllerService,
    private customCodeApi: CustomCodeControllerService,
    private store: Store<any>,
    private applicationBuilderApi: ApplicationBuilderControllerService,
    private notificationService: NotificationService,

  ) { }

  getProjectSourceCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.GetProjectSourceCode),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        /* fail if there is no active project */
        if (!activeProjectId) {
          return [
            ProjectSourceCodeActions.GetProjectSourceCodesFailure()
          ];
        }

        // stop animated source code generation show; when we trigger a manuel source code display
        this.animateProjectSourceFilesCodeGeneration = false;

        /* get the ProjectSourceCode */
        return this.sourceCodeApi.getFileContent({ filePath: action.payload.sourceFilePath }).pipe(
          mergeMap(projectSourceCode => ([
            ProjectSourceCodeActions.GetProjectSourceCodeSuccessful({
              payload: {
                projectSourceCode: <ProjectSourceCode>{
                  ...projectSourceCode,
                  fullPath: action.payload.sourceFilePath
                }
              }
            })])),
          catchError((error) => [
            ProjectSourceCodeActions.GetProjectSourceCodeFailure()
          ])
        );
      })
    )
  );

  getProjectSourceCodeAnimated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.GetProjectSourceCodeAnimated),
      filter(() => this.animateProjectSourceFilesCodeGeneration),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        /* fail if there is no active project */
        if (!activeProjectId) {
          return [
            ProjectSourceCodeActions.GetProjectSourceCodesFailure()
          ];
        }

        /* get the ProjectSourceCode */
        return this.sourceCodeApi.getFileContent({ filePath: action.payload.sourceFilePath }).pipe(
          mergeMap(projectSourceCode => ([
            ProjectSourceCodeActions.GetProjectSourceCodeSuccessful({
              payload: {
                projectSourceCode: <ProjectSourceCode>{
                  ...projectSourceCode,
                  fullPath: action.payload.sourceFilePath
                }
              }
            })])),
          catchError((error) => [
            ProjectSourceCodeActions.GetProjectSourceCodeFailure()
          ])
        );
      })
    )
  );

  getProjectSourceFileCustomCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.GetProjectSourceFileCustomCodes),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        /* fail if there is no active project */
        if (!activeProjectId) {
          return [
            ProjectSourceCodeActions.GetProjectSourceFileCustomCodesFailure()
          ];
        }

        /* set a filter */
        const params = {
          filter: {
            where: {
              filePath: action.payload.sourceFilePath
            }
          }
        };

        /* get the ProjectSourceCodes */
        return this.customCodeApi.find(params).pipe(
          mergeMap(customCodes => ([
            ProjectSourceCodeActions.GetProjectSourceFileCustomCodesSuccessful({
              payload: { customCodes }
            })])),
          catchError((error) => [
            ProjectSourceCodeActions.GetProjectSourceFileCustomCodesFailure()
          ])
        );
      })
    )
  );

  getProjectSourceFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.GetProjectSourceFiles),
      mergeMap((action) =>
        /* get the ProjectSourceCodes */
        this.sourceCodeApi.getDirectoryEntries().pipe(
          mergeMap((directoryTree: any) => [
            ProjectSourceCodeActions.GetProjectSourceFilesSuccessful({
              payload: {
                projectSourceFiles: directoryTree,
                flattenedSourceFiles: flattenDeep(directoryTree)
              }
            })]
          ),
          catchError((error) => [
            ProjectSourceCodeActions.GetProjectSourceFilesFailure()
          ])
        )
      )
    )
  );

  animateProjectSourceFilesCodeGeneration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.GetProjectSourceFilesSuccessful),
      mergeMap((action) => {
        const flattenedFiles = flattenAndFilterAnimatedSourceFiles(action.payload?.flattenedSourceFiles || []);
        return from(flattenedFiles).pipe(
          filter((sourceFile: any) => !this.animateProjectSourceFilesCodeGeneration),
          concatMap((sourceFile: any) =>
            of(ProjectSourceCodeActions.GetProjectSourceCodeAnimated({ payload: { sourceFilePath: sourceFile.fullPath } }))
              .pipe(
                filter(() => this.animateProjectSourceFilesCodeGeneration),
                delay(1000)
              ) // Adding a delay between each action dispatch
          )
        );
      })
    )
  );

  stopAnimatedSourceCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.StopAnimatedSourceCode),
      mergeMap(() => {
        this.animateProjectSourceFilesCodeGeneration = false;
        return [];
      })
    ), { dispatch: false });

  upsertCustomCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.UpsertProjectSourceFileCustomCodes),
      mergeMap((action) =>
        /* save the custom codes for source files */
        forkJoin(
          action.payload.customCodes.map((customCode) => this.customCodeApi.create({ body: customCode }))
        ).pipe(
          mergeMap(createdCustomCodes => ([
            ProjectSourceCodeActions.UpsertProjectSourceFileCustomCodesSuccessful({ payload: { createdCustomCodes } })
          ])),
          catchError((error) => [
            ProjectSourceCodeActions.UpsertProjectSourceFileCustomCodesFailure()
          ])
        )
      )
    )
  );

  deleteCustomCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.DeleteProjectSourceFileCustomCodes),
      mergeMap((action) => {
        /* save the custom codes for source files */
        if (action.payload.customCodeIds.length === 0) {
          return [
            ProjectSourceCodeActions.DeleteProjectSourceFileCustomCodesSuccessful()
          ];
        }
        return forkJoin(
          action.payload.customCodeIds.map((customCodeId) => this.customCodeApi.deleteById({ id: customCodeId }))
        ).pipe(
          mergeMap(() => ([
            ProjectSourceCodeActions.DeleteProjectSourceFileCustomCodesSuccessful()
          ])),
          catchError((error) => [
            ProjectSourceCodeActions.DeleteProjectSourceFileCustomCodesFailure()
          ])
        );
      })
    )
  );

  generateCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.GenerateCode),
      withLatestFrom(this.store.select(state => state.projectVersion?.data)),
      mergeMap(([action, projectVersions]) =>
        this.applicationBuilderApi.generateCode().pipe(
          mergeMap(() => {
            const latestVersion = projectVersions[0];

            this.notificationService.createNotification(
              'success',
              'Project Latest Version',
              'Project Code Generated Successfully.'
            );
            return [
              ProjectSourceCodeActions.GenerateCodeSuccessful(),
              new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_DETAIL}/${action.payload.projectVersionId || latestVersion.id}` })
            ];
          }),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              error?.message
            );
            return [ProjectSourceCodeActions.GenerateCodeFailure()];
          })
        )
      )
    )
  );

  downloadSourceFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.DownloadProjectSourceFile),
      withLatestFrom(this.store.select(state => state.projectVersion?.data)),
      mergeMap(([action, projectVersions]) => {

        const latestVersion = projectVersions[0];
        const latestVersionNumber = latestVersion?.versionNumber;
        const params = { versionNumber: latestVersionNumber };

        return this.applicationBuilderApi.downloadCode(params).pipe(
          mergeMap((blobResponse) => {
            const url = window.URL.createObjectURL(blobResponse);
            const projectDownloadLink = document.createElement('a');
            projectDownloadLink.href = url;
            projectDownloadLink.download = 'project-source.zip';
            document.body.appendChild(projectDownloadLink);
            projectDownloadLink.click();
            document.body.removeChild(projectDownloadLink);
            window.URL.revokeObjectURL(url);

            this.notificationService.createNotification(
              'success',
              'Success',
              'Download Started Successfully.'
            );

            return [ProjectSourceCodeActions.DownloadProjectSourceFileSuccesful()];
          }),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              error?.message
            );
            return [ProjectSourceCodeActions.DownloadProjectSourceFileFailure()];
          })
        );
      })
    )
  );

  deploy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.Deploy),
      mergeMap(() =>
        this.applicationBuilderApi.deploy().pipe(
          map((deployInfo) => ProjectSourceCodeActions.DeploySuccessful({ payload: { deployInfo } })),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              error?.message
            );
            return [ProjectSourceCodeActions.DeployFailure()];
          })
        )
      )
    )
  );

  monitorApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.MonitorApplication),
      mergeMap(() =>
        this.applicationBuilderApi.monitorApplication().pipe(
          map((monitorApplicationInfo) => ProjectSourceCodeActions.MonitorApplicationSuccessful({ payload: { monitorApplicationInfo } })),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              error?.message
            );
            return [ProjectSourceCodeActions.MonitorApplicationFailure()];
          })
        )
      )
    )
  );

  startApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.StartApplication),
      mergeMap(() =>
        this.applicationBuilderApi.startApplication().pipe(
          map((startApplicationInfo) => ProjectSourceCodeActions.StartApplicationSuccessful({ payload: { startApplicationInfo } })),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              error?.message
            );
            return [ProjectSourceCodeActions.StartApplicationFailure()];
          })
        )
      )
    )
  );

  stopApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectSourceCodeActions.StopApplication),
      mergeMap(() =>
        this.applicationBuilderApi.stopApplication().pipe(
          map((stopApplicationInfo) => ProjectSourceCodeActions.StopApplicationSuccessful({ payload: { stopApplicationInfo } })),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              error?.message
            );
            return [ProjectSourceCodeActions.StopApplicationFailure()];
          })
        )
      )
    )
  );

}
