<div class="post-it-container"
     [ngStyle]="{'background-color': getPostItColor()}"
     [class]="size || 'medium'">
  <nz-card class="post-it-card"
           [class]="size || 'medium'"
           nzHoverable>
    <ng-container *ngIf="!isEditable">
      <div class="post-it-text"
           [class]="size || 'medium'">{{ noteText }}</div>
    </ng-container>

    <ng-container *ngIf="isEditable">
      <textarea class="post-it-edit"
                [class]="size || 'medium'"
                [(ngModel)]="noteText"
                (ngModelChange)="onNoteTextChange(noteText)"></textarea>
    </ng-container>
  </nz-card>
</div>
