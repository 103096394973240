<div class="server-page-wrapper">
  <div class="title-wrapper">
    <rappider-paragraph content="Power (on/off)"
                        [typography]="{fontWeight:'bold', fontSize:'1.5rem'}"></rappider-paragraph>
    <rappider-switch  [ngModel]="switchValue"
                      (valueChange)="onSwitchToggled($event)"></rappider-switch>
  </div>
  <nz-descriptions nzTitle="Backend Status" [nzColumn]="1" nzBordered>
    <nz-descriptions-item nzTitle="Created Date">{{backendInfo?.CreatedAt | date}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Status">{{backendInfo?.Status}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="State" [nzSpan]="3">
      <nz-badge [nzStatus]="backendInfo?.State === 'running' ? 'success' : 'error'" [nzText]="backendInfo?.State"></nz-badge>
    </nz-descriptions-item>
  </nz-descriptions>

  <nz-descriptions nzTitle="Frontend Status" [nzColumn]="1" nzBordered>
    <nz-descriptions-item nzTitle="Created Date">{{frontendInfo?.CreatedAt | date}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Status">{{backendInfo?.Status}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="State" [nzSpan]="3">
      <nz-badge [nzStatus]="frontendInfo?.State === 'running' ? 'success' : 'error'" [nzText]="backendInfo?.State"></nz-badge>
    </nz-descriptions-item>
  </nz-descriptions>
</div>
