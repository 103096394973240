<div class="email-viewer"
     (click)="onMailToClick()">
  <rappider-icon *ngIf="showIcon"
                 [name]="icon?.name"
                 [type]="icon?.type">
  </rappider-icon>

  <a class="email-link">
    {{ labelVisibility && label ? label : email }}
  </a>

</div>