import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { ProjectRoleApi, ProjectRoleInterface } from '@rappider/api-sdk';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, PaginationService } from '@rappider/services';
import * as ProjectRoleActions from './project-role.actions';
import { ProjectControllerService, ProjectRole } from '@rappider/rappider-sdk';
import * as ActiveProjectActions from '../active-project-state/active-project.actions';


@Injectable()
export class ProjectRoleEffects {
  constructor(
    private actions$: Actions,
    private projectRoleApi: ProjectRoleApi,
    private projectApi: ProjectControllerService,
    private store: Store<any>,
    private notificationService: NotificationService,
    private paginationService: PaginationService
  ) { }


  loadModule$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectRoleActions.LoadModule>(
      ProjectRoleActions.ActionTypes.LoadModule,
    ),
    concatMap(() => [
      new ProjectRoleActions.GetTotalCount(),
      new ProjectRoleActions.GetProjectRoles()
    ])
  ));


  enableProjectRolesLoading$ = createEffect(() => this.actions$.pipe(
    ofType<
      ProjectRoleActions.GetProjectRoles
    >(
      ProjectRoleActions.ActionTypes.GetProjectRoles,
    ),
    map(() => new ProjectRoleActions.EnableProjectRolesLoading())
  ));


  disableProjectRolesLoading$ = createEffect(() => this.actions$.pipe(
    ofType<
      ProjectRoleActions.SetProjectRoles
    >(
      ProjectRoleActions.ActionTypes.SetProjectRoles,
    ),
    map(() => new ProjectRoleActions.DisableProjectRolesLoading())
  ));


  getTotalCount$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectRoleActions.GetTotalCount>(
      ProjectRoleActions.ActionTypes.GetTotalCount
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data),
      this.store.select(state => state.projectRole.pagination)
    ),
    mergeMap(([action, activeProject, pagination]) => {
      const filter = {
        where: { projectId: activeProject.id }
      };
      return this.projectRoleApi.count(filter.where).pipe(
        map((res: { count: number }) => new ProjectRoleActions.SetPagination({
          pagination: {
            totalCount: res.count,
            totalPageNumber: this.paginationService.getTotalPageNumber(res.count, pagination.pageSize)
          }
        }))
      );
    }), catchError(error => [
      new ProjectRoleActions.ProjectRoleError({ errorOn: 'GetTotalCount', error: error }),
    ])
  ));

  /**
   * Create Project Role and update state fields
   *
   * @memberof ProjectRoleEffects
   */

  getProjectRoles$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectRoleActions.GetProjectRoles | ActiveProjectActions.SetActiveProject>(
      ProjectRoleActions.ActionTypes.GetProjectRoles, ActiveProjectActions.ActionTypes.SetActiveProject
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id),
      this.store.select(state => state.projectRole.pagination),
    ),
    mergeMap(([action, activeProjectId, projectRolesPagination]) => {
      const filter = {
        order: ['createdDate DESC'],
        skip: this.paginationService.getSkippedSizeByPagination(projectRolesPagination.currentPageNumber, projectRolesPagination.pageSize),
        limit: projectRolesPagination.pageSize
      };
      return this.projectApi.findProjectRoles({ filter }).pipe(
        mergeMap((projectRoles: ProjectRole[]) => [
          new ProjectRoleActions.GetTotalCount(),
          new ProjectRoleActions.SetProjectRoles({ projectRoles: projectRoles })
        ]),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_COULDNOT_LAODED'
          );
          return [
            new ProjectRoleActions.ProjectRoleError({ errorOn: 'GetProjectRoles', error: error }),
          ];
        })
      );
    })
  ));


  setPagination$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectRoleActions.SetPagination>(
      ProjectRoleActions.ActionTypes.SetPagination
    ),
    mergeMap(action => {
      if (action.payload.getDataAfter) {
        return of(new ProjectRoleActions.GetProjectRoles());
      } else {
        return [new ProjectRoleActions.GetProjectRolesFailure({ error: 'Project roles could not loaded', key: 'GetProjectRolesFailure', timestamp: Date.now() })];
      }
    })
  ));

  /**
   * Create Project Role and update state fields
   *
   * @memberof ProjectRoleEffects
   */

  createProjectRole$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectRoleActions.CreateProjectRole>(
      ProjectRoleActions.ActionTypes.CreateProjectRole
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson),
    ),
    mergeMap(([action, person,]) => {
      const projectRoleData = {
        ...action.payload.projectRole,
      };
      return this.projectRoleApi.create(projectRoleData).pipe(
        mergeMap(projectRole => {
          if (projectRole) {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESSFUL',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_CREATED'
            );
            return of(new ProjectRoleActions.SetPagination({ pagination: { currentPageNumber: 1 }, getDataAfter: true }));
          } else {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_COULDNOT_CREATED'
            );
            return [new ProjectRoleActions.CreateProjectRoleFailure({ error: 'Project role could not created', key: 'CreateProjectRoleFailure', timestamp: Date.now() })];
          }
        })
      );
    }), catchError(error => [
      new ProjectRoleActions.ProjectRoleError({ errorOn: 'CreateProjectRole', error: error }),
    ])
  ));

  /**
   * Update Project Role and update state fields
   *
   * @memberof ProjectRoleEffects
   */

  updateProjectRole$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectRoleActions.UpdateProjectRole>(
      ProjectRoleActions.ActionTypes.UpdateProjectRole
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson)
    ),
    mergeMap(([action, person]) => {
      const projectRoleData = action.payload.projectRole;
      return this.projectRoleApi.patchAttributes(action.payload.projectRoleId, projectRoleData).pipe(
        mergeMap(projectRole => {
          if (projectRole) {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESSFUL',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_UPDATED'
            );
            return of(new ProjectRoleActions.GetProjectRoles());
          } else {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_COULDNOT_UPDATED'
            );
            return [new ProjectRoleActions.UpdateProjectRoleFailure({ error: 'Project role could not updated', key: 'UpdateProjectRoleFailure', timestamp: Date.now() })];
          }
        })
      );
    }), catchError(error => [
      new ProjectRoleActions.ProjectRoleError({ errorOn: 'UpdateProjectRole', error: error }),
    ])
  ));

  /**
   * Delete Project Role and update state fields
   *
   * @memberof ProjectRoleEffects
   */

  deleteProjectRole$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectRoleActions.DeleteProjectRole>(
      ProjectRoleActions.ActionTypes.DeleteProjectRole
    ),
    mergeMap(action => {
      if (action.payload.projectRoleId) {
        return this.projectRoleApi.deleteById(action.payload.projectRoleId).pipe(
          mergeMap(projectRole => {
            if (projectRole) {
              this.notificationService.createNotification(
                'success',
                'SHARED.SUCCESSFUL',
                'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_DELETED'
              );
              return of(new ProjectRoleActions.GetProjectRoles());
            } else {
              this.notificationService.createNotification(
                'error',
                'SHARED.ERROR',
                'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ROLE_COULDNOT_DELETED'
              );
              return [new ProjectRoleActions.DeleteProjectRoleFailure({ error: 'Project role could not deleted', key: 'DeleteProjectRoleFailure', timestamp: Date.now() })];
            }
          })
        );
      }
    }), catchError(error => [
      new ProjectRoleActions.ProjectRoleError({ errorOn: 'DeleteProjectRole', error: error }),
    ])
  ));

}
