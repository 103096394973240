import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UpdatePage } from '@rappider/pages';
import { ButtonComponentConfig, IconType, InputGroupComponentConfig } from '@rappider/rappider-components/utils';
import { Page } from '@rappider/rappider-sdk';
import { SearchService } from '@rappider/services';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-layout-menu',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.scss']
})
export class LayoutMenuComponent implements OnInit, OnDestroy {
  @Input() page: Page;

  layouts: Page[];
  matchedLayouts: Page[];
  searchText: string;

  inputGroupConfig: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'Search in Layouts'
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome
    }
  };

  cardButtton: ButtonComponentConfig = {
    icon: {
      name: 'fa-solid fa-right-from-line',
    }
  };

  /* searchOptions for fuse */
  searchOptions = {
    threshold: 0.2,
    keys: [
      'title'
    ]
  };

  noSearchResultsAvailableAnimationConfig = {
    path: 'assets/animations/no-search-result.json',
    loop: true
  };

  subscription: Subscription;

  constructor(
    private store: Store<any>,
    private searchService: SearchService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription = this.subscribeToLayouts();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeToLayouts() {
    return this.store.select(state => state.layout?.data).subscribe((layouts: Page[]) => {
      this.layouts = layouts;
    });
  }

  onSearchTextChange() {
    this.matchedLayouts = this.searchService.searchByOptions(this.layouts, this.searchOptions, this.searchText);
  }

  goToLayoutEditor(item) {
    // for reload page with navigate
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([`${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${item.id}`]));
  }

  applyLayout(item) {
    this.store.dispatch(new UpdatePage({ pageId: this.page.id, page: { layoutId: item.id }, navigateToPageList: false, updateInContentEditor: true }));
  }

  onClickRemoveLayout() {
    this.store.dispatch(new UpdatePage({ pageId: this.page.id, page: { layoutId: null }, navigateToPageList: false, updateInContentEditor: true }));
  }

}
