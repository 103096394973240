/* Files contains these string will be filtered from the code generation animation */
export const filteredSourcePaths = [
  'spec.',
  '.github',
  'asset',
  'styles',
  'rappider',
  'shared',
  '.ico',
  'test',
  'polyfills',
  '.json',
  'conf.js',
  'eslint',
  'karma',
  '.md',
];


export function flattenAndFilterAnimatedSourceFiles(files: any[], currentPath = '') {
  return files.reduce((acc: any, file: any) => {
    const fullPath = currentPath ? `${currentPath}/${file.name}` : file.name;

    if (file.children?.length) {
      // Recursively flatten children
      // if one string is a substring of fullpath, then it is a filtered path
      let isFiltered = false;
      filteredSourcePaths.forEach((filteredPath) => {
        if (fullPath.includes(filteredPath)) {
          isFiltered = true;
        }
      });
      if (!isFiltered) {
        acc.push(...flattenAndFilterAnimatedSourceFiles(file.children, fullPath));
      }
    } else {
      // Add file with full path

      // if one string is a substring of fullpath, then it is a filtered path
      let isFiltered = false;
      filteredSourcePaths.forEach((filteredPath) => {
        if (fullPath.includes(filteredPath)) {
          isFiltered = true;
        }
      });
      if (!isFiltered) {
        acc.push({ ...file, fullPath });
      }
    }

    return acc;
  }, []);
}
