import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as ScopeOfWorkActions from './scope-of-work.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

import { NotificationService } from '@rappider/services';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

import { ScopeOfWork, ScopeOfWorkPartial, NewScopeOfWork } from '@rappider/rappider-sdk';

/* Named import */
import { ScopeOfWorkControllerService as ApiService } from '@rappider/rappider-sdk';

export const navigatePathAfterCreatingInstance = '';
export const navigatePathAfterUpdatingInstance = '';

@Injectable()
export class ScopeOfWorkEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  getScopeOfWorks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOfWorkActions.GetScopeOfWorks),
      withLatestFrom(
        this.store.select(state => state.activeProject?.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        /* fail if there is no active project */
        if (!activeProjectId) {
          return [
            ScopeOfWorkActions.GetScopeOfWorksFailure()
          ];
        }
        /* set a filter */
        const filter = {};
        /* get the ScopeOfWorks */
        return this.apiService.find({ filter }).pipe(
          mergeMap(scopeOfWorks => ([
            ScopeOfWorkActions.GetScopeOfWorksSuccessful({ payload: { scopeOfWorks: scopeOfWorks } })
          ])),
          catchError((error) => [
            ScopeOfWorkActions.GetScopeOfWorksFailure()
          ])
        );
      })
    )
  );

  createScopeOfWork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOfWorkActions.CreateScopeOfWork),
      withLatestFrom(
        this.store.select(state => state.activeProject?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        const params = {
          body: {
            ...action.payload.scopeOfWork,
            projectId: activeProjectId
          }
        };

        return this.apiService.create(params).pipe(
          map((scopeOfWork) => {
            this.notificationService.createNotification(
              'success',
              params.body?.title,
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return ScopeOfWorkActions.CreateScopeOfWorkSuccessful({ payload: { scopeOfWork: scopeOfWork } });
          }),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              params.body?.title,
              'SHARED.COULDNT_CREATED'
            );
            return [
              ScopeOfWorkActions.CreateScopeOfWorkFailure(),
              ScopeOfWorkActions.ErrorAction({ payload: { error: error, key: 'CreateScopeOfWork', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  createScopeOfWorkSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOfWorkActions.CreateScopeOfWorkSuccessful),
      map(action =>
        new Navigate({ url: navigatePathAfterCreatingInstance })
      )
    )
  );

  updateScopeOfWork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOfWorkActions.UpdateScopeOfWork),
      mergeMap(action => {
        const params = {
          id: action.payload.scopeOfWorkId,
          body: action.payload.scopeOfWork
        };

        return this.apiService.updateById(params).pipe(
          map((scopeOfWork) => {
            this.notificationService.createNotification(
              'success',
              params.body.name,
              'SHARED.SUCCESSFULLY_UPDATED'
            );
            return ScopeOfWorkActions.UpdateScopeOfWorkSuccessful({ payload: { scopeOfWorkId: action.payload.scopeOfWorkId, scopeOfWork: scopeOfWork } });
          }
          ),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              params.body.name,
              'SHARED.COULDNT_UPDATED'
            );
            return [
              ScopeOfWorkActions.UpdateScopeOfWorkFailure(),
              ScopeOfWorkActions.ErrorAction({ payload: { error: error, key: 'UpdateScopeOfWork', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  updateScopeOfWorkSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOfWorkActions.UpdateScopeOfWorkSuccessful),
      mergeMap(action => [
        new Navigate({ url: navigatePathAfterCreatingInstance }),
        ScopeOfWorkActions.GetScopeOfWorks()
      ])
    )
  );

  deleteScopeOfWork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScopeOfWorkActions.DeleteScopeOfWork),
      withLatestFrom(
        this.store.select(state => <ScopeOfWork[]>state.scopeOfWork?.data)
      ),
      mergeMap(([action, scopeOfWorks]) => {
        const params = { id: action.payload.scopeOfWorkId };
        const deletedScopeOfWork = scopeOfWorks.find(scopeOfWork => scopeOfWork.id === action.payload.scopeOfWorkId);

        return this.apiService.softDeleteById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              deletedScopeOfWork?.title || 'ScopeOfWork',
              'SHARED.SUCCESSFULLY_DELETED'
            );
            return ScopeOfWorkActions.GetScopeOfWorks();
          }),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              deletedScopeOfWork?.title || 'ScopeOfWork',
              'SHARED.COULDNT_DELETED'
            );
            return [
              ScopeOfWorkActions.ErrorAction({ payload: { error: error, key: 'DeleteScopeOfWork', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  /* Old actions can not listen on new reducers. So we use this effect temp. */
  resetStateToInitial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.Logout>(AuthenticationActions.ActionTypes.Logout),
      mergeMap(action => [
        ScopeOfWorkActions.ResetStateToInitial()
      ])
    )
  );

  resetStateToInitialChangeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.ChangeActiveProject>(AuthenticationActions.ActionTypes.ChangeActiveProject),
      mergeMap(action => [
        ScopeOfWorkActions.ResetStateToInitial()
      ])
    )
  );
}
