import { createAction, createReducer, on } from '@ngrx/store';
import { WorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';

import * as WorkflowStepFunctionActions from './workflow-step-function.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { PostDataTransformationData, PreDataTransformationData } from '@rappider/shared/interfaces';
import { cloneDeep } from 'lodash';
/* state key */
export const featureKey = 'workflowStepFunction';

/* state interface */
export interface WorkflowStepFunctionState {
  data: WorkflowStepFunctionWithRelations[];
  isLoading: boolean;
  isDataFetched: boolean;
  /** added for diagram. Whenever user clicks add step function button, we're rendering placeholder step function to help the user
   * visually. Removing it whenever stepfunction is added */
  placeholderDataId?: string;
  isDataTransformationLoading: boolean;
  preDataTransformationData: PreDataTransformationData;
  postDataTransformationData: PostDataTransformationData;
  templateWorkflowStepFunctions: WorkflowStepFunctionWithRelations[];
  error: any;
  isTemplateWorkflowStepFunctionsLoaded: boolean;
}

/* initial values */
export const initialState: WorkflowStepFunctionState = {
  data: [],
  isLoading: false,
  isDataFetched: false,
  placeholderDataId: undefined,
  isDataTransformationLoading: false,
  preDataTransformationData: null,
  postDataTransformationData: null,
  templateWorkflowStepFunctions: [],
  error: null,
  isTemplateWorkflowStepFunctionsLoaded: false
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(WorkflowStepFunctionActions.CreateWorkflowStepFunction, (state, action) => ({
    ...state,
    isLoading: true,
    placeholderDataId: action.payload.placeholderDataId
  })),
  on(WorkflowStepFunctionActions.CreateWorkflowStepFunctionSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      action.payload.workflowStepFunction
    ],
    isLoading: false,
    placeholderDataId: action.payload.placeholderDataId ? undefined : state.placeholderDataId
  })),
  on(WorkflowStepFunctionActions.CreateWorkflowStepFunctionFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowStepFunctionActions.GetWorkflowStepFunctionFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false,
    isDataFetched: false,
    data: []
  })),
  on(WorkflowStepFunctionActions.GetWorkflowStepFunction, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionActions.GetWorkflowStepFunctionSuccessful, (state, action) => ({
    ...state,
    data: action.payload.workflowStepFunctions,
    isLoading: false,
    isDataFetched: true
  })),
  on(WorkflowStepFunctionActions.UpdateWorkflowStepFunction, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionActions.UpdateWorkflowStepFunctionSuccessful, (state, action) => {
    const updatedWorkflowStepFunction = {
      ...state.data.find(stepFunction => stepFunction.id === action.payload.workflowStepFunctionId),
      ...action.payload.workflowStepFunction
    };
    return {
      ...state,
      data: [
        ...state.data.filter(workflowStepfunction => workflowStepfunction.id !== updatedWorkflowStepFunction.id),
        updatedWorkflowStepFunction
      ],
      isLoading: false
    };
  }),
  on(WorkflowStepFunctionActions.UpdateWorkflowStepFunctionFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowStepFunctionActions.DeleteWorkflowStepFunction, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionActions.DeleteWorkflowStepFunctionSuccessful, (state, action) => ({
    ...state,
    data: state.data?.filter(workflowStepFunction => workflowStepFunction.id !== action.payload.workflowStepFunctionId),
    isLoading: false
  })),
  on(WorkflowStepFunctionActions.DeleteWorkflowStepFunctionFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowStepFunctionActions.AddPublishedEventOnSuccessToWorkflowStepFunction, (state, action) => {
    let existingWorkflowStepFunction = cloneDeep(state.data.find(workflowStepFunction => workflowStepFunction.id === action.payload.workflowStepFunctionId));
    existingWorkflowStepFunction = {
      ...existingWorkflowStepFunction,
      publishedEventsOnSuccess: [
        ...(existingWorkflowStepFunction.publishedEventsOnSuccess || []),
        action.payload.event
      ]
    };
    return {
      ...state,
      data: [
        ...state.data.filter(workflowStepFunction => workflowStepFunction.id !== action.payload.workflowStepFunctionId),
        existingWorkflowStepFunction
      ],
      isLoading: false
    };
  }),
  on(WorkflowStepFunctionActions.AddPublishedEventOnFailureToWorkflowStepFunction, (state, action) => {
    let existingWorkflowStepFunction = cloneDeep(state.data.find(workflowStepFunction => workflowStepFunction.id === action.payload.workflowStepFunctionId));
    existingWorkflowStepFunction = {
      ...existingWorkflowStepFunction,
      publishedEventsOnFailure: [
        ...(existingWorkflowStepFunction.publishedEventsOnFailure || []),
        action.payload.event
      ]
    };
    return {
      ...state,
      data: [
        ...state.data.filter(workflowStepFunction => workflowStepFunction.id !== action.payload.workflowStepFunctionId),
        existingWorkflowStepFunction
      ],
      isLoading: false
    };
  }),

  /* PRE DATA TRANSFORMATION */
  on(WorkflowStepFunctionActions.GetPreDataTransformationData, (state, action) => ({
    ...state,
    isDataTransformationLoading: true
  })),
  on(WorkflowStepFunctionActions.GetPreDTSourceAndTargetSchemaData, (state, action) => {
    const updatedWorkflowStepFunction = {
      ...state.data.find(workflowStepFunction => workflowStepFunction.id === action.payload.workflowStepFunction.id),
      ...action.payload.workflowStepFunction
    };

    return {
      ...state,
      data: [
        ...state.data.filter(workflowStepFunction => workflowStepFunction.id !== action.payload.workflowStepFunction.id),
        updatedWorkflowStepFunction
      ]
    };
  }),
  on(WorkflowStepFunctionActions.GetPreDataTransformationDataSuccessful, (state, action) => ({
    ...state,
    preDataTransformationData: {
      preDataTransformationId: action.payload.preDataTransformationId,
      preSourceJsonSchema: action.payload.sourceJsonSchema,
      preTargetJsonSchema: action.payload.targetJsonSchema
    },
    isDataTransformationLoading: false
  })),
  on(WorkflowStepFunctionActions.GetPreDataTransformationDataFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isDataTransformationLoading: false
  })),

  /* POST DATA TRANSFORMATION */
  on(WorkflowStepFunctionActions.GetPostDataTransformationData, (state, action) => ({
    ...state,
    isDataTransformationLoading: true
  })),
  on(WorkflowStepFunctionActions.GetPostDTSourceAndTargetSchemaData, (state, action) => {
    const updatedWorkflowStepFunction = {
      ...state.data.find(workflowStepFunction => workflowStepFunction.id === action.payload.workflowStepFunction.id),
      ...action.payload.workflowStepFunction
    };

    return {
      ...state,
      data: [
        ...state.data.filter(workflowStepFunction => workflowStepFunction.id !== action.payload.workflowStepFunction.id),
        updatedWorkflowStepFunction
      ]
    };
  }),
  on(WorkflowStepFunctionActions.GetPostDataTransformationDataSuccessful, (state, action) => ({
    ...state,
    postDataTransformationData: {
      postDataTransformationId: action.payload.postDataTransformationId,
      postSourceJsonSchema: action.payload.sourceJsonSchema,
      postTargetJsonSchema: action.payload.targetJsonSchema
    },
    isDataTransformationLoading: false
  })),
  on(WorkflowStepFunctionActions.GetPostDataTransformationDataFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isDataTransformationLoading: false
  })),
  on(WorkflowStepFunctionActions.GetRappiderWorkflowStepFunctions, (state, action) => ({
    ...state,
    isLoading: true,
    isTemplateWorkflowStepFunctionsLoaded: false
  })),
  on(WorkflowStepFunctionActions.GetRappiderWorkflowStepFunctionsSuccessful, (state, action) => ({
    ...state,
    templateWorkflowStepFunctions: action.payload.workflowStepFunctions,
    isLoading: false,
    isTemplateWorkflowStepFunctionsLoaded: true
  })),
  on(WorkflowStepFunctionActions.GetRappiderWorkflowStepFunctionsFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false,
    isTemplateWorkflowStepFunctionsLoaded: true
  })),
  on(WorkflowStepFunctionActions.ErrorAction, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    }
  })),

  on(UpdateAuthenticationTokenWithProjectIdSuccessful, (state) => ({
    ...initialState,
    templateWorkflowStepFunctions: state.templateWorkflowStepFunctions,
    isTemplateWorkflowStepFunctionsLoaded: state.isTemplateWorkflowStepFunctionsLoaded
  })),

  on(Logout, () => (initialState))
);
