<div class="pagination">
    <nz-pagination [nzPageIndex]="pageIndex"
                   [nzTotal]="total"
                   [nzSize]="'small'"
                   [nzPageSize]="pageSize"
                   [nzDisabled]="disabled"
                   [nzResponsive]="true"
                   [nzShowTotal]="showTotal"
                   [nzItemRender]="renderItemTemplate"
                   (nzPageIndexChange)="onPageIndexChange($event)"
                   (nzPageSizeChange)="onPageSizeChange($event)"></nz-pagination>
                   <ng-template #renderItemTemplate let-type let-page="page">
                    <ng-container [ngSwitch]="type">
                      <a *ngSwitchCase="'page'">{{ page }}</a>
                      <a *ngSwitchCase="'prev'"><rappider-button [shape]="'round'" [icon]="{
                        type: 'FONT_AWESOME',
                        name: 'fa-solid fa-angle-left',
                        size: 'var(--margin-4x)'
                      }"></rappider-button></a>
                      <a *ngSwitchCase="'next'"><rappider-button [shape]="'round'" [icon]="{
                        type: 'FONT_AWESOME',
                        name: 'fa-solid fa-angle-right',
                        size: 'var(--margin-4x)'
                      }"></rappider-button></a>
                      <a *ngSwitchCase="'prev_5'"><<</a>
                      <a *ngSwitchCase="'next_5'">>></a>
                    </ng-container>
                  </ng-template>
                </div>
