/* actions */
import { createAction, createReducer, on } from '@ngrx/store';
import { CommentWithRelations } from '@rappider/rappider-sdk';
import * as CommentActions from './comment.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { orderBy } from 'lodash';

/* state key */
export const featureKey = 'comment';

/* state interface */
export interface State {
  data: CommentWithRelations[];
  isLoading: boolean;
  isLoaded: boolean;
  activeCommentType: string;
  error: {
    error: any;
    key: string;
    date: number;
  };
}

export const initialState: State = {
  data: [],
  isLoading: false,
  isLoaded: false,
  activeCommentType: null,
  error: null
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,

  /* get */
  on(CommentActions.GetComments, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false
  })),
  on(CommentActions.GetCommentsSuccessful, (state, action) => ({
    ...state,
    data: action.comments,
    isLoading: false,
    isLoaded: true
  })),
  on(CommentActions.GetCommentsFailure, (state, action) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: {
      error: action.error,
      key: action.key,
      date: action.date
    }
  })),

  /* set */
  on(CommentActions.SetComment, (state, action) => ({
    ...state,
    data: [
      action.comment,
      ...state.data
    ]
  })),

  on(CommentActions.SetComments, (state, action) => ({
    ...state,
    data: action.comments
  })),

  /* update */
  on(CommentActions.UpdateComment, (state) => ({
    ...state,
    isLoading: true
  })),
  on(CommentActions.UpdateCommentSuccessful, (state, action) => {
    const updatedComment = {
      ...state.data.find(comment => comment.id === action.id),
      ...action.comment
    };

    return {
      ...state,
      data: [
        updatedComment,
        ...state.data.filter(comment => comment.id !== action.id)
      ],
      isLoading: false
    };
  }),
  on(CommentActions.UpdateCommentFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.error,
      key: action.key,
      date: action.date
    }
  })),

  /* delete */
  on(CommentActions.DeleteComment, (state) => ({
    ...state,
    isLoading: true
  })),
  on(CommentActions.DeleteCommentSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(comment => comment.id !== action.id)
    ],
    isLoading: false
  })),

  /* delete reply */
  on(CommentActions.DeleteReply, (state) => ({
    ...state,
    isLoading: true
  })),
  on(CommentActions.DeleteReplySuccessful, (state, action) => {
    const updatedComments = state.data.map(comment => {
      if (comment.id === action.parentCommentId) {
        return {
          ...comment,
          replies: comment.replies.filter(reply => reply.id !== action.replyId)
        };
      }
      return comment;
    });

    const sortedComments = orderBy(updatedComments, 'createdDate', 'desc');

    return {
      ...state,
      data: sortedComments,
      isLoading: false
    };
  }),
  on(CommentActions.UpdateReply, (state) => ({
    ...state,
    isLoading: true
  })),
  on(CommentActions.UpdateReplySuccessful, (state, action) => {
    const parentCommentId = action.parentCommentId;
    const updatedCommentIndex = state.data?.findIndex(comment => comment.id === parentCommentId);

    if (updatedCommentIndex === -1) {
      return state; // Comment not found, return the current state
    }

    const updatedComment = { ...state.data[updatedCommentIndex] };
    const updatedReplyIndex = updatedComment.replies.findIndex(reply => reply.id === action.replyId);

    if (updatedReplyIndex === -1) {
      return state; // Reply not found, return the current state
    }

    const updatedReply = { ...updatedComment.replies[updatedReplyIndex], ...action.reply };

    const updatedCommentWithReply = {
      ...updatedComment,
      replies: [...updatedComment.replies.slice(0, updatedReplyIndex), updatedReply, ...updatedComment.replies.slice(updatedReplyIndex + 1)]
    };

    return {
      ...state,
      data: [
        ...state.data.filter(comment => comment.id !== parentCommentId),
        updatedCommentWithReply
      ],
      isLoading: false
    };
  }),

  on(CommentActions.DeleteReplyFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.error,
      key: action.key,
      date: action.date
    }
  })),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
