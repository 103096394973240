import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentDefinitionWithRelations, ContainerTemplateWithRelations } from '@rappider/rappider-sdk';
import { ContentTreeContainer } from 'libs/content-tree-renderer/src/lib/models';
import { CreateComponent } from '../../../state/content-editor.actions';

@Component({
  selector: 'rappider-add-element-modal',
  templateUrl: './add-element-modal.component.html',
  styleUrls: ['./add-element-modal.component.scss']
})
export class AddElementModalComponent {

  @Input() componentDefinitions: ComponentDefinitionWithRelations[];
  @Input() addElementModalVisibility = false; /* add component modal visibility */
  @Input() activeContentTreeItem: ContentTreeContainer;
  @Input() containerTemplates: ContainerTemplateWithRelations[];

  @Output() addElementModalVisibilityChange = new EventEmitter<boolean>();

  constructor(private store: Store<any>) { }

  onElementAdd(componentDefinitionId: string) {
    this.store.dispatch(new CreateComponent({
      componentDefinitionId: componentDefinitionId,
      title: 'New Component',
    }));
    this.closeAddComponentModal();
  }

  closeAddComponentModal() {
    this.addElementModalVisibility = false;
    this.onModalVisibilityChange(false);
  }

  setModalTitle() {
    return `Add Element Inside ${this.activeContentTreeItem?.title}`;
  }

  onModalVisibilityChange(visibility: boolean) {
    this.addElementModalVisibilityChange.emit(visibility);
  }

}
