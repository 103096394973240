/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { ThemeMode } from '@rappider/models';
import { Person, PersonWithRelations, ProjectRole, ProjectWithRelations, RegisterByCredentialsRequestDto, Tenant, User } from '@rappider/rappider-sdk';
import { LoginType } from '@rappider/shared/definitions';
import { LastProcessedAction, UserRegisterData } from '@rappider/shared/interfaces';

export enum ActionTypes {
  EnableUserLoading = '[Authentication] EnableUserLoading',
  DisableUserLoading = '[Authentication] DisableUserLoading',
  Login = '[Authentication] Login',
  LoginByAuthenticationToken = '[Authentication] LoginByAuthenticationToken',
  LoginByAuthenticationTokenFailure = '[Authentication] LoginByAuthenticationTokenFailure',
  Logout = '[Authentication] Logout',
  LoggedIn = '[Authentication] LoggedIn',
  LoginFailure = '[Authentication] LoginFailure',
  Register = '[Authentication] Register',
  RegisterSuccess = '[Authentication] RegisterSuccess',
  RegisterFailure = '[Authentication] RegisterFailure',
  SetUser = '[Authentication] SetUser',
  GetActivePerson = '[Authentication] GetActivePerson',
  SetActivePerson = '[Authentication] SetActivePerson',
  SetActivePersonFailure = '[Authentication] SetActivePersonFailure',
  ChangeActivePerson = '[Authentication] ChangeActivePerson',
  ChangeActivePersonSuccessful = '[Authentication] ChangeActivePersonSuccessful',
  ChangeActivePersonFailure = '[Authentication] ChangeActivePersonFailure',
  UpdateAuthenticationTokenWithProjectId = '[Authentication] UpdateAuthenticationTokenWithProjectId',
  UpdateAuthenticationTokenWithProjectIdSuccessful = '[Authentication] UpdateAuthenticationTokenWithProjectIdSuccessful',
  SendVerifySms = '[Authentication] SendVerifySms ',
  SendVerifySmsSuccess = '[Authentication] SendVerifySmsSuccess',
  SendVerifySmsFailure = '[Authentication] SendVerifySmsFailure',
  SendVerifyEmail = '[Authentication] SendVerifyEmail',
  SendVerifyEmailSuccess = '[Authentication] SendVerifyEmailSuccess',
  SendVerifyEmailFailure = '[Authentication] SendVerifyEmailFailure',
  SetAuthenticationToken = '[Authentication] SetAuthenticationToken',
  AuthenticationError = '[Authentication] AuthenticationError',
  GetTenant = '[Authentication] GetTenant',
  SetTenant = '[Authentication] SetTenant',
  GetPersonProjectRoles = '[Authentication] GetPersonProjectRoles',
  SetPersonProjectRoles = '[Authentication] SetPersonProjectRoles',
  VerifyEmail = '[Authentication] VerifyEmail',
  VerifyPhoneNumber = '[Authentication] VerifyPhoneNumber',
  ChangeLastProcessedAction = '[Authentication] ChangeLastProcessedAction',
  ForgotPassword = '[Authentication] ForgotPassword',
  ForgotPasswordSuccessful = '[Authentication] ForgotPasswordSuccessful',
  ForgotPasswordFailure = '[Authentication] ForgotPasswordFailure',
  ChangePassword = '[Authentication] ChangePassword',
  ChangePasswordSuccessful = '[Authentication] ChangePasswordSuccessful',
  ChangePasswordFailure = '[Authentication] ChangePasswordFailure',
  AcceptInvitationByToken = '[Authentication] AcceptInvitationByToken',
  AcceptInvitationByTokenSuccessful = '[Authentication] AcceptInvitationByTokenSuccessful',
  AcceptInvitationByTokenFailure = '[Authentication] AcceptInvitationByTokenFailure',
  ErrorAction = '[Authentication] ErrorAction',
  UpdatePreferredThemeForActiveUser = '[Authentication] UpdatePreferredThemeForActiveUser',
  UpdatePreferredThemeForActiveUserSuccessful = '[Authentication] UpdatePreferredThemeForActiveUserSuccessful',
  VerifyEmailSuccessful = '[Authentication] VerifyEmailSuccessful',
  VerifyEmailFailure = '[Authentication] VerifyEmailFailure',
  UpdatePerson = '[Person] UpdatePerson',
  UpdatePersonSuccessful = '[Person] UpdatePersonSuccessful',
  GetPermissions = '[Authentication] GetPermissions',
  GetPermissionsSuccessful = '[Authentication] GetPermissionsSuccessful',
  GetPermissionsFailure = '[Authentication] GetPermissionsFailure',
  SetPermissions = '[Authentication] SetPermissions'
}

export class EnableUserLoading implements Action {
  readonly type = ActionTypes.EnableUserLoading;
}

export class DisableUserLoading implements Action {
  readonly type = ActionTypes.DisableUserLoading;
}
export class Login implements Action {
  readonly type = ActionTypes.Login;
  constructor(public payload: { username: string; password: string; loginType: LoginType }) { }
}

export class LoginByAuthenticationToken implements Action {
  readonly type = ActionTypes.LoginByAuthenticationToken;
  constructor(public payload: { authenticationToken: string; loginType: LoginType; redirectUrl?: string }) {
  }
}

export class LoginByAuthenticationTokenFailure implements Action {
  readonly type = ActionTypes.LoginByAuthenticationTokenFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class LoggedIn implements Action {
  readonly type = ActionTypes.LoggedIn;
  constructor(public payload: { user: User; loginType: LoginType; redirectUrl?: string }) { }
}

export class LoginFailure implements Action {
  readonly type = ActionTypes.LoginFailure;
}

export class Register implements Action {
  readonly type = ActionTypes.Register;
  constructor(public payload: { registerData: RegisterByCredentialsRequestDto }) { }
}

export class RegisterSuccess implements Action {
  readonly type = ActionTypes.RegisterSuccess;
  constructor(public payload: { user: User }) { }
}

export class RegisterFailure implements Action {
  readonly type = ActionTypes.RegisterFailure;
  constructor(public payload: { error: any }) { }
}

export class SetUser implements Action {
  readonly type = ActionTypes.SetUser;
  constructor(public payload: { user: any }) { }
}

export class GetActivePerson implements Action {
  readonly type = ActionTypes.GetActivePerson;
  constructor(public payload: { personId: string }) { }
}

export class SetActivePerson implements Action {
  readonly type = ActionTypes.SetActivePerson;
  constructor(public payload: { person: PersonWithRelations }) { }
}

export class SetActivePersonFailure implements Action {
  readonly type = ActionTypes.SetActivePersonFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class ChangeActivePerson implements Action {
  readonly type = ActionTypes.ChangeActivePerson;
  constructor(public payload: { person: PersonWithRelations }) { }
}

export class ChangeActivePersonSuccessful implements Action {
  readonly type = ActionTypes.ChangeActivePersonSuccessful;
  constructor(public payload: { person: PersonWithRelations }) { }
}

export class ChangeActivePersonFailure implements Action {
  readonly type = ActionTypes.ChangeActivePersonFailure;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export class UpdateAuthenticationTokenWithProjectId implements Action {
  readonly type = ActionTypes.UpdateAuthenticationTokenWithProjectId;
  constructor(public payload: { project: ProjectWithRelations; navigateToProjectDetail?: boolean; navigateAIAssistantPage?: boolean; isAdmin?: boolean }) { }
}

export class UpdateAuthenticationTokenWithProjectIdSuccessful implements Action {
  readonly type = ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful;
  constructor(public payload: { project: ProjectWithRelations; navigateToProjectDetail?: boolean; navigateAIAssistantPage?: boolean }) { }
}

export class Logout implements Action {
  readonly type = ActionTypes.Logout;
}

export class SendVerifySms implements Action {
  readonly type = ActionTypes.SendVerifySms;
}

export class SendVerifySmsSuccess implements Action {
  readonly type = ActionTypes.SendVerifySmsSuccess;
}

export class SendVerifySmsFailure implements Action {
  readonly type = ActionTypes.SendVerifySmsFailure;
}

export class SendVerifyEmail implements Action {
  readonly type = ActionTypes.SendVerifyEmail;
}

export class SendVerifyEmailSuccess implements Action {
  readonly type = ActionTypes.SendVerifyEmailSuccess;
}

export class SendVerifyEmailFailure implements Action {
  readonly type = ActionTypes.SendVerifyEmailFailure;
}

export class SetAuthenticationToken implements Action {
  readonly type = ActionTypes.SetAuthenticationToken;
  constructor(public payload: { authenticationToken: string }) { }
}

export class AuthenticationError implements Action {
  readonly type = ActionTypes.AuthenticationError;
  constructor(public payload: { errorOn: string; error?: any }) { }
}

export class GetTenant implements Action {
  readonly type = ActionTypes.GetTenant;
  constructor(public payload: { tenantId: string }) { }
}

export class SetTenant implements Action {
  readonly type = ActionTypes.SetTenant;
  constructor(public payload: { tenant: Tenant }) { }
}

export class GetPersonProjectRoles implements Action {
  readonly type = ActionTypes.GetPersonProjectRoles;
}

export class SetPersonProjectRoles implements Action {
  readonly type = ActionTypes.SetPersonProjectRoles;
  constructor(public payload: { roles: ProjectRole[] }) { }
}

export class VerifyEmail implements Action {
  readonly type = ActionTypes.VerifyEmail;
  constructor(public payload: { token: string }) { }
}

export class VerifyPhoneNumber implements Action {
  readonly type = ActionTypes.VerifyPhoneNumber;
  constructor(public payload: { pin: string }) { }
}

export class ChangeLastProcessedAction implements Action {
  readonly type = ActionTypes.ChangeLastProcessedAction;
  constructor(public payload: {
    lastProcessedAction: LastProcessedAction;
  }) { }
}

export class ForgotPassword implements Action {
  readonly type = ActionTypes.ForgotPassword;
  constructor(public payload: { username: string }) { }
}
export class ForgotPasswordSuccessful implements Action {
  readonly type = ActionTypes.ForgotPasswordSuccessful;
}
export class ForgotPasswordFailure implements Action {
  readonly type = ActionTypes.ForgotPasswordFailure;
  constructor(public payload: { error: any }) { }
}

export class ChangePassword implements Action {
  readonly type = ActionTypes.ChangePassword;
  constructor(public payload: { token: string; password: string }) { }
}

export class ChangePasswordSuccessful implements Action {
  readonly type = ActionTypes.ChangePasswordSuccessful;
}
export class ChangePasswordFailure implements Action {
  readonly type = ActionTypes.ChangePasswordFailure;
  constructor(public payload: { error: any }) { }
}

export class AcceptInvitationByToken implements Action {
  readonly type = ActionTypes.AcceptInvitationByToken;
  constructor(public payload: { invitationToken: string }) { }
}

export class AcceptInvitationByTokenSuccessful implements Action {
  readonly type = ActionTypes.AcceptInvitationByTokenSuccessful;
}

export class AcceptInvitationByTokenFailure implements Action {
  readonly type = ActionTypes.AcceptInvitationByTokenFailure;
  constructor(public payload: { error: any }) { }
}

export class UpdatePreferredThemeForActiveUser implements Action {
  readonly type = ActionTypes.UpdatePreferredThemeForActiveUser;
  constructor(public payload: { preferredTheme: ThemeMode }) { }
}

export class UpdatePreferredThemeForActiveUserSuccessful implements Action {
  readonly type = ActionTypes.UpdatePreferredThemeForActiveUserSuccessful;
  constructor(public payload: { preferredTheme: ThemeMode }) { }
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.ErrorAction;
  constructor(public payload: { error: any; key: string; timestamp?: number }) { }
}

export class VerifyEmailSuccessful implements Action {
  readonly type = ActionTypes.VerifyEmailSuccessful;
}

export class VerifyEmailFailure implements Action {
  readonly type = ActionTypes.VerifyEmailFailure;
}


export class UpdatePerson implements Action {
  readonly type = ActionTypes.UpdatePerson;
  constructor(public payload: { person: Partial<Person>; id: string; navigateToProjectList?: boolean }) { }
}

export class UpdatePersonSuccessful implements Action {
  readonly type = ActionTypes.UpdatePersonSuccessful;
  constructor(public payload: { person: Partial<Person>; id: string }) { }
}

export class GetPermissions implements Action {
  readonly type = ActionTypes.GetPermissions;
}

export class GetPermissionsSuccessful implements Action {
  readonly type = ActionTypes.GetPermissionsSuccessful;
  constructor(public payload: { permissions: string[] }) { }
}

export class GetPermissionsFailure implements Action {
  readonly type = ActionTypes.GetPermissionsFailure;
  constructor(public payload: { errorMessage?: string }) { }
}

export class SetPermissions implements Action {
  readonly type = ActionTypes.SetPermissions;
  constructor(public payload: { permissions: string[] }) { }
}

export type Actions =
  EnableUserLoading
  | DisableUserLoading
  | Login
  | LoginByAuthenticationToken
  | LoginByAuthenticationTokenFailure
  | LoggedIn
  | LoginFailure
  | Register
  | RegisterSuccess
  | RegisterFailure
  | GetActivePerson
  | SetActivePerson
  | SetActivePersonFailure
  | ChangeActivePerson
  | ChangeActivePersonSuccessful
  | ChangeActivePersonFailure
  | UpdateAuthenticationTokenWithProjectId
  | UpdateAuthenticationTokenWithProjectIdSuccessful
  | SetUser
  | Logout
  | SendVerifySms
  | SendVerifySmsSuccess
  | SendVerifySmsFailure
  | SendVerifyEmail
  | SendVerifyEmailSuccess
  | SendVerifyEmailFailure
  | SetAuthenticationToken
  | AuthenticationError
  | GetTenant
  | SetTenant
  | GetPersonProjectRoles
  | SetPersonProjectRoles
  | VerifyEmail
  | VerifyPhoneNumber
  | ChangeLastProcessedAction
  | ForgotPassword
  | ForgotPasswordSuccessful
  | ForgotPasswordFailure
  | ChangePassword
  | ChangePasswordSuccessful
  | ChangePasswordFailure
  | UpdatePreferredThemeForActiveUser
  | UpdatePreferredThemeForActiveUserSuccessful
  | ErrorAction
  | VerifyEmailSuccessful
  | VerifyEmailFailure
  | UpdatePerson
  | UpdatePersonSuccessful
  | GetPermissions
  | GetPermissionsSuccessful
  | GetPermissionsFailure
  | SetPermissions
  ;

