import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectInterface } from '@rappider/api-sdk';
import { Store } from '@ngrx/store';
import { MESSAGE_TEMPLATE_LIST_CONFIG } from '@rappider/shared/configs';
import { Subscription } from 'rxjs';
import { ActiveEnvironmentChange, DeleteMessageTemplate } from '../../states/message-template-state/message-template.actions';
import { MessageTemplateWithRelations } from '@rappider/rappider-sdk';
import { ActionResponse, BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { MessageTemplateList } from './utils/message-template-list-enum';

@Component({
  selector: 'rappider-message-template-list',
  templateUrl: './message-template-list.component.html',
  styleUrls: ['./message-template-list.component.scss']
})
export class MessageTemplateListComponent implements OnInit, OnDestroy {
  MESSAGE_TEMPLATE_LIST_CONFIG = MESSAGE_TEMPLATE_LIST_CONFIG;
  mainTitle: HeadingComponentConfig;
  title: string | string[] | BreadcrumbOption[];
  /* Active environment key index for tabset */
  selectedEnvironmentTabIndex: number;
  /* filtered message templates data by active environment displayed in list grid */
  displayedMessageTemplateData: MessageTemplateWithRelations[];
  environments: string[];
  activeEnvironment: string;
  subscriptions: Subscription[];
  messageTemplates: MessageTemplateWithRelations[];

  displayToolbar = false;
  displayToolbarBackButton = false;
  loading = false;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  /**
  *  Subscribing all Data
  *
  * @memberof PageCreateComponent
  */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToEnvironments(),
      this.subscribeToActiveEnvironment(),
      this.subscribeToMessageTemplates(),
      this.subscribeToMessageTemplatesLoading(),
    ];
  }

  /**
   * Subscribe Active Project
   *
   * @memberof ProjectEnvironmentVariablesComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject) {
        this.mainTitle = {
          content: 'SHARED.TEMPLATES',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: 'SHARED.TEMPLATES'
          }
        ];
      }
    });
  }

  subscribeToEnvironments() {
    return this.store.select(state => state.environmentVariable?.environments).subscribe((environments: string[]) => {
      this.environments = environments;
    });
  }

  subscribeToActiveEnvironment() {
    return this.store.select(state => state.environmentVariable?.activeEnvironment).subscribe((activeEnvironment: string) => {
      this.selectedEnvironmentTabIndex = this.environments?.findIndex(environment => environment === activeEnvironment);
      this.activeEnvironment = activeEnvironment;
    });
  }

  subscribeToMessageTemplates() {
    return this.store.select(state => state.messageTemplate?.data).subscribe(messageTemplates => {
      this.messageTemplates = messageTemplates;
      if (this.activeEnvironment) {
        this.setActiveEnvironment(this.activeEnvironment);
      } else {
        this.setActiveEnvironment(MessageTemplateList.DefaultMessageTemplateTab);
      }
    });
  }

  subscribeToMessageTemplatesLoading() {
    return this.store.select(state => state.messageTemplate?.loading).subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  setActiveEnvironment(environment: string) {
    if (this.activeEnvironment !== environment) {
      this.store.dispatch(new ActiveEnvironmentChange({ environment: environment }));
    }
    this.displayedMessageTemplateData = this.messageTemplates?.filter(messageTemplate => messageTemplate.environmentKey === this.activeEnvironment);
  }

  /**
   * filters message template data by active environment to set list grid data
   *
   * @param {*} messageTemplates
   * @memberof MessageTemplateListComponent
   */

  onDeleteMessageTemplate(response: ActionResponse) {
    const action = response.action;
    const messageTemplateId = response.data.id;

    if (action.name === 'DELETE_ITEM') {
      this.store.dispatch(new DeleteMessageTemplate({ messageTemplateId }));
    }
  }
}
