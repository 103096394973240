import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderImageUploadComponent } from './image-upload.component';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderImageModule } from '../image/image.module';
import { RappiderSpinModule } from '../spin/spin.module';
import { RappiderPreviewerModule } from '../previewer/previewer.module';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RappiderImageUploadComponent
  ],
  imports: [
    CommonModule,
    RappiderButtonModule,
    RappiderImageModule,
    RappiderSpinModule,
    RappiderPreviewerModule,
    NzUploadModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    RappiderImageUploadComponent
  ]
})
export class RappiderImageUploadModule { }
