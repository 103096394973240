import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rappider-project-description',
  templateUrl: './project-description.component.html',
  styleUrls: ['./project-description.component.scss']
})
export class ProjectDescriptionComponent implements OnInit {

  ngOnInit(): void {
    console.log('Component initialized.');
  }

}
