import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormLayout, SelectComponentConfig, SelectMode, SelectableOption } from '@rappider/rappider-components/utils';
import { StepFunctionEndpointOrCustomOptions } from 'libs/shared/src/lib/models/step-functions/step-function-endpoint-or-custom-options.enum';
import { WorkflowStepFunctionCreateEditFormItem } from '../../models/workflow-step-function-create-edit-form-item.enum';
import { ProjectModelWithRelations } from 'libs/rappider-sdk/src/lib/models/project-model-with-relations';
import { Store } from '@ngrx/store';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomFunctionDefinition, ProjectModelEndpointWithRelations, WorkflowEventWithRelations } from 'libs/rappider-sdk/src/lib/models';
import { ProjectInterface, WorkflowEventInterface } from 'libs/shared/src/lib/sdk/models';
import { Subscription } from 'rxjs';
import { KeyValue } from '@angular/common';
import { CAMEL_CASE_REGEX, NO_SPACE_REGEX } from '@rappider/shared/definitions';
import { WorkflowFunctionTypeOptions } from './utils/workflow-functions-options.enum';
import { WorkflowFunctionTypeRadioGroupOptions } from './utils/workflow-function-type-options.config';
import { StringUtilityService } from '@rappider/services';
import { getWorkflowFunctionsAndTemplates } from 'libs/custom-function/src/lib/components/custom-function-crud-modal-wrapper/utils/get-custom-functions-and-templates-selector';

@Component({
  selector: 'rappider-workflow-step-function-form',
  templateUrl: './workflow-step-function-form.component.html',
  styleUrls: ['./workflow-step-function-form.component.scss']
})
export class WorkflowStepFunctionFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() formLayout: FormLayout = FormLayout.Horizontal;
  @Input() isLoading: boolean;
  @Input() isSubmitButtonFloat: boolean;
  @Input() data: Record<string, unknown>;
  @Input() submitButtonLoading: boolean;

  @Output() workflowStepFunctionFormSubmit = new EventEmitter<Record<string, unknown>>();
  @Output() nameChange = new EventEmitter<string>();
  @Output() selectedMode = new EventEmitter<string>();

  /* form group */
  createWorkflowStepFunctionForm: FormGroup;
  /* project model options */
  projectModelOptions: SelectableOption[];
  /* endpoint options by selected project model */
  endpointOptions: SelectableOption[];
  /* workflow step function type options */
  workflowStepFunctionTypeOptions: SelectableOption[];
  /* active project */
  activeProject: ProjectInterface;
  /* subscriptions */
  subscriptions: Subscription[];
  /* workflow events */
  workflowEvents: WorkflowEventInterface[];
  /* workflow events as key values for selectbox */
  workflowEventOptions: KeyValue<string, string>[];
  customOrEndpointRadioGroupValue = StepFunctionEndpointOrCustomOptions.Endpoint;
  projectModels: ProjectModelWithRelations[];
  /* project model endpoints */
  projectModelEndpoints: ProjectModelEndpointWithRelations[];
  StepFunctionEndpointOrCustomOptions = StepFunctionEndpointOrCustomOptions;
  isSubmitButtonClick = false;
  serviceOptions: SelectableOption[];
  workflowFunctionOptions: SelectableOption[];
  WorkflowFunctionTypeOptions = WorkflowFunctionTypeOptions;
  workflowFunctionModeRadioGroupValue: WorkflowFunctionTypeOptions;
  workflowFunctionModeRadioGroupOptions = WorkflowFunctionTypeRadioGroupOptions;
  workflowFunctions: CustomFunctionDefinition[];
  templateWorkflowFunctions: CustomFunctionDefinition[];
  mode: string;

  /* subscribed events select component config */
  subscribedEventsSelectConfig: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Multiple
    }
  };

  /* published events on success select component config */
  publishedEventsOnSuccess: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Multiple
    }
  };

  /* published events on failure select component config */
  publishedEventsOnFailure: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Multiple
    }
  };

  customOrEndpointRadioGroupOptions = [
    {
      label: 'Endpoint',
      value: StepFunctionEndpointOrCustomOptions.Endpoint
    },
    {
      label: 'Custom',
      value: StepFunctionEndpointOrCustomOptions.Custom
    }
  ];

  monacoEditorSettings = {
    editorOptions: {
      theme: 'vs-dark',
      language: 'javascript'
    },
    sizeSettings: {
      minHeight: '200px'
    }
  };

  constructor(
    private store: Store<any>,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.buildForm();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  buildForm() {
    this.mode = this.data?.mode as string;
    this.workflowFunctionModeRadioGroupValue = this.data?.mode as WorkflowFunctionTypeOptions;
    this.createWorkflowStepFunctionForm = this.formBuilder.group({
      name: [this.data?.name, [Validators.required, Validators.pattern(CAMEL_CASE_REGEX), Validators.pattern(NO_SPACE_REGEX)]],
      subscribedEvents: [this.data?.subscribedEventIds, Validators.required],
      publishedEventsOnSuccess: [this.data?.publishedEventsOnSuccess],
      publishedEventsOnFailure: [this.data?.publishedEventsOnFailure],
      projectModel: [this.data?.projectModel],
      mode: [this.data?.mode],
      customFunctionDefinitionId: [this.data?.customFunctionDefinitionId, Validators.required]
    });
    setTimeout(() => {
      document.getElementById('workflowStepFunctionName').focus();
    }, 100);
    this.isLoading = false;
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectModels(),
      this.subscribeToProjectModelEndpoints(),
      this.subscribeToWorkflowEvents(),
      this.subscribeToWorkflowFunctionsAndTemplates()
    ];
  }

  /**
 * get workflow events by project id
 *
 * @memberof WorkflowStepFunctionCreateComponent
 */

  subscribeToWorkflowEvents() {
    return this.store.select(state => state.workflowEvent.data).subscribe((workflowEvents: WorkflowEventWithRelations[]) => {
      this.workflowEvents = workflowEvents;
      this.workflowEventOptions = workflowEvents.map((workflowEvent: WorkflowEventWithRelations) => ({
        key: workflowEvent.name,
        value: workflowEvent.id
      }));
      /* set workflow event options */
      this.setWorkflowEventOptions();
    });
  }

  subscribeToWorkflowFunctionsAndTemplates() {
    return this.store.select(<any>getWorkflowFunctionsAndTemplates).subscribe(data => {
      this.workflowFunctions = data.workflowFunctions;
      this.templateWorkflowFunctions = data.templateWorkflowFunctions;
      this.setWorkflowFunctionFormItem();
      this.setServiceFormItem();
    });
  }

  setServiceFormItem() {
    this.serviceOptions = this.templateWorkflowFunctions?.map(templateWorkflowFunction => ({
      key: `${templateWorkflowFunction.serviceName}.${templateWorkflowFunction.functionName}`,
      value: templateWorkflowFunction.id
    }));
  }

  setWorkflowFunctionFormItem() {
    this.workflowFunctionOptions = this.workflowFunctions?.map(workflowFunction => ({
      key: workflowFunction.name,
      value: workflowFunction.id
    }));
  }

  stepFunctionModeSelectValueChange(value: WorkflowFunctionTypeOptions) {
    if (this.workflowFunctionModeRadioGroupValue !== value) {
      this.workflowFunctionModeRadioGroupValue = value;
      if (this.data?.customFunctionDefinitionId) {
        this.data.customFunctionDefinitionId = null;
      }
      this.mode = value;
    }
    this.selectedMode.emit(this.mode);
  }

  /**
* set workflow event options in config
*
* @memberof WorkflowStepFunctionCreateComponent
*/
  setWorkflowEventOptions() {
    this.subscribedEventsSelectConfig.options = this.workflowEventOptions;
    this.publishedEventsOnSuccess.options = this.workflowEventOptions;
    this.publishedEventsOnFailure.options = this.workflowEventOptions;
  }

  subscribeToProjectModels() {
    return this.store.select(state => state.projectModel.data).subscribe((projectModels: ProjectModelWithRelations[]) => {
      /* save project models for endpoints */
      this.projectModels = projectModels;
      /* set project model options */
      this.projectModelOptions = projectModels.map((projectModel: ProjectModelWithRelations) => ({
        key: projectModel.name,
        value: projectModel.id
      }));
    });

  }

  subscribeToProjectModelEndpoints() {
    return this.store.select(state => state.projectModelEndpoint?.data).subscribe((projectModelEndpoints: ProjectModelEndpointWithRelations[]) => {
      this.projectModelEndpoints = projectModelEndpoints;
    });
  }

  /**
 * when project model change, reset enpointId value and options
 *
 * @param {string} projectModelId
 * @memberof WorkflowStepFunctionCreateComponent
 */
  onProjectModelValueChange(projectModelId: string) {
    this.endpointOptions = [];
    this.createWorkflowStepFunctionForm.controls[WorkflowStepFunctionCreateEditFormItem.EndpointId].reset();
    if (projectModelId !== null) {
      this.setEndpointsFormItem(projectModelId);
    }
  }

  /**
* sets endpoints select box form item in config
*
* @param {string} projectModelId
* @memberof WorkflowStepFunctionCreateComponent
*/
  setEndpointsFormItem(projectModelId: string) {
    const endpoints = this.projectModelEndpoints?.filter(projectModelEndpoint => projectModelEndpoint.modelId === projectModelId);

    this.endpointOptions = endpoints?.map(endpoint => ({
      key: endpoint.name,
      value: endpoint.id
    }));

  }


  /**
 * deletes or adds projectModel and endpointId by radio group value
 *
 * @param {StepFunctionEndpointOrCustomOptions} value
 * @memberof WorkflowStepFunctionCreateComponent
 */
  onCustomOrEndpointRadioValueChange(value: StepFunctionEndpointOrCustomOptions) {
    this.customOrEndpointRadioGroupValue = value;
    if (value === StepFunctionEndpointOrCustomOptions.Custom) {
      this.createWorkflowStepFunctionForm.removeControl(WorkflowStepFunctionCreateEditFormItem.ProjectModel);
      this.createWorkflowStepFunctionForm.removeControl(WorkflowStepFunctionCreateEditFormItem.EndpointId);
      this.endpointOptions = [];
    } else {
      this.createWorkflowStepFunctionForm.addControl(WorkflowStepFunctionCreateEditFormItem.ProjectModel, new FormControl());
      this.createWorkflowStepFunctionForm.addControl(WorkflowStepFunctionCreateEditFormItem.EndpointId, new FormControl());
    }
  }

  getNameFieldErrorsByErrorKey(errorKey: string) {
    const control = this.createWorkflowStepFunctionForm.get(WorkflowStepFunctionCreateEditFormItem.Name);
    const isDirty = control.dirty;
    const errors = control.errors;
    return isDirty && errors && control.hasError(errorKey);
  }

  onActiveCustomFunctionEmit(output) {
    this.createWorkflowStepFunctionForm.get('customFunctionDefinitionId').setValue(output?.id);
  }

  onWorkflowStepFunctionFormSubmit() {
    this.isSubmitButtonClick = true;
    if (this.createWorkflowStepFunctionForm.valid) {
      if (!this.createWorkflowStepFunctionForm.get('customFunctionDefinitionId').value) {
        this.createWorkflowStepFunctionForm.removeControl('customFunctionDefinitionId');
      }
      this.workflowStepFunctionFormSubmit.emit(this.createWorkflowStepFunctionForm.value);
    }
  }

  onNameChange(name: string) {
    const stepFunctionName = StringUtilityService.toCamelCase(name);
    if (stepFunctionName !== name) {
      this.createWorkflowStepFunctionForm.patchValue({ name: stepFunctionName });
    }
    this.nameChange.emit(stepFunctionName);
  }

  calculateFormLayout() {
    return this.formLayout === FormLayout.Horizontal ? { label: 6, input: 18 } : { label: null, input: null };
  }
}
