<nz-carousel [nzAutoPlay]="autoplay"
             [nzAutoPlaySpeed]="autoplaySpeed"
             [nzDotPosition]="dotPosition"
             [nzDots]="dots"
             [nzEffect]="effect || CarouselEffect.Fade"
             [nzEnableSwipe]="enableSwipe">
  <div class="carousel"
       nz-carousel-content
       *ngFor="let content of contents">
    <div class="content">
      <div *ngIf="isContentVisible">
        <rappider-text [text]="content"></rappider-text>
      </div>
      <ng-container *ngTemplateOutlet="content.carouselTemplate"></ng-container>
    </div>
  </div>
</nz-carousel>
