import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ButtonComponentConfig, ButtonSize, ButtonType, CODEMIRROR_CSS_SETTINGS, IconType } from '@rappider/rappider-components/utils';
import { Page } from '@rappider/rappider-sdk';
import { UpdatePageCss } from '../../state/content-editor.actions';

@Component({
  selector: 'rappider-page-style-customization',
  templateUrl: './page-style-customization.component.html',
  styleUrls: ['./page-style-customization.component.scss']
})
export class PageStyleCustomizationComponent {

  @Input() page: Page;

  codemirrorSettings = CODEMIRROR_CSS_SETTINGS;
  codemirrorAdditionalButtons: ButtonComponentConfig[] = [
    {
      key: 'open-modal',
      icon: {
        name: 'fa-light fa-expand',
        type: IconType.FontAwesome,
        color: 'var(--text-color)'
      },
      size: ButtonSize.Small,
      type: ButtonType.Link
    }
  ];
  modalMode = false;
  /* for handle codemirror visibility after modal render */
  codemirrorInModalVisibility = false;

  constructor(private store: Store<any>) { }

  onEditProjectCssStyle(css: string) {
    this.store.dispatch(new UpdatePageCss({
      pageId: this.page.id,
      css: css
    }));
  }

  onAdditionalButtonClick(button: ButtonComponentConfig) {
    if (button.key === 'open-modal') {
      this.modalMode = true;
      setTimeout(() => {
        this.codemirrorInModalVisibility = true;
      }, 400);
    }
  }

  onPageStyleModalClose() {
    this.modalMode = false;
    this.codemirrorInModalVisibility = false;
  }

}
