<!-- ----------------------------------------------------------------------------------------- -->
<!-- TITLE AREA -->
<rappider-title-toolbar *ngIf="title"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>
<!-- / TITLE AREA -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- INVITED USERS AREA -->
<section>
  <rappider-list-grid [config]="INVITED_USER_LIST_CONFIG"
                      [data]="invitedUsers"
                      [loading]="isLoading"
                      (listActionClick)="openInviteNewUserModal($event)"
                      (columnActionClick)="resendOrCancelInvitation($event)"></rappider-list-grid>
</section>
<!-- ----------------------------------------------------------------------------------------- -->
<!-- / INVITED USERS AREA -->
<!-- ----------------------------------------------------------------------------------------- -->
<!-- INVITE USER MODAL -->
<rappider-modal [(visible)]="inviteUser.visible"
                [title]="inviteUser.title | translate"
                [okText]="'SHARED.INVITE' | translate"
                [cancelText]="'SHARED.CANCEL' | translate"
                [fullScreen]="inviteUser.fullScreen"
                (okClick)="onInviteUserSubmit()"
                (cancelClick)="closeInviteUser()">
  <rappider-crud-view-edit-form *ngIf="inviteUser.visible"
                                [config]="INVITE_USER_CONFIG"
                                [data]="inviteUser.data"
                                [submitted]="inviteUser.isSubmitted"
                                [submitButtonLoading]="isLoading"
                                (validityChange)="onInviteUserIsValid($event)"
                                (formValueChange)="onInviteUserDataChange($event)">
  </rappider-crud-view-edit-form>
</rappider-modal>
<!-- / INVITE USER MODAL -->
<!-- ----------------------------------------------------------------------------------------- -->