import { createAction, props } from '@ngrx/store';
import { DbDiagramNode, NewDbDiagramNode } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GenerateDBDiagramData = '[DBDiagramStore] GenerateDBDiagramData',
  GetDBDiagramNodes = '[DbDiagramStore] GetDbDiagramNodes',
  GetDBDiagramNodesSuccessful = '[DbDiagramStore] GetDBDiagramNodesSuccessful',
  GetDBDiagramNodesFailure = '[DbDiagramStore] GetDBDiagramNodesFailure',
  CreateDBDiagramNode = '[DbDiagramStore] CreateDBDiagramNode',
  CreateDBDiagramNodeFailure = '[DbDiagramStore] CreateDBDiagramNodeFailure',
  CreateDBDiagramNodeSuccessful = '[DbDiagramStore] CreateDBDiagramNodeSuccessful',
  UpdateDBDiagramNode = '[DbDiagramStore] UpdateDBDiagramNode',
  UpdateDBDiagramNodeFailure = '[DbDiagramStore] UpdateDBDiagramNodeFailure',
  UpdateDBDiagramNodeSuccessful = '[DbDiagramStore] UpdateDBDiagramNodeSuccessful',
  UpdateFormPanelVisibility = '[DbDiagramStore] UpdateFormPanelVisibility',
  UpdateScrollingDataModulId = '[DbDiagramStore] UpdateScrollingDataModulId'
}

/* actions */

export const GetDBDiagramNodes = createAction(
  ActionTypes.GetDBDiagramNodes,
);

export const GetDBDiagramNodesSuccessful = createAction(
  ActionTypes.GetDBDiagramNodesSuccessful,
  props<{ payload: { dbDiagramNodes: DbDiagramNode[] } }>()
);

export const CreateDBDiagramNode = createAction(
  ActionTypes.CreateDBDiagramNode,
  props<{ payload: { dbDiagramNode: NewDbDiagramNode } }>()
);
export const CreateDBDiagramNodeSuccessful = createAction(
  ActionTypes.CreateDBDiagramNodeSuccessful,
  props<{ payload: { dbDiagramNode: DbDiagramNode } }>()
);

export const CreateDBDiagramNodeFailure = createAction(
  ActionTypes.CreateDBDiagramNodeFailure,
);
export const UpdateDBDiagramNode = createAction(
  ActionTypes.UpdateDBDiagramNode,
  props<{ payload: { id: string; dbDiagramNode: Partial<DbDiagramNode> } }>()
);
export const UpdateDBDiagramNodeSuccessful = createAction(
  ActionTypes.UpdateDBDiagramNodeSuccessful,
  props<{ payload: { id: string; dbDiagramNode: Partial<DbDiagramNode> } }>()
);

export const UpdateDBDiagramNodeFailure = createAction(
  ActionTypes.UpdateDBDiagramNodeFailure,
);

export const GetDBDiagramNodesFailure = createAction(ActionTypes.GetDBDiagramNodesFailure)();

export const UpdateFormPanelVisibility = createAction(
  ActionTypes.UpdateFormPanelVisibility,
  props<{ payload: { visibility: boolean } }>()
);

export const UpdateScrollingDataModulId = createAction(
  ActionTypes.UpdateScrollingDataModulId,
  props<{ payload: { updateScrollingDataWithScaling?: { id?: string; renderingAreaPercent?: number; isLastModel?: boolean; isAnimateStart?: boolean } } }>()
);

export const FocusToRelatedModelId = createAction(
  ActionTypes.GetDBDiagramNodesSuccessful,
  props<{ payload: { focusToRelatedModelId: string } }>()
);
