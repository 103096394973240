import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  CustomFunctionDefinition,
  CustomFunctionDefinitionControllerService,
  CustomFunctionDefinitionPartial, CustomFunctionDefinitionWithRelations,
  NewCustomFunctionDefinition
} from '@rappider/rappider-sdk';
import { removeEmptyFields } from '../utils/remove-empty-fields.function';

import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import * as CustomFunctionActions from './custom-function.actions';
import * as RouterActions from '@rappider/shared';
import { NotificationService } from '@rappider/services';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

@Injectable()
export class CustomFunctionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private customFunctionApi: CustomFunctionDefinitionControllerService,
    private notificationService: NotificationService,
  ) { }

  getRappiderCustomFunctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomFunctionActions.GetRappiderCustomFunctions),
      switchMap((action) => this.customFunctionApi.findTemplateFunctions().pipe(
          map((customFunctions: CustomFunctionDefinitionWithRelations[]) =>
            CustomFunctionActions.GetRappiderCustomFunctionsSuccessful({ payload: { customFunctions } })
          ),
          catchError((error) => [
            CustomFunctionActions.GetRappiderCustomFunctionsFailure({
              payload: {
                error,
                key: 'GetRappiderCustomFunctionsFailure',
                timestamp: Date.now(),
              }
            }),
          ])
        )
      )
    )
  );

  getCustomFunctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomFunctionActions.GetCustomFunctions),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      switchMap(([action, activeProjectId]) => {
        const filter = {
          where: {
            projectId: activeProjectId
          },
          order: [
            'serviceName ASC',
            'functionName ASC'
          ]
        };

        return this.customFunctionApi.find({ filter }).pipe(
          map((customFunctions: CustomFunctionDefinitionWithRelations[]) => CustomFunctionActions.GetCustomFunctionsSuccessful({ customFunctions })),
          catchError((error) => [
            CustomFunctionActions.GetCustomFunctionsFailure({ error, key: 'GetCustomFunctions', timestamp: Date.now() })
          ])
        );
      })
    )
  );

  getCustomFunctionById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomFunctionActions.GetCustomFunctionById
      ),
      switchMap((action) => this.customFunctionApi.findById({ id: action.customFunctionId }).pipe(
        map((customFunction: CustomFunctionDefinitionWithRelations) => CustomFunctionActions.GetCustomFunctionByIdSuccessful({ customFunction })),
        catchError((error) => [
          CustomFunctionActions.GetCustomFunctionByIdFailure({ error, key: 'GetCustomFunctionById', timestamp: Date.now() })
        ])
      ))
    )
  );

  createCustomFunction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomFunctionActions.CreateCustomFunction
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      switchMap(([action, activeProjectId]) => {
        let createBody: NewCustomFunctionDefinition = {
          ...action.customFunction,
          requestJSONSample: action.customFunction.requestJSONSample,
          responseJSONSample: action.customFunction.responseJSONSample
        };

        createBody = removeEmptyFields(createBody) as NewCustomFunctionDefinition;

        return this.customFunctionApi.create({ body: createBody }).pipe(
          map((customFunction: CustomFunctionDefinition) => CustomFunctionActions.CreateCustomFunctionSuccessful({ customFunction, navigateAfterSubmit: action.navigateAfterSubmit, lastCreatedOrEdited: customFunction })),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              'There was an error while creating workflow function.'
            );
            return [
              CustomFunctionActions.CreateCustomFunctionFailure({ error, key: 'CreateCustomFunction', timestamp: Date.now() })
            ];
          })
        );
      })
    )
  );

  createCustomfunctionSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomFunctionActions.CreateCustomFunctionSuccessful
      ),
      map((action) => {
        this.notificationService.createNotification(
          'success',
          'SHARED.SUCCESSFUL',
          `${action.customFunction.functionName} Successfully Created`
        );
        if (action.navigateAfterSubmit) {
          return new RouterActions.Navigate({ url: `${PAGE_DEFINITIONS.CUSTOM_FUNCTION.URL}/${PAGE_DEFINITIONS.WORKFLOW_FUNCTION.URL}` });
        } else {
          return CustomFunctionActions.CustomFunctionInfo({ info: 'No routing instruction' });
        }
      })
    )
  );

  updateCustomfunction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomFunctionActions.UpdateCustomFunction
      ),
      switchMap((action) => {
        let updateBody: CustomFunctionDefinitionPartial = {
          ...action.customFunction,
          requestJSONSchema: action.customFunction.requestJSONSchema,
          responseJSONSchema: action.customFunction.responseJSONSchema
        };

        updateBody = removeEmptyFields(updateBody) as CustomFunctionDefinitionPartial;

        return this.customFunctionApi.updateById({ id: action.id, body: updateBody }).pipe(
          map(() => CustomFunctionActions.UpdateCustomFunctionSuccessful({ id: action.id, customFunction: action.customFunction, navigateAfterSubmit: action.navigateAfterSubmit })),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              'There was an error while updating workflow function.'
            );
            return [
              CustomFunctionActions.UpdateCustomFunctionFailure({ error, key: 'UpdateCustomFunction', timestamp: Date.now() })
            ];
          })
        );
      })
    )
  );

  updateCustomfunctionSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomFunctionActions.UpdateCustomFunctionSuccessful
      ),
      map((action) => {
        this.notificationService.createNotification(
          'success',
          'SHARED.SUCCESSFUL',
          `${action.customFunction.functionName} Successfully Updated`
        );
        if (action.navigateAfterSubmit) {
          return new RouterActions.Navigate({ url: `${PAGE_DEFINITIONS.CUSTOM_FUNCTION.URL}/${PAGE_DEFINITIONS.WORKFLOW_FUNCTION.URL}` });
        } else {
          return CustomFunctionActions.CustomFunctionInfo({ info: 'No routing instruction' });
        }
      })
    )
  );

  deleteCustomFunction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CustomFunctionActions.DeleteCustomFunction
      ),
      switchMap((action) => this.customFunctionApi.deleteById({ id: action.id }).pipe(
        map(() => CustomFunctionActions.DeleteCustomFunctionSuccessful({ id: action.id })),
        catchError((error) => [
          CustomFunctionActions.DeleteCustomFunctionFailure({ error, key: 'DeleteCustomFunction', timestamp: Date.now() })
        ])
      ))
    )
  );
}
