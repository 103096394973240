import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, PaginationService } from '@rappider/services';
import { EnvironmentVariableControllerService, NewEnvironmentVariable, EnvironmentVariableUpdateDto, EnvironmentVariable } from '@rappider/rappider-sdk';

import * as EnvironmentVariableActions from './environment-variable.actions';
import * as ActiveProjectActions from '../../states/active-project-state/active-project.actions';


@Injectable()
export class EnvironmentVariableEffects {
  constructor(
    private actions$: Actions,
    private environmentVariableApi: EnvironmentVariableControllerService,
    private store: Store<any>,
    private notificationService: NotificationService,
    private paginationService: PaginationService
  ) { }


  loadModule$ = createEffect(() => this.actions$.pipe(
    ofType<EnvironmentVariableActions.LoadModule>(
      EnvironmentVariableActions.ActionTypes.LoadModule
    ),
    concatMap(() => [
      new EnvironmentVariableActions.GetEnvironmentVariables()
    ])
  ));

  /**
   * get environment variables and update the state
   *
   * @memberof EnvironmentVariablesEffects
   */

  getEnvironmentVariables$ = createEffect(() => this.actions$.pipe(
    ofType<EnvironmentVariableActions.GetEnvironmentVariables>(
      EnvironmentVariableActions.ActionTypes.GetEnvironmentVariables
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectId]) => {
      if (!activeProjectId) {
        return [
          new EnvironmentVariableActions.EnvironmentVariableError({ errorOn: 'GetEnvironmentVariables' }),
        ];
      }
      const filter = {
        order: ['createdDate DESC']
      };
      return this.environmentVariableApi.find({ filter }).pipe(
        map(
          (environmentVariables) => new EnvironmentVariableActions.GetEnvironmentVariablesSuccessful({ environmentVariables: environmentVariables })
        ),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ENVIRONMENT_VARIABLE_COULDNOT_LOADED'
          );
          return [
            new EnvironmentVariableActions.EnvironmentVariableError({ errorOn: 'GetEnvironmentVariables', error: error }),
          ];
        })
      );
    })
  ));

  /**
   * Create Environment Variables and update state fields
   *
   * @memberof EnvironmentVariablesEffects
   */

  createEnvironmentVariable$ = createEffect(() => this.actions$.pipe(
    ofType<EnvironmentVariableActions.CreateEnvironmentVariable>(
      EnvironmentVariableActions.ActionTypes.CreateEnvironmentVariable
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson),
    ),
    mergeMap(([action, person]) => {
      const environmentVariableData = <NewEnvironmentVariable>{
        ...action.payload.environmentVariable,
        environmentKey: action.payload.environmentKey
      };
      return this.environmentVariableApi.createEnvironmentVariableForAllEnvironmentKeys({
        body: environmentVariableData
      }).pipe(
        map((environmentVariables: EnvironmentVariable[]) => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ENVIRONMENT_VARIABLE_CREATED'
          );
          return new EnvironmentVariableActions.CreateEnvironmentVariableSuccessful({ environmentVariables: environmentVariables });
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ENVIRONMENT_VARIABLE_COULDNOT_CREATED'
          );
          return [
            new EnvironmentVariableActions.EnvironmentVariableError({ errorOn: 'CreateEnvironmentVariable', error: error }),
          ];
        })
      );
    })
  ));

  /**
   * Update Environment Variables and update state fields
   *
   * @memberof EnvironmentVariablesEffects
   */

  updateEnvironmentVariable$ = createEffect(() => this.actions$.pipe(
    ofType<EnvironmentVariableActions.UpdateEnvironmentVariable>(
      EnvironmentVariableActions.ActionTypes.UpdateEnvironmentVariable
    ),
    withLatestFrom(
      this.store.select(state => state.environmentVariable.data),
    ),
    mergeMap(([action, environmentVariablesData]) => {
      // form data
      const environmentData = <EnvironmentVariableUpdateDto>{
        ...action.payload.environmentVariable,
      };
      // state data
      const environmentVariable: EnvironmentVariable = environmentVariablesData.find(envVar => envVar.id === action.payload.environmentVariableId);

      // If the isSensitive field of the data to be updated in the state is true, we delete the value field.
      if (environmentVariable.isSensitive) {
        delete environmentData.value;
      }

      return this.environmentVariableApi.updateById({
        id: action.payload.environmentVariableId,
        body: environmentData
      }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ENVIRONMENT_VARIABLE_UPDATED'
          );
          const updatedData = {
            ...action.payload,
            environmentVariable: {
              ...action.payload.environmentVariable,
              value: action.payload.environmentVariable.isSensitive ? '*****' : action.payload.environmentVariable.value
            }
          };
          return new EnvironmentVariableActions.UpdateEnvironmentVariableSuccessful(updatedData);
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ENVIRONMENT_VARIABLE_COULDNOT_UPDATED'
          );
          return [
            new EnvironmentVariableActions.EnvironmentVariableError({ errorOn: 'UpdateEnvironmentVariable', error: error }),
          ];
        })
      );
    })
  ));

  /**
   * Delete Environment Variables and update state fields
   *
   * @memberof EnvironmentVariablesEffects
   */

  deleteEnvironmentVariable$ = createEffect(() => this.actions$.pipe(
    ofType<EnvironmentVariableActions.DeleteEnvironmentVariable>(
      EnvironmentVariableActions.ActionTypes.DeleteEnvironmentVariable
    ),
    mergeMap(action => {
      if (action.payload.environmentVariableId) {
        return this.environmentVariableApi.deleteById({ id: action.payload.environmentVariableId }).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESSFUL',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ENVIRONMENT_VARIABLE_DELETED'
            );
            return new EnvironmentVariableActions.DeleteEnvironmentVariableSuccessful({ environmentVariableId: action.payload.environmentVariableId });
          }),
          catchError(error => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ENVIRONMENT_VARIABLE_COULDNOT_DELETED'
            );
            return [
              new EnvironmentVariableActions.EnvironmentVariableError({ errorOn: 'DeleteEnvironmentVariable', error: error }),
            ];
          })
        );
      }
    })
  ));

  /**
  * Delete Environment Variables and update state fields
  *
  * @memberof EnvironmentVariablesEffects
  */

  activeEnvironmentKeyChange$ = createEffect(() => this.actions$.pipe(
    ofType<EnvironmentVariableActions.ActiveEnvironmentChange>(
      EnvironmentVariableActions.ActionTypes.ActiveEnvironmentChange
    ),
    concatMap(action => {
      if (action.payload.environment) {
        return [
          new EnvironmentVariableActions.SetActiveEnvironment({ environment: action.payload.environment }),
        ];
      }
    })
  ));

  /**
  * Create Environment Variables and update state fields
  *
  * @memberof EnvironmentVariablesEffects
  */

  getEnvironmentKeys$ = createEffect(() => this.actions$.pipe(
    ofType<EnvironmentVariableActions.GetEnvironments | ActiveProjectActions.SetActiveProject>(
      EnvironmentVariableActions.ActionTypes.GetEnvironments,
      ActiveProjectActions.ActionTypes.SetActiveProject
    ),
    withLatestFrom(
      this.store.select(state => state.environmentVariable.environmentKeys),
    ),
    mergeMap(([action, environmentKeys]) => {
      if (environmentKeys?.length) {
        return [];
      }
      return this.environmentVariableApi.getEnvironmentKeys().pipe(
        map((environmentKeys) =>
          new EnvironmentVariableActions.SetEnvironments({ environments: environmentKeys })
        ),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_ENVIRONMENT_VARIABLE_COULDNOT_LOADED'
          );
          return [
            new EnvironmentVariableActions.EnvironmentVariableError({ errorOn: 'GetEnvironmentVariables', error: error }),
          ];
        })
      );
    })
  ));

}
