<!-- ---------------------------------------------------------------------------------------------- -->
<!-- ADD ELEMENT MODAL -->
<rappider-modal [(visible)]="addElementModalVisibility"
                [title]="setModalTitle()"
                className="add-component-modal"
                [fullScreen]="true"
                [footer]="null"
                (cancelClick)="closeAddComponentModal()"
                (visibleChange)="onModalVisibilityChange($event)">
  <rappider-spin [spinning]="!componentDefinitions">
    <rappider-element-menu [componentDefinitions]="componentDefinitions"
                           [isComponentUsingInModal]="true"
                           (componentAdded)="closeAddComponentModal()"></rappider-element-menu>
  </rappider-spin>
</rappider-modal>
<!-- / ADD ELEMENT MODAL -->
<!-- ---------------------------------------------------------------------------------------------- -->