import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommentPartial, PersonWithRelations } from '@rappider/rappider-sdk';
import { CommentStatus } from '../../utils/comment-status.enum';
import { KeyValue } from '@angular/common';
import { commentStatusOptions } from '../../utils/comment-status-options';
import { CommentDisplayReply } from '../../utils/comment-display-reply.interface';
import { cloneDeep } from 'lodash';
import { CommentAnimationService } from '../../utils/services/comment-animation.service';


@Component({
  selector: 'rappider-comment-display',
  templateUrl: './comment-display.component.html',
  styleUrls: ['./comment-display.component.scss'],
  animations: [CommentAnimationService.hideShow],
})
export class CommentDisplayComponent implements OnChanges {
  /* status of the comment */
  @Input() status: CommentStatus;
  /* content of the comment */
  @Input() content: string;
  /* created day of the comment */
  @Input() createdDate: string;
  /* person who the comment is assigned */
  @Input() assignedTo: PersonWithRelations;
  /* assigned person's id */
  @Input() assignedToPersonId: string;
  /* person who created the comment */
  @Input() createdBy: string;
  /* comment creator's id */
  @Input() createdById: string;
  /* active person of the project */
  @Input() activePerson: PersonWithRelations;
  /* key value data of people in project for selectbox */
  @Input() assignedPersonSelectData: KeyValue<string, string>[];
  /* additional contenet for comment displayed at the bottom */
  @Input() additionalContent: string;
  /* componet edit mode state */
  @Input() isCommentEditModeActive = false;
  /* component reply mode state - displays reply textbox, save and cancel buttons */
  @Input() isCreateReplyModeActive = false;
  /* key value data for mentions select options - person name & person mail */
  @Input() mentionData: KeyValue<string, string>[] = [];
  /* replies of the comment */
  @Input() replies?: CommentDisplayReply[];

  /* comment */
  @Output() saveComment = new EventEmitter<CommentPartial>();
  @Output() cancelComment = new EventEmitter();
  @Output() deleteComment = new EventEmitter();
  /* reply */
  @Output() saveReply = new EventEmitter();
  @Output() editReply = new EventEmitter();
  @Output() deleteReply = new EventEmitter<string>();
  /* utils */
  @Output() clickCard = new EventEmitter();
  @Output() assignToPerson = new EventEmitter();
  @Output() commentStatusClick = new EventEmitter();

  /* hovered state of comment */
  isCommentHovered = false;
  /* key vale pair of comment status oprions */
  commentStatusOptions = commentStatusOptions;
  /*  */
  isReplyEditModeActive = false;
  commentStatus = CommentStatus;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.replies) {
      this.replies = this.replies ? cloneDeep(this.replies) : [];
    }
  }

  // #region COMMENT FUNCTIONS
  setCommentHoveredState(isHovered: boolean) {
    this.isCommentHovered = isHovered;
  }

  onAssignToPerson(personId: string) {
    this.assignToPerson.emit(personId);
  }

  onClickCommentCard() {
    if (!this.isCommentEditModeActive && !this.isReplyEditModeActive) {
      this.isCreateReplyModeActive = true;
    }
    this.clickCard.emit();
  }

  onCommentStatusButtonClick() {
    this.commentStatusClick.emit();
  }

  toggleCommentEditMode() {
    this.isCommentEditModeActive = !this.isCommentEditModeActive;
  }

  onSaveComment(commentData) {
    this.saveComment.emit(commentData);
  }

  onCancelComment() {
    this.cancelComment.emit();
  }

  onClickDeleteComment() {
    this.deleteComment.emit();
  }
  // #endregion COMMENT FUNCTIONS

  // #region REPLY FUNCTIONS
  toggleCreateReplyMode() {
    this.isCreateReplyModeActive = !this.isCreateReplyModeActive;
  }

  onSaveReplyButtonClick(replyData) {
    this.saveReply.emit(replyData);
    this.toggleCreateReplyMode();
  }

  onEditReply(replyData) {
    this.editReply.emit(replyData);
  }

  onDeleteReply(replyId: string) {
    this.deleteReply.emit(replyId);
  }

  onCancelReply() {
    this.toggleCreateReplyMode();
  }
  // #endregion REPLY FUNCTIONS
}
