<nz-table #dynamicTable
          [nzData]="data"
          [nzShowPagination]="data?.length > (config?.pageSize || defaultPageSize) || false">
  <thead>
    <tr *ngIf="config?.showHeaders">
      <th nzWidth="40px"
          *ngIf="config?.expandable"
          [nzLeft]="fixedColumn"></th>
      <!-- Select Checkboxes -->
      <th *ngIf="config?.selectable"
          nzWidth="60px"
          [(nzChecked)]="allItemsSelected"
          [nzLeft]="fixedColumn"
          [nzIndeterminate]="!allItemsSelected && !allItemsUnSelected"></th>

      <th *ngFor="let item of config.items"
          [nzLeft]="fixedColumn">{{item.title}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let dataItem of data"
        [ngClass]="{'enableRowPointer': enableRowPointer}"
        (click)="onRowActionClick(dataItem)">
      <td [nzLeft]="fixedColumn"
          *ngIf="config?.expandable"></td>
      <td [nzLeft]="fixedColumn"
          *ngIf="config?.selectable"
          [(nzChecked)]="dataItem.selected"></td>

      <td *ngFor="let item of (config?.items?.length ? config?.items : config?.columns)"
          [nzLeft]="fixedColumn">{{ dataItem[item?.fieldName] }}</td>
    </tr>
    <tr *ngIf="config?.expandable">
      <span>Expanded template</span>
    </tr>
  </tbody>
</nz-table>
