<rappider-page-wrapper [titleToolbarConfig]="titleToolbarConfig">
  <section class="d-flex">
    <rappider-button text="Create a New Theme"
                     (click)="navigateToCreateThemePage()"></rappider-button>
  </section>
  <rappider-spin [spinning]="loading">
    <section>
      <div class="theme-list-wrapper row">
        <div *ngFor="let theme of themes"
             class="col-12 pb-4">
          <section class="theme-card">
            <div class="theme-preview-thumbnail"
                 [id]="theme.id"
                 (click)="navigateToProjectTheme(theme.id)">
              <rappider-example-template-two [showTooltip]="false"></rappider-example-template-two>
              <div *ngIf="theme.id === activeProject?.activeProjectThemeId"
                   class="active-theme-icon">
                <rappider-icon [name]="activeThemeIcon.name"
                               [type]="activeThemeIcon.type"></rappider-icon>
              </div>
            </div>
            <div class="theme-card-body">
              <rappider-tag *ngIf="theme.id === activeProject?.activeProjectThemeId"
                            [text]="tagConfig.text"
                            [color]="tagConfig.color"></rappider-tag>
              <h4>
                {{theme.name}}
              </h4>
              <h6>
                {{
                theme.description?.length > 36
                ? (theme.description | slice:0:36) + '...'
                : theme.description
                }}
              </h6>
            </div>
            <div class="theme-card-footer">
              <rappider-dropdown-menu [items]="themeListDropdownMenuConfig.items"
                                      [icon]="themeListDropdownMenuConfig.icon"
                                      [label]="themeListDropdownMenuConfig.label"
                                      [dropdownType]="themeListDropdownMenuConfig.dropdownType"
                                      [triggerType]="themeListDropdownMenuConfig.triggerType"
                                      (menuItemClick)="onProjectThemeCardActionClick($event.key, theme.id)"></rappider-dropdown-menu>
              <rappider-button [text]="themeListButtonConfig.text"
                               [icon]="themeListButtonConfig.icon"
                               [iconPlacement]="themeListButtonConfig.iconPlacement"
                               (click)="navigateToProjectTheme(theme.id)">
              </rappider-button>
            </div>
          </section>
        </div>
      </div>
    </section>
  </rappider-spin>
</rappider-page-wrapper>
