import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
/* ProjectSourceCode data store imports */
import * as fromProjectSourceCodeState from './project-source-code.reducer';
import { ProjectSourceCodeEffects } from './project-source-code.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /* data store for ProjectSourceCode */
    StoreModule.forFeature(
      fromProjectSourceCodeState.featureKey,
      fromProjectSourceCodeState.reducer,
      { initialState: fromProjectSourceCodeState.initialState }
    ),
    EffectsModule.forFeature([ProjectSourceCodeEffects])
  ]
})
export class ProjectSourceCodeDataStoreModule { }
