<nz-select [(ngModel)]="selectedVariation"
           [nzShowSearch]="true"
           class="variation-select"
           (ngModelChange)="onVariationSelect($event)">
    <nz-option *ngFor="let option of options"
               [nzLabel]="option.key"
               [nzValue]="option.value"
               [nzCustomContent]="true">
        <div nz-popover
             nzPopoverPlacement="left"
             nzPopoverOverlayClassName="popover-overlay"
             [nzPopoverContent]="popoverContentTemplate"
             (nzPopoverVisibleChange)="onPopoverVisibilityChange($event,option.value)">
            {{ option.key }}
        </div>
    </nz-option>
</nz-select>

<ng-template #popoverContentTemplate>
    <ng-container #componentContainer></ng-container>
</ng-template>
