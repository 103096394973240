<div class="editor-wrapper">
  <rappider-diagram-editor-toolbar [(menuVisibility)]="menuVisibility"
                                   [(formPanelVisibility)]="formPanelVisibility"
                                   [activeUIDataStore]="activeUIDataStore"
                                   [activeItem]="activeItem"
                                   [showEventsWithProcess]="showEventsWithProcess"
                                   (diagramSettingsModeChange)="onDiagramSettingsModeChange($event)"
                                   (showAllWorkflowsClick)="showAllWorkflows()"
                                   (menuVisibilityChange)="repaintDiagramEditor()"
                                   (showEventsWithProcessClick)="onShowEventsWithProcess()"
                                   (changeModalVisibility)="changeModalVisibility()">
  </rappider-diagram-editor-toolbar>

  <nz-spin [nzSpinning]="!workflowMenu || isDiagramEditorLoading">
    <div class="content-area">
      <div *ngIf="menuVisibility">
        <rappider-diagram-workflow-menu [workflowMenu]="workflowMenu"
                                        [activeItem]="activeItem">
        </rappider-diagram-workflow-menu>
      </div>

      <div class="diagram-area"
           [class]="!activeItem && !formPanelVisibility ? 'diagram-area-without-tab' : ''"
           [ngClass]="!menuVisibility ? 'full-width-diagram-area' : ''">
        <rappider-diagram-editor #diagramEditorComponent
                                 [uiDataStores]="uiDataStores"
                                 [projectModels]="projectModels"
                                 [uiWorkflowStepFunctions]="uiWorkflowStepFunctions"
                                 [workflowStepFunctions]="workflowStepFunctions"
                                 [workflowEvents]="workflowEvents"
                                 [diagramTree]="displayedDiagramTree"
                                 [activeItem]="activeItem"
                                 [isDiagramEditorLoading]="isDiagramEditorLoading"
                                 (formPanelTabIndexChange)="openFormPanelCommentTab($event)"></rappider-diagram-editor>
      </div>

      <div *ngIf="menuVisibility && (activeItem || formPanelVisibility) && !readOnlyMode"
           class="settings-area">
        <rappider-diagram-settings [(formPanelVisibility)]="formPanelVisibility"
                                   [(formPanelTabIndex)]="formPanelTabIndex"
                                   [diagramSettingsMode]="diagramSettingsMode"
                                   [activeItem]="activeItem"
                                   [uiDataStoreId]="activeUIDataStore?.id"
                                   [uiStepFunctions]="uiWorkflowStepFunctions"
                                   [uiDataEvents]="uiDataEvents"
                                   [activeUIDataStore]="activeUIDataStore"
                                   [activePerson]="activePerson"
                                   [comments]="displayedComments"
                                   [diagramComments]="diagramComments"
                                   [projectPeopleData]="projectPeopleData"
                                   [commentsLoading]="isCommentsLoading"
                                   [activeCommentId]="activeCommentId"
                                   (addWorkflow)="onAddWorkflow()"
                                   (addUiStepFunctionToDataEvent)="onAddUiStepFunctionToDataEvent()"
                                   (addDataEventToUiStepFunction)="onAddDataEventToUiStepFunction()"></rappider-diagram-settings>
      </div>
    </div>
  </nz-spin>
</div>
<rappider-modal [(visible)]="addWorkflowTemplateModalVisibility"
                [title]="DiagramWrapperCards.modalConfig?.title"
                [okText]="DiagramWrapperCards.modalConfig?.okText"
                [fullScreen]="DiagramWrapperCards.modalConfig?.fullScreen"
                (okClick)="onSelectClick()">
  <div *ngIf="addWorkflowTemplateModalVisibility">
    <div *ngIf="isSelectedEvent"
         class="ui-data-store-select">
      <rappider-heading [className]="'asterisk'"
                        [type]="uiDataStoreTitle.type"
                        [content]="uiDataStoreTitle.content"></rappider-heading>
      <rappider-select [options]="DiagramWrapperCards.selectConfig?.options"
                       [settings]="DiagramWrapperCards.selectConfig?.settings"
                       [placeholder]="DiagramWrapperCards.selectConfig?.placeholder"
                       (valueChange)="onSelectedUIDataStoreChange($event)"></rappider-select>
    </div>
    <div class="workflow-template-list">
      <rappider-heading [type]="workflowTemplate.type"
                        [content]="workflowTemplate.content"></rappider-heading>
      <rappider-cards [items]="DiagramWrapperCards.cardsConfig?.items"
                      [itemCountPerRow]="DiagramWrapperCards.cardsConfig?.itemCountPerRow"
                      [showTitleOnImage]="DiagramWrapperCards.cardsConfig?.showTitleOnImage"
                      [showDescriptionOnImage]="DiagramWrapperCards.cardsConfig?.showDescriptionOnImage"
                      [selectConfig]="DiagramWrapperCards.cardsConfig?.selectConfig"
                      [paginationConfig]="DiagramWrapperCards.cardsConfig?.paginationConfig"
                      [showCheckMarkOnCard]="DiagramWrapperCards.cardsConfig?.showCheckMarkOnCard"
                      (cardClick)="onTemplateSelect($event)"></rappider-cards>
    </div>
  </div>
</rappider-modal>
