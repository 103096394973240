import { Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { MODEL_ENDPOINT_LIST_CONFIG, CustomEndpointListFieldName } from '@rappider/shared/configs';
import { ProjectModelEndpoint, ProjectModelEndpointWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import { ActionResponse, CrudTableViewConfig, } from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { AddQueryParam, Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { DeleteProjectModelEndpoint } from 'libs/project/src/lib/states/project-model-endpoint-state/project-model-endpoint.actions';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'rappider-model-endpoint-list',
  templateUrl: './model-endpoint-list.component.html',
  styleUrls: ['./model-endpoint-list.component.scss']
})

export class ModelEndpointListComponent implements OnInit, OnChanges {

  @Input() isLoading = false;
  @Input() activeProject: ProjectWithRelations;
  @Input() projectModelEndpoints: ProjectModelEndpointWithRelations;
  @Input() activeProjectModelId: string;
  @Input() endpointsGridConfig?: CrudTableViewConfig | undefined;
  @Input() animateTable? = false;
  @Input() enableRowPointer = false;

  @Output() rowActionClick = new EventEmitter<ActionResponse>();

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.setProjectModelEndpointListConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeProjectModelId) {
      this.setProjectModelEndpointListConfig();
    }
  }

  setProjectModelEndpointListConfig() {
    /* list config */
    if (!this.endpointsGridConfig) {
      this.endpointsGridConfig = cloneDeep(MODEL_ENDPOINT_LIST_CONFIG);
    }

    this.endpointsGridConfig.searchable = this.activeProjectModelId ? true : false;
    if (!this.activeProjectModelId) {
      this.endpointsGridConfig.listActions = this.endpointsGridConfig.listActions.filter(action => action.name !== 'createCustomEndpoint');
    }
    this.endpointsGridConfig = { ...this.endpointsGridConfig };
  }

  /**
   * filters deleted item from custom endpoints
   *
   * @param {string} deletedItemId
   * @memberof ModelEndpointListComponent
   */
  filterDeletedItem(deletedEndpoint: ProjectModelEndpoint) {
    const projectModel = this.projectModelEndpoints.find(projectModel => deletedEndpoint.modelId === projectModel.id);
    projectModel.children = projectModel.children.filter(child => child.id !== deletedEndpoint.id);
  }

  /**
  * navigate to the create custom endpoint page
  *
  * @memberof ModelEndpointListComponent
  */
  navigateToCreateEndpointPage(projectModelId: string) {
    this.store.dispatch(new AddQueryParam({ key: 'redirectURL', value: `projects/model-data-field-list/${projectModelId}?tab=endpoints` }));
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.CUSTOM_ENDPOINT_CREATE}/${projectModelId}` }));
  }

  onListActionClick(actionResponse: ActionResponse) {
    if (actionResponse.action.name === 'createCustomEndpoint') {
      this.navigateToCreateEndpointPage(this.activeProjectModelId);
    }
  }

  onColumnActionClick(actionResponse: ActionResponse) {
    const endpoint = <ProjectModelEndpoint>actionResponse.data;

    if (actionResponse.action.name === CustomEndpointListFieldName.DeleteCustomEndpoint) {
      this.store.dispatch(DeleteProjectModelEndpoint({ payload: { endpointId: endpoint.id, modelId: this.activeProjectModelId } }));
    } else if (actionResponse.action.name === CustomEndpointListFieldName.EditCustomEndpoint) {
      if (endpoint.isCustomEndpoint) {
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.CUSTOM_ENDPOINT_EDIT}/${endpoint.id}` }));
      } else {
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.ENDPOINT_DETAIL}/${endpoint.id}` }));
      }
    }
  }

  onRowActionClick(data: ActionResponse) {
    this.rowActionClick.emit(data);
  }
}
