import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { WorkflowEvent, WorkflowEventControllerService, WorkflowEventWithRelations } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import * as WorkflowEventActions from './workflow-event.actions';
import * as WorkflowEventFailureActions from '../workflow-step-function-published-event-on-failure-state/workflow-step-function-published-event-on-failure.actions';
import * as WorkflowEventSuccessActions from '../workflow-step-function-published-event-on-success-state/workflow-step-function-published-event-on-success.actions';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import * as WorkflowStepFunctionActions from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.actions';

@Injectable()
export class WorkflowEventEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private workflowEventApi: WorkflowEventControllerService,
  ) { }

  createWorkflowEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowEventActions.CreateWorkflowEvent
      ),
      withLatestFrom(
        this.store.select(state => state.workflowEvent.data)
      ),
      mergeMap(([action, workflowEvents]) => {
        const params = {
          body: action.payload.workflowEvent
        };
        const isWorkflowEventNameExist = workflowEvents.some(workflowEvent => (action.payload.workflowEvent.name) === workflowEvent.name);
        if (isWorkflowEventNameExist) {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            `${action.payload.workflowEvent.name} already exists.`
          );
          return [
            WorkflowEventActions.CreateWorkflowEventFailure({ payload: { error: 'Name Exist', key: 'CreateWorkflowEventFailure', timestamp: Date.now() } })
          ];
        } else {
          return this.workflowEventApi.create(params).pipe(
            mergeMap((workflowEvent: WorkflowEvent) => {
              const actions: Action[] = [
                WorkflowEventActions.CreateWorkflowEventSuccessful({ payload: { workflowEvent, createdPlaceholderId: action.payload.placeholderData.id } })
              ];

              if (action.payload.placeholderData) {
                if (action.payload.placeholderData.type === 'success') {
                  actions.push(WorkflowStepFunctionActions.AddPublishedEventOnSuccessToWorkflowStepFunction({ payload: { event: workflowEvent, workflowStepFunctionId: action.payload.placeholderData.stepFunctionId } }));
                  actions.push(WorkflowEventSuccessActions.CreateWorkflowStepFunctionPublishedEventOnSuccess({
                    payload: {
                      workflowStepFunctionPublishedEventOnSuccess: {
                        workflowEventId: workflowEvent.id,
                        workflowStepFunctionId: (action.payload.placeholderData.stepFunctionId as string)
                      }
                    }
                  }));
                } else if (action.payload.placeholderData.type === 'failure') {
                  actions.push(WorkflowStepFunctionActions.AddPublishedEventOnFailureToWorkflowStepFunction({ payload: { event: workflowEvent, workflowStepFunctionId: action.payload.placeholderData.stepFunctionId } }));
                  actions.push(WorkflowEventFailureActions.CreateWorkflowStepFunctionPublishedEventOnFailure({
                    payload: {
                      workflowStepFunctionPublishedEventOnFailure: {
                        workflowEventId: workflowEvent.id,
                        workflowStepFunctionId: (action.payload.placeholderData.stepFunctionId as string)
                      }
                    }
                  }));
                }
              }
              this.notificationService.createNotification(
                'success',
                'success',
                'SHARED.SUCCESSFULLY_CREATED'
              );
              return actions;
            }), catchError((error) => {
              this.notificationService.createNotification(
                'error',
                'SHARED.ERROR',
                'SHARED.COULDNT_CREATED'
              );
              return [
                WorkflowEventActions.CreateWorkflowEventFailure({ payload: { error, key: 'CreateWorkflowEventFailure', timestamp: Date.now() } })
              ];
            })
          );
        }
      })
    )
  );

  getWorkflowEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowEventActions.GetWorkflowEvent
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        const params = {
          filter: {
            where: {
              projectId: activeProjectId
            }
          }
        };
        return this.workflowEventApi.find(params).pipe(
          map((workflowEvents: WorkflowEventWithRelations[]) =>
            WorkflowEventActions.GetWorkflowEventSuccessful({ payload: { workflowEvents } })
          ),
          catchError((error) => [
            WorkflowEventActions.GetWorkflowEventFailure({ payload: { error, key: 'GetWorkflowEventFailure', timestamp: Date.now() } })
          ])
        );
      })
    )
  );

  updateWorkflowEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowEventActions.UpdateWorkflowEvent
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.workflowEventId,
          body: action.payload.workflowEventBody
        };

        return this.workflowEventApi.updateById(params).pipe(
          mergeMap(() => {
            this.notificationService.createNotification(
              'success',
              'success',
              'SHARED.SUCCESSFULLY_UPDATED'
            );
            return [
              WorkflowEventActions.UpdateWorkflowEventSuccessful({ payload: { workflowEventId: action.payload.workflowEventId, workflowEventBody: action.payload.workflowEventBody } }),
            ];
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              error?.error?.error?.message
            );
            return [
              WorkflowEventActions.UpdateWorkflowEventFailure({ payload: { error, key: 'UpdateWorkflowEventFailure', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  deleteWorkflowEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkflowEventActions.DeleteWorkflowEvent
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.workflowEventId
        };
        return this.workflowEventApi.deleteById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'SHARED.SUCCESSFULLY_DELETED'
            );
            return WorkflowEventActions.DeleteWorkflowEventSuccessful({ payload: { workflowEventId: action.payload.workflowEventId } });
          }, catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'SHARED.COULDNT_DELETED'
            );
            return [
              WorkflowEventActions.DeleteWorkflowEventFailure({ payload: { error, key: 'DeleteWorkflowEventFailure', timestamp: Date.now() } })
            ];
          }))
        );
      })
    )
  );
}
