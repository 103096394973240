import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderListWrapperComponent } from './list-wrapper.component';
import { RappiderListGridModule } from '../list-grid/list-grid.module';
import { RappiderTitleToolbarModule } from '../title-toolbar/title-toolbar.module';
import { RappiderInputGroupModule } from '../input-group/input-group.module';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { RappiderCardCcsModule } from '../card-ccs/card-ccs.module';
import { FormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';

@NgModule({
  declarations: [
    RappiderListWrapperComponent
  ],
  imports: [
    CommonModule,
    RappiderListGridModule,
    RappiderTitleToolbarModule,
    RappiderInputGroupModule,
    NzEmptyModule,
    RappiderCardCcsModule,
    FormsModule,
    NzGridModule
  ],
  exports: [
    RappiderListWrapperComponent
  ]
})
export class RappiderListWrapperModule { }
