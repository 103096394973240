import { orderBy } from 'lodash';
import * as PageActions from './page.actions';
import { LastProcessedAction, Pagination } from '@rappider/shared/interfaces';
import { DEFAULT_PAGINATION } from '@rappider/shared/definitions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { PageWithRelations, Page } from '@rappider/rappider-sdk';
import { id } from 'date-fns/locale';

export const pageFeatureKey = 'page';

export interface State {
  loading: boolean;
  isLoaded: boolean;
  submitButtonLoading: boolean;
  isTemplatePagesLoading: boolean;
  data: PageWithRelations[];
  pagination: Pagination;
  lastProcessedAction: LastProcessedAction;
  templatePages: PageWithRelations[];
  templatePagesTags: string[];
  templatePagesCount: number;
  isTemplatePagesLoaded: boolean;
  isTemplatePagesTagsLoaded: boolean;
  error: {
    timestamp?: number;
    key?: string;
    error?: any;
  };
}

export const initialState: State = {
  loading: true,
  isLoaded: false,
  submitButtonLoading: false,
  isTemplatePagesLoading: true,
  data: null,
  pagination: {
    currentPageNumber: DEFAULT_PAGINATION.ACTIVE_PAGE_NUMBER,
    pageSize: 999,
    totalCount: null,
    totalPageNumber: null
  },
  lastProcessedAction: {
    success: null,
    action: null,
    message: null,
    timestamp: 0,
    data: null
  },
  templatePages: null,
  templatePagesTags: [],
  templatePagesCount: null,
  isTemplatePagesLoaded: false,
  isTemplatePagesTagsLoaded: false,
  error: null,
};

export function reducer(
  state: State = initialState,
  action: PageActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {
    case PageActions.ActionTypes.EnablePageLoading:
      return {
        ...state,
        loading: true
      };

    case PageActions.ActionTypes.DisablePageLoading:
      return {
        ...state,
        loading: false
      };

    case PageActions.ActionTypes.GetPages:
      return {
        ...state,
        isLoaded: false
      };

    case PageActions.ActionTypes.SetPages:
      return {
        ...state,
        data: orderBy(action.payload.pages, 'title', 'asc'),
        isLoaded: true,
        loading: false
      };

    case PageActions.ActionTypes.GetPageById:
      return {
        ...state,
        loading: true
      };

    case PageActions.ActionTypes.GetPageByIdSuccessful:
      return {
        ...state,
        data: orderBy([
          ...state.data.filter(page => page.id === action.payload?.page?.id),
          action.payload.page
        ], 'title', 'asc'),
        loading: false,
        isLoaded: true
      };

    case PageActions.ActionTypes.GetTemplatePages:
      return {
        ...state,
        isTemplatePagesLoading: true
      };

    case PageActions.ActionTypes.GetTemplatePagesSuccessful:
      return {
        ...state,
        templatePages: action.payload.templatePages || [],
        templatePagesTags: [...new Set(action.payload?.templatePages?.map(page => page.tags).flat().filter(tag => tag !== undefined) || [])],
        isTemplatePagesLoading: false,
        isTemplatePagesLoaded: true,
        isTemplatePagesTagsLoaded: true
      };

    case PageActions.ActionTypes.GetTemplatePagesPagination:
      return {
        ...state,
        isTemplatePagesLoading: true
      };

    case PageActions.ActionTypes.GetTemplatePagesPaginationSuccessful:
      return {
        ...state,
        templatePagesCount: action.payload.count,
        templatePagesTags: action.payload.templatePagesTags,
        isTemplatePagesTagsLoaded: true
      };

    case PageActions.ActionTypes.CreatePage:
      return {
        ...state,
        submitButtonLoading: true
      };

    case PageActions.ActionTypes.UpdatePage:
      return {
        ...state,
        submitButtonLoading: true
      };

    case PageActions.ActionTypes.UpdatePageSuccessful: {
      const updatedPage = {
        ...state.data.find(page => page.id === action.payload.pageId),
        ...action.payload.page
      };

      return {
        ...state,
        data: orderBy([
          ...state.data.filter(page => page.id !== action.payload.pageId),
          updatedPage
        ], 'title', 'asc'),
        submitButtonLoading: false
      };
    }

    case PageActions.ActionTypes.BulkUpdatePages: {
      return {
        ...state,
        submitButtonLoading: true
      };
    }

    case PageActions.ActionTypes.BulkUpdatePagesSuccessful: {
      const updatedPages = action.payload.body;
      const updatedData = state.data.map(page => {
        const updatedPage = updatedPages.find(updatedPage => updatedPage.id === page.id);
        if (updatedPage) {
          return {
            ...page,
            ...updatedPage.data
          };
        }
        return page;
      });

      return {
        ...state,
        data: [...updatedData],
        submitButtonLoading: false,
      };
    }

    case PageActions.ActionTypes.DeletePageSuccessful: {
      return {
        ...state,
        data: state.data.filter(page => page.id !== action.payload.pageId)
      };
    }

    case PageActions.ActionTypes.BulkDeletePagesSuccessful: {
      return {
        ...state,
        data: state.data.filter(page => !action.payload.pageIds.includes(page.id))
      };
    }

    case PageActions.ActionTypes.SetPagination:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination
        },
        submitButtonLoading: false
      };

    case PageActions.ActionTypes.ChangeLastProcessedAction:
      return {
        ...state,
        lastProcessedAction: action.payload.lastProcessedAction
      };

    case PageActions.ActionTypes.UpdatePageWithLifecycleActions: {
      return {
        ...state,
        loading: true
      };
    }
    case PageActions.ActionTypes.UpdatePageWithLifecycleActionsSuccessful: {
      return {
        ...state,
        data: orderBy([
          ...state.data.filter(page => page.id !== action.payload.pageId),
          action.payload.page
        ], 'title', 'asc'),
        loading: false
      };
    }

    case PageActions.ActionTypes.PageError:
      return {
        ...state,
        error: {
          ...state.error,
          key: action.payload.errorOn,
          error: action.payload.error
        },
        submitButtonLoading: false,
        loading: false,
        isTemplatePagesLoading: false
      };

    case PageActions.ActionTypes.GetPagesByModuleIdSuccessful: {
      return {
        ...state,
        data: orderBy([
          ...state.data,
          ...action.payload.pages
        ], 'title', 'asc')
      };
    }

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful: {
      const templatePages = state.templatePages;
      return {
        ...initialState,
        templatePages: templatePages,
        isTemplatePagesLoading: state.isTemplatePagesLoading,
        templatePagesCount: state.templatePagesCount,
        templatePagesTags: state.templatePagesTags
      };
    }

    default:
      return state;
  }
}
