/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

export enum ProjectDesignSettingButtons {
  WizardNextButton = 'wizardNextButton',
  EditProjectDescription = 'editProjectDescription',
  EditProjectTitle = 'editProjectTitle',
  ImproveProjectWithAI = 'improveProjectWithAI',
  AddAuthorization = 'addAuthorization',
  ChangeToMobileAppProject = 'changeToMobileAppProject',
  ChangeProgrammingLanguage = 'changeProgrammingLanguage',
  InviteOthers = 'inviteOthers',
  NextUIPageButton = 'nextUIPageButton',
  DisplayDataModelDiagram = 'displayDataModelDiagram',
  DataModelList = 'dataModelList',
  DataSchemaList = 'dataSchemaList',
  CreateDataModelManually = 'createDataModelManually',
  CreateDataModelFromJsonDataSample = 'createDataModelFromJsonDataSample',
  CreateDataModelWithAI = 'createDataModelWithAI',
  EditScopeOfWork = 'editScopeOfWork',
  AddScopeOfWork = 'addScopeOfWork',
  AddEndpointWithAI = 'addEndpointWithAI',
  DisplayApiEndpoints = 'displayApiEndpoints',
  AddUiPageManually = 'addUiPageManually',
  AddUiPageWithAI = 'addUiPageWithAI',
  UiPageList = 'uiPageList',
  UiModules = 'uiModules',
  UiPageLayoutList = 'uiPageLayoutList',
  AddComponentWithAI = 'addComponentWithAI',
  ChangeDesignSystemPackage = 'changeDesignSystemPackage',
  ThemeList = 'themeList',
  AddThemeWithAI = 'addThemeWithAI',
  AddThemeManually = 'addThemeManually',
  CustomStyles = 'customStyles',
  AddPageLayoutWithAI = 'addPageLayoutWithAI',
  UiProcessDiagram = 'uiProcessDiagram',
  UiDataStores = 'uiDataStores',
  UiDataEvents = 'uiDataEvents',
  UiDataEventUpdateFunctions = 'uiDataEventUpdateFunctions',
  UiDataStepFunctions = 'uiDataStepFunctions',
  MessageTemplateList = 'messageTemplateList',
  AddMessageTemplateWithAI = 'addMessageTemplateWithAI',
  AddMessageTemplateManually = 'addMessageTemplateManually',
  ProjectPackages = 'projectPackages',
  EmbeddedExternalScripts = 'embeddedExternalScripts',
  ServerSideBusinessProcessDiagram = 'serverSideBusinessProcessDiagram',
  AddWorkflowWithAI = 'addWorkflowWithAI',
  WorkflowCustomFunctionDefinitions = 'workflowCustomFunctionDefinitions',
  DisplayProjectSettings = 'displayProjectSettings',
  DisplayEnvironmentVariables = 'displayEnvironmentVariables',
  ProjectMembers = 'projectMembers',
  ProjectRoles = 'projectRoles',
}

import { ButtonComponentConfig } from '@rappider/rappider-components/utils';

export const PROJECT_TITLE_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.EditProjectTitle,
    icon: {
      name: 'fa-light fa-pen'
    },
    tooltipText: 'Edit Project Title'
  }];

export const PROJECT_DESCRIPTION_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.EditProjectDescription,
    icon: {
      name: 'fa-regular fa-pen-to-square'
    },
    tooltipText: 'Edit Project Description'
  },
  {
    key: ProjectDesignSettingButtons.ImproveProjectWithAI,
    icon: {
      name: 'fa-solid fa-sparkles'
    },
    tooltipText: 'Improve Project Description with AI',
    customCssClass: 'ai-button'
  },
];

export const PROJECT_INFO_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.AddAuthorization,
    icon: {
      name: 'fa-solid fa-shield-check'
    },
    tooltipText: 'Add authentication to the project',
    customCssClass: ''
  },
  {
    key: ProjectDesignSettingButtons.ChangeToMobileAppProject,
    icon: {
      name: 'fa-solid fa-mobile-alt'
    },
    tooltipText: 'Change to Mobile Application Project',
    customCssClass: ''
  },
  {
    key: ProjectDesignSettingButtons.ChangeProgrammingLanguage,
    icon: {
      name: 'fa-solid fa-code'
    },
    tooltipText: 'Change Programming Languages and Frameworks',
    customCssClass: ''
  },
  {
    key: ProjectDesignSettingButtons.ProjectPackages,
    icon: {
      name: 'fa-regular fa-gear-complex-code'
    },
    tooltipText: 'Project Code Libraries & Packages',
  },
  {
    key: ProjectDesignSettingButtons.EmbeddedExternalScripts,
    icon: {
      name: 'fa-solid fa-file-code'
    },
    tooltipText: 'Embedded External Scripts',
  },
];

export const PROJECT_MEMBERS_ACTION_BUTTONS: ButtonComponentConfig[] = [
  // {
  //   key: ProjectDesignSettingButtons.InviteOthers,
  //   icon: {
  //     name: 'fa-solid fa-user-plus'
  //   },
  //   tooltipText: 'Invite others to help with this project',
  //   customCssClass: ''
  // },
  {
    key: ProjectDesignSettingButtons.ProjectMembers,
    icon: {
      name: 'fa-regular fa-users'
    },
    tooltipText: 'Project Members',
  },
  {
    key: ProjectDesignSettingButtons.ProjectRoles,
    icon: {
      name: 'fa-regular fa-shield-check'
    },
    tooltipText: 'Project Roles',
  },
];

// SCOPE_OF_WORK
export const SCOPE_OF_WORK_CONFIG_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.EditScopeOfWork,
    icon: {
      name: 'fa-light fa-pen'
    },
    tooltipText: 'Edit Scope of Work | This feature is not available for free plans',
    disabled: true,
  },
  {
    key: ProjectDesignSettingButtons.AddScopeOfWork,
    icon: {
      name: 'fa-light fa-plus'
    },
    tooltipText: 'Add New Scope | This feature is not available for free plans',
    disabled: true,
  },
];


// DATA-MODELS-AND-DATABASE
export const DATA_MODELS_AND_DATABASE_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.DisplayDataModelDiagram,
    icon: {
      name: 'fa-solid fa-database'
    },
    tooltipText: 'Database Diagram'
  },
  {
    key: ProjectDesignSettingButtons.CreateDataModelWithAI,
    icon: {
      name: 'fa-solid fa-sparkles'
    },
    tooltipText: 'Create Data Model With AI',
    customCssClass: 'ai-button',
    disabled: true,
  },
  {
    key: ProjectDesignSettingButtons.CreateDataModelManually,
    icon: {
      name: 'fa-kit fa-light-database-circle-plus'
    },
    tooltipText: 'Create Data Model Manually'
  },
  {
    key: ProjectDesignSettingButtons.CreateDataModelFromJsonDataSample,
    icon: {
      name: 'fa-kit fa-regular-brackets-curly-circle-plus'
    },
    tooltipText: 'Create Data Model From JSON Data Sample',
    disabled: true,
  },
  {
    key: ProjectDesignSettingButtons.DataModelList,
    icon: {
      name: 'fa-regular fa-server'
    },
    tooltipText: 'Data Model List'
  },
  {
    key: ProjectDesignSettingButtons.DataSchemaList,
    icon: {
      name: 'fa-sharp fa-server'
    },
    tooltipText: 'Data Schema List'
  },
];

// API-ENDPOINTS
export const API_ENDPOINTS_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.DisplayApiEndpoints,
    icon: {
      name: 'fa-regular fa-arrow-up-right-from-square'
    },
    tooltipText: 'Display API Endpoints on Main Panel',
  },
  {
    key: ProjectDesignSettingButtons.AddEndpointWithAI,
    icon: {
      name: 'fa-solid fa-sparkles'
    },
    tooltipText: 'Add API Endpoints with AI',
    customCssClass: 'ai-button',
    disabled: true,
  }
];

// UI-PAGES
export const UI_PAGES_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.AddUiPageManually,
    icon: {
      name: 'fa-light fa-window'
    },
    tooltipText: 'Add UI Page Manually'
  },
  {
    key: ProjectDesignSettingButtons.AddUiPageWithAI,
    icon: {
      name: 'fa-solid fa-sparkles'
    },
    tooltipText: 'Add API Endpoints with AI',
    customCssClass: 'ai-button',
    disabled: true,
  },
  {
    key: ProjectDesignSettingButtons.UiPageList,
    icon: {
      name: 'fa-regular fa-table'
    },
    tooltipText: 'UI Page List'
  },
  {
    key: ProjectDesignSettingButtons.UiModules,
    icon: {
      name: 'fa-regular fa-box'
    },
    tooltipText: 'UI Modules'
  },
];

// UI-PAGE-LAYOUTS
export const UI_PAGE_LAYOUTS_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.UiPageLayoutList,
    icon: {
      name: 'fa-regular fa-table'
    },
    tooltipText: 'UI Page Layout List'
  },
  {
    key: ProjectDesignSettingButtons.AddPageLayoutWithAI,
    icon: {
      name: 'fa-solid fa-sparkles'
    },
    tooltipText: 'Add UI Page Layout with AI',
    customCssClass: 'ai-button',
    disabled: true,
  }
];

// DESIGN-SYSTEM
export const DESIGN_SYSTEM_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.ChangeDesignSystemPackage,
    icon: {
      name: 'fa-regular fa-arrows-rotate'
    },
    tooltipText: 'ChangeDesignSystemPackage | This feature is not available for free plans',
    disabled: true,
  },
  {
    key: ProjectDesignSettingButtons.AddComponentWithAI,
    icon: {
      name: 'fa-solid fa-sparkles'
    },
    tooltipText: 'Add Component with AI | This feature is not available for free plans',
    customCssClass: 'ai-button',
    disabled: true,
  },

];

// THEME
export const THEME_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.ThemeList,
    icon: {
      name: 'fa-regular fa-palette'
    },
    tooltipText: 'Theme List',
  },
  {
    key: ProjectDesignSettingButtons.AddThemeWithAI,
    icon: {
      name: 'fa-solid fa-sparkles'
    },
    tooltipText: 'Add Theme with AI',
    customCssClass: 'ai-button',
    disabled: true,
  },
  {
    key: ProjectDesignSettingButtons.AddThemeManually,
    icon: {
      name: 'fa-kit fa-regular-palette-circle-plus'
    },
    tooltipText: 'Add Theme Manually',
  },
  {
    key: ProjectDesignSettingButtons.CustomStyles,
    icon: {
      name: 'fa-regular fa-brush'
    },
    tooltipText: 'Edit CSS Custom Stylesheet',
  },
];

export const UI_BUSINESS_PROCESSES_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.UiProcessDiagram,
    icon: {
      name: 'fa-regular fa-diagram-project'
    },
    tooltipText: 'UI Process Diagram',
  },
  {
    key: ProjectDesignSettingButtons.UiDataStores,
    icon: {
      name: 'fa-regular fa-database'
    },
    tooltipText: 'UI Data Stores',
  },
  {
    key: ProjectDesignSettingButtons.UiDataEvents,
    icon: {
      name: 'fa-regular fa-diagram-nested'
    },
    tooltipText: 'UI Data Events',
  },
  {
    key: ProjectDesignSettingButtons.UiDataEventUpdateFunctions,
    icon: {
      name: 'fa-regular fa-file-binary'
    },
    tooltipText: 'UI Data Event Update Functions',
  },
  {
    key: ProjectDesignSettingButtons.UiDataStepFunctions,
    icon: {
      name: 'fa-regular fa-square-ring'
    },
    tooltipText: 'UI Data Step Functions',
  }
];

// MESSAGE TEMPLATES
export const MESSAGE_TEMPLATES_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.MessageTemplateList,
    icon: {
      name: 'fa-regular fa-scroll'
    },
    tooltipText: 'Message & Document Template List',
  },
  {
    key: ProjectDesignSettingButtons.AddMessageTemplateWithAI,
    icon: {
      name: 'fa-solid fa-sparkles'
    },
    tooltipText: 'Add Message & Document with AI',
    customCssClass: 'ai-button',
    disabled: true,
  },
  {
    key: ProjectDesignSettingButtons.AddMessageTemplateManually,
    icon: {
      name: 'fa-kit fa-regular-scroll-circle-plus'
    },
    tooltipText: 'Add Message & Document Template Manually',
  },
];

// SERVER-SIDE-BUSINESS-PROCESSES
export const SERVER_SIDE_BUSINESS_PROCESSES_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.ServerSideBusinessProcessDiagram,
    icon: {
      name: 'fa-regular fa-diagram-project'
    },
    tooltipText: 'Backend Workflow Process Diagram',
  },
  {
    key: ProjectDesignSettingButtons.AddWorkflowWithAI,
    icon: {
      name: 'fa-solid fa-sparkles'
    },
    tooltipText: 'Add Workflow with AI',
    customCssClass: 'ai-button',
    disabled: true,
  },
  {
    key: ProjectDesignSettingButtons.WorkflowCustomFunctionDefinitions,
    icon: {
      name: 'fa-regular fa-server'
    },
    tooltipText: 'Workflow Step Function Code List',
  },
];

// SETTINGS_VARIABLES_AND_ENVIRONMENTS
export const SETTINGS_VARIABLES_AND_ENVIRONMENTS_ACTION_BUTTONS: ButtonComponentConfig[] = [
  {
    key: ProjectDesignSettingButtons.DisplayProjectSettings,
    icon: {
      name: 'fa-solid fa-sliders-up'
    },
    tooltipText: 'Display Project Settings on Main Panel',
  },
  {
    key: ProjectDesignSettingButtons.DisplayEnvironmentVariables,
    icon: {
      name: 'fa-regular fa-arrow-up-right-from-square'
    },
    tooltipText: 'Display Environment Variables on Main Panel',
  },
];

// GENERATE-PROJECT-CODE
export const GENERATE_PROJECT_CODE_ACTION_BUTTONS: ButtonComponentConfig[] = [
];

// DEPLOY
export const DEPLOY_ACTION_BUTTONS: ButtonComponentConfig[] = [
];

