import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectInterface } from '@rappider/api-sdk';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { FullPaths, PAGE_DEFINITIONS, PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PROJECT_HOME_FEATURES_CONFIG } from '@rappider/shared/configs';
import { Person, Tenant } from '@rappider/rappider-sdk';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { ButtonComponentConfig } from '@rappider/rappider-components/utils';
import { Location } from '@angular/common';
import { Select } from '@antv/g2';
import { SelectActiveProject } from '../../states/active-project-state/active-project.actions';
import { th } from 'date-fns/locale';

@Component({
  selector: 'rappider-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
  projectId: string;

  subscriptions: Subscription[] = [];
  features = PROJECT_HOME_FEATURES_CONFIG;

  constructor(
    private route: ActivatedRoute,
    private store: Store<any>,
  ) { }

  ngOnInit() {
    /* get project id by url and set into projectId variable */
    const activeProjectId = this.route.snapshot.paramMap.get('projectId');
    const projectIdOnLocalStorage = localStorage.getItem('activeProjectId');

    if (!activeProjectId) {
      // There is no projectId in the url; read from local storage
      if (projectIdOnLocalStorage) {
        // There is no projectId in the url BUT There is projectId in the local storage;
        // set as active project, it will navigate to project detail itself
        // this.navigateToProjectDesignPage(projectIdOnLocalStorage);
      } else {
        // There is no projectId in the url and local storage; navigate to project list
        this.navigateToProjectList();
      }
    } else {
      // There is projectId in the url
      if (projectIdOnLocalStorage === activeProjectId) {
        // projectId in the url and local storage are the same; set as active project, it will navigate to project detail itself
        this.projectId = activeProjectId;
        // NAVIGATE TO PROJECT DESIGN PAGE
        // this.navigateToProjectDesignPage(activeProjectId);
      } else {
        // projectId in the url and local storage are different; set projectId in the url as active project
        this.store.dispatch(new SelectActiveProject({ projectId: activeProjectId }));
        this.projectId = activeProjectId;
        // this.navigateToProjectDesignPage(activeProjectId);
      }
    }

    /* if there is projectId id, get project details by projectId */
    if (this.projectId) {
      this.subscribeToData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
    ];
  }

  navigateToProjectList() {
    this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH }));
  }

  navigateToSourceCodePage(projectId: string) {
    this.store.dispatch(new Navigate({ url: FullPaths.SourceCode.replace(':projectId', projectId) }));
  }

  // navigateToProjectDesignPage(projectId: string) {
  //   this.store.dispatch(new Navigate({ url: FullPaths.ProjectDesign.replace(':projectId', projectId) }));
  // }

}
