/* eslint-disable max-len */
export const US_PHONE_MASK = '(000) 000-0000';

// Matches US phone numbers, with optional area code and optional separators (-, ., or space)
export const US_PHONE_REGEX = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;

// Matches standard email addresses
export const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/;

// Matches a password with at least one uppercase letter, one number, and one special character, and at least 8 characters long
export const PASSWORD_REGEX = /^(?=\S*[A-Z])(?=\S*\d)(?=\S*([^\w\s]|[_]))\S{8,}$/;

// Matches strings containing at least one special character
export const SPECIAL_CHARACTER_REGEX = /(?=\S*([^\w\s]|[_]))/;

// Matches either a valid email address or a US phone number
export const EMAIL_AND_US_PHONE_REGEX = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$|^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/;

// Matches letters and spaces, with specific characters (e.g., ÅÄÖåäö) allowed
export const MENTION_ALLOWED_CHARS = /^[A-Za-z\sÅÄÖåäö]*$/;

// Mask for version numbers (e.g., 1.0.0)
export const VERSION_MASK = [/[0-9]/, '.', /[0-9]/, '.', /[0-9]/];

// Matches a valid URL path, allowing letters (uppercase and lowercase), numbers, dashes, underscores, slashes, colons, and common special characters
export const PAGE_PATH_REGEX = /^[a-zA-Z0-9\-._~!$&'()*+,;=/:]*$/;

// Matches valid project settings and environment variable keys, starting with a letter or underscore
export const PROJECT_SETTING_AND_ENVIRONMENT_VARIABLE_KEY_REGEX = /^[a-zA-Z\_][a-zA-Z0-9\_]+$/;

// Matches valid project keys, starting with a lowercase letter, allowing letters, numbers, and dashes
export const PROJECT_KEY_REGEX = /^[a-z][a-z0-9\-]*$/;

// Matches valid project names, starting with a letter, allowing letters, numbers, and special characters
export const PROJECT_NAME_REGEX = /^[a-zA-Z][a-zA-Z0-9 _-]*$/;

// Cleanses phone numbers by removing dashes, parentheses, and spaces
export const PHONE_NUMBER_CLEANSE_REGEX = /[\-\(\)\s]/g;

// Matches data field names starting with a lowercase letter, followed by alphanumeric characters or underscores
export const DATA_FIELD_NAME_REGEX = /^[a-z][0-9a-zA-Z_]*$/;

// Matches data field names in title case, starting with an uppercase letter, followed by alphanumeric characters or underscores
export const TITLE_CASE_DATA_FIELD_NAME_REGEX = /^[A-Z][0-9a-zA-Z_]*$/;

// Matches either camel case or snake case (starting with lowercase or underscore, followed by alphanumeric characters or underscores)
// Used in project settings config for key validation
export const CAMEL_CASE_OR_SNAKE_CASE_REGEX = /^[a-z_][0-9a-zA-Z_]*$/;

// Matches valid kebab case strings (lowercase letters, numbers, and dashes)
export const KEBAB_CASE_REGEX = /^[a-z][a-z0-9-]*$/;

// Matches valid camel case strings (starting with a lowercase letter, followed by alphanumeric characters)
export const CAMEL_CASE_REGEX = /^[a-z][a-zA-Z0-9]*$/;

// Matches Pascal case strings, starting with an uppercase letter and containing only letters
export const PASCAL_CASE_ONLY_LETTER_REGEX = /^[A-Z][a-zA-Z]*$/;

// Matches Pascal case strings, starting with an uppercase letter, followed by alphanumeric characters
export const PASCAL_CASE_REGEX = /^[A-Z][a-zA-Z0-9]*$/;

// Matches title case strings, starting with an uppercase letter, followed by alphanumeric characters, underscores, or spaces
export const TITLE_CASE_REGEX = /^[A-Z][0-9a-zA-Z_\s]*$/;

// Ensures no spaces in the string
export const NO_SPACE_REGEX = /^\S*$/;

// Matches valid role and permission keys, starting with a lowercase letter, allowing lowercase letters, numbers, colons, and dashes
export const ROLE_AND_PERMISSION_KEY_REGEX = /^[a-z][a-z:\-0-9]*$/;

// Matches standard email addresses (improved version with case insensitivity)
export const ENHANCED_EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// Matches valid IPv4 addresses (e.g., 192.168.1.1)
export const IPV4_REGEX = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

// Matches hexadecimal color codes, allowing 3 or 6 hex digits
export const HEX_COLOR_REGEX = /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;

// Matches dates in the format YYYY-MM-DD
export const SHORT_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;

// Matches US postal codes (ZIP codes) with optional 4-digit extension
export const US_ZIP_CODE_REGEX = /^\d{5}(-\d{4})?$/;
