import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store, createSelector } from '@ngrx/store';
import { DataTransformService, NotificationService } from '@rappider/services';
import { Subscription } from 'rxjs';
import * as PageVariablesActions from 'libs/content-editor/src/lib/state/page-variables-state/page-variables.actions';
import { PageVariable, UiDataSelectorWithRelations } from '@rappider/rappider-sdk';
import { getUIDataSelectors } from 'libs/shared/src/lib/state-selectors/get-ui-data-selectors.selector';
import { camelCase, cloneDeep } from 'lodash';
import { PAGE_VARIABLES_GRID_CONFIG } from './config/page-variables.config';
import { CRUDType } from '@rappider/shared/definitions';
import { ActionResponse, ButtonComponentConfig, CrudFormSelectItem } from '@rappider/rappider-components/utils';
import { PAGE_VARIABLE_CREATE_OR_EDIT_CONFIG, PAGE_VARIABLE_CREATE_WITH_AI_CONFIG } from './config/page-variables-create-and-edit-form.config';

@Component({
  selector: 'rappider-page-variables',
  templateUrl: './page-variables.component.html',
  styleUrls: ['./page-variables.component.scss']
})
export class PageVariablesComponent implements OnInit, OnDestroy {

  @Output() pageVariablesSubmit = new EventEmitter();
  @Output() cancel = new EventEmitter<void>();

  pageVariablesConfig = PAGE_VARIABLES_GRID_CONFIG;
  pageVariableCreateAndEditFormConfig = cloneDeep(PAGE_VARIABLE_CREATE_OR_EDIT_CONFIG);
  pageVariableCreateWithAiConfig = cloneDeep(PAGE_VARIABLE_CREATE_WITH_AI_CONFIG);

  pageVariables: PageVariable[] = [];
  uiDataSelectors: UiDataSelectorWithRelations[] = [];
  form: FormGroup;
  emptyRow = { variableName: '', uiDataSelectorId: '' };

  optMode = CRUDType.Read;
  crudType = CRUDType;
  gridData: any;
  createModalVisible = false;
  createWithAiModalVisible = false;
  editModalVisible = false;
  createPageVariableData: any;
  createWithAiPageVariableData: any;
  editedPageVariableData: any;
  editedUiDataSelector: UiDataSelectorWithRelations;
  loading = false;
  newCreatedSelector: UiDataSelectorWithRelations;

  editDataSelectorModalVisibility = false;
  createDataSelectorModalVisibility = false;

  subscriptions: Subscription[];

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private dataTransformService: DataTransformService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.subscriptions = [
      this.subscribeToPageVariables(),
      this.subscribeToUIDataSelectors(),
      this.subscribeToLoading()
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  subscribeToPageVariables() {
    return this.store.select(
      createSelector(
        state => <any[]>state.pageVariables.data,
        state => state.contentEditor?.page?.id,
        (pageVariables: any[], pageId) => pageVariables?.filter(pageVariable => pageVariable.pageId === pageId)
      )
    ).subscribe(pageVariables => {
      if (pageVariables?.length) {
        this.pageVariables = pageVariables;
        this.setGridData();
      }
    });
  }

  subscribeToLoading() {
    return this.store.select(state => state.pageVariables.isLoading)
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });
  }

  subscribeToUIDataSelectors() {
    return this.store.select(<any>getUIDataSelectors)
      .subscribe((uiDataSelectors: UiDataSelectorWithRelations[]) => {
        if (uiDataSelectors) {
          const newData = this.findNewData(this.uiDataSelectors, uiDataSelectors);

          if (this.uiDataSelectors.length && newData) {
            this.newCreatedSelector = newData;
          }

          this.uiDataSelectors = cloneDeep(uiDataSelectors);
          this.setGridData();
        }
      });
  }

  setLatestSelector() {
    if (this.createModalVisible) {
      this.createPageVariableData = {
        ...this.createPageVariableData,
        uiDataSelectorId: this.newCreatedSelector.id
      };
    } else {
      this.editedPageVariableData = {
        ...this.editedPageVariableData,
        uiDataSelectorId: this.newCreatedSelector.id
      };
    }
  }

  findNewData(currentData: UiDataSelectorWithRelations[], newData: UiDataSelectorWithRelations[]): UiDataSelectorWithRelations | null {
    const currentIds = new Set(currentData.map(data => data.id));
    const newEntries = newData?.filter(data => !currentIds.has(data.id));

    if (newEntries.length === 0) {
      return null;
    }

    // createdDate'e göre en son eklenen veriyi bul
    return newEntries.reduce((latest, entry) =>
      new Date(latest.createdDate) > new Date(entry.createdDate) ? latest : entry
    );
  }

  setGridData() {
    if (this.pageVariables?.length && this.uiDataSelectors?.length) {
      this.gridData = this.pageVariables.map(pageVariable => {
        const uiDataSelector = this.uiDataSelectors?.find(selector => selector.id === pageVariable.uiDataSelectorId);
        return {
          ...pageVariable,
          uiDataSelectorName: uiDataSelector ? uiDataSelector.name : null
        };
      });
    }
    if (this.uiDataSelectors?.length) {
      const selectItem = this.pageVariableCreateAndEditFormConfig.items.find(
        item => item.fieldName === 'uiDataSelectorId') as CrudFormSelectItem;
      selectItem.options = this.uiDataSelectors?.map(uiDataSelector => ({
        key: uiDataSelector.name,
        value: uiDataSelector.id
      })) || [];

      this.pageVariableCreateAndEditFormConfig = {
        ...this.pageVariableCreateAndEditFormConfig
      };

      if (this.newCreatedSelector) {
        this.setLatestSelector();
      }
    }
  }

  onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      const finalPageVariables = this.form.value.pageVariables;

      const diff = this.dataTransformService.compareArrays(this.pageVariables, finalPageVariables, 'id', true);

      diff.createdArray?.forEach((pageVariable: PageVariable) => {
        this.store.dispatch(PageVariablesActions.CreatePageVariable({ payload: { pageVariable } }));
      });
      diff.updatedArray?.forEach((changes) => {
        this.store.dispatch(PageVariablesActions.UpdatePageVariables({ payload: { pageVariable: changes.item, pageVariableId: changes.item.id } }));
      });
      diff.deletedArray?.forEach((pageVariable: PageVariable) => {
        this.store.dispatch(PageVariablesActions.DeletePageVariable({ payload: { pageVariableId: pageVariable.id } }));
      });
      this.pageVariablesSubmit.emit(this.form.value);
    }
  }

  onColumnActionClick(event: ActionResponse) {
    if (event.action.name === this.crudType.Update) {
      this.editedPageVariableData = this.pageVariables.find(pageVariable => pageVariable?.id === event.data?.id);
      this.optMode = this.crudType.Update;
      this.editModalVisible = true;

      // Update buttons for edit modal
      this.updateUiDataSelectorButton(this.editedPageVariableData.uiDataSelectorId);
    } else if (event.action.name === this.crudType.Delete) {
      const deletedPageVariable = this.pageVariables.find(pageVariable => pageVariable?.id === event.data?.id);
      this.store.dispatch(PageVariablesActions.DeletePageVariable({ payload: { pageVariableId: deletedPageVariable.id } }));
    }
  }

  onListActionClick(event: ActionResponse) {
    if (event.action.name === this.crudType.Create) {
      this.optMode = this.crudType.Create;
      this.createModalVisible = true;
    } else if (event.action.name === 'create-with-ai') {
      this.createWithAiModalVisible = true;
    }
  }

  // Create Modal Management Area
  onAddPageVariableModalClose() {
    this.createPageVariableData = null;
    this.optMode = this.crudType.Read;
    this.createModalVisible = false;
    this.newCreatedSelector = null;
    this.resetUiDataSelectorButton();
  }

  onAddPageVariableModalOk() {
    if (this.createPageVariableData.variableName && this.createPageVariableData.uiDataSelectorId) {
      const isValid = this.checkVariableNameValidity(this.createPageVariableData.variableName);
      if (isValid) {
        this.store.dispatch(PageVariablesActions.CreatePageVariable({ payload: { pageVariable: this.createPageVariableData } }));
        this.optMode = this.crudType.Read;
        this.createModalVisible = false;
        this.newCreatedSelector = null;
      } else {
        this.notificationService.createNotification(
          'error',
          'Error',
          'Invalid Variable Name'
        );
      }
    } else {
      this.notificationService.createNotification(
        'error',
        'Missing Fields',
        'Fill the blank fields'
      );
    }
  }

  checkVariableNameValidity(variableName: string) {
    return camelCase(variableName) === variableName;
  }

  onCreateFormValueChange(formValue) {
    this.createPageVariableData = {
      ...formValue
    };

    this.updateUiDataSelectorButton(this.createPageVariableData.uiDataSelectorId);
  }

  onCreateWithAiFormValueChange(formValue) {
    this.createWithAiPageVariableData = {
      ...formValue
    };
  }

  onAddPageVariableWithAIModalClose() {
    this.createWithAiPageVariableData = null;
    this.createWithAiModalVisible = false;
  }

  onAddPageVariableWithAIModalOk() {
    if (this.createWithAiPageVariableData?.variableDescription) {
      this.store.dispatch(PageVariablesActions.CreatePageVariableWithAI({
        payload: { pageVariableDescription: this.createWithAiPageVariableData?.variableDescription }
      }));
      this.createWithAiModalVisible = false;
    } else {
      this.notificationService.createNotification(
        'error',
        'Missing Fields',
        'Fill the blank fields'
      );
    }
  }

  onCreateSelectorModalVisibilityChange(visibility: boolean) {
    this.createDataSelectorModalVisibility = visibility;
  }

  // Edit Modal Management Area
  onEditPageVariableModalClose() {
    this.editedPageVariableData = null;
    this.optMode = this.crudType.Read;
    this.editModalVisible = false;
    this.newCreatedSelector = null;
    this.resetUiDataSelectorButton();
  }

  onEditPageVariableModalOk() {
    if (this.editedPageVariableData.variableName && this.editedPageVariableData.uiDataSelectorId) {
      const isValid = this.checkVariableNameValidity(this.editedPageVariableData.variableName);
      if (isValid) {
        this.store.dispatch(PageVariablesActions.UpdatePageVariables({ payload: { pageVariable: this.editedPageVariableData, pageVariableId: this.editedPageVariableData.id } }));
        this.optMode = this.crudType.Read;
        this.editModalVisible = false;
        this.newCreatedSelector = null;
      } else {
        this.notificationService.createNotification(
          'error',
          'Error',
          'Invalid Variable Name'
        );
      }
    } else {
      this.notificationService.createNotification(
        'error',
        'Missing Fields',
        'Fill the blank fields'
      );
    }
  }

  onEditSelectorModalVisibilityChange(visibility: boolean) {
    this.editDataSelectorModalVisibility = visibility;
    if (!visibility) {
      this.editedUiDataSelector = null;
    }
  }

  onEditFormValueChange(formData) {
    this.editedPageVariableData = {
      ...this.editedPageVariableData,
      ...formData
    };

    this.updateUiDataSelectorButton(this.editedPageVariableData.uiDataSelectorId);
  }

  onSelectButtonClick(event: ButtonComponentConfig) {
    if (event.key === 'create') {
      this.createDataSelectorModalVisibility = true;
    } else if (event.key === 'edit') {
      if (this.createModalVisible) {
        this.editedUiDataSelector = this.uiDataSelectors.find(uiDataSelector => uiDataSelector.id === this.createPageVariableData.uiDataSelectorId);
      } else {
        this.editedUiDataSelector = this.uiDataSelectors.find(uiDataSelector => uiDataSelector.id === this.editedPageVariableData.uiDataSelectorId);
      }
      this.editDataSelectorModalVisibility = true;
    }
  }

  onCreateWithAiSelectButtonClick(event: ButtonComponentConfig) {
    console.log(event);
  }

  resetUiDataSelectorButton() {
    const selectItem = this.pageVariableCreateAndEditFormConfig.items.find(
      item => item.fieldName === 'uiDataSelectorId') as CrudFormSelectItem;

    if (selectItem) {
      selectItem.buttons = selectItem.buttons.filter(button => button.key !== 'edit');
      this.pageVariableCreateAndEditFormConfig = {
        ...this.pageVariableCreateAndEditFormConfig
      };
    }
  }
  updateUiDataSelectorButton(uiDataSelectorId: any) {
    const selectItem = this.pageVariableCreateAndEditFormConfig.items.find(
      item => item.fieldName === 'uiDataSelectorId') as CrudFormSelectItem;
    const isExistEditButton = selectItem.buttons.some(button => button.key === 'edit');

    if (uiDataSelectorId !== null) {
      if (!isExistEditButton) {
        selectItem.buttons.push({
          key: 'edit',
          text: 'Edit Selector'
        });
      }
    } else {
      if (isExistEditButton) {
        selectItem.buttons = selectItem.buttons.filter(button => button.key !== 'edit');
      }
    }

    this.pageVariableCreateAndEditFormConfig = {
      ...this.pageVariableCreateAndEditFormConfig
    };
  }
}
