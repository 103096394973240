<div class="comment-header-area flex">
  <div class="comment-author-details flex">
    <nz-avatar [nzText]="getCommentAuthorNameInitials(createdBy)"
               [style]="{color:'#f56a00', backgroundColor:'#fde3cf'}"
               [nzSize]="35"></nz-avatar>
    <div class="comment-details">
      <div class="author-name-area">{{ createdBy }}</div>
      <div class="time-area"
           *ngIf="createdDate">{{ setCommentCreatedDate(createdDate) }}</div>
    </div>
  </div>

  <!-- Comment Actions Area -->
  <div [@hideShow]="(isCommentHovered || status === commentStatus.completed) ? 'show' : 'hide'"
       class="comment-actions-area flex">
    <ng-container *ngIf="!isCommentEditModeActive">
      <rappider-button *ngIf=" status !== commentStatus.completed"
                       rappiderClickStopPropagation
                       nz-tooltip
                       [nzTooltipTitle]="'Mark as resolved' | translate"
                       [icon]="commentStatusButtonConfig.icon"
                       [size]="commentStatusButtonConfig.size"
                       [shape]="commentStatusButtonConfig.shape"
                       (click)="onCommentStatusButtonClick()"></rappider-button>

      <rappider-tag *ngIf=" status === commentStatus.completed"
      [text]="{text:'Completed',colorSettings: {color: '#fff'}}"
      [color]="'#52c41a'"></rappider-tag>
    </ng-container>

    <ng-container *ngIf="(activePerson?.id === createdById || activePerson?.id === assignedTo?.id)">
      <rappider-dropdown-menu rappiderClickStopPropagation
                              class="comment-actions-dropdown"
                              [icon]="commentActionsDropdownConfig.icon"
                              [items]="commentActionsDropdownConfig.items"
                              (menuItemClick)="onCommentMenuItemClick($event)"></rappider-dropdown-menu>
    </ng-container>
  </div>
</div>
