/* Angular */
import { Action, createReducer, on } from '@ngrx/store';

/* Model Interface */
import { ScopeOfWork, ScopeOfWorkPartial, NewScopeOfWork } from '@rappider/rappider-sdk';

/* Actions */
import * as ScopeOfWorkActions from './scope-of-work.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* State key */
export const featureKey = 'scopeOfWork';

/* State interface */
export interface State {
  data: ScopeOfWork[] | null;
  isLoading: boolean;
  error: any;
}

/* Initial values */
export const initialState: State = {
  data: null,
  isLoading: false,
  error: null
};

/* Function to calculate updated scopeOfWork optimistically */
function updateScopeOfWorks(
  updatedScopeOfWorkId: string, updatedScopeOfWorkData: ScopeOfWorkPartial, allScopeOfWorks: ScopeOfWork[]): ScopeOfWork[] {
  if (allScopeOfWorks?.length) {
    const updatedScopeOfWork: ScopeOfWork = allScopeOfWorks?.find(
      scopeOfWork => scopeOfWork.id === updatedScopeOfWorkId) || <ScopeOfWork>{};
    const updatedScopeOfWorkWithNewData = <ScopeOfWork>{ ...updatedScopeOfWork, ...updatedScopeOfWorkData };
    const updatedScopeOfWorks: ScopeOfWork[] = [
      ...allScopeOfWorks.filter(scopeOfWork => scopeOfWork.id !== updatedScopeOfWorkId),
      updatedScopeOfWorkWithNewData
    ];
    return updatedScopeOfWorks;
  } else {
    return <ScopeOfWork[]>[];
  }
}

export const _reducer = createReducer(
  initialState,
  on(ScopeOfWorkActions.GetScopeOfWorksSuccessful, (state, action) => ({
    ...state,
    data: action.payload.scopeOfWorks,
  })),
  on(ScopeOfWorkActions.CreateScopeOfWork, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ScopeOfWorkActions.CreateScopeOfWorkSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data ?? [],
      action.payload.scopeOfWork
    ],
    isLoading: false
  })),
  on(ScopeOfWorkActions.CreateScopeOfWorkFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(ScopeOfWorkActions.UpdateScopeOfWork, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ScopeOfWorkActions.UpdateScopeOfWorkSuccessful, (state, action) => ({
    ...state,
    data: updateScopeOfWorks(action.payload.scopeOfWorkId, action.payload.scopeOfWork, (state.data?.length ? state.data : [])),
    isLoading: false
  })),
  on(ScopeOfWorkActions.UpdateScopeOfWorkFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(ScopeOfWorkActions.DeleteScopeOfWork, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ScopeOfWorkActions.DeleteScopeOfWorkSuccessful, (state, action) => ({
    ...state,
    data: state?.data?.filter(item => action.payload.scopeOfWorkId !== item.id) ?? [],
    isLoading: false
  })),
  on(ScopeOfWorkActions.DeleteScopeOfWorkFailure, (state) => ({
    ...state,
    isLoading: false
  })),

  on(ScopeOfWorkActions.ResetStateToInitial, () => initialState)
);

// combine created reducer with class-based actions
export function reducer(state: State | undefined, action: Action): State {
  const newState = _reducer(state, action);

  switch (action.type) {
    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful:
      return initialState;

    default:
      return newState;
  }
}
