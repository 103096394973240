import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, Subject, timer } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom, takeUntil } from 'rxjs/operators';
import { FileService, NotificationService, PaginationService, RouterStateService } from '@rappider/services';
import * as ProjectExportActions from './project-export.actions';
import { PATH_DEFINITIONS, ProjectExportStatus, PROJECT_EXPORT_POLLING_TIME } from '@rappider/shared/definitions';
import { Router } from '@angular/router';
import { ProjectExport, ProjectExportControllerService } from '@rappider/rappider-sdk';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';

@Injectable()
export class ProjectExportEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private projectExportApi: ProjectExportControllerService,
    private paginationService: PaginationService,
    private routerStateService: RouterStateService,
    private fileService: FileService,
    private router: Router
  ) { }


  loadModule$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectExportActions.LoadModule>(
      ProjectExportActions.ActionTypes.LoadModule,
    ),
    concatMap(() => [
      new ProjectExportActions.GetProjectExports()
    ])
  ));

  /**
   * Get Project Export Total Count
   *
   * @memberof ProjectExportEffects
   */

  getTotalCount$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectExportActions.GetTotalCount>(
      ProjectExportActions.ActionTypes.GetTotalCount
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data),
      this.store.select(state => state.projectExport.pagination)
    ),
    mergeMap(([action, activeProject, pagination]) => {
      const filter = {
        where: { projectId: activeProject.id }
      };
      return this.projectExportApi.count({
        where: filter.where
      }).pipe(
        map((res: { count: number }) => new ProjectExportActions.SetPagination({
          pagination: {
            totalCount: res.count,
            totalPageNumber: this.paginationService.getTotalPageNumber(res.count, pagination.pageSize)
          }
        })),
        catchError(error => [
          new ProjectExportActions.ProjectExportError({ errorOn: 'GetTotalCount', error: error }),
        ])
      );
    })
  ));

  /**
   * Get Project Exports
   *
   * @memberof ProjectExportEffects
   */

  getProjectExports$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectExportActions.GetProjectExports>(
      ProjectExportActions.ActionTypes.GetProjectExports
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id),
      this.store.select(state => state.projectExport.pagination),
    ),
    switchMap(([action, activeProjectId, projectExportsPagination]) => {
      const filter = {
        where: {
          projectId: activeProjectId
        },
        include: ['projectVersion'],
        order: ['createdDate DESC'],
        skip: this.paginationService.
          getSkippedSizeByPagination(projectExportsPagination.currentPageNumber, projectExportsPagination.pageSize),
        limit: projectExportsPagination.pageSize
      };
      const stop = new Subject();
      return timer(0, PROJECT_EXPORT_POLLING_TIME).pipe(
        takeUntil(stop),
        switchMap(() => this.projectExportApi.find({ filter }).pipe(
          mergeMap((projectExports: ProjectExport[]) => {
            if (projectExports?.length) {
              const isProjectExportStatusPending = projectExports[0].status === ProjectExportStatus.Exporting;
              const isCurrentPagePathVersionDetail = this.router.routerState.snapshot.url
                .includes(PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_DETAIL);

              /*
               * stop polling if projectExportStatus is not pending
               * or current page path is not version detail
               */
              if (!isProjectExportStatusPending || !isCurrentPagePathVersionDetail) {
                stop.next(null);
              }
            } else {
              stop.next(null);
            }
            return [
              new ProjectExportActions.SetProjectExports({ projectExports: projectExports })
            ];
          }),
          catchError(error => {
            stop.next(null);
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_EXPORT_COULDNOT_LOADED'
            );
            return [
              new ProjectExportActions.ProjectExportError({ errorOn: 'GetProjectExports', error: error })
            ];
          })
        ))
      );
    })
  ));


  /**
   * Set Project Export Pagination
   *
   * @memberof ProjectExportEffects
   */

  setPagination$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectExportActions.SetPagination>(
      ProjectExportActions.ActionTypes.SetPagination
    ),
    mergeMap(action => {
      if (action.payload.getDataAfter) {
        return of(new ProjectExportActions.GetProjectExports());
      } else {
        return [new ProjectExportActions.SetPaginationFailure({ error: 'There is no getDataAfter in payload', key: 'SetPaginationFailure', timestamp: Date.now() })];
      }
    })
  ));

  /**
   * export project
   *
   * @memberof ProjectEffects
   */

  exportProject$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectExportActions.ExportProject>(
      ProjectExportActions.ActionTypes.ExportProject
    ),
    mergeMap((action) => {
      const projectVersionId = action.payload.projectVersionId;

      return this.projectExportApi.export({ projectVersionId }).pipe(
        mergeMap(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_EXPORT_STARTED'
          );
          return of(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_DETAIL}/${action.payload.projectVersionId}` }));
        })
      );
    }),
    catchError(error => {
      console.log('There was an error while building the project. The reason for this error was an incorrect customization. Please review your code', error);
      // this.notificationService.createNotification(
      //   'error',
      //   'SHARED.ERROR',
      //   error?.error?.message || 'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_COULDNOT_EXPORTED'
      // );
      return [
        new ProjectExportActions.ProjectExportError({ errorOn: 'ExportProject', error: error }),
      ];
    })
  ));

  /**
   * export project
   *
   * @memberof ProjectEffects
   */
  // @Effect()
  // downloadExportedProject$ = this.actions$.pipe(
  //   ofType<ProjectExportActions.DownloadExportedProject>(
  //     ProjectExportActions.ActionTypes.DownloadExportedProject
  //   ),
  //   withLatestFrom(
  //     this.store.select(state => state.activeProject.data)
  //   ),
  //   mergeMap(([action, activeProject]) => {
  //     /* get project export id from payload */
  //     const projectExportId = action.payload.projectExport.id;
  //     /* get datetime variables */
  //     const nowDate = new Date();
  //     const yearStr = nowDate.getFullYear();
  //     const monthStr = nowDate.getMonth() < 10 ? `0${nowDate.getMonth() + 1}` : nowDate.getMonth();
  //     const dateStr = nowDate.getDate() < 10 ? `0${nowDate.getDate()}` : nowDate.getDate();
  //     const hourStr = nowDate.getHours() < 10 ? `0${nowDate.getHours()}` : nowDate.getHours();
  //     const minuteStr = nowDate.getMinutes() < 10 ? `0${nowDate.getMinutes()}` : nowDate.getMinutes();
  //     /* set full date string */
  //     const fullDateStr = `${yearStr}${monthStr}${dateStr}${hourStr}${minuteStr}`;
  //     /* set file name */
  //     const fileName = `${activeProject.key}_v${action.payload.projectExport.projectVersion.versionNumber}_${fullDateStr}.zip`;
  //     /* call download api */
  //     return this.projectExportApi.downloadExport({ projectExportId }).pipe(
  //       map((data: Blob) => new ProjectExportActions.DownloadExportedProjectSuccess({ data: data, fileName: fileName })),
  //       catchError(error => [
  //         new ProjectExportActions.ProjectExportError({ errorOn: 'DownloadExportedProject', error: error }),
  //       ])
  //     );
  //   })
  // );

  /**
   * export project
   *
   * @memberof ProjectEffects
   */
  // @Effect({ dispatch: false })
  // downloadExportedProjectSuccess$ = this.actions$.pipe(
  //   ofType<ProjectExportActions.DownloadExportedProjectSuccess>(
  //     ProjectExportActions.ActionTypes.DownloadExportedProjectSuccess
  //   ),
  //   map(action => {
  //     /* download file */
  //     this.fileService.downloadFile(action.payload.data, action.payload.fileName);
  //   })
  // );

}
