/* angular */
import { createAction, props } from '@ngrx/store';
import { PersonNotificationWithRelations } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetNotifications = '[Notification] GetNotifications',
  GetNotificationsSuccessful = '[Notification] GetNotificationsSuccessful',
  GetNotificationsFailure = '[Notification] GetNotificationsFailure',
  SetNotificationStatusAsSeen = '[Notification] SetNotificationStatusAsSeen',
  SetNotificationStatusAsSeenSuccessful = '[Notification] SetNotificationStatusAsSeenSuccessful',
  SetNotificationStatusAsSeenFailure = '[Notification] SetNotificationStatusAsSeenFailure',
  ChangeActiveProjectWithNotification = '[Notification] ChangeActiveProjectWithNotification',
  GetNotificationsSuccessfulWithDelay = '[Notification] GetNotificationsSuccessfulWithDelay'
}

/* actions */
export const GetNotifications = createAction(ActionTypes.GetNotifications);

export const GetNotificationsSuccessful = createAction(
  ActionTypes.GetNotificationsSuccessful,
  props<{ notifications: PersonNotificationWithRelations[] }>()
);

export const GetNotificationsFailure = createAction(
  ActionTypes.GetNotificationsFailure,
  props<{ error: any; key: string; time: any }>()
);

export const GetNotificationsSuccessfulWithDelay = createAction(ActionTypes.GetNotificationsSuccessfulWithDelay);

export const SetNotificationStatusAsSeen = createAction(
  ActionTypes.SetNotificationStatusAsSeen,
  props<{ notificationId: string }>()
);

export const SetNotificationStatusAsSeenSuccessful = createAction(
  ActionTypes.SetNotificationStatusAsSeenSuccessful,
  props<{ notificationId: string }>()
);

export const SetNotificationStatusAsSeenFailure = createAction(
  ActionTypes.SetNotificationStatusAsSeenFailure,
  props<{ error: any; key: string; time: any }>()
);

export const ChangeActiveProjectWithNotification = createAction(
  ActionTypes.ChangeActiveProjectWithNotification,
  props<{ changeActiveProjectWithNotification: { commentType?: string; pageId?: string; commentId: string; projectId: string; isProjectChange: boolean; isProjectChangeWithoutRefresh: boolean; activeProjectLoadedThroughNotification: boolean } }>()
);
