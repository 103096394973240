import { ProjectModelEditOptions, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import {
  ButtonType,
  ActionBehavior,
  CrudTableViewConfig,
  CrudTableViewIconColumn,
  CrudViewColumnType,
  IconType,
  HorizontalPosition,
  DropdownMenuPlacement,
  MenuActionBehavior
} from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const DATA_FIELD_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  showPagination: false,
  columns: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.REQUIRED',
      fieldName: 'isRequired',
      type: CrudViewColumnType.Boolean
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.UNIQUE',
      fieldName: 'isUnique',
      type: CrudViewColumnType.Boolean
    },
    <CrudTableViewIconColumn>{
      title: 'SHARED.HIDDEN',
      fieldName: 'isHidden',
      type: CrudViewColumnType.Icon,
      // iconSettings: [
      //   {
      //     values: [false, null, undefined],
      //     icon: 'far fa-eye'
      //   },
      //   {
      //     values: [true],
      //     icon: 'far fa-eye-slash'
      //   }
      // ]
    },
    {
      title: 'SHARED.DEFAULT',
      fieldName: 'default',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.DEFAULT_FN',
      fieldName: 'defaultFn',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.ADD_PROJECT_MODEL_DATA_FIELD',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit,
      icon: { name: 'fas fa-plus' }
    },
    {
      text: 'PROJECT_MODULE.PROJECT_MODEL_EDIT_COMPONENT.EDIT_PROJECT_MODEL',
      name: 'editDataModel',
      buttonType: ButtonType.Default,
      icon: {
        name: 'down',
        type: IconType.NgZorro
      },
      behavior: ActionBehavior.DropDownMenu,
      horizontalPosition: HorizontalPosition.Right,
      dropdownConfig: {
        label: 'Edit Data Model',
        placement: DropdownMenuPlacement.BottomLeft,
        items: [
          {
            key: ProjectModelEditOptions.EditDataSchemaOptions,
            label: 'Edit Data Schema',
            actionBehavior: MenuActionBehavior.Emit
          },
          {
            key: ProjectModelEditOptions.GenerateCrudPages,
            label: 'Generate CRUD Pages',
            actionBehavior: MenuActionBehavior.Emit
          }
        ]
      },
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'edit',
      icon: { name: 'far fa-edit' },
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_EDIT}/{{id}}`
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    if (item.isUpdatable && action.name === 'edit') {
      return true;
    }

    if (item.isDeletable && action.name === 'DELETE_ITEM') {
      return true;
    }
  },
  handleorderableFn: (data, item) => {
    if (item.isUpdatable) {
      return true;
    }
  }
};

export const DATA_FIELD_LIST_CONFIG_FOR_DESIGN_MODULE: CrudTableViewConfig = {
  defaultSearchField: 'name',
  showPagination: false,
  columns: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      name: 'updateDataModelWithAI',
      text: 'Update Data Model With AI',
      tooltipText: 'This feature is available for pro users only',
      disabled: true,
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit,
      icon: { name: 'fa-solid fa-sparkles' },
      horizontalPosition: HorizontalPosition.Right,
    },
    {
      name: 'addDataField',
      text: 'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_LIST_COMPONENT.ADD_PROJECT_MODEL_DATA_FIELD',
      buttonType: ButtonType.Default,
      behavior: ActionBehavior.Emit,
      icon: { name: 'fas fa-plus' },
      horizontalPosition: HorizontalPosition.Right,
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'edit',
      icon: { name: 'far fa-edit' },
      behavior: ActionBehavior.Route,
      redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_EDIT}/{{id}}`
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popconfirmCancelText: POP_CONFIRM_DELETE_ACTION.popconfirmCancelText,
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    if (item.isUpdatable && action.name === 'edit') {
      return true;
    }

    if (item.isDeletable && action.name === 'DELETE_ITEM') {
      return true;
    }
  },
  handleorderableFn: (data, item) => {
    if (item.isUpdatable) {
      return true;
    }
  }
};


export const GENERATE_DATA_STORE = {
  key: ProjectModelEditOptions.GenerateDataStore,
  label: 'Generate Data Store',
  actionBehavior: MenuActionBehavior.Emit
};

export const blackListedFieldNamesForCrudPageConfig = [
  'id',
  'createdDate',
  'createdBy',
  'createdById',
  'updatedDate',
  'updatedBy',
  'updatedById',
  'deletedDate',
  'deletedBy',
  'deletedById',
  'isDeleted'
];
