
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

/* This component depends on a 3rd part package: marked to compile markdown to html */
import { marked } from 'marked';

@Component({
  selector: 'rappider-markdown-viewer',
  templateUrl: 'markdown-viewer.component.html',
  styleUrls: ['./markdown-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RappiderMarkdownViewerComponent implements OnInit, OnChanges {
  @Input() markdownText: string | undefined;
  @Input() typeInRealTime? = true;

  markDownPreviewValue = '';

  /* The string variable that saves the types text if typeInRealTime is true  */
  typingText = '';

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (!this.typeInRealTime) {
      this.setMarkDownPreviewValue(this.markdownText);
    } else {
      this.typeTextInRealTime();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.markdownText.currentValue !== this.markdownText) {
      this.markdownText = changes.markdownText.currentValue;
      this.setMarkDownPreviewValue(this.markdownText);
    }
    // if (changes.markdownText) {
    //   this.markdownText = changes.markdownText.currentValue;
    //   if (!this.typeInRealTime) {

    //   } else {
    //     this.typeTextInRealTime();
    //   }
    // }
  }

  setMarkDownPreviewValue(markdownText: string) {
    this.markDownPreviewValue = marked.parse(markdownText, { breaks: true });
  }

  typeTextInRealTime(): void {
    const typingSpeed = 20; // milliseconds
    let index = 0;


    const typeChar = () => {
      if (index < this.markdownText?.length) {
        this.typingText += this.markdownText.charAt(index);
        this.setMarkDownPreviewValue(this.typingText);

        index++;
        // Manually trigger change detection to update the UI
        this.cdr.detectChanges();

        setTimeout(typeChar, typingSpeed);
      }
    };

    typeChar(); // initiate typing
  }

}
