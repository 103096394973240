<div class="content-tree">
  <!-- Search Area -->
  <div class="search-area">
    <rappider-input-group [(ngModel)]="searchValue"
                          [textbox]="inputGroupConfig.textbox"
                          [suffixIcon]="inputGroupConfig.suffixIcon"></rappider-input-group>
  </div>
  <!-- End of Search Area -->

  <!-- Tree Area -->
  <div class="tree-area">
    <nz-tree nzDraggable
             nzBlockNode
             nzShowLine
             [nzShowExpand]="false"
             [nzSelectedKeys]="selectedNodeKeys"
             [nzMultiple]="false"
             [nzData]="dataSource"
             [nzTreeTemplate]="nzTreeTemplate"
             [nzSearchValue]="searchValue"
             [nzBeforeDrop]="beforeDrop"
             (nzOnDrop)="calculateAndDispatchDropEvent($event)"
             (nzClick)="onClickNode($event)">
    </nz-tree>
    <ng-template #nzTreeTemplate
                 let-node
                 let-origin="origin">
      <div class="custom-node">
        <!-- Title and Collapse / Expand Switch Area -->
        <div class="title-and-switch">
          <!-- If the Node is leaf, which means the Node is Container -->
          <div *ngIf="!node.isLeaf"
               class="container-node">
            <span nz-icon
                  [nzType]="node.isExpanded ? 'minus-square' : 'plus-square'"
                  (click)="expandContainer(node)"></span>
            <span class="container-title">{{ node.title }}</span>
          </div>
          <!-- End of Container Area -->
          <!-- If the Node is not leaf, which means the Node is Element / Component -->
          <div *ngIf="node.isLeaf"
               class="component-node">
            <span nz-icon
                  nzType="file"></span>
            <span class="component-title">{{ node.title }}</span>
          </div>
          <!-- Endf of Element / Component Area -->
        </div>
        <!-- End of Title and Collapse / Expand Switch Area -->

        <!-- Action Button Area -->
        <div class="node-action-buttons">
          <!-- If more than one action buttons exist || for container & element or component -->
          <div *ngIf="origin.actionButtonsLength !== 1; else buttonTemplate">
            <rappider-dropdown-menu [items]="origin.actionButtonsLength === 3 ?
                                    actionButtonForComponent: actionButtonForContainer"
                                    [icon]="dropdownIconConfig"
                                    (menuItemClick)="onDropdownMenuItemClick($event, node)"></rappider-dropdown-menu>
          </div>
          <!-- If there is only one action button || for Main Container -->
          <ng-template #buttonTemplate>
            <span nz-icon
                  nzType="container"
                  nzTheme="outline"
                  (click)="createPageContainer(node)"></span>
            <!-- Button Template Content -->
          </ng-template>
        </div>
        <!-- End of Action Button Area -->
      </div>
    </ng-template>
  </div>
  <!-- End of Tree Area -->
</div>
