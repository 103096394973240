import {
  CrudFormTextBoxItem,
  CrudViewFormItemType,
  TextBoxType,
  CrudFormInputValidatorInfoItem,
  FormLayout,
  CrudFormConfig,
  CrudFormSelectItem,
  ButtonType
} from '@rappider/rappider-components/utils';
import { Validators } from '@angular/forms';
import { EMAIL_REGEX, PASSWORD_REGEX, SPECIAL_CHARACTER_REGEX } from '../../definitions/regex';
import { INTERESTS } from '../../data/interests';


export const CHANGE_PROFILE_ONBOARDING_INFORMATIONS_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'SHARED.FIRST_NAME',
      fieldName: 'firstName',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      index: 1,
      showRequiredSign: true,
      placeholder: 'SHARED.FIRST_NAME',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      columnWidth: 50
    },
    <CrudFormTextBoxItem>{
      title: 'SHARED.LAST_NAME',
      fieldName: 'lastName',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      index: 2,
      showRequiredSign: true,
      placeholder: 'SHARED.LAST_NAME',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      columnWidth: 50
    },
    <CrudFormTextBoxItem>{
      title: 'Email',
      fieldName: 'email',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      index: 3,
      showRequiredSign: true,
      placeholder: 'Email',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(EMAIL_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.VALID_EMAIL_MESSAGE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'Company Name',
      fieldName: 'companyName',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      index: 3,
      placeholder: 'Your Company',
    },
    <CrudFormSelectItem>{
      title: 'I\'m primarily interested in',
      fieldName: 'interest',
      type: CrudViewFormItemType.Select,
      // options: LEAD_SOURCES,
      grouppedOptions: INTERESTS,
      index: 4,
      placeholder: 'Select a template category',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
    },
  ],
  itemSettings: {
    labelComponentSize: {
      xs: 24
    },
    inputComponentSize: {
      xs: 24
    },

  },
  formLayout: FormLayout.Vertical,
  submitButton: {
    type: ButtonType.Primary,
    text: 'SHARED.SAVE',
    size: 'default'
  }
};
