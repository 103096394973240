import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { ComponentDefinitionWithRelations } from 'libs/rappider-sdk/src/lib/models/component-definition-with-relations';
import { RenderComponentService } from '@rappider/services';
import { getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';

import { zip, interval, from, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { SHOWROOM_CATEGORY_IDS } from '@rappider/shared/definitions';
import { ProjectTheme } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-component-showroom',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './component-showroom.component.html',
  styleUrls: ['./component-showroom.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentShowroomComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('showcaseContainer', { read: ViewContainerRef }) showcaseContainers: QueryList<ViewContainerRef>;

  showroomComponents: ComponentDefinitionWithRelations[] = [];
  elementComponents: ComponentDefinitionWithRelations[] = [];
  componentDefinitions: ComponentDefinitionWithRelations[];
  subscriptions: Subscription[] = [];
  componentsRendered = false;
  componentsRendering = false;
  isViewportReady = false;
  renderedComponents = [];
  themes: ProjectTheme[];
  activeTheme: ProjectTheme;

  constructor(
    private store: Store<any>,
    private renderComponentService: RenderComponentService,
    private changeDetectionRef: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    this.isViewportReady = true;
    this.renderShowRoomComponents();
  }

  ngOnInit() {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions.push(
      this.subscribeToComponentDefinitions(),
      this.subscribeToProjectThemes()
    );
  }

  subscribeToComponentDefinitions() {
    return this.store.select(<any>getComponentDefinitionsWithDetailsSelector).subscribe(
      (componentDefinitions: ComponentDefinitionWithRelations[]) => {
        if (componentDefinitions) {
          this.componentDefinitions = componentDefinitions;
          if (this.componentDefinitions.length) {
            this.setElementComponents();
          }
        }
      });
  }

  subscribeToProjectThemes() {
    return this.store.select(state => state.projectTheme?.data).subscribe((projectThemes: ProjectTheme[]) => {
      this.themes = projectThemes;
      if (this.themes?.length) {
        this.activeTheme = this.themes[0];
        this.applyTheme();
      }
    });
  }

  clearShowcaseContainer() {
    if (this.showcaseContainers) {
      this.showcaseContainers.forEach(c => c.clear());
    }
  }

  setElementComponents() {
    this.elementComponents = this.componentDefinitions?.filter(componentDefinition =>
      componentDefinition.categoryId && SHOWROOM_CATEGORY_IDS.includes(componentDefinition.categoryId)
    );
    this.showroomComponents = [...this.elementComponents];
    // console.log('this.showroomComponents', this.showroomComponents);
    this.renderShowRoomComponents();
  }

  applyTheme() {
    const componentShowroomRoot: any = document.getElementById('componentShowroom');
    if (componentShowroomRoot && this.activeTheme?.theme) {
      Object.entries(this.activeTheme?.theme || {}).forEach(([key, value]) => {
        if (key && value) {
          componentShowroomRoot.style.setProperty(key, value);
        }
      });
    }
  }

  renderShowRoomComponents() {
    if (this.isViewportReady && this.showroomComponents?.length) {
      // this.changeDetectionRef.detectChanges();
      // this.applyTheme();
      setTimeout(() => {
        this.componentsRendering = true;
        this.renderComponents(this.showroomComponents);
      }, 100);
    }
  }

  renderComponents(components) {
    // Clear containers before rendering
    this.clearShowcaseContainer();

    if (components?.length) {
      const interval$ = interval(250); // Increased to 250ms for smoother rendering
      const components$ = from(components);
      const zipped$ = zip(interval$, components$);

      zipped$.pipe(
        take(components.length)
      ).subscribe(([index, component]: [number, ComponentDefinitionWithRelations]) => {
        const container = this.showcaseContainers?.toArray()[index];

        if (container && component && container?.length === 0) {  // && !this.renderedComponents.includes(component?.id)) {
          // Render component
          this.renderComponentService.renderComponentAndAddToContainer(
            component.className,
            container,
            component.defaultInputs
          );

          this.renderedComponents.push(component?.id);
          // this.changeDetectionRef.detectChanges(); // Trigger change detection after each render
        }
      });
    }
  }
}
