import { createAction, createReducer, on } from '@ngrx/store';
import { DeployStatus } from '../../components/live-preview/utils/deploy-status.enum';
import { LivePreviewInformation } from '@rappider/shared/interfaces';

import * as DeployManagementActions from './deploy-management.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { GitActionWorkflowRunDetailResponseDto } from '@rappider/rappider-sdk';

export const deployManagementFeatureKey = 'deployManagement';

export interface DeployManagementState {
  backendURL: string;
  frontendURL: string;
  deployStatus: DeployStatus;
  livePreviewInformation: LivePreviewInformation;
  loading: boolean;
  gitActionWorkflowRunDetails: GitActionWorkflowRunDetailResponseDto;
  error: {
    error: any;
    key: string;
    timeStamp: number;
  };
}

export const initialState: DeployManagementState = {
  backendURL: null,
  frontendURL: null,
  deployStatus: null,
  livePreviewInformation: null,
  loading: false,
  error: null,
  gitActionWorkflowRunDetails: null
};

const ChangeActiveProjectSuccessful = createAction(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(DeployManagementActions.DeployProject, (state) => ({
    ...state,
    deployStatus: DeployStatus.Deploying,
    loading: true
  })),
  on(DeployManagementActions.DeployProjectSuccessful, (state, action) => ({
    ...state,
    backendURL: action.backendURL,
    frontendURL: action.frontendURL,
    deployStatus: DeployStatus.Success,
    loading: false
  })),
  on(DeployManagementActions.DeployProjectFailure, (state, action) => ({
    ...state,
    deployStatus: DeployStatus.Failure,
    loading: false,
    error: {
      error: action.error,
      key: action.key,
      timeStamp: action.timeStamp
    }
  })),
  on(DeployManagementActions.GetLivePreviewInformationSuccessful, (state, action) => ({
    ...state,
    livePreviewInformation: action.data
  })),
  on(DeployManagementActions.GetLivePreviewInformationFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timeStamp: action.timeStamp
    }
  })),
  on(DeployManagementActions.SetLivePreviewInformation, (state, action) => ({
    ...state,
    livePreviewInformation: action.data
  })),
  on(DeployManagementActions.GetGitActionWorkflowRunDetailsSuccessful, (state, action) => ({
    ...state,
    gitActionWorkflowRunDetails: action.runDetails
  })),
  on(ChangeActiveProjectSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
