<div *ngIf="activeProject?.id && !isLoading"
     class="content-container">
  <ng-container *ngTemplateOutlet="bluePrintManager"></ng-container>
</div>
<div *ngIf="!(activeProject?.id && !isLoading)"
     class="content-container">
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</div>

<!-- BLUEPRINT CONTAINER -->
<ng-template #bluePrintManager>

  <!-- Project NAVBAR -->
  <nz-card class="project-details-card"
           [id]="CollapsePanels.ProjectTitle">
    <div class="project-header">
      <div class="title-bar">
        <h1 class="title">{{ activeProject?.name }} <span class="header-edit-project"><i class="fa-regular fa-pencil"
               (click)="navigateEditProject()"></i></span></h1>
        <div class="title-bar-action-buttons">
          <div joyrideStep="completeWizardFirstStep"
               title="Collapse BluePrint Panel"
               text="Hide the design side menu to expand the content area and get a larger workspace.">
            <button nz-button
                    nz-tooltip
                    [nzTooltipTitle]="'Collapse BluePrint Panel'"
                    nzTooltipPlacement="left"
                    nzSize="small"
                    (click)="collapseBluePrintManager()">
              <i class="fa-regular fa-angle-left"></i>
              <!-- <rappider-icon [name]="'fa-regular fa-square-chevron-left'"></rappider-icon>   -->
            </button>
          </div>
        </div>
      </div>
      <div class="toolbar">
        <div class="toolbar-left">
          <div joyrideStep="completeWizardThirdStep"
               title="Dashboard Menu"
               text="Use this menu to navigate to low-code customization features and tailor your project to your needs."
               (next)="collapseBluePrintManager()">
            <button nz-button
                    nz-tooltip
                    [nzTooltipTitle]="megaMenuTemplate"
                    [(nzTooltipVisible)]="megaMenuVisible"
                    nzTooltipOverlayClassName="mega-menu-overlay"
                    nzTooltipPlacement="bottomLeft"
                    nzSize="small"
                    nzTooltipTrigger="click">
              <rappider-icon [name]="'fas fa-ellipsis-h'"></rappider-icon>
            </button>
          </div>
          <button nz-button
                  nz-tooltip="Collapse All"
                  nzSize="small"
                  (click)="updateCollapsePanelVisibilities('collapse-all')">
            <i class="fa-light fa-arrow-up-from-line"></i></button>
        </div>

        <!-- <div class="toolbar-right">
          <i class="fa fa-info-circle"
             nz-tooltip
             [nzTooltipTitle]="projectInfoTooltip"
             nzTooltipPlacement="bottom"></i>
        </div> -->

      </div>
    </div>
  </nz-card>

  <!-- BLUEPRINT FEATURES -->
  <div class="content-scroll-area">

    <!-- PROJECT DESCRIPTION -->
    <div [class]="isStepVisitedForTheFirstTime(WizardSteps.ProjectDescription) ? 'project-design-container slide-in-bounce' : 'project-design-container'"
         [ngClass]="{ 'apply-blur': activeWizardStep === 0  }"
         [id]="CollapsePanels.ProjectDescription">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Project Description"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.ProjectDescription)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.ProjectDescription, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.ProjectDescription)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.ProjectDescription)">
              <div *ngIf="aiStatusProjectDescriptionImproved === AIProgressStatus.InProgress"
                   class="ai-in-progress">
                <nz-skeleton [nzActive]="true"
                             [nzParagraph]="true"
                             [nzAvatar]="false"></nz-skeleton>
              </div>
              <ng-container *ngIf="aiStatusProjectDescriptionImproved !== AIProgressStatus.InProgress">
                <div class="project-description-editor-container">
                  <rappider-crud-fields-display [data]="activeProject"
                                                [editMode]="projectDescriptionEditMode"
                                                [config]="collapsePanelConfigs[CollapsePanels.ProjectDescription].crudForm"
                                                (formSubmit)="onProjectDescriptionSaved($event?.description)"
                                                (formCancel)="onProjectDescriptionEditCancelled()"></rappider-crud-fields-display>
                </div>
              </ng-container>
            </ng-container>

          </nz-collapse-panel>
        </nz-collapse>

      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="visiblePanelKeys?.includes(CollapsePanels.ProjectDescription) ? collapsePanelConfigs[CollapsePanels.ProjectDescription]!.actionButtons : collapsePanelConfigs[CollapsePanels.ProjectDescription]!.actionButtons.slice(0,2)"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <ng-container
                      *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.ProjectDescription)">
          <ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.ProjectDescription)">
            <rappider-post-it [title]="potItNote.title"
                              [noteText]="potItNote.content"
                              [isEditable]="false"
                              [color]="potItNote.color || PostItColor.Yellow"
                              [size]="potItNote.size || PostItSize.Small"
                              (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- PROJECT INFO -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 2"
         class="project-design-container"
         [id]="CollapsePanels.ProjectInformation">
      <div class="project-design-item-container">
        <rappider-crud-fields-display [data]="activeProject"
                                      [collapseExtra]="getStepStatusTemplate(WizardSteps.ProjectInformation)"
                                      collapsePanelCustomClass="project-design-collapse-panel"
                                      [config]="collapsePanelConfigs[CollapsePanels.ProjectInformation].crudForm"
                                      [collapsePanelsActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.ProjectInformation)"
                                      (collapseActiveChange)="onCollapsePanelActiveChange(CollapsePanels.ProjectInformation, $event?.isActive || false)">
        </rappider-crud-fields-display>
      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="visiblePanelKeys?.includes(CollapsePanels.ProjectInformation) ? collapsePanelConfigs[CollapsePanels.ProjectInformation]!.actionButtons : collapsePanelConfigs[CollapsePanels.ProjectInformation]!.actionButtons.slice(0,2)"
                                        [isExpanded]="projectInformationCollapseActive"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>
      </div>
    </div>

    <!-- SCOPE OF WORK -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 3"
         class="project-design-container"
         [id]="CollapsePanels.ScopeOfWork">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Scope of Work"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.ScopeOfWork)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.ScopeOfWork, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.ScopeOfWork)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.ScopeOfWork)">
              <p>
                Rapider AI generates a scope of work for your MVP (minimum viable product) web application that has CRUD
                (create, read, update, delete ) features.
                <br><br>
                You can edit the scope of work to help Rapider AI understand your requirements better, on paid plans.
                <br><br>
                Our product management team offers a wide range of services to help you build your scope of work on
                enterprise plans.
              </p>

            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.ScopeOfWork]!.actionButtons"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>
      </div>

    </div>

    <!-- DATA MODELS -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 4"
         class="project-design-container"
         [id]="CollapsePanels.DataModelsAndDatabase">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Data Models and Database"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.DataModelsAndDatabase)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.DataModelsAndDatabase, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.DataModelsAndDatabase)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.DataModelsAndDatabase)">
              <!-- Data model list -->
              <nz-collapse nzGhost
                           class="sub-collapse-container">
                <nz-collapse-panel class="project-design-collapse-panel"
                                   nzHeader="Data Models"
                                   [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.DataModelsAndDatabase)">
                  <rappider-data-model-list [dataModels]="projectModels"
                                            [animateTable]="isStepVisitedForTheFirstTime(WizardSteps.DataModelsAndDatabase)"
                                            [enableRowPointer]="true"
                                            (rowActionClick)="onDataModelRowClick($event)"></rappider-data-model-list>
                </nz-collapse-panel>
              </nz-collapse>
              <!-- Data model relations -->
              <nz-collapse *ngIf="projectModelRelations?.length"
                           nzGhost
                           class="sub-collapse-container">
                <nz-collapse-panel class="project-design-collapse-panel"
                                   nzHeader="Data Relationships"
                                   [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.DataModelsAndDatabase)">
                  <rappider-data-model-relationships [projectModelRelations]="projectModelRelations"
                                                     [animateTable]="isStepVisitedForTheFirstTime(WizardSteps.DataModelsAndDatabase)"></rappider-data-model-relationships>

                </nz-collapse-panel>
              </nz-collapse>
              <!-- <div>
         <pre>displayedProjectModels: {{displayedProjectModels}}</pre>
       </div> -->
              <!-- Data model details and  fields -->
              <ng-container *ngFor="let projectModel of projectModels">
                <div [id]="projectModel.id">
                  <nz-collapse nzGhost
                               class="sub-collapse-container">
                    <nz-collapse-panel class="project-design-collapse-panel"
                                       [nzHeader]="projectModel.name"
                                       (nzActiveChange)="onSubCollapsePanelActiveChange(projectModel.id, $event)"
                                       [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.DataModelsAndDatabase) && isStepVisitedForTheFirstTime(WizardSteps.DataModelsAndDatabase)"
                                       (click)="onDataModelRowClick(projectModel)">
                      <ng-container
                                    *ngIf="isStepVisitedForTheFirstTime(WizardSteps.DataModelsAndDatabase) ? true: isSubCollapsePanelVisible[projectModel.id]">
                        <rappider-data-model-detail [projectModel]="projectModel"
                                                    [animateTable]="isStepVisitedForTheFirstTime(WizardSteps.DataModelsAndDatabase)"
                                                    [enableRowPointer]="true"
                                                    (rowActionClick)="onDataModelRowClicka($event)"></rappider-data-model-detail>
                      </ng-container>

                    </nz-collapse-panel>
                  </nz-collapse>
                </div>
              </ng-container>

              <!-- data model relations -->
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="visiblePanelKeys?.includes(CollapsePanels.DataModelsAndDatabase) ? collapsePanelConfigs[CollapsePanels.DataModelsAndDatabase]!.actionButtons : collapsePanelConfigs[CollapsePanels.DataModelsAndDatabase]!.actionButtons.slice(0,2)"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>
      </div>
    </div>

    <!-- API Endpoints -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 5"
         class="project-design-container"
         [id]="CollapsePanels.APIEndpoints">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="API Endpoints"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.APIEndpoints)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.APIEndpoints, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.APIEndpoints)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.APIEndpoints)">
              <!--  [animateTable]="isStepVisitedForTheFirstTime(WizardSteps.APIEndpoints)" -->
              <rappider-endpoints [stopScrollingToView]="!!activeWizardStep && activeWizardStep >= 8"
                                  [animateTable]="isStepVisitedForTheFirstTime(WizardSteps.APIEndpoints)"
                                  [enableRowPointer]="true"
                                  [showNextModel]="showNextEndpointModel"
                                  (endpointRowActionClick)="onEndpointRowActionClick($event)">
              </rappider-endpoints>
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.APIEndpoints]!.actionButtons"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>
      </div>
    </div>

    <!-- UI DESIGN SYSTEM -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 6"
         class="project-design-container"
         [id]="CollapsePanels.DesignSystem">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Design System"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.DesignSystem)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.DesignSystem, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.DesignSystem)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.DesignSystem)">
              <!-- Content for the Design System Section -->
              <!-- <rappider-component-definitions-preview></rappider-component-definitions-preview> -->
              <rappider-element-menu [componentDefinitions]="componentDefinitions ?? []"
                                     [isComponentUsingInModal]="true"
                                     [addComponentToTheActiveContentEditorOnSelection]="false"
                                     (componentAdded)="onElementComponentSelected($event)"></rappider-element-menu>

            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>

      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.DesignSystem]!.actionButtons"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <ng-container
                      *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.DesignSystem)">
          <ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.DesignSystem)">
            <rappider-post-it [title]="potItNote.title"
                              [noteText]="potItNote.content"
                              [isEditable]="false"
                              [color]="potItNote.color || PostItColor.Yellow"
                              [size]="potItNote.size || PostItSize.Small"
                              (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- THEME -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 7"
         class="project-design-container"
         [id]="CollapsePanels.Theme">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Theme"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.Theme)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.Theme, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.Theme)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.Theme)">
              <!-- Content for the Theme Section -->
              <div *ngIf="projectThemes?.length">
                <div class="template-wrapper">
                  <div class="cards-with-preview">
                    <div class="cards-wrapper">
                      <rappider-cards [items]="themeCardsConfig.cardsConfig?.items"
                                      [itemCountPerRow]="themeCardsConfig.cardsConfig?.itemCountPerRow"
                                      [showTitleOnImage]="themeCardsConfig.cardsConfig?.showTitleOnImage"
                                      [showDescriptionOnImage]="themeCardsConfig.cardsConfig?.showDescriptionOnImage"
                                      [selectConfig]="themeCardsConfig.cardsConfig?.selectConfig"
                                      [paginationConfig]="themeCardsConfig.cardsConfig?.paginationConfig"
                                      [showCheckMarkOnCard]="themeCardsConfig.cardsConfig?.showCheckMarkOnCard"
                                      [inputGroupConfig]="themeCardsConfig.cardsConfig?.inputGroupConfig"
                                      [buttons]="themeCardsConfig.cardsConfig?.buttons"
                                      [clickedCardId]="clickedThemeCardId"
                                      (topButtonsClick)="onThemeButtonsClick()"
                                      (cardDetail)="onCardDetail($event)"
                                      (imageButtonClick)="onCardsSelect($event)"></rappider-cards>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <rappider-project-theme-list></rappider-project-theme-list> -->
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>

      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="visiblePanelKeys?.includes(CollapsePanels.Theme) ? collapsePanelConfigs[CollapsePanels.Theme]!.actionButtons : collapsePanelConfigs[CollapsePanels.Theme]!.actionButtons.slice(0,2)"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <ng-container
                      *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.Theme)">
          <ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.Theme)">
            <rappider-post-it [title]="potItNote.title"
                              [noteText]="potItNote.content"
                              [isEditable]="false"
                              [color]="potItNote.color || PostItColor.Yellow"
                              [size]="potItNote.size || PostItSize.Small"
                              (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- UI PAGE LAYOUTS -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 8"
         class="project-design-container"
         [id]="CollapsePanels.UIPageLayouts">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="UI Page Layouts"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.UIPageLayouts)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.UIPageLayouts, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.UIPageLayouts)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.UIPageLayouts)">
              <!-- Content for the UI Page Layouts Section -->
              <div *ngIf="layouts?.length">
                <div class="template-wrapper">
                  <div class="cards-with-preview">
                    <div class="cards-wrapper">
                      <rappider-cards [items]="layoutCardsConfig.cardsConfig?.items"
                                      [itemCountPerRow]="layoutCardsConfig.cardsConfig?.itemCountPerRow"
                                      [showTitleOnImage]="layoutCardsConfig.cardsConfig?.showTitleOnImage"
                                      [showDescriptionOnImage]="layoutCardsConfig.cardsConfig?.showDescriptionOnImage"
                                      [selectConfig]="layoutCardsConfig.cardsConfig?.selectConfig"
                                      [paginationConfig]="layoutCardsConfig.cardsConfig?.paginationConfig"
                                      [showCheckMarkOnCard]="layoutCardsConfig.cardsConfig?.showCheckMarkOnCard"
                                      [inputGroupConfig]="layoutCardsConfig.cardsConfig?.inputGroupConfig"
                                      [clickedCardId]="clickedLayoutCardId"
                                      (cardDetail)="onCardDetail($event)"
                                      (imageButtonClick)="onCardsSelect($event)"></rappider-cards>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <rappider-ui-page-layouts></rappider-ui-page-layouts> -->
            </ng-container>

          </nz-collapse-panel>
        </nz-collapse>

      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.UIPageLayouts]!.actionButtons"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <ng-container
                      *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.UIPageLayouts)">
          <ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.UIPageLayouts)">
            <rappider-post-it [title]="potItNote.title"
                              [noteText]="potItNote.content"
                              [isEditable]="false"
                              [color]="potItNote.color || PostItColor.Yellow"
                              [size]="potItNote.size || PostItSize.Small"
                              (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- UI PAGES -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 9"
         class="project-design-container"
         [id]="CollapsePanels.UIPages">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="UI Pages"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.UIPages)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.UIPages, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.UIPages)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.UIPages)">
              <!-- Content for the UI Pages Section -->
              <div class="ui-pages-wrapper"
                   *ngIf="pages?.length">
                <div class="template-wrapper">
                  <div class="cards-with-preview">
                    <div class="cards-wrapper">
                      <rappider-cards [items]="pageCardsConfig.cardsConfig?.items"
                                      [itemCountPerRow]="pageCardsConfig.cardsConfig?.itemCountPerRow"
                                      [showTitleOnImage]="pageCardsConfig.cardsConfig?.showTitleOnImage"
                                      [showDescriptionOnImage]="pageCardsConfig.cardsConfig?.showDescriptionOnImage"
                                      [selectConfig]="pageCardsConfig.cardsConfig?.selectConfig"
                                      [paginationConfig]="pageCardsConfig.cardsConfig?.paginationConfig"
                                      [showCheckMarkOnCard]="pageCardsConfig.cardsConfig?.showCheckMarkOnCard"
                                      [inputGroupConfig]="pageCardsConfig.cardsConfig?.inputGroupConfig"
                                      [grouppedCards]="grouppedCards"
                                      [bottomCards]="pageCardsConfig.cardsConfig?.bottomCards"
                                      [bottomCardsItemCountPerRow]="ItemPerRow.Two"
                                      (cardDetail)="onCardDetail($event)"
                                      (bottomCardClick)="onPageBottomCardClick($event)"
                                      (imageButtonClick)="onCardsSelect($event)"></rappider-cards>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="visiblePanelKeys?.includes(CollapsePanels.UIPages) ? collapsePanelConfigs[CollapsePanels.UIPages]!.actionButtons : collapsePanelConfigs[CollapsePanels.UIPages]!.actionButtons.slice(0,2)"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <!-- <ng-container
            *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.UIPages)">
<ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.UIPages)">
  <rappider-post-it [title]="potItNote.title"
                    [noteText]="potItNote.content"
                    [isEditable]="false"
                    [color]="potItNote.color || PostItColor.Yellow"
                    [size]="potItNote.size || PostItSize.Small"
                    (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
</ng-container>
</ng-container> -->
      </div>
    </div>

    <!-- UI BUSINESS PROCESSES -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 10"
         class="project-design-container"
         [id]="CollapsePanels.UIBusinessProcesses">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="UI Business Processes"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.UIBusinessProcesses)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.UIBusinessProcesses, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.UIBusinessProcesses)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.UIBusinessProcesses)">
              <!-- Content for the UI Business Processes Section -->
              <div>
                <rappider-markdown-viewer [markdownText]="UI_BUSINESS_PROCESSES_EXPLANATION"></rappider-markdown-viewer>
              </div>
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>

      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="visiblePanelKeys?.includes(CollapsePanels.UIBusinessProcesses) ? collapsePanelConfigs[CollapsePanels.UIBusinessProcesses]!.actionButtons : collapsePanelConfigs[CollapsePanels.UIBusinessProcesses]!.actionButtons.slice(0,2)"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <ng-container
                      *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.UIBusinessProcesses)">
          <ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.UIBusinessProcesses)">
            <rappider-post-it [title]="potItNote.title"
                              [noteText]="potItNote.content"
                              [isEditable]="false"
                              [color]="potItNote.color || PostItColor.Yellow"
                              [size]="potItNote.size || PostItSize.Small"
                              (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- MESSAGE TEMPLATES -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 11"
         class="project-design-container"
         [id]="CollapsePanels.MessageTemplates">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Message Templates"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.MessageTemplates)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.MessageTemplates, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.MessageTemplates)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.MessageTemplates)">
              <!-- Content for the MessageTemplates Section -->

              <div *ngIf="messageTemplates?.length">
                <div class="template-wrapper">
                  <div class="cards-with-preview">
                    <div class="cards-wrapper">
                      <rappider-cards [items]="messageTemplatesCardsConfig.cardsConfig?.items"
                                      [itemCountPerRow]="messageTemplatesCardsConfig.cardsConfig?.itemCountPerRow"
                                      [showTitleOnImage]="messageTemplatesCardsConfig.cardsConfig?.showTitleOnImage"
                                      [showDescriptionOnImage]="messageTemplatesCardsConfig.cardsConfig?.showDescriptionOnImage"
                                      [selectConfig]="messageTemplatesCardsConfig.cardsConfig?.selectConfig"
                                      [paginationConfig]="messageTemplatesCardsConfig.cardsConfig?.paginationConfig"
                                      [showCheckMarkOnCard]="messageTemplatesCardsConfig.cardsConfig?.showCheckMarkOnCard"
                                      [inputGroupConfig]="messageTemplatesCardsConfig.cardsConfig?.inputGroupConfig"
                                      [bottomCards]="messageTemplatesCardsConfig.cardsConfig?.bottomCards"
                                      [bottomCardsItemCountPerRow]="ItemPerRow.Two"
                                      (cardDetail)="onCardDetail($event)"
                                      (bottomCardClick)="onTemplateBottomCardClick($event)"
                                      (imageButtonClick)="onCardsSelect($event)"></rappider-cards>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <rappider-markdown-viewer
                                                [markdownText]="MESSAGE_TEMPLATES_EXPLANATION"></rappider-markdown-viewer> -->
              <!-- <rappider-message-template-display
                                                       [messageTemplate]="messageTemplate"></rappider-message-template-display> -->
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>

      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="visiblePanelKeys?.includes(CollapsePanels.MessageTemplates) ? collapsePanelConfigs[CollapsePanels.MessageTemplates]!.actionButtons : collapsePanelConfigs[CollapsePanels.MessageTemplates]!.actionButtons.slice(0,2)"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <ng-container
                      *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.ServerSideBusinessProcesses)">
          <ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.ServerSideBusinessProcesses)">
            <rappider-post-it [title]="potItNote.title"
                              [noteText]="potItNote.content"
                              [isEditable]="false"
                              [color]="potItNote.color || PostItColor.Yellow"
                              [size]="potItNote.size || PostItSize.Small"
                              (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- SERVER SIDE BUSINESS PROCESSES -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 12"
         class="project-design-container"
         [id]="CollapsePanels.ServerSideBusinessProcesses">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Server Side Business Processes"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.ServerSideBusinessProcesses)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.ServerSideBusinessProcesses, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.ServerSideBusinessProcesses)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.ServerSideBusinessProcesses)">
              <!-- Content for the Server Side Business Processes Section -->
              <!-- <rappider-diagram-wrapper></rappider-diagram-wrapper>-->

              <!-- <rappider-workflow-step-function-list></rappider-workflow-step-function-list> -->
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>

      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="visiblePanelKeys?.includes(CollapsePanels.ServerSideBusinessProcesses) ? collapsePanelConfigs[CollapsePanels.ServerSideBusinessProcesses]!.actionButtons : collapsePanelConfigs[CollapsePanels.ServerSideBusinessProcesses]!.actionButtons.slice(0,2)"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <ng-container
                      *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.ServerSideBusinessProcesses)">
          <ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.ServerSideBusinessProcesses)">
            <rappider-post-it [title]="potItNote.title"
                              [noteText]="potItNote.content"
                              [isEditable]="false"
                              [color]="potItNote.color || PostItColor.Yellow"
                              [size]="potItNote.size || PostItSize.Small"
                              (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- ENVIRONMENT VARIABLES -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 13"
         class="project-design-container"
         [id]="CollapsePanels.SettingsVariablesAndEnvironments">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Settings, Variables and Environments"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.SettingsVariablesAndEnvironments)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.SettingsVariablesAndEnvironments, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.SettingsVariablesAndEnvironments)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.SettingsVariablesAndEnvironments)">
              <!-- Content for the Environment Variables Section -->
              <div class="settings-environment-variables-container">
                <rappider-project-environment-variables [enableRowPointer]="true"
                                                        (projectEnvironmentVariablesRowActionClick)="onProjectEnvironmentVariablesRowActionClick($event)"></rappider-project-environment-variables>
              </div>
              <div class="settings-project-settings-container">
                <rappider-project-settings [displaySystemSettings]="false"
                                           [enableRowPointer]="true"
                                           (projectSettingsRowActionClick)="onProjectSettingsRowActionClick($event)"></rappider-project-settings>
              </div>
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>

      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.SettingsVariablesAndEnvironments]!.actionButtons"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <ng-container
                      *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.SettingsVariablesAndEnvironments)">
          <ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.SettingsVariablesAndEnvironments)">
            <rappider-post-it [title]="potItNote.title"
                              [noteText]="potItNote.content"
                              [isEditable]="false"
                              [color]="potItNote.color || PostItColor.Yellow"
                              [size]="potItNote.size || PostItSize.Small"
                              (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- YOUR APP IS READY -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 14"
         class="project-design-container"
         [id]="CollapsePanels.GenerateProjectCode">
      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Generate Project Code"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.GenerateProjectCode)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.GenerateProjectCode, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.GenerateProjectCode)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.GenerateProjectCode)">
              <!-- Content for the Generate Project Code Section -->
              <rappider-project-version-list></rappider-project-version-list>
              <ul class="generate-project-code-buttons">
                <li (click)="onDisplayCodePanelClicked()"
                    nz-tooltip
                    nzTooltipPlacement="top"
                    nzTooltipTitle="Display to Code View. Quick access is also available in the top-right header">
                  <a>
                    <i class="fa-regular fa-rectangle-code"></i>
                  </a>
                  <span class="button-label">Code Panel - View and edit your project's source code directly</span>
                </li>
              </ul>
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>

      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.GenerateProjectCode]!.actionButtons"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>

        <ng-container
                      *ngIf="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.GenerateProjectCode)">
          <ng-container *ngFor="let potItNote of getPostItNotes(CollapsePanels.GenerateProjectCode)">
            <rappider-post-it [title]="potItNote.title"
                              [noteText]="potItNote.content"
                              [isEditable]="false"
                              [color]="potItNote.color || PostItColor.Yellow"
                              [size]="potItNote.size || PostItSize.Small"
                              (noteUpdated)="onPostItNoteUpdated($event)"></rappider-post-it>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- SHARE PROJECT -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 15"
      class="project-design-container"
      [id]="CollapsePanels.ShareApplication">

    <div class="project-design-item-container">
      <nz-collapse>
        <nz-collapse-panel class="project-design-collapse-panel"
                          nzHeader="Share Application"
                          [nzExtra]="getStepStatusTemplate(WizardSteps.ShareApplication)"
                          (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.ShareApplication, $event)"
                          [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.ProjectMembers)">
          <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.ShareApplication)">
            <rappider-paragraph content="Share your achievement on X or LinkedIn, tag @rapiderai (X) or /rapiderai (LinkedIn)"></rappider-paragraph>
          </ng-container>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div class="project-design-item-settings-container">
      <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.ShareApplication]!.actionButtons"
                                      (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>
    </div>
  </div>

    <!-- PROJECT MEMBERS -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 15"
         class="project-design-container"
         [id]="CollapsePanels.ProjectMembers">

      <div class="project-design-item-container">
        <nz-collapse>
          <nz-collapse-panel class="project-design-collapse-panel"
                             nzHeader="Project Members"
                             [nzExtra]="getStepStatusTemplate(WizardSteps.ProjectMembers)"
                             (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.ProjectMembers, $event)"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.ProjectMembers)">
            <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.ProjectMembers)">
              <rappider-project-members-navigation
                                                   [projectMembers]="activeProject?.people || []"></rappider-project-members-navigation>
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <div class="project-design-item-settings-container">
        <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.ProjectMembers]!.actionButtons"
                                        (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>
      </div>
    </div>

    <!-- DEPLOY -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 15"
      class="project-design-container"
      [id]="CollapsePanels.Deploy">

    <div class="project-design-item-container">
      <nz-collapse>
        <nz-collapse-panel class="project-design-collapse-panel"
                          nzHeader="Deploy"
                          [nzExtra]="getStepStatusTemplate(WizardSteps.Deploy)"
                          (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.Deploy, $event)"
                          [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.Deploy)">
          <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.Deploy)">
          <div class="deploy-wrapper">
            <rappider-paragraph content="Please click the button to deploy the project."></rappider-paragraph>
            <rappider-button  [text]="'Deploy'"
                              [disabled]="deployStatus === DeployStatus.InProgress || deployStatus === DeployStatus.Queued"
                              [icon]="{type: 'FONT_AWESOME',name: 'fa-regular fa-play'}"
                              (click)="deployButtonClick()"></rappider-button>
          </div>
          </ng-container>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div class="project-design-item-settings-container">
      <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.Deploy]!.actionButtons"
                                      (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>
    </div>
  </div>

    <!-- SERVER -->
    <div *ngIf="activeWizardStep && activeWizardStep >= 15 && (deployStatus === DeployStatus.Successful)"
      class="project-design-container"
      [id]="CollapsePanels.Server">

    <div class="project-design-item-container">
      <nz-collapse>
        <nz-collapse-panel class="project-design-collapse-panel"
                          nzHeader="Server"
                          [nzExtra]="getStepStatusTemplate(WizardSteps.Server)"
                          (nzActiveChange)="onCollapsePanelActiveChange(CollapsePanels.Server, $event)"
                          [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys?.includes(CollapsePanels.Server)">
          <ng-container *ngIf="visiblePanelKeys?.includes(CollapsePanels.Server)">
          <div class="deploy-wrapper">
            <rappider-paragraph content="The server-related information can be viewed here."></rappider-paragraph>
          </div>
          </ng-container>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div class="project-design-item-settings-container">
      <rappider-settings-button-group [buttons]="collapsePanelConfigs[CollapsePanels.Deploy]!.actionButtons"
                                      (buttonClicked)="onActionButtonClicked($event)"></rappider-settings-button-group>
    </div>
  </div>

    <!-- DEBUGGER -->
    <ng-container *ngIf="false"
                  [ngTemplateOutlet]="debugPanel"></ng-container>


    <!-- WIZARD -->
    <!-- <div class="project-design-container"
         *ngIf="wizardStepsConfigs?.length && wizardStepsConfigs[activeWizardStep]?.status === WizardStepStatus.InProgress">
      <div class="project-design-item-container">
        <nz-card class="blueprint-rappider-wizard-container project-design-container">
          <div class="blueprint-rappider-wizard-header">
            <div class="blueprint-rappider-wizard-logo-container">
              <img class="logo-image bouncing-rabbit"
                   src="assets/img/logo/white-rabbit-01.jpg"
                   alt="Rapider AI App Builder Wizard">
            </div>
            <div class="blueprint-rappider-wizard-title">
              <h2>
                {{((activeWizardStep || 0)+1) + '. ' + wizardStepsConfigs[activeWizardStep]?.title || 'Rapider AI App
                Builder
                Wizard'}}
              </h2>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="aiWizardContentTemplate"></ng-container>
        </nz-card>
      </div>
      <div class="project-design-item-settings-container">
      </div>
    </div> -->

    <!-- EXTRA WIZARD FOOTER -->
    <ng-container *ngIf="displayWizard && showWizardOnBluePrintFooter && wizardStepsConfigs?.length"
                  [ngTemplateOutlet]="aiWizardAdditionalFooter"></ng-container>

  </div>
  <!-- END OF BLUEPRINT FEATURES -->

</ng-template>
<!-- END OF BLUEPRINT MANAGER -->

<ng-template #aiWizardContentTemplate>
  <div [class]="'rapider-ai-wizard-content step_' + (activeWizardStep || 0) "
       #wizardContainer>
    <div class="wizard-content-markdown-container">
      <rappider-markdown-viewer
                                [markdownText]="wizardStepsConfigs[activeWizardStep]?.description || '...' "></rappider-markdown-viewer>
    </div>
    <div class="rapider-ai-wizard-footer">
      <div> </div>
      <div class="footer-right">
        <!-- <button *ngIf="displayNextUIPageButton"
                [id]="ProjectDesignSettingButtons.NextUIPageButton"
                nz-button
                nzType="default"
                (click)="onWizardNextUIPageButtonClicked()">Next Page</button> -->
        <!--
        <button *ngIf="wizardStepsConfigs[activeWizardStep]?.skippable"
                nz-button
                nzType="default"
                (click)="onWizardSkipStepsButtonClicked()">Skip</button> -->
        <button [id]="ProjectDesignSettingButtons.WizardNextButton"
                nz-button
                nzType="primary"
                [disabled]="activeWizardStep && wizardStepsConfigs[activeWizardStep]?.disableNextButton">
          <!-- (click)="onWizardNextStepButtonClicked()" -->
          {{activeWizardStep && activeWizardStep >= (wizardStepsConfigs?.length! - 1) ?
          'Complete the Wizard' : 'Next'}}</button>
      </div>
    </div>
  </div>

</ng-template>


<!-- This footer is displayed when the original footer is not visible -->
<ng-template #aiWizardAdditionalFooter>

  <div class="project-design-container footer-sticky">
    <div class="project-design-item-container footer-sticky-item-container">
      <nz-card class="blueprint-rappider-wizard-container project-design-container">
        <div class="blueprint-rappider-wizard-header">
          <div class="blueprint-rappider-wizard-logo-container">
            <img class="logo-image bouncing-rabbit"
                 src="/assets/wr/wr-welcome.gif"
                 alt="Rapider AI App Builder Wizard">
            <!-- src="assets/img/logo/white-rabbit-01.jpg" -->
          </div>
          <div class="blueprint-rappider-wizard-title">
            <h2>
              <span *ngIf="activeWizardStep">{{(+activeWizardStep) + '. '}}</span>
              <span>{{wizardStepsConfigs[activeWizardStep]?.title || 'Rapider AI App Builder
                Wizard'}}</span>
              <!-- Introduction  -->
              <!-- <div *ngIf="displayWizardOnIntroduction && wizardStepsConfigs?.length && wizardStepsConfigs[activeWizardStep]?.status === WizardStepStatus.Introduction"
                   class="wizard-content-markdown-container">
                <rappider-markdown-viewer [markdownText]="wizardStepsConfigs[activeWizardStep]?.introduction ">
                </rappider-markdown-viewer>
              </div> -->
            </h2>
          </div>
        </div>
        <div *ngIf="wizardStepsConfigs?.length && wizardStepsConfigs[activeWizardStep]?.status === WizardStepStatus.Introduction"
             class="wizard-content-markdown-container">

          <rappider-wizard-rabbit [activeProjectId]="activeProject?.id"
                                  [wizardStepsConfigs]="wizardStepsConfigs"
                                  [activeWizardStep]="activeWizardStep"
                                  [displayRabbitOnHeader]="false"
                                  (typingsCompleted)="onWizardModalTypingsCompleted($event)"></rappider-wizard-rabbit>

          <!--
          <rappider-markdown-viewer [markdownText]="wizardStepsConfigs[activeWizardStep]?.introductionExtra ">
          </rappider-markdown-viewer> -->
        </div>
        <div *ngIf="wizardStepsConfigs?.length && wizardStepsConfigs[activeWizardStep]?.status !== WizardStepStatus.Introduction"
             class="wizard-content-markdown-container">

          <rappider-timeline *ngIf="wizardStepsTimelineItems?.length"
                             [items]="wizardStepsTimelineItems"></rappider-timeline>

          <!-- <rappider-markdown-viewer [markdownText]="wizardStepsConfigs[activeWizardStep]?.description ">
          </rappider-markdown-viewer> -->
        </div>
        <div *ngIf="displayWizardNextButton"
             class="rapider-ai-wizard-additional-footer">
          <div class="footer-right">

            <!-- <button *ngIf="activeWizardStep && wizardStepsConfigs[activeWizardStep]?.skippable"
                    nz-button
                    nzType="default"
                    (click)="onWizardSkipStepsButtonClicked()">Skip</button> -->
            <button *ngIf="displayWizardNextButton"
                    [id]="ProjectDesignSettingButtons.WizardNextButton"
                    nz-button
                    nzType="primary"
                    [disabled]="activeWizardStep && wizardStepsConfigs[activeWizardStep]?.disableNextButton"
                    [nzLoading]="true">
              <!-- (click)="onWizardNextStepButtonClicked()"> -->
              {{wizardStepsConfigs[activeWizardStep]?.planTitle || 'Please wait while generating the next step...'}}
            </button>
          </div>
        </div>
      </nz-card>
    </div>
    <div class="project-design-item-settings-container">
    </div>
  </div>



</ng-template>

<ng-template #debugPanel>
  <div class="project-design-container">
    <div class="project-design-item-container">
      <nz-collapse>
        <nz-collapse-panel class="project-design-collapse-panel"
                           [nzActive]="true"
                           nzHeader="Debug Panel">
          <pre>activeWizardStep: {{activeWizardStep}}</pre>
          <pre>activeWizardStep: {{activeWizardStep ? wizardStepsConfigs[activeWizardStep]?.status ?? '---' : 'null'}}</pre>
          <hr>
          <pre
               *ngIf="wizardStepsConfigs?.length">activeWizardStep Config: {{wizardStepsConfigs[activeWizardStep] | json}}</pre>
        </nz-collapse-panel>
        <nz-collapse-panel class="project-design-collapse-panel"
                           nzHeader="Debug: activeProject">
          <pre>activeProject: {{activeProject | json}}</pre>
        </nz-collapse-panel>
        <nz-collapse-panel class="project-design-collapse-panel"
                           nzHeader="Debug: wizardStepsConfigs">
          <pre>wizardStepsConfigs: {{wizardStepsConfigs | json}}</pre>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div class="project-design-item-settings-container">
    </div>
  </div>
</ng-template>

<ng-template #loremIpsum>
  <div>
    <!-- Reusable lorem ipsum to mock the text -->
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis commodo, lacus nec eleifend sollicitudin, mi
      nisi aliquet nulla, at vulputate urna eros rhoncus ante. Morbi interdum in nulla at pretium. Aenean eu urna
      condimentum, efficitur leo tincidunt, mattis neque. Nunc porttitor euismod urna id congue. Quisque sodales
      tempus pulvinar. Fusce hendrerit, est vel iaculis vestibulum, odio est scelerisque odio, sed aliquam justo
      lorem in ex. Ut semper dictum est. Morbi eget quam at est fringilla mattis. Mauris purus ligula, semper ac
      blandit vel, semper ut mi. Quisque volutpat mauris et ipsum venenatis viverra sit amet a leo.
    </p>
    <p>
      Duis sit amet quam ante. Pellentesque vel lorem scelerisque, mattis mi vitae, gravida massa. Nullam cursus
      egestas eleifend. Curabitur consectetur arcu at odio auctor faucibus. Nunc scelerisque tortor eget tortor
      ullamcorper, ut ultricies massa suscipit. Cras mattis cursus nibh, sed rutrum felis porta blandit. Duis
      libero lorem, ultricies eu ipsum quis, semper commodo nulla. Lorem ipsum dolor sit amet, consectetur
      adipiscing elit. Ut id nisl auctor, aliquam sapien quis, tempus odio. Praesent a mattis magna. Quisque sit
      amet ultricies dui, eget auctor risus. In vehicula leo eu vestibulum laoreet. Fusce ligula ligula, vehicula
      sit amet condimentum a, varius in ex.
    </p>
  </div>
</ng-template>

<ng-template #stepNotStarted>
  <div class="project-design-item-header-right">
    <i class="fa-solid fa-star"></i>
    <span>Not Started</span>
  </div>
</ng-template>

<ng-template #stepInProgress>
  <div class="project-design-item-header-right">
    <i class="fa-solid fa-cog fa-spin color-info"
       style="--fa-animation-duration: 8s;"></i>
    <span>In Progress</span>
  </div>
</ng-template>

<ng-template #stepCompleted>
  <div class="project-design-item-header-right">
    <i class="fa-solid fa-check color-success"></i>
    <span>Completed</span>
  </div>
</ng-template>

<ng-template #stepError>
  <div class="project-design-item-header-right">
    <i class="fa-solid fa-exclamation-triangle color-error"></i>
    <span>Error</span>
  </div>
</ng-template>

<!-- Mega Menu -->
<ng-template #megaMenuTemplate>
  <div class="mega-menu-container">
    <button nz-button
            nz-dropdown
            class="mega-menu-close-button"
            nzTrigger="click"
            (click)="onMegaMenuClose()">
      <rappider-icon [name]="'fas fa-close'"></rappider-icon>
    </button>
    <section class="customization-menu-content">
      <div class="feature-card-container row">
        <div *ngFor="let feature of projectMegaMenuFeatures; let i = index"
             class="feature-card  col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div class="feature-card-content">
            <i [class]="feature.icon"></i>
            <h5 class="feature-title">
              {{feature.title}}
            </h5>
            <p class="feature-description">
              {{feature.description}}
            </p>
            <div class="feature-links">
              <ng-container *ngFor="let subFeature of feature.subFeatures">
                <a *ngIf="subFeature?.isVisible !== false && subFeature?.title !== 'Invite Users'; else inviteUserTemplate"
                   class="feature-link"
                   [routerLink]="[subFeature.path]"
                   (click)="onMegaMenuClicked(subFeature)">{{subFeature.title}}</a>
                <ng-template #inviteUserTemplate>
                  <a *ngIf="isAdminOrManagerUser() && subFeature?.isVisible !== false"
                     class="feature-link"
                     [routerLink]="[subFeature.path]"
                     (click)="onMegaMenuClicked(subFeature)">{{subFeature.title}}</a>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-template>

<ng-template #projectInfoTooltip>
  <div>
    <span *ngIf="tenant?.name"
          class="field-container">
      <span class="field">Tenant Name:</span>
      {{ tenant?.name }}
    </span>

    <span class="field-container">
      <span class="field">{{ 'Created By' | translate }} </span>
      {{ activeProject?.createdBy || '-' }}
      <span class="field">{{ 'at' | translate }} </span>
      {{ activeProject?.createdDate | formatDate}}
    </span>
  </div>
</ng-template>
