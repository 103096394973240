import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, throwError } from 'rxjs';
import { BreadcrumbOption, HeadingComponentConfig, IconType, TimelineItem } from '@rappider/rappider-components/utils';
import { GitActionWorkflowRunDetailResponseDto, Project, ProjectVersion, ProjectWithRelations } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { titleBarActionButtonsConfig } from './utils/title-bar-action-buttons.config';
import { deployErrorAlertConfig } from './utils/deploy-error-alert.config';
import { GetLivePreviewInformation, } from '../../states/deploy-management/deploy-management.actions';
import { DeployManagementState } from '../../states/deploy-management/deploy-management.reducer';
import { DeployStatus } from './utils/deploy-status.enum';
import { TimelineItemColor } from 'libs/components/src/lib/utils/timeline/timeline-item-color.enum';
import { projectDeploymentAnimation } from './utils/project-deployment-animation';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { StartApplication, StopApplication } from 'libs/project-source-code/src/lib/project-source-code-data-source/project-source-code.actions';


@Component({
  selector: 'rappider-live-preview',
  templateUrl: './live-preview.component.html',
  styleUrls: ['./live-preview.component.scss']
})
export class LivePreviewComponent implements OnInit, OnDestroy {

  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'Live Preview',
    type: defaultToolbarTitleHeadingSize
  };

  powerSwitchConfig = {
    tooltipText: 'Power (on/off)',
    defaultValue: false
  }

  /* breadcrumb title of the page */
  title: string[] | BreadcrumbOption[];
  subscriptions: Subscription[] = [];
  activeProject: ProjectWithRelations;
  backendURL: string;
  frontendURL: string;
  refreshIframeFlag = false;
  initialUpdateBreadcrumb = false;
  frontendURLWithoutTimeStamp: string;
  /**
   * The deployment status indicates whether the initiation of the deployment process
   * was successful or if an error occurred during its commencement;
   * it does not imply the completion or non-completion of the deployment itself.
  *
  * @type {DeployStatus}
  * @memberof LivePreviewComponent
  */
  deployStatus = DeployStatus.Failure;
  displayedBuild: any;
  isDeploymentLogVisible = true;

  isIFrameContentLoaded = false;
  iframeVisibility = false;

  titleBarActionButtons = titleBarActionButtonsConfig;
  deployErrorAlert = deployErrorAlertConfig;
  projectDeploymentAnimation = projectDeploymentAnimation;
  timelineItems: TimelineItem[];
  projectVersions: ProjectVersion[];
  applicationInfo: any;

  gitActionWorkflowRunDetails: GitActionWorkflowRunDetailResponseDto;

  /**
   * timer of the git action workflow run details information getter
   *
   * @type {*}
   * @memberof LivePreviewComponent
   */
  getGitActionWorkflowRunDetailsTimer: any;

  /* enum definitions */
  DeployStatus = DeployStatus;

  /* third party libs */
  moment = moment;

  refreshFlag = false;

  constructor(
    private store: Store<{ deployManagement: DeployManagementState; activeProject: Project, projectVersion: ProjectVersion }>,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectVersions(),
      this.subscribeToApplicationInfo(),
      this.subscribeToDeployManagementInformation(),
      this.subscribeToDeploymentProcessStatus(),
      this.subscribeToActiveProject()
    ];
  }

  getLivePreviewInformation() {
    this.store.dispatch(GetLivePreviewInformation({ continuePollingAfterSuccessAndFailure: true }));
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.getLivePreviewInformation();
        this.setLivePreviewURLs(activeProject.id);
      }
      this.setPageTitle(activeProject);
    });
  }

  subscribeToDeploymentProcessStatus() {
    return this.store.select(state => state.deployManagement.deployInfo).subscribe((status: DeployStatus) => {
      this.deployStatus = status;
    });
  }

  subscribeToProjectVersions() {
    return this.store.select(state => state.projectVersion.data).subscribe((projectVersions: ProjectVersion[]) => {
      this.projectVersions = projectVersions;
    });
  }

  subscribeToApplicationInfo() {
    return this.store.select(state => state.deployManagement.applicationInfo).subscribe((applicationInfo: any) => {
      if (applicationInfo?.length) {
        if (applicationInfo[0].State === 'exited') {
          this.powerSwitchConfig = {
            tooltipText: 'Power (on/off)',
            defaultValue: false
          };
        } else {
          this.powerSwitchConfig = {
            tooltipText: 'Power (on/off)',
            defaultValue: true
          };
        }
      }
    });
  }

  subscribeToDeployManagementInformation() {
    return this.store.select(state => state.deployManagement?.deployInfo)
      .subscribe(deployInfo => {
        if (deployInfo?.length) {
          this.displayedBuild = {
            status: deployInfo[0]?.status,
            start_time: deployInfo[0]?.createdDate,
            end_time: deployInfo[0]?.updatedDate,
            version_branch: this.projectVersions?.find(projectVersion => projectVersion.id === deployInfo[0].projectVersionId)?.versionNumber,
            run_id: deployInfo[0]?.id
          }
          this.timelineItems = deployInfo[0].processes.map(process => ({
            title: process.message,
            icon: {
              name: process.status === 'in-progress' ? 'fa-duotone fa-gear fa-spin'
                : process.status === 'failure' ? 'fa-regular fa-xmark'
                  : process.status === 'successful' ? 'fa-solid fa-circle-check'
                    : 'fa-solid fa-circle-check',
              type: IconType.FontAwesome,
              color: process.status === 'in-progress' ? 'var(--text-color)'
                : process.status === 'failure' ? 'var(--danger-color)'
                  : process.status === 'successful' ? 'var(--success-color)'
                    : 'var(--success-color)',
            },
            date: process.timestamp,
            color: TimelineItemColor.Success
          }))

          if (this.displayedBuild) {
            this.updateBreadcrumb(this.displayedBuild);
          }
        } else if (deployInfo !== null) {
          this.displayedBuild = {
            status: DeployStatus.NotStarted
          }
        }

      });
  }

  updateBreadcrumb(displayedBuild?) {
    if (!this.deployStatus && !this.initialUpdateBreadcrumb && displayedBuild.status === DeployStatus.Successful) {
      /* Update Breadcrumb */
      if (this.title && displayedBuild.end_time) {
        this.title[2] = {
          label: `Previewing the build on ${moment(displayedBuild.end_time).calendar()}`,
        };
      }
      this.initialUpdateBreadcrumb = true;
    } else {
      // if deployStatus & displayedBuild.status exist and success,
      // also refreshCheck is false, then refresh the iframe
      if (
        displayedBuild.status === DeployStatus.Successful &&
        !this.refreshIframeFlag
      ) {
        if (
          this.title &&
          displayedBuild.end_time
        ) {
          // update breadcrumb
          this.title[2] = {
            label: `Previewing the build on ${moment(displayedBuild.end_time).calendar()}`,
          };

          // update url
          const frontendURL = new URL(`https://${this.activeProject.id}.app.rapider.ai`);
          this.frontendURLWithoutTimeStamp = frontendURL.href;
          frontendURL.searchParams.append('timestamp', new Date().getTime().toString());
          this.frontendURL = frontendURL.href;

          // Update iframe src after 5 seconds
          setTimeout(() => {
            // src is updated to refresh iframe
            this.iframe.nativeElement.src = this.frontendURL;
          }, 5000);

          // We set it to true to avoid it from refreshing repeatedly.
          this.refreshIframeFlag = true;

          this.cdr.detectChanges();
        }
      } else if (displayedBuild.status === DeployStatus.InProgress) {
        // in progress condition
        this.refreshIframeFlag = false;
      }
    }
  }

  onIFrameContentLoaded() {
    this.isIFrameContentLoaded = true;
    this.cdr.detectChanges();
    // this.getIFrameStatus();
  }

  getIFrameStatus() {
    if (this.iframe) {
      console.log(this.iframe);
      const headers = new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
      });

      this.http.get(this.frontendURLWithoutTimeStamp,
        { headers: headers, withCredentials: true }).pipe(
          catchError(error => {
            console.log(error);
            return throwError(error);
          })
        ).subscribe(response => {
          console.log(response);
        });
    } else {
      console.log('Iframe not ready yet, waiting...');
    }
  }

  setPageTitle(activeProject: ProjectWithRelations) {
    this.title = [
      {
        label: activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
      },
      {
        label: 'Live Preview',
      }
    ];
  }

  toggleDeploymentLogVisibility() {
    this.isDeploymentLogVisible = !this.isDeploymentLogVisible;
  }

  onTitleBarActionButtonClick(action) {
    if (action.key === 'deploymentLog') {
      const deploymentButton = this.titleBarActionButtons.find(button => button.key === 'deploymentLog');
      deploymentButton.icon = {
        ...deploymentButton.icon,
        name: this.isDeploymentLogVisible ? 'menu-fold' : 'menu-unfold'
      };
      this.toggleDeploymentLogVisibility();
    } else if (action.key === 'openInNewTab') {
      window.open(this.frontendURL, '_blank');
    }
  }

  setLivePreviewURLs(activeProjectId: string) {
    /* set timestamp */
    const timestamp = new Date().getTime().toString();

    /* define as url object */
    const frontendURL = new URL(`https://${activeProjectId}.app.rapider.ai`);
    this.frontendURLWithoutTimeStamp = frontendURL.href;
    /* set timestamp query param */
    frontendURL.searchParams.append('timestamp', new Date().getTime().toString());
    /* set frontend url */
    this.frontendURL = frontendURL.href;

    /* define as url object */
    const backendURL = new URL(`https://${activeProjectId}.api.rapider.ai`);
    /* set timestamp query param */
    backendURL.searchParams.append('timestamp', timestamp);
    /* set backend url */
    this.backendURL = backendURL.href;
  }

  setDeployStatusIcon(livePreviewStatus) {
    const defaultIcon = 'fa-duotone fa-gear fa-spin';
    let icon: string;
    let text: string;

    if (livePreviewStatus === DeployStatus.Successful) {
      icon = 'fa-duotone fa-check';
      text = 'Completed';
    } else if (livePreviewStatus === DeployStatus.InProgress) {
      icon = defaultIcon;
      text = 'In Progress';
    } else if (livePreviewStatus === DeployStatus.Failure) {
      icon = 'fa-duotone fa-x';
      text = 'Failed';
    } else if (livePreviewStatus === DeployStatus.NotStarted) {
      icon = 'fa-regular fa-hexagon-exclamation';
      text = 'Not Started';
    } else {
      icon = defaultIcon;
      text = 'Loading';
    }

    return { icon, text };
  }

  onSwitchToggled(switchValue) {
    if (switchValue) {
      this.store.dispatch(StartApplication());
    } else {
      this.store.dispatch(StopApplication());
    }
  }

}
