import {
  ActionBehavior,
  CrudTableViewConfig,
  CrudViewColumnType,
} from '@rappider/rappider-components/utils';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';
import { ActionMode } from '../../../../../../custom-function/src/lib/components/custom-function-tab-list-wrapper/utils/action-mode.enum';

export const NEW_PROJECT_DATA_FIELD_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'name',
  showPagination: false,
  columns: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewColumnType.Text
    },
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: ActionMode.Edit,
      icon: { name: 'far fa-edit' },
      behavior: ActionBehavior.Emit,
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: ActionMode.Delete,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      behavior: ActionBehavior.Emit,
      popconfirmTitle: POP_CONFIRM_DELETE_ACTION.popconfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    if (item.isUpdatable && action.name === ActionMode.Edit) {
      return true;
    }

    if (item.isDeletable && action.name === ActionMode.Delete) {
      return true;
    }
  },
  handleorderableFn: (data, item) => {
    if (item.isUpdatable) {
      return true;
    }
  }
};
