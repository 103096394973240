import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[rappiderLazyRender]'
})
export class LazyRenderDirective {
  private observer: IntersectionObserver;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
    });
  }

  ngOnInit() {
    const element = this.viewContainer.createEmbeddedView(this.templateRef).rootNodes[0];
    if (element && element.nodeType === 1) {  // Make sure it's an actual element node
      this.observer.observe(element);
    }
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
