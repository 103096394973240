import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService } from '@rappider/services';
import { ProjectRequirementControllerService } from '@rappider/rappider-sdk';

import * as ProjectRequirementsActions from './project-requirements.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

@Injectable()
export class ProjectRequirementsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private projectRequirementApi: ProjectRequirementControllerService,
    private notificationService: NotificationService
  ) { }

  getProjectRequirements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectRequirementsActions.GetProjectRequirements
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        const filter = {
          where: {
            projectId: activeProjectId
          }
        };

        return this.projectRequirementApi.find({ filter }).pipe(
          map((projectRequirements) => ProjectRequirementsActions.GetProjectRequirementsSuccessful({ payload: { projectRequirements } })),
          catchError(error => {
            this.notificationService.createNotification(
              'error',
              'SHARED.COULDNT_LOAD',
              'SHARED.COULDNT_LOAD'
            );

            return [
              ProjectRequirementsActions.ErrorAction({
                payload: {
                  error: error,
                  key: 'GetProjectRequirements',
                  timestamp: Date.now()
                }
              })
            ];
          })
        );
      })
    )
  );

  createProjectRequirement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectRequirementsActions.CreateProjectRequirement
      ),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id),
        this.store.select(state => state.projectRequirements?.data)
      ),
      mergeMap(([action, activeProjectId, projectRequirements]) => {
        const projectRequirement = {
          ...action.payload.projectRequirement,
          projectId: activeProjectId
        };
        return this.projectRequirementApi.create({ body: projectRequirement }).pipe(
          map(projectRequirement => ProjectRequirementsActions.CreateProjectRequirementSuccessful({ payload: { projectRequirement } })),
          catchError(error => {
            this.notificationService.createNotification(
              'error',
              'SHARED.COULDNT_CREATED',
              'SHARED.COULDNT_CREATED'
            );

            return [
              ProjectRequirementsActions.ErrorAction({
                payload: {
                  error: error,
                  key: 'CreateProjectRequirement',
                  timestamp: Date.now()
                }
              })
            ];
          })
        );
      })
    )
  );

}
