/* Angular */
import { Action, createReducer, on } from '@ngrx/store';

/* Model Interface */
import { ProjectSourceCode, ProjectSourceFile } from './project-source-code.interfaces';

/* Actions */
import * as ProjectSourceCodeActions from './project-source-code.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

import { CustomCode } from '@rappider/rappider-sdk';

/* State key */
export const featureKey = 'projectSourceCode';

/* State interface */
export interface State {
  data: ProjectSourceCode[];
  activeProjectFileSourceCode: ProjectSourceCode | null;
  activeProjectFileCustomCodes: CustomCode[] | null;
  projectSourceFiles: ProjectSourceFile[] | null;
  flattenedSourceFiles: ProjectSourceFile[] | null;
  animatedSourceFiles: string[] | null;
  isLoading: boolean;
  error: any;
  isUpsertProcessLoading: boolean;
  isDeleteProcessLoading: boolean;
  isProjectDownloadLoading: boolean;
  isProjectGenerateCodeLoading: boolean;
  isProjectViewCodeLoading: boolean;
}

/* Initial values */
export const initialState: State = {
  data: [],
  activeProjectFileSourceCode: null,
  activeProjectFileCustomCodes: null,
  projectSourceFiles: null,
  flattenedSourceFiles: null,
  animatedSourceFiles: null,
  isLoading: false,
  error: null,
  isUpsertProcessLoading: false,
  isDeleteProcessLoading: false,
  isProjectDownloadLoading: false,
  isProjectGenerateCodeLoading: false,
  isProjectViewCodeLoading: false
};

export const getProjectSourceCode = (state: State, updatingSourceCode: ProjectSourceCode): ProjectSourceCode[] => {
  // filter the data to get the project source code that is being updated
  const filteredProjectSourceCodes: ProjectSourceCode[] = state.data?.filter(d => d.key !== updatingSourceCode.key);
  const updatedProjectSourceCodes: ProjectSourceCode[] = [
    ...filteredProjectSourceCodes,
    updatingSourceCode
  ];
  return updatedProjectSourceCodes;
};

export const _reducer = createReducer(
  initialState,
  on(ProjectSourceCodeActions.GetProjectSourceCode, (state, action) => ({
    ...state,
    isLoading: true,
    isProjectViewCodeLoading: true
  })),
  on(ProjectSourceCodeActions.GetProjectSourceCodeSuccessful, (state, action) => ({
    ...state,
    data: getProjectSourceCode(state, action.payload.projectSourceCode),
    activeProjectFileSourceCode: action.payload.projectSourceCode,
    isLoading: false,
    isProjectViewCodeLoading: false
  })),
  on(ProjectSourceCodeActions.GetProjectSourceFileCustomCodesSuccessful, (state, action) => ({
    ...state,
    activeProjectFileCustomCodes: action.payload.customCodes,
    isLoading: false
  })),
  on(ProjectSourceCodeActions.GetProjectSourceCodeFailure, (state, action) => ({
    ...state,
    isLoading: false,
    isProjectViewCodeLoading: false
  })),
  on(ProjectSourceCodeActions.GetProjectSourceFiles, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(ProjectSourceCodeActions.GetProjectSourceFilesSuccessful, (state, action) => ({
    ...state,
    projectSourceFiles: action.payload.projectSourceFiles,
    flattenedSourceFiles: action.payload.flattenedSourceFiles || [],
    isLoading: false
  })),
  on(ProjectSourceCodeActions.GetProjectSourceFilesFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),
  on(ProjectSourceCodeActions.UpsertProjectSourceFileCustomCodes, state => ({
    ...state,
    isUpsertProcessLoading: true
  })),
  on(ProjectSourceCodeActions.UpsertProjectSourceFileCustomCodesSuccessful, (state, action) => ({
    ...state,
    isUpsertProcessLoading: false,
  })),
  on(ProjectSourceCodeActions.UpsertProjectSourceFileCustomCodesFailure, state => ({
    ...state,
    isUpsertProcessLoading: false
  })),
  on(ProjectSourceCodeActions.DeleteProjectSourceFileCustomCodes, state => ({
    ...state,
    isDeleteProcessLoading: true
  })),
  on(ProjectSourceCodeActions.DeleteProjectSourceFileCustomCodesSuccessful, (state, action) => ({
    ...state,
    isDeleteProcessLoading: false
  })),
  on(ProjectSourceCodeActions.DeleteProjectSourceFileCustomCodesFailure, state => ({
    ...state,
    isDeleteProcessLoading: false
  })),
  on(ProjectSourceCodeActions.GenerateCode, (state) => ({
    ...state,
    isProjectGenerateCodeLoading: true
  })),
  on(ProjectSourceCodeActions.GenerateCodeSuccessful, (state) => ({
    ...state,
    isProjectGenerateCodeLoading: false
  })),
  on(ProjectSourceCodeActions.GenerateCodeFailure, (state) => ({
    ...state,
    isProjectGenerateCodeLoading: false
  })),
  on(ProjectSourceCodeActions.DownloadProjectSourceFile, (state) => ({
    ...state,
    isProjectDownloadLoading: true
  })),
  on(ProjectSourceCodeActions.DownloadProjectSourceFileSuccesful, (state) => ({
    ...state,
    isProjectDownloadLoading: false
  })),
  on(ProjectSourceCodeActions.DownloadProjectSourceFileFailure, (state) => ({
    ...state,
    isProjectDownloadLoading: false
  })),
  on(ProjectSourceCodeActions.ErrorAction, (state, action) => ({
    ...state,
    isLoading: false
  })),
  on(ProjectSourceCodeActions.ResetStateToInitial, () => initialState)
);

// combine created reducer with class-based actions
export function reducer(state: State | undefined, action: Action): State {
  const newState = _reducer(state, action);

  switch (action.type) {
    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful:
      return initialState;

    default:
      return newState;
  }
}
