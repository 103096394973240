import { Validators } from '@angular/forms';
import { TITLE_CASE_DATA_FIELD_NAME_REGEX } from '@rappider/shared/definitions';
import { ButtonType, CrudFormConfig, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { DataSchemaTypes } from '../data-schema/models/data-schema-type.enum';


export const DATA_SCHEMA_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.pattern(TITLE_CASE_DATA_FIELD_NAME_REGEX),
          errorKey: 'pattern',
          errorMessage: 'PROJECT_MODULE.DATA_SCHEMA_MODULE.DATA_SCHEMA_ELEMENT_ROW_COMPONENT.NAME_NOT_VALID'
        },
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'SHARED.REQUIRED'
        }
      ]
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewFormItemType.TextArea,
    }
  ],
  submitButton: {
    type: ButtonType.Primary
  }
};

export const DATA_SCHEMA_TYPE_SELECT_CONFIG = {
  type: CrudViewFormItemType.Select,
  fieldName: 'type',
  title: 'SHARED.TYPE',
  options: [
    {
      key: 'Model',
      value: DataSchemaTypes.Model
    },
    {
      key: 'Enum',
      value: DataSchemaTypes.Enum
    }
  ],
  validators: [
    {
      type: Validators.required,
      errorKey: 'required',
      errorMessage: 'SHARED.REQUIRED'
    }
  ]
};
