import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* 3rd Part Modules */
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

/* Internal Modules */
import { SharedModule } from '@rappider/shared';
import { UIDataStoreFieldModule } from './modules/ui-data-store-field/ui-data-store-field.module';
import { UiDataStoreModule } from './modules/ui-data-store/ui-data-store.module';
import { DataSchemaModule } from './modules/data-schema/data-schema.module';
import { ProjectModelRelationModule } from './modules/project-model-relation/project-model-relation.module';
import { CustomEndpointModule } from './modules/custom-endpoint/custom-endpoint.module';
import { WorkflowEventModule } from '@rappider/workflow-event';
import {
  RappiderEditFormModule,
  RappiderTitleModule,
  RappiderListGridModule,
  RappiderButtonModule,
  RappiderMenuModule,
  RappiderUploadFileModule,
  RappiderSelectModule,
  RappiderInputLabelModule,
  RappiderCodeEditorModule,
  RappiderDividerModule,
  RappiderRadioModule,
  RappiderTextboxModule,
  RappiderLabelModule,
  RappiderTitleToolbarModule,
  RappiderLottiePlayerModule,
  RappiderPageWrapperModule,
  RappiderCodemirrorInputFormModule,
  RappiderCardCcsModule,
  RappiderTextModule,
  RappiderDropdownMenuModule,
  RappiderCrudDetailModule,
  RappiderIconModule,
  RappiderModalModule,
  RappiderAlertModule,
  RappiderInputTemplateModule,
  RappiderHeadingModule,
  RappiderParagraphModule,
  RappiderCardFourModule,
  RappiderReviewResultsModule,
  RappiderCardTenModule,
  RappiderProgressOneModule,
  RappiderSpinModule,
  RappiderCardOneModule,
  RappiderProgressModule,
  RappiderTimelineModule,
  RappiderCardsModule,
  RappiderInputGroupModule,
  RappiderImageModule
} from '@rappider/rappider-components';
/* Components */
import { ProjectCreateComponent } from './components/project-create/project-create.component';
import { ProjectCreatePreviewComponent } from './components/project-create-preview/project-create-preview.component';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectEditComponent } from './components/project-edit/project-edit.component';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { ProjectMembersComponent } from './components/project-members/project-members.component';
import { MessageTemplateComponent } from './components/message-template/message-template.component';
import { MessageTemplateListComponent } from './components/message-template-list/message-template-list.component';
import { EditMessageTemplateComponent } from './components/edit-message-template/edit-message-template.component';
import { ProjectRolesComponent } from './components/project-roles/project-roles.component';
import { ProjectMemberListComponent } from './components/project-member-list/project-member-list.component';
import { ProjectExportComponent } from './components/project-export/project-export.component';
import { ProjectVersionListComponent } from './components/project-version-list/project-version-list.component';
import { AddProjectScriptComponent } from './components/add-project-script/add-project-script.component';
import { FileListComponent } from './components/add-project-script/file-list/file-list.component';
import { ProjectScriptListComponent } from './components/project-script-list/project-script-list.component';
import { ProjectVersionCreateComponent } from './components/project-version-create/project-version-create.component';
import { UserInvitationsComponent } from './components/user-invitations/user-invitations.component';
import {
  ProjectModelDataFieldCreateComponent
} from './components/project-model-data-field-create/project-model-data-field-create.component';
import { DataFieldListComponent } from './components/data-field-list/data-field-list.component';
import { DataFieldEditComponent } from './components/data-field-edit/data-field-edit.component';
import { ProjectModelListComponent } from './components/project-model-list/project-model-list.component';
import { ProjectModelCreateComponent } from './components/project-model-create/project-model-create.component';
import { ProjectModelEditComponent } from './components/project-model-edit/project-model-edit.component';
import { ProjectVersionDetailComponent } from './components/project-version-detail/project-version-detail.component';
import { ProjectScriptEditComponent } from './components/project-script-edit/project-script-edit.component';
import { ProjectPackageCreateComponent } from './components/project-package-create/project-package-create.component';
import { ProjectPackageCreateModalComponent } from './components/project-package-create-modal/project-package-create-modal.component';
import { ProjectPackageEditComponent } from './components/project-package-edit/project-package-edit.component';
import { ProjectPackageListComponent } from './components/project-package-list/project-package-list.component';
import { UIDataUpdateFunctionModule } from './modules/ui-data-update-function/ui-data-update-function.module';
import { MemberRolesComponent } from './components/project-members/member-roles/member-roles.component';
import { CustomFunctionModule } from './modules/custom-function/custom-function.module';
import {
  ProjectCssStyleCustomizationComponent
} from './components/project-css-style-customization/project-css-style-customization.component';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';
import { ProjectExportDetailsComponent } from './components/project-export-details/project-export-details.component';
import { RappiderOpenapiModule } from './modules/openapi/openapi.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { RappiderProjectRequirementsModule } from './modules/project-requirements/project-requirements.module';
import { RappiderProjectDocumentModule } from './modules/project-document/project-document.module';
import { ProjectTestMembersComponent } from './components/project-test-members/project-test-members.component';
import { TestUsersModalsComponent } from './components/project-test-members/test-users-modals/test-users-modals.component';

import { LivePreviewComponent } from './components/live-preview/live-preview.component';
import { SafeUrlPipe } from 'libs/shared/src/lib/pipes/safe-url/safe-url.pipe';
import { RappiderProgressStepsModule } from 'libs/components/src/lib/components/progress-steps/progress-steps.module';
import { ProjectMembersWrapperComponent } from './components/project-members-wrapper/project-members-wrapper.component';


@NgModule({
  imports: [
    CommonModule,
    NzSkeletonModule,
    RappiderModalModule,
    NzFormModule,
    NzTabsModule,
    NzCardModule,
    NzCheckboxModule,
    NzDividerModule,
    NzButtonModule,
    NzDrawerModule,
    NzDropDownModule,
    NzIconModule,
    NzPopoverModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    UIDataStoreFieldModule,
    UiDataStoreModule,
    DataSchemaModule,
    UIDataStoreFieldModule,
    UIDataUpdateFunctionModule,
    ProjectModelRelationModule,
    CustomFunctionModule,
    CustomEndpointModule,
    WorkflowEventModule,
    RappiderEditFormModule,
    RappiderTitleModule,
    RappiderListGridModule,
    RappiderButtonModule,
    RappiderMenuModule,
    RappiderUploadFileModule,
    RappiderSelectModule,
    RappiderInputLabelModule,
    RappiderCodeEditorModule,
    RappiderDividerModule,
    RappiderRadioModule,
    RappiderTextboxModule,
    RappiderLabelModule,
    RappiderTitleToolbarModule,
    RappiderLottiePlayerModule,
    RappiderPageWrapperModule,
    RappiderCodemirrorInputFormModule,
    RappiderCardCcsModule,
    RappiderTextModule,
    RappiderDropdownMenuModule,
    NzPopconfirmModule,
    NzSelectModule,
    RappiderIconModule,
    RappiderCrudDetailModule,
    RappiderAlertModule,
    RappiderInputTemplateModule,
    RappiderHeadingModule,
    RappiderParagraphModule,
    RappiderCardFourModule,
    RappiderReviewResultsModule,
    RappiderCardTenModule,
    RappiderProgressOneModule,
    NzToolTipModule,
    RappiderSpinModule,
    NzCollapseModule,
    RappiderCardOneModule,
    RappiderOpenapiModule,
    NzModalModule,
    RappiderProjectRequirementsModule,
    RappiderProjectDocumentModule,
    RappiderProgressModule,
    RappiderTimelineModule,
    RappiderCardsModule,
    RappiderInputGroupModule,
    RappiderImageModule,
    UserInvitationsComponent,
    NzDescriptionsModule,
    NzBadgeModule,
    RappiderProgressStepsModule
  ],
  providers: [
    FormatDatePipe,
    SafeUrlPipe
  ],
  declarations: [
    ProjectCreateComponent,
    ProjectCreatePreviewComponent,
    ProjectDetailComponent,
    ProjectEditComponent,
    ProjectListComponent,
    ProjectMembersComponent,
    ProjectMembersWrapperComponent,
    MessageTemplateComponent,
    MessageTemplateListComponent,
    EditMessageTemplateComponent,
    ProjectRolesComponent,
    ProjectMemberListComponent,
    ProjectExportComponent,
    ProjectVersionListComponent,
    AddProjectScriptComponent,
    FileListComponent,
    ProjectScriptListComponent,
    ProjectVersionCreateComponent,
    ProjectModelDataFieldCreateComponent,
    DataFieldListComponent,
    DataFieldEditComponent,
    ProjectModelListComponent,
    ProjectModelCreateComponent,
    ProjectModelEditComponent,
    ProjectVersionDetailComponent,
    ProjectPackageCreateComponent,
    ProjectPackageCreateModalComponent,
    ProjectPackageEditComponent,
    ProjectPackageListComponent,
    MemberRolesComponent,
    ProjectScriptEditComponent,
    ProjectCssStyleCustomizationComponent,
    ProjectExportDetailsComponent,
    ProjectTestMembersComponent,
    TestUsersModalsComponent,
    LivePreviewComponent
  ],
  exports: [
    ProjectCssStyleCustomizationComponent,
    ProjectVersionDetailComponent,
    ProjectCreateComponent,
    ProjectCreatePreviewComponent,
    ProjectDetailComponent,
    ProjectEditComponent,
    ProjectListComponent,
    ProjectMembersComponent,
    ProjectMembersWrapperComponent,
    MessageTemplateComponent,
    MessageTemplateListComponent,
    EditMessageTemplateComponent,
    ProjectRolesComponent,
    ProjectMemberListComponent,
    ProjectExportComponent,
    ProjectVersionListComponent,
    AddProjectScriptComponent,
    FileListComponent,
    ProjectScriptListComponent,
    ProjectVersionCreateComponent,
    ProjectModelDataFieldCreateComponent,
    DataFieldListComponent,
    DataFieldEditComponent,
    ProjectModelListComponent,
    ProjectModelCreateComponent,
    ProjectModelEditComponent,
    ProjectPackageCreateComponent,
    ProjectPackageCreateModalComponent,
    ProjectPackageEditComponent,
    ProjectPackageListComponent,
    MemberRolesComponent,
    ProjectScriptEditComponent,
    ProjectExportDetailsComponent,
    ProjectTestMembersComponent,
    TestUsersModalsComponent,
    LivePreviewComponent
  ]
})
export class ProjectComponentsModule { }
