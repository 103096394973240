import { createSelector } from '@ngrx/store';
import { Component, ComponentDefinition, PageWithRelations } from '@rappider/rappider-sdk';
import { ContentTree } from 'libs/content-tree-renderer/src/lib/models';
import { mergeComponentsAndDefinitionsToContentTree } from '../state/content-editor-helper-functions';
import { getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';

export const getActiveLayoutForPage = createSelector(
  state => state['contentEditor'].page?.layoutId,
  state => state['layout'].data,
  getComponentDefinitionsWithDetailsSelector,
  (layoutId: string, layouts: PageWithRelations[], componentDefinitions: ComponentDefinition[]) => {
    if (layouts?.length && layoutId && componentDefinitions?.length) {
      const activeLayout = layouts.find(layout => layout.id === layoutId);
      /* if the layout has no component, return just contentTree */
      if (activeLayout?.components?.length && componentDefinitions?.length) {
        return {
          ...activeLayout,
          contentTree: mergeComponentsAndDefinitionsToContentTree(activeLayout.contentTree[0], componentDefinitions, activeLayout.components)
        };
      } else {
        return activeLayout;
      }
    }
  }
);
