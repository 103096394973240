<rappider-modal [(visible)]="deletePageContainerModalVisibility"
                [title]="'CONTENT_EDITOR_MODULE.DELETE_PAGE_CONTAINER_COMPONENT.DELETE_CONTAINER' | translate"
                [footer]="footerTemplate"
                width="40%"
                (visibleChange)="onModalVisibilityChange($event)">
  {{'CONTENT_EDITOR_MODULE.DELETE_PAGE_CONTAINER_COMPONENT.DELETE_CONTAINER_MODAL_CONTENT' | translate}}
</rappider-modal>

<ng-template #footerTemplate>
  <div class="modal-footer-wrapper">
    <rappider-button class="modal-footer-button"
                     text="Delete All"
                     colorType="danger"
                     type="dashed"
                     size="small"
                     [block]="true"
                     (click)="this.deleteContainer(ContainerRemoveStrategy.All)">
    </rappider-button>
    <rappider-button class="modal-footer-button"
                     text="Move children to parent container"
                     colorType="danger"
                     type="dashed"
                     size="small"
                     [block]="true"
                     (click)="this.deleteContainer(ContainerRemoveStrategy.MoveChildrenToParent)">
    </rappider-button>
    <rappider-button class="modal-footer-button"
                     text="Remove only children (Keep the container)"
                     colorType="danger"
                     type="dashed"
                     size="small"
                     [block]="true"
                     (click)="this.deleteContainer(ContainerRemoveStrategy.OnlyChildren)">
    </rappider-button>
    <rappider-button class="modal-footer-button"
                     [text]="'SHARED.CANCEL'"
                     [block]="true"
                     size="small"
                     (click)="this.closeDeletePageContainerModal()">
    </rappider-button>
  </div>
</ng-template>
