<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [titleBarActionButtons]="titleBarActionButtons"
                        [titleBarSwitchSettings]="powerSwitchConfig"
                        [isSwitchVisible]="displayedBuild?.status === DeployStatus.Successful"
                        (switchToggled)="onSwitchToggled($event)"
                        (titleBarActionButtonClick)="onTitleBarActionButtonClick($event)"></rappider-title-toolbar>

<ng-template #iframeTemplate>
  <ng-container *ngIf="displayedBuild?.status === DeployStatus.Successful
                || displayedBuild?.status === DeployStatus.Successful">
    <div class="live-preview-iframe">
      <iframe [src]="frontendURL | safeUrl"
              [ngClass]="!isDeploymentLogVisible ? 'full-witdh-preview-area' : ''"
              class="live-preview"
              #iframe
              scrolling="yes"
              frameborder="0"
              (load)="onIFrameContentLoaded()"></iframe>
    </div>
  </ng-container>
</ng-template>

<div class="live-preview-wrapper">
  <ng-container [ngSwitch]="displayedBuild?.status">
    <div class="live-preview-content-area">

      <ng-container *ngSwitchCase="DeployStatus.InProgress">
        <div *ngIf="displayedBuild?.status === DeployStatus.InProgress"
             class="animation live-preview-in-progress-content-area">
          <rappider-alert class="live-preview-fail-info-alert"
                          [showIcon]="true"
                          type="info"
                          title="Deployment in Progress"
                          description="The deployment is in progress. Please wait a moment while it completes."></rappider-alert>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="DeployStatus.NotStarted">
        <div *ngIf="displayedBuild?.status === DeployStatus.NotStarted"
             class="animation live-preview-in-progress-content-area">
          <rappider-alert class="live-preview-fail-info-alert"
                          [showIcon]="true"
                          type="info"
                          title="Deployment Not Started"
                          description="The deployment process has not been initiated. Please check the required steps and try again."></rappider-alert>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="DeployStatus.Queued">
        <div *ngIf="displayedBuild?.status === DeployStatus.Queued"
             class="animation live-preview-in-progress-content-area">
          <rappider-alert class="live-preview-fail-info-alert"
                          [showIcon]="true"
                          type="info"
                          title="Waiting in Queue"
                          description="You are currently in the queue. Your request will be processed as soon as it reaches the front."></rappider-alert>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="DeployStatus.Failure">
        <rappider-alert class="live-preview-fail-info-alert"
                        [showIcon]="true"
                        type="info"
                        title="Deploy Failed"
                        description="An error occurred during the deploy process. Please try again. If the issue persists, please contact the support team for assistance."></rappider-alert>
      </ng-container>

      <ng-container [ngTemplateOutlet]="iframeTemplate"></ng-container>
    </div>
  </ng-container>

  <section class="log-area"
           *ngIf="isDeploymentLogVisible">
    <ng-container [ngTemplateOutlet]="deploymentInfoTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      build: displayedBuild,
                      timestamp: displayedBuild?.end_time
                    }
                  }"></ng-container>
    <!-- SET LATEST_BUILD AS 'build' VARIABLE IN TEMPLATE -->
    <ng-template #deploymentInfoTemplate
                 let-build="data.build"
                 let-timestamp="data.timestamp">
      <div class="deployment-log-body">
        <div class="log-tabs">
          <nz-tabset class="tabset-overflow"
                     nzSize="small">
            <nz-tab nzTitle="Build Info">
              <div class="deploy-time-and-version">
                <div class="build-info-item">
                  <strong>
                    Status:
                  </strong>
                  <div class="icon-text">
                    <div>
                      {{setDeployStatusIcon(build?.status).text}}
                    </div>
                    <rappider-icon [name]="setDeployStatusIcon(build?.status).icon"></rappider-icon>
                  </div>
                </div>

                <div class="build-info-item">
                  <strong>
                    Version:
                  </strong>
                  {{build?.version_branch ?? '-'}}
                </div>

                <div class="build-info-item">
                  <strong>
                    Started at:
                  </strong>
                  {{ build?.start_time ? moment(build?.start_time).calendar() : '-' }}
                </div>

                <div class="build-info-item">
                  <strong>
                    Ended at:
                  </strong>
                  {{ build?.end_time ? moment(build?.end_time).calendar() : '-' }}
                </div>

                <div class="build-info-item"
                     *ngIf="build?.index_created_date">
                  <strong>
                    Files Deployed At:
                  </strong>
                  {{ build?.index_created_date ? (build?.index_created_date | formatDate) : '-' }}
                </div>

                <div class="build-steps">
                  <strong>
                    Build & Deploy Steps
                  </strong>
                  <hr class="build-steps-ruler">
                  <rappider-timeline *ngIf="timelineItems?.length"
                                     [items]="timelineItems"
                                     [reverseTreeItem]="true"></rappider-timeline>
                  <div *ngIf="!timelineItems?.length && !(displayedBuild?.status === DeployStatus.NotStarted)">
                    Loading <i class="fa-duotone fa-gear fa-spin"></i>
                  </div>
                  <div *ngIf="!timelineItems?.length && displayedBuild?.status === DeployStatus.NotStarted">
                    Not Started <i class="fa-regular fa-hexagon-exclamation"></i>
                  </div>
                </div>
              </div>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>
    </ng-template>
  </section>
</div>
