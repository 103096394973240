<rappider-title-toolbar [mainTitle]="mainTitle"
                        [displayToolbar]="false"
                        [displayToolbar]="displayToolbar"
                        [titleBarActionButtons]="[addNewProjectButton]"
                        [displayToolbarBackButton]="displayToolbarBackButton"
                        (titleBarActionButtonClick)="onActionButtonClick($event)"
                        class="project-list-title-toolbar">
</rappider-title-toolbar>

<!-- Custom toolbar -->
<section class="filter-and-create-project-area">
  <div class="cards-search-container">
    <rappider-input-group [(ngModel)]="cardListSearchText"
                          [textbox]="cardSearchInputConfig.textbox"
                          [suffixIcon]="cardSearchInputConfig.suffixIcon"></rappider-input-group>
  </div>

  <nz-select class="filter-project-select"
             [(ngModel)]="selectedCategoryName"
             [nzMaxTagCount]="0"
             [nzPlaceHolder]="colSelectPlaceHolder"
             [nzMaxTagPlaceholder]="colSelectPlaceHolder"
             nzMode="default"
             (ngModelChange)="onProjectFilterChange()">
    <nz-option *ngFor="let filter of ProjectFilterOptions"
               [nzLabel]="filter.key | translate"
               [nzValue]="filter.value"></nz-option>
  </nz-select>
  <ng-template #colSelectPlaceHolder
               let-selectedList>
    <div class="filter-project-placeholder">
      <div>
        <i class="far fa-line-columns"></i>
      </div>
      <div>
        &nbsp;{{'Filter Project'|translate}}
      </div>
    </div>
  </ng-template>
</section>

<section>
  <rappider-spin [spinning]="isLoading || loadingActiveProjectId || !isLoaded">
    <div *ngIf="displayedProjects?.length; else noProjectTmpl">
      <div *ngIf="filteredProjects?.length; else noFilteredProjectTmpl"
           class="cards-container row">
        <div *ngFor="let project of filteredProjects; let i = index"
             class="project-card col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <rappider-card-ccs [data]="project"
                             [image]="getProjectListCardConfig(project, i).image"
                             [title]="getProjectListCardConfig(project, i).title"
                             [subtitles]="[{
                              content: (('created by' | translate) + ' ' + project.createdBy +
                              '<br> on ' + (project.createdDate | date:'longDate')),
                              type: 'h6'
                             }]"
                             [description]="project.description"
                             [secondActionButton]="getSecondActionButton(project)"
                             [thirdActionButton]="projectListWithCard.thirdActionButton"
                             (actionClick)="onProjectAction($event, project)"
                             (itemSelect)="onActiveProjectSelect(project)">
          </rappider-card-ccs>
        </div>
      </div>
    </div>
  </rappider-spin>
</section>

<ng-template #noFilteredProjectTmpl>
  <div *ngIf="isLoaded">
    <rappider-alert [type]="projectListAlertConfigBySearch.type"
                    [title]="projectListAlertConfigBySearch.title"
                    [closeable]="projectListAlertConfigBySearch.closeable"
                    [showIcon]="projectListAlertConfigBySearch.showIcon"></rappider-alert>
  </div>
</ng-template>

<ng-template #noProjectTmpl>
  <div *ngIf="!isStarredProjectsSelected && isLoaded; else starredProjectsSelected"
       class="no-project-wrapper">
    <div class="no-project"
         [class.disabled]="!person?.isVerified"
         [nz-tooltip]="getTooltipText() | translate"
         (click)="createProject()">
      <rappider-lottie-player [options]="lottieConfig.options"
                              [height]="lottieConfig.height"></rappider-lottie-player>
      <rappider-text [text]="'PROJECT_MODULE.PROJECT_LIST_COMPONENT.NO_PROJECT' | translate"></rappider-text>
    </div>
  </div>
</ng-template>

<ng-template #starredProjectsSelected>
  <div *ngIf="isLoaded">
    <rappider-alert [type]="projectListAlertConfig.type"
                    [title]="projectListAlertConfig.title"
                    [closeable]="projectListAlertConfig.closeable"
                    [showIcon]="projectListAlertConfig.showIcon"></rappider-alert>
  </div>
</ng-template>
