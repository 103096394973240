import { ProjectModelRelation } from '@rappider/rappider-sdk';
import * as ProjectModelRelationActions from './project-model-relation.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as ProjectModelActions from '../project-model-state/project-model.actions';

export const projectModelRelationFeatureKey = 'projectModelRelation';

export interface ProjectModelRelationState {
  data: ProjectModelRelation[];
  loading: boolean;
  lastAction: {
    name: string;
    payload: any;
  };
  error: {
    error?: any;
    timestamp?: number;
    key?: string;
  };
}

export const projectModelRelationInitialState: ProjectModelRelationState = {
  data: [],
  loading: true,
  error: null,
  lastAction: null
};

export function projectModelRelationReducer(
  state: ProjectModelRelationState = projectModelRelationInitialState,
  action: ProjectModelRelationActions.Actions | AuthenticationActions.Actions | ProjectModelActions.Actions
): ProjectModelRelationState {
  switch (action.type) {
    case ProjectModelRelationActions.ActionTypes.GetAllProjectModelRelations:
      return {
        ...state,
        loading: true
      };
    case ProjectModelRelationActions.ActionTypes.GetAllProjectModelRelationsSuccessful:
      return {
        ...state,
        data: action.payload.projectModelRelations ? [...action.payload.projectModelRelations] : [],
        loading: false
      };
    case ProjectModelRelationActions.ActionTypes.GetProjectModelRelations:
      return {
        ...state,
        loading: true
      };
    case ProjectModelRelationActions.ActionTypes.GetProjectModelRelationsSuccessful:
      return {
        ...state,
        data: action.payload.projectModelRelations,
        loading: false
      };
    case ProjectModelRelationActions.ActionTypes.GetProjectModelRelationsFailure:
      return {
        ...state,
        loading: false
      };

    case ProjectModelRelationActions.ActionTypes.CreateProjectModelRelation:
      return {
        ...state,
        loading: true,
        lastAction: {
          name: action.type,
          payload: action.payload
        }
      };
    case ProjectModelRelationActions.ActionTypes.CreateProjectModelRelationSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload.projectModelRelations
        ],
        loading: false,
        lastAction: {
          name: action.type,
          payload: action.payload
        }
      };
    case ProjectModelRelationActions.ActionTypes.CreateProjectModelRelationFailure:
      return {
        ...state,
        loading: false,
        lastAction: {
          name: action.type,
          payload: null
        }
      };

    case ProjectModelRelationActions.ActionTypes.UpdateProjectModelRelation:
      return {
        ...state,
        loading: true
      };
    case ProjectModelRelationActions.ActionTypes.UpdateProjectModelRelationSuccessful:
      {
        const updatedRelation = {
          ...state.data.find(projectModelRelation => projectModelRelation.id !== action.payload.projectModelRelationId),
          ...action.payload.projectModelRelation
        };
        return {
          ...state,
          data: [
            ...state.data.filter(projectModelRelation => projectModelRelation.id !== action.payload.projectModelRelationId),
            updatedRelation
          ],
          loading: false
        };
      }
    case ProjectModelRelationActions.ActionTypes.UpdateProjectModelRelationFailure:
      return {
        ...state,
        loading: false
      };

    case ProjectModelRelationActions.ActionTypes.DeleteProjectModelRelation:
      return {
        ...state,
        loading: true
      };
    case ProjectModelRelationActions.ActionTypes.DeleteProjectModelRelationSuccessful:
      // eslint-disable-next-line no-case-declarations
      const newData = state.data.filter(projectModelRelation => projectModelRelation.id !== action.payload.projectModelRelationId);
      return {
        ...state,
        data: newData,
        loading: false
      };
    case ProjectModelActions.ActionTypes.GetProjectModelsSuccessful:
      return {
        ...state,
        loading: true
      };
    case ProjectModelRelationActions.ActionTypes.DeleteProjectModelRelationFailure:
      return {
        ...state,
        loading: false
      };
    case ProjectModelRelationActions.ActionTypes.ErrorAction:
      return {
        ...state,
        error: {
          ...state.error,
          error: action.payload.error,
          timestamp: action.payload.timestamp,
          key: action.payload.key
        },
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return projectModelRelationInitialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return projectModelRelationInitialState;

    default:
      return state;
  }
}
