import { PAGE_DEFINITIONS } from './page-definition';
import { environment } from '../../../../../apps/rappider/src/environments/environment';

export const PATH_DEFINITIONS = {
  ADMIN_DASHBOARD: {
    PROJECTS: `/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL}/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.CHILDREN.PROJECTS.URL}`,
    ROLES: `/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL}/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.CHILDREN.ROLES.URL}`,
    PROJECT_DETAIL: `/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL}/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.CHILDREN.PROJECT_DETAIL.URL}`,
    PERMISSIONS_CRUD: `/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL}/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.CHILDREN.PERMISSIONS_CRUD.URL}`,
    ROLES_CRUD: `/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL}/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.CHILDREN.ROLES_CRUD.URL}`,
    PROJECT_EDIT: `/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL}/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.CHILDREN.PROJECT_EDIT.URL}`,
    PERSON_MANAGEMENT: `/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.URL}/${PAGE_DEFINITIONS.ADMIN_DASHBOARD.CHILDREN.PERSON_MANAGEMENT.URL}`,
  },
  AI: {
    ASSISTANT: `/${PAGE_DEFINITIONS.AI.URL}/${PAGE_DEFINITIONS.AI.CHILDREN.ASSISTANT.URL}`
  },
  CATEGORIES: {
    CATEGORY_LIST: `/${PAGE_DEFINITIONS.CATEGORIES.URL}/${PAGE_DEFINITIONS.CATEGORIES.CHILDREN.CATEGORY_LIST.URL}`,
    CATEGORY_EDIT: `/${PAGE_DEFINITIONS.CATEGORIES.URL}/${PAGE_DEFINITIONS.CATEGORIES.CHILDREN.CATEGORY_EDIT.URL}`,
    CATEGORY_CREATE: `/${PAGE_DEFINITIONS.CATEGORIES.URL}/${PAGE_DEFINITIONS.CATEGORIES.CHILDREN.CATEGORY_CREATE.URL}`
  },
  COMPONENT_BROWSER: {
    COMPONENT_BROWSER_ELEMENTS_PATH: `/${PAGE_DEFINITIONS.COMPONENT_BROWSER.URL}/${PAGE_DEFINITIONS.COMPONENT_BROWSER.CHILDREN.ELEMENT.URL}`,
    COMPONENT_BROWSER_CATEGORY_PATH: `/${PAGE_DEFINITIONS.COMPONENT_BROWSER.URL}/${PAGE_DEFINITIONS.COMPONENT_BROWSER.CHILDREN.CATEGORY.URL}`,
    COMPONENT_SEARCH_RESULT_PATH: `/${PAGE_DEFINITIONS.COMPONENT_BROWSER.URL}/${PAGE_DEFINITIONS.COMPONENT_BROWSER.CHILDREN.COMPONENT_SEARCH_RESULT.URL}`,
    COMPONENT_BROWSER_PATH: `/${PAGE_DEFINITIONS.COMPONENT_BROWSER.URL}`,
    COMPONENT_DEFINITION_DETAIL_PATH: `/${PAGE_DEFINITIONS.COMPONENT_BROWSER.URL}/${PAGE_DEFINITIONS.COMPONENT_BROWSER.CHILDREN.COMPONENT_DEFINITION_DETAIL.URL}`,
    COMPONENT_DEFINITION_ADD_PATH: `${PAGE_DEFINITIONS.COMPONENT_BROWSER.URL}/${PAGE_DEFINITIONS.COMPONENT_BROWSER.CHILDREN.COMPONENT_DEFINITION_CREATE.URL}`,
    COMPONENT_DEFINITION_EDIT_PATH: `${PAGE_DEFINITIONS.COMPONENT_BROWSER.URL}/${PAGE_DEFINITIONS.COMPONENT_BROWSER.CHILDREN.COMPONENT_DEFINITION_EDIT.URL}`,
  },
  DATABASE_DIAGRAM_EDITOR: {
    DATABASE_DIAGRAM_EDITOR_PATH: `/${PAGE_DEFINITIONS.DATABASE_DIAGRAM_EDITOR.URL}`
  },
  ELEMENT_BROWSER: {
    ELEMENT_SEARCH_RESULT_PATH: `/${PAGE_DEFINITIONS.COMPONENT_BROWSER.URL}/${PAGE_DEFINITIONS.ELEMENT_BROWSER.CHILDREN.ELEMENT_SEARCH_RESULT.URL}`
  },
  TEMPLATE_BROWSER: {
    TEMPLATE_BROWSER_LIST_PATH: `/${PAGE_DEFINITIONS.TEMPLATE_BROWSER.URL}/${PAGE_DEFINITIONS.TEMPLATE_BROWSER.CHILDREN.TEMPLATE_LIST.URL}`
  },
  PAGE_TEMPLATES: {
    PAGE_TEMPLATES_LIST_PATH: `/${PAGE_DEFINITIONS.PAGE_TEMPLATES.URL}/${PAGE_DEFINITIONS.PAGE_TEMPLATES.CHILDREN.PAGE_TEMPLATES_LIST.URL}`
  },
  TENANTS: {
    TENANT_CREATE_PATH: `/${PAGE_DEFINITIONS.TENANT.URL}/${PAGE_DEFINITIONS.TENANT.CHILDREN.TENANT_CREATE.URL}`,
    TENANT_DETAIL_PATH: `/${PAGE_DEFINITIONS.TENANT.URL}/${PAGE_DEFINITIONS.TENANT.CHILDREN.TENANT_DETAIL.URL}`,
    TENANT_EDIT_PATH: `/${PAGE_DEFINITIONS.TENANT.URL}/${PAGE_DEFINITIONS.TENANT.CHILDREN.TENANT_EDIT.URL}`,
    TENANT_LIST_PATH: `/${PAGE_DEFINITIONS.TENANT.URL}/${PAGE_DEFINITIONS.TENANT.CHILDREN.TENANT_LIST.URL}`,
  },
  PAGES: {
    PAGE_CREATE_PATH: `/${PAGE_DEFINITIONS.PAGES.URL}/${PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_CREATE.URL}`,
    PAGE_DETAIL_PATH: `/${PAGE_DEFINITIONS.PAGES.URL}/${PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_DETAIL.URL}`,
    PAGE_EDIT_PATH: `/${PAGE_DEFINITIONS.PAGES.URL}/${PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_EDIT.URL}`,
    PAGE_LIST_PATH: `/${PAGE_DEFINITIONS.PAGES.URL}/${PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_LIST.URL}`,
    PAGE_LAYOUT_LIST_PATH: `/${PAGE_DEFINITIONS.PAGES.URL}/${PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_LIST.URL}`,
    PAGE_LAYOUT_CREATE_PATH: `/${PAGE_DEFINITIONS.PAGES.URL}/${PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_CREATE.URL}`,
    PAGE_LAYOUT_EDIT_PATH: `/${PAGE_DEFINITIONS.PAGES.URL}/${PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_EDIT.URL}`,
  },
  MODULE: {
    MODULE_CREATE_PATH: `/${PAGE_DEFINITIONS.MODULE.URL}/${PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_CREATE.URL}`,
    MODULE_DETAIL_PATH: `/${PAGE_DEFINITIONS.MODULE.URL}/${PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_DETAIL.URL}`,
    MODULE_EDIT_PATH: `/${PAGE_DEFINITIONS.MODULE.URL}/${PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_EDIT.URL}`,
    MODULE_LIST_PATH: `/${PAGE_DEFINITIONS.MODULE.URL}/${PAGE_DEFINITIONS.MODULE.CHILDREN.MODULE_LIST.URL}`,
  },
  CONTENT_EDITOR: {
    CONTENT_EDITOR_PATH: `/${PAGE_DEFINITIONS.CONTENT_EDITOR.URL}`,
  },
  PROJECTS: {
    PROJECT_ROOT: PAGE_DEFINITIONS.PROJECTS.URL,
    PROJECT_CREATE_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_CREATE.URL}`,
    PROJECT_DETAIL_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_DETAIL.URL}`,
    PROJECT_EDIT_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_EDIT.URL}`,
    PROJECT_LIST_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_LIST.URL}`,
    PROJECT_LIVE_PREVIEW_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_LIVE_PREVIEW.URL}`,
    PROJECT_SETTINGS_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_SETTINGS.URL}`,
    PROJECT_ENVIRONMENT_VARIABLES: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_ENVIRONMENT_VARIABLES.URL}`,
    PROJECT_MESSAGE_TEMPLATE_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_MESSAGE_TEMPLATE_LIST.URL}`,
    PROJECT_MESSAGE_TEMPLATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_MESSAGE_TEMPLATE.URL}`,
    PROJECT_EDIT_MESSAGE_TEMPLATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_EDIT_MESSAGE_TEMPLATE.URL}`,
    PROJECT_MEMBER_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_MEMBER_LIST.URL}`,
    PROJECT_TEST_MEMBER_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_TEST_MEMBER_LIST.URL}`,
    PROJECT_ROLE_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_ROLE_LIST.URL}`,
    PROJECT_VERSION_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_VERSION_LIST.URL}`,
    PROJECT_VERSION_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_VERSION_CREATE.URL}`,
    PROJECT_EXPORT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_EXPORT.URL}`,
    PROJECT_VERSION_DETAIL: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_VERSION_DETAIL.URL}`,
    PROJECT_THEME: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_THEME_SETTINGS.URL}`,
    PROJECT_THEME_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_THEME_CREATE.URL}`,
    PROJECT_THEME_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_THEME_EDIT.URL}`,
    PROJECT_THEME_LIST_PATH: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_THEME_LIST.URL}`,
    PROJECT_SCRIPT_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_SCRIPT_LIST.URL}`,
    ADD_PROJECT_SCRIPT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.ADD_PROJECT_SCRIPT.URL}`,
    EDIT_PROJECT_SCRIPT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.EDIT_PROJECT_SCRIPT.URL}`,
    PROJECT_USER_INVITATIONS: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_USER_INVITATIONS.URL}`,
    PROJECT_MODEL_DATA_FIELD_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_FIELD_EDIT.URL}`,
    PROJECT_MODEL_DATA_FIELD_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_FIELD_LIST.URL}`,
    PROJECT_MODEL_DATA_FIELD_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_FIELD_CREATE.URL}`,
    PROJECT_MODEL_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_LIST.URL}`,
    PROJECT_MODEL_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_CREATE.URL}`,
    PROJECT_MODEL_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_EDIT.URL}`,
    PROJECT_MODEL_RELATION_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_RELATION_LIST.URL}`,
    PROJECT_MODEL_RELATION_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_RELATION_CREATE.URL}`,
    PROJECT_MODEL_RELATION_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_MODEL_RELATION_EDIT.URL}`,
    UI_DATA_STORE_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_CREATE.URL}`,
    UI_DATA_STORE_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_LIST.URL}`,
    UI_DATA_STORE_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_EDIT.URL}`,
    UI_DATA_STORE_DETAIL: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_DETAIL.URL}`,
    UI_DATA_STORE_UPDATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_UPDATE_FUNCTION.URL}`,
    DATA_SCHEMA_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_CREATE.URL}`,
    DATA_SCHEMA_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_LIST.URL}`,
    DATA_SCHEMA_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_EDIT.URL}`,
    DATA_SCHEMA_ELEMENT_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_ELEMENTS_LIST.URL}`,
    DATA_SCHEMA_ELEMENT_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_ELEMENT_CREATE.URL}`,
    UI_DATA_STORE_FIELD_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_FIELDS.URL}`,
    UI_DATA_STORE_FIELD_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_FIELD_CREATE.URL}`,
    UI_DATA_STORE_FIELD_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_FIELD_EDIT.URL}`,
    UI_DATA_EVENTS: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_EVENTS.URL}`,
    DATA_EVENT_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_EVENT_CREATE.URL}`,
    DATA_EVENT_PAYLOAD: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_EVENT_PAYLOAD.URL}`,
    DATA_EVENT_DETAIL: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_EVENT_DETAIL.URL}`,
    DATA_EVENT_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_EVENT_EDIT.URL}`,
    PROJECT_PACKAGE_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_LIST.URL}`,
    PROJECT_PACKAGE_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_CREATE.URL}`,
    PROJECT_PACKAGE_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_EDIT.URL}`,
    UI_DATA_UPDATE_FUNCTION_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_UPDATE_FUNCTION_CREATE.URL}`,
    CREATE_DATA_MAPPING_PAGE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.CREATE_DATA_MAPPING_PAGE.URL}`,
    EDIT_CODE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.EDIT_CODE.URL}`,
    UI_STEP_FUNCTION_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_STEP_FUNCTION_CREATE.URL}`,
    UI_STEP_FUNCTION_UPDATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_STEP_FUNCTION_UPDATE.URL}`,
    UI_STEP_FUNCTION_DETAIL: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_STEP_FUNCTION_DETAIL.URL}`,
    CUSTOM_ENDPOINT_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.CUSTOM_ENDPOINT_LIST.URL}`,
    CUSTOM_ENDPOINT_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.CUSTOM_ENDPOINT_CREATE.URL}`,
    CUSTOM_ENDPOINT_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.CUSTOM_ENDPOINT_EDIT.URL}`,
    ENDPOINT_DETAIL: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.ENDPOINT_DETAIL.URL}`,
    DATA_SCHEMA_DATA_DEFINITION_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_DATA_DEFINITION_LIST.URL}`,
    DATA_SCHEMA_DATA_DEFINITION_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_DATA_DEFINITION_CREATE.URL}`,
    DATA_SCHEMA_DATA_DEFINITION_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_DATA_DEFINITION_EDIT.URL}`,
    OPENAPI_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.OPENAPI_LIST.URL}`,
    OPENAPI_ENDPOINT_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.OPENAPI_ENDPOINT_LIST.URL}`,
    PROJECT_DOCUMENT_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_LIST.URL}`,
    PROJECT_DOCUMENT_CREATE: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_CREATE.URL}`,
    PROJECT_DOCUMENT_EDIT: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_DOCUMENT_EDIT.URL}`,
    PROJECT_MODEL_ENDPOINT_LIST: `/${PAGE_DEFINITIONS.PROJECTS.URL}/${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_ENDPOINT_LIST.URL}`,
  },
  AUTH: {
    PERSON_SELECTION_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.PERSON_SELECTION.URL}`,
    ACCOUNT_VERIFICATION_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.ACCOUNT_VERIFICATION.URL}`,
    VERIFY_PHONE_NUMBER_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.VERIFY_PHONE_NUMBER.URL}`,
    LOGIN_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.LOGIN.URL}`,
    REGISTER_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.REGISTER.URL}`,
    FORGOT_PASSWORD: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.FORGOT_PASSWORD.URL}`,
    FACEBOOK_LOGIN_PATH: `${environment.apiBaseUrl}/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.FACEBOOK_LOGIN.URL}`,
    GOOGLE_LOGIN_PATH: `${environment.apiBaseUrl}/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.GOOGLE_LOGIN.URL}`,
    GITHUB_LOGIN_PATH: `${environment.apiBaseUrl}/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.GITHUB_LOGIN.URL}`,
    APPLE_LOGIN_PATH: `${environment.apiBaseUrl}/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.APPLE_LOGIN.URL}`,
    FORGOT_PASSWORD_SUCCESS_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.FORGOT_PASSWORD_SUCCESS.URL}`,
    CHANGE_PASSWORD_SUCCESS_PATH: `/${PAGE_DEFINITIONS.AUTH.URL}/${PAGE_DEFINITIONS.AUTH.CHILDREN.CHANGE_PASSWORD_SUCCESS.URL}`,
  },
  OTHER: {
    SHOWCASE_PAGE_PATH: `/${PAGE_DEFINITIONS.SHOW_CASE.URL}`,
  },
  HOME_PAGE: {
    HOME_PAGE_PATH: '/',
  },
  ERROR: {
    NOT_FOUND_PATH: `/${PAGE_DEFINITIONS.ERROR.URL}/${PAGE_DEFINITIONS.ERROR.CHILDREN.NOT_FOUND.URL}`,
  },
  PROFILE: {
    PROFILE_PATH: `/${PAGE_DEFINITIONS.PROFILE_PAGE.URL}`,
    PROFILE_CHANGE_PASSWORD_PATH: `/${PAGE_DEFINITIONS.PROFILE_PAGE.URL}/${PAGE_DEFINITIONS.PROFILE_PAGE.CHILDREN.CHANGE_PASSWORD.URL}`,
    PROFILE_PERSON_LIST_PATH: `/${PAGE_DEFINITIONS.PROFILE_PAGE.URL}/${PAGE_DEFINITIONS.PROFILE_PAGE.CHILDREN.PERSON_LIST.URL}`,
    PROFILE_PROJECT_LIST_PATH: `/${PAGE_DEFINITIONS.PROFILE_PAGE.URL}/${PAGE_DEFINITIONS.PROFILE_PAGE.CHILDREN.PROJECT_LIST.URL}`,
    PROFILE_ONBOARDING_PATH: `/${PAGE_DEFINITIONS.PROFILE_PAGE.URL}/${PAGE_DEFINITIONS.PROFILE_PAGE.CHILDREN.ONBOARDING_PAGE.URL}`
  },
  FILE_MANAGEMENT: {
    EXPLORER_PATH: `/${PAGE_DEFINITIONS.FILE_MANAGEMENT.URL}/${PAGE_DEFINITIONS.FILE_MANAGEMENT.CHILDREN.EXPLORER.URL}`,
  },
  WORKFLOW_TEMPLATE: {
    WORKFLOW_TEMPLATE_PATH: `/${PAGE_DEFINITIONS.WORKFLOW_TEMPLATE.URL}/${PAGE_DEFINITIONS.WORKFLOW_TEMPLATE.CHILDREN.WORKFLOW_TEMPLATE_LIST.URL}`,
  },
  WORKFLOW_FUNCTIONS: {
    WORKFLOW_FUNCTIONS_LIST: `/${PAGE_DEFINITIONS.CUSTOM_FUNCTION.URL}/${PAGE_DEFINITIONS.WORKFLOW_FUNCTION.URL}`,
    WORKFLOW_FUNCTION_EDIT: `/${PAGE_DEFINITIONS.CUSTOM_FUNCTION.URL}/${PAGE_DEFINITIONS.WORKFLOW_FUNCTION.URL}/${PAGE_DEFINITIONS.WORKFLOW_FUNCTION.CHILDREN.WORKFLOW_FUNCTION_EDIT.URL}`
  },
  WORKFLOW_STEP_FUNCTION: {
    WORKFLOW_STEP_FUNCTION_LIST: `/${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.URL}/${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.CHILDREN.WORKFLOW_STEP_FUNCTION_LIST.URL}`,
    WORKFLOW_STEP_FUNCTION_CREATE: `/${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.URL}/${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.CHILDREN.WORKFLOW_STEP_FUNCTION_CREATE.URL}`,
    WORKFLOW_STEP_FUNCTION_EDIT: `/${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.URL}/${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.CHILDREN.WORKFLOW_STEP_FUNCTION_EDIT.URL}`,
    WORKFLOW_STEP_FUNCTION_DETAIL: `/${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.URL}/${PAGE_DEFINITIONS.WORKFLOW_STEP_FUNCTION.CHILDREN.WORKFLOW_STEP_FUNCTION_DETAIL.URL}`
  }
};
