<rappider-list-grid *ngIf="projectModel?.fields; else noValue"
                    [config]="DATA_FIELD_LIST_CONFIG_FOR_DESIGN_MODULE"
                    [className]="animateTable ? 'animated-table': ''"
                    [data]="projectModel?.fields!"
                    (listActionClick)="navigateCreateDataFieldPage()"
                    (columnActionClick)="onDeleteDataFieldClick($event)"
                    (listActionDropdownItemClick)="onDataFieldListActionDropdownItemClick($event)"
                    (orderChange)="onOrderChange($event)"
                    [enableRowPointer]="enableRowPointer"
                    (rowActionClick)="onRowActionClick($event)"></rappider-list-grid>


<ng-template #noValue>
  <div class="no-value">
    <nz-skeleton [nzActive]="true"
                 [nzParagraph]="false"></nz-skeleton>
  </div>
</ng-template>
