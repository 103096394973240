import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { CommentWithRelations, DiagramCommentWithRelations, PersonWithRelations, ProjectModel, UiDataEvent, UiDataEventWithRelations, UiDataStoreWithRelations, UiWorkflowStepFunctionWithRelations, WorkflowEventWithRelations, WorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { GetUIDataStores } from 'libs/project/src/lib/states/ui-data-store/ui-data-store.actions';
import { Subscription } from 'rxjs';
import { DiagramActionType } from '../../utils/diagram-action-type';
import { DiagramSettingsMode } from '../../utils/diagram-settings-mode';
import { DiagramItemType } from '../../utils/diagram-item-type';
import { WorkflowItemInDiagram } from '../../utils/workflow-item-in-diagram.interface';
import { HeadingType, MenuConfig, TextMode } from '@rappider/rappider-components/utils';
import { DiagramTreeItem } from '../../utils/interfaces/diagram-editor/diagram-tree-item.interface';
import { DuplicateWorkflow, SetActiveDiagramItem } from '../../state/diagram-state/diagram.actions';
import { DiagramTreeItemConnector } from '../../utils/interfaces/diagram-editor/diagram-tree-item-connector.interface';
import { DiagramEditorComponent } from '../diagram-editor/diagram-editor.component';
import { GetDiagramComments } from 'libs/comment/src/lib/state/diagram-comment/diagram-comment.actions';
import { getDiagramCommentsWithDetails } from 'libs/comment/src/lib/state/diagram-comment/selectors/get-diagram-comments-with-details.selector';
import { commentAndDiagramCommentsLoading } from 'libs/comment/src/lib/state/diagram-comment/selectors/comment-and-diagram-comments-loading.selector';
import { cloneDeep } from 'lodash';
import { getCreationInformationFromEntity } from 'libs/shared/src/lib/functions/get-creation-information-from-entity';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';
import { uiWorkflowTemplatePageSelector } from 'libs/workflow-templates/src/lib/components/workflow-templates-pages/selectors/workflow-templates.selector';
import { DiagramWrapperCards } from '../../utils/definitions/diagram-editor/diagram-wrapper-cards-config';
import { CommentCategory } from '../../../../../comment/src/lib/utils/comment-category.enum';
import { ActivatedRoute } from '@angular/router';
import { RemoveQueryParam } from '@rappider/shared';
import { BrowserStorageManagementService } from '@rappider/services';
import { ChangeActiveProjectWithNotification } from 'libs/shared/src/lib/states/notification/notification.actions';

@Component({
  selector: 'rappider-diagram-editor-wrapper',
  templateUrl: './diagram-editor-wrapper.component.html',
  styleUrls: ['./diagram-editor-wrapper.component.scss']
})
export class DiagramEditorWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('diagramEditorComponent', { static: false }) diagramEditorcomponent: DiagramEditorComponent;

  subscriptions: Subscription[] = [];
  initSubscription: Subscription;
  uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[];
  uiDataStores: UiDataStoreWithRelations[];
  uiDataEvents: UiDataEvent[];
  activeUIDataStore: UiDataStoreWithRelations;
  activePerson: PersonWithRelations;
  projectPeopleData: PersonWithRelations[];
  displayedComments: CommentWithRelations[];
  diagramComments: DiagramCommentWithRelations[];
  isCommentsLoading: boolean;
  projectModels: ProjectModel[];
  workflowStepFunctions: WorkflowStepFunctionWithRelations[];
  workflowEvents: WorkflowEventWithRelations[];

  diagramSettingsMode: DiagramSettingsMode = {
    activeItemType: DiagramItemType.UIDataEvent,
    activeAction: DiagramActionType.Create
  };

  DiagramItemType = DiagramItemType;
  /* active item in diagram */
  activeItem: WorkflowItemInDiagram;
  /* diagram tree data */
  diagramTree: DiagramTreeItem[];
  /* workflow menu config */
  workflowMenu?: MenuConfig;
  /* connections between nodes */
  connectedNodes: DiagramTreeItemConnector[] = [];
  displayedDiagramTree: DiagramTreeItem[];

  menuVisibility = true;
  isDiagramEditorLoading = false;
  isActiveUIDataStoreSet = false;
  isUIStepFunctionsLoading = false;
  showEventsWithProcess = false;
  sourceEventId: string;
  targetEventId: string;
  uiDataStoreId: string;

  addWorkflowTemplateModalVisibility = false;
  starterEvents: UiDataEventWithRelations[];
  DiagramWrapperCards = DiagramWrapperCards;
  isSelectedEvent = true;
  isSelectedUIDataStore = false;
  formPanelVisibility = false;
  formPanelTabIndex = 0;
  activeCommentId: string;
  uiDataEventsAndStepFunctions;
  diagramCommentsLoaded: boolean;
  uiDataEventsAndStepFunctionsLoaded: boolean;
  isDiagramRendered: boolean;
  readOnlyMode = false;

  uiDataStoreTitle = {
    content: 'UI Data Stores',
    type: HeadingType.H5
  };

  workflowTemplate = {
    content: 'Workflow Templates',
    type: HeadingType.H5
  };

  constructor(
    private store: Store<any>,
    private formatDatePipe: FormatDatePipe,
    private activatedRoute: ActivatedRoute,
    private browserStorageManagementService: BrowserStorageManagementService
  ) { }

  ngOnInit(): void {
    const projectId = this.activatedRoute?.snapshot?.queryParams?.projectId;
    const commentId = this.activatedRoute?.snapshot?.queryParams?.activeCommentId;
    this.initSubscription = this.store.select((state) =>
      state.notification?.changeActiveProjectWithNotification?.activeProjectLoadedThroughNotification)
      .subscribe((activeProjectLoadedThroughNotification) => {
        if (activeProjectLoadedThroughNotification) {
          this.initialFunction();
        }
      });
    if (projectId !== this.browserStorageManagementService.getLocalStorageItem('activeProjectId') && projectId) {
      this.store.dispatch(ChangeActiveProjectWithNotification(
        {
          changeActiveProjectWithNotification: {
            commentType: 'ui-diagram', commentId, projectId, isProjectChange: true,
            isProjectChangeWithoutRefresh: false, activeProjectLoadedThroughNotification: false
          }
        }));
    } else {
      this.initialFunction();
    }
  }

  initialFunction() {
    this.subscribeToData();
    this.store.dispatch(GetDiagramComments());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.initSubscription.unsubscribe();
    this.store.dispatch(SetActiveDiagramItem({ payload: { activeItem: null } }));
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToStateRouteQueryParams(),
      this.subscribeToServiceActivatedRouteQueryParams(),
      this.subscribeToActivePerson(),
      this.subscribeToPeopleData(),
      this.subscribeToUIDataStores(),
      this.subscribeToUIDataEventsAndStepFunctions(),
      this.subscribeToActiveDiagramItem(),
      this.subscribeToWorkflowMenu(),
      this.subscribeToDiagramTreeData(),
      this.subscribeToDiagramTreeConnectors(),
      this.subscribeToCommentsLoading(),
      this.subscribeToDiagramComments(),
      this.subscribeToStarterEvents(),
      this.subscribeToProjectModels(),
      this.subscribeToWorkflowStepFunctions(),
      this.subscribeToWorkflowEvents(),
      this.subscribeToDiagramLoading(),
      this.subscribeToRouterQueryParams(),
      this.subscribeToActiveWizardStep()
    ];
  }

  getUIDataStores() {
    this.store.dispatch(new GetUIDataStores());
  }

  subscribeToStateRouteQueryParams() {
    return this.store.select(state => state.router.queryParams)
      .subscribe(q => {
        if (q?.activeCommentId) {
          this.activeCommentId = q?.activeCommentId;
          this.findCommentAndsetActiveDiagramItem()
        }
      });
  }

  subscribeToServiceActivatedRouteQueryParams() {
    return this.activatedRoute.queryParams.subscribe(q => {
      if (q?.activeCommentId) {
        this.activeCommentId = q?.activeCommentId;
        this.findCommentAndsetActiveDiagramItem();
      }
    });
  }

  findCommentAndsetActiveDiagramItem() {
    if (this.diagramCommentsLoaded && this.uiDataEventsAndStepFunctionsLoaded && this.activeCommentId && this.isDiagramRendered) {
      const selectedEvent = this.uiDataEventsAndStepFunctions.find(event => event.id === this.diagramComments.find(diagramComment => diagramComment.commentId === this.activeCommentId)?.relatedEntityId)
      if (selectedEvent && this.activeItem?.item?.id !== selectedEvent?.id) {
        this.store.dispatch(SetActiveDiagramItem({ payload: { activeItem: { item: selectedEvent, type: selectedEvent?.name && selectedEvent?.name.charAt(0) === selectedEvent.name.charAt(0).toUpperCase() ? DiagramItemType.UIDataEvent : DiagramItemType.UIStepFunction } } }));
        setTimeout(() => {
          this.formPanelTabIndex = 1;
        }, 1000);
      }
    }
  }

  // #region SUBSCRiPTIONS
  subscribeToUIDataStores() {
    return this.store.select(state => state.uiDataStore.data).subscribe((uiDataStores: UiDataStoreWithRelations[]) => {
      this.uiDataStores = uiDataStores;
      if (!this.isActiveUIDataStoreSet) {
        this.setActiveUIDataStore(undefined);
      }
      if (uiDataStores) {
        this.DiagramWrapperCards.selectConfig.options = uiDataStores.map(uiDataStore => ({
          key: uiDataStore.name,
          value: uiDataStore.name
        }));
        this.mapDataToItems();
      }
    });
  }

  subscribeToActivePerson() {
    return this.store.select(state => state.auth?.activePerson).subscribe((activePerson: PersonWithRelations) => {
      this.activePerson = activePerson;
    });
  }

  subscribeToPeopleData() {
    return this.store.select(state => state.activeProject.data?.people).subscribe((people: PersonWithRelations[]) => {
      this.projectPeopleData = people;
    });
  }

  subscribeToActiveDiagramItem() {
    return this.store.select(state => state.diagram?.activeItem).subscribe(activeItem => {
      this.activeItem = activeItem;
      this.setActiveUIDataStore(activeItem);
      this.displayedComments = this.filterDiagramComments();
      // this.filterDiagramTreeByActiveItemUIDataStore(activeItem);
    });
  }

  subscribeToUIDataEventsAndStepFunctions() {
    return this.store.select(createSelector(
      state => <UiDataEvent[]>state.uiDataEvent?.data,
      state => <boolean>state.uiDataEvent?.isLoaded,
      state => <UiWorkflowStepFunctionWithRelations[]>state.uiWorkflowStepFunction?.data,
      state => <boolean>state.uiWorkflowStepFunction?.isLoaded,
      (
        uiDataEvents: UiDataEvent[],
        uiDataEventsIsLoaded: boolean,
        uiStepFunctions: UiWorkflowStepFunctionWithRelations[],
        uiStepFunctionsIsLoaded: boolean
      ) => ({
        uiDataEvents: uiDataEvents,
        uiDataEventsIsLoaded: uiDataEventsIsLoaded,
        uiStepFunctions: uiStepFunctions,
        uiStepFunctionsIsLoaded: uiStepFunctionsIsLoaded
      })
    )).subscribe(data => {
      if (data.uiDataEventsIsLoaded && data.uiStepFunctionsIsLoaded) {
        this.uiDataEvents = cloneDeep(data.uiDataEvents);
        this.uiWorkflowStepFunctions = data.uiStepFunctions;
        this.uiDataEventsAndStepFunctions = [...this.uiDataEvents, ...this.uiWorkflowStepFunctions];
        this.uiDataEventsAndStepFunctionsLoaded = true
        this.findCommentAndsetActiveDiagramItem();
      }
    });
  }

  subscribeToDiagramTreeData() {
    return this.store.select(createSelector(
      state => state.diagram.diagramTree,
      state => state.diagramComment.data,
      (diagramTree, nodeComments) => ({
        diagramTree: diagramTree,
        nodeComments: nodeComments
      })
    )).subscribe(data => {
      this.diagramTree = this.mergeCommentsToTreeNode(data.diagramTree, data.nodeComments);
      this.displayedDiagramTree = this.mergeCommentsToTreeNode(data.diagramTree, data.nodeComments);
    });
  }

  mergeCommentsToTreeNode(tree, comments) {
    const diagraTree = cloneDeep(tree);
    function visitNode(node) {
      const nodeComments = comments?.filter(comment => comment?.relatedEntityId === node?.item?.id);
      if (nodeComments?.length) {
        node.item.comments = nodeComments;
        node.comments = nodeComments;
      }
      if (node.items?.length) {
        node.items?.forEach(item => visitNode(item));
      }
    }
    diagraTree?.forEach(node => visitNode(node));
    return diagraTree;
  }

  subscribeToDiagramTreeConnectors() {
    return this.store.select(state => state.diagram?.diagramTreeConnectors)
      .subscribe((connectors: DiagramTreeItemConnector[]) => {
        this.connectedNodes = connectors;
      });
  }

  subscribeToWorkflowMenu() {
    return this.store.select(state => state.diagram?.workflowMenu).subscribe((workflowMenu: MenuConfig) => {
      if (workflowMenu?.items?.length) {
        const updatedItems = [...workflowMenu.items];
        updatedItems[updatedItems.length - 1] = {
          ...updatedItems[updatedItems.length - 1],
          isExpanded: true
        };
        this.workflowMenu = { ...workflowMenu, items: updatedItems };
      }
    });
  }

  subscribeToDiagramLoading() {
    return this.store.select(state => state.diagram?.loading).subscribe((isLoading: boolean) => {
      this.isDiagramEditorLoading = isLoading;
      this.isDiagramRendered = !isLoading
      if (this.isDiagramRendered) {
        setTimeout(() => {
          this.findCommentAndsetActiveDiagramItem();
        }, 1000);
      }
    });
  }

  subscribeToCommentsLoading() {
    return this.store.select(<any>commentAndDiagramCommentsLoading).subscribe((isLoading: boolean) => {
      this.isCommentsLoading = isLoading;
    });
  }

  subscribeToDiagramComments() {
    return this.store.select(<any>getDiagramCommentsWithDetails).subscribe((diagramComments) => {
      const uiStepFunctionsAndUiDataEvents = [...(this.uiWorkflowStepFunctions || []), ...(this.uiDataEvents || [])];
      this.diagramComments = diagramComments?.map(diagramComment => {
        const relatedEntity = uiStepFunctionsAndUiDataEvents?.find(item => item.id === diagramComment?.relatedEntityId);
        return {
          ...diagramComment,
          relatedEntity: relatedEntity,
          comment: {
            ...diagramComment.comment,
            additionalContent: `Commented on: ${relatedEntity?.name}`
          }
        };
      });
      if (this.diagramComments?.length) {
        this.diagramCommentsLoaded = true
        this.findCommentAndsetActiveDiagramItem();
      }
      this.displayedComments = this.filterDiagramComments();
    });
  }

  subscribeToProjectModels() {
    return this.store.select(state => state.projectModel?.data).subscribe(projectModels => {
      this.projectModels = projectModels;
    });
  }

  subscribeToWorkflowStepFunctions() {
    const workflowStepFunctionsWithTemplates = createSelector(
      (state: WorkflowStepFunctionWithRelations) => state.workflowStepFunction?.data,
      (state: WorkflowStepFunctionWithRelations) => state.workflowStepFunction?.templateWorkflowStepFunctions,
      (workflowStepFunctions, wsfTemplates) => [...workflowStepFunctions, ...wsfTemplates]
    );
    return this.store.select(workflowStepFunctionsWithTemplates).subscribe((workflowStepFunctions: WorkflowStepFunctionWithRelations[]) => {
      this.workflowStepFunctions = workflowStepFunctions;
    });
  }

  subscribeToWorkflowEvents() {
    return this.store.select(state => state.workflowEvent?.data).subscribe((workflowEvents: WorkflowEventWithRelations[]) => {
      this.workflowEvents = workflowEvents;
    });
  }

  // re-render diagram when sidebar visibility is changed
  subscribeToRouterQueryParams() {
    return this.store.select(state => state.router?.queryParams).subscribe(queryParams => {
      setTimeout(() => {
        this.diagramEditorcomponent?.diagram?.instance.repaint();
      }, 1);
    });
  }

  subscribeToActiveWizardStep() {
    return this.store.select(state => state.clientLocalState?.data?.projectDesignActiveWizardStep).subscribe(activeWizardStep => {
      if (activeWizardStep <= 14) {
        this.readOnlyMode = true
      } else {
        this.readOnlyMode = false
      }
    });
  }

  // #endregion

  filterDiagramComments() {
    let diagramComments: DiagramCommentWithRelations[] = [];
    diagramComments = this.diagramComments?.filter(diagramComment =>
      diagramComment?.comment?.category === CommentCategory.UIDataEvent ||
      diagramComment?.comment?.category === CommentCategory.UIWorkflowStepFunction);

    return diagramComments?.map(diagramComment => diagramComment.comment);
  }

  onDiagramSettingsModeChange(diagramSettingsMode: DiagramSettingsMode) {
    this.diagramSettingsMode = diagramSettingsMode;
  }

  setActiveUIDataStore(activeItem) {
    if (activeItem) {
      const { item } = activeItem;
      this.activeUIDataStore = this.uiDataStores.find(uiDataStore => uiDataStore.id === item?.uiDataStoreId) ?? this.uiDataStores[0];
      this.targetEventId = item?.id;
      this.uiDataStoreId = item?.uiDataStoreId;
      this.isSelectedEvent = false;
      this.isSelectedUIDataStore = true;
    } else {
      this.activeUIDataStore = this.uiDataStores[0];
    }
    this.isActiveUIDataStoreSet = true;
  }

  filterDiagramTreeByActiveItemUIDataStore(activeItem: WorkflowItemInDiagram) {
    if (this.activeItem) {
      this.displayedDiagramTree = this.diagramTree?.filter(treeItem => treeItem?.item?.uiDataStoreId === activeItem?.item?.uiDataStoreId);
    } else {
      this.showAllWorkflows();
    }
  }

  showAllWorkflows() {
    this.displayedDiagramTree = this.diagramTree;
    this.store.dispatch(SetActiveDiagramItem({ payload: { activeItem: null } }));
  }

  onShowEventsWithProcess() {
    this.showEventsWithProcess = !this.showEventsWithProcess;
    if (this.showEventsWithProcess) {
      this.displayedDiagramTree = this.diagramTree?.filter(displayedItem => displayedItem?.items?.length > 0);
      this.store.dispatch(SetActiveDiagramItem({ payload: { activeItem: null } }));
    } else {
      this.showAllWorkflows();
    }
  }

  /**
   * re-renders diagram editor when menu visibility change (without reload data)
   * scrolls to active item when diagram re-rendered
   *
   * @memberof DiagramEditorWrapperComponent
   */
  repaintDiagramEditor() {
    // hold active item in diagram
    const activeItem = this.diagramEditorcomponent?.diagram?.instance.getItemByKey(this.activeItem?.item?.id);
    /* wait visibility state change for re-render */
    setTimeout(() => {
      this.diagramEditorcomponent?.diagram?.instance.repaint();
      // scroll to active item if it exists
      if (activeItem) {
        this.diagramEditorcomponent?.diagram?.instance.scrollToItem(activeItem);
        this.diagramEditorcomponent?.diagram?.instance.setSelectedItems([activeItem]);
      }
    }, 1);
  }

  subscribeToStarterEvents() {
    return this.store.select(<any>uiWorkflowTemplatePageSelector).subscribe(starterEvents => {
      this.starterEvents = starterEvents;
      if (starterEvents) {
        this.DiagramWrapperCards.cardsConfig.selectConfig.options = starterEvents.reduce(
          (acc, templatePage) => [
            ...acc,
            ...(
              templatePage?.tags?.filter(tag => !acc.some(item => item.value === tag))?.map(tag => ({
                key: tag,
                value: tag
              })) ?? []
            )
          ],
          []);
        this.mapDataToItems();
      }
    });
  };

  mapDataToItems() {
    this.DiagramWrapperCards.cardsConfig.items = this.starterEvents?.map(event => ({
      data: event,
      image: {
        source: 'assets/img/abstracts/elizabeth-lies-ZWPerNlqUu0-unsplash.jpg'
      },
      titles: [
        {
          type: HeadingType.H4,
          content: event.name
        }
      ],
      descriptions: [
        {
          content: event.description
        },
        {
          content: getCreationInformationFromEntity(event, this.formatDatePipe.transform(event.createdDate))
        }
      ],
      additionalTags: event.tags?.map(tag => ({
        text: {
          textMode: TextMode.Text,
          text: tag
        }
      }))
    }));
  };

  onSelectedUIDataStoreChange(uiDataStoreName) {
    this.uiDataStoreId = this.uiDataStores.find(uiDataStore => uiDataStore.name === uiDataStoreName).id;
    this.isSelectedUIDataStore = true;
  }

  onTemplateSelect(sourceEventId: string) {
    this.sourceEventId = sourceEventId;
  }

  onAddWorkflow() {
    this.addWorkflowTemplateModalVisibility = true;
    this.sourceEventId = null;
  }

  onSelectClick() {
    if (this.isSelectedUIDataStore) {
      this.store.dispatch(DuplicateWorkflow({
        payload: {
          duplicateWorkflowWithIds: {
            sourceEventId: this.sourceEventId,
            targetEventId: this.targetEventId,
            uiDataStoreId: this.uiDataStoreId,
            isScheduledEvent: this.targetEventId ? false : true
          }
        }
      }));
      this.addWorkflowTemplateModalVisibility = false;
    }
  }

  changeModalVisibility() {
    if (this.activeItem?.type === DiagramItemType.UIDataEvent) {
      this.isSelectedEvent = false;
    } else {
      this.targetEventId = undefined;
      this.isSelectedEvent = true;
    }
    this.addWorkflowTemplateModalVisibility = true;
    this.sourceEventId = null;
    if (!this.targetEventId) {
      this.isSelectedUIDataStore = false;
    }
  }

  isOkDisabled() {
    if (this.isSelectedUIDataStore && this.sourceEventId) {
      return false;
    } else {
      return true;
    }
  }

  onAddUiStepFunctionToDataEvent() {
    this.diagramSettingsMode = {
      activeItemType: DiagramItemType.UIStepFunction,
      activeAction: DiagramActionType.Create
    };
  }

  onAddDataEventToUiStepFunction() {
    this.diagramSettingsMode = {
      activeItemType: DiagramItemType.UIDataEvent,
      activeAction: DiagramActionType.Create
    };
  }

  openFormPanelCommentTab(tabIndex: number) {
    this.formPanelTabIndex = tabIndex;
  }
}
