<div class="content-editor-wrapper">
  <rappider-spin size="large"
                 [spinning]="isComponentDefinitionsLoading || isContentEditorLoading">
    <div class="main-wrapper">
      <as-split unit="pixel"
                direction="horizontal"
                gutterSize="7"
                gutterAriaLabel="adjustable divider between content and settings views"
                (dragEnd)="splitDragEnds($event)"
                #split="asSplit">
        <div *asSplitGutter
             class="custom-settings-area-gutter">
          <div asSplitGutterDragHandle
               class="custom-settings-area-gutter"></div>
        </div>
        <as-split-area size="*">

          <!-- Main content - page preview -->
          <div class="main-content"
               [ngClass]="{'main-content-full-width': !rightSidebarVisibility}">
            <rappider-content-tree-renderer *ngIf="layout; else pageContentTreeTemplate"
                                            [page]="page"
                                            [activeContentTreeItem]="activeContentTreeItem"
                                            [contentTree]="layout.contentTree"
                                            [componentDefinitions]="componentDefinitions"
                                            [componentTitleBarButtons]="componentTitleBarButtons"
                                            [componentTitleBarButtonVisibilityFunction]="componentTitleBarButtonVisibilityFunction"
                                            [hasClipboardData]="!!clipboardData"
                                            [isRulerVisible]="true"
                                            [readonly]="true"
                                            [wrappedWithLayout]="false"
                                            [themeData]="projectTheme"
                                            (previewModeChange)="onPreviewModeChange($event)"
                                            [isLayout]="true"
                                            [hasLayout]="!!layout"
                                            (clickToCommentIcon)="onClickToCommentIcon()">
              <ng-container *ngTemplateOutlet="pageContentTreeTemplate"></ng-container>
            </rappider-content-tree-renderer>
            <ng-template #pageContentTreeTemplate>
              <rappider-content-tree-renderer [activeContentTreeItem]="activeContentTreeItem"
                                              [readonly]="previewMode"
                                              [page]="page"
                                              [contentTree]="contentTree"
                                              [allCommentsOnPage]="allCommentsOnPage"
                                              [componentDefinitions]="componentDefinitions"
                                              [componentTitleBarButtons]="componentTitleBarButtons"
                                              [componentTitleBarDropdownConfig]="componentTitleBarDropdownConfig"
                                              [containerTitleBarDropdownConfig]="containerTitleBarDropdownConfig"
                                              [componentTitleBarButtonVisibilityFunction]="componentTitleBarButtonVisibilityFunction"
                                              [componentTitleBarDropdownItemVisibilityFunction]="componentTitleBarDropdownItemsVisibilityFunction"
                                              [containerTitleBarDropdownItemVisibilityFunction]="containerTitleBarDropdownItemsVisibilityFunction"
                                              [hasClipboardData]="!!clipboardData"
                                              [isRulerVisible]="false"
                                              [wrappedWithLayout]="!!layout"
                                              [themeData]="projectTheme"
                                              [hasLayout]="!!layout"
                                              [isLayout]="false"
                                              (copyToClipboard)="copyToClipboard($event)"
                                              (pasteFromClipboard)="pasteFromClipboard($event)"
                                              (activeContentTreeItemChange)="activeContentTreeItemChange($event)"
                                              (draggedContentTreeItemDropped)="draggedContentTreeItemDropped($event)"
                                              (addPageContainer)="addPageContainer($event)"
                                              (addComponentInPageContainer)="addComponentInPageContainer($event)"
                                              (addElementInPageContainer)="addElementInPageContainer()"
                                              (titleBarDropdownItemClick)="contentTreeItemTitleBarDropdownItemClick($event)"
                                              (titleBarButtonClick)="contentTreeItemTitleBarButtonClick($event)"
                                              (titleBarDataSubscriptionItemControllerClick)="onTitleBarDataSubscriptionItemControllerClick($event)"
                                              (previewModeChange)="onPreviewModeChange($event)"
                                              (addComponentWithPosition)="onAddComponentWithPosition($event)"
                                              (clickToCommentIcon)="onClickToCommentIcon()">
              </rappider-content-tree-renderer>
            </ng-template>
          </div>

        </as-split-area>
        <as-split-area [size]="rightSidebarSize"
                       [minSize]="rightSidebarMinSize"
                       [maxSize]="1100">

          <!-- Right sidebar -->
          <div class="right-sidebar">
            <rappider-content-editor-right-sidebar-menu #rightSidebarMenuComponentRef
                                                        [(visibility)]="rightSidebarVisibility"
                                                        [componentDefinitions]="componentDefinitions"
                                                        [contentTree]="contentTree"
                                                        [page]="page"
                                                        [activeContentTreeItem]="activeContentTreeItem"
                                                        [containerTemplates]="containerTemplates"
                                                        [pageComments]="pageComments"
                                                        [isCommentActive]="isCommentActive"
                                                        [activePerson]="activePerson"
                                                        [peopleData]="activeProject?.people"
                                                        [commentsLoading]="commentsLoading"
                                                        [uiDataEvents]="uiDataEvents"
                                                        [uiDataStores]="uiDataStores"
                                                        [cardsConfig]="snippetsCardsConfig"
                                                        [activeCommentId]="activeCommentId"
                                                        [allPanelsActive]="allPanelsActive"
                                                        [isDisplayingAllComments]="activeCommentType === CommentCategory.AllComments"
                                                        [selectedElementOnTitlebar]="selectedElementOnTitlebar"
                                                        [componentsAndSnippetsModalVisibility]="componentsAndSnippetsModalVisibility"
                                                        [preferredTheme]="preferredTheme"
                                                        (clickCommentCard)="onClickCommentCard($event)"
                                                        (createComment)="onCreateComment($event)"
                                                        (editComment)="onEditComment($event)"
                                                        (deleteComment)="onDeleteComment($event)"
                                                        (allPanelsActiveEmit)="allPanelsActiveEmit($event)"
                                                        (addComponentButtonClicked)="onAddComponentButtonClicked($event)"
                                                        (addComponentAndSnippetsButtonClicked)="onAddComponentAndSnippetsButtonClicked($event)"
                                                        (labelMenuItemClick)="onComponentSettingsLabelMenuItemClick($event)"
                                                        (pageSelected)="onPageSelected($event)"
                                                        (containerDeleteModalVisibility)="onContainerDeleteModalVisibility($event)"
                                                        (dropdownMenuItemClick)="onDropdownMenuItemClick($event)"
                                                        (isCommentActiveEmit)="onIsCommentActiveEmit($event)"
                                                        (sidebarSizeChange)="onSidebarSizeChange($event.newSize, $event.newMinSize)"
                                                        (snippetSelect)="onSnippetSelectClick($event)"
                                                        (componentsAndSnippetsModalVisibilityChange)="onComponentsAndSnippetsModalVisibility($event)"
                                                        (filterMenuItemClick)="onFilterMenuItemClick($event)"
                                                        (topButtonsClick)="onTopButtonsClick($event)">
            </rappider-content-editor-right-sidebar-menu>
          </div>

        </as-split-area>
      </as-split>

    </div>
  </rappider-spin>
</div>



<rappider-loading-notification [loading]="isCopyContainerTemplateLoading"
                               [loadingText]="'Loading Container Template'"></rappider-loading-notification>


<!-- MODALS -->

<!-- AI CONTENT CONFIG FORM MODEL -->
<rappider-ai-content-config-modal *ngIf="aiContentConfigModal.isVisible"
                                  [(modalVisibility)]="aiContentConfigModal.isVisible"
                                  [modalTitle]="aiContentConfigModal.title"
                                  [data]="aiContentConfigModal.data"
                                  (modalVisibilityChange)="aiContentConfigModalVisibilityChange($event)"
                                  (formSubmit)="aiContentConfigFormSubmit($event)">
</rappider-ai-content-config-modal>

<!-- DATA SUBSCRIPTION MODAL -->
<rappider-data-subscription *ngIf="dataSubscriptionModal.isVisible"
                            [(modalVisibility)]="dataSubscriptionModal.isVisible"
                            [modalTitle]="dataSubscriptionModal.title"
                            [uiDataStores]="uiDataStores"
                            [modalLoading]="dataSubscriptionModal.isLoading"
                            [componentInputDefinition]="dataSubscriptionModal?.inputDefinition"
                            [componentInputDefinitionNestedFields]="dataSubscriptionModal?.targetDataSchemaFields"
                            [componentId]="activeContentTreeItem?.component?.id"
                            [isDataSchemaLoading]="isDataSchemaLoading"
                            [updatingComponentDataSubscription]="dataSubscriptionModal?.updatingComponentDataSubscription"
                            [aiContentConfigs]="activeContentTreeItem?.component?.aiContentConfig"
                            (uiDataStoreChange)="onComponentSettingsSelectedUIDataStoreChange($event)"
                            (dataSubscriptionSubmitted)="onComponentDataSubscriptionSubmitted($event)"
                            (modalVisibilityChange)="onDataSubscriptionModalVisibilityChange($event)"
                            (addAIContentConfigClick)="onAddAIContentConfigClickInDataSubscriptionModal()">

</rappider-data-subscription>

<!-- DELETE PAGE CONTAINER MODAL -->
<rappider-delete-page-container *ngIf="deletePageContainerModalVisibility"
                                [(deletePageContainerModalVisibility)]="deletePageContainerModalVisibility"
                                [pageContainer]="activeContentTreeItem">
</rappider-delete-page-container>

<!-- ADD COMPONENT MODAL -->
<rappider-add-component-modal *ngIf="addComponentModalVisibility"
                              [(addComponentModalVisibility)]="addComponentModalVisibility"
                              [componentDefinitions]="componentDefinitions"
                              [activeContentTreeItem]="activeContentTreeItem"
                              [containerTemplates]="containerTemplates"></rappider-add-component-modal>

<!-- CONTAINER VISIBILITY CONDITION -->
<rappider-visibility-condition *ngIf="visibilityConditionModalVisibility"
                               [(visibility)]="visibilityConditionModalVisibility"
                               [container]="activeContentTreeItem"></rappider-visibility-condition>

<!-- /MODALS -->


<nz-drawer [nzClosable]="false"
           [nzMask]="true"
           [nzMaskClosable]="true"
           [(nzVisible)]="addComponentDrawerVisibility"
           nzWrapClassName="add-component-drawer"
           nzPlacement="right"
           nzSize="large"
           (nzOnClose)="addComponentDrawerClosed()">
  <ng-container *nzDrawerContent>
    <div class="add-component-container">


      <rappider-element-menu [componentDefinitions]="componentDefinitions"
                             [activeContentTreeItem]="activeContentTreeItem"
                             (addElementModalVisibility)="onAddElementDrawerVisibilityChanged($event)"
                             (drawerClosed)="onAddElementDrawerClosed()"></rappider-element-menu>

      <!-- Add component drawer close button -->
      <i class="fa-sharp fa-regular fa-circle-xmark fa-lg add-copmponent-drawer-close-button"
         (click)="closeAddComponentDrawer()"></i>

    </div>
  </ng-container>
</nz-drawer>

<!-- TAB ICON TEMPLATE -->
<ng-template #tabIcon
             let-icon="icon"
             let-title="title"
             let-tooltip="tooltip">
  <div class="tab-icon d-flex"
       [nzTooltipTitle]="tooltip"
       nzTooltipPlacement="bottom"
       nz-tooltip>
    <i [ngClass]="icon"></i>
    <p *ngIf="title">{{ title }}</p>
  </div>
</ng-template>
<!-- / TAB ICON TEMPLATE -->
