import { IconComponentConfig, IconType } from '@rappider/rappider-components/utils';

export const MenuFoldIconConfig: IconComponentConfig = {
  name: 'menu-fold',
  type: IconType.NgZorro
};

export const MenuUnfoldIconConfig: IconComponentConfig = {
  name: 'menu-unfold',
  type: IconType.NgZorro
};
