export enum DataSchemaCategory {
  UserGenerated = 'user-generated' /* user generated data-schema */,
  ProjectModel = 'project-model' /* data-schema for project model ( fields duplicated ) */,
  ComponentConfig = 'component-config' /* data-schema for component inputs */,
  ComponentIOType = 'component-io-type' /* data-schema for component input and output */,
  UiDataStoreState = 'ui-data-store-state' /* data-schema for ui-data-store's state interface */,
  UiDataEventPayload = 'ui-data-event-payload' /* data-schema for event's payload interface */,
  EndpointRequest = 'endpoint-request' /* data-schema for event's payload interface */,
  EndpointResponse = 'endpoint-response' /* data-schema for event's payload interface */,
  TemplateSchema = 'template-schema' /* data-schema for message template's input */
}
