/* actions */
import { createAction, createReducer, on } from '@ngrx/store';
import { PageCommentWithRelations } from '@rappider/rappider-sdk';
import * as PageCommentActions from './page-comment.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'pageComment';

/* state interface */
export interface State {
  data: PageCommentWithRelations[];
  isLoading: boolean;
  error: {
    error: any;
    key: string;
    date: number;
  };
}

export const initialState: State = {
  data: [],
  isLoading: false,
  error: null
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,

  /* get */
  on(PageCommentActions.GetPageComments, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PageCommentActions.GetPageCommentsSuccessful, (state, action) => ({
    ...state,
    data: action.pageComments,
    isLoading: false
  })),
  on(PageCommentActions.GetPageCommentsFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.error,
      key: action.key,
      date: action.date
    }
  })),

  /* create */
  on(PageCommentActions.CreatePageComment, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PageCommentActions.CreatePageCommentSuccessful, (state, action) => ({
    ...state,
    data: [
      action.pageComment,
      ...state.data
    ],
    isLoading: false
  })),
  on(PageCommentActions.CreatePageCommentFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.error,
      key: action.key,
      date: action.date
    }
  })),

  /* update */
  on(PageCommentActions.UpdatePageComment, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PageCommentActions.UpdatePageCommentSuccessful, (state, action) => ({
    ...state,
    isLoading: false
  })),
  on(PageCommentActions.UpdatePageCommentFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.error,
      key: action.key,
      date: action.date
    }
  })),

  /* delete */
  on(PageCommentActions.DeletePageComment, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PageCommentActions.DeletePageCommentSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(comment => comment.id !== action.commentId)
    ],
    isLoading: false
  })),
  on(PageCommentActions.DeletePageCommentFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: {
      error: action.error,
      key: action.key,
      date: action.date
    }
  })),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
