<rappider-table *ngIf="dataModels; else noValue"
                [class]="animateTable ? 'animated-table' : ''"
                [config]="tableConfig"
                [data]="dataModels"
                [enableRowPointer]="enableRowPointer"
                (rowActionClick)="onRowActionClick($event)"></rappider-table>

<ng-template #noValue>
  <div class="no-value">
    <nz-skeleton [nzActive]="true"
                 [nzParagraph]="false"></nz-skeleton>
  </div>
</ng-template>
