import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as lodash from 'lodash';

import {
  CustomFunctionDefinitionControllerService, CustomFunctionDefinitionPartial,
  ProjectModelEndpoint, ProjectModelEndpointControllerService, ProjectModelEndpointUpdateDto,
  ProjectModelEndpointWithRelations, SourceCodeControllerService
} from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

import * as ProjectModelEndpointActions from './project-model-endpoint.actions';
import * as ProjectModelActions from './../project-model-state/project-model.actions';
import * as CustomFunctionActions from 'libs/custom-function/src/lib/state/custom-function.actions';

@Injectable()
export class ProjectModelEndpointEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private notificationService: NotificationService,
    private projectModelEndpointApi: ProjectModelEndpointControllerService,
    private customFunctionApi: CustomFunctionDefinitionControllerService,
    private sourceCodeControllerAPI: SourceCodeControllerService
  ) { }

  createProjectModelEndpoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelEndpointActions.CreateProjectModelEndpoint
      ),
      mergeMap((action) => {
        const params = {
          body: {
            endpoint: action.payload.endpoint.endpoint,
            params: action.payload.endpoint.params,
            queryParams: action.payload.endpoint.queryParams,
            customFunctionDefinition: action.payload.endpoint.customFunctionDefinition
          }
        };

        return this.projectModelEndpointApi.create(params).pipe(
          mergeMap((endpoint: ProjectModelEndpoint) => {
            this.notificationService.createNotification(
              'success',
              endpoint.name,
              'SHARED.SUCCESSFULLY_CREATED'
            );
            return [
              ProjectModelEndpointActions.GetProjectModelEndpointById({ payload: { endpointId: endpoint.id } })
            ];
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              error?.error?.error?.message
            );
            return [
              ProjectModelEndpointActions.CreateProjectModelEndpointFailure({ payload: { error, key: 'CreateProjectModelEndpoint', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  getProjectModelEndpoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelActions.ActionTypes.GetProjectModelsSuccessful
      ),
      withLatestFrom(
        this.store.select(state => state.projectModel.data)
      ),
      mergeMap(([action, projectModel]) => {
        const params = {
          filter: {
            order: ['index ASC'],
            include: [
              {
                relation: 'params',
              },
              {
                relation: 'queryParams',
              },
              {
                relation: 'customFunctionDefinition'
              }
            ]
          }
        };
        return this.projectModelEndpointApi.find(params).pipe(
          map((endpoints: ProjectModelEndpointWithRelations[]) => {

            // Define the custom order for HTTP methods
            const methodOrder = ['GET', 'POST', 'PATCH', 'PUT', 'DEL'];

            // Sort using lodash's orderBy
            const sortedEndpoints = lodash.orderBy(
              endpoints,
              [
                (endpoint) => methodOrder.indexOf(endpoint.method.toUpperCase()), // Sort by custom order for method
                'path' // Sort by path alphabetically for same method types
              ],
              ['asc', 'asc'] // Sort both in ascending order
            );

            return ProjectModelEndpointActions.GetProjectModelEndpointsSuccessful({ payload: { endpoints: sortedEndpoints } });
          }),
          catchError((error) => [
            ProjectModelEndpointActions.GetProjectModelEndpointsFailure({
              payload: { error, key: 'GetProjectModelEndpoint', timestamp: Date.now() }
            })
          ])
        );
      })
    )
  );

  getProjectModelEndpointsByModelId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelEndpointActions.GetProjectModelEndpointByModelId
      ),
      mergeMap((action) => {
        const params = {
          filter: {
            where: {
              modelId: action.payload.projectModelId
            },
            order: ['index ASC']
          }
        };
        return this.projectModelEndpointApi.find(params).pipe(
          map((endpoints: ProjectModelEndpointWithRelations[]) => {
            // Define the custom order for HTTP methods
            const methodOrder = ['GET', 'POST', 'PATCH', 'PUT', 'DEL'];

            // Sort using lodash's orderBy
            const sortedEndpoints = lodash.orderBy(
              endpoints,
              [
                (endpoint) => methodOrder.indexOf(endpoint.method.toUpperCase()), // Sort by custom order for method
                'path' // Sort by path alphabetically for same method types
              ],
              ['asc', 'asc'] // Sort both in ascending order
            );

            return ProjectModelEndpointActions.GetProjectModelEndpointByModelIdSuccessful({ payload: { endpoints: sortedEndpoints } });
          }),
          catchError((error) => [
            ProjectModelEndpointActions.GetProjectModelEndpointByModelIdFailure({
              payload: { error, key: 'GetProjectModelEndpointByModelIdFailure', timestamp: Date.now() }
            })
          ])
        );
      })
    )
  );

  getProjectModelEndpointById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelEndpointActions.GetProjectModelEndpointById,
        ProjectModelEndpointActions.UpdateProjectModelEndpointSuccessful,
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.endpointId,
          filter: {
            order: ['index ASC'],
            include: [
              {
                relation: 'params',
              },
              {
                relation: 'queryParams',
              },
              {
                relation: 'customFunctionDefinition'
              }
            ]
          }
        };
        return this.projectModelEndpointApi.findById(params).pipe(
          mergeMap((updatedEndpoint: ProjectModelEndpointWithRelations) => {
            if (updatedEndpoint?.customFunctionDefinitionId) {
              return [
                ProjectModelEndpointActions.GetProjectModelEndpointByIdSuccessful({ payload: { updatedEndpoint: updatedEndpoint } }),
                new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_ENDPOINT_LIST }),
                CustomFunctionActions.GetCustomFunctionById({ customFunctionId: updatedEndpoint.customFunctionDefinitionId })
              ];
            } else {
              return [
                ProjectModelEndpointActions.GetProjectModelEndpointByIdSuccessful({ payload: { updatedEndpoint: updatedEndpoint } }),
                new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_ENDPOINT_LIST })
              ];
            }
          }
          ),
          catchError((error) => [
            ProjectModelEndpointActions.GetProjectModelEndpointByIdFailure({ payload: { error, key: 'GetProjectModelEndpointByIdFailure', timestamp: Date.now() } })
          ])
        );
      })
    )
  );

  updateProjectModelEndpointAndCustomFunction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelEndpointActions.UpdateProjectModelEndpointAndCustomFunction
      ),
      mergeMap((action) => {
        const customFunctionUpdateParams = {
          id: action.payload.customFunctionDefinition.id,
          body: action.payload.customFunctionDefinition as CustomFunctionDefinitionPartial
        };

        const endpointParams = {
          id: action.payload.endpoint.endpoint.id,
          endpoint: action.payload.endpoint
        };
        return this.customFunctionApi.updateById(customFunctionUpdateParams).pipe(
          mergeMap(() => [
            CustomFunctionActions.UpdateCustomFunctionSuccessful({ id: customFunctionUpdateParams.id, customFunction: customFunctionUpdateParams.body, navigateAfterSubmit: false }),
            ProjectModelEndpointActions.UpdateProjectModelEndpoint({ payload: { endpointId: endpointParams.id, endpoint: endpointParams } })
          ]), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              error?.error?.error?.message
            );
            return [
              CustomFunctionActions.UpdateCustomFunctionFailure({ error, key: 'UpdateProjectModelEndpoint', timestamp: Date.now() })
            ];
          })
        );
      })
    )
  );

  updateProjectModelEndpoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelEndpointActions.UpdateProjectModelEndpoint
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.endpointId,
          body: action.payload.endpoint.endpoint as ProjectModelEndpointUpdateDto
        };

        return this.projectModelEndpointApi.updateById(params).pipe(
          mergeMap(() => {
            this.notificationService.createNotification(
              'success',
              action.payload.endpoint.endpoint.name,
              'SHARED.SUCCESSFULLY_UPDATED'
            );
            return [
              ProjectModelEndpointActions.UpdateProjectModelEndpointSuccessful({ payload: { endpointId: action.payload.endpointId, endpointUpdateDto: action.payload.endpoint } })
            ];
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              error?.error?.error?.message
            );
            return [
              ProjectModelEndpointActions.UpdateProjectModelEndpointFailure({ payload: { error, key: 'UpdateProjectModelEndpoint', timestamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  updateProjectModelEndpointsSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelEndpointActions.UpdateProjectModelEndpointSuccessful,
      ), map((action) => ProjectModelEndpointActions.GetProjectModelEndpointById({ payload: { endpointId: action.payload.endpointId } }))
    )
  );

  getOpenAPIDefinition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelEndpointActions.GetOpenAPIDefinition
      ),
      mergeMap(() => this.sourceCodeControllerAPI.getFileContent({ filePath: '.shared/openapi.json' }).pipe(
        map((res) => ProjectModelEndpointActions.GetOpenAPIDefinitionSuccessful({ payload: { openAPIDefinition: JSON.parse(res.code) } })),
        catchError((error) => [
          ProjectModelEndpointActions.GetOpenAPIDefinitionFailure({ payload: { error, key: 'UpdateProjectModelEndpoint', timestamp: Date.now() } })
        ])
      ))
    )
  );

  deleteProjectModelEndpoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectModelEndpointActions.DeleteProjectModelEndpoint
      ),
      mergeMap((action) => {
        const params = {
          id: action.payload.endpointId
        };
        return this.projectModelEndpointApi.deleteById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESS',
              'SHARED.SUCCESSFULLY_DELETED'
            );
            return ProjectModelEndpointActions.DeleteProjectModelEndpointSuccessful({ payload: { endpointId: action.payload.endpointId } });
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'SHARED.ERROR',
              error?.error?.error?.message
            );
            return [
              ProjectModelEndpointActions.DeleteProjectModelEndpointFailure({ payload: { error, key: 'DeleteProjectModelEndpoint', timestamp: Date.now() } })
            ];
          }));
      })
    )
  );
}
