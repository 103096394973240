export enum ContentEditorRightSidebarMenuTabKey {
  AddComponent = 'add-component',
  AddElement = 'add-element',
  Sidebar = 'sidebar-fold',
  ContentTree = 'content-tree',
  Pages = 'pages',
  Layouts = 'layouts',
  Comments = 'comments',
  Settings = 'settings',
  PageLifecycle = 'page-lifecycle',
  UpdateGlobalStyle = 'update-global-style',
  UpdateTheme = 'update-theme',
  AddTemplateToPage = 'add-template-to-page',
  PageVariables = 'page-variables',
  AddComponentAndSnippets = 'add-component-and-snippets',
}
