
export interface SkeletonAvatarConfig {
  /**
   * Size of the avatar
   *
   * @type {SkeletonAvatarSize}
   * @memberof SkeletonAvatarConfig
   */
  size?: SkeletonSizeAvatar;

  /**
   * Shape of the avatar
   *
   * @type {SkeletonAvatarShape}
   * @memberof SkeletonAvatarConfig
   */
  shape?: SkeletonAvatarShape;
}


export enum SkeletonSizeAvatar {
  Default = 'default',
  Large = 'large',
  Small = 'small'
}

export enum SkeletonAvatarShape {
  Circle = 'circle',
  Square = 'square'
}
