import { ButtonComponentConfig, DropdownMenuComponentConfig, DropdownMenuLabelMode, DropdownMenuPlacement, DropdownMenuTriggerType, IconType } from '@rappider/rappider-components/utils';
import { ContentTreeItem } from 'libs/content-tree-renderer/src/lib/models';
import { POP_CONFIRM_DELETE_ACTION } from 'libs/shared/src/lib/configs/pop-confirm-button/pop-confirm-button-config';

export const componentTitleBarDropdownConfig: DropdownMenuComponentConfig = {
  icon: {
    name: 'fa-regular fa-caret-down',
    type: IconType.FontAwesome,
    color: '#343a40',
    size: '15px'
  },
  labelMode: DropdownMenuLabelMode.StaticLabel,
  placement: DropdownMenuPlacement.BottomLeft,
  triggerType: DropdownMenuTriggerType.Click,
  items: [
    {
      data: 'add-comment-to-component',
      label: 'Add comment',
      icon: {
        name: 'fa-solid fa-comment',
        type: IconType.FontAwesome,
        color: 'var(--text-color)'
      }
    },
    {
      data: 'delete-component',
      label: 'CONTENT_EDITOR_MODULE.RENDERED_PAGE.DELETE_COMPONENT',
      popconfirmTitle: 'CONTENT_EDITOR_MODULE.RENDERED_PAGE.DELETE_COMPONENT_CONFIRMATION',
      popconfirmOkText: POP_CONFIRM_DELETE_ACTION.popconfirmOkText,
      popconfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popconfirmOkDanger,
      icon: {
        name: 'fas fa-trash',
        type: IconType.FontAwesome,
        color: 'var(--text-color)'
      }
    }
  ]
};

export function componentTitleBarDropdownItemsVisibilityFunction(button: ButtonComponentConfig, contentTreeItem: ContentTreeItem, hasClipboardData: boolean) {
  return true;
}
