import { ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';
import { NzTreeNode } from 'ng-zorro-antd/tree';

export function isDragEventValid(node: NzTreeNode, position: number) {
    return !(
        (node.origin.type === ContentTreeItemType.Component && position === 0)
        ||
        (node.origin.type === ContentTreeItemType.Outlet && position === 0)
        ||
        (node.origin.isMainContainer && position !== 0)
    );
}
