<div class="page-tree">
  <div class="search-area">
    <rappider-input-group [(ngModel)]="searchText"
                          [textbox]="inputGroupConfig.textbox"
                          [suffixIcon]="inputGroupConfig.suffixIcon"></rappider-input-group>
  </div>
  <div class="tree-area">
    <nz-tree nzShowLine
             [nzMultiple]="false"
             [nzData]="dataSource"
             [nzSelectedKeys]="selectedKeys"
             [nzSearchValue]="searchText"
             (nzClick)="onClickNode($event)">
    </nz-tree>
  </div>
</div>