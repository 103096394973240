import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';

import * as PaymentActions from './payment.actions';
import { PaymentControllerService } from 'libs/rappider-sdk/src/lib/services';
import { environment } from '@environment';
import { NotificationService } from 'libs/components/src/lib/services';
import { PlanType } from '../utils/plan-type.enum';

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions,
    private paymentApi: PaymentControllerService,
    private notificationService: NotificationService
  ) { }

  createCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.CreateCheckout),
      mergeMap((action) => {
        const params = {
          body: {
            productId: environment.stripeProductionId,
            priceId: action.payload.subscriptionType === PlanType.Yearly ? environment.stripePricingIdYearly : environment.stripePricingIdMonthly
          }
        }
        return this.paymentApi.stripeCreateCheckoutSession(params).pipe(
          mergeMap((checkout) => {
            const parsedCheckout = typeof checkout === 'string' ? JSON.parse(checkout) : checkout;
            window.location.href = parsedCheckout.url;
            return [PaymentActions.CreateCheckoutSuccessful()];
          }),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              'Error',
              error?.message
            );
            return [PaymentActions.CreateCheckoutFailure()];
          })
        )
      }

      )
    )
  );

}
