import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderProgressSteps } from './progress-steps.component';
import { RappiderProgressModule } from '../progress/progress.module';
import { RappiderLottiePlayerModule } from '../lottie-player/lottie-player.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    RappiderProgressSteps
  ],
  imports: [
    CommonModule,
    NzBackTopModule,
    RappiderButtonModule,
    RappiderLottiePlayerModule,
    RappiderProgressModule,
    TranslateModule
  ],
  exports: [
    RappiderProgressSteps
  ]
})
export class RappiderProgressStepsModule { }
