import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentTreeRendererComponent } from './components/content-tree-renderer/content-tree-renderer.component';
import { RappiderButtonModule, RappiderDropdownMenuModule, RappiderHeadingModule, RappiderIconModule, RappiderLottiePlayerModule, RappiderSwitchModule } from '@rappider/rappider-components';
import { TranslateModule } from '@ngx-translate/core';
import { ContentTreeItemTitleBarComponent } from './components/content-tree-item-title-bar/content-tree-item-title-bar.component';
import { SystemGeneratedComponentRendererComponent } from './components/system-generated-component-renderer/system-generated-component-renderer.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ContentTreeRendererControllerComponent } from './components/content-tree-renderer-controller/content-tree-renderer-controller.component';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ResizableModule } from 'angular-resizable-element';

import { SortablejsModule } from 'ngx-sortablejs';

@NgModule({
  declarations: [
    ContentTreeRendererComponent,
    ContentTreeItemTitleBarComponent,
    SystemGeneratedComponentRendererComponent,
    ContentTreeRendererControllerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RappiderLottiePlayerModule,
    TranslateModule,
    RappiderButtonModule,
    NzPopoverModule,
    NzToolTipModule,
    NzButtonModule,
    NzInputModule,
    NzSelectModule,
    RappiderSwitchModule,
    ResizableModule,
    RappiderHeadingModule,
    RappiderDropdownMenuModule,
    RappiderIconModule,
    SortablejsModule,
  ],
  exports: [
    ContentTreeRendererComponent,
    SystemGeneratedComponentRendererComponent
  ],
})
export class ContentTreeRendererModule { }
