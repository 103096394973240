import { ActionButtonConfig, ButtonSize } from '@rappider/rappider-components/utils';

export const AddUiStepFunctionButtonConfig: ActionButtonConfig = {
  text: 'Add UI Step Function to Data Event',
  size: ButtonSize.Default,
  icon: {
    name: 'fa-solid fa-plus'
  },
  block: true,
  visible: true
};
