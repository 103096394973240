import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ButtonComponentConfig, ButtonSize, ButtonType } from '@rappider/rappider-components/utils';
import { DataSchema, DataSchemaControllerService, DataTransformationControllerService, PageControllerService, UiDataStore, UiDataStoreFieldSelectorControllerService, UiDataStoreFieldSelectorWithRelations } from '@rappider/rappider-sdk';
import { JsonSchemaService } from 'libs/components/src/lib/services';
import { ContentTreeContainer } from 'libs/content-tree-renderer/src/lib/models';
import { UiDataStoreFieldSelectorValue } from 'libs/data-subscription/src/lib/components/ui-data-store-field-tree-selector/ui-data-store-field-selector-value.interface';
import { JsonSchema } from 'libs/data-transformation/src/lib/shared';
import { QueryBaseOperation } from 'libs/data-transformation/src/lib/shared/interfaces/query-base-operation.interface';
import { AddConditionToContainerRequestDto } from 'libs/rappider-sdk/src/lib/models/add-condition-to-container-request-dto';
import { Subscription } from 'rxjs';
import { CreateContainerVisibilityCondition, DeleteContainerVisibilityCondition, UpdateContainerVisibilityCondition } from '../../state/content-editor.actions';
import { UpdateContainerConditionRequestDto } from 'libs/rappider-sdk/src/lib/models/update-container-condition-request-dto';
import { DataTransformService } from '@rappider/services';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

@Component({
  selector: 'rappider-visibility-condition',
  templateUrl: './visibility-condition.component.html',
  styleUrls: ['./visibility-condition.component.scss']
})
export class VisibilityConditionComponent implements OnInit {

  @Input() visibility = false;
  @Input() container: ContentTreeContainer;

  @Output() visibilityChange = new EventEmitter<boolean>();

  /* ui-data-store-field-tree-selector variables */
  selectedDataSources: UiDataStoreFieldSelectorValue[];
  removeDataSourceButtonConfig: ButtonComponentConfig = {
    icon: {
      name: 'fa-regular fa-trash'
    },
    size: ButtonSize.ExtraSmall,
    type: ButtonType.Link
  };
  /* filter-find variables */
  condition: QueryBaseOperation;
  functions: string;
  jsonSchema: JsonSchema;
  /* monaco editor variables */
  sampleJson: string;
  sampleJsonOptions = {
    theme: 'vs-default',
    language: 'json',
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 }
  };
  sampleJsonLoading = false;
  monacoEditorVisibility = false;
  /* others */
  dataSchemas: DataSchema[];
  pageId: string;
  editMode = false;
  subscriptions: Subscription[];

  constructor(
    private store: Store<any>,
    private jsonSchemaService: JsonSchemaService,
    private dataSchemaApi: DataSchemaControllerService,
    private dataTransformationApi: DataTransformationControllerService,
    private uiDataStoreFieldSelectorApi: UiDataStoreFieldSelectorControllerService,
    private dataTransformService: DataTransformService
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.subscribeToDataSchemas(),
      this.getFilterFindFunctions(),
      this.subscribeToActivePageId()
    ];
    /* for render issues */
    setTimeout(() => this.monacoEditorVisibility = true, 200);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.container && this.container?.visibilityCondition) {
      this.buildForEditMode();
    }
  }

  subscribeToDataSchemas() {
    return this.store.select(<any>getDataSchemasWithDetailsSelector)
      .subscribe(dataSchemas => this.dataSchemas = dataSchemas);
  }

  getFilterFindFunctions() {
    return this.dataTransformationApi
      .getDataTransformationItemOperationFunctions()
      .subscribe(functions => this.functions = functions?.filter);
  }

  subscribeToActivePageId() {
    return this.store.select(state => state.contentEditor?.page?.id)
      .subscribe(pageId => this.pageId = pageId);
  }

  buildForEditMode() {
    this.editMode = true;
    const params = {
      filter: {
        where: {
          id: {
            inq: this.container.visibilityCondition.uiDataStoreFieldSelectorIds
          },
        },
        include: ['fields', 'uiDataStore']
      }
    };

    this.uiDataStoreFieldSelectorApi.find(params).subscribe(response => {
      this.container.visibilityCondition.uiDataStoreFieldSelectors = response;
      /* set data sources */
      this.selectedDataSources = response?.map(uiDataStoreFieldSelector => ({
        variableName: uiDataStoreFieldSelector.variableName,
        uiDataStore: uiDataStoreFieldSelector.uiDataStore,
        uiDataStoreSchemaFields: uiDataStoreFieldSelector.fields,
      })) || [];
      this.condition = this.container?.visibilityCondition?.condition || {};
      this.buildJsonSchemaBySelectedDataSources();
    });
  }

  buildJsonSchemaBySelectedDataSources() {
    this.sampleJsonLoading = true;
    const mappedDataSources = this.selectedDataSources?.map(dataSource => ({
      dataSchemaId: dataSource?.uiDataStoreSchemaFields?.length
        ? dataSource.uiDataStoreSchemaFields[dataSource.uiDataStoreSchemaFields?.length - 1]?.typeId
        : dataSource.uiDataStore.dataSchemaId,
      isArray: dataSource.uiDataStoreSchemaFields?.[dataSource.uiDataStoreSchemaFields?.length - 1]?.isArray,
      variableName: dataSource.variableName
    }));

    this.dataSchemaApi.getJSONSchemasByIds({ dataSchemaId: mappedDataSources.map(ds => ds.dataSchemaId) }).subscribe(jsonSchemas => {
      if (jsonSchemas?.length === mappedDataSources?.length) {
        this.sampleJson = '';
        jsonSchemas.forEach((jsonSchema, i) => {
          this.jsonSchemaService.generateJsonSampleFromJsonSchema(jsonSchema.jsonSchema).then(sampleData => {
            let exampleJsonObject: any = this.sampleJson ? JSON.parse(this.sampleJson) : {};
            exampleJsonObject = {
              ...exampleJsonObject,
              [mappedDataSources[i].variableName]: mappedDataSources[i].isArray ? [sampleData] : sampleData
            };
            this.sampleJson = JSON.stringify(exampleJsonObject);
          });
        });
      }
      this.sampleJsonLoading = false;
    });
  }

  onDataSourceSelectionChange() {
    this.buildJsonSchemaBySelectedDataSources();
  }

  onSaveButtonClick() {
    if (this.editMode) {
      this.updateVisibilityCondition();
    } else {
      this.createNewVisibilityCondition();
    }
  }

  createNewVisibilityCondition() {
    const body: AddConditionToContainerRequestDto = {
      pageContainerId: this.container.id,
      uiDataStoreFieldSelectors: this.selectedDataSources.map(ds => ({
        uiDataStoreId: ds.uiDataStore.id,
        variableName: ds.variableName,
        uiDataStoreSchemaFieldIds: ds.uiDataStoreSchemaFields.map(el => el.id),
      })),
      visibilityCondition: this.condition
    };
    this.store.dispatch(new CreateContainerVisibilityCondition({ pageId: this.pageId, body: body }));
    this.onModalVisibilityChange(false);
  }

  updateVisibilityCondition() {
    const changes = this.dataTransformService.compareArrays(this.container.visibilityCondition.uiDataStoreFieldSelectors, this.selectedDataSources, 'variableName');
    const body: UpdateContainerConditionRequestDto = {
      pageContainerId: this.container.id,
      visibilityCondition: this.condition,
      createdUIDataStoreFieldSelectors: changes.createdArray.map(createdSelector => ({
        uiDataStoreId: createdSelector.uiDataStore.id,
        variableName: createdSelector.variableName,
        uiDataStoreSchemaFieldIds: createdSelector.uiDataStoreSchemaFields?.map(schemaField => schemaField.id) || []
      })) || [],
      deletedUIDataStoreFieldSelectorIds: changes?.deletedArray?.map((deletedSelector: UiDataStoreFieldSelectorWithRelations) => deletedSelector.id) || []
    };
    this.store.dispatch(new UpdateContainerVisibilityCondition({ pageId: this.pageId, body: body }));
    this.onModalVisibilityChange(false);
  }

  onModalVisibilityChange(visibility: boolean) {
    this.visibility = visibility;
    this.visibilityChange.emit(this.visibility);
  }

  onDataSourceRemoved(removedDataSource) {
    this.selectedDataSources = this.selectedDataSources.filter(dataSource => dataSource.variableName !== removedDataSource.variableName);
    const sampleJsonObject = this.sampleJson ? JSON.parse(this.sampleJson) : {};
    delete sampleJsonObject[removedDataSource.variableName];
    this.sampleJson = JSON.stringify(sampleJsonObject);
  }

  onRemoveVisibilityCondition() {
    this.store.dispatch(new DeleteContainerVisibilityCondition({ pageId: this.pageId, pageContainerId: this.container.id }));
    this.onModalVisibilityChange(false);
  }

}
