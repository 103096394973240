import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
/* ProjectDesignTask data store imports */
import * as fromProjectDesignTaskState from './project-design-task.reducer';
import { ProjectDesignTaskEffects } from './project-design-task.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /* data store for ProjectDesignTask */
    StoreModule.forFeature(
      fromProjectDesignTaskState.featureKey,
      fromProjectDesignTaskState.reducer,
      { initialState: fromProjectDesignTaskState.initialState }
    ),
    EffectsModule.forFeature([ProjectDesignTaskEffects])
  ]
})
export class ProjectDesignTaskDataStoreModule { }
