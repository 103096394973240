import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudFormSelectItem, CrudViewFormItemType, FormLayout } from '@rappider/rappider-components/utils';
import { CAMEL_CASE_REGEX } from '@rappider/shared/definitions';

export const PAGE_VARIABLE_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'Variable Name',
      fieldName: 'variableName',
      type: CrudViewFormItemType.TextBox,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(CAMEL_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'Data Selector',
      fieldName: 'uiDataSelectorId',
      type: CrudViewFormItemType.Select,
      showRequiredSign: true,
      buttons: [
        {
          key: 'create',
          text: 'Create Selector'
        }
      ],
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    }
  ],
  submitButton: { visible: false }
};


export const PAGE_VARIABLE_CREATE_WITH_AI_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'Add a description for the variable',
      fieldName: 'variableDescription',
      type: CrudViewFormItemType.TextArea,
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    }
  ],
  formLayout: FormLayout.Vertical,
  submitButton: { visible: false }
};
