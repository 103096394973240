/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProjectBuild } from '../models/project-build';
import { ProjectBuildPartial } from '../models/project-build-partial';

@Injectable({
  providedIn: 'root',
})
export class ProjectBuildControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectBuildControllerBuild
   */
  static readonly ProjectBuildControllerBuildPath = '/project-builds/build';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `build()` instead.
   *
   * This method doesn't expect any request body.
   */
  build$Response(params?: {
    projectVersionId?: string;
  }): Observable<StrictHttpResponse<ProjectBuild>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectBuildControllerService.ProjectBuildControllerBuildPath, 'post');
    if (params) {
      rb.query('projectVersionId', params.projectVersionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectBuild>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `build$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  build(params?: {
    projectVersionId?: string;
  }): Observable<ProjectBuild> {

    return this.build$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectBuild>) => r.body as ProjectBuild)
    );
  }

  /**
   * Path part for operation projectBuildControllerUpdateById
   */
  static readonly ProjectBuildControllerUpdateByIdPath = '/project-builds/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ProjectBuildPartial
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectBuildControllerService.ProjectBuildControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ProjectBuildPartial
  }): Observable<void> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
