/* Angular */
import { createReducer, on } from '@ngrx/store';

/* Model Interface */
import { ProjectDesignTask, NewProjectDesignTask } from '@rappider/rappider-sdk';

/* Actions */
import * as ProjectDesignTaskActions from './project-design-task.actions';
import { WizardStepConfig } from '../../configs/wizard-steps';
import { COLLAPSE_PANEL_CONFIGS, CollapsePanelConfig } from '../../configs/collapse-panel-config';
import { cloneDeep } from 'lodash';
import { ProjectDesignTaskIdentifier } from './project-design-task.interface';

/* State key */
export const featureKey = 'projectDesignTask';

/* State interface */
export interface State {
  data: ProjectDesignTask[] | undefined;
  isLoading: boolean;
  isLoaded?: boolean;
  /* save the key of the tasks in progress so that we dont trigger them again before teh process completed in the backend */
  tasksCompleted: string[];
  error: any;
}

/* Initial values */
export const initialState: State = {
  data: undefined,
  isLoading: false,
  isLoaded: false,
  tasksCompleted: [],
  error: null,
};

/* Function to calculate updated projectDesignTask optimistically */
function updateProjectDesignTasks(
  updatedProjectDesignTaskId: string,
  updatedProjectDesignTaskData: Partial<ProjectDesignTask>,
  allProjectDesignTasks: ProjectDesignTask[]
): ProjectDesignTask[] {

  if (!allProjectDesignTasks?.length) {
    return [];
  }

  return allProjectDesignTasks.map(projectDesignTask =>
    projectDesignTask.id === updatedProjectDesignTaskId // check if the object is the one we have updated
      ? { ...projectDesignTask, ...updatedProjectDesignTaskData } // update the object content with the new data
      : projectDesignTask // return the other objects as they are
  );
}

export const reducer = createReducer(
  initialState,
  on(ProjectDesignTaskActions.GetProjectDesignTasksSuccessful, (state, action) => ({
    ...state,
    data: action.payload.projectDesignTasks,
  })),
  on(ProjectDesignTaskActions.CreateProjectDesignTask, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ProjectDesignTaskActions.CreateProjectDesignTaskSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data ?? [],
      action.payload.projectDesignTask
    ],
    isLoading: false,
    isLoaded: true
  })),
  on(ProjectDesignTaskActions.CreateProjectDesignTaskFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(ProjectDesignTaskActions.UpdateProjectDesignTask, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ProjectDesignTaskActions.UpdateProjectDesignTaskSuccessful, (state, action) => ({
    ...state,
    data: updateProjectDesignTasks(action.payload?.projectDesignTaskId, action.payload.projectDesignTask, (state.data?.length ? state.data : [])),
    isLoading: false
  })),
  on(ProjectDesignTaskActions.UpdateProjectDesignTaskFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(ProjectDesignTaskActions.DeleteProjectDesignTask, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ProjectDesignTaskActions.DeleteProjectDesignTaskSuccessful, (state, action) => ({
    ...state,
    data: state?.data?.filter(item => action.payload.projectDesignTaskId !== item.id) ?? [],
    isLoading: false
  })),
  on(ProjectDesignTaskActions.DeleteProjectDesignTaskFailure, (state) => ({
    ...state,
    isLoading: false
  })),

  on(ProjectDesignTaskActions.ImproveProjectDescription, (state) => ({
    ...state,
    tasksCompleted:[...state.tasksCompleted, ProjectDesignTaskIdentifier.ImproveProjectDescription]
  })),
  on(ProjectDesignTaskActions.TriggerProjectOrchestration, (state) => ({
    ...state,
    tasksCompleted:[...state.tasksCompleted, ProjectDesignTaskIdentifier.UpdateProjectInformation]
  })),

  on(ProjectDesignTaskActions.ResetStateToInitial, () => initialState)
);
