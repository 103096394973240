import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BreadcrumbOption, FormLayout, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import {
  CustomFunctionDefinitionWithRelations,
  Project,
  ProjectModelWithRelations,
  WorkflowStepFunction
} from '@rappider/rappider-sdk';
import { DataTransformService } from '@rappider/services';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';
import { GetProjectModels } from 'libs/project/src/lib/states/project-model-state/project-model.actions';
import { UpdateWorkflowStepFunction } from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.actions';

@Component({
  selector: 'rappider-workflow-step-function-edit',
  templateUrl: './workflow-step-function-edit.component.html',
  styleUrls: ['./workflow-step-function-edit.component.scss']
})
export class WorkflowStepFunctionEditComponent implements OnInit, OnDestroy {

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.WORKFLOW_STEP_FUNCTION_EDIT',
    type: defaultToolbarTitleHeadingSize
  };
  /* page title */
  title: BreadcrumbOption[];
  /* active project */
  activeProject: Project;
  /* subscriptions */
  subscriptions: Subscription[];
  /* step function */
  workflowStepFunction: any;
  /* step function id */
  workflowStepFunctionId: string;

  projectModels: ProjectModelWithRelations[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  activeCustomFunctionId: string;
  activeCustomFunction: CustomFunctionDefinitionWithRelations;

  editFormConfig = {
    formLayout: FormLayout.Horizontal,
    isSubmitButtonFloat: false
  };

  constructor(
    private store: Store<any>,
    private dataTransformService: DataTransformService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getWorkflowStepFunctionIdFromUrl();
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getWorkflowStepFunctionIdFromUrl() {
    this.workflowStepFunctionId = this.route.snapshot.params['id'];
  }

  /**
   * gets workflow step functions by workflow step function id
   *
   * @param {string} workflowStepFunctionId
   * @memberof WorkflowStepFunctionEditComponent
   */

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectModels(),
      this.subscribeToWorkflowStepFunctions()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: Project) => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.dispatchProjectModels();
        this.setTitle();
      } else {
        this.activeProject = null;
      }
    });
  }

  /**
   * get workflow events by project id
   *
   * @memberof WorkflowStepFunctionEditComponent
   */

  dispatchProjectModels() {
    this.store.dispatch(new GetProjectModels());
  }

  subscribeToProjectModels() {
    return this.store.select(state => state.projectModel.data).subscribe((projectModels: ProjectModelWithRelations[]) => {
      if (projectModels?.length) {
        this.projectModels = projectModels;
        // this.getProjectModelByEndpointId(projectModels);
        // this.setProjectModelSelectOptions();
      } else {
        this.projectModels = null;
      }
    });
  }

  subscribeToWorkflowStepFunctions() {
    return this.store.select(state => state.workflowStepFunction?.data).subscribe((workflowStepFunctions: WorkflowStepFunction[]) => {
      const workflowStepFunction = workflowStepFunctions.find(workflowStepFunction => workflowStepFunction.id === this.workflowStepFunctionId);
      this.activeCustomFunction = workflowStepFunction?.customFunction;
      this.activeCustomFunctionId = workflowStepFunction?.customFunction?.id;
      const publishedEventOnSuccessIds = workflowStepFunction?.publishedEventsOnSuccess?.map(item => item?.id);
      const publishedEventOnFailureIds = workflowStepFunction?.publishedEventsOnFailure?.map(item => item?.id);
      const subscribedEventIds = workflowStepFunction?.subscribedEvents?.map(item => item?.id);
      this.workflowStepFunction = {
        ...workflowStepFunction,
        subscribedEvents: subscribedEventIds,
        publishedEventsOnSuccess: publishedEventOnSuccessIds,
        publishedEventsOnFailure: publishedEventOnFailureIds
      };
      this.setTitle();
    });
  }

  /**
   * set page title
   *
   * @memberof WorkflowStepFunctionEditComponent
   */
  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.WORKFLOW_STEP_FUNCTION_LIST',
        redirectUrl: `${PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_LIST}`
      },
      {
        label: 'Edit ' + this.workflowStepFunction?.name
      }
    ];
  }

  /**
   * compare the edited and current subscribed and published events and call
   * send request function on form submit
   *
   * @memberof WorkflowStepFunctionEditComponent
   */
  onEditWorkflowStepFunction(formValue) {
    this.sendEditRequest(
      formValue,
      this.compareSubscribedEvents(formValue),
      this.comparePublishedEventsOnSuccess(formValue),
      this.comparePublishedEventsOnFailure(formValue)
    );
  }

  /* TODO: Will be moved to state */
  /**
   * sends api request with added and deleted subscribed evnets
   *
   * @param {any} editedStepFunction
   * @param {Object} editedSubscribedEventIds
   * @param {Object} editedPublishedEventOnSuccessIds
   * @param {Object} editedPublishedEventOnFailureIds
   * @memberof WorkflowStepFunctionEditComponent
   */
  sendEditRequest(
    editedStepFunction: any,
    editedSubscribedEventIds: any,
    editedPublishedEventOnSuccessIds: any,
    editedPublishedEventOnFailureIds: any,
  ) {
    const workflowStepFunction = {
      id: this.workflowStepFunction.id,
      name: this.workflowStepFunction.name,
      projectId: this.workflowStepFunction.projectId,
      endpointId: this.workflowStepFunction.endpointId,
    };
    const params = {
      workflowStepFunctionId: this.workflowStepFunctionId,
      workflowStepFunctionBody: {
        workflowStepFunction: {
          ...workflowStepFunction,
          name: editedStepFunction.name,
          endpointId: editedStepFunction.endpointId || null,
          customFunction: this.activeCustomFunction,
        },
        ...editedSubscribedEventIds,
        ...editedPublishedEventOnSuccessIds,
        ...editedPublishedEventOnFailureIds
      }
    };
    this.store.dispatch(UpdateWorkflowStepFunction({ payload: params }));
  }

  /**
   * gets initial and final step events from workflow step function and compares them
   * returns added and deleted items
   *
   * @param {*} initialStepFunction
   * @param {*} finalStepFunction
   * @return {*}
   * @memberof WorkflowStepFunctionEditComponent
   */
  compareEvents(initialStepFunction: any, finalStepFunction: any) {
    const stepFunctionChanges = this.dataTransformService.compareArrays(initialStepFunction, finalStepFunction);

    const addedEventIds = stepFunctionChanges.createdArray.map(item => item.id);
    const deletedEventIds = stepFunctionChanges.deletedArray.map((item: any) => item.id);

    return {
      addedEventIds,
      deletedEventIds
    };
  }

  /**
   * compare changes in subscribed events
   *
   * @param {any} workflowStepFunction
   * @return {*}
   * @memberof WorkflowStepFunctionEditComponent
   */
  compareSubscribedEvents(workflowStepFunction: any) {
    const initialStepFunction = this.workflowStepFunction.subscribedEvents?.map(item => ({ id: item }));
    const finalStepFunction = workflowStepFunction.subscribedEvents?.map(item => ({ id: item }));
    if (finalStepFunction) {
      return {
        subscribedEventIds: finalStepFunction?.map(item => item.id),
      };
    } else {
      return {
        subscribedEventIds: initialStepFunction?.map(item => item.id),
      };
    }
  }

  /**
   * compare changes in published events on success
   *
   * @param {any} workflowStepFunction
   * @return {*}
   * @memberof WorkflowStepFunctionEditComponent
   */
  comparePublishedEventsOnSuccess(workflowStepFunction: any) {
    const initialStepFunction = this.workflowStepFunction.publishedEventsOnSuccess?.map(item => ({ id: item }));
    const finalStepFunction = workflowStepFunction.publishedEventsOnSuccess?.map(item => ({ id: item }));
    if (finalStepFunction) {
      return {
        publishedEventOnSuccessIds: finalStepFunction?.map(item => item.id),
      };
    } else {
      return {
        publishedEventOnSuccessIds: initialStepFunction?.map(item => item.id),
      };
    }
  }

  /**
   * compare changes in published events on failure
   *
   * @param {any} workflowStepFunction
   * @return {*}
   * @memberof WorkflowStepFunctionEditComponent
   */
  comparePublishedEventsOnFailure(workflowStepFunction: any) {
    const initialStepFunction = this.workflowStepFunction.publishedEventsOnFailure?.map(item => ({ id: item }));
    const finalStepFunction = workflowStepFunction.publishedEventsOnFailure?.map(item => ({ id: item }));
    if (finalStepFunction) {
      return {
        publishedEventOnFailureIds: finalStepFunction?.map(item => item.id),
      };
    } else {
      return {
        publishedEventOnFailureIds: initialStepFunction?.map(item => item.id)
      };
    }
  }

  navigateToListPage() {
    this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_LIST }));
  }

}
