<div class="component-definitions-previews-custom-classes">
<section *ngIf="!categorizedComponentDefinitionsGroup;">
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</section>
<!-- HEADER -->
<section>
  <div class="category-preview-header">
    <div>
      <h3> {{categorizedComponentDefinitionsGroup?.title}} </h3>
      <p> {{categorizedComponentDefinitionsGroup?.description}}</p>
    </div>
    <rappider-button-group class="component-preview-button-group"
                           [buttons]="buttons"
                           (buttonClick)="onGridHandlerButtonClick($event)"></rappider-button-group>
  </div>
  <div *ngIf="categorizedComponentDefinitionsGroup?.title === 'Elements'"
       class="search mt-3">
    <rappider-search [primarySearchBox]="searchBox"
                     (primarySearchTextChange)="onSearchTextChange($event)"></rappider-search>
  </div>
</section>

<!-- COMPONENTS -->
<ng-container
              *ngFor="let subCategory of searchValue ? (elementsForSearchResult | keyvalue) : elementsWithSubCategory | keyvalue">
  <h5>{{subCategory.key}} <rappider-tag [text]="{text: subCategory.value.length}"></rappider-tag>
  </h5>

  <div class="components-container"
       [style.grid-template-columns]="'repeat('+columnNumber+', minmax(0, 1fr))'">
    <ng-container *ngFor="let component of subCategory.value; let index = index">
      <div [class]="'component-card-container'">
        <section class="component-card">
          <div *ngIf="!component.componentDefinition.previewImgUrls?.length || component.componentDefinition.previewImgUrls[0] === 'null'; else imageTemplate"
               class="component-container">
            <ng-container *ngIf="component.componentDefinition.isSystemGenerated; else showcaseContainer">
              <rappider-system-generated-component-renderer [componentDefinitions]="componentDefinitions"
                                                            [systemGeneratedComponentDefinition]="component.componentDefinition"
                                                            [contentTree]="[component.componentDefinition.contentTreeItem]">
              </rappider-system-generated-component-renderer>
            </ng-container>
            <ng-template #showcaseContainer></ng-template>
          </div>
          <ng-template #imageTemplate>
            <div class="component-container">
              <rappider-image
                              [source]="activeTheme === 'dark' ? component?.componentDefinition?.previewImgUrls[1]?.replace('\\', '/')
                              : component?.componentDefinition?.previewImgUrls[0]?.replace('\\', '/')"></rappider-image>
            </div>
          </ng-template>
          <hr class="full-hr" />
          <div class="component-info"
               (click)="onRouteToComponentDefinitionDetail(component.componentDefinition)">
            <h5>{{component.componentDefinition.title}}</h5>
            <div class="component-info-content">
              <div class="component-info-left">
                <rappider-paragraph [content]="component?.componentDefinition?.content"
                                    [colorSettings]="component?.componentDefinition?.colorSettings">
                </rappider-paragraph>
                <rappider-tag *ngFor="let tag of component.componentDefinition.tags"
                              [text]="{text: tag}"> </rappider-tag>
              </div>
              <div class="component-info-right">
                <button [nzTooltipTitle]="'Go to ' + component.componentDefinition.title + ' details' "
                        nzTooltipPlacement="topRight"
                        nz-button
                        nz-tooltip>
                  <i class="fa-solid fa-right fa-2x"></i>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ng-container>
  </div>

</ng-container>
</div>
