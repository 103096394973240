import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

/* Antd */
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

// Rappider Components
import {
  RappiderButtonGroupModule, RappiderButtonModule, RappiderCardOneListModule,
  RappiderCardsModule,
  RappiderCrudFieldsDisplayModule, RappiderHeadingModule, RappiderIconModule,
  RappiderLinkListModule,
  RappiderListGridModule,
  RappiderLottiePlayerModule,
  RappiderParagraphModule,
  RappiderSwitchModule,
  RappiderTextModule,
  RappiderTimelineModule
} from '@rappider/rappider-components';


import { ProjectDesignComponent } from './project-design.smart.component';
import { WizardRabbitComponent } from './components/wizard-rabbit/wizard-rabbit.component';
import { ProjectInfoComponent } from './components/project-info/project-info.component';
import { ProjectDescriptionComponent } from './components/project-description/project-description.component';
import { BaseFeaturesComponent } from './components/base-features/base-features.component';
import { ScopeOfWorkComponent } from './components/scope-of-work/scope-of-work.component';
import { DataModelListComponent } from './components/data-model-list/data-model-list.component';
import { DataModelDetailComponent } from './components/data-model-detail/data-model-detail.component';
import { DataModelsDiagramComponent } from './components/data-models-diagram/data-models-diagram.component';
import { DataModelRelationshipsComponent } from './components/data-model-relationships/data-model-relationships.component';
import { EnvironmentVariablesComponent } from './components/environment-variables/environment-variables.component';
import { EndpointsComponent } from './components/endpoints/endpoints.component';
import { UiClientPagesComponent } from './components/ui-client-pages/ui-client-pages.component';
import { UiAdminPagesComponent } from './components/ui-admin-pages/ui-admin-pages.component';
import { ThemeComponent } from './components/theme/theme.component';
import { GlobalStylesComponent } from './components/global-styles/global-styles.component';
import { SettingsButtonGroupComponent } from './components/settings-button-group/settings-button-group.component';
import { ScopeOfWorkDataStoreModule } from './state/scope-of-work/scope-of-work-data-store.module';
import { RappiderTableModule } from 'libs/components/src/lib/components/table/table.module';
import { RappiderPostItModule } from 'libs/components/src/lib/components/post-it/post-it.module';

import { ArrowHighlightDirective, LazyRenderDirective } from 'libs/shared/src/lib/directives';
import { RappiderMarkdownViewerModule } from 'libs/components/src/lib/components/markdown-viewer/markdown-viewer.module';

import { UserInvitationsComponent } from 'libs/project/src/lib/components/user-invitations/user-invitations.component';

import { CustomEndpointModule } from 'libs/project/src/lib/modules/custom-endpoint/custom-endpoint.module';
import { ContentTreeRendererModule } from 'libs/content-tree-renderer/src/lib/content-tree-renderer.module';

import { UiPageLayoutsComponent } from './components/ui-page-layouts/ui-page-layouts.component';

import { ThemeModule } from '@rappider/theme';
import { ComponentDefinitionComponentsModule } from 'libs/component-definition/src/lib/component-definition-components.module';


import { ProjectEnvironmentVariablesComponent } from 'libs/project/src/lib/components/project-environment-variables/project-environment-variables.component';
import { ProjectSettingsComponent } from 'libs/project/src/lib/components/project-settings/project-settings.component';

import { DiagramEditorForComponentsModule } from 'libs/diagram-editor/src/lib/diagram-editor-for-components.module';

import { MessageTemplateComponent } from './components/message-template/message-template.component';

import { WorkflowDiagramForComponentsModule } from 'libs/workflow-diagram/src/lib/workflow-diagram-for-components.module';
import { ProjectComponentsModule } from 'libs/project/src/lib/project-components.module';

import { NzCardModule } from 'ng-zorro-antd/card';
import { FormatDateModule } from '../../../components/src/lib/utils/common-utils/pipes/format-date-pipe/format-date.module';
import { TranslateModule } from '@ngx-translate/core';

import { AngularSplitModule } from 'angular-split';

import { DataEventsWithoutRoutesModule } from 'libs/project/src/lib/modules/data-events/data-events.without-route.module';
import { RappiderAccordionComponent } from '../../../components/src/lib/components/accordion/accordion.component';

import { ContentEditorComponentsModule } from 'libs/content-editor/src/lib/content-editor.components.module';

import { RappiderAlertModule } from 'libs/components/src/lib/components/alert/alert.module';
import { JoyrideModule } from 'ngx-joyride';

import { CompleteWizardComponent } from './components/complete-wizard/complete-wizard.component';
import { ProjectMembersNavigationComponent } from './components/project-members-navigation/project-members-navigation.component';
import { ProjectDesignTaskDataStoreModule } from './state/project-design-task/project-design-task-data-store.module';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { ServerPageComponent } from './components/server-page/server-page.component';
import { FormsModule } from '@angular/forms';


const routes: Routes = [
  {
    path: '',
    component: ProjectDesignComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ScopeOfWorkDataStoreModule,
    ProjectDesignTaskDataStoreModule,
    NzCollapseModule,
    RappiderCrudFieldsDisplayModule,
    RappiderButtonGroupModule,
    RappiderButtonModule,
    RappiderTableModule,
    RappiderListGridModule,
    RappiderPostItModule,
    RappiderMarkdownViewerModule,
    CustomEndpointModule,
    NzToolTipModule,
    JoyrideModule.forChild(),
    NzButtonModule,
    NzAffixModule,
    NzSkeletonModule,
    NzPopoverModule,
    UserInvitationsComponent,
    ContentTreeRendererModule,
    ThemeModule,
    ComponentDefinitionComponentsModule,
    ProjectSettingsComponent,
    ProjectEnvironmentVariablesComponent,
    DiagramEditorForComponentsModule,
    MessageTemplateComponent,
    WorkflowDiagramForComponentsModule,
    ProjectComponentsModule,
    RappiderCardOneListModule,
    NzCardModule,
    RappiderIconModule,
    FormatDateModule,
    TranslateModule,
    AngularSplitModule,
    DataEventsWithoutRoutesModule,
    RappiderAccordionComponent,
    ContentEditorComponentsModule,
    RappiderCardsModule,
    RappiderAlertModule,
    RappiderHeadingModule,
    RappiderLinkListModule,
    RappiderTextModule,
    RappiderLottiePlayerModule,
    RappiderTimelineModule,
    RappiderParagraphModule,
    NzDescriptionsModule,
    NzBadgeModule,
    RappiderSwitchModule,
    FormsModule
  ],
  declarations: [
    ProjectDesignComponent,
    CompleteWizardComponent,
    WizardRabbitComponent,
    ProjectInfoComponent,
    ProjectDescriptionComponent,
    SettingsButtonGroupComponent,
    BaseFeaturesComponent,
    ScopeOfWorkComponent,
    DataModelListComponent,
    DataModelDetailComponent,
    DataModelsDiagramComponent,
    DataModelRelationshipsComponent,
    EnvironmentVariablesComponent,
    EndpointsComponent,
    UiClientPagesComponent,
    UiAdminPagesComponent,
    UiPageLayoutsComponent,
    ThemeComponent,
    GlobalStylesComponent,
    LazyRenderDirective,
    ArrowHighlightDirective,
    ProjectMembersNavigationComponent,
    ServerPageComponent
  ],
  exports: [
    ProjectDesignComponent,
    CompleteWizardComponent,
    WizardRabbitComponent,
    ServerPageComponent
  ]
})
export class ProjectDesignModule { }
