import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, featureKey } from './process-info.reducer';

/*
    Create feature selector to get the whole `processInfo` state
*/
export const selectProcessInfoState = createFeatureSelector<State>(featureKey);

/*
    Selector to get all process infos
*/
export const selectAllProcessInfos = createSelector(
  selectProcessInfoState,
  (state) => state.data
);

/*
    Selector to get loaded state
*/
export const selectProcessInfoLoaded = createSelector(
  selectProcessInfoState,
  (state) => !!state.loaded
);

/*
    Selector to get the last polling date
*/
export const selectLastPollingDate = createSelector(
  selectProcessInfoState,
  (state) => state.lastPollingDate
);

/*
    Selector to get the error state
*/
export const selectProcessInfoError = createSelector(
  selectProcessInfoState,
  (state) => state.error
);

/*
    Selector to get a process info by id
*/
export const selectProcessInfoById = (id: string) => createSelector(
  selectAllProcessInfos,
  (processInfos) => processInfos.find(info => info.id === id)
);
