import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as CategoryActions from './category.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';

import { NotificationService } from '@rappider/services';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

/* Named import */
import { CategoryControllerService as ApiService } from '@rappider/rappider-sdk';
import * as ComponentDefinitionActions from 'libs/component-definition/src/lib/state/component-definition.actions';
import * as ProjectActions from 'libs/project/src/lib/states/projects-state/project.actions';
import { orderBy } from 'lodash';

export const navigatePathAfterCreatingInstance = '/category/list';

@Injectable()
export class CategoryEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  getCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentDefinitionActions.ActionTypes.LoadComponentDefinitionsAfterGetCategories, <any>CategoryActions.GetCategories, ActiveProjectActions.ActionTypes.SetActiveProject),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        /* set a filter */
        const filter = {
          where: {
            or: [
              {
                projectId: activeProjectId
              },
              {
                projectId: undefined
              }
            ]
          },
          order: ['name ASC']
        };
        /* get the Categories */
        return this.apiService.find({ filter }).pipe(
          mergeMap(categories => {
            const actions: Action[] = [];
            actions.push(CategoryActions.GetCategoriesSuccessful({ payload: { categories } }));
            if (action.payload?.navigateToLoadComponentDefinitions) {
              actions.push(new ComponentDefinitionActions.LoadComponentDefinitions());
            }
            return actions;
          }),
          catchError((error) => [
            CategoryActions.GetCategoriesFailure()
          ])
        );
      })
    )
  );

  createCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.CreateCategory),
      withLatestFrom(
        this.store.select(state => state.activeProject.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {
        const params = {
          body: {
            ...action.payload.category
          }
        };

        return this.apiService.create(params).pipe(
          mergeMap((category) => {
            this.notificationService.createNotification(
              'success',
              params.body.name,
              'SHARED.SUCCESSFULLY_CREATED'
            );
            if (action.payload.navigateToListAfterCreate) {
              return [
                CategoryActions.CreateCategorySuccessful({ payload: { category: category } }),
                new Navigate({ url: PATH_DEFINITIONS.CATEGORIES.CATEGORY_LIST })
              ];
            } else {
              return [
                CategoryActions.CreateCategorySuccessful({ payload: { category: category } })
              ];
            }
          }),
          catchError((error) => {
            const errorMessage = error?.error?.error?.message || 'SHARED.COULDNT_CREATED';
            this.notificationService.createNotification(
              'error',
              params.body.name,
              errorMessage
            );
            return [
              CategoryActions.CreateCategoryFailure(),
              CategoryActions.ErrorAction({ payload: { error: error, key: 'CreateCategory', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  createCategorySuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.CreateCategorySuccessful),
      map(action =>
        new Navigate({ url: navigatePathAfterCreatingInstance })
      )
    )
  );

  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.UpdateCategory),
      mergeMap(action => {
        const params = {
          id: action.payload.categoryId,
          body: action.payload.category
        };

        return this.apiService.updateById(params).pipe(
          mergeMap((category) => {
            this.notificationService.createNotification(
              'success',
              params.body.name,
              'SHARED.SUCCESSFULLY_UPDATED'
            );
            if (action.payload.navigateToListAfterUpdate) {
              return [
                CategoryActions.UpdateCategorySuccessful({ payload: { categoryId: action.payload.categoryId, category: category } }),
                new Navigate({ url: PATH_DEFINITIONS.CATEGORIES.CATEGORY_LIST })
              ];
            } else {
              return [
                CategoryActions.UpdateCategorySuccessful({ payload: { categoryId: action.payload.categoryId, category: category } })
              ];
            }
          }
          ),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              params.body.name,
              'SHARED.COULDNT_UPDATED'
            );
            return [
              CategoryActions.UpdateCategoryFailure(),
              CategoryActions.ErrorAction({ payload: { error: error, key: 'UpdateCategory', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  updateCategorySuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.UpdateCategorySuccessful),
      mergeMap(action => [
        new Navigate({ url: navigatePathAfterCreatingInstance }),
        CategoryActions.GetCategories()
      ])
    )
  );

  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.DeleteCategory),
      withLatestFrom(
        this.store.select(state => state.category?.data)
      ),
      mergeMap(([action, Categories]) => {
        const params = { id: action.payload.categoryId };
        const deletedCategory = Categories.find(category => category.id === action.payload.categoryId);

        return this.apiService.deleteById(params).pipe(
          map(() => {
            this.notificationService.createNotification(
              'success',
              deletedCategory.name,
              'SHARED.SUCCESSFULLY_DELETED'
            );
            return CategoryActions.GetCategories();
          }),
          catchError((error) => {
            this.notificationService.createNotification(
              'error',
              deletedCategory.name,
              'SHARED.COULDNT_DELETED'
            );
            return [
              CategoryActions.ErrorAction({ payload: { error: error, key: 'DeleteCategory', timeStamp: Date.now() } })
            ];
          })
        );
      })
    )
  );

  /* Old actions can not listen on new reducers. So we use this effect temp. */
  resetStateToInitial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.Logout>(AuthenticationActions.ActionTypes.Logout),
      mergeMap(action => [
        CategoryActions.ResetStateToInitial()
      ])
    )
  );

  resetStateToInitialChangeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.ChangeActiveProjectSuccessful>(AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful),
      mergeMap(action => [
        CategoryActions.ResetStateToInitial()
      ])
    )
  );

  getPublicProjectsCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.ActionTypes.GetPublicProjectsCategories),
      mergeMap(() => {
        const params = {
          filter: {
            where: {
              type: 'project'
            },
            order: ['name ASC']
          }
        };
        return this.apiService.findPublicCategories(params).pipe(
          map(publicProjectsCategories =>
            new ProjectActions.GetPublicProjectsCategoriesSuccessful({ publicProjectsCategories })
          ),
          catchError((error) => [
            CategoryActions.GetCategoriesFailure()
          ])
        );
      })
    )
  );

}
