import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueryParam, PATH_DEFINITIONS, PAGE_DEFINITIONS, AUTH_REDIRECTION_DEFINITIONS, UsernameType } from '../../definitions';
import { RouterStateService } from '../../services';
import { EMAIL_REGEX } from '@rappider/shared/definitions';

@Injectable({
  providedIn: 'root'
})
export class VerifyPersonGuard implements CanActivate {

  constructor(
    private store: Store<any>,
    private routerStateService: RouterStateService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    // console.log('VERIFY PERSON GUARD');
    return this.store.select(s => s.auth.activePerson).pipe(
      withLatestFrom(
        this.store.select(s => s.auth.user)
      ),
      map(([person, user]) => {
        if (person) {
          if (!person.isVerified) {
            // Social media user check
            const isSocialMediaUser = user?.usernameType !== UsernameType.Email || !EMAIL_REGEX.test(user?.username);
            // console.log('VerifyPersonGuard - Is social media user:', isSocialMediaUser);

            // Skip redirect for social media users
            if (!isSocialMediaUser) {
              this.routerStateService.addQueryParam(QueryParam.RedirectUrl, state.url);
              if (user.usernameType === UsernameType.Email) {
                this.routerStateService.navigate(PATH_DEFINITIONS.AUTH.ACCOUNT_VERIFICATION_PATH);
              } if (user.usernameType === UsernameType.PhoneNumber) {
                this.routerStateService.navigate(AUTH_REDIRECTION_DEFINITIONS.LOGIN.LOGIN_WITH_PHONE_NUMBER_VERIFY_REDIRECTION_PATH);
              }
            }
          }
          return true;
        }
        return true;
      })
    );
  }

}
