/* eslint-disable max-len */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionResponse, AlertConfig, AlertTypes, HeadingType } from '@rappider/rappider-components/utils';
import { Module, ModuleWithRelations } from '@rappider/rappider-sdk';
import { MODULE_LIST_CONFIG } from '@rappider/shared/configs';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';
import { DeleteModule } from '../../state/module.actions';
import { BulkDeletePages, BulkUpdatePages } from 'libs/pages/src/lib/state/page-state/page.actions';
import { Page } from '@rappider/api-sdk';
import { ModuleDeleteOptions, moduleDeleteOptions } from './utils/radio-type.enum';
import { getModulesWithDefaultModule } from '../../state/selectors/get-modules-with-default-module.selector';

@Component({
  selector: 'rappider-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss']
})
export class ModuleListComponent implements OnInit, OnDestroy {
  MODULE_LIST_CONFIG = MODULE_LIST_CONFIG;
  MODULE_ALERT_CONFIG: AlertConfig = {
    type: AlertTypes.Info,
    title: {
      type: HeadingType.H5,
      content: '',
    },
    showIcon: true,
    marginSettings: {
      top: 'var(--margin-3x)'
    },
    closeable: false
  };


  modules: Module[];
  defaultModuleId: string;
  subscriptions: Subscription[];

  isLoading = false;
  pagesDeleteLoading = false;

  pages: Page[];
  modalVisibility = false;
  relatedPages: Page[];
  selectedModule: Module;

  moduleDeleteOptions = moduleDeleteOptions;
  moduleDeleteOption: ModuleDeleteOptions = ModuleDeleteOptions.DeleteModuleWithPages;
  ModalSelectRadioOption = ModuleDeleteOptions;
  selectModuleOptions = [];
  matchingPageNames = [];
  selectedModuleId: string;

  modalOkDisabled = false;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subsription => subsription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToModules(),
      this.subscribeToModulesLoadingState(),
      this.subscribeToPages(),
      this.subscribeToPageTransferLoading()
    ];
  }

  subscribeToModules() {
    return this.store.select(getModulesWithDefaultModule).subscribe((modules: Array<ModuleWithRelations & { isDefaultModule?: boolean }>) => {
      this.modules = modules ?? [];
    });
  }


  subscribeToModulesLoadingState() {
    return this.store.select(state => state.module.isLoading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }


  subscribeToPageTransferLoading() {
    return this.store.select(state => state.page.submitButtonLoading).subscribe((submitButtonLoading: boolean) => {
      this.pagesDeleteLoading = submitButtonLoading;
    });
  }


  subscribeToPages() {
    return this.store.select(state => state.page.data).subscribe((pages: Page[]) => {
      this.pages = pages;
    });
  }

  /**
   * navigates to module details when clicked on module name
   *
   * @param {Module} module
   * @memberof ModuleListComponent
   */
  onModuleNameClick(module: Module) {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.MODULE.MODULE_DETAIL_PATH}/${module.id}` }));
  }

  onColumnActionClick(actionResponse: ActionResponse) {
    this.selectedModule = <Module>actionResponse.data;

    if (actionResponse.action.name === 'DELETE_ITEM') {
      this.showDeleteModuleModal(this.selectedModule);
    }

    if (actionResponse.action.name === 'editModule') {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.MODULE.MODULE_EDIT_PATH}/${this.selectedModule.id}` }));
    }
  }

  onRadioChange(event: ModuleDeleteOptions) {
    this.moduleDeleteOption = event;
    if (event === ModuleDeleteOptions.DeleteModuleWithPages) {
      this.modalOkDisabled = false;
      this.selectedModuleId = null;
    } else {
      this.modalOkDisabled = true;
    }
  }

  onModuleChange(moduleId: string) {
    if (!moduleId) {
      this.modalOkDisabled = true;
    } else {
      this.transfaredPagesControl(moduleId);
    }
  }

  transfaredPagesControl(selectedModuleId: string) {
    if (this.relatedPages?.length) {
      const deletedModulePages = this.relatedPages.map((page: Page) => page.slug);
      const selectedModulePages = this.pages.filter((page: Page) => page.moduleId === selectedModuleId);
      const selectedModulePagesIds = selectedModulePages.map((page: Page) => page.slug);
      const matchingSlugs = deletedModulePages.filter((slug: string) => selectedModulePagesIds.includes(slug));
      this.matchingPageNames = matchingSlugs.map((slug: string) => this.pages.find((page: Page) => page.slug === slug).title);

      if (matchingSlugs?.length) {
        this.modalOkDisabled = true;
      } else {
        this.modalOkDisabled = false;
      }
    }
  }

  showDeleteModuleModal(module: Module) {
    this.modalVisibility = true;
    this.MODULE_ALERT_CONFIG.title.content = `Are you sure you want to delete the ${module.name} module?`;

    this.relatedPages = this.pages.filter(page => page.moduleId === module.id);

    if (this.relatedPages?.length) {
      this.selectModuleOptions = this.modules.filter((mod: Module) => mod.id !== module.id).map((mod: Module) => ({ key: mod.title, value: mod.id }));
    }
  }

  onDeleteModal() {
    const pagesNames = this.relatedPages.map((page: Page) => page.title);

    if (this.moduleDeleteOption === ModuleDeleteOptions.DeleteModuleWithPages) {
      if (this.relatedPages?.length) {
        const deletedPages = this.relatedPages.map((page: Page) => page.id);
        this.store.dispatch(DeleteModule({ payload: { moduleId: this.selectedModule.id } }));
        this.store.dispatch(new BulkDeletePages({ pageNames: pagesNames, pageIds: deletedPages }));

      } else {
        this.store.dispatch(DeleteModule({ payload: { moduleId: this.selectedModule.id } }));
      }
    } else {
      if (!this.selectedModuleId) {
        return;
      }
      const transfaredModuleName = this.selectModuleOptions.find((mod: Module) => mod.value === this.selectedModuleId)?.key;
      const transfaredPages = this.relatedPages.map((page: Page) => ({
        id: page.id,
        data: {
          moduleId: this.selectedModuleId,
        }
      }));
      this.store.dispatch(DeleteModule({ payload: { moduleId: this.selectedModule.id } }));
      this.store.dispatch(new BulkUpdatePages({ body: transfaredPages, pageNames: pagesNames, moduleName: transfaredModuleName }));
    }
    this.onCancelModal();
  }

  onCancelModal() {
    this.closeModal();
  }

  closeModal() {
    this.modalVisibility = false;
    this.modalOkDisabled = false;
    this.selectedModuleId = null;
    this.moduleDeleteOption = ModuleDeleteOptions.DeleteModuleWithPages;
  }

}
