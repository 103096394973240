import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { PersonNotificationControllerService } from '@rappider/rappider-sdk';
import { environment } from 'apps/rappider/src/environments/environment';
import { Store } from '@ngrx/store';
import { NotificationStatus } from '../../definitions/notification/notification-status.enum';

import * as NotificationActions from './notification.actions';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import { Router } from '@angular/router';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';
import { BrowserStorageManagementService } from '../../services/browser-storage-management-service/browser-storage-management.service';

@Injectable()
export class NotificationEffects {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private notificationApi: PersonNotificationControllerService,
    public router: Router,
    private browserStorageManagementService: BrowserStorageManagementService
  ) { }

  getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NotificationActions.GetNotifications
      ),
      switchMap(() => this.notificationApi.find().pipe(
        switchMap(notifications => [NotificationActions.GetNotificationsSuccessful({ notifications }), NotificationActions.GetNotificationsSuccessfulWithDelay()]),
        catchError(error => [NotificationActions.GetNotificationsFailure({ error, key: 'GetNotifications', time: Date.now() })])
      ))
    )
  );

  getNotificationsWithDelay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.GetNotificationsSuccessfulWithDelay),
      withLatestFrom(
        this.store.select(state => state.activeProject.data)
      ),
      filter(([action, activeProject]) => activeProject),
      delay(environment.delayTimeForNotificationApiCall),
      map(() => NotificationActions.GetNotifications())
    )
  );

  setNotificationStatusAsSeen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.SetNotificationStatusAsSeen),
      mergeMap((action) => this.notificationApi.updateById({ id: action.notificationId, body: { status: NotificationStatus.Read } }).pipe(
        map(() => NotificationActions.SetNotificationStatusAsSeenSuccessful({ notificationId: action.notificationId }))
      ))
    )
  );

  changeActiveProjectWithNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(<any>NotificationActions.ChangeActiveProjectWithNotification, ActiveProjectActions.ActionTypes.SelectActiveProjectSuccessful),
      withLatestFrom(this.store.select(state => state.notification?.changeActiveProjectWithNotification)),
      map(([action, changeActiveProjectWithNotification]) => {
        if (!this.browserStorageManagementService.getLocalStorageItem('activeProjectId') || this.browserStorageManagementService.getLocalStorageItem('activeProjectId') === action?.payload?.project?.id || changeActiveProjectWithNotification.isProjectChangeWithoutRefresh) {
          if (changeActiveProjectWithNotification.isProjectChange && action?.payload?.project?.id !== changeActiveProjectWithNotification.projectId) {
            return new ActiveProjectActions.SelectActiveProject({ projectId: changeActiveProjectWithNotification.projectId, navigateToProjectDetail: false });
          } else if (changeActiveProjectWithNotification.isProjectChange && action?.payload?.project?.id === changeActiveProjectWithNotification.projectId) {
            if(changeActiveProjectWithNotification.commentType === 'page'){
              this.router.navigate([`${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}`, changeActiveProjectWithNotification.pageId], { queryParams: { activeCommentId: changeActiveProjectWithNotification.commentId } });
              return NotificationActions.ChangeActiveProjectWithNotification({ changeActiveProjectWithNotification: { commentType:null,pageId: null, commentId: null, projectId: null, isProjectChange: false, isProjectChangeWithoutRefresh: false, activeProjectLoadedThroughNotification: true } });
            }else if(changeActiveProjectWithNotification.commentType === 'ui-diagram'){
              this.router.navigate(['ui-workflow-diagram'], { queryParams: { activeCommentId: changeActiveProjectWithNotification.commentId } });
              return NotificationActions.ChangeActiveProjectWithNotification({ changeActiveProjectWithNotification: { commentType:null,pageId: null, commentId: null, projectId: null, isProjectChange: false, isProjectChangeWithoutRefresh: false, activeProjectLoadedThroughNotification: true } });
            }
          }
        }
        return { type: 'NO_ACTION' };
      })
    )
  );

}
