import { Injectable } from '@angular/core';
import { map, mergeMap, Observable, of } from 'rxjs';

export const ROOT_TABLE_NAME = '_root';

/**
 * Improved version of the synced IndexDB Datastore service
 * Saves and retrieves data from the local IndexDB
 * Data is synced with the remote server with configuration
 * As a result, the data is always up-to-date, and no no need to handle by the component itself
 * Additionally, the data is stored in the local IndexDB for offline use
 *
 * @export
 * @class SyncedDataStoreService
 */
@Injectable({
  providedIn: 'root'
})
export class SyncedLocalDatabaseService {

  constructor() { }

  public getRootVariableValue<T>(key: string, databaseName: string): Observable<T> {
    const tableName = ROOT_TABLE_NAME;
    return this._getDataFromLocalDB(key, tableName, databaseName).pipe(
      mergeMap((data: T) => of(data as T))
    );
  }

  public setRootVariableValue<T>(key: string, value: T, databaseName: string): Observable<boolean> {
    const tableName = ROOT_TABLE_NAME;
    return this._saveDataToLocalDB(key, value, tableName, databaseName).pipe(
      map((response) => !!response)
    );
  }

  public getEntityFromTableById<T>(id: string, tableName: string, databaseName: string): Observable<T | null> {
    return this._getDataFromLocalDB('data', tableName, databaseName).pipe(
      mergeMap((data: T[]) => {
        if (data?.length) {
          const entity = data.find((item: T) => item['id'] === id);
          return of(entity || null);
        } else {
          return of(null);
        }
      })
    );
  }

  public getAllRootVariables(databaseName: string): Observable<Record<string, any>> {
    const tableName = ROOT_TABLE_NAME;
    return this._getAllDataFromLocalDB(tableName, databaseName).pipe(
      mergeMap((data: Record<string, any>) => of(data)));
  }

  public getAllEntitiesFromTable<T>(tableName: string, databaseName: string): Observable<T[]> {
    return this._getDataFromLocalDB('data', tableName, databaseName).pipe(
      mergeMap((data: T[]) => data?.length ? of(data) : of([])));
  }

  public addEntityToTable<T>(entity: T, tableName: string, databaseName: string): Observable<boolean> {
    return this._getDataFromLocalDB('data', tableName, databaseName).pipe(
      mergeMap((data: T[]) => {
        data = data || [];
        data.push(entity);
        return this._saveDataToLocalDB('data', data, tableName, databaseName);
      })
    );
  }

  // public updateEntitiesInTable<T>(tableName: string, entity: T): Observable<boolean> {
  // }

  // public deleteEntitiesFromTable(tableName: string, id: string): Observable<boolean> {
  // }

  private _getAllDataFromLocalDB(tableName: string, databaseName: string): Observable<any> {
    return new Observable((subscriber) => {
      this._openDatabase(tableName, databaseName).then((db) => {
        const transaction = db.transaction(tableName, 'readonly');
        const store = transaction.objectStore(tableName);

        // Get all keys and values
        const keysRequest = store.getAllKeys();
        const valuesRequest = store.getAll();

        keysRequest.onsuccess = () => {
          valuesRequest.onsuccess = () => {
            const keys = keysRequest.result ?? [];
            const values = valuesRequest.result ?? [];

            const dataObject: Record<string, any> = {};
            // Create an object mapping keys to values
            keys.forEach((key: string | number, index) => {
              dataObject[key] = values[index];
            });

            // Emit the data object and complete the observable
            subscriber.next(dataObject);
            subscriber.complete();
          };

          valuesRequest.onerror = () => {
            subscriber.error(valuesRequest.error);
            subscriber.complete();
          };
        };

        keysRequest.onerror = () => {
          subscriber.error(keysRequest.error);
          subscriber.complete();
        };
      }).catch((error) => {
        // Handle database connection errors
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }


  private _getDataFromLocalDB(key: IDBValidKey, tableName: string, databaseName: string): Observable<any> {
    return new Observable((subscriber) => {
      this._openDatabase(tableName, databaseName).then((db) => {
        const transaction = db.transaction(tableName, 'readonly');
        const store = transaction.objectStore(tableName);
        const request = store.get(key);

        request.onsuccess = () => {
          console.log('Local data:', request.result);

          // Set a fallback value in case data is null
          const fallbackOnNullValue = request.result?.__fallbackOnNullValue || null;
          const createdData = request.result?.created ?? fallbackOnNullValue;
          const updatedData = request.result?.updated ?? fallbackOnNullValue;
          const mergedData = { ...createdData, ...updatedData };

          // Emit the data and complete the observable
          subscriber.next(mergedData);
          subscriber.complete();
        };

        request.onerror = () => {
          // Emit an error and complete the observable
          subscriber.error(request.error);
          subscriber.complete();
        };
      }).catch((error) => {
        // Handle database connection errors
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }

  private _saveDataToLocalDB(key: IDBValidKey, data: any, tableName?: string, databaseName?: string): Observable<boolean> {
    return new Observable((subscriber) => {
      this._openDatabase(tableName, databaseName)
        .then((db) => {
          const transaction = db.transaction(tableName, 'readwrite');
          const store = transaction.objectStore(tableName);
          const request = store.put(data, key);

          request.onsuccess = () => {
            console.log('*** ClientLocalState Saved to IndexDB successfully', key, data, tableName, databaseName);
            subscriber.next(true); // Notify that the save was successful
            subscriber.complete(); // Complete the observable
          };

          request.onerror = () => {
            console.log('Error saving data:', request.error);
            subscriber.error(request.error); // Notify the subscriber of the error
            subscriber.complete(); // Complete the observable
          };

          // Handle transaction error in case of failure
          transaction.onerror = (event) => {
            console.log('Transaction error:', transaction.error);
            subscriber.error(transaction.error);
            subscriber.complete();
          };
        })
        .catch((error) => {
          console.log('Error opening database:', error);
          subscriber.error(error); // Notify of an error if opening the database fails
          subscriber.complete();
        });
    });
  }

  private async _openDatabase(tableName: string, databaseName: string): Promise<IDBDatabase> {

    const db = await this._checkAndOpenDatabase(databaseName);
    if (!db.objectStoreNames.contains(tableName)) {
      db.close();
      return this._createEntityTable(tableName, databaseName);
    }

    return db;
  }

  private _checkAndOpenDatabase(databaseName: string): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(databaseName);
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  private _createEntityTable(tableName: string, databaseName: string): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {

      const request = indexedDB.open(databaseName, new Date().getTime()); // Increment version dynamically

      request.onupgradeneeded = () => {
        const db = request.result;
        if (!db.objectStoreNames.contains(tableName)) {
          db.createObjectStore(tableName);
        }
      };

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }


}
