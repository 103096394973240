import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionResponse } from '../../utils/action-utils';

@Component({
  selector: 'rappider-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RappiderTableComponent {

  @Input() config: any;
  @Input() data: any[];
  @Input() enableRowPointer: boolean;

  @Output() rowActionClick = new EventEmitter<ActionResponse>();

  defaultPageSize = 25;


  /* True when, select all is checked, ll items are selected */
  allItemsSelected = false;

  /* True when all items are not selected */
  allItemsUnSelected = false;

  fixedColumn = false;

  onRowActionClick(data: ActionResponse) {
    this.rowActionClick.emit(data);
  }

}
