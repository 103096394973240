import { ButtonComponentConfig, ButtonSize, DropdownMenuComponentConfig, DropdownMenuItem, IconType } from '@rappider/rappider-components/utils';

export const CONTAINER_SETTINGS_DROPDOWN_MENU_CONFIG: DropdownMenuComponentConfig = {
  icon: {
    name: 'ellipsis',
    type: IconType.NgZorro,
    size: ButtonSize.Large
  },
  items: [
    {
      label: 'Add Comment',
      data: 'add-comment-to-container',
      icon: {
        name: 'fa-regular fa-comment',
        type: IconType.FontAwesome
      }
    },
    {
      label: 'Copy template to clipboard',
      data: 'copy-to-clipboard',
      icon: {
        name: 'copy',
        type: IconType.NgZorro
      }
    },
    {
      label: 'Clear Page',
      data: 'clear-page',
      icon: {
        name: 'fa-regular fa-broom-wide',
        type: IconType.FontAwesome
      }
    },
    {
      label: 'Container Visibility',
      data: 'add-container-visibility',
      icon: {
        name: 'fa-eye-slash fa-regular',
        type: IconType.FontAwesome
      }
    },
    {
      label: 'Delete Container',
      data: 'delete-container',
      icon: {
        name: 'far fa-trash',
        type: IconType.FontAwesome
      }
    },
  ]
};

export const CONTAINER_SETTINGS_EDIT_TITLE_BUTTON_CONFIG: ButtonComponentConfig = {
  icon: {
    name: 'fa-regular fa-pen-to-square',
    type: IconType.FontAwesome
  },
  tooltipText: 'Edit Container Title'
};

export function containerSettingsDropdownItemsVisibilityFunction(item: DropdownMenuItem, contentTreeItem) {
  if (item.data === 'delete-container' && contentTreeItem.isMainContainer) {
    return false;
  } else if (item.data === 'clear-page' && !contentTreeItem.isMainContainer) {
    return false;
  } else if (item.data === 'add-container-visibility' && contentTreeItem.isMainContainer) {
    return false;
  } else {
    return true;
  }
}
