import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

/* 3rd Part Modules */

/* Guards */
import { AuthGuard } from '@rappider/authentication/guards';
import { ActiveProjectGuard, PersonGuard, ProjectRoleGuardGuard } from '@rappider/shared/guards';
import { VerifyPersonGuard } from '@rappider/shared/guards';

/* State */
import { StoreModule } from '@ngrx/store';
import * as fromProject from './states/active-project-state/active-project.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ActiveProjectEffects } from './states/active-project-state/active-project.effects';

/* Internal Modules */

/* Components */
import { ProjectCreateComponent } from './components/project-create/project-create.component';
import { ProjectCreatePreviewComponent } from './components/project-create-preview/project-create-preview.component';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectEditComponent } from './components/project-edit/project-edit.component';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { ProjectMembersComponent } from './components/project-members/project-members.component';
import { MessageTemplateComponent } from './components/message-template/message-template.component';
import { MessageTemplateListComponent } from './components/message-template-list/message-template-list.component';
import { EditMessageTemplateComponent } from './components/edit-message-template/edit-message-template.component';
import { ProjectRolesComponent } from './components/project-roles/project-roles.component';
import { ProjectMemberListComponent } from './components/project-member-list/project-member-list.component';
import { ProjectExportComponent } from './components/project-export/project-export.component';
import { ProjectVersionListComponent } from './components/project-version-list/project-version-list.component';
import { AddProjectScriptComponent } from './components/add-project-script/add-project-script.component';
import { ProjectScriptListComponent } from './components/project-script-list/project-script-list.component';
import { ProjectVersionCreateComponent } from './components/project-version-create/project-version-create.component';
import { UserInvitationsComponent } from './components/user-invitations/user-invitations.component';
import {
  ProjectModelDataFieldCreateComponent
} from './components/project-model-data-field-create/project-model-data-field-create.component';
import { DataFieldListComponent } from './components/data-field-list/data-field-list.component';
import { DataFieldEditComponent } from './components/data-field-edit/data-field-edit.component';
import { ProjectModelListComponent } from './components/project-model-list/project-model-list.component';
import { ProjectModelCreateComponent } from './components/project-model-create/project-model-create.component';
import { ProjectModelEditComponent } from './components/project-model-edit/project-model-edit.component';
import { Authority, FullPaths, PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { DataSchemaCreateComponent } from './modules/data-schema/components/data-schema-create/data-schema-create.component';
import { ProjectVersionDetailComponent } from './components/project-version-detail/project-version-detail.component';
import { ProjectScriptEditComponent } from './components/project-script-edit/project-script-edit.component';
import { ProjectPackageCreateComponent } from './components/project-package-create/project-package-create.component';
import { ProjectPackageEditComponent } from './components/project-package-edit/project-package-edit.component';
import { ProjectPackageListComponent } from './components/project-package-list/project-package-list.component';
import { DataEventEditComponent } from './modules/data-events/components/data-event-edit/data-event-edit.component';
import {
  ProjectCssStyleCustomizationComponent
} from './components/project-css-style-customization/project-css-style-customization.component';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';
import { ProjectTestMembersComponent } from './components/project-test-members/project-test-members.component';

import { ProjectThemeCreateComponent } from 'libs/theme/src/lib/components/project-theme-create/project-theme-create.component';
import { ProjectThemeEditComponent } from 'libs/theme/src/lib/components/project-theme-edit/project-theme-edit.component';
import { ProjectThemeListComponent } from 'libs/theme/src/lib/components/project-theme-list/project-theme-list.component';
import { ProjectThemeComponent } from 'libs/theme/src/lib/components/project-theme/project-theme.component';
import { LivePreviewComponent } from './components/live-preview/live-preview.component';
import { SafeUrlPipe } from 'libs/shared/src/lib/pipes/safe-url/safe-url.pipe';
import { ProjectComponentsModule } from './project-components.module';
import { ProjectMembersWrapperComponent } from './components/project-members-wrapper/project-members-wrapper.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: FullPaths.ProjectList,
        pathMatch: 'full'
      },
      {
        path: 'create',
        component: ProjectCreateComponent,
        canActivate: [AuthGuard, VerifyPersonGuard],
        data: { displayLivePreviewNotification: false, isHomePricingVisible: false, layoutMode: 'hidden' }
      },
      {
        path: 'preview/:id',
        component: ProjectCreatePreviewComponent,
        canActivate: [AuthGuard, VerifyPersonGuard],
        data: { displayLivePreviewNotification: false, isHomePricingVisible: false, layoutMode: 'hidden' }
      },
      {
        path: 'live-preview/:projectId',
        component: LivePreviewComponent,
        canActivate: [AuthGuard, VerifyPersonGuard],
        data: { displayLivePreviewNotification: false, title: 'Project Preview', layoutMode: 'projectDesign' }
      },
      {
        path: 'detail/:projectId',
        component: ProjectDetailComponent,
        canActivate: [AuthGuard],
        data: { displayLivePreviewNotification: false, isHomePricingVisible: false, layoutMode: 'projectDesign', }
      },
      {
        path: 'edit/:projectId',
        component: ProjectEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'projects/list',
        component: ProjectListComponent,
        canActivate: [AuthGuard, VerifyPersonGuard, PersonGuard],
        data: { displayLivePreviewNotification: false, isHomePricingVisible: false, layoutMode: 'hidden' }
      },
      {
        path: 'list',
        component: ProjectListComponent,
        canActivate: [AuthGuard, VerifyPersonGuard, PersonGuard],
        data: { displayLivePreviewNotification: false, isHomePricingVisible: false }
      },
      {
        // Converted to a standalone component
        path: 'project-settings',
        loadComponent: () => import('./components/project-settings/project-settings.component').then(
          m => m.ProjectSettingsComponent),
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Project Settings', layoutMode: 'projectDesign', }
      },
      {
        // Converted to a standalone component
        path: 'environment-variables',
        loadComponent: () => import('./components/project-environment-variables/project-environment-variables.component').then(
          m => m.ProjectEnvironmentVariablesComponent),
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Environment Variables', layoutMode: 'projectDesign', }
      },
      {
        path: 'members',
        component: ProjectMembersComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Project Members', layoutMode: 'projectDesign', }
      },
      {
        path: 'members-list',
        component: ProjectMembersWrapperComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Project Members', layoutMode: 'projectDesign', }
      },
      {
        path: 'test-members',
        component: ProjectTestMembersComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Test Accounts', layoutMode: 'projectDesign', }
      },
      {
        path: 'message-template',
        component: MessageTemplateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Message Templates', layoutMode: 'projectDesign', }
      },
      {
        path: 'edit-message-template/:templateId',
        component: EditMessageTemplateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Edit Message Template', layoutMode: 'projectDesign', }
      },
      {
        path: 'message-template-list',
        component: MessageTemplateListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Message Templates', layoutMode: 'projectDesign', }
      },
      {
        path: 'project-roles',
        component: ProjectRolesComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Roles', layoutMode: 'projectDesign', }
      },
      {
        path: 'members/:roleId',
        component: ProjectMemberListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Members', layoutMode: 'projectDesign', }
      },
      {
        path: 'export/:projectVersionId',
        component: ProjectExportComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Export', layoutMode: 'projectDesign', }
      },
      {
        path: 'versions',
        component: ProjectVersionListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Versions', layoutMode: 'projectDesign', },
      },
      {
        path: 'create-theme',
        component: ProjectThemeCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
      },
      {
        path: 'edit-theme/:id',
        component: ProjectThemeEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Edit Theme', layoutMode: 'projectDesign', }
      },
      {
        path: 'theme-list',
        component: ProjectThemeListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'User Interface | Theme', layoutMode: 'projectDesign', }
      },
      {
        path: 'theme-settings/:id',
        component: ProjectThemeComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Theme Settings', layoutMode: 'projectDesign', }
      },
      {
        path: 'add-project-script',
        component: AddProjectScriptComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Add Script', layoutMode: 'projectDesign', }
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.EDIT_PROJECT_SCRIPT.URL}/:id`,
        component: ProjectScriptEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Edit Script', layoutMode: 'projectDesign', }
      },
      {
        path: 'script-list',
        component: ProjectScriptListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Scripts', layoutMode: 'projectDesign', }
      },
      {
        path: 'version-create',
        component: ProjectVersionCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Create Version', layoutMode: 'projectDesign', }
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECTS_VERSION_DETAIL.URL}/:projectVersionId`,
        component: ProjectVersionDetailComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Version', layoutMode: 'projectDesign', }
      },
      {
        path: 'user-invitations',
        component: UserInvitationsComponent,
        data: [
          Authority.ProjectOwner,
          Authority.ProjectManager
        ],
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard, ProjectRoleGuardGuard],
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_FIELD_CREATE.URL}/:projectModelId`,
        component: ProjectModelDataFieldCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Create Data Field', layoutMode: 'projectDesign', }
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_FIELD_LIST.URL}/:projectModelId`,
        component: DataFieldListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Data Fields', layoutMode: 'projectDesign', }
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_FIELD_EDIT.URL}/:id`,
        component: DataFieldEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Edit Data Field', layoutMode: 'projectDesign', }
      },
      {
        path: 'model-list',
        component: ProjectModelListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Data Models', layoutMode: 'projectDesign', }
      },
      {
        path: 'model-create',
        component: ProjectModelCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Create Data Model', layoutMode: 'projectDesign', }
      },
      {
        path: 'model-edit/:id',
        component: ProjectModelEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Edit Data Model', layoutMode: 'projectDesign', }
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_SCHEMA_CREATE.URL}`,
        component: DataSchemaCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Create Data Schema', layoutMode: 'projectDesign', }
      },
      {
        path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_LIST.URL,
        component: ProjectPackageListComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Project Packages', layoutMode: 'projectDesign', }
      },
      {
        path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_CREATE.URL,
        component: ProjectPackageCreateComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Create Project Package', layoutMode: 'projectDesign', }
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.PROJECT_PACKAGE_EDIT.URL}/:id`,
        component: ProjectPackageEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Edit Project Package', layoutMode: 'projectDesign', }
      },
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.DATA_EVENT_EDIT.URL}/:id`,
        component: DataEventEditComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Edit Data Event', layoutMode: 'projectDesign', }
      },
      /* project css style customization */
      {
        path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.CSS_STYLE_CUSTOMIZATION.URL}`,
        component: ProjectCssStyleCustomizationComponent,
        canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
        data: { title: 'Customize CSS', layoutMode: 'projectDesign', }
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature(fromProject.activeProjectFeatureKey, fromProject.reducer, {
      initialState: fromProject.initialState
    }),
    EffectsModule.forFeature([ActiveProjectEffects]),
    ProjectComponentsModule
  ],
  providers: [
    FormatDatePipe,
    SafeUrlPipe
  ]
})
export class ProjectModule { }
