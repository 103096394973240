/* Angular */
import { createReducer, on } from '@ngrx/store';

/* Model Interface */
import { ClientLocalState } from '@rappider/rappider-sdk';

/* Actions */
import * as ClientLocalStateActions from './client-local-state.actions';

/* State key */
export const featureKey = 'clientLocalState';

/* State interface */
export interface State {
  data: ClientLocalState[] | null;
  isLoading: boolean;
  loaded?: boolean;
  error: any;
}

/* Initial values */
export const initialState: State = {
  data: null,
  isLoading: false,
  error: null
};

/* Function to calculate updated clientLocalState optimistically */
function updateClientLocalStates(updatedClientLocalStateId: string, updatedClientLocalStateData: Partial<ClientLocalState>, allClientLocalStates: ClientLocalState[]): ClientLocalState[] {
  if (allClientLocalStates?.length) {
    const updatedClientLocalState: ClientLocalState = allClientLocalStates?.find(clientLocalState => clientLocalState.id === updatedClientLocalStateId) || <ClientLocalState>{};
    const updatedClientLocalStateWithNewData = <ClientLocalState>{ ...updatedClientLocalState, ...updatedClientLocalStateData };
    const updatedClientLocalStates: ClientLocalState[] = [
      ...allClientLocalStates.filter(clientLocalState => clientLocalState.id !== updatedClientLocalStateId),
      updatedClientLocalStateWithNewData
    ];
    return updatedClientLocalStates;
  } else {
    return <ClientLocalState[]>[];
  }
}

export const reducer = createReducer(
  initialState,
  on(ClientLocalStateActions.GetClientLocalStatesSuccessful, (state, action) => ({
    ...state,
    data: action.payload.clientLocalStates,
    loaded: true
  })),
  on(ClientLocalStateActions.CreateClientLocalState, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ClientLocalStateActions.CreateClientLocalStateSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data ?? [],
      action.payload.clientLocalState
    ],
    isLoading: false
  })),
  on(ClientLocalStateActions.CreateClientLocalStateFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(ClientLocalStateActions.UpdateClientLocalState, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ClientLocalStateActions.UpdateClientLocalStateSuccessful, (state, action) => ({
    ...state,
    data: updateClientLocalStates(action.payload.clientLocalStateId, action.payload.clientLocalState, (state.data?.length ? state.data : [])),
    isLoading: false
  })),
  on(ClientLocalStateActions.UpdateClientLocalStateFailure, (state) => ({
    ...state,
    isLoading: false
  })),
  on(ClientLocalStateActions.DeleteClientLocalState, (state) => ({
    ...state,
    isLoading: true
  })),
  on(ClientLocalStateActions.DeleteClientLocalStateSuccessful, (state, action) => ({
    ...state,
    data: state?.data?.filter(item => action.payload.clientLocalStateId !== item.id) ?? [],
    isLoading: false
  })),
  on(ClientLocalStateActions.DeleteClientLocalStateFailure, (state) => ({
    ...state,
    isLoading: false
  })),

  on(ClientLocalStateActions.ResetStateToInitial, () => initialState)
);
