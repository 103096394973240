import { ActionBehavior, CrudTableViewConfig, CrudViewColumnType } from '@rappider/rappider-components/utils';
import { CRUDType } from '@rappider/shared/definitions';

export const PAGE_VARIABLES_GRID_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'variableName',
  columns: [
    {
      title: 'Id',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'Variable Name',
      fieldName: 'variableName',

      type: CrudViewColumnType.Text
    },
    {
      title: 'Data Selector Id',
      fieldName: 'uiDataSelectorId',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'Data Selector',
      fieldName: 'uiDataSelectorName',
      type: CrudViewColumnType.Text,
      visible: false
    }
  ],
  listActions: [
    {
      text: 'Add Page Variables with AI',
      name: 'create-with-ai',
      icon: { name: 'fa-solid fa-sparkles' },
      behavior: ActionBehavior.Emit
    },
    {
      text: 'Add Page Variables Manually',
      name: CRUDType.Create,
      icon: { name: 'fas fa-plus' },
      behavior: ActionBehavior.Emit
    }
  ],
  itemActions: [
    {
      text: 'Edit',
      name: CRUDType.Update,
      icon: { name: 'far fa-edit' },
      behavior: ActionBehavior.Emit
    },
    {
      text: 'Delete',
      name: CRUDType.Delete,
      icon: { name: 'far fa-trash' },
      behavior: ActionBehavior.Emit,
      popconfirmTitle: 'Are you sure to remove this variable from the page?',
      emitWithoutPopconfirm: false
    }
  ]
};
