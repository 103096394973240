import { DiagramItemType } from '../../diagram-item-type';

export const ExclusiveConditionItemConfig = {
  type: DiagramItemType.ExclusiveCondition,
  defaultText: DiagramItemType.ExclusiveCondition,
  backgroundImageUrl: 'assets/icons/exclusive-symbol.svg',
  defaultWidth: 2.5,
  defaultHeight: 2.5,
  backgroundImageWidth: 0.3,
  backgroundImageHeight: 0.3,
  backgroundImageLeft: 0.35,
  backgroundImageTop: 0.35,
  connectionPoints: {
    top: {
      x: 0.5,
      y: 0.35
    },
    right: {
      x: 0.65,
      y: 0.5
    },
    bottom: {
      x: 0.5,
      y: 0.65
    },
    left: {
      x: 0.35,
      y: 0.5
    }
  }
};
