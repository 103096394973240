<section *ngIf="comments?.length || !isDisplayingAllComments" class="comment-wrapper">
  <div class="comment-wrapper-toolbar">

    <rappider-input-group *ngIf="comments?.length || !isDisplayingAllComments" class="search-box"
                          [(ngModel)]="searchText"
                          [textbox]="searchBarConfig.textbox"
                          [suffixIcon]="searchBarConfig.suffixIcon"
                          (ngModelChange)="onSearchtextChange($event)"></rappider-input-group>
    <div [ngClass]="{'add-button-wrapper': isAddNewCommentModeActive}">
      <rappider-button *ngIf="!isDisplayingAllComments"
                      [text]="'SHARED.ADD'"
                      [icon]="{name: 'fas fa-plus'}"
                      (confirm)="onAddcomment()"></rappider-button>
                    </div>
    <rappider-button *ngIf="comments?.length" nz-tooltip
                    [nzTooltipTitle]="commentSettingsButton.tooltipText"
                    [icon]="commentSettingsButton.icon"
                    (confirm)="onCommentSettingsButtonClick()"></rappider-button>
  </div>

  <ng-container *ngFor="let comment of displayedComments; let i = index">
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <rappider-comment-display id="{{comment.id}}"
                                [content]="comment?.content"
                                [assignedTo]="comment?.assignedTo"
                                [assignedToPersonId]="comment?.assignedToPersonId"
                                [status]="CommentStatus[comment?.status]"
                                [createdBy]="comment?.createdBy"
                                [createdById]="comment?.createdById"
                                [createdDate]="comment?.createdDate"
                                [activePerson]="activePerson"
                                [assignedPersonSelectData]="peopleKeyValueData"
                                [isCommentEditModeActive]="comment?.isEditModeActive"
                                [additionalContent]="comment?.additionalContent"
                                [mentionData]="mentionKeyValueData"
                                [replies]="comment?.replies"
                                [isCreateReplyModeActive]="comment?.id === scrollingCommentId"
                                (clickCard)="onClickCommentCard(comment)"
                                (assignToPerson)="onAssignToPerson($event, comment)"
                                (saveComment)="onSaveComment($event, comment.id, comment.createdById)"
                                (cancelComment)="onCancelComment(comment, i)"
                                (deleteComment)="onDeleteComment(comment)"
                                (saveReply)="onReplyComment($event, comment.id)"
                                (editReply)="onEditReply($event)"
                                (deleteReply)="onDeleteReply($event, comment.id)"
                                (commentStatusClick)="markCommentAsResolved(comment)"></rappider-comment-display>
    </ng-container>
  </ng-container>

  <ng-template #loadingTemplate>
    <nz-skeleton [nzAvatar]="true"
                 [nzParagraph]="{ rows: 3 }"></nz-skeleton>
  </ng-template>

</section>

<rappider-alert class="comment-wrapper-alert" *ngIf="!comments?.length && isDisplayingAllComments"
                [title]="alertComponentConfig.title"
                [type]="alertComponentConfig.type"
                [showIcon]="alertComponentConfig.showIcon"
                [closeable]="alertComponentConfig.closeable"></rappider-alert>
