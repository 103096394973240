import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonComponentConfig } from '@rappider/rappider-components/utils';
import { MenuFoldIconConfig, MenuUnfoldIconConfig } from '../../utils/menu-icon.config';
import { WorkflowDiagramInfoIcon } from '../../utils/workflow-diagram-info-icon.config';
import { WorkflowDiagramInfoModalConfig } from '../../utils/workflow-diagram-info-modal.config';
import { WorkflowDiagramInfoModalContents } from '../../utils/workflow-diagram-info-modal-contents.config';
import { DiagramButtonConfig } from '../../utils/diagram-button.config';

@Component({
  selector: 'rappider-diagram-toolbar',
  templateUrl: './diagram-toolbar.component.html',
  styleUrls: ['./diagram-toolbar.component.scss']
})
export class DiagramToolbarComponent {

  @Input() menuVisibility = false;

  @Output() menuVisibilityChange = new EventEmitter<boolean>();
  @Output() createWorkflowEvent = new EventEmitter();
  @Output() createStepFunction = new EventEmitter();

  infoModalVisible = false;
  DiagramButtonConfig = DiagramButtonConfig;
  MenuFoldIcon = MenuFoldIconConfig;
  MenuUnfoldIcon = MenuUnfoldIconConfig;
  WorkflowDiagramInfoIcon = WorkflowDiagramInfoIcon;
  WorkflowDiagramInfoModalConfig = WorkflowDiagramInfoModalConfig;
  WorkflowDiagramInfoModalContents = WorkflowDiagramInfoModalContents;

  onChangeMenuVisibility() {
    this.menuVisibility = !this.menuVisibility;
    this.menuVisibilityChange.emit(this.menuVisibility);
  }

  infoModalVisibility() {
    this.infoModalVisible = !this.infoModalVisible;
  }

  onButtonClick(button: ButtonComponentConfig) {
    if (button.text === DiagramButtonConfig[0].text) {
      this.createWorkflowEvent.emit();
    } else if (button.text === DiagramButtonConfig[1].text) {
      this.createStepFunction.emit();
    }
  }
}
