import { Injectable } from '@angular/core';
import * as lodash from 'lodash';
import { CategorizedData } from '../../models';
import { AlertTypes, CrudFormConfig, CrudFormItem, CrudViewFormItemType, TextMode } from '@rappider/rappider-components/utils';
import { EditFormService } from '../edit-form-service/edit-form.service';
import { ComponentInputDefinition, ComponentWithRelations } from '@rappider/rappider-sdk';
import { ContentTree, ContentTreeComponent, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';

@Injectable({
  providedIn: 'root'
})
export class ContentEditorService {

  constructor(
    private editFormService: EditFormService
  ) { }

  findContentTreeItemInContentTree(
    searchingContentId: string,
    contentTreeNode: any
  ) {
    const stack = [];
    let node: any;

    stack.push(contentTreeNode);

    while (stack?.length) {
      node = stack.pop();
      if (node.id === searchingContentId) {
        // Found it!
        return node;
      } else if (node.children && node.children.length) {
        stack.push(...node.children);
      }
    }

    // Didn't find it. Return null.
    return null;
  }

  convertPageContainerToTemplateTree(contentTree: ContentTree): any[] {
    /* define template tree */
    return contentTree?.map(contentTreeItem => {
      /* define template tree item */
      if (contentTreeItem.type === ContentTreeItemType.Container) {
        return {
          type: ContentTreeItemType.Container,
          options: {
            displayMode: contentTreeItem.displayMode,
            flexOptions: contentTreeItem.flexOptions,
            columnWidth: contentTreeItem.columnWidth,
            autoColumnWidth: contentTreeItem.autoColumnWidth,
            cssClasses: contentTreeItem.cssClasses
          },
          children: this.convertPageContainerToTemplateTree(contentTreeItem.children)
        };
      } else if (contentTreeItem.type === ContentTreeItemType.Component) {
        return {
          type: ContentTreeItemType.Component,
          componentDefinitionId: contentTreeItem.component.componentDefinitionId,
          inputs: contentTreeItem.component.inputs
        };
      }
    });
  }

  // #region COMPONENT SETTINGS

  getCategorizedComponentInputDefinitionItemConfigsByComponent(
    activeContentTreeItem: ContentTreeComponent,
    defaultEditFormConfig: CrudFormConfig
  ): CategorizedData[] {
    return lodash(activeContentTreeItem.component.componentDefinition.inputDefinitions)
      .orderBy(
        [
          inputDefinition => inputDefinition.index,
          inputDefinition => inputDefinition.category?.toUpperCase()
        ]
      )
      .groupBy(inputDefinition => inputDefinition.category?.toUpperCase())
      .map((data, category) => <CategorizedData>{
        title: category,
        data: data
      })
      .value()
      .map(categorizedInputDefinition => {
        /* create crud form items by component input definitions */
        const componentInputDefinitions: ComponentInputDefinition[] = categorizedInputDefinition.data;
        const crudFormItems: CrudFormItem[] = this.editFormService.createCrudFormItemsByComponentInputDefinitions(componentInputDefinitions)
          .map((item, index) => {
            this.buildMenuForEachItemRecursively(item, componentInputDefinitions[index], activeContentTreeItem);
            return item;
          });
        /* push categorized config to array */
        return <CategorizedData>{
          title: categorizedInputDefinition.title,
          data: {
            config: {
              ...defaultEditFormConfig,
              items: crudFormItems
            },
            active: true
          }
        };
      });
  }

  buildMenuForEachItemRecursively(item, componentInputDefinition, activeContentTreeItem, parentField?) {
    /* subscription */
    activeContentTreeItem.component.dataSubscriptions?.forEach(dataSubscription => {
      const hasExistingNestedSubscription = item.metadata.key === dataSubscription.componentInputDefinitionNestedFieldIds?.[dataSubscription.componentInputDefinitionNestedFieldIds?.length - 1];
      if (dataSubscription?.inputDefinition.id === componentInputDefinition?.id) {
        if (item?.metadata?.key === componentInputDefinition?.id && !dataSubscription.componentInputDefinitionNestedFieldIds?.length) {
          this.updateConfigOfItemWithTheSubscription(item, dataSubscription, componentInputDefinition);
          /* set children as undefined to disable object mode. Because, in the EditForm component, object mode overrides the Alert component */
          item.items = undefined;
        } else if (hasExistingNestedSubscription) {
          /* remove parent field menu if nested subscription exist */
          parentField.menu = null;
          this.updateConfigOfItemWithTheSubscription(item, dataSubscription, componentInputDefinition);
        }
      }
    });

    /*
     * If there is no remove-data-subscription item exist in the menu as a result of the above processes,
     * it means there is no subscription.
     */
    if (!item?.menu?.items?.some(item => item.name === 'remove-data-subscription')) {
      const dataSchemaFields = this.findNodeToRootPath(componentInputDefinition, item.metadata.key);
      /* remove input definition */
      dataSchemaFields.pop();
      item.menu = {
        additionalItem: activeContentTreeItem.component.inputs ? this.getAdditionalItem(componentInputDefinition, activeContentTreeItem) : null,
        items: [
          {
            label: 'Add Data Subscription',
            name: 'add-data-subscription',
            data: {
              inputDefinition: componentInputDefinition,
              targetDataSchemaFields: dataSchemaFields.reverse()
            }
          },
          {
            label: 'AI Content Config',
            name: 'ai-content-config',
            data: {
              inputDefinition: {
                id: componentInputDefinition.id,
                childDataSchemaFieldIds: dataSchemaFields?.map(field => field.id) || [],
                targetNodeFieldType: dataSchemaFields?.length ? dataSchemaFields[0].type.name : componentInputDefinition.type?.type
              }
            }
          }
        ]
      };
    }

    if (componentInputDefinition?.type?.fields?.length > 0 && componentInputDefinition.isArray) {
      item.dynamicFormInputs = componentInputDefinition;
      item.defaultInputs = activeContentTreeItem.component.inputs;
    }

    if (item?.items?.length) {
      item.items.forEach(child => {
        this.buildMenuForEachItemRecursively(child, componentInputDefinition, activeContentTreeItem, item);
      });
    }
  }

  updateConfigOfItemWithTheSubscription(item, dataSubscription, componentInputDefinition) {
    const dataSchemaFields = this.findNodeToRootPath(componentInputDefinition, item.metadata.key);
    /* remove input definition */
    dataSchemaFields.pop();
    item.type = CrudViewFormItemType.Alert;
    item.alert = {
      title: {
        content: 'Data Subscription',
        type: 'h6'
      },
      description: {
        content: `
        <div style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">
        ${dataSubscription.jsonataConfig}
        </div>
        `,
        textMode: TextMode.Html
      },
      type: AlertTypes.Info,
      showIcon: false
    };
    item.menu = {
      items: [
        {
          label: 'Remove Subscription',
          name: 'remove-data-subscription',
          data: {
            componentDataSubscriptionId: dataSubscription?.id
          }
        },
        {
          label: 'Edit Subscription',
          name: 'edit-data-subscription',
          data: {
            dataSubscription: dataSubscription,
            inputDefinition: componentInputDefinition,
            targetDataSchemaFields: dataSchemaFields.reverse()
          }
        },
        {
          label: 'AI Content Config',
          name: 'ai-content-config',
        }
      ]
    };
  }

  findFieldFromComponentInputDefinition(inputDefinition: ComponentInputDefinition, key: string) {
    const stack = [];
    const stackOfParentNodes = [];

    stack.push(inputDefinition);

    while (stack?.length) {
      const node = stack.pop();
      const parentNode = stackOfParentNodes.pop();
      if (node.id === key) {
        return {
          node: node,
          parentNode: parentNode
        };
      } else {
        node?.type?.fields?.forEach(field => {
          stack.push(field);
          stackOfParentNodes.push(node);
        });
      }
    }
    return null;
  }

  findNodeToRootPath(root: ComponentInputDefinition, key: string) {
    const existingNode = this.findFieldFromComponentInputDefinition(root, key);
    let parentNode = existingNode?.parentNode;
    const path = [existingNode?.node];
    while (parentNode) {
      path.push(parentNode);
      parentNode = this.findFieldFromComponentInputDefinition(root, parentNode.id)?.parentNode;
    }
    return path.filter(e => e);
  }

  // #endregion

  getAdditionalItem(componentInputDefinition, activeContentTreeItem) {
    if (componentInputDefinition.type?.fields || componentInputDefinition.type?.name === 'object') {
      return null;
    }

    if (this.hasMatchingInput(activeContentTreeItem.component.inputs, componentInputDefinition.fieldName)) {
      return {
        tooltipText: 'Remove Variable',
        icon: {
          name: 'fa-kit fa-solid-code-circle-xmark'
        },
        name: 'remove-variable',
        data: {
          activeContentTreeItem: activeContentTreeItem,
          inputDefinition: componentInputDefinition,
        }
      };
    }

    return {
      tooltipText: 'Use Variable',
      icon: {
        name: 'fa-solid fa-code'
      },
      name: 'use-variable',
      data: {
        activeContentTreeItem: activeContentTreeItem,
        inputDefinition: componentInputDefinition,
      }
    };
  }

  hasMatchingInput(inputs, fieldName) {
    const regex = /\{\{[^{}]*\}\}/;
    const value = inputs[fieldName];
    return typeof value === 'string' && regex.test(value);
  }

}
