<section [ngClass]="{'comment-display-card': status !== commentStatus.completed, 'comment-display-card-completed': status === commentStatus.completed}"
         [style.boxShadow]="isCommentEditModeActive || isCreateReplyModeActive || isReplyEditModeActive ? 'var(--box-shadow)' : ''"
         [style.backgroundColor]="isCommentEditModeActive || isCreateReplyModeActive || isReplyEditModeActive ? 'var(--comment-hover-color)' : ''"
         (mouseenter)="setCommentHoveredState(true)"
         (mouseleave)="setCommentHoveredState(false)"
         (click)="onClickCommentCard()">

  <!-- Comment Header -->
  <rappider-comment-header [(isCommentEditModeActive)]="isCommentEditModeActive"
                           [isCommentHovered]="isCommentHovered"
                           [createdDate]="createdDate"
                           [activePerson]="activePerson"
                           [createdById]="createdById"
                           [createdBy]="createdBy"
                           [assignedTo]="assignedTo"
                           [status]="status"
                           (deleteComment)="onClickDeleteComment()"
                           (commentStatusClick)="onCommentStatusButtonClick()"></rappider-comment-header>

  <!-- Comment Content Area -->
  <div class="content-area">
    <rappider-comment-content [(isCommentEditModeActive)]="isCommentEditModeActive"
                              [content]="content"
                              [mentionData]="mentionData"
                              [assignedTo]="assignedTo"
                              (saveComment)="onSaveComment($event)"
                              (cancelComment)="onCancelComment()"></rappider-comment-content>

    <!-- Replies Section -->
    <rappider-comment-replies [(isCreateReplyModeActive)]="isCreateReplyModeActive"
                              [replies]="replies"
                              [isCommentHovered]="isCommentHovered"
                              [activePerson]="activePerson"
                              [createdById]="createdById"
                              [assignedTo]="assignedTo"
                              [mentionData]="mentionData"
                              (saveReply)="onSaveReplyButtonClick($event)"
                              (editReply)="onEditReply($event)"
                              (deleteReply)="onDeleteReply($event)"
                              (cancelReply)="onCancelReply()"
                              (editReplyModeChange)="isReplyEditModeActive=$event"></rappider-comment-replies>

    <!-- Divider After Replies -->
    <rappider-divider *ngIf="additionalContent || assignedTo || isCommentEditModeActive"
                      class="divider"></rappider-divider>

    <!-- Comment Footer Area -->
    <div class="footer-wrapper">
      <div class="footer">
        <div class="comment-footer flex">
          <!-- Assigned To Area -->
          <ng-container *ngIf="assignedTo || isCommentEditModeActive">
            <div class="assigned-to-area flex">
              <ng-container>
                <div class="text-color">{{ 'SHARED.ASSIGNED_TO' | translate }}</div>

                <ng-container *ngIf="!isCommentEditModeActive; else editAssignedPersonTemplate">
                  <div class="text-color">{{ assignedTo?.firstName }} {{ assignedTo?.lastName}}</div>
                </ng-container>
              </ng-container>

              <ng-template #editAssignedPersonTemplate>
                <div class="edit-assigned-person-area">
                  <rappider-select rappiderClickStopPropagation
                                   [(ngModel)]="assignedToPersonId"
                                   [options]="assignedPersonSelectData"
                                   (ngModelChange)="onAssignToPerson($event)"></rappider-select>
                </div>
              </ng-template>
            </div>
          </ng-container>

          <!-- Additional Content Area -->
          <ng-container *ngIf="additionalContent">
            <div class="additional-content-area">{{ additionalContent }}</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
