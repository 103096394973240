import { Injectable } from '@angular/core';
import { ProjectFileControllerService, ProjectFolderControllerService, ProjectFolderWithRelations } from '@rappider/rappider-sdk';

@Injectable({
  providedIn: 'root'
})
export class AssetBrowserService {

  constructor(
    private projectFileApi: ProjectFileControllerService,
    private projectFolderApi: ProjectFolderControllerService
  ) { }

  getRootFolder() {
    const params = {
      filter: {
        include: [
          {
            relation: 'subFolders'
          },
        ]
      }
    };

    return this.projectFolderApi.getRootFolder(params);
  }

  getFolderByIdWithSubFolders(folderId: string) {
    const params = {
      id: folderId,
      filter: {
        include: ['subFolders']
      }
    };
    return this.projectFolderApi.findById(params);
  }

  getProjectFilesByFolderId(folderId: string) {
    const params = {
      projectFolderId: folderId
    };
    return this.projectFileApi.getFolderFiles(params);
  }

  getFileContent(projectFileId: string) {
    const params = {
      projectFileId: projectFileId
    };
    return this.projectFileApi.getFileContent(params);
  }

  mapProjectFolderDataToNzTreeNodeOptions(folder: ProjectFolderWithRelations) {
    return {
      ...folder,
      title: folder.name || 'Root',
      key: folder.id,
      expanded: folder?.isRoot,
      isLeaf: false,
      loading: false,
      children: folder.subFolders ? folder.subFolders.map(subFolder => this.mapProjectFolderDataToNzTreeNodeOptions(subFolder)) : []
    };
  }

}
