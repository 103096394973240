<section class="toolbar">
  <div class="diagram-settings-left-side">
    <div class="active-ui-data-store-area">
      {{ setActiveUIDataStoreAndActiveItemName() }}
    </div>
  </div>

  <div class="diagram-settings-right-side">
    <rappider-button [text]="menuVisibility ? 'Fullscreen' : 'Exit FullScreen' | translate"
                     [size]="ButtonSize"
                     (click)="changeMenuVisibility()"></rappider-button>
    <rappider-button [text]="showEventsWithProcess ? 'DIAGRAM_EDITOR_MODULE.SHOW_IDLE_EVENTS' : 'DIAGRAM_EDITOR_MODULE.HIDE_IDLE_EVENTS' | translate"
                     [size]="ButtonSize"
                     (click)="onShowEventsWithProcess()"></rappider-button>
    <div class="diagram-settings-dropdown">
      <rappider-button nz-dropdown
                       [nzDropdownMenu]="menu"
                       [size]="ButtonSize"
                       [text]="DiagramSettingsDropdownConfig.text"
                       [icon]="DiagramSettingsDropdownConfig.icon"></rappider-button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item
              (click)="onCreateUIDataEventButtonClick()">{{ 'DIAGRAM_EDITOR_MODULE.ADD_UI_DATA_EVENT' | translate }}
          </li>
          <li nz-menu-item
              (click)="onCreateUIStepFunctionButtonClick()">{{ 'DIAGRAM_EDITOR_MODULE.ADD_UI_STEP_FUNCTION' | translate
            }}
          </li>
          <li nz-menu-item
              nz-tooltip="Adds a UI Workflow From Workflow Template Library"
              (click)="onCreateUIWorkflowFunctionButtonClick()">{{ activeItem?.type === 'uiDataEvent' ?
            'Add UI Workflow to ' + activeItem?.item?.name : 'Add UI Workflow' | translate }}
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</section>
