import * as ComponentDefinitionActions from './component-definition.actions';
import { ComponentDefinitionWithRelations, ComponentOutputDefinitionWithRelations } from '@rappider/rappider-sdk';
import { cloneDeep } from 'lodash';
import { BreadcrumbOption } from '@rappider/rappider-components/utils';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

export const componentDefinitionFeatureKey = 'componentDefinition';

export interface State {
  data: ComponentDefinitionWithRelations[];
  isComponentDefinitionsLoading: boolean;
  isComponentDefinitionsLoaded: boolean;
  title: BreadcrumbOption[];
}

export const initialState: State = {
  data: null,
  isComponentDefinitionsLoading: null,
  isComponentDefinitionsLoaded: false,
  title: []
};

export function reducer(
  state: State = initialState,
  action: ComponentDefinitionActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case ComponentDefinitionActions.ActionTypes.LoadComponentDefinitions: {
      return {
        ...state,
        isComponentDefinitionsLoading: true
      };
    }

    case ComponentDefinitionActions.ActionTypes.LoadComponentDefinitionsFailure: {
      return {
        ...state,
        isComponentDefinitionsLoading: false
      };
    }

    case ComponentDefinitionActions.ActionTypes.LoadComponentDefinitionsSuccess: {
      return {
        ...state,
        data: action.payload.componentDefinitions,
        isComponentDefinitionsLoading: false,
        isComponentDefinitionsLoaded: true
      };
    }

    case ComponentDefinitionActions.ActionTypes.UpdateComponentDefinition: {
      return {
        ...state,
        isComponentDefinitionsLoading: true
      };
    }

    case ComponentDefinitionActions.ActionTypes.UpdateComponentDefinitionSuccessful: {
      // get updating component definition from state
      const updatingComponentDefinition = state.data
        .find(componentDefinition => componentDefinition.id === action.payload.componentDefinitionId)
        || {};
      // merge updating component definition with the payload in order to not lose the component definition
      const updatedComponentDefinition = {
        ...updatingComponentDefinition,
        ...action.payload.updatedComponentDefinition
      };
      // remove old component definition and add the updated component definition
      return {
        ...state,
        data: [
          ...state.data.filter(componentDefinition => componentDefinition.id !== action.payload.componentDefinitionId),
          updatedComponentDefinition
        ],
        isComponentDefinitionsLoading: false
      };

    }

    case ComponentDefinitionActions.ActionTypes.UpdateComponentDefinitionFailure: {
      return {
        ...state,
        isComponentDefinitionsLoading: false
      };
    }

    case ComponentDefinitionActions.ActionTypes.CreateComponentDefinition: {
      return {
        ...state,
        isComponentDefinitionsLoading: true
      };
    }

    case ComponentDefinitionActions.ActionTypes.CreateComponentDefinitionSuccessful: {
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.createdComponentDefinition
        ],
        isComponentDefinitionsLoading: false
      };
    }

    case ComponentDefinitionActions.ActionTypes.CreateComponentDefinitionFailure: {
      return {
        ...state,
        isComponentDefinitionsLoading: false
      };
    }

    case ComponentDefinitionActions.ActionTypes.DeleteComponentDefinition: {
      return {
        ...state,
        isComponentDefinitionsLoading: true
      };
    }

    case ComponentDefinitionActions.ActionTypes.DeleteComponentDefinitionSuccessful: {
      return {
        ...state,
        data: [
          ...state.data.filter(componentDefinition => componentDefinition.id !== action.payload.componentDefinitionId)
        ],
        isComponentDefinitionsLoading: false
      };
    }

    case ComponentDefinitionActions.ActionTypes.DeleteComponentDefinitionFailure: {
      return {
        ...state,
        isComponentDefinitionsLoading: false
      };
    }

    case ComponentDefinitionActions.ActionTypes.CreateComponentInputDefinitionFailure: {
      return {
        ...state,
        isComponentDefinitionsLoading: false
      };
    }

    case ComponentDefinitionActions.ActionTypes.CreateComponentInputDefinitionSuccess: {
      /* get component definition id from payload */
      const componentDefinitionId = action.payload.componentDefinitionId;
      /* updating component definition */
      const updatingComponentDefinition = cloneDeep(state.data.find(item => item.id === componentDefinitionId));
      /* created input definitions of updating component definition */
      const updatingComponentDefinitionInputDefinitions = updatingComponentDefinition.inputDefinitions || [];
      /* updated component definition with new input definitions */
      const updatedComponentDefinition = {
        ...updatingComponentDefinition,
        inputDefinitions: [
          ...updatingComponentDefinitionInputDefinitions,
          ...action.payload.createdComponentInputDefinitions
        ]
      };

      const componentDefinitions = state.data?.filter(item => item.id !== componentDefinitionId) || [];

      return {
        ...state,
        data: [
          ...componentDefinitions,
          updatedComponentDefinition
        ],
        isComponentDefinitionsLoading: false
      };
    }

    case ComponentDefinitionActions.ActionTypes.CreateComponentOutputDefinitionsSuccess: {
      /* get component definition id by payload */
      const componentDefinitionId = action.payload.componentDefinitionId;
      /* state componentDefinitions without updated componentDefinition */
      const updatingComponentDefinition = cloneDeep(state.data.find(item => item.id === componentDefinitionId));
      const updatingComponentDefinitionOutputDefinitions = updatingComponentDefinition.outputDefinitions || [];
      const updatedComponentDefinition = {
        ...updatingComponentDefinition,
        outputDefinitions: [
          ...updatingComponentDefinitionOutputDefinitions,
          ...action.payload.componentOutputDefinitions
        ]
      };

      const componentDefinitions = state.data?.filter(item => item.id !== componentDefinitionId) || [];

      return {
        ...state,
        data: [
          ...componentDefinitions,
          updatedComponentDefinition
        ]
      };
    }

    case ComponentDefinitionActions.ActionTypes.UpdateComponentOutputDefinitionsSuccess: {
      const updatedComponentDefinitions = state.data.map(componentDefinition => {
        if (componentDefinition.id === action.payload.componentDefinitionId) {
          const updatedOutputDefinitions = componentDefinition.outputDefinitions.map(outputDefinition => {
            const updatedOutputDefinition = action.payload.componentOutputDefinitions.find(newOutput => newOutput.id === outputDefinition.id);
            return updatedOutputDefinition ? { ...outputDefinition, ...updatedOutputDefinition } : outputDefinition;
          });
          return {
            ...componentDefinition,
            outputDefinitions: updatedOutputDefinitions
          }
        } else {
          return componentDefinition;
        }
      });

      return {
        ...state,
        data: updatedComponentDefinitions
      };
    }

    case ComponentDefinitionActions.ActionTypes.DeleteComponentOutputDefinitionsSuccess: {
      const componentDefinitionId = action.payload.componentDefinitionId;
      const updatedComponentDefinition = cloneDeep(state.data?.find(item => item.id === componentDefinitionId));
      /* state componentDefinitions without updated componentDefinition */
      const stateDataWithoutUpdatedComponentDefinition = state.data.filter(item => item.id !== componentDefinitionId);

      /* deleted component definitions */
      const deletedComponentOutputDefinitionIds = action.payload.deletedComponentOutputDefinitionIds;
      /* remove deleted component definition */
      updatedComponentDefinition.outputDefinitions = updatedComponentDefinition?.outputDefinitions?.filter(
        (outputDefinition: ComponentOutputDefinitionWithRelations) => !deletedComponentOutputDefinitionIds.includes(outputDefinition.id)
      );

      return {
        ...state,
        data: [
          ...stateDataWithoutUpdatedComponentDefinition,
          updatedComponentDefinition
        ]
      };
    }

    case ComponentDefinitionActions.ActionTypes.SetComponentBrowserTitle: {
      return {
        ...state,
        title: [...action.payload.title]
      };
    }

    case ComponentDefinitionActions.ActionTypes.DeleteSampleInputSuccessful: {
      const deletedSampleId = action.payload.sampleInputId;
      /* find component definition */
      const updatedComponentDefinition = cloneDeep(
        state.data.find(componentDefinition => componentDefinition?.samples?.some(sample => sample.id === deletedSampleId))
      );
      /* filter deleted sample */
      updatedComponentDefinition.samples = updatedComponentDefinition.samples?.filter(sample => sample.id !== deletedSampleId);

      return {
        ...state,
        data: [
          ...state.data.filter(componentDefinition => componentDefinition.id !== updatedComponentDefinition.id),
          updatedComponentDefinition
        ]
      };
    }

    case ComponentDefinitionActions.ActionTypes.CreateSampleInputSuccessful: {
      const createdSample = action.payload.sampleInput;
      const componentDefinitionId = action.payload.componentDefinitionId;
      /* find component definition */
      let updatedComponentDefinition = cloneDeep(
        state.data?.find(componentDefinition => componentDefinition.id === componentDefinitionId)
      );
      /* add created sample */
      updatedComponentDefinition = {
        ...updatedComponentDefinition,
        samples: updatedComponentDefinition?.samples?.length ?
          [
            ...<any[]>updatedComponentDefinition?.samples,
            createdSample
          ] :
          [
            createdSample
          ]
      };

      return {
        ...state,
        data: [
          ...state.data.filter(componentDefinition => componentDefinition.id !== updatedComponentDefinition.id),
          updatedComponentDefinition
        ]
      };
    }

    case ComponentDefinitionActions.ActionTypes.DeprecateComponentDefinitionSuccessful: {
      const existingComponentDefinition = cloneDeep(
        state.data?.find(componentDefinition => componentDefinition.id === action.payload.componentDefinitionId)
      );
      existingComponentDefinition.isDeprecated = true;
      return {
        ...state,
        data: [
          ...state.data.filter(componentDefinition => componentDefinition.id !== action.payload.componentDefinitionId),
          existingComponentDefinition
        ]
      };
    }

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    default: {
      return state;
    }
  }
}
