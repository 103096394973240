import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PostItColor, PostItSize } from './post-it.module';


@Component({
  selector: 'rappider-post-it',
  templateUrl: './post-it.component.html',
  styleUrls: ['./post-it.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RappiderPostItComponent {
  @Input() noteText = '';
  @Input() isEditable = false;
  @Input() color: PostItColor = PostItColor.Yellow;  // Input for Post-it color
  @Input() size?: PostItSize;

  @Output() noteUpdated = new EventEmitter<string>();

  // Method to emit the updated note text
  onNoteTextChange(newText: string): void {
    this.noteUpdated.emit(newText);
  }

  // Getter to determine the background color based on the enum value
  getPostItColor(): string {
    // TODO: Implement the logic to return the css class names and move colors to scss file
    switch (this.color) {
      case PostItColor.Pink:
        return '#ffcccb';
      case PostItColor.Blue:
        return '#ccf2ff';
      case PostItColor.Green:
        return '#ccffcc';
      default:
        return '#ffeb3b';  // Default is yellow
    }
  }
}
