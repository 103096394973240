<div class="button">
  <button *ngIf="text || icon?.name"
          [id]="elementId"
          [ngClass]="{'ant-btn-xs': size === 'extra-small'}"
          nz-button
          nz-popconfirm
          nz-tooltip
          [ngStyle]="borderStyles"
          [nzTooltipTitle]="tooltipText | translate"
          [nzTooltipOverlayClassName]="'button-tooltip'"
          [nzTooltipPlacement]="tooltipPlacement"
          [nzPopconfirmTitle]="popconfirmTitle | translate"
          [nzCondition]="emitWithoutPopconfirm"
          [nzType]="type || 'default'"
          [nzCancelText]="popconfirmCancelText| translate"
          [nzOkText]="popconfirmOkText| translate"
          [nzOkDanger]="popconfirmOkDanger"
          [nzShape]="shape || ''"
          [nzSize]="size || 'default'"
          [nzGhost]="transparent"
          [nzBlock]="block"
          [nzLoading]="loading"
          [disabled]="disabled"
          [class]="colorType + customCssClass ? ' ' + customCssClass : ''"
          [type]="formButtonType || 'button'"
          [style.border-top]="borderSettings?.borderTop + ' ' + borderSettings?.borderStyle"
          [style.border-right]="borderSettings?.borderRight + ' ' + borderSettings?.borderStyle"
          [style.border-bottom]="borderSettings?.borderBottom + ' ' + borderSettings?.borderStyle"
          [style.border-left]="borderSettings?.borderLeft + ' ' + borderSettings?.borderStyle"
          [style.border-radius]="borderSettings?.borderRadius"
          [style.border-top-left-radius]="borderSettings?.borderTopLeftRadius"
          [style.border-top-right-radius]="borderSettings?.borderTopRightRadius"
          [style.border-bottom-right-radius]="borderSettings?.borderBottomRightRadius"
          [style.border-bottom-left-radius]="borderSettings?.borderBottomLeftRadius"
          [style.border-color]="borderSettings?.borderColor"
          [style.border-style]="borderSettings?.borderStyle"
          [style.padding]="paddingSettings?.all"
          [style.margin]="marginSettings?.all"
          [style.box-shadow]="shadowSettings?.boxShadow"
          [style.color]="customColorSettings?.color"
          [style.background-color]="customColorSettings?.backgroundColor"
          [style.width]="customSizeSettings?.width"
          [style.max-width]="customSizeSettings?.maxWidth"
          [style.min-width]="customSizeSettings?.minWidth"
          [style.height]="customSizeSettings?.height"
          [style.max-height]="customSizeSettings?.maxHeight"
          [style.min-height]="customSizeSettings?.minHeight"
          (click)="onButtonClick()"
          (mouseleave)="onMouseLeave($event)"
          (nzOnConfirm)="onConfirm()"
          (nzOnCancel)="onCancel()">
    <div [ngClass]="iconPlacement === IconPlacement.Right ? 'button-icon-right' : 'button-content'">
      <div *ngIf="icon?.name"
           class="icon"
           [ngClass]="disabled ? 'disabled-icon' : null">
        <rappider-icon [name]="icon.name"
                       [color]="icon.color"
                       [type]="icon.type"
                       [theme]="icon.theme"
                       [size]="icon.size"></rappider-icon>
      </div>
      <div *ngIf="text"
           class="text">
        {{ text | translate }}
      </div>
    </div>
  </button>
</div>
