import { ButtonComponentConfig, ButtonType, IconType } from '@rappider/rappider-components/utils';
import { SourceCodeButtonItemAction } from './source-code-button-item-action.enum';

export const TITLEBAR_ACTION_BUTTON_CONFIG: ButtonComponentConfig[] = [
  {
    key: SourceCodeButtonItemAction.Find,
    icon: {
      name: 'fa-regular fa-search',
      type: IconType.FontAwesome
    },
    tooltipText: 'SHARED.FIND'
  },
  /* The reload process can be executed without generating code. This also creates issues; the reload action should be paused for now. */
  /*
  {
    key: SourceCodeButtonItemAction.Reload,
    icon: {
      name: 'fa-regular fa-arrows-rotate',
      type: IconType.FontAwesome
    },
    tooltipText: 'SHARED.RELOAD_SOURCE_FILE'
  },
  */
  {
    key: SourceCodeButtonItemAction.DownloadFile,
    icon: {
      name: 'fa fa-file-arrow-down',
      type: IconType.FontAwesome
    },
    tooltipText: 'SHARED.DOWNLOAD_FILE'
  },
  {
    key: SourceCodeButtonItemAction.DownloadSourceFile,
    icon: {
      name: 'fa fa-folder-arrow-down',
      type: IconType.FontAwesome
    },
    tooltipText: 'SHARED.DOWNLOAD_SOURCE_FILE'
  }
];
