/* eslint-disable @typescript-eslint/member-ordering */

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ButtonComponentConfig, DropdownMenuComponentConfig, DropdownMenuItem } from '@rappider/rappider-components/utils';
import { ContentTreeItem, ContentTreeItemType } from '../../models';
import { ComponentDataSubscriptionWithRelations } from '@rappider/rappider-sdk';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'rappider-content-tree-item-title-bar',
  templateUrl: './content-tree-item-title-bar.component.html',
  styleUrls: ['./content-tree-item-title-bar.component.scss']
})
export class ContentTreeItemTitleBarComponent implements OnInit {

  @Input() isVisible = false;
  @Input() title: string;
  @Input() contentTreeItem: ContentTreeItem;
  @Input() isContentAtTop: boolean;
  @Input() buttons: ButtonComponentConfig[];
  @Input() hasClipboardData: boolean;
  @Input() contentTreeItemType: ContentTreeItemType;
  @Input() dropdownConfig: DropdownMenuComponentConfig;
  @Input() buttonVisibilityFunction: (button: ButtonComponentConfig, contentTreeItem: ContentTreeItem, hasClipboardData: boolean) => boolean = () => true;
  @Input() dropdownItemVisibilityFunction: (item: DropdownMenuItem, contentTreeItem: ContentTreeItem, hasClipboardData: boolean) => boolean = () => true;
  @Input() isHovered? = false;

  @Output() buttonClick = new EventEmitter<ButtonComponentConfig>();
  @Output() dropdownItemClick = new EventEmitter<DropdownMenuItem>();
  @Output() dataSubscriptionItemControllerClick = new EventEmitter<{ key: string; dataSubscription: ComponentDataSubscriptionWithRelations }>();

  @HostBinding('style.--content-tree-item-title-bar-color')
  contentTreeItemTitleBarColor: string;
  @HostBinding('style.--content-tree-item-title-bar-hover-color')
  contentTreeItemTitleBarHoverColor: string;
  @HostBinding('style.--content-tree-item-title-bar-border-color')
  contentTreeItemTitleBarBorderColor: string;
  @HostBinding('style.--text-and-icon-color')
  textAndIconColor: string;

  ContentTreeItemType = ContentTreeItemType;
  isRightSideVisible: boolean;

  onButtonClick(button: ButtonComponentConfig) {
    this.buttonClick.emit(button);
  }

  onDropdownItemClick(item: DropdownMenuItem) {
    this.dropdownItemClick.emit(item);
  }

  ngOnInit(): void {
    if (this.contentTreeItemType === ContentTreeItemType.Component) {
      this.contentTreeItemTitleBarColor = '#adc1e6';
      this.contentTreeItemTitleBarHoverColor = '#adcfe6';
      this.contentTreeItemTitleBarBorderColor = '#adc1e6';
      this.textAndIconColor = '#53535f';
    } else {
      this.contentTreeItemTitleBarColor = '#c5c5f3';
      this.contentTreeItemTitleBarHoverColor = '#e6e6fa';
      this.contentTreeItemTitleBarBorderColor = '#c5c5f3';
      this.textAndIconColor = '#53535f';
    }
    this.dropdownConfig = cloneDeep(this.dropdownConfig);
    this.setDropdownItemsVisibilityRecursively(this.dropdownConfig.items);
    this.setRightSideVisibility();
  }

  setDropdownItemsVisibilityRecursively(items: DropdownMenuItem[]) {
    items.forEach(item => {
      item.visible = this.dropdownItemVisibilityFunction(item, this.contentTreeItem, this.hasClipboardData);
      if (item.items?.length) {
        this.setDropdownItemsVisibilityRecursively(item.items);
      }
    });
  }

  onDataSubscriptionItemControllerClick(key: string, dataSubscription: ComponentDataSubscriptionWithRelations) {
    this.dataSubscriptionItemControllerClick.emit({ key, dataSubscription });
  }

  onMenuItemClick(menuItem: DropdownMenuItem) {
    this.dropdownItemClick.emit(menuItem);
  }

  setRightSideVisibility() {
    this.isRightSideVisible = this.buttons.some(button => this.buttonVisibilityFunction(button, this.contentTreeItem, this.hasClipboardData));
  }

}
