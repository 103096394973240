<ng-template #itemTemplate
             let-item="item">
  <div class="items">
    <span class="timeline-title">
      {{item.title}}
    </span>
    <span class="timeline-subtitle">
      {{item.subtitle}}
    </span>
    <p class="timeline-paragraph">
      {{item.paragraph}}
    </p>
    <div class="timeline-markdown-container"
         *ngIf="item.markdownContent">
      <rappider-markdown-viewer [customMarkdownClass]="'no-padding'"
                                [typeInRealTime]="item.markdownContent?.typeInRealTime"
                                [typingSpeed]="item.markdownContent?.typingSpeed ?? 1"
                                [markdownText]="item.markdownContent.markdownText"></rappider-markdown-viewer>
    </div>
    <div *ngIf="item.date"
         class="date-area"
         nz-row>
      <div nz-col>
        <i class="date-icon"
           nzType="clock-circle-o"
           nz-icon></i>
      </div>
      <div nz-col>
        <span>
          {{item.date | formatDate }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<div class="timeline">
  <nz-timeline [nzReverse]="reverseTreeItem">
    <ng-container *ngIf="isTimelineItemsHasIcon; else iconlessTmpl">
      <nz-timeline-item *ngFor="let item of items"
                        [nzDot]="iconTemplate">
        <ng-container *ngTemplateOutlet="itemTemplate;context:{item}">
        </ng-container>
        <ng-template #iconTemplate>
          <ng-container *ngIf="item.icon && item.icon?.name">
            <rappider-icon class="dot-icon"
                           [color]="item.icon.color"
                           [theme]="item.icon.theme"
                           [name]="item.icon.name"
                           [type]="item.icon.type"
                           [size]="item.icon.size">
            </rappider-icon>
          </ng-container>
        </ng-template>
      </nz-timeline-item>

    </ng-container>
    <ng-template #iconlessTmpl>
      <nz-timeline-item *ngFor="let item of items"
                        [nzColor]="item.color">
        <ng-container *ngTemplateOutlet="itemTemplate;context:{item}">
        </ng-container>
      </nz-timeline-item>
    </ng-template>

  </nz-timeline>
</div>
