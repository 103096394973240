import { DiagramItemType } from '../../diagram-item-type';

export const DiagramUIScheduledDataEventItemConfig = {
  type: DiagramItemType.UIScheduledDataEvent,
  defaultText: DiagramItemType.UIScheduledDataEvent,
  backgroundImageUrl: 'assets/icons/start-event-timer.svg',
  defaultWidth: 2,
  defaultHeight: 2,
  backgroundImageWidth: 0.3,
  backgroundImageHeight: 0.3,
  backgroundImageLeft: 0.35,
  backgroundImageTop: 0.35,
  textTop: 0.25,
  connectionPoints: {
    top: {
      x: 0.5,
      y: 0.35
    },
    right: {
      x: 0.65,
      y: 0.5
    },
    bottom: {
      x: 0.5,
      y: 0.65
    },
    left: {
      x: 0.35,
      y: 0.5
    }
  }
};
