import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectModelFieldState, featureKey } from './project-model-field.reducer';

// Feature Selector
const selectProjectModelFieldState = createFeatureSelector<ProjectModelFieldState>(featureKey);

// Selectors
export const selectAllProjectModelFields = createSelector(
  selectProjectModelFieldState,
  (state: ProjectModelFieldState) => state.data
);

export const selectIsLoading = createSelector(
  selectProjectModelFieldState,
  (state: ProjectModelFieldState) => state.isLoading
);

export const selectIsLoaded = createSelector(
  selectProjectModelFieldState,
  (state: ProjectModelFieldState) => state.isLoaded
);

export const selectError = createSelector(
  selectProjectModelFieldState,
  (state: ProjectModelFieldState) => state.error
);

export const selectCreatingModelIds = createSelector(
  selectProjectModelFieldState,
  (state: ProjectModelFieldState) => state.creatingModelIds
);

export const selectUpdatingProjectModelFieldIds = createSelector(
  selectProjectModelFieldState,
  (state: ProjectModelFieldState) => state.updatingProjectModelFieldIds
);

export const selectDeletingProjectModelFieldIds = createSelector(
  selectProjectModelFieldState,
  (state: ProjectModelFieldState) => state.deletingProjectModelFieldIds
);

export const selectFieldsLoadingModelIds = createSelector(
  selectProjectModelFieldState,
  (state: ProjectModelFieldState) => state.fieldsLoadingModelIds
);

// Select a specific ProjectModelField by ID
export const selectProjectModelFieldById = (id: string) => createSelector(
  selectAllProjectModelFields,
  (projectModelFields) => projectModelFields.find(field => field.id === id)
);

// Check if a ProjectModelField is being created
export const selectIsCreatingProjectModelField = (modelId: string) => createSelector(
  selectCreatingModelIds,
  (creatingModelIds) => creatingModelIds.includes(modelId)
);

// Check if a ProjectModelField is being updated
export const selectIsUpdatingProjectModelField = (modelId: string) => createSelector(
  selectUpdatingProjectModelFieldIds,
  (updatingModelIds) => updatingModelIds.includes(modelId)
);

// Check if a ProjectModelField is being deleted
export const selectIsDeletingProjectModelField = (modelId: string) => createSelector(
  selectDeletingProjectModelFieldIds,
  (deletingModelIds) => deletingModelIds.includes(modelId)
);

// Check if fields for a specific model are loading
export const selectIsFieldsLoadingForModel = (modelId: string) => createSelector(
  selectFieldsLoadingModelIds,
  (fieldsLoadingModelIds) => fieldsLoadingModelIds.includes(modelId)
);
