/* Angular */
import { createReducer, on } from '@ngrx/store';

/* Model Interface */
import { ProjectCodeGeneration } from '@rappider/rappider-sdk';

/* Actions */
import * as ProjectCodeGenerationActions from './project-code-generation.actions';

/* State key */
export const featureKey = 'projectCodeGeneration';

/* State interface */
export interface State {
  data: ProjectCodeGeneration[] | null;
  isLoading: boolean;
  error: any;
  loaded?: boolean;
}

/* Initial values */
export const initialState: State = {
  data: null,
  isLoading: false,
  error: null,
  loaded: false,
};

export const reducer = createReducer(
  initialState,
  on(ProjectCodeGenerationActions.GetProjectCodeGenerationsSuccessful, (state, action) => ({
    ...state,
    data: action.payload.projectCodeGenerations,
    isLoading: false,
    loaded: true,
  })),
  on(ProjectCodeGenerationActions.ResetStateToInitial, () => initialState)
);
