<div *ngIf="!activeProjectModelId"
     class="endpoint-list-header">
  <rappider-internal-title-toolbar [mainTitle]="mainTitle"
                                   [options]="titleBreadcrumb"
                                   [displayToolbar]="displayToolbar"
                                   [displayToolbarBackButton]="displayToolbarBackButton"></rappider-internal-title-toolbar>
  <div class="searchCollapse">
    <rappider-input-group [(ngModel)]="searchText"
                          [textbox]="endpointListSearchBarComponentConfig.textbox"
                          [suffixIcon]="endpointListSearchBarComponentConfig.suffixIcon"
                          (ngModelChange)="onSearchTextChange()"></rappider-input-group>

    <div class="collapseToggle">
      <rappider-button *ngIf="openAPIDefinition"
                       [text]="changeViewText | translate"
                       [tooltipText]="openAPIDefinition ? null : 'We will generate your API specifications and Swagger documentation once the first version is defined and deployed.' | translate"
                       (confirm)="changeView()"></rappider-button>
      <rappider-button [text]="collapseAndExpandButtons.expandButton.text"
                       [type]="collapseAndExpandButtons.expandButton.type"
                       [key]="collapseAndExpandButtons.expandButton.key"
                       [icon]="collapseAndExpandButtons.expandButton.icon"
                       (confirm)="collapseOrExpandAll(collapseAndExpandButtons.expandButton.key)">
      </rappider-button>
      <rappider-button [text]="collapseAndExpandButtons.collapseButton.text"
                       [type]="collapseAndExpandButtons.collapseButton.type"
                       [key]="collapseAndExpandButtons.collapseButton.key"
                       [icon]="collapseAndExpandButtons.collapseButton.icon"
                       (confirm)="collapseOrExpandAll(collapseAndExpandButtons.collapseButton.key)">
      </rappider-button>
    </div>
  </div>
</div>

<ng-container *ngIf="gridView">
  <ng-container *ngIf="!activeProjectModelId; else projectModelEndpointListTemplate">
    <nz-collapse [nzAccordion]="false">
      <ng-container
                    *ngFor="let data of searchText ? searchResults : animateTable ? displayedProjectModels : projectModelListData">
        <a [id]="'apis_'+data.id"></a>
        <nz-collapse-panel [nzHeader]="data.name"
                           [nzActive]="panels[data?.id]"
                           (nzActiveChange)="onPanelChange(data.id, $event)"
                           [nzExtra]="collapseHeaderTemplate">

          <ng-container *ngIf="animateTable ? true : !!panels[data?.id]"
                        [ngTemplateOutlet]="data.name === userControllerClassName ? userDataModelTemplate : projectModelEndpointListTemplate"
                        [ngTemplateOutletContext]="{endpoints:data.children}"></ng-container>

          <ng-template #collapseHeaderTemplate>
            <div>
              <!-- (mouseover)="changeText=true"
                 (mouseout)="changeText=false"> -->
              <rappider-button [text]="addEndpointButtonConfig.text | translate"
                               [icon]="addEndpointButtonConfig.icon"
                               [size]="addEndpointButtonConfig.size"
                               (click)="navigateToCreateEndpointPage(data?.id)"></rappider-button>
            </div>
          </ng-template>

        </nz-collapse-panel>
      </ng-container>
    </nz-collapse>
  </ng-container>

  <ng-template #projectModelEndpointListTemplate
               let-endpoints="endpoints">
    <rappider-model-endpoint-list #projectModelEndpointList
                                  [projectModelEndpoints]="activeProjectModelId ? activeProjectModel?.children : endpoints"
                                  [activeProjectModelId]="activeProjectModelId"
                                  [activeProject]="activeProject"
                                  [endpointsGridConfig]="endpointsGridConfig"
                                  [animateTable]="animateTable"
                                  [isLoading]="isLoading"
                                  [enableRowPointer]="enableRowPointer"
                                  (rowActionClick)="onRowActionClick($event)"></rappider-model-endpoint-list>
  </ng-template>

  <ng-template #userDataModelTemplate>
    <div class="info-message">
      <i class="fa-solid fa-circle-info"></i>
      <p>{{'User and Authentication endpoints are not generated yet, I will generate them when the project is ready to
        deploy' | translate}}</p>
    </div>
  </ng-template>
</ng-container>

<div class="swagger-wrapper"
     [ngStyle]="{'display': !gridView ? 'block' : 'none' }"
     id="swagger-ui"></div>
