<div class="left-sidebar">
  <section class="workflow-title-area">
    <div class="workflow-menu-toolbar">
      <rappider-input-group [(ngModel)]="searchText"
                            [textbox]="SearchBarConfig.textbox"
                            [suffixIcon]="SearchBarConfig.suffixIcon"
                            (ngModelChange)="onSearchTextChange()"></rappider-input-group>
    </div>

    <div class="toggle-workflow-menu-buttons">
      <rappider-button nz-tooltip
                       [nzTooltipTitle]="'SHARED.EXPAND_ALL' | translate"
                       [icon]="ExpandAllButtonConfig.icon"
                       [size]="ExpandAllButtonConfig.size"
                       (click)="toggleWorkflowMenu(true)"></rappider-button>

      <rappider-button nz-tooltip
                       [nzTooltipTitle]="'SHARED.COLLAPSE_ALL' | translate"
                       [icon]="CollapseAllButtonConfig.icon"
                       [size]="CollapseAllButtonConfig.size"
                       (click)="toggleWorkflowMenu(false)"></rappider-button>
    </div>

  </section>

  <section class="workflow-menu-container">
    <rappider-menu [config]="displayedWorkflowMenu"
                   [activeItem]="activeItem"
                   [currentSubmenuOnly]="true"
                   (menuItemClick)="onWorkflowMenuItemClick($event)"></rappider-menu>
  </section>
</div>