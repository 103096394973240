import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ProjectDesignSettingButtons } from '../../configs/action-button-config';
import { cloneDeep } from 'lodash';
import { WIZARD_STEPS_CONFIG, WizardStepConfig, WizardStepStatus } from '../../configs/wizard-steps';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-wizard-rabbit',
  templateUrl: './wizard-rabbit.component.html',
  styleUrls: ['./wizard-rabbit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardRabbitComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activeProjectId = '';
  @Input() activeWizardStep = 0;
  @Input() wizardStepsConfigs: WizardStepConfig[] = cloneDeep(WIZARD_STEPS_CONFIG);
  @Input() displayRabbitOnHeader = false;
  @Output() typingsCompleted = new EventEmitter<{ projectId: string; wizardStep: number; typingsCompleted: boolean }>();

  ProjectDesignSettingButtons = ProjectDesignSettingButtons;
  displayExtraContent: boolean[] = [];
  subscriptions: Subscription[] = [];
  lastRenderedProjectId = '';
  latestProjectVersionId = '';
  projectVersions: any[] | undefined;

  // Typing completion tracking
  headerTypingComplete = false;
  extraContentTypingComplete = false;

  constructor(
    private store: Store<any>,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Reset typing completion tracking when wizard step changes
    if (changes.activeWizardStep) {
      this.resetTypingCompletionState();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [];
  }

  resetTypingCompletionState() {
    this.headerTypingComplete = false;
    this.extraContentTypingComplete = false;

    // Reset display of extra content for current step
    this.displayExtraContent[this.activeWizardStep] = false;
    this.cdr.detectChanges();
  }

  resetWizardStepsConfigs() {
    this.wizardStepsConfigs = cloneDeep(WIZARD_STEPS_CONFIG);
  }

  onWizardSkipStepsButtonClicked() {
    // this.store.dispatch(NextStep({ payload: { activeProjectId: this.activeProjectId } }));
  }

  onWizardNextStepButtonClicked() {
    // this.store.dispatch(NextStep({ payload: { activeProjectId: this.activeProjectId } }));
  }

  // Handler for header typing completion
  onHeaderRealtimeTypingCompleted(time: string) {
    console.log('onHeaderRealtimeTypingCompleted: ', time);
    this.headerTypingComplete = true;
    this.displayExtraContent[this.activeWizardStep] = true;
    this.cdr.detectChanges();

    // Check if current step is in Introduction status and if extra content exists
    this.checkAndProgressAfterTyping();
  }

  // Handler for extra content typing completion
  onExtraContentRealtimeTypingCompleted(time: string) {
    console.log('onExtraContentRealtimeTypingCompleted: ', time);
    this.extraContentTypingComplete = true;
    this.cdr.detectChanges();

    // Check if we should automatically progress to next step
    this.checkAndProgressAfterTyping();
  }

  /**
   * Checks if typing is complete and automatically progresses to next step
   * if the current step is in Introduction status
   */
  private checkAndProgressAfterTyping() {

    if (this.extraContentTypingComplete && this.headerTypingComplete) {
      this.typingsCompleted.emit(
        {
          projectId: this.activeProjectId,
          wizardStep: this.activeWizardStep,
          typingsCompleted: true
        }
      );
    }
  }

  onWizardPreviousStepsButtonClicked() {
    this.activeWizardStep = this.activeWizardStep - 1;
  }
}
