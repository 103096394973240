import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderCRUDModelDisplayComponent } from './crud-model-display.component';

@NgModule({
  declarations: [
    RappiderCRUDModelDisplayComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    RappiderCRUDModelDisplayComponent,
  ]
})
export class RappiderCRUDModelDisplayModule { }
