<rappider-spin [spinning]="isLoading">
  <form *ngIf="!isLoading"
        nz-form
        [formGroup]="createWorkflowStepFunctionForm"
        [nzLayout]="formLayout"
        (ngSubmit)="onWorkflowStepFunctionFormSubmit()">

    <!-- Name -->
    <nz-form-item>
      <nz-form-label nzRequired
                     [nzSpan]="calculateFormLayout().label">
        {{ 'SHARED.NAME' | translate }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="nameFieldErrorTemplates"
                       [nzSpan]="calculateFormLayout().input">
        <rappider-textbox formControlName="name"
                          elementId="workflowStepFunctionName"
                          autoFocus="true"
                          (ngModelChange)="onNameChange($event)"></rappider-textbox>
      </nz-form-control>
    </nz-form-item>

    <!-- Subscribed Events -->
    <nz-form-item>
      <nz-form-label nzRequired
                     [nzSpan]="calculateFormLayout().label">
        {{ 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.SUBSCRIBED_EVENTS' | translate }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                       [nzSpan]="calculateFormLayout().input">
        <rappider-select [options]="subscribedEventsSelectConfig.options"
                         [settings]="subscribedEventsSelectConfig.settings"
                         formControlName="subscribedEvents"></rappider-select>
      </nz-form-control>
    </nz-form-item>

    <!-- Step Function Mode Select -->
    <nz-form-item>
      <nz-form-label nzRequired
                     [nzSpan]="calculateFormLayout().label">
        {{ 'Workflow Function Mode' | translate }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                       [nzSpan]="calculateFormLayout().input">
        <rappider-select [ngModel]="workflowFunctionModeRadioGroupValue"
                         [options]="workflowFunctionModeRadioGroupOptions"
                         [disabled]="createWorkflowStepFunctionForm.get('mode').disabled"
                         formControlName="mode"
                         (ngModelChange)="stepFunctionModeSelectValueChange($event)"></rappider-select>
      </nz-form-control>
      <nz-form-label *ngIf="!createWorkflowStepFunctionForm?.get('mode')?.value && isSubmitButtonClick">
        <small style="color: var(--danger-color); font-weight: 300;">
          {{'This field is required' | translate}}</small>
      </nz-form-label>
    </nz-form-item>

    <!-- Rappider Workflow Services -->
    <nz-form-item *ngIf="workflowFunctionModeRadioGroupValue === WorkflowFunctionTypeOptions.RappiderServices">
      <nz-form-label nzRequired
                     [nzSpan]="calculateFormLayout().label">
        {{ 'Rappider Workflow Services' | translate }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                       [nzSpan]="calculateFormLayout().input">
        <rappider-custom-function-crud-modal-wrapper [activeCustomFunctionId]="data?.customFunctionDefinitionId"
                                                     [mode]="mode"
                                                     (activeCustomFunctionEmit)="onActiveCustomFunctionEmit($event)"></rappider-custom-function-crud-modal-wrapper>
      </nz-form-control>
      <nz-form-label
                     *ngIf="!createWorkflowStepFunctionForm?.get('customFunctionDefinitionId')?.value && isSubmitButtonClick">
        <small style="color: var(--danger-color); font-weight: 300;">
          {{'This field is required' | translate}}</small>
      </nz-form-label>
    </nz-form-item>

    <!-- Workflow Functions -->
    <nz-form-item *ngIf="workflowFunctionModeRadioGroupValue === WorkflowFunctionTypeOptions.WorkflowFunctions">
      <nz-form-label nzRequired
                     [nzSpan]="calculateFormLayout().label">
        {{ 'Workflow Functions' | translate }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="requiredErrorTemplate"
                       [nzSpan]="calculateFormLayout().input">
        <rappider-custom-function-crud-modal-wrapper [activeCustomFunctionId]="data?.customFunctionDefinitionId"
                                                     [mode]="mode"
                                                     (activeCustomFunctionEmit)="onActiveCustomFunctionEmit($event)"></rappider-custom-function-crud-modal-wrapper>
      </nz-form-control>
      <nz-form-label
                     *ngIf="!createWorkflowStepFunctionForm?.get('customFunctionDefinitionId')?.value && isSubmitButtonClick">
        <small style="color: var(--danger-color); font-weight: 300;">
          {{'This field is required' | translate}}</small>
      </nz-form-label>
    </nz-form-item>

    <nz-collapse nzGhost="true">
      <nz-collapse-panel nzHeader="Advanced Settings (Optional)"
                         nzActive="false">
        <!-- Published Events On Success -->
        <nz-form-item>
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PUBLISHED_EVENTS_ON_SUCCESS' | translate
            }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="publishedEventsOnSuccess.options"
                             [settings]="publishedEventsOnSuccess.settings"
                             formControlName="publishedEventsOnSuccess"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Published Events On Failure -->
        <nz-form-item>
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PUBLISHED_EVENTS_ON_FAILURE' | translate
            }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="publishedEventsOnFailure.options"
                             [settings]="publishedEventsOnFailure.settings"
                             formControlName="publishedEventsOnFailure"></rappider-select>
          </nz-form-control>
        </nz-form-item>

        <!-- Custom/Endpoint Radio -->
        <!-- <nz-form-item>
          <nz-form-label [nzSpan]="calculateFormLayout().label"
                         [nzNoColon]="true">
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-radio-group [options]="customOrEndpointRadioGroupOptions"
                                  size="small"
                                  formControlName="customOrEndpoint"
                                  (ngModelChange)="onCustomOrEndpointRadioValueChange($event)"></rappider-radio-group>
          </nz-form-control>
        </nz-form-item> -->

        <!-- Project Model -->
        <!-- <nz-form-item *ngIf="customOrEndpointRadioGroupValue === StepFunctionEndpointOrCustomOptions.Endpoint">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PROJECT_MODEL_FOR_ENDPOINT' | translate
            }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="projectModelOptions"
                             formControlName="projectModel"
                             (ngModelChange)="onProjectModelValueChange($event)"></rappider-select>
          </nz-form-control>
        </nz-form-item> -->

        <!-- Endpoint ID -->
        <!-- <nz-form-item *ngIf="customOrEndpointRadioGroupValue === StepFunctionEndpointOrCustomOptions.Endpoint">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PROJECT_MODEL_ENDPOINT' | translate }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-select [options]="endpointOptions"
                             formControlName="endpointId"></rappider-select>
          </nz-form-control>
        </nz-form-item> -->

        <!-- Custom Code-->
        <!-- <nz-form-item *ngIf="customOrEndpointRadioGroupValue === StepFunctionEndpointOrCustomOptions.Custom">
          <nz-form-label [nzSpan]="calculateFormLayout().label">
            {{ 'SHARED.CODE' | translate }}
          </nz-form-label>
          <nz-form-control [nzSpan]="calculateFormLayout().input">
            <rappider-monaco-code-editor [options]="monacoEditorSettings.editorOptions"
                                    [sizeSettings]="monacoEditorSettings.sizeSettings"
                                    formControlName="customCode"></rappider-monaco-code-editor>
          </nz-form-control>
        </nz-form-item> -->
      </nz-collapse-panel>
    </nz-collapse>

    <!-- Submit Button -->
    <nz-form-item [ngClass]="isSubmitButtonFloat ? 'float-submit-button' : 'submit-button'">
      <nz-form-control [nzSpan]="calculateFormLayout().input">
        <button nz-button
                type="submit"
                nzType="primary"
                [nzLoading]="submitButtonLoading">
          {{ 'SHARED.SUBMIT' | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>

    <!-- Error Templates -->
    <ng-template #nameFieldErrorTemplates>
      <small *ngIf="getNameFieldErrorsByErrorKey('required')">
        - {{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'|translate }}
        <br />
      </small>
      <small *ngIf="getNameFieldErrorsByErrorKey('pattern')">
        - {{'ERRORS.PATTERN_MESSAGES.MUST_START_WITH_LOWERCASE_LETTER' | translate }}
      </small>
    </ng-template>
    <ng-template #requiredErrorTemplate>
      <small>
        - {{ 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'|translate }}
      </small>
    </ng-template>
  </form>
</rappider-spin>
