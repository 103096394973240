import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PROJECT_MEMBERS_CONFIG } from '@rappider/shared/configs';

@Component({
  selector: 'rappider-project-members-navigation',
  templateUrl: './project-members-navigation.component.html',
  styleUrls: ['./project-members-navigation.component.scss'],
})
export class ProjectMembersNavigationComponent {
  @Input() projectMembers?: any[] | undefined;

  PROJECT_MEMBERS_CONFIG = PROJECT_MEMBERS_CONFIG;

  onItemClick(item: any) {
    console.log('item', item);
  }
}
