export enum ProjectDesignTaskIdentifier {
  ImproveProjectDescription = 'improve-project-description',
  UpdateProjectInformation = 'update-project-information',
  GenerateScopeOfWork = 'generate-scope-of-work',
  GenerateDataModelsAndDatabase = 'generate-data-models-and-database',
  GenerateAPIEndpoints = 'generate-api-endpoints',
  GenerateDesignSystem = 'generate-design-system',
  GenerateTheme = 'generate-theme',
  // GenerateCustomCSSStyles = 'generate-custom-css-styles', // TODO: implement
  GenerateUIPageLayouts = 'generate-ui-page-layouts',
  GenerateUIPages = 'generate-ui-pages',
  GenerateUIBusinessProcesses = 'generate-ui-business-processes',
  GenerateMessageTemplates = 'generate-message-templates',
  GenerateServerSideBusinessProcesses = 'generate-server-side-business-processes',
  GenerateSettingsVariablesAndEnvironments = 'generate-settings-variables-and-environments',
  // AddProjectPackages = 'add-project-packages', // TODO: implement
  // AddProjectExternalScripts = 'add-project-external-scripts', // TODO: implement
  GenerateProjectCode = 'generate-project-code',
  ProjectMembers = 'ProjectMembers',
  Deploy = 'deploy',
  ShareApplication = 'shareApplication'
}

export enum ProjectDesignTaskStatus {
  NotStarted = 'not-started',
  InProgress = 'in-progress',
  Introduction = 'introduction',
  Completed = 'completed',
  Error = 'error',
}
