import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CardOneComponentConfig, HeadingType, ItemPerRow, PaginationComponentConfig, SelectComponentConfig, SelectMode, TextMode, TitleToolbarComponentConfig } from '@rappider/rappider-components/utils';
import { Page } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';
import { Subscription } from 'rxjs';
import { getCreationInformationFromEntity } from '@rappider/shared-functions';
import { GetTemplatePages, GetTemplatePagesPagination } from 'libs/pages/src/lib/state/page-state/page.actions';
import { getTemplatePagesWithTags } from './utils/get-template-pages-with-tags-selector';
import { Permission } from 'apps/rappider/src/app/components/header/utils/admin-dashboard-permissions.enum';

@Component({
  selector: 'rappider-page-templates',
  templateUrl: './page-templates.component.html',
  styleUrls: ['./page-templates.component.scss']
})
export class PageTemplatesComponent implements OnInit, OnDestroy {

  /**
 * to check if used in modal
 *
 * @memberof PageTemplatesComponent
 */
  @Input() isModalOpen = false;
  /**
   * To show active item
   *
   * @memberof PageTemplatesComponent
   */
  @Input() showCheckMarkOnCard = false;

  @Output() cardClick = new EventEmitter<string>();

  templatePages: Page[];
  items: CardOneComponentConfig[];
  itemCountPerRow = ItemPerRow.Three;
  showTitleOnImage = false;
  showDescriptionOnImage = false;
  isLoading: boolean;
  subscriptions: Subscription[];
  title: TitleToolbarComponentConfig = {
    mainTitle: {
      content: 'Page Templates',
      type: HeadingType.H2
    }
  };
  paginationConfig: PaginationComponentConfig = {
    pageIndex: 1,
    total: 3,
    pageSize: 6,
    showPagination: true
  };
  selectConfig: SelectComponentConfig = {
    options: [],
    settings: {
      mode: SelectMode.Multiple,
      maxTagCount: 1
    },
    placeholder: 'Filter Tags'
  };

  isDynamicPagination = true;
  timer;
  searchText: string;
  tags: string[];
  permissions: string[];

  constructor(
    private store: Store<any>,
    private formatDatePipe: FormatDatePipe
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToPageTemplatesWithTags(),
      this.subscribeToIsPagesTemplateLoading(),
      this.subscribeToPagination(),
      this.subscribeToPermissions()
    ];
  }

  ngOnDestroy(): void {
    this.store.dispatch(new GetTemplatePagesPagination({ pageIndex: 1, pageSize: 6 }));
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToPageTemplatesWithTags() {
    return this.store.select(<any>getTemplatePagesWithTags).subscribe((templatePagesWithTags) => {
      if (templatePagesWithTags) {
        this.templatePages = templatePagesWithTags.templatePages;
        this.selectConfig.options = templatePagesWithTags.templatePagesTags.map(tag => ({
          key: tag,
          value: tag
        }));
        this.mapDataToItems();
      }
    });
  }

  subscribeToIsPagesTemplateLoading() {
    return this.store.select(state => state.page?.isTemplatePagesLoading).subscribe((isTemplatePagesLoading: boolean) => {
      this.isLoading = isTemplatePagesLoading;
    });
  }

  subscribeToPermissions() {
    return this.store.select(state => state?.auth?.permissions).subscribe(permissions => {
      this.permissions = permissions;
    });
  }

  mapDataToItems() {
    this.items = this.templatePages.map(template => ({
      data: template,
      image: {
        source: template.thumbnailImageUrl || 'assets/img/abstracts/elizabeth-lies-ZWPerNlqUu0-unsplash.jpg'
      },
      titles: [
        {
          type: HeadingType.H4,
          content: template.title
        }
      ],
      descriptions: [
        {
          content: template.description
        },
        {
          content: getCreationInformationFromEntity(template, this.formatDatePipe.transform(template.createdDate))
        }
      ],
      additionalTags: template.tags?.map(tag => ({
        text: {
          textMode: TextMode.Text,
          text: tag
        }
      }))
    }));
  }

  subscribeToPagination() {
    return this.store.select(state => state.page.templatePagesCount).subscribe((count: number) => {
      if (count) {
        this.paginationConfig.total = count;
      }
    });
  }

  /**
   * If it is used in modal, it only needs to emit. It should not navigate any page.
   * @param pageId
   */
  onCardClick(pageId: string) {

    if (!this.isModalOpen) {
      if (this.permissions.includes(Permission.Admin)) {
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${pageId}` }));
      }
    } else {
      this.cardClick.emit(pageId);
    }
  }

  onPageIndexChange(pageIndex: number) {
    this.store.dispatch(new GetTemplatePages({ pageIndex, pageSize: 6, searchText: this.searchText, tags: this.tags, isDynamicPagination: true }));
  }

  onTagFilterChange(tags) {
    this.tags = tags;
    this.store.dispatch(new GetTemplatePagesPagination({ pageIndex: 1, pageSize: 6, searchText: this.searchText, tags }));
  }

  onSearchTextChange(searchText: string) {
    if (searchText || searchText === '') {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.searchText !== searchText) {
          this.searchText = searchText;
          this.store.dispatch(new GetTemplatePagesPagination({ pageIndex: 1, pageSize: 6, searchText, tags: this.tags }));
          this.paginationConfig.pageIndex = 1;
        }
      }, 1000);
    }
  }
}
