import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProjectInterface, ProjectRoleInterface } from '@rappider/api-sdk';
import { BreadcrumbOption, CrudFormConfig, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { PersonInvitationInviteRequestDto } from '@rappider/rappider-sdk';
import { INVITED_USER_LIST_CONFIG, INVITE_USER_VIA_MAIL_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { CancelInvitation, GetInvitedUsers, InviteUser, ResendInvitation } from '../../states/user-invitations-state/user-invitation.actions';
import { UserInvitationButtonMode } from './utils/user-invitations-button-mode.enum';

@Component({
  selector: 'rappider-user-invitations',
  templateUrl: './user-invitations.component.html',
  styleUrls: ['./user-invitations.component.scss']
})
export class UserInvitationsComponent implements OnInit, OnDestroy {

  /* loading status */
  isLoading: boolean;
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* component title */
  title: string | string[] | BreadcrumbOption[];
  /* invited users list grid config */
  INVITED_USER_LIST_CONFIG = cloneDeep(INVITED_USER_LIST_CONFIG);
  INVITE_USER_CONFIG: CrudFormConfig;
  INVITE_USER_VIA_MAIL_CONFIG = INVITE_USER_VIA_MAIL_CONFIG;
  /* invited user list */
  invitedUsers: any[];
  /* active project from state */
  activeProject: ProjectInterface;
  /* Subscriptions */
  subscriptions: Subscription[];
  /* invite user modal variables */

  inviteUser: {
    title: string;
    visible: boolean;
    isSubmitted: boolean;
    isValid: boolean;
    fullScreen: boolean;
    data: PersonInvitationInviteRequestDto;
  } = {
      title: 'Invite User',
      visible: false,
      isSubmitted: false,
      isValid: false,
      fullScreen: true,
      data: { email: '', invitedFirstName: '', invitedLastName: '', isManager: false }
    };

  displayToolbar = false;
  displayToolbarBackButton = false;
  projectRoles: ProjectRoleInterface[];

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Subscribe to all data
   *
   * @memberof UserInvitationsComponent
   */
  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToUserInvitation(),
      this.subscribeToLadingState()
    ];
  }

  /**
 * Subscribe User
 *
 * @return {*}
 * @memberof UserInvitationsComponent
 */
  /**
   * get active project from state and get invited users by active project id
   *
   * @return {*}
   * @memberof UserInvitationsComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (activeProject) {
        this.activeProject = activeProject;
        this.mainTitle = {
          content: 'User Invitation',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: this.activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: 'User Invitation'
          }
        ];
        this.getInvitedUsers();
        // this.subscribeToProjectRole();
      }
    });
  }

  /**
   * Subscribe to user invitation state
   *
   * @return {*}
   * @memberof UserInvitationsComponent
   */
  subscribeToUserInvitation() {
    return this.store.select(state => state.userInvitation).subscribe(userInvitationState => {
      if (userInvitationState.data) {
        this.invitedUsers = userInvitationState.data;
      }
    });
  }

  subscribeToProjectRole() {
    return this.store.select(state => state.projectRole?.data).subscribe(projectRoleState => {
      if (projectRoleState) {
        this.projectRoles = projectRoleState.map(projectRole => ({
          key: projectRole?.title,
          value: projectRole?.title,
        })
        );
      }
      this.setMultipleSelectItem();
    });
  }

  setMultipleSelectItem() {
    this.INVITE_USER_VIA_MAIL_CONFIG.items = this.INVITE_USER_VIA_MAIL_CONFIG.items.map(item => {
      if (item.fieldName === 'usersRoles') {
        return {
          ...item,
          options: this.projectRoles
        };
      } else {
        return item;
      }
    });
  }

  subscribeToLadingState() {
    return this.store.select(state => state.userInvitation.isLoading).subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  /**
 * Get Project Versions
 *
 * @memberof UserInvitationsComponent
 */

  getInvitedUsers() {
    this.store.dispatch(GetInvitedUsers());
  }

  /**
 * set user invite form by username type
 *
 * @param {UsernameType} usernameType
 * @memberof UserInvitationsComponent
 */
  /**
  * Open Invite User Modal
  *
  * @param {*} data
  * @memberof UserInvitationsComponent
  */
  openInviteNewUserModal(event) {
    const inviteUserEventName = event?.action?.name;
    if (inviteUserEventName === 'Invite User') {
      this.INVITE_USER_CONFIG = INVITE_USER_VIA_MAIL_CONFIG;
      this.inviteUser.visible = true;
    }
  }

  /**
   * Reset invite user modal and close invite user modal
   *
   *
   * @memberof UserInvitationsComponent
   */

  closeInviteUser() {
    this.inviteUser.visible = false;
    this.inviteUser.data = { email: '', invitedFirstName: '', invitedLastName: '', isManager: false };
    this.inviteUser.isValid = true;
    this.inviteUser.isSubmitted = false;
  }

  /**
   * Submit form
   *
   * @memberof UserInvitationsComponent
   */

  onInviteUserSubmit() {
    this.inviteUser.isSubmitted = true;
    if (this.inviteUser.isValid) {
      if (this.inviteUser.isSubmitted && this.inviteUser.isValid) {
        this.store.dispatch(InviteUser({
          payload: {
            invitedUser: {
              email: this.inviteUser.data.email,
              invitedFirstName: this.inviteUser.data.invitedFirstName,
              invitedLastName: this.inviteUser.data.invitedLastName,
              isManager: this.inviteUser.data.isManager
            }
          }
        }));
      }
      this.closeInviteUser();
    }
  }

  onInviteUserDataChange(userInvitationData: PersonInvitationInviteRequestDto) {
    this.inviteUser.data = userInvitationData;
  }

  onInviteUserIsValid(isValid: boolean) {
    this.inviteUser.isValid = isValid;
  }

  resendOrCancelInvitation(invitationData) {
    if (invitationData.action.name === UserInvitationButtonMode.Resend) {
      this.store.dispatch(ResendInvitation({ payload: { invitationId: invitationData.data.id } }));
    } else if (invitationData.action.name === UserInvitationButtonMode.Cancel) {
      this.store.dispatch(CancelInvitation({ payload: { invitationId: invitationData.data.id } }));
    }
  }
}
