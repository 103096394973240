import { PreviewGridType } from './preview-grid-type.enum';

export interface ButtonDefinition {
  key: PreviewGridType;
  icon: IconDefinition;
}

export interface IconDefinition {
  name: string;
}

export const buttons: ButtonDefinition[] = [
  {
    key: PreviewGridType.Row,
    icon: {
      name: 'fas fa-grip-lines'
    }
  },
  {
    key: PreviewGridType.TwoColumns,
    icon: {
      name: 'fas fa-line-columns'
    }
  },
  {
    key: PreviewGridType.FourColumns,
    icon: {
      name: 'fa-solid fa-columns-3'
    }
  },
];

export const defaultImagePath = '/assets/img/placeholders/img-placeholder.png';
