import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  ProjectModelControllerService,
  ProjectModelField,
  ProjectModelWithRelations,
  ProjectModelFieldControllerService,
  ProjectModel,
  UiDataStoreControllerService
} from '@rappider/rappider-sdk';
import { NotificationService, RouterStateService, StringTransformService } from '@rappider/services';
import { PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { plural } from 'pluralize';
import { camelCase } from 'lodash';
import { ErrorAction } from '../data-schema/data-schema.actions';
import { Router } from '@angular/router';
import { Navigate } from '@rappider/shared';

import * as ProjectModelActions from './project-model.actions';
import * as ModuleActions from './../../../../../module/src/lib/state/module.actions';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import * as ProjectModelFieldActions from 'libs/project-model-field/src/lib/state/project-model-field.actions';
import * as DataSchemaActions from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import * as UIDataStoreActions from './../../../../../project/src/lib/states/ui-data-store/ui-data-store.actions';
import { environment } from '@environment';

@Injectable()
export class ProjectModelEffects {

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private store: Store<any>,
    private actions$: Actions,
    private projectModelApi: ProjectModelControllerService,
    private notificationService: NotificationService,
    private routerStateService: RouterStateService,
    private stringTransformService: StringTransformService,
    private projectModelFieldApi: ProjectModelFieldControllerService,
    private router: Router,
    private uiDataStoreApi: UiDataStoreControllerService,
    // eslint-disable-next-line no-empty-function
  ) { }


  getProjectModels$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.GetProjectModels>(
      ProjectModelActions.ActionTypes.GetProjectModels
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mergeMap(([action, activeProjectIdByState]) => {
      if (activeProjectIdByState) {
        const params = {
          filter: {
            where: {
              projectId: activeProjectIdByState,
            },
            include: [
              'relations',
              'generatedUIDataStore',
            ]
          }
        };
        return this.projectModelApi.find(params).pipe(
          mergeMap((projectModels: ProjectModelWithRelations[]) => [
            new ProjectModelActions.GetProjectModelsSuccessful({ projectModels: projectModels }),
          ])
        );
      } else {
        return [
          new ProjectModelActions.ErrorAction({ error: 'NoActiveProject', key: 'GetProjectModels', timestamp: Date.now() })
        ];
      }
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'PROJECT_MODULE.PROJECT_MODEL_LIST_COMPONENT.PROJECT_MODEL_LIST',
        'SHARED.COULDNT_LOAD'
      );
      return [
        new ProjectModelActions.ErrorAction({
          error: error,
          key: 'GetProjectModels',
          timestamp: Date.now()
        })
      ];
    })
  ));


  createProjectModel$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.CreateProjectModel>(
      ProjectModelActions.ActionTypes.CreateProjectModel
    ),
    mergeMap((action) => {
      const params = {
        body: {
          ...action.payload.projectModel,
          generateUIDataStore: true,
        }
      };
      return this.projectModelApi.create(params).pipe(
        mergeMap((projectModel: ProjectModel) => {
          this.notificationService.createNotification(
            'success',
            projectModel.name,
            'PROJECT_MODULE.PROJECT_MODEL_CREATE_COMPONENT.PROJECT_MODEL_CREATED'
          );
          if (action.payload.navigateAfterCreate) {
            /* TODO: needs Router state navigate action */
            this.router.navigate(
              [`${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${projectModel.id}`],
              { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.FIELDS_TAB }
            );
          }
          return [
            new ProjectModelActions.CreateProjectModelSuccessful({ projectModel }),
            new DataSchemaActions.GetDataSchemaById({ id: projectModel.relationedTypeId })
          ];
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            params.body.name,
            error?.error?.error?.message
          );
          return [
            new ProjectModelActions.ErrorAction({
              error: error,
              key: ProjectModelActions.ActionTypes.CreateProjectModel,
              timestamp: Date.now()
            })
          ];
        })
      );
    })
  ));


  updateProjectModel$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.UpdateProjectModel>(
      ProjectModelActions.ActionTypes.UpdateProjectModel
    ),
    mergeMap((action) => {
      let params = {
        id: action.payload.id,
        body: {
          ...action.payload.projectModel,
          scope: action.payload.projectModel.scope ?? []
        }
      };

      if (params.body.name) {
        if (!params.body.dataTableName) {
          /* set project model name to data table name */
          const dataTableName = camelCase(params.body.name);
          params = {
            ...params,
            body: {
              ...params.body,
              dataTableName: dataTableName
            }
          };
        }

        if (!params.body.basePath) {
          /* pluralizes and transforms project model name to camel case for base path */
          const basePath = this.stringTransformService
            .getKebabCaseTextFromCapitalizedCamelCaseText(plural(params.body.name));

          params = {
            ...params,
            body: {
              ...params.body,
              basePath: basePath,
            }
          };
        }
      }

      return this.projectModelApi.updateById(params).pipe(
        map((projectModel: ProjectModel) => {
          this.notificationService.createNotification(
            'success',
            projectModel.name,
            'SHARED.SUCCESSFULLY_UPDATED'
          );
          if (action.payload.navigateAfterUpdate) {
            this.routerStateService.navigate(PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_LIST);
          }
          return new ProjectModelActions.UpdateProjectModelSuccessful(
            {
              id: action.payload.id,
              projectModel: projectModel
            }
          );
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            params.body.name,
            'SHARED.COULDNT_UPDATED'
          );
          return [
            new ProjectModelActions.ErrorAction({ error: error, key: 'UpdateProjectModel', timestamp: Date.now() })
          ];
        })
      );
    })
  ));


  deleteProjectModel$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.DeleteProjectModel>(
      ProjectModelActions.ActionTypes.DeleteProjectModel
    ), withLatestFrom(
      this.store.select(state => state.projectModel?.data)
    ),
    mergeMap(([action, projectModels]) => {
      const params = {
        id: action.payload.id
      };
      const deletedProjectModel = projectModels.find((projectModel: ProjectModel) => projectModel.id === action.payload.id);

      return this.projectModelApi.deleteById(params).pipe(
        mergeMap(() => {
          this.notificationService.createNotification(
            'success',
            deletedProjectModel.name,
            'SHARED.SUCCESSFULLY_DELETED'
          );
          return [
            new ProjectModelActions.DeleteProjectModelSuccessful({ id: action.payload.id }),
            new DataSchemaActions.GetDataSchemaById({ id: deletedProjectModel.relationedTypeId })
          ];
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            deletedProjectModel.name,
            error?.error?.error?.message
          );
          return [
            new ProjectModelActions.ErrorAction({ error: error, key: 'DeleteProjectModel', timestamp: Date.now() })
          ];
        })
      );
    })
  ));


  getProjectModelWithModelId$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.GetProjectModelWithModelId>(
      ProjectModelActions.ActionTypes.GetProjectModelWithModelId,
    ),
    mergeMap((action) => {
      const params = {
        id: action.payload.id,
        filter: {
          include: [
            'relations',
            'generatedUIDataStore',
          ]
        }
      };
      const isCrudPagesGenerated = action.payload.isCrudPagesGenerated;
      const calculatedDelay = Math.min(environment.delayTimeForRecursiveApiCalls * action.payload.tryCount, environment.maxDelayTimeForRecursiveApiCalls);
      if (!action.payload.tryCount || action.payload.tryCount <= environment.maxTryCountForRecursiveAPICalls) {
        return this.projectModelApi.findById(params).pipe(
          delay(calculatedDelay),
          mergeMap((projectModel: ProjectModelWithRelations) => {
            const actionsToDispatch: Action[] = [
              new ProjectModelActions.GetProjectModelWithModelIdSuccessful({ projectModel: projectModel })
            ];
            if (projectModel.isEachCRUDPageGenerated && !isCrudPagesGenerated) {
              actionsToDispatch.push(
                ModuleActions.GetModuleById({ payload: { moduleId: projectModel.relatedModuleId } }),
              );
            }
            if (!isCrudPagesGenerated) {
              actionsToDispatch.push(
                new ProjectModelActions.GetProjectModelWithModelId({ id: action.payload.id, tryCount: action.payload.tryCount + 1, isCrudPagesGenerated })
              );
            }
            return actionsToDispatch;
          }), catchError((error) => [
            new ProjectModelActions.GetProjectModelWithModelIdFailure({
              error: error,
              key: 'GetProjectModelWithModelIdFailure',
              timestamp: Date.now()
            })]
          )
        );
      } else {
        return [new ProjectModelActions.GetProjectModelWithModelIdFailure({
          error: 'There was an error while trying to get the project model',
          key: 'GetProjectModelWithModelIdFailure',
          timestamp: Date.now()
        })];
      }
    })
  ));


  createProjectModelField$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.CreateProjectModelField>
      (ProjectModelActions.ActionTypes.CreateProjectModelField),
    mergeMap((action) => {
      const params = {
        body: {
          ...action.payload.projectModelField,
          projectModelId: action.payload.projectModelId
        }
      };
      return this.projectModelFieldApi.create(params).pipe(
        mergeMap((projectModelField: ProjectModelField) => {
          /* create success notification  */
          this.notificationService.createNotification(
            'success',
            projectModelField.name,
            'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.PROJECT_MODEL_DATA_FIELD_SUCCESSFULY_CREATED'
          );
          this.router.navigate(
            [`${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${projectModelField.projectModelId}`],
            { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.FIELDS_TAB }
          );
          return [
            new ProjectModelActions.CreateProjectModelFieldSuccessful({ createdProjectModelField: projectModelField })
          ];
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            action.payload.projectModelField.name,
            'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_CREATE_COMPONENT.PROJECT_MODEL_DATA_FIELD_COULDNT_CREATED'
          );
          return [
            new ProjectModelActions.CreateProjectModelFieldFailure({ error: error })
          ];
        })
      );
    })
  ));


  createProjectModelFieldFailure$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.CreateProjectModelFieldFailure>
      (ProjectModelActions.ActionTypes.CreateProjectModelFieldFailure),
    map((action) => new ErrorAction({ error: action.payload.error, key: 'CreateProjectModelField', timestamp: Date.now() }))
  ));


  updateProjectModelField$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.UpdateProjectModelField>
      (ProjectModelActions.ActionTypes.UpdateProjectModelField),
    mergeMap(action => {
      const params = {
        id: action.payload.projectModelFieldId,
        body: action.payload.projectModelField
      };
      return this.projectModelFieldApi.updateById(params).pipe(
        mergeMap((projectModelField: ProjectModelField) => {
          this.notificationService.createNotification(
            'success',
            projectModelField.name,
            'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_EDIT_COMPONENT.PROJECT_MODEL_DATA_FIELD_SUCCESSFULY_UPDATED');
          this.router.navigate(
            [`${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${projectModelField.projectModelId}`],
            { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.FIELDS_TAB }
          );
          return [
            new ProjectModelActions.UpdateProjectModelFieldSuccessful({ updatedProjectModelField: projectModelField }),
            ProjectModelFieldActions.UpdateProjectModelFieldSuccessful({ payload: { projectModelFieldId: projectModelField.id, projectModelField: projectModelField, projectModelId: projectModelField.projectModelId } }),
            new DataSchemaActions.GetDataSchemaById({ id: action.payload.relationedTypeId })
          ];
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            params.body.name,
            'PROJECT_MODULE.PROJECT_MODEL_DATA_FIELD_EDIT_COMPONENT.PROJECT_MODEL_DATA_FIELD_COULDNT_UPDATED'
          );
          return [
            new ProjectModelActions.UpdateProjectModelFieldFailure({ error: error })
          ];
        })
      );
    })
  ));


  updateProjectModelFieldFailure$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.UpdateProjectModelFieldFailure>
      (ProjectModelActions.ActionTypes.UpdateProjectModelFieldFailure),
    map((action) => new ErrorAction({ error: action.payload.error, key: 'UpdateProjectModelField', timestamp: Date.now() }))
  ));


  deleteProjectModelField$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.DeleteProjectModelField>
      (ProjectModelActions.ActionTypes.DeleteProjectModelField),
    mergeMap(action => {
      const params = {
        id: action.payload.deletedProjectModelField.id
      };
      return this.projectModelFieldApi.deleteById(params).pipe(
        mergeMap(() => {
          this.notificationService.createNotification(
            'success',
            action.payload.deletedProjectModelField.name,
            'SHARED.SUCCESSFULLY_DELETED'
          );
          return [
            new ProjectModelActions.DeleteProjectModelFieldSuccessful(
              {
                deletedProjectModelFieldId: action.payload.deletedProjectModelField.id,
                projectModelId: action.payload.projectModelId
              }
            )
          ];
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            action.payload.deletedProjectModelField.name,
            'SHARED.COULDNT_DELETED'
          );
          return [
            new ProjectModelActions.DeleteProjectModelFieldFailure({ error: error })
          ];
        })
      );
    })
  ));


  deleteProjectModelFieldFailure$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.DeleteProjectModelFieldFailure>
      (ProjectModelActions.ActionTypes.DeleteProjectModelFieldFailure),
    map(action => new ErrorAction({ error: action.payload.error, key: 'DeleteProjectModelField', timestamp: Date.now() }))
  ));


  createProjectModelsFromJSON$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.CreateProjectModelsFromJSON>
      (ProjectModelActions.ActionTypes.CreateProjectModelsFromJSON),
    mergeMap(action => {
      const body = {
        jsonData: action.payload.exampleData,
        modelName: action.payload.modelName
      };
      return this.projectModelApi.createByJSONData({ body }).pipe(
        map(() => new ProjectModelActions.CreateProjectModelsFromJSONSuccessful()),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.COULDNT_CREATED',
            'SHARED.COULDNT_CREATED'
          );
          return [
            new ErrorAction({ error, key: 'CreateProjectModelsFromJSON', timestamp: Date.now() })
          ];
        })
      );
    })
  ));


  createProjectModelsFromJSONSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.CreateProjectModelsFromJSONSuccessful>
      (ProjectModelActions.ActionTypes.CreateProjectModelsFromJSONSuccessful),
    mergeMap((action) => {
      this.notificationService.createNotification(
        'success',
        'SHARED.SUCCESSFULLY_CREATED',
        'SHARED.SUCCESSFULLY_CREATED'
      );
      return [
        new ProjectModelActions.GetProjectModels(),
        new ProjectModelActions.ToggleCreateProjectModelsFromJSONModal()
      ];
    })
  ));


  createCrudPagesForProjectModel$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.CreateCrudPagesForProjectModel>
      (ProjectModelActions.ActionTypes.CreateCrudPagesForProjectModel),
    mergeMap((action) => this.projectModelApi.createDefaultCrudPages({
      id: action.payload.projectModel.id,
      options: { layoutId: action.payload.layoutId, replaceExistingCrudPages: action.payload.replaceExistingCrudPages }
    }).pipe(
      map(() => {
        this.notificationService.createNotification(
          'success',
          action.payload.projectModel.name,
          'CRUD pages have been generated successfully'
        );
        return new ProjectModelActions.CreateCrudPagesForProjectModelSuccessful({ projectModel: action.payload.projectModel });
      }), catchError((error) => {
        this.notificationService.createNotification(
          'error',
          action.payload.projectModel.name,
          JSON.parse(error?.error)?.error?.message || 'There was an error while generating CRUD pages'
        );
        return [
          new ProjectModelActions.CreateCrudPagesForProjectModelFailure({ error, key: 'CreateCrudPagesForProjectModel', timestamp: Date.now() })
        ];
      })
    ))
  ));


  createCrudPagesForProjectModelSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.CreateCrudPagesForProjectModelSuccessful>
      (ProjectModelActions.ActionTypes.CreateCrudPagesForProjectModelSuccessful),
    mergeMap((action) => {
      if (action.payload.projectModel.isEachCRUDPageGenerated) {
        return [ModuleActions.GetModuleById({ payload: { moduleId: action.payload.projectModel.relatedModuleId } })];
      } else {
        return this.projectModelApi.findById({ id: action.payload.projectModel.id }).pipe(
          delay(environment.delayTimeForRecursiveApiCalls),
          map((projectModel) => new ProjectModelActions.CreateCrudPagesForProjectModelSuccessful({ projectModel }))
        );
      }

    })
  ));


  generateDataStore$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.GenerateDataStore>(
      ProjectModelActions.ActionTypes.GenerateDataStore
    ),
    mergeMap((action) => {
      const params = { projectModelId: action.payload.projectModelId };
      return this.uiDataStoreApi.createUIDataStoreByProjectModel(params).pipe(
        mergeMap((uiDataStore) => {
          this.notificationService.createNotification(
            'success',
            uiDataStore.name,
            'SHARED.SUCCESSFULLY_CREATED'
          );
          const publishedActions: Action[] = [
            new ProjectModelActions.GenerateDataStoreSuccessful({ projectModelId: action.payload.projectModelId, generatedUIDataStore: uiDataStore }),
            new UIDataStoreActions.GetUIDataStoreByProjectModelId({ projectModelId: action.payload.projectModelId, tryCount: 0 })
          ];
          return publishedActions;
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'Data Store could not be created.',
            error?.error?.error?.message
          );
          return [
            new ProjectModelActions.GenerateDataStoreFailure({ error, key: 'GenerateDataStore', timestamp: Date.now(), projectModelId: action.payload.projectModelId })
          ];
        })
      );
    })
  ));


  createProjectModelsFromPostgreMetadata$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.CreateProjectModelsFromPostgreMetadata>(
      ProjectModelActions.ActionTypes.CreateProjectModelsFromPostgreMetadata
    ),
    mergeMap((action) => this.projectModelApi.createModelsByPostgreSQLExport({ body: action.payload.postgreMetadata }).pipe(
      map(() => new ProjectModelActions.CreateProjectModelsFromPostgreMetadataSuccessful()),
      catchError((error) => [
        new ErrorAction({ error, key: 'CreateProjectModelsFromPostgreMetadata', timestamp: Date.now() })
      ])
    ))
  ));


  createProjectModelsFromPostgreMetadataSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectModelActions.CreateProjectModelsFromPostgreMetadataSuccessful>(
      ProjectModelActions.ActionTypes.CreateProjectModelsFromPostgreMetadataSuccessful
    ),
    map(() => new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_LIST }))
  ));
}

