/* eslint-disable max-len */


export const welcomeStepIntroductionContent = `
Welcome to **Rapider.AI** – a powerful, AI-low-code software development platform designed to help you build your applications, modules, scripts and integrations faster and more efficiently.

<br>

Lets build a free web application together using your project description.
`;

export const welcomeStepIntroductionExtraContent = `

#### What is Rapider.AI?

<br>

<span style="font-size:1.4rem; font-weight:600">
Rapider.AI is an "AI Software Developer" to help you build smart applications that also uses AI to automate your business processes.
</span>

<br>
<br>


<span style="font-size:1.2rem; font-weight:600">Yes! Rapider AI build apps that has AI in it.</span>

<br>

Rapider.AI uses artificial intelligence and best practice enterprise scaffolds to generate code, components, and workflows based on your project requirements, allowing you to create applications with minimal coding effort.

Rapider.AI allows users to create web and mobile applications, modules, APIs or scripts with minimal coding effort by BPMN logic diagrams and pre-built building blocks.
By leveraging AI-driven suggestions and intuitive interfaces, you can design and deploy applications tailored to your needs, whether you're a developer or someone with limited coding experience.

<br>

#### How Does It Work?
1. **AI-Powered Development**: Rapider.ai uses artificial intelligence to suggest components, integrations, and optimizations as you build, allowing you to create complex applications quickly.
2. **Drag-and-Drop Interface**: With a user-friendly drag-and-drop interface, you can easily add elements such as forms, data tables, buttons, and charts to your app.
3. **Customizable Templates**: Start with customizable templates to speed up the process, and modify them to suit your project's requirements.
4. **Build Logic with BPMN Diagrams**: Design both frontend and backend logic visually using BPMN (Business Process Model and Notation) diagrams, allowing you to map out workflows and interactions seamlessly.
5. **Real-Time Collaboration**: Collaborate with your team in real-time and see changes reflected instantly in the development environment.
6. **Seamless Deployment**: Once you're ready, deploy your application to the web or mobile with just a few clicks.

<br>

#### Key Features of Rapider.AI:
- **Low-Code/No-Code/Your Code Environment**: Build applications without needing extensive coding knowledge, however Rapider will generate your code and you can view it and customize it.
- **AI Assistance**: Get recommendations for best practices, components, and workflows as you design.
- **Integration Ready**: Easily connect with external services like databases, APIs, and third-party applications.
- **Real-Time Preview**: See how your app looks and functions as you build.

Click **Next** to proceed to Step 2, where I'll help you to improve your project description.

`;

export const welcomeStepContent = `
### Getting Started with Rapider.AI



<br>

Click **Next** to proceed to Step 2, where I'll help you to improve your project description.

`;
// ---------------------------------------------------------------

export const projectDescriptionStepIntroductionContent = `
### Improve Your Project Description with AI

Your project description plays a crucial role in helping the AI generate better code and a more refined application tailored to your needs.
<br>

In this step, you'll get help from Rapider.AI to improve your project description that you previously wrote.

`;

export const projectDescriptionStepIntroductionExtraContent = `
#### Why is this important?
A detailed and accurate project description allows our AI to:
- **Understand your project requirements** more clearly.
- **Optimize the code and application structure** based on your goals.
- **Suggest better components and workflows** to improve functionality.

#### How does it work?
Rapider AI will analyze your project description and provide enhanced suggestions to make it more precise and comprehensive. This will ultimately help produce more effective code and a high-quality application.

`;

export const projectDescriptionStepContent = `
<div class='markdown-instructions-section'>
  Please click the blinking button near the "Project Description" section to improve your project description with AI and proceed. You can skip this step if you prefer to use your description as it is.
</div>

<br>

If you're ready to enhance it, simply click the **blinking** button near the "Project Description" section. Rapider.AI will take care of the rest, and you'll be on your way to building a better application!

`;

export const projectDescriptionStepCompletedContent = `
### Improve Your Project Description with AI

<br>

<span class='markdown-success-section'>
🎉 Great job! Your project description has been improved with AI assistance. Please click the next button to proceed.
</span><br><br>

`;
// ---------------------------------------------------------------

export const projectInformationStepIntroductionContent = `
### Review Your Project Information and Stack
In this step, you will review the core details of your project setup before proceeding with the build process. Below is a summary of your project’s key configurations:
`;

export const projectInformationStepIntroductionExtraContent = `

**Authentication Enabled**:
  If checked, your app will include an authentication module allowing users to log in securely via JWT (JSON Web Token).

<br>

**Application Type**:
  Defines whether your application is a **web** app or a **mobile** app.

<br>

**FrontEnd Programming Language & Framework**:
  You can choose one of the following frontend frameworks:
    - **Typescript & Angular**
    - **Typescript & React**
    - **Typescript & Vue**

<br>

**Backend Programming Language & Framework**:
  Choose your preferred backend technology from:
    - **NodeJS**
    - **Python**
    - **C#**
    - **Java**

<br>

**Database**:
  Select the database that best fits your project:
    - **PostgreSQL**
    - **MySQL**
    - **MongoDB**
    - **Others**

<br>

#### Free Plans
<div class="markdown-info-section">
Please note that free users are limited to NodeJS backend and Typescript/Angular frontend for web applications. To unlock additional options, contact our sales team to upgrade your plan.
</div>

`;


export const projectInformationStepContent = `
Please take a moment to review the information. Click **Next** to continue.
`;

// ---------------------------------------------------------------


export const projectMembersStepIntroductionContent = `
### Invite Others to Collaborate

Would you like to invite others to help you develop your project? Collaborating with team members can enhance the development process by allowing multiple contributors to work together seamlessly.

`;

export const projectMembersStepIntroductionExtraContent = `
#### Why invite others?
- **Boost Productivity**: Divide tasks among your team members to accelerate the development process.
- **Leverage Expertise**: Utilize the skills and insights of your colleagues for a better project outcome.
- **Real-Time Collaboration**: Work together in real-time, with changes reflected instantly for all team members.

`;

export const projectMembersStepContent = `

<div class="section markdown-optional-section">
This step is optional, and you can skip it if you prefer to work alone for now.
</div>

If you want to invite others, click **Invite User** to proceed. Otherwise, click **Skip** to continue to the next step.
`;

// ---------------------------------------------------------------


export const scopeOfWorkStepIntroductionContent = `

Based on the project description you provided, we will generated a Scope of Work for your basic CRUD MVP project.

`;

export const scopeOfWorkStepIntroductionExtraContent = `

Scope of Work documentation is a detailed document that outlines the project's objectives, deliverables, timeline, and other essential information. It serves as a roadmap for the project, ensuring that all stakeholders are aligned on the project's goals and requirements.

<br>

Rapider AI uses teh Scope of Work to generate the code and application structure based on your project's needs.

<br>

Please take a moment to review the details below to ensure everything aligns with your requirements. In this free version, you can only view the scope but cannot make any changes. If you'd like to modify or customize the scope, consider upgrading to access full editing capabilities.

<br>

<div class="markdown-info-section">
On an Enterprise plan, you can customize the Scope of Work to match your project's specific requirements.
</div>
`;


export const scopeOfWorkStepContent = `

You can click **Next** to proceed to the next step.
`;

// ---------------------------------------------------------------

export const dataModelsAndDatabaseStepsIntroductionContent = `
Lets generate the data models and database design for your project based on the project description and scope of work.
`;

export const dataModelsAndDatabaseStepsIntroductionExtraContent = `
Rapider AI is going to generate:

- **Data Models**
- **Database Design**
- **Data Fields**
- **Relationships** between models

<br>

Design of the data models are crucial for the features and operations of your application.
`;

export const dataModelsAndDatabaseStepsContent = `
Please take a moment to *review the data models and relationships* to ensure they match your project's requirements.
Click **Next** to proceed to the next step.
`;

// ---------------------------------------------------------------

export const apiEndpointsStepIntroductionContent = `
Rapider AI is going to create all the necessary API endpoints for your project. These endpoints will allow you to interact with the database and perform CRUD operations on your data models.
`;

export const apiEndpointsStepIntroductionExtraContent = `
Here's a summary of the endpoints plan:

- **Add APIs** – Create new records in the system
- **Update APIs** – Modify existing records
- **Get APIs** – Retrieve a single record by its identifier
- **List APIs** – Retrieve multiple records with optional filters and pagination
- **Relationship APIs** – Manage and retrieve relationships between data models

<br>

Rapider AI ensures that your application has a fully functional and scalable API structure to handle all core CRUD operations, as well as maintain data integrity through relationships.

<br>
`;

export const apiEndpointsStepContent = `
Please take a moment to review these endpoints. In this **free version**, you can **add custom endpoints** too.

<br>

Click **Next** to proceed to the next step.
`;

// ---------------------------------------------------------------

export const uiPagesStepIntroductionContent = `
Now, I am going to create the **UI pages** for your application's CRUD operations.
`;

export const uiPagesStepIntroductionExtraContent = `

Generating CRUD UI Page plan:

<br>

- **Create Page** – Add new records
- **Read Page** – View detailed information for a single record
- **Update Page** – Modify existing records
- **List Page** – View and search through multiple records

<br>

Additionally, **Rapider AI** provides an easy-to-use **Content Editor** that allows you to design and customize your UI exactly how you want.
Whether you want to tweak layouts, colors, or components, our content editor makes it simple to create a user-friendly experience.

`;

export const uiPagesStepContent = `
Please review the generated pages, click **Next** to proceed to the next step.
`;

// ---------------------------------------------------------------

export const uiPageLayoutsStepIntroductionContent = `
I have created **default page layouts** for your application.
`;

export const uiPageLayoutsStepIntroductionExtraContent = `

These layouts are designed to provide a clean, consistent, and user-friendly experience. The generated layouts include:

- **Header and Navigation Layout** – For easy access to key pages and sections
- **Content Layout** – Optimized for displaying page content and forms
- **List and Detail Layouts** – For viewing data in lists and detailed views
- **Dashboard Layout** – A summary view for important metrics and data

These layouts are fully customizable. You can modify the structure, components, and design through our **Content Editor** or **Theme Manager** to suit your specific needs.

`;

export const uiPageLayoutsStepContent = `
Please review the provided layouts and let us know when you're ready to proceed! You can change your default layout.
`;

// ---------------------------------------------------------------

export const themeStepIntroductionContent = `
Now, I am going to generate a **custom theme** for your application.
`;

export const themeStepIntroductionExtraContent = `

In this custom theme, I will add default styles for:

- **Colors**
- **Typography**
- **Buttons**
- **Forms**
- **Layout components**

<br>

You can manage and customize this theme using the **Theme Manager**. This tool lets you easily modify colors, fonts, and component styles to match your brand identity or personal preferences.

<br>
`;

export const themeStepContent = `
Please review the generated theme. If you'd like further customization, the **Theme Manager** is available to help you design a unique and consistent look for your application.

<br>

Click **Next** to proceed to the next step.
`;

// ---------------------------------------------------------------

export const designSystemStepIntroductionContent = `
Now, I am going to add UI building blocks (components) for your application. This is called a **Design System** with pre-built components and styles to help you create a consistent and professional user interface.

`;

export const designSystemStepIntroductionExtraContent = `

This design system includes all the necessary building blocks and components, ready to use and customize. Here's what it provides:

<br>

- **Typography** – Pre-defined font styles for headers, body text, and captions
- **Color Palette** – A consistent set of colors for primary, secondary, and accent elements
- **Buttons and Controls** – Ready-to-use buttons, input fields, checkboxes, and dropdowns
- **Layouts and Containers** – Pre-built sections for easy page structuring
- **Alerts, Modals, and Cards** – Common UI components for notifications and content display

<br>

With this design system, you can quickly create a cohesive and professional UI for your application. Each component is designed to work seamlessly with others, ensuring consistency across all your pages.

<br>

`;

export const designSystemStepContent = `
Feel free to explore components to match your needs. You can add new components.

<br>
In paid plans you can also modify your components.

<br>

Click **Next** to proceed to the next step.

`;

// ---------------------------------------------------------------

export const uiBusinessProcessesStepIntroductionContent = `
Now, I am going to create the **business processes** for your client (UI) application,
ensuring that your pages and data flow are fully functional and logically connected.

`;

export const uiBusinessProcessesStepIntroductionExtraContent = `
Here's how it works:

<br>

- We use **BPMN (Business Process Model and Notation) diagrams** to help you define and visualize the business logic of your application.
- This allows you to easily create and manage workflows, decisions, and data processes in a visual and intuitive way.
- **Rapider AI** also automatically generates all the necessary logic to handle **page interactions** and **data flow** between components, ensuring smooth operation.

<br>

`;

export const uiBusinessProcessesStepContent = `
You can review these processes and modify them using our BPMN editor to better suit your requirements.

<br>

Click **Next** to proceed to the next step.

`;

// ---------------------------------------------------------------

export const serverSideBusinessProcessesStepIntroductionContent = `

Now I am going to generate code for the **server-side business processes** for your application; events, micro-services and backend data flow management.

`;

export const serverSideBusinessProcessesStepIntroductionExtraContent = `

#### Server-Side Business Process Management

We use **BPMN (Business Process Model and Notation)** to visually define and automate backend processes. Here's what we are going to set up for you:

<br>

- **Server-Side Events (Pub/Sub)** – Automatically generated event-driven architecture using publish/subscribe mechanisms to handle real-time data updates and notifications.
- **Microservices** – Independent, scalable services that manage specific business logic or tasks, ensuring your application is modular and efficient.
- **Data Flow Management** – Seamless integration of backend processes with your application's data models and API endpoints.

<br>

With this BPMN-based system, you can easily visualize, modify, and optimize your backend processes to match your business needs.

<br>

`;

export const serverSideBusinessProcessesStepContent = `
You can create a sample action and microservice to see how it works.

<br>
This step might need some guidance, feel free to contact us to learn more about it.
<br>


Click **Next** to proceed to the next step.
`;

// ---------------------------------------------------------------

export const messageTemplatesStepIntroductionContent = `
Now I am going to generate code for managing **templates** for various communication and document creation needs.
`;

export const messageTemplatesStepIntroductionExtraContent = `
#### Templates for Email, SMS, and PDF Generated

Rapider AI automatically generates templates for various communication needs, ensuring your messages are consistent and professional across all channels.

<br>

Sample template types include:
<br>

- **Email Templates** – Pre-built designs for notifications, newsletters, and transactional emails
- **SMS Templates** – Concise message formats for alerts and updates
- **PDF Templates** – Professional document layouts for reports, invoices, and other printable content

<br>
The necessary **data is automatically injected** into these templates, ensuring that your messages and documents are dynamic and personalized.

`;

export const messageTemplatesStepContent = `

<br>
You can customize the templates to fit your style and business requirements through our easy-to-use template editor.

<br>
Click **Next** to proceed to the next step.
`;

// ---------------------------------------------------------------

export const environmentVariablesStepIntroductionContent = `
Now I am going to generate settings and **environment variables** for your application. These variables can be used to store sensitive information, configuration settings, and other global values that can be accessed across your application.
`;

export const environmentVariablesStepIntroductionExtraContent = `
You can review and modify these variables to suit your needs.

Here's how it works:

<br>

- **Environment Variables** – Define global variables that can differ based on the environment (e.g., development, staging, production).
- **Custom Variables** – Set reusable values to be used across backend and frontend logic, reducing redundancy and improving maintainability.
- **Dynamic Usage** – Variables can be injected directly into API calls, business processes, UI components, and workflows.

<br>

This feature enables you to efficiently manage configurations and adapt your application to different contexts with minimal effort.

<br>
`;

export const environmentVariablesStepContent = `
Please review the generated environments and variables, and let us know if you're ready to proceed or need further customization!
`;

// ---------------------------------------------------------------

export const generateProjectCodeStepIntroductionContent = `
**Congratulations! 🎉 Together, We've Successfully Created Your First App!**
`;

export const generateProjectCodeStepIntroductionExtraContent = `

Great job! You have completed all the steps to build your first application using our low-code platform. Here's what you can do next:

<br>

- **Build and Review Your Code** – Explore the generated code for your application.
- **Upgrade Your Plan** – Deploy your application to a live environment and unlock additional features such as advanced customization, integrations, and more.

<br>

Thank you for using our platform! Let us know if you need any help as you continue to develop and improve your app.

<br>

You're one step closer to launching your project—keep up the amazing work!
`;

export const generateProjectCodeStepContent = `
Please click the **Generate Code** button to complete the wizard. You can customize your application by using Rapider Low-Code Platform.
<br>

 You can download the code and deploy it to your server or or cloud platform.

 <br>

 Deployments are available in paid plans.

`;

// ---------------------------------------------------------------
