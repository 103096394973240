import { CardsConfig } from '@rappider/rappider-components/utils';
import { IconType, HeadingType, ButtonSize } from '@rappider/rappider-components/utils';
import { ItemPerRow } from 'libs/components/src/lib/utils/card-one-list';
import { SelectMode } from 'libs/components/src/lib/utils/select';

export const pageCardsConfig: { cardsConfig: CardsConfig } = {
  cardsConfig: {
    itemCountPerRow: ItemPerRow.Two,
    showTitleOnImage: false,
    showDescriptionOnImage: false,
    showCheckMarkOnCard: false,
    paginationConfig: {
      pageIndex: 1,
      total: 3,
      pageSize: 6,
      showPagination: false,
      nzFrontPagination: false
    },
    selectConfig: {
      options: [],
      settings: {
        mode: SelectMode.Multiple,
        maxTagCount: 1
      },
      placeholder: 'Filter Tags',
      visibility: false
    },
    items: [],
    inputGroupConfig: {
      textbox: {
        placeholder: 'Search in Pages',
      },
      suffixIcon: {
        name: 'fas fa-search',
        type: IconType.FontAwesome,
      }
    },
    bottomCards: [
      {
        data: { id: 'blank-page', type: 'create-blank-page' },
        titles: [
          {
            type: HeadingType.H1,
            content: '📄'
          },
          {
            type: HeadingType.H4,
            content: 'Create Blank Page',
            colorSettings: {
              color: 'var(--primary-color)',
            }
          }
        ],
        descriptions: [
          {
            content: 'Start with a clean slate and design your page from scratch with full customization options',
            typography: {
              fontSize: 'var(--subtext-font-size)',
              whiteSpace: 'pre-wrap'
            }
          }
        ]
      },
      {
        data: { id: 'ai-page', type: 'create-ai-page' },
        titles: [
          {
            type: HeadingType.H1,
            content: '⭐'
          },
          {
            type: HeadingType.H4,
            content: 'Create Page with AI',
            colorSettings: {
              color: 'var(--primary-color)',
            }
          }
        ],
        descriptions: [
          {
            content: 'Leverage AI to quickly generate a professional page based on your requirements and specifications',
            typography: {
              fontSize: 'var(--subtext-font-size)',
              whiteSpace: 'pre-wrap'
            }
          }
        ]
      }
    ],
    bottomCardsItemCountPerRow: ItemPerRow.Two,
  }
};

export const layoutCardsConfig: { cardsConfig: CardsConfig } = {
  cardsConfig: {
    itemCountPerRow: ItemPerRow.Two,
    showTitleOnImage: false,
    showDescriptionOnImage: false,
    showCheckMarkOnCard: false,
    paginationConfig: {
      pageIndex: 1,
      total: 3,
      pageSize: 6,
      showPagination: false,
      nzFrontPagination: false
    },
    selectConfig: {
      options: [],
      settings: {
        mode: SelectMode.Multiple,
        maxTagCount: 1
      },
      placeholder: 'Filter Tags',
      visibility: false
    },
    items: [],
    inputGroupConfig: {
      textbox: {
        placeholder: 'Search in Layouts',
      },
      suffixIcon: {
        name: 'fas fa-search',
        type: IconType.FontAwesome,
      }
    }
  }
};

export const themeCardsConfig: { cardsConfig: CardsConfig } = {
  cardsConfig: {
    itemCountPerRow: ItemPerRow.Two,
    showTitleOnImage: false,
    showDescriptionOnImage: false,
    showCheckMarkOnCard: false,
    paginationConfig: {
      pageIndex: 1,
      total: 3,
      pageSize: 6,
      showPagination: false,
      nzFrontPagination: false
    },
    selectConfig: {
      options: [],
      settings: {
        mode: SelectMode.Multiple,
        maxTagCount: 1
      },
      placeholder: 'Filter Tags',
      visibility: false
    },
    buttons: [
      {
        text: 'Create Theme',
        icon: {
          name: 'fas fa-add'
        }
      },
    ],
    items: [],
    inputGroupConfig: {
      textbox: {
        placeholder: 'Search in Themes',
      },
      suffixIcon: {
        name: 'fas fa-search',
        type: IconType.FontAwesome,
      }
    }
  }
};

export const messageTemplatesCardsConfig: { cardsConfig: CardsConfig } = {
  cardsConfig: {
    itemCountPerRow: ItemPerRow.Two,
    showTitleOnImage: false,
    showDescriptionOnImage: false,
    showCheckMarkOnCard: false,
    paginationConfig: {
      pageIndex: 1,
      total: 3,
      pageSize: 6,
      showPagination: false,
      nzFrontPagination: false
    },
    selectConfig: {
      options: [],
      settings: {
        mode: SelectMode.Multiple,
        maxTagCount: 1
      },
      placeholder: 'Filter Tags',
      visibility: false
    },
    items: [],
    inputGroupConfig: {
      textbox: {
        placeholder: 'Search in Message Templates',
      },
      suffixIcon: {
        name: 'fas fa-search',
        type: IconType.FontAwesome,
      }
    },
    bottomCards: [
      {
        data: { id: 'blank-template', type: 'create-blank-template' },
        titles: [
          {
            type: HeadingType.H1,
            content: '📄'
          },
          {
            type: HeadingType.H4,
            content: 'Create Blank Template',
            colorSettings: {
              color: 'var(--primary-color)',
            }
          }
        ],
        descriptions: [
          {
            content: 'Start with a clean slate and design your message template from scratch with full customization options',
            typography: {
              fontSize: 'var(--subtext-font-size)',
              whiteSpace: 'pre-wrap'
            }
          }
        ]
      },
      {
        data: { id: 'ai-template', type: 'create-ai-template' },
        titles: [
          {
            type: HeadingType.H1,
            content: '⭐'
          },
          {
            type: HeadingType.H4,
            content: 'Create Template with AI',
            colorSettings: {
              color: 'var(--primary-color)',
            }
          }
        ],
        descriptions: [
          {
            content: 'Leverage AI to quickly generate professional message templates based on your requirements and specifications',
            typography: {
              fontSize: 'var(--subtext-font-size)',
              whiteSpace: 'pre-wrap'
            }
          }
        ]
      }
    ],
    bottomCardsItemCountPerRow: ItemPerRow.Two,
  }
};
