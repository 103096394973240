import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ERROR_NOTIFICATION_DISPLAY_DURATION, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { ContentEditorService, NotificationService, BrowserStorageManagementService, StringUtilityService } from '@rappider/services';
import { Action, Store } from '@ngrx/store';
import { ContentTree, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';
import {
  catchError,
  mergeMap,
  withLatestFrom,
  concatMap,
  map
} from 'rxjs/operators';
import {
  ComponentControllerService,
  ComponentOutputEventControllerService,
  ContainerTemplateControllerService,
  CopyPageContentRequestDto,
  PageComponentControllerService,
  PageControllerService,
  PageWithRelations
} from '@rappider/rappider-sdk';

import * as ContentEditorActions from './content-editor.actions';
import * as AppActions from 'apps/rappider/src/app/state/app.actions';
import { activeContentTreeItemSelector } from '../selectors/active-content-tree-item.selector';
import { removeRelationalFieldsOfContentTree } from './content-editor-helper-functions';
import { Navigate } from '@rappider/shared';

import { v4 as uuidV4 } from 'uuid';
import { EMPTY } from 'rxjs';
import * as LayoutActions from 'libs/pages/src/lib/state/layout-state/layout.actions';
import { CopyPage, SetActiveContentTreeItem } from './content-editor.actions';

@Injectable()
export class ContentEditorEffects {
  constructor(
    private actions$: Actions,
    private pageApi: PageControllerService,
    private nzNotificationService: NzNotificationService,
    private contentEditorService: ContentEditorService,
    private notificationService: NotificationService,
    private store: Store<any>,
    private containerTemplateApi: ContainerTemplateControllerService,
    private browserStorageManagementService: BrowserStorageManagementService,
    private componentOutputEventApi: ComponentOutputEventControllerService,
    private pageComponentApi: PageComponentControllerService,
    private componentService: ComponentControllerService
  ) { }


  getPageByIdWithIncludes$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.LoadPageWithComponents>(
      ContentEditorActions.ActionTypes.LoadPageWithComponents
    ),
    mergeMap((action) => {
      const params = {
        pageId: action.payload.pageId
      };
      return this.pageApi.getPageForContentEditor(params).pipe(
        concatMap((page: PageWithRelations) => {
          return [
            new ContentEditorActions.LoadPageWithComponentsSuccessful({ page })
          ];
        }),
        catchError((error) => [
          new ContentEditorActions.ContentEditorError({
            errorOn: 'LoadPageWithComponents',
            error: error,
          })
        ])
      );
    })
  ));



  createComponent$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.CreateComponent>
      (ContentEditorActions.ActionTypes.CreateComponent),
    withLatestFrom(
      this.store.select(state => state.contentEditor?.page),
      this.store.select(state => state.contentEditor?.page?.id),
      this.store.select(state => state.contentEditor?.page?.contentTree),
      this.store.select(<any>activeContentTreeItemSelector),
      this.store.select(state => state.contentEditor?.lastSavedContentTree),
    ),
    mergeMap(([action, activePage, activePageId, contentTree, activeContentTreeItem, lastSavedContentTree]) => {
      let pageContainerId: string;
      // Generate a new pageComponentId as uuid
      const pageComponentId = uuidV4();
      const newComponentId = uuidV4();

      /* if pageContainerId is null set pageContainerId as main container id*/
      if (activeContentTreeItem && activeContentTreeItem?.type === ContentTreeItemType.Container) {
        pageContainerId = activeContentTreeItem.id;
      } else {
        pageContainerId = contentTree[0].id;
      }

      /* Instead of waiting for the API response, we set an optimistic response */
      const optimisticComponentResponse = {
        id: pageComponentId,
        componentId: newComponentId,
        type: ContentTreeItemType.Component,
        component: {
          id: newComponentId,
          componentDefinitionId: action.payload.componentDefinitionId,
          title: action.payload.title || 'New Component',
          name: StringUtilityService.toPascalCase(action.payload.title) || 'new_component',
          cssClassName: StringUtilityService.toKebabCase(action.payload.title) || 'new-component',
          cssClasses: [],
          inputs: action.payload.inputs,
          outputs: {},
          isUpdatable: true,
          isDeleted: false,
          isDeletable: true,
        }
      };
      this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId: pageComponentId }));
      setTimeout(() => {
        this.scrollToElementBySelector('.selected-component');
      }, 100);
      this.store.dispatch(
        new ContentEditorActions.CreateComponentSuccessful(
          { createdComponent: optimisticComponentResponse, pageContainerId: pageContainerId, pageComponentId: pageComponentId })
      );
      const params = {
        pageId: activePageId,
        body: {
          pageContainerId: pageContainerId,
          componentDefinitionId: action.payload.componentDefinitionId,
          pageComponentId: pageComponentId,
          overridePageContainerComponent: {
            component: {
              id: newComponentId
            }
          },

        },
        options: {
          /* this flag prevents content tree update on a component creation  */
          updateContentTree: false
        }
      };
      const isContentTreesEqual = JSON.stringify(contentTree) === JSON.stringify(lastSavedContentTree);
      const addNewPageContainerComponentRequest = this.pageApi.addNewPageContainerComponent(params).pipe(
        mergeMap(() => {
          if (activePage.type === 'layout') {
            this.store.dispatch(new LayoutActions.GetLayoutById({ layoutId: activePageId }));
          }
          return EMPTY;
        }),
        catchError(error => [new ContentEditorActions.ContentEditorError({ errorOn: 'CreateComponent', error: error })])
      );
      if (!isContentTreesEqual) {
        // eslint-disable-next-line max-len
        return this.pageApi.updateContentTreeByPageId({ pageId: activePageId, body: removeRelationalFieldsOfContentTree(contentTree) }).pipe(
          mergeMap(() => addNewPageContainerComponentRequest)
        );
      } else {
        return addNewPageContainerComponentRequest;
      }
    })
  ));


  deleteComponent$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.DeleteComponent>
      (ContentEditorActions.ActionTypes.DeleteComponent),
    withLatestFrom(
      this.store.select(state => state.contentEditor?.page?.id)
    ),
    mergeMap(([action, activePageId]) => {
      const params = {
        pageId: activePageId,
        body: {
          pageContainerComponentId: action.payload.componentId
        }
      };
      return this.pageApi.deletePageContainerComponent(params).pipe(
        mergeMap((response: ContentTree) => [
          new ContentEditorActions.DeleteComponentSuccessful({ deletedComponentId: action.payload.componentId })
        ]),
        catchError(error => [new ContentEditorActions.ContentEditorError({ errorOn: 'DeleteComponent', error: error })])
      );
    })
  ));


  updateComponent$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.UpdateComponent>(
      ContentEditorActions.ActionTypes.UpdateComponent
    ),
    withLatestFrom(
      this.store.select((state) => state.contentEditor?.page?.id)
    ),
    mergeMap(([action, pageId]) => {
      const component = action.payload.component;
      return this.componentService.updateById({ id: component.id, body: component }).pipe(
        // fix
        mergeMap((res) => ([new ContentEditorActions.UpdateComponentSuccessful({ component: res })])),
        catchError((error) => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'CONTENT_EDITOR_MODULE.COMPONENT_SETTINGS_COMPONENT.ERROR_ON_UPDATING_COMPONENT',
            { nzDuration: ERROR_NOTIFICATION_DISPLAY_DURATION }
          );
          return [
            new ContentEditorActions.ContentEditorError({
              errorOn: 'UpdateComponent',
              error: error,
            }),
          ];
        })
      );
    })
  ));

  updateComponentInputs$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.UpdateComponentInputs>(
      ContentEditorActions.ActionTypes.UpdateComponentInputs
    ),
    withLatestFrom(
      this.store.select((state) => state.contentEditor?.page?.components),
      this.store.select((state) => state.contentEditor?.page?.id)
    ),
    mergeMap(([action, components, pageId]) => {
      const component = components.find(c => c.id === action.payload.componentId);
      const inputs = {
        ...(component?.inputs ?? []),
        ...action.payload.inputs
      };
      return this.componentService.updateInputs({ id: action.payload.componentId, body: inputs }).pipe(
        mergeMap(() => {
          if (action.payload.pageType && action.payload.pageType === 'layout') {
            this.store.dispatch(new LayoutActions.GetLayoutById({ layoutId: pageId }));
          }
          return [];
        }),
        catchError((error) => {
          this.nzNotificationService.error(
            'Error',
            'There was an error while updating the input.',
            { nzDuration: ERROR_NOTIFICATION_DISPLAY_DURATION }
          );
          return [
            new ContentEditorActions.ContentEditorError({
              errorOn: 'UpdateComponentInputs',
              error: error,
            })
          ];
        })
      );
    })
  ));


  updatePageCss$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.UpdatePageCss>
      (ContentEditorActions.ActionTypes.UpdatePageCss),
    withLatestFrom(
      this.store.select((state) => state.contentEditor?.page)
    ),
    mergeMap(([action, page]) => {
      const params = {
        id: action.payload.pageId,
        body: {
          cssStyle: action.payload.css
        }
      };
      return this.pageApi.updateById(params).pipe(
        mergeMap(() => {
          this.notificationService.createNotification('success', 'SHARED.SUCCESSFUL', 'Page style updated successfully');
          if (page.type === 'layout') {
            return [
              new ContentEditorActions.UpdatePageCssSuccesful({ pageId: action.payload.pageId, css: action.payload.css }),
              new LayoutActions.GetLayoutById({ layoutId: params.id })
            ];
          } else {
            return [
              new ContentEditorActions.UpdatePageCssSuccesful({ pageId: action.payload.pageId, css: action.payload.css })
            ];
          }
        }),
        catchError(error => {
          this.notificationService.createNotification('error', 'SHARED.ERROR', 'Page style could not updated');
          return [
            new ContentEditorActions.ContentEditorError({ errorOn: 'UpdatePageCss', error: error })
          ];
        })
      );
    })
  ));


  copyPage$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.CopyPage>
      (ContentEditorActions.ActionTypes.CopyPage),
    mergeMap(action => {
      const params: CopyPageContentRequestDto = {
        sourcePageId: action.payload.sourcePageId,
        targetPageId: action.payload.targetPageId
      };
      return this.pageApi.copyPageContent({ body: params }).pipe(
        mergeMap(() => ([
          new ContentEditorActions.LoadPageWithComponents({ pageId: action.payload.targetPageId }),
        ])),
        catchError(error => ([
          new ContentEditorActions.ContentEditorError({ errorOn: 'CopyPage', error: error })
        ]))
      );
    })
  ));

  copyContainer$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.CopyContainer>
      (ContentEditorActions.ActionTypes.CopyContainer),
    mergeMap(action => {
      const params: CopyPageContentRequestDto = {
        sourcePageId: action.payload.sourcePageId,
        targetPageId: action.payload.targetPageId,
        componentPairs: action.payload.componentPairs,
        targetContainerId: action.payload.targetContainerId,
      };
      return this.pageApi.copyPageContent({ body: params }).pipe(
        map(() => new ContentEditorActions.CopyContainerSuccessful()
        ),
        catchError(error => ([
          new ContentEditorActions.ContentEditorError({ errorOn: 'CopyPage', error: error })
        ]))
      );
    })
  ));


  copyTemplateToPageContainer$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.CopyTemplateToPageContainer>
      (ContentEditorActions.ActionTypes.CopyTemplateToPageContainer),
    mergeMap((action) => {
      const params = { body: { containerTemplateTree: action.payload.templateTree, pageContainerId: action.payload.targetContainerId, pageId: action.payload.targetPageId } };
      return this.containerTemplateApi.copyTemplateToPageContainer(params).pipe(
        mergeMap(contentTree => [
          new ContentEditorActions.CopyTemplateToPageContainerSuccessful({ contentTree: contentTree }),
          new ContentEditorActions.LoadPageWithComponents({ pageId: action.payload.targetPageId })]
        ),
        catchError(error => [new ContentEditorActions.ContentEditorError({ errorOn: ContentEditorActions.ActionTypes.CopyTemplateToPageContainer, error: error })])
      );
    })
  ));


  copyActiveContentTemplateToLocalStorage$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.CopyActiveContentTemplateToLocalStorage>
      (ContentEditorActions.ActionTypes.CopyActiveContentTemplateToLocalStorage),
    withLatestFrom(
      this.store.select(<any>activeContentTreeItemSelector),
      this.store.select(state => state.contentEditor?.page),
    ),
    mergeMap(([action, activeContentTreeItem, page]) => {
      if (activeContentTreeItem?.type === ContentTreeItemType.Container) {
        const clipboardData = {
          templateTree: this.contentEditorService.convertPageContainerToTemplateTree([activeContentTreeItem]),
          metadata: {
            pageTitle: page.title,
            containerTitle: activeContentTreeItem.title
          }
        };
        this.browserStorageManagementService.setLocalStorageItem('contentEditorClipboard', JSON.stringify(clipboardData));
        this.notificationService.createNotification('success', activeContentTreeItem.title, 'Template copied into clipboard');
        return [new ContentEditorActions.WriteCopiedTemplateTreeFromLocalStorageToState(clipboardData)];
      } else {
        return [new ContentEditorActions.WriteCopiedTemplateTreeFromLocalStorageToStateFailure({ error: 'activeContentTreeItem type could not match to container', key: 'WriteCopiedTemplateTreeFromLocalStorageToStateFailure', timestamp: Date.now() })];
      }
    })
  ));


  writeCopiedTemplateTreeFromLocalStorageToState$ = createEffect(() => this.actions$.pipe(
    ofType<AppActions.LoadApp>(AppActions.ActionTypes.LoadApp),
    mergeMap(() => {
      const copiedTemplate = this.browserStorageManagementService.getLocalStorageItem('contentEditorClipboard');
      if (copiedTemplate) {
        const parsedData = JSON.parse(copiedTemplate);
        return [new ContentEditorActions.WriteCopiedTemplateTreeFromLocalStorageToState(parsedData)];
      }
      return [new AppActions.LoadAppSuccessful()];
    })
  ));


  pasteTemplateFromLocalStorage$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.PasteTemplateFromLocalStorage>
      (ContentEditorActions.ActionTypes.PasteTemplateFromLocalStorage),
    withLatestFrom(
      this.store.select(state => state.contentEditor?.activeContentTreeItemId),
      this.store.select(state => state.contentEditor?.page?.id),
    ),
    mergeMap(([action, activeContentTreeItemId, pageId]) => {
      const actionsToDispatch = [];
      const clipboardData = this.browserStorageManagementService.getLocalStorageItem('contentEditorClipboard');
      if (clipboardData) {
        const parsedClipboardData = JSON.parse(clipboardData);
        const templateTree = parsedClipboardData?.templateTree;
        const data = {
          templateTree: templateTree,
          targetContainerId: activeContentTreeItemId,
          targetPageId: pageId
        };
        actionsToDispatch.push(new ContentEditorActions.CopyTemplateToPageContainer(data));
      }
      return actionsToDispatch;
    })
  ));


  createContainerVisibilityCondition$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.CreateContainerVisibilityCondition>
      (ContentEditorActions.ActionTypes.CreateContainerVisibilityCondition),
    mergeMap((action) => {
      const params = {
        pageId: action.payload.pageId,
        body: action.payload.body
      };
      return this.pageApi.addConditionToContainer(params).pipe(
        mergeMap(response => ([
          new ContentEditorActions.CreateContainerVisibilityConditionSuccessful({
            pageContainerId: action.payload.body.pageContainerId,
            createdVisibilityCondition: response
          })
        ])),
        catchError(error => [new ContentEditorActions.ContentEditorError({ errorOn: 'CreateContainerVisibilityCondition', error: error })])
      );
    })
  ));


  updateContainerVisibilityCondition$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.UpdateContainerVisibilityCondition>
      (ContentEditorActions.ActionTypes.UpdateContainerVisibilityCondition),
    mergeMap((action) => {
      const params = {
        pageId: action.payload.pageId,
        body: action.payload.body
      };
      return this.pageApi.updateContainerCondition(params).pipe(
        mergeMap(response => ([
          new ContentEditorActions.UpdateContainerVisibilityConditionSuccessful({
            pageContainerId: action.payload.body.pageContainerId,
            updatedVisibilityCondition: response
          })
        ])),
        catchError(error => [new ContentEditorActions.ContentEditorError({ errorOn: 'UpdateContainerVisibilityCondition', error: error })])
      );
    })
  ));


  deleteContainerVisibilityCondition$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.DeleteContainerVisibilityCondition>
      (ContentEditorActions.ActionTypes.DeleteContainerVisibilityCondition),
    mergeMap((action) => {
      const params = {
        pageId: action.payload.pageId,
        body: {
          pageContainerId: action.payload.pageContainerId
        }
      };
      return this.pageApi.removeContainerCondition(params).pipe(
        mergeMap(response => ([
          new ContentEditorActions.DeleteContainerVisibilityConditionSuccessful({
            pageContainerId: action.payload.pageContainerId
          })
        ])),
        catchError(error => [new ContentEditorActions.ContentEditorError({ errorOn: 'DeleteContainerVisibilityCondition', error: error })])
      );
    })
  ));


  updateContentTreeOfPage$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.UpdateContentTreeOfPage>
      (ContentEditorActions.ActionTypes.UpdateContentTreeOfPage),
    withLatestFrom(
      this.store.select(state => state.contentEditor?.page?.id),
      this.store.select(state => state.contentEditor?.page?.contentTree),
      this.store.select(state => state.contentEditor?.componentIdsToRemove),
      this.store.select(state => state.layout?.data),
    ),
    mergeMap(([action, pageId, contentTree, componentIdsToRemove, layouts]) => {
      if (pageId != null && contentTree != null) {
        const pureTree = removeRelationalFieldsOfContentTree(contentTree);
        const params = {
          pageId: pageId,
          body: contentTree
        };
        return this.pageApi.updateContentTreeByPageId(params).pipe(
          mergeMap(() => {
            const actionsToDispatch: Action[] = [
              new ContentEditorActions.UpdateContentTreeOfPageSuccessful({ contentTree: pureTree }),
            ];
            const isLayout = layouts.find(layout => layout.id === pageId);
            if (isLayout) {
              actionsToDispatch.push(
                new LayoutActions.UpdateContentTreeOfLayout({ layoutId: pageId, contentTree: pureTree })
              )
            }
            if (componentIdsToRemove?.length) {
              actionsToDispatch.push(
                new ContentEditorActions.DeletePageComponents({ componentIds: componentIdsToRemove, pageId: pageId })
              );
            }
            return actionsToDispatch;
          }),
          catchError(e => [new ContentEditorActions.ContentEditorError({ error: e, errorOn: 'UpdateContentTreeOfPage' })])
        );
      } else {
        return [];
      }
    })
  ));


  contentEditorDestroy$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.ContentEditorDestroy>
      (ContentEditorActions.ActionTypes.ContentEditorDestroy),
    mergeMap((action) => {
      const actions: Action[] = [
        new ContentEditorActions.ResetContentEditorData()
      ];
      // If targetPageId exists, the page change in the content editor is done using navigate.
      if (action.payload?.targetPageId) {
        actions.push(new Navigate({ url: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${action.payload?.targetPageId}` }));
      }
      return actions;
    })
  ));


  createComponentOutputEvent$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.CreateComponentOutputEvent>
      (ContentEditorActions.ActionTypes.CreateComponentOutputEvent),
    mergeMap((action) => {
      const params = {
        body: action.payload.createdOutputEvent
      };
      return this.componentOutputEventApi.create(params).pipe(
        mergeMap((createdComponentOutputEvent) => [
          new ContentEditorActions.CreateComponentOutputEventSuccessful({
            createdOutputEvent: createdComponentOutputEvent,
            componentId: action.payload.componentId
          })
        ]),
        catchError((error) => [
          new ContentEditorActions.ContentEditorError({
            errorOn: 'CreateComponentOutputEvent',
            error: error
          })
        ])
      );
    })
  ));


  updateComponentOutputEvent$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.UpdateComponentOutputEvent>
      (ContentEditorActions.ActionTypes.UpdateComponentOutputEvent),
    mergeMap((action) => {
      const params = {
        id: action.payload.updatedOutputEventId,
        body: action.payload.updatedOutputEvent
      };
      return this.componentOutputEventApi.updateById(params).pipe(
        mergeMap((_) => [
          new ContentEditorActions.UpdateComponentOutputEventSuccessful(action.payload)
        ]),
        catchError((error) => [
          new ContentEditorActions.ContentEditorError({
            errorOn: 'UpdateComponentOutputEvent',
            error: error
          })
        ])
      );
    })
  ));


  deleteComponentOutputEvent$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.DeleteComponentOutputEvent>
      (ContentEditorActions.ActionTypes.DeleteComponentOutputEvent),
    mergeMap((action) => {
      const params = {
        id: action.payload.eventId,
      };
      return this.componentOutputEventApi.deleteById(params).pipe(
        mergeMap((_) => [
          new ContentEditorActions.DeleteComponentOutputEventSuccessful(action.payload)
        ]),
        catchError((error) => [
          new ContentEditorActions.ContentEditorError({
            errorOn: 'DeleteComponentOutputEvent',
            error: error
          })
        ])
      );
    })
  ));


  deletePageComponents$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.DeletePageComponents>
      (ContentEditorActions.ActionTypes.DeletePageComponents),
    mergeMap((action) => {
      const params = {
        pageId: action.payload.pageId,
        componentIds: action.payload.componentIds
      };
      return this.pageComponentApi.removeComponentsFromPage(params).pipe(
        mergeMap((_) => [
          new ContentEditorActions.DeletePageComponentsSuccessful({ pageId: action.payload.pageId, removedComponentIds: action.payload.componentIds })
        ]),
        catchError((error) => [
          new ContentEditorActions.ContentEditorError({
            errorOn: 'DeletePageComponents',
            error: error
          })
        ])
      );
    })
  ));

  getTemplateById$ = createEffect(() => this.actions$.pipe(
    ofType<ContentEditorActions.GetTemplateById>
      (ContentEditorActions.ActionTypes.GetTemplateById),
    mergeMap((action) => {
      const params = {
        id: action.payload.id,
        filter: {
          include: [
            {
              relation: 'components',
            }
          ],
        }
      };
      return this.pageApi.findById(params).pipe(
        map((template) => new ContentEditorActions.GetTemplateByIdSuccessful({template})
        ),
        catchError((error) => [
          new ContentEditorActions.ContentEditorError({
            errorOn: 'GetTemplateById',
            error: error
          })
        ])
      );
    })
  ));

  scrollToElementBySelector(selector: string) {
    const element = document.querySelector(selector);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

}
