import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentDefinitionInterface } from '@rappider/api-sdk';
import { ContainerTemplateWithRelations } from '@rappider/rappider-sdk';
import { CreateComponent } from '../../../state/content-editor.actions';
import { ContentTreeContainer } from 'libs/content-tree-renderer/src/lib/models';

@Component({
  selector: 'rappider-add-component-modal',
  templateUrl: './add-component-modal.component.html',
  styleUrls: ['./add-component-modal.component.scss']
})
export class AddComponentModalComponent {

  @Input() componentDefinitions: ComponentDefinitionInterface[];
  @Input() addComponentModalVisibility = false; /* add component modal visibility */
  @Input() activeContentTreeItem: ContentTreeContainer;
  @Input() containerTemplates: ContainerTemplateWithRelations[];

  @Output() addComponentModalVisibilityChange = new EventEmitter<boolean>();

  constructor(private store: Store<any>) { }

  onComponentAdd(componentDefinitionId: string) {
    this.store.dispatch(new CreateComponent({ componentDefinitionId: componentDefinitionId, title: 'New Component' }));
    this.closeAddComponentModal();
  }

  closeAddComponentModal() {
    this.addComponentModalVisibility = false;
    this.onModalVisibilityChange(false);
  }

  setModalTitle() {
    return `Add Component Inside ${this.activeContentTreeItem?.title}`;
  }

  onModalVisibilityChange(visibility: boolean) {
    this.addComponentModalVisibilityChange.emit(visibility);
  }

}
