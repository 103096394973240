import { WorkflowFunctionTypeOptions } from './workflow-functions-options.enum';

export const WorkflowFunctionTypeRadioGroupOptions = [
  {
    key: 'Rappider Workflow Services',
    value: WorkflowFunctionTypeOptions.RappiderServices
  },
  {
    key: 'Rappider Workflow Functions',
    value: WorkflowFunctionTypeOptions.WorkflowFunctions
  }
];
