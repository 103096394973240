import { DiagramEditorNodeSettings } from '../../interfaces/diagram-editor/diagram-editor-node-settings.interface';

export const DiagramNodesConfig: DiagramEditorNodeSettings = {
  /* Specifies the name of a data source field or an expression that returns node texts. */
  textExpr: 'name',
  /* Specifies the name of a data source field or an expression that returns a node's child items. */
  itemsExpr: 'items',
  /* Specifies whether or not a shape size is automatically changed to fit the text when the UI component is bound to a data source. */
  autoSizeEnabled: false
};
