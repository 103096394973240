import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbOption, CrudFormLabelFunctionItem, CrudFormMonacoCodeEditorItem, CrudFormSelectItem, CrudViewFormItemType, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { CustomEndpointParamsService, JsonValidationService, NotificationService, StringUtilityService } from '@rappider/services';
import { HTTP_METHOD_KEY_VALUES, HttpMethods, PATH_DEFINITIONS, controllerClassNameSuffix, defaultToolbarTitleHeadingSize } from '@rappider/shared/definitions';
import { StringTransformService } from 'libs/shared/src/lib/services/string-transform-service/string-transform.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  CUSTOM_ENDPOINT_CREATE_OR_EDIT_CONFIG,
  CustomEndpointCreateOrEditFieldName,
} from '@rappider/shared/configs';
import {
  DataSchemaControllerService,
  DataSchemaWithRelations,
  Project,
  ProjectModel,
  ProjectModelEndpoint,
  ProjectModelEndpointCreateDto,
  ProjectModelEndpointParamCreateDto,
  ProjectModelEndpointQueryParamCreateDto,
  ProjectModelEndpointWithRelations,
} from '@rappider/rappider-sdk';
import { cloneDeep } from 'lodash';
import { ProjectModelState } from '@rappider/project';
import { DataSchemaState } from 'libs/project/src/lib/states/data-schema/data-schema.reducer';
import { CreateProjectModelEndpoint } from 'libs/project/src/lib/states/project-model-endpoint-state/project-model-endpoint.actions';
import { ProjectModelEndpointState } from 'libs/project/src/lib/states/project-model-endpoint-state/project-model-endpoint.reducer';
import { AuthState } from 'libs/authentication/src/lib/state/authentication.reducer';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

@Component({
  selector: 'rappider-custom-endpoint-create',
  templateUrl: './custom-endpoint-create.component.html',
  styleUrls: ['./custom-endpoint-create.component.scss']
})
export class CustomEndpointCreateComponent implements OnInit, OnDestroy {
  /* create config */
  CUSTOM_ENDPOINT_CREATE_CONFIG = cloneDeep(CUSTOM_ENDPOINT_CREATE_OR_EDIT_CONFIG);
  /* main title */
  mainTitle: HeadingComponentConfig;
  /* title */
  title: BreadcrumbOption[];
  /* subscriptions */
  subscriptions: Subscription[];
  /* active project */
  activeProject: Project;
  /* project model id */
  projectModelId: string;
  /* project model that endpoint belongs to */
  projectModel: ProjectModel;
  /* htpp methods as key values */
  HTTP_METHOD_KEY_VALUES = HTTP_METHOD_KEY_VALUES;
  /* default custom endpoint with GET method selected */
  defaultCustomEndpoint: any = {
    method: HttpMethods.GET
  };
  /* custom function data */
  customFunction: any;
  /* string data schema for type of params */
  stringDataSchema: DataSchemaWithRelations;
  isFormLoading = true;
  isSubmitButtonLoading = false;
  hasPathChanged = false;

  displayToolbar = false;
  displayToolbarBackButton = false;
  isProjectModelsLoading: boolean;
  redirectURL: string;
  isEnableSubmitButton = false;

  visibleFields = [
    'serviceName',
    'functionName',
    'programmingLanguage',
    'packages',
    'DEV',
    'QA',
    'PROD',
    'environmentVariables',
    'code',
    'requestJSONSample',
    'responseJSONSample',
    'submit-button'
  ];

  constructor(
    private store: Store<{ activeProject: Project; projectModel: ProjectModelState; dataSchema: DataSchemaState; auth: AuthState; projectModelEndpoint: ProjectModelEndpointState }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataSchemaApi: DataSchemaControllerService,
    private notificationService: NotificationService,
    private stringTransformService: StringTransformService,
    private jsonValidationService: JsonValidationService,
    private paramsService: CustomEndpointParamsService
  ) { }

  ngOnInit(): void {
    this.setFormConfig();
    this.getProjectModelIdFromUrl();
    this.setHttpMethodSelectOptions();
    this.subscribeToData();
    this.setDataModelSelectOptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToDataSchemas(),
      this.subscribeToProjectModels(),
      this.subscribeToProjectModelsLoading(),
      this.subscribeToSubmitButtonLoading(),
      this.subscribeToPreferredThemeAndUpdateMonacoTheme()
    ];
  }

  getProjectModelIdFromUrl() {
    this.projectModelId = this.activatedRoute.snapshot.params['projectModelId'];
    this.redirectURL = this.activatedRoute.snapshot.queryParams['redirectURL'];
  }

  subscribeToDataSchemas() {
    return this.store.select(<any>getDataSchemasWithDetailsSelector).subscribe((dataSchemas: DataSchemaWithRelations[]) => {
      if (dataSchemas?.length) {
        this.stringDataSchema = dataSchemas.find(schema => schema.name === 'string' && schema.isPrimitive);
      } else {
        this.stringDataSchema = null;
      }
    });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: Project) => {
      if (activeProject) {
        this.activeProject = activeProject;
      } else {
        this.activeProject = null;
      }
    });
  }

  subscribeToProjectModels() {
    return this.store.select(state => state.projectModel.data).subscribe(projectModels => {
      if (projectModels?.length) {
        this.projectModel = projectModels.find(projectModel => projectModel.id === this.projectModelId);
        this.setTitle();
        this.dataSchemaApi.getExampleDataByDataSchemaId({ id: this.projectModel.relationedTypeId }).subscribe(exampleData => {
          this.defaultCustomEndpoint = {
            ...this.defaultCustomEndpoint,
            // responseData: JSON.stringify(exampleData)
          };
          this.isFormLoading = false;
        });
      }
    });
  }

  subscribeToProjectModelsLoading() {
    return this.store.select(state => state.projectModel.loading).subscribe(isLoading => {
      this.isProjectModelsLoading = isLoading;
    });
  }

  subscribeToSubmitButtonLoading() {
    return this.store.select(state => state.projectModelEndpoint?.isLoading).subscribe((isLoading: boolean) => {
      this.isSubmitButtonLoading = isLoading;
    });
  }

  /**
   * set page title
   *
   * @memberof CustomEndpointCreateComponent
   */
  setTitle() {
    this.mainTitle = {
      content: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.CUSTOM_ENDPOINT_CREATE',
      type: defaultToolbarTitleHeadingSize
    };
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: 'PROJECT_MODULE.CUSTOM_ENDPOINT_COMPONENT.ENDPOINTS',
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_ENDPOINT_LIST}`
      },
      {
        label: this.projectModel?.name,
      },
      {
        label: 'PROJECT_MODULE.CUSTOM_ENDPOINT_CREATE_OR_EDIT_COMPONENT.CUSTOM_ENDPOINT_CREATE'
      }
    ];
  }

  /**
   * sets http method select item's options
   *
   * @memberof CustomEndpointCreateComponent
   */
  setHttpMethodSelectOptions() {
    const httpMethodFormItem = <CrudFormSelectItem>(
      this.CUSTOM_ENDPOINT_CREATE_CONFIG.items.find(
        item => item.fieldName === CustomEndpointCreateOrEditFieldName.Method
      )
    );
    /* set http method form item options */
    httpMethodFormItem.options = this.HTTP_METHOD_KEY_VALUES;
  }

  setDataModelSelectOptions() {
    const dataModelFormItem = <CrudFormSelectItem>(
      this.CUSTOM_ENDPOINT_CREATE_CONFIG.items.find(
        item => item.fieldName === CustomEndpointCreateOrEditFieldName.DataModel
      )
    );
    /* set http method form item options */
  }

  onCreateCustomEndpointFormValueChange(projectModelEndpoint: any) {

    // if other fields are changed except name
    if (!projectModelEndpoint.name) {
      /* If path hasn’t changed before and changed now */
      if (!this.hasPathChanged && projectModelEndpoint.path) {
        this.hasPathChanged = true;
      }
      if (projectModelEndpoint.isMocked !== undefined) {
        this.setMockResponseFormItem(projectModelEndpoint.isMocked);
      }
      this.defaultCustomEndpoint = {
        ...this.defaultCustomEndpoint,
        ...projectModelEndpoint
      };
    } else {
      this.defaultCustomEndpoint = {
        ...this.defaultCustomEndpoint,
        name: projectModelEndpoint.name,
      };
      this.customFunction = {
        ...this.customFunction,
        serviceName: this.projectModel.name + controllerClassNameSuffix,
        functionName: projectModelEndpoint.name,
      };
      /* if path hasn’t changed before and name is changing, update the path */
      if (!this.hasPathChanged) {
        this.defaultCustomEndpoint = {
          ...this.defaultCustomEndpoint,
          path: StringUtilityService.toKebabCase(projectModelEndpoint.name)
        };
      }
    }

    /* set custom function form submit button flag (disable or not), according to custom endpoint form fields (name, path, method) */
    this.isEnableSubmitButton = this.defaultCustomEndpoint.name && this.defaultCustomEndpoint.path && this.defaultCustomEndpoint.method;
  }

  /**
   * sets mock response form item on config depending on isMocked property
   *
   * @param {boolean} isMocked
   * @memberof CustomEndpointCreateComponent
   */
  setMockResponseFormItem(isMocked: boolean) {
    if (isMocked) {
      this.visibleFields.push('mockResponse');
    } else if (isMocked === false) {
      this.visibleFields = this.visibleFields.filter(field => field !== 'mockResponse');
    }
    this.visibleFields = [
      ...this.visibleFields
    ];
  }

  /**
   * returns custom endpoints name as kebab-case
   *
   * @param {ProjectModelEndpointWithRelations} customEnpoint
   * @return {*}
   * @memberof CustomEndpointCreateComponent
   */
  setPathOfRequestBody(customEndpoint: ProjectModelEndpointWithRelations) {
    return this.stringTransformService.getKebabCaseFromText(customEndpoint.name);
  }

  validateMockResponse(customEndpoint: ProjectModelEndpointWithRelations) {
    return this.jsonValidationService.validateStringifiedJson(customEndpoint.mockResponse, true);
  }

  validateResponseData(customEndpoint: ProjectModelEndpointWithRelations) {
    return this.jsonValidationService.validateStringifiedJson(customEndpoint.responseData, true);
  }

  validateRequestData(customEndpoint: ProjectModelEndpoint) {
    return this.jsonValidationService.validateStringifiedJson(customEndpoint.requestData, true);
  }

  /**
   * sends create request
   *
   * @param {NewProjectModelEndpoint} endpointRequestBody
   * @memberof CustomEndpointCreateComponent
   */
  createCustomEndpoint(endpointRequestBody) {
    this.store.dispatch(CreateProjectModelEndpoint({ payload: { endpoint: endpointRequestBody, redirectUrl: this.redirectURL } }));
  }

  setFormConfig() {
    const labelFunctionItem = <CrudFormLabelFunctionItem>this.CUSTOM_ENDPOINT_CREATE_CONFIG.items.find(item => item.fieldName === 'pathPreview');
    labelFunctionItem.functionToDisplay = (configItem, data) => {
      const params = this.paramsService.getParamNames(data.params);
      const queryParams = this.paramsService.getParamNames(data.queryParams);
      const basePath = this.projectModel?.basePath;
      return this.paramsService.setPathPreview(`${basePath ? basePath + '/' : ''}${data?.path ?? ''}`, params, queryParams);
    };
  }

  subscribeToPreferredThemeAndUpdateMonacoTheme() {
    return this.store.select(state => state.auth?.activePerson?.preferredTheme).subscribe(preferredTheme => {
      const existingItems = this.CUSTOM_ENDPOINT_CREATE_CONFIG.items.filter(item => item.type === CrudViewFormItemType.MonacoCodeEditor) as CrudFormMonacoCodeEditorItem[];
      existingItems?.forEach(existingItem => {
        if (preferredTheme === 'light') {
          existingItem.editorOptions.theme = 'vs-light';
        } else {
          existingItem.editorOptions.theme = 'vs-dark';
        }
      });
      this.CUSTOM_ENDPOINT_CREATE_CONFIG = { ...this.CUSTOM_ENDPOINT_CREATE_CONFIG };
    });
  }

  onCreateFormSubmit(formData: any) {
    if (!this.defaultCustomEndpoint.path || !this.defaultCustomEndpoint.name || !this.defaultCustomEndpoint.method) {
      this.notificationService.createNotification(
        'error',
        'Error',
        'Missing Fields. Enter the name, path and method information of the endpoint to be created.'
      );
    } else {
      const mockRequestAndResponseData: any = {
        mockResponse: formData.mockResponse,
        requestData: formData.requestJSONSample,
        responseData: formData.responseJSONSample,
      };
      const jsonValidityStatuses = [
        {
          title: 'Mock Response',
          validity: this.validateMockResponse(mockRequestAndResponseData).isJsonValid
        },
        {
          title: 'Response Data Sample',
          validity: this.validateResponseData(mockRequestAndResponseData).isJsonValid
        },
        {
          title: 'Request Data Sample',
          validity: this.validateRequestData(mockRequestAndResponseData).isJsonValid
        }
      ];
      if (
        jsonValidityStatuses.every(status => status.validity)
      ) {
        /* set param's typeIds */
        const params = this.paramsService.setParamTypeIds(this.defaultCustomEndpoint?.params || [], this.stringDataSchema.id);
        /* set query param's typeIds */
        const queryParams = this.paramsService.setParamTypeIds(this.defaultCustomEndpoint.queryParams, this.stringDataSchema.id);

        const endpointRequestBody: any = {
          endpoint: {
            ...this.defaultCustomEndpoint,
            modelId: this.projectModelId,
            isCustomEndpoint: true,
            mockResponse: this.validateMockResponse(mockRequestAndResponseData).dataAsJson || null,
            responseData: this.validateResponseData(mockRequestAndResponseData).dataAsJson || undefined,
            requestData: this.validateRequestData(mockRequestAndResponseData).dataAsJson || undefined
          },
          customFunctionDefinition: {
            ...formData,
            packages: formData.packages?.length ? formData.packages : []
          },
          params: params as ProjectModelEndpointParamCreateDto[],
          queryParams: queryParams as ProjectModelEndpointQueryParamCreateDto[]
        };

        /* delete unused fields from request body */
        delete endpointRequestBody.endpoint.params;
        delete endpointRequestBody.endpoint.code;
        delete endpointRequestBody.endpoint.queryParams;
        delete endpointRequestBody.endpoint.pathPreview;

        delete endpointRequestBody.customFunctionDefinition.responseJSONSample;
        delete endpointRequestBody.customFunctionDefinition.requestJSONSample;
        delete endpointRequestBody.customFunctionDefinition.mockResponse;

        /* endpointRequestBody.params and endpointRequestBody.queryParams have id field */
        /* ProjectModelEndpointParamCreateDto and ProjectModelEndpointQueryParamCreateDto don't have id field */
        /* so we should delete id fields */
        endpointRequestBody.params?.forEach(item => {
          delete item['id'];
        });
        endpointRequestBody.queryParams?.forEach(item => {
          delete item['id'];
        });

        this.createCustomEndpoint(endpointRequestBody);
      } else {
        const invalidJSONs = jsonValidityStatuses.filter(status => !status.validity);
        invalidJSONs.forEach(invalidStatus => {
          this.notificationService.createNotification(
            'error',
            invalidStatus.title,
            'ERRORS.WRONG_JSON_FORMAT'
          );
        });
      }
    }
  }
}
