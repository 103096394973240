import { PageWithRelations } from '@rappider/rappider-sdk';
import { LastProcessedAction } from '@rappider/shared/interfaces';

import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as LayoutActions from './layout.actions';
import { orderBy } from 'lodash';

export const layoutFeatureKey = 'layout';

export interface State {
  loading: boolean;
  data: PageWithRelations[];
  lastProcessedAction: LastProcessedAction;
}

export const initialState: State = {
  loading: true,
  data: null,
  lastProcessedAction: {
    success: null,
    action: null,
    message: null,
    timestamp: null,
    data: null
  }
};

export function reducer(
  state: State = initialState,
  action: LayoutActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case LayoutActions.ActionTypes.CreateLayout:
      return {
        ...state,
        loading: true
      };

    case LayoutActions.ActionTypes.CreateLayoutSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.layout
        ],
        loading: false,
      };

    case LayoutActions.ActionTypes.CreateLayoutFailure:
      return {
        ...state,
        loading: false
      };

    case LayoutActions.ActionTypes.GetLayouts:
      return {
        ...state,
        loading: true
      };
    case LayoutActions.ActionTypes.GetLayoutsSuccessful:
      return {
        ...state,
        data: action.payload.layouts,
        loading: false
      };

    case LayoutActions.ActionTypes.GetLayoutsFailure:
      return {
        ...state,
        loading: false
      };

    case LayoutActions.ActionTypes.GetLayoutById:
      return {
        ...state,
        loading: true
      };

    case LayoutActions.ActionTypes.GetLayoutByIdSuccessful:
      return {
        ...state,
        data: orderBy([
          ...state.data.filter(layout => layout.id !== action.payload.layout.id),
          action.payload.layout
        ], 'createdDate', 'desc'),
        loading: false
      };

    case LayoutActions.ActionTypes.UpdateLayout:
      return {
        ...state,
        loading: true
      };

    case LayoutActions.ActionTypes.UpdateLayoutSuccessful: {
      const updatedLayout = {
        ...state.data.find(layout => layout.id === action.payload.layout.id),
        ...action.payload.layout
      };
      return {
        ...state,
        data: [
          ...state.data.filter(layout => layout.id !== action.payload.layout.id),
          updatedLayout
        ],
        loading: false,
      };
    }

    case LayoutActions.ActionTypes.UpdateLayoutFailure:
      return {
        ...state,
        loading: false
      };

    case LayoutActions.ActionTypes.UpdateContentTreeOfLayout: {
      const updatedLayouts = state.data.map(layout => {
        if (layout.id === action.payload.layoutId) {
          return {
            ...layout,
            contentTree: action.payload.contentTree
          };
        } else {
          return layout;
        }
      });

      return {
        ...state,
        data: updatedLayouts
      }
    }

    case LayoutActions.ActionTypes.DeleteLayout:
      return {
        ...state,
        loading: true
      };

    case LayoutActions.ActionTypes.DeleteLayoutSuccessful:
      return {
        ...state,
        data: state.data.filter(layout => layout.id !== action.payload.layoutId),
        loading: false,
      };

    case LayoutActions.ActionTypes.DeleteLayoutFailure:
      return {
        ...state,
        loading: false
      };

    case LayoutActions.ActionTypes.ChangeDefaultLayout:
      return {
        ...state,
        loading: true
      };

    case LayoutActions.ActionTypes.ChangeDefaultLayoutSuccessful:
      return {
        ...state,
        loading: false,
      };

    case LayoutActions.ActionTypes.ChangeDefaultLayoutFailure:
      return {
        ...state,
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.ChangeActiveProjectSuccessful:
      return initialState;

    default:
      return state;
  }
}
