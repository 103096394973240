import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

/* 3rd Part Modules */
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

/* Internal Modules */
import { SharedModule } from '../../../shared/src/lib/shared.module';
import { NzSelectModule } from 'ng-zorro-antd/select';

import {
  RappiderListGridModule,
  RappiderTitleModule,
  RappiderEditFormModule,
  RappiderSpinModule,
  RappiderModalModule,
  RappiderCardOneListModule,
  RappiderInputGroupModule,
  RappiderTreeGridModule,
  RappiderDropdownMenuModule,
  RappiderButtonModule,
  RappiderDividerModule,
  RappiderInternalTitleToolbarModule,
  RappiderCardsModule,
  RappiderTagModule,
  RappiderSelectModule,
  RappiderAlertModule,
} from '@rappider/rappider-components';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';
import { CardSelectorModule } from 'libs/components/src/lib/components/card-selector/card-selector.module';

/* Components */
import { PagesComponent } from './pages.component';
import { PageCreateComponent } from './components/page-create/page-create.component';
import { PageDetailComponent } from './components/page-detail/page-detail.component';
import { PageListComponent } from './components/page-list/page-list.component';
import { PageEditComponent } from './components/page-edit/page-edit.component';
import { PageLayoutListComponent } from './components/page-layout-list/page-layout-list.component';
import { PageLayoutCreateComponent } from './components/page-layout-create/page-layout-create.component';
import { PageLayoutEditComponent } from './components/page-layout-edit/page-layout-edit.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent
  },
  {
    path: 'detail/:pageId',
    component: PageDetailComponent
  },
  {
    path: 'create',
    component: PageCreateComponent
  },
  {
    path: 'list',
    component: PageListComponent
  },
  {
    path: 'edit/:id',
    component: PageEditComponent
  },
  {
    path: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_LIST.URL,
    component: PageLayoutListComponent
  },
  {
    path: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_CREATE.URL,
    component: PageLayoutCreateComponent
  },
  {
    path: `${PAGE_DEFINITIONS.PAGES.CHILDREN.PAGE_LAYOUTS_EDIT.URL}/:id`,
    component: PageLayoutEditComponent
  }
];

@NgModule({
  providers: [
    { provide: NZ_I18N, useValue: en_US },
  ],
  declarations: [
    PagesComponent,
    PageDetailComponent,
    PageCreateComponent,
    PageListComponent,
    PageEditComponent,
    PageLayoutListComponent,
    PageLayoutCreateComponent,
    PageLayoutEditComponent
  ],
  exports: [
    PagesComponent,
    PageListComponent,
    PageLayoutListComponent
  ],
  imports: [
    CommonModule,
    NzCardModule,
    NzTableModule,
    NzSkeletonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NzSelectModule,
    SharedModule,
    TranslateModule,
    RappiderListGridModule,
    RappiderTitleModule,
    RappiderEditFormModule,
    RappiderInternalTitleToolbarModule,
    RappiderSpinModule,
    RappiderModalModule,
    RappiderCardOneListModule,
    RappiderInputGroupModule,
    RappiderTreeGridModule,
    RappiderDropdownMenuModule,
    RappiderButtonModule,
    RappiderDividerModule,
    RappiderCardsModule,
    CardSelectorModule,
    NzIconModule,
    NzToolTipModule,
    NzTabsModule,
    RappiderTagModule,
    RappiderSelectModule,
    RappiderAlertModule
  ]
})
export class PagesModule { }
