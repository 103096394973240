import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonComponentConfig, ButtonSize, ButtonType } from '@rappider/rappider-components/utils';
import {
  AiGenerateComponentDataSubscriptionDto,
  ComponentDataSubscriptionControllerService,
  ComponentDataSubscriptionWithRelations,
  ComponentInputDefinition,
  DataSchemaControllerService,
  DataSchemaField,
  UiDataStore,
} from '@rappider/rappider-sdk';
import * as jsonata from 'jsonata';
import { UiDataStoreFieldSelectorValue } from '../ui-data-store-field-tree-selector/ui-data-store-field-selector-value.interface';
import { JoyrideService } from 'ngx-joyride';
import { ModalButtonOptions } from 'ng-zorro-antd/modal';
import { JsonSchemaService } from 'libs/components/src/lib/services';
import { SchemaTreeService } from 'libs/data-transformation/src/lib/services/schema-tree/schema-tree.service';
import { cloneDeep } from 'lodash';
import { formatJsonata } from '@stedi/prettier-plugin-jsonata/dist/lib';

@Component({
  selector: 'rappider-data-subscription',
  templateUrl: './data-subscription.component.html',
  styleUrls: ['./data-subscription.component.scss']
})
export class DataSubscriptionComponent implements OnInit, OnChanges {

  @Input() componentId: string;
  @Input() componentInputDefinition: ComponentInputDefinition;
  @Input() componentInputDefinitionNestedFields: DataSchemaField[];
  @Input() modalVisibility = false;
  @Input() modalTitle: string;
  @Input() modalLoading: boolean;
  @Input() isDataSchemaLoading: boolean;
  @Input() updatingComponentDataSubscription: ComponentDataSubscriptionWithRelations;
  @Input() aiContentConfigs: [];
  @Input() uiDataStores: UiDataStore[];

  @Output() modalVisibilityChange = new EventEmitter<boolean>();
  @Output() dataSubscriptionSubmitted = new EventEmitter();
  @Output() addAIContentConfigClick = new EventEmitter<void>();

  targetTree;
  targetTreeLoading = false;
  selectedTargetField: string;
  jsonataConfig: string;
  dataSources: UiDataStoreFieldSelectorValue[] = [];
  exampleJsonLoading = false;
  jsonataPreviewLoading = false;
  jsonataPreview = null;
  jsonataPreviewError = false;
  exampleJsonOptions = {
    theme: 'vs-default',
    language: 'json',
    minimap: { autohide: true, enabled: false },
    lineNumbers: { renderType: 0 }
  };
  removeDataSourceButtonConfig: ButtonComponentConfig = {
    icon: {
      name: 'fa-regular fa-trash'
    },
    size: ButtonSize.ExtraSmall,
    type: ButtonType.Link
  };
  /* this is for render issue */
  monacoEditorVisibility = false;
  displayTargetSchemaSample = true;
  targetSchemaSampleLoading = false;
  targetSchemaSample: string;
  noDataSourceError = false;
  isAIContentConfigExist = false;
  generateDataSubscriptionLoading = false;

  nzFooter: ModalButtonOptions[] = [
    {
      label: 'Help',
      shape: 'round',
      onClick: () => this.showSteps()
    },
    {
      label: 'Cancel',
      onClick: () => {
        this.modalVisibility = false;
        this.modalVisibilityChange.emit(this.modalVisibility);
      }
    },
    {
      label: 'Save',
      type: 'primary',
      onClick: () => this.onDataSubscriptionSaved()
    },
  ];

  private _exampleJson: string;

  get exampleJson(): string {
    return this._exampleJson;
  }

  set exampleJson(value: string) {
    if (value !== this._exampleJson) {
      this._exampleJson = value;
      this.setPreviewOfJsonataConfig();
    }
  }

  constructor(
    private dataSchemaApi: DataSchemaControllerService,
    private readonly joyrideService: JoyrideService,
    private jsonSchemaService: JsonSchemaService,
    private schemaTreeService: SchemaTreeService,
    private componentDataSubscriptionApi: ComponentDataSubscriptionControllerService
  ) { }

  ngOnInit(): void {
    if (this.modalVisibility) {
      setTimeout(() => {
        this.monacoEditorVisibility = true;
      }, 10);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.updatingComponentDataSubscription && this.updatingComponentDataSubscription) {
      this.buildForEditMode();
    }
    if (
      (changes.componentInputDefinition && changes.componentInputDefinitionNestedFields)
      && (this.componentInputDefinition || this.componentInputDefinitionNestedFields?.length)
    ) {
      this.buildTargetTreeAndSampleFromDataSchema();
    }
    if (changes.componentId || changes.componentInputDefinition || changes.componentInputDefinitionNestedFields || changes.aiContentConfigs) {
      this.isAIContentConfigExist = this.aiContentConfigs?.some((aiContentConfig: any) =>
        aiContentConfig.inputDefinition.id === this.componentInputDefinition.id
        && JSON.stringify(aiContentConfig.inputDefinition.childDataSchemaFieldIds || []) === JSON.stringify(this.componentInputDefinitionNestedFields?.map(field => field.id) || [])
      );
    }
  }

  buildTargetTreeAndSampleFromDataSchema() {
    this.targetTreeLoading = true;
    this.targetSchemaSampleLoading = true;
    let field;
    if (this.componentInputDefinitionNestedFields?.length) {
      field = this.componentInputDefinitionNestedFields[this.componentInputDefinitionNestedFields.length - 1];
    } else {
      field = this.componentInputDefinition;
    }

    this.dataSchemaApi.getJSONSchemasByIds({ dataSchemaId: [field.typeId] }).subscribe(response => {
      const jsonSchema = response?.[0]?.jsonSchema;
      if (jsonSchema) {
        this.targetTree = this.schemaTreeService.buildTreeFromJSONSchema(cloneDeep(jsonSchema));
        /* set root field as array manually if field is array. */
        if (field.isArray) {
          this.targetTree[0].data.type = 'array';
        }
        this.jsonSchemaService.generateJsonSampleFromJsonSchema(jsonSchema).then(sample => {
          this.targetSchemaSample = JSON.stringify(field.isArray ? [sample] : sample) || '';
        });
      }
      this.targetTreeLoading = false;
      this.targetSchemaSampleLoading = false;
    });
  }

  buildForEditMode() {
    /* set jsonata */
    this.jsonataConfig = this.updatingComponentDataSubscription?.jsonataConfig;
    /* set data sources */
    this.dataSources = this.updatingComponentDataSubscription?.uiDataStoreFieldSelectors?.map(uiDataStoreFieldSelector => ({
      variableName: uiDataStoreFieldSelector?.variableName,
      uiDataStore: this.uiDataStores.find(uiDataStore => uiDataStore.id === uiDataStoreFieldSelector?.uiDataStoreId),
      uiDataStoreSchemaFields: uiDataStoreFieldSelector?.fields || [],
    }));

    /* get example json */
    this.onDataSourceSelectionChange();
  }

  onDataSourceSelectionChange() {
    this.exampleJson = null;
    this.dataSources.forEach(dataSource => {
      const dataSchemaId = dataSource?.uiDataStoreSchemaFields?.length
        ? dataSource.uiDataStoreSchemaFields[dataSource.uiDataStoreSchemaFields?.length - 1]?.typeId
        : dataSource.uiDataStore.dataSchemaId;
      this.exampleJsonLoading = true;
      this.dataSchemaApi.getJSONSchemasByIds({ dataSchemaId: [dataSchemaId] }).subscribe(jsonSchemas => {
        const jsonSchema = jsonSchemas?.[0]?.jsonSchema;
        const isArray = dataSource.uiDataStoreSchemaFields?.[dataSource.uiDataStoreSchemaFields?.length - 1]?.isArray;

        if (jsonSchema) {
          this.jsonSchemaService.generateJsonSampleFromJsonSchema(jsonSchema).then(sampleData => {
            let exampleJsonObject: any = this.exampleJson ? JSON.parse(this.exampleJson) : {};
            exampleJsonObject = {
              ...exampleJsonObject,
              [dataSource.variableName]: isArray ? [sampleData] : sampleData
            };
            this.exampleJson = JSON.stringify(exampleJsonObject);
          });
        }
      });
      this.exampleJsonLoading = false;
    });
  }

  onDataSourceRemoved(removedDataSource) {
    this.dataSources = this.dataSources.filter(dataSource => dataSource.variableName !== removedDataSource.variableName);
    const exampleJsonObject = this.exampleJson ? JSON.parse(this.exampleJson) : {};
    delete exampleJsonObject[removedDataSource.variableName];
    this.exampleJson = JSON.stringify(exampleJsonObject);
  }

  onDataSourceItemClicked(dataSource) {
    this.jsonataConfig = `${this.jsonataConfig ?? ''}$.${dataSource.variableName}`;
  }

  onModalVisibilityChange() {
    this.modalVisibilityChange.emit(this.modalVisibility);
  }

  onDataSubscriptionSaved() {
    if (this.dataSources?.length) {
      const dataSubscriptionCreatePayload = {
        jsonataConfig: this.jsonataConfig,
        componentId: this.componentId,
        componentInputDefinitionId: this.componentInputDefinition.id,
        componentInputDefinitionNestedFieldIds: this.componentInputDefinitionNestedFields?.map(c => c.id) || [],
        uiDataStoreFieldSelectors: this.dataSources.map(dataSource => ({
          variableName: dataSource.variableName,
          uiDataStoreId: dataSource.uiDataStore?.id,
          uiDataStoreSchemaFieldIds: dataSource.uiDataStoreSchemaFields?.map(d => d.id)
        }))
      };

      this.noDataSourceError = false;
      this.dataSubscriptionSubmitted.emit(dataSubscriptionCreatePayload);
    } else {
      this.noDataSourceError = true;
    }
  }

  onModalClosed() {
    this.modalVisibility = false;
    this.onModalVisibilityChange();
  }

  async setPreviewOfJsonataConfig() {
    if (this.jsonataConfig && this.jsonataConfig !== '') {
      try {
        this.jsonataPreviewLoading = true;
        const exampleJsonObject = JSON.parse(this.exampleJson);
        this.jsonataPreview = await jsonata(this.jsonataConfig).evaluate(exampleJsonObject);
        if (this.jsonataPreview == null) {
          this.jsonataPreview = 'Invalid Script. Check the key that entered from Source Schema Sample or Enter data in JSON Format';
        } else {
          this.jsonataPreview = JSON.stringify(this.jsonataPreview);
        }
        this.jsonataPreviewError = false;
        this.jsonataPreviewLoading = false;
      } catch (e) {
        this.jsonataPreview = (await e)?.message;
        this.jsonataPreviewError = true;
        this.jsonataPreviewLoading = false;
      }
    } else {
      this.jsonataPreview = 'Enter data in JSON Format or Copy/Paste a key from Source Schema Sample to see Output';
    }
  }

  showSteps() {
    this.joyrideService.startTour(
      { steps: ['firstStep', 'secondStep', 'thirdStep', 'fourthStep', 'fifthStep'] }
    );
  }

  onClickTargetSchemaSwitcher() {
    this.displayTargetSchemaSample = !this.displayTargetSchemaSample;
  }

  async onClickImportSample() {
    this.jsonataConfig = await formatJsonata(this.targetSchemaSample);
  }

  generateDataSubscriptionWithAI() {
    this.generateDataSubscriptionLoading = true;
    const params: { body: AiGenerateComponentDataSubscriptionDto } = {
      body: {
        componentId: this.componentId,
        componentInputDefinitionId: this.componentInputDefinition.id,
        childDataSchemaFieldIds: this.componentInputDefinitionNestedFields?.map(field => field.id) || []
      }
    };
    if (!params.body.childDataSchemaFieldIds?.length) {
      delete params.body.childDataSchemaFieldIds;
    }
    this.componentDataSubscriptionApi.generateByAI(params).subscribe(response => {
      this.updatingComponentDataSubscription = response;
      this.buildForEditMode();
      this.generateDataSubscriptionLoading = false;
    });
  }

  onClickAIContentConfig() {
    this.addAIContentConfigClick.emit();
  }

}
