<div class="content-tree-renderer-controller">
  <div *ngIf="page"
       class="page-title">
    <i class="fa-light fa-file-code"></i>
    <rappider-heading type="h5"
                      [content]="page.title">
    </rappider-heading>
  </div>

  <div class="controller-buttons">
    <div class="preview-mode-controller">
      <rappider-switch [(ngModel)]="previewMode"
                       [icon]="previewModeSwitchConfig.icon"
                       [size]="previewModeSwitchConfig.size"
                       [tooltipText]="previewModeSwitchConfig.tooltipText"
                       [textPosition]="previewModeSwitchConfig.textPosition"
                       (ngModelChange)="onPreviewModeChange()"></rappider-switch>
    </div>
    <div class="scale-controllers">
      <div class="scale-controller">
        <rappider-switch [(ngModel)]="rulerVisibility"
                         [icon]="rulerVisibilityConfig.icon"
                         [size]="rulerVisibilityConfig.size"
                         [tooltipText]="rulerVisibilityConfig.tooltipText"
                         [textPosition]="rulerVisibilityConfig.textPosition"
                         (ngModelChange)="onRulerVisibilityChange()"></rappider-switch>
      </div>
      <div *ngIf="editorSize"
           class="widht-height-controllers">
        <input class="width-input"
               nz-input
               disabled
               nzSize="small"
               [(ngModel)]="editorSize.width"
               (blur)="onEditorSizeChange()" />
        <i class="fa-regular fa-x"></i>
        <input class="height-input"
               nz-input
               disabled
               nzSize="small"
               [(ngModel)]="editorSize.height"
               (blur)="onEditorSizeChange()" />
      </div>
      <div *ngIf="editorSize"
           class="preset-devices">
        <nz-select [(ngModel)]="selectedDevice"
                   nzSize="small"
                   (ngModelChange)="onDeviceChange($event)">
          <nz-option *ngFor="let device of devices"
                     [nzValue]="device.value"
                     [nzLabel]="device.label"
                     [nzDisabled]="device.disabled"></nz-option>
        </nz-select>
      </div>
      <!-- The scale feature is disabled as it is not working properly for mobile devices -->
      <!-- <div *ngIf="editorSize"
           class="scale-controller">
        <nz-select [(ngModel)]="editorSize.scale"
                   nzSize="small"
                   (ngModelChange)="onEditorSizeChange()">
          <nz-option *ngFor="let scaleOption of scaleOptions"
                     [nzValue]="scaleOption.value"
                     [nzLabel]="scaleOption.label"></nz-option>
        </nz-select>
      </div> -->
    </div>
  </div>
</div>
