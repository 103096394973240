import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { groupBy, toNumber } from 'lodash';

import { ComponentDefinitionInterface } from '@rappider/api-sdk';
import { CategorizedData } from '@rappider/models';
import { ComponentDefinitionService, RenderComponentService, RouterStateService } from '@rappider/services';
import { COMPONENT_DEFINITIONS_GROUP_LIST_CONFIG } from '@rappider/shared/configs';
import { elementsCategoryId, PATH_DEFINITIONS } from '@rappider/shared/definitions';

import { SetComponentBrowserTitle } from '../../state/component-definition.actions';
import { ButtonDefinition, buttons } from './model/component-definitions-preview';
import { ComponentDefinitionWithRelations } from '@rappider/rappider-sdk';
import { getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';

@Component({
  selector: 'rappider-component-definitions-preview',
  templateUrl: './component-definitions-preview.component.html',
  styleUrls: ['./component-definitions-preview.component.scss']
})
export class ComponentDefinitionsPreviewComponent implements AfterViewInit, OnDestroy {

  /* Showcase components render area */
  @ViewChildren('showcaseContainer', { read: ViewContainerRef }) showcaseContainers: QueryList<ViewContainerRef>;

  COMPONENT_DEFINITIONS_GROUP_LIST_CONFIG = COMPONENT_DEFINITIONS_GROUP_LIST_CONFIG;

  componentDefinitions: any[];
  /* categorized component definition groups to be listed */
  categorizedComponentDefinitionsGroup: CategorizedData;
  subscriptions: Subscription[];
  buttons = buttons;
  /* renders components of the category and show all as a showcase */
  showAllComponentsAsPreview = false;
  categoryName: string;
  columnNumber = 4;
  searchValue: string;
  searchResults: string[];

  activeTheme: string;
  isElement = true; // TODO: If its category is element, show it, otherwise don't show it

  defaultCategoryName = 'elements';
  defaultCategoryId = elementsCategoryId; // elements category

  elementsWithSubCategory: any;
  elementsForSearchResult: any;

  searchBox = {
    placeholder: 'Search Element',
    sizeSettings: {
      height: '53px'
    },
    typographySettings: {
      fontSize: '18px'
    }
  };

  constructor(
    private store: Store<any>,
    private componentDefinitionService: ComponentDefinitionService,
    private routerStateService: RouterStateService,
    private activatedRoute: ActivatedRoute,
    private renderComponentService: RenderComponentService,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.subscribeToData();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToUrl(),
      this.subscribeToActiveTheme(),
      this.subscribeToComponentDefinitions()
    ];
  }

  subscribeToActiveTheme() {
    return this.store.select(state => state.auth?.activePerson?.preferredTheme).subscribe(activeTheme => {
      this.activeTheme = activeTheme;
    });
  }

  subscribeToUrl() {
    return this.activatedRoute.params.subscribe(params => {
      const categoryName = params['categoryName'] || this.defaultCategoryName;
      this.categoryName = categoryName;
      if (categoryName && this.componentDefinitions) {
        this.setCategorizedComponentDefinitionsGroup(categoryName);
      }
    });
  }

  subscribeToComponentDefinitions() {
    return this.store.select(<any>getComponentDefinitionsWithDetailsSelector).subscribe(componentDefinitions => {
      this.componentDefinitions = componentDefinitions;
      if (!this.categoryName) {
        this.categoryName = this.defaultCategoryName;
      }
      if (this.componentDefinitions) {
        this.setCategorizedComponentDefinitionsGroup(this.categoryName);
      }
    });
  }

  setCategorizedComponentDefinitionsGroup(categoryName: string) {
    if (this.componentDefinitions?.length) {
      this.categorizedComponentDefinitionsGroup = this.componentDefinitionService
        .categorizeComponentDefinitions(this.componentDefinitions)
        .find(componentDefinition => componentDefinition.name === categoryName)
        || null;
      /* Render page */
      this.setSubCategoryNames();
      this.renderPage();
    } else {
      this.categorizedComponentDefinitionsGroup = null;
    }
  }

  setSubCategoryNames() {
    this.elementsWithSubCategory = groupBy(this.categorizedComponentDefinitionsGroup.data, 'componentDefinition.mainCategory.name');
  }

  renderPage() {
    this.setCategoryTitle();
    if (this.categorizedComponentDefinitionsGroup?.data?.length) {
      const delayTime = 1000;
      const render$ = of('').pipe(delay(delayTime)).subscribe(() => this.renderShowcaseComponents());
    } else {
      this.clearShowcaseContainer();
    }
  }

  setCategoryTitle() {
    if (this.categoryName === 'elements') {
      const title = [
        {
          label: 'COMPONENT_BROWSER_MODULE.COMPONENT_BROWSER',
          redirectUrl: PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_BROWSER_PATH
        },
        {
          label: this.categorizedComponentDefinitionsGroup.title
        }
      ];
      this.store.dispatch(new SetComponentBrowserTitle({ title: title }));
    } else {
      const title = [
        {
          label: 'COMPONENT_BROWSER_MODULE.COMPONENT_BROWSER',
          redirectUrl: PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_BROWSER_PATH
        },
        {
          label: 'Category'
        },
        {
          label: this.categorizedComponentDefinitionsGroup.title
        }
      ];
      this.store.dispatch(new SetComponentBrowserTitle({ title: title }));
    }
  }

  onRouteToComponentDefinitionDetail(componentDefinition: ComponentDefinitionInterface) {
    this.routerStateService.navigate(`${PATH_DEFINITIONS.COMPONENT_BROWSER.COMPONENT_DEFINITION_DETAIL_PATH}/${componentDefinition.id}`);
  }

  onGridHandlerButtonClick(buttonConfig: ButtonDefinition) {
    if (buttonConfig.key.startsWith('col')) {
      const columnPrefix = 'col-';
      this.showAllComponentsAsPreview = false;
      /* remove column prefix to get the column number e.g. col-2 => 2 */
      this.columnNumber = toNumber(buttonConfig.key.replace(columnPrefix, ''));
    } else {
      this.columnNumber = 1;
    }
  }

  getDefaultPreviewImageUrl(componentDefinition: ComponentDefinitionWithRelations) {
    return this.componentDefinitionService.getDefaultPreviewImageUrl(componentDefinition);
  }

  onSearchTextChange(searchText: string) {
    this.searchValue = searchText;

    this.searchResults = this.categorizedComponentDefinitionsGroup?.data
      .filter(element =>
        element.componentDefinition.title.toLowerCase().includes(this.searchValue.toLowerCase())
      );

    this.elementsForSearchResult = groupBy(this.searchResults, 'componentDefinition.mainCategory.name');
    this.renderPage();
  }

  // #region Showcase

  clearShowcaseContainer() {
    if (this.showcaseContainers) {
      this.showcaseContainers.toArray().forEach(c => c.clear());
    }
  }

  renderShowcaseComponents() {
    /* delete previous renders */
    this.clearShowcaseContainer();
    if (this.searchValue) {
      const showcaseComponents = this.searchResults || [];

      if (showcaseComponents?.length) {
        showcaseComponents.forEach((component: any, index) => {
          let container;
          if (this.showcaseContainers) {
            container = this.showcaseContainers.toArray()[index];
          }
          if (container && component?.componentDefinition) {
            /* render and add component to container ref */
            this.renderComponentService.renderComponentAndAddToContainer(
              component.componentDefinition.className,
              container,
              component.componentDefinition.defaultInputs
            );
          }
        });
      }
    } else {
      // Since groupBy sorts, it is necessary to sort here as well.
      const sortingElements = Object.entries(this.elementsWithSubCategory).sort();

      // then we need to delete the key field
      const elementsWithoutKey = sortingElements.map(item => {
        item.shift();
        return item;
      });

      // finally, we should convert the 2d array into 1d array
      const showcaseComponents = elementsWithoutKey.flat().flat();
      const filteredShowcaseComponents = showcaseComponents.filter((component: any) => component.componentDefinition?.previewImgUrls?.includes('null') || !component.componentDefinition.previewImgUrls?.length);

      if (filteredShowcaseComponents?.length) {
        filteredShowcaseComponents.forEach((component: any, index) => {
          let container;
          if (this.showcaseContainers) {
            container = this.showcaseContainers.toArray()[index];
          }
          if (container && component?.componentDefinition) {
            /* render and add component to container ref */
            this.renderComponentService.renderComponentAndAddToContainer(
              component.componentDefinition.className,
              container,
              component.componentDefinition.defaultInputs
            );
          }
        });
      }
    }
  }

  // #endregion End of Showcase

}
