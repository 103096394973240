import { Component, EventEmitter, Input, Output } from '@angular/core';
import { commentStatusButtonConfig } from '../../utils/comment-status-button.config';
import { commentActionsDropdownConfig } from '../../utils/comment-display-buttons.config';
import { StringUtilityService } from '@rappider/services';
import { PersonWithRelations } from '@rappider/rappider-sdk';
import { CommentAnimationService } from '../../utils/services/comment-animation.service';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { TagComponentConfig, TextComponentConfig } from '@rappider/rappider-components/utils';
import { CommentStatus } from '../../utils/comment-status.enum';

@Component({
  selector: 'rappider-comment-header',
  templateUrl: './comment-header.component.html',
  styleUrls: ['./comment-header.component.scss'],
  animations: [CommentAnimationService.hideShow],
})
export class CommentHeaderComponent {
  /* Inputs */
  @Input() isCommentHovered: boolean;
  @Input() isCommentEditModeActive: boolean;
  @Input() createdDate: string; // creation date of the comment
  @Input() activePerson: PersonWithRelations; // currently active person
  @Input() createdById: string; // ID of the person who created the comment
  @Input() createdBy: string; // name of the person who created the comment
  @Input() assignedTo: PersonWithRelations; // person assigned to the comment
  @Input() status; // status of comment

  /* Outputs */
  @Output() deleteComment = new EventEmitter();
  @Output() commentStatusClick = new EventEmitter();
  @Output() isCommentEditModeActiveChange = new EventEmitter<boolean>();

  /* Configs */
  commentStatusButtonConfig = commentStatusButtonConfig;
  commentActionsDropdownConfig = commentActionsDropdownConfig;
  commentStatus = CommentStatus;

  getCommentAuthorNameInitials(createdBy: string) {
    return StringUtilityService.getInitialCharactersOfString(createdBy);
  }

  setCommentCreatedDate(date: string) {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  }

  /**
   * comment action dropdown menu function
   *
   * @param {*} action
   * @memberof CommentHeaderComponent
   */
  onCommentMenuItemClick(action) {
    if (action.key === 'edit') {
      this.toggleCommentEditMode();
    } else if (action.key === 'delete') {
      this.deleteComment.emit();
    }
  }

  toggleCommentEditMode() {
    this.isCommentEditModeActive = !this.isCommentEditModeActive;
    this.isCommentEditModeActiveChange.emit(this.isCommentEditModeActive);
  }

  onCommentStatusButtonClick() {
    this.commentStatusClick.emit();
  }

}
