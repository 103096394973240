export const UI_BUSINESS_PROCESSES_EXPLANATION = `

Rapider AI automates UI logic creation, ensuring structured and event-driven workflows. The system revolves around:

- **UI Data Stores**: Manage state and data.
- **Events**: Define user and system interactions.
- **UI Step Functions (Effects)**: Handle asynchronous actions like API calls.

---

#### **2. UI Data Stores (State Management)**
Rapider AI automatically generates UI Data Stores to maintain the application state. These stores include:

- **Entity-based state management** (e.g., managing tasks).
- **Workflow states** such as **loading** and **error**.
- **Selectors** for optimized data retrieval.

This ensures a **consistent data flow** across components.

---

#### **3. Events (Triggers and Actions)**
Events define how the UI reacts to user interactions. AI detects common user workflows and generates:

- **Action events** (e.g., **CreateTask** for task creation).
- **API call triggers** (e.g., **createTask** event).
- **Response-based events** (e.g., **CreateTaskSuccessful** or **CreateTaskFailure**).

These ensure a **clear and structured event-driven architecture**.

---

#### **4. UI Step Functions (Effects)**
Effects manage side effects such as API calls. AI scans backend API definitions and automatically generates:

- **Effects that call APIs based on events.**
- **Success and failure event handlers.**
- **State updates linked to API responses.**

This enables a **seamless integration between frontend and backend**.

---

#### **5. Task Creation Flow (Example)**
Based on the provided diagram, AI follows this process:

1. **User triggers **CreateTask** event.**
2. **Effect calls the API (**Task.create**).**
3. **Response determines the outcome:**
   - **Success → **CreateTaskSuccessful** updates the state.**
   - **Failure → **CreateTaskFailure** handles errors.**

This ensures a **reactive, state-driven UI**.

---

#### **6. Benefits of AI-Generated UI Logic**
- **Automates repetitive coding** for state management and API handling.
- **Ensures consistency** across the application.
- **Optimizes performance** with efficient state updates.
- **Simplifies development** by reducing manual coding.

Rapider AI enables a **scalable, event-driven architecture**, allowing developers to focus on business logic rather than UI infrastructure.
`;


export const MESSAGE_TEMPLATES_EXPLANATION = `

#### **Creating Message Templates**

Message templates allow users to create reusable content for sending emails, text messages, and generating PDFs.
These templates support **dynamic data fields** that can be inserted using double brackets ({{ ... }}).

---

#### **Structure of a Template**
A template consists of **static text** combined with **dynamic placeholders** that are replaced with actual data when used in an application.

#### **Example Template:**

<pre>

Hello {{ user.name }},

Your appointment is confirmed for {{ date }} at {{ time }}.

Best regards,
{{ company.name }}

</pre>


#### **Best Practices**
- **Use clear placeholders** to improve readability.
- **Ensure proper spacing** within brackets
- **Test templates** before sending to verify correct data replacement.
- **Use default values** (if supported) for missing data (e.g., {{ user.name | default: "Customer" }}).

---

#### **How Rapider AI Helps**
Rapider AI platform can:
- **Suggest relevant fields** based on the context.
- **Auto-complete placeholders** for faster template creation.
- **Validate templates** to prevent formatting errors.
- **Preview data replacement** before finalizing templates.

By leveraging Rapider AI, you can quickly create **personalized, scalable, and reusable** message templates for their applications.

`;
