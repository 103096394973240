/* Angular */
import { createAction, createReducer, on, props } from '@ngrx/store';

/* Model Interface */
import { ProjectModel, ProjectModelField, ProjectModelFieldPartial } from '@rappider/rappider-sdk';

/* Actions */
import * as ProjectModelFieldActions from './project-model-field.actions';
import * as ProjectModelActions from '../../../../project/src/lib/states/project-model-state/project-model.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { orderBy } from 'lodash';

/* State key */
export const featureKey = 'projectModelField';

/* State interface */
export interface ProjectModelFieldState {
  data: ProjectModelField[];
  isLoading: boolean;
  isLoaded: boolean;
  error: any;
  creatingModelIds: string[];
  updatingProjectModelFieldIds: string[];
  deletingProjectModelFieldIds: string[];
  fieldsLoadingModelIds: string[];
}

/* Initial values */
export const initialState: ProjectModelFieldState = {
  data: [],
  isLoading: true,
  isLoaded: false,
  error: null,
  creatingModelIds: [],
  updatingProjectModelFieldIds: [],
  deletingProjectModelFieldIds: [],
  fieldsLoadingModelIds: []
};

/* Function to calculate updated projectModelField optimistically */
function updateProjectModelFields(updatedProjectModelFieldId: string, updatedProjectModelFieldData: ProjectModelFieldPartial, allProjectModelFields: ProjectModelField[]): ProjectModelField[] {
  if (allProjectModelFields?.length) {
    const updatedProjectModelField: ProjectModelField = allProjectModelFields?.find(projectModelField => projectModelField.id === updatedProjectModelFieldId) || <ProjectModelField>{};
    const updatedProjectModelFieldWithNewData = <ProjectModelField>{ ...updatedProjectModelField, ...updatedProjectModelFieldData };
    const updatedProjectModelFields: ProjectModelField[] = [
      ...allProjectModelFields.filter(projectModelField => projectModelField.id !== updatedProjectModelFieldId),
      updatedProjectModelFieldWithNewData
    ];
    return updatedProjectModelFields;
  } else {
    return <ProjectModelField[]>[];
  }
}

export const CreateProjectModelSuccessful = createAction(
  ProjectModelActions.ActionTypes.CreateProjectModelSuccessful,
  props<{ payload: { projectModel: ProjectModel } }>()
);
const UpdateProjectModelField = createAction(ProjectModelActions.ActionTypes.UpdateProjectModelField);
const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(ProjectModelFieldActions.GetProjectModelFieldsSuccessful, (state, action) => ({
    ...state,
    data: action.payload.projectModelFields,
    isLoading: false,
    isLoaded: true
  })),

  on(ProjectModelFieldActions.GetProjectModelFieldsByModelIdSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(datum => !action.payload.projectModelFields.some(field => field.id === datum.id)),
      ...action.payload.projectModelFields
    ],
    isLoading: false,
    isLoaded: true,
    fieldsLoadingModelIds: state.fieldsLoadingModelIds.filter(id => id !== action.payload.projectModelId)
  })),

  on(CreateProjectModelSuccessful, (state, action) => ({
    ...state,
    isLoading: true,
    fieldsLoadingModelIds: [...state.fieldsLoadingModelIds, action.payload.projectModel.id]
  })),

  on(ProjectModelFieldActions.CreateProjectModelField, (state, action) => ({
    ...state,
    isLoading: true,
    creatingModelIds: [...state.creatingModelIds, action.payload.projectModelField.projectModelId as string]
  })),

  on(ProjectModelFieldActions.CreateProjectModelFieldSuccessful, (state, action) => ({
    ...state,
    isLoading: false,
    data: orderBy([
      ...state.data,
      action.payload.projectModelField
    ], 'index', 'asc'),
    creatingModelIds: state.creatingModelIds.filter(id => id !== action.payload.projectModelField.projectModelId)
  })),

  on(ProjectModelFieldActions.CreateProjectModelFieldFailure, (state) => ({
    ...state,
    isLoading: false
  })),

  on(ProjectModelFieldActions.UpdateProjectModelField, (state, action) => ({
    ...state,
    updatingProjectModelFieldIds: [
      ...state.updatingProjectModelFieldIds,
      action.payload.projectModelId as string
    ],
    isLoading: true,
  })),

  on(UpdateProjectModelField, (state) => ({
    ...state,
    isLoading: true
  })),

  on(ProjectModelFieldActions.UpdateProjectModelFieldSuccessful, (state, action) => ({
    ...state,
    data: updateProjectModelFields(action.payload.projectModelFieldId, action.payload.projectModelField, (state.data?.length ? state.data : [])),
    updatingProjectModelFieldIds: state.updatingProjectModelFieldIds.filter(id => id !== action.payload.projectModelId),
    isLoading: false
  })),

  on(ProjectModelFieldActions.UpdateProjectModelFieldFailure, (state) => ({
    ...state,
    isLoading: false
  })),

  on(ProjectModelFieldActions.DeleteProjectModelField, (state, action) => ({
    ...state,
    deletingProjectModelFieldIds: [
      ...state.deletingProjectModelFieldIds,
      action.payload.projectModelId as string
    ],
    isLoading: true
  })),

  on(ProjectModelFieldActions.DeleteProjectModelFieldSuccessful, (state, action) => ({
    ...state,
    data: state?.data?.filter(item => action.payload.projectModelFieldId !== item.id) ?? [],
    deletingProjectModelFieldIds: state.deletingProjectModelFieldIds.filter(id => id !== action.payload.projectModelId),
    isLoading: false
  })),

  on(ProjectModelFieldActions.DeleteProjectModelFieldFailure, (state) => ({
    ...state,
    isLoading: false
  })),

  on(ProjectModelFieldActions.BulkUpdateProjectModelFields, (state) => ({
    ...state,
    isLoading: true
  })),

  on(ProjectModelFieldActions.BulkUpdateProjectModelFieldsSuccessful, (state, action) => {
    const nonUpdatedProjectModelFields = state.data.filter(projectModelField =>
      !action.payload.updatedProjectModelFields.some(updatedProjectModelField => updatedProjectModelField.id === projectModelField.id)
    );

    return {
      ...state,
      data: [
        ...nonUpdatedProjectModelFields,
        ...action.payload.updatedProjectModelFields
      ],
      isLoading: false
    };
  }),

  on(ProjectModelFieldActions.BulkUpdateProjectModelFieldsFailure, (state) => ({
    ...state,
    isLoading: false
  })),

  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),

  on(Logout, () => (initialState))
);
