import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
/* components */
import { CommentDisplayComponent } from './components/comment-display/comment-display.component';
import { CommentWrapperComponent } from './components/comment-wrapper/comment-wrapper.component';
/* 3rd party components */
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTagModule } from 'ng-zorro-antd/tag';
/* rappider components */
import {
  RappiderAlertModule,
  RappiderButtonModule,
  RappiderDividerModule,
  RappiderDropdownMenuModule,
  RappiderInputGroupModule,
  RappiderSelectModule,
  RappiderTagModule,
  RappiderTextareaModule,
  RappiderTextboxModule
} from '@rappider/rappider-components';
import { SharedModule } from '@rappider/shared';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { CommentContentComponent } from './components/comment-content/comment-content.component';
import { CommentHeaderComponent } from './components/comment-header/comment-header.component';
import { CommentRepliesComponent } from './components/comment-replies/comment-replies.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzListModule,
    NzTagModule,
    RappiderButtonModule,
    RappiderDividerModule,
    RappiderTextboxModule,
    RappiderTextareaModule,
    RappiderSelectModule,
    RappiderDropdownMenuModule,
    RappiderInputGroupModule,
    TranslateModule,
    NzSkeletonModule,
    NzToolTipModule,
    NzMentionModule,
    NzInputModule,
    NzAvatarModule,
    SharedModule,
    RappiderTagModule,
    NzDropDownModule,
    NzIconModule,
    RappiderAlertModule
  ],
  declarations: [
    CommentDisplayComponent,
    CommentWrapperComponent,
    CommentContentComponent,
    CommentHeaderComponent,
    CommentRepliesComponent
  ],
  exports: [
    CommentDisplayComponent,
    CommentWrapperComponent
  ]
})
export class CommentModule { }
