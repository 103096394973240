import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { CustomFunctionDefinitionWithRelations, ProjectWithRelations, WorkflowStepFunction, WorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { ActionResponse, BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { WORKFLOW_STEP_FUNCTION_LIST_CONFIG } from '@rappider/shared/configs';
import { Subscription } from 'rxjs';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { DeleteWorkflowStepFunction, GetPostDataTransformationData, GetPreDataTransformationData } from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.actions';
import { WorkflowStepFunctionDataTransformationComponentMode } from '../../models/workflow-step-function-data-transformation-component-mode.enum';
import { PostDataTransformationData, PreDataTransformationData } from '@rappider/shared/interfaces';
import { NotificationService } from '@rappider/services';

@Component({
  selector: 'rappider-workflow-step-function-list',
  templateUrl: './workflow-step-function-list.component.html',
  styleUrls: ['./workflow-step-function-list.component.scss']
})
export class WorkflowStepFunctionListComponent implements OnInit, OnDestroy {
  /* workflow list config */
  WORKFLOW_STEP_FUNCTION_LIST_CONFIG = WORKFLOW_STEP_FUNCTION_LIST_CONFIG;
  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.WORKFLOW_STEP_FUNCTION_LIST',
    type: defaultToolbarTitleHeadingSize
  };
  /* page title */
  title: BreadcrumbOption[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* subscriptions */
  subscriptions: Subscription[];
  /* workflow step functions */
  workflowStepFunctions: WorkflowStepFunctionWithRelations[];
  /* mapped worflow step function data to display in list grid */
  workflowStepFunctionListData: any[];
  /* WorkflowStepFunctionDataTransformationComponent visibility */
  dataTransformationModalVisibility = false;
  /* data transformation loading state */
  isDataTransformationLoading = false;

  /* Data transformation data for WorkflowStepFunctionDataTransformationComponent */
  dataTransformationId: string;
  sourceSchema: any;
  targetSchema: any;

  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToWorkflowStepFunctions(),
      this.subscribeToDataTransformationLoading(),
      this.subscribeToPreDataTransformation(),
      this.subscribeToPostDataTransformation()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      this.activeProject = activeProject;
      this.setTitle();
    });
  }

  /**
   * subscribe to workflow step functions and custom functions
   *
   * @return {*}
   * @memberof WorkflowStepFunctionListComponent
   */
  subscribeToWorkflowStepFunctions() {
    return this.store.select(createSelector(
      state => <WorkflowStepFunctionWithRelations[]>state.workflowStepFunction?.data,
      state => <CustomFunctionDefinitionWithRelations[]>state.customFunction?.data,
      (
        workflowStepFunctions: WorkflowStepFunctionWithRelations[],
        customFunctions: CustomFunctionDefinitionWithRelations[]
      ) => {
        const workflowStepFunctionWithRelations = workflowStepFunctions?.map(stepFunction => ({
          ...stepFunction,
          customFunction: customFunctions?.find(customFunction => customFunction.id === stepFunction.customFunctionDefinitionId)
        }));
        /* set workflow step functions with custom functions */
        this.workflowStepFunctions = workflowStepFunctionWithRelations;

        /* map events of step function to display on the list */
        return workflowStepFunctionWithRelations.map(stepFunction => ({
          ...stepFunction,
          subscribedEvents: stepFunction.subscribedEvents?.map(subscribedEvent => subscribedEvent.name).join(', '),
          publishedEventsOnSuccess: stepFunction.publishedEventsOnSuccess?.map(
            publishedEventOnSuccess => publishedEventOnSuccess.name).join(', '),
          publishedEventsOnFailure: stepFunction.publishedEventsOnFailure?.map(
            publishedEventOnFailure => publishedEventOnFailure.name).join(', ')
        }));
      }
    )).subscribe((workflowStepFunctions) => {
      /* set step function list data */
      this.workflowStepFunctionListData = workflowStepFunctions;
    });
  }

  subscribeToDataTransformationLoading() {
    return this.store.select(state => state.workflowStepFunction?.isDataTransformationLoading).subscribe(isLoading => {
      this.isDataTransformationLoading = isLoading;
    });
  }

  subscribeToPreDataTransformation() {
    return this.store.select(state => state.workflowStepFunction?.preDataTransformationData).subscribe((preDT: PreDataTransformationData) => {
      this.dataTransformationId = preDT?.preDataTransformationId;
      this.sourceSchema = preDT?.preSourceJsonSchema;
      this.targetSchema = preDT?.preTargetJsonSchema;
    });
  }

  subscribeToPostDataTransformation() {
    return this.store.select(state => state.workflowStepFunction?.postDataTransformationData).subscribe((postDT: PostDataTransformationData) => {
      this.dataTransformationId = postDT?.postDataTransformationId;
      this.sourceSchema = postDT?.postSourceJsonSchema;
      this.targetSchema = postDT?.postTargetJsonSchema;
    });
  }

  /**
   *  set page title
   *
   * @memberof WorkflowStepFunctionListComponent
   */
  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.WORKFLOW_STEP_FUNCTION_LIST'
      }
    ];
  }

  setDataTransformationComponentData(
    workflowStepFunction: WorkflowStepFunctionWithRelations,
    dataTransformationComponentMode: WorkflowStepFunctionDataTransformationComponentMode
  ) {
    this.toggleDataTransformationModalVisibility();

    if (dataTransformationComponentMode === WorkflowStepFunctionDataTransformationComponentMode.PreDataTransformation) {
      if (!workflowStepFunction.customFunctionDefinition?.requestJSONSchema || !workflowStepFunction.subscribedEvents[0].inputDataSample) {
        this.notificationService.createNotification(
          'warning',
          'Warning',
          'To add a data transformation, both the Request JSON Schema in the custom function and the Input Data sample in the subscribed event are required.'
        );
      } else {
        this.store.dispatch(GetPreDataTransformationData({ payload: { workflowStepFunction } }));
      }

    } else if (dataTransformationComponentMode === WorkflowStepFunctionDataTransformationComponentMode.PostDataTransformation) {
      if (!workflowStepFunction.customFunctionDefinition?.responseJSONSchema || !workflowStepFunction.subscribedEvents[0].inputDataSample) {
        this.notificationService.createNotification(
          'warning',
          'Warning',
          'To add a data transformation, both the Response JSON Schema in the custom function and the Input Data sample in the subscribed event are required.'
        );
      } else {
        this.store.dispatch(GetPostDataTransformationData({ payload: { workflowStepFunction } }));
      }
    }
  }

  onColumnActionClick(actionResponse: ActionResponse) {
    /* clicked column's data */
    const workflowStepFunction = this.workflowStepFunctions.find(stepFunction => stepFunction.id === actionResponse.data.id);

    if (actionResponse.action.name === 'DELETE_ITEM') {
      /* delete step function */
      this.store.dispatch(DeleteWorkflowStepFunction({ payload: { workflowStepFunctionId: workflowStepFunction.id } }));
    } else if (actionResponse.action.name === 'preDataTransformation') {
      this.setDataTransformationComponentData(
        workflowStepFunction,
        WorkflowStepFunctionDataTransformationComponentMode.PreDataTransformation
      );
    } else if (actionResponse.action.name === 'postDataTransformation') {
      this.setDataTransformationComponentData(
        workflowStepFunction,
        WorkflowStepFunctionDataTransformationComponentMode.PostDataTransformation
      );
    }
  }

  toggleDataTransformationModalVisibility() {
    this.dataTransformationModalVisibility = !this.dataTransformationModalVisibility;
  }
}
