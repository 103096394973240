<div class="diagram-wrapper">
  <rappider-diagram-toolbar [(menuVisibility)]="menuVisibility"
                            (menuVisibilityChange)="onMenuVisibilityChange()"
                            (createWorkflowEvent)="onCreateWorkflowEventButtonClick()"
                            (createStepFunction)="onCreateStepFunctionButtonClick()"></rappider-diagram-toolbar>

  <div class="zoom-buttons-area">
    <div class="zoom-button-child"
         [ngClass]="{'visible-zoom-buttons': zoomButtonVisibility}">
      <ul class="zoom-button-list">
        <li *ngFor="let command of commands"><a (click)="transform(command.key)">{{ command.label }}</a></li>
      </ul>
    </div>
    <button class="float-button"
            (click)="zoomButtonVisibilityChange()">
      <i *ngIf="!zoomButtonVisibility"
         class="fa-regular fa-magnifying-glass-plus"></i>
      <i *ngIf="zoomButtonVisibility"
         class="fa-solid fa-x"></i>
    </button>
  </div>

  <div class="diagram-area">
    <div id="diagram-container"
         class="diagram-container"
         [ngStyle]="{'width': menuVisibility ? '70%': '100%' }">
    </div>

    <!-- <div *ngIf="displayNoData()"
         class="info-alert">
      <rappider-alert [type]="infoAlertConfig.type"
                      [title]="infoAlertConfig.title"
                      [showIcon]="infoAlertConfig.showIcon"
                      [closeable]="infoAlertConfig.closeable"></rappider-alert>
    </div> -->

    <ng-container *ngIf="menuVisibility">
      <div *ngIf="menuVisibility"
           class="close-icon">
        <rappider-icon [name]="closeIcon.name"
                       [color]="closeIcon.color"
                       (click)="menuVisibilityChange()"></rappider-icon>
      </div>
      <ng-container *ngIf="isAddEventMenuVisible">
        <rappider-workflow-event-wrapper [ngStyle]="{'width': isAddEventMenuVisible ? '30%': '0%' }"
                                         [submitButtonLoading]="eventFormSubmitButtonLoading"
                                         [eventMode]="eventMode"
                                         [workflowEvent]="editingWorkflowEvent"
                                         (eventNameChange)="onEventNameChangeOnCreate($event)"
                                         (editWorkflowEvent)="onEditWorkflowEvent($event)"
                                         (createWorkflowEvent)="onCreateWorkflowEvent($event)"></rappider-workflow-event-wrapper>
      </ng-container>
      <ng-container *ngIf="isAddStepFunctionMenuVisible">
        <div [@inOutAnimation]
             class="form-area">
          <section class="heading-area">
            <rappider-heading [content]="stepFunctionMode === StepFunctionMode.Create ? 'New ' + workflowStepFunctionFormHeading.content : 'Edit ' + workflowStepFunctionFormHeading.content"
                              [type]="workflowStepFunctionFormHeading.type"></rappider-heading>
          </section>
          <div class="form">
            <rappider-workflow-step-function-form [data]="stepFunctionMode === StepFunctionMode.Create ? initialStepFunctionData : editingWorkflowStepFunction"
                                                  [submitButtonLoading]="stepFunctionFormSubmitButtonLoading"
                                                  [formLayout]="formLayout"
                                                  [isSubmitButtonFloat]="true"
                                                  (nameChange)="onStepFunctionNameChangeOnCreate($event)"
                                                  (selectedMode)="onModeChange($event)"
                                                  (workflowStepFunctionFormSubmit)="onStepFunctionFormSubmit($event)"></rappider-workflow-step-function-form>
            <!-- Data Transformation Area -->
            <section *ngIf="stepFunctionMode === StepFunctionMode.Edit"
                     class="data-transformation-area">
              <div>
                <rappider-button [text]="preDTButtonConfig.text"
                                 [icon]="preDTButtonConfig.icon"
                                 [block]="preDTButtonConfig.block"
                                 (confirm)="onDataTransformationButtonClick(
                                  DataTransformationComponentMode.PreDataTransformation,
                                  editingWorkflowStepFunction)">
                </rappider-button>
              </div>

              <div>
                <rappider-button [text]="postDTButtonConfig.text"
                                 [icon]="postDTButtonConfig.icon"
                                 [block]="postDTButtonConfig.block"
                                 (confirm)="onDataTransformationButtonClick(
                                  DataTransformationComponentMode.PostDataTransformation,
                                  editingWorkflowStepFunction)">
                </rappider-button>
              </div>
            </section>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div *ngIf="!isDiagramBuilt"
         class="loading-icon">
      <i class="fa-duotone fa-spinner fa-spin"></i>
    </div>

    <rappider-modal [(visible)]="dataTransformationModalVisibility"
                    [fullScreen]="true"
                    [title]="'Data Transformation'"
                    (okClick)="toggleDataTransformationModalVisibility()"
                    (cancelClick)="toggleDataTransformationModalVisibility()">
      <rappider-workflow-step-function-data-transformation *ngIf="dataTransformationModalVisibility"
                                                           [isLoading]="isDataTransformationLoading"
                                                           [dataTransformationId]="dataTransformationId"
                                                           [sourceSchema]="sourceSchema"
                                                           [targetSchema]="targetSchema"></rappider-workflow-step-function-data-transformation>
    </rappider-modal>
  </div>

  <ng-template #commentsTemplate
               let-data="ngArguments">
    <div class="comment-component-wrapper">
      <rappider-comment-wrapper [activePerson]="data.activePerson"
                                [peopleData]="data.peopleData"
                                [comments]="data.comments"
                                [isLoading]="data.isLoading"
                                (createComment)="onCreateComment($event)"
                                (editComment)="onEditComment($event)"
                                (deleteComment)="onDeleteComment($event)"></rappider-comment-wrapper>
    </div>
  </ng-template>
</div>
