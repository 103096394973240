/* eslint-disable @typescript-eslint/no-explicit-any */
import * as AuthenticationActions from './authentication.actions';
import { LastProcessedAction } from '@rappider/shared/interfaces';
import { Person, ProjectRole, Tenant } from '@rappider/rappider-sdk';

export const authenticationFeatureKey = 'auth';

export interface AuthState {
  user: any;
  activePerson: Person;
  tenant: Tenant;
  roles: ProjectRole[];
  isPersonLoading: boolean;
  isUserLoading: boolean;
  lastProcessedAction: LastProcessedAction;
  isLoading: boolean;
  error: {
    error: any;
    key: string;
    timestamp: number;
  };
  isEmailVerified: boolean;
  permissions: string[];
}

export const initialState: AuthState = {
  user: null,
  activePerson: null,
  tenant: null,
  roles: null,
  isPersonLoading: false,
  isUserLoading: false,
  lastProcessedAction: {
    success: null,
    action: null,
    message: null,
    timestamp: 0,
    data: null
  },
  isLoading: false,
  error: null,
  isEmailVerified: null,
  permissions: null
};

export function reducer(
  state: AuthState = initialState,
  action: AuthenticationActions.Actions
): AuthState {
  switch (action.type) {

    case AuthenticationActions.ActionTypes.EnableUserLoading: {
      return {
        ...state,
        isUserLoading: true
      };
    }

    case AuthenticationActions.ActionTypes.DisableUserLoading: {
      return {
        ...state,
        isUserLoading: false
      };
    }

    case AuthenticationActions.ActionTypes.SetUser:
      return {
        ...state,
        user: action.payload.user
      };

    case AuthenticationActions.ActionTypes.SetTenant:
      return {
        ...state,
        tenant: action.payload.tenant
      };

    case AuthenticationActions.ActionTypes.SetActivePerson:
      /**
       * get user by parameter and delete unnecessary included fields
       * if needed, they can be reached their states
      */
      // eslint-disable-next-line no-case-declarations
      const activePerson = action?.payload?.person
        ? {
          ...action.payload.person,
          initials: [action.payload.person.firstName, action.payload.person.middleName, action.payload.person.lastName]
            .filter(x => x).map(name => name.substring(0, 1)).join(''),
          tenant: undefined,
          projects: undefined
        }
        : null;


      return {
        ...state,
        activePerson: activePerson
      };

    case AuthenticationActions.ActionTypes.SetActivePersonFailure:
      return {
        ...state,
        error: {
          error: action.payload.error,
          key: action.payload.key,
          timestamp: Date.now()
        },
      };

    case AuthenticationActions.ActionTypes.SetPersonProjectRoles:
      return {
        ...state,
        roles: action.payload.roles
      };

    case AuthenticationActions.ActionTypes.SetPermissions:
      return {
        ...state,
        permissions: action.payload.permissions
      };

    case AuthenticationActions.ActionTypes.Logout:
      return {
        ...state,
        user: null,
        activePerson: null,
        isEmailVerified: null,
        permissions: null
      };

    case AuthenticationActions.ActionTypes.ChangeLastProcessedAction:
      return {
        ...state,
        lastProcessedAction: {
          ...action.payload.lastProcessedAction,
          timestamp: Date.now()
        }
      };

    case AuthenticationActions.ActionTypes.ForgotPassword:
      return {
        ...state,
        isLoading: true
      };

    case AuthenticationActions.ActionTypes.ForgotPasswordSuccessful:
      return {
        ...state,
        isLoading: false
      };

    case AuthenticationActions.ActionTypes.ForgotPasswordFailure:
      return {
        ...state,
        isLoading: false
      };

    case AuthenticationActions.ActionTypes.ChangePassword:
      return {
        ...state,
        isLoading: true
      };

    case AuthenticationActions.ActionTypes.ChangePasswordSuccessful:
      return {
        ...state,
        isLoading: false
      };

    case AuthenticationActions.ActionTypes.ChangePasswordFailure:
      return {
        ...state,
        isLoading: false
      };

    case AuthenticationActions.ActionTypes.UpdatePreferredThemeForActiveUserSuccessful:
      return {
        ...state,
        activePerson: {
          ...state.activePerson,
          preferredTheme: action.payload.preferredTheme
        }
      };

    case AuthenticationActions.ActionTypes.VerifyEmailSuccessful:
      return {
        ...state,
        isEmailVerified: true,
        activePerson: {
          ...state.activePerson,
          isVerified: true
        }
      };

    case AuthenticationActions.ActionTypes.VerifyEmailFailure:
      return {
        ...state,
        isEmailVerified: false
      };

    case AuthenticationActions.ActionTypes.LoginByAuthenticationTokenFailure:
      return {
        ...state,
        error: {
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        }
      };

    case AuthenticationActions.ActionTypes.ChangeActivePersonFailure:
      return {
        ...state,
        error: {
          error: action.payload.error,
          key: action.payload.key,
          timestamp: Date.now()
        },
      };

    case AuthenticationActions.ActionTypes.ErrorAction:
      return {
        ...state,
        error: {
          error: action.payload.error,
          key: action.payload.key,
          timestamp: Date.now()
        },
      };
    case AuthenticationActions.ActionTypes.UpdatePersonSuccessful: {
      return {
        ...state,
        activePerson: {
          ...state.activePerson,
          ...action.payload.person
        }
      };
    }
    default:
      return state;
  }
}
