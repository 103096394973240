<div class="example-template">
  <div class="heading-area">
    <rappider-heading [content]="heading.content"
                      [type]="heading.type"
                      nz-tooltip
                      [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                      [nzTooltipPlacement]="'left'"
                      nzTooltipTitle="{{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H1_COLOR' | translate }}"></rappider-heading>
    <rappider-paragraph [content]="paragraph?.content"
                        [colorSettings]="paragraph?.colorSettings"
                        nz-tooltip
                        [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                        nzTooltipTitle="Text Color"></rappider-paragraph>
  </div>

  <div class="body-area">
    <div class="element">
      <rappider-heading *ngIf="headingConfigs['steps']"
                        [content]="headingConfigs['steps'].content"
                        [type]="headingConfigs['steps'].type"
                        nz-tooltip
                        [nzTooltipPlacement]="'left'"
                        [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                        nzTooltipTitle="{{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H2_COLOR' | translate }}">
      </rappider-heading>

      <div class="step-components">
        <rappider-steps [(current)]="current"
                        [steps]="stepsComponentConfig.steps"
                        class="steps"
                        nz-tooltip
                        [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                        [nzTooltipTitle]="stepTooltipTemplate"
                        [nzTooltipOverlayClassName]="'tooltip-style'">
        </rappider-steps>
        <ng-template #stepTooltipTemplate>
          <div>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_BACKGROUND_COLOR' | translate }}<br>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SECONDARY_BORDER_COLOR' | translate }}<br>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_COLOR' | translate }}
          </div>
        </ng-template>
        <div *ngIf="current === 0"
             class="step-one">
          <div *ngFor="let cardFourConfig of cardFourComponentConfigs"
               class="card-four">
            <rappider-card-four [avatar]="cardFourConfig.avatar"
                                [title]="cardFourConfig.title"
                                nz-tooltip
                                [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                                [nzTooltipTitle]="stepOneTooltipTemplate"
                                [nzTooltipOverlayClassName]="'tooltip-style'">
            </rappider-card-four>
            <ng-template #stepOneTooltipTemplate>
              <div>
                {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.COMPONENT_INNER_BACKGROUND_COLOR' | translate }}<br>
                {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_BORDER_COLOR' | translate }}
              </div>
            </ng-template>
          </div>
        </div>
        <div *ngIf="current === 1"
             class="step-two">
          <section class="card-content">
            <div class="w-100">
              <rappider-image [source]="imageComponentConfig.source"
                              [customSizeSettings]="imageComponentConfig.customSizeSettings"></rappider-image>
            </div>
            <div class="w-100">
              <rappider-heading [content]="heading.content"
                                [type]="heading.type"
                                nz-tooltip
                                [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                                [nzTooltipPlacement]="'left'"
                                nzTooltipTitle="{{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H1_COLOR' | translate }}"></rappider-heading>
              <rappider-rate-display [rate]="4"
                                     nz-tooltip
                                     [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                                     nzTooltipTitle="{{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_COLOR' | translate }}"></rappider-rate-display>
              <rappider-paragraph [content]="paragraph?.content"
                                  [colorSettings]="paragraph?.colorSettings"
                                  nz-tooltip
                                  [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                                  nzTooltipTitle="{{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLOR' | translate }}"></rappider-paragraph>
            </div>
          </section>
        </div>
        <div *ngIf="current === 2"
             class="step-three">
          <section class="card-content">
            <rappider-comment [comments]="commentComponentConfig"
                              nz-tooltip
                              [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                              [nzTooltipTitle]="stepThreeTooltipTemplate"></rappider-comment>
            <ng-template #stepThreeTooltipTemplate>
              <div>
                {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.MAIN_FONT_SIZE' | translate }}<br>
                {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_TEXT_COLOR' | translate }}<br>
                {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TEXT_COLOR' | translate }}
              </div>
            </ng-template>
          </section>
        </div>
      </div>

      <div class="steps-buttons">
        <rappider-button text="Previous"
                         [disabled]="current === 0"
                         (click)="decreaseStep()"
                         nz-tooltip
                         [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                         [nzTooltipTitle]="current === 0 ? disabledText : buttonTooltipTemplate"
                         [nzTooltipOverlayClassName]="'tooltip-style'">
        </rappider-button>

        <rappider-button text="Next"
                         [disabled]="current === 2"
                         (click)="increaseStep()">
        </rappider-button>

        <ng-template #disabledText>
          {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DISABLED_TEXT_COLOR' | translate }}
        </ng-template>
        <ng-template #buttonTooltipTemplate>
          <div>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SECONDARY_BUTTON_TEXT_COLOR' | translate }}<br>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DEFAULT_COLOR' | translate }}<br>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DEFAULT_HOVER_COLOR' | translate }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="element">
      <rappider-heading *ngIf="headingConfigs['listGrid']"
                        [content]="headingConfigs['listGrid'].content"
                        [type]="headingConfigs['listGrid'].type"
                        nz-tooltip
                        [nzTooltipPlacement]="'left'"
                        [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                        nzTooltipTitle="{{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H2_COLOR' | translate }}">
      </rappider-heading>

      <div class="list-grid"
           nz-tooltip
           [nzTooltipTrigger]="showTooltip ? 'hover' : null"
           [nzTooltipTitle]="listgridTooltipTemplate"
           [nzTooltipOverlayClassName]="'tooltip-style'">
        <rappider-list-grid [data]="listGridComponentConfig.data"
                            [config]="listGridComponentConfig.config">
        </rappider-list-grid>
      </div>

      <ng-template #listgridTooltipTemplate>
        <div>
          {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_COLOR' | translate }}<br>
          {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_HOVER_COLOR' | translate }}<br>
          {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.INPUT_BACKGROUND_COLOR' | translate }}<br>
          {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TABLE_HEADER_BACKGROUND_COLOR' | translate }}<br>
          {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TABLE_BACKGROUND_COLOR' | translate }}<br>
          {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.TABLE_HOVER_COLOR' | translate }}<br>
          {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.PRIMARY_BORDER_COLOR' | translate }}
        </div>
      </ng-template>
    </div>

    <div class="element">
      <rappider-heading *ngIf="headingConfigs['form']"
                        [content]="headingConfigs['form'].content"
                        [type]="headingConfigs['form'].type"
                        nz-tooltip
                        [nzTooltipPlacement]="'left'"
                        [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                        nzTooltipTitle="{{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.H2_COLOR' | translate }}">
      </rappider-heading>

      <div class="form">
        <rappider-crud-view-edit-form [config]="THEME_FORM_CONFIG"
                                      [data]="formData"
                                      nz-tooltip
                                      [nzTooltipTrigger]="showTooltip ? 'hover' : null"
                                      [nzTooltipTitle]="formTooltipTemplate"
                                      [nzTooltipOverlayClassName]="'tooltip-style'">
        </rappider-crud-view-edit-form>
        <ng-template #formTooltipTemplate>
          <div>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.SECONDARY_BORDER_COLOR' | translate }}<br>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.FOCUS_COLOR' | translate }}<br>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DEFAULT_COLOR' | translate }}<br>
            {{ 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.DEFAULT_HOVER_COLOR' | translate }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
