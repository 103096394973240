import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionResponse } from '@rappider/rappider-components/utils';
import { ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { DATA_FIELD_LIST_CONFIG_FOR_DESIGN_MODULE } from 'libs/project/src/lib/components/data-field-list/config/data-field-list-config';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'rappider-data-model-detail',
  templateUrl: './data-model-detail.component.html',
  styleUrls: ['./data-model-detail.component.scss']
})
export class DataModelDetailComponent implements OnInit {

  @Input() projectModel: ProjectModelWithRelations | undefined;
  @Input() animateTable = false;
  @Input() enableRowPointer = false;

  @Output() rowActionClick = new EventEmitter<ActionResponse>();

  DATA_FIELD_LIST_CONFIG_FOR_DESIGN_MODULE = cloneDeep(DATA_FIELD_LIST_CONFIG_FOR_DESIGN_MODULE);

  constructor(private store: Store<any>) { }

  ngOnInit(): void {
    console.log('Component initialized.');
    if (!this.projectModel?.isUpdatable) {
      DATA_FIELD_LIST_CONFIG_FOR_DESIGN_MODULE.orderable = false;
    }
  }

  navigateCreateDataFieldPage() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_CREATE}/${this.projectModel?.id}` }));
  }

  onDeleteDataFieldClick(dataFieldId: any) {
    console.log('Delete data field clicked', dataFieldId);
  }
  onDataFieldListActionDropdownItemClick(action: any) {
    console.log('Data field list action dropdown item clicked', action);
  }

  onOrderChange(event: any) {
    console.log('Order changed', event);
  }

  onRowActionClick(data: ActionResponse) {
    this.rowActionClick.emit(data);
  }


}
