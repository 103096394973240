import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSubscriptionComponent } from './components/data-subscription/data-subscription.component';
import { RappiderButtonModule, RappiderDataBinderModule, RappiderJsonataEditorModule, RappiderModalModule, RappiderMonacoEditorModule, RappiderSpinModule } from '@rappider/rappider-components';
import { FormsModule } from '@angular/forms';
import { UiDataStoreFieldTreeSelectorComponent } from './components/ui-data-store-field-tree-selector/ui-data-store-field-tree-selector.component';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { JoyrideModule } from 'ngx-joyride';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [
    DataSubscriptionComponent,
    UiDataStoreFieldTreeSelectorComponent
  ],
  imports: [
    CommonModule,
    RappiderMonacoEditorModule,
    FormsModule,
    RappiderButtonModule,
    RappiderDataBinderModule,
    RappiderModalModule,
    RappiderSpinModule,
    NzTreeModule,
    JoyrideModule.forChild(),
    NzIconModule,
    TranslateModule,
    RappiderJsonataEditorModule,
    NzTreeModule,
    TranslateModule,
    NzInputModule,
    NzIconModule
  ],
  exports: [
    DataSubscriptionComponent,
    UiDataStoreFieldTreeSelectorComponent
  ],
})
export class DataSubscriptionModule { }
