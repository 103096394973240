import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationService } from '@rappider/services';
import { catchError, delay, map, mergeMap, retryWhen, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { Router } from '@angular/router';
import { DiagramItemType } from 'libs/diagram-editor/src/lib/utils/diagram-item-type';
import { WorkflowItemInDiagram } from 'libs/diagram-editor/src/lib/utils/workflow-item-in-diagram.interface';
import { DataSchemaControllerService, DataSchemaWithRelations, UiDataEventControllerService, UiDataEventWithRelations, UiDataStoreControllerService, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { UIDataStoreIdForTemplateWorkflows } from '../../../../../workflow-templates/src/lib/components/workflow-templates-pages/utils/get-ui-data-store-with-filtered-id.enum';
import { environment } from '@environment';

import * as UIDataEventActions from './ui-data-event.actions';
import * as DiagramActions from 'libs/diagram-editor/src/lib/state/diagram-state/diagram.actions';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';
import * as DataSchemaActions from 'libs/project/src/lib/states/data-schema/data-schema.actions';

@Injectable()
export class UIDataEventEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private uiDataEventApi: UiDataEventControllerService,
    private uiDataStoreApi: UiDataStoreControllerService,
    private notificationService: NotificationService,
    private dataSchemaApi: DataSchemaControllerService,
    private router: Router
  ) { }


  getUIDataEvent$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.GetUIDataEvents>(
      UIDataEventActions.ActionTypes.GetUIDataEvents
    ),
    withLatestFrom(
      this.store.select(state => state.activeProject.data?.id)
    ),
    mergeMap(([action, activeProjectId]) => {
      if (activeProjectId) {
        const filter = {
          where: {
            projectId: activeProjectId
          },
          fields: [
            'id'
          ],
          include: [
            {
              relation: 'events',
              scope: {
                include: [
                  {
                    relation: 'payload',
                    scope: {
                      order: ['name ASC'],
                      include: ['fields']
                    }
                  },
                  {
                    relation: 'uiDataUpdateFunction'
                  }
                ]
              }
            }
          ]
        };
        /* TODO: ui data event api ile cekilmesi lazim, relation olarak cekilmemeli data */
        return this.uiDataStoreApi.find({ filter }).pipe(
          mergeMap((uiDataStores: UiDataStoreWithRelations[]) => {
            const events = uiDataStores.filter(uiDataStore => uiDataStore.events).map(uiDataStore => uiDataStore.events).flat();

            return [
              new UIDataEventActions.SetUIDataEvents({ uiDataEvents: events })
            ];
          })
        );
      } else {
        return [
          new UIDataEventActions.ErrorAction({ error: 'NoActiveProject', key: 'GetDataEvents', timestamp: Date.now() })
        ];
      }
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [
        new UIDataEventActions.ErrorAction({ error: error, key: 'GetUIDataEvents', timestamp: Date.now() })
      ];
    })
  ));


  getUIDataEventAccordingToIds$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.GetUIDataEventsAccordingToId>(
      UIDataEventActions.ActionTypes.GetUIDataEventsAccordingToId
    ),
    mergeMap((action) => {
      const filter = {
        where: {
          id: { inq: action.payload.uiDataEventsIds }
        },
      };
      return this.uiDataEventApi.find({ filter }).pipe(
        map((uiDataEvents: UiDataEventWithRelations[]) =>
          new UIDataEventActions.BulkCreateUIDataEventSuccessful({ uiDataEvents })
        )
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [new UIDataEventActions.GetUIDataEventsAccordingToIdFailure({ error, key: 'GetUIDataEventsAccordingToIdFailure', timestamp: Date.now() })];
    })
  ));

  /**
  * This effect triggers when project model created with flag generateUIDataStore set to true
  *
  * @memberof UIDataEventEffects
  */

  getUIDataEventAccordingToUIDataStoreIds$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.GetUIDataEventsAccordingToUIDataStoreId>(
      UIDataEventActions.ActionTypes.GetUIDataEventsAccordingToUIDataStoreId
    ),
    mergeMap((action) => {
      const filter = {
        where: {
          uiDataStoreId: action.payload.uiDataStoreId
        },
      };
      return this.uiDataEventApi.find({ filter }).pipe(
        map((uiDataEvents: UiDataEventWithRelations[]) =>
          new UIDataEventActions.BulkCreateUIDataEventSuccessful({ uiDataEvents })
        )
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_LOAD'
      );
      return [new UIDataEventActions.GetUIDataEventsAccordingToUIDataStoreIdFailure({ error, key: 'GetUIDataEventsAccordingToUIDataStoreIdFailure', timestamp: Date.now() })];
    })
  ));


  createUIDataEvent$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.CreateUIDataEvent>(
      UIDataEventActions.ActionTypes.CreateUIDataEvent
    ),
    mergeMap((action) => {
      const params = {
        options: {
          createSuccessAndFailureEvents: action.payload.createSuccessAndFailureEvents
        },
        body: <UiDataEventWithRelations>{
          ...action.payload.uiDataEvent,
          uiDataStoreId: action.payload.uiDataStoreId
        }
      };

      const actions = [];

      return this.uiDataEventApi.create(params).pipe(
        mergeMap((uiDataEvents: UiDataEventWithRelations[]) => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            `${action.payload.uiDataEvent.name} Successfully Created`
          );

          const uiDataEvent = uiDataEvents.find(uiDataEvent => uiDataEvent.name === params.body.name);

          actions.push(...uiDataEvents.map(uiDataEvent => new UIDataEventActions.CreateUIDataEventSuccessful({ uiDataEvent: uiDataEvent })));

          if (action.payload.navigateAfterCreate) {
            actions.push(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.DATA_EVENT_DETAIL}/${uiDataEvent.id}` }));
          }
          return actions;
        }), catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            error?.error?.error?.message
          );
          return [
            new UIDataEventActions.ErrorAction({ error: error, key: 'UIDataEventCreate', timestamp: Date.now() })
          ];
        })
      );
    })
  ));


  createUIDataEventSuccessful$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.CreateUIDataEventSuccessful>(
      UIDataEventActions.ActionTypes.CreateUIDataEventSuccessful
    ),
    mergeMap((action) => [
      new UIDataEventActions.GetUIDataEventPayload({ uiDataEvent: action.payload.uiDataEvent })
    ])
  ));


  getUIDataEventPayload$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.GetUIDataEventPayload>(
      UIDataEventActions.ActionTypes.GetUIDataEventPayload
    ),
    mergeMap((action) => {
      let currentTryCount = 0;

      return this.dataSchemaApi.findById({ id: action.payload.uiDataEvent?.dataSchemaId }).pipe(
        retryWhen(errors =>
          errors.pipe(
            tap(() => currentTryCount++),
            takeWhile(() => currentTryCount <= environment.maxTryCountForRecursiveAPICalls),
            delay(1000)
          )
        ),
        mergeMap((payload: DataSchemaWithRelations) => [
          new DataSchemaActions.CreateDataSchemaSuccessful({ createdDataSchema: payload, navigateToDataSchemaList: false }),
          new UIDataEventActions.GetUIDataEventPayloadSuccessful()
        ])
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_CREATED'
      );
      return [
        new UIDataEventActions.GetUIDataEventPayloadFailure({ error: error, key: 'GetUIDataEventPayload', timestamp: Date.now() })
      ];
    })
  ));


  updateUIDataEvents$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.UpdateUIDataEvent>(
      UIDataEventActions.ActionTypes.UpdateUIDataEvent
    ),
    mergeMap((action) => {
      const params = {
        id: action.payload.uiDataEventId,
        body: action.payload.uiDataEvent
      };

      return this.uiDataEventApi.updateById(params).pipe(
        mergeMap((uiDataEvent: UiDataEventWithRelations) => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'SHARED.SUCCESSFULLY_UPDATED'
          );
          if (action.payload.navigateAfterUpdate) {
            /* TODO: needs router state navigate action */
            this.router.navigate(
              [`${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${uiDataEvent.uiDataStoreId}`],
              { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.EVENTS_TAB }
            );
          }
          return [
            new UIDataEventActions.UpdateUIDataEventSuccessful({ uiDataEvent: uiDataEvent })
          ];
        }
        )
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'SHARED.COULDNT_UPDATED'
      );
      return [
        new UIDataEventActions.ErrorAction({ error: error, key: 'UIDataEventUpdate', timestamp: Date.now() })
      ];
    })
  ));


  updateUIDataEventInState$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.UpdateUIDataEventSuccessful>(
      UIDataEventActions.ActionTypes.UpdateUIDataEventSuccessful
    ),
    map((action) => {
      const updatedUIDataEvent: WorkflowItemInDiagram = {
        item: action.payload.uiDataEvent,
        type: DiagramItemType.UIDataEvent
      };
      return DiagramActions.SetActiveDiagramItem({ payload: { activeItem: updatedUIDataEvent } });
    })
  ));


  deleteUIDataEvent$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.DeleteUIDataEvent>(
      UIDataEventActions.ActionTypes.DeleteUIDataEvent
    ),
    mergeMap(action => {
      const params = {
        id: action.payload.uiDataEvent.id
      };

      return this.uiDataEventApi.deleteById(params).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            `${action.payload.uiDataEvent.name} Successfully Deleted`
          );
          return new UIDataEventActions.DeleteUIDataEventSuccessful({ deletedUIDataEventId: params.id });
        })
      );
    }), catchError(error => {
      this.notificationService.createNotification(
        'error',
        error,
        'SHARED.COULDNT_CREATED'
      );
      return [
        new UIDataEventActions.ErrorAction({ error: error, key: 'DeleteUIDataEvent', timestamp: Date.now() })
      ];
    })
  ));


  getTemplateUIDataEvents$ = createEffect(() => this.actions$.pipe(
    ofType<UIDataEventActions.GetTemplateUIDataEvents>(
      UIDataEventActions.ActionTypes.GetTemplateUIDataEvents
    ),
    mergeMap((action) => this.uiDataEventApi.findStarterTemplateEvents().pipe(
      mergeMap((uiDataEvents: UiDataEventWithRelations[]) =>
        [new UIDataEventActions.GetTemplateUIDataEventsSuccessful({ uiDataEvents: uiDataEvents })]
      )
    )),
    catchError(error => {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'UI_WORKFLOW_TEMPLATE_COMPONENT.COULDNT_LOAD_TEMPLATE_UI_DATA_EVENTS'
      );
      return [
        new UIDataEventActions.ErrorAction({ error: error, key: 'TemplateUIDataEvents', timestamp: Date.now() }),
      ];
    })
  ));
}
