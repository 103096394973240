<div class="component-search">
  <rappider-input-group [(ngModel)]="searchText"
                        [textbox]="inputGroupConfig.textbox"
                        [suffixIcon]="inputGroupConfig.suffixIcon"
                        (ngModelChange)="onSearchTextChange()"></rappider-input-group>
</div>

<div [class]="isComponentUsingInModal ? 'component-menu-modal-mode' : ''">
  <ng-container *ngIf="!searchText; else componentSearchResultTemplate">
    <nz-collapse *ngFor="let categorizedComponentDefintion of categorizedComponentDefinitions"
                 class="component-menu-collapse-panel">
      <nz-collapse-panel [nzHeader]="categorizedComponentDefintion.title"
                         [nzActive]="false">
        <ng-container *ngFor="let categorizedComponentDefinitionData of categorizedComponentDefintion.data">
          <ng-container *ngTemplateOutlet="componentTemplate;
            context:{$implicit: categorizedComponentDefinitionData.componentDefinition} ">
          </ng-container>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>

    <nz-collapse class="component-menu-collapse-panel">
      <nz-collapse-panel [nzHeader]="'Templates'"
                         [nzActive]="false">
        <ng-container *ngFor="let containerTemplate of containerTemplates">
          <ng-container *ngTemplateOutlet="containerTemplateTemplate;
            context:{$implicit: containerTemplate} ">
          </ng-container>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
  </ng-container>
</div>
<nz-spin [nzSpinning]="areThereAnyActiveSearch">
  <ng-template #componentSearchResultTemplate>
    <ng-container *ngIf="!areThereAnyActiveSearch">
      <rappider-heading content="CONTENT_EDITOR_MODULE.COMPONENT_MENU_COMPONENT.SEARCH_RESULTS_BY"
                        type="h6"></rappider-heading>
      <ng-container *ngFor="let matchedComponentDefinitionGroup of matchedComponentDefinitionGroups">
        <nz-collapse *ngIf="matchedComponentDefinitionGroup.data && matchedComponentDefinitionGroup.data?.length"
                     class="component-menu-collapse-panel">
          <nz-collapse-panel [nzHeader]="matchedComponentDefinitionGroup.title"
                             [nzActive]="true">
            <ng-container *ngFor="let componentDefinition of matchedComponentDefinitionGroup.data">
              <ng-container *ngTemplateOutlet="componentTemplate;
                                                           context:{$implicit: componentDefinition} ">
              </ng-container>
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>
      </ng-container>
    </ng-container>
  </ng-template>
</nz-spin>

<!-- COMPONENT TEMPLATE -->

<ng-template #componentTemplate
             let-componentDefinition>
  <section class="component-definition"
           (mouseover)="mouseOver(componentDefinition)"
           (mouseleave)="mouseOut()">
    <div *ngIf="hoveredComponentDefinitionId === componentDefinition.id"
         class="component-preview-popover"
         nz-popover
         nzPopoverTrigger="hover"
         nzPopoverPlacement="right"
         nzPopoverOverlayClassName="popover-overlay"
         [nzPopoverContent]="popoverContentTemplate"
         (nzPopoverVisibleChange)="onPopoverVisibilityChange($event,componentDefinition)">
      <i class="fa-regular fa-eye"></i>
    </div>
    <div class="component-title">
      <rappider-text [text]="componentDefinition.title">
      </rappider-text>
    </div>
    <div class="add-component-button">
      <rappider-button [icon]="addComponentButtonIcon"
                       (click)="onAddComponentDefinitionButtonClick(componentDefinition)">
      </rappider-button>
    </div>
  </section>
</ng-template>

<ng-template #popoverContentTemplate>
  <div *ngIf="!isComponentUsingInModal">
    <span *ngIf="activeContentTreeItem?.type === ContentEditorContentType.Container; else noActiveContentTreeItemTemplate"
          class="add-component-inside-information">
      Add Component Inside <b>{{getContentTreeItemAsPageContainer(activeContentTreeItem).title}}</b>
    </span>
    <h6>
      Component Preview
    </h6>
    <hr>
  </div>
  <ng-container #componentContainer></ng-container>
</ng-template>

<!-- /COMPONENT TEMPLATE -->

<!-- CONTAINER TEMPLATE TEMPLATE -->

<ng-template #containerTemplateTemplate
             let-template>
  <section class="component-definition"
           nz-popover
           nzPopoverPlacement="right"
           nzPopoverOverlayClassName="popover-overlay"
           [nzPopoverContent]="popoverContentTemplate"
           (nzPopoverVisibleChange)="onPopoverVisibilityChange($event,template)">
    <div class="component-title">
      <rappider-text [text]="template.name">
      </rappider-text>
    </div>
    <div class="add-component-button">
      <rappider-button [icon]="addComponentButtonIcon"
                       (click)="onCopyTemplateToPageButtonClick(template)">
      </rappider-button>
    </div>
  </section>
</ng-template>

<!-- /CONTAINER TEMPLATE TEMPLATE -->

<ng-template #noActiveContentTreeItemTemplate>
  <span class="add-component-inside-information">
    Please select a container for add component, or component will be added inside <b>Main Container</b>.
  </span>
</ng-template>
