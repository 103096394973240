import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { QrCodeElementType, QRCodeErrorCorrectionLevel } from '../../utils/qr-code';


@Component({
  selector: 'rappider-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class RappiderQrCodeComponent implements OnInit, OnChanges {

  /**
   * String to encode
   *
   * Default: ''
   * @type {string}
   * @memberof RappiderQrCodeComponent
   */
  @Input() qrData: string;

  /**
   * HTML alt attribute (supported by img, url)
   * 
   * Default: null
   * @type {string}
   * @memberof RappiderQrCodeComponent
   */
  @Input() alt: string;

  /**
   * Allow qrdata to be an empty string
   * 
   * Default: false
   * @type {boolean}
   * @memberof RappiderQrCodeComponent
   */
  @Input() allowEmptyString: boolean;

  /**
   * HTML title attribute (supported by canvas, img, url)
   * 
   * Default: null
   * @type {string}
   * @memberof RappiderQrCodeComponent
   */
  @Input() title: string;

  /**
   * Link to your image
   * 
   * Default: null
   * @type {string}
   * @memberof RappiderQrCodeComponent
   */
  @Input() imageSrc: string;

  /**
   * 'canvas', 'svg', 'img', 'url' (alias for 'img')
   * 
   * Default: null
   * @type {string}
   * @memberof RappiderQrCodeComponent
   */
  @Input() elementType: QrCodeElementType;

  /**
   * height of your image
   *
   * Default: null
   * @type {number}
   * @memberof RappiderQrCodeComponent
   */
  @Input() imageHeight: number;

  /**
   * width of your image
   *
   * Default: null
   * @type {number}
   * @memberof RappiderQrCodeComponent
   */
  @Input() imageWidth: number;

  /**
   * Height/Width (any value)
   *
   * Default: 10
   * @type {number}
   * @memberof RappiderQrCodeComponent
   */
  @Input() width: number;

  /**
   * Define how much wide the quiet zone should be.
   *
   * Default: 4
   * @type {number}
   * @memberof RappiderQrCodeComponent
   */
  @Input() margin: number;

  /**
   * Scale factor. A value of 1 means 1px per modules (black dots).
   *
   * Default: 4
   * @type {number}
   * @memberof RappiderQrCodeComponent
   */
  @Input() scale: number;

  /**
   * QR Correction level ('L', 'M', 'Q', 'H')
   *
   * Default: 'M'
   * @type {string}
   * @memberof RappiderQrCodeComponent
   */
  @Input() errorCorrectionLevel: QRCodeErrorCorrectionLevel;

  /**
   * 1-40
   *
   * Default: auto
   * @type {number}
   * @memberof RappiderQrCodeComponent
   */
  @Input() version: number;

  /**
   * RGBA color, color of dark module (foreground)
   *
   * Default: '#000000ff'
   * @type {string}
   * @memberof RappiderQrCodeComponent
   */
  @Input() colorDark: string;

  /**
   * RGBA color, color of light module (background)
   *
   * Default: '#ffffffff'
   * @type {string}
   * @memberof RappiderQrCodeComponent
   */
  @Input() colorLight: string;

  /**
   * CSS Class
   *
   * Default: 'qrcode'
   * @type {string}
   * @memberof RappiderQrCodeComponent
   */
  @Input() cssClass: string;

  /**
   * Returns the QR Code URL
   *
   * @memberof RappiderQrCodeComponent
   */
  @Output() qrCodeURL = new EventEmitter<SafeUrl>();

  ngOnInit(): void {
    this.initDefaults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initDefaults();
  }

  initDefaults() {
    /* set default values if it is null */
    this.qrData = this.qrData ?? '';
    this.allowEmptyString = this.allowEmptyString ?? false;
    this.elementType = this.elementType ?? QrCodeElementType.Canvas;
    this.width = this.width ?? 10;
    this.margin = this.margin ?? 4;
    this.scale = this.scale ?? 4;
    this.errorCorrectionLevel = this.errorCorrectionLevel ?? QRCodeErrorCorrectionLevel.Medium;
    this.colorDark = this.colorDark ?? '#000000ff';
    this.colorLight = this.colorLight ?? '#ffffffff';
  }

  onQrCodeUrl(event: any) {
    // TODO: It is not written in the document under what conditions it works. Need to search.
    // URL: https://www.npmjs.com/package/angularx-qrcode
  }
}
