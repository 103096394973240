<div class="search">
    <rappider-input-group [(ngModel)]="searchText"
                          [textbox]="inputGroupConfig.textbox"
                          [suffixIcon]="inputGroupConfig.suffixIcon"
                          (ngModelChange)="onSearchTextChange()"></rappider-input-group>
</div>

<ng-container *ngIf="!searchText; else searchResultsTemplate">
    <ng-container *ngFor="let layout of layouts">
        <ng-container *ngTemplateOutlet="cardTemplate;
                       context:{$implicit: layout} ">
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #cardTemplate
             let-item>
    <section nz-tooltip
             [nzTooltipTitle]="item.title"
             class="layout-menu-card">
        <div class="layout-menu-card-title">
            <span>
                {{item.title}}
            </span>
            <nz-tag *ngIf="page.layoutId === item.id"
                    class="m-0">
                {{'Current Layout' | translate}}
            </nz-tag>
        </div>
        <a nz-dropdown
           [nzDropdownMenu]="menu">
            <span nz-icon
                  nzType="ellipsis"></span>
        </a>
        <nz-dropdown-menu #menu>
            <ul nz-menu
                nzSelectable>
                <li nz-menu-item
                    (click)="goToLayoutEditor(item)">Edit Layout in the Editor</li>
                <li *ngIf="page.layoutId !== item.id"
                    nz-menu-item
                    (click)="applyLayout(item)">Set as layout for the current page</li>
                <li *ngIf="page.layoutId === item.id"
                    nz-menu-item
                    (click)="onClickRemoveLayout()">Remove Layout for the current page</li>
            </ul>
        </nz-dropdown-menu>
    </section>
</ng-template>

<ng-template #searchResultsTemplate>
    <nz-collapse class="item-collapse-panel">
        <nz-collapse-panel [nzHeader]="'Search Results in Layouts' | translate"
                           [nzActive]="true">
            <ng-container *ngIf="matchedLayouts.length; else noSearchResultsAvailableTemplate">
                <ng-container *ngFor="let matchedLayout of matchedLayouts">
                    <ng-container *ngTemplateOutlet="cardTemplate;
                                   context:{$implicit: matchedLayout.item} ">
                    </ng-container>
                </ng-container>
            </ng-container>
        </nz-collapse-panel>
    </nz-collapse>
</ng-template>

<ng-template #noSearchResultsAvailableTemplate>
    <div class="no-search-results-available-container">
        <div class="no-search-results-available-animation">
            <rappider-lottie-player [options]="noSearchResultsAvailableAnimationConfig"
                                    width="100%"
                                    height="100%"></rappider-lottie-player>
        </div>
        <rappider-heading class="no-search-results-available-text"
                          type="h6"
                          content="No search results available">
        </rappider-heading>
    </div>
</ng-template>
