import { cloneDeep } from 'lodash';
import {
  addCreatedComponentContentToTree,
  createPageContainer,
  deleteComponent,
  deletePageContainer,
  deleteVisibilityConditionToContainer,
  getChildComponentIds,
  mergeVisibilityConditionToContainer,
  moveContentTreeItem,
  updatePageContainerProperties
} from './content-editor-helper-functions';
import { ContentEditorClipboardData } from '../models/content-editor-clipboard-data.interface';

import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as ContentEditorActions from './content-editor.actions';
import * as ComponentDefinitionActions from 'libs/component-definition/src/lib/state/component-definition.actions';
import * as PageActions from 'libs/pages/src/lib/state/page-state/page.actions';
import { Page, PageWithRelations } from '@rappider/rappider-sdk';

export const contentEditorFeatureKey = 'contentEditor';

export interface State {
  loading: boolean;
  page: PageWithRelations;
  activeContentTreeItemId: string;
  clipboard: ContentEditorClipboardData;
  isContentTreeSaving: boolean;
  lastSavedContentTree: any;
  componentIdsToRemove: string[];
  copiedTemplate: Page;
}

export const initialState: State = {
  loading: false,
  page: null,
  activeContentTreeItemId: null,
  clipboard: null,
  isContentTreeSaving: false,
  lastSavedContentTree: null,
  componentIdsToRemove: [],
  copiedTemplate: null
};

export function reducer(
  state: State = initialState,
  action: ContentEditorActions.Actions
    | ComponentDefinitionActions.Actions
    | AuthenticationActions.Actions
    | PageActions.Actions
): State {
  switch (action.type) {

    case ContentEditorActions.ActionTypes.ContentEditorError: {
      return {
        ...state,
        loading: false
      };
    }

    case ContentEditorActions.ActionTypes.LoadPageWithComponents: {
      return {
        ...state,
        loading: true
      };
    }

    case ContentEditorActions.ActionTypes.LoadPageWithComponentsSuccessful: {
      /* get page from payload */
      const page = action.payload.page['data']
        ? { ...action.payload.page['data'] }
        : { ...action.payload.page };

      return {
        ...state,
        page: page,
        loading: false,
        lastSavedContentTree: page.contentTree
      };
    }

    case ContentEditorActions.ActionTypes.CreateComponent: {
      return {
        ...state,
        loading: false
      };
    }

    case ContentEditorActions.ActionTypes.CreateComponentSuccessful: {
      const contentTree = cloneDeep(state.page.contentTree);
      addCreatedComponentContentToTree(contentTree, action.payload.pageContainerId, action.payload.createdComponent);
      return {
        ...state,
        page: {
          ...state.page,
          components: [
            ...(state.page?.components || []),
            action.payload.createdComponent.component
          ],
          contentTree: contentTree
        },
        loading: false
      };
    }

    case ContentEditorActions.ActionTypes.DeleteComponent: {
      const contentTree = cloneDeep(state.page.contentTree);
      deleteComponent(contentTree, action.payload.componentId);
      return {
        ...state,
        page: {
          ...state.page,
          contentTree: contentTree,
          components: state.page?.components?.filter(component => component.id !== action.payload.componentId) || []
        },
        activeContentTreeItemId: null,
        loading: false,
      };
    }

    case ContentEditorActions.ActionTypes.UpdateComponentInputs: {
      const component = state.page?.components?.find(component => component.id === action.payload.componentId);
      return {
        ...state,
        page: {
          ...state.page,
          components: [
            ...(state.page?.components?.filter(component => component.id !== action.payload.componentId) || []),
            {
              ...component,
              inputs: {
                ...component.inputs,
                ...action.payload.inputs
              }
            }
          ]
        },
        loading: false
      };
    }

    case ContentEditorActions.ActionTypes.UpdateComponentSuccessful: {
      const updatedComponent = state.page.components.find(component => component.id === action.payload.component.id);
      return {
        ...state,
        page: {
          ...state.page,
          components: [
            ...state.page.components.filter(component => component.id !== updatedComponent.id),
            {
              ...updatedComponent,
              ...action.payload.component
            }
          ]
        },
      };
    }

    case ContentEditorActions.ActionTypes.CreatePageContainer: {
      const contentTree = cloneDeep(state.page.contentTree);
      createPageContainer(contentTree, action.payload.pageContainerId, action.payload.pageContainerData);
      return {
        ...state,
        page: {
          ...state.page,
          contentTree: contentTree
        },
      };
    }

    case ContentEditorActions.ActionTypes.CopyContainer: {
      return {
        ...state,
        page: {
          ...state.page,
          components: [
            ...state?.page?.components || [],
            ...action.payload?.components
          ]
        }
      };
    }

    case ContentEditorActions.ActionTypes.UpdatePageContainerProperties: {
      const contentTree = cloneDeep(state.page.contentTree);
      updatePageContainerProperties(contentTree, action.payload.pageContainerId, action.payload);
      return {
        ...state,
        page: {
          ...state.page,
          contentTree: contentTree
        },
        loading: false,
      };
    }

    case ContentEditorActions.ActionTypes.DeletePageContainer: {
      const contentTree = cloneDeep(state.page.contentTree);
      const componentIdsToRemove = deletePageContainer(contentTree, action.payload.pageContainerId, action.payload.removeStrategy);

      return {
        ...state,
        page: {
          ...state.page,
          contentTree: contentTree
        },
        activeContentTreeItemId: null,
        loading: false,
        componentIdsToRemove: [
          ...state.componentIdsToRemove,
          ...componentIdsToRemove
        ]
      };
    }

    case ContentEditorActions.ActionTypes.MoveContentTreeItem: {
      const contentTree = cloneDeep(state.page.contentTree);
      moveContentTreeItem(
        contentTree,
        action.payload.targetPageContainer.id,
        action.payload.targetContentTreeItem.id,
        action.payload.targetIndex
      );
      return {
        ...state,
        page: {
          ...state.page,
          contentTree: contentTree
        },
        loading: false,
      };
    }

    case ContentEditorActions.ActionTypes.SetActiveContentTreeItem: {
      return {
        ...state,
        activeContentTreeItemId: action.payload.contentTreeItemId
      };
    }

    case ContentEditorActions.ActionTypes.UpdatePageCssSuccesful: {
      return {
        ...state,
        page: {
          ...state.page,
          cssStyle: action.payload.css
        }
      };
    }

    case ContentEditorActions.ActionTypes.CopyPage: {
      return {
        ...state,
        loading: true
      };
    }

    case ContentEditorActions.ActionTypes.CopyPageSuccessful: {
      return {
        ...state,
        page: action.payload.page,
        loading: false
      };
    }

    case ContentEditorActions.ActionTypes.CopyTemplateToPageContainer: {
      return {
        ...state,
        loading: true
      };
    }

    case ContentEditorActions.ActionTypes.CopyTemplateToPageContainerSuccessful: {
      return {
        ...state,
        page: {
          ...state.page,
          contentTree: action.payload.contentTree
        },
        loading: false
      };
    }

    case ContentEditorActions.ActionTypes.WriteCopiedTemplateTreeFromLocalStorageToState: {
      return {
        ...state,
        clipboard: action.payload
      };
    }

    case ContentEditorActions.ActionTypes.ResetContentEditorData: {
      return {
        ...state,
        page: null,
        activeContentTreeItemId: null,
        isContentTreeSaving: false,
        lastSavedContentTree: null
      };
    }

    case ContentEditorActions.ActionTypes.UpdateContentTreeOfPage: {
      return {
        ...state,
        isContentTreeSaving: true
      };
    }

    case ContentEditorActions.ActionTypes.UpdateContentTreeOfPageSuccessful: {
      return {
        ...state,
        lastSavedContentTree: action.payload.contentTree,
        isContentTreeSaving: false
      };
    }

    case ContentEditorActions.ActionTypes.CreateContainerVisibilityCondition: {
      return {
        ...state,
        loading: true
      };
    }

    case ContentEditorActions.ActionTypes.CreateContainerVisibilityConditionSuccessful: {
      const contentTree = cloneDeep(state.page.contentTree);
      mergeVisibilityConditionToContainer(contentTree, action.payload.pageContainerId, action.payload.createdVisibilityCondition);
      return {
        ...state,
        loading: false,
        page: {
          ...state.page,
          contentTree: contentTree
        }
      };
    }

    case ContentEditorActions.ActionTypes.UpdateContainerVisibilityCondition: {
      return {
        ...state,
        loading: true
      };
    }

    case ContentEditorActions.ActionTypes.UpdateContainerVisibilityConditionSuccessful: {
      const contentTree = cloneDeep(state.page.contentTree);
      mergeVisibilityConditionToContainer(contentTree, action.payload.pageContainerId, action.payload.updatedVisibilityCondition);
      return {
        ...state,
        loading: false,
        page: {
          ...state.page,
          contentTree: contentTree
        }
      };
    }

    case ContentEditorActions.ActionTypes.DeleteContainerVisibilityCondition: {
      return {
        ...state,
        loading: true
      };
    }

    case ContentEditorActions.ActionTypes.DeleteContainerVisibilityConditionSuccessful: {
      const contentTree = cloneDeep(state.page.contentTree);
      deleteVisibilityConditionToContainer(contentTree, action.payload.pageContainerId);
      return {
        ...state,
        loading: false,
        page: {
          ...state.page,
          contentTree: contentTree
        }
      };
    }

    case ContentEditorActions.ActionTypes.CreateComponentOutputEventSuccessful: {
      const component = state.page.components.find(component => component.id === action.payload.componentId);
      return {
        ...state,
        loading: false,
        page: {
          ...state.page,
          components: [
            ...state.page.components.filter(component => component.id !== action.payload.componentId),
            {
              ...component,
              outputEvents: [
                ...(component.outputEvents || []),
                action.payload.createdOutputEvent
              ]
            }
          ]
        }
      };
    }

    case ContentEditorActions.ActionTypes.UpdateComponentOutputEventSuccessful: {
      const component = state.page.components.find(component => component.id === action.payload.componentId);
      const outputEvent = component.outputEvents.find(event => event.id === action.payload.updatedOutputEventId);
      component.outputEvents = [
        ...component.outputEvents.filter(event => event.id !== action.payload.updatedOutputEventId),
        {
          ...outputEvent,
          ...action.payload.updatedOutputEvent
        }
      ];
      return {
        ...state,
        loading: false,
        page: {
          ...state.page,
          components: [
            ...state.page.components,
            component
          ]
        }
      };
    }

    case ContentEditorActions.ActionTypes.DeleteComponentOutputEventSuccessful: {
      const component = state.page.components.find(component => component.id === action.payload.componentId);
      const updatedComponent = {
        ...component,
        outputEvents: component.outputEvents.filter(event => event.id !== action.payload.eventId)
      };
      return {
        ...state,
        loading: false,
        page: {
          ...state.page,
          components: [
            ...state.page.components.filter(component => component.id !== action.payload.componentId),
            updatedComponent
          ]
        }
      };
    }

    case ContentEditorActions.ActionTypes.SyncPageDataOptimistic: {
      const isContentEditorActive = state.page;
      return isContentEditorActive ? {
        ...state,
        loading: false,
        page: {
          ...state.page,
          ...action.payload.pageData
        }
      } : initialState;
    }

    case ContentEditorActions.ActionTypes.DeletePageComponentsSuccessful: {
      const pageComponents = state.page.components.filter(component => !state.componentIdsToRemove?.includes(component.id));
      return {
        ...state,
        componentIdsToRemove: [],
        page: {
          ...state.page,
          components: pageComponents
        }
      };
    }

    case ComponentDefinitionActions.ActionTypes.ComponentDefinitionError: {
      return {
        ...state,
        loading: false
      };
    }

    case ContentEditorActions.ActionTypes.GetTemplateByIdSuccessful: {
      return {
        ...state,
        copiedTemplate: action.payload.template
      };
    }

    case PageActions.ActionTypes.UpdatePageWithLifecycleActionsSuccessful: {
      const updatedPage = action.payload.page;
      const isContentEditorActive = state.page;
      return isContentEditorActive ? {
        ...state,
        page: {
          ...state.page,
          ...updatedPage
        }
      } : initialState;
    }

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    default: {
      return state;
    }
  }
}
