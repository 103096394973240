<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton">
</rappider-title-toolbar>

<rappider-spin [spinning]="!messageTemplate">
  <section class="text-editor">
    <rappider-crud-view-edit-form [config]="MESSAGE_TEMPLATE_EDIT_CONFIG"
                                  [data]="messageTemplate"
                                  [submitButtonLoading]="submitButtonLoading"
                                  (submitButtonClick)="onSubmitButtonClick($event)"
                                  (formSubmit)="editMessageTemplate($event)">
    </rappider-crud-view-edit-form>
    <rappider-divider></rappider-divider>
    <rappider-list-grid [config]="AVAILABLE_DATA_FIELD_LIST_CONFIG"
                        [data]="availableDataFields"></rappider-list-grid>
  </section>
</rappider-spin>