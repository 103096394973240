import { createAction, props } from '@ngrx/store';
import { CustomFunctionDefinition, CustomFunctionDefinitionPartial, CustomFunctionDefinitionWithRelations, NewCustomFunctionDefinition } from '@rappider/rappider-sdk';

/* action types */
export enum ActionTypes {
  GetCustomFunctions = '[CustomFunction] GetCustomFunctions',
  GetCustomFunctionsSuccessful = '[CustomFunction] GetCustomFunctionsSuccessful',
  GetCustomFunctionsFailure = '[CustomFunction] GetCustomFunctionsFailure',
  GetCustomFunctionById = '[CustomFunction] GetCustomFunctionById',
  GetCustomFunctionByIdSuccessful = '[CustomFunction] GetCustomFunctionByIdSuccessful',
  GetCustomFunctionByIdFailure = '[CustomFunction] GetCustomFunctionByIdFailure',
  GetRappiderCustomFunctions = '[CustomFunction] GetRappiderCustomFunctions',
  GetRappiderCustomFunctionsSuccessful = '[CustomFunction] GetRappiderCustomFunctionsSuccessful',
  GetRappiderCustomFunctionsFailure = '[CustomFunction] GetRappiderCustomFunctionsFailure',
  CreateCustomFunction = '[CustomFunction] CreateCustomFunction',
  CreateCustomFunctionSuccessful = '[CustomFunction] CreateCustomFunctionSuccessful',
  CreateCustomFunctionFailure = '[CustomFunction] CreateCustomFunctionFailure',
  UpdateCustomFunction = '[CustomFunction] UpdateCustomFunction',
  UpdateCustomFunctionSuccessful = '[CustomFunction] UpdateCustomFunctionSuccessful',
  UpdateCustomFunctionFailure = '[CustomFunction] UpdateCustomFunctionFailure',
  DeleteCustomFunction = '[CustomFunction] DeleteCustomFunction',
  DeleteCustomFunctionSuccessful = '[CustomFunction] DeleteCustomFunctionSuccessful',
  DeleteCustomFunctionFailure = '[CustomFunction] DeleteCustomFunctionFailure',
  CustomFunctionInfo = '[CustomFunction] CustomFunctionInfo',
}

export const GetCustomFunctions = createAction(
  ActionTypes.GetCustomFunctions
);

export const GetCustomFunctionsSuccessful = createAction(
  ActionTypes.GetCustomFunctionsSuccessful,
  props<{ customFunctions: CustomFunctionDefinitionWithRelations[] }>()
);

export const GetCustomFunctionsFailure = createAction(
  ActionTypes.GetCustomFunctionsFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

export const GetCustomFunctionById = createAction(
  ActionTypes.GetCustomFunctionById,
  props<{ customFunctionId: string }>()
);

export const GetCustomFunctionByIdSuccessful = createAction(
  ActionTypes.GetCustomFunctionByIdSuccessful,
  props<{ customFunction: CustomFunctionDefinitionWithRelations }>()
);

export const GetCustomFunctionByIdFailure = createAction(
  ActionTypes.GetCustomFunctionByIdFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

export const GetRappiderCustomFunctions = createAction(
  ActionTypes.GetRappiderCustomFunctions,
);

export const GetRappiderCustomFunctionsSuccessful = createAction(
  ActionTypes.GetRappiderCustomFunctionsSuccessful,
  props<{ payload: { customFunctions: CustomFunctionDefinitionWithRelations[] } }>()
);

export const GetRappiderCustomFunctionsFailure = createAction(
  ActionTypes.GetRappiderCustomFunctionsFailure,
  props<{ payload: { error: any; key: string; timestamp: any } }>()
);

export const CreateCustomFunction = createAction(
  ActionTypes.CreateCustomFunction,
  props<{ customFunction: NewCustomFunctionDefinition; navigateAfterSubmit?: boolean }>()
);
export const CreateCustomFunctionSuccessful = createAction(
  ActionTypes.CreateCustomFunctionSuccessful,
  props<{ customFunction: CustomFunctionDefinition; navigateAfterSubmit?: boolean; lastCreatedOrEdited?: CustomFunctionDefinition }>()
);
export const CreateCustomFunctionFailure = createAction(
  ActionTypes.CreateCustomFunctionFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

export const UpdateCustomFunction = createAction(
  ActionTypes.UpdateCustomFunction,
  props<{ id: string; customFunction: CustomFunctionDefinitionPartial; navigateAfterSubmit?: boolean }>()
);
export const UpdateCustomFunctionSuccessful = createAction(
  ActionTypes.UpdateCustomFunctionSuccessful,
  props<{ id: string; customFunction: CustomFunctionDefinition | CustomFunctionDefinitionPartial; navigateAfterSubmit?: boolean }>()
);
export const UpdateCustomFunctionFailure = createAction(
  ActionTypes.UpdateCustomFunctionFailure,
  props<{ error: any; key: string; timestamp: number }>()
);

export const DeleteCustomFunction = createAction(
  ActionTypes.DeleteCustomFunction,
  props<{ id: string }>()
);
export const DeleteCustomFunctionSuccessful = createAction(
  ActionTypes.DeleteCustomFunctionSuccessful,
  props<{ id: string }>()
);
export const DeleteCustomFunctionFailure = createAction(
  ActionTypes.DeleteCustomFunctionFailure,
  props<{ error: any; key: string; timestamp: number }>()
);
export const CustomFunctionInfo = createAction(
  ActionTypes.CustomFunctionInfo,
  props<{ info: string }>()
);
