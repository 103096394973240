import { Validators } from '@angular/forms';
import { ButtonType, CrudFormConfig, CrudViewFormItemType } from '@rappider/rappider-components/utils';
import { KEBAB_CASE_REGEX, PASCAL_CASE_ONLY_LETTER_REGEX } from '@rappider/shared/definitions';


export const MODULE_CREATE_OR_EDIT_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.TITLE',
      fieldName: 'title',
      type: CrudViewFormItemType.TextBox,
      placeholder: 'ex: File Management',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      placeholder: 'ex: FileManagement',
      showRequiredSign: true,
      validators: [
        {
          type: Validators.pattern(PASCAL_CASE_ONLY_LETTER_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_TITLE_CASE_MESSAGE'
        },
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    {
      title: 'SHARED.SLUG',
      fieldName: 'slug',
      placeholder: 'ex: file-management',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.pattern(KEBAB_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_KEBAB_CASE'
        }
      ]
    },
    {
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewFormItemType.TextArea,
    }
  ],
  submitButton: {
    type: ButtonType.Primary
  }
};
