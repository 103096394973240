import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CreateAllDataFields,
  DeleteAllDataFields,
  UpdateAllDataFields
} from 'libs/project/src/lib/states/data-schema/data-schema.actions';
import { DataSchemaFieldRowPages } from '@rappider/shared/interfaces';
import { Subscription } from 'rxjs';
import { DataSchemaElementRowOutput } from '../../../data-schema/components/data-schema-element-row/models/data-schema-element-row';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { PATH_DEFINITIONS, QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { DataSchemaField, DataSchemaFieldBulkUpdateDto, DataSchemaFieldCreateDto, DataSchemaFieldUpdateDto, ProjectWithRelations, UiDataEventWithRelations } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-data-event-payload',
  templateUrl: './data-event-payload.component.html',
  styleUrls: ['./data-event-payload.component.scss']
})
export class DataEventPayloadComponent implements OnInit, OnDestroy {
  @Input() dataEvent: UiDataEventWithRelations;
  /* flag for whether to navigate after data event payload creation */
  @Input() navigateAfterCreate = false;

  activeProject: {
    id: string;
    name: string;
  };
  subscriptions: Subscription[];
  /* data schema field row page type - will be used in filter */
  DataSchemaPageType = DataSchemaFieldRowPages.DataEvent;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [this.subscribeToActiveProject()];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        this.activeProject = {
          id: activeProject.id,
          name: activeProject.name
        };
      }
    });
  }

  onDataSchemaSave(dataSchemaFieldDifferences: DataSchemaElementRowOutput) {
    if (dataSchemaFieldDifferences?.updated?.length) {
      const updatedDataSchemaFields = dataSchemaFieldDifferences.updated.map(updatedField => {
        const { id, ...data } = updatedField;
        return {
          id,
          data: data as DataSchemaFieldUpdateDto
        };
      }) as DataSchemaFieldBulkUpdateDto[];
      this.store.dispatch(
        new UpdateAllDataFields({ updatedDataSchemaFields: updatedDataSchemaFields, parentDataSchemaId: this.dataEvent.payload?.id })
      );
    }
    if (dataSchemaFieldDifferences?.created?.length) {
      const createRequestBody = dataSchemaFieldDifferences.created.map(createdField => ({
        ...createdField,
        parentDataSchemaId: this.dataEvent.payload?.id
      })) as DataSchemaFieldCreateDto[];

      const filteredPayload = createRequestBody.map(obj => {
        // Check all properties except 'typeId', 'parentDataSchemaId', and 'name'
        const { typeId, parentDataSchemaId, name, ...optionalFields } = obj;

        // Create a new object to store the non-null fields
        const filteredObj = { typeId, parentDataSchemaId, name };

        // Check the optional fields and add the non-null ones
        for (const [key, value] of Object.entries(optionalFields)) {
          if (value !== null) {
            filteredObj[key] = value;
          }
        }
        return filteredObj;
      });

      // Dispatch Create Action
      this.store.dispatch(
        new CreateAllDataFields({ dataSchemaFields: filteredPayload, parentDataSchemaId: this.dataEvent.payload?.id })
      );
    }
    if (dataSchemaFieldDifferences?.deleted?.length) {
      const deleteRequestBody = dataSchemaFieldDifferences.deleted.map((item: DataSchemaField) => item.id);
      this.store.dispatch(
        new DeleteAllDataFields({ dataSchemaFieldIds: deleteRequestBody, parentDataSchemaId: this.dataEvent.payload?.id }
        ));
    }
    if (this.navigateAfterCreate) {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.UI_DATA_STORE_DETAIL}/${this.dataEvent.payload?.id}/${QUERY_PARAM_DEFINITIONS.PROJECT.UI_DATA_STORE_DETAIL.EVENTS_TAB}`}));
    }
  }

}
