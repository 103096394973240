/* eslint-disable max-len */

import { ProjectDesignSettingButtons } from './action-button-config';
import { CollapsePanels } from './collapse-panel-config';
import {
  apiEndpointsStepContent,
  apiEndpointsStepIntroductionContent,
  apiEndpointsStepIntroductionExtraContent,
  dataModelsAndDatabaseStepsContent, dataModelsAndDatabaseStepsIntroductionContent,
  dataModelsAndDatabaseStepsIntroductionExtraContent, designSystemStepContent,
  designSystemStepIntroductionContent,
  designSystemStepIntroductionExtraContent,
  environmentVariablesStepContent,
  environmentVariablesStepIntroductionContent,
  environmentVariablesStepIntroductionExtraContent,
  generateProjectCodeStepContent, generateProjectCodeStepIntroductionContent,
  generateProjectCodeStepIntroductionExtraContent, messageTemplatesStepContent,
  messageTemplatesStepIntroductionContent, messageTemplatesStepIntroductionExtraContent,
  projectDescriptionStepContent,
  projectDescriptionStepIntroductionContent,
  projectDescriptionStepIntroductionExtraContent,
  projectInformationStepContent, projectInformationStepIntroductionContent,
  projectInformationStepIntroductionExtraContent, projectMembersStepContent,
  projectMembersStepIntroductionContent, projectMembersStepIntroductionExtraContent, scopeOfWorkStepContent,
  scopeOfWorkStepIntroductionContent,
  scopeOfWorkStepIntroductionExtraContent,
  serverSideBusinessProcessesStepContent, serverSideBusinessProcessesStepIntroductionContent,
  serverSideBusinessProcessesStepIntroductionExtraContent, themeStepContent, themeStepIntroductionContent,
  themeStepIntroductionExtraContent, uiBusinessProcessesStepContent,
  uiBusinessProcessesStepIntroductionContent,
  uiBusinessProcessesStepIntroductionExtraContent,
  uiPageLayoutsStepContent, uiPageLayoutsStepIntroductionContent,
  uiPageLayoutsStepIntroductionExtraContent, uiPagesStepContent,
  uiPagesStepIntroductionContent,
  uiPagesStepIntroductionExtraContent,
  welcomeStepContent,
  welcomeStepIntroductionContent,
  welcomeStepIntroductionExtraContent
} from './wizard-steps-content';
import { IntroductionNextButton } from './wizard-rabbit-introduction-next-button.enum';
import { ProjectDesignTaskIdentifier } from '../state/project-design-task/project-design-task.interface';
import { LayoutMode } from './layoutMode.enum';

export enum WizardStepStatus {
  NotStarted = 'not-started',
  Introduction = 'introduction',
  InProgress = 'in-progress',
  Completed = 'completed',
  Error = 'error',
  ScaffoldedFromTemplate = 'scaffolded-from-template' // This status is used to indicate that the step is scaffolded from a template without AI improvement
}

export enum AIProgressStatus {
  Unknown = 'unknown',
  NotStarted = 'not-started',
  InProgress = 'in-progress',
  Completed = 'completed',
  Error = 'error'
}

export interface WizardStepConfig {
  key: WizardSteps; // Assuming WizardSteps is an enum or type elsewhere in the code
  projectDesignTaskIdentifier?: ProjectDesignTaskIdentifier;
  title: string;
  planTitle?: string;
  description: string;
  introduction?: string;
  introductionExtra?: string;
  collapsePanel: CollapsePanels; // Assuming CollapsePanels is an enum or type elsewhere in the code
  visiblePanels?: CollapsePanels[]; // Array of CollapsePanels
  icon: {
    name: string;
  };
  highlightedElements: string[];
  isCompleted: boolean;
  status?: WizardStepStatus; // Assuming WizardStepStatus is an enum or type elsewhere in the code
  disableNextButton?: boolean;
  skippable?: boolean;
  layoutPaths?: Record<string, string>;
  layoutMode?: string;
  actionConfigs?: Record<string, any>;
  introductionNextButtonTitle?: string; // Title of the next button in the introduction (rabbit) content
  displayInPlanningTimeline?: boolean;
  autoSkipDurationOnPlanning?: number; // Duration in milliseconds after which the step should be auto-skipped in planning/introduction
  autoSkipDurationOnDesignView?: number; // Duration in milliseconds after which the step should be auto-skipped in design view
}


export enum WizardSteps {
  Welcome = 'welcome',
  ProjectDescription = 'projectDescription',
  ProjectInformation = 'projectInformation',
  ScopeOfWork = 'scopeOfWork',
  DataModelsAndDatabase = 'dataModelsAndDatabase',
  APIEndpoints = 'apiEndpoints',
  UIPages = 'uiPages',
  UIPageLayouts = 'uiPageLayouts',
  Theme = 'theme',
  DesignSystem = 'designSystem',
  UIBusinessProcesses = 'uiBusinessProcesses',
  MessageTemplates = 'messageTemplates',
  ServerSideBusinessProcesses = 'serverSideBusinessProcesses',
  SettingsVariablesAndEnvironments = 'settingsVariablesAndEnvironments',
  GenerateProjectCode = 'generateProjectCode',
  VersionsAndDeployments = 'versionsAndDeployments',
  EnjoyYourProject = 'enjoyYourProject',
  ProjectMembers = 'projectMembers',
  Deploy = 'deploy',
  Server = 'server',
  ShareApplication = 'shareApplication',
}

/* Steps */
// 1. Welcome
// 2. Project Description
// 3. Project Information
// 4. Project Members
// 5. Scope of Work
// 6. Data Models and Database
// 7. API Endpoints
// 8. Design System
// 9. Theme
// 10. UI Page Layouts
// 11. UI Pages
// 12. UI Business Processes
// 13. Message Templates
// 14. Server Side Business Processes
// 15. Settings, Variables and Environments
// 16. Generate Project Code
// 17. Versions & Deployments
// 18. Enjoy Your Project


export const WIZARD_STEPS_CONFIG: WizardStepConfig[] = [
  /* 1 Welcome */
  {
    key: WizardSteps.Welcome,
    title: 'Welcome', // Title of the step
    planTitle: 'Generate a plan to create a full-stack web application code from your description', // Title of the timeline plan card
    description: welcomeStepContent, // Description of the step, displayed in the step card
    introduction: welcomeStepIntroductionContent, // Introduction content displayed with rabbit
    introductionExtra: welcomeStepIntroductionExtraContent, // Extra content displayed with rabbit, under the introduction content
    collapsePanel: CollapsePanels.ProjectTitle,
    icon: {
      name: 'fas fa-sparkles'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.Introduction,
    layoutPaths: {
      codePath: 'backend/src/application.ts'
    },
    layoutMode: LayoutMode.SourceCode,
    introductionNextButtonTitle: IntroductionNextButton.LetsGo,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* 2 ProjectDescription */
  {
    key: WizardSteps.ProjectDescription,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.ImproveProjectDescription,
    title: 'Let\'s Improve Your Project Description',
    planTitle: 'Improve your project description with AI', // Title of the timeline plan card
    description: projectDescriptionStepContent,
    introduction: projectDescriptionStepIntroductionContent,
    introductionExtra: projectDescriptionStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.ProjectDescription,
    icon: {
      name: 'fas fa-lightbulb'
    },
    highlightedElements: [ProjectDesignSettingButtons.ImproveProjectWithAI],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    disableNextButton: true,
    skippable: true,
    layoutPaths: {
      codePath: 'backend/src/application.ts'
    },
    layoutMode: LayoutMode.SourceCode,
    introductionNextButtonTitle: IntroductionNextButton.ProjectDescription,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* 3 ProjectInformation */
  {
    key: WizardSteps.ProjectInformation,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.UpdateProjectInformation,
    title: 'Your Project Information',
    planTitle: 'Generate your stack and scaffolding', // Title of the timeline plan
    description: projectInformationStepContent,
    introduction: projectInformationStepIntroductionContent,
    introductionExtra: projectInformationStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.ProjectInformation,
    icon: {
      name: 'fas fa-users'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutPaths: {
      codePath: 'backend/src/application.ts'
    },
    layoutMode: LayoutMode.SourceCode,
    introductionNextButtonTitle: IntroductionNextButton.ProjectInformation,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* ScopeOfWork */
  {
    key: WizardSteps.ScopeOfWork,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateScopeOfWork,
    title: 'Generate Scope of Work',
    planTitle: 'Generate a scope of work for your project', // Title of the timeline plan
    description: scopeOfWorkStepContent,
    introduction: scopeOfWorkStepIntroductionContent,
    introductionExtra: scopeOfWorkStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.ScopeOfWork,
    icon: {
      name: 'fas fa-file-alt'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutPaths: {
      designPath: '/scope-of-work'
    },
    layoutMode: LayoutMode.ProjectDesign,
    introductionNextButtonTitle: IntroductionNextButton.ScopeOfWork,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* DataModelsAndDatabase */
  {
    key: WizardSteps.DataModelsAndDatabase,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateDataModelsAndDatabase,
    title: 'Generate Data Models and Database',
    planTitle: 'Generate data models and data schemas and initialize the database', // Title of the timeline plan
    description: dataModelsAndDatabaseStepsContent,
    introduction: dataModelsAndDatabaseStepsIntroductionContent,
    introductionExtra: dataModelsAndDatabaseStepsIntroductionExtraContent,
    collapsePanel: CollapsePanels.DataModelsAndDatabase,
    icon: {
      name: 'fas fa-database'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutPaths: {
      designPath: '/db-diagram-editor'
    },
    layoutMode: LayoutMode.ProjectDesign,
    introductionNextButtonTitle: IntroductionNextButton.DataModelsAndDatabase,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* APIEndpoints */
  {
    key: WizardSteps.APIEndpoints,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateAPIEndpoints,
    title: 'Generate API Endpoints',
    planTitle: 'Generate API endpoints and services', // Title of the timeline plan
    description: apiEndpointsStepContent,
    introduction: apiEndpointsStepIntroductionContent,
    introductionExtra: apiEndpointsStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.APIEndpoints,
    icon: {
      name: 'fas fa-cogs'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutMode: LayoutMode.SourceCode,
    introductionNextButtonTitle: IntroductionNextButton.APIEndpoints,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* DesignSystem */
  {
    key: WizardSteps.DesignSystem,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateDesignSystem,
    title: 'Generate Your UI Design System',
    planTitle: 'Generate UI design system, package the components', // Title of the timeline plan
    description: designSystemStepContent,
    introduction: designSystemStepIntroductionContent,
    introductionExtra: designSystemStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.DesignSystem,
    icon: {
      name: 'fas fa-swatchbook'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutMode: LayoutMode.ProjectDesign,
    introductionNextButtonTitle: IntroductionNextButton.DesignSystem,
    layoutPaths: {
      designPath: '/component-showroom'
    },
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* Theme */
  {
    key: WizardSteps.Theme,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateTheme,
    title: 'Generate UI Application Theme',
    planTitle: 'Generate UI application theme and styles, colors and sizes', // Title of the timeline plan
    description: themeStepContent,
    introduction: themeStepIntroductionContent,
    introductionExtra: themeStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.Theme,
    icon: {
      name: 'fas fa-paint-brush'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutMode: LayoutMode.ProjectDesign,
    introductionNextButtonTitle: IntroductionNextButton.Theme,
    layoutPaths: {
      designPath: '/projects/theme-settings/{{activeProjectThemeId}}'
    },
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* UIPageLayouts */
  {
    key: WizardSteps.UIPageLayouts,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateUIPageLayouts,
    title: 'Generate UI Application Layouts',
    planTitle: 'Generate UI application layouts and wireframes', // Title of the timeline
    description: uiPageLayoutsStepContent,
    introduction: uiPageLayoutsStepIntroductionContent,
    introductionExtra: uiPageLayoutsStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.UIPageLayouts,
    icon: {
      name: 'fas fa-columns'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutMode: LayoutMode.ProjectDesign,
    introductionNextButtonTitle: IntroductionNextButton.UIPageLayouts,
    layoutPaths: {
      designPath: '/content-editor/{{activePageLayoutId}}'
    },
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* UIPages */
  {
    key: WizardSteps.UIPages,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateUIPages,
    title: 'Generate UI Application Pages',
    planTitle: 'Generate UI application pages and contents', // Title of the timeline
    description: uiPagesStepContent,
    introduction: uiPagesStepIntroductionContent,
    introductionExtra: uiPagesStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.UIPages,
    icon: {
      name: 'fas fa-file-code'
    },
    highlightedElements: [ProjectDesignSettingButtons.NextUIPageButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutMode: LayoutMode.ProjectDesign,
    layoutPaths: {
      designPath: '/content-editor/{{activePageId}}',
    },
    introductionNextButtonTitle: 'Generate Client Application Pages',
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* UIBusinessProcesses */
  {
    key: WizardSteps.UIBusinessProcesses,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateUIBusinessProcesses,
    title: 'Generate Event-Based UI Architecture',
    planTitle: 'Generate event-based UI architecture and workflows and wire the data from database to ui elements using the apis', // Title of the timeline
    description: uiBusinessProcessesStepContent,
    introduction: uiBusinessProcessesStepIntroductionContent,
    introductionExtra: uiBusinessProcessesStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.UIBusinessProcesses,
    icon: {
      name: 'fas fa-network-wired'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutMode: LayoutMode.ProjectDesign,
    layoutPaths: {
      designPath: '/ui-workflow-diagram'
    },
    introductionNextButtonTitle: IntroductionNextButton.UIBusinessProcesses,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* 13 MessageTemplates */
  {
    key: WizardSteps.MessageTemplates,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateMessageTemplates,
    title: 'Generate Message & Document Templates',
    planTitle: 'Generate message and document templates', // Title of the timeline
    description: messageTemplatesStepContent,
    introduction: messageTemplatesStepIntroductionContent,
    introductionExtra: messageTemplatesStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.MessageTemplates,
    icon: {
      name: 'fas fa-envelope'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutMode: LayoutMode.ProjectDesign,
    layoutPaths: {
      designPath: 'projects/edit-message-template/{{activeMessageTemplateId}}'
    },
    introductionNextButtonTitle: IntroductionNextButton.MessageTemplates,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* ServerSideBusinessProcesses */
  {
    key: WizardSteps.ServerSideBusinessProcesses,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateServerSideBusinessProcesses,
    title: 'Generate Micro Services and Workflows',
    planTitle: 'Generate micro services and workflows', // Title of the timeline
    description: serverSideBusinessProcessesStepContent,
    introduction: serverSideBusinessProcessesStepIntroductionContent,
    introductionExtra: serverSideBusinessProcessesStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.ServerSideBusinessProcesses,
    icon: {
      name: 'fas fa-calendar-plus'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutMode: LayoutMode.ProjectDesign,
    layoutPaths: {
      designPath: '/workflow-diagram'
    },
    introductionNextButtonTitle: IntroductionNextButton.ServerSideBusinessProcesses,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* SettingsVariablesAndEnvironments */
  {
    key: WizardSteps.SettingsVariablesAndEnvironments,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateSettingsVariablesAndEnvironments,
    title: 'Generate Environments and Variables',
    planTitle: 'Generate settings, environments and environment variables', // Title of the timeline
    description: environmentVariablesStepContent,
    introduction: environmentVariablesStepIntroductionContent,
    introductionExtra: environmentVariablesStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.SettingsVariablesAndEnvironments,
    icon: {
      name: 'fas fa-code'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: true,
    layoutMode: LayoutMode.SourceCode,
    layoutPaths: {
      codePath: 'loopback4/environments/dev.env'
    },
    introductionNextButtonTitle: IntroductionNextButton.SettingsVariablesAndEnvironments,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* GenerateProjectCode */
  {
    key: WizardSteps.GenerateProjectCode,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.GenerateProjectCode,
    title: 'Your Applications and Code is Ready!',
    planTitle: 'Verify and generate the remaining code...', // Title of the timeline
    description: generateProjectCodeStepContent,
    introduction: generateProjectCodeStepIntroductionContent,
    introductionExtra: generateProjectCodeStepIntroductionExtraContent,
    collapsePanel: CollapsePanels.GenerateProjectCode,
    icon: {
      name: 'fas fa-code'
    },
    highlightedElements: [ProjectDesignSettingButtons.WizardNextButton],
    isCompleted: false,
    status: WizardStepStatus.NotStarted,
    skippable: false,
    layoutMode: LayoutMode.ProjectDesign,
    layoutPaths: {
      designPath: '/projects/version-detail/{{latestProjectVersionId}}'
    },
    introductionNextButtonTitle: IntroductionNextButton.GenerateProjectCode,
    displayInPlanningTimeline: true,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* AFTER ShareApplication */
  {
    key: WizardSteps.ShareApplication,
    projectDesignTaskIdentifier: ProjectDesignTaskIdentifier.ShareApplication,
    title: 'Share Application',
    planTitle: 'Share Application',
    description: '',
    introduction: '',
    introductionExtra: '',
    collapsePanel: CollapsePanels.ShareApplication,
    icon: {
      name: 'fas fa-users'
    },
    highlightedElements: [ProjectDesignSettingButtons.InviteOthers],
    isCompleted: true,
    status: WizardStepStatus.ScaffoldedFromTemplate,
    disableNextButton: false,
    skippable: true,
    layoutPaths: {
      designPath: '/complete-wizard'
    },
    layoutMode: 'projectDesign',
    displayInPlanningTimeline: false,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* AFTER ProjectMembers */
  {
    key: WizardSteps.ProjectMembers,
    title: 'Invite Others to Your Project',
    description: '',
    introduction: '',
    introductionExtra: '',
    collapsePanel: CollapsePanels.ProjectMembers,
    icon: {
      name: 'fas fa-users'
    },
    highlightedElements: [ProjectDesignSettingButtons.InviteOthers],
    isCompleted: true,
    status: WizardStepStatus.ScaffoldedFromTemplate,
    disableNextButton: false,
    skippable: false,
    layoutPaths: {
      designPath: '/projects/members-list'
    },
    layoutMode: LayoutMode.ProjectDesign,
    introductionNextButtonTitle: IntroductionNextButton.ProjectMembers,
    displayInPlanningTimeline: false,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
    /* AFTER Deploy */
  {
    key: WizardSteps.Deploy,
    title: 'Deploy Your Project',
    description: '',
    introduction: '',
    introductionExtra: '',
    collapsePanel: CollapsePanels.Deploy,
    icon: {
      name: 'fas fa-users'
    },
    highlightedElements: [ProjectDesignSettingButtons.InviteOthers],
    isCompleted: true,
    status: WizardStepStatus.ScaffoldedFromTemplate,
    disableNextButton: false,
    skippable: true,
    layoutPaths: {
      designPath: '/projects/live-preview/{{projectId}}'
    },
    layoutMode: 'projectDesign',
    displayInPlanningTimeline: false,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  },
  /* AFTER Server */
  {
    key: WizardSteps.Server,
    title: 'Server',
    description: '',
    introduction: '',
    introductionExtra: '',
    collapsePanel: CollapsePanels.Server,
    icon: {
      name: 'fas fa-users'
    },
    highlightedElements: [ProjectDesignSettingButtons.InviteOthers],
    isCompleted: true,
    status: WizardStepStatus.ScaffoldedFromTemplate,
    disableNextButton: false,
    skippable: true,
    layoutPaths: {
      designPath: '/server-page'
    },
    layoutMode: 'projectDesign',
    displayInPlanningTimeline: false,
    autoSkipDurationOnPlanning: 5000,
    autoSkipDurationOnDesignView: 5000
  }
];

