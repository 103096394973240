import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProjectModelRelation, ProjectModelRelationControllerService, ProjectModelRelationUpdateDto } from '@rappider/rappider-sdk';
import { NotificationService } from '@rappider/services';
import { QUERY_PARAM_DEFINITIONS } from '@rappider/shared/definitions';
import { PATH_DEFINITIONS } from 'libs/shared/src/lib/definitions/path-definition';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import * as ProjectModelRelationActions from './project-model-relation.actions';
import * as ProjectModelActions from '../project-model-state/project-model.actions';
import * as DataSchemaActions from '../data-schema/data-schema.actions';

@Injectable()
export class ProjectModelRelationEffects {

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private projectModelRelationApi: ProjectModelRelationControllerService,
    private notificationService: NotificationService,
    private router: Router
  ) { }


  getAllProjectModelRelations = createEffect(
    () => this.actions$.pipe(
      ofType<ProjectModelRelationActions.GetAllProjectModelRelations>(
        ProjectModelRelationActions.ActionTypes.GetAllProjectModelRelations
      ),
      mergeMap((action) => {
        const params = {
          filter: {
            include: [
              'sourceModel',
              'targetModel',
              'throughModel'
            ]
          }
        } as any;
        return this.projectModelRelationApi.find(params).pipe(
          mergeMap((projectModelRelations: ProjectModelRelation[]) => [
            new ProjectModelRelationActions.GetAllProjectModelRelationsSuccessful({ projectModelRelations: projectModelRelations })
          ]), catchError(err => ([new ProjectModelRelationActions.GetProjectModelRelationsFailure()]))
        );
      }
      )
    )
  );

  getProjectModelRelations = createEffect(
    () => this.actions$.pipe(
      ofType<ProjectModelActions.GetProjectModelsSuccessful>(
        ProjectModelActions.ActionTypes.GetProjectModelsSuccessful
      ),
      mergeMap((action) => {
        const params = {
          filter: {
            include: [
              'sourceModel',
              'targetModel',
              'throughModel'
            ]
          }
        } as any;
        const sourceModelIds = action.payload.projectModels.map(projectModel => ({ sourceModelId: projectModel.id }));
        if (sourceModelIds.length) {
          params.filter = {
            where: { or: sourceModelIds },
            ...params.filter
          };
        }
        return this.projectModelRelationApi.find(params).pipe(
          mergeMap((projectModelRelations: ProjectModelRelation[]) => [
            new ProjectModelRelationActions.GetProjectModelRelationsSuccessful({ projectModelRelations: projectModelRelations })
          ]), catchError(err => ([new ProjectModelRelationActions.GetProjectModelRelationsFailure()]))
        );
      }
      )
    )
  );

  createProjectModelRelation = createEffect(
    () => this.actions$.pipe(
      ofType<ProjectModelRelationActions.CreateProjectModelRelation>(
        ProjectModelRelationActions.ActionTypes.CreateProjectModelRelation
      ),
      switchMap((action) => {
        const projectModelRelation = action.payload.projectModelRelation;
        return this.projectModelRelationApi.create({ body: projectModelRelation }).pipe(
          switchMap((projectModelRelations: ProjectModelRelation[]) => {
            this.notificationService.createNotification(
              'success',
              'SHARED.SUCCESSFUL',
              'PROJECT_MODULE.PROJECT_MODEL_RELATION_CREATE_COMPONENT.MODEL_RELATION_SUCCESSFULY_CREATED'
            );
            if (action.payload.navigateAfterCreate) {
              this.router.navigate(
                [`${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${action.payload.sourceModelId}`],
                { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.FIELDS_TAB }
              );
            }
            return [
              new ProjectModelRelationActions.CreateProjectModelRelationSuccessful({ projectModelRelations }),
              new DataSchemaActions.GetDataSchemaById({ id: action.payload.relationedTypeId }),
              new ProjectModelActions.UpdateProjectModelWithRelations({ projectModelRelations: projectModelRelations })
            ];
          }), catchError((error) => {
            this.notificationService.createNotification(
              'error',
              projectModelRelation?.name,
              error?.error?.error?.message
            );

            return [
              new ProjectModelRelationActions.CreateProjectModelRelationFailure(),
              new ProjectModelRelationActions.ErrorAction({ error: error, key: 'ProjectModelRelationCreate', timestamp: Date.now() })
            ];
          })
        );
      })
    )
  );

  updateProjectModelRelation = createEffect(
    () => this.actions$.pipe(
      ofType<ProjectModelRelationActions.UpdateProjectModelRelation>(
        ProjectModelRelationActions.ActionTypes.UpdateProjectModelRelation
      ),
      withLatestFrom(
        this.store.select(state => state.projectModelRelation?.data)
      ),
      mergeMap(([action, projectModelRelations]) => {
        /* unedited project model relation data */
        const projectModelRelation = projectModelRelations.find(
          modelRelation => modelRelation.id === action.payload.projectModelRelationId
        ) as ProjectModelRelation;

        const editedProjectModelRelation: ProjectModelRelationUpdateDto = {
          name: action.payload.projectModelRelation.name
        };
        const sourceModelId = projectModelRelation.sourceModelId;

        /* delete name field from request body if it's not changed */
        if (editedProjectModelRelation.name === projectModelRelation.name) {
          delete editedProjectModelRelation.name;
        }

        const params = {
          id: action.payload.projectModelRelationId,
          body: {
            ...editedProjectModelRelation
          }
        };

        return this.projectModelRelationApi.updateById(params).pipe(
          mergeMap(() => {
            this.notificationService.createNotification(
              'success',
              params.body.name,
              'PROJECT_MODULE.PROJECT_MODEL_RELATION_EDIT_COMPONENT.MODEL_RELATION_SUCCESSFULY_UPDATED'
            );
            // TODO: needs navigate action
            this.router.navigate(
              [`${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_DATA_FIELD_LIST}/${sourceModelId}`],
              { queryParams: QUERY_PARAM_DEFINITIONS.PROJECT.DATA_FIELD_LIST_COMPONENT.FIELDS_TAB }
            );
            return [
              new ProjectModelRelationActions.UpdateProjectModelRelationSuccessful(
                { projectModelRelationId: params.id, projectModelRelation }
              )
            ];
          }), catchError(error => {
            this.notificationService.createNotification(
              'error',
              params.body.name,
              'PROJECT_MODULE.PROJECT_MODEL_RELATION_EDIT_COMPONENT.MODEL_RELATION_COULDNT_UPDATED'
            );

            return [
              new ProjectModelRelationActions.UpdateProjectModelRelationFailure(),
              new ProjectModelRelationActions.ErrorAction({ error: error, key: 'ProjectModelRelationUpdate', timestamp: Date.now() })
            ];
          })
        );
      })
    )
  );

  deleteProjectModelRelation = createEffect(
    () => this.actions$.pipe(
      ofType<ProjectModelRelationActions.DeleteProjectModelRelation>(
        ProjectModelRelationActions.ActionTypes.DeleteProjectModelRelation
      ),
      withLatestFrom(
        this.store.select(state => state.projectModelRelation?.data)
      ),
      mergeMap(([action, projectModelRelations]) => {
        const params = {
          id: action.payload.projectModelRelationId
        };

        const deletedProjectModelRelation = projectModelRelations
          .find(projectModelRelation => projectModelRelation.id === action.payload.projectModelRelationId);

        return this.projectModelRelationApi.deleteById(params).pipe(
          mergeMap(() => {
            this.notificationService.createNotification(
              'success',
              deletedProjectModelRelation.name,
              'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.RELATION_SUCCESSFULLY_DELETED'
            );

            return [
              new ProjectModelRelationActions.DeleteProjectModelRelationSuccessful({ projectModelRelationId: params.id })
            ];
          }), catchError(error => {
            this.notificationService.createNotification(
              'error',
              deletedProjectModelRelation?.name,
              'PROJECT_MODULE.PROJECT_MODEL_RELATION_LIST_COMPONENT.RELATION_COULDNOT_DELETED'
            );

            return [
              new ProjectModelRelationActions.DeleteProjectModelRelationFailure(),
              new ProjectModelRelationActions.ErrorAction({ error: error, key: 'ProjectModelRelationDelete', timestamp: Date.now() })
            ];
          })
        );
      })
    )
  );
}
