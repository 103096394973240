import { DEFAULT_PAGINATION } from '@rappider/shared/definitions';
import { Pagination } from '@rappider/shared/interfaces';
import * as ProjectRoleActions from './project-role.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ProjectRoleFeatureKey = 'projectRole';

export interface State {
  data: any[];
  pagination: Pagination;
  loading: boolean;
}

export const initialState: State = {
  data: null,
  pagination: {
    currentPageNumber: DEFAULT_PAGINATION.ACTIVE_PAGE_NUMBER,
    pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
    totalCount: null,
    totalPageNumber: null
  },
  loading: false
};

export function reducer(
  state: State = initialState,
  action: ProjectRoleActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case ProjectRoleActions.ActionTypes.EnableProjectRolesLoading:
      return {
        ...state,
        loading: true
      };

    case ProjectRoleActions.ActionTypes.DisableProjectRolesLoading:
      return {
        ...state,
        loading: false
      };

    case ProjectRoleActions.ActionTypes.SetProjectRoles:
      return {
        ...state,
        data: action.payload.projectRoles
      };

    case ProjectRoleActions.ActionTypes.SetPagination:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination
        }
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful:
      return initialState;

    default:
      return state;
  }
}
