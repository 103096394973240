import { createAction, createReducer, on } from '@ngrx/store';
import { WorkflowStepFunctionPublishedEventOnFailureWithRelations } from '@rappider/rappider-sdk';

import * as WorkflowStepFunctionPublishedEventOnFailureActions from './workflow-step-function-published-event-on-failure.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'workflowStepFunctionPublishedEventOnFailure';

/* state interface */
export interface WorkflowStepFunctionPublishedEventOnFailureState {
  data: WorkflowStepFunctionPublishedEventOnFailureWithRelations[];
  isLoading: boolean;
  error: any;
  isDataFetched: boolean;
}

/* initial values */
export const initialState: WorkflowStepFunctionPublishedEventOnFailureState = {
  data: [],
  isLoading: false,
  error: null,
  isDataFetched: false
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(WorkflowStepFunctionPublishedEventOnFailureActions.CreateWorkflowStepFunctionPublishedEventOnFailure, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.CreateWorkflowStepFunctionPublishedEventOnFailureSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      action.payload.workflowStepFunctionPublishedEventOnFailure
    ],
    isLoading: false,
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.AddWorkflowStepFunctionPublishedEventOnFailureSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      ...action.payload.workflowStepFunctionPublishedEventOnFailure
    ],
    isLoading: false,
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.CreateWorkflowStepFunctionPublishedEventOnFailureFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.GetWorkflowStepFunctionPublishedEventOnFailure, (state, action) => ({
    ...state,
    isLoading: true,
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.GetWorkflowStepFunctionPublishedEventOnFailureSuccessful, (state, action) => ({
    ...state,
    data: action.payload.workflowStepFunctionPublishedEventOnFailures,
    isLoading: false,
    isDataFetched: true
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.UpdateWorkflowStepFunctionPublishedEventOnFailure, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.UpdateWorkflowStepFunctionPublishedEventOnFailureSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(workflowStepFunctionPublishedEventOnFailure => workflowStepFunctionPublishedEventOnFailure.id !== action.payload.workflowStepFunctionPublishedEventOnFailureBody.id),
      action.payload.workflowStepFunctionPublishedEventOnFailureBody
    ],
    isLoading: false
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.UpdateWorkflowStepFunctionPublishedEventOnFailureFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.DeleteWorkflowStepFunctionPublishedEventOnFailure, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.DeleteWorkflowStepFunctionPublishedEventOnFailureSuccessful, (state, action) => ({
    ...state,
    data: state.data?.filter(workflowStepFunctionPublishedEventOnFailure => workflowStepFunctionPublishedEventOnFailure.id !== action.payload.workflowStepFunctionPublishedEventOnFailureId),
    isLoading: false
  })),
  on(WorkflowStepFunctionPublishedEventOnFailureActions.DeleteWorkflowStepFunctionPublishedEventOnFailureFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
