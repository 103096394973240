import { DEFAULT_PAGINATION } from '@rappider/shared/definitions';
import { Pagination } from '@rappider/shared/interfaces';
import * as ProjectMemberActions from './project-member.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ProjectMemberFeatureKey = 'projectMember';

export interface State {
  data: any[];
  pagination: Pagination;
  loading: boolean;
}

export const initialState: State = {
  data: null,
  pagination: {
    currentPageNumber: DEFAULT_PAGINATION.ACTIVE_PAGE_NUMBER,
    pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
    totalCount: null,
    totalPageNumber: null
  },
  loading: false
};

export function reducer(
  state: State = initialState,
  action: ProjectMemberActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {
    case ProjectMemberActions.ActionTypes.GetProjectMembersSuccessful:
      return {
        ...state,
        data: action.payload.projectMembers
      };

    case ProjectMemberActions.ActionTypes.AssignManagerSuccessful: {
      const tempDataForAssign = action.payload.personAuthorities.find(personAuth => personAuth.person.id === action.payload.personId);
      const updatedProjectMemberForAssign = {
        ...tempDataForAssign.person,
        authorities: [tempDataForAssign.role]
      };
      return {
        ...state,
        data: [
          ...state.data.filter(projectMember => projectMember.id !== action.payload.personId),
          updatedProjectMemberForAssign
        ],
        loading: false
      };
    }

    case ProjectMemberActions.ActionTypes.UnassignManagerSuccessful: {
      const tempDataForUnassign = state.data.find(member => member.id === action.payload.personId);
      const updatedProjectMemberForUnassign = {
        ...tempDataForUnassign,
        authorities: []
      };
      return {
        ...state,
        data: [
          ...state.data.filter(projectMember => projectMember.id !== action.payload.personId),
          updatedProjectMemberForUnassign
        ],
        loading: false
      };
    }

    case ProjectMemberActions.ActionTypes.CreateRoles: {
      return {
        ...state,
        loading: true
      };
    }

    case ProjectMemberActions.ActionTypes.CreateRolesSuccessful: {
      const tempDataForNewRoles = state.data.find(member => member.id === action.payload.result[0].personId);
      const updatedProjectMemberForNewRoles = {
        ...tempDataForNewRoles,
        roles: [
          ...tempDataForNewRoles.roles,
          ...action.payload.roles
        ]
      };
      return {
        ...state,
        data: [
          ...state.data.filter(data => data.id !== tempDataForNewRoles.id),
          updatedProjectMemberForNewRoles
        ],
        loading: false
      };
    }

    case ProjectMemberActions.ActionTypes.CreateRolesFailure: {
      return {
        ...state,
        loading: false
      };
    }

    case ProjectMemberActions.ActionTypes.DeleteRoles: {
      return {
        ...state,
        loading: true
      };
    }

    case ProjectMemberActions.ActionTypes.DeleteRolesSuccessful: {
      const dataToUpdate = state.data.find(member => member.id === action.payload.result.personProjectRoles[0].personId);
      const updatedData = {
        ...dataToUpdate,
        roles: dataToUpdate.roles.filter(role => action.payload.result.personProjectRoles.every(pRole => pRole.projectRoleId !== role.id))
      };
      return {
        ...state,
        data: [
          ...state.data.filter(data => data.id !== dataToUpdate.id),
          updatedData
        ],
        loading: false
      };
    }

    case ProjectMemberActions.ActionTypes.DeleteRolesFailure: {
      return {
        ...state,
        loading: false
      };
    }

    case ProjectMemberActions.ActionTypes.UnassignPersonFromProject: {
      return {
        ...state,
        loading: true
      };
    }

    case ProjectMemberActions.ActionTypes.UnassignPersonFromProjectSuccessful: {
      return {
        ...state,
        data: [
          ...state.data.filter(projectMember => projectMember.id !== action.payload.personId)
        ],
        loading: false
      };
    }

    case ProjectMemberActions.ActionTypes.UnassignPersonFromProjectFailure: {
      return {
        ...state,
        loading: false
      };
    }

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful:
      return initialState;

    default:
      return state;
  }
}
