import { Component, OnInit, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';

import { environment } from '@environment';

import { UsernameType, PATH_DEFINITIONS, RAPPIDER_THEME_SETTINGS, AppRightSidebarTab } from '@rappider/shared/definitions';
import { BrowserStorageManagementService, LanguageService, RoutingService, ScrollService } from '@rappider/services';

import { UserInterface, ProjectInterface, PageInterface, Project } from '@rappider/api-sdk';
import { Logout, SendVerifyEmail, SendVerifySms } from 'libs/authentication/src/lib/state/authentication.actions';
import { selectClientLocalStates } from 'libs/shared/src/lib/states/client-local-state/client-local-state.selectors';

import {
  PERSON_EMAIL_VERIFY_ALERT_CONFIG, PERSON_PHONE_NUMBER_VERIFY_ALERT_CONFIG,
  PROJECT_MENU_CONFIG,
  WORKSPACE_FOOTER_MENU,
  WORKSPACE_MENU
} from '@rappider/shared/configs';

import { AlertConfig, Menu, MenuActionBehavior } from '@rappider/rappider-components/utils';
import { ThemeMode } from '@rappider/models';
import { Person, ProjectDesignTask } from '@rappider/rappider-sdk';
import { Navigate, sidebarToggleAnimation } from '@rappider/shared';
import { ChangeAppSearchVisibility, ToggleRightSidebarVisibility } from './state/app.actions';
import { homePricingConfigApp } from './utils/home-pricing-app.config';
import { SubscriptionAlertVisibility } from 'libs/payment/src/lib/state/payment.actions';
import { Permission } from './components/header/utils/admin-dashboard-permissions.enum';
import { TierType } from 'libs/authentication/src/lib/utils/tier-type.enum';
import { WizardStepConfig, WizardStepStatus } from 'libs/project-design/src/lib/configs/wizard-steps';
import { cloneDeep } from 'lodash';
import { ProjectDesignService } from 'libs/project-design/src/lib/services/project-design.service';
import { selectProjectDesignTasks } from 'libs/project-design/src/lib/state/project-design-task/project-design-task.selectors';
import { NextStep } from 'libs/project-design/src/lib/state/project-design-task/project-design-task.actions';
import { ProjectDesignTaskIdentifier } from 'libs/project-design/src/lib/state/project-design-task/project-design-task.interface';
import { LayoutMode } from 'libs/project-design/src/lib/configs/layoutMode.enum';

@Component({
  selector: 'rappider-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    sidebarToggleAnimation
  ]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  /* authentication variables */
  authenticatedUser: UserInterface;
  activePerson: Person;
  activePersonOnboardingCompleted: boolean | undefined;

  PERSON_VERIFICATION_ALERT_CONFIG: AlertConfig;

  isMenuVisible = true;
  isPersonVerified = false;
  isAlertVisible = true;
  isFooterVisible = true;
  isHeaderVisible = true;

  rightSidebarVisibility = false;
  isLayoutsVisible = true;
  isHomePricingVisible = false;
  isHomePricingVisiblePermanently = false;
  isRelativeSplitter = false;

  activeRightSidebarTabName = null;

  noInnerContentPadding = true;
  displayLivePreviewNotification = false;
  pagesMenu = null;
  workSpaceMenu = WORKSPACE_MENU;
  // workSpaceMenuDropdown = WORKSPACE_MENU_DROPDOWN;
  workSpaceFooterMenu = WORKSPACE_FOOTER_MENU;
  rappiderThemeSettings = RAPPIDER_THEME_SETTINGS;
  title = 'rappider';
  subscriptions: Subscription[] = [];
  activeProject: ProjectInterface;
  pages: PageInterface[];
  isWorkSpaceMenuCollapsed = true;
  projectListPath = PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH;
  selectedTheme: ThemeMode = ThemeMode.Dark;
  // page title saved on the route data
  routePageTitle: string;
  /* flag to enable the page title on the header */
  showPageTitle = false;

  projectFeatures = PROJECT_MENU_CONFIG;

  deployAlertConfig = {
    title: '',
    description: '',
    titleIcon: ''
  };

  /* Used to scale app, small:1 , medium: 1.25, large 1,5 */
  defaultScale = 1;

  /**
   * The deployment status indicates whether the initiation of the deployment process
   * was successful or if an error occurred during its commencement;
   * it does not imply the completion or non-completion of the deployment itself.
   * @type {DeployStatus}
   * @memberof AppComponent
   */
  // deployStatus: DeployStatus;
  // DeployStatus = DeployStatus;

  /* active route path */
  activePath: string;

  appSearchVisibility;

  homePricingConfigApp = homePricingConfigApp;

  isCustomizationModalVisible = true;

  splitterLeftContainerSizeDefault = 40; // percentage
  splitterRightContainerSizeDefault = 60; // percentage
  splitterSourceCodeContainerSizeDefault = 50; // percentage
  splitterRouterOutletContainerSizeDefault = 50; // percentage

  splitterLeftContainerSize = this.splitterLeftContainerSizeDefault; // percentage
  splitterRightContainerSize = this.splitterRightContainerSizeDefault; // percentage
  splitterSourceCodeContainerSize = this.splitterSourceCodeContainerSizeDefault; // percentage
  splitterRouterOutletContainerSize = this.splitterRouterOutletContainerSizeDefault; // percentage

  // Display the layout root component on the left side of the screen
  layoutMode: LayoutMode | undefined | null;
  LayoutMode = LayoutMode;

  clientLocalState: Record<string, any> = {};

  // wizard state
  WizardStepStatus = WizardStepStatus;
  wizardStepsConfigs: WizardStepConfig[] | undefined;
  activeWizardStep = 0;

  displayWizardIntroduction$ = new BehaviorSubject<boolean>(false);

  // Displays the rabbit wizard modal
  displayWizardModal = false;
  // Displays the wizard in the project design page
  displayWizard = false;

  projectDesignTasksLoaded = false;
  projectDesignTasks: ProjectDesignTask[] = [];


  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.appSearchVisibility) {
      this.store.dispatch(new ChangeAppSearchVisibility());
    }
    if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'f') {
      this.store.dispatch(new ChangeAppSearchVisibility());
      return false;
    }
  }

  constructor(
    // TODO: Fix type issue below
    private store: Store<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    private router: Router,
    private routingService: RoutingService,
    private scrollService: ScrollService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private browserStorageManagementService: BrowserStorageManagementService,
    private projectDesignService: ProjectDesignService
  ) { }

  ngOnInit(): void {
    console.log('*** AppComponent ngOnInit');
    this.subscribeToData();
    this.setThemeBySystemTheme();
    this.verifyPerson();
  }

  /**
   *
   *
   * @memberof AppComponent
   */
  ngOnDestroy(): void {
    console.log('*** AppComponent ngOnDestroy');
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    console.log('*** AppComponent ngAfterViewInit');
    this.setScale(this.defaultScale);
  }

  /**
   * Init application theme based on the system preference
   * Flow as below
   * 1 - If there is a saved mode in the person data - use this as the initial value
   * 2 - If there is no saved mode, Check for the preferred device theme
   * 3 - If device theme is dark, set the init value to `dark`
   * 4 - Else set the default value to `light`
   */
  setThemeBySystemTheme() {
    console.log('*** AppComponent setThemeBySystemTheme');
    // remove old class
    document.body.classList.remove(this.selectedTheme);
    if (this.activePerson?.preferredTheme || this.browserStorageManagementService.getLocalStorageItem('preferredTheme')) {
      if (this.activePerson?.preferredTheme) {
        this.selectedTheme = this.activePerson.preferredTheme as ThemeMode;
      } else {
        this.selectedTheme = this.browserStorageManagementService.getLocalStorageItem('preferredTheme') as ThemeMode;
      }
      // add new class
      document.body.classList.add(this.selectedTheme);
      this.browserStorageManagementService.setLocalStorageItem('preferredTheme', this.selectedTheme);
    } else {
      // const deviceMode = window.matchMedia('(prefers-color-scheme: dark)');
      // this.selectedTheme = deviceMode.matches ? ThemeMode.Dark : ThemeMode.Light;
      this.selectedTheme = ThemeMode.Dark;
      // add new class
      document.body.classList.add(this.selectedTheme);
      this.browserStorageManagementService.setLocalStorageItem('preferredTheme', this.selectedTheme);

    }
  }

  /**
   * Subscribe all data
   *
   * @memberof AppComponent
   */
  subscribeToData() {
    console.log('*** AppComponent subscribeToData');
    this.subscriptions = [
      this.subscribeToNavigation(),
      this.subscribeToActivePerson(),
      this.subscribeToUser(),
      this.subscribeToActiveProject(),


      this.subscribeToClientLocalState(),
      this.subscribeToProjectDesignTasks(),

      this.subscribeToAppSearchVisibility(),
      this.subscribeToRightSidebarTabName(),
      this.subscribeToRightSidebarVisibility(),
    ];
  }

  updateWorkspaceMenuHomePageItemPath() {
    console.log('*** AppComponent updateWorkspaceMenuHomePageItemPath');
    const projectHomePath = this.activeProject?.id
      ? `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject.id}`
      : PATH_DEFINITIONS.PROJECTS.PROJECT_ROOT;

    const updateMenuItemPath = (menuItem) => {
      if (menuItem) {
        menuItem.redirectUrl = projectHomePath;
      }
    };

    updateMenuItemPath(this.workSpaceMenu.items.find(m => m.key === 'project-home'));
    // updateMenuItemPath(this.workSpaceMenuDropdown.items.find(m => m.key === 'project-home'));

    this.workSpaceMenu = { ...this.workSpaceMenu };
    // this.workSpaceMenuDropdown = { ...this.workSpaceMenuDropdown };
  }

  /**
   * subscribes to router event to get information about path
   *
   * @returns
   * @memberof AppComponent
   */
  subscribeToNavigation() {
    console.log('*** AppComponent subscribeToNavigation');
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isAlertVisible = !(this.routingService.getRouteDataByFieldName('alertVisibility') === false);
        this.isMenuVisible = !(this.routingService.getRouteDataByFieldName('isSideMenuVisible') === false);
        this.isFooterVisible = !(this.routingService.getRouteDataByFieldName('isFooterVisible') === false);
        this.isHeaderVisible = !(this.routingService.getRouteDataByFieldName('isHeaderVisible') === false);
        this.noInnerContentPadding = !!this.routingService.getRouteDataByFieldName('noInnerContentPadding');
        this.displayLivePreviewNotification = !(this.routingService.getRouteDataByFieldName('displayLivePreviewNotification') === false);
        this.isLayoutsVisible = !(this.routingService.getRouteDataByFieldName('isLayoutsVisible') === false);
        this.isRelativeSplitter = !(this.routingService.getRouteDataByFieldName('isRelativeSplitter') === false);
        // this.isHomePricingVisible = this.isHomePricingVisiblePermanently ? (this.routingService.getRouteDataByFieldName('isHomePricingVisible') === false) : false;
        this.activePath = event.url;

        if (this.routingService?.getRouteDataByFieldName('layoutMode')) {
          const routeLayoutMode = this.routingService.getRouteDataByFieldName('layoutMode') as LayoutMode;
          this.setLayoutMode(routeLayoutMode);
        } else {
          this.setSplitterLayout(null);
        }

        let currentRoute = this.activatedRoute.snapshot;
        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }

        // Now, currentRoute is the deepest active child route.
        const data = currentRoute.data;
        this.routePageTitle = data?.title || null;
        this.showPageTitle = data?.showPageTitle;
      }
    });
  }


  setSplitterLayout(layout: LayoutMode | undefined | null) {
    console.log('*** AppComponent setSplitterLayout', layout);
    let newLeftSize: number;
    let newRightSize: number;
    let newSourceCodeSize: number;
    let newRouterOutletSize: number;

    switch (layout) {
      case LayoutMode.Hidden:
        newLeftSize = 0;
        newRightSize = 100;
        newSourceCodeSize = 0;
        newRouterOutletSize = 100;
        break;
      case LayoutMode.ProjectDesign:
        newLeftSize = this.splitterLeftContainerSizeDefault;
        newRightSize = this.splitterRightContainerSizeDefault;
        newSourceCodeSize = 0;
        newRouterOutletSize = 100;
        break;
      case LayoutMode.SourceCode:
        newLeftSize = this.splitterLeftContainerSizeDefault;
        newRightSize = this.splitterRightContainerSizeDefault;
        newSourceCodeSize = 100;
        newRouterOutletSize = 0;
        break;
      case LayoutMode.TPM:
        newLeftSize = this.splitterLeftContainerSizeDefault;
        newRightSize = this.splitterRightContainerSizeDefault;
        newSourceCodeSize = this.splitterSourceCodeContainerSizeDefault;
        newRouterOutletSize = this.splitterRouterOutletContainerSizeDefault;
        break;
      default:
        newLeftSize = 0;
        newRightSize = 100;
        newSourceCodeSize = 0;
        newRouterOutletSize = 100;
        break;
    }

    if (this.splitterLeftContainerSize !== newLeftSize ||
      this.splitterRightContainerSize !== newRightSize ||
      this.splitterSourceCodeContainerSize !== newSourceCodeSize ||
      this.splitterRouterOutletContainerSize !== newRouterOutletSize) {
      this.splitterLeftContainerSize = newLeftSize;
      this.splitterRightContainerSize = newRightSize;
      this.splitterSourceCodeContainerSize = newSourceCodeSize;
      this.splitterRouterOutletContainerSize = newRouterOutletSize;
    }
  }

  subscribeToRightSidebarTabName() {
    console.log('*** AppComponent   subscribeToRightSidebarTabName');
    return this.store.select(state => state.app.rightSidebarTabName).subscribe(rightSidebarTabName => {
      this.activeRightSidebarTabName = rightSidebarTabName;
    });
  }

  subscribeToRightSidebarVisibility() {
    console.log('*** AppComponent subscribeToRightSidebarVisibility');
    return this.store.select(state => state.app.rightSidebarVisibility).subscribe(rightSidebarVisibility => {
      this.rightSidebarVisibility = rightSidebarVisibility;
    });
  }

  subscribeToClientLocalState() {
    return this.store.select(selectClientLocalStates).subscribe(clientLocalState => {
      this.clientLocalState = cloneDeep(clientLocalState);
    });
  }

  /**
   * Subscribe active person
   *
   * @returns
   * @memberof AppComponent
   */
  subscribeToActivePerson() {
    return this.store.select(state => state.auth?.activePerson)
      .subscribe(activePerson => {
        if (activePerson) {
          this.isPersonVerified = activePerson?.isVerified && activePerson?.interest;
          this.activePerson = activePerson;
          this.verifyPerson();
          // set active person to root DOM for Zendesk
          window['activePerson'] = activePerson;
          this.setThemeBySystemTheme();
        }
      });
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      if (!activeProject) {
        this.activeProject = null;
      } else {
        const currentActiveProjectId = this.activeProject?.id;
        if (activeProject?.id && currentActiveProjectId !== activeProject?.id) {
          this.activeProject = cloneDeep(activeProject);
        }
      }
      this.updateWorkspaceMenuHomePageItemPath();
    });
  }

  // subscribeToDeployStatus() {
  //   return this.store.select(state => state.deployManagement?.deployStatus).subscribe(status => {
  //     this.deployStatus = status;
  //     if (this.deployStatus === DeployStatus.Success) {
  //       this.deployAlertConfig = {
  //         title: 'Project Deployment Started',
  //         description: 'Click here to go to live preview page',
  //         titleIcon: 'info-circle'
  //       };
  //     } else {
  //       this.deployAlertConfig = {
  //         title: 'Error',
  //         description: 'There was an error while deploying project.',
  //         titleIcon: 'close-circle'
  //       };
  //     }
  //   });
  // }

  // subscribeToPages() {
  //   return this.store.select(state => state.page?.pages)
  //     .subscribe(pages => {
  //       if (pages) {
  //         this.pages = pages;
  //       }
  //     });
  // }

  subscribeToProjectDesignTasks() {
    return this.store.select(selectProjectDesignTasks).subscribe(projectDesignTasks => {
      if (!projectDesignTasks) {
        this.projectDesignTasksLoaded = false;
        this.wizardStepsConfigs = [];
        this.activeWizardStep = undefined;
      } else {
        this.setProjectDesignTasks(projectDesignTasks);
      }
    });
  }

  setProjectDesignTasks(projectDesignTasks: ProjectDesignTask[]) {
    console.log('* Project Design Tasks:', projectDesignTasks);
    this.projectDesignTasks = projectDesignTasks;
    if (this.projectDesignTasks) {
      // calculate the wizard step configs
      const { activeStepIndex, wizardStepConfig } = this.projectDesignService.calculateWizardStepConfigs(projectDesignTasks);
      this.wizardStepsConfigs = [...wizardStepConfig];
      console.log('* Wizard Steps Configs:', this.wizardStepsConfigs);
      // calculate the active step
      this.activeWizardStep = activeStepIndex;
      this.projectDesignTasksLoaded = true;
      // set design and code path
      const designPath = this.wizardStepsConfigs[this.activeWizardStep]?.layoutPaths?.designPath;
      const stepLayoutMode: LayoutMode = this.wizardStepsConfigs[this.activeWizardStep]?.layoutMode as LayoutMode;
      if (stepLayoutMode) {
        this.setLayoutMode(stepLayoutMode);
      }
      if (designPath) {
        this.navigateTo(designPath);
      }

      // Decide if we should display the wizard;
      // filter the wizard steps by displayInPlanningTimeline field to find planning steps
      // check if the last planning step is completed
      const planningWizardSteps = this.wizardStepsConfigs.filter(step => step.displayInPlanningTimeline);
      this.displayWizard = planningWizardSteps.length && planningWizardSteps[planningWizardSteps.length - 1].status !== WizardStepStatus.Completed;
    } else {
      this.projectDesignTasksLoaded = false;
      this.displayWizard = false;
    }
  }

  subscribeToUser() {
    return this.store.select(state => state.auth)
      .subscribe(auth => {
        this.authenticatedUser = auth.user;
        const activePerson = auth.activePerson;
        this.verifyPerson();
        /* reset person verification alert config */
        this.PERSON_VERIFICATION_ALERT_CONFIG = null;
        if (this.authenticatedUser && activePerson) {
          if (this.authenticatedUser.usernameType === UsernameType.Email) {
            /* set person verification alert config */
            this.PERSON_VERIFICATION_ALERT_CONFIG = activePerson?.interest ? PERSON_EMAIL_VERIFY_ALERT_CONFIG : null;
          } else if (this.authenticatedUser.usernameType === UsernameType.PhoneNumber) {
            /* set person verification alert config */
            this.PERSON_VERIFICATION_ALERT_CONFIG = activePerson?.interest ? PERSON_PHONE_NUMBER_VERIFY_ALERT_CONFIG : null;
          }
        }
        if (auth.isPermissionLoaded && auth.tenant) {
          if (auth.permissions.includes(Permission.Admin)) {
            this.store.dispatch(SubscriptionAlertVisibility({ payload: { subscriptionAlertVisibility: false, subscriptionAlertVisibilityPermanently: false } }));
          } else if (auth?.tenant?.role?.key === TierType.FreeTier) {
            this.store.dispatch(SubscriptionAlertVisibility({ payload: { subscriptionAlertVisibility: true, subscriptionAlertVisibilityPermanently: true } }));
          } else if (auth?.tenant?.role?.key === TierType.BuilderTier) {
            this.store.dispatch(SubscriptionAlertVisibility({ payload: { subscriptionAlertVisibility: false, subscriptionAlertVisibilityPermanently: false } }));
          }
          this.updateWorkspaceFooterMenu(auth.permissions.includes(Permission.Admin));
        }
      });
  }

  subscribeToAppSearchVisibility() {
    return this.store.select(state => state.app.appSearch.visibility)
      .subscribe(visibility => this.appSearchVisibility = visibility);
  }

  verifyPerson() {
    console.log('*** AppComponent verifyPerson');
    if (this.activePerson?.isVerified && this.activePerson?.interest) {
      this.activePersonOnboardingCompleted = true;
    }
  }

  updateWorkspaceFooterMenu(isAdmin: boolean) {
    console.log('*** AppComponent updateWorkspaceFooterMenu');
    const uiPageTemplatesIndex = this.workSpaceFooterMenu.items[0].children.findIndex(
      item => item.key === 'ui-page-templates'
    );
    const languageMenuIndex = this.workSpaceFooterMenu.items.findIndex(
      item => item.key === 'language'
    );

    if (isAdmin && uiPageTemplatesIndex === -1) {
      this.workSpaceFooterMenu.items[0].children.push({
        label: 'UI Page Templates',
        tooltip: 'UI Page Templates',
        key: 'ui-page-templates',
        showLabel: true,
        icon: {
          name: 'fa-thin fa-files'
        },
        actionBehavior: MenuActionBehavior.Emit,
        redirectUrl: PATH_DEFINITIONS.PAGE_TEMPLATES.PAGE_TEMPLATES_LIST_PATH
      });
    } else if (!isAdmin && uiPageTemplatesIndex > -1) {
      this.workSpaceFooterMenu.items[0].children.splice(uiPageTemplatesIndex, 1);
    }

    if (languageMenuIndex > -1 && this.workSpaceFooterMenu.items[languageMenuIndex].children.length < 2) {
      this.workSpaceFooterMenu.items.splice(languageMenuIndex, 1);
    }

    this.workSpaceFooterMenu = { ...this.workSpaceFooterMenu };
  }

  workSpaceMenuItemClicked(clickedMenuItem: Menu) {
    console.log('*** AppComponent workSpaceMenuItemClicked', clickedMenuItem);
    switch (clickedMenuItem.key) {
      case 'english':
        this.languageService.changeLanguage('en');
        break;
      case 'logout':
        this.logout();
        break;
      case 'size-large':
        this.setScale(1.4);
        break;
      case 'size-medium':
        this.setScale(1.2);
        break;
      case 'size-small':
        this.setScale(1);
        break;
      case 'design-view':
        this.setLayoutMode(LayoutMode.ProjectDesign);
        break;
      case 'code-view':
        this.setLayoutMode(LayoutMode.SourceCode);
        break;
      case 'tpm-view':
        this.setLayoutMode(LayoutMode.TPM);
        break;
      case 'ui-elements':
        this.navigateTo(clickedMenuItem.redirectUrl);
        break;
      default:
        if (clickedMenuItem.actionBehavior === MenuActionBehavior.Router) {
          const redirectUrl = clickedMenuItem.redirectUrl.replace(':projectId', this.activeProject?.id || '');
          this.navigateTo(redirectUrl, clickedMenuItem.queryParams);
        }
        break;
    }
  }

  setLayoutMode(layoutMode: LayoutMode | undefined | null) {
    if (this.layoutMode !== layoutMode) {
      console.log('*** AppComponent setLayoutMode: New layout', layoutMode, 'Old layout', this.layoutMode);
      this.layoutMode = layoutMode;
      this.setSplitterLayout(layoutMode);
      // this.setVisibilityOfWizardIntroduction();

    }
  }

  navigateTo(path: string, queryParams?: Record<string, unknown>) {
    // if the path is not the same as the current path, then navigate
    if (this.activePath !== path) {
      this.router.navigate([path], { queryParams: queryParams });
    }
  }

  /* TODO: Move to menu service. This shouldnt be in this component  */
  setMenuTag(menuItem: Menu, content: string, tagColor?: string) {
    console.log('*** AppComponent setMenuTag');
    menuItem.tag = content;
    menuItem.tagColor = tagColor;
  }

  resendVerifyEmailOrSms() {
    if (this.authenticatedUser?.usernameType === UsernameType.Email) {
      this.store.dispatch(new SendVerifyEmail());
      this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.AUTH.ACCOUNT_VERIFICATION_PATH }));
    } else if (this.authenticatedUser.usernameType === UsernameType.PhoneNumber) {
      this.store.dispatch(new SendVerifySms());
      this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.AUTH.VERIFY_PHONE_NUMBER_PATH }));
    }
  }

  onRouteChange() {
    console.log('*** AppComponent onRouteChange');
    /* whenever route changes, page will scroll to top */
    this.scrollService.scrollToTop();
  }

  onMenuToggled(toggled: boolean) {
    console.log('*** AppComponent onMenuToggled');
    this.isWorkSpaceMenuCollapsed = !!toggled;
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  onRightSidebarToggle(tabName: AppRightSidebarTab) {
    this.store.dispatch(new ToggleRightSidebarVisibility());
  }

  setScale(scaleValue) {
    const defaultBodyFontSize = 14;
    const fontScale = scaleValue * defaultBodyFontSize;
    document.documentElement.style.fontSize = `${fontScale}px`;
    // document.getElementById('main-layout').style.transform = `scale(${scaleValue})`;
  }

  pricingCloseButtonClick() {
    this.isHomePricingVisible = false;
    this.isHomePricingVisiblePermanently = false;
    this.store.dispatch(SubscriptionAlertVisibility({ payload: { subscriptionAlertVisibility: false, subscriptionAlertVisibilityPermanently: true } }));
  }

  navigateToPayment() {
    this.router.navigateByUrl('/payment');
  }

  splitDragEnds(event) {
    console.log('splitDragEnds', event);
    if (event?.sizes?.length) {
      this.splitterLeftContainerSize = event.sizes[0];
      this.splitterRightContainerSize = event.sizes[1];
    }
  }

  verticalSplitDragEnds(event) {
    console.log('verticalSplitDragEnds', event);
    if (event?.sizes?.length) {
      this.splitterSourceCodeContainerSize = event.sizes[0];
      this.splitterRouterOutletContainerSize = event.sizes[1];
    }
  }

  onBlueprintManagerCollapseButtonClicked() {
    this.splitterLeftContainerSize = 0;
    this.splitterRightContainerSize = 100;
  }

  onResetLayout() {
    this.splitterLeftContainerSize = this.splitterLeftContainerSizeDefault;
    this.splitterRightContainerSize = this.splitterRightContainerSizeDefault;
    this.splitterSourceCodeContainerSize = this.splitterSourceCodeContainerSizeDefault;
    this.splitterRouterOutletContainerSize = this.splitterRouterOutletContainerSizeDefault;
  }

  onDisplayAllPanelsClicked() {
    this.setSplitterLayout(LayoutMode.TPM);
  }

  onDisplayBlueprintPanelClicked() {
    if (this.splitterLeftContainerSize < this.splitterLeftContainerSizeDefault) {
      this.splitterLeftContainerSize = this.splitterLeftContainerSizeDefault;
      this.splitterRightContainerSize = this.splitterRightContainerSizeDefault;
    }
  }

  onDisplayDesignPanelClicked() {
    this.setLayoutMode(LayoutMode.ProjectDesign);
    this.splitterSourceCodeContainerSize = 0;
    this.splitterRouterOutletContainerSize = 100;
    if (this.splitterRightContainerSize < this.splitterRightContainerSizeDefault) {
      this.splitterLeftContainerSize = this.splitterLeftContainerSizeDefault;
      this.splitterRightContainerSize = this.splitterRightContainerSizeDefault;
    }
  }

  onDisplayCodePanelClicked() {
    this.setLayoutMode(LayoutMode.SourceCode);
    this.splitterSourceCodeContainerSize = 100;
    this.splitterRouterOutletContainerSize = 0;
    if (this.splitterRightContainerSize < this.splitterRightContainerSizeDefault) {
      this.splitterLeftContainerSize = this.splitterLeftContainerSizeDefault;
      this.splitterRightContainerSize = this.splitterRightContainerSizeDefault;
    }
  }

  onWizardModalCancelled() {
    console.log('*** AppComponent onWizardModalCancelled');
    this.displayWizardModal = false;
  }

  onWizardModalNextButtonClicked() {
    console.log('*** AppComponent onWizardModalNextButtonClicked');
    // triggerNextWizardStep()
  }

  onWizardModalTypingsCompleted(event) {
    console.log('*** AppComponent onWizardModalTypingsCompleted', event);

    if (event.typingsCompleted) {
      if (event.wizardStep === this.activeWizardStep && event.projectId === this.activeProject.id) {
        this.triggerNextWizardStep();
      }

    }
  }

  triggerNextWizardStep() {
    this.store.dispatch(NextStep({ payload: { activeProjectId: this.activeProject.id } }));
  }

}

// Disable console logs in no-local environmentÍ
if (environment.production) {
  (function () {
    const originalLog = console.log;
    console.log = function (...args) {
      // do nothing
    };
  })();
}
