import { cloneDeep, orderBy } from 'lodash';
import { createAction, createReducer, on } from '@ngrx/store';
import { ProjectWithRelations } from '@rappider/rappider-sdk';

import * as AdminProjectsActions from './admin-projects.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as PersonActions from '../person-state/person.actions';
import { StateErrorService } from 'libs/shared/src/lib/services/state-error-service/state-error-service';

export const featureKey = 'adminProjects';

export interface State {
  data: ProjectWithRelations[];
  isLoading: boolean;
  projectCount: number;
}

export const initialState: State = {
  data: null,
  isLoading: false,
  projectCount: null
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,

  /* Init Action */
  on(PersonActions.InitAdminDashboard, (state) => ({
    ...state,
    isLoading: true
  })),

  /* get */
  on(AdminProjectsActions.GetAdminProjects, (state) => ({
    ...state,
    isLoading: true
  })),
  on(AdminProjectsActions.GetAdminProjectsSuccessful, (state, action) => ({
    ...state,
    data: action.projects,
    isLoading: false
  })),
  on(AdminProjectsActions.GetAdminProjectsFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  /* delete */
  on(AdminProjectsActions.DeleteAdminProject, (state) => ({
    ...state,
    isLoading: true
  })),
  on(AdminProjectsActions.DeleteAdminProjectSuccessful, (state, action) => ({
    ...state,
    data: state.data.filter(project => project.id !== action.projectId),
    isLoading: false
  })),
  on(AdminProjectsActions.DeleteAdminProjectFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  /* update */
  on(AdminProjectsActions.UpdateAdminProject, (state) => ({
    ...state,
    isLoading: true
  })),
  on(AdminProjectsActions.UpdateAdminProjectSuccessful, (state, action) => {
    const updatedProject = {
      ...cloneDeep(state.data.find(project => project.id === action.projectId)),
      ...action.project
    };
    return {
      ...state,
      data: orderBy([
        ...state.data.filter(project => project.id !== action.projectId),
        updatedProject
      ], 'createdDate', 'asc'),
      isLoading: false
    };
  }
  ),
  on(AdminProjectsActions.UpdateProjectByAdmin, (state) => ({
    ...state,
    isLoading: true
  })),
  on(AdminProjectsActions.UpdateProjectByAdminSuccessful, (state, action) => ({
    ...state,
    data: state.data.map(project => project.id === action.publicProject.id ? { ...project, ...action.publicProject.body } : project),
    isLoading: false
  })),
  on(AdminProjectsActions.UpdateProjectByAdminFailure, (state, action) => ({
    ...StateErrorService.handleFailure(state, action),
    isLoaded: true
  })),
  on(AdminProjectsActions.UpdateAdminProjectFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(AdminProjectsActions.GetPaginationBySearchCountSuccessful, (state, action) => ({
    ...state,
    projectCount: action.count,
  })),
  on(AdminProjectsActions.GetPaginationBySearchCount, (state) => ({
    ...state,
    isLoading: true
  })),
  on(Logout, () => (initialState))
);
