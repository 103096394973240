import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentOutputDefinitionInterface } from '@rappider/api-sdk';
import {
  ComponentOutputDefinition,
  ComponentOutputEventWithRelations,
  ComponentWithRelations,
  DataSchemaControllerService,
  DataTransformationControllerService,
  NewComponentOutputEvent,
  UiDataEventWithRelations,
  UiDataStoreWithRelations
} from '@rappider/rappider-sdk';

import { ButtonSize, SelectableOption, IconType, DropdownMenuComponentConfig, MenuActionBehavior, DropdownMenuTriggerType, DropdownMenuLabelMode } from '@rappider/rappider-components/utils';
import { Subscription } from 'rxjs';

import { getUIDataStoreWithEvents } from './utils/get-ui-data-store-with-events.selector';
import { CreateComponentOutputEvent, DeleteComponentOutputEvent, UpdateComponentOutputEvent } from '../../state/content-editor.actions';
@Component({
  selector: 'rappider-component-event',
  templateUrl: './component-event.component.html',
  styleUrls: ['./component-event.component.scss']
})
export class ComponentEventComponent implements OnInit, OnDestroy {

  @Input() component: ComponentWithRelations;

  uiDataStoreOptions: SelectableOption[] = [];

  subscriptions: Subscription[];

  isAddComponentOutputEventModalVisible = false;

  selectedComponentOutputEvent: NewComponentOutputEvent;

  selectedUIDataStoreId: string;

  uiDataStores: UiDataStoreWithRelations[];

  dataEventOptions: SelectableOption[];

  uiDataEvents: UiDataEventWithRelations[];

  componentOutputEventAddButton = {
    icon: {
      name: 'fa-kit fa-light-inbox-out-circle-plus',
      type: IconType.FontAwesome
    },
    size: ButtonSize.Small
  };

  dropdownMenuConfig: DropdownMenuComponentConfig = {
    triggerType: DropdownMenuTriggerType.Hover,
    labelMode: DropdownMenuLabelMode.StaticLabel,
    icon: {
      name: 'ellipsis',
      type: IconType.NgZorro
    },
    items: [
      {
        key: 'data-transformation',
        label: 'Data Transformation',
        actionBehavior: MenuActionBehavior.Emit,
        icon: {
          name: 'fal fa-database',
          type: IconType.FontAwesome
        },
      },
      {
        key: 'delete',
        label: 'Delete',
        popconfirmTitle: 'CONTENT_EDITOR_MODULE.COMPONENT_OUTPUT_EVENT_COMPONENT.ARE_YOU_SURE_TO_DELETE',
        actionBehavior: MenuActionBehavior.Emit,
        icon: {
          name: 'far fa-trash',
          type: IconType.FontAwesome
        }
      }
    ]
  };

  selectedComponentOutputDefinitionId: string;
  selectedComponentOutputDefinitionTypeId: string;
  dataTransformationId: string;

  /* DT */
  isAddDataTransformationModalVisible = false;
  jsonSchema;
  targetJsonSchema;

  constructor(
    private store: Store<any>,
    private dataTransformationApi: DataTransformationControllerService,
    private dataSchemaApi: DataSchemaControllerService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToUIDataStores()
    ];
  }

  subscribeToUIDataStores() {
    return this.store.select(<any>getUIDataStoreWithEvents).subscribe((uiDataStores: UiDataStoreWithRelations[]) => {
      this.uiDataStores = uiDataStores;
      this.uiDataEvents = this.uiDataStores.map(uiDataStore => uiDataStore.events).flat().filter(item => item);
      this.setUIDataStoreOptions();
    });
  }

  setUIDataStoreOptions() {
    this.uiDataStoreOptions = this.uiDataStores?.map(uiDataStore => ({
      key: uiDataStore.name,
      value: uiDataStore.id
    }));
  }

  onUIDataStoreSelect() {
    this.dataEventOptions = this.uiDataStores?.find(
      uiDataStore => uiDataStore.id === this.selectedUIDataStoreId
    )?.events?.map(uiDataEvent => ({
      key: uiDataEvent.name,
      value: uiDataEvent.id
    }));
  }

  onAddComponentOutputEventButtonClick(componentOutputDefinition: ComponentOutputDefinition) {
    this.selectedComponentOutputEvent = this.component.outputEvents?.find(
      componentOutputEvent => componentOutputEvent.componentOutputDefinitionId === componentOutputDefinition.id
    ) || {};
    this.selectedUIDataStoreId = this.uiDataStores?.find(uiDataStore => uiDataStore.events?.some(
      event => event.id === this.selectedComponentOutputEvent.uiDataEventId)
    )?.id || '';
    this.selectedComponentOutputDefinitionId = componentOutputDefinition.id;
    this.isAddComponentOutputEventModalVisible = true;
    this.selectedComponentOutputDefinitionTypeId = componentOutputDefinition.typeId;
    this.dataTransformationId = this.selectedComponentOutputEvent.dataTransformationId;
  }

  onAddComponentOutputEvent() {
    this.selectedComponentOutputEvent = {
      ...this.selectedComponentOutputEvent,
      componentOutputDefinitionId: this.selectedComponentOutputDefinitionId,
      componentId: this.component.id
    };

    if (this.dataTransformationId) {
      this.selectedComponentOutputEvent.dataTransformationId = this.dataTransformationId;
    }

    this.store.dispatch(new CreateComponentOutputEvent({
      componentId: this.component.id,
      createdOutputEvent: this.selectedComponentOutputEvent as any
    }));
    this.handleCancel();
  }

  handleCancel() {
    this.selectedComponentOutputDefinitionId = null;
    this.selectedComponentOutputEvent = {};
    this.selectedComponentOutputDefinitionTypeId = null;
    this.isAddComponentOutputEventModalVisible = false;
  }

  getUIDataEventFromComponentOutputDefinition(componentOutputDefinition: ComponentOutputDefinitionInterface) {
    const componentOutputEvents = this.component.outputEvents;
    const uiDataEventId = componentOutputEvents?.find(
      outputEvent => outputEvent.componentOutputDefinitionId === componentOutputDefinition.id
    )?.uiDataEventId;
    const uiDataEvent = this.uiDataEvents?.find(dataEvent => dataEvent?.id === uiDataEventId);
    return uiDataEvent;
  }

  getUIDataStoreFromUIDataEvent(uiDataEvent: UiDataEventWithRelations) {
    const uiDataStore = this.uiDataStores?.find(dataStore => dataStore.events?.some(event => event.id === uiDataEvent.id));
    return uiDataStore;
  }

  deleteComponentOutputEvent(componentOutputDefinition: ComponentOutputDefinition) {
    const deletedComponentOutputEventId = this.component.outputEvents.find(
      outputEvent => outputEvent.componentOutputDefinitionId === componentOutputDefinition.id
    ).id;
    this.store.dispatch(new DeleteComponentOutputEvent({
      componentId: this.component.id,
      eventId: deletedComponentOutputEventId
    }));
  }

  isOutputDefinitionHasOutputEvent(componentOutputDefinition: ComponentOutputDefinitionInterface) {
    return this.getUIDataEventFromComponentOutputDefinition(componentOutputDefinition);
  }

  closeDataTransformationModal() {
    this.isAddDataTransformationModalVisible = false;
  }

  onAddDataTransformation() {
    this.closeDataTransformationModal();
  }

  onAddDataTransformationClick(outputDefinition?: any) {
    const outputEvent = <ComponentOutputEventWithRelations>this.component.outputEvents.find(
      item => item.componentOutputDefinitionId === outputDefinition?.id
    ) || this.selectedComponentOutputEvent;
    this.dataTransformationId = outputEvent?.dataTransformationId || this.dataTransformationId;
    const uiDataEvent = this.uiDataEvents?.find(dataEvent => dataEvent?.id === outputEvent.uiDataEventId);
    const eventSchemaId = uiDataEvent.dataSchemaId;

    if (this.dataTransformationId) {
      this.getJsonSchemaByDataSchemaIdAndOpenDTModal(eventSchemaId, outputDefinition);
    } else {
      this.dataTransformationApi.create({
        body: {
          inputVariableName: 'output',
          outputVariableName: 'payload'
        }
      }).subscribe(dt => {
        this.dataTransformationId = dt.id;
        this.store.dispatch(new UpdateComponentOutputEvent({
          componentId: this.component.id,
          updatedOutputEvent: { dataTransformationId: dt.id },
          updatedOutputEventId: outputEvent.id
        }));
        this.getJsonSchemaByDataSchemaIdAndOpenDTModal(eventSchemaId, outputDefinition);
      });
    }
  }

  getJsonSchemaByDataSchemaIdAndOpenDTModal(eventSchemaId: string, outputDefinition) {
    const dataSchemaId = outputDefinition?.typeId || this.selectedComponentOutputDefinitionTypeId;
    this.dataSchemaApi.calculateJSONSchema({ id: dataSchemaId, body: { isFieldArray: outputDefinition?.isArray } }).subscribe(jsonSchema => {
      this.jsonSchema = jsonSchema;
      this.dataSchemaApi.calculateJSONSchema({ id: eventSchemaId }).subscribe(targetSchema => {
        this.targetJsonSchema = targetSchema;
        this.isAddDataTransformationModalVisible = true;
      });
    });
  }

  dropdownMenuItemClick(event, outputDefinition) {
    switch (event?.key) {
      case 'delete':
        this.deleteComponentOutputEvent(outputDefinition);
        break;
      case 'data-transformation':
        this.onAddDataTransformationClick(outputDefinition);
        break;
      default:
        break;
    };
  }
}
