<div *ngIf="component?.componentDefinition?.outputDefinitions; else noOutputDefiniton"
     class="output-container">
  <div class="outputs">
    <ng-container *ngFor="let outputDefinition of component?.componentDefinition.outputDefinitions">
      <div [ngClass]="{'section': isOutputDefinitionHasOutputEvent(outputDefinition)}">
        <div class="output-information">
          <div class="label">
            {{outputDefinition?.title || outputDefinition.fieldName}}
          </div>

          <div *ngIf="!isOutputDefinitionHasOutputEvent(outputDefinition)"
               class="add-component-output-event-button">
            <rappider-button [nzTooltipTitle]="'CONTENT_EDITOR_MODULE.COMPONENT_OUTPUT_EVENT_COMPONENT.ADD_OUTPUT_EVENT' | translate"
                             nzTooltipPlacement="bottom"
                             nz-tooltip
                             [icon]="componentOutputEventAddButton.icon"
                             [size]="componentOutputEventAddButton.size"
                             (click)="onAddComponentOutputEventButtonClick(outputDefinition)">
            </rappider-button>
          </div>
        </div>
        <div *ngIf="isOutputDefinitionHasOutputEvent(outputDefinition)"
             class="output-information">

          <div class="dispatched-action-name">
            <div class="item">
              <div class="label">
                {{ 'SHARED.UI_DATA_STORE' | translate }}
              </div>
              {{
              getUIDataStoreFromUIDataEvent(getUIDataEventFromComponentOutputDefinition(outputDefinition))?.name
              }}
            </div>
            <div class="item">
              <div class="label">
                {{ 'SHARED.UI_DATA_EVENT' | translate }}
              </div>
              {{ getUIDataEventFromComponentOutputDefinition(outputDefinition)?.name }}
            </div>
          </div>
          <section class="output-event-controller">
            <rappider-dropdown-menu [icon]="dropdownMenuConfig.icon"
                                    [items]="dropdownMenuConfig.items"
                                    [labelMode]="dropdownMenuConfig.labelMode"
                                    [triggerType]="dropdownMenuConfig.triggerType"
                                    (menuItemClick)="dropdownMenuItemClick($event, outputDefinition)"></rappider-dropdown-menu>
          </section>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noOutputDefiniton>
  {{ 'CONTENT_EDITOR_MODULE.COMPONENT_OUTPUT_EVENT_COMPONENT.NO_OUTPUT_DEFINITION' | translate }}
</ng-template>

<rappider-modal *ngIf="isAddComponentOutputEventModalVisible"
                [(visible)]="isAddComponentOutputEventModalVisible"
                [title]="'CONTENT_EDITOR_MODULE.COMPONENT_OUTPUT_EVENT_COMPONENT.ADD_OUTPUT_EVENT' | translate"
                [width]="'30%'"
                (cancelClick)="handleCancel()"
                (okClick)="onAddComponentOutputEvent()">
  <div class="form-area">
    <div class="item">
      <div class="label">
        {{ 'SHARED.UI_DATA_STORE' | translate}}:
      </div>

      <rappider-select [(ngModel)]="selectedUIDataStoreId"
                       [options]="uiDataStoreOptions"
                       (ngModelChange)="onUIDataStoreSelect()"></rappider-select>
    </div>

    <div *ngIf="selectedUIDataStoreId"
         class="item">
      <div class="label">
        {{ 'SHARED.UI_DATA_EVENT' | translate }}:
      </div>

      <rappider-select [(ngModel)]="selectedComponentOutputEvent.uiDataEventId"
                       [options]="dataEventOptions"></rappider-select>
    </div>
  </div>
</rappider-modal>

<rappider-modal *ngIf="jsonSchema && dataTransformationId && isAddDataTransformationModalVisible"
                [(visible)]="isAddDataTransformationModalVisible"
                [title]="'Add Data Transformation'"
                [fullScreen]="true"
                (cancelClick)="closeDataTransformationModal()"
                (okClick)="onAddDataTransformation()">
  <rappider-data-transformation *ngIf="jsonSchema && dataTransformationId && isAddDataTransformationModalVisible"
                                [dataTransformationId]="dataTransformationId"
                                [schema]="jsonSchema"
                                [targetSchema]="targetJsonSchema"></rappider-data-transformation>
</rappider-modal>
