import { KeyValue } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { UpdatePageWithLifecycleActions, UpdatePage } from '@rappider/pages';
import { SelectMode, SelectSettings } from '@rappider/rappider-components/utils';
import { PageWithRelations, UiDataEventWithRelations, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { PageLifecycleType } from '../../models/page-lifecycle-type.enum';

@Component({
  selector: 'rappider-page-lifecyle',
  templateUrl: './page-lifecyle.component.html',
  styleUrls: ['./page-lifecyle.component.scss']
})
export class PageLifeCycleComponent implements OnChanges {
  @Input() activePage: PageWithRelations;
  @Input() uiDataStores: UiDataStoreWithRelations[];
  @Input() uiDataEvents: UiDataEventWithRelations[];

  isEventsModalVisible = false;
  eventsModalTitle = '';
  activeLifeCycleType: PageLifecycleType;
  uiDataEventIdsOnInit: string[] = [];
  uiDataEventIdsOnDestroy: string[] = [];
  uiDataStoreSelectOptions: KeyValue<string, string>[] = [];
  uiDataEventSelectOptions: KeyValue<string, string>[] = [];
  activeUiDataStore: UiDataEventWithRelations;
  PageLifecycleType = PageLifecycleType;

  uiDataEventSelectSettings: SelectSettings = {
    mode: SelectMode.Multiple,
    allowClear: true,
    searchable: true,
    maxTagCount: 3,
  };

  constructor(private store: Store<any>) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activePage) {
      this.uiDataEventIdsOnInit = [...<any[]>this.activePage?.uiDataEventIdsOnInit];
      this.uiDataEventIdsOnDestroy = [...<any[]>this.activePage?.uiDataEventIdsOnDestroy];
    }
    if (changes.uiDataEvents || changes.uiDataStores) {
      this.filterUIDataEventsWithPayload();
    }
  }

  setUiDataStoreSelectOptions() {
    this.uiDataStoreSelectOptions = this.uiDataStores?.map(uiDataStore => ({ key: uiDataStore.name, value: uiDataStore.id }));
  }

  onUiDataStoreSelect(uiDataStoreId: string) {
    this.setUIDataEventSelectOptions(uiDataStoreId);
  }

  mapUIDataEventsToUIDataStores() {
    this.uiDataStores = this.uiDataStores?.map(uiDataStore => ({
      ...uiDataStore,
      events: this.uiDataEvents?.filter(event => event.uiDataStoreId === uiDataStore.id)
    }));
    this.setUiDataStoreSelectOptions();
  }

  filterUIDataEventsWithPayload() {
    this.uiDataEvents = this.uiDataEvents?.filter(uiDataEvent => !uiDataEvent.payload?.fields?.length);
    this.mapUIDataEventsToUIDataStores();
  }

  setUIDataEventSelectOptions(uiDataStoreId: string): void {
    const selectedStore = this.uiDataStores?.find(store => store.id === uiDataStoreId);

    if (selectedStore) {
      this.uiDataEventSelectOptions = selectedStore.events.map(event => ({
        key: event.name,
        value: event.id
      }));
    } else {
      this.uiDataEventSelectOptions = [];
    }
  }

  setEventsModalTitle() {
    if (this.isEventsModalVisible) {
      if (this.activeLifeCycleType === PageLifecycleType.Init) {
        this.eventsModalTitle = 'Add Initial Action';
      } else if (this.activeLifeCycleType === PageLifecycleType.Destroy) {
        this.eventsModalTitle = 'Add Destroy Action';
      }
    }
  }

  onAddEventButtonClick(event, lifecycleType: PageLifecycleType) {
    event.stopPropagation();

    this.activeLifeCycleType = lifecycleType;
    this.toggleEventsModalVisibility();
    this.setEventsModalTitle();
  }

  private removeEventId(eventIds: string[], eventIdToRemove: string): string[] {
    return eventIds.filter(id => id !== eventIdToRemove);
  }

  onRemoveEvent(eventId: string, lifecycleType: PageLifecycleType) {
    let updatedIds: string[];

    if (lifecycleType === PageLifecycleType.Init) {
      updatedIds = this.removeEventId(this.uiDataEventIdsOnInit, eventId);
      this.store.dispatch(new UpdatePageWithLifecycleActions({ pageId: this.activePage.id, page: { uiDataEventIdsOnInit: updatedIds } }));
    } else if (lifecycleType === PageLifecycleType.Destroy) {
      updatedIds = this.removeEventId(this.uiDataEventIdsOnDestroy, eventId);
      this.store.dispatch(new UpdatePageWithLifecycleActions({ pageId: this.activePage.id, page: { uiDataEventIdsOnDestroy: updatedIds } }));
    }
  }

  toggleEventsModalVisibility() {
    this.isEventsModalVisible = !this.isEventsModalVisible;
  }

  onSelectUIDataEvents(uiDataEventIds: string[]) {
    if (this.activeLifeCycleType === PageLifecycleType.Init) {
      this.uiDataEventIdsOnInit = [...uiDataEventIds, ...this.uiDataEventIdsOnInit];
    } else if (this.activeLifeCycleType === PageLifecycleType.Destroy) {
      this.uiDataEventIdsOnDestroy = [...uiDataEventIds, ...this.uiDataEventIdsOnDestroy];
    }
  }

  private getUniqueEventIds(eventIds: string[]): string[] {
    return eventIds.filter((item, pos) => eventIds.indexOf(item) === pos);
  }

  onAddEvents() {
    let eventIds: string[];

    if (this.activeLifeCycleType === PageLifecycleType.Init) {
      eventIds = this.getUniqueEventIds(this.uiDataEventIdsOnInit);
      this.store.dispatch(new UpdatePageWithLifecycleActions({ pageId: this.activePage.id, page: { uiDataEventIdsOnInit: eventIds } }));
    } else if (this.activeLifeCycleType === PageLifecycleType.Destroy) {
      eventIds = this.getUniqueEventIds(this.uiDataEventIdsOnDestroy);
      this.store.dispatch(new UpdatePageWithLifecycleActions({ pageId: this.activePage.id, page: { uiDataEventIdsOnDestroy: eventIds } }));
    }
    this.toggleEventsModalVisibility();
  }
}
