import {
  RappiderCardOneListModule,
  RappiderIconModule,
  RappiderListGridModule,
  RappiderPaginationModule,
  RappiderTitleToolbarModule,
  RappiderEditFormModule,
  RappiderModalModule,
  RappiderSelectModule,
  RappiderDividerModule
} from '@rappider/rappider-components';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectCommentsComponent } from './components/project-comments/project-comments.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';

import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { AllProjectsComponent } from './components/all-projects/all-projects.component';
import { AdminProjectDetailComponent } from './components/admin-project-detail/admin-project-detail.component';

import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { VerifyPersonGuard, IsAdminGuard } from '@rappider/shared/guards';
import { AuthGuard } from '@rappider/authentication/guards';
import { AdminDashboardStateModule } from './state/admin-dashboard.state.module';
import { AdminProjectEditComponent } from './components/admin-project-edit/admin-project-edit.component';
import { CommentEditComponent } from './components/comment-edit/comment-edit.component';
import { ProjectPagesComponent } from './components/project-pages/project-pages.component';
import { FormsModule } from '@angular/forms';
import { ProjectMembersAdminDashboardComponent } from './components/project-members/project-members-admin-dashboard.component';
import { JiraDashboardComponent } from './components/jira-dashboard/jira-dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: AdminDashboardComponent,
  },
  {
    path: 'projects',
    component: AllProjectsComponent,
    canActivate: [AuthGuard, VerifyPersonGuard, IsAdminGuard]
  },
  {
    path: 'project-detail/:id',
    component: AdminProjectDetailComponent,
    canActivate: [AuthGuard, VerifyPersonGuard, IsAdminGuard]
  },
  {
    path: 'project-detail/:id/comments',
    component: ProjectCommentsComponent,
    canActivate: [AuthGuard, VerifyPersonGuard, IsAdminGuard]
  },
  {
    path: 'project-detail/:id/comment-edit/:commentId',
    component: CommentEditComponent,
    canActivate: [AuthGuard, VerifyPersonGuard, IsAdminGuard]
  },
  {
    path: 'project-detail/:id/project-pages',
    component: ProjectPagesComponent,
    canActivate: [AuthGuard, VerifyPersonGuard, IsAdminGuard]
  },
  {
    path: 'project-detail/:id/project-members',
    component: ProjectMembersAdminDashboardComponent,
    canActivate: [AuthGuard, VerifyPersonGuard, IsAdminGuard]
  },
  {
    path: 'permissions-crud',
    loadChildren: () => import('./modules/permissions/permissions.module').then(m => m.PermissionsModule),
  },
  {
    path: 'project-edit/:id',
    component: AdminProjectEditComponent,
    canActivate: [AuthGuard, VerifyPersonGuard, IsAdminGuard]
  },
  {
    path: 'roles-crud',
    loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule),
  },
  {
    path: 'person-management',
    loadChildren: () => import('./modules/person-list/person-list.module').then(m => m.PersonListModule),
  },
  {
    path: 'jira',
    component: JiraDashboardComponent,
    canActivate: [AuthGuard, VerifyPersonGuard, IsAdminGuard]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RappiderListGridModule,
    RappiderTitleToolbarModule,
    AdminDashboardStateModule,
    RappiderCardOneListModule,
    RappiderPaginationModule,
    NzSkeletonModule,
    RappiderIconModule,
    NzTabsModule,
    TranslateModule,
    RappiderEditFormModule,
    RappiderModalModule,
    RappiderSelectModule,
    FormsModule,
    RappiderDividerModule
  ],
  declarations: [
    ProjectCommentsComponent,
    AdminDashboardComponent,
    AllProjectsComponent,
    AdminProjectDetailComponent,
    AdminProjectEditComponent,
    CommentEditComponent,
    ProjectPagesComponent,
    ProjectMembersAdminDashboardComponent,
    JiraDashboardComponent
  ]
})
export class AdminDashboardModule { }
