import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, projectFeatureKey } from './project.reducer';

// Select the feature slice from the store
export const selectProjectState = createFeatureSelector<State>(projectFeatureKey);

// Selectors
export const selectProjects = createSelector(
  selectProjectState,
  (state: State) => state.data
);

export const selectIsProjectsLoading = createSelector(
  selectProjectState,
  (state: State) => state.loading
);

export const selectIsProjectsLoaded = createSelector(
  selectProjectState,
  (state: State) => state.isLoaded
);

export const selectPagination = createSelector(
  selectProjectState,
  (state: State) => state.pagination
);

export const selectPersonProjects = createSelector(
  selectProjectState,
  (state: State) => state.personProjects
);

export const selectIsPersonProjectsLoaded = createSelector(
  selectProjectState,
  (state: State) => state.isPersonProjectsLoaded
);

export const selectIsPublicProjectsLoading = createSelector(
  selectProjectState,
  (state: State) => state.isPublicProjectsLoading
);

export const selectPublicProjects = createSelector(
  selectProjectState,
  (state: State) => state.publicProjects
);

export const selectChangeFavorite = createSelector(
  selectProjectState,
  (state: State) => state.changeFavorite
);

export const selectLoadingActiveProjectId = createSelector(
  selectProjectState,
  (state: State) => state.loadingActiveProjectId
);

export const selectLastError = createSelector(
  selectProjectState,
  (state: State) => state.lastError
);
