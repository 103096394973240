import { v4 } from 'uuid';
import { Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CategorizedData } from '@rappider/models';
import { IconComponentConfig, IconType, InputGroupComponentConfig } from '@rappider/rappider-components/utils';
import { ComponentDefinitionWithRelations, ContainerTemplateWithRelations } from '@rappider/rappider-sdk';
import { ComponentDefinitionService, RenderComponentService, SearchService } from '@rappider/services';
import { CategorizedComponentDefinitionGroup } from '@rappider/shared/interfaces';
import { ContentTreeContainer, ContentTreeItem, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';
import { CreateComponent } from '../../../state/content-editor.actions';
import { ComponentDefinitionInterface } from '@rappider/api-sdk';
import { CopyTemplateToPageContainer } from '../../../state/container-template-state/container-template.actions';

@Component({
  selector: 'rappider-element-menu',
  templateUrl: './element-menu.component.html',
  styleUrls: ['./element-menu.component.scss']
})
export class ElementMenuComponent {

  /**
     * Container ref for render component view dynamically
     *
     * @type {ViewContainerRef}
     * @memberof ComponentMenuComponent
     */
  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer: ViewContainerRef;

  @Input() componentDefinitions: ComponentDefinitionWithRelations[];
  /* flag for when component is used in modal  */
  @Input() isComponentUsingInModal = false;
  @Input() activeContentTreeItem: ContentTreeItem;
  @Input() addComponentToTheActiveContentEditorOnSelection = true;
  @Input() showPopover = false;

  @Output() componentAdded = new EventEmitter<ComponentDefinitionInterface>();
  @Output() addElementModalVisibility = new EventEmitter();
  @Output() drawerClosed = new EventEmitter<void>();

  elements: CategorizedComponentDefinitionGroup[];
  elementsBySearch: CategorizedComponentDefinitionGroup;
  hoveredComponentDefinitionId: string;

  /* searchOptions for fuse */
  searchOptions = {
    threshold: 0.2,
    keys: [
      'componentDefinition.title'
    ]
  };
  /* groupped search results */
  matchedComponentDefinitionGroups: CategorizedData[];
  searchText: string;

  inputGroupConfig: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'Search Elements'
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome
    }
  };

  addComponentButtonIcon: IconComponentConfig = {
    name: 'fas fa-plus',
    type: IconType.FontAwesome,
  };

  ContentEditorContentType = ContentTreeItemType;
  searchHistory: string[] = [];

  constructor(
    private renderComponentService: RenderComponentService,
    private searchService: SearchService,
    private componentDefinitionService: ComponentDefinitionService,
    private store: Store<any>
  ) { }

  ngOnChanges(): void {
    this.setCategorizedComponentDefinitions();
  }

  setCategorizedComponentDefinitions() {
    const categorizeComponentDefinitions = this.componentDefinitionService
      .categorizeComponentDefinitions(this.componentDefinitions);

    this.elements = categorizeComponentDefinitions?.filter(componentDefinition => componentDefinition.name === 'elements');
  }

  onAddComponentDefinitionButtonClick(componentDefinition: ComponentDefinitionInterface) {
    if (this.addComponentToTheActiveContentEditorOnSelection) {
      this.store.dispatch(new CreateComponent({
        componentDefinitionId: componentDefinition.id,
        title: componentDefinition.title,
        inputs: componentDefinition.defaultInputs
      }));
      this.drawerClosed.emit();
    }
    /* emit for add component modal */
    this.componentAdded.emit(componentDefinition);
  }

  onSearchTextChange() {
    this.elementsBySearch = {
      data: this.searchService.searchByOptions(this.elements[0].data, this.searchOptions, this.searchText)?.map(res => res.item)
    };
  }

  getContentTreeItemAsPageContainer(contentTreeItem: ContentTreeItem): ContentTreeContainer {
    return <ContentTreeContainer>contentTreeItem;
  }

  // #region popover and render component

  /**
   * Runs when popover visibility change
   * if visibility is true finds inputs and pass to renderComponentDynamically fn
   *
   * @param {boolean} visibility
   * @param {ComponentDefinitionInterface} componentDefinition
   * @memberof ComponentMenuComponent
   */
  onPopoverVisibilityChange(visibility: boolean, componentDefinition: ComponentDefinitionInterface) {
    if (visibility) {
      /* To reach the #componentContainer template, #popoverContentTemplate must have been created.
       * wait 1ms for the #popoverContentTemplate template to be created
       */
      setTimeout(() => this.renderComponentDynamically(componentDefinition), 1);
    }
  }

  /**
   * Renders component dynamically in #componentContainer
   *
   * @param {ComponentDefinitionInterface} componentDefinition
   * @memberof ComponentMenuComponent
   */
  renderComponentDynamically(componentDefinition: ComponentDefinitionInterface) {
    if (this.componentContainer) {
      this.componentContainer.clear();
      this.renderComponentService.renderComponentAndAddToContainer(
        componentDefinition.className,
        this.componentContainer,
        componentDefinition.defaultInputs
      );
    }
  }

  // #endregion popover and render component

  onCopyTemplateToPageButtonClick(containerTemplate: ContainerTemplateWithRelations) {
    this.store.dispatch(CopyTemplateToPageContainer({
      payload: { containerTemplateId: containerTemplate.id }
    }));
    /* emit for add component modal */
    this.componentAdded.emit();
  }

  getIconForElement(path: string) {
    return `url('${path}')`;
  }

  addEventElementModalVisibilityChange(visibility) {
    this.addElementModalVisibility.emit(visibility);
  }

  mouseOver(componentDefinition) {
    this.hoveredComponentDefinitionId = componentDefinition.id;
  }

  mouseOut() {
    this.hoveredComponentDefinitionId = undefined;
  }
}
