import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { DataSchemaField, DataSchemaWithRelations, UiDataStoreWithRelations } from '@rappider/rappider-sdk';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Store, createSelector } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UiDataStoreFieldSelectorValue } from './ui-data-store-field-selector-value.interface';
import { capitalize } from 'lodash';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';

@Component({
  selector: 'rappider-ui-data-store-field-tree-selector',
  templateUrl: './ui-data-store-field-tree-selector.component.html',
  styleUrls: ['./ui-data-store-field-tree-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => UiDataStoreFieldTreeSelectorComponent),
      multi: true
    }
  ]
})
export class UiDataStoreFieldTreeSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('nzTreeComponent') treeComponent: NzTreeComponent;

  uiDataStores: UiDataStoreWithRelations[];
  dataSchemasWithRelations: DataSchemaWithRelations[];
  tree: NzTreeNodeOptions[];
  fetchedNodeKeysInTree = [];
  selectedNodes = [];
  searchValue = '';
  searchResult: NzTreeNodeOptions[];
  isModuleLoaded = false;

  subscription: Subscription;

  _value: UiDataStoreFieldSelectorValue[];

  get value() {
    return this._value;
  }

  set value(value: UiDataStoreFieldSelectorValue[]) {
    this._value = value ?? [];
    this.setSelectedKeys();
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: UiDataStoreFieldSelectorValue[]): void {
    this._value = value ?? [];
    this.setSelectedKeys();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  constructor(private store: Store<any>) { }

  ngOnInit(): void {
    this.subscription = this.subscribeToUIDataStoreAndDataSchemas();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeToUIDataStoreAndDataSchemas() {
    return this.store.select(createSelector(
      state => state['uiDataStore'].data,
      <any>getDataSchemasWithDetailsSelector,
      state => state['dataSchema'].isLoaded,
      state => state['uiDataStore'].isLoaded,
      (
        uiDataStores: UiDataStoreWithRelations[],
        dataSchemas: DataSchemaWithRelations[],
        isDataSchemasLoaded: boolean,
        isUIDataStoresLoaded: boolean
      ) => ({
        uiDataStores: uiDataStores,
        dataSchemas: dataSchemas,
        isModuleLoaded: isDataSchemasLoaded && isUIDataStoresLoaded
      })
    )).subscribe((selectorResponse) => {
      this.isModuleLoaded = <boolean>selectorResponse?.isModuleLoaded;
      if (selectorResponse) {
        this.dataSchemasWithRelations = <DataSchemaWithRelations[]>selectorResponse.dataSchemas;
        this.uiDataStores = <UiDataStoreWithRelations[]>selectorResponse.uiDataStores;
        this.buildTreeFromUIDataStores();
      }
    });
  }

  buildTreeFromUIDataStores() {
    this.tree = this.uiDataStores.map(uiDataStore => {
      const existingSchemaOfUIDataStore = this.dataSchemasWithRelations?.find(ds => ds.id === uiDataStore.dataSchemaId);
      return {
        title: uiDataStore.name,
        key: uiDataStore.id,
        dataSchemaId: uiDataStore.dataSchemaId,
        data: uiDataStore,
        children: existingSchemaOfUIDataStore?.fields?.map(dataSchemaField => this.buildChildrenRecursively(dataSchemaField, uiDataStore.id))
      };
    }) as NzTreeNodeOptions[];

  }

  /**
   * In order for each node's key to be unique,
   * we obtain the keys of the nodes by combining the keys of the nodes up to the root node with / .
   *
   * @param {DataSchemaField} dataSchemaField
   * @param {string} parentPath
   * @return {*}
   * @memberof UiDataStoreFieldTreeSelectorComponent
   */
  buildChildrenRecursively(dataSchemaField: DataSchemaField, parentPath: string) {
    return {
      title: dataSchemaField.name,
      key: parentPath + '/' + dataSchemaField.id,
      isLeaf: !dataSchemaField?.type?.fields?.length,
      children: dataSchemaField?.type?.fields?.map(dsField => this.buildChildrenRecursively(dsField, parentPath + '/' + dataSchemaField.id)),
      data: dataSchemaField
    };
  }

  addChildrenToNode() {
    this.treeComponent?.getTreeNodes()?.forEach(node => {
      if (!this.fetchedNodeKeysInTree.includes(node.key) && node.isExpanded) {
        const existingSchemaOfUIDataStore = this.dataSchemasWithRelations?.find(ds => ds.id === node.origin.dataSchemaId);
        const children = existingSchemaOfUIDataStore?.fields?.map(dataSchemaField => this.buildChildrenRecursively(dataSchemaField, node.key));
        if (children?.length) {
          node.clearChildren();
          node.addChildren(children);
          this.fetchedNodeKeysInTree.push(node.key);
          this.setSelectedKeys();
        } else if (existingSchemaOfUIDataStore) {
          node.children = undefined;
          node.isLeaf = true;
        }
      }
    });
  }

  onNodeExpandChange(e: NzFormatEmitEvent): void {
    if (e.node.isExpanded && this.uiDataStores.some(uiDataStore => uiDataStore.id === e.node.key) && !this.fetchedNodeKeysInTree.includes(e.node.key)) {
      if (!e.node.origin.children) {
        const treeNode = this.treeComponent.getTreeNodeByKey(e.node.key);
        if (treeNode) {
          treeNode.isLoading = false;
          treeNode.isLeaf = true;
        }
      }
    }
  }

  setSelectedKeys() {
    this.selectedNodes = this.value?.map(v => {
      if (v.uiDataStoreSchemaFields?.length) {
        return v.uiDataStore.id + '/' + v.uiDataStoreSchemaFields.map(field => field.id).join('/');
      } else {
        return v.uiDataStore.id;
      }
    }) ?? [];
  }

  onSelectedKeysChange(): void {
    const nodeToRootPath = this.treeComponent.getSelectedNodeList().map(node => this.findNodeToRootPath(node));

    this.value = nodeToRootPath.map(y => {
      const uiDataStore = y.pop();
      y.reverse();
      return {
        variableName: (y?.length < 2 ? uiDataStore.name : '') + y.map(w => capitalize(w.name)).join(''),
        uiDataStore: uiDataStore,
        uiDataStoreSchemaFields: y
      };
    });
  }

  findNodeToRootPath(node: NzTreeNode) {
    const x = [];
    const stack = [node];
    while (stack.length) {
      const node = stack.pop();
      x.push(node.origin.data);
      if (node.parentNode) {
        stack.push(node.parentNode);
      }
    }
    return x;
  }

  searchValueChange() {
    this.searchResult = this.tree.filter(node => node.title.includes(this.searchValue));
  }

}
