import { ProjectExternalScriptInterface } from '@rappider/api-sdk';
import * as ProjectScriptActions from './project-script.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

export const projectScriptFeatureKey = 'projectScript';

export interface State {
  data: ProjectExternalScriptInterface[];
  loading: boolean;
  error: {
    timestamp?: number;
    key?: string;
    error?: unknown;
  };
  /* loading state of components and services */
  loadings: Record<string, unknown>;
}

export const initialState: State = {
  data: null,
  loading: false,
  error: null,
  loadings: {}
};

export function reducer(
  state: State = initialState,
  action: ProjectScriptActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {

    case ProjectScriptActions.ActionTypes.SetProjectScripts:
      return {
        ...state,
        data: action.payload.projectScripts,
        loading: false
      };

    case ProjectScriptActions.ActionTypes.CreateProjectScriptSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.projectScript
        ],
        loading: false
      };

    case ProjectScriptActions.ActionTypes.UpdateProjectScript:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          updateProjectScript: true
        },
        /** remove below use loadings */
        loading: true
      };

    case ProjectScriptActions.ActionTypes.UpdateProjectScriptSuccessful:
      return {
        ...state,
        data: state.data.map(script =>
          script.id === action.payload.projectScript.id ? action.payload.projectScript : script
        ),
        loadings: {
          ...state.loadings,
          updateProjectScript: false
        },
        /** remove below use loadings */
        loading: false
      };

    case ProjectScriptActions.ActionTypes.GetProjectScripts:
      return {
        ...state,
        loading: true
      };


    case ProjectScriptActions.ActionTypes.SetLoadingState:
      return {
        ...state,
        loading: action.payload.loading
      };

    case ProjectScriptActions.ActionTypes.ErrorAction:
      return {
        ...state,
        error: {
          ...state.error,
          timestamp: action.payload.timestamp,
          key: action.payload.key,
          error: action.payload.error
        }
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful:
      return initialState;

    default:
      return state;
  }
}
