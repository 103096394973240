<!-- ---------------------------------------------------------------------------------------------- -->
<!-- ADD COMPONENT MODAL -->
<rappider-modal [(visible)]="addComponentModalVisibility"
                [title]="setModalTitle()"
                className="add-component-modal"
                [fullScreen]="true"
                [footer]="null"
                (cancelClick)="closeAddComponentModal()"
                (visibleChange)="onModalVisibilityChange($event)">
  <rappider-spin [spinning]="!componentDefinitions">
    <rappider-component-menu [componentDefinitions]="componentDefinitions"
                             [isComponentUsingInModal]="true"
                             [containerTemplates]="containerTemplates"
                             (componentAdded)="closeAddComponentModal()"></rappider-component-menu>
  </rappider-spin>
</rappider-modal>
<!-- / ADD COMPONENT MODAL -->
<!-- ---------------------------------------------------------------------------------------------- -->