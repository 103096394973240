<nz-collapse *ngIf="!value.isMainContainer"
             class="layout-section">
  <nz-collapse-panel [nzHeader]="'CONTENT_EDITOR_MODULE.CONTAINER_LAYOUT_SETTINGS_COMPONENT.CONTAINER_WIDTH' | translate"
                     [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.ContainerDisplayMode)">
    <div class="inner-collapse">
      <div class="container-setting">
        <rappider-checkbox [(ngModel)]="value.autoColumnWidth"
                           [text]="{text: 'Auto Width'}"
                           (ngModelChange)="triggerChangeDetection()"></rappider-checkbox>
        <rappider-slider *ngIf="!value?.autoColumnWidth"
                         [(ngModel)]="value.columnWidth"
                         [minRange]="settingConfigs.containerWidth.minRange"
                         [maxRange]="settingConfigs.containerWidth.maxRange"
                         [marks]="settingConfigs.containerWidth.marks"
                         (ngModelChange)="triggerChangeDetection()"></rappider-slider>
      </div>
    </div>
  </nz-collapse-panel>
</nz-collapse>

<nz-collapse class="layout-section">
  <nz-collapse-panel [nzHeader]="'Display Mode'"
                     [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.ContainerDisplayMode)">
    <div class="inner-collapse">
      <div class="container-setting">
        <rappider-select [(ngModel)]="value.displayMode"
                         [options]="settingConfigs.displayMode.options"
                         (ngModelChange)="triggerChangeDetection()"></rappider-select>
      </div>
    </div>
  </nz-collapse-panel>
</nz-collapse>

<nz-collapse *ngIf="value.displayMode === 'flex'"
             class="layout-section">
  <nz-collapse-panel [nzHeader]="'CONTENT_EDITOR_MODULE.CONTAINER_LAYOUT_SETTINGS_COMPONENT.FLEX_OPTIONS' | translate"
                     [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.ContainerDisplayMode)">
    <div class="inner-collapse">
      <div class="container-setting">
        <div class="container-setting-header">
          <rappider-heading type="h6"
                            content="CONTENT_EDITOR_MODULE.CONTAINER_LAYOUT_SETTINGS_COMPONENT.DIRECTION">
          </rappider-heading>
        </div>
        <div class="direction-settings">
          <rappider-radio-group class="direction-radio"
                                [(ngModel)]="localFlexDirection"
                                [options]="settingConfigs.direction.options"
                                (ngModelChange)="onLocalDirectionValueChange()">
          </rappider-radio-group>
          <rappider-checkbox [(ngModel)]="localDirectionReverse"
                             [icon]="settingConfigs.reverseCheckbox.icon"
                             [checkboxType]="settingConfigs.reverseCheckbox.checkboxType"
                             [tooltip]="settingConfigs.reverseCheckbox.tooltip"
                             (ngModelChange)="onLocalDirectionValueChange()">
          </rappider-checkbox>
        </div>
      </div>
      <div class="container-setting">
        <div class="container-setting-header">
          <rappider-heading type="h6"
                            content="CONTENT_EDITOR_MODULE.CONTAINER_LAYOUT_SETTINGS_COMPONENT.ALIGN_ITEMS">
          </rappider-heading>
        </div>
        <rappider-radio-group [(ngModel)]="value.flexOptions.flexAlignItems"
                              [options]="settingConfigs.alignItems.options"
                              (ngModelChange)="triggerChangeDetection()"></rappider-radio-group>
      </div>

      <div class="container-setting">
        <div class="container-setting-header">
          <rappider-heading type="h6"
                            content="CONTENT_EDITOR_MODULE.CONTAINER_LAYOUT_SETTINGS_COMPONENT.JUSTIFY_CONTENT">
          </rappider-heading>
        </div>
        <rappider-radio-group [(ngModel)]="value.flexOptions.flexJustifyContent"
                              [options]="settingConfigs.justifyContent.options"
                              (ngModelChange)="triggerChangeDetection()"></rappider-radio-group>
      </div>
      <div class="container-setting">
        <div class="container-setting-header">
          <rappider-heading type="h6"
                            content="CONTENT_EDITOR_MODULE.CONTAINER_LAYOUT_SETTINGS_COMPONENT.WRAP">
          </rappider-heading>
        </div>
        <div class="wrap-settings">
          <rappider-radio-group [(ngModel)]="localFlexWrap"
                                class="wrap-radio"
                                [options]="settingConfigs.wrap.options"
                                (ngModelChange)="onLocalFlexWrapAndWrapReverseValueChange()">
          </rappider-radio-group>
          <rappider-checkbox [(ngModel)]="localWrapReverse"
                             [icon]="settingConfigs.reverseCheckbox.icon"
                             [checkboxType]="settingConfigs.reverseCheckbox.checkboxType"
                             [tooltip]="settingConfigs.reverseCheckbox.tooltip"
                             [disabled]="isWrapReverseCheckboxDisabled"
                             (ngModelChange)="onLocalFlexWrapAndWrapReverseValueChange()">
          </rappider-checkbox>
        </div>
      </div>
      <div *ngIf="value.flexOptions.flexWrap === 'wrap' || value.flexOptions.flexWrap === 'wrap-reverse'"
           class="container-setting">
        <div class="container-setting-header">
          <rappider-heading type="h6"
                            content="CONTENT_EDITOR_MODULE.CONTAINER_LAYOUT_SETTINGS_COMPONENT.ALIGN_CONTENT">
          </rappider-heading>
        </div>
        <rappider-radio-group [(ngModel)]="value.flexOptions.flexAlignContent"
                              [options]="settingConfigs.alignContent.options"
                              (ngModelChange)="triggerChangeDetection()"></rappider-radio-group>
      </div>
    </div>
  </nz-collapse-panel>
</nz-collapse>

<nz-collapse *ngIf="!value.isMainContainer"
             class="layout-section">
  <nz-collapse-panel [nzHeader]="'CONTENT_EDITOR_MODULE.CONTAINER_LAYOUT_SETTINGS_COMPONENT.CONTAINER_TYPE' | translate"
                     [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.ContainerType)">
    <div class="inner-collapse">
      <div class="container-setting">
        <rappider-select [(ngModel)]="containerTypeValue"
                         [options]="containerType.options"
                         (ngModelChange)="onContainerTypeChange($event)"></rappider-select>
      </div>
    </div>
  </nz-collapse-panel>
</nz-collapse>

<nz-collapse *ngIf="value.isIterative"
             class="layout-section">
  <nz-collapse-panel [nzHeader]="'CONTENT_EDITOR_MODULE.CONTAINER_LAYOUT_SETTINGS_COMPONENT.LIST_DATA_SOURCE' | translate"
                     [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.ContainerListDataSource)">
    <div class="inner-collapse">
      <div class="container-setting">
        <ng-container *ngIf="value.isIterative">
          <rappider-button [key]="listDataSourceButton.key"
                           [text]="listDataSourceButton.text"
                           (click)="onListDataSourceButtonClick()"></rappider-button>
        </ng-container>
      </div>
    </div>
  </nz-collapse-panel>
</nz-collapse>

<ng-container *ngIf="listDataSourceModalVisibility">
  <rappider-modal [visible]="listDataSourceModalVisibility"
                  [title]="value.title + ' | ' + 'Data Source'"
                  width="60%"
                  (cancelClick)="onListDataSourceModalCancel()"
                  (okClick)="onListDataSourceModalOk()">
    <rappider-crud-view-edit-form [config]="LIST_DATA_SOURCE_CONFIG"
                                  [data]="iterativeContainerFormData"
                                  (formValueChange)="onIterativeContainerDataFormChange($event)"></rappider-crud-view-edit-form>
  </rappider-modal>
</ng-container>
