<rappider-code-editor-input-form codemirrorTitle="Page Style"
                                 [codemirrorSettings]="codemirrorSettings"
                                 [codemirrorContent]="page?.cssStyle"
                                 [codemirrorAdditionalButtons]="codemirrorAdditionalButtons"
                                 (buttonClick)="onEditProjectCssStyle($event)"
                                 (codemirrorAdditionalButtonClick)="onAdditionalButtonClick($event)">
</rappider-code-editor-input-form>

<rappider-modal [visible]="modalMode"
                title="Page Style Customization"
                [fullScreen]="true"
                [footer]="null"
                (cancelClick)="onPageStyleModalClose()">
  <ng-container *ngIf="codemirrorInModalVisibility">
    <rappider-code-editor-input-form codemirrorTitle="Page Style"
                                     [codemirrorSettings]="codemirrorSettings"
                                     [codemirrorContent]="page?.cssStyle"
                                     (buttonClick)="onEditProjectCssStyle($event)">
    </rappider-code-editor-input-form>
  </ng-container>
</rappider-modal>