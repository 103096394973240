import { DEFAULT_PAGINATION } from '@rappider/shared/definitions';
import { LastProcessedAction, Pagination } from '@rappider/shared/interfaces';
import * as ProjectSettingActions from './project-setting.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

export const projectSettingFeatureKey = 'projectSetting';

export interface State {
  data: any[];
  pagination: Pagination;
  loading: boolean;
  isLoaded: boolean;
  lastProcessedAction: LastProcessedAction;
}

export const initialState: State = {
  data: null,
  pagination: {
    currentPageNumber: DEFAULT_PAGINATION.ACTIVE_PAGE_NUMBER,
    pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
    totalCount: null,
    totalPageNumber: null
  },
  loading: false,
  isLoaded: false,
  lastProcessedAction: {
    success: null,
    action: null,
    message: null,
    timestamp: 0,
    data: null
  }
};

export function reducer(
  state: State = initialState,
  action: ProjectSettingActions.Actions | AuthenticationActions.Actions
): State {
  switch (action.type) {
    case ProjectSettingActions.ActionTypes.LoadModule:
      return {
        ...state,
        loading: true
      };

    case ProjectSettingActions.ActionTypes.GetProjectSettings:
      return {
        ...state,
        isLoaded: false
      };

    case ProjectSettingActions.ActionTypes.SetProjectSettings:
      return {
        ...state,
        data: action.payload.projectSettings,
        loading: false,
        isLoaded: true
      };

    case ProjectSettingActions.ActionTypes.SetPagination:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination
        }
      };

    case ProjectSettingActions.ActionTypes.ChangeLastProcessedAction:
      return {
        ...state,
        lastProcessedAction: action.payload.lastProcessedAction
      };

    case ProjectSettingActions.ActionTypes.CreateProjectSetting:
      return {
        ...state,
        loading: true
      };

    case ProjectSettingActions.ActionTypes.CreateProjectSettingSuccessful:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload.projectSetting
        ],
        loading: false
      };

    case ProjectSettingActions.ActionTypes.ProjectSettingError:
      return {
        ...state,
        loading: false
      };

    case ProjectSettingActions.ActionTypes.UpdateProjectSetting:
      return {
        ...state,
        loading: true
      };

    case ProjectSettingActions.ActionTypes.UpdateProjectSettingSuccessful:
      return {
        ...state,
        data: [
          ...state.data.filter(setting => setting.id !== action.payload.projectSettingId),
          action.payload.projectSetting
        ],
        loading: false
      };

    case ProjectSettingActions.ActionTypes.DeleteProjectSetting:
      return {
        ...state,
        loading: true
      };

    case ProjectSettingActions.ActionTypes.DeleteProjectSettingSuccessful:
      return {
        ...state,
        data: state.data.filter(setting => setting.id !== action.payload.projectSettingId),
        loading: false
      };

    case AuthenticationActions.ActionTypes.Logout:
      return initialState;

    case AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful:
      return initialState;

    default:
      return state;
  }
}
