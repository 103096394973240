import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { RappiderPaginationComponent } from './pagination.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RappiderButtonModule } from '../button/button.module';


@NgModule({
  declarations: [
    RappiderPaginationComponent
  ],
  imports: [
    CommonModule,
    NzPaginationModule,
    NzToolTipModule,
    RappiderButtonModule
  ],
  exports: [
    RappiderPaginationComponent
  ]
})
export class RappiderPaginationModule { }
