import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ButtonComponentConfig, ButtonSize, ButtonType, HeadingType, IconType, OrderChangeOutput } from '@rappider/rappider-components/utils';
import { NewProjectModelField, ProjectModelField, ProjectModelFieldPartial, ProjectModelFieldWithRelations, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { NEW_PROJECT_DATA_FIELD_LIST_CONFIG } from './config/new-project-data-field-list-config';
import { ActionMode } from '../../../../../custom-function/src/lib/components/custom-function-tab-list-wrapper/utils/action-mode.enum';
import { ActionResponse } from '@rappider/rappider-components/utils';
import { ProjectModelFieldUpdateData } from '../../utils/project-model-field-update-data';
import { ProjectModelFieldComponentMode } from '../../utils/project-model-field-component-mode.enum';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NotificationService } from '@rappider/services';
import { BulkUpdateProjectModelFields } from '../../state/project-model-field.actions';

@Component({
  selector: 'rappider-new-project-model-field',
  templateUrl: './new-project-model-field.component.html',
  styleUrls: ['./new-project-model-field.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ transform: 'translateX(100%)', opacity: 0 }),
            animate('150ms', style({ transform: 'translateX(0)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ transform: 'translateX(0)', opacity: 1 }),
            animate('150ms', style({ transform: 'translateX(100%)', opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class NewProjectModelFieldComponent implements OnInit, OnChanges {
  @Input() projectModel!: ProjectModelWithRelations;
  @Input() fieldCreatingModelIds!: string[];
  @Input() submitButtonLoading: boolean;

  @Output() projectModelUpdate = new EventEmitter<ProjectModelWithRelations>();
  @Output() projectModelFieldDelete = new EventEmitter<ProjectModelField>();
  @Output() projectModelFieldCreate = new EventEmitter<NewProjectModelField>();
  @Output() projectModelFieldUpdate = new EventEmitter<ProjectModelFieldUpdateData>();
  @Output() projectModelFieldsUpdate = new EventEmitter<ProjectModelFieldPartial[]>();

  activeProjectModelField!: ProjectModelFieldWithRelations;
  NEW_PROJECT_DATA_FIELD_LIST_CONFIG = NEW_PROJECT_DATA_FIELD_LIST_CONFIG;

  loading = false;

  newButtonType = ButtonType.Primary;

  mode: ProjectModelFieldComponentMode = ProjectModelFieldComponentMode.Read;

  editButtonConfig: ButtonComponentConfig = {
    size: ButtonSize.Small,
    icon: {
      type: IconType.FontAwesome,
      name: 'fa-sharp fa-solid fa-pen-to-square',
      color: 'var(--text-color)'
    }
  };

  backButtonConfig: ButtonComponentConfig = {
    size: ButtonSize.Small,
    icon: {
      type: IconType.FontAwesome,
      name: 'fa-sharp fa-solid fa-arrow-left'
    }
  };

  ComponentMode = ProjectModelFieldComponentMode;
  projectModelHeadingType = HeadingType.H4;
  projectModelFieldsLoading = false;
  subscriptions!: Subscription[];

  constructor(
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.handleLoading();
    this.subscribeToData();
  }

  ngOnChanges(): void {
    this.handleLoading();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToProjectModelFieldsLoading()
    ];
  }

  subscribeToProjectModelFieldsLoading() {
    return this.store.select(state => state.projectModelField?.isLoading).subscribe(loading => this.projectModelFieldsLoading = loading);
  }

  handleLoading() {
    if (this.fieldCreatingModelIds?.includes(this.projectModel?.id)) {
      this.loading = true;
    } else {
      this.loading = false;
    }
  }

  /**
   * trigger project model field create mode
   *
   * @memberof NewProjectModelFieldComponent
   */
  onNewFieldClick() {
    this.mode = ProjectModelFieldComponentMode.Create;
  }

  onItemClick(actionResponse: ActionResponse) {
    if (actionResponse.action.name === ActionMode.Delete) {
      this.onDeleteProjectModelField(actionResponse.data);
    } else if (actionResponse.action.name === ActionMode.Edit) {
      this.onEditFieldClick(actionResponse.data);
    }
  }

  /**
   * trigger project model field edit mode and set active project model field
   *
   * @memberof NewProjectModelFieldComponent
   */
  onEditFieldClick(projectModelField: ProjectModelField) {
    this.activeProjectModelField = projectModelField;
    this.mode = ProjectModelFieldComponentMode.Update;
  }

  /**
   * set components' mode to read
   *
   * @memberof NewProjectModelFieldComponent
   */
  public setModeToRead() {
    this.mode = ProjectModelFieldComponentMode.Read;
  }

  /* edit formunun submit'ine basilinca emit edilecek */
  onUpdateProjectModelField(projectModelFieldUpdateData: ProjectModelFieldUpdateData) {
    this.projectModelFieldUpdate.emit(projectModelFieldUpdateData);
  }

  /* The sorting of the model will be emitted when changed.*/
  onUpdateProjectModelFields(projectModelFields: ProjectModelFieldPartial[]) {
    this.projectModelFieldsUpdate.emit(projectModelFields);
  }

  /**
   * emit deleting project model field
   *
   * @param {ProjectModelField} projectModelField
   * @memberof NewProjectModelFieldComponent
   */
  onDeleteProjectModelField(projectModelField: ProjectModelField) {
    this.projectModelFieldDelete.emit(projectModelField);
  }

  onNewProjectModelFieldClick(projectModelField: NewProjectModelField) {
    // this.mode = 'read';
    this.projectModelFieldCreate.emit(projectModelField);
  }

  onClickProjectModelUpdate() {
    this.projectModelUpdate.emit(this.projectModel);
  }

  /**
 * event that will be fired whenever list order changed
 *
 * @param {OrderChangeOutput} orderChangeData
 * @memberof DataFieldListComponent
 */
  onOrderChange(orderChangeData: OrderChangeOutput) {
    /* check if any of the items are not updatable */
    const hasNonUpdatableItem = orderChangeData.data.some(item => item.isUpdatable === false);
    if (hasNonUpdatableItem) {
      this.notificationService.createNotification(
        'error',
        'Order Change Failed',
        'Only updatable data fields can be reordered.'
      );

      this.projectModel.fields = [...this.projectModel.fields];
      return;
    }

    /* start index of ordered item */
    let startIndex = orderChangeData.orderStartIndex;

    /* new indices of changed data */
    const updatedProjectModelDataFields = orderChangeData.data.map((item: ProjectModelField) => ({
      id: item.id,
      index: startIndex++
    }));

    /* Emit each update individually */
    this.onUpdateProjectModelFields(updatedProjectModelDataFields);
  }

}
