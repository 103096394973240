<section>
  <h1>
    {{ projectData?.name }}
  </h1>

  <rappider-button-group class="component-preview-button-group"
                         [buttons]="actionButtons"
                         (buttonClick)="onActionButtonClicked($event)"></rappider-button-group>

</section>

<rappider-crud-fields-display [data]="projectData"
                              [editMode]="editMode"
                              [config]="projectFrontPropertiesConfig"></rappider-crud-fields-display>
