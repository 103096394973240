import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { createSelector, Store } from '@ngrx/store';
import { toArray } from 'lodash';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';

@Injectable({
  providedIn: 'root'
})
export class ProjectRoleGuardGuard implements CanActivate {
  constructor(
    private store: Store<any>,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('PROJECT ROLE GUARD');
    const allowedRoles = toArray(route.data);

    return this.store.select(createSelector(
      state => <any[]>state['personAuthority']?.data,
      state => <any>state['auth'].activePerson,
      state => <any>state['activeProject']?.data,
      (
        personAuthorities: any[],
        activePerson: any,
        activeProject: any
      ) => {
        if (!personAuthorities || !activePerson || !activeProject) {
          return null;
        }

        return personAuthorities.some(personAuthority => (
          allowedRoles.includes(personAuthority.role?.name) &&
          personAuthority.principalId === activePerson.id &&
          personAuthority.projectId === activeProject.id
        ));
      }
    )).pipe(
      filter(data => data !== null),
      switchMap(isRoleAllowed => {
        if (isRoleAllowed) {
          return of(true);
        } else {
          return of(this.router.parseUrl(PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH));
        }
      })
    );
  }

}
