import { createAction, createReducer, on } from '@ngrx/store';
import { LivePreviewInformation } from '@rappider/shared/interfaces';

import * as DeployManagementActions from './deploy-management.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as ProjectSourceCodeActions from 'libs/project-source-code/src/lib/project-source-code-data-source/project-source-code.actions';
import { GitActionWorkflowRunDetailResponseDto } from '@rappider/rappider-sdk';

export const deployManagementFeatureKey = 'deployManagement';

export interface DeployManagementState {
  deployInfo: any;
  applicationInfo: any;
  livePreviewInformation: LivePreviewInformation;
  loading: boolean;
  gitActionWorkflowRunDetails: GitActionWorkflowRunDetailResponseDto;
  error: {
    error: any;
    key: string;
    timeStamp: number;
  };
}

export const initialState: DeployManagementState = {
  deployInfo: null,
  applicationInfo: null,
  livePreviewInformation: null,
  loading: false,
  error: null,
  gitActionWorkflowRunDetails: null
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(DeployManagementActions.DeployProject, (state) => ({
    ...state,
    loading: true
  })),
  on(DeployManagementActions.DeployProjectSuccessful, (state, action) => ({
    ...state,
    deployInfo: action.deployInfo,
    loading: false
  })),
  on(DeployManagementActions.DeployProjectFailure, (state, action) => ({
    ...state,
    loading: false,
    error: {
      error: action.error,
      key: action.key,
      timeStamp: action.timeStamp
    }
  })),
  on(DeployManagementActions.GetLivePreviewInformationSuccessful, (state, action) => ({
    ...state,
    livePreviewInformation: action.data
  })),
  on(DeployManagementActions.GetLivePreviewInformationFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timeStamp: action.timeStamp
    }
  })),
  on(DeployManagementActions.SetLivePreviewInformation, (state, action) => ({
    ...state,
    livePreviewInformation: action.data
  })),
  on(DeployManagementActions.GetGitActionWorkflowRunDetailsSuccessful, (state, action) => ({
    ...state,
    gitActionWorkflowRunDetails: action.runDetails
  })),
  on(ProjectSourceCodeActions.DeploySuccessful, (state, action) => ({
    ...state,
    deployInfo: [
      action.payload.deployInfo,
      ...state.deployInfo
    ]
  })),
  on(ProjectSourceCodeActions.MonitorApplicationSuccessful, (state, action) => {
    return {
      ...state,
      applicationInfo: action.payload.monitorApplicationInfo
    }
  }),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
