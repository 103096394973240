import { CheckboxType } from "./checkbox-type.enum";

export const checkboxTypeOptions = [
  {
    key: 'Default',
    value: CheckboxType.Default
  },
  {
    key: 'Button',
    value: CheckboxType.Button
  }
];
