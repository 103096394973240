import { createAction, createReducer, on } from '@ngrx/store';

import * as GlobalErrorActions from './global-error.action';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import { GlobalError } from '../../utils/error.interface';

export const errorFeatureKey = 'error';

export interface GlobalErrorState {
  error: GlobalError;
}

export const initialState: GlobalErrorState = {
  error: null
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(GlobalErrorActions.GlobalErrorAction, (state, action) => ({
    ...state,
    error: {
      errorHeader: action.payload.errorHeader || null,
      errorText: action.payload.errorText,
      key: action.payload.key,
      timeStamp: action.payload.timeStamp
    }
  })),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
