import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProjectInterface } from '@rappider/api-sdk';
import { WORKFLOW_STEP_FUNCTION_DATA_TRANSFORMATIONS_CONFIG } from '@rappider/shared/configs';
import { defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { HeadingType, BreadcrumbOption, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { Subscription } from 'rxjs';
import { WorkflowStepFunction, WorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { KeyValue } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rappider-workflow-step-function-detail',
  templateUrl: './workflow-step-function-detail.component.html',
  styleUrls: ['./workflow-step-function-detail.component.scss']
})
export class WorkflowStepFunctionDetailComponent implements OnInit, OnDestroy {
  /* data transformations config */
  WORKFLOW_STEP_FUNCTION_DATA_TRANSFORMATIONS_CONFIG = WORKFLOW_STEP_FUNCTION_DATA_TRANSFORMATIONS_CONFIG;
  /* workflow step function breadcrumb title */
  title: BreadcrumbOption[];
  /* workflow step function */
  workflowStepFunction: WorkflowStepFunctionWithRelations;
  /* subscriptions */
  subscriptions: Subscription[];
  /* workflow step function id */
  workflowStepFunctionId: string;
  /* active project */
  activeProject: ProjectInterface;
  /* main title of the page */
  mainTitle: HeadingComponentConfig;
  /* heading type */
  PropertyHeadingType = HeadingType;
  /* workflow step function data as key values */
  workflowStepFunctionData: KeyValue<string, any>[];
  displayToolbar = false;
  displayToolbarBackButton = false;
  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getWorkflowStepFunctionIdFromUrl() {
    this.workflowStepFunctionId = this.activatedRoute.snapshot.params['id'];
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToWorkflowStepFunctions()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      this.activeProject = activeProject;
      this.getWorkflowStepFunctionIdFromUrl();
    });
  }

  setTitle() {
    this.mainTitle = {
      content: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_DETAIL_COMPONENT.WORKFLOW_STEP_FUNCTION_DETAIL',
      type: defaultToolbarTitleHeadingSize
    };

    this.title = [
      {
        label: this.activeProject?.name
      },
      {
        label: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.WORKFLOW_STEP_FUNCTION_LIST',
        redirectUrl: `${PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_LIST}`
      },
      {
        label: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_DETAIL_COMPONENT.WORKFLOW_STEP_FUNCTION_DETAIL'
      },
      {
        label: this.workflowStepFunction?.name,
        redirectUrl: `${PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_EDIT}/${this.workflowStepFunctionId}`
      }
    ];
  }

  subscribeToWorkflowStepFunctions() {
    return this.store.select(state => state.workflowStepFunction.data).subscribe((workflowStepFunctions: WorkflowStepFunction[]) => {
      const workflowStepFunction = workflowStepFunctions.find(workflowStepFunction => workflowStepFunction.id === this.workflowStepFunctionId);
      this.workflowStepFunction = workflowStepFunction;
      this.setWorkflowStepFunctionData(workflowStepFunction);
      this.setTitle();
    });
  }

  setWorkflowStepFunctionData(workflowStepFunction: WorkflowStepFunctionWithRelations) {
    this.workflowStepFunctionData = [
      {
        key: this.translateService.instant('SHARED.NAME'),
        value: [workflowStepFunction?.name]
      },
      {
        key: this.translateService.instant('SHARED.TYPE'),
        value: [workflowStepFunction?.type]
      },
      {
        key: this.translateService.instant('PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_DETAIL_COMPONENT.SUBSCRIBED_EVENTS'),
        value: workflowStepFunction?.subscribedEvents?.map(item => item.name)
      },
      {
        key: this.translateService.instant('PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PUBLISHED_EVENTS_ON_SUCCESS'),
        value: workflowStepFunction?.publishedEventsOnSuccess?.map(item => item.name)
      },
      {
        key: this.translateService.instant('PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.PUBLISHED_EVENTS_ON_FAILURE'),
        value: workflowStepFunction?.publishedEventsOnFailure?.map(item => item.name)
      },
      {
        key: this.translateService.instant('SHARED.CODE'),
        value: [workflowStepFunction?.code]
      }
    ];
  }

}
