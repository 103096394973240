import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataSchemaTreeFieldNodeWithRelations, ProjectModel, UiDataStoreWithRelations, UiWorkflowStepFunctionWithRelations, WorkflowEventWithRelations, WorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { SetActiveDiagramItem } from '../../state/diagram-state/diagram.actions';
import ArrayStore from 'devextreme/data/array_store';
import { DiagramAutoLayoutConfig } from '../../utils/definitions/diagram-editor/diagram-auto-layout.config';
import { DiagramUIDataEventItemConfig } from '../../utils/definitions/diagram-editor/diagram-ui-data-event-item.config';
import { DiagramUIStepFunctionWithRelationsItemConfig } from '../../utils/definitions/diagram-editor/diagram-ui-step-function-with-relations-item.config';
import { DiagramNodesConfig } from '../../utils/definitions/diagram-editor/diagram-nodes.config';
import { DiagramEditorConfig } from '../../utils/definitions/diagram-editor/diagram-editor.config';
import { DiagramTreeItem } from '../../utils/interfaces/diagram-editor/diagram-tree-item.interface';
import { DiagramUIScheduledDataEventItemConfig } from '../../utils/definitions/diagram-editor/diagram-ui-scheduled-data-event-item.config';
import { DxDiagramComponent } from 'devextreme-angular';
import { WorkflowItemInDiagram } from '../../utils/workflow-item-in-diagram.interface';
import { ExclusiveConditionItemConfig } from '../../utils/definitions/diagram-editor/diagram-exclusive-condition-item.config';
import { DiagramUIStepFunctionItemConfig } from '../../utils/definitions/diagram-editor/diagram-ui-step-function-item.config';

@Component({
  selector: 'rappider-diagram-editor',
  templateUrl: './diagram-editor.component.html',
  styleUrls: ['./diagram-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DiagramEditorComponent implements OnChanges {
  @ViewChild('diagram', { static: false }) diagram: DxDiagramComponent;

  /* diagram tree */
  @Input() diagramTree: DiagramTreeItem[];
  @Input() uiDataStores: UiDataStoreWithRelations[];
  @Input() uiWorkflowStepFunctions: UiWorkflowStepFunctionWithRelations[];
  @Input() activeItem: WorkflowItemInDiagram;
  @Input() isDiagramEditorLoading: boolean;
  @Input() projectModels: ProjectModel[];
  @Input() workflowStepFunctions: WorkflowStepFunctionWithRelations[];
  @Input() workflowEvents: WorkflowEventWithRelations[];

  @Output() formPanelTabIndexChange = new EventEmitter();

  formPanelTabIndex = 0;

  /* data source for dxDiagram */
  dataSource: ArrayStore;

  /* Diagram Editor Configs */
  DiagramEditorConfig = DiagramEditorConfig;
  DiagramAutoLayoutConfig = DiagramAutoLayoutConfig;
  DiagramNodesConfig = DiagramNodesConfig;
  /* Custom Diagram Item Configs */
  DiagramUIDataEventItemConfig = DiagramUIDataEventItemConfig;
  DiagramUIScheduledDataEventItemConfig = DiagramUIScheduledDataEventItemConfig;
  DiagramUIStepFunctionWithRelationsItemConfig = DiagramUIStepFunctionWithRelationsItemConfig;
  DiagramUIStepFunctionItemConfig = DiagramUIStepFunctionItemConfig;
  ExclusiveConditionItemConfig = ExclusiveConditionItemConfig;
  activeItemId: string;

  constructor(
    private store: Store<any>
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.diagramTree) {
      this.setDiagramTreeDataSource();
    }
    if (changes.activeItem) {
      if (this.activeItem?.item) {
        this.scrollToActiveItem(this.activeItem?.item.id);
      }
    }
  }

  /**
   * creates a data source that is compatible with dxDiagram from tree input
   *
   * @memberof DiagramEditorComponent
   */
  setDiagramTreeDataSource() {
    this.dataSource = new ArrayStore({
      key: 'this',
      data: this.diagramTree
    });
  }

  /**
   * A function that is executed after a shape or connector is clicked.
   *
   * @param {*} nodeData
   * @memberof DiagramEditorComponent
   */
  onSelectionChanged(nodeData) {
    this.formPanelTabIndex = 0;
    this.formPanelTabIndexChange.emit(this.formPanelTabIndex);

    /* data of selected node's on diagram */
    const selectedNodeData = nodeData.items[0]?.dataItem || null;

    if (selectedNodeData) {
      const selectedItem = {
        item: selectedNodeData.item,
        type: selectedNodeData.type
      };
      /* delete subitem data coming from tree */
      delete selectedItem.item.items;
      this.activeItemId = selectedItem.item.id;
      if (this.activeItemId !== selectedItem.item.id) {
        this.store.dispatch(SetActiveDiagramItem({ payload: { activeItem: selectedItem } }));
      }
    }
  }
  customizeTextStyle = (node) => {
    if (node.type === "uiStepFunctionWithRelations" || node.type === "uiStepFunction") {
      return { transform: 'translateY(77px)'};
    }
    return {};
  };

  onItemClick(nodeData) {
    this.formPanelTabIndex = 0;
    this.formPanelTabIndexChange.emit(this.formPanelTabIndex);

    /* data of selected node's on diagram */
    const selectedNodeData = nodeData?.item?.dataItem || null;

    if (selectedNodeData) {
      const selectedItem = {
        item: selectedNodeData.item,
        type: selectedNodeData.type
      };
      /* delete subitem data coming from tree */
      delete selectedItem.item.items;
      this.store.dispatch(SetActiveDiagramItem({ payload: { activeItem: selectedItem } }));
    }
  }

  scrollToActiveItem(activeItemId: string) {
    if (this.activeItemId !== activeItemId) {
      this.activeItemId = activeItemId;
      setTimeout(() => {
        const activeItem = this.diagram?.instance.getItemByKey(activeItemId);
        this.diagram?.instance?.scrollToItem(activeItem);
        this.diagram?.instance?.setSelectedItems([activeItem]);
        this.diagram?.instance?.focus();
      }, 10);
    }
  }

  setUIDataStoreSubscriptions(nodes: DataSchemaTreeFieldNodeWithRelations[]) {
    return nodes?.map(node => node.field?.name).join('.');
  }

  /**
   * used for programatically set the position of each ui data store subscription in diagram editor
   *
   * @param {number} index
   * @return {*}
   * @memberof DiagramEditorComponent
   */
  setUIDataStoreSubscriptionPosition(index: number): string {
    return `${25 + (index * 5)}%`;
  }

  findProjectModelNameById(id: string): string {
    return this.projectModels?.find(model => id === model.id)?.name;
  }

  findWorkflowStepFunctionById(id: string): WorkflowStepFunctionWithRelations {
    return this.workflowStepFunctions?.find(wsf => wsf.id === id);
  }

  findWorkflowEventFunctionById(id: string): WorkflowEventWithRelations {
    return this.workflowEvents?.find(event => event.id === id);
  }

  navigatePanelCommentsTab() {
    this.formPanelTabIndex = 1;
    this.formPanelTabIndexChange.emit(this.formPanelTabIndex);
  }
}
