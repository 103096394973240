/* angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

/* 3rd Part Modules */
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { ContentTreeRendererModule } from 'libs/content-tree-renderer/src/lib/content-tree-renderer.module';

/* Internal Modules */
import {
  RappiderMenuModule,
  RappiderButtonModule,
  RappiderHeadingModule,
  RappiderListGridModule,
  RappiderEditFormModule,
  RappiderTitleModule,
  RappiderTagModule,
  RappiderParagraphModule,
  RappiderButtonGroupModule,
  RappiderRadioModule,
  RappiderTitleBarModule,
  RappiderAlertModule,
  RappiderTextboxModule,
  RappiderTitleToolbarModule,
  RappiderModalModule,
  RappiderSpinModule,
  RappiderSearchModule,
  RappiderImageModule
} from '@rappider/rappider-components';
import { DataSchemaModule } from 'libs/project/src/lib/modules/data-schema/data-schema.module';

/* state libs */
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ComponentDefinitionEffects } from './state/component-definition.effects';
import * as fromComponentDefinition from './state/component-definition.reducer';

/* Components */
import { ComponentBrowserComponent } from './layouts/component-browser/component-browser.component';
import { ComponentSearchComponent } from './components/component-search/component-search.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { CreateComponentDefinitionComponent } from './components/create-component-definition/create-component-definition.component';
import { ComponentDefinitionsPreviewComponent } from './components/component-definitions-preview/component-definitions-preview.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CreateCategoryComponent } from './components/create-category/create-category.component';

/* 3rd party */
import { NzInputModule } from 'ng-zorro-antd/input';

/* components */
import {
  ComponentDefinitionDetailPageComponent
} from './components/component-definition-detail-page/component-definition-detail.page.component';
import {
  ComponentInputDefinitionSectionComponent
} from './components/component-definition-detail-page/component-input-definition-section/component-input-definition-section.component';
import {
  ComponentOutputDefinitionSectionComponent
} from './components/component-definition-detail-page/component-output-definition-section/component-output-definition-section.component';
import {
  SampleInputSectionComponent
} from './components/component-definition-detail-page/sample-input-section/sample-input-section.component';
import {
  DefaultInputSectionComponent
} from './components/component-definition-detail-page/default-input-section/default-input-section.component';
import {
  EditComponentDefinitionComponent
} from './components/component-definition-detail-page/edit-component-definition/edit-component-definition.component';
import {
  ExampleSettingsDisplayComponent
} from './components/component-definition-detail-page/example-settings-display/example-settings-display.component';
import {
  SaveAsExampleSectionComponent
} from './components/component-definition-detail-page/save-as-example-section/save-as-example-section.component';
import {
  DeleteComponentDefinitionComponent
} from './components/component-definition-detail-page/delete-component-definition/delete-component-definition.component';
import {
  ElementSearchResultComponent
} from './components/element-search-result/element-search-result.component';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromComponentDefinition.componentDefinitionFeatureKey,
      fromComponentDefinition.reducer,
      { initialState: fromComponentDefinition.initialState }
    ),
    EffectsModule.forFeature([ComponentDefinitionEffects]),
    NzSkeletonModule,
    NzTabsModule,
    NzAlertModule,
    NzCardModule,
    NzTableModule,
    NzPaginationModule,
    NzPopconfirmModule,
    RappiderModalModule,
    NzCollapseModule,
    NzSkeletonModule,
    NzLayoutModule,
    NzSelectModule,
    NzDividerModule,
    NzTagModule,
    NzEmptyModule,
    NzButtonModule,
    NzInputModule,
    NzToolTipModule,
    NzAutocompleteModule,
    TranslateModule,
    FormsModule,
    RappiderMenuModule,
    RappiderButtonModule,
    RappiderHeadingModule,
    RappiderListGridModule,
    RappiderEditFormModule,
    RappiderTitleModule,
    RappiderTagModule,
    RappiderParagraphModule,
    RappiderButtonGroupModule,
    RappiderRadioModule,
    DataSchemaModule,
    RappiderTitleBarModule,
    RappiderAlertModule,
    RappiderTextboxModule,
    RappiderTitleToolbarModule,
    RappiderSpinModule,
    RappiderSearchModule,
    ContentTreeRendererModule,
    RappiderImageModule
  ],
  declarations: [
    ComponentDefinitionsPreviewComponent,
  ],
  exports: [
    ComponentDefinitionsPreviewComponent
  ]
})
export class ComponentDefinitionComponentsModule { }
