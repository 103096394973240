import { cloneDeep } from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PROJECT_MEMBERS_CONFIG, PROJECT_MANAGERS_CONFIG, PROJECT_OWNERS_CONFIG, Authorities } from '@rappider/shared/configs';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PersonInterface, PersonProjectRoleApi, ProjectInterface } from '@rappider/api-sdk';
import { ProjectRoleEvents } from './models/project-role-events.enum';
import { ASSIGN_MANAGER_CONFIG, UNASSIGN_MANAGER_CONFIG, EDIT_ROLES_CONFIG } from './models/project-members';
import { ChangeRoleEvent, RolesModal } from './models/project-members.interface';
import { ColorService, NotificationService } from '@rappider/services';
import { Person, PersonWithRelations, ProjectRole } from '@rappider/rappider-sdk';
import { Authority, defaultToolbarTitleHeadingSize, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { CreateRoles, AssignManager, DeleteRoles, UnassignManager } from '../../states/project-member-state/project-member.actions';
import { UserInvitationButtonMode } from '../user-invitations/utils/user-invitations-button-mode.enum';
import { CancelInvitation } from '../../states/user-invitations-state/user-invitation.actions';
import { ResendInvitation } from '../../states/user-invitations-state/user-invitation.actions';
import { InviteUser } from '../../states/user-invitations-state/user-invitation.actions';

@Component({
  selector: 'rappider-project-members',
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.scss']
})
export class ProjectMembersComponent implements OnInit, OnDestroy {

  isLoading = false;
  activeProject: ProjectInterface;
  activePerson: PersonInterface;
  members = [];
  owners = [];
  managers = [];
  mainTitle: HeadingComponentConfig;
  title = [];
  PROJECT_MEMBERS_CONFIG = PROJECT_MEMBERS_CONFIG;
  PROJECT_MANAGERS_CONFIG = PROJECT_MANAGERS_CONFIG;
  PROJECT_OWNERS_CONFIG = PROJECT_OWNERS_CONFIG;
  ASSIGN_MANAGER_CONFIG = ASSIGN_MANAGER_CONFIG;
  UNASSIGN_MANAGER_CONFIG = UNASSIGN_MANAGER_CONFIG;
  EDIT_ROLES_CONFIG = EDIT_ROLES_CONFIG;
  rolesModalConfig: RolesModal = {
    visible: false,
    data: null,
    width: '60%'
  };
  subscriptions: Subscription[] = [];
  displayToolbar = false;
  displayToolbarBackButton = false;

  projectMembers: PersonWithRelations[];
  projectRoles: ProjectRole[] = [];
  roleColors: Record<string, string>;

  constructor(
    private store: Store<any>,
    private personProjectRoleApi: PersonProjectRoleApi,
    private notificationService: NotificationService,
    private colorService: ColorService
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToProjectMembers(),
      this.subscribeToProjectRoles(),
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      /* get active project */
      this.activeProject = activeProject;
      if (activeProject) {
        /* set component title */
        this.mainTitle = {
          content: 'SHARED.MEMBERS',
          type: defaultToolbarTitleHeadingSize
        };
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: 'SHARED.MEMBERS'
          }
        ];
        this.subscribeToActivePerson();
      }
    });
  }

  subscribeToActivePerson() {
    return this.store.select(state => state.auth.activePerson).subscribe((activePerson: PersonInterface) => {
      if (activePerson) {
        this.activePerson = activePerson;
      }
    });
  }

  subscribeToProjectMembers() {
    return this.store.select(state => state.activeProject?.data).subscribe((activeProject: any) => {
      if (activeProject) {
        this.projectMembers = activeProject.people;
        this.getMembers();
      }
    });
  }

  subscribeToProjectRoles() {
    return this.store.select(state => state.projectRole.data).subscribe(roles => {
      this.projectRoles = roles;
      if (this.projectRoles) {
        this.setRoleColors();
      }
    });
  }

  setRoleColors() {
    const roles = this.projectRoles?.map(role => role.title);
    this.roleColors = this.colorService.assignColorsToRoles(roles);
    if (this.projectMembers) {
      this.getMembers();
    }
  }

  getMembers() {
    if (this.projectMembers) {
      this.owners = this.projectMembers
        .filter(member => member.authorities?.some(authority => authority.name === Authority.ProjectOwner));
      this.managers = this.projectMembers
        .filter(member => member.authorities?.some(authority => authority.name === Authority.ProjectManager));

      this.members = cloneDeep(this.projectMembers)?.map(member => {
        if (member.authorities?.some(auth => auth.name === Authorities.ProjectOwner)) {
          return {
            ...member,
            roleNames: [
              {
                title: 'Owner',
                color: this.colorService.getColorForRole(Authorities.ProjectOwner)
              }
            ]
          };
        } else if (member.authorities?.some(auth => auth.name === Authorities.ProjectManager)) {
          return {
            ...member,
            roleNames: [
              {
                title: 'Manager',
                color: this.colorService.getColorForRole(Authorities.ProjectManager)
              }
            ]
          };
        } else {
          if (member?.roles?.length > 0) {
            const roleNames = member.roles.map(role => {
              const color = this.colorService.getColorForRole(role?.title);
              return {
                title: role.title,
                color: color
              };
            });
            return {
              ...member,
              roleNames: roleNames
            };
          } else {
            return {
              ...member,
              roleNames: [
                {
                  title: 'Member',
                  color: this.colorService.getColorForRole(Authorities.ProjectMember)
                }
              ]
            };
          }
        }
      });

      this.members = [...this.members];

      this.checkPersonAuth();
    } else {
      this.notificationService.createNotification(
        'error',
        'SHARED.ERROR',
        'PROJECT_MODULE.PROJECT_MEMBERS_COMPONENT.COULD_NOT_GET_MEMBERS'
      );
    }
  }

  /**
   * Checks the authority of the active person
   * and makes config changes according to the authority.
   */
  checkPersonAuth() {
    this.PROJECT_MANAGERS_CONFIG.itemActions = [];
    this.PROJECT_MEMBERS_CONFIG.itemActions = [];
    const isManager = this.managers.some(manager => this.activePerson.id === manager.id);
    const isOwner = this.owners.some(owner => this.activePerson.id === owner.id);

    if (isManager || isOwner) {
      this.PROJECT_MEMBERS_CONFIG.itemActions = [
        ...this.PROJECT_MEMBERS_CONFIG.itemActions,
        this.EDIT_ROLES_CONFIG
      ];
    }
    if (isOwner) {
      this.PROJECT_MEMBERS_CONFIG.itemActions = isManager === true ? [...this.PROJECT_MEMBERS_CONFIG.itemActions]
        : [
          ...this.PROJECT_MEMBERS_CONFIG.itemActions,
          this.ASSIGN_MANAGER_CONFIG
        ];
      this.PROJECT_MANAGERS_CONFIG.itemActions = [
        ...this.PROJECT_MANAGERS_CONFIG.itemActions,
        this.UNASSIGN_MANAGER_CONFIG,
      ];
    }
    this.PROJECT_MEMBERS_CONFIG = { ...this.PROJECT_MEMBERS_CONFIG };
    this.PROJECT_MANAGERS_CONFIG = { ...this.PROJECT_MANAGERS_CONFIG };
  }

  onItemClick(event) {
    switch (event.action.name) {
      case ProjectRoleEvents.AssignManager:
        this.assignManager(event.data);
        break;
      case ProjectRoleEvents.UnassignManager:
        this.unassignManager(event.data);
        break;
      case ProjectRoleEvents.EditRoles:
        this.editRoles(event.data);
        break;
      default:
        break;
    }
  }

  // #region ASSIGN AND UNASSIGN AS MANAGER

  assignManager(person: Person) {
    this.store.dispatch(new AssignManager({ personId: person.id }));
  }

  unassignManager(person: Person) {
    this.store.dispatch(new UnassignManager({ personId: person.id }));
  }

  // #endregion ASSIGN AND UNASSIGN AS MANAGER

  // #region EDIT-ROLE FUNCTIONS

  editRoles(person: Person) {
    this.rolesModalConfig = {
      ...this.rolesModalConfig,
      data: person,
      visible: true
    };
  }

  /**
   * When the person's role changes, it directs them to functions to compare their former roles and selected roles.
   */
  onRoleChange(event: ChangeRoleEvent) {
    const selectedRoles = event.selectedRoles;
    const person = event.person;
    const oldRoles = (<any>person)?.roles;
    this.findNewRoles(oldRoles, person.id, selectedRoles);
    this.findDeletedRoles(oldRoles, person?.id, selectedRoles);
  }

  /**
   * Finds if there is a newly added role among the selected roles
   * and pushes it to the roleIdsToAdd array.
   */
  findNewRoles(oldRoles, personId, selectedRoles) {
    const roleIdsToAdd = selectedRoles?.filter(selectedRole => !oldRoles?.some(oldRole => oldRole.id === selectedRole.id))
      .map(filteredSelectedRoles => filteredSelectedRoles.id);
    this.addNewRoles(personId, roleIdsToAdd);
  }

  /**
   * If there is a deleted role among the selected roles,
   * it finds and pushes it to the roleIdsToDelete array.
   */
  findDeletedRoles(oldRoles, personId, selectedRoles) {
    const roleIdsToDelete = oldRoles.filter(oldRole => !selectedRoles?.some(selectedRole => selectedRole.id === oldRole.id))
      .map(oldRole => oldRole.id);
    this.deleteOldRoles(personId, roleIdsToDelete);
  }

  /**
   * Sends a request to the bulk create endpoint
   * by assigning the role IDs requested to be added to the person to postBody.
   */
  addNewRoles(personId, roleIdsToAdd) {
    const postBody = {
      projectId: this.activeProject.id,
      personId: this.activePerson.id,
      personProjectRoles: []
    };
    roleIdsToAdd.forEach(id => {
      postBody.personProjectRoles.push(
        {
          personId: personId,
          projectRoleId: id
        }
      );
    });
    if (postBody.personProjectRoles.length) {
      this.store.dispatch(new CreateRoles({ body: postBody }));
    }
  }

  /**
   * Sends a request to the bulk delete endpoint
   * by assigning the role IDs requested to be deleted from the person to postBody.
   */
  deleteOldRoles(personId, roleIdsToDelete) {
    const postBody = {
      projectId: this.activeProject.id,
      personId: this.activePerson.id,
      personProjectRoles: []
    };
    roleIdsToDelete.forEach(id => {
      postBody.personProjectRoles.push(
        {
          personId: personId,
          projectRoleId: id
        }
      );
    });
    if (postBody.personProjectRoles.length) {
      this.store.dispatch(new DeleteRoles({ body: postBody }));
    }
  }

  // #endregion EDIT-ROLE FUNCTIONS

}
