import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  CreateCheckout = '[Payment] CreateCheckout',
  CreateCheckoutSuccessful = '[Payment] CreateCheckoutSuccessful',
  CreateCheckoutFailure = '[Payment] CreateCheckoutFailure',
  SubscriptionAlertVisibility = '[Payment] SubscriptionAlertVisibility'
}

export const CreateCheckout = createAction(
  ActionTypes.CreateCheckout,
  props<{ payload: { subscriptionType: string } }>()
);

export const CreateCheckoutSuccessful = createAction(
  ActionTypes.CreateCheckoutSuccessful
);

export const CreateCheckoutFailure = createAction(
  ActionTypes.CreateCheckoutFailure
);

export const SubscriptionAlertVisibility = createAction(
  ActionTypes.SubscriptionAlertVisibility,
  props<{ payload: { subscriptionAlertVisibility: boolean } }>()
);
