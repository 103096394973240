import { NgxMaskModule } from 'ngx-mask';
/* angular */
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { Store } from '@ngrx/store';

/* 3rd modules */
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

/* ng zorro */
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
registerLocaleData(en);
import { NzImageModule } from 'ng-zorro-antd/image';

/* ngx quill */
import { QuillModule } from 'ngx-quill';
import 'quill-mention';

/* color picker */
import { ColorPickerModule } from 'ngx-color-picker';

/* Ngx translate */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* ngx lottie */
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

/* ng zorro */
import { NzModalModule } from 'ng-zorro-antd/modal';

/* Angular Guidance tour package */
import { JoyrideModule } from 'ngx-joyride';

/* internal modules */
import { SDKBrowserModule } from '@rappider/api-sdk';
import { GuidanceModule } from '@rappider/guidance';
import { ApiModule } from '@rappider/rappider-sdk';
import {
  RappiderAlertModule,
  RappiderFeedbackModule,
  RappiderMenuModule,
  RappiderMonacoEditorModule,
  RappiderCardsModule,
  RappiderPdfViewerModule,
  RappiderSpinModule,
  RappiderModalModule,
  RappiderButtonModule,
  RappiderSwitchModule,
  RappiderHeadingModule,
  RappiderLottiePlayerModule,
  RappiderChartBarComponent,
  RappiderChartLineComponent,
  RappiderChartPieComponent,
  RappiderChartBaseComponent,
  RappiderIconModule,
  RappiderHtmlViewerModule,
  RappiderParagraphModule,
  RappiderDividerModule,
  RappiderLabelModule
} from '@rappider/rappider-components';

import { RappiderProjectRequirementsModule } from 'libs/project/src/lib/modules/project-requirements/project-requirements.module';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { ProjectModule } from '@rappider/project';
import { ThemeModule } from '@rappider/theme';
import { ErrorModule } from '@rappider/error';

/* path definitions */
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

/* State */
import { AppStateModule } from './state/app.state.module';

/* Guards */
import { AuthGuard } from '@rappider/authentication/guards';
import {
  VerifyPersonGuard,
  ActiveProjectGuard,
  ActivePersonGuard,
  IsAdminGuard,
  PersonGuard,
} from '@rappider/shared/guards';

/* Interceptors */
import { ErrorInterceptor } from '@rappider/shared/interceptors';
import { TokenInterceptor } from '@rappider/authentication/interceptors';

/* Components */
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ShowcaseComponent } from './components/showcase/showcase.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';

/* app actions */
import { LoadApp } from './state/app.actions';

/* environment */
import { environment } from '../environments/environment';

import { AppSearchComponent } from './components/app-search/app-search.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { AiIntegrationModule } from '@rappider/ai-integration';
import { RappiderNotificationModule } from 'libs/components/src/lib/components/notification/notification.module';
import { AppBuildPageComponent } from './components/app-build-page/app-build-page.component';

const monacoEditorConfig = {
  baseUrl: 'assets',
  defaultOptions: { scrollBeyondLastLine: false },
  onMonacoLoad: () => {
    console.log((<any>window).monaco);
  },
};

/* routes */
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH,
  },
  {
    path: 'build',
    component: AppBuildPageComponent,
    data: { title: 'Build Information' }
  },
  {
    path: 'showcase',
    component: ShowcaseComponent
  },
  {
    path: 'admin-dashboard',
    loadChildren: () =>
      import('libs/admin-dashboard/src/lib/admin-dashboard.module').then(
        (module) => module.AdminDashboardModule
      ),
    canActivate: [AuthGuard, VerifyPersonGuard, IsAdminGuard, PersonGuard],
    data: { title: 'Admin Dashboard', showTitle: true },
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('libs/authentication/src/lib/authentication.module').then(
        (module) => module.AuthenticationModule
      ),
  },
  {
    path: 'ai',
    loadChildren: () =>
      import('libs/ai-integration/src/lib/ai-integration.module').then(
        (module) => module.AiIntegrationModule
      ),
    canActivate: [
      AuthGuard,
      ActiveProjectGuard,
      VerifyPersonGuard,
      PersonGuard,
    ],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('libs/profile/src/lib/profile.module').then(
        (module) => module.ProfileModule
      ),
    data: { title: 'Profile', showTitle: false },
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      PersonGuard
    ]
  },
  {
    path: 'component-browser',
    loadChildren: () =>
      import(
        'libs/component-definition/src/lib/component-definition.module'
      ).then((module) => module.ComponentDefinitionModule),
    canActivate: [AuthGuard, VerifyPersonGuard, PersonGuard],
    data: { title: 'Components' },
  },
  {
    path: 'content-editor',
    loadChildren: () =>
      import('libs/content-editor/src/lib/content-editor.module').then(
        (module) => module.ContentEditorModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
    data: { title: 'User Interface | Editor', showPageTitle: true },
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('libs/pages/src/lib/pages.module').then(
        (module) => module.PagesModule
      ),
    canActivate: [
      AuthGuard,
      ActivePersonGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
    data: { title: 'User Interface | Pages' },
  },
  {
    path: 'modules',
    loadChildren: () =>
      import('libs/module/src/lib/module.module').then(
        (module) => module.ModuleModule
      ),
    canActivate: [
      AuthGuard,
      ActivePersonGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
    data: { title: 'User Interface | Modules' },
  },
  {
    path: 'tenant',
    loadChildren: () =>
      import('libs/tenant/src/lib/tenant.module').then(
        (module) => module.TenantModule
      ),
    canActivate: [AuthGuard],
    data: { title: 'Tenant' },
  },
  {
    path: 'template-browser',
    loadChildren: () =>
      import('libs/template-browser/src/lib/template-browser.module').then(
        (module) => module.TemplateBrowserModule
      ),
    canActivate: [AuthGuard, PersonGuard],
    data: { title: 'Templates' },
  },
  {
    path: 'workflow-template',
    loadChildren: () =>
      import('libs/workflow-templates/src/lib/workflow-templates.module').then(
        (module) => module.WorkflowTemplatesModule
      ),
    canActivate: [
      AuthGuard,
      ActiveProjectGuard,
      VerifyPersonGuard,
      PersonGuard,
    ],
    data: { title: 'UI Business Process', showPageTitle: true },
  },
  {
    path: 'page-templates',
    loadChildren: () =>
      import('libs/page-templates/src/lib/page-templates.module').then(
        (module) => module.PageTemplatesModule
      ),
    canActivate: [
      AuthGuard,
      ActiveProjectGuard,
      VerifyPersonGuard,
      PersonGuard,
    ],
    data: { title: 'Page Templates' },
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('libs/project/src/lib/project.module').then(
        (module) => module.ProjectModule
      ),
    canActivate: [AuthGuard, PersonGuard],
    data: { title: '' },
  },
  {
    path: 'file-management',
    loadChildren: () =>
      import('libs/file-management/src/lib/file-management.module').then(
        (module) => module.FileManagementModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'font-browser',
    loadChildren: () =>
      import(
        'libs/font-picker-browser/src/lib/font-picker-browser.module'
      ).then((module) => module.FontPickerBrowserModule),
    canActivate: [AuthGuard, PersonGuard],
    data: { title: 'Fonts' },
  },
  {
    path: 'ui-workflow-diagram',
    loadChildren: () =>
      import('libs/diagram-editor/src/lib/diagram-editor.module').then(
        (module) => module.DiagramEditorModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
    data: { title: 'UI Business Process', showPageTitle: true },
  },
  {
    path: 'project-management',
    loadChildren: () =>
      import(
        'libs/agile-project-management/src/lib/agile-project-management.module'
      ).then((module) => module.AgileProjectManagementModule),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'source-code',
    loadChildren: () =>
      import(
        'libs/project-source-code/src/lib/project-source-code.module'
      ).then((module) => module.ProjectSourceCodeModule),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'db-diagram-editor',
    loadChildren: () =>
      import('libs/db-diagram/src/lib/db-diagram.module').then(
        (module) => module.DbDiagramModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
    data: { title: 'Data Models', showPageTitle: true },
  },
  {
    path: 'custom-function',
    loadChildren: () =>
      import('libs/custom-function/src/lib/custom-function.module').then(
        (module) => module.CustomFunctionModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'project-requirements',
    loadChildren: () =>
      import(
        'libs/project-requirements/src/lib/project-requirements.module'
      ).then((module) => module.ProjectRequirementsModule),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'workflow-diagram',
    loadChildren: () =>
      import('libs/workflow-diagram/src/lib/workflow-diagram.module').then(
        (module) => module.WorkflowDiagramModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
    data: { title: 'Server Side Workflows', showPageTitle: true },
  },
  {
    path: 'workflow-step-function',
    loadChildren: () =>
      import(
        'libs/workflow-step-function/src/lib/workflow-step-function.module'
      ).then((module) => module.WorkflowStepFunctionModule),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'client-comment',
    loadChildren: () =>
      import('libs/client-comment/src/lib/client-comment.module').then(
        (module) => module.ClientCommentModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('libs/categories/src/lib/categories.module').then(
        (module) => module.CategoriesModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'project-design/:projectId',
    loadChildren: () =>
      import('libs/project-design/src/lib/project-design.module').then(
        (module) => module.ProjectDesignModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('libs/payment/src/lib/payment.module').then(
        (module) => module.PaymentModule
      ),
    canActivate: [
      AuthGuard,
      VerifyPersonGuard,
      ActiveProjectGuard,
      PersonGuard,
    ],
  },
  {
    path: 'error/:errorKey',
    component: ErrorPageComponent,
  },
];

export function initApplication(store: Store<any>) {
  return () =>
    new Promise((resolve) => {
      store.dispatch(new LoadApp());
      resolve(true);
    });
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    HeaderComponent,
    FooterComponent,
    ShowcaseComponent,
    RightSidebarComponent,
    HeaderMenuComponent,
    AppSearchComponent,
    AppBuildPageComponent,
  ],
  imports: [
    BrowserModule,
    NzLayoutModule,
    NzIconModule,
    NzDropDownModule,
    NzAvatarModule,
    NzBadgeModule,
    NzSkeletonModule,
    NzDrawerModule,
    NzButtonModule,
    NzToolTipModule,
    NzListModule,
    NzTabsModule,
    NzBreadCrumbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzNotificationModule,
    RappiderNotificationModule,
    SDKBrowserModule.forRoot(),
    JoyrideModule.forRoot(),
    ApiModule.forRoot({
      rootUrl: environment.apiBaseUrl,
    }),
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
      },
    }),
    QuillModule.forRoot(),
    LottieModule.forRoot({ player: () => player }),
    ColorPickerModule,
    AppStateModule,
    RappiderFeedbackModule,
    RappiderAlertModule,
    RappiderMenuModule,
    NzImageModule,
    GuidanceModule,
    NzModalModule,
    RappiderPdfViewerModule,
    RappiderSpinModule,
    ProjectModule,
    RappiderProjectRequirementsModule,
    RappiderMonacoEditorModule.forRoot(monacoEditorConfig),
    RappiderCardsModule,
    NgxMaskModule.forRoot(),
    NzDropDownModule,
    RappiderModalModule,
    ThemeModule,
    RappiderButtonModule,
    RappiderSwitchModule,
    RappiderHeadingModule,
    NzTagModule,
    NzEmptyModule,
    AiIntegrationModule,
    RappiderLottiePlayerModule,
    ErrorModule,
    RappiderChartBarComponent,
    RappiderChartLineComponent,
    RappiderChartPieComponent,
    RappiderChartBaseComponent,
    RappiderIconModule,
    RappiderHtmlViewerModule,
    RappiderParagraphModule,
    RappiderDividerModule,
    RappiderLabelModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },

    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [Store],
    },
    NzModalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
