import { Injectable } from '@angular/core';
import { Cell, Graph, Node } from '@antv/x6';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor() { }

  registerWorkflowEvent() {
    Graph.registerNode(
      'workflowEvent',
      {
        inherit: 'circle',
        markup: [
          {
            tagName: 'circle',
            selector: 'body'
          },
          {
            tagName: 'text',
            selector: 'label'
          },
        ],
        attrs: {
          btn: {
            event: 'node:add-step-function',
          },
          body: {
            strokeWidth: 2,
            stroke: '#1890ff',
            fill: 'transparent'
          },
          label: {
            fontSize: 12,
            fill: 'var(--text-color)',
            screenTop: 25,
            refDy: -5
          },
          '.addText': {
            fontSize: 20,
            fontWeight: 800,
            textAnchor: 'middle',
            fill: '#1890ff',
            text: '+',
            fontFamily: 'Times New Roman'
          }
        }
      },
      true
    );

    Graph.registerNode(
      'workflowEventSuccess',
      {
        inherit: 'circle',
        markup: [
          {
            tagName: 'circle',
            selector: 'body'
          },
          {
            tagName: 'text',
            selector: 'label'
          },
        ],
        attrs: {
          body: {
            strokeWidth: 2,
            stroke: '#1DD109',
            fill: 'transparent'
          },
          label: {
            fontSize: 12,
            fill: 'var(--text-color)',
            screenTop: 25,
            refDy: -5
          },
        }
      },
      true
    );

    Graph.registerNode(
      'workflowEventFailure',
      {
        inherit: 'circle',
        attrs: {
          body: {
            strokeWidth: 2,
            stroke: '#FF0000',
            fill: 'transparent'
          },
          label: {
            fontSize: 12,
            fill: 'var(--text-color)',
            screenTop: 25,
            refDy: -5
          },
        },
      },
      true
    );

    Graph.registerNode(
      'workflowEventFailurePlaceholder',
      {
        inherit: 'circle',
        attrs: {
          body: {
            strokeWidth: 2,
            stroke: '#FF0000',
            strokeDasharray: 5,
            fill: 'transparent',
            refDy: -38
          },
          label: {
            fontSize: 12,
            fill: 'var(--text-color)',
            screenTop: 25,
            refDy: 5
          },
        },
      },
      true
    );

    Graph.registerNode(
      'workflowEventSuccessPlaceholder',
      {
        inherit: 'circle',
        attrs: {
          body: {
            strokeWidth: 2,
            stroke: '#1DD109',
            strokeDasharray: 5,
            fill: 'transparent',
            refDy: -38
          },
          label: {
            fontSize: 12,
            fill: 'var(--text-color)',
            screenTop: 25,
            refDy: 5
          },
        },
      },
      true
    );

    Graph.registerNode(
      'workflowEventPlaceholder',
      {
        inherit: 'circle',
        attrs: {
          body: {
            strokeWidth: 2,
            stroke: '#1890ff',
            strokeDasharray: 5,
            fill: 'transparent',
            refDy: -38
          },
          label: {
            fontSize: 12,
            fill: 'var(--text-color)',
            screenTop: 25,
            refDy: 5
          },
        },
      },
      true
    );
  }

  registerWorkflowStepFunction() {
    Graph.registerNode(
      'workflowStepFunction',
      {
        inherit: 'rect',
        markup: [
          {
            tagName: 'rect',
            selector: 'body',
          },
          {
            tagName: 'text',
            selector: 'label',
          },
          {
            tagName: 'g',
            attrs: {
              class: 'btn add-success',
            },
            children: [
              {
                tagName: 'circle',
                attrs: {
                  class: 'add',
                },
              },
              {
                tagName: 'text',
                attrs: {
                  class: 'add',
                }
              },
            ],
          },
          {
            tagName: 'g',
            attrs: {
              class: 'btn add-failure',
            },
            children: [
              {
                tagName: 'circle',
                attrs: {
                  class: 'add-failure',
                },
              },
              {
                tagName: 'text',
                attrs: {
                  class: 'add-failure',
                }
              },
            ],
          }
        ],
        attrs: {
          body: {
            rx: 6,
            ry: 6,
            stroke: '#5F95FF',
            fill: 'transparent',
            strokeWidth: 1,
            refWidth: '100%',
            refHeight: '100%'
          },
          label: {
            fontSize: 12,
            fill: 'var(--text-color)'
          },
          '.btn.add-success': {
            refDx: -16,
            refY: 16,
            event: 'node:add-success',
          },
          '.btn.add-success > text': {
            fontSize: 20,
            refY: 0,
            refX: 0,
            fontWeight: 800,
            fill: '#1DD109',
            fontFamily: 'Times New Roman',
            text: '+',
          },
          '.add-success > circle': {
            r: 10,
            fill: 'transparent',
            stroke: '#1DD109',
            strokeWidth: 1,
          },
          '.btn.add-failure': {
            refDx: -16,
            refY: 60,
            event: 'node:add-failure',
          },
          '.btn.add-failure > text': {
            fontSize: 20,
            refY: 0,
            refX: 0,
            fontWeight: 800,
            fill: '#FF0000',
            fontFamily: 'Times New Roman',
            text: '+',
          },
          '.add-failure > circle': {
            r: 10,
            fill: 'transparent',
            stroke: '#FF0000',
            strokeWidth: 1,
          },
        },
      },
      true
    );

    Graph.registerNode(
      'workflowStepFunctionPlaceholder',
      {
        inherit: 'rect',
        markup: [
          {
            tagName: 'rect',
            selector: 'body',
          },
          {
            tagName: 'text',
            selector: 'label',
          },
        ],
        attrs: {
          body: {
            rx: 6,
            ry: 6,
            stroke: '#5F95FF',
            strokeDasharray: 5,
            fill: 'transparent',
            strokeWidth: 1,
            refWidth: '100%',
            refHeight: '100%',
          },
          label: {
            fontSize: 12,
            fill: 'var(--text-color)',
          }
        },
      },
      true
    );
  }

  registerEdge() {
    Graph.registerEdge(
      'edge',
      {
        inherit: 'edge',
        attrs: {
          line: {
            stroke: '#A2B1C3',
            strokeWidth: 2,
          }
        },
        connector: {
          name: 'jumpover'
        },
        router: {
          name: 'manhattan',
          args: {
            startDirections: ['left', 'right', 'top'],
            endDirections: ['right', 'left', 'top'],
          }
        }
      },
      true
    );

    Graph.registerEdge(
      'success-edge',
      {
        inherit: 'edge',
        attrs: {
          line: {
            stroke: '#A2B1C3',
            strokeWidth: 2,
          }
        },
        connector: {
          name: 'jumpover'
        },
        router: {
          name: 'manhattan',
          args: {
            startDirections: ['left', 'right', 'top'],
            endDirections: ['right', 'left', 'top'],
          }
        }
      },
      true
    );

    Graph.registerEdge(
      'failure-edge',
      {
        inherit: 'edge',
        attrs: {
          line: {
            stroke: '#A2B1C3',
            strokeWidth: 2,
          }
        },
        connector: {
          name: 'jumpover'
        },
        router: {
          name: 'manhattan',
          args: {
            startDirections: ['left', 'right', 'top'],
            endDirections: ['right', 'left', 'top'],
          }
        }
      },
      true
    );
  }

  registerCustomTools() {
    this.registerCommentButton();
  }

  private registerCommentButton() {
    Graph.registerNodeTool('commentButton', {
      name: 'commentButton',
      inherit: 'button'
    }, true);
  }
}
