import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

/* 3rd Part Modules */
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

/* Internal Modules */
import {
  RappiderTitleToolbarModule,
  RappiderCardOneListModule,
  RappiderInputGroupModule,
  RappiderPaginationModule,
  RappiderCardsModule,
} from '@rappider/rappider-components';
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';

/* Components */
import { PageTemplatesComponent } from './components/page-templates/page-templates.component';

const routes: Routes = [
  {
    path: PAGE_DEFINITIONS.PAGE_TEMPLATES.CHILDREN.PAGE_TEMPLATES_LIST.URL,
    component: PageTemplatesComponent
  },
];

@NgModule({
  imports: [
    CommonModule,
    NzSkeletonModule,
    RouterModule.forChild(routes),
    FormsModule,
    RappiderTitleToolbarModule,
    RappiderCardOneListModule,
    RappiderInputGroupModule,
    RappiderPaginationModule,
    RappiderCardsModule
  ],
  declarations: [
    PageTemplatesComponent
  ],
  exports: [
    PageTemplatesComponent
  ]
})
export class PageTemplatesModule { }
