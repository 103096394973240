import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
/* ScopeOfWork data store imports */
import * as fromScopeOfWorkState from './scope-of-work.reducer';
import { ScopeOfWorkEffects } from './scope-of-work.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /* data store for ScopeOfWork */
    StoreModule.forFeature(
      fromScopeOfWorkState.featureKey,
      fromScopeOfWorkState.reducer,
      { initialState: fromScopeOfWorkState.initialState }
    ),
    EffectsModule.forFeature([ScopeOfWorkEffects])
  ]
})
export class ScopeOfWorkDataStoreModule { }
