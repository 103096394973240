export enum WorkflowStepFunctionCreateEditFormItem {
    Name = 'name',
    Type = 'type',
    SubscribedEvents = 'subscribedEvents',
    PublishedEventsOnSuccess = 'publishedEventsOnSuccess',
    PublishedEventsOnFailure = 'publishedEventsOnFailure',
    CustomOrEndpoint = 'customOrEndpoint',
    ProjectModel = 'projectModel',
    EndpointId = 'endpointId',
    Code = 'code'
}
