import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BreadcrumbOption, FormLayout, HeadingComponentConfig } from '@rappider/rappider-components/utils';
import { CustomFunctionDefinitionWithRelations, ProjectModelWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';
import {
  defaultToolbarTitleHeadingSize,
  PATH_DEFINITIONS,
  WorkflowStepFunctionTypes
} from '@rappider/shared/definitions';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Subscription } from 'rxjs';
import { CreateWorkflowStepFunction } from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.actions';
@Component({
  selector: 'rappider-workflow-step-function-create',
  templateUrl: './workflow-step-function-create.component.html',
  styleUrls: ['./workflow-step-function-create.component.scss']
})
export class WorkflowStepFunctionCreateComponent {

  /* main title */
  mainTitle: HeadingComponentConfig = {
    content: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.WORKFLOW_STEP_FUNCTION_CREATE',
    type: defaultToolbarTitleHeadingSize
  };
  /* page title */
  title: BreadcrumbOption[];
  /* active project */
  activeProject: ProjectWithRelations;
  /* subscriptions */
  subscriptions: Subscription[];
  /* default workflow step function create */
  workflowStepFunction = {
    type: WorkflowStepFunctionTypes.Function
  };

  createFormConfig = {
    formLayout: FormLayout.Horizontal,
    isSubmitButtonFloat: false
  };

  displayToolbar = false;
  displayToolbarBackButton = false;

  constructor(
    private store: Store<any>,
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject()
    ];
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectWithRelations) => {
      if (activeProject) {
        this.activeProject = activeProject;
        /* set page title */
        this.setTitle();
      }
    });
  }

  /**
   * set page title
   *
   * @memberof WorkflowStepFunctionCreateComponent
   */
  setTitle() {
    this.title = [
      {
        label: this.activeProject?.name,
        redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      },
      {
        label: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_LIST_COMPONENT.WORKFLOW_STEP_FUNCTION_LIST',
        redirectUrl: `${PATH_DEFINITIONS.WORKFLOW_STEP_FUNCTION.WORKFLOW_STEP_FUNCTION_LIST}`
      },
      {
        label: 'PROJECT_MODULE.WORKFLOW_STEP_FUNCTION_CREATE_OR_EDIT_COMPONENT.WORKFLOW_STEP_FUNCTION_CREATE'
      }
    ];
  }

  /**
   * create workflow step function on form submit
   *
   * @param {WorkflowStepFunctionInterface} workflowStepFunction
   * @memberof WorkflowStepFunctionCreateComponent
   */
  onCreateWorkflowStepFunction(formValue) {
    const params = {
      body: {
        workflowStepFunction: {
          name: formValue.name,
          endpointId: formValue.endpointId || null,
          customFunctionDefinitionId: formValue.customFunctionDefinitionId
        },
        publishedEventOnFailureIds: formValue.publishedEventsOnFailure || [],
        publishedEventOnSuccessIds: formValue.publishedEventsOnSuccess || [],
        subscribedEventIds: formValue.subscribedEvents || []
      }
    };
    this.store.dispatch(CreateWorkflowStepFunction({ payload: { workflowStepFunction: params.body, navigateAfterCreate: true } }));
  }

}
