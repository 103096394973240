/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateProjectModelByJsonDataRequestDto } from '../models/create-project-model-by-json-data-request-dto';
import { GenerateDataModelFromExampleDataDto } from '../models/generate-data-model-from-example-data-dto';
import { GenerateModelFromJsonSchemaDto } from '../models/generate-model-from-json-schema-dto';
import { GenerateModelsFromOpenApiSpecDto } from '../models/generate-models-from-open-api-spec-dto';
import { LastProcessInformation } from '../models/last-process-information';
import { NewProjectModel } from '../models/new-project-model';
import { OpenAiRequestDto } from '../models/open-ai-request-dto';
import { ProjectModel } from '../models/project-model';
import { ProjectModelPartial } from '../models/project-model-partial';
import { ProjectModelWithRelations } from '../models/project-model-with-relations';
import { UpdateProjectModelFieldsByJsonDataRequestDto } from '../models/update-project-model-fields-by-json-data-request-dto';
import { UpdateProjectModelFieldsByJsonDataResponseDto } from '../models/update-project-model-fields-by-json-data-response-dto';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ProjectModelControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectModelControllerCount
   */
  static readonly ProjectModelControllerCountPath = '/project-models/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation projectModelControllerCreateByJsonData
   */
  static readonly ProjectModelControllerCreateByJsonDataPath = '/project-models/create-by-json-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createByJSONData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createByJSONData$Response(params?: {
    body?: CreateProjectModelByJsonDataRequestDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerCreateByJsonDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createByJSONData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createByJSONData(params?: {
    body?: CreateProjectModelByJsonDataRequestDto
  }): Observable<void> {

    return this.createByJSONData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectModelControllerCreateModelsByPostgreSqlExport
   */
  static readonly ProjectModelControllerCreateModelsByPostgreSqlExportPath = '/project-models/create-models-by-postgresql-export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createModelsByPostgreSQLExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createModelsByPostgreSQLExport$Response(params?: {
    body?: {  }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerCreateModelsByPostgreSqlExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createModelsByPostgreSQLExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createModelsByPostgreSQLExport(params?: {
    body?: {  }
  }): Observable<void> {

    return this.createModelsByPostgreSQLExport$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectModelControllerGenerateByAi
   */
  static readonly ProjectModelControllerGenerateByAiPath = '/project-models/generate-by-ai';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateByAI()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateByAI$Response(params?: {
    body?: OpenAiRequestDto
  }): Observable<StrictHttpResponse<GenerateDataModelFromExampleDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerGenerateByAiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenerateDataModelFromExampleDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateByAI$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateByAI(params?: {
    body?: OpenAiRequestDto
  }): Observable<GenerateDataModelFromExampleDataDto> {

    return this.generateByAI$Response(params).pipe(
      map((r: StrictHttpResponse<GenerateDataModelFromExampleDataDto>) => r.body as GenerateDataModelFromExampleDataDto)
    );
  }

  /**
   * Path part for operation projectModelControllerGenerateModelsFromExampleData
   */
  static readonly ProjectModelControllerGenerateModelsFromExampleDataPath = '/project-models/generate-models-from-example-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateModelsFromExampleData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateModelsFromExampleData$Response(params?: {
    body?: any
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerGenerateModelsFromExampleDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateModelsFromExampleData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateModelsFromExampleData(params?: {
    body?: any
  }): Observable<void> {

    return this.generateModelsFromExampleData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectModelControllerGenerateModelsFromJsonSchema
   */
  static readonly ProjectModelControllerGenerateModelsFromJsonSchemaPath = '/project-models/generate-models-from-json-schema';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateModelsFromJsonSchema()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateModelsFromJsonSchema$Response(params?: {
    body?: GenerateModelFromJsonSchemaDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerGenerateModelsFromJsonSchemaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateModelsFromJsonSchema$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateModelsFromJsonSchema(params?: {
    body?: GenerateModelFromJsonSchemaDto
  }): Observable<void> {

    return this.generateModelsFromJsonSchema$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectModelControllerGenerateModelsFromOpenApiSpec
   */
  static readonly ProjectModelControllerGenerateModelsFromOpenApiSpecPath = '/project-models/generate-models-from-openapi-spec';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateModelsFromOpenApiSpec()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateModelsFromOpenApiSpec$Response(params?: {
    body?: GenerateModelsFromOpenApiSpecDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerGenerateModelsFromOpenApiSpecPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateModelsFromOpenApiSpec$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateModelsFromOpenApiSpec(params?: {
    body?: GenerateModelsFromOpenApiSpecDto
  }): Observable<void> {

    return this.generateModelsFromOpenApiSpec$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectModelControllerGenerateMultipleByAi
   */
  static readonly ProjectModelControllerGenerateMultipleByAiPath = '/project-models/generate-multiple-by-ai';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateMultipleByAI()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateMultipleByAI$Response(params?: {
    body?: OpenAiRequestDto
  }): Observable<StrictHttpResponse<Array<GenerateDataModelFromExampleDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerGenerateMultipleByAiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GenerateDataModelFromExampleDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateMultipleByAI$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateMultipleByAI(params?: {
    body?: OpenAiRequestDto
  }): Observable<Array<GenerateDataModelFromExampleDataDto>> {

    return this.generateMultipleByAI$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GenerateDataModelFromExampleDataDto>>) => r.body as Array<GenerateDataModelFromExampleDataDto>)
    );
  }

  /**
   * Path part for operation projectModelControllerGetAllWithDetailsByProjectId
   */
  static readonly ProjectModelControllerGetAllWithDetailsByProjectIdPath = '/project-models/get-all-with-details/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllWithDetailsByProjectId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWithDetailsByProjectId$Response(params: {
    projectId: string;
  }): Observable<StrictHttpResponse<Array<ProjectModel>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerGetAllWithDetailsByProjectIdPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllWithDetailsByProjectId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWithDetailsByProjectId(params: {
    projectId: string;
  }): Observable<Array<ProjectModel>> {

    return this.getAllWithDetailsByProjectId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectModel>>) => r.body as Array<ProjectModel>)
    );
  }

  /**
   * Path part for operation projectModelControllerFindLatestChangeTimestamps
   */
  static readonly ProjectModelControllerFindLatestChangeTimestampsPath = '/project-models/latest-change-timestamps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLatestChangeTimestamps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps$Response(params?: {
  }): Observable<StrictHttpResponse<LastProcessInformation>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerFindLatestChangeTimestampsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LastProcessInformation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLatestChangeTimestamps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps(params?: {
  }): Observable<LastProcessInformation> {

    return this.findLatestChangeTimestamps$Response(params).pipe(
      map((r: StrictHttpResponse<LastProcessInformation>) => r.body as LastProcessInformation)
    );
  }

  /**
   * Path part for operation projectModelControllerUpdateProjectModelFieldsByJsonData
   */
  static readonly ProjectModelControllerUpdateProjectModelFieldsByJsonDataPath = '/project-models/update-fields-by-json-data';

  /**
   * creates,updates, and deletes project-model-fields by provided json-data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProjectModelFieldsByJSONData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectModelFieldsByJSONData$Response(params?: {
    body?: UpdateProjectModelFieldsByJsonDataRequestDto
  }): Observable<StrictHttpResponse<UpdateProjectModelFieldsByJsonDataResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerUpdateProjectModelFieldsByJsonDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateProjectModelFieldsByJsonDataResponseDto>;
      })
    );
  }

  /**
   * creates,updates, and deletes project-model-fields by provided json-data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateProjectModelFieldsByJSONData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProjectModelFieldsByJSONData(params?: {
    body?: UpdateProjectModelFieldsByJsonDataRequestDto
  }): Observable<UpdateProjectModelFieldsByJsonDataResponseDto> {

    return this.updateProjectModelFieldsByJSONData$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateProjectModelFieldsByJsonDataResponseDto>) => r.body as UpdateProjectModelFieldsByJsonDataResponseDto)
    );
  }

  /**
   * Path part for operation projectModelControllerCreateDefaultCrudPages
   */
  static readonly ProjectModelControllerCreateDefaultCrudPagesPath = '/project-models/{id}/create-crud-pages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDefaultCrudPages()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDefaultCrudPages$Response(params: {
    id: string;
    options?: any;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerCreateDefaultCrudPagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('options', params.options, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDefaultCrudPages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createDefaultCrudPages(params: {
    id: string;
    options?: any;
  }): Observable<void> {

    return this.createDefaultCrudPages$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectModelControllerFindById
   */
  static readonly ProjectModelControllerFindByIdPath = '/project-models/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ProjectModel>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ProjectModel> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectModel>) => r.body as ProjectModel)
    );
  }

  /**
   * Path part for operation projectModelControllerDeleteById
   */
  static readonly ProjectModelControllerDeleteByIdPath = '/project-models/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelControllerUpdateById
   */
  static readonly ProjectModelControllerUpdateByIdPath = '/project-models/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ProjectModelPartial
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ProjectModelPartial
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelControllerFind
   */
  static readonly ProjectModelControllerFindPath = '/project-models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectModelWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectModelWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ProjectModelWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectModelWithRelations>>) => r.body as Array<ProjectModelWithRelations>)
    );
  }

  /**
   * Path part for operation projectModelControllerCreate
   */
  static readonly ProjectModelControllerCreatePath = '/project-models';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    options?: any;
    body?: NewProjectModel
  }): Observable<StrictHttpResponse<ProjectModel>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelControllerService.ProjectModelControllerCreatePath, 'post');
    if (params) {
      rb.query('options', params.options, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    options?: any;
    body?: NewProjectModel
  }): Observable<ProjectModel> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectModel>) => r.body as ProjectModel)
    );
  }

}
