<div class="source-code-container">
  <rappider-title-toolbar [displayToolbar]="false"
                          [titleBarActionButtons]="titleBarActionButtonConfig"
                          [options]="titleBarBreadcrumbOptions || titleBarDefaultBreadcrumbOptions"
                          [displayToolbarBackButton]="false"
                          (titleBarActionButtonClick)="onTitleBarActionButtonClick($event)">
  </rappider-title-toolbar>


  <as-split unit="percent"
                  direction="horizontal"
                  [gutterSize]="isCodeEditorLeftContainerVisible ? 7 : 0"
                  gutterAriaLabel="divider"
                  (dragEnd)="splitDragEnds($event)">
          <div *asSplitGutter
               class="custom-settings-area-gutter"
               [style.display]="isCodeEditorLeftContainerVisible ? 'block' : 'none'">
            <div asSplitGutterDragHandle
                 class="custom-settings-area-gutter"></div>
          </div>
          <as-split-area [size]="codeSplitterLeftContainerSize"
                         visible="isCodeEditorLeftContainerVisible">

                         <div class="code-left-side-collapse-container">
                          <nz-collapse nzAccordion
                                       [nzBordered]="false"
                                       class="code-left-side-collapse">
                            <nz-collapse-panel class="source-code-panel-title"
                                               *ngFor="let panel of panels"
                                               [nzHeader]="panel.name"
                                               [nzActive]="panel.active">
                              <rappider-project-source-files (pathChanged)="onPatchChanged($event)"></rappider-project-source-files>
                            </nz-collapse-panel>
                          </nz-collapse>
                        </div>

          </as-split-area>
          <as-split-area [size]="codeSplitterRightContainerSize">

            <!-- Right sidebar -->
            <div class="splitter-right-side-container">

              <div class="code-editor-container"
              [style.width]="'calc(100% - '+leftSideContainerWidth +'px)'">
           <div style="width: calc(100%); height: calc(100vh - 152px);">
             <rappider-monaco-code-editor #editorRef
                                          [options]="editorOptions"
                                          [borderSettings]="{border: 'none'}"
                                          [loading]="isLoading"
                                          (customCodeRegions)="onCustomCodeRegionsEmitted($event)"
                                          [(ngModel)]="code"></rappider-monaco-code-editor>
           </div>
         </div>


            </div>

          </as-split-area>
        </as-split>


  <!-- <div class="code-content-container">

    <div class="code-left-side-container"
         rappiderResizable
         [resizableGrabWidth]="4"
         [additionalLeftOffset]="47"
         [style.width]="leftSideContainerWidth + 'px'"
         (onResize)="onLeftSideResized($event)">

      <div class="code-left-side-collapse-container">
        <nz-collapse nzAccordion
                     [nzBordered]="false"
                     class="code-left-side-collapse">
          <nz-collapse-panel class="source-code-panel-title"
                             *ngFor="let panel of panels"
                             [nzHeader]="panel.name"
                             [nzActive]="panel.active">
            <rappider-project-source-files (pathChanged)="onPatchChanged($event)"></rappider-project-source-files>
          </nz-collapse-panel>
        </nz-collapse>
      </div>


    </div>
    <div class="code-editor-container"
         [style.width]="'calc(100% - '+leftSideContainerWidth +'px)'">
      <div style="width: calc(100%); height: calc(100vh - 152px);">
        <rappider-monaco-code-editor #editorRef
                                     [options]="editorOptions"
                                     [borderSettings]="{border: 'none'}"
                                     [loading]="isLoading"
                                     (customCodeRegions)="onCustomCodeRegionsEmitted($event)"
                                     [(ngModel)]="code"></rappider-monaco-code-editor>
      </div>
    </div>
  </div> -->

</div>
