import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, messageTemplateFeatureKey } from './message-template.reducer';

// Select the entire messageTemplate state
export const selectMessageTemplateState = createFeatureSelector<State>(messageTemplateFeatureKey);

// Select loading state
export const selectLoading = createSelector(
  selectMessageTemplateState,
  (state: State) => state.loading
);

// Select all message templates
export const selectMessageTemplates = createSelector(
  selectMessageTemplateState,
  (state: State) => state.data
);

// Select pagination state
export const selectPagination = createSelector(
  selectMessageTemplateState,
  (state: State) => state.pagination
);

// Select current page number
export const selectCurrentPageNumber = createSelector(
  selectPagination,
  (pagination) => pagination.currentPageNumber
);

// Select page size
export const selectPageSize = createSelector(
  selectPagination,
  (pagination) => pagination.pageSize
);

// Select total count of message templates
export const selectTotalCount = createSelector(
  selectPagination,
  (pagination) => pagination.totalCount
);

// Select total number of pages
export const selectTotalPageNumber = createSelector(
  selectPagination,
  (pagination) => pagination.totalPageNumber
);
