import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentDefinitionService, NotificationService, RenderComponentService, ThemeService } from '@rappider/services';
import { THEME_FORM_CONFIG } from '@rappider/shared/configs';
import { Subscription } from 'rxjs';
import { DeleteProjectTheme, GetProjectThemes, UpdateProjectTheme } from '../../../../../project/src/lib/states/project-theme-state/project-theme.actions';
import { DEFAULT_THEME } from '@rappider/shared/data';
import { cloneDeep } from 'lodash';
import { defaultToolbarTitleHeadingSize, BLACKLISTED_COMPONENTS_ON_THEME_PREVIEW, ComponentDefinitionCategories, PATH_DEFINITIONS, ThemeCategoryKeys } from '@rappider/shared/definitions';
import { Category } from 'libs/shared/src/lib/sdk/models';
import { ComponentDefinitionWithRelations, Project, ProjectTheme } from '@rappider/rappider-sdk';
import { ThemeCategoryComponents } from './project-theme-models';
import { ButtonComponentConfig, CrudFormItem, DropdownMenuItem, FormLayout, TitleToolbarComponentConfig } from '@rappider/rappider-components/utils';
import { ActivatedRoute } from '@angular/router';
import * as ComponentDefinitionActions from 'libs/component-definition/src/lib/state/component-definition.actions';
import { THEME_SETTING_BUTTONS_CONFIG } from '../../utils/theme-setting-buttons.config';
import { SetAsActiveProjectTheme } from 'libs/project/src/lib/states/projects-state/project.actions';
import { getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';

@Component({
  selector: 'rappider-project-theme',
  templateUrl: './project-theme.component.html',
  styleUrls: ['./project-theme.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectThemeComponent implements OnInit, AfterViewInit, OnDestroy {

  /* Showcase components render area */
  @ViewChildren('showcaseContainer', { read: ViewContainerRef }) showcaseContainers: QueryList<ViewContainerRef>;
  @ViewChild('themeConfig') themeConfig: ElementRef;
  resizeObserver: ResizeObserver;

  @Input() previewVisibility = true;

  THEME_FORM_CONFIG = THEME_FORM_CONFIG;
  THEME_SETTING_BUTTONS_CONFIG = THEME_SETTING_BUTTONS_CONFIG;
  activeProjectThemeId: string;
  activeProjectTheme: ProjectTheme;
  activeProject: Project;
  /* main title */
  titleToolbarConfig: TitleToolbarComponentConfig;
  leftTitleToolbarMainTitle = 'PROJECT_MODULE.PROJECT_THEME_COMPONENT.THEME_SETTINGS';
  subscriptions: Subscription[] = [];
  loading = true;
  formData: any;
  isThemeIdSetFromUrl = false;

  // #region Theme Preview Variables

  /* components to show theme changes */
  showcaseComponents;
  /* component definitions */
  componentDefinitions = [];
  /* component definition categories for theme preview showcase */
  componentDefinitionCategories: Category[] = [];
  selectedComponentCategoryKeyForThemePreview = ThemeCategoryKeys.ExampleTemplate;
  blacklistedComponentsOnThemePreview = BLACKLISTED_COMPONENTS_ON_THEME_PREVIEW;
  /* dropdown settings for component category selection */
  componentCategoryDropDownSettings = {
    placement: 'bottomRight',
    items: [],
    labelMode: 'static-label',
  };
  defaultComponentCategoryDropDownOption = {
    label: 'Example Template',
    key: 'example-template',
  };
  /* selected component category title for dropdown label to select theme preview */
  selectedComponentCategoryTitle = this.defaultComponentCategoryDropDownOption.label;

  isShowCaseContainersReady = false;
  isShowcaseComponentsRendered = false;
  setActiveThemeButtonConfig: ButtonComponentConfig = {
    tooltipText: 'Set as Active Theme',
    icon: {
      name: 'fa-regular fa-check'
    }
  }

  // #endregion Theme Preview Variables

  constructor(
    private themeService: ThemeService,
    private store: Store<any>,
    private componentDefinitionService: ComponentDefinitionService,
    private renderComponentService: RenderComponentService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscribeToData();
  }

  ngAfterViewInit() {
    this.applyTheme();
    /* we need the DOM elements ready and created by the angular lifecycle, so that we can render the showcase components into them  */
    this.showcaseContainers.changes.subscribe(() => {
      // The container has been added to the DOM
      if (this.showcaseComponents?.length) {
        this.isShowCaseContainersReady = true;
        this.renderShowcaseComponentsOnReady();
      }
    });
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        if ((width > 400 && this.THEME_FORM_CONFIG.formLayout === FormLayout.Vertical) || (width < 400 && this.THEME_FORM_CONFIG.formLayout === FormLayout.Horizontal)) {
          if (width > 400) {
            this.THEME_FORM_CONFIG = {
              ...this.THEME_FORM_CONFIG,
              formLayout: FormLayout.Horizontal
            };
            this.cd.detectChanges();
          } else {
            this.THEME_FORM_CONFIG = {
              ...this.THEME_FORM_CONFIG,
              formLayout: FormLayout.Vertical
            };
            this.cd.detectChanges();
          }
        }
      }
    });
    this.resizeObserver.observe(this.themeConfig.nativeElement);
  }

  renderShowcaseComponentsOnReady() {
    if (this.showcaseComponents?.length && this.isShowCaseContainersReady && !this.isShowcaseComponentsRendered) {
      this.renderShowcaseComponents(this.showcaseComponents);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }


  getActiveThemeIdFromUrl() {
    this.activeProjectThemeId = this.activatedRoute.snapshot.params['id'];
    if (this.activeProjectThemeId) {
      this.isThemeIdSetFromUrl = true;
      this.subscriptions.push(
        this.subscribeToProjectThemeData()
      );
    }
  }

  // #region Subscriptions

  /**
   * Subscribe To All Data
   *
   * @memberof ProjectThemeComponent
   */
  subscribeToData() {
    this.subscriptions.push(
      this.subscribeToRouteParams(),
      this.subscribeToActiveProject(),
      this.subscribeToProjectThemeLoading(),
      this.subscribeToComponentDefinitions()
    );
  }

  /**
* Subscribe to route params to detect theme id changes
*/
  subscribeToRouteParams() {
    return this.activatedRoute.params.subscribe(params => {
      const themeId = params['id'];
      if (themeId) {
        this.activeProjectThemeId = themeId;
        this.isThemeIdSetFromUrl = true;
        this.subscribeToProjectThemeData();
        this.cd.detectChanges();
      }
    });
  }

  /**
   * Subscribe To Active Project
   *
   * @return {*}
   * @memberof ProjectThemeComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
      if (activeProject && !this.isThemeIdSetFromUrl) {
        this.activeProjectThemeId = activeProject.activeProjectThemeId;
        this.subscribeToProjectThemeData();
      } else {
        this.setTitleToolbarConfig();
      }
    });
  }

  setTitleToolbarConfig() {
    this.titleToolbarConfig = {
      mainTitle: {
        content: 'Themes',
        type: defaultToolbarTitleHeadingSize
      },
      options: [
        {
          label: this.activeProject?.name,
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
        },
        {
          label: 'Project Themes',
          redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME_LIST_PATH}`
        },
        {
          label: this.activeProjectTheme?.name
        },
        {
          label: 'Project Theme Settings'
        }
      ]
    };
  }

  /**
   * subscribe to project theme
   *
   * @memberof ProjectThemeComponent
   */
  subscribeToProjectThemeData() {
    return this.store.select(state => state.projectTheme?.data).subscribe((projectThemes: ProjectTheme[]) => {
      const activeTheme = projectThemes?.find(projectTheme => projectTheme.id === this.activeProjectThemeId);

      if (activeTheme) {
        this.activeProjectTheme = cloneDeep(activeTheme);
        this.setFormData();
        this.applyTheme();
        this.setTitleToolbarConfig();
      }
    });
  }

  subscribeToProjectThemeLoading() {
    return this.store.select(state => state.projectTheme?.loading).subscribe(loading => {
      this.loading = loading;
      this.cd.detectChanges();
    });
  }

  subscribeToComponentDefinitions() {
    return this.store.select(<any>getComponentDefinitionsWithDetailsSelector).subscribe(
      (componentDefinitions: ComponentDefinitionWithRelations[]) => {
        if (componentDefinitions) {
          this.componentDefinitions = componentDefinitions;
          this.componentDefinitionCategories = this.componentDefinitionService.
            getSortedCategoriesOfComponentDefinitions(componentDefinitions);
          /* if there are categories, populate the category select options */
          if (this.componentDefinitionCategories?.length) {
            this.populateCategoryDropdown();
          }
          /* set the components for the showcase */
          this.setThemePreviewComponentsByCategory(this.selectedComponentCategoryKeyForThemePreview);
        }
      });
  }

  /**
   * Populate category dropdown data from categories
   * DT function
   * @memberof ProjectThemeComponent
   */
  populateCategoryDropdown() {
    this.componentCategoryDropDownSettings.items = [
      this.defaultComponentCategoryDropDownOption,
      {
        label: 'Elements',
        key: 'default'
      }
    ];
  }

  // #endregion End of Subscriptions

  // #region Theme Form

  /**
   * Save or update operation
   *
   * @memberof ProjectThemeComponent
   */
  saveUpdatedThemeData(formData) {
    this.formData = formData;
    const updatedProjectThemeData: any = this.themeService.getThemeData(this.formData);
    delete updatedProjectThemeData.customTheme;
    const updatedCustomTheme = {};
    this.formData.customTheme?.forEach(customTheme => {
      updatedCustomTheme[customTheme.key] = customTheme.value;
    });
    this.store.dispatch(new UpdateProjectTheme({
      projectThemeId: this.activeProjectTheme.id,
      projectTheme: {
        theme: updatedProjectThemeData,
        customTheme: updatedCustomTheme
      }
    }));
  }

  onClickSetDefaultTheme() {
    this.activeProjectTheme.theme = cloneDeep(DEFAULT_THEME);
    this.saveUpdatedThemeData(cloneDeep(DEFAULT_THEME));
  }

  onFieldChange(data: object) {
    Object.entries(data).forEach(([key, value]) => {
      this.formData[key] = value;
    });
    this.applyTheme();
  }

  setFormData() {
    let customThemeData;
    if (this.activeProjectTheme?.customTheme) {
      customThemeData = Object.entries(this.activeProjectTheme?.customTheme).map(([key, value]) => ({
        key: key,
        value: value
      }));
    }

    this.formData = {
      ...this.activeProjectTheme.theme,
      customTheme: customThemeData ?? []
    };
  }

  applyTheme() {
    const root: any = document.getElementById('theme-preview');
    if (root) {
      Object.entries(this.formData || {}).forEach(([key, value]) => {
        if (key && value) {
          root.style.setProperty(key, value);
        }
      });
    }
  }

  onFieldLinkStateChange(item: CrudFormItem) {
    const config = this.THEME_FORM_CONFIG.items.find(configItem => configItem.fieldName === item.fieldName);
    config.isLinked = item.isLinked;
  }

  // #endregion End of Theme Form

  // #region Showcase

  clearShowcaseContainer() {
    if (this.showcaseContainers) {
      this.showcaseContainers.toArray().forEach(c => c.clear());
    }
  }

  renderShowcaseComponents(showcaseComponents) {
    if (!this.isShowcaseComponentsRendered) {
      setTimeout(() => {
        /* delete previous renders */
        this.clearShowcaseContainer();
        if (showcaseComponents?.length) {
          showcaseComponents.forEach((component, index) => {
            let container;
            if (this.showcaseContainers) {
              container = this.showcaseContainers.toArray()[index];
            }
            if (container && component?.componentDefinition) {
              /* render and add component to container ref */
              this.renderComponentService.renderComponentAndAddToContainer(
                component.componentDefinition.className,
                container,
                component.componentDefinition.defaultInputs
              );
            }
          }).then(() => {
            this.isShowcaseComponentsRendered = true;
          });
        }
      }, 10);
    }
  }

  /**
   * Sets the theme preview components using the selected category
   * Id the category key is 'default' Elements & BasicComponents will be displayed together
   * @param {*} categoryKey
   * @memberof ProjectThemeComponent
   */
  setThemePreviewComponentsByCategory(categoryKey: string) {
    let categoryComponentsForShowcase: ThemeCategoryComponents[] = [];

    /* get the category components */
    if (categoryKey === ThemeCategoryKeys.Default) {
      const categorizedComponents = this.componentDefinitionService
        .categorizeComponentDefinitions(this.componentDefinitions);

      const elementComponents = categorizedComponents.find(categorizedComponentDefinitions =>
        categorizedComponentDefinitions.name === ComponentDefinitionCategories.Elements);

      categoryComponentsForShowcase = [...elementComponents.data];
    } else {
      /* filter the category components */
      const selectedCategoryComponents = this.componentDefinitions.filter(componentDefinition =>
        componentDefinition.mainCategory?.name === categoryKey ||
        componentDefinition.subCategories?.find(subCategory => subCategory.name === categoryKey)
      );
      /* data transformation for the type match */
      categoryComponentsForShowcase = selectedCategoryComponents.map(componentDefinition => <ThemeCategoryComponents>{
        isMainCategory: true,
        componentDefinition: componentDefinition
      });
    }
    /* set the showcase components by filtering blacklisted components */
    if (categoryComponentsForShowcase?.length) {
      const removeComponents = this.blacklistedComponentsOnThemePreview;
      const filteredShowcaseComponents = categoryComponentsForShowcase
        .filter(component => !removeComponents.includes(component.componentDefinition.className));
      this.showcaseComponents = filteredShowcaseComponents;
    } else {
      this.showcaseComponents = null;
    }
  }

  // #endregion End of Showcase

  // #region Theme Preview Category Selection

  /**
   * Triggers setThemePreviewComponentsByCategory in order to change the showcaseComponents on ComponentCategoryDropDownClick
   *
   * @param {*} selectedCategoryOption
   * @memberof ProjectThemeComponent
   */
  onComponentCategoryDropDownClick(selectedCategoryOption) {
    const categoryKey = selectedCategoryOption.key;
    this.selectedComponentCategoryTitle = selectedCategoryOption.label;
    this.selectedComponentCategoryKeyForThemePreview = categoryKey;
    /* render theme preview components of the selected category */
    this.setThemePreviewComponentsByCategory(this.selectedComponentCategoryKeyForThemePreview);
  }

  // #endregion End of Theme Preview Category Selection

  // #region Menu Item Click

  onMenuItemClick(menuItem: DropdownMenuItem) {
    if (menuItem.key === 'resetThemeAsDefault') {
      this.onClickSetDefaultTheme();
    } else if (menuItem.key === 'setAsActiveTheme') {
      if (this.activeProjectThemeId === this.activeProject.activeProjectThemeId) {
        this.notificationService.createNotification(
          'warning',
          'Warning',
          'This theme is already active theme. Please choose another theme.'
        );
      } else {
        this.store.dispatch(new SetAsActiveProjectTheme({ projectThemeId: this.activeProjectThemeId, activeProjectId: this.activeProject.id }));
      }
    } else if (menuItem.key === 'deleteTheme') {
      if (this.activeProject.activeProjectThemeId !== this.activeProjectThemeId) {
        this.store.dispatch(new DeleteProjectTheme({ projectThemeId: this.activeProjectThemeId }));
      } else {
        this.notificationService.createNotification(
          'warning',
          'Warning',
          'Active theme cannot be deleted'
        );
      }
    }
  }

  onSetActiveThemeButtonClick() {
    if (this.activeProjectThemeId === this.activeProject.activeProjectThemeId) {
      this.notificationService.createNotification(
        'warning',
        'Warning',
        'This theme is already active theme. Please choose another theme.'
      );
    } else {
      this.store.dispatch(new SetAsActiveProjectTheme({ projectThemeId: this.activeProjectThemeId, activeProjectId: this.activeProject.id }));
    }
  }

  // #endregion Menu Item Click
}
