import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RouterActions from './router.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { QueryParam } from '../../definitions/query-param';
import { Location } from '@angular/common';
import { Project, ProjectVersion } from '@rappider/rappider-sdk';
import { MessageTemplate, Page, ProjectTheme } from '@rappider/api-sdk';
import { orderBy } from 'lodash';


function replacePathParametersWithValues(
  path: string,
  activeProject: Project,
  projectThemes: ProjectTheme[],
  projectVersions: ProjectVersion[],
  layouts: Page[],
  pages: Page[],
  messageTemplates: MessageTemplate[]
): string {
  let replacedPath;
  const replacements = {
    '{{projectId}}': activeProject?.id || '',
    '{{activeProjectThemeId}}': activeProject?.activeProjectThemeId || (projectThemes?.length ? projectThemes[0]?.id : ''),
    '{{latestProjectVersionId}}': projectVersions?.length ? orderBy(projectVersions, 'createdDate', 'desc')[0]?.id || '' : '',
    '{{activePageLayoutId}}': activeProject?.activeLayoutId || layouts?.[0]?.id || '',
    '{{activePageId}}': pages?.[0]?.id || '',
    '{{activeMessageTemplateId}}': messageTemplates?.[0]?.id || ''
  };
  if (path) {
    replacedPath = path?.replace(/\{\{\w+\}\}/g, (all) => replacements[all as keyof typeof replacements] || all);
  }

  if (replacedPath?.includes('{{')) {
    return null;
  } else {
    return replacedPath;
  }

}
@Injectable()
export class RouterEffects {

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { }


  navigate$ = createEffect(() => this.actions$.pipe(
    ofType<RouterActions.Navigate>(RouterActions.ActionTypes.Navigate),
    withLatestFrom(
      this.store.select(state => state.router.queryParams)
    ),
    concatMap(([action, queryParams]) => {
      /* return actions */
      const actions: Action[] = [];
      /* get url */
      const url = action.payload.url;
      /* if url equals to redirect url then remove redirect url query param */
      if (url === queryParams[QueryParam.RedirectUrl]) {
        actions.push(
          new RouterActions.RemoveQueryParam({ key: QueryParam.RedirectUrl })
        );
      }
      /* route to url if url exists */
      if (url) {
        actions.push(
          new RouterActions.RouteToUrl({ url: url })
        );
      }
      return actions.length ? actions : [new RouterActions.NavigateFailure({ error: 'This url could not found', key: 'NavigateFailure', timestamp: Date.now() })];
    })
  ));


  routeToUrl$ = createEffect(() => this.actions$.pipe(
    ofType<RouterActions.RouteToUrl>(RouterActions.ActionTypes.RouteToUrl),
    withLatestFrom(
      this.store.select(state => state.router.queryParams)
    ),
    map(([action, queryParams]) => {
      const url = action.payload.url;
      if (url?.includes('{{')) {
        this.store.dispatch(new RouterActions.RouteToUrlWithVariables({ url: url }));
      } else {
        if (!queryParams) {
          this.router.navigateByUrl(url);
        } else {
          this.router.navigate([url], { queryParams: queryParams || [] });
        }
      }
    })
  ), { dispatch: false });

  routeToUrlWithVariables$ = createEffect(() => this.actions$.pipe(
    ofType<RouterActions.RouteToUrlWithVariables>(RouterActions.ActionTypes.RouteToUrlWithVariables),
    withLatestFrom(
      this.store.select(state => state.router.queryParams),
      this.store.select(state => state.activeProject),
      this.store.select(state => state.projectTheme?.data),
      this.store.select(state => state.projectVersion?.data),
      this.store.select(state => state.layout?.data),
      this.store.select(state => state.pages?.data),
      this.store.select(state => state.messageTemplate?.data),
    ),
    map(([action, queryParams, activeProject, projectThemes, projectVersions, layouts, pages, messageTemplates]) => {
      const url = action.payload.url;
      const replacedUrl = replacePathParametersWithValues(url,
        activeProject, projectThemes, projectVersions, layouts, pages, messageTemplates);
      if (replacedUrl) {
        if (!queryParams) {
          this.router.navigateByUrl(url);
        } else {
          this.router.navigate([url], { queryParams: queryParams || [] });
        }
      }
    })
  ), { dispatch: false });

  queryParamsChange$ = createEffect(() => this.actions$.pipe(
    ofType<RouterActions.AddQueryParam | RouterActions.RemoveQueryParam | RouterActions.ClearQueryParams>
      (
        RouterActions.ActionTypes.AddQueryParam,
        RouterActions.ActionTypes.RemoveQueryParam,
        RouterActions.ActionTypes.ClearQueryParams
      ),
    withLatestFrom(
      this.store.select(state => state.router.queryParams)
    ),
    map(([action, queryParams]) => {
      const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: queryParams }).toString();
      this.location.go(url);
    })
  ), { dispatch: false });

}
