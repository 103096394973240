import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AIContentConfigFormConfig } from './ai-content-config-form.config';

@Component({
  selector: 'rappider-ai-content-config-modal',
  templateUrl: './ai-content-config-modal.component.html',
  styleUrls: ['./ai-content-config-modal.component.scss']
})
export class AiContentConfigModalComponent {

  @Input() modalTitle: string;
  @Input() modalVisibility: boolean;
  @Input() data: { aiUpdateType: string; description: string };

  @Output() modalVisibilityChange = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<{ aiUpdateType: string; description: string }>();

  formConfig = AIContentConfigFormConfig;

  handleModalVisibility(visibility: boolean) {
    this.modalVisibility = visibility;
    this.modalVisibilityChange.emit(this.modalVisibility);
  }

  onFormSubmit(value) {
    this.formSubmit.emit(value);
  }

}
