import { createAction, createReducer, on } from '@ngrx/store';
import { DiagramCommentWithRelations } from '@rappider/rappider-sdk';

/* actions */
import * as DiagramCommentActions from './diagram-comment.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'diagramComment';

/* state interface */
export interface DiagramCommentState {
  data: DiagramCommentWithRelations[];
  isLoading: boolean;
  isLoaded: boolean;
  error: {
    error: any;
    key: string;
    timestamp: number;
  };
}

export const initialState: DiagramCommentState = {
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(DiagramCommentActions.GetDiagramComments, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false
  })),
  on(DiagramCommentActions.GetDiagramCommentsSuccessful, (state, action) => ({
    ...state,
    data: action.comments,
    isLoading: false,
    isLoaded: true
  })),
  on(DiagramCommentActions.GetDiagramCommentsFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timestamp: action.timestamp
    },
    isLoading: false,
    isLoaded: true
  })),

  on(DiagramCommentActions.CreateDiagramComment, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(DiagramCommentActions.CreateDiagramCommentSuccessful, (state, action) => ({
    ...state,
    data: [
      action.diagramComment,
      ...state.data
    ],
    isLoading: false
  })),
  on(DiagramCommentActions.CreateDiagramCommentFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timestamp: action.timestamp
    },
    isLoading: false
  })),

  on(DiagramCommentActions.UpdateDiagramComment, (state) => ({
    ...state,
    isLoading: true
  })),
  on(DiagramCommentActions.UpdateDiagramCommentSuccessful, (state) => ({
    ...state,
    isLoading: false
  })),
  on(DiagramCommentActions.UpdateDiagramCommentFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timestamp: action.timestamp
    },
    isLoading: false
  })),

  on(DiagramCommentActions.DeleteDiagramComment, (state) => ({
    ...state,
    isLoading: true
  })),
  on(DiagramCommentActions.DeleteDiagramCommentSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(comment => comment.id !== action.diagramCommentId)
    ],
    isLoading: false
  })),
  on(DiagramCommentActions.DeleteDiagramCommentFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timestamp: action.timestamp
    },
    isLoading: false
  })),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
