import * as localForage from 'localforage';
import {middlewareStorage} from './middleware-storage';
import {config, StorageConfig} from './models';
import {storageSync} from './storage-sync';

// localforage is a library for offline storage which also is a wrapper for IndexedDB, WebSQL and localStorage
// this function configures and returns all data from localForage
// and sets it to the middlewareStorage
// it returns a promise with the data
// it receives the storage configuration
// it sets the keys and the storage to the config
// it configures the localForage
// it gets all the keys and items from the localForage

export const getAllDataFromLocalForage = (options: StorageConfig) => {
  config.keys = options.keys;
  config.storage = middlewareStorage;
  localForage.config({
    driver      : options.driver || localForage.INDEXEDDB,
    name        : 'NGRX Storage',
    version     : 1.0,
    size        : 4980736,
    storeName   : 'keyvaluepairs',
    description : 'NGRX storage persist'
  });

  return localForage.keys()
    .then(keys => Promise.all(
        keys.map(
          (key) => localForage.getItem(key).then(data => [key, data])
        )
      ))
    .then(dataWithKeys => {
      const dataStorage = dataWithKeys.reduce((previousValue: any, [key, data]) => {
        previousValue[<string>key] = data;
        return previousValue;
      }, {});
      middlewareStorage.dataStorage = dataStorage;
      return dataStorage;
    });
};

export function storageSyncMetaReducer(
    reducer: any
): any {
  return storageSync(reducer);
}

export default localForage;
