<!-- ----------------------------------------------------------------------------------------- -->
<!-- TITLE -->
<rappider-title-toolbar *ngIf="title"
                        [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>
<!-- / TITLE -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- LOADING SKELETON -->
<section *ngIf="!projectExports">
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</section>
<!-- / LOADING SKELETON -->
<!-- ----------------------------------------------------------------------------------------- -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- TOOLBAR -->
<div *ngIf="projectVersion && !exportStatusFlag"
     class="toolbar">
  <rappider-button [text]="projectExportPageButton.text"
                   [icon]="projectExportPageButton.icon"
                   [type]="projectExportPageButton.type"
                   (click)="onNavigateToProjectExportPage()"></rappider-button>
</div>
<!-- / TOOLBAR -->
<!-- ----------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------- -->
<!-- LATEST PROJECT EXPORT -->
<section *ngIf="latestProjectExport">
  <div class="last-exported-version-list">
                        <nz-descriptions nzTitle="Latest Build" [nzColumn]="1" nzBordered>
                          <nz-descriptions-item nzTitle="App Type">{{latestProjectExport?.appType}}</nz-descriptions-item>
                          <nz-descriptions-item nzTitle="Frontend Framework">{{latestProjectExport?.frontendFramework}}</nz-descriptions-item>
                          <nz-descriptions-item nzTitle="Backend Framework">{{latestProjectExport?.backendFramework}}</nz-descriptions-item>
                          <nz-descriptions-item nzTitle="Version Number">{{latestProjectExport?.versionNumber}}</nz-descriptions-item>
                          <nz-descriptions-item nzTitle="Build Date">{{latestProjectExport?.createdDate | date:'medium'}}</nz-descriptions-item>
                          <nz-descriptions-item nzTitle="Created By">{{latestProjectExport?.createdBy}}</nz-descriptions-item>
                          <nz-descriptions-item nzTitle="Status" [nzSpan]="3">
                            <nz-badge [nzStatus]="status" [nzText]="status"></nz-badge>
                          </nz-descriptions-item>
                        </nz-descriptions>
  </div>
  <div class="export-details-wrapper">
    <section>
      <rappider-progress-steps [progressData]="latestProjectExport"
                               (status)="onStatus($event)"></rappider-progress-steps>
    </section>
  </div>
</section>
<!-- ----------------------------------------------------------------------------------------- -->
<!-- / LATEST PROJECT EXPORT -->

<!-- ----------------------------------------------------------------------------------------- -->
<!-- PROJECT EXPORTS -->
<section *ngIf="projectExports">
  <rappider-list-grid [config]="PROJECT_EXPORT_LIST_CONFIG"
                      [data]="projectExports"></rappider-list-grid>
</section>
<!-- ----------------------------------------------------------------------------------------- -->
<!-- / PROJECT EXPORTS -->
