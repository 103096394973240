export enum ProjectModelRelationType {
  HasOne = 'hasOne',
  HasMany = 'hasMany',
  BelongsTo = 'belongsTo',
  HasManyThrough = 'hasManyThrough',
  ReferencesMany = 'referencesMany'
}

export enum ProjectModelRelationTypeDescriptions {
  HasOne = 'A hasOne relation denotes a one-to-one connection of a model to another model through referential integrity. ' +
  'The referential integrity is enforced by a foreign key constraint on both the source model and the target model, ' +
  'which usually references a primary key on the source model for the target model and a primary key on the target ' +
  'model for the source model. This relation indicates that each instance of the declaring or source model belongs to ' +
  'exactly one instance of the target model.',
  HasMany = 'A hasMany relation denotes a one-to-many connection of a model to another model through referential integrity. ' +
  'The referential integrity is enforced by a foreign key constraint on the target model which usually references ' +
  'a primary key on the source model. This relation indicates that each instance of the declaring or source model ' +
  'has zero or more instances of the target model.',
  HasManyThrough = 'A hasManyThrough relation denotes a many-to-many connection with another model. ' +
  'The referential integrity is enforced by foreign key constraints on the through model, which usually ' +
  'references primary keys on the source model and the target model. This relation indicates that the ' +
  'declaring model can be matched with zero or more instances of another model by proceeding through a ' +
  'third model.',
  BelongsTo = 'A belongsTo relation denotes a many-to-one connection of a model to another model through referential integrity. ' +
  'The referential integrity is enforced by a foreign key constraint on the source model, which usually references ' +
  'a primary key on the target model. This relation indicates that each instance of the declaring or source model ' +
  'belongs to exactly one instance of the target model.',
  ReferencesMany = 'Relations enable you to create connections between models and provide navigation APIs to deal with a graph of model instances. ' +
  'In addition to the traditional ones, it supports referencesMany relation that embeds an array of foreign keys to reference other objects.'
}
