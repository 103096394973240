import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PersonWithRelations } from '@rappider/rappider-sdk';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterStateService } from '@rappider/services';
import { QueryParam, PATH_DEFINITIONS } from '@rappider/shared/definitions';

@Injectable({
  providedIn: 'root'
})
export class PersonGuard implements CanActivate {

  private isRedirecting = false;

  constructor(
    private router: Router,
    private store: Store<any>,
    private routerStateService: RouterStateService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // console.log('PERSON GUARD');
    return this.store.select(s => s.auth.activePerson).pipe(
      map((activePerson: PersonWithRelations) => {
        if (activePerson) {
          if (activePerson.isVerified && activePerson.interest) {
            this.isRedirecting = false;
            return true;
          }
          if (!activePerson.interest && !this.isRedirecting) {
            this.isRedirecting = true;
            this.routerStateService.addQueryParam(QueryParam.RedirectUrl, state.url);
            this.router.navigate([PATH_DEFINITIONS.PROFILE.PROFILE_ONBOARDING_PATH]).then(() => {
              this.isRedirecting = false;
            });
            return false;
          }
        }
        return true;
      })
    );
  }
}
