import { ButtonComponentConfig, IconType } from '@rappider/rappider-components/utils';

export const componentTitleBarButtons: ButtonComponentConfig[] = [
    // {
    //     key: 'add-container-before',
    //     tooltipText: 'Add a container before this component',
    //     icon: {
    //         name: 'fa-kit fa-regular-square-dashed-circle-arrow-right fa-flip-horizontal',
    //         type: IconType.FontAwesome,
    //         color: 'var(--text-and-icon-color)'
    //     }
    // },
    // {
    //     key: 'add-container-after',
    //     tooltipText: 'Add a container after this component',
    //     icon: {
    //         name: 'fa-kit fa-regular-square-dashed-circle-arrow-right',
    //         type: IconType.FontAwesome,
    //         color: 'var(--text-and-icon-color)'
    //     }
    // },
    // {
    //     key: 'add-component-before',
    //     tooltipText: 'Add a component before this component',
    //     icon: {
    //         name: 'fa-kit fa-sharp-regular-puzzle-piece-simple-circle-arrow-right fa-flip-horizontal',
    //         type: IconType.FontAwesome,
    //         color: 'var(--text-and-icon-color)'
    //     }
    // },
    // {
    //     key: 'add-component-after',
    //     tooltipText: 'Add a component after this component',
    //     icon: {
    //         name: 'fa-kit fa-sharp-regular-puzzle-piece-simple-circle-arrow-right',
    //         type: IconType.FontAwesome,
    //         color: 'var(--text-and-icon-color)'
    //     }
    // },
    {
        key: 'component-add-comment',
        tooltipText: 'Add a comment for this component',
        icon: {
            name: 'fa-kit fa-regular-comment-circle-plus',
            type: IconType.FontAwesome,
            color: 'var(--text-and-icon-color)'
        }
    },
    {
        key: 'subscription',
        tooltipText: 'This item has subscription',
        icon: {
            name: 'fa-light fa-database',
            type: IconType.FontAwesome,
            color: 'var(--text-and-icon-color)'
        }
    },
    {
        key: 'see-component-comments',
        tooltipText: 'Click to see comments',
        icon: {
            name: 'fa-regular fa-comment',
            type: IconType.FontAwesome,
            color: 'var(--text-and-icon-color)'
        }
    }
];

export function componentTitleBarButtonVisibilityFunction(button: ButtonComponentConfig, contentTreeItem, hasClipboardData: boolean) {
    if (button?.key === 'subscription' && !contentTreeItem?.component?.dataSubscriptions?.length) {
        return false;
    } else if (button?.key === 'see-component-comments' && !contentTreeItem?.component?.comments?.length) {
        return false;
    }
    return true;
}
