import { IconType, MenuMode, MenuConfig, MenuActionBehavior } from '@rappider/rappider-components/utils';
import { ContentEditorRightSidebarMenuTabKey } from './content-editor-right-sidebar-menu-tab-key.enum';

export const RIGHT_SIDEBAR_BOTTOM_MENU_CONFIG: MenuConfig = {
  items: [
    {
      label: '',
      tooltip: 'Update Global Style',
      key: ContentEditorRightSidebarMenuTabKey.UpdateGlobalStyle,
      icon: {
        name: 'fa-light fa-globe',
        type: IconType.FontAwesome
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    {
      label: '',
      tooltip: 'Update Theme',
      key: ContentEditorRightSidebarMenuTabKey.UpdateTheme,
      icon: {
        name: 'fa-light fa-palette',
        type: IconType.FontAwesome
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    /*
    {
      label: '',
      tooltip: 'Add Template to Page',
      key: ContentEditorRightSidebarMenuTabKey.AddTemplateToPage,
      icon: {
        name: 'fa-light fa-files',
        type: IconType.FontAwesome
      },
      actionBehavior: MenuActionBehavior.Emit
    },
    */
    // {
    //   label: '',
    //   tooltip: 'Page Variables',
    //   key: ContentEditorRightSidebarMenuTabKey.PageVariables,
    //   icon: {
    //     name: 'fa-light fa-x',
    //     type: IconType.FontAwesome
    //   },
    //   actionBehavior: MenuActionBehavior.Emit
    // }
  ],
  mode: MenuMode.Inline
};
