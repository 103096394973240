import { ButtonComponentConfig, IconType } from '@rappider/rappider-components/utils';

export const DiagramItemDeleteButtonConfig: ButtonComponentConfig = {
  text: 'SHARED.DELETE',
  icon: {
    name: 'fas fa-trash',
    type: IconType.FontAwesome
  },
  block: true,
  popconfirmTitle: 'DIAGRAM_EDITOR_MODULE.DELETE_DIAGRAM_ITEM_CONFIRMATION'
};
