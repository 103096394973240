<!-- For each section; add a collapse, flex columns -->

<!-- DISPLAY MODE -->
<div *ngIf="!editMode"
     class="crud-display-collapse-container">

  <!-- WITH COLLAPSIBLE SECTIONS -->
  <ng-container *ngIf="config?.showInCollapsedSections">
    <nz-collapse *ngFor="let itemSection of itemSections">
      <nz-collapse-panel nzActive="true"
                         [ngClass]="collapsePanelCustomClass || ''"
                         [nzActive]="collapsePanelsActive"
                         [nzHeader]="itemSection?.titleType ? getTitleWithHeadingType(itemSection?.titleType, itemSection?.title) : itemSection.title">
        <ng-container *ngTemplateOutlet="section; context: {$implicit: itemSection}"></ng-container>
      </nz-collapse-panel>
    </nz-collapse>
  </ng-container>

  <!-- WITHOUT SECTIONS -->
  <ng-container *ngIf="!config?.showInCollapsedSections">
    <div *ngFor="let itemSection of itemSections">
      <ng-container *ngTemplateOutlet="section; context: {$implicit: itemSection}"></ng-container>
    </div>
  </ng-container>

</div>




<div *ngIf="editMode">
  <rappider-crud-view-edit-form [config]="config"
                                [data]="data"
                                (fieldValueChange)="onFieldValueChange($event)"
                                (formSubmit)="onFormSubmit($event)"
                                (cancelButtonClick)="onFormCancel()"></rappider-crud-view-edit-form>

</div>

<!-- for each item in the section add a field displayer -->


<ng-template #noValue>
  <div class="no-value">
    <nz-skeleton [nzActive]="true"
                 [nzParagraph]="false"></nz-skeleton>
  </div>
</ng-template>


<!-- SECTION CONTENT -->
<ng-template #section
             let-itemSection>
  <div class="section-container">
    <div *ngFor="let item of groupedItems[itemSection.title]"
         class="item-area"
         [style.width]="getItemSize(itemSection)">

      <!-- TITLE -->
      <div class="property-title">
        <span>{{ item.title | translate }}</span>
        <i *ngIf="item.fieldDescription"
           class="fa fa-info-circle"
           [nzTooltipTitle]="item.fieldDescription | translate"
           nzTooltipPlacement="right"
           nz-tooltip></i>
      </div>
      <!-- END OF TITLE -->

      <!-- VALUE -->
      <div *ngIf="data && item?.fieldName else noValue"
           class="property-value">
        <ng-container *ngIf="item?.type === CrudViewFormItemType.Table">
          <rappider-table [config]="item?.settings"
                          [data]="data[item?.fieldName]"></rappider-table>
        </ng-container>
        <ng-container *ngIf="item?.type === CrudViewFormItemType.CheckBox">
          <i *ngIf="!!data[item.fieldName]"
             class="fa-light fa-square-check"></i>
          <i *ngIf="!data[item?.fieldName]"
             class="fa-light fa-square"></i>
        </ng-container>
        <ng-container *ngIf="item?.type === CrudViewFormItemType.MarkDown">
          <rappider-markdown-viewer [markdownText]="data[item?.fieldName]"></rappider-markdown-viewer>
        </ng-container>
        <ng-container *ngIf="!itemsDoNotShowAsLabel?.includes(item?.type)">
          <rappider-text [text]="data[item?.fieldName]"></rappider-text>
        </ng-container>
      </div>
      <!-- END OF VALUE -->

    </div>
  </div>
</ng-template>
