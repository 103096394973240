import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { SelectionModel } from '@angular/cdk/collections';

import { FlatTreeControl } from '@angular/cdk/tree';

import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import { Store } from '@ngrx/store';
import { GetProjectSourceCode, GetProjectSourceFileCustomCodes, GetProjectSourceFiles } from '../../project-source-code-data-source/project-source-code.actions';
import { ProjectSourceCode, ProjectSourceFile } from '../../project-source-code-data-source/project-source-code.interfaces';
import { TreeNode } from './project-source-files';
import { ProjectInterface, ProjectModel } from '@rappider/api-sdk';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import { selectActiveProjectFileSourceCode, selectProjectSourceCodeState, selectProjectSourceFiles } from '../../project-source-code-data-source/project-source-code.selectors';

export interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  // disabled: boolean;
  fullPath?: string;
};

@Component({
  selector: 'rappider-project-source-files',
  templateUrl: './project-source-files.component.html',
  styleUrls: ['./project-source-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSourceFilesComponent implements OnInit, AfterViewInit, OnDestroy {

  /*
  children
  name
  fullPath
  type
    "folder"
  */

  @Output() pathChanged = new EventEmitter<NzTreeNode>();

  activatedNode?: NzTreeNode;
  activeSourceCodeKey?: string;

  projectSourceFiles: ProjectSourceFile[] = [];
  projectSourceFilesTreeData: NzTreeNode[] = [];

  sourceFileTree: TreeNode[] = [];

  activeProject: ProjectInterface | null = null;

  subscriptions: Subscription[] = [];

  treeData: any[] = [];

  selectListSelection = new SelectionModel<FlatNode>();

  // treeFlattener;

  // dataSource;

  showLeafIcon = false;

  // flag to set the project source code files initialized
  isProjectSourceCodeFilesInitialized = false;

  /* For searching the files */
  searchWord = '';

  // Search text box configuration
  searchTextBoxConfig = {
    suffixIcon: {
      name: 'fa-regular fa-magnifying-glass',
    },
    textbox: {
      placeholder: 'Search files',
    }
  };

  // add a rxjs delay to avoid keypress in 200ms
  // Create a subject to track key presses
  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  /* subscribed project data */
  // projectModels: ProjectModel[] = [];

  // treeControl = new FlatTreeControl<FlatNode>(
  //   node => node.level,
  //   node => node.expandable
  // );

  // transformer = (node: TreeNode, level: number): FlatNode => ({
  //   expandable: node?.children ? node.children?.length > 0 : false,
  //   name: node.name,
  //   level,
  //   fullPath: node.fullPath,
  // });

  private treeDataInitialized = false;

  private readonly isDebug = true;

  private log(...args: any[]): void {
    if (this.isDebug) {
      console.log('------------------------------------------------');
      console.log(...args);
      console.log('------------------------------------------------');
    }
  }

  constructor(
    private store: Store<any>,
    private cdr: ChangeDetectorRef
  ) {

    //   this.treeFlattener = new NzTreeFlattener(
    //     this.transformer,
    //     node => node.level,
    //     node => node.expandable,
    //     node => node.children
    //   );

    //   this.dataSource = new NzTreeFlatDataSource(<any>this.treeControl, <any>this.treeFlattener);

    //   this.showLeafIcon = false;

  }

  // hasChild = (_: number, node: FlatNode): boolean => node.expandable;

  ngAfterViewInit(): void {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      // this.subscribeToSearchFiles(),
      this.subscribeToActiveProjectFileSourceCode()
    ];
  }

  ngOnInit(): void {
    this.refreshProjectSourceCodeFiles();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    // Unsubscribe and complete the subjects
    this.destroy$.next();
    this.destroy$.complete();
    this.treeDataInitialized = false;
  }

  refreshProjectSourceCodeFiles() {
    if (this.activeProject?.id) {
      this.isProjectSourceCodeFilesInitialized = true;
      if (!this.projectSourceFiles?.length) {
        this.store.dispatch(GetProjectSourceFiles());
      }
    }
  }

  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe((activeProject: ProjectInterface) => {
      if (activeProject?.id) {
        this.activeProject = activeProject;
        this.subscribeToData();
      }
    });
  }

  subscribeToData() {
    this.subscriptions.push(...[
      this.subscribeToSourceCodeFiles()
    ]);
  }

  subscribeToSourceCodeFiles() {
    return this.store.select(selectProjectSourceFiles)
      .pipe(
        // Only emit if the value has changed
        distinctUntilChanged((prev, curr) =>
          JSON.stringify(prev) === JSON.stringify(curr)
        ),
        filter(files => !!files && files.length > 0)
      )
      .subscribe((projectSourceFiles: ProjectSourceFile[] | null) => {
        if (projectSourceFiles?.length) {
          // if the sourceFileTree is not initialized, initialize it
          if (JSON.stringify(this.sourceFileTree) !== JSON.stringify(projectSourceFiles)) {
            this.projectSourceFiles = projectSourceFiles;
            this.sourceFileTree = [...projectSourceFiles];

            if (!this.treeDataInitialized) {
              setTimeout(() => {
                this.setTreeData(this.sourceFileTree);
                this.treeDataInitialized = true;
              }, 200);
            }
          }
        } else if (!this.isProjectSourceCodeFilesInitialized) {
          this.isProjectSourceCodeFilesInitialized = true;
          setTimeout(() => {
            this.refreshProjectSourceCodeFiles();
          }, 1000);
        }
      });
  }

  subscribeToActiveProjectFileSourceCode() {
    return this.store.select(selectActiveProjectFileSourceCode)
      .subscribe((activeProjectFileSourceCode: ProjectSourceCode | null) => {
        if (activeProjectFileSourceCode?.fullPath != null) {
          // this.selectNodeByFullPath(activeProjectFileSourceCode.fullPath);
        }
      });
  }

  setTreeData(projectSourceFiles: ProjectSourceFile[]): void {
    const newTreeData = this.mapProjectSourceFilesToTreeData(projectSourceFiles);
    if (JSON.stringify(this.projectSourceFilesTreeData) !== JSON.stringify(newTreeData)) {
      this.projectSourceFilesTreeData = newTreeData;
      this.log('Tree Data Updated:', this.projectSourceFilesTreeData);
      this.cdr.detectChanges();
    }
  }

  // expandAllFolders() {
  //   setTimeout(() => {
  //     this.treeControl.expandAll();
  //   }, 500);
  // }

  // getNode(name: string): FlatNode | null {
  //   return this.treeControl.dataNodes.find(n => n.name === name) || null;
  // }

  getSourceCode(sourceFilePath: string | undefined): void {
    if (sourceFilePath) {
      this.store.dispatch(GetProjectSourceCode({ payload: { sourceFilePath: sourceFilePath } }));
    }
  }

  getCustomCodes(sourceFilePath: string | undefined): void {
    if (sourceFilePath) {
      this.store.dispatch(GetProjectSourceFileCustomCodes({ payload: { sourceFilePath: sourceFilePath } }));
    }
  }

  /**
   * Maps the project source files to tree data
   *
   * @param {ProjectSourceFile[]} projectSourceFiles
   * @param {*} [visitedKeys=new Set<string>()]
   * @param {number} [depth=0]
   * @param {number} [maxDepth=5]
   * @return {*}  {NzTreeNode[]}
   * @memberof ProjectSourceFilesComponent
   */
  mapProjectSourceFilesToTreeData(
    projectSourceFiles: ProjectSourceFile[],
    visitedKeys = new Set<string>(),
    depth = 0,
    maxDepth = 5
  ): NzTreeNode[] {
    if (depth > maxDepth) {
      return [];
    }

    return <NzTreeNode[]>(
      projectSourceFiles?.map((projectSourceFile: ProjectSourceFile) => {
        const key = projectSourceFile?.fullPath || '';
        if (visitedKeys.has(key)) {
          console.log(`Circular reference detected at key: ${key}`);
          return null;
        }

        visitedKeys.add(key);

        return <NzTreeNode><unknown>{
          title: projectSourceFile?.name || '--',
          key,
          type: projectSourceFile?.type,
          icon: projectSourceFile?.type === 'folder' ? null : 'file',
          selected: false,
          isLeaf: projectSourceFile?.type === 'file',
          expanded: projectSourceFile?.type === 'folder',
          children: projectSourceFile?.children?.length
            ? this.mapProjectSourceFilesToTreeData(projectSourceFile.children, visitedKeys, depth + 1, maxDepth)
            : []
        } as NzTreeNode;
      })?.filter(Boolean) || []
    );
  }

  onTreeNodeClicked(event: any): void {
    console.log('onTreeNodeClicked', event);
    if (event?.node?.origin.type) {
      switch (event?.node?.origin.type) {
        case 'folder':
          this.folderNodeClicked(event?.node?.origin);
          break;
        case 'file':
          this.leafNodeClicked(event?.node?.origin);
          break;
      }
    }
  }

  /* leaf Node Clicked on the file tree */
  leafNodeClicked(node: NzTreeNode): void {
    console.log('leafNodeClicked', node);
    this.pathChanged.emit(node);
    this.getSourceCode(node?.key);
    this.getCustomCodes(node?.key);
  }

  /* folder node clicked on the file menu */
  folderNodeClicked(node: NzTreeNode): void {
    console.log('folderNodeClicked', node);
  }

  onSearchFiles(searchText: string): void {
    // if (!searchText.trim()) {
    //   setTimeout(() => {
    //     this.dataSource.setData(this.sourceFileTree);
    //   }, 100);
    // } else {
    //   // Cancel any previous search and start a new one
    //   this.searchSubject.next(searchText);
    // }
  }

  // subscribeToSearchFiles() {
  //   // Subscribe to the search subject
  //   return this.searchSubject.pipe(
  //     debounceTime(1500),
  //     distinctUntilChanged(),
  //     takeUntil(this.destroy$)
  //   ).subscribe((searchWord: string) => {
  //     if (searchWord.trim()) {
  //       const searchResults = this.findMatchingNodes(searchWord);
  //       setTimeout(() => {
  //         this.dataSource.setData(searchResults);
  //       }, 250);
  //       setTimeout(() => {
  //         this.expandAllFolders();
  //       }, 500);
  //     } else {
  //       setTimeout(() => {
  //         this.dataSource.setData(this.sourceFileTree);
  //       }, 250);
  //     }
  //   });
  // }

  // // this function should push all the nodes if they have children but not the leaf nodes
  // findMatchingNodes(searchString: string): TreeNode[] {
  //   const matchingNodes: TreeNode[] = [];
  //   const traverseTree = (nodes: TreeNode[], parentPath: string = '') => {
  //     for (const node of nodes) {
  //       const fullPath = parentPath ? `${parentPath}/${node.name}` : node.name;

  //       if (node.children?.length === 0 || !node.children) {
  //         if (node.name.toLowerCase().includes(searchString.toLowerCase())) {
  //           matchingNodes.push({ ...node, fullPath: fullPath });
  //         }
  //       } else {
  //         traverseTree(node.children, fullPath);
  //       }
  //     }
  //   };
  //   traverseTree(this.sourceFileTree);

  //   const searchedFilesTree = this.buildTreeFromTheFlatNodesArray(matchingNodes);
  //   return searchedFilesTree;
  // }

  // selectNodeByFullPath(fullPath: string): void {
  //   console.log('selectNodeByFullPath: ', fullPath);
  //   const node = this.findNodeByPath(fullPath);
  //   if (node?.fullPath) {
  //     // convert node to flatNode
  //     // Calculate the level based on the number of '/' in fullPath
  //     const nodeLevel = (node?.fullPath.match(/\//g) || []).length;

  //     const flatNode = this.transformer(node, nodeLevel);// .getNode(node.name);
  //     this.selectListSelection.toggle(flatNode);
  //     this.treeControl.expand(flatNode);
  //   }
  // }

  findNodeByPath(fullPath: string): TreeNode | null {
    const traverseTree = (nodes: TreeNode[], parentPath: string = ''): TreeNode | null => {
      for (const node of nodes) {
        const currentPath = parentPath ? `${parentPath}/${node.name}` : node.name;

        if (currentPath === fullPath) {
          return node;
        }

        if (node.children && node.children.length > 0) {
          const foundNode = traverseTree(node.children, currentPath);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return null;
    };

    return traverseTree(this.sourceFileTree);
  }

  // buildTreeFromTheFlatNodesArray(nodes: TreeNode[]): TreeNode[] {
  //   const root: TreeNode = { name: '', fullPath: '', type: 'folder', children: [] };

  //   nodes.forEach(node => {
  //     const parts = node.fullPath?.split('/') || [];
  //     let current = root;

  //     parts.forEach((part, index) => {
  //       let child = current?.children?.find(c => c.name === part);

  //       if (!child) {
  //         child = {
  //           name: part,
  //           fullPath: parts.slice(0, index + 1).join('/'),
  //           type: index === parts.length - 1 ? node.type : 'folder',
  //           children: []
  //         };
  //         current.children?.push(child);
  //       }

  //       current = child;
  //     });
  //   });

  //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //   return root.children!;
  // }

  // filterTreeData(data: TreeNode[], value: string): FilteredTreeResult {
  //   const needsToExpanded = new Set<TreeNode>();
  //   const _filter = (node: TreeNode, result: TreeNode[]): TreeNode[] => {
  //     if (node.name.search(value) !== -1) {
  //       result.push(node);
  //       return result;
  //     }
  //     if (Array.isArray(node.children)) {
  //       const nodes = node.children.reduce((a, b) => _filter(b, a), [] as TreeNode[]);
  //       if (nodes.length) {
  //         const parentNode = { ...node, children: nodes };
  //         needsToExpanded.add(parentNode);
  //         result.push(parentNode);
  //       }
  //     }
  //     return result;
  //   };
  //   const treeData = data.reduce((a, b) => _filter(b, a), [] as TreeNode[]);
  //   return new FilteredTreeResult(treeData, [...needsToExpanded]);
  // }


}
