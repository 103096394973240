<div class="ui-data-selector-split-view-wrapper">
  <div class="ui-data-selector-name">
    <input [(ngModel)]="uiDataSelectorName"
           nz-input
           nzSize="small"
           [ngClass]="{'ui-data-selector-name-error': isExistError}"
           placeholder="UI Data Selector Name"
           (ngModelChange)="onUiDataSelectorNameChange($event)">
    <rappider-input-error [errors]="isExistError ? ['Enter a Ui Data Selector Name'] : []"></rappider-input-error>
  </div>
  <div class="main-area">
    <as-split direction="horizontal"
              [restrictMove]="true"
              [gutterSize]="2">
      <as-split-area [size]="20"
                     gutterSize="50">
        <div class="split-section border-right-section">
          <rappider-ui-data-store-field-tree-selector [(ngModel)]="dataSources"
                                                      (ngModelChange)="fieldTreeSelectorChange()"></rappider-ui-data-store-field-tree-selector>
        </div>
      </as-split-area>
      <as-split-area [size]="40">
        <div class="split-section border-right-section">
          <rappider-monaco-code-editor [(ngModel)]="sampleJson"
                                       [options]="sampleJsonOptions"
                                       [showSelectedKeyPath]="true"
                                       [loading]="sampleJsonLoading"
                                       [autoFormat]="true"
                                       [borderSettings]="{border: 'none'}"></rappider-monaco-code-editor>
        </div>
      </as-split-area>
      <as-split-area [size]="40">
        <as-split direction="vertical"
                  [restrictMove]="true">
          <as-split-area>
            <div class="split-section border-right-bottom">
              <div class="sample-json-editor-wrapper ">
                <rappider-jsonata-editor [(ngModel)]="jsonataConfig"
                                         (ngModelChange)="setPreviewOfJsonataConfig()"></rappider-jsonata-editor>
              </div>
            </div>
          </as-split-area>
          <as-split-area>
            <div class="split-section">
              <rappider-monaco-code-editor [ngModel]="scriptOutput"
                                           [options]="scriptOutputOptions"
                                           [autoFormat]="true"
                                           [borderSettings]="{border: 'none'}"></rappider-monaco-code-editor>
            </div>
          </as-split-area>
        </as-split>
      </as-split-area>
    </as-split>
  </div>
  <div class="buttons">
    <button nz-button
            nzType="default"
            nzSize="small"
            (click)="onCancel()">Cancel</button>
    <button nz-button
            nzType="primary"
            nzSize="small"
            (click)="onSave()">Save</button>
  </div>
</div>