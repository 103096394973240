/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ComponentDefinition } from '../models/component-definition';
import { CopyProjectRequestDto } from '../models/copy-project-request-dto';
import { ImproveProjectDescriptionByAiResponseDto } from '../models/improve-project-description-by-ai-response-dto';
import { NewProject } from '../models/new-project';
import { OpenAiRequestDto } from '../models/open-ai-request-dto';
import { Person } from '../models/person';
import { PersonWithRelations } from '../models/person-with-relations';
import { Project } from '../models/project';
import { ProjectPartial } from '../models/project-partial';
import { ProjectRole } from '../models/project-role';
import { ProjectUpdateDtoPartial } from '../models/project-update-dto-partial';
import { ProjectWithRelations } from '../models/project-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ProjectControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectControllerCountByAdmin
   */
  static readonly ProjectControllerCountByAdminPath = '/admin/projects/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  countByAdmin$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerCountByAdminPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countByAdmin(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.countByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation projectControllerFindByIdByAdmin
   */
  static readonly ProjectControllerFindByIdByAdminPath = '/admin/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByIdByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByIdByAdmin$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ProjectWithRelations>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerFindByIdByAdminPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectWithRelations>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByIdByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByIdByAdmin(params: {
    id: string;
    filter?: any;
  }): Observable<ProjectWithRelations> {

    return this.findByIdByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectWithRelations>) => r.body as ProjectWithRelations)
    );
  }

  /**
   * Path part for operation projectControllerUpdateByIdByAdmin
   */
  static readonly ProjectControllerUpdateByIdByAdminPath = '/admin/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateByIdByAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateByIdByAdmin$Response(params: {
    id: string;
    body?: ProjectPartial
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerUpdateByIdByAdminPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateByIdByAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateByIdByAdmin(params: {
    id: string;
    body?: ProjectPartial
  }): Observable<void> {

    return this.updateByIdByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectControllerFindByAdmin
   */
  static readonly ProjectControllerFindByAdminPath = '/admin/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAdmin$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerFindByAdminPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAdmin(params?: {
    filter?: any;
  }): Observable<Array<ProjectWithRelations>> {

    return this.findByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectWithRelations>>) => r.body as Array<ProjectWithRelations>)
    );
  }

  /**
   * Path part for operation projectControllerSoftDeleteCopiedEntitiesById
   */
  static readonly ProjectControllerSoftDeleteCopiedEntitiesByIdPath = '/projects/copy/{projectId}';

  /**
   * Deleting entities copied from another project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softDeleteCopiedEntitiesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDeleteCopiedEntitiesById$Response(params: {
    projectId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerSoftDeleteCopiedEntitiesByIdPath, 'delete');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deleting entities copied from another project.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `softDeleteCopiedEntitiesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softDeleteCopiedEntitiesById(params: {
    projectId: string;
  }): Observable<void> {

    return this.softDeleteCopiedEntitiesById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectControllerCopyProject
   */
  static readonly ProjectControllerCopyProjectPath = '/projects/copy';

  /**
   * Copies source project's all entities by creating new ones into another project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copyProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyProject$Response(params?: {
    body?: CopyProjectRequestDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerCopyProjectPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Copies source project's all entities by creating new ones into another project.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copyProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copyProject(params?: {
    body?: CopyProjectRequestDto
  }): Observable<void> {

    return this.copyProject$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectControllerCount
   */
  static readonly ProjectControllerCountPath = '/projects/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation projectControllerGenerateByAi
   */
  static readonly ProjectControllerGenerateByAiPath = '/projects/improve-description-by-ai';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateByAI()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateByAI$Response(params?: {
    body?: OpenAiRequestDto
  }): Observable<StrictHttpResponse<ImproveProjectDescriptionByAiResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerGenerateByAiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImproveProjectDescriptionByAiResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateByAI$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateByAI(params?: {
    body?: OpenAiRequestDto
  }): Observable<ImproveProjectDescriptionByAiResponseDto> {

    return this.generateByAI$Response(params).pipe(
      map((r: StrictHttpResponse<ImproveProjectDescriptionByAiResponseDto>) => r.body as ImproveProjectDescriptionByAiResponseDto)
    );
  }

  /**
   * Path part for operation projectControllerFindMembers
   */
  static readonly ProjectControllerFindMembersPath = '/projects/members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  findMembers$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<Person>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerFindMembersPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Person>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findMembers(params?: {
    filter?: any;
  }): Observable<Array<Person>> {

    return this.findMembers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Person>>) => r.body as Array<Person>)
    );
  }

  /**
   * Path part for operation projectControllerFindProjectMembersWithTheirRoles
   */
  static readonly ProjectControllerFindProjectMembersWithTheirRolesPath = '/projects/members-with-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findProjectMembersWithTheirRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProjectMembersWithTheirRoles$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<PersonWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerFindProjectMembersWithTheirRolesPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findProjectMembersWithTheirRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProjectMembersWithTheirRoles(params?: {
    filter?: any;
  }): Observable<Array<PersonWithRelations>> {

    return this.findProjectMembersWithTheirRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PersonWithRelations>>) => r.body as Array<PersonWithRelations>)
    );
  }

  /**
   * Path part for operation projectControllerFindProjectRoles
   */
  static readonly ProjectControllerFindProjectRolesPath = '/projects/project-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findProjectRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProjectRoles$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectRole>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerFindProjectRolesPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectRole>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findProjectRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProjectRoles(params?: {
    filter?: any;
  }): Observable<Array<ProjectRole>> {

    return this.findProjectRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectRole>>) => r.body as Array<ProjectRole>)
    );
  }

  /**
   * Path part for operation projectControllerFindPublicProjects
   */
  static readonly ProjectControllerFindPublicProjectsPath = '/projects/public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findPublicProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  findPublicProjects$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerFindPublicProjectsPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findPublicProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findPublicProjects(params?: {
    filter?: any;
  }): Observable<Array<ProjectWithRelations>> {

    return this.findPublicProjects$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectWithRelations>>) => r.body as Array<ProjectWithRelations>)
    );
  }

  /**
   * Path part for operation projectControllerGetProjectComponentDefinitionsDataSchemas
   */
  static readonly ProjectControllerGetProjectComponentDefinitionsDataSchemasPath = '/projects/{projectId}/get-project-component-definitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProjectComponentDefinitionsDataSchemas()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectComponentDefinitionsDataSchemas$Response(params: {
    projectId: string;
  }): Observable<StrictHttpResponse<Array<ComponentDefinition>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerGetProjectComponentDefinitionsDataSchemasPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ComponentDefinition>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProjectComponentDefinitionsDataSchemas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectComponentDefinitionsDataSchemas(params: {
    projectId: string;
  }): Observable<Array<ComponentDefinition>> {

    return this.getProjectComponentDefinitionsDataSchemas$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ComponentDefinition>>) => r.body as Array<ComponentDefinition>)
    );
  }

  /**
   * Path part for operation projectControllerFindById
   */
  static readonly ProjectControllerFindByIdPath = '/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<Project>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Project>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<Project> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<Project>) => r.body as Project)
    );
  }

  /**
   * Path part for operation projectControllerUpdateById
   */
  static readonly ProjectControllerUpdateByIdPath = '/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ProjectUpdateDtoPartial
  }): Observable<StrictHttpResponse<Project>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Project>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ProjectUpdateDtoPartial
  }): Observable<Project> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<Project>) => r.body as Project)
    );
  }

  /**
   * Path part for operation projectControllerDeleteById
   */
  static readonly ProjectControllerDeleteByIdPath = '/projects/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    projectId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    projectId: string;
  }): Observable<void> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation projectControllerFind
   */
  static readonly ProjectControllerFindPath = '/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ProjectWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectWithRelations>>) => r.body as Array<ProjectWithRelations>)
    );
  }

  /**
   * Path part for operation projectControllerCreate
   */
  static readonly ProjectControllerCreatePath = '/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewProject
  }): Observable<StrictHttpResponse<Project>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectControllerService.ProjectControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Project>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewProject
  }): Observable<Project> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Project>) => r.body as Project)
    );
  }

}
