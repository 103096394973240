import { createAction, createReducer, on } from '@ngrx/store';
import { DbDiagramNode } from '@rappider/rappider-sdk';
import { ProjectModelFieldActions } from '@rappider/project-model-field';
import * as DbDiagramActions from './db-diagram.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as ProjectModelActions from '../../../../../libs/project/src/lib/states/project-model-state/project-model.actions';

/* state key */
export const featureKey = 'dbDiagramState';

/* state interface */
export interface DBDiagramState {
  dbDiagramNodes: DbDiagramNode[];
  isLoaded: boolean;
  formPanelVisibility?: boolean;
  updateScrollingDataWithScaling: { id: string, renderingAreaPercent: number, isLastModel: boolean, isAnimateStart: boolean };
  focusToRelatedModelId: string;
}

/* initial values */
export const initialState: DBDiagramState = {
  dbDiagramNodes: [],
  isLoaded: false,
  formPanelVisibility: null,
  updateScrollingDataWithScaling: null,
  focusToRelatedModelId: null
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);
const DeleteProjectModelSuccessful = createAction(ProjectModelActions.ActionTypes.DeleteProjectModelSuccessful);

export const reducer = createReducer(
  initialState,
  on(DbDiagramActions.GetDBDiagramNodesSuccessful, (state, action) => ({
    ...state,
    dbDiagramNodes: action.payload.dbDiagramNodes,
    isLoaded: true
  })),
  on(DbDiagramActions.CreateDBDiagramNodeSuccessful, (state, action) => ({
    ...state,
    dbDiagramNodes: [...state.dbDiagramNodes, action.payload.dbDiagramNode],
  })),
  on(DbDiagramActions.UpdateDBDiagramNodeSuccessful, (state, action) => {
    const updatedNode = {
      ...state.dbDiagramNodes.find(diagramNode => action.payload.id === diagramNode.id),
      ...action.payload.dbDiagramNode
    } as DbDiagramNode;

    return {
      ...state,
      dbDiagramNodes: [
        ...state.dbDiagramNodes.filter(diagramNode => diagramNode.id !== action.payload.id),
        updatedNode
      ]
    };
  }),
  on(DeleteProjectModelSuccessful, (state) => ({
    ...state,
    formPanelVisibility: false
  })),
  on(DbDiagramActions.UpdateFormPanelVisibility, (state, action) => ({
    ...state,
    formPanelVisibility: action.payload.visibility
  })),
  on(DbDiagramActions.UpdateScrollingDataModulId, (state, action) => ({
    ...state,
    updateScrollingDataWithScaling: {
      id: action.payload.updateScrollingDataWithScaling?.id || state.updateScrollingDataWithScaling?.id,
      renderingAreaPercent: action.payload.updateScrollingDataWithScaling?.renderingAreaPercent || state.updateScrollingDataWithScaling?.renderingAreaPercent,
      isLastModel: action.payload.updateScrollingDataWithScaling?.isLastModel || state.updateScrollingDataWithScaling?.isLastModel,
      isAnimateStart: action.payload.updateScrollingDataWithScaling?.isAnimateStart || state.updateScrollingDataWithScaling?.isAnimateStart
    }
  })),
  on(DbDiagramActions.FocusToRelatedModelId, (state, action) => ({
    ...state,
    focusToRelatedModelId: action.payload.focusToRelatedModelId
  })),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
