import { Validators } from '@angular/forms';
import {
  ActionBehavior,
  CrudFormConfig,
  CrudFormSelectItem,
  CrudTableViewConfig,
  CrudViewColumnType,
  CrudViewFormItemType,
  FormLayout,
  CrudFormRichTextEditorItem,
  InputSize,
  ButtonType,
  CrudFormLabelItem,
  CrudFormConfigInputChangeReaction
} from '@rappider/rappider-components/utils';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';
import { VERSION_TYPES } from '../../definitions/project-version-definitions';

export const PROJECT_VERSION_LIST_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'title',
  filterable: false,
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.VERSION',
      fieldName: 'versionNumber',
      type: CrudViewColumnType.Link,
      routerLink: `${PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_DETAIL}/{{id}}`
    },
    {
      title: 'SHARED.CREATED_DATE',
      fieldName: 'createdDate',
      type: CrudViewColumnType.DateTime
    },
    {
      title: 'SHARED.CREATED_BY',
      fieldName: 'createdBy',
      type: CrudViewColumnType.Text
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.ADD_NEW_VERSION',
      behavior: ActionBehavior.Route,
      icon: { name: 'fas fa-plus' },
      buttonType: ButtonType.Default,
      redirectUrl: PATH_DEFINITIONS.PROJECTS.PROJECT_VERSION_CREATE
    }
  ],
  pageSize: 10,
  showPagination: true,
};

export const PROJECT_VERSION_CREATE_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormSelectItem>{
      title: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.VERSION_TYPE',
      fieldName: 'versionType',
      type: CrudViewFormItemType.Select,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.SELECT_VERSION_TYPE',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: [
        { key: VERSION_TYPES.PATCH.key, value: VERSION_TYPES.PATCH.value },
        { key: VERSION_TYPES.MINOR.key, value: VERSION_TYPES.MINOR.value },
        { key: VERSION_TYPES.MAJOR.key, value: VERSION_TYPES.MAJOR.value }]
    },
    <CrudFormLabelItem>{
      title: 'PROJECT_MODULE.PROJECT_VERSION_DETAIL_COMPONENT.NEW_VERSION_NUMBER',
      fieldName: 'versionNumber',
      type: CrudViewFormItemType.Label
    },
    <CrudFormRichTextEditorItem>{
      title: 'SHARED.DESCRIPTION',
      fieldName: 'description',
      type: CrudViewFormItemType.RichTextEditor,
    },
  ],
  validators: [
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    }
  },
  submitButton: {
    visible: true,
    text: 'PROJECT_MODULE.PROJECT_VERSIONS_COMPONENT.CREATE_VERSION',
    type: ButtonType.Primary
  },
  inputChangeReaction: CrudFormConfigInputChangeReaction.Blur,
  formLayout: FormLayout.Vertical
};
