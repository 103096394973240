import { ActionBehavior, ActionButtonConfig } from '@rappider/rappider-components/utils';

export const AddWorkflowButtonConfig: ActionButtonConfig[] = [
  {
    key: 'add-workflow',
    text: 'Add Workflow',
    icon: { name: 'far fa-add' },
    behavior: ActionBehavior.Emit,
    visible: true
  }
];
