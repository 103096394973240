import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AddPageButtonConfig, PageListDropdownMenuConfig, PageListModuleDropdownMenuConfig } from '@rappider/shared/configs';
import {
  defaultToolbarTitleHeadingSize, DUPLICATE_PAGE_TITLE_SUFFIX, DUPLICATE_PAGE_DESCRIPTION_PREFIX,
  PAGE_DEFINITIONS, PATH_DEFINITIONS
} from '@rappider/shared/definitions';
import { DeletePage, DuplicatePage, SetHomePage, ChangeDefaultModule } from '../../state/page-state/page.actions';
import { getPagesWithHomePageAndModules } from './selector/page.selector';
import { Navigate } from '@rappider/shared';
import { NotificationService, PageListService, SearchService } from '@rappider/services';
import { PageListItemDropdownAction } from './utils/page-list-item-dropdown-action.enum';
import { PageListModuleDropdownAction } from './utils/page-list-module-dropdown-action.enum';
import { AlertConfig, AlertTypes, BreadcrumbOption, HeadingComponentConfig, HeadingType } from '@rappider/rappider-components/utils';
import { Category, ModuleWithRelations, Page, PageWithRelations, ProjectSetting } from '@rappider/rappider-sdk';
import { pageCategoryTypes } from '../../../../../shared/src/lib/interfaces/page-category-enum';
import { PageDevelopmentStatus } from '@rappider/admin-dashboard';
import { orderBy } from 'lodash';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
/**
 * This component list's the pages, you can go to detail page, content editor, edit page or you can delete pages.
 *
 * @export
 * @class PageListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'rappider-page-list',
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.scss']
})
export class PageListComponent implements OnInit, OnDestroy {
  mainTitle: HeadingComponentConfig = {
    content: 'PAGES_MODULE.PAGE_LIST_COMPONENT.PAGE_LIST',
    type: defaultToolbarTitleHeadingSize
  };
  displayToolbar = false;
  displayToolbarBackButton = false;
  title: string | string[] | BreadcrumbOption[];
  isModulesLoading = false;
  /* List Grid Config */
  subscriptions: Subscription[] = [];
  categoryType = pageCategoryTypes;
  modules: ModuleWithRelations[];
  displayedModules: ModuleWithRelations[] | any[];
  adminModules: ModuleWithRelations[] | any[];
  clientModules: ModuleWithRelations[] | any[];
  mapOfExpandedPageListData: { [key: string]: any[] } = {};
  /* data for nz-select */
  selectedCategoryNames = [];
  /* data filtered by search */
  mappedResults: Page[];
  /* text for search bar */
  searchWord: string;
  /* Category state data */
  categories: Category[];
  /* data for nz-option */
  displayedCategories: Category[];
  /* categorized data */
  defaultModuleId: string;
  pageListDropdownMenuConfig = PageListDropdownMenuConfig;
  pageListModuleDropdownMenuConfig = PageListModuleDropdownMenuConfig;
  /* active Project id data */
  activeProjectId: string;
  addPageButtonConfig = AddPageButtonConfig;
  allCollapsed = false;
  allExpanded = false;
  rappiderInputConfig = {
    suffixIcon: {
      name: 'fa-regular fa-magnifying-glass',
    },
    textbox: {
      placeholder: 'Search by page names',
    }
  };
  pageStatusModalConfig = {
    title: 'This Page is in review',
    footer: null
  };
  pageLoading: boolean;
  pageStatusModalVisibility = false;
  defaultModuleModalVisibility = false;
  selectedModule: ModuleWithRelations;
  selectedPageId: string;
  selectDefaultModuleOptions = [];
  defaultModuleModalOkDisabled = true;
  PageDevelopmentStatus = PageDevelopmentStatus;
  pageListTabs = [
    {
      name: 'Client'
    },
    {
      name: 'Admin'
    }
  ];
  selectedTabIndex = 0;

  defaultModuleAlertConfig: AlertConfig = {
    type: AlertTypes.Info,
    title: {
      type: HeadingType.H5,
      content: 'Please Select a Homepage to Set as Default Module'
    },
    showIcon: true,
    marginSettings: { top: 'var(--margin-4x)' },
    closeable: false
  };

  categoryFilter() {
    if (this.selectedCategoryNames.length) {
      if (this.selectedTabIndex === 0) {
        // Client Tab
        this.displayedModules = this.clientModules.filter(module =>
          module.children?.some(page =>
            this.selectedCategoryNames.some(categoryId => page.categories?.some(category => category.id === categoryId))
          )
        ).map(module => ({
          ...module,
          children: module.children?.filter(page =>
            this.selectedCategoryNames.some(categoryId => page.categories?.some(category => category.id === categoryId))
          )
        }));
      } else {
        // Admin Tab
        this.displayedModules = this.adminModules.filter(module =>
          module.children?.some(page =>
            this.selectedCategoryNames.some(categoryId => page.categories?.some(category => category.id === categoryId))
          )
        ).map(module => ({
          ...module,
          children: module.children?.filter(page =>
            this.selectedCategoryNames.some(categoryId => page.categories?.some(category => category.id === categoryId))
          )
        }));
      }
    } else {
      if (this.selectedTabIndex === 0) {
        this.displayedModules = this.clientModules;
      } else {
        this.displayedModules = this.adminModules;
      }
    }

    this.renderList();
  }

  constructor(
    private store: Store<any>,
    private pageListService: PageListService,
    private searchService: SearchService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToActiveProject(),
      this.subscribeToPageLoading(),
      this.subscribeToModuleLoading(),
      this.subscribeToPagesWithHomePageAndModules(),
      this.subscribeToCategory(),
      this.subscribeToDefaultModuleId()
    ];
  }

  subscribeToActiveProject() {
    /* Subscribe to store */
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      /* Checks if there is active project */
      if (activeProject) {
        this.activeProjectId = activeProject.id;
        this.title = [
          {
            label: activeProject.name,
            redirectUrl: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProject?.id}`
          },
          {
            label: PAGE_DEFINITIONS.PAGES.CHILDREN.PAGES_LIST.PAGE_TITLE
          }
        ];
      }
    });
  }

  setDisplayedCategories() {
    this.displayedCategories = this.categories?.filter(categories => categories.projectId === this.activeProjectId) || [];
  }

  subscribeToCategory() {
    return this.store.select(state => state.category?.data).subscribe(category => {
      this.categories = category;
      this.setDisplayedCategories();
    });
  }

  subscribeToPagesWithHomePageAndModules() {
    return this.store.select(<any>getPagesWithHomePageAndModules).subscribe(data => {
      this.clientModules = data?.filter(module => !module.isAdmin);
      this.adminModules = data?.filter(module => module.isAdmin);

      this.modules = data;
      if (this.selectedTabIndex === 0) {
        this.displayedModules = this.clientModules;
      } else {
        this.displayedModules = this.adminModules;
      }
      if (this.modules) {
        this.modules.forEach(item => {
          this.mapOfExpandedPageListData[item.key] = this.pageListService.convertTreeToList(item);
        });
      }
    });
  }

  subscribeToModuleLoading() {
    return this.store.select(state => state.module?.isLoading).subscribe(isLoading => {
      this.isModulesLoading = isLoading;
    });
  }

  subscribeToPageLoading() {
    return this.store.select(state => state.page?.loading).subscribe(loading => {
      this.pageLoading = loading;
    });
  }

  collapse(mapOfExpandedPageListData: any[], data: any, $event: boolean): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach(d => {
          const target = mapOfExpandedPageListData.find(a => a.key === d.key);
          target.expand = false;
          this.collapse(mapOfExpandedPageListData, target, false);
        });
      } else {
        return;
      }
    }
  }

  getModuleDropdownMenuItems(item: any) {
    if (item.moduleId !== this.defaultModuleId) {
      return this.pageListModuleDropdownMenuConfig.items;
    }
  }

  onModuleDropdownMenuItemClick(action: { key: string }, module: ModuleWithRelations): void {
    const { key } = action;
    switch (key) {
      case PageListModuleDropdownAction.SetAsDefaultModule:
        if (module?.children.length) {
          this.defaultModuleModalVisibility = true;
          this.selectedModule = module;
          this.setDefaultModulePages(this.selectedModule);
        } else {
          this.notificationService.createNotification(
            'error',
            'Unable to Change Default Module',
            'To set a module as the default, it must contain at least one page, and one of those pages should be designated as the home page. Please ensure the selected module meets these requirements before proceeding.'
          );
        }
        break;

      default:
        break;
    }
  }

  subscribeToDefaultModuleId() {
    return this.store.select(state => state.projectSetting?.data).subscribe((projectSettings: ProjectSetting[]) => {
      if (projectSettings?.length) {
        this.defaultModuleId = projectSettings?.find(setting => setting.key === 'defaultModuleId')?.value;
      }
    }
    );
  }

  setDefaultModulePages(module: ModuleWithRelations) {
    this.selectDefaultModuleOptions = module.children.map(page => ({ key: page.name, value: page.id }));
  }

  onChangeDefaultModuleAndSetHomePage(pageId: string) {
    if (!pageId) {
      this.defaultModuleModalOkDisabled = true;
    } else {
      this.selectedPageId = pageId;
      this.defaultModuleModalOkDisabled = false;
    }
  }

  onChangeDefaultModuleModal() {
    this.store.dispatch(new ChangeDefaultModule({ moduleId: this.selectedModule.id }));
    this.store.dispatch(new SetHomePage({ pageId: this.selectedPageId }));
    this.onCancelModal();
  }

  onCancelModal() {
    this.defaultModuleModalVisibility = false;
    this.selectedPageId = null;
    this.defaultModuleModalOkDisabled = true;
  }

  /**
   * getDropdownMenuItem function is used to filter and return the dropdown menu items based on the moduleId parameter.
   * @param item - The item containing the moduleId to be compared.
   * @returns The filtered dropdown menu items.
   */
  getDropdownMenuItems(item: any) {
    if (item.moduleId !== this.defaultModuleId || item.isHomepage) {
      return this.pageListDropdownMenuConfig.items.filter(menuItem => menuItem.key !== PageListItemDropdownAction.SetAsHomepage);
    }
    return this.pageListDropdownMenuConfig.items;
  }

  /**
   * Handles different actions based on the selected dropdown menu item.
   * @param action - The selected dropdown menu item.
   * @param page - The page object associated with the dropdown menu item.
   */
  onDropdownMenuItemClick(action: { key: string }, page: PageWithRelations): void {
    const { key } = action;

    switch (key) {
      case PageListItemDropdownAction.SetAsHomepage:
        this.store.dispatch(new SetHomePage({ pageId: page.id }));
        break;

      case PageListItemDropdownAction.Edit:
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PAGES.PAGE_EDIT_PATH}/${page.id}` }));
        break;

      case PageListItemDropdownAction.Delete:
        this.store.dispatch(new DeletePage({ pageId: page.id }));
        break;

      case PageListItemDropdownAction.Duplicate:
        if (page.id && page.moduleId) {
          const pageTitle = page.title ? `${page.title}${DUPLICATE_PAGE_TITLE_SUFFIX}` : '-';
          const description = page.description ? `${DUPLICATE_PAGE_DESCRIPTION_PREFIX}${page.description}` : DUPLICATE_PAGE_DESCRIPTION_PREFIX;
          this.store.dispatch(new DuplicatePage({ pageId: page.id, moduleId: page.moduleId, title: pageTitle, description: description }));
        }
        break;

      default:
        break;
    }
  }

  onTabChange(tab: NzTabChangeEvent) {
    if (tab.index === 0) {
      this.displayedModules = this.clientModules;
    } else {
      this.displayedModules = this.adminModules;
    }
    this.onSearchAndCategoriesFilter();
  }

  onClickCreatePage() {
    this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.PAGES.PAGE_CREATE_PATH }));
  }

  onClickPageTitle(itemId: string) {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${itemId}` }));
  }

  onClickModuleTitle(itemId: string) {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.MODULE.MODULE_EDIT_PATH}/${itemId}` }));
  }

  onSearchAndCategoriesFilter() {
    if (this.searchWord && this.selectedCategoryNames.length > 0) {
      // both search and category exist
      const options = {
        keys: ['title'],
        threshold: 0.1
      };
      /* keeping module's children (Client or Admin) */
      const pages = this.selectedTabIndex === 0
        ? this.clientModules.map(data => data.children).flat()
        : this.adminModules.map(data => data.children).flat();

      /* Search the module's children.*/
      const results = this.searchService.searchByOptions(pages, options, this.searchWord);

      /* Mapping the data returned from the search service*/
      this.mappedResults = results.map(result => result.item);

      /* filter existing pages in module's children (Client or Admin) */
      const tempModules = this.selectedTabIndex === 0 ? this.clientModules
        /* Find the parent module has the child module id */
        .filter(module => this.mappedResults.some(mappedResult => mappedResult.moduleId === module.id))
        /* rebuild child module based on the filtered child module result*/
        .map(module => ({
          ...module,
          children: module.children.filter(child => this.mappedResults.some(result => result.id === child.id))
        })) : this.adminModules
          /* Find the parent module has the child module id */
          .filter(module => this.mappedResults.some(mappedResult => mappedResult.moduleId === module.id))
          /* rebuild child module based on the filtered child module result*/
          .map(module => ({
            ...module,
            children: module.children.filter(child => this.mappedResults.some(result => result.id === child.id))
          }));

      this.displayedModules = tempModules.filter(module =>
        module.children?.some(page =>
          this.selectedCategoryNames.some(categoryId => page.categories?.some(category => category.id === categoryId))
        )
      ).map(module => ({
        ...module,
        children: module.children?.filter(page =>
          this.selectedCategoryNames.some(categoryId => page.categories?.some(category => category.id === categoryId))
        )
      }));
    } else if (this.searchWord && this.selectedCategoryNames.length === 0) {
      // just search exist
      const options = {
        keys: ['title'],
        threshold: 0.1
      };
      /* keeping module's children (Client or Admin) */
      const pages = this.selectedTabIndex === 0
        ? this.clientModules.map(data => data.children).flat()
        : this.adminModules.map(data => data.children).flat();

      /* Search the module's children.*/
      const results = this.searchService.searchByOptions(pages, options, this.searchWord);

      /* Mapping the data returned from the search service*/
      this.mappedResults = results.map(result => result.item);

      /* filter existing pages in module's children (Client or Admin) */
      this.displayedModules = this.selectedTabIndex === 0 ? this.clientModules
        /* Find the parent module has the child module id */
        .filter(module => this.mappedResults.some(mappedResult => mappedResult.moduleId === module.id))
        /* rebuild child module based on the filtered child module result*/
        .map(module => ({
          ...module,
          children: module.children.filter(child => this.mappedResults.some(result => result.id === child.id))
        })) : this.adminModules
          /* Find the parent module has the child module id */
          .filter(module => this.mappedResults.some(mappedResult => mappedResult.moduleId === module.id))
          /* rebuild child module based on the filtered child module result*/
          .map(module => ({
            ...module,
            children: module.children.filter(child => this.mappedResults.some(result => result.id === child.id))
          }));
    } else if (!this.searchWord && this.selectedCategoryNames.length) {
      // just category exist
      this.categoryFilter();
    } else {
      // no search or filter
      this.displayedModules = this.selectedTabIndex === 0 ? this.clientModules : this.adminModules;
    }
    this.renderList();
  }

  renderList() {
    // to render
    this.displayedModules.forEach(item => {
      this.mapOfExpandedPageListData[item.key] = this.pageListService.convertTreeToList(item);
    });
    Object.keys(this.mapOfExpandedPageListData).forEach(key => {
      this.mapOfExpandedPageListData[key] = [this.mapOfExpandedPageListData[key][0], ...orderBy(this.mapOfExpandedPageListData[key].slice(1), 'createdDate', 'desc')];
    });
  }

  onExpandCollapsePages(isExpanded: boolean) {
    for (const [key, value] of Object.entries(this.mapOfExpandedPageListData)) {
      if (value[0]) {
        value[0].expand = isExpanded;
      }
    }
  }

  pageStatusModalVisibilityChange() {
    this.pageStatusModalVisibility = !this.pageStatusModalVisibility;
  }
}
