<rappider-list-grid [config]="pageVariablesConfig"
                    [data]="gridData"
                    [loading]="loading"
                    (listActionClick)="onListActionClick($event)"
                    (columnActionClick)="onColumnActionClick($event)"></rappider-list-grid>

<!-- Create Modal -->
<rappider-modal *ngIf="optMode === crudType.Create"
                [visible]="createModalVisible"
                [fullScreen]="true"
                title="Add Page Variable"
                (cancelClick)="onAddPageVariableModalClose()"
                (okClick)="onAddPageVariableModalOk()">
  <rappider-crud-view-edit-form [config]="pageVariableCreateAndEditFormConfig"
                                [data]="createPageVariableData"
                                (formValueChange)="onCreateFormValueChange($event)"
                                (selectButtonClick)="onSelectButtonClick($event)"></rappider-crud-view-edit-form>
</rappider-modal>

<!-- Edit Modal -->
<rappider-modal *ngIf="optMode === crudType.Update"
                [visible]="editModalVisible"
                fullScreen="true"
                title="Edit Page Variable"
                (cancelClick)="onEditPageVariableModalClose()"
                (okClick)="onEditPageVariableModalOk()">
  <rappider-crud-view-edit-form [config]="pageVariableCreateAndEditFormConfig"
                                [data]="editedPageVariableData"
                                (formValueChange)="onEditFormValueChange($event)"
                                (selectButtonClick)="onSelectButtonClick($event)"></rappider-crud-view-edit-form>
</rappider-modal>

<!-- Create With AI Modal -->
<rappider-modal
                [visible]="createWithAiModalVisible"
                [fullScreen]="true"
                title="Add Page Variable With AI"
                (cancelClick)="onAddPageVariableWithAIModalClose()"
                (okClick)="onAddPageVariableWithAIModalOk()">
  <rappider-crud-view-edit-form [config]="pageVariableCreateWithAiConfig"
                                (formValueChange)="onCreateWithAiFormValueChange($event)"
                                (selectButtonClick)="onCreateWithAiSelectButtonClick($event)"></rappider-crud-view-edit-form>
</rappider-modal>

<!-- Create Data Selector Modal -->
<ng-container *ngIf="createDataSelectorModalVisibility">
  <rappider-ui-data-selector-select [createDataSelectorModalVisibility]="createDataSelectorModalVisibility"
                                    (createModalVisibilityChange)="onCreateSelectorModalVisibilityChange($event)"></rappider-ui-data-selector-select>
</ng-container>

<!-- Edit Data Selector Modal -->
<ng-container *ngIf="editDataSelectorModalVisibility">
  <rappider-ui-data-selector-select [editDataSelectorModalVisibility]="editDataSelectorModalVisibility"
                                    [editingUiDataSelector]="editedUiDataSelector"
                                    (editModalVisibilityChange)="onEditSelectorModalVisibilityChange($event)"></rappider-ui-data-selector-select>
</ng-container>
