import { ButtonComponentConfig, ButtonSize, IconComponentConfig, IconType } from '@rappider/rappider-components/utils';

export const CreateUIStepFunctionButtonConfig: ButtonComponentConfig = {
  text: 'Create UI Step Function',
  size: ButtonSize.Small,
  icon: <IconComponentConfig>{
    name: 'fa-solid fa-rectangle',
    type: IconType.FontAwesome
  }
};
