/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccessTokenControllerService } from './services/access-token-controller.service';
import { CommentControllerService } from './services/comment-controller.service';
import { ComponentInputDefinitionControllerService } from './services/component-input-definition-controller.service';
import { DataSchemaFieldControllerService } from './services/data-schema-field-controller.service';
import { DataSchemaControllerService } from './services/data-schema-controller.service';
import { PageControllerService } from './services/page-controller.service';
import { PersonControllerService } from './services/person-controller.service';
import { PermissionControllerService } from './services/permission-controller.service';
import { ProjectControllerService } from './services/project-controller.service';
import { RoleMappingControllerService } from './services/role-mapping-controller.service';
import { RoleControllerService } from './services/role-controller.service';
import { SnippetDefinitionControllerService } from './services/snippet-definition-controller.service';
import { UserControllerService } from './services/user-controller.service';
import { AiLogControllerService } from './services/ai-log-controller.service';
import { ApmEpicControllerService } from './services/apm-epic-controller.service';
import { ApmIssueControllerService } from './services/apm-issue-controller.service';
import { ApmLabelControllerService } from './services/apm-label-controller.service';
import { ApmSprintControllerService } from './services/apm-sprint-controller.service';
import { ApplicationBuilderControllerService } from './services/application-builder-controller.service';
import { CacheControllerService } from './services/cache-controller.service';
import { CategoryControllerService } from './services/category-controller.service';
import { ChatGptHistoryControllerService } from './services/chat-gpt-history-controller.service';
import { ChatSessionControllerService } from './services/chat-session-controller.service';
import { CodeGenerationTemplateControllerService } from './services/code-generation-template-controller.service';
import { CodePreviewControllerService } from './services/code-preview-controller.service';
import { ComponentConditionControllerService } from './services/component-condition-controller.service';
import { ComponentDataSubscriptionControllerService } from './services/component-data-subscription-controller.service';
import { ComponentDefinitionStyleSheetControllerService } from './services/component-definition-style-sheet-controller.service';
import { ComponentDefinitionSubCategoryControllerService } from './services/component-definition-sub-category-controller.service';
import { ComponentDefinitionControllerService } from './services/component-definition-controller.service';
import { ComponentOutputDefinitionControllerService } from './services/component-output-definition-controller.service';
import { ComponentOutputEventControllerService } from './services/component-output-event-controller.service';
import { ComponentSampleControllerService } from './services/component-sample-controller.service';
import { ComponentControllerService } from './services/component-controller.service';
import { ContainerTemplateControllerService } from './services/container-template-controller.service';
import { CustomCodeDefinitionControllerService } from './services/custom-code-definition-controller.service';
import { CustomCodeControllerService } from './services/custom-code-controller.service';
import { CustomFunctionDefinitionParameterControllerService } from './services/custom-function-definition-parameter-controller.service';
import { CustomFunctionDefinitionControllerService } from './services/custom-function-definition-controller.service';
import { DataMappingControllerService } from './services/data-mapping-controller.service';
import { DataSchemaEnumDataControllerService } from './services/data-schema-enum-data-controller.service';
import { DataSchemaTreeFieldNodeControllerService } from './services/data-schema-tree-field-node-controller.service';
import { DataSchemaTreeFieldControllerService } from './services/data-schema-tree-field-controller.service';
import { DataSourceControllerService } from './services/data-source-controller.service';
import { DataTransformationItemControllerService } from './services/data-transformation-item-controller.service';
import { DataTransformationControllerService } from './services/data-transformation-controller.service';
import { DbDiagramNodeControllerService } from './services/db-diagram-node-controller.service';
import { DbDiagramNodeProjectControllerService } from './services/db-diagram-node-project-controller.service';
import { DbDiagramNodeProjectModelControllerService } from './services/db-diagram-node-project-model-controller.service';
import { DependentComponentDefinitionControllerService } from './services/dependent-component-definition-controller.service';
import { DeveloperUtilityControllerService } from './services/developer-utility-controller.service';
import { DevopsManagementControllerService } from './services/devops-management-controller.service';
import { DiagramCommentControllerService } from './services/diagram-comment-controller.service';
import { EnumDataControllerService } from './services/enum-data-controller.service';
import { EnvironmentVariableControllerService } from './services/environment-variable-controller.service';
import { GitInfoControllerService } from './services/git-info-controller.service';
import { HttpRequestControllerService } from './services/http-request-controller.service';
import { JiraControllerService } from './services/jira-controller.service';
import { JsonSchemaControllerService } from './services/json-schema-controller.service';
import { KeyValueControllerService } from './services/key-value-controller.service';
import { LibraryFunctionControllerService } from './services/library-function-controller.service';
import { LogControllerService } from './services/log-controller.service';
import { MessageTemplateDataFieldControllerService } from './services/message-template-data-field-controller.service';
import { MessageTemplateControllerService } from './services/message-template-controller.service';
import { MetadataControllerService } from './services/metadata-controller.service';
import { ModuleControllerService } from './services/module-controller.service';
import { OpenApiEndpointControllerService } from './services/open-api-endpoint-controller.service';
import { OpenApiControllerService } from './services/open-api-controller.service';
import { PageCommentControllerService } from './services/page-comment-controller.service';
import { PageComponentControllerService } from './services/page-component-controller.service';
import { PageVariableControllerService } from './services/page-variable-controller.service';
import { PaymentControllerService } from './services/payment-controller.service';
import { PersonInvitationControllerService } from './services/person-invitation-controller.service';
import { PersonNotificationControllerService } from './services/person-notification-controller.service';
import { PersonProjectRoleControllerService } from './services/person-project-role-controller.service';
import { PersonProjectControllerService } from './services/person-project-controller.service';
import { PhoneNumberControllerService } from './services/phone-number-controller.service';
import { ProcessInfoControllerService } from './services/process-info-controller.service';
import { ProjectApiKeyControllerService } from './services/project-api-key-controller.service';
import { ProjectBuildControllerService } from './services/project-build-controller.service';
import { ProjectDocumentControllerService } from './services/project-document-controller.service';
import { ProjectExportControllerService } from './services/project-export-controller.service';
import { ProjectExternalScriptControllerService } from './services/project-external-script-controller.service';
import { ProjectFileControllerService } from './services/project-file-controller.service';
import { ProjectFolderControllerService } from './services/project-folder-controller.service';
import { ProjectModelEndpointExcludeFieldControllerService } from './services/project-model-endpoint-exclude-field-controller.service';
import { ProjectModelEndpointParamControllerService } from './services/project-model-endpoint-param-controller.service';
import { ProjectModelEndpointQueryParamControllerService } from './services/project-model-endpoint-query-param-controller.service';
import { ProjectModelEndpointControllerService } from './services/project-model-endpoint-controller.service';
import { ProjectModelFieldControllerService } from './services/project-model-field-controller.service';
import { ProjectModelRelationControllerService } from './services/project-model-relation-controller.service';
import { ProjectModelControllerService } from './services/project-model-controller.service';
import { ProjectPackageControllerService } from './services/project-package-controller.service';
import { ProjectRequirementControllerService } from './services/project-requirement-controller.service';
import { ProjectRoleAllowedPageControllerService } from './services/project-role-allowed-page-controller.service';
import { ProjectRoleRestrictedPageControllerService } from './services/project-role-restricted-page-controller.service';
import { ProjectRoleControllerService } from './services/project-role-controller.service';
import { ProjectSettingControllerService } from './services/project-setting-controller.service';
import { ProjectThemeControllerService } from './services/project-theme-controller.service';
import { ProjectVersionControllerService } from './services/project-version-controller.service';
import { RappiderFunctionControllerService } from './services/rappider-function-controller.service';
import { ScopeOfWorkControllerService } from './services/scope-of-work-controller.service';
import { SettingsControllerService } from './services/settings-controller.service';
import { SourceCodeControllerService } from './services/source-code-controller.service';
import { SourceCodeFileMetadataControllerService } from './services/source-code-file-metadata-controller.service';
import { TemplatePageControllerService } from './services/template-page-controller.service';
import { TenantControllerService } from './services/tenant-controller.service';
import { TransactionControllerService } from './services/transaction-controller.service';
import { UiDataEventControllerService } from './services/ui-data-event-controller.service';
import { UiDataSelectorControllerService } from './services/ui-data-selector-controller.service';
import { UiDataStoreFieldSelectorControllerService } from './services/ui-data-store-field-selector-controller.service';
import { UiDataStoreControllerService } from './services/ui-data-store-controller.service';
import { UiDataUpdateFunctionControllerService } from './services/ui-data-update-function-controller.service';
import { UiWorkflowStepFunctionPublishedEventOnFailureControllerService } from './services/ui-workflow-step-function-published-event-on-failure-controller.service';
import { UiWorkflowStepFunctionPublishedEventOnSuccessControllerService } from './services/ui-workflow-step-function-published-event-on-success-controller.service';
import { UiWorkflowStepFunctionSubscribedEventControllerService } from './services/ui-workflow-step-function-subscribed-event-controller.service';
import { UiWorkflowStepFunctionUiDataStoreSubscriptionControllerService } from './services/ui-workflow-step-function-ui-data-store-subscription-controller.service';
import { UiWorkflowStepFunctionControllerService } from './services/ui-workflow-step-function-controller.service';
import { VerificationTokenControllerService } from './services/verification-token-controller.service';
import { WorkflowConditionPublishedWorkflowItemControllerService } from './services/workflow-condition-published-workflow-item-controller.service';
import { WorkflowConditionControllerService } from './services/workflow-condition-controller.service';
import { WorkflowConditionProjectControllerService } from './services/workflow-condition-project-controller.service';
import { WorkflowEventControllerService } from './services/workflow-event-controller.service';
import { WorkflowManagementControllerService } from './services/workflow-management-controller.service';
import { WorkflowStepFunctionPublishedEventOnFailureControllerService } from './services/workflow-step-function-published-event-on-failure-controller.service';
import { WorkflowStepFunctionPublishedEventOnSuccessControllerService } from './services/workflow-step-function-published-event-on-success-controller.service';
import { WorkflowStepFunctionSubscribedEventControllerService } from './services/workflow-step-function-subscribed-event-controller.service';
import { WorkflowStepFunctionControllerService } from './services/workflow-step-function-controller.service';
import { WorkflowControllerService } from './services/workflow-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccessTokenControllerService,
    CommentControllerService,
    ComponentInputDefinitionControllerService,
    DataSchemaFieldControllerService,
    DataSchemaControllerService,
    PageControllerService,
    PersonControllerService,
    PermissionControllerService,
    ProjectControllerService,
    RoleMappingControllerService,
    RoleControllerService,
    SnippetDefinitionControllerService,
    UserControllerService,
    AiLogControllerService,
    ApmEpicControllerService,
    ApmIssueControllerService,
    ApmLabelControllerService,
    ApmSprintControllerService,
    ApplicationBuilderControllerService,
    CacheControllerService,
    CategoryControllerService,
    ChatGptHistoryControllerService,
    ChatSessionControllerService,
    CodeGenerationTemplateControllerService,
    CodePreviewControllerService,
    ComponentConditionControllerService,
    ComponentDataSubscriptionControllerService,
    ComponentDefinitionStyleSheetControllerService,
    ComponentDefinitionSubCategoryControllerService,
    ComponentDefinitionControllerService,
    ComponentOutputDefinitionControllerService,
    ComponentOutputEventControllerService,
    ComponentSampleControllerService,
    ComponentControllerService,
    ContainerTemplateControllerService,
    CustomCodeDefinitionControllerService,
    CustomCodeControllerService,
    CustomFunctionDefinitionParameterControllerService,
    CustomFunctionDefinitionControllerService,
    DataMappingControllerService,
    DataSchemaEnumDataControllerService,
    DataSchemaTreeFieldNodeControllerService,
    DataSchemaTreeFieldControllerService,
    DataSourceControllerService,
    DataTransformationItemControllerService,
    DataTransformationControllerService,
    DbDiagramNodeControllerService,
    DbDiagramNodeProjectControllerService,
    DbDiagramNodeProjectModelControllerService,
    DependentComponentDefinitionControllerService,
    DeveloperUtilityControllerService,
    DevopsManagementControllerService,
    DiagramCommentControllerService,
    EnumDataControllerService,
    EnvironmentVariableControllerService,
    GitInfoControllerService,
    HttpRequestControllerService,
    JiraControllerService,
    JsonSchemaControllerService,
    KeyValueControllerService,
    LibraryFunctionControllerService,
    LogControllerService,
    MessageTemplateDataFieldControllerService,
    MessageTemplateControllerService,
    MetadataControllerService,
    ModuleControllerService,
    OpenApiEndpointControllerService,
    OpenApiControllerService,
    PageCommentControllerService,
    PageComponentControllerService,
    PageVariableControllerService,
    PaymentControllerService,
    PersonInvitationControllerService,
    PersonNotificationControllerService,
    PersonProjectRoleControllerService,
    PersonProjectControllerService,
    PhoneNumberControllerService,
    ProcessInfoControllerService,
    ProjectApiKeyControllerService,
    ProjectBuildControllerService,
    ProjectDocumentControllerService,
    ProjectExportControllerService,
    ProjectExternalScriptControllerService,
    ProjectFileControllerService,
    ProjectFolderControllerService,
    ProjectModelEndpointExcludeFieldControllerService,
    ProjectModelEndpointParamControllerService,
    ProjectModelEndpointQueryParamControllerService,
    ProjectModelEndpointControllerService,
    ProjectModelFieldControllerService,
    ProjectModelRelationControllerService,
    ProjectModelControllerService,
    ProjectPackageControllerService,
    ProjectRequirementControllerService,
    ProjectRoleAllowedPageControllerService,
    ProjectRoleRestrictedPageControllerService,
    ProjectRoleControllerService,
    ProjectSettingControllerService,
    ProjectThemeControllerService,
    ProjectVersionControllerService,
    RappiderFunctionControllerService,
    ScopeOfWorkControllerService,
    SettingsControllerService,
    SourceCodeControllerService,
    SourceCodeFileMetadataControllerService,
    TemplatePageControllerService,
    TenantControllerService,
    TransactionControllerService,
    UiDataEventControllerService,
    UiDataSelectorControllerService,
    UiDataStoreFieldSelectorControllerService,
    UiDataStoreControllerService,
    UiDataUpdateFunctionControllerService,
    UiWorkflowStepFunctionPublishedEventOnFailureControllerService,
    UiWorkflowStepFunctionPublishedEventOnSuccessControllerService,
    UiWorkflowStepFunctionSubscribedEventControllerService,
    UiWorkflowStepFunctionUiDataStoreSubscriptionControllerService,
    UiWorkflowStepFunctionControllerService,
    VerificationTokenControllerService,
    WorkflowConditionPublishedWorkflowItemControllerService,
    WorkflowConditionControllerService,
    WorkflowConditionProjectControllerService,
    WorkflowEventControllerService,
    WorkflowManagementControllerService,
    WorkflowStepFunctionPublishedEventOnFailureControllerService,
    WorkflowStepFunctionPublishedEventOnSuccessControllerService,
    WorkflowStepFunctionSubscribedEventControllerService,
    WorkflowStepFunctionControllerService,
    WorkflowControllerService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
