import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CrudViewColumnType } from '@rappider/rappider-components/utils';
import { ProjectModelRelation } from '@rappider/rappider-sdk';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';


export interface TransformedRelationData {
  name: string;
  sourceModelName: string;
  type: string;
  targetModelName: string;
  throughModelName: string;
}



@Component({
  selector: 'rappider-data-model-relationships',
  templateUrl: './data-model-relationships.component.html',
  styleUrls: ['./data-model-relationships.component.scss']
})
export class DataModelRelationshipsComponent implements OnInit, OnChanges {

  @Input() projectModelRelations: ProjectModelRelation[] | null | undefined = null;
  @Input() animateTable = false;

  tableConfig = {
    showHeaders: true,
    defaultSearchField: 'name',
    items: [
      {
        title: 'Name',
        fieldName: 'name',
        type: CrudViewColumnType.Link,
        routerLink: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MODEL_RELATION_EDIT}/{{id}}`
      },
      {
        title: 'Source Model',
        fieldName: 'sourceModelName',
        type: CrudViewColumnType.Text
      },
      {
        title: 'Type',
        fieldName: 'type',
        type: CrudViewColumnType.Text
      },

      {
        title: 'Target Model',
        fieldName: 'targetModelName',
        type: CrudViewColumnType.Text
      },
      {
        title: 'Through Model',
        fieldName: 'throughModelName',
        type: CrudViewColumnType.Text
      }
    ]
  };

  transformedData: TransformedRelationData[] | undefined = [];

  ngOnInit(): void {
    this.transformData();
  }

  ngOnChanges(): void {
    this.transformData();
  }

  transformData() {
    this.transformedData = this.projectModelRelations?.map((projectModelRelation: ProjectModelRelation) => <TransformedRelationData>{
      name: projectModelRelation.name,
      sourceModelName: projectModelRelation.sourceModel?.name,
      type: projectModelRelation.type,
      targetModelName: projectModelRelation.targetModel?.name,
      throughModelName: projectModelRelation.throughModel?.name ? `through (${projectModelRelation.throughModel?.name})` : '-'
    });
  }

}
