import { ItemPerRow, SelectMode } from '@rappider/rappider-components/utils';
import { BlankTemplate } from '../../project-list/utils/blank-template';
import { CustomProjectWithAI } from '../../project-list/utils/create-with-ai-template';

export const publicProjectsCardsConfig = {
  itemCountPerRow: ItemPerRow.Four,
  showTitleOnImage: false,
  showDescriptionOnImage: false,
  showCheckMarkOnCard: true,
  paginationConfig: {
    pageIndex: 1,
    total: 3,
    pageSize: 8,
    showPagination: true
  },
  selectConfig: {
    options: [],
    settings: {
      mode: SelectMode.Multiple,
      maxTagCount: 1
    },
    placeholder: 'Filter Tags'
  },
  items: [],
  isDynamicPagination: true,
  publicProjectsIsLoading: true
};

export const publicProjectsTopSectionCardsConfig = {
  itemCountPerRow: ItemPerRow.Two,
  showTitleOnImage: false,
  showDescriptionOnImage: false,
  showCheckMarkOnCard: true,
  showPopover: true,
  paginationConfig: {
    pageIndex: 1,
    total: 3,
    pageSize: 8,
    showPagination: true
  },
  selectConfig: {
    options: [],
    settings: {
      mode: SelectMode.Multiple,
      maxTagCount: 1
    },
    placeholder: 'Filter Tags'
  },
  items: [{
    'data': CustomProjectWithAI,
    'titles': [
      {
        'type': 'h1',
        'content': '⭐'
      },
      {
        'type': 'h4',
        'content': 'Build Enterprise Web CRUD Application with AI',
        'colorSettings': {
          'color': 'var(--primary-color)',
          'whiteSpace': 'pre-wrap'
        }
      }
    ],
    'showPopover': true,
    'descriptions': [
      {
        'content': 'This template builds an enterprise grade full-stack backend and UI using simple description prompts',
        'typography': {
          'fontSize': 'var(--subtext-font-size)',
          'whiteSpace': 'pre-wrap'
        }
      }
    ]
  },
  {
    'data': BlankTemplate,
    'titles': [
      {
        'type': 'h1',
        'content': '📄'
      },
      {
        'type': 'h4',
        'content': 'Backend API with AI',
        'colorSettings': {
          'whiteSpace': 'pre-wrap'
        }
      }
    ],
    'showPopover': true,
    'descriptions': [
      {
        'content': 'Generate a backend API with AI capabilities.',
        'typography': {
          'fontSize': 'var(--subtext-font-size)',
          'whiteSpace': 'pre-wrap'
        }
      }
    ],
    cardTooltipContent: 'This template is disabled for the free tier.',
    disabledCard: true
  },
  {
    'data': BlankTemplate,
    'titles': [
      {
        'type': 'h1',
        'content': '📱'
      },
      {
        'type': 'h4',
        'content': 'Mobile Application with Backend from the prompt',
        'colorSettings': {
          'whiteSpace': 'pre-wrap'
        }
      }
    ],
    'showPopover': true,
    'descriptions': [
      {
        'content': 'Generate an iOS or Android mobile application with a backend API from the prompt.',
        'typography': {
          'fontSize': 'var(--subtext-font-size)',
          'whiteSpace': 'pre-wrap'
        }
      }
    ],
    cardTooltipContent: 'This template is disabled for the free tier.',
    disabledCard: true
  },
  {
    'data': BlankTemplate,
    'titles': [
      {
        'type': 'h1',
        'content': '📄'
      },
      {
        'type': 'h4',
        'content': 'Blank Project',
        'colorSettings': {
          'whiteSpace': 'pre-wrap'
        }
      }
    ],
    'showPopover': true,
    'descriptions': [
      {
        'content': 'Begin with a clean foundation and shape your vision. You can add features and integrations as you go.',
        'typography': {
          'fontSize': 'var(--subtext-font-size)',
          'whiteSpace': 'pre-wrap'
        }
      }
    ],
    cardTooltipContent: 'This template is disabled for the free tier.',
    disabledCard: true
  }
  ],
  isDynamicPagination: false,
  publicProjectsIsLoading: true
};

