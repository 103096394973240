import { CheckboxType, IconType } from '@rappider/rappider-components/utils';

export const alignItemsHorizantal = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-align-items-start-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-align-items-center-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-align-items-end-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'stretch',
      tooltip: 'Stretch',
      icon: {
        name: 'fak fa-align-items-stretch-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'baseline',
      tooltip: 'Baseline',
      icon: {
        name: 'fak fa-align-items-baseline-row',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const alignItemsHorizantalReverse = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-align-items-start-row-reverse',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-align-items-center-row-reverse',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-align-items-end-row-reverse',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'stretch',
      tooltip: 'Stretch',
      icon: {
        name: 'fak fa-align-items-stretch-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'baseline',
      tooltip: 'Baseline',
      icon: {
        name: 'fak fa-align-items-baseline-row-reverse',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const alignItemsVertical = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-align-items-start-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-align-items-center-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-align-items-end-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'stretch',
      tooltip: 'Stretch',
      icon: {
        name: 'fak fa-align-items-stretch-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'baseline',
      tooltip: 'Baseline',
      icon: {
        name: 'fak fa-align-items-baseline-column',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const alignItemsVerticalReverse = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-align-items-start-column-reverse',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-align-items-center-column-reverse',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-align-items-end-column-reverse',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'stretch',
      tooltip: 'Stretch',
      icon: {
        name: 'fak fa-align-items-stretch-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'baseline',
      tooltip: 'Baseline',
      icon: {
        name: 'fak fa-align-items-baseline-column-reverse',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const justifyContentHorizantal = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-justify-content-start-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-justify-content-center-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-justify-content-end-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-between',
      tooltip: 'Space Between',
      icon: {
        name: 'fak fa-justify-content-space-between-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-around',
      tooltip: 'Space Around',
      icon: {
        name: 'fak fa-justify-content-space-around-row',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const justifyContentHorizantalReverse = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-justify-content-end-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-justify-content-center-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-justify-content-start-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-between',
      tooltip: 'Space Between',
      icon: {
        name: 'fak fa-justify-content-space-between-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-around',
      tooltip: 'Space Around',
      icon: {
        name: 'fak fa-justify-content-space-around-row',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const justifyContentVertical = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-justify-content-start-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-justify-content-center-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-justify-content-end-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-between',
      tooltip: 'Space Between',
      icon: {
        name: 'fak fa-justify-content-space-between-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-around',
      tooltip: 'Space Around',
      icon: {
        name: 'fak fa-justify-content-space-around-column',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const justifyContentVerticalReverse = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-justify-content-end-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-justify-content-center-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-justify-content-start-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-between',
      tooltip: 'Space Between',
      icon: {
        name: 'fak fa-justify-content-space-between-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-around',
      tooltip: 'Space Around',
      icon: {
        name: 'fak fa-justify-content-space-around-column',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const alignContentHorizontal = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-justify-content-start-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-justify-content-center-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-justify-content-end-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'stretch',
      tooltip: 'Stretch',
      icon: {
        name: 'fak fa-align-items-stretch-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-between',
      tooltip: 'Space Between',
      icon: {
        name: 'fak fa-justify-content-space-between-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-around',
      tooltip: 'Space Around',
      icon: {
        name: 'fak fa-justify-content-space-around-column',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const alignContentHorizontalReverse = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-justify-content-end-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-justify-content-center-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-justify-content-start-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'stretch',
      tooltip: 'Stretch',
      icon: {
        name: 'fak fa-align-items-stretch-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-between',
      tooltip: 'Space Between',
      icon: {
        name: 'fak fa-justify-content-space-between-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-around',
      tooltip: 'Space Around',
      icon: {
        name: 'fak fa-justify-content-space-around-column',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const alignContentVertical = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-justify-content-start-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-justify-content-center-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-justify-content-end-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'stretch',
      tooltip: 'Stretch',
      icon: {
        name: 'fak fa-align-items-stretch-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-between',
      tooltip: 'Space Between',
      icon: {
        name: 'fak fa-justify-content-space-between-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-around',
      tooltip: 'Space Around',
      icon: {
        name: 'fak fa-justify-content-space-around-row',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const alignContentVerticalReverse = {
  options: [
    {
      value: 'flex-start',
      tooltip: 'Start',
      icon: {
        name: 'fak fa-justify-content-end-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'center',
      tooltip: 'Center',
      icon: {
        name: 'fak fa-justify-content-center-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'flex-end',
      tooltip: 'End',
      icon: {
        name: 'fak fa-justify-content-start-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'stretch',
      tooltip: 'Stretch',
      icon: {
        name: 'fak fa-align-items-stretch-column',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-between',
      tooltip: 'Space Between',
      icon: {
        name: 'fak fa-justify-content-space-between-row',
        type: IconType.FontAwesome
      }
    },
    {
      value: 'space-around',
      tooltip: 'Space Around',
      icon: {
        name: 'fak fa-justify-content-space-around-row',
        type: IconType.FontAwesome
      }
    }
  ]
};

export const containerSettingsConfig = {
  containerWidth: {
    maxRange: 12,
    minRange: 1,
    marks: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12'
    }
  },
  displayMode: {
    options: [
      {
        key: 'Flex',
        value: 'flex'
      },
      {
        key: 'Block',
        value: 'block'
      }
    ]
  },
  direction: {
    options: [
      {
        value: 'row',
        label: 'Horizantal'
      },
      {
        value: 'column',
        label: 'Vertical'
      }
    ]
  },
  reverseCheckbox: {
    icon: {
      name: 'fal fa-exchange',
      type: IconType.FontAwesome
    },
    checkboxType: CheckboxType.Button,
    tooltip: 'Reverse'
  },
  wrap: {
    options: [
      {
        value: 'wrap',
        label: 'Wrap'
      },
      {
        value: 'nowrap',
        label: 'No Wrap'
      }
    ]
  },
  alignItems: alignItemsHorizantal,
  justifyContent: justifyContentHorizantal,
  alignContent: alignContentHorizontal
};

export const containerType = {
  options: [
    {
      key: 'Container',
      value: 'container'
    },
    {
      key: 'List Container',
      value: 'list-container'
    }
  ]
};
