<div>
  <rappider-title-toolbar [mainTitle]="mainTitle"
                          [options]="title"
                          [displayToolbar]="displayToolbar"
                          [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>
</div>

<rappider-crud-detail [data]="workflowStepFunctionData"></rappider-crud-detail>

<section>
  <rappider-list-grid [config]="WORKFLOW_STEP_FUNCTION_DATA_TRANSFORMATIONS_CONFIG"></rappider-list-grid>
</section>
