import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import {
  CrudViewFormItemType,
  CrudFormConfig,
  FormLayout,
  CrudFormConfigInputChangeReaction,
  CrudFormItemMenuItem,
  ButtonSize,
  CrudFormValueEmitMode,
  InputGroupComponentConfig,
  IconType,
  ButtonComponentConfig,
} from '@rappider/rappider-components/utils';
import { Store } from '@ngrx/store';
import { UpdateComponent, UpdateComponentInputs } from '../../state/content-editor.actions';
import { AssetBrowserService, ContentEditorService, StringUtilityService } from '@rappider/services';
import { CategorizedData } from '@rappider/models';
import { Subscription } from 'rxjs';
import { cloneDeep, isEqual, orderBy } from 'lodash';
import {
  CommentWithRelations,
  ComponentDataSubscriptionWithRelations,
  ComponentPartial, ComponentWithRelations,
  NewPageComment,
  Page,
  PageCommentWithRelations,
  PersonWithRelations,
} from '@rappider/rappider-sdk';
import { TreeService } from 'libs/shared/src/lib/services/tree-service/tree.service';
import { CreatePageComment, DeletePageComment, UpdatePageComment } from 'libs/comment/src/lib/state/page-comment/page-comment.actions';
import { NzFormatEmitEvent, NzTreeNode } from 'ng-zorro-antd/tree';
import { environment } from '@environment';
import { ContentTree, ContentTreeComponent } from 'libs/content-tree-renderer/src/lib/models';
import { CommentWrapperComponent } from 'libs/comment/src/lib/components/comment-wrapper/comment-wrapper.component';
import { ContentEditorSettingsSection } from '../../constants/enums';

@Component({
  selector: 'rappider-content-editor-component-settings',
  templateUrl: './component-settings.component.html',
  styleUrls: ['./component-settings.component.scss']
})
export class ComponentSettingsComponent implements OnInit, OnChanges {
  @ViewChild('commentWrapperComponentRef') commentWrapperComponent: CommentWrapperComponent;

  @Input() activeContentTreeItem: ContentTreeComponent;
  @Input() contentTree: ContentTree;
  /* loading status */
  @Input() isLoading: boolean;
  /* active page */
  @Input() page: Page;
  @Input() comments: CommentWithRelations[];
  @Input() activePerson: PersonWithRelations;
  @Input() projectPeopleData: PersonWithRelations[];
  @Input() pageComments: PageCommentWithRelations[];
  @Input() commentsLoading: boolean;
  @Input() visiblePanelKeys?: string[] = [];
  @Input() allPanelsActive?: boolean = false;
  @Input() activeCommentId: string;

  /* component input definition item's menu click action */
  @Output() labelMenuItemClick = new EventEmitter<CrudFormItemMenuItem>();
  /* component data subscription delete action */
  @Output() componentDataSubscriptionDeleteClick = new EventEmitter<ComponentDataSubscriptionWithRelations>();
  /* comment header see all comments button action */
  @Output() seeAllCommentsClick = new EventEmitter();

  INPUT_DEFINITION_SECTION_CONFIGS: CategorizedData[] = [];
  tempConfig: CategorizedData[] = [];

  ContentEditorSettingsSection = ContentEditorSettingsSection;

  commentsFilteredByActiveTreeItemId: CommentWithRelations[] = [];

  DEFAULT_INPUT_DEFINITION_SECTION_CONFIG: CrudFormConfig = {
    formLayout: FormLayout.Vertical,
    items: [],
    itemSettings: {
      inputComponentSize: { xs: 24 },
      labelComponentSize: { xs: 24 },
    },
    inputChangeReaction: CrudFormConfigInputChangeReaction.Blur,
    submitButton: {
      visible: false
    },
    formValueEmitMode: CrudFormValueEmitMode.ReturnAllValues
  };

  componentCustomClassesFormConfig: CrudFormConfig = {
    ...this.DEFAULT_INPUT_DEFINITION_SECTION_CONFIG,
    items: [
      {
        fieldName: 'cssClasses',
        type: CrudViewFormItemType.TagInput
      }
    ]
  };

  DATA_SUBSCRIPTION_SECTION_CONFIG = {
    editButton: {
      icon: {
        name: 'far fa-edit'
      },
      size: ButtonSize.Small
    },
    deleteButton: {
      icon: {
        name: 'far fa-trash'
      },
      size: ButtonSize.Small
    }
  };

  componentSettingsEditTitleButtonConfig: ButtonComponentConfig = {
    icon: {
      name: 'fa-regular fa-pen-to-square',
      type: IconType.FontAwesome
    },
    tooltipText: 'Edit Component Title'
  };

  subscriptions: Subscription[];

  componentTitleEditMode = false;
  editedComponentTitle: string;

  existingInputsByParentComponent: Record<string, any>;

  /* asset picker variables */
  subfoldersFetchedFolderIds: string[] = [];
  folderTree;
  selectedTabIndex = 0;

  inputDefinitionSearchValue: string;
  inputDefinitionSearchInputGroup: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'Search fields'
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome
    }
  };
  filteredInputDefinitionSectionConfigsBySearchValue = this.DEFAULT_INPUT_DEFINITION_SECTION_CONFIG;

  constructor(
    private store: Store<any>,
    private contentEditorService: ContentEditorService,
    private treeService: TreeService,
    private assetBrowserService: AssetBrowserService
  ) { }

  ngOnInit(): void {
    /* get root folder for asset picker */
    this.getRootFolder();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( /* component input */
      changes.activeContentTreeItem &&
      (!changes.activeContentTreeItem?.previousValue && changes.activeContentTreeItem?.currentValue) ||
      changes.activeContentTreeItem?.currentValue?.id !== changes.activeContentTreeItem?.previousValue?.id
    ) {
      this.setComponentInputDefinitionConfig();
      this.orderComponentDataSubscriptionsByComponent(this.activeContentTreeItem?.component);
      this.buildInputsByParentComponent();
      this.setAssetPickerItemConfig();
    } else if (changes.activeContentTreeItem &&
      !isEqual(changes.activeContentTreeItem?.currentValue?.component?.dataSubscriptions,
        changes.activeContentTreeItem?.previousValue?.component?.dataSubscriptions)
    ) {
      this.setComponentInputDefinitionConfig();
    }

    /* filter comments when content tree active items changes */
    if (changes.activeContentTreeItem) {
      if (this.comments && this.activeContentTreeItem?.id) {
        this.commentsFilteredByActiveTreeItemId = this.pageComments?.filter(comment =>
          comment.contentTreeItemId === this.activeContentTreeItem.id)?.map(pageComment =>
            <CommentWithRelations>pageComment.comment) || [];
      } else {
        this.commentsFilteredByActiveTreeItemId = [];
      }
    }
  }

  /* build inputs by parent component if active component belongs to systemGeneratedComponent */
  buildInputsByParentComponent() {
    if (this.contentTree && this.activeContentTreeItem?.parentPageComponentContentId && this.activeContentTreeItem?.component) {
      const parent = this.treeService.findNodeInTree(this.contentTree[0], this.activeContentTreeItem?.parentPageComponentContentId, 'id', ['children'], false);

      const inputFieldName = parent?.component?.componentDefinition?.contentTreeItem?.fieldNameToComponentDefinitionIds?.find(element => element.contentId === this.activeContentTreeItem?.id)?.fieldName;
      this.existingInputsByParentComponent = parent.component.inputs[inputFieldName];
    }
  }

  clearComponentInputDefinitionSectionConfigs() {
    this.INPUT_DEFINITION_SECTION_CONFIGS = [];
  }

  orderComponentDataSubscriptionsByComponent(component: ComponentWithRelations) {
    if (!component.dataSubscriptions?.length) {
      return;
    } else {
      component.dataSubscriptions = orderBy(
        component.dataSubscriptions,
        [
          dataSubscription => dataSubscription.inputDefinition.title.toUpperCase()
        ]
      );
    }
  }

  setComponentInputDefinitionConfig() {
    /* set component input definition item configs */
    if (this.activeContentTreeItem?.component?.componentDefinition?.inputDefinitions?.length) {
      this.tempConfig = this.contentEditorService
        .getCategorizedComponentInputDefinitionItemConfigsByComponent(this.activeContentTreeItem, this.DEFAULT_INPUT_DEFINITION_SECTION_CONFIG);
      this.tempConfig[0].active = true;
    } else {
      /* clear all input definition section configs */
      this.clearComponentInputDefinitionSectionConfigs();
    }
    /* to trigger angular change detection ( onChange ) */
    this.tempConfig = [...this.tempConfig];
    this.updateComponentInputDefinitionConfig();
  }

  updateComponentInputDefinitionConfig() {
    const inputs = this.activeContentTreeItem.component.inputs;
    const regex = /{{\s*[_a-zA-Z][_a-zA-Z0-9]*\s*}}/;

    this.INPUT_DEFINITION_SECTION_CONFIGS = this.tempConfig.map(section => {
      const newSection = JSON.parse(JSON.stringify(section));
      newSection.data.config.items.forEach(item => {
        const inputValue = this.getNestedValue(inputs, item.fieldName);
        if (inputValue && typeof inputValue === 'string' && regex.test(inputValue)) {
          item.type = 'textbox';
        }
      });
      return newSection;
    });

    this.INPUT_DEFINITION_SECTION_CONFIGS = [...this.INPUT_DEFINITION_SECTION_CONFIGS];
  }

  getNestedValue(obj, path) {
    const keys = path.split('.');
    let value = obj;
    for (const key of keys) {
      if (value) {
        if (value[key] !== undefined) {
          value = value[key];
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
    return value;
  }

  /* component input update */
  onComponentFormDataChange(updatedInput: { [key: string]: any }) {
    this.INPUT_DEFINITION_SECTION_CONFIGS.forEach(config => {
      config.data.config.items.forEach(item => {
        if (item.metadata?.hasSubscription) {
          /* remove fields if has data subscription */
          delete updatedInput[item.fieldName];
        }
      });
    });

    this.store.dispatch(new UpdateComponentInputs({ componentId: this.activeContentTreeItem.componentId, inputs: updatedInput, parentPageComponentContentId: this.activeContentTreeItem.parentPageComponentContentId, pageType: this.page.type }));
  }

  setUseVariableButton(labelMenuItem: CrudFormItemMenuItem) {
    const updatedConfigs = this.INPUT_DEFINITION_SECTION_CONFIGS.map(section => {
      const isCurrentSection = StringUtilityService.toTitleCase(section.title) === StringUtilityService.toTitleCase(labelMenuItem.data.inputDefinition.category);

      const updatedItems = section.data.config.items.map(item => {
        if (item.fieldName === labelMenuItem.data.inputDefinition.fieldName) {
          return {
            ...item,
            type: 'textbox',
            menu: {
              ...item.menu,
              additionalItem: {
                tooltipText: 'Remove Variable',
                icon: {
                  name: 'fa-kit fa-solid-code-circle-xmark'
                },
                name: 'remove-variable',
                data: {
                  activeContentTreeItem: this.activeContentTreeItem,
                  inputDefinition: labelMenuItem.data.inputDefinition,
                }
              }
            }
          };
        }
        return item;
      });

      return {
        ...section,
        active: isCurrentSection, // Set active based on whether it's the current section
        data: {
          ...section.data,
          config: {
            ...section.data.config,
            items: updatedItems
          }
        }
      };
    });

    this.INPUT_DEFINITION_SECTION_CONFIGS = updatedConfigs;
  }

  changeToDefaultFormItemType(labelMenuItem: CrudFormItemMenuItem) {
    const updatedConfigs = this.INPUT_DEFINITION_SECTION_CONFIGS.map(section => {
      const isCurrentSection = StringUtilityService.toTitleCase(section.title) === StringUtilityService.toTitleCase(labelMenuItem.data.inputDefinition.category);

      const updatedItems = section.data.config.items.map(item => {
        if (item.fieldName === labelMenuItem.data.inputDefinition.fieldName) {
          return {
            ...item,
            type: labelMenuItem.data.inputDefinition.uiDataSelector || item.type, // Use the uiDataSelector or fall back to the current type if not found
            menu: {
              ...item.menu,
              additionalItem: {
                tooltipText: 'Use Variable',
                icon: {
                  name: 'fa-solid fa-code'
                },
                name: 'use-variable',
                data: {
                  activeContentTreeItem: this.activeContentTreeItem,
                  inputDefinition: labelMenuItem.data.inputDefinition,
                }
              }
            }
          };
        }
        return item;
      });

      return {
        ...section,
        active: isCurrentSection, // Set active based on whether it's the current section
        data: {
          ...section.data,
          config: {
            ...section.data.config,
            items: updatedItems
          }
        }
      };
    });

    this.INPUT_DEFINITION_SECTION_CONFIGS = updatedConfigs;

    // Update activeContentTreeItem.component.inputs
    if (this.activeContentTreeItem.component.componentDefinition.defaultInputs) {
      const updatedInputs = { ...this.activeContentTreeItem.component.inputs };
      const defaultInputs = { ...this.activeContentTreeItem.component.componentDefinition.defaultInputs };
      const fieldName = labelMenuItem.data.inputDefinition.fieldName;

      if (updatedInputs[fieldName] != null && defaultInputs[fieldName] != null) {
        // Retrieve the default value from componentDefinition.defaultInputs
        updatedInputs[fieldName] = defaultInputs[fieldName];
      }

      this.activeContentTreeItem.component.inputs = updatedInputs;
      this.store.dispatch(new UpdateComponentInputs({ componentId: this.activeContentTreeItem.componentId, inputs: updatedInputs, parentPageComponentContentId: this.activeContentTreeItem.parentPageComponentContentId, pageType: this.page.type }));
    }
  }

  /* label menu item click emit */
  onLabelMenuItemClick(labelMenuItem: CrudFormItemMenuItem) {
    if (labelMenuItem.name === 'use-variable') {
      this.setUseVariableButton(labelMenuItem);
    } else if (labelMenuItem.name === 'remove-variable') {
      this.changeToDefaultFormItemType(labelMenuItem);
    } else {
      this.labelMenuItemClick.emit(labelMenuItem);
    }
  }

  /* component data subscription delete emit */
  onComponentDataSubscriptionDelete(componentDataSubscription: ComponentDataSubscriptionWithRelations) {
    this.componentDataSubscriptionDeleteClick.emit(componentDataSubscription);
  }

  onComponentUpdate(updatedComponentFields: ComponentPartial) {
    this.store.dispatch(new UpdateComponent({
      component: {
        id: this.activeContentTreeItem?.component.id,
        ...updatedComponentFields
      }
    }));
  }

  onEditComponentTitleButtonClick() {
    this.componentTitleEditMode = true;
    this.editedComponentTitle = this.activeContentTreeItem?.component.title;
  }

  onCancelEditComponentTitle() {
    this.componentTitleEditMode = false;
    this.editedComponentTitle = this.activeContentTreeItem?.component.title;
  }

  onComponentTitleSave() {
    this.componentTitleEditMode = false;
    this.store.dispatch(new UpdateComponent({
      component: {
        id: this.activeContentTreeItem?.component.id,
        title: this.editedComponentTitle
      }
    }));
  }

  onClickSeeAllComments() {
    this.seeAllCommentsClick.emit();
  }

  onCreateComment(comment: NewPageComment) {
    this.store.dispatch(CreatePageComment({ pageComment: comment }));
  }

  onEditComment(comment: CommentWithRelations) {
    this.store.dispatch(UpdatePageComment({ commentId: comment.id, comment }));
  }

  onDeleteComment(comment: PageCommentWithRelations) {
    const activePageComment = this.pageComments.find(pageComment => pageComment.commentId === comment.id);
    this.store.dispatch(DeletePageComment({ pageCommentId: activePageComment.id, commentId: activePageComment.comment.id }));
  }

  selectedTabIndexChange(index: number) {
    this.selectedTabIndex = index;
  }

  // #region Asset Picker Functions

  onFormItemOutputEvent(event) {
    if (event.eventName === 'folderExpand') {
      this.onFolderExpand(event.eventPayload);
    }
  }

  getRootFolder() {
    return this.store.select(state => state.activeProject?.rootFolder).subscribe(rootFolder => {
      this.folderTree = [this.assetBrowserService.mapProjectFolderDataToNzTreeNodeOptions(rootFolder)];
      this.subfoldersFetchedFolderIds = [this.folderTree[0].key];
      this.setAssetPickerItemConfig();
    });
  }

  setAssetPickerItemConfig() {
    this.INPUT_DEFINITION_SECTION_CONFIGS.forEach(config => {
      config.data.config.items.forEach(item => {
        this.setAssetPickerItemConfigRecursive(item);
      });
    });
    this.INPUT_DEFINITION_SECTION_CONFIGS = cloneDeep(this.INPUT_DEFINITION_SECTION_CONFIGS);
  }

  setAssetPickerItemConfigRecursive(item) {
    if (item.type === 'asset-picker') {
      item['assetPickerConfig'] = {
        ...(item.assetPickerConfig || []),
        folderTree: this.folderTree,
        basePath: environment.apiBaseUrl
      };
    }
    item.items?.forEach(childItem => {
      if (childItem.type === 'asset-picker') {
        childItem['assetPickerConfig'] = {
          ...(childItem.assetPickerConfig || []),
          folderTree: this.folderTree,
          basePath: environment.apiBaseUrl
        };
      }
      if (childItem.items?.length) {
        childItem.items.forEach(element => this.setAssetPickerItemConfigRecursive(element));
      }
    });
  }

  onFolderExpand(event: NzFormatEmitEvent): void {
    const node = event.node;
    this.getFolderFiles(node);
    this.getFolderSubfolders(node);
  }

  getFolderSubfolders(node: NzTreeNode) {
    if (!this.subfoldersFetchedFolderIds.includes(node.key) && node?.isExpanded) {
      node.origin.loading = true;
      this.assetBrowserService.getFolderByIdWithSubFolders(node.key).subscribe((data: any) => {
        const mappedFolders = data.subFolders?.map(subFolder => this.assetBrowserService.mapProjectFolderDataToNzTreeNodeOptions(subFolder));
        if (mappedFolders?.length) {
          node.addChildren(mappedFolders);
        }
        this.subfoldersFetchedFolderIds.push(node.key);
        node.origin.loading = false;
      }, (e) => node.origin.loading = false
      );
    }
  }

  getFolderFiles(node: NzTreeNode) {
    node.origin.loading = true;
    // get project files by folder id and assign project files to selectedFolder
    this.assetBrowserService.getProjectFilesByFolderId(node.key)
      .subscribe(projectFiles => {
        node.origin.files = projectFiles.map(projectFile => ({
          ...projectFile,
          isLeaf: true
        }));
        node.origin.loading = false;
      }, (e) => node.origin.loading = false);
  }

  // #endregion Asset Picker Functions

  onInputDefinitionSearch() {
    if (this.inputDefinitionSearchValue) {
      this.filteredInputDefinitionSectionConfigsBySearchValue.items = this.INPUT_DEFINITION_SECTION_CONFIGS
        .map(sectionConfig => sectionConfig.data.config.items
          .filter(item => item.title.toLowerCase().includes(this.inputDefinitionSearchValue.toLowerCase())))
        .flat();
    }
  }

}
