<div class="row">
  <div class="col-4">
    <h4>data</h4>
    <pre>{{ data | json }}</pre>
  </div>

  <div class="col-4">
    <h4>formConfig</h4>
    <pre>{{ formConfig | json }}</pre>
  </div>

  <div class="col-4">
    <h4>relations</h4>
    <pre>{{ relations | json }}</pre>
  </div>
</div>
