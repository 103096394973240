import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommentWithRelations, PersonWithRelations } from '@rappider/rappider-sdk';
import { cancelContentButtonConfig, commentActionsDropdownConfig } from '../../utils/comment-display-buttons.config';
import { replyButtonConfig } from '../../utils/reply-button.config';
import { StringUtilityService } from '@rappider/services';
import { formatDistanceToNow } from 'date-fns';
import { CommentDisplayReply } from '../../utils/comment-display-reply.interface';
import { KeyValue } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommentAnimationService } from '../../utils/services/comment-animation.service';
import { CommentMentionService } from '../../utils/services/comment-mention.service';

@Component({
  selector: 'rappider-comment-replies',
  templateUrl: './comment-replies.component.html',
  styleUrls: ['./comment-replies.component.scss'],
  animations: [CommentAnimationService.hideShow],
})
export class CommentRepliesComponent {
  /* Inputs */
  @Input() isCommentHovered: boolean;
  @Input() replies: CommentWithRelations[];
  @Input() activePerson: PersonWithRelations;
  @Input() createdById: string;
  @Input() assignedTo: PersonWithRelations;
  @Input() mentionData: KeyValue<string, string>[] = [];
  @Input() isCreateReplyModeActive: boolean;

  /* Outputs */
  @Output() saveReply = new EventEmitter<string>();
  @Output() deleteReply = new EventEmitter<string>();
  @Output() editReply = new EventEmitter<{ replyId: string; parentCommentId: string; content: string }>();
  @Output() cancelReply = new EventEmitter<void>();
  @Output() isCreateReplyModeActiveChange = new EventEmitter<boolean>();
  @Output() editReplyModeChange = new EventEmitter<boolean>();

  commentActionsDropdownConfig = commentActionsDropdownConfig;
  replyButtonConfig = replyButtonConfig;
  cancelButtonConfig = cancelContentButtonConfig;

  replyContent: string;

  /* mapping function for displayed mention values */
  valueWith = (data: KeyValue<string, string>): string => data.value;

  constructor(
    private sanitizer: DomSanitizer,
    private commentMentionService: CommentMentionService
  ) { }

  renderReplyPreview(content: string): SafeHtml {
    if (content) {
      const regex = this.commentMentionService.getRegExp('@');
      const previewValue = content.replace(
        regex,
        match => ` @<a class="mention-text" target="_blank">${this.mentionData.find(d => d.value === match.trim().substring(1))?.key}</a>`
      );
      return this.sanitizer.bypassSecurityTrustHtml(previewValue);
    }
  }

  onReplyMenuItemClick(action, replyId: string) {
    if (action.key === 'edit') {
      const reply = this.replies.find(r => r.id === replyId);
      this.toggleReplyEditMode(reply);
    } else if (action.key === 'delete') {
      this.deleteReply.emit(replyId);
    }
  }

  setCommentCreatedDate(date: string) {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  }

  getCommentAuthorNameInitials(createdBy: string) {
    return StringUtilityService.getInitialCharactersOfString(createdBy);
  }

  toggleReplyEditMode(reply: CommentDisplayReply) {
    reply.isEditModeActive = !reply.isEditModeActive;
    this.editReplyModeChange.emit(reply.isEditModeActive);
  }

  onSaveReply() {
    this.saveReply.emit(this.replyContent);
    this.replyContent = null;
  }

  onEditReply(replyId: string, parentCommentId: string, content: string) {
    this.editReply.emit({ replyId: replyId, parentCommentId: parentCommentId, content: content });
  }

  onCancelReply() {
    this.cancelReply.emit();
    this.replyContent = null;
  }

  replyTrackBy(index, reply) {
    return reply.id;
  }
}
