import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

export enum DBType {
  Local = 'local',
  Remote = 'remote'
}

export enum CRUDKeys {
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted'
}

@Injectable({
  providedIn: 'root'
})
export class IndexDBService {

  /**
   * Local DB is used to store the data locally, this data might be not synced with the remote DB
   * Local DB uses store keys and updated, deleted, created keys to save the local data
   * When we confirm this local data is synced with the remote data, we delete the local data
   * Local data is merged with remote data before saving the data to Rapider ui ngrx store
   *
   * @memberof IndexDBService
   */
  localDBName = 'RapiderLocalDB';


  /**
   * Remote DB is used to store the data from the remote server, as it is
   *
   * @memberof IndexDBService
   */
  remoteDBName = 'RapiderRemoteDB';
  defaultStoreName = 'keyvaluepairs';

  constructor() { }

  public async openDatabase(dbType: DBType, storeName?: string): Promise<IDBDatabase> {
    storeName = storeName || this.defaultStoreName;

    const db = await this.checkAndOpenDatabase(dbType);
    if (!db.objectStoreNames.contains(storeName)) {
      db.close();
      return this.createObjectStore(dbType, storeName);
    }

    return db;
  }

  /**
   * Gets the database name based on the dbType
   *
   * @private
   * @param {DBType} dbType
   * @return {*}  {string}
   * @memberof IndexDBService
   */
  private getDBName(dbType: DBType): string {
    return dbType === DBType.Local ? this.localDBName : this.remoteDBName;
  }

  private checkAndOpenDatabase(dbType: DBType): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const dbName = this.getDBName(dbType);
      const request = indexedDB.open(dbName);

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  private createObjectStore(dbType: DBType, storeName: string): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const dbName = this.getDBName(dbType);
      const request = indexedDB.open(dbName, new Date().getTime()); // Increment version dynamically

      request.onupgradeneeded = () => {
        const db = request.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName);
        }
      };

      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  /**
   * Save remote data to indexedDB
   * storeName is table name
   * projectId is the key
   * data is the data to be saved under the value.date
   * sync timestamp is saved as value._lastSyncTimestamp
   *
   * @param {string} storeName
   * @param {IDBValidKey} projectId
   * @param {*} data
   * @memberof IndexDBService
   */
  public async saveRemoteData(storeName: string, projectId: IDBValidKey, data: any) {
    if (projectId) {
      try {
        const dbType = DBType.Remote;
        const db = await this.openDatabase(dbType, storeName);
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const dataValue = {
          _lastSyncTimestamp: moment().tz('America/New_York').format(), // Example: "2024-11-19T05:15:30-05:00"
          data: data
        };
        const request = store.put(dataValue, projectId);

        request.onsuccess = () => console.log('Data saved successfully');
        request.onerror = () => console.log('Error saving data:', request.error);
      } catch (error) {
        console.log('Error opening database or saving data:', error);
      }
    }
  }

  /**
   * Save data to indexedDB with the key
   * Both local and remote data can be saved using this method
   *
   * @param {DBType} dbType
   * @param {string} storeName
   * @param {IDBValidKey} key
   * @param {*} data
   * @memberof IndexDBService
   */
  public async saveData(dbType: DBType, storeName: string, key: IDBValidKey, data: any) {
    try {
      const db = await this.openDatabase(dbType, storeName);
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.put(data, key);

      request.onsuccess = () => console.log('Data saved successfully');
      request.onerror = () => console.log('Error saving data:', request.error);
    } catch (error) {
      console.log('Error opening database or saving data:', error);
    }
  }

  /**
   * Get data from indexedDB
   *
   * @param {string} storeName
   * @param {IDBValidKey} key
   * @return {*}
   * @memberof IndexDBService
   */
  public async getData(dbType: DBType, storeName: string, key: IDBValidKey) {
    const db = await this.openDatabase(dbType, storeName);
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.get(key);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  public async getCachedRemoteData(storeName: string, projectId: IDBValidKey) {
    try {
      let db = await this.openDatabase(DBType.Remote, storeName);

      // Check if store exists after opening the database
      if (!db.objectStoreNames.contains(storeName)) {
        db.close();
        db = await this.createObjectStore(DBType.Remote, storeName);
      }

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const request = store.get(projectId);

        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });
    } catch (error) {
      console.log("### Error accessing cached remote data:", error);
      throw error;
    }
  }

  public async deleteCachedRemoteData(storeName: string, projectId: IDBValidKey) {
    try {
      let db = await this.openDatabase(DBType.Remote, storeName);

      // Check if store exists after opening the database
      if (!db.objectStoreNames.contains(storeName)) {
        db.close();
        db = await this.createObjectStore(DBType.Remote, storeName);
      }

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.delete(projectId);
        console.log('### Deleting cached remote data:', storeName, projectId);

        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });
    } catch (error) {
      console.log("### Error deleting cached remote data:", error);
      throw error;
    }
  }


  public async getLocalData(storeName: string, key: IDBValidKey) {
    const db = await this.openDatabase(DBType.Local, storeName);
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.get(key);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        console.log('Local data:', request.result);
        // we will set a fall back value in case data is null
        const fallbackOnNullValue = request.result?.__fallbackOnNullValue || null;
        const createdData = request?.result?.created ?? fallbackOnNullValue;
        const updatedData = request?.result?.updated ?? fallbackOnNullValue;
        const mergedData = { ...createdData, ...updatedData };
        return resolve(mergedData);
      };
      request.onerror = () => reject(request.error);
    });
  }

  public async getAllData(dbType: DBType, storeName: string) {
    const db = await this.openDatabase(dbType, storeName);
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }


  public async updateLocalDataItemsIfRemoteItemIsNewer(dbType: DBType, storeName: string, key: IDBValidKey, remoteData: any[]) {
    try {
      const db = await this.openDatabase(dbType, storeName);
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      let request;
      let updatedData = [];
      // get the data in the local db
      const localData = await <Promise<any[]>>this.getData(dbType, storeName, key);
      if (localData?.length) {
        // for each localData item in the local db, check if the data is updated later
        remoteData.forEach((remoteDataItem, index) => {
          // find the localData item with the same remote id in the local db
          const localDataItem = localData.find(item => item.id === remoteDataItem.id);
          // if the data is updated later, update the local db
          if (remoteDataItem &&
            new Date(remoteDataItem.updatedDate || remoteDataItem.createdDate) >
            new Date(localDataItem.updatedDate || localDataItem.createdDate)
          ) {
            // if the data is updated later, update the local db
            localData[index] = remoteDataItem;
          } else {
            // if the data is not updated later, we need to post the local to the remote db using the APIs
            // this will be handled later
            // TODO: post the local to the remote using the APIs
          }

          // save the updated local data to the local db
          updatedData = [...localData];
          request = store.put(localData, key);
        });

      } else {
        // if there is no local data, save the remote data to the local db
        updatedData = [...remoteData];
        request = store.put(remoteData, key);
      }

      request.onsuccess = () => {
        console.log('Data saved successfully');
        return updatedData;
      };

      request.onerror = () => {
        console.log('Error saving data:', request.error);
        // if there is an error, return the remote data as it is to continue the process
        return remoteData;
      };
    } catch (error) {
      console.log('Error opening database or saving data:', error);
    }
  }
}
