import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { PersonInterface, PersonApi, Tenant } from '@rappider/api-sdk';
import { PATH_DEFINITIONS, BOOTSTRAP_SCREEN_SIZES, SUPPORTED_LANGUAGES, LoginProvider, AppRightSidebarTab, FullPaths } from '@rappider/shared/definitions';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { Language, ThemeMode } from '@rappider/models';
import { LanguageService, NotificationService } from '@rappider/services';

import { Logout } from 'libs/authentication/src/lib/state/authentication.actions';
import { AddQueryParam, Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { Page, PageCommentWithRelations, PageWithRelations, PersonNotificationWithRelations, Project } from '@rappider/rappider-sdk';
import { Router } from '@angular/router';
import { HeadingType, SwitchTextPosition, TextMode } from '@rappider/rappider-components/utils';
import { UpdateContentTreeOfPage } from 'libs/content-editor/src/lib/state/content-editor.actions';
import { getCreationInformationFromEntity } from '@rappider/shared-functions';
import { FormatDatePipe } from 'libs/shared/src/lib/pipes/format-date.pipe';
import { templateCardsConfig } from './utils/template-cards-config';
import { ModalButtonOptions } from 'ng-zorro-antd/modal';
import { HeaderMenuItemVisibilities, NotificationListItem } from '@rappider/shared/interfaces';
import { isContentTreeChangesSaved } from 'libs/content-editor/src/lib/selectors/is-content-tree-changes-saved.selector';
import { DeployProject } from 'libs/project/src/lib/states/deploy-management/deploy-management.actions';
import { DomSanitizer } from '@angular/platform-browser';
import { DeployStatus } from 'libs/project/src/lib/components/live-preview/utils/deploy-status.enum';
import { ChangeAppSearchVisibility } from '../../state/app.actions';
import { Permission } from './utils/admin-dashboard-permissions.enum';
import { ChangeActiveProjectWithNotification, SetNotificationStatusAsSeen } from 'libs/shared/src/lib/states/notification/notification.actions';
import { formatDistanceToNow } from 'date-fns';
import { emptyNotificationAnimation } from './utils/empty-notification-animation';
import { PageDevelopmentStatus } from '@rappider/admin-dashboard';
import { NotificationStatus } from 'libs/shared/src/lib/definitions/notification/notification-status.enum';
import { environment } from '@environment';
import { getNotificationsWithProjectSelector } from './utils/get-notifications-with-project.selector';

declare global {
  interface Window { // ⚠️ notice that "Window" is capitalized here
    location: Location;
  }
}

@Component({
  selector: 'rappider-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() theme?: ThemeMode = ThemeMode.Light;
  @Input() lastActivePath?: string;
  @Input() activePath?: string;
  @Input() routePageTitle?: string;
  @Input() showPageTitle?: boolean;

  @Output() menuToggled = new EventEmitter<boolean>();
  @Output() codeViewToggled = new EventEmitter<boolean>();
  @Output() rightSidebarToggleButtonClick = new EventEmitter<AppRightSidebarTab>();

  activeProject: Project;
  activePage: Page;

  FullPaths = FullPaths;
  ThemeMode = ThemeMode;
  AppRightSidebarTab = AppRightSidebarTab;

  /* Person Definitions */
  personFullName: string;
  personInitials: string;
  activePerson: PersonInterface;
  tenant: Tenant;
  isUserLoading: boolean;
  isSocialMediaUser: boolean;
  /* Paths */
  PROFILE_PAGE_PATH = PATH_DEFINITIONS.PROFILE.PROFILE_PATH;
  LOGIN_PATH = PATH_DEFINITIONS.AUTH.LOGIN_PATH;
  REGISTER_PATH = PATH_DEFINITIONS.AUTH.REGISTER_PATH;
  PROJECT_LIST_PATH = PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH;
  /* Languages */
  selectedLanguage: string;
  supportedLanguages = SUPPORTED_LANGUAGES;
  ACTIVE_LANGUAGE: Language;

  subscriptions: Subscription[] = [];
  mobileScreenSize = BOOTSTRAP_SCREEN_SIZES.XS.maxPoint;

  isMenuCollapsed = true;
  displayCodeView = true;
  isLanguageChanged = false;
  headerMenuItemVisibilities: HeaderMenuItemVisibilities;
  pageTitle: string;
  userPermissions: string[];
  adminDashboardVisibility: boolean;

  codeViewSwitchSettings = {
    tooltipText: 'Switch to code view',
    size: 'small',
    text: {
      content: 'Code view',
      textMode: TextMode.Html
    },
    textPosition: SwitchTextPosition.Left,
    defaultValue: false
  };
  pageStatusModalConfig = {
    title: 'There are pages with a status set as in review.',
    footer: null
  };

  codeViewSwitchChecked = false;
  pageStatusModalVisibility = false;
  isPagesInReview = false;
  pages: Page;
  PageDevelopmentStatus = PageDevelopmentStatus;

  /* router.url */
  routerUrl: string;

  /* user's profile picture */
  /* TODO: Needs to be implemented  */
  /* TODO: Subscribe and add the user's profile picture url here to show the picture in user's drop down menu */
  userProfilePictureUrl: string;

  // #region Notification Variables
  notificationList: NotificationListItem[] = [];
  unreadNotificationCount: number;
  emptyNotificationAnimation = emptyNotificationAnimation;
  // #endregion Notification Variables

  // #region Header Menu Variables

  globalStyleModalVisibility = false;
  themeModalVisibility = false;
  templatesModalVisibility = false;
  templatePages: PageWithRelations[];
  cardsConfig = templateCardsConfig;
  templateModalFooterConfig: ModalButtonOptions[] = null;
  isContentTreeSaving = false;
  isContentTreeChangesSaved = true;

  // #endregion Header Menu Variables

  deployStatus: DeployStatus;
  DeployStatus = DeployStatus;
  deployLoading: boolean;
  userRole: string;

  /* comments */
  pageComments: PageCommentWithRelations[];
  NotificationStatus = NotificationStatus;
  environment = environment;

  constructor(
    private store: Store<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    public router: Router,
    private languageService: LanguageService,
    private personApi: PersonApi,
    private formatDatePipe: FormatDatePipe,
    private notificationService: NotificationService,
    public sanitizer: DomSanitizer
  ) {
    /* init language pack */
    this.languageService.initLanguagePack();
  }

  ngOnInit(): void {
    this.subscribeToData();
  }

  /**
   *
   *
   * @memberof HeaderComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Subscribe all data
   *
   * @memberof HeaderComponent
   */
  subscribeToData() {
    this.subscriptions = [
      /* TODO: THESE SUBSCRIPTIONS SHOULD MOVE TO THE SMART COMPONENT (e.g. on this sample app.component) */
      /* and the data should be passed to the child component in order to not replication subscriptions and drain the memory */
      // this.subscribeToNavigation(),
      this.subscribeToActiveProject(),
      this.subscribeToActivePerson(),
      this.subscribeToUserLoading(),
      this.subscribeToTenant(),
      this.subscribeToUserType(),
      this.subscribeToPageTemplates(),
      this.subscribeToActivePage(),
      this.subscribeToHeaderMenuItemVisibilities(),
      this.subscribeToContentTreeSavingStatus(),
      this.subscribeToContentTreeChangesSavedStatus(),
      this.subscribeToPageTitle(),
      this.subscribeToDeployStatus(),
      this.subscribeToDeployLoading(),
      this.subscribeToPageComments(),
      this.subscribeToNewNotifications(),
      this.subscribeToPageDevelopmentStatus(),
      this.subscribeToPages(),
      this.subscribeToUserPermissions(),
    ];
  }

  /**
   * subscribes to user loading status
   *
   * @return {*}
   * @memberof HeaderComponent
   */
  subscribeToUserLoading() {
    return this.store.select(state => state.auth?.isUserLoading).subscribe(isUserLoading => {
      this.isUserLoading = isUserLoading;
    });
  }

  subscribeToDeployStatus() {
    return this.store.select(state => state.deployManagement?.deployStatus).subscribe(status => {
      this.deployStatus = status;
    });
  }

  subscribeToDeployLoading() {
    return this.store.select(state => state.deployManagement?.loading).subscribe(loading => {
      this.deployLoading = loading;
    });
  }

  /**
   * Subscribe active person
   *
   * @returns
   * @memberof HeaderComponent
   */
  subscribeToActivePerson() {
    return this.store.select(state => state.auth?.activePerson).subscribe(activePerson => {
      this.activePerson = activePerson;
      /* set active language */
      if (this.activePerson) {
        this.selectedLanguage = this.activePerson.language;
        this.setFullNameFromPerson(this.activePerson);
      }
      this.changeLanguage(this.selectedLanguage);
    });
  }

  subscribeToTenant() {
    return this.store.select(state => state.auth?.tenant).subscribe(tenant => {
      this.tenant = tenant;
    });
  }

  /**
   * subscribe to active project
   *
   * @return {*}
   * @memberof HeaderComponent
   */
  subscribeToActiveProject() {
    return this.store.select(state => state.activeProject.data).subscribe(activeProject => {
      this.activeProject = activeProject;
    });
  }

  subscribeToUserType() {
    return this.store.select(state => state?.auth?.user?.provider).subscribe(provider => {
      this.isSocialMediaUser = provider !== LoginProvider.Rappider;
    });
  }

  subscribeToUserPermissions() {
    return this.store.select(state => state.auth?.permissions).subscribe(permissions => {
      this.userPermissions = permissions;
      this.adminDashboardVisibility = permissions.includes(Permission.Admin);
    });
  }

  subscribeToPageTemplates() {
    return this.store.select(state => state.page?.templatePages).subscribe((templatePages: PageWithRelations[]) => {
      this.templatePages = templatePages;
      if (templatePages?.length) {
        this.cardsConfig.selectConfig.options = templatePages.reduce(
          (acc, templatePage) => [
            ...acc,
            ...(
              templatePage?.tags?.filter(tag => !acc.some(item => item.value === tag))?.map(tag => ({
                key: tag,
                value: tag
              })) ?? []
            )
          ],
          []);
        this.buildCardOneConfigByTemplateData();
      }
    });
  }

  subscribeToActivePage() {
    return this.store.select(state => state.contentEditor?.page).subscribe((activePage: Page) => {
      this.activePage = activePage;
    });
  }

  subscribeToHeaderMenuItemVisibilities() {
    return this.store.select(state => state.app.headerMenuItemVisibilities).subscribe((headerMenuItemVisibilities: HeaderMenuItemVisibilities) => {
      this.headerMenuItemVisibilities = headerMenuItemVisibilities;
    });
  }

  subscribeToContentTreeSavingStatus() {
    return this.store.select(state => state.contentEditor.isContentTreeSaving).subscribe((isContentTreeSaving: boolean) => {
      this.isContentTreeSaving = isContentTreeSaving;
    });
  }

  subscribeToContentTreeChangesSavedStatus() {
    return this.store.select(<any>isContentTreeChangesSaved)
      .subscribe((isContentTreeSaved) => this.isContentTreeChangesSaved = isContentTreeSaved);
  }

  subscribeToPageTitle() {
    return this.store.select(state => state.app.pageTitle).subscribe(pageTitle => {
      this.pageTitle = pageTitle;
    });
  }

  subscribeToNewNotifications() {
    return this.store.select(<any>getNotificationsWithProjectSelector).subscribe((notifications: PersonNotificationWithRelations[]) => {
      const notificationList = notifications
        ?.map(notification => ({
          title: notification.title + ' within the "' + notification?.project?.name + '" project.',
          time: formatDistanceToNow(new Date(notification.createdDate), { addSuffix: true }),
          icon: 'comment',
          color: notification.status === NotificationStatus.Unread ? 'var(--primary-color)' : 'var(--text-color)',
          backgroundColor: notification.status === NotificationStatus.Unread ? 'color-mix(in srgb, var(--primary-color) 10%, transparent)' : 'color-mix(in srgb, var(--text-color) 10%, transparent)',
          id: notification.id,
          data: notification.data,
          status: notification.status
        }));
      this.unreadNotificationCount = notificationList.filter(notification => notification.status === NotificationStatus.Unread).length;
      this.notificationList = notificationList.length ? notificationList.slice(0, environment.maxVisibleNotifications) : undefined;
    });
  }

  subscribeToPageComments() {
    return this.store.select(state => state.pageComment?.data).subscribe((pageComments: PageCommentWithRelations[]) => {
      this.pageComments = pageComments;
    });
  }

  subscribeToPageDevelopmentStatus() {
    return this.store.select(state => state.page?.data).subscribe(data => {
      this.isPagesInReview = data?.some(page => page?.developmentStatus === PageDevelopmentStatus.InReview);
    });
  }

  subscribeToPages() {
    return this.store.select(state => state.page?.data).subscribe(pages => {
      this.pages = pages;
    });
  }

  /**
   * logs out for authenticated user
   *
   * @memberof HeaderComponent
   */
  logout() {
    this.store.dispatch(new Logout());
  }

  /**
   * Assign person's full name to variable
   *
   * @memberof HeaderComponent
   */
  setFullNameFromPerson(person: PersonInterface) {
    let fullName = null;
    if (person) {
      fullName = person.firstName;
      if (person.middleName) {
        fullName += ` ${person.middleName}`;
      }
      if (person.lastName) {
        fullName += ` ${person.lastName}`;
      }
    }
    this.personFullName = fullName;
  }

  /**
   * Get's the screen size
   *
   * @returns
   * @memberof HeaderComponent
   */
  innerWidth() {
    return window.innerWidth;
  }

  changeLanguage(languageName: string) {
    this.languageService.changeLanguage(languageName);
    this.ACTIVE_LANGUAGE = this.languageService.getActiveLanguage();
  }

  changePersonLanguage(languageName) {
    this.isLanguageChanged = true;
    if (this.activePerson) {
      this.personApi.upsertPatch({ id: this.activePerson.id, language: languageName }).subscribe(() => {
        this.changeLanguage(languageName);
        this.isLanguageChanged = false;
      }, () => {
        this.isLanguageChanged = false;
      });
    }
  }

  foldMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.menuToggled.emit(this.isMenuCollapsed);
  }

  onRightSidebarTogglerButtonClick(tabName: AppRightSidebarTab) {
    this.rightSidebarToggleButtonClick.emit(tabName);
  }

  navigateToProjectDetailPage() {
    const url = this.activeProject
      ? `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${this.activeProject?.id}`
      : PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH;
    this.store.dispatch(new Navigate({
      url
    }));
  }

  onSelectNotification(notification) {
    let commentType;
    if (notification.data?.uiWorkflowStepFunctionId || notification.data?.uiDataEventId) {
      commentType = 'ui-diagram';
    } else if (notification.data?.pageId) {
      commentType = 'page';
    } else if (notification.data?.projectModelId) {
      commentType = 'db-diagram';
    } else if (notification.data?.workflowEventId || notification.data?.workflowStepFunctionId) {
      commentType = 'serverside-diagram';
    }
    if (notification.status === NotificationStatus.Unread) {
      this.store.dispatch(SetNotificationStatusAsSeen({ notificationId: notification.id }));
    }
    if (notification.data.projectId !== this.activeProject?.id) {
      if (commentType === 'page') {
        this.store.dispatch(ChangeActiveProjectWithNotification({ changeActiveProjectWithNotification: { commentType: 'page', pageId: notification.data.pageId, commentId: notification.data.commentId, projectId: notification.data.projectId, isProjectChange: true, isProjectChangeWithoutRefresh: true, activeProjectLoadedThroughNotification: false } }));
      } else if (commentType === 'ui-diagram') {
        this.store.dispatch(ChangeActiveProjectWithNotification({ changeActiveProjectWithNotification: { commentType: 'ui-diagram', commentId: notification.data.commentId, projectId: notification.data.projectId, isProjectChange: true, isProjectChangeWithoutRefresh: true, activeProjectLoadedThroughNotification: false } }));
      }
    } else if (commentType === 'page' && notification.data.projectId === this.activeProject?.id && this.router.url.includes(PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH)) {
      this.store.dispatch(new AddQueryParam({ key: 'activeCommentId', value: notification.data.commentId }));
      if (this.activePage.id !== notification.data.pageId) {
        this.store.dispatch(new AddQueryParam({ key: 'targetPageId', value: notification.data.pageId }));
      }
    } else if (commentType === 'ui-diagram' && notification.data.projectId === this.activeProject?.id && this.router.url.includes('ui-workflow-diagram')) {
      this.store.dispatch(new AddQueryParam({ key: 'activeCommentId', value: notification.data.commentId }));
    } else {
      if (commentType === 'page') {
        this.router.navigate([`${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}`, notification.data.pageId], { queryParams: { activeCommentId: notification.data.commentId } });
      } else if (commentType === 'ui-diagram') {
        this.router.navigate(['ui-workflow-diagram'], { queryParams: { activeCommentId: notification.data.commentId } });
      }
    }
  }

  buildCardOneConfigByTemplateData() {
    this.cardsConfig.items = this.templatePages.map(template => ({
      data: template,
      image: {
        source: 'assets/img/abstracts/elizabeth-lies-ZWPerNlqUu0-unsplash.jpg'
      },
      titles: [
        {
          type: HeadingType.H4,
          content: template.title
        }
      ],
      descriptions: [
        {
          content: template.description
        },
        {
          content: getCreationInformationFromEntity(template, this.formatDatePipe.transform(template.createdDate))
        }
      ],
      additionalTags: template.tags?.map(tag => ({
        text: {
          textMode: TextMode.Text,
          text: tag
        }
      }))
    }));
  }

  onCodeViewClicked() {
    if (!window.location.pathname.includes('source-code')) {
      /* navigate to source code */
      this.router.navigate(['/source-code'], { queryParams: { p: window.location.pathname } });
    }
  }

  onContentEditorSaveChangesButtonClick() {
    this.store.dispatch(new UpdateContentTreeOfPage());
  }

  onDeployButtonClick() {
    if (this.deployLoading) {
    } else {
      this.notificationService.createNotification(
        'info',
        'Build In Progress',
        'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_DEPLOY_STARTED'
      );
      this.store.dispatch(DeployProject());
    }
  }

  openAppSearch() {
    this.store.dispatch(new ChangeAppSearchVisibility());
  }

  pageStatusModalVisibilityChange() {
    this.pageStatusModalVisibility = !this.pageStatusModalVisibility;
  }

}

