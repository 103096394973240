import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MODEL_ENDPOINT_LIST_CONFIG_FOR_PROJECT_DESIGN } from 'libs/shared/src/lib/configs/project/custom-endpoint';
import { ActionResponse } from '@rappider/rappider-components/utils';
import { ProjectModelWithRelations } from '@rappider/rappider-sdk';

@Component({
  selector: 'rappider-endpoints',
  templateUrl: './endpoints.component.html',
  styleUrls: ['./endpoints.component.scss']
})
export class EndpointsComponent implements OnInit {

  @Input() stopScrollingToView? = false;
  @Input() animateTable? = false;
  @Input() enableRowPointer = false;
  @Input() showNextModel = false;

  @Output() endpointRowActionClick = new EventEmitter<ActionResponse>();
  @Output() endpointDisplayed = new EventEmitter<ProjectModelWithRelations>();

  MODEL_ENDPOINT_LIST_CONFIG_FOR_PROJECT_DESIGN = MODEL_ENDPOINT_LIST_CONFIG_FOR_PROJECT_DESIGN;

  ngOnInit(): void {
    console.log('Component initialized.');
  }

  onEndpointRowActionClick(event: ActionResponse) {
    this.endpointRowActionClick.emit(event);
  }

  onEndpointDisplayed(endpoint: ProjectModelWithRelations) {
    this.endpointDisplayed.emit(endpoint);
  }
}
