/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DataSchemaField } from '../models/data-schema-field';
import { DataSchemaFieldBulkUpdateDto } from '../models/data-schema-field-bulk-update-dto';
import { DataSchemaFieldBulkUpdateUiSettingsBodyDto } from '../models/data-schema-field-bulk-update-ui-settings-body-dto';
import { DataSchemaFieldCreateDto } from '../models/data-schema-field-create-dto';
import { DataSchemaFieldPartial } from '../models/data-schema-field-partial';
import { DataSchemaFieldWithRelations } from '../models/data-schema-field-with-relations';
import { LastProcessInformation } from '../models/last-process-information';
import { NewDataSchemaField } from '../models/new-data-schema-field';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class DataSchemaFieldControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation dataSchemaFieldControllerCreateAllByAdmin
   */
  static readonly DataSchemaFieldControllerCreateAllByAdminPath = '/admin/data-schema-fields/bulk-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAllByAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAllByAdmin$Response(params?: {
    body?: Array<DataSchemaFieldCreateDto>
  }): Observable<StrictHttpResponse<Array<DataSchemaField>>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerCreateAllByAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataSchemaField>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAllByAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAllByAdmin(params?: {
    body?: Array<DataSchemaFieldCreateDto>
  }): Observable<Array<DataSchemaField>> {

    return this.createAllByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DataSchemaField>>) => r.body as Array<DataSchemaField>)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerBulkDeleteByAdmin
   */
  static readonly DataSchemaFieldControllerBulkDeleteByAdminPath = '/admin/data-schema-fields/bulk-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDeleteByAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDeleteByAdmin$Response(params?: {
    dataSchemaFieldIds?: Array<string>;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerBulkDeleteByAdminPath, 'delete');
    if (params) {
      rb.query('dataSchemaFieldIds', params.dataSchemaFieldIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkDeleteByAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDeleteByAdmin(params?: {
    dataSchemaFieldIds?: Array<string>;
  }): Observable<any> {

    return this.bulkDeleteByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerBulkUpdateByAdmin
   */
  static readonly DataSchemaFieldControllerBulkUpdateByAdminPath = '/admin/data-schema-fields/bulk-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpdateByAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateByAdmin$Response(params?: {
    body?: Array<DataSchemaFieldBulkUpdateDto>
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerBulkUpdateByAdminPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpdateByAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateByAdmin(params?: {
    body?: Array<DataSchemaFieldBulkUpdateDto>
  }): Observable<any> {

    return this.bulkUpdateByAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerCreateAll
   */
  static readonly DataSchemaFieldControllerCreateAllPath = '/data-schema-fields/bulk-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAll$Response(params?: {
    body?: Array<DataSchemaFieldCreateDto>
  }): Observable<StrictHttpResponse<Array<DataSchemaField>>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerCreateAllPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataSchemaField>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAll(params?: {
    body?: Array<DataSchemaFieldCreateDto>
  }): Observable<Array<DataSchemaField>> {

    return this.createAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DataSchemaField>>) => r.body as Array<DataSchemaField>)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerBulkDelete
   */
  static readonly DataSchemaFieldControllerBulkDeletePath = '/data-schema-fields/bulk-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDelete$Response(params?: {
    dataSchemaFieldIds?: Array<string>;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerBulkDeletePath, 'delete');
    if (params) {
      rb.query('dataSchemaFieldIds', params.dataSchemaFieldIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkDelete(params?: {
    dataSchemaFieldIds?: Array<string>;
  }): Observable<any> {

    return this.bulkDelete$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerBulkUpdate
   */
  static readonly DataSchemaFieldControllerBulkUpdatePath = '/data-schema-fields/bulk-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdate$Response(params?: {
    body?: Array<DataSchemaFieldBulkUpdateDto>
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerBulkUpdatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdate(params?: {
    body?: Array<DataSchemaFieldBulkUpdateDto>
  }): Observable<any> {

    return this.bulkUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerBulkUpdateUiSettings
   */
  static readonly DataSchemaFieldControllerBulkUpdateUiSettingsPath = '/data-schema-fields/bulk-update-ui-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpdateUISettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateUISettings$Response(params?: {
    body?: Array<DataSchemaFieldBulkUpdateUiSettingsBodyDto>
  }): Observable<StrictHttpResponse<Array<DataSchemaField>>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerBulkUpdateUiSettingsPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataSchemaField>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpdateUISettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateUISettings(params?: {
    body?: Array<DataSchemaFieldBulkUpdateUiSettingsBodyDto>
  }): Observable<Array<DataSchemaField>> {

    return this.bulkUpdateUISettings$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DataSchemaField>>) => r.body as Array<DataSchemaField>)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerCount
   */
  static readonly DataSchemaFieldControllerCountPath = '/data-schema-fields/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerGetJsonSchema
   */
  static readonly DataSchemaFieldControllerGetJsonSchemaPath = '/data-schema-fields/get-json-schema/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getJSONSchema()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchema$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerGetJsonSchemaPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getJSONSchema$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getJSONSchema(params: {
    id: string;
  }): Observable<any> {

    return this.getJSONSchema$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerFindLatestChangeTimestamps
   */
  static readonly DataSchemaFieldControllerFindLatestChangeTimestampsPath = '/data-schema-fields/latest-change-timestamps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLatestChangeTimestamps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps$Response(params?: {
  }): Observable<StrictHttpResponse<LastProcessInformation>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerFindLatestChangeTimestampsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LastProcessInformation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findLatestChangeTimestamps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLatestChangeTimestamps(params?: {
  }): Observable<LastProcessInformation> {

    return this.findLatestChangeTimestamps$Response(params).pipe(
      map((r: StrictHttpResponse<LastProcessInformation>) => r.body as LastProcessInformation)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerFindById
   */
  static readonly DataSchemaFieldControllerFindByIdPath = '/data-schema-fields/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<DataSchemaField>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataSchemaField>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<DataSchemaField> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<DataSchemaField>) => r.body as DataSchemaField)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerDeleteById
   */
  static readonly DataSchemaFieldControllerDeleteByIdPath = '/data-schema-fields/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerUpdateById
   */
  static readonly DataSchemaFieldControllerUpdateByIdPath = '/data-schema-fields/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: DataSchemaFieldPartial
  }): Observable<StrictHttpResponse<DataSchemaField>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataSchemaField>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: DataSchemaFieldPartial
  }): Observable<DataSchemaField> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<DataSchemaField>) => r.body as DataSchemaField)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerFind
   */
  static readonly DataSchemaFieldControllerFindPath = '/data-schema-fields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<DataSchemaFieldWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataSchemaFieldWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<DataSchemaFieldWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DataSchemaFieldWithRelations>>) => r.body as Array<DataSchemaFieldWithRelations>)
    );
  }

  /**
   * Path part for operation dataSchemaFieldControllerCreate
   */
  static readonly DataSchemaFieldControllerCreatePath = '/data-schema-fields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewDataSchemaField
  }): Observable<StrictHttpResponse<DataSchemaField>> {

    const rb = new RequestBuilder(this.rootUrl, DataSchemaFieldControllerService.DataSchemaFieldControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataSchemaField>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewDataSchemaField
  }): Observable<DataSchemaField> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<DataSchemaField>) => r.body as DataSchemaField)
    );
  }

}
