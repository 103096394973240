<rappider-spin [spinning]="isLoading">

  <ng-template #settingsIconTemplate>
    <ng-container *ngTemplateOutlet="tabIcon;context:{
    icon: 'far fa-cog',
    title: selectedTabIndex === 0 ? 'Settings' : '',
    tooltip: 'CONTENT_EDITOR_MODULE.COMPONENT_SETTINGS_COMPONENT.SETTINGS' | translate
  }"></ng-container>
  </ng-template>

  <rappider-spin
                 [spinning]="activeContentTreeItem?.component && (activeContentTreeItem?.component.componentDefinition?.inputDefinitions && !activeContentTreeItem?.component.componentDefinition?.inputDefinitions.length)">

    <ng-container *ngTemplateOutlet="componentTitle;context:{component:activeContentTreeItem?.component}">
      component title template
    </ng-container>

    <ng-container *ngIf="activeContentTreeItem?.component; else noComponentSelected">

      <ng-container *ngIf="INPUT_DEFINITION_SECTION_CONFIGS?.length; else noComponentInputDefinition">
        <div class="search-area">
          <rappider-input-group [(ngModel)]="inputDefinitionSearchValue"
                                [textbox]="inputDefinitionSearchInputGroup.textbox"
                                [suffixIcon]="inputDefinitionSearchInputGroup.suffixIcon"
                                (ngModelChange)="onInputDefinitionSearch()"></rappider-input-group>
        </div>

        <ng-container *ngIf="!this.inputDefinitionSearchValue">
          <nz-collapse class="input-section"
                       nzAccordion>
            <nz-collapse-panel *ngFor="let sectionConfig of INPUT_DEFINITION_SECTION_CONFIGS"
                               [nzHeader]="sectionConfig.title"
                               [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ?
                               false : allPanelsActive ? true : (visiblePanelKeys.includes(ContentEditorSettingsSection.ComponentSettings) && sectionConfig.active)"
                               class="input-section-panel">
              <div class="input-form component-settings-form">
                <rappider-crud-view-edit-form class="component-settings-edit-form"
                                              dividerMode="true"
                                              [focusFirstInput]="false"
                                              [itemLength]="2"
                                              [config]="sectionConfig.data.config"
                                              [data]="activeContentTreeItem?.parentPageComponentContentId ? existingInputsByParentComponent:activeContentTreeItem?.component.inputs"
                                              (formValueChange)="onComponentFormDataChange($event)"
                                              (labelMenuItemClick)="onLabelMenuItemClick($event)"
                                              (itemOutputEvent)="onFormItemOutputEvent($event)">
                </rappider-crud-view-edit-form>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </ng-container>

        <ng-container *ngIf="this.inputDefinitionSearchValue">
          <div class="input-form component-settings-form">
            <rappider-crud-view-edit-form *ngIf="filteredInputDefinitionSectionConfigsBySearchValue.items?.length; else noResultTmpl"
                                          class="component-settings-edit-form"
                                          [config]="filteredInputDefinitionSectionConfigsBySearchValue"
                                          [data]="activeContentTreeItem?.parentPageComponentContentId ? existingInputsByParentComponent:activeContentTreeItem?.component.inputs"
                                          (formValueChange)="onComponentFormDataChange($event)"
                                          (labelMenuItemClick)="onLabelMenuItemClick($event)"
                                          (itemOutputEvent)="onFormItemOutputEvent($event)">
            </rappider-crud-view-edit-form>
            <ng-template #noResultTmpl>
              <nz-empty [nzNotFoundContent]="'No Result'"></nz-empty>
            </ng-template>
          </div>
        </ng-container>

        <nz-collapse class="input-section">
          <nz-collapse-panel [nzHeader]="'PRE-DEFINED SETTINGS' | translate"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.InputVariations)">
            <div class="input-form">
              <rappider-component-input-variation-select [activeComponent]="activeContentTreeItem?.component">
              </rappider-component-input-variation-select>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <nz-collapse class="css-classes-input-collapse-wrapper">
          <nz-collapse-panel [nzHeader]="'CSS CLASSES' | translate"
                             [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.CssClasses)">
            <div class="input-form">
              <rappider-crud-view-edit-form [config]="componentCustomClassesFormConfig"
                                            [data]="activeContentTreeItem?.component"
                                            (fieldValueChange)="onComponentUpdate($event)">
              </rappider-crud-view-edit-form>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
      </ng-container>

    </ng-container>

  </rappider-spin>

  <ng-template #noComponentSelected>
    <div class="no-component-selected">
      {{ 'CONTENT_EDITOR_MODULE.COMPONENT_SETTINGS_COMPONENT.PLEASE_SELECT_COMPONENT_TO_SET' | translate }}
    </div>
  </ng-template>

  <ng-template #noComponentInputDefinition>
    <div class="no-component-input-definition">
      {{ 'CONTENT_EDITOR_MODULE.COMPONENT_SETTINGS_COMPONENT.THIS_COMPONENT_HAS_NO_INPUT' | translate }}
    </div>
  </ng-template>

  <!-- ADDITIONAL COLLAPSE PANES ----------------------------------------------------------------->
  <!-- Component Output -->
  <nz-collapse class="output-collapse-container">
    <nz-collapse-panel [nzHeader]="'OUTPUTS' | translate"
                       [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.ComponentOutputs)">
      <div class="collapse-item-container">
        <rappider-spin
                       [spinning]="activeContentTreeItem?.component && !activeContentTreeItem?.component.componentDefinition?.inputDefinitions?.length">
          <ng-container *ngIf="activeContentTreeItem?.component; else noComponentSelected">
            <rappider-component-event [component]="activeContentTreeItem?.component"></rappider-component-event>
          </ng-container>
        </rappider-spin>
      </div>
    </nz-collapse-panel>
  </nz-collapse>

  <nz-collapse class="component-comments-collapse-container">
    <nz-collapse-panel [nzHeader]="'COMPONENT COMMENTS' | translate"
                       [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.ComponentComments)">
      <div class="collapse-item-container comments">
        <rappider-comment-wrapper #commentWrapperComponentRef
                                  [comments]="commentsFilteredByActiveTreeItemId"
                                  [activePerson]="activePerson"
                                  [peopleData]="projectPeopleData"
                                  [isLoading]="commentsLoading"
                                  [scrollingCommentId]="activeCommentId"
                                  [isDisplayingAllComments]="true"
                                  (createComment)="onCreateComment($event)"
                                  (editComment)="onEditComment($event)"
                                  (deleteComment)="onDeleteComment($event)"></rappider-comment-wrapper>
      </div>
    </nz-collapse-panel>
  </nz-collapse>

</rappider-spin>

<!-- ------------------------------------------------------------------------------------------ -->
<!-- COMPONENT TITLE TEMPLATE -->
<ng-template #componentTitle
             let-component="component">
  <section class="component-title">
    <div *ngIf="!componentTitleEditMode"
         class="title-wrapper">
      <h5>{{component?.title}}</h5>
      <rappider-button [icon]="componentSettingsEditTitleButtonConfig.icon"
                       [tooltipText]="componentSettingsEditTitleButtonConfig.tooltipText"
                       (click)="onEditComponentTitleButtonClick()">
      </rappider-button>
    </div>
    <div *ngIf="componentTitleEditMode"
         class="title-wrapper">
      <nz-input-group [nzSuffix]="editComponentTitleButtonsTemplate">
        <input nz-input
               [(ngModel)]="editedComponentTitle"
               (keydown.enter)="onComponentTitleSave()"
               (keydown.escape)="onCancelEditComponentTitle()" />
      </nz-input-group>
      <ng-template #editComponentTitleButtonsTemplate>
        <i class="fa-regular fa-xmark component-title-cancel-button"
           (click)="onCancelEditComponentTitle()"></i>
        <i class="fa-regular fa-check component-title-save-button"
           (click)="onComponentTitleSave()"></i>
      </ng-template>
    </div>
  </section>
</ng-template>
<!-- / COMPONENT TITLE TEMPLATE -->
<!-- ------------------------------------------------------------------------------------------ -->

<!-- ------------------------------------------------------------------------------------------ -->
<!-- TAB ICON TEMPLATE -->
<ng-template #tabIcon
             let-icon="icon"
             let-title="title"
             let-tooltip="tooltip">
  <div class="tab-icon d-flex"
       [nzTooltipTitle]="tooltip"
       nzTooltipPlacement="bottom"
       nz-tooltip>
    <i [ngClass]="icon"></i>
    <p *ngIf="title">{{ title }}</p>
  </div>
</ng-template>
<!-- / TAB ICON TEMPLATE -->
<!-- ------------------------------------------------------------------------------------------ -->
