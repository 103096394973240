<!-- Replies Section -->
<ng-container *ngIf="replies?.length">
  <div *ngFor="let reply of replies trackBy: replyTrackBy">
    <rappider-divider class="divider"></rappider-divider>

    <!-- Reply Header Area -->
    <div class="comment-header-area flex">
      <div class="comment-author-details flex">
        <nz-avatar [nzText]="getCommentAuthorNameInitials(reply.createdBy)"
                   [style]="{color:'#f56a00', backgroundColor:'#fde3cf'}"
                   [nzSize]="35"></nz-avatar>
        <div class="comment-details">
          <div class="author-name-area">{{ reply.createdBy }}</div>
          <div class="time-area"
               *ngIf="reply.createdDate">{{ setCommentCreatedDate(reply.createdDate) }}</div>
        </div>
      </div>

      <!-- Comment Actions Area for Replies -->
      <div [@hideShow]="isCommentHovered ? 'show' : 'hide'"
           class="comment-actions-area flex">
        <ng-container *ngIf="activePerson?.id === reply.createdById">
          <rappider-dropdown-menu rappiderClickStopPropagation
                                  class="comment-actions-dropdown"
                                  [icon]="commentActionsDropdownConfig.icon"
                                  [items]="commentActionsDropdownConfig.items"
                                  (menuItemClick)="onReplyMenuItemClick($event, reply.id)"></rappider-dropdown-menu>
        </ng-container>
      </div>
    </div>

    <!-- Reply Content Area -->
    <div *ngIf="!reply.isEditModeActive; else replyEditTemplate"
         class="content-area">
      <div class="comment-content">
        <p [innerHTML]="renderReplyPreview(reply.content)"></p>
      </div>
    </div>

    <!-- Reply Edit Template -->
    <ng-template #replyEditTemplate>
      <div class="reply-textarea">
        <nz-mention [nzSuggestions]="mentionData"
                    [nzValueWith]="valueWith"
                    [nzNotFoundContent]="'Person not found' | translate">
          <textarea rappiderClickStopPropagation
                    nz-input
                    rows="2"
                    [(ngModel)]="reply.content"
                    [placeholder]="'Reply with your comment or mention people with @' | translate"
                    nzMentionTrigger></textarea>
          <ng-container *nzMentionSuggestion="let person">
            <div class="mention-dropdown-item">
              <span class="mention-dropdown-name">{{ person.key }}</span><br>
              <span class="mention-dropdown-email">{{ person.value }}</span>
            </div>
          </ng-container>
        </nz-mention>
      </div>

      <div *ngIf="reply.content"
           class="reply-textarea-buttons">
        <rappider-button rappiderClickStopPropagation
                         [text]="replyButtonConfig.text"
                         [size]="replyButtonConfig.size"
                         [type]="replyButtonConfig.type"
                         (click)="onEditReply(reply.id, reply.parentCommentId, reply.content)"></rappider-button>
        <rappider-button rappiderClickStopPropagation
                         [text]="cancelButtonConfig.text"
                         [size]="cancelButtonConfig.size"
                         (click)="toggleReplyEditMode(reply)"></rappider-button>
      </div>
    </ng-template>
  </div>
</ng-container>

<!-- Reply Create Section -->
<ng-container *ngIf="isCreateReplyModeActive">
  <rappider-divider class="divider"></rappider-divider>

  <div class="reply-textarea">
    <nz-mention [nzSuggestions]="mentionData"
                [nzValueWith]="valueWith"
                [nzNotFoundContent]="'Person not found' | translate">
      <textarea rappiderClickStopPropagation
                nz-input
                rows="2"
                [(ngModel)]="replyContent"
                [placeholder]="'Reply with your comment or mention people with @' | translate"
                nzMentionTrigger></textarea>
      <ng-container *nzMentionSuggestion="let person">
        <div class="mention-dropdown-item">
          <span class="mention-dropdown-name">{{ person.key }}</span><br>
          <span class="mention-dropdown-email">{{ person.value }}</span>
        </div>
      </ng-container>
    </nz-mention>
  </div>

  <!-- Reply Textarea Buttons -->
  <div class="reply-textarea-buttons">
    <rappider-button rappiderClickStopPropagation
                     [text]="replyButtonConfig.text"
                     [size]="replyButtonConfig.size"
                     [type]="replyButtonConfig.type"
                     (click)="onSaveReply()"></rappider-button>
    <rappider-button rappiderClickStopPropagation
                     [text]="cancelButtonConfig.text"
                     [size]="cancelButtonConfig.size"
                     (click)="onCancelReply()"></rappider-button>
  </div>
</ng-container>
