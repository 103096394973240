import { Validators } from '@angular/forms';
import { ButtonType, CrudFormConfig, CrudFormSelectItem, CrudViewFormItemType, FormLayout, InputSize } from '@rappider/rappider-components/utils';
import { APP_TYPES, BACKEND_FRAMEWORK_TYPES, FRONTEND_FRAMEWORK_TYPES, PROJECT_KEY_REGEX, PROJECT_NAME_REGEX } from '@rappider/shared/definitions';
import { METADATA_EDIT_FORM_CONFIG } from 'libs/shared/src/lib/configs/metadata/metadata-edit-form-config';
import { MONACO_MARKDOWN_CONFIG } from 'libs/shared/src/lib/configs/monaco-markdown-config';

export const ADMIN_PROJECT_EDIT_FORM_CONFIG: CrudFormConfig = {
  items: [
    {
      section: 'Project Information',
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PROJECT_NAME_REGEX),
          errorKey: 'pattern',
          errorMessage: 'PROJECT_MODULE.PROJECT_NAME_ERROR'
        }
      ]
    },
    MONACO_MARKDOWN_CONFIG,
    {
      section: 'Project Information',
      title: 'SHARED.KEY',
      fieldName: 'key',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PROJECT_KEY_REGEX),
          errorKey: 'pattern',
          errorMessage: 'PROJECT_MODULE.PROJECT_KEY_ERROR'
        }

      ]
    },
    <CrudFormSelectItem>{
      section: 'Project Settings',
      disabled: true,
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.APP_TYPE',
      fieldName: 'appType',
      type: CrudViewFormItemType.Select,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.SELECT_APP_TYPE',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: [
        { key: APP_TYPES.WEB.key, value: APP_TYPES.WEB.value },
      ],
      default: APP_TYPES.WEB.value,
    },
    <CrudFormSelectItem>{
      section: 'Project Settings',
      disabled: true,
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.FRONTEND_FRAMEWORK_TITLE',
      fieldName: 'frontendFramework',
      type: CrudViewFormItemType.Select,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.SELECT_FRONTEND_FRAMEWORK',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: [
        { key: FRONTEND_FRAMEWORK_TYPES.ANGULAR.key, value: FRONTEND_FRAMEWORK_TYPES.ANGULAR.value },
      ],
      default: FRONTEND_FRAMEWORK_TYPES.ANGULAR.value,
    },
    <CrudFormSelectItem>{
      section: 'Project Settings',
      disabled: true,
      title: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.BACKEND_FRAMEWORK_TITLE',
      fieldName: 'backendFramework',
      type: CrudViewFormItemType.Select,
      size: InputSize.Large,
      placeholder: 'PROJECT_MODULE.PROJECT_EXPORT_COMPONENT.SELECT_BACKEND_FRAMEWORK',
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: [
        { key: BACKEND_FRAMEWORK_TYPES.LOOPBACK4.key, value: BACKEND_FRAMEWORK_TYPES.LOOPBACK4.value },
        { key: BACKEND_FRAMEWORK_TYPES.SQLALCHEMY.key, value: BACKEND_FRAMEWORK_TYPES.SQLALCHEMY.value },
      ],
      default: BACKEND_FRAMEWORK_TYPES.LOOPBACK4.value
    },
    METADATA_EDIT_FORM_CONFIG
  ],
  itemSettings: {
    inputComponentSize: {
      xs: 24
    },
    labelComponentSize: {
      xs: 24
    },
  },
  submitButton: {
    type: ButtonType.Primary
  },
  formLayout: FormLayout.Vertical
};

