import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiagramWrapperComponent } from './components/diagram-wrapper/diagram-wrapper.component';
import {
  RappiderAlertModule, RappiderButtonModule,
  RappiderHeadingModule, RappiderIconModule,
  RappiderModalModule
} from '@rappider/rappider-components';
import { DiagramToolbarComponent } from './components/diagram-toolbar/diagram-toolbar.component';
import { WorkflowEventModule } from '@rappider/workflow-event';
import { WorkflowStepFunctionModule } from '@rappider/workflow-step-function';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { CommentModule } from '@rappider/comment';
import { RappiderButtonListModule } from '@rappider/rappider-components';

@NgModule({
  imports: [
    CommonModule,
    RappiderButtonModule,
    RappiderIconModule,
    WorkflowEventModule,
    WorkflowStepFunctionModule,
    RappiderHeadingModule,
    RappiderIconModule,
    NzDropDownModule,
    TranslateModule,
    RappiderModalModule,
    NzCollapseModule,
    RappiderAlertModule,
    CommentModule,
    RappiderButtonListModule
  ],
  declarations: [
    DiagramWrapperComponent,
    DiagramToolbarComponent
  ],
  exports: [
    DiagramWrapperComponent
  ]
})
export class WorkflowDiagramForComponentsModule { }
