import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rappider-global-styles',
  templateUrl: './global-styles.component.html',
  styleUrls: ['./global-styles.component.scss']
})
export class GlobalStylesComponent implements OnInit {

  ngOnInit(): void {
    console.log('Component initialized.');
  }

}
