import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import {
  ButtonComponentConfig
} from '@rappider/rappider-components/utils';

@Component({
  selector: 'rappider-settings-button-group',
  templateUrl: './settings-button-group.component.html',
  styleUrls: ['./settings-button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsButtonGroupComponent {
  @Input() buttons: ButtonComponentConfig[] = [];
  @Input() isExpanded? = true;

  @Output() buttonClicked = new EventEmitter<string>();


  onButtonClicked(key: string) {
    this.buttonClicked.emit(key);
  }
}
