import { createAction, createReducer, on } from '@ngrx/store';
import { PageVariable } from '@rappider/rappider-sdk';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';
import * as PageVariablesActions from './page-variables.actions';

export const featureKey = 'pageVariables';

export interface PageVariablesState {
  data: PageVariable[];
  isLoading: boolean;
  isLoaded: boolean;
}

export const initialState: PageVariablesState = {
  data: [],
  isLoading: false,
  isLoaded: false
};

const UpdateAuthenticationTokenWithProjectId = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectId);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,

  on(PageVariablesActions.GetPageVariables, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(PageVariablesActions.GetPageVariablesSuccessful, (state, action) => ({
    ...state,
    data: action.payload.pageVariables,
    isLoaded: true,
    isLoading: false
  })),
  on(PageVariablesActions.CreatePageVariable, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(PageVariablesActions.CreatePageVariableSuccessful, (state, action) => ({
    ...state,
    data: [...state.data, action.payload.pageVariable],
    isLoading: false
  })),
  on(PageVariablesActions.CreatePageVariableWithAISuccessful, (state, action) => ({
    ...state,
    data: [...state.data, action.payload.pageVariable],
    isLoading: false
  })),
  on(PageVariablesActions.UpdatePageVariables, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(PageVariablesActions.UpdatePageVariablesSuccessful, (state, action) => ({
    ...state,
    data: state.data.map((pageVariable) =>
      pageVariable.id === action.payload.pageVariable.id ? action.payload.pageVariable : pageVariable
    ),
    isLoading: false
  })),
  on(PageVariablesActions.DeletePageVariable, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(PageVariablesActions.DeletePageVariableSuccessful, (state, action) => ({
    ...state,
    data: state.data.filter((pageVariable) => pageVariable.id !== action.payload.pageVariableId),
    isLoading: false
  })),

  on(UpdateAuthenticationTokenWithProjectId, () => ({ ...initialState })),

  on(Logout, () => initialState)
);
