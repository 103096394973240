import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappiderEmailViewerComponent } from './email-viewer.component';
import { RappiderIconModule } from '../icon/icon.module';
import { RappiderButtonModule } from '../button/button.module';
import { RappiderTextModule } from '../text/text.module';

@NgModule({
  declarations: [
    RappiderEmailViewerComponent
  ],
  imports: [
    CommonModule,
    RappiderIconModule,
    RappiderButtonModule,
    RappiderTextModule
  ],
  exports: [
    RappiderEmailViewerComponent
  ]
})
export class RappiderEmailViewerModule { }
