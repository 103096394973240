<rappider-modal [(visible)]="visibility"
                [fullScreen]="true"
                [title]="container.title + ' Visibility'"
                [className]="'visibility-condition-modal'"
                [footer]="modalFooterTemplate"
                (cancelClick)="onModalVisibilityChange(false)"
                (visibleChange)="onModalVisibilityChange($event)">
  <div class="visibility-condition">
    <div class="data-source-selector-section">
      <div class="data-source-tree-wrapper">
        <div class="section-title-wrapper">
          <h6>
            Select Data Source
          </h6>
        </div>
        <div class="data-source-tree">
          <rappider-ui-data-store-field-tree-selector [(ngModel)]="selectedDataSources"
                                                      (ngModelChange)="onDataSourceSelectionChange()"></rappider-ui-data-store-field-tree-selector>
        </div>
      </div>
      <div class="data-source-list">
        <div class="section-title-wrapper">
          <h6>{{'Data Sources' | translate}}</h6>
        </div>
        <div *ngIf="!selectedDataSources?.length">
          <i>Please select data source</i>
        </div>
        <div class="data-source-list-body">
          <section *ngFor="let dataSource of selectedDataSources; let index = index"
                   class="data-source-item">
            {{dataSource.variableName}}
            <rappider-button [type]="removeDataSourceButtonConfig.type"
                             [icon]="removeDataSourceButtonConfig.icon"
                             [size]="removeDataSourceButtonConfig.size"
                             (confirm)="onDataSourceRemoved(dataSource)"></rappider-button>
          </section>
        </div>
      </div>
    </div>
    <div class="json-sample-and-condition-wrapper">
      <div class="condition-section">
        <div class="section-title-wrapper">
          <h6>
            Condition
          </h6>
        </div>
        <div class="filter-find-row-element-wrapper">
          <rappider-filter-find-row-element [(ngModel)]="condition"
                                            [visibility]="true"
                                            [functions]="functions"
                                            [tree]="[]"></rappider-filter-find-row-element>
        </div>
      </div>
      <div class="json-sample-section">
        <div class="section-title-wrapper">
          <h6>
            Sample Data
          </h6>
        </div>
        <div *ngIf="monacoEditorVisibility"
             class="json-sample-editor-wrapper">
          <rappider-monaco-code-editor [ngModel]="sampleJson"
                                       [options]="sampleJsonOptions"
                                       [autoFormat]="true"
                                       [borderSettings]="{border: 'none'}"
                                       [showSelectedKeyPath]="true"
                                       [loading]="sampleJsonLoading"></rappider-monaco-code-editor>
        </div>
      </div>
    </div>
  </div>
</rappider-modal>

<ng-template #modalFooterTemplate>
  <div class="visibility-condition-modal-footer">
    <div *ngIf="editMode"
         class="footer-left">
      <button nz-button
              nzType="dashed"
              nzDanger
              (click)="onRemoveVisibilityCondition()">
        Delete
      </button>
    </div>
    <div class="footer-right">
      <button nz-button
              (click)="onModalVisibilityChange(false)">
        Discard
      </button>
      <button nz-button
              nzType="primary"
              (click)="onSaveButtonClick()">
        Save
      </button>
    </div>
  </div>
</ng-template>
