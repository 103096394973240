import { createSelector } from '@ngrx/store';
import { PersonProjectWithRelations, ProjectWithRelations } from '@rappider/rappider-sdk';

export const getPersonWithProjects = createSelector(
  state => state['project']?.data,
  state => state['project']?.personProjects,
  state => state['project']?.isLoaded,
  state => state['project']?.isPersonProjectsLoaded,
  state => state['auth']?.activePerson,
  (
    projects: ProjectWithRelations[],
    personProjects: PersonProjectWithRelations[],
    projectsIsLoaded: boolean,
    personProjectsIsLoaded: boolean,
    activePerson: any
  ) => ({
    projects: projects?.map(project => {
      return {
        ...project,
        isFavorited: !!personProjects?.find(personProject => personProject.projectId === project.id)?.isFavorited
      } as ProjectWithRelations & { isFavorited: boolean }
    }) || [],
    isLoaded: projectsIsLoaded && personProjectsIsLoaded,
    person: activePerson
  })
);
