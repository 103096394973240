import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rappider-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {

  ngOnInit(): void {
    console.log('Component initialized.');
  }

}
