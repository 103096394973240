<div class="page-templates-wrapper">
  <rappider-cards [title]="title"
                  [items]="items"
                  [isLoading]="isLoading"
                  [itemCountPerRow]="itemCountPerRow"
                  [showTitleOnImage]="showTitleOnImage"
                  [showDescriptionOnImage]="showDescriptionOnImage"
                  [selectConfig]="selectConfig"
                  [paginationConfig]="paginationConfig"
                  [showCheckMarkOnCard]="showCheckMarkOnCard"
                  [isDynamicPagination]="isDynamicPagination"
                  (pageIndexChange)="onPageIndexChange($event)"
                  (searchTextChange)="onSearchTextChange($event)"
                  (tagFilterChange)="onTagFilterChange($event)"
                  (cardClick)="onCardClick($event)"></rappider-cards>
</div>
