import { Validators } from '@angular/forms';
import {
  ButtonType,
  CrudTableViewConfig,
  CrudViewColumnType,
  InputSize,
  ActionBehavior,
  CrudFormConfig,
  CrudFormTextBoxItem,
  CrudViewFormItemType,
  TextBoxType,
  CrudFormInputTemplateItem,
  CrudFormSelectItem,
  PropertyType
} from '@rappider/rappider-components/utils';
import { PROJECT_SETTING_AND_ENVIRONMENT_VARIABLE_KEY_REGEX } from '../../definitions/regex';
import { POP_CONFIRM_DELETE_ACTION, environmentVariablesPopConfirmTitle } from '../pop-confirm-button/pop-confirm-button-config';
import { ENVIRONMENT_VARIABLE_DATA_TYPES } from './environment-variable-data-types.enum';

export const ENVIRONMENT_VARIABLES_CONFIG: CrudTableViewConfig = {
  defaultSearchField: 'key',
  columns: [
    {
      title: 'ID',
      fieldName: 'id',
      type: CrudViewColumnType.Text,
      visible: false
    },
    {
      title: 'SHARED.KEY',
      fieldName: 'key',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.VALUE',
      fieldName: 'value',
      type: CrudViewColumnType.Text
    },
    {
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewColumnType.Text
    },
    {
      title: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.EXPOSE_TO_FRONTEND',
      fieldName: 'exposeToFrontend',
      type: CrudViewColumnType.Boolean
    },
    {
      title: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.EXPOSE_TO_BACKEND',
      fieldName: 'exposeToBackend',
      type: CrudViewColumnType.Boolean
    }
  ],
  listActions: [
    {
      text: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.ADD_ENVIRONMENT_VARIABLE',
      behavior: ActionBehavior.Emit,
      buttonType: ButtonType.Default,
      icon: { name: 'fas fa-plus' }
    }
  ],
  itemActions: [
    {
      text: 'SHARED.EDIT',
      name: 'EDIT_ENVIRONMENT_VARIABLE',
      icon: { name: 'far fa-edit' },
      behavior: ActionBehavior.Emit
    },
    {
      text: POP_CONFIRM_DELETE_ACTION.text,
      name: POP_CONFIRM_DELETE_ACTION.name,
      popconfirmTitle: environmentVariablesPopConfirmTitle,
      emitWithoutPopconfirm: POP_CONFIRM_DELETE_ACTION.emitWithoutPopconfirm,
      behavior: POP_CONFIRM_DELETE_ACTION.behavior,
      icon: POP_CONFIRM_DELETE_ACTION.icon,
      popConfirmCancelText: POP_CONFIRM_DELETE_ACTION.popConfirmCancelText,
      popConfirmOkText: POP_CONFIRM_DELETE_ACTION.popConfirmOkText,
      popConfirmOkDanger: POP_CONFIRM_DELETE_ACTION.popConfirmOkDanger,
    }
  ],
  handleVisibilityFn: (data, item, action) => {
    if (item.isUpdatable && action.name === 'EDIT_ENVIRONMENT_VARIABLE') {
      return true;
    }

    if (item.isDeletable && action.name === 'DELETE_ITEM') {
      return true;
    }
  }
};

export const CREATE_OR_EDIT_ENVIRONMENT_VARIABLE_FORM_CONFIG: CrudFormConfig = {
  items: [
    <CrudFormTextBoxItem>{
      title: 'SHARED.KEY',
      fieldName: 'key',
      type: CrudViewFormItemType.TextBox,
      textType: TextBoxType.Text,
      disabled: false,
      placeholder: 'SHARED.KEY',
      showRequiredSign: true,
      size: InputSize.Default,
      validators: [
        {
          type: Validators.pattern(PROJECT_SETTING_AND_ENVIRONMENT_VARIABLE_KEY_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.ENVIRONMENT_KEY_AND_SETTING_ERROR'
        },
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'SHARED.TYPE',
      fieldName: 'type',
      type: CrudViewFormItemType.Select,
      default: PropertyType.String,
      disabled: false,
      settings: {
        searchable: true
      },
      showRequiredSign: true,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ],
      options: ENVIRONMENT_VARIABLE_DATA_TYPES
    },
    <CrudFormInputTemplateItem>{
      title: 'SHARED.VALUE',
      fieldName: 'value',
      type: CrudViewFormItemType.InputTemplate,
      disabled: false,
      showCodemirrorForObjectAndArray: true,
      isInputOptionsVisible: true,
      typeAndFormat: {
        type: PropertyType.String
      }
    },
    {
      title: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.EXPOSE_TO_FRONTEND',
      fieldName: 'exposeToFrontend',
      disabled: false,
      type: CrudViewFormItemType.CheckBox
    },
    {
      title: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.EXPOSE_TO_BACKEND',
      fieldName: 'exposeToBackend',
      disabled: false,
      type: CrudViewFormItemType.CheckBox
    },
    {
      title: 'PROJECT_MODULE.PROJECT_ENVIRONMENT_VARIABLES_COMPONENT.SECURE',
      fieldName: 'isSensitive',
      disabled: false,
      type: CrudViewFormItemType.CheckBox
    }
  ],
  submitButton: { visible: false },
  itemSettings: {
    inputComponentSize: {
      xs: 24
    }
  },
};
