import { Validators } from '@angular/forms';
import { CrudFormConfig, CrudFormStringArrayItem, CrudFormSelectItem, CrudFormTextBoxItem, CrudViewFormItemType, CrudFormTextAreaItem, SelectMode, ButtonType } from '@rappider/rappider-components/utils';
import { PAGE_PATH_REGEX, PASCAL_CASE_REGEX } from '../../definitions/regex';
import { METADATA_EDIT_FORM_CONFIG } from '../metadata/metadata-edit-form-config';

export const PAGE_EDIT_FORM_CONFIG: CrudFormConfig = {
  items: [
    {
      title: 'SHARED.TITLE',
      fieldName: 'title',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    {
      title: 'SHARED.NAME',
      fieldName: 'name',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        },
        {
          type: Validators.pattern(PASCAL_CASE_REGEX),
          errorKey: 'pattern',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.MUST_BE_PASCAL_CASE'
        }
      ]
    },
    <CrudFormTextBoxItem>{
      title: 'PAGES_MODULE.PAGE_CREATE_COMPONENT.PAGE_SLUG',
      fieldName: 'slug',
      type: CrudViewFormItemType.TextBox,
      validators: [
        {
          type: Validators.pattern(PAGE_PATH_REGEX),
          errorKey: 'pattern',
          errorMessage: 'PAGES_MODULE.PAGE_CREATE_COMPONENT.PAGE_SLUG_ERROR'
        }
      ]
    },
    <CrudFormTextAreaItem>{
      title: 'Description',
      fieldName: 'description',
      type: CrudViewFormItemType.TextArea,
      autosize: true
    },
    // MONACO_MARKDOWN_CONFIG,
    <CrudFormSelectItem>
    {
      title: 'PAGES_MODULE.PAGE_LAYOUT_LIST_COMPONENT.PAGE_LAYOUT_LIST',
      fieldName: 'layoutId',
      type: CrudViewFormItemType.Select
    },
    <CrudFormSelectItem>
    {
      title: 'MODULE_MODULE.MODULE',
      fieldName: 'moduleId',
      type: CrudViewFormItemType.Select,
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'ERRORS.PATTERN_MESSAGES.REQUIRED_FIELD_MESSAGE'
        }
      ]
    },
    <CrudFormSelectItem>{
      title: 'Categories',
      fieldName: 'categoryIds',
      type: CrudViewFormItemType.Select,
      settings: {
        mode: SelectMode.Multiple
      }
    },
    <CrudFormTextBoxItem>{
      title: 'Thumbnail Image Url',
      fieldName: 'thumbnailImageUrl',
      type: CrudViewFormItemType.TextBox
    },
    <CrudFormSelectItem>
    {
      title: 'SHARED.AUTHENTICATION_REQUIRED',
      fieldName: 'isAuthenticationRequired',
      type: CrudViewFormItemType.Switch
    },
    <CrudFormSelectItem>
    {
      title: 'SHARED.ACTIVE',
      fieldName: 'isActive',
      type: CrudViewFormItemType.Switch
    },
    <CrudFormStringArrayItem>{
      title: 'SHARED.KEYWORDS',
      fieldName: 'keywords',
      type: CrudViewFormItemType.StringArray,
      orderable: true,
      orderNumbersVisibility: true
    },
    METADATA_EDIT_FORM_CONFIG
  ],
  submitButton: {
    type: ButtonType.Primary
  }
};
