<div class="ui-page-layouts-container">
  <nz-collapse nzGhost
               class="sub-collapse-container">
    <nz-collapse-panel nzHeader="Top Navigation"
                       nzActive="true">
      <div class="ui-page-layout">
        <div class="ui-page-layout-preview">

          <img class="layout-preview-image"
               src="/assets/img/page-layouts/layout-top-nav-01.png"
               alt="UI Page Layout 1" />
        </div>
        <div class="ui-page-layout-description">
          <!-- nz-popover [nzPopoverContent]="extra1"  -->

          <!-- START OF CONTENT  -->

          <h3>Top Navigation Layout</h3>

          <p>The <strong>Top Navigation Layout</strong> is a versatile UI design for web applications that provides a
            clean and intuitive user experience. This layout features:</p>

          <p>This layout is ideal for applications needing a structured navigation system with clearly defined areas for
            content and branding. It's responsive and customizable to fit different application requirements.</p>

          <!-- END OF CONTENT  -->

          <ng-template #extra1>
            <div class="ui-page-layout-extra">
              <ol>
                <li>
                  <p><strong>Header Section</strong>:</p>
                  <ul>
                    <li><strong>Logo Area</strong>: Positioned on the left-hand side for branding, with space for a logo
                      and
                      accompanying text.</li>
                    <li><strong>Navigation Links</strong>: A row of links (e.g., Home, Link 1, Link 2, etc.)
                      horizontally
                      aligned at the center for easy access to key sections of the application.</li>
                    <li><strong>User Account Icon</strong>: Positioned on the far right, serving as a quick access point
                      to
                      user account options.</li>
                  </ul>
                </li>
                <li>
                  <p><strong>Main Content Area</strong>:</p>
                  <ul>
                    <li><strong>Outlet Container</strong>: A central area for dynamic page content. This is where the
                      application renders the main content, ensuring the navigation remains consistent.</li>
                  </ul>
                </li>
                <li>
                  <p><strong>Footer Section</strong>:</p>
                  <ul>
                    <li><strong>Footer Links</strong>: Includes secondary navigation links (e.g., About, Contact, Blog,
                      Library) for supplementary information and site resources.</li>
                    <li><strong>Social Media Icons</strong>: Positioned below the links for quick access to social media
                      profiles.</li>
                    <li><strong>Copyright Notice</strong>: At the bottom, there is space for a copyright message to
                      display
                      ownership and the current year.</li>
                  </ul>
                </li>
              </ol>
            </div>
          </ng-template>


        </div>
      </div>
    </nz-collapse-panel>


    <nz-collapse-panel nzHeader="Left Sidenav"
                       nzActive="true">
      <div class="ui-page-layout">
        <div class="ui-page-layout-preview">

          <img class="layout-preview-image"
               src="/assets/img/page-layouts/layout-left-nav-01.png"
               alt="UI Page Layout 1" />
        </div>
        <div class="ui-page-layout-description">
          <!-- START OF CONTENT  -->

          <h3>Left Side Navigation Layout</h3>
          <p>The <strong>Left Side Navigation Layout</strong> is a structured and highly organized UI design that
            combines a vertical navigation menu with a dynamic content area, making it ideal for dashboard-like
            applications. This layout includes the following key sections:</p>
          <ng-template #extra2>
            <div class="ui-page-layout-extra">
              <ol>
                <li>
                  <p><strong>Header Section</strong>:</p>
                  <ul>
                    <li><strong>Logo Area</strong>: Positioned on the top-left corner, allowing space for a logo and
                      branding.</li>
                    <li><strong>Horizontal Top Navigation</strong>: Includes a row of navigation links (e.g., Home,
                      Services, Contact, Blog, FAQ, Who we are) prominently displayed at the top. This ensures
                      accessibility
                      to key sections of the application.</li>
                    <li><strong>User Account and Cart Icons</strong>: Located on the far-right of the header for quick
                      access to user account details and cart functionality.</li>
                  </ul>
                </li>
                <li>
                  <p><strong>Left Side Navigation (Sidebar)</strong>:</p>
                  <ul>
                    <li><strong>Vertical Menu</strong>: A collapsible menu positioned on the left-hand side, labeled as
                      "Dashboard." It includes key links such as:
                      <ul>
                        <li>Welcome</li>
                        <li>About this course</li>
                        <li>Get a practice course</li>
                        <li>Review, learn and practice</li>
                        <li>Tutorial: Start exploring</li>
                      </ul>
                    </li>
                    <li><strong>Nested Items</strong>: The menu supports a hierarchical structure, allowing categories
                      and
                      subcategories for better organization.</li>
                  </ul>
                </li>
                <li>
                  <p><strong>Main Content Area</strong>:</p>
                  <ul>
                    <li><strong>Outlet Container</strong>: A central area for displaying dynamic page content. It works
                      seamlessly with the sidebar and top navigation to display relevant content based on user
                      interaction.
                    </li>
                  </ul>
                </li>
                <li>
                  <p><strong>Footer Section</strong>:</p>
                  <ul>
                    <li><strong>Footer Links</strong>: Includes additional links (e.g., About, Contact, Blog, Library)
                      for
                      secondary navigation.</li>
                    <li><strong>Social and Download Section</strong>: Space for app download buttons (Google Play and
                      App
                      Store) alongside brief text content, ideal for marketing or informational purposes.</li>
                  </ul>
                </li>
                <li>
                  <p><strong>Additional Branding Area</strong>:</p>
                  <ul>
                    <li>A small footer section in the sidebar includes a logo and placeholder text, which can be used
                      for
                      branding, contact information, or additional details.</li>
                  </ul>
                </li>
              </ol>
            </div>
          </ng-template>

          <!-- END OF CONTENT  -->
        </div>
      </div>
    </nz-collapse-panel>


    <nz-collapse-panel nzHeader="Left Sidenav and Right Section"
                       nzActive="true">
      <div class="ui-page-layout">
        <div class="ui-page-layout-preview">

          <img class="layout-preview-image"
               src="/assets/img/page-layouts/layout-left-nav-01.png"
               alt="UI Page Layout 1" />
        </div>
        <div class="ui-page-layout-description">
          <!-- START OF CONTENT  -->
          <h3>Left and Right Sidebar Layout</h3>
          <p>The <strong>Left and Right Sidebar Layout</strong> is a comprehensive and balanced UI design that features
            dual sidebars, making it ideal for applications that require simultaneous access to navigation and
            additional contextual content. This layout provides a structured and functional design, including the
            following elements:</p>
          <ng-template #extra2>
            <div class="ui-page-layout-extra">
              <h4><strong>Header Section</strong>:</h4>
              <ul>
                <li><strong>Logo Area</strong>: Positioned at the top-left corner for branding and quick recognition.
                </li>
                <li><strong>Horizontal Top Navigation</strong>: A row of navigation links (e.g., Home, Services,
                  Contact,
                  Blog, FAQ, Who we are) is displayed prominently, ensuring users can access primary application
                  sections.
                </li>
                <li><strong>User Account and Cart Icons</strong>: Located on the far-right of the header for user
                  profile
                  management and shopping cart functionality.</li>
              </ul>
              <hr />
              <h4><strong>Left Sidebar (Primary Navigation)</strong>:</h4>
              <ul>
                <li><strong>Vertical Navigation Menu</strong>: Positioned on the left side, labeled as "Dashboard" or
                  other
                  relevant categories.
                  <ul>
                    <li>Includes collapsible or expandable sections with links such as:
                      <ul>
                        <li>Welcome</li>
                        <li>About this course</li>
                        <li>Get a practice course</li>
                        <li>Review, learn and practice</li>
                        <li>Tutorial: Start exploring</li>
                      </ul>
                    </li>
                    <li>Supports nested submenus for a hierarchical navigation structure.</li>
                  </ul>
                </li>
                <li><strong>Branding or Informational Section</strong>: A small area at the bottom of the sidebar for
                  logos,
                  brief text, or additional branding details.</li>
              </ul>
              <hr />
              <h4><strong>Main Content Area</strong>:</h4>
              <ul>
                <li><strong>Central Outlet Container</strong>: A large, dynamic space where the main content of the
                  application is displayed. This container seamlessly integrates with both sidebars, allowing the layout
                  to
                  stay responsive and functional.</li>
              </ul>
              <hr />
              <h4><strong>Right Sidebar (Content Side Area)</strong>:</h4>
              <ul>
                <li><strong>Contextual Information</strong>: The right sidebar provides additional space for secondary
                  or
                  contextual content such as:
                  <ul>
                    <li>User notifications or activity feed</li>
                    <li>Filters or settings for the displayed content</li>
                    <li>Supplementary resources, tips, or help documentation</li>
                  </ul>
                </li>
                <li><strong>Collapsible Design</strong>: The sidebar can be designed to expand or collapse based on user
                  interaction to save space when not in use.</li>
              </ul>
              <hr />
              <h4><strong>Footer Section</strong>:</h4>
              <ul>
                <li><strong>Footer Links</strong>: Includes links such as About, Contact, Blog, and Library for
                  secondary
                  navigation.</li>
                <li><strong>Social Media &amp; App Download Buttons</strong>: Space for social media icons and app store
                  links for further engagement.</li>
                <li><strong>Copyright Section</strong>: A small area for a copyright notice and company information.
                </li>
              </ul>
              <hr />
              <h3>Key Benefits of the Left and Right Sidebar Layout:</h3>
              <ol>
                <li><strong>Dual Focus</strong>: The left sidebar focuses on navigation, while the right sidebar is
                  reserved
                  for supplemental or contextual content.</li>
                <li><strong>Highly Organized</strong>: Ideal for applications requiring a large volume of navigable
                  sections
                  and real-time interaction with secondary content.</li>
                <li><strong>Customizable</strong>: Easily adjusted to fit a variety of use cases, including dashboards,
                  e-learning platforms, e-commerce, or analytics applications.</li>
              </ol>
            </div>
          </ng-template>


          <!-- END OF CONTENT -->
        </div>
      </div>
    </nz-collapse-panel>

    <nz-collapse-panel nzHeader="Blank"
                       nzActive="true">
      <div class="ui-page-layout">
        <div class="ui-page-layout-preview">

          <img class="layout-preview-image"
               src="/assets/img/page-layouts/layout-blank.png"
               alt="UI Page Layout 1" />
        </div>
        <div class="ui-page-layout-description">

          <!-- START OF CONTENT -->
          <h3>Blank Layout Description</h3>
          <p>The <strong>Blank Layout</strong> is a minimalistic and flexible layout designed for maximum customization.
            It provides an empty canvas for building user interfaces, making it suitable for applications that require a
            starting point with no pre-defined structure. This layout includes:</p>

          <ng-template #extra2>
            <div class="ui-page-layout-extra">
              <h4><strong>Main Content Area</strong>:</h4>
              <ul>
                <li><strong>Outlet Container</strong>: A large, central placeholder where the main content or components
                  will be rendered. This container is designed to hold any type of content, such as text, images, forms,
                  or
                  interactive elements.</li>
                <li><strong>Full-Screen Canvas</strong>: The layout prioritizes open space, offering developers and
                  designers the freedom to create custom UI elements without the constraints of pre-built navigation or
                  sidebars.</li>
              </ul>
              <hr />
              <h3>Key Features of the Blank Layout:</h3>
              <ol>
                <li><strong>Unstructured Design</strong>: No predefined navigation, headers, or footers, allowing users
                  to
                  create a completely custom page layout.</li>
                <li><strong>Customizable</strong>: Ideal for building unique and application-specific components or
                  experimenting with new designs.</li>
                <li><strong>Responsive</strong>: The container is flexible and adapts to various screen sizes, ensuring
                  compatibility with both desktop and mobile devices.</li>
              </ol>
            </div>
          </ng-template>




          <!-- END OF CONTENT -->


        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</div>
