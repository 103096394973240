/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FuzzyCreateProjectModelRelationDto } from '../models/fuzzy-create-project-model-relation-dto';
import { GenerateDataModelRelationsFromExampleDataDto } from '../models/generate-data-model-relations-from-example-data-dto';
import { GenerateDataModelRelationsFromExampleDataResponseDto } from '../models/generate-data-model-relations-from-example-data-response-dto';
import { NewProjectModelRelation } from '../models/new-project-model-relation';
import { OpenAiRequestDto } from '../models/open-ai-request-dto';
import { ProjectModelRelation } from '../models/project-model-relation';
import { ProjectModelRelationUpdateDto } from '../models/project-model-relation-update-dto';
import { ProjectModelRelationWithRelations } from '../models/project-model-relation-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';

@Injectable({
  providedIn: 'root',
})
export class ProjectModelRelationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectModelRelationControllerCount
   */
  static readonly ProjectModelRelationControllerCountPath = '/project-model-relations/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: {
    where?: any;
  }): Observable<StrictHttpResponse<LoopbackCount>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelRelationControllerService.ProjectModelRelationControllerCountPath, 'get');
    if (params) {
      rb.query('where', params.where, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoopbackCount>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: {
    where?: any;
  }): Observable<LoopbackCount> {

    return this.count$Response(params).pipe(
      map((r: StrictHttpResponse<LoopbackCount>) => r.body as LoopbackCount)
    );
  }

  /**
   * Path part for operation projectModelRelationControllerCreateFuzzy
   */
  static readonly ProjectModelRelationControllerCreateFuzzyPath = '/project-model-relations/fuzzy-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFuzzy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFuzzy$Response(params?: {
    body?: FuzzyCreateProjectModelRelationDto
  }): Observable<StrictHttpResponse<ProjectModelRelation>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelRelationControllerService.ProjectModelRelationControllerCreateFuzzyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectModelRelation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFuzzy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFuzzy(params?: {
    body?: FuzzyCreateProjectModelRelationDto
  }): Observable<ProjectModelRelation> {

    return this.createFuzzy$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectModelRelation>) => r.body as ProjectModelRelation)
    );
  }

  /**
   * Path part for operation projectModelRelationControllerGenerateByAi
   */
  static readonly ProjectModelRelationControllerGenerateByAiPath = '/project-model-relations/generate-by-ai';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateByAI()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateByAI$Response(params?: {
    body?: OpenAiRequestDto
  }): Observable<StrictHttpResponse<GenerateDataModelRelationsFromExampleDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelRelationControllerService.ProjectModelRelationControllerGenerateByAiPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenerateDataModelRelationsFromExampleDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateByAI$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateByAI(params?: {
    body?: OpenAiRequestDto
  }): Observable<GenerateDataModelRelationsFromExampleDataDto> {

    return this.generateByAI$Response(params).pipe(
      map((r: StrictHttpResponse<GenerateDataModelRelationsFromExampleDataDto>) => r.body as GenerateDataModelRelationsFromExampleDataDto)
    );
  }

  /**
   * Path part for operation projectModelRelationControllerGenerateFromSampleJson
   */
  static readonly ProjectModelRelationControllerGenerateFromSampleJsonPath = '/project-model-relations/generate-from-example-json';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateFromSampleJson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateFromSampleJson$Response(params?: {
    body?: GenerateDataModelRelationsFromExampleDataDto
  }): Observable<StrictHttpResponse<GenerateDataModelRelationsFromExampleDataResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelRelationControllerService.ProjectModelRelationControllerGenerateFromSampleJsonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GenerateDataModelRelationsFromExampleDataResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateFromSampleJson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateFromSampleJson(params?: {
    body?: GenerateDataModelRelationsFromExampleDataDto
  }): Observable<GenerateDataModelRelationsFromExampleDataResponseDto> {

    return this.generateFromSampleJson$Response(params).pipe(
      map((r: StrictHttpResponse<GenerateDataModelRelationsFromExampleDataResponseDto>) => r.body as GenerateDataModelRelationsFromExampleDataResponseDto)
    );
  }

  /**
   * Path part for operation projectModelRelationControllerFindById
   */
  static readonly ProjectModelRelationControllerFindByIdPath = '/project-model-relations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
    filter?: any;
  }): Observable<StrictHttpResponse<ProjectModelRelation>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelRelationControllerService.ProjectModelRelationControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectModelRelation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
    filter?: any;
  }): Observable<ProjectModelRelation> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectModelRelation>) => r.body as ProjectModelRelation)
    );
  }

  /**
   * Path part for operation projectModelRelationControllerDeleteById
   */
  static readonly ProjectModelRelationControllerDeleteByIdPath = '/project-model-relations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelRelationControllerService.ProjectModelRelationControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: {
    id: string;
  }): Observable<any> {

    return this.deleteById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelRelationControllerUpdateById
   */
  static readonly ProjectModelRelationControllerUpdateByIdPath = '/project-model-relations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: {
    id: string;
    body?: ProjectModelRelationUpdateDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelRelationControllerService.ProjectModelRelationControllerUpdateByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: {
    id: string;
    body?: ProjectModelRelationUpdateDto
  }): Observable<any> {

    return this.updateById$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation projectModelRelationControllerFind
   */
  static readonly ProjectModelRelationControllerFindPath = '/project-model-relations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: {
    filter?: any;
  }): Observable<StrictHttpResponse<Array<ProjectModelRelationWithRelations>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelRelationControllerService.ProjectModelRelationControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectModelRelationWithRelations>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: {
    filter?: any;
  }): Observable<Array<ProjectModelRelationWithRelations>> {

    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectModelRelationWithRelations>>) => r.body as Array<ProjectModelRelationWithRelations>)
    );
  }

  /**
   * Path part for operation projectModelRelationControllerCreate
   */
  static readonly ProjectModelRelationControllerCreatePath = '/project-model-relations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: {
    body?: NewProjectModelRelation
  }): Observable<StrictHttpResponse<Array<ProjectModelRelation>>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectModelRelationControllerService.ProjectModelRelationControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProjectModelRelation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: {
    body?: NewProjectModelRelation
  }): Observable<Array<ProjectModelRelation>> {

    return this.create$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProjectModelRelation>>) => r.body as Array<ProjectModelRelation>)
    );
  }

}
