export const checkIsBrowserEnv = () => typeof window !== 'undefined';

export const validateStateKeys = (keys: any[] = []) => keys.map(key => {
    const attr = key;

    if (typeof attr !== 'string') {
      throw new TypeError(
        'localStorageSync Unknown Parameter Type: ' +
        `Expected type of string, got ${typeof attr}`
      );
    }
    return key;
  });
