/* eslint-disable no-shadow */
import { Action } from '@ngrx/store';
import { ProjectExternalScriptInterface } from '@rappider/api-sdk';

export enum ActionTypes {
  GetProjectScripts = '[ProjectScript] GetProjectScripts',
  SetProjectScripts = '[ProjectScript] SetProjectScripts',
  SetLoadingState = '[ProjectScript] SetLoadingState',
  CreateProjectScript = '[ProjectScript] CreateProjectScript',
  CreateProjectScriptSuccessful = '[ProjectScript] CreateProjectScriptSuccessful',
  UpdateProjectScript = '[ProjectScript] UpdateProjectScript',
  DeleteProjectScript = '[ProjectScript] DeleteProjectScript',
  UpdateProjectScriptSuccessful = '[ProjectScript] UpdateProjectScriptSuccessful',
  ErrorAction = '[ProjectScript] ErrorAction'
}

export class GetProjectScripts implements Action {
  readonly type = ActionTypes.GetProjectScripts;
}

export class SetProjectScripts implements Action {
  readonly type = ActionTypes.SetProjectScripts;
  constructor(public payload: { projectScripts: ProjectExternalScriptInterface[] }) { }
}

export class SetLoadingState implements Action {
  readonly type = ActionTypes.SetLoadingState;
  constructor(public payload: { loading: boolean }) { }
}

export class CreateProjectScript implements Action {
  readonly type = ActionTypes.CreateProjectScript;
  constructor(public payload: { projectScript: ProjectExternalScriptInterface }) { }
}

export class CreateProjectScriptSuccessful implements Action {
  readonly type = ActionTypes.CreateProjectScriptSuccessful;
  constructor(public payload: { projectScript: ProjectExternalScriptInterface }) { }
}

export class UpdateProjectScript implements Action {
  readonly type = ActionTypes.UpdateProjectScript;
  constructor(public payload: { id: string; projectScript: ProjectExternalScriptInterface }) { }
}

export class UpdateProjectScriptSuccessful implements Action {
  readonly type = ActionTypes.UpdateProjectScriptSuccessful;
  constructor(public payload: { projectScript: ProjectExternalScriptInterface }) { }
}

export class DeleteProjectScript implements Action {
  readonly type = ActionTypes.DeleteProjectScript;
  constructor(public payload: { projectScriptId: string }) { }
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.ErrorAction;
  constructor(public payload: { error: any; key: string; timestamp: number }) { }
}

export type Actions =
  GetProjectScripts
  | SetProjectScripts
  | SetLoadingState
  | CreateProjectScript
  | CreateProjectScriptSuccessful
  | UpdateProjectScript
  | UpdateProjectScriptSuccessful
  | DeleteProjectScript
  | ErrorAction
  ;
