import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

/* 3rd party modules */
import { DxDiagramModule } from 'devextreme-angular';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { TranslateModule } from '@ngx-translate/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTableModule } from 'ng-zorro-antd/table';

/* rappider modules */
import { DataEventsModule } from 'libs/project/src/lib/modules/data-events/data-events.module';
import { DataEventsWithoutRoutesModule } from 'libs/project/src/lib/modules/data-events/data-events.without-route.module';
import { RappiderButtonModule, RappiderCardsModule, RappiderDividerModule, RappiderDropdownMenuModule, RappiderHeadingModule, RappiderIconModule, RappiderInputGroupModule, RappiderLabelModule, RappiderLoadingNotificationModule, RappiderMenuModule, RappiderModalModule, RappiderSelectModule, RappiderSpinModule, RappiderTextboxModule } from '@rappider/rappider-components';
import { UIStepFunctionModule } from 'libs/project/src/lib/modules/ui-step-function/ui-step-function.module';
import { CommentModule } from '@rappider/comment';

/* components */
import { DiagramSettingsComponent } from './components/diagram-settings/diagram-settings.component';
import { DiagramEditorComponent } from './components/diagram-editor/diagram-editor.component';
import { DiagramEditorToolbarComponent } from './components/diagram-editor-toolbar/diagram-editor-toolbar.component';
import { DiagramWorkflowMenuComponent } from './components/diagram-workflow-menu/diagram-workflow-menu.component';
import { DiagramEditorWrapperComponent } from './components/diagram-editor-wrapper/diagram-editor-wrapper.component';
import { DataTransformationModule } from '@rappider/data-transformation';
import { CardSelectorModule } from 'libs/components/src/lib/components/card-selector/card-selector.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DxDiagramModule,
    UIStepFunctionModule,
    NzModalModule,
    RappiderMenuModule,
    RappiderTextboxModule,
    RappiderButtonModule,
    RappiderDropdownMenuModule,
    RappiderDividerModule,
    RappiderLabelModule,
    NzDropDownModule,
    RappiderInputGroupModule,
    RappiderLabelModule,
    RappiderSpinModule,
    NzToolTipModule,
    TranslateModule,
    NzSpinModule,
    RappiderIconModule,
    RappiderModalModule,
    DataTransformationModule,
    RappiderLoadingNotificationModule,
    NzTabsModule,
    CommentModule,
    CardSelectorModule,
    RappiderSelectModule,
    RappiderCardsModule,
    NzTableModule,
    RappiderHeadingModule,
    DataEventsWithoutRoutesModule
    // DataEventsModule
  ],
  declarations: [
    DiagramSettingsComponent,
    DiagramEditorComponent,
    DiagramEditorToolbarComponent,
    DiagramWorkflowMenuComponent,
    DiagramEditorWrapperComponent
  ],
  exports: [
    DiagramSettingsComponent,
    DiagramEditorComponent,
    DiagramEditorToolbarComponent,
    DiagramWorkflowMenuComponent,
    DiagramEditorWrapperComponent
  ]
})
export class DiagramEditorForComponentsModule { }
