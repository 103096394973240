import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {
  getAllDataFromLocalForage,
  default as localForage,
} from './app/state/persist/localforage-extended';


/* CODE MIRROR EXTENSIONS */

/* Code Mirror Modes */
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';

/* Code Mirror Addons */
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/edit/closebrackets';

if (environment.production) {
  enableProdMode();
}

export const STORE_KEYS_TO_PERSIST = [
  'app',
  'category',
  'componentDefinition',
  'project',
  'activeProject',
  'environmentVariable',
  'projectRole',
  'messageTemplate',
  'projectSetting',
  'projectMember',
  'projectVersion',
  'projectExport',
  'userInvitation',
  'projectTheme',
  'uiDataStore',
  'uiDataEvent'
];

getAllDataFromLocalForage({
  driver: localForage.INDEXEDDB,
  keys: STORE_KEYS_TO_PERSIST,
}).then(() => {
  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
});
