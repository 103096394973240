import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as ProjectCodeGenerationActions from './project-code-generation.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

import { NotificationService } from '@rappider/services';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';

/* DataStorage interface for ngrx */

import { ProjectCodeGeneration } from '@rappider/rappider-sdk';

/* Named import */
import { ProjectCodeGenerationControllerService as ApiService } from '@rappider/rappider-sdk';
import * as ActiveProjectActions from 'libs/project/src/lib/states/active-project-state/active-project.actions';

export const navigatePathAfterCreatingInstance = '';
export const navigatePathAfterUpdatingInstance = '';

@Injectable()
export class ProjectCodeGenerationEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<any>,
    private notificationService: NotificationService
  ) { }

  getProjectCodeGenerations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectCodeGenerationActions.GetProjectCodeGenerations),
      withLatestFrom(
        this.store.select(state => state.activeProject?.data?.id)
      ),
      mergeMap(([action, activeProjectId]) => {

        /* fail if there is no active project */
        if (!activeProjectId) {
          return [
            ProjectCodeGenerationActions.GetProjectCodeGenerationsFailure()
          ];
        }
        /* set a filter */
        const filter = {};
        /* get the ProjectCodeGenerations */
        return this.apiService.find({ filter }).pipe(
          mergeMap(projectCodeGenerations => {
            return ([
              ProjectCodeGenerationActions.GetProjectCodeGenerationsSuccessful({ payload: { projectCodeGenerations: projectCodeGenerations } }),
            ])
          }),
          catchError((error) => [
            ProjectCodeGenerationActions.GetProjectCodeGenerationsFailure()
          ])
        );
      })
    )
  );

  /* Old actions can not listen on new reducers. So we use this effect temp. */
  resetStateToInitial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.Logout>(AuthenticationActions.ActionTypes.Logout),
      mergeMap(() => [
        ProjectCodeGenerationActions.ResetStateToInitial()
      ])
    )
  );

  resetStateToInitialChangeProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationActions.UpdateAuthenticationTokenWithProjectIdSuccessful>
        (AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful),
      mergeMap(() => [
        ProjectCodeGenerationActions.ResetStateToInitial()
      ])
    )
  );
}
