import { PageTemplatesModule } from 'libs/page-templates/src/lib/page-templates.module';
import { ThemeModule } from 'libs/theme/src/lib/theme.module';
/* Angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Material Modules */
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';

/* State */
import { ContentTreeRendererModule } from '@rappider/content-tree-renderer';
/* Modules */
import { TranslateModule } from '@ngx-translate/core';

/* ngzorro */
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

/* Services */
import { PageApi } from '@rappider/api-sdk';

/* Internal Modules */
import { UIDataUpdateFunctionModule } from 'libs/project/src/lib/modules/ui-data-update-function/ui-data-update-function.module';
import {
  RappiderCodemirrorInputFormModule,
  RappiderDropdownMenuModule,
  RappiderHeadingModule,
  RappiderInputGroupModule,
  RappiderLabelModule,
  RappiderLottiePlayerModule,
  RappiderModalModule,
  RappiderSpinModule,
  RappiderTextareaModule,
  RappiderTextModule,
  RappiderMenuModule,
  RappiderSelectModule,
  RappiderButtonModule,
  RappiderEditFormModule,
  RappiderTextboxModule,
  RappiderRadioGroupModule,
  RappiderCheckboxModule,
  RappiderSliderModule,
  RappiderLoadingNotificationModule,
  RappiderDataBinderModule,
  RappiderMonacoEditorModule,
  RappiderRowFormModule,
  RappiderSwitchModule,
  RappiderListGridModule,
  RappiderCardsModule
} from '@rappider/rappider-components';
import { DataTransformationModule } from '@rappider/data-transformation';
import { ProjectComponentsModule } from 'libs/project/src/lib/project-components.module';
import { PagesModule } from '@rappider/pages';
import { CommentModule } from '@rappider/comment';
import { UiDataSelectorModule } from '@rappider/ui-data-selector';
/* Component */
import { ContentEditorComponent } from './layouts/content-editor/content-editor.page.component';
import { ComponentSettingsComponent } from './components/component-settings/component-settings.component';
import { ComponentEventComponent } from './components/component-event/component-event.component';
import { ContainerSettingsComponent } from './components/container-settings/container-settings.component';
import { ContainerLayoutSettingsComponent } from './components/container-settings/container-layout-settings/container-layout-settings.component';
import { ComponentInputVariationSelectComponent } from './components/component-input-variation-select/component-input-variation-select.component';
import { FilterFindModule } from 'libs/data-transformation/src/lib/modules/filter-find/filter-find.module';
import { ComponentMenuComponent } from './layouts/content-editor-left-sidebar-menu/component-menu/component-menu.component';
import { ContentTreeComponent } from './layouts/content-editor-left-sidebar-menu/content-tree/content-tree.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AddComponentModalComponent } from './components/modals/add-component-modal/add-component-modal.component';
import { LayoutMenuComponent } from './layouts/content-editor-left-sidebar-menu/layout-menu/layout-menu.component';
import { PageMenuComponent } from './layouts/content-editor-left-sidebar-menu/page-menu/page-menu.component';
import { PageStyleCustomizationComponent } from './components/page-style-customization/page-style-customization.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { CardSelectorModule } from 'libs/components/src/lib/components/card-selector/card-selector.module';
import { DataSubscriptionModule } from '@rappider/data-subscription';
import { DeletePageContainerComponent } from './components/delete-page-container/delete-page-container.component';
import { ElementMenuComponent } from './layouts/content-editor-left-sidebar-menu/element-menu/element-menu.component';
import { AddElementModalComponent } from './components/modals/add-element-modal/add-element-modal.component';
import { ContentEditorRightSidebarMenuComponent } from './layouts/content-editor-left-sidebar-menu/content-editor-right-sidebar-menu.component';
import { VisibilityConditionComponent } from './components/visibility-condition/visibility-condition.component';
import { AiContentConfigModalComponent } from './components/ai-content-config-modal/ai-content-config-modal.component';
import { PageLifeCycleComponent } from './components/page-lifecyle/page-lifecyle.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { PageVariablesComponent } from './components/page-variables/page-variables.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { AngularSplitModule } from 'angular-split';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzSkeletonModule,
    NzDropDownModule,
    NzButtonModule,
    NzToolTipModule,
    NzTabsModule,
    NzAlertModule,
    NzCardModule,
    NzTableModule,
    NzPaginationModule,
    NzPopconfirmModule,
    RappiderModalModule,
    NzCollapseModule,
    NzLayoutModule,
    NzSelectModule,
    NzDividerModule,
    NzTreeModule,
    NzTagModule,
    NzIconModule,
    NzEmptyModule,
    FormsModule,
    DragDropModule,
    MatIconModule,
    MatTreeModule,
    TranslateModule,
    UIDataUpdateFunctionModule,
    RappiderMenuModule,
    RappiderSelectModule,
    RappiderButtonModule,
    RappiderEditFormModule,
    RappiderTextboxModule,
    RappiderRadioGroupModule,
    RappiderCheckboxModule,
    RappiderSliderModule,
    DataTransformationModule,
    RappiderHeadingModule,
    NzPopoverModule,
    FilterFindModule,
    RappiderTextModule,
    RappiderInputGroupModule,
    RappiderLottiePlayerModule,
    NzInputModule,
    NzToolTipModule,
    ProjectComponentsModule,
    RappiderCodemirrorInputFormModule,
    RappiderSpinModule,
    PagesModule,
    RappiderDropdownMenuModule,
    RappiderLabelModule,
    RappiderTextareaModule,
    NzSpinModule,
    RappiderLoadingNotificationModule,
    NzBreadCrumbModule,
    RappiderDataBinderModule,
    CommentModule,
    ContentTreeRendererModule,
    CardSelectorModule,
    DataSubscriptionModule,
    NzEmptyModule,
    RappiderMonacoEditorModule,
    NzModalModule,
    ThemeModule,
    PageTemplatesModule,
    RappiderRowFormModule,
    NzFormModule,
    RappiderSwitchModule,
    UiDataSelectorModule,
    RappiderListGridModule,
    AngularSplitModule,
    NzDrawerModule,
    RappiderCardsModule
  ],
  providers: [
    PageApi
  ],
  declarations: [
    ContentEditorComponent,
    ComponentSettingsComponent,
    ContentEditorComponent,
    ComponentEventComponent,
    ContainerSettingsComponent,
    ContainerLayoutSettingsComponent,
    ComponentInputVariationSelectComponent,
    ContentEditorRightSidebarMenuComponent,
    ComponentMenuComponent,
    ContentTreeComponent,
    AddComponentModalComponent,
    LayoutMenuComponent,
    PageMenuComponent,
    PageStyleCustomizationComponent,
    DeletePageContainerComponent,
    ElementMenuComponent,
    AddElementModalComponent,
    VisibilityConditionComponent,
    AiContentConfigModalComponent,
    PageLifeCycleComponent,
    PageVariablesComponent
  ],
  exports: [
    ElementMenuComponent,
  ]
})
export class ContentEditorComponentsModule { }
