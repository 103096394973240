/* angular */
import { createAction, createReducer, on } from '@ngrx/store';
import { CustomFunctionDefinition, CustomFunctionDefinitionPartial, CustomFunctionDefinitionWithRelations } from '@rappider/rappider-sdk';

/* actions */
import * as CustomFunctionActions from './custom-function.actions';
import * as WorkflowStepFunctionActions from 'libs/project/src/lib/states/workflow-step-function/workflow-step-function.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'customFunction';

/* state interface */
export interface CustomFunctionState {
  data: CustomFunctionDefinition[] | null;
  lastCreatedOrEdited: CustomFunctionDefinition;
  templateWorkflowFunctions: CustomFunctionDefinition[];
  error: {
    error: any;
    key: string;
    timestamp: number;
  } | null;
  isLoading: boolean;
  isWorkflowFunctionsLoaded: boolean;
  isTemplateWorkflowFunctionsLoaded: boolean;
}

/* initial values */
export const initialState: CustomFunctionState = {
  data: [],
  lastCreatedOrEdited: null,
  templateWorkflowFunctions: [],
  error: null,
  isLoading: false,
  isWorkflowFunctionsLoaded: false,
  isTemplateWorkflowFunctionsLoaded: false
};

function updateCustomFunctions(updatedCustomFunctionId: string, updatedCustomFunctionData: CustomFunctionDefinitionPartial, allCustomFunctions: CustomFunctionDefinition[]): CustomFunctionDefinition[] {
  if (allCustomFunctions?.length) {
    const updatedCustomFunction: CustomFunctionDefinition = allCustomFunctions?.find(CustomFunction => CustomFunction.id === updatedCustomFunctionId) || <CustomFunctionDefinition>{};
    const updatedCustomFunctionWithNewData = <CustomFunctionDefinition>{ ...updatedCustomFunction, ...updatedCustomFunctionData };
    const updatedCustomFunctions = allCustomFunctions.map(customFunction =>
      customFunction.id === updatedCustomFunctionId ?
      updatedCustomFunctionWithNewData : customFunction
    );
    return updatedCustomFunctions;
  } else {
    return <CustomFunctionDefinition[]>[];
  }
}

function updateCustomFunction(updatedCustomFunctionId: string, updatedCustomFunctionData: CustomFunctionDefinitionPartial, allCustomFunctions: CustomFunctionDefinition[]): CustomFunctionDefinition {
  const updatedCustomFunction: CustomFunctionDefinition = allCustomFunctions?.find(CustomFunction => CustomFunction.id === updatedCustomFunctionId) || <CustomFunctionDefinition>{};
  const updatedCustomFunctionWithNewData = <CustomFunctionDefinition>{ ...updatedCustomFunction, ...updatedCustomFunctionData };
  return updatedCustomFunctionWithNewData;
}


const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(CustomFunctionActions.GetCustomFunctions, (state, action) => ({
    ...state,
    isLoading: true,
    isWorkflowFunctionsLoaded: false
  })),
  on(CustomFunctionActions.GetCustomFunctionsSuccessful, (state, action) => ({
    ...state,
    data: action.customFunctions,
    isLoading: false,
    isWorkflowFunctionsLoaded: true
  })),
  on(CustomFunctionActions.GetCustomFunctionsFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timestamp: action.timestamp
    },
    isLoading: false,
    isWorkflowFunctionsLoaded: true
  })),

  /* Get Custom Function By Id */
  on(CustomFunctionActions.GetCustomFunctionById, (state) => ({
    ...state,
    isLoading: true,
    isWorkflowFunctionsLoaded: false
  })),
  on(CustomFunctionActions.GetCustomFunctionByIdSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      action.customFunction
    ],
    isLoading: false,
    isWorkflowFunctionsLoaded: true
  })),
  on(CustomFunctionActions.GetCustomFunctionsFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timestamp: action.timestamp
    },
    isLoading: false,
    isWorkflowFunctionsLoaded: true
  })),

  on(CustomFunctionActions.GetRappiderCustomFunctions, (state) => ({
    ...state,
    isLoading: true,
    isTemplateWorkflowFunctionsLoaded: false
  })),
  on(CustomFunctionActions.GetRappiderCustomFunctionsSuccessful, (state, action) => ({
    ...state,
    templateWorkflowFunctions: action.payload.customFunctions,
    isLoading: false,
    isTemplateWorkflowFunctionsLoaded: true
  })),
  on(CustomFunctionActions.GetRappiderCustomFunctionsFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false,
    isTemplateWorkflowFunctionsLoaded: true
  })),

  on(CustomFunctionActions.CreateCustomFunction, (state) => ({
    ...state,
    isLoading: true
  })),
  on(CustomFunctionActions.CreateCustomFunctionSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data ?? [],
      action.customFunction
    ],
    lastCreatedOrEdited: action.lastCreatedOrEdited,
    isLoading: false
  })),
  on(CustomFunctionActions.CreateCustomFunctionFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timestamp: action.timestamp
    },
    isLoading: false
  })),

  on(CustomFunctionActions.UpdateCustomFunction, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(CustomFunctionActions.UpdateCustomFunctionSuccessful, (state, action) => ({
    ...state,
    data: updateCustomFunctions(action.id, action.customFunction, state.data ?? []),
    lastCreatedOrEdited: updateCustomFunction(action.id, action.customFunction, state.data ?? []),
    isLoading: false
  })),
  on(CustomFunctionActions.UpdateCustomFunctionFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timestamp: action.timestamp
    },
    isLoading: false
  })),

  on(CustomFunctionActions.DeleteCustomFunction, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(CustomFunctionActions.DeleteCustomFunctionSuccessful, (state, action) => ({
    ...state,
    data: state?.data?.filter(item => action.id !== item.id) ?? [],
    isLoading: false
  })),
  on(CustomFunctionActions.DeleteCustomFunctionFailure, (state, action) => ({
    ...state,
    error: {
      error: action.error,
      key: action.key,
      timestamp: action.timestamp
    },
    isLoading: false
  })),

  on(UpdateAuthenticationTokenWithProjectIdSuccessful, (state) => ({
    ...initialState,
    templateWorkflowFunctions: state.templateWorkflowFunctions,
    isTemplateWorkflowFunctionsLoaded: state.isTemplateWorkflowFunctionsLoaded
  })),

  on(Logout, () => (initialState))
);
