import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { ActionTypes } from 'libs/authentication/src/lib/state/authentication.actions';

import { initialState } from './app.reducer';
import { AppState } from './app.reducer';

export const GET_SUCCESS_ACTION_REGEX = /^\[(?<storeKey>[^\]]+)] Get(?<actionName>.+)Successful$/;

// export function onActionMetaReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
export function onActionMetaReducer(reducer: any): any {
  return (state, action: Action) => {
    // Check if the action is Logout; if so, return the initial state
    if (action.type === ActionTypes.Logout) {
      // Return the initial state when Logout action is dispatched
      console.log('Logout action dispatched');
      // return initialState;
    } else if (action.type === ActionTypes.Login) {
      console.log('Login action dispatched');
    }
    // You can enable action log to console by setting logActionsToConsole to true in the state
    // or by setting window['logActionsToConsole'] = true while debugging runtime
    if (state?.app?.logActionsToConsole || window['logActionsToConsole']) {
      console.log('## Action Log ---------------------------------');
      console.log(action);
    }

    // Return the current state for all other actions
    return reducer(state, action);
  };
}
