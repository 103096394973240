import { createSelector } from '@ngrx/store';
import { DataSchemaWithRelations, ProjectModelField, ProjectModelWithRelations } from '@rappider/rappider-sdk';
import { orderBy } from 'lodash';
import { getDataSchemasWithDetailsSelector } from '../../../states/selectors/get-data-schemas-with-details.selector';
import {
  selectProjectModels,
  selectIsProjectModelsLoaded
} from '../../../states/project-model-state/project-model.selectors';
import {
  selectAllProjectModelFields,
  selectIsLoaded as selectProjectModelFieldsLoaded
} from 'libs/project-model-field/src/lib/state/project-model-field.selectors';
import { selectIsDataSchemaLoaded } from '../../../states/data-schema/data-schema.selectors';

export const getProjectModelsWithFieldsAndSchemas = createSelector(
  selectProjectModels,
  selectAllProjectModelFields,
  selectProjectModelFieldsLoaded,
  selectIsProjectModelsLoaded,
  getDataSchemasWithDetailsSelector,
  selectIsDataSchemaLoaded,
  (
    projectModels: ProjectModelWithRelations[],
    projectModelFields: ProjectModelField[],
    isProjectModelsLoaded: boolean,
    isProjectModelFieldsLoaded: boolean,
    dataSchemas: DataSchemaWithRelations[],
    isDataSchemasLoaded: boolean
  ) => {
    if (isProjectModelFieldsLoaded && isProjectModelsLoaded && isDataSchemasLoaded) {
      const projectModelWithFields = projectModels.map(projectModel => (
        {
          ...projectModel,
          fields: orderBy(projectModelFields.filter(projectModelField => projectModelField.projectModelId === projectModel.id), 'index', 'asc')
        }
      ));
      const projectModelWithFieldsAndSchemas = {
        projectModels: projectModelWithFields,
        dataSchemas: dataSchemas
      };
      return projectModelWithFieldsAndSchemas;
    }
  }
);
