import { createReducer, on, createAction } from '@ngrx/store';
import { WorkflowStepFunctionPublishedEventOnSuccessWithRelations } from '@rappider/rappider-sdk';

import * as WorkflowStepFunctionPublishedEventOnSuccessActions from './workflow-step-function-published-event-on-success.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

/* state key */
export const featureKey = 'workflowStepFunctionPublishedEventOnSuccess';

/* state interface */
export interface WorkflowStepFunctionPublishedEventOnSuccessState {
  data: WorkflowStepFunctionPublishedEventOnSuccessWithRelations[];
  isLoading: boolean;
  error: any;
  isDataFetched: boolean;
}

/* initial values */
export const initialState: WorkflowStepFunctionPublishedEventOnSuccessState = {
  data: [],
  isLoading: false,
  error: null,
  isDataFetched: false
};

const UpdateAuthenticationTokenWithProjectIdSuccessful = createAction(AuthenticationActions.ActionTypes.UpdateAuthenticationTokenWithProjectIdSuccessful);
const Logout = createAction(AuthenticationActions.ActionTypes.Logout);

export const reducer = createReducer(
  initialState,
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.CreateWorkflowStepFunctionPublishedEventOnSuccess, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.CreateWorkflowStepFunctionPublishedEventOnSuccessSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      action.payload.workflowStepFunctionPublishedEventOnSuccess
    ],
    isLoading: false,
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.AddWorkflowStepFunctionPublishedEventOnSuccessSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data,
      ...action.payload.workflowStepFunctionPublishedEventOnSuccess
    ],
    isLoading: false,
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.CreateWorkflowStepFunctionPublishedEventOnSuccessFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.GetWorkflowStepFunctionPublishedEventOnSuccess, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.GetWorkflowStepFunctionPublishedEventOnSuccessSuccessful, (state, action) => ({
    ...state,
    data: action.payload.workflowStepFunctionPublishedEventOnSuccesss,
    isLoading: false,
    isDataFetched: true
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.UpdateWorkflowStepFunctionPublishedEventOnSuccess, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.UpdateWorkflowStepFunctionPublishedEventOnSuccessSuccessful, (state, action) => ({
    ...state,
    data: [
      ...state.data.filter(workflowStepFunctionPublishedEventOnSuccess => workflowStepFunctionPublishedEventOnSuccess.id !== action.payload.workflowStepFunctionPublishedEventOnSuccessBody.id),
      action.payload.workflowStepFunctionPublishedEventOnSuccessBody
    ],
    isLoading: false
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.UpdateWorkflowStepFunctionPublishedEventOnSuccessFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.DeleteWorkflowStepFunctionPublishedEventOnSuccess, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.DeleteWorkflowStepFunctionPublishedEventOnSuccessSuccessful, (state, action) => ({
    ...state,
    data: state.data?.filter(workflowStepFunctionPublishedEventOnSuccess => workflowStepFunctionPublishedEventOnSuccess.id !== action.payload.workflowStepFunctionPublishedEventOnSuccessId),
    isLoading: false
  })),
  on(WorkflowStepFunctionPublishedEventOnSuccessActions.DeleteWorkflowStepFunctionPublishedEventOnSuccessFailure, (state, action) => ({
    ...state,
    error: {
      error: action.payload.error,
      key: action.payload.key,
      timestamp: action.payload.timestamp
    },
    isLoading: false
  })),
  on(UpdateAuthenticationTokenWithProjectIdSuccessful, () => (initialState)),
  on(Logout, () => (initialState))
);
