/* eslint-disable max-len */
// #region Imports
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ElementRef, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';

import { of, Subscription, delay, Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';


// Selectors
import { selectActiveProject } from 'libs/project/src/lib/states/active-project-state/active-project.selectors';


// Models
import {
  DataSchemaWithRelations, ImproveProjectDescriptionByAiResponseDto, Project,
  ProjectControllerService, ProjectModelField,
  ProjectModelRelation, ProjectModelWithRelations,
  ScopeOfWork,
  PageWithRelations,
  ProjectTheme,
  ComponentDefinitionWithRelations,
  UiDataStore,
  Page,
  MessageTemplate,
  User,
  Person,
  Tenant,
  ModuleWithRelations,
  ClientLocalState
} from '@rappider/rappider-sdk';


// Enums
import { PostItColor, PostItSize } from 'libs/components/src/lib/components/post-it/post-it.module';

// Configs
import {
  ProjectDesignSettingButtons
} from './configs/action-button-config';
import { SetAsActiveProjectTheme, UpdateProject } from 'libs/project/src/lib/states/projects-state/project.actions';

import { selectIsProjectModelsLoaded, selectProjectModels } from 'libs/project/src/lib/states/project-model-state/project-model.selectors';
import { selectProjectModelRelations } from 'libs/project/src/lib/states/project-model-relation-state/project-model-relation.selectors';
import { selectMessageTemplates } from 'libs/project/src/lib/states/message-template-state/message-template.selectors';
import { selectUser } from 'libs/authentication/src/lib/state/authentication.selectors';
import { selectClientLocalStateLoaded, selectClientLocalStates } from 'libs/shared/src/lib/states/client-local-state/client-local-state.selectors';

import { Deploy, GenerateCode, GetProjectSourceCode, GetProjectSourceFileCustomCodes, GetProjectSourceFiles } from 'libs/project-source-code/src/lib/project-source-code-data-source/project-source-code.actions';

import { ActionResponse, CardOneComponentConfig, CrudFormMonacoCodeEditorItem, HeadingType, IconType, ItemPerRow, NgZorroIconTheme, SelectableOption, ButtonSize, TimelineItem, CardOneListCardClickOutput } from '@rappider/rappider-components/utils';
import {
  DeleteProjectModelField
} from 'libs/project/src/lib/states/project-model-state/project-model.actions';
import { selectAllProjectModelFields } from 'libs/project-model-field/src/lib/state/project-model-field.selectors';
import { AIProgressStatus, WIZARD_STEPS_CONFIG, WizardStepConfig, WizardSteps, WizardStepStatus } from './configs/wizard-steps';

import { NotificationService } from 'libs/components/src/lib/services';
import { cloneDeep, orderBy } from 'lodash';
import { COLLAPSE_PANEL_CONFIGS, CollapsePanelConfig, CollapsePanels } from './configs/collapse-panel-config';
import { projectDescriptionStepCompletedContent } from './configs/wizard-steps-content';


import { getComponentDefinitionsWithDetailsSelector, Navigate } from '@rappider/shared';
import { ContentTree } from 'libs/content-tree-renderer/src/lib/models';

import { PROJECT_HOME_FEATURES_CONFIG } from '@rappider/shared/configs';
import { FullPaths, PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { MESSAGE_TEMPLATES_EXPLANATION, UI_BUSINESS_PROCESSES_EXPLANATION } from './configs/content';
import { pageCardsConfig, layoutCardsConfig, messageTemplatesCardsConfig, themeCardsConfig } from './configs/cards-config';
import { ChangeDefaultLayout } from 'libs/pages/src/lib/state/layout-state/layout.actions';
import { NavigatePages } from './configs/navigate-pages.enum';
import { ProjectDesignService } from './services/project-design.service';
import { CreateClientLocalState } from 'libs/shared/src/lib/states/client-local-state/client-local-state.actions';

import { ClientLocalStateKeys } from 'libs/shared/src/lib/states/client-local-state/client-local-state.interface';
import { TimelineItemColor } from 'libs/components/src/lib/utils/timeline/timeline-item-color.enum';
import { NextStep } from './state/project-design-task/project-design-task.actions';
import { FocusToRelatedModelId } from 'libs/db-diagram/src/lib/state/db-diagram.actions';
import { LayoutMode } from './configs/layoutMode.enum';
import { DeployProject } from 'libs/project/src/lib/states/deploy-management/deploy-management.actions';
import { DeployStatus } from 'libs/project/src/lib/components/live-preview/utils/deploy-status.enum';

const autoCollapsedPanels = [
  CollapsePanels.ProjectDescription,
  CollapsePanels.ProjectInformation,
  CollapsePanels.ProjectMembers,
  CollapsePanels.ScopeOfWork,
  CollapsePanels.DataModelsAndDatabase,
  CollapsePanels.APIEndpoints,
  CollapsePanels.UIPages,
  CollapsePanels.UIPageLayouts,
  CollapsePanels.Theme,
  CollapsePanels.DesignSystem,
  CollapsePanels.UIBusinessProcesses,
  CollapsePanels.MessageTemplates,
  CollapsePanels.ServerSideBusinessProcesses,
  CollapsePanels.SettingsVariablesAndEnvironments,
  CollapsePanels.GenerateProjectCode,
  // CollapsePanels.VersionsAndDeployments
];

// #endregion
@Component({
  selector: 'rappider-project-design',
  templateUrl: './project-design.smart.component.html',
  styleUrls: ['./project-design.smart.component.scss'],
})
export class ProjectDesignComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() displayWizardOnIntroduction? = false;
  @Input() isLoading? = false;
  @Input() wizardStepsConfigs: WizardStepConfig[] = [];
  @Input() activeWizardStep?: number | undefined = undefined;
  @Input() displayWizard?: boolean = true;

  @Output() collapseButtonClicked = new EventEmitter<string>();
  @Output() layoutModeChanged = new EventEmitter<string>();
  @Output() displayCodePanelClicked = new EventEmitter<string>();

  @ViewChild('wizardContainer', { static: false }) wizardContainer!: ElementRef;

  // #region Variables

  @ViewChild('stepNotStarted', { static: true }) stepNotStarted!: TemplateRef<any>;
  @ViewChild('stepInProgress', { static: true }) stepInProgress!: TemplateRef<any>;
  @ViewChild('stepCompleted', { static: true }) stepCompleted!: TemplateRef<any>;
  @ViewChild('stepError', { static: true }) stepError!: TemplateRef<any>;

  subscriptions: Subscription[] = [];
  // set the flag to true when all subscriptions are populated
  allSubscriptionsPopulated = false;

  latestRenderedStep: number | undefined = 0;
  latestRenderedStatus: WizardStepStatus | undefined = WizardStepStatus.NotStarted;

  // projectDesignTasks: ProjectDesignTask[] = [];

  collapsePanelConfigs: CollapsePanelConfig = cloneDeep(COLLAPSE_PANEL_CONFIGS);

  // subscription based entity data
  activeProject: Project | undefined;

  UI_BUSINESS_PROCESSES_EXPLANATION = UI_BUSINESS_PROCESSES_EXPLANATION;
  MESSAGE_TEMPLATES_EXPLANATION = MESSAGE_TEMPLATES_EXPLANATION;

  clickedLayoutCardId: string | undefined;
  clickedThemeCardId: string | undefined;

  scopeOfWorks: ScopeOfWork[] = [];
  scopeOfWorkStatuses: any = {};
  projectModels: ProjectModelWithRelations[] = [];
  projectModelRelations: ProjectModelRelation[] | undefined;
  projectModelFields: ProjectModelField[] | undefined;
  dataSchemas: DataSchemaWithRelations[] | undefined;
  messageTemplates: any[] | undefined;
  projectVersions: any[] | undefined;
  projectVersionsLength: number | undefined;
  contentTree: ContentTree | undefined;
  componentDefinitions: ComponentDefinitionWithRelations[] | undefined;
  uiDataStores: UiDataStore[] | undefined;
  page: PageWithRelations | undefined;
  layout: Page | undefined;
  projectTheme: ProjectTheme | any;
  pages: Page[] | undefined;
  layouts: Page[] | undefined;
  projectThemes: ProjectTheme[] | undefined;
  uiPageCards: CardOneComponentConfig[] | undefined = [];
  activeLayout: PageWithRelations | null | undefined;
  user: User | undefined;
  person: Person | undefined;
  tenant: Tenant | undefined;

  projectModelsLoaded = false;

  grouppedCards: SelectableOption[] | undefined;
  modules: ModuleWithRelations[] | undefined;
  previousLayoutId: string | undefined;
  previousThemeId: string | undefined;

  latestProjectVersionId: string | undefined;

  // imported enums and constants
  CollapsePanels = CollapsePanels;
  WizardStepStatus = WizardStepStatus;
  WizardSteps = WizardSteps;
  AIProgressStatus = AIProgressStatus;
  PostItColor = PostItColor;
  PostItSize = PostItSize;
  ProjectDesignSettingButtons = ProjectDesignSettingButtons;
  ItemPerRow = ItemPerRow;


  pageCardsConfig = cloneDeep(pageCardsConfig);
  layoutCardsConfig = cloneDeep(layoutCardsConfig);
  themeCardsConfig = cloneDeep(themeCardsConfig);
  messageTemplatesCardsConfig = cloneDeep(messageTemplatesCardsConfig);

  // others

  rapiderAiWizardVisible = true;
  // shows a footer when the wizard is outside the viewport
  showWizardOnBluePrintFooter = true;

  projectDescriptionEditMode = false;

  /* active - visible collapse panel keys as string array */
  visiblePanelKeys: string[] = [];

  // next button is disabled when the user is not allowed to navigate to the next step
  // disableNextButton = false;

  /* True; If all collapse panels are opened - active */
  allPanelsActive = false;

  /* Sets as true/false when the crud emits the status of the collapse */
  projectInformationCollapseActive = true;

  /* Passes to user invitation component to show invitation modal  */
  showInvitationModal = '';

  /* variables to show models with a delayed animation */
  displayModelFieldsWithAnimation = false;

  // menu items for low-code customization pages
  projectMegaMenuFeatures = PROJECT_HOME_FEATURES_CONFIG;
  megaMenuVisible = false;

  // record set for the sub collapse panel visibility
  isSubCollapsePanelVisible: Record<string, any> = {};

  displayBlueprint = true;
  leftSideContainerWidth = 740;

  // active code path
  activeCodePath = '';

  // active design path
  activeDesignPath = '';
  messageTemplateIsLoaded = false;
  pagesIsLoaded = false;
  pageLayoutIsLoaded = false;
  activeProjectThemeIsLoaded = false;
  activeNavigationType: NavigatePages | undefined;

  // show animation next endpoint model
  showNextEndpointModel = false;
  currentEndpointIndex = 0;
  isEndpointScrolling = false;
  currentEndpoints: any[] = [];

  clientLocalState: ClientLocalState[] | undefined | null;

  projectDescriptionStep = WIZARD_STEPS_CONFIG.find(step => step.key === WizardSteps.ProjectDescription);

  layoutMode: string | undefined;

  aiStatusProjectDescriptionImproved: AIProgressStatus | undefined;

  wizardStepsTimelineItems: TimelineItem[] = [];
  displayWizardNextButton = false;
  deployStatus: DeployStatus = DeployStatus.NotStarted;
  DeployStatus = DeployStatus;

  // #endregion

  // #region Constructor
  constructor(
    private store: Store<any>,
    private renderer: Renderer2,
    private projectApi: ProjectControllerService,
    private notificationService: NotificationService,
    private projectDesignService: ProjectDesignService,
  ) {
    console.log('project design constructed');
  }
  // #endregion

  // #region Lifecycle Hooks

  ngOnInit(): void {
    this.subscribeToInitialData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.activeWizardStep && changes.activeWizardStep.currentValue !== undefined) ||
      (changes.wizardStepsConfigs && changes.wizardStepsConfigs.currentValue !== undefined)
    ) {
      this.renderStep();
    }
  }

  ngAfterViewInit(): void {
    // If the wizard is outside the viewport, show the footer
    // this.observeWizardFooterVisibility();
    console.log('project design after view init');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // #endregion

  // #region Subscriptions

  subscribeToInitialData() {
    // Subscribe to user and active project
    this.subscriptions = [
      this.subscribeToUser(),
      this.subscribeToActiveProject(),
    ];
  }

  subscribeToRemainingData() {
    // Subscribe to project design tasks
    if (!this.allSubscriptionsPopulated) {
      this.allSubscriptionsPopulated = true;
      this.subscriptions.push(...[
        this.subscribeToClientLocalState(),

        /* entities */
        this.subscribeToProjectModels(),
        this.subscribeToProjectModelRelations(),
        // this.subscribeToDataSchemas(),
        // this.subscribeToScopeOfWork(),
        this.subscribeToMessageTemplates(),
        // this.subscribeToMessageTemplatesIsLoaded(),
        this.subscribeToProjectVersions(),
        /* pages */
        // this.subscribeToContentTree(),
        this.subscribeToComponentDefinitions(),
        // this.subscribeToUIDataStores(),
        this.subscribeToPages(),
        // this.subscribeToPagesIsLoaded(),
        this.subscribeToModules(),
        this.subscribeToPageLayouts(),
        this.subscribeToProjectTheme(),
        this.subscribeToDeployManagementInformation()
      ]);
    }
  }

  subscribeToUser() {
    return this.store.select(selectUser).subscribe(user => {
      if (user) {
        this.user = user;
        this.person = user?.people?.length ? user.people[0] : undefined;
        this.tenant = this.person?.tenant;
      }
    });
  }

  subscribeToActiveProject() {
    return this.store.select(selectActiveProject).subscribe(activeProject => {
      if (activeProject?.id) {
        if (activeProject?.id && this.activeProject?.id && this.activeProject?.id !== activeProject?.id) {
          this.resetProjectDataToLoadAnotherProject();
        }
        this.activeProject = activeProject;
        if (this.activeProject?.activeProjectThemeId) {
          this.activeProjectThemeIsLoaded = true;
        }
        if (activeProject?.id) {
          this.subscribeToRemainingData();
          this.renderStep();
        }
      } else {
        this.activeProject = undefined;
        this.projectModelsLoaded = false;
        console.log('No active project');
      }
    });
  }

  resetProjectDataToLoadAnotherProject() {
    this.projectModelsLoaded = false;
    this.activeCodePath = '';
    this.activeDesignPath = '';
    this.projectVersions = [];
    this.latestProjectVersionId = undefined;
  }

  subscribeToClientLocalState() {
    // Trigger effect to read data from IndexDB and populate the state
    return combineLatest([
      this.store.select(selectClientLocalStates),
      this.store.select(selectClientLocalStateLoaded)
    ]).subscribe(([clientLocalState, isLoaded]) => {
      if (isLoaded) {
        this.clientLocalState = cloneDeep(clientLocalState);

        // when we complete the project description improvement step, we save it to the client local state
        const projectDescriptionImproved = this.clientLocalState?.find((cls: ClientLocalState) => cls.key === ClientLocalStateKeys.AiStatusProjectDescriptionImproved);
        this.aiStatusProjectDescriptionImproved = projectDescriptionImproved?.value;

      }
    });
  }

  subscribeToProjectModels() {
    return combineLatest([
      this.store.select(selectIsProjectModelsLoaded),
      this.store.select(selectProjectModels),
      this.store.select(selectAllProjectModelFields)
    ]).subscribe(([isProjectModelsLoaded, projectModels, projectModelFields]) => {
      this.projectModelFields = projectModelFields ? projectModelFields : [];
      this.projectModelsLoaded = !!isProjectModelsLoaded;
      if (projectModels?.length) {
        this.projectModels = projectModels.map((projectModel: ProjectModelWithRelations) => <ProjectModelWithRelations>{
          ...projectModel,
          fields: orderBy(projectModelFields.filter((field) => field.projectModelId === projectModel?.id), ['index'], ['asc'])
        });
        // console.log('Project Models:', this.projectModels);

        if (this.projectModels?.length) {
          this.projectModelsLoaded = true;
        }
      }
    });
  }

  subscribeToProjectModelRelations() {
    return this.store.select(selectProjectModelRelations).subscribe((projectModelRelations: ProjectModelRelation[]) => {
      this.projectModelRelations = projectModelRelations ? projectModelRelations : [];
      console.log('Project Model Relations:', projectModelRelations);
    });
  }

  subscribeToMessageTemplates() {
    return this.store.select(selectMessageTemplates).subscribe((messageTemplates: MessageTemplate[]) => {
      this.messageTemplates = messageTemplates ? messageTemplates : [];
      if (messageTemplates?.length) {
        this.mapMessageTemplateItems();
      }
    });
  }

  subscribeToPages() {
    return this.store.select(state => state.page?.data).subscribe((pages) => {
      if (pages?.length) {
        this.pages = pages;
        this.mapPageItems();
      }
    });
  }

  subscribeToComponentDefinitions() {
    return this.store
      .select(<any>getComponentDefinitionsWithDetailsSelector)
      .subscribe((componentDefinitions: ComponentDefinitionWithRelations[]) => {
        this.componentDefinitions = cloneDeep(componentDefinitions);
      });
  }

  subscribeToUIDataStores() {
    return this.store
      .select((state) => state.uiDataStore?.data)
      .subscribe((uiDataStores: UiDataStore[]) => {
        this.uiDataStores = uiDataStores;
      });
  }

  subscribeToModules() {
    return this.store
    .select(state => state.module?.data)
    .subscribe(modules => {
      this.modules = modules;
      if (this.modules?.length && this.pages?.length) {
        this.mapPageItems();
      }
    });
  }

  subscribeToPageLayouts() {
    return this.store.select(state => state.layout?.data).subscribe(layouts => {
      this.layouts = layouts;
      const defaultLayout = this.layouts?.find(layout => layout.isDefault === true);
      this.previousLayoutId = defaultLayout?.id;
      if (this.previousLayoutId !== this.clickedLayoutCardId) {
        this.clickedLayoutCardId = this.previousLayoutId;
      }
      if (!this.activeLayout?.id && this.layouts?.length) {
        this.activeLayout = defaultLayout || this.layouts[0];
        this.pageLayoutIsLoaded = true;
      }
      this.mapLayoutItems();
    });
  }

  subscribeToProjectTheme() {
    return this.store
      .select(state => state.projectTheme?.data)
      .subscribe((projectThemes: ProjectTheme[]) => {
        if (projectThemes?.length) {
          this.projectThemes = projectThemes;
          const activeProjectTheme = projectThemes.find(projectTheme => projectTheme.id === this.activeProject?.activeProjectThemeId);
          this.projectTheme = {
            ...this.projectTheme,
            ...(activeProjectTheme?.theme ?? {}),
            ...(activeProjectTheme?.customTheme ?? {})
          };

          this.previousThemeId = activeProjectTheme?.id;
          if (this.previousThemeId !== this.clickedThemeCardId) {
            this.clickedThemeCardId = this.previousThemeId;
          }
          this.mapThemeItems();
        }
      });
  }

  subscribeToDeployManagementInformation() {
    return this.store.select(state => state.deployManagement?.deployInfo)
      .subscribe(deployInfo => {
        if (deployInfo?.length) {
          this.deployStatus = deployInfo[0].status;
        }
      });
  }

  subscribeToProjectVersions() {
    return this.store.select(state => state.projectVersion.data).subscribe(projectVersions => {
      this.projectVersions = projectVersions;
      if (this.projectVersions?.length && projectVersions?.length !== this.projectVersionsLength) {
        const latestVersions = orderBy(this.projectVersions, 'createdDate', 'desc');
        this.latestProjectVersionId = latestVersions[0].id;
        if (this.latestProjectVersionId) {
          this.projectVersionsLength = projectVersions?.length;
        }
      }
    });
  }

  // #endregion

  // #region Wizard Navigation and Step Specific Logic

  onWizardNextStepButtonClicked() {
    // this.store.dispatch(NextStep({ payload: { activeProjectId: this.activeProject?.id ?? '' } }));
  }

  onWizardSkipStepsButtonClicked() {
    console.log('Wizard skip step button clicked');
    // this.store.dispatch(NextStep({ payload: { activeProjectId: this.activeProject?.id ?? '' } }));
  }

  scrollToActiveSectionCollapsePanel() {
    /* scroll to the section */
    setTimeout(() => {
      this.scrollToDomElement(this.wizardStepsConfigs[this.activeWizardStep].collapsePanel || '');
    }, 250);
  }

  isStepVisible(stepNumber: number): boolean {
    return (stepNumber <= this.activeWizardStep + 1) &&
      (
        this.wizardStepsConfigs[stepNumber - 1].status === WizardStepStatus.InProgress ||
        this.wizardStepsConfigs[stepNumber - 1].status === WizardStepStatus.Completed
      );
  }

  getStepStatusTemplate(key: WizardSteps): TemplateRef<any> {
    const status = this.wizardStepsConfigs.find(step => step.key === key)?.status || WizardStepStatus.NotStarted;

    switch (status) {
      case WizardStepStatus.NotStarted:
        return this.stepNotStarted;
      case WizardStepStatus.InProgress:
        return this.stepInProgress;
      case WizardStepStatus.Completed:
        return this.stepCompleted;
      case WizardStepStatus.Error:
        return this.stepError;
      default:
        return this.stepNotStarted;
    }
  }

  // Renders the wizard and step content based on the active step
  renderStep() {

    if (this.activeProject?.id && this.activeWizardStep !== undefined && this.wizardStepsConfigs[this.activeWizardStep] &&
      (
        this.latestRenderedStep !== this.activeWizardStep ||
        (this.latestRenderedStep === this.activeWizardStep &&
          this.latestRenderedStatus !== this.wizardStepsConfigs[this.activeWizardStep]?.status
        )
      )
    ) {

      this.mapWizardStepsTimelineItems();

      // refresh the source code list
      this.getProjectSourceCodeFiles();

      // memorize the last rendered step
      this.latestRenderedStep = this.activeWizardStep;
      this.latestRenderedStatus = this.wizardStepsConfigs[this.activeWizardStep].status;

      // update visibility of the collapse panel
      this.onCollapsePanelActiveChange(this.wizardStepsConfigs[this.activeWizardStep].collapsePanel, true);

      if (this.wizardStepsConfigs && this.wizardStepsConfigs[this.activeWizardStep].status === WizardStepStatus.Introduction &&
        this.wizardStepsConfigs[this.activeWizardStep - 1]) {
        this.onCollapsePanelActiveChange(this.wizardStepsConfigs[this.activeWizardStep - 1].collapsePanel, true);
      }

      this.scrollToActiveSectionCollapsePanel();

      // // trigger next step if the current step is in progress and max wait time has passed
      // if (this.wizardStepsConfigs[this.activeWizardStep]?.status === WizardStepStatus.InProgress) {
      //   this.triggerNextWizardStep();
      // }
    }

  }

  mapWizardStepsTimelineItems() {
    const notStartedIcon = 'fa-regular fa-square';
    const inProgressIcon = 'fa-solid fa-spinner-third fa-spin';
    const completedIcon = 'fa-solid fa-check-square';
    if (this.wizardStepsConfigs?.length) {
      this.wizardStepsTimelineItems = this.wizardStepsConfigs?.filter(i => i.projectDesignTaskIdentifier)?.map((step: WizardStepConfig) => <TimelineItem>{
        title: step.planTitle,
        color: step.status === WizardStepStatus.Completed ? TimelineItemColor.Success : step.status === WizardStepStatus.NotStarted ? TimelineItemColor.Disabled : TimelineItemColor.InProgress,
        icon: {
          type: IconType.FontAwesome,
          name: step.status === WizardStepStatus.Completed ? completedIcon : step.status === WizardStepStatus.InProgress ? inProgressIcon : notStartedIcon,
          color: step.status === WizardStepStatus.Completed ? TimelineItemColor.Success : step.status === WizardStepStatus.InProgress ? TimelineItemColor.InProgress : TimelineItemColor.Disabled,
          size: '20px',
          customColorSettings: {
            backgroundColor: 'var(--alternative-background-color)',
          },
          borderSettings: {
            borderColor: 'var(--alternative-background-color)',
          }
        },
        markdownContent: step.status === WizardStepStatus.InProgress ? {
          typeInRealTime: true,
          typingSpeed: 1,
          markdownText: step.description
        } : undefined
      });
    }
  }


  shouldProjectDescriptionTypeInRealTime(): boolean {
    const projectDescriptionStepIndex = 1;
    if (this.activeProject?.description && this.activeWizardStep <= projectDescriptionStepIndex) {
      if (this.isStepVisitedForTheFirstTime(WizardSteps.ProjectDescription)) {
        return true;
      }
    }
    return false;
  }

  setStepAnimations() {
    // Step specific actions, step 0 is the welcome step, subtract 1 from the step number on the wizard
    const activeStep = this.wizardStepsConfigs[this.activeWizardStep] ?? null;
    switch (activeStep?.key) {
      // Project Description
      case WizardSteps.ProjectDescription:
        // const isProjectDescriptionImproved = this.clientLocalState?.find((cls: ClientLocalState) => cls.key === ClientLocalStateKeys.aiStatusProjectDescriptionImproved);
        // if (isProjectDescriptionImproved?.value) {
        //   this.enableAndHighlightNextButton();
        //   activeStep.description = projectDescriptionStepCompletedContent;
        // }
        break;

      //  Data Models
      case WizardSteps.DataModelsAndDatabase:
        break;

      // API Endpoints
      case WizardSteps.APIEndpoints:
        break;

      // UI Pages
      case WizardSteps.UIPages:
        break;

      default:
        break;
    }

    if (this.shouldProjectDescriptionTypeInRealTime() && this.collapsePanelConfigs[CollapsePanels.ProjectDescription]?.crudForm?.items[0]) {
      (this.collapsePanelConfigs[CollapsePanels.ProjectDescription].crudForm.items[0] as CrudFormMonacoCodeEditorItem).typeInRealTime = true;
    } else {
      (this.collapsePanelConfigs[CollapsePanels.ProjectDescription].crudForm.items[0] as CrudFormMonacoCodeEditorItem).typeInRealTime = false;
    }

    if (this.aiStatusProjectDescriptionImproved === AIProgressStatus.Completed) {
      // disable the improve with AI button if the project description is already improved
      if (this.collapsePanelConfigs[CollapsePanels.ProjectDescription]?.actionButtons?.length) {
        const improveProjectWithAIButton = this.collapsePanelConfigs[CollapsePanels.ProjectDescription].actionButtons.find(button =>
          button.key === ProjectDesignSettingButtons.ImproveProjectWithAI);
        if (improveProjectWithAIButton) {
          improveProjectWithAIButton.disabled = true;
          improveProjectWithAIButton.tooltipText = 'Project description is already improved';
        }
      }
    }
  }

  highlightActionButtons() {
    if (this.activeWizardStep) {
      this.removeHighlightEffectFromAllActionButtons();
      const activeStep = this.wizardStepsConfigs[this.activeWizardStep];
      const highlightedButtons = activeStep.highlightedElements;
      if (activeStep?.status !== WizardStepStatus.Completed && highlightedButtons?.length) {
        highlightedButtons.forEach(buttonKey => {
          if (buttonKey === ProjectDesignSettingButtons.ImproveProjectWithAI) {
            const projectDescriptionStep = this.wizardStepsConfigs?.find(wizardStep => wizardStep.key === WizardSteps.ProjectDescription);
            const projectDescriptionStatus = projectDescriptionStep?.status || WizardStepStatus.NotStarted;
            if (this.aiStatusProjectDescriptionImproved === AIProgressStatus.NotStarted && projectDescriptionStatus === WizardStepStatus.InProgress) {
              this.addHighlightEffectToActionButton(buttonKey);
            }
          } else {
            this.addHighlightEffectToActionButton(buttonKey);
          }
        });
      }
    }
  }

  // enableAndHighlightNextButton() {
  //   if (this.activeWizardStep) {
  //     // Highlight the next button to after the project description is improved
  //     this.addHighlightEffectToActionButton(ProjectDesignSettingButtons.WizardNextButton);
  //     this.wizardStepsConfigs[this.activeWizardStep].disableNextButton = false;
  //   }
  // }

  addHighlightEffectToActionButton(actionButtonKey: string) {
    setTimeout(() => {
      const actionButton = document.getElementById(actionButtonKey);
      if (actionButton) {
        this.renderer.addClass(actionButton, 'blinking-outline-shake');
      }
    }, 500);
  }

  removeHighlightEffectFromAllActionButtons() {
    const actionButtonKeys = Object.values(ProjectDesignSettingButtons);
    actionButtonKeys.forEach(key => {
      const actionButton = document.getElementById(key);
      if (actionButton) {
        this.renderer.removeClass(actionButton, 'blinking-outline-shake');
      }
    });
  }

  // returns true if the step is visited for the first time; meaning next step is not started
  isStepVisitedForTheFirstTime(key: WizardSteps): boolean {
    const stepIndex = this.wizardStepsConfigs.findIndex(step => step.key === key);
    const nextStepStatus = this.wizardStepsConfigs[stepIndex + 1].status;
    return nextStepStatus === WizardStepStatus.NotStarted;
  }

  // #endregion

  // #region Action / Setting Button Functions

  /* Action buttons on settings (right side) of the sections */
  onActionButtonClicked(actionKey: string) {
    console.log('Action button clicked:', actionKey);

    switch (actionKey) {
      case ProjectDesignSettingButtons.EditProjectDescription:
        this.projectDescriptionEditMode = !this.projectDescriptionEditMode;
        break;

      case ProjectDesignSettingButtons.ImproveProjectWithAI:
        // Update config to show the description typing in real time
        // this.setProjectDescriptionAsTypeInRealTime(true);
        this.improveProjectDescriptionWithAI();
        // this.removeHighlightEffectFromAllActionButtons();
        break;

      case ProjectDesignSettingButtons.AddAuthorization:
        this.notificationService.createNotification(
          'info',
          'Authentication Module Enabled',
          'Authentication module has already been added to the project. You can change the settings later'
        );
        break;

      case ProjectDesignSettingButtons.ChangeProgrammingLanguage:
        this.notificationService.createNotification(
          'info',
          'This feature is not available for free users',
          'We only provide NodeJS, Angular & MongoDB stack for free plans. Please upgrade to a premium plan to change the programming languages and frameworks',
        );
        break;

      case ProjectDesignSettingButtons.ChangeToMobileAppProject:
        this.notificationService.createNotification(
          'info',
          'This feature is not available for free users',
          'We only provide web application for free plans. Please upgrade to a premium plan to build mobile applications',
        );
        break;

      case ProjectDesignSettingButtons.InviteOthers:
        this.showInvitationModal = new Date().toISOString();
        break;

      case ProjectDesignSettingButtons.ProjectPackages: {
        this.navigateToUrl(FullPaths.ProjectPackages);
        break;
      }

      case ProjectDesignSettingButtons.EmbeddedExternalScripts: {
        this.navigateToUrl(FullPaths.EmbeddedScripts);
        break;
      }

      case ProjectDesignSettingButtons.ProjectMembers: {
        this.navigateToUrl(FullPaths.ProjectMembers);
        break;
      }

      case ProjectDesignSettingButtons.ProjectRoles: {
        this.navigateToUrl(FullPaths.ProjectRoles);
        break;
      }

      case ProjectDesignSettingButtons.DisplayDataModelDiagram: {
        this.navigateToUrl(FullPaths.DatabaseDiagram);
        break;
      }


      case ProjectDesignSettingButtons.CreateDataModelManually: {
        this.navigateToUrl('/projects/model-create');
        break;
      }


      case ProjectDesignSettingButtons.DataModelList: {
        this.navigateToUrl(FullPaths.DataModels);
        break;
      }

      case ProjectDesignSettingButtons.DataSchemaList: {
        this.navigateToUrl(FullPaths.DataSchemas);
        break;
      }

      case ProjectDesignSettingButtons.DisplayApiEndpoints: {
        this.navigateToUrl(FullPaths.Endpoint);
        break;
      }

      case ProjectDesignSettingButtons.AddUiPageManually: {
        this.navigateToUrl('/pages/create');
        break;
      }

      case ProjectDesignSettingButtons.UiPageList: {
        this.navigateToUrl(FullPaths.Pages);
        break;
      }

      case ProjectDesignSettingButtons.UiModules: {
        this.navigateToUrl(FullPaths.Modules);
        break;
      }

      case ProjectDesignSettingButtons.UiPageLayoutList: {
        this.navigateToUrl(FullPaths.Layouts);
        break;
      }

      case ProjectDesignSettingButtons.ThemeList: {
        this.navigateToUrl(FullPaths.ThemeList);
        break;
      }

      case ProjectDesignSettingButtons.AddThemeManually: {
        this.navigateToUrl('/projects/create-theme');
        break;
      }

      case ProjectDesignSettingButtons.CustomStyles: {
        this.navigateToUrl(FullPaths.CSStyleCustomStyles);
        break;
      }

      case ProjectDesignSettingButtons.UiProcessDiagram: {
        this.navigateToUrl(FullPaths.DiagramEditor);
        break;
      }

      case ProjectDesignSettingButtons.UiDataStores: {
        this.navigateToUrl(FullPaths.UIDataStores);
        break;
      }

      case ProjectDesignSettingButtons.UiDataEvents: {
        this.navigateToUrl(FullPaths.Events);
        break;
      }

      case ProjectDesignSettingButtons.UiDataEventUpdateFunctions: {
        this.navigateToUrl(FullPaths.UIDataStoreUpdateFunctions);
        break;
      }

      case ProjectDesignSettingButtons.UiDataStepFunctions: {
        this.navigateToUrl(FullPaths.UIStepFunctions);
        break;
      }

      case ProjectDesignSettingButtons.MessageTemplateList: {
        this.navigateToUrl(FullPaths.MessageTemplates);
        break;
      }

      case ProjectDesignSettingButtons.AddMessageTemplateManually: {
        this.navigateToUrl('/projects/message-template');
        break;
      }

      case ProjectDesignSettingButtons.ServerSideBusinessProcessDiagram: {
        this.navigateToUrl(FullPaths.WorkflowDiagram);
        break;
      }

      case ProjectDesignSettingButtons.WorkflowCustomFunctionDefinitions: {
        this.navigateToUrl(FullPaths.WorkflowFunctions);
        break;
      }

      case ProjectDesignSettingButtons.DisplayProjectSettings: {
        this.navigateToUrl(FullPaths.ProjectSettings);
        break;
      }

      case ProjectDesignSettingButtons.DisplayEnvironmentVariables: {
        this.navigateToUrl(FullPaths.EnvironmentVariables);
        break;
      }
    }
  }

  // #endregion

  // #region Display Code and Design Panels

  onDisplayCodePanelClicked() {
    this.displayCodePanelClicked.emit(Date.toString());
    this.collapseButtonClicked.emit((new Date()).toString());
  }

  // #endregion

  // #region Collapse Panel Visibility
  updateCollapsePanelVisibilities(mode: string, visiblePanelKeys?: string[]) {

    if (mode === 'expand-all') {
      this.allPanelsActive = true;
      this.visiblePanelKeys = [];
    } else if (mode === 'collapse-all') {
      this.allPanelsActive = false;
      this.visiblePanelKeys = [];
    } else {
      this.allPanelsActive = false;
      this.visiblePanelKeys = visiblePanelKeys || [];
    }
  }

  onCollapsePanelActiveChange(panelKey: CollapsePanels, active: boolean) {

    // Check if this panel is visible
    const isPanelVisible = this.visiblePanelKeys.includes(panelKey);
    if (active === isPanelVisible) {
      return;
    }

    console.log('Collapse panel active change:', panelKey, active);

    if (active) {
      this.visiblePanelKeys = [
        ...this.visiblePanelKeys.filter(key => !autoCollapsedPanels.includes(key as CollapsePanels)),
        panelKey
      ];
    } else {
      this.visiblePanelKeys = this.visiblePanelKeys.filter(key => key !== panelKey);
    }

    // Layout mode updates
    const wizardStepConfig = this.wizardStepsConfigs.find(step => step.collapsePanel === panelKey);
    if (wizardStepConfig?.layoutMode && this.layoutMode !== wizardStepConfig.layoutMode) {
      this.layoutModeChanged.emit(wizardStepConfig.layoutMode);
    }

    // Code path updates
    if (wizardStepConfig?.layoutPaths?.codePath && this.activeCodePath !== wizardStepConfig.layoutPaths.codePath) {
      this.activeCodePath = wizardStepConfig.layoutPaths.codePath;
      this.getSourceCode(this.activeCodePath);
    }

    // Design path updates
    if (wizardStepConfig?.layoutPaths?.designPath && this.activeDesignPath !== wizardStepConfig.layoutPaths.designPath) {
      this.activeDesignPath = wizardStepConfig.layoutPaths.designPath;
      this.store.dispatch(new Navigate({ url: this.activeDesignPath }));
    }
  }

  onCrudCollapseActiveChange(event: any) {
    if (event?.collapseSection?.key === CollapsePanels.ProjectInformation) {
      this.projectInformationCollapseActive = event.isActive;
    }
  }
  // #endregion

  // #region Collapse Panel and Content Animations


  // #region Animated Endpoints Source Code Scroll

  // #endregion

  scrollToElement(elementId: string, end = false) {
    const element = document.getElementById(elementId);
    if (element) {
      if (!end) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      } else {
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
      }
    }
  }
  // #endregion

  // #region Post It Notes

  onPostItNoteUpdated(event: any) {
    console.log('Post it note updated:', event);
  }

  // TODO: Should return the post it notes from the data
  getPostItNotes(sectionKey: string): any[] {
    // TODO: Implement the logic to get the post it notes from the data
    return [
      // {
      //   id: '1',
      //   title: 'Post It Note 1',
      //   color: PostItColor.Yellow,
      //   size: PostItSize.Small,
      //   content: 'This is a post it note content',
      // }
    ];
  }

  // #endregion

  // // #region AI Functions


  improveProjectDescriptionWithAI() {
    const mockThisStep = false;

    if (this.activeProject?.description) {
      const params = {
        body: {
          message: this.activeProject?.description
        }
      };
      this.updateAiStatus('projectDescriptionImproved', AIProgressStatus.InProgress);

      let aiCall$: Observable<ImproveProjectDescriptionByAiResponseDto>;
      if (mockThisStep) {
        // create a mock rxjs observable to simulate the AI response
        aiCall$ = of({
          description: this.activeProject?.description || ''
        }).pipe(delay(1000));
      } else {
        aiCall$ = this.projectApi.generateByAI(params);
      }

      aiCall$.subscribe({
        next: (response) => {
          if (response.description) {
            this.updateAiStatus('projectDescriptionImproved', AIProgressStatus.Completed);
            this.projectDescriptionEditMode = false;
            if (this.activeProject?.id) {
              this.store.dispatch(new UpdateProject({
                project: {
                  description: response.description
                },
                projectId: this.activeProject.id,
                redirectToProjectDetail: false,
                notificationMessageOnSuccess: 'Project Description Improved with AI',
                notificationMessageOnFailure: 'Project Description Could Not Be Improved with AI'
              }));
            }
            this.wizardStepsConfigs[1].description = projectDescriptionStepCompletedContent;
          }
        },
        error: (error) => {
          this.updateAiStatus('projectDescriptionImproved', AIProgressStatus.Error);
          this.notificationService.createNotification(
            'error',
            'Error with AI',
            'Project Description Could Not Be Improved with AI',
          );
        }
      });
    }
  }

  // #endregion

  // #region Client Local State Functions

  updateAiStatus(key: string, value: AIProgressStatus) {
    this._saveClientLocalStateVariable(key, value.toString());
  }

  /**
   * ClientLocalState Saves to the: [IndexDB / projectId / _root / key: value]
   *
   * @param {string} key
   * @param {*} value
   * @memberof ProjectDesignComponent
   */
  private _saveClientLocalStateVariable(key: string, value: any) {
    if (this.activeProject?.id) {
      this.store.dispatch(CreateClientLocalState({ payload: { clientLocalState: <ClientLocalState>{ key, value } } }));
    }
  }

  // #endregion


  // #region Utility Functions
  // TODO: Move it to a service
  typeTextInRealTime(fullText: string, fieldReference: string): void {
    const typingSpeed = 100; // milliseconds
    let index = 0;

    const typeChar = () => {
      if (index < fullText.length) {
        fieldReference += fieldReference.charAt(index);
        index++;
        setTimeout(typeChar, typingSpeed);
      }
    };

    typeChar(); // initiate typing
  }

  // #endregion

  // #region SECTION SPECIFIC FUNCTIONS

  // #region Project Description
  onProjectDescriptionSaved(projectDescription: string) {
    console.log('Project description saved:', projectDescription);
    this.projectDescriptionEditMode = false;

    if (this.activeProject?.id) {
      this.store.dispatch(new UpdateProject({
        project: {
          description: projectDescription
        },
        projectId: this.activeProject.id,
        redirectToProjectDetail: false,
        notificationMessageOnSuccess: 'Project Description Updated',
        notificationMessageOnFailure: 'Project Description Could Not Be Updated',
      }));
    }
  }

  onProjectDescriptionEditCancelled() {
    console.log('Project description edit cancelled');
    this.projectDescriptionEditMode = false;
  }
  // #endregion

  // #region Data Model
  onDeleteDataFieldClick(response: ActionResponse, projectModelId: string) {
    const dataFieldToDelete = <ProjectModelField>response.data;

    this.store.dispatch(new DeleteProjectModelField(
      {
        deletedProjectModelField: dataFieldToDelete,
        projectModelId: projectModelId
      }
    ));
  }
  // #endregion


  // #endregion

  /**
  * this function clear css style of the content area and
  * re-set content area css style by page css style and layout css style if exists
  */

  // TODO: Implement the function
  hasManagerRole(): boolean {
    return true;
  }

  navigateEditProject() {
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_EDIT_PATH}/${this.activeProject?.id}` }));
  }

  navigateToProjectList() {
    this.store.dispatch(new Navigate({ url: PATH_DEFINITIONS.PROJECTS.PROJECT_LIST_PATH }));
  }

  navigateToUrl(url: string) {
    this.store.dispatch(new Navigate({ url }));
  }

  onMegaMenuClose() {
    this.megaMenuVisible = false;
  }


  onMegaMenuClicked(event: any) {
    if (event?.path) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    this.megaMenuVisible = false;
  }

  // TODO: Implement the function
  isAdminOrManagerUser() {
    // should return the data according to the role of the user
    return true;
  }

  onSubCollapsePanelActiveChange(subPanelId: string, isActive: boolean) {
    this.isSubCollapsePanelVisible[subPanelId] = !!isActive;
  }

  splitDragEnds(event: any) {
    console.log('splitDragEnds');

  }

  collapseBluePrintManager() {
    console.log('collapseBluePrintManager');
    this.collapseButtonClicked.emit((new Date()).toString());
  }

  // #region Project Source Code

  getSourceCode(sourceFilePath: string | undefined) {
    if (this.layoutMode !== LayoutMode.SourceCode) {
      this.layoutModeChanged.emit(LayoutMode.SourceCode);
    }
    if (sourceFilePath) {
      this.store.dispatch(GetProjectSourceCode({ payload: { sourceFilePath } }));
    }
  }


  getCustomCodes(sourceFilePath: string | undefined): void {
    if (sourceFilePath) {
      this.store.dispatch(GetProjectSourceFileCustomCodes({ payload: { sourceFilePath: sourceFilePath } }));
    }
  }

  getProjectSourceCodeFiles() {
    this.store.dispatch(GetProjectSourceFiles());
  }

  onDataModelRowClick(data: any) {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    this.store.dispatch(FocusToRelatedModelId({ payload: { focusToRelatedModelId: data.id } }));
  }

  onDataModelRowClicka(data: any) {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    this.store.dispatch(FocusToRelatedModelId({ payload: { focusToRelatedModelId: data.projectModelId } }));
  }


  onEndpointRowActionClick(event: any) {
    const endpoint = event;

    if (endpoint?.path) {
      const controllerPath = this.getControllerPathFromEndpoint(endpoint.path) || '';
      if (controllerPath) {
        if (this.activeCodePath === controllerPath) {
          this.scrollToCode(endpoint.name);
        } else {
          this.activeCodePath = controllerPath;
          this.getSourceCode(this.activeCodePath);

          setTimeout(() => {
            const editor = (window as any).monaco?.editor?.getEditors()?.[0];
            if (editor) {
              setTimeout(() => {
                this.scrollToCode(endpoint.name);
              }, 4000);
            }
          }, 1000);
        }
      }
    }
  }

  getControllerPathFromEndpoint(endpointPath: string): string {
    const basePath = endpointPath.split('/')[0];

    const singularPath = basePath.endsWith('s')
      ? basePath.slice(0, -1)
      : basePath;

    return `backend/src/controllers/${singularPath}.controller.ts`;
  }

  scrollToCode(codeName: string): void {
    if (this.layoutMode !== LayoutMode.SourceCode) {
      this.layoutModeChanged.emit(LayoutMode.SourceCode);
    }
    const editor = (window as any).monaco?.editor?.getEditors()?.[0];

    if (editor) {
      const searchPatterns = [
        `async ${codeName}(`,
        `${codeName}(`,
        `${codeName}:`,
        `${codeName}=`
      ];

      const model = editor.getModel();
      const content = model.getValue();

      for (const pattern of searchPatterns) {
        const match = content.indexOf(pattern);
        if (match !== -1) {
          const targetLineNumber = content.substring(0, match).split('\n').length;
          const currentLineNumber = editor.getPosition().lineNumber;

          if (pattern.startsWith('async')) {
            const scrollDuration = 1500;
            const distance = targetLineNumber - currentLineNumber;
            const startTime = Date.now();

            const animate = () => {
              const currentTime = Date.now();
              const elapsed = currentTime - startTime;
              const progress = Math.min(elapsed / scrollDuration, 1);

              const easeInOutCubic = (p: number) => p < 0.5
                ? 4 * p * p * p
                : 1 - Math.pow(-2 * p + 2, 3) / 2;

              const currentLine = currentLineNumber + distance * easeInOutCubic(progress);
              editor.revealLineInCenter(Math.round(currentLine));

              if (progress < 1) {
                requestAnimationFrame(animate);
              } else {
                editor.setPosition({ lineNumber: targetLineNumber, column: 1 });
                editor.setSelection(new (window as any).monaco.Range(
                  targetLineNumber,
                  1,
                  targetLineNumber,
                  model.getLineMaxColumn(targetLineNumber)
                ));

                setTimeout(() => {
                  editor.setSelection(new (window as any).monaco.Range(
                    targetLineNumber,
                    1,
                    targetLineNumber,
                    1
                  ));
                }, 3000);
              }
            };

            animate();
          } else {
            editor.revealLineInCenter(targetLineNumber);
            editor.setPosition({ lineNumber: targetLineNumber, column: 1 });
            editor.setSelection(new (window as any).monaco.Range(
              targetLineNumber,
              1,
              targetLineNumber,
              model.getLineMaxColumn(targetLineNumber)
            ));

            setTimeout(() => {
              editor.setSelection(new (window as any).monaco.Range(
                targetLineNumber,
                1,
                targetLineNumber,
                1
              ));
            }, 3000);
          }
          break;
        }
      }
    }
  }

  onProjectSettingsRowActionClick(event: any) {
    const filePath = 'backend/src/definitions/settings.ts';
    const scrollToSettings = event?.key;

    if (this.activeCodePath === filePath) {
      if (scrollToSettings) {
        this.scrollToCode(scrollToSettings);
      }
    } else {
      this.activeCodePath = filePath;
      this.getSourceCodeAndRefreshCodeEditor(scrollToSettings);
    }
  }

  getSourceCodeAndRefreshCodeEditor(scrollToSettings: any) {
    this.getSourceCode(this.activeCodePath);
    setTimeout(() => {
      const editor = (window as any).monaco?.editor?.getEditors()?.[0];
      if (editor && scrollToSettings) {
        setTimeout(() => {
          this.scrollToCode(scrollToSettings);
        }, 1500);
      }
    }, 500);
  }

  onProjectEnvironmentVariablesRowActionClick(event: any) {
    const filePath = this.getEnvironmentVariablesPath(event.environmentKey);
    const scrollToEnvironmentVariable = event?.key;

    if (this.activeCodePath === filePath) {
      if (scrollToEnvironmentVariable) {
        this.scrollToCode(scrollToEnvironmentVariable);
      }
    } else {
      this.activeCodePath = filePath;
      this.getSourceCodeAndRefreshCodeEditor(scrollToEnvironmentVariable);
    }
  }

  getEnvironmentVariablesPath(environmentKey: string): string {
    const environmentFileName = environmentKey.toLowerCase();
    return `backend/environments/${environmentFileName}.env`;
  }

  // #endregion

  onElementComponentSelected(event: ComponentDefinitionWithRelations) {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    if (event?.id) {
      this.scrollToElement(event.id);
    }
  }

  // #region DOM Functions

  scrollToDomElement(sectionId: string) {
    const targetElement = document.getElementById(sectionId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  // #endregion

  onCardsSelect(card: any) {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    if (card.imageButtonData.cardOneButtonOutput.button.name === 'preview') {
      if (card.imageButtonData.item.data.type === 'page' || card.imageButtonData.item.data.type === 'layout') {
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${card.imageButtonData.item.data.id}` }));
      } else {
        this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_EDIT_MESSAGE_TEMPLATE}/${card.imageButtonData.item.data.id}` }));
      }
    }

    if (card.imageButtonData.cardOneButtonOutput.button.name === 'preview-page' || card.imageButtonData.cardOneButtonOutput.button.name === 'preview-layout') {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${card.imageButtonData.item.data.id}` }));
    }

    if (card.imageButtonData.cardOneButtonOutput.button.name === 'preview-message-template') {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_EDIT_MESSAGE_TEMPLATE}/${card.imageButtonData.item.data.id}` }));
    }

    if (card.imageButtonData.cardOneButtonOutput.button.name === 'preview-theme') {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME}/${card?.imageButtonData.item?.data?.id}` }));
    }


    if (card.imageButtonData.cardOneButtonOutput.button.name === 'set-active-layout') {
      this.clickedLayoutCardId = card?.imageButtonData.item?.data?.id;
      this.store.dispatch(new ChangeDefaultLayout({ layoutId: card?.imageButtonData.item?.data?.id }));
    }

    if (card.imageButtonData.cardOneButtonOutput.button.name === 'set-active-theme') {
      this.clickedThemeCardId = card?.imageButtonData.item?.data?.id;
      if (this.clickedThemeCardId && this.activeProject?.id) {
        this.store.dispatch(new SetAsActiveProjectTheme({ projectThemeId: this.clickedThemeCardId, activeProjectId: this.activeProject.id }));
      }
    }

  }

  onCardDetail(cardDetail: any) {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    if (cardDetail.item.type === 'page') {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${cardDetail.item.data.id}` }));
    } else if (cardDetail.item.type === 'layout') {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.CONTENT_EDITOR.CONTENT_EDITOR_PATH}/${cardDetail.item.data.id}` }));
      this.clickedLayoutCardId = cardDetail.item.data.id;
      this.store.dispatch(new ChangeDefaultLayout({ layoutId: cardDetail.item.data.id }));
    } else if (cardDetail.item.type === 'message-template') {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_EDIT_MESSAGE_TEMPLATE}/${cardDetail.item.data.id}` }));
    } else if (cardDetail.item.type === 'theme') {
      this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME}/${cardDetail.item?.data?.id}` }));
      this.clickedThemeCardId = cardDetail.item?.data?.id;
      if (this.clickedThemeCardId && this.activeProject?.id) {
        this.store.dispatch(new SetAsActiveProjectTheme({ projectThemeId: this.clickedThemeCardId, activeProjectId: this.activeProject.id }));
      }
    }
  }

  onThemeButtonsClick() {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_THEME_CREATE}` }));
  }

  // #endregion

  // #region Action Card Click

  onPageBottomCardClick(event: CardOneListCardClickOutput) {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    const cardType = event.item.data.type;
    if (cardType === 'create-blank-page') {
      this.createBlankPage();
    } else if (cardType === 'create-ai-page') {
      this.createPageWithAI();
    }
  }

  createBlankPage() {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    console.log('Create Blank Page');
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PAGES.PAGE_CREATE_PATH}` }));
  }

  createPageWithAI() {
    console.log('Create Ai Page');
  }

  onTemplateBottomCardClick(event: CardOneListCardClickOutput) {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    const cardType = event.item.data.type;
    if (cardType === 'create-blank-template') {
      this.createBlankTemplate();
    } else if (cardType === 'create-ai-template') {
      this.createTemplateWithAI();
    }
  }

  createBlankTemplate() {
    if (this.layoutMode !== LayoutMode.ProjectDesign) {
      this.layoutModeChanged.emit(LayoutMode.ProjectDesign);
    }
    console.log('Create Blank Template');
    this.store.dispatch(new Navigate({ url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_MESSAGE_TEMPLATE}` }));
  }

  createTemplateWithAI() {
    console.log('Create Template With AI');
  }


  // #endregion

  // #region map items

  mapPageItems() {
    this.grouppedCards = this.modules?.map(module => ({
        key: module.title,
        value: module.id
      }));
    if (this.pages?.map?.length) {
      this.pageCardsConfig.cardsConfig.items = this.pages?.map(template => ({
        data: template,
        type: 'page',
        titles: [
          {
            type: HeadingType.H4,
            content: template.title
          }
        ],
        descriptions: [
          {
            typography: {
              fontSize: 'var(--subtext-font-size)'
            },
            content: template.description
          }
        ],
        isTooltipEnabledOnDescription: true,
        image: {
          source: template?.thumbnails?.length > 0 ? template.thumbnails[0].url : 'assets/img/page-templates/profile-template1-thumbnail.png'
        },
        imageTags: [{}],
        imageButtons: [{
          name: 'preview-page',
          icon: {
            name: 'eye',
            type: IconType.NgZorro,
            theme: NgZorroIconTheme.Outline
          },
          customColorSettings: {
            backgroundColor: 'var(--section-background-color)',
            color: 'var(--text-color)'
          },
          shadowSettings: {
            boxShadow: '0 0 0 var(--section-background-color)'
          },
          borderSettings: {
            borderColor: 'transparent'
          },
          size: ButtonSize.Small,
          tooltipText: 'Preview'
        }]
      })) as any;
    }
  }

  mapLayoutItems() {
    if (this.layouts?.length) {
      this.layoutCardsConfig.cardsConfig.items = this.layouts?.map(template => ({
        data: template,
        type: 'layout',
        titles: [
          {
            type: HeadingType.H4,
            content: template.title
          }
        ],
        descriptions: [
          {
            typography: {
              fontSize: 'var(--subtext-font-size)'
            },
            content: template.description
          }
        ],
        isTooltipEnabledOnDescription: true,
        image: {
          source: template?.thumbnails?.length > 0 ?
            template.thumbnails[0].url : 'assets/img/page-templates/profile-template1-thumbnail.png'
        },
        imageTags: [{}],
        imageButtons: [{
          name: 'preview-layout',
          icon: {
            name: 'eye',
            type: IconType.NgZorro,
            theme: NgZorroIconTheme.Outline
          },
          customColorSettings: {
            backgroundColor: 'var(--section-background-color)',
            color: 'var(--text-color)'
          },
          shadowSettings: {
            boxShadow: '0 0 0 var(--section-background-color)'
          },
          borderSettings: {
            borderColor: 'transparent'
          },
          size: ButtonSize.Small,
          tooltipText: 'Preview'
        },
        {
          icon: {
            name: 'check',
            type: IconType.NgZorro,
            theme: NgZorroIconTheme.Outline
          },
          name: 'set-active-layout',
          customColorSettings: {
            backgroundColor: 'var(--section-background-color)',
            color: 'var(--text-color)'
          },
          shadowSettings: {
            boxShadow: '0 0 0 var(--section-background-color)'
          },
          borderSettings: {
            borderColor: 'transparent'
          },
          size: ButtonSize.Small,
          tooltipText: 'Set as Active Layout'
        }],
      })) as any;
    }
  }

  mapThemeItems() {
    if (this.projectThemes?.length) {
      this.themeCardsConfig.cardsConfig.items = this.projectThemes?.map(template => ({
        data: template,
        type: 'theme',
        titles: [
          {
            type: HeadingType.H4,
            content: template.name
          }
        ],
        image: {
          source: template?.thumbnails?.length > 0 ? template.thumbnails[0].url : 'assets/img/page-templates/profile-template1-thumbnail.png'
        },
        imageTags: [{}],
        imageButtons: [{
          name: 'preview-theme',
          icon: {
            name: 'eye',
            type: IconType.NgZorro,
            theme: NgZorroIconTheme.Outline
          },
          customColorSettings: {
            backgroundColor: 'var(--section-background-color)',
            color: 'var(--text-color)'
          },
          shadowSettings: {
            boxShadow: '0 0 0 var(--section-background-color)'
          },
          borderSettings: {
            borderColor: 'transparent'
          },
          size: ButtonSize.Small,
          tooltipText: 'Preview'
        }],
      }));
    }
  }

  mapMessageTemplateItems() {
    if (this.messageTemplates?.length && this.messageTemplatesCardsConfig?.cardsConfig) {
      this.messageTemplatesCardsConfig.cardsConfig.items = this.messageTemplates.map(template => ({
        data: template,
        type: 'message-template',
        titles: [
          {
            type: HeadingType.H4,
            content: template?.key ?? 'No Key'
          }
        ],
        descriptions: [
          {
            typography: {
              fontSize: 'var(--subtext-font-size)'
            },
            content: template.description
          }
        ],
        isTooltipEnabledOnDescription: true,
        image: {
          source: (template?.thumbnails?.[0]?.url) || 'assets/img/page-templates/profile-template1-thumbnail.png'
        },
        imageTags: [{}],
        imageButtons: [
          {
            name: 'preview-message-template',
            icon: {
              name: 'eye',
              type: IconType.NgZorro,
              theme: NgZorroIconTheme.Outline
            },
            customColorSettings: {
              backgroundColor: 'var(--section-background-color)',
              color: 'var(--text-color)'
            },
            shadowSettings: {
              boxShadow: '0 0 0 var(--section-background-color)'
            },
            borderSettings: {
              borderColor: 'transparent'
            },
            size: ButtonSize.Small,
            tooltipText: 'Preview'
          }
        ]
      }));
    } else {
      this.messageTemplatesCardsConfig.cardsConfig.items = []; // Clear items if no templates
    }
  }

  // #endregion


  onWizardModalTypingsCompleted(event) {
    console.log('*** AppComponent onWizardModalTypingsCompleted', event);

    if (event.typingsCompleted) {
      if (event.wizardStep === this.activeWizardStep && event.projectId === this.activeProject?.id) {
        this.triggerNextWizardStep();
      }

    }
  }


  triggerNextWizardStep() {
    if (this.activeProject?.id) {
      this.store.dispatch(NextStep({ payload: { activeProjectId: this.activeProject?.id } }));
    }
  }

  deployButtonClick() {
    this.store.dispatch(Deploy());
  }

}

