import {
  Component, EventEmitter, Input, OnChanges,
  OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentDefinitionInterface } from '@rappider/api-sdk';
import { ButtonComponentConfig, ButtonSize, CardsConfig, DropdownMenuItem, Menu } from '@rappider/rappider-components/utils';
import {
  CommentPartial, CommentWithRelations, ContainerTemplateWithRelations,
  NewPageComment, PageCommentWithRelations, PageWithRelations, PersonWithRelations, UiDataEventWithRelations,
  UiDataStoreWithRelations
} from '@rappider/rappider-sdk';
import { ContentEditorRightSidebarMenuTabKey } from '../../models/content-editor-right-sidebar-menu-tab-key.enum';
import { RIGHT_SIDEBAR_MENU_CONFIG, SIDEBAR_SIZES } from '../../models/right-sidebar-menu-config';
import { ContentTree, ContentTreeItem, ContentTreeItemType } from 'libs/content-tree-renderer/src/lib/models';
import { cloneDeep } from 'lodash';
import { ContainerSettingsComponent } from '../../components/container-settings/container-settings.component';
import { ComponentSettingsComponent } from '../../components/component-settings/component-settings.component';
import { CopyPage, SetActiveContentTreeItem } from '../../state/content-editor.actions';
import { sidebarToggleAnimation } from '@rappider/shared';
import { RIGHT_SIDEBAR_BOTTOM_MENU_CONFIG } from '../../models/right-sidebar-bottom-menu.config';
import { ModalButtonOptions } from 'ng-zorro-antd/modal';
import { CommentCategory } from 'libs/comment/src/lib/utils/comment-category.enum';
import { ContentEditorSettingsSection } from '../../constants/enums';
import { ThemeMode } from '@rappider/models';

enum SettingTabs {
  Pages = 0,
  PageSettings = 1,
  ComponentSettings = 2,
  Comments = 3
}

@Component({
  selector: 'rappider-content-editor-right-sidebar-menu',
  templateUrl: './content-editor-right-sidebar-menu.component.html',
  styleUrls: ['./content-editor-right-sidebar-menu.component.scss'],
  animations: [sidebarToggleAnimation]
})
export class ContentEditorRightSidebarMenuComponent implements OnInit, OnChanges {

  @ViewChild('containerSettingsComponentRef') containerSettingsComponent: ContainerSettingsComponent;
  @ViewChild('componentSettingsComponentRef') componentSettingsComponent: ComponentSettingsComponent;

  @Input() componentDefinitions: ComponentDefinitionInterface[];
  @Input() contentTree: ContentTree;
  @Input() page: PageWithRelations;
  @Input() activeContentTreeItem: ContentTreeItem;
  @Input() containerTemplates: ContainerTemplateWithRelations[];
  @Input() pageComments: PageCommentWithRelations[];
  @Input() activePerson: PersonWithRelations;
  @Input() peopleData: PersonWithRelations[];
  @Input() commentsLoading: boolean;
  @Input() selectedElementOnTitlebar: { button: ButtonComponentConfig, content: ContentTreeItem };
  @Input() componentsAndSnippetsModalVisibility: boolean;

  /**
   * Visibility of the right sidebar
   *
   * @memberof ContentEditorRightSidebarMenuComponent
   */
  @Input() visibility = true;
  @Input() uiDataEvents: UiDataEventWithRelations[];
  @Input() uiDataStores: UiDataStoreWithRelations[];
  @Input() activeCommentId: string;
  @Input() isDisplayingAllComments: boolean;
  @Input() isCommentActive: boolean;
  @Input() cardsConfig: CardsConfig;
  @Input() visiblePanelKeys?: string[] = [];
  @Input() isAddComponentDrawerVisible: boolean;
  @Input() allPanelsActive = true;
  @Input() preferredTheme: ThemeMode;

  @Output() clickCommentCard = new EventEmitter();
  @Output() createComment = new EventEmitter<NewPageComment>();
  @Output() editComment = new EventEmitter<CommentPartial>();
  @Output() deleteComment = new EventEmitter();
  @Output() labelMenuItemClick = new EventEmitter<Menu>();
  @Output() addComponentButtonClicked = new EventEmitter<string>();
  @Output() addComponentAndSnippetsButtonClicked = new EventEmitter<string>();
  @Output() visibilityChange = new EventEmitter<boolean>();
  @Output() pageSelected = new EventEmitter<string>();
  @Output() isCommentActiveEmit = new EventEmitter<boolean>();
  @Output() containerDeleteModalVisibility = new EventEmitter<boolean>();
  @Output() dropdownMenuItemClick = new EventEmitter<{ item: DropdownMenuItem; contentTreeItem: any }>();
  @Output() sidebarSizeChange = new EventEmitter<{ newSize: number; newMinSize: number }>();
  @Output() allPanelsActiveEmit = new EventEmitter<boolean>();

  @Output() snippetSelect = new EventEmitter();
  @Output() tagFilterChange = new EventEmitter();
  @Output() searchTextChange = new EventEmitter();
  @Output() pageIndexChange = new EventEmitter();
  @Output() avatarButtonClick = new EventEmitter();
  @Output() componentsAndSnippetsModalVisibilityChange = new EventEmitter();
  @Output() filterMenuItemClick = new EventEmitter();
  @Output() topButtonsClick = new EventEmitter();

  RIGHT_SIDEBAR_MENU_CONFIG = RIGHT_SIDEBAR_MENU_CONFIG;
  RIGHT_SIDEBAR_BOTTOM_MENU_CONFIG = cloneDeep(RIGHT_SIDEBAR_BOTTOM_MENU_CONFIG);

  ContentEditorSettingsSection = ContentEditorSettingsSection;
  /* default tab is content tree */
  activeMenuTabKey: string = ContentEditorRightSidebarMenuTabKey.ContentTree;
  ContentEditorRightSidebarMenuTabKey = ContentEditorRightSidebarMenuTabKey;
  activePageId: string;
  ContentEditorContentType = ContentTreeItemType;

  comments: CommentWithRelations[] = [];

  sidebarVisibility = false;
  globalStyleModalVisibility = false;
  themeModalVisibility = false;
  templatesModalVisibility = false;
  pageVariablesModalVisibility = false;
  templateModalFooterConfig: ModalButtonOptions[] = null;
  closeButtonIcon = {
    type: 'FONT_AWESOME',
    name: 'fa-solid fa-xmark',
    size: ButtonSize.ExtraSmall,
    customColorSettings: {
      backgroundColor: 'var(--component-header-background-color)',
      color: '#ffffff'
    },
    borderSettings: {
      borderColor: 'var(--component-header-background-color)'
    },
    redirectTarget: 'page',
    redirectUrl: 'projects/list'
  };

  /**
   * Filtered comments by the active content tree item id
   *
   * @type {CommentWithRelations[]}
   * @memberof ContentEditorRightSidebarMenuComponent
   */
  displayedContentTreeItemComments: CommentWithRelations[] = [];

  /* Tab index under the header */
  selectedTabIndex = 2;

  constructor(
    private store: Store<any>
  ) { }

  ngOnInit(): void {
    this.subscribeToActivePageId();
  }

  getCommentsByActiveContentTreeItemId(contentTreeItemId: string) {
    return this.pageComments.filter(pageComment => pageComment.contentTreeItemId === contentTreeItemId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page || changes.uiDataEvents) {
      this.mapUIDataEventsToPageLifecycleMethods();
    }

    if (changes.pageComments) {
      this.comments = this.pageComments.map(pageComment => pageComment.comment).filter(c => c);
    }

    if (changes.selectedElementOnTitlebar) {
      if (this.selectedElementOnTitlebar?.button?.key === 'component-add-comment') {
        this.updateCollapsePanelVisibilities('', [ContentEditorSettingsSection.ComponentComments]);
      }
      if (this.selectedElementOnTitlebar?.button?.key === 'container-add-comment') {
        this.updateCollapsePanelVisibilities('', [ContentEditorSettingsSection.ContainerComments]);
      }
    }
    if (changes.allPanelsActive) {
      if (this.allPanelsActive) {
        this.updateCollapsePanelVisibilities('expand-all');
      }
    }
  }

  onMenuItemClick(menuItem: Menu) {
    if (menuItem.key === ContentEditorRightSidebarMenuTabKey.AddComponent) {
      this.addComponentButtonClicked.emit('elements');
    }
    if (menuItem.key === ContentEditorRightSidebarMenuTabKey.AddComponentAndSnippets) {
      this.addComponentAndSnippetsButtonClicked.emit('snippets');
      this.componentsAndSnippetsModalVisibilityChange.emit(true);
    }
    if (menuItem.key === ContentEditorRightSidebarMenuTabKey.Sidebar) {
      this.toggleSidebarVisibility();
    }
  }

  showSidebar(visibility: boolean) {
    if (visibility && !this.sidebarVisibility) {
      const visibilitySidebar = this.RIGHT_SIDEBAR_MENU_CONFIG.items.find(item => item.key === ContentEditorRightSidebarMenuTabKey.Sidebar);

      if (visibilitySidebar) {
        visibilitySidebar.icon.name = 'menu-unfold';
      }

      this.sidebarVisibility = visibility;
      const sizes = SIDEBAR_SIZES.EXPANDED;
      this.sidebarSizeChange.emit({ newSize: sizes.size, newMinSize: sizes.minSize });

      this.RIGHT_SIDEBAR_MENU_CONFIG = {
        ...this.RIGHT_SIDEBAR_MENU_CONFIG,
        items: [...this.RIGHT_SIDEBAR_MENU_CONFIG.items],
      };
    }
  }

  toggleSidebarVisibility() {
    this.sidebarVisibility = !this.sidebarVisibility;

    const visibilitySidebar = this.RIGHT_SIDEBAR_MENU_CONFIG.items.find(item => item.key === ContentEditorRightSidebarMenuTabKey.Sidebar);
    if (visibilitySidebar) {
      visibilitySidebar.icon.name = this.sidebarVisibility ? 'menu-unfold' : 'menu-fold';
    }

    const sizes = this.sidebarVisibility ? SIDEBAR_SIZES.EXPANDED : SIDEBAR_SIZES.COLLAPSED;
    this.sidebarSizeChange.emit({ newSize: sizes.size, newMinSize: sizes.minSize });

    this.RIGHT_SIDEBAR_MENU_CONFIG = {
      ...this.RIGHT_SIDEBAR_MENU_CONFIG,
      items: [...this.RIGHT_SIDEBAR_MENU_CONFIG.items],
    };
  }

  onAddComponentAndSnippetsButtonClick() {
    this.addComponentAndSnippetsButtonClicked.emit('snippets');
    this.componentsAndSnippetsModalVisibilityChange.emit(true);
  }

  onAvatarButtonClick(avatarButton) {
    this.avatarButtonClick.emit(avatarButton);
  }

  onBottomMenuItemClick(menuItem: Menu) {
    if (menuItem.key === ContentEditorRightSidebarMenuTabKey.UpdateGlobalStyle) {
      this.handleGlobalStyleModalVisibility(true);
    } else if (menuItem.key === ContentEditorRightSidebarMenuTabKey.UpdateTheme) {
      this.handleThemeModalVisibility(true);
    } else if (menuItem.key === ContentEditorRightSidebarMenuTabKey.AddTemplateToPage) {
      this.handleTemplatesModalVisibility(true);
    }
  }

  handleGlobalStyleModalVisibility(visibility: boolean) {
    this.globalStyleModalVisibility = visibility;
  }

  handleThemeModalVisibility(visibility: boolean) {
    this.themeModalVisibility = visibility;
  }

  handleTemplatesModalVisibility(visibility: boolean) {
    this.templatesModalVisibility = visibility;
  }

  handlePageVariablesModalVisibility(visibility: boolean) {
    this.pageVariablesModalVisibility = visibility;
  }

  onTemplatePageSelect(selectedTemplateId) {
    if (selectedTemplateId) {
      this.templateModalFooterConfig = [
        {
          label: 'Select Template',
          onClick: () => {
            this.store.dispatch(new CopyPage({
              targetPageId: this.page.id,
              sourcePageId: selectedTemplateId
            }));
            this.handleTemplatesModalVisibility(false);
          }
        }
      ];
    } else {
      this.templateModalFooterConfig = null;
    }
  }


  subscribeToActivePageId() {
    return this.store.select(state => state.contentEditor?.page?.id).subscribe(activePageId => {
      this.activePageId = activePageId;
    });
  }

  onSeeAllCommentsButtonClick() {
    // you should be seeing all :)

    // this.activeMenuTabKey = this.ContentEditorRightSidebarMenuTabKey.Comments;
    // this.setMenuItemAsSelected(this.ContentEditorRightSidebarMenuTabKey.Comments);
  }

  // setMenuItemAsSelected(key: string) {
  //   this.RIGHT_SIDEBAR_MENU_CONFIG.items.forEach(item => {
  //     if (item.key === key) {
  //       item.selected = true;
  //     } else {
  //       item.selected = false;
  //     }
  //   });
  //   this.RIGHT_SIDEBAR_MENU_CONFIG = { ...this.RIGHT_SIDEBAR_MENU_CONFIG };
  // }

  mapUIDataEventsToPageLifecycleMethods() {
    this.page = {
      ...this.page,
      uiDataEventIdsOnInit: this.page?.uiDataEventIdsOnInit ?? [],
      uiDataEventIdsOnDestroy: this.page?.uiDataEventIdsOnDestroy ?? [],
      uiDataEventsOnInit: this.uiDataEvents?.filter(event => this.page?.uiDataEventIdsOnInit?.includes(event.id)) ?? [],
      uiDataEventsOnDestroy: this.uiDataEvents?.filter(event => this.page?.uiDataEventIdsOnDestroy?.includes(event.id)) ?? [],
    };
  }

  onClickCommentCard(event) {
    this.clickCommentCard.emit(event);
  }

  onCreateComment(event) {
    this.createComment.emit(event);
  }

  onEditComment(event) {
    this.editComment.emit(event);
  }

  onDeleteComment(event) {
    this.deleteComment.emit(event);
  }

  onComponentSettingsLabelMenuItemClick(event) {
    this.labelMenuItemClick.emit(event);
  }

  onContentTreeSelectedTreeNodeChange(contentTreeItem: ContentTreeItem) {
    this.store.dispatch(new SetActiveContentTreeItem({ contentTreeItemId: contentTreeItem.id }));
  }

  onContainerDeleteModalVisibility(visibility: boolean) {
    this.containerDeleteModalVisibility.emit(visibility);
  }

  // addElementModalVisibilityChange(visibility) {
  //   this.addElementModalVisibility.emit(visibility);
  // }

  onVisibilityChange(visibility: boolean) {
    this.visibility = visibility;
    this.visibilityChange.emit(this.visibility);
  }

  onPageSelected(pageId) {
    this.pageSelected.emit(pageId);
  }

  onDropdownMenuItemClick(event) {
    this.dropdownMenuItemClick.emit(event);
  }

  onVerticalSplitDragEnds(event) {
  }

  onHorizontalSplitDragEnds(event) {
  }


  selectedTabIndexChange(index: number) {
    this.selectedTabIndex = index;
    this.allPanelsActive = true;
    this.visiblePanelKeys = [];
  }

  updateCollapsePanelVisibilities(mode: string, visiblePanelKeys?: string[]) {
    this.allPanelsActive = true;
    setTimeout(() => {
      if (mode === 'expand-all') {
        this.allPanelsActive = true;
        this.visiblePanelKeys = [];
        this.allPanelsActiveEmit.emit(true);
      } else if (mode === 'collapse-all') {
        this.allPanelsActive = false;
        this.visiblePanelKeys = [];
        this.allPanelsActiveEmit.emit(false);
      } else {
        this.allPanelsActive = false;
        this.allPanelsActiveEmit.emit(false);
        this.visiblePanelKeys = visiblePanelKeys;
      }
    }, 0);
  }

  onSnippetSelect(selectedSnippet) {
    this.snippetSelect.emit(selectedSnippet);
  }

  onTagFilterChange(tagFilter) {
    this.tagFilterChange.emit(tagFilter);
  }

  onSearchTextChange(searchText) {
    this.searchTextChange.emit(searchText);
  }

  onPageIndexChange(pageIndex) {
    this.pageIndexChange.emit(pageIndex);
  }

  onComponentsAndSnippetsModalVisibility(visible) {
    this.componentsAndSnippetsModalVisibilityChange.emit(visible);
  }

  onFilterMenuItemClick(menuItem) {
    this.filterMenuItemClick.emit(menuItem);
  }

  onTopButtonsClick(buttons) {
    this.topButtonsClick.emit(buttons);
  }

}
