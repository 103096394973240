import { Validators } from '@angular/forms';
import { ButtonType, CrudFormConfig, CrudFormSelectItem, CrudViewFormItemType, FormLayout } from '@rappider/rappider-components/utils';

export const AIContentConfigFormConfig: CrudFormConfig = {
  items: [
    <CrudFormSelectItem>{
      type: CrudViewFormItemType.Select,
      title: 'AI Update Type',
      fieldName: 'aiUpdateType',
      showRequiredSign: true,
      options: [
        {
          key: 'Subscription',
          value: 'subscription'
        },
        {
          key: 'Value',
          value: 'value'
        }
      ],
      validators: [
        {
          type: Validators.required,
          errorKey: 'required',
          errorMessage: 'This field is required'
        }
      ]
    },
    {
      type: CrudViewFormItemType.TextArea,
      title: 'Description',
      fieldName: 'description'
    }
  ],
  formLayout: FormLayout.Vertical,
  submitButton: { type: ButtonType.Primary }
};
