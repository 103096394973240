import { Component, Input, OnInit, ViewContainerRef, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentInterface } from '@rappider/api-sdk';
import { ComponentDefinitionWithRelations } from '@rappider/rappider-sdk';
import { RenderComponentService } from '@rappider/services';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { UpdateComponentInputs } from '../../state/content-editor.actions';
import { getComponentDefinitionsWithDetailsSelector } from '@rappider/shared';

@Component({
  selector: 'rappider-component-input-variation-select',
  templateUrl: './component-input-variation-select.component.html',
  styleUrls: ['./component-input-variation-select.component.scss']
})
export class ComponentInputVariationSelectComponent implements OnInit, OnDestroy {

  /**
   * container ref for render component view dynamically
   *
   * @type {ViewContainerRef}
   * @memberof ComponentInputVariationSelectComponent
   */
  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer: ViewContainerRef;

  /**
   * actively selected component
   *
   * @type {ComponentInterface}
   * @memberof ComponentInputVariationSelectComponent
   */
  @Input() activeComponent: ComponentInterface;

  /* select options */
  options = [];
  /* selected input variations */
  selectedVariation;
  /* component definition with all relations */
  @Input() componentDefinitionWithDetails: ComponentDefinitionWithRelations;
  /* subscription */
  subscription: Subscription;

  constructor(
    private store: Store<any>,
    private renderComponentService: RenderComponentService
  ) { }

  ngOnInit(): void {
    this.subscription = this.subscribeToComponentDefinition();
  }

  ngOnChanges(): void {
    this.subscription = this.subscribeToComponentDefinition();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Subscribe component definition with relations data from state
   *
   * @return {*}
   * @memberof ComponentInputVariationSelectComponent
   */
  subscribeToComponentDefinition() {
    return this.store.select(<any>getComponentDefinitionsWithDetailsSelector).subscribe(componentDefinitionWithDetails => {
      if (componentDefinitionWithDetails) {
        this.componentDefinitionWithDetails = componentDefinitionWithDetails
          .find(componentDefinitionWithDetail => componentDefinitionWithDetail.id === this.activeComponent.componentDefinitionId);
        this.mapComponentSamplesForSelectOptions();
        this.setActiveVariation();
      }
    });
  }

  /**
   * Sets select options by component samples
   * First item is always must be Default Inputs
   *
   * @memberof ComponentInputVariationSelectComponent
   */
  mapComponentSamplesForSelectOptions() {
    /* map samples to options if any sample exist */
    if (this.componentDefinitionWithDetails?.samples && this.componentDefinitionWithDetails?.samples?.length) {
      this.options = [
        {
          key: 'Default Inputs',
          value: 'default',
        },
        ...<any[]>this.componentDefinitionWithDetails?.samples?.map(sample => ({
          key: sample.title,
          value: sample.id
        }))
      ];
    }
  }

  /**
   * sets active variation value when component change
   *
   * @memberof ComponentInputVariationSelectComponent
   */
  setActiveVariation() {
    if (isEqual(this.componentDefinitionWithDetails.defaultInputs, this.activeComponent.inputs)) {
      this.selectedVariation = 'default';
    } else {
      this.componentDefinitionWithDetails?.samples?.forEach(sample => {
        if (isEqual(sample.inputs, this.activeComponent.inputs)) {
          this.selectedVariation = sample.id;
        }
      });
    }
  }

  /**
   * find input object by variation id
   *
   * @param {string} id
   * @return {*}
   * @memberof ComponentInputVariationSelectComponent
   */
  findInputsByVariationId(id: string) {
    let inputs = {};
    if (id === 'default') {
      inputs = this.componentDefinitionWithDetails.defaultInputs;
    } else {
      inputs = this.componentDefinitionWithDetails.samples.find(sample => sample.id === id).inputs;
    }

    return inputs;
  }

  /**
   * dispatches action when select value change
   *
   * @param {string} inputId
   * @memberof ComponentInputVariationSelectComponent
   */
  onVariationSelect(inputId: string) {
    const inputs = this.findInputsByVariationId(inputId);
    this.store.dispatch(new UpdateComponentInputs({ componentId: this.activeComponent.id, inputs: inputs, parentPageComponentContentId: null }));
  }

  /**
   * Runs when popover visibility change
   * if visibility is true finds inputs and pass to renderComponentDynamicallyWithInputValues fn
   *
   * @param {boolean} visibility
   * @param {string} inputId
   * @memberof ComponentInputVariationSelectComponent
   */
  onPopoverVisibilityChange(visibility: boolean, inputId: string) {
    if (visibility) {
      const inputs = this.findInputsByVariationId(inputId);
      /* To reach the #componentContainer template, #popoverContentTemplate must will be created
       * wait 1ms for the #popoverContentTemplate template to be created
       */
      setTimeout(() => this.renderComponentDynamicallyWithInputValues(this.componentDefinitionWithDetails.className, inputs), 1);
    }
  }

  /**
   * Renders component dynamically in #componentContainer
   *
   * @param {string} componentClassName
   * @param {*} inputValues
   * @memberof ComponentInputVariationSelectComponent
   */
  renderComponentDynamicallyWithInputValues(componentClassName: string, inputValues: any) {
    if (this.componentContainer) {
      this.componentContainer.clear();
      this.renderComponentService.renderComponentAndAddToContainer(componentClassName, this.componentContainer, inputValues);
    }
  }

}
