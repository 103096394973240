import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concat, Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  PersonProjectControllerService,
  PersonProjectWithRelations, Project,
  ProjectControllerService,
  ProjectUpdateDtoPartial
} from '@rappider/rappider-sdk';
import { BrowserStorageManagementService, NotificationService, PaginationService } from '@rappider/services';
import { PATH_DEFINITIONS } from '@rappider/shared/definitions';
import { environment } from 'apps/rappider/src/environments/environment';
import { Navigate } from 'libs/shared/src/lib/states/router/router.actions';
import { GetActiveProject } from '../active-project-state/active-project.actions';
import * as ProjectActions from './project.actions';
import * as AdminProjectsActions from 'libs/admin-dashboard/src/lib/state/admin-projects/admin-projects.actions';
import * as AuthenticationActions from 'libs/authentication/src/lib/state/authentication.actions';

@Injectable()
export class ProjectEffects {
  constructor(
    private actions$: Actions,
    private projectApi: ProjectControllerService,
    private personProjectApi: PersonProjectControllerService,
    private store: Store<any>,
    private notificationService: NotificationService,
    private paginationService: PaginationService,
    private browserStorageManagementService: BrowserStorageManagementService,
  ) { }


  getPersonProjects = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.GetProjects>(
      ProjectActions.ActionTypes.GetProjects,
      AuthenticationActions.ActionTypes.AcceptInvitationByTokenSuccessful
    ),
    mergeMap(() =>
      this.personProjectApi.find().pipe(
        map((personProjects: PersonProjectWithRelations[]) =>
          new ProjectActions.GetPersonProjectsSuccessful({ personProjects })
        ),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'SHARED.COULDNT_LOAD'
          );
          return [
            new ProjectActions.GetProjectsFailure({ payload: { error, key: 'GetProjectsFailure', timestamp: Date.now() } })
          ];
        })
      )
    )
  ));

  getPublicProjectsCount$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.GetPublicProjectsPagination>(
      ProjectActions.ActionTypes.GetPublicProjectsPagination
    ),
    mergeMap((action) => {
      const params = {
        filter: {
          fields: {
            id: true,
            categoryIds: true
          }
        }
      } as any;
      return this.projectApi.findPublicProjects(this.handleGetPublicProjectsFilter(params.filter, action.payload.searchText, action.payload.categoryIds, true)).pipe(
        mergeMap(publicProjects => {
          const publicProjectCategoryIds = publicProjects.reduce((acc, page) => {
            if (page.categoryIds) {
              acc.push(...page.categoryIds);
            }
            return acc;
          }, []);
          const actions = [];
          if (!action.payload.searchText && !action.payload.categoryIds) {
            actions.push(new ProjectActions.GetPublicProjectsCategoryIds({ publicProjectCategoryIds }));
          }
          actions.push(new ProjectActions.GetPublicProjectsPaginationSuccessful({ pageIndex: action.payload.pageIndex, pageSize: action.payload.pageSize, searchText: action.payload.searchText, count: publicProjects.length, categoryIds: action.payload.categoryIds, isDynamicPagination: true }));
          return actions;
        }),
        catchError(error => [
          new ProjectActions.ProjectError({ error, errorOn: 'GetPublicProjectsFailure' }),
        ])
      );
    })
  ));

  getPublicProjects$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.GetPublicProjectsPaginationSuccessful | ProjectActions.GetPublicProjects | any>(
      ProjectActions.ActionTypes.GetPublicProjectsPaginationSuccessful,
      ProjectActions.ActionTypes.GetPublicProjects,
      AdminProjectsActions.ActionTypes.UpdateProjectByAdminSuccessful
    ),
    mergeMap((action) => {
      const params = {
        filter: {
          skip: this.paginationService.
            getSkippedSizeByPagination(action.payload.pageIndex, action.payload.pageSize),
          limit: action.payload.pageSize
        }
      };
      return this.projectApi.findPublicProjects(this.handleGetPublicProjectsFilter(params.filter, action.payload.searchText, action.payload.categoryIds, action.payload.isDynamicPagination)).pipe(
        map(publicProjects => new ProjectActions.GetPublicProjectsSuccessful({ publicProjects })
        ),
        catchError(error => [
          new ProjectActions.GetPublicProjectsFailure({ payload: { error, key: 'GetPublicProjectsFailure', timestamp: Date.now() } })
        ])
      );
    })
  ));

  getPersonProjectByProjectId = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.GetPersonProjectByProjectId>(
      ProjectActions.ActionTypes.GetPersonProjectByProjectId
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson?.id),
    ),
    mergeMap(([action, activePersonId]) => {
      const params = {
        filter: {
          where: {
            personId: activePersonId,
            projectId: action.payload.projectId
          }
        }
      };
      return this.personProjectApi.find(params).pipe(
        map((personProjects: PersonProjectWithRelations[]) => {
          if (personProjects?.length) {
            const personProject = personProjects[0];
            return new ProjectActions.GetPersonProjectByProjectIdSuccessful({
              personProject: personProject
            });
          } else {
            return new ProjectActions.GetPersonProjectByProjectIdFailure({ error: `Could not find PersonProject with projectId ${action.payload.projectId}` });
          }
        }, catchError(err => [new ProjectActions.GetPersonProjectByProjectIdFailure({ error: err })]))
      );
    })
  ));


  favoritePersonProjects = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.FavoritePersonProject>(
      ProjectActions.ActionTypes.FavoritePersonProject),
    mergeMap((action) => {
      const params = {
        projectId: action.payload.projectId
      };
      return this.personProjectApi.favorite(params).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'This project has been successfully changed to a favorite project'
          );
          return new ProjectActions.FavoritePersonProjectSuccessful({
            projectId: action.payload.projectId, isFavorited: action.payload.isFavorited
          });
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'This project could not be changed to a favorite project'
          );
          return [
            new ProjectActions.FavoritePersonProjectFailure({ payload: { error, key: 'FavoritePersonProjectFailure', timestamp: Date.now() } })
          ];
        })
      );
    })
  ));


  unfavoritePersonProjects = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.UnFavoritePersonProject>(
      ProjectActions.ActionTypes.UnFavoritePersonProject),
    mergeMap((action) => {
      const params = {
        projectId: action.payload.projectId
      };
      return this.personProjectApi.unfavorite(params).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'This project has been successfully changed to a non-favorite project'
          );
          return new ProjectActions.UnFavoritePersonProjectSuccessful({
            projectId: params.projectId, isFavorited: action.payload.isFavorited
          });
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'This project could not be changed to a non-favorite project'
          );
          return [
            new ProjectActions.UnFavoritePersonProjectFailure({ payload: { error, key: 'UnFavoritePersonProjectFailure', timestamp: Date.now() } })
          ];
        })
      );
    })
  ));


  getTotalCount$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.GetTotalCount>(
      ProjectActions.ActionTypes.GetTotalCount
    ),
    withLatestFrom(
      this.store.select(state => state.auth.activePerson?.id),
      this.store.select(state => state.project.pagination)
    ),
    mergeMap(([action, activePersonId, pagination]) => {
      const where = {
        personId: activePersonId
      };
      return this.projectApi.count({ where }).pipe(
        map((res: { count: number }) => new ProjectActions.SetPagination({
          pagination: {
            totalCount: res.count,
            totalPageNumber: this.paginationService.getTotalPageNumber(res.count, pagination.pageSize)
          }
        }))
      );
    }),
    catchError(error => [
      new ProjectActions.ProjectError({ errorOn: 'GetTotalCount', error: error }),
    ])
  ));

  /**
   * Get Projects
   *
   * @memberof ProjectsEffects
   */

  getProjects$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.GetProjects>(
      ProjectActions.ActionTypes.GetProjects,
      AuthenticationActions.ActionTypes.AcceptInvitationByTokenSuccessful
    ),
    mergeMap((action) => this.projectApi.find().pipe(
      mergeMap((projects: Project[]) => {
        if (projects) {
          return [
            new ProjectActions.SetProjects({ projects: projects })
          ];
        } else {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_COULDNOT_LOADED'
          );
          return [new ProjectActions.SetProjects({ projects: [] })];
        }
      }),
      catchError(error => [
        new ProjectActions.ProjectError({ errorOn: 'GetProjects', error: error }),
      ])
    ))
  ));


  setPagination$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.SetPagination>(
      ProjectActions.ActionTypes.SetPagination
    ),
    mergeMap(action => {
      if (action.payload.getDataAfter) {
        return of(new ProjectActions.GetProjects());
      } else {
        return [new ProjectActions.SetPaginationFailure({ error: 'There is no getDataAfter in payload', key: 'SetPaginationFailure', timestamp: Date.now() })];
      }
    })
  ));

  /**
   * Create Project
   *
   * @memberof ProjectsEffects
   */

  createProject$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.CreateProject>(
      ProjectActions.ActionTypes.CreateProject
    ),
    concatMap(action => {
      const projectData = {
        ...action.payload.project,
        metadata: action.payload.project.metadata ?? []
      };
      return this.projectApi.create({ body: projectData }).pipe(
        mergeMap((project: Project) => {
          const actions: Action[] = [
            new ProjectActions.CreateProjectSuccessful({ project: project }),
            new GetActiveProject({
              projectId: project.id,
              navigateToProjectDetail: !project.isCreatedByAI,
              navigateAIAssistantPage: project.isCreatedByAI
            })
          ];
          this.notificationService.createNotification(
            'success',
            'Your project has been successfully created',
            'It is ready for further development. Use builder tools on the left menu and get help from our AI to enhance its features.',
            { nzDuration: environment.successNotificationVisibilityDuration }
          );

          return actions;
        }),
        catchError(error => {
          const errorMessage = error?.error?.error?.message;
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            errorMessage
          );
          return [
            new ProjectActions.CreateProjectFailure(),
            new ProjectActions.ProjectError({ errorOn: 'CreateProject', error: error }),
          ];
        })
      );
    })
  ));

  /**
   * Update project
   *
   * @memberof ProjectEffects
   */
  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ProjectActions.UpdateProject>(
        ProjectActions.ActionTypes.UpdateProject
      ),
      withLatestFrom(
        this.store.select((state) => state.auth.activePerson),
        this.store.select((state) => state.activeProject.data?.id)
      ),
      concatMap(([action, person, activeProjectId]) => {
        const result: Observable<Action>[] = [];

        // Optimistic update action
        result.push(
          of(
            new ProjectActions.UpdateProjectSuccessful({
              project: action.payload.project,
              projectId: action.payload.projectId,
            })
          )
        );

        const body: ProjectUpdateDtoPartial = {
          ...action.payload.project,
          metadataCRUD: {
            newMetadataItems: action.payload.metadataCRUD?.newMetadataItems ?? [],
            updatedMetadataItems: action.payload.metadataCRUD?.updatedMetadataItems ?? [],
            deletedMetadataIds: action.payload.metadataCRUD?.deletedMetadataIds ?? [],
          },
        };

        if (action.payload.project) {
          result.push(
            this.projectApi.updateById({
              id: action.payload.projectId,
              body,
            }).pipe(
              map((project) => {
                if (project) {
                  this.notificationService.createNotification(
                    'success',
                    'SHARED.SUCCESSFUL',
                    action.payload.notificationMessageOnSuccess ||
                    'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_UPDATED'
                  );
                  return new ProjectActions.UpdateProjectSuccessful({
                    project: project,
                    projectId: action.payload.projectId,
                  });
                } else {
                  this.notificationService.createNotification(
                    'error',
                    'SHARED.ERROR',
                    action.payload.notificationMessageOnSuccess ||
                    'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_COULDNOT_UPDATED'
                  );
                  return new ProjectActions.UpdateProjectFailure({
                    error: 'Project could not be updated',
                    key: 'UpdateProjectFailure',
                    timestamp: Date.now(),
                  });
                }
              }),
              catchError((error) =>
                of(
                  new ProjectActions.UpdateProjectFailure({
                    error: 'Project could not be updated',
                    key: 'UpdateProjectFailure',
                    timestamp: Date.now(),
                  })
                )
              )
            )
          );
        }

        if (action.payload.redirectToProjectDetail !== false) {
          result.push(
            of(
              new Navigate({
                url: `${PATH_DEFINITIONS.PROJECTS.PROJECT_DETAIL_PATH}/${activeProjectId}`,
              })
            )
          );
        }

        return concat(...result);
      })
    )
  );


  /**
   * Delete Project
   *
   * @memberof ProjectsEffects
   */

  deleteProject$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.DeleteProject>(
      ProjectActions.ActionTypes.DeleteProject
    ),
    mergeMap((action) => {
      /* get projectId by action payload */
      const projectId = action.payload.projectId;
      /* check projectId is specified */
      if (!projectId) {
        return [new ProjectActions.DeleteProjectFailure({ error: 'There is no projectId', key: 'DeleteProjectFailure', timestamp: Date.now() })];
      }
      /* delete project */
      return this.projectApi.deleteById({ projectId: action.payload.projectId }).pipe(
        map(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_DELETED'
          );
          this.browserStorageManagementService.removeLocalStorageItem('activeProjectId');
          return new ProjectActions.DeleteProjectSuccessful({ projectId: action.payload.projectId });
        }),
        catchError(error => {
          const errorObject = JSON.parse(error.error);
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            errorObject?.error?.message || 'PROJECT_MODULE.PROJECT_NOTIFICATIONS.PROJECT_COULDNOT_DELETED'
          );
          return [
            new ProjectActions.ProjectError({ errorOn: ProjectActions.ActionTypes.DeleteProject, error: error })
          ];
        })
      );
    })
  ));


  setAsActiveProjectTheme$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectActions.SetAsActiveProjectTheme>(
      ProjectActions.ActionTypes.SetAsActiveProjectTheme
    ),
    mergeMap((action) => {
      const params = {
        id: action.payload.activeProjectId,
        body: {
          activeProjectThemeId: action.payload.projectThemeId
        }
      };

      return this.projectApi.updateById(params).pipe(
        mergeMap(() => {
          this.notificationService.createNotification(
            'success',
            'SHARED.SUCCESSFUL',
            'Project theme successfully set to active'
          );
          return [
            new ProjectActions.SetAsActiveProjectThemeSuccessful({ projectThemeId: action.payload.projectThemeId, activeProjectId: action.payload.activeProjectId })
          ];
        }),
        catchError(error => {
          this.notificationService.createNotification(
            'error',
            'SHARED.ERROR',
            'Project theme could not set to active'
          );
          return [
            new ProjectActions.ProjectError({ errorOn: ProjectActions.ActionTypes.SetAsActiveProjectTheme, error: error })
          ];
        })
      );
    })
  ));

  private handleGetPublicProjectsFilter(filter, searchText, categoryIds, isDynamicPagination) {
    const params = {
      filter: filter
    } as any;

    if (searchText && searchText !== '' && isDynamicPagination) {
      params.filter.where = {
        ...params.filter.where,
        name: { regexp: `/${searchText}/i` }
      };
    }
    if (categoryIds?.length && isDynamicPagination) {
      params.filter.where = {
        ...params.filter.where,
        categoryIds: { inq: categoryIds }
      };
    }
    return params;
  }
}
