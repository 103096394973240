import { ButtonComponentConfig, ButtonSize } from '@rappider/rappider-components/utils';

export const DiagramButtonConfig: ButtonComponentConfig[] = [
  {
    icon: { name: 'far fa-circle' },
    text: 'Create Workflow Event',
    size: ButtonSize.Small
  },
  {
    icon: { name: 'far fa-rectangle' },
    text: 'Create Workflow Step Function',
    size: ButtonSize.Small
  }
];
