import { GrouppedOption } from "libs/components/src/lib/utils/select/groupped-option.interface";

export const INTERESTS: GrouppedOption[] = [
  // Enterprise Systems
  {
    groupLabel: 'Enterprise Systems',
    label: 'Enterprise Resource Planning (ERP)',
    value: 'enterprise-resource-planning'
  },
  {
    groupLabel: 'Enterprise Systems',
    label: 'Customer Relationship Management (CRM)',
    value: 'customer-relationship-management'
  },
  {
    groupLabel: 'Enterprise Systems',
    label: 'Human Resource Management',
    value: 'human-resource-management'
  },
  {
    groupLabel: 'Enterprise Systems',
    label: 'Supply Chain Management',
    value: 'supply-chain-management'
  },
  // Business Operations
  {
    groupLabel: 'Business Operations',
    label: 'Invoice Management',
    value: 'invoice-management'
  },
  {
    groupLabel: 'Business Operations',
    label: 'Inventory Management',
    value: 'inventory-management'
  },
  {
    groupLabel: 'Business Operations',
    label: 'Procurement System',
    value: 'procurement-system'
  },
  {
    groupLabel: 'Business Operations',
    label: 'Accounting System',
    value: 'accounting-system'
  },
  // Industry Solutions
  {
    groupLabel: 'Industry Solutions',
    label: 'School Management System',
    value: 'school-management-system'
  },
  {
    groupLabel: 'Industry Solutions',
    label: 'Tour Management System',
    value: 'tour-management-system'
  },
  {
    groupLabel: 'Industry Solutions',
    label: 'Event Management System',
    value: 'event-management-system'
  },
  {
    groupLabel: 'Industry Solutions',
    label: 'Healthcare Management',
    value: 'healthcare-management'
  },
  // Project Tools
  {
    groupLabel: 'Project Tools',
    label: 'Project Management',
    value: 'project-management'
  },
  {
    groupLabel: 'Project Tools',
    label: 'Document Management',
    value: 'document-management'
  },
  {
    groupLabel: 'Project Tools',
    label: 'Task Management',
    value: 'task-management'
  },
  {
    groupLabel: 'Project Tools',
    label: 'Team Collaboration',
    value: 'team-collaboration'
  },
  // Others
  {
    groupLabel: 'Others',
    label: 'Others',
    value: 'others'
  },
];
