<div class="card-one-list row"
     [ngStyle]="{ height: maxHeight }">
  <ng-container *ngFor="let item of dataToDisplay">
    <rappider-card-one [class]="calculateColumns()"
                       [data]="item.data"
                       [image]="item.image"
                       [imageTags]="item.imageTags"
                       [imageButtons]="item.imageButtons"
                       [imageButtonPlacement]="item?.imageButtonPlacement"
                       [rate]="item.rate"
                       [titles]="item.titles"
                       [currency]="item.currency"
                       [finalPrice]="item.finalPrice"
                       [price]="item.price"
                       [additionalTags]="item.additionalTags"
                       [additionalButtons]="item.additionalButtons"
                       [descriptions]="item.descriptions"
                       [isTooltipEnabledOnDescription]="item.isTooltipEnabledOnDescription"
                       [avatar]="item.avatar"
                       [avatarButton]="item.avatarButton"
                       [avatarPlacement]="item.avatarPlacement"
                       [isSelected]="item.isSelected"
                       [selectedCardIcon]="item.selectedCardIcon"
                       [showTitleOnImage]="showTitleOnImage"
                       [showDescriptionOnImage]="showDescriptionOnImage"
                       [showPopover]="showPopover"
                       [disabledCard]="item.disabledCard"
                       [cardTooltipContent]="item.cardTooltipContent"
                       [cardTooltipPlacement]="item.cardTooltipPlacement"
                       [showPopoverImage]="showPopoverImage"
                       [displayMode]="displayMode"
                       [useDescriptionIfNoImage]="item.useDescriptionIfNoImage"
                       (cardClick)="onCardClick($event, item)"
                       (imageButtonClick)="onImageButtonClick($event, item)"
                       (additionalButtonClick)="onAdditionalButtonClick($event, item)"
                       (avatarButtonClick)="onAvatarButtonClick($event, item)"></rappider-card-one>
  </ng-container>
  <rappider-pagination *ngIf="pagination"
                       [(pageIndex)]="paginationPageIndex"
                       [(pageSize)]="pageSize"
                       [total]="items?.length"
                       (pageIndexChange)="onPageIndexChange($event)"></rappider-pagination>
</div>
