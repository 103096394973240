import { Component } from '@angular/core';

@Component({
  selector: 'rappider-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class RappiderGoogleMapsComponent {

  center = { lat: 41.0214, lng: 29.0044 }; // Kız Kulesi konumu
  mapOptions = {
    mapTypeId: 'roadmap',
    apiKey: ''
  };

}
