import { createSelector } from '@ngrx/store';
import { Category } from '@rappider/api-sdk';
import { ProjectWithRelations } from '@rappider/rappider-sdk';

export const getPublicProjectWithCategoriesSelector = createSelector(
  state => state['project']?.publicProjectCategories,
  state => state['project']?.publicProjectCategoryIds,
  state => state['project']?.publicProjects,
  (publicProjectCategories: Category[], publicProjectCategoryIds: string[], publicProjects: ProjectWithRelations[]) => {
    if (publicProjectCategories?.length && publicProjectCategoryIds && publicProjects?.length) {
      return {
        publicProjects,
        projectCategoryWithIds: publicProjectCategories.map(tag => ({
          value: publicProjectCategoryIds.find(category => category === tag.id),
          key: tag.title
        }))
      }
    }
  }
);
