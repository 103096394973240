import { ButtonSize } from "libs/components/src/lib/utils/button";

export const headerButtons = {
  upgradeButton: {
    text: 'Upgrade Now',
    customColorSettings: {
      color: '#5682d4',
      backgroundColor: 'transparent'
    },
    icon: {
      name: 'fa-solid fa-star-of-life',
      color: '#5682d4'
    },
    borderSettings: {
      borderColor: 'transparent'
    },
    size: ButtonSize.ExtraSmall
  }
}
