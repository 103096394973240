import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CategorizedData } from '@rappider/models';
import { IconType, InputGroupComponentConfig } from '@rappider/rappider-components/utils';
import { ModuleWithRelations, Page } from '@rappider/rappider-sdk';
import { DEFAULT_MODULE_TITLE } from '@rappider/shared/definitions';
import { camelCase, groupBy, orderBy } from 'lodash';
import { NzFormatEmitEvent } from 'ng-zorro-antd/tree';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rappider-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss']
})
export class PageMenuComponent implements OnInit, OnDestroy {

  @Output() pageSelected = new EventEmitter<string>();

  categorizedPagesByModules: CategorizedData[] = [];
  pages: Page[];
  searchText: string;
  selectedKeys: any[] = [];
  pageId: string;
  modules: ModuleWithRelations[];

  dataSource: any[];

  inputGroupConfig: InputGroupComponentConfig = {
    textbox: {
      placeholder: 'Search in Pages'
    },
    suffixIcon: {
      name: 'fas fa-search',
      type: IconType.FontAwesome
    }
  };

  subscriptions: Subscription[] = [];

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.subscribeToPages(),
      this.subscribeToModules()
    ];
    this.getPageIdFromUrl();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getPageIdFromUrl() {
    this.pageId = this.activatedRoute.snapshot.params['pageId'];
    if (this.pageId) {
      this.selectedKeys.push(this.pageId);
    }
  }

  subscribeToModules() {
    return this.store.select(state => state.module.data).subscribe(modules => {
      this.modules = modules;
      this.categorizePages();
    });
  }

  subscribeToPages() {
    return this.store.select(state => state.page?.data).subscribe((pages: Page[]) => {
      this.pages = pages;
      this.categorizePages();
    });
  }

  categorizePages() {
    if (this.pages && this.modules) {
      this.categorizedPagesByModules = [];

      const validPages = this.pages.filter(page =>
        this.modules.some(mod => mod.id === page.moduleId)
      );

      const categorizedPages = groupBy(validPages, (page) => {
        const module = this.modules.find(mod => mod.id === page.moduleId);
        return module.title;
      });

      this.categorizedPagesByModules = Object.entries(categorizedPages).map(([key, value]) => ({
        title: key,
        data: value
      }));

      this.dataSource = orderBy(this.categorizedPagesByModules.map(module => ({
        title: module.title,
        key: camelCase(module.title),
        expanded: true,
        children: orderBy(module.data.map(page => ({
          ...page,
          key: page.id,
          isLeaf: true
        })), 'title', 'asc')
      })), [(item) => item.title !== DEFAULT_MODULE_TITLE, 'title'], ['asc', 'asc']);
    }
  }

  onClickNode(event: NzFormatEmitEvent) {
    if (event.node.level === 1) {
      const itemId = event.node.origin.id;
      this.pageSelected.emit(itemId);
    }
  }

}
