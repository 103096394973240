import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Menu, MenuConfig } from '@rappider/rappider-components/utils';
import { toLower } from 'lodash';
import { SetActiveDiagramItem } from '../../state/diagram-state/diagram.actions';
import { CollapseAllButtonConfig } from '../../utils/definitions/diagram-workflow-menu/collapse-all-button.config';
import { ExpandAllButtonConfig } from '../../utils/definitions/diagram-workflow-menu/expand-all-button.config';
import { SearchBarConfig } from '../../utils/definitions/diagram-workflow-menu/search-bar.config';
import { DiagramTreeService } from '../../utils/services/diagram-tree.service';
import { WorkflowItemInDiagram } from '../../utils/workflow-item-in-diagram.interface';

@Component({
  selector: 'rappider-diagram-workflow-menu',
  templateUrl: './diagram-workflow-menu.component.html',
  styleUrls: ['./diagram-workflow-menu.component.scss']
})
export class DiagramWorkflowMenuComponent implements OnChanges {
  @Input() workflowMenu: MenuConfig;
  @Input() activeItem: WorkflowItemInDiagram;

  /* Searched string for teh workflow menu*/
  searchText: string;
  displayedWorkflowMenu: MenuConfig;

  SearchBarConfig = SearchBarConfig;
  CollapseAllButtonConfig = CollapseAllButtonConfig;
  ExpandAllButtonConfig = ExpandAllButtonConfig;

  constructor(
    private store: Store<any>,
    private diagramTreeService: DiagramTreeService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeItem) {
      this.setActiveMenuItem();
    }
    if (changes.workflowMenu) {
      this.displayedWorkflowMenu = { ...this.workflowMenu };
    }
  }

  /**
   * dispatches setActiveDiagramItem action with selected item from diagram workflow menu
   *
   * @param {*} menuItem
   * @memberof DiagramEditorComponent
   */
  onWorkflowMenuItemClick(menuItem: Menu) {
    this.store.dispatch(SetActiveDiagramItem({ payload: { activeItem: menuItem.data } }));
  }

  onSearchTextChange() {
    const filteredWorkflowMenu = this.workflowMenu.items.map(workflowMenuItem => ({
      ...workflowMenuItem,
      isExpanded: true,
      children: workflowMenuItem.children.filter(childItem => toLower(childItem.label).includes(toLower(this.searchText)))
    }));
    this.displayedWorkflowMenu.items = filteredWorkflowMenu;

    if (!this.searchText) {
      this.displayedWorkflowMenu = this.workflowMenu;
    }

    this.displayedWorkflowMenu = { ...this.displayedWorkflowMenu };
  }

  toggleWorkflowMenu(isMenuExpanded: boolean) {
    this.displayedWorkflowMenu.items = this.displayedWorkflowMenu.items.map(menuItem => ({
      ...menuItem,
      isExpanded: isMenuExpanded
    }));
    this.displayedWorkflowMenu = { ...this.displayedWorkflowMenu };
  }

  setActiveMenuItem() {
    if (this.displayedWorkflowMenu) {
      this.resetWorkflowMenuSelection();
      const activeItem = this.diagramTreeService.setActiveItemItemData(this.activeItem);

      if (this.activeItem?.item) {
        const activeUIDataStoreInMenu = this.displayedWorkflowMenu?.items.find(menuItem => menuItem.data.uiDataStore.id === activeItem.item.uiDataStoreId);
        const activeItemInMenu = activeUIDataStoreInMenu?.children.find(menuItem => menuItem.data.item.id === activeItem.item.id);

        activeUIDataStoreInMenu.isExpanded = true;
        activeItemInMenu.selected = true;
      } else {
        this.resetWorkflowMenuSelection();
      }
    }
  }

  /**
   * collapses and unselects all menu items
   *
   * @memberof DiagramWorkflowMenuComponent
   */
  resetWorkflowMenuSelection() {
    this.searchText = '';
    this.displayedWorkflowMenu = { ...this.workflowMenu };
    this.displayedWorkflowMenu.items = this.displayedWorkflowMenu?.items.map(menuItem => ({
      ...menuItem,
      isExpanded: false,
      children: menuItem.children?.map(childItem => ({
        ...childItem,
        selected: false
      }))
    }));
    this.displayedWorkflowMenu = { ...this.displayedWorkflowMenu };
  }

}
