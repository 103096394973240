
/* angular imports */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

/* definitions */
import { PAGE_DEFINITIONS } from '@rappider/shared/definitions';

/* guards */
import { AuthGuard } from '@rappider/authentication/guards';
import { ActiveProjectGuard, VerifyPersonGuard } from '@rappider/shared/guards';

/* components */
import { UiDataStoreCreateComponent } from './components/ui-data-store-create/ui-data-store-create.component';
import { UiDataStoreDetailComponent } from './components/ui-data-store-detail/ui-data-store-detail.component';
import { UiDataStoreEditComponent } from './components/ui-data-store-edit/ui-data-store-edit.component';
import { UiDataStoreListComponent } from './components/ui-data-store-list/ui-data-store-list.component';

/* internal modules */
import { UIDataStoreFieldModule } from '../ui-data-store-field/ui-data-store-field.module';
import { DataEventsModule } from '../data-events/data-events.module';
import { UIDataUpdateFunctionModule } from '../ui-data-update-function/ui-data-update-function.module';
import { UIStepFunctionModule } from '../ui-step-function/ui-step-function.module';
import {
  RappiderListGridModule,
  RappiderTitleModule,
  RappiderEditFormModule,
  RappiderTitleBarModule,
  RappiderTitleToolbarModule,
  RappiderSpinModule,
  RappiderButtonModule
} from '@rappider/rappider-components';

/* external modules */
import { TranslateModule } from '@ngx-translate/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { DataEventsWithoutRoutesModule } from '../data-events/data-events.without-route.module';

const routes: Routes = [
  {
    path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_CREATE.URL,
    component: UiDataStoreCreateComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_LIST.URL,
    component: UiDataStoreListComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard],
    data: { title: 'UI Data Stores' }
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_EDIT.URL}/:id`,
    component: UiDataStoreEditComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  },
  {
    path: `${PAGE_DEFINITIONS.PROJECTS.CHILDREN.UI_DATA_STORE_DETAIL.URL}/:id`,
    component: UiDataStoreDetailComponent,
    canActivate: [AuthGuard, ActiveProjectGuard, VerifyPersonGuard]
  }
];

@NgModule({
  declarations: [
    UiDataStoreDetailComponent,
    UiDataStoreEditComponent,
    UiDataStoreListComponent,
    UiDataStoreCreateComponent
  ],
  imports: [
    CommonModule,
    NzTabsModule,
    RouterModule.forChild(routes),
    TranslateModule,
    UIDataStoreFieldModule,
    UIDataUpdateFunctionModule,
    UIStepFunctionModule,
    RappiderListGridModule,
    RappiderTitleModule,
    RappiderEditFormModule,
    RappiderTitleBarModule,
    RappiderTitleToolbarModule,
    RappiderSpinModule,
    NzSkeletonModule,
    RappiderButtonModule,
    NzCollapseModule,
    // DataEventsWithoutRoutesModule,
    DataEventsModule
  ],
  exports: [
    RouterModule,
    UiDataStoreDetailComponent,
    UiDataStoreEditComponent,
    UiDataStoreListComponent,
    UiDataStoreCreateComponent
  ]
})
export class UiDataStoreModule { }
