import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PATH_DEFINITIONS } from '../../definitions/path-definition';
import { Permission } from 'apps/rappider/src/app/components/header/utils/admin-dashboard-permissions.enum';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) { }

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(state => state?.auth?.permissions).pipe(
      filter(permissions => permissions !== null),
      take(1),
      map(permissions => {
        if (permissions?.includes(Permission.Admin)) {
          return true;
        } else {
          this.router.navigate([PATH_DEFINITIONS.HOME_PAGE.HOME_PAGE_PATH]);
          return false;
        }
      })
    );
  }
}
