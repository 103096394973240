<rappider-title-toolbar [mainTitle]="mainTitle"
                        [options]="title"
                        [displayToolbar]="displayToolbar"
                        [displayToolbarBackButton]="displayToolbarBackButton"></rappider-title-toolbar>

<section>
  <rappider-list-grid [config]="WORKFLOW_STEP_FUNCTION_LIST_CONFIG"
                      [loading]="!workflowStepFunctionListData"
                      [data]="workflowStepFunctionListData"
                      (columnActionClick)="onColumnActionClick($event)"></rappider-list-grid>
</section>

<rappider-modal [(visible)]="dataTransformationModalVisibility"
                [fullScreen]="true"
                [title]="'Data Transformation'"
                (cancelClick)="toggleDataTransformationModalVisibility()">
  <rappider-workflow-step-function-data-transformation *ngIf="dataTransformationModalVisibility"
                                                       [isLoading]="isDataTransformationLoading"
                                                       [dataTransformationId]="dataTransformationId"
                                                       [sourceSchema]="sourceSchema"
                                                       [targetSchema]="targetSchema"></rappider-workflow-step-function-data-transformation>
</rappider-modal>
