import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { FormLayout, SelectMode } from '@rappider/rappider-components/utils';
import { DiagramActionType } from '../../utils/diagram-action-type';
import { DiagramSettingsMode } from '../../utils/diagram-settings-mode';
import { DiagramItemType } from '../../utils/diagram-item-type';
import { UIDataEventDetailsButtonConfig } from '../../utils/ui-data-event-details-button.config';
import { WorkflowItemInDiagram } from '../../utils/workflow-item-in-diagram.interface';
import { DiagramItemDeleteButtonConfig } from '../../utils/diagram-item-delete-button.config';
import { CommentWithRelations, DataSchemaWithRelations, DiagramCommentWithRelations, PersonWithRelations, UiDataEventWithRelations, UiDataStoreWithRelations, UiWorkflowStepFunctionWithRelations } from '@rappider/rappider-sdk';
import { SetActiveDiagramItem } from '../../state/diagram-state/diagram.actions';
import { CreatedWorkflowItem } from '../../utils/interfaces/diagram-settings/created-workflow-item.interface';
import { DeleteUIDataEvent } from 'libs/project/src/lib/states/ui-data-event-state/ui-data-event.actions';
import { DeleteUIWorkflowStepFunction, GetPostDataTransformationData, GetPreDataTransformationData } from 'libs/project/src/lib/states/ui-step-functions/ui-step-function.actions';
import { PreDTButtonConfig, PostDTButtonConfig } from '../../utils/definitions/diagram-settings/data-transformation-buttons.config';
import { PostDataTransformationData, PreDataTransformationData } from '@rappider/shared/interfaces';
import { Subscription } from 'rxjs';
import { CreateUIDiagramComment, DeleteDiagramComment, UpdateDiagramComment } from 'libs/comment/src/lib/state/diagram-comment/diagram-comment.actions';
import { DiagramTreeService } from '../../utils/services/diagram-tree.service';
import { NotificationService } from '@rappider/services';
import { UIWorkflowItemTypeValues } from '@rappider/shared/definitions';
import { cloneDeep } from 'lodash';
import { AddWorkflowButtonConfig } from '../../utils/add-workflow-button.config';
import { AddUiStepFunctionButtonConfig } from '../../utils/add-ui-step-function-button.config';
import { getDataSchemasWithDetailsSelector } from 'libs/project/src/lib/states/selectors/get-data-schemas-with-details.selector';
import { CommentCategory } from 'libs/comment/src/lib/utils/comment-category.enum';

@Component({
  selector: 'rappider-diagram-settings',
  templateUrl: './diagram-settings.component.html',
  styleUrls: ['./diagram-settings.component.scss']
})
export class DiagramSettingsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() diagramSettingsMode: DiagramSettingsMode;
  @Input() uiDataStoreId: string;
  @Input() activeItem: WorkflowItemInDiagram;
  @Input() uiStepFunctions: UiWorkflowStepFunctionWithRelations[];
  @Input() uiDataEvents: UiDataEventWithRelations[];
  @Input() activeUIDataStore: UiDataStoreWithRelations;
  @Input() activePerson: PersonWithRelations;
  @Input() comments: CommentWithRelations[] = [];
  @Input() projectPeopleData: PersonWithRelations[];
  @Input() diagramComments: DiagramCommentWithRelations[];
  @Input() commentsLoading: boolean;
  @Input() formPanelVisibility: boolean;
  @Input() formPanelTabIndex: number;
  @Input() activeCommentId: string;

  @Output() addWorkflow = new EventEmitter();
  @Output() formPanelVisibilityChange = new EventEmitter<boolean>();
  @Output() addUiStepFunctionToDataEvent = new EventEmitter();
  @Output() addDataEventToUiStepFunction = new EventEmitter();
  @Output() formPanelTabIndexChange = new EventEmitter();

  uiDataEventId: string;
  uiStepFunctionId: string;

  lastCreatedWorkflowItem: CreatedWorkflowItem;

  subscriptions: Subscription[];

  /* layout of the form displayed in settings area */
  formLayout = FormLayout.Vertical;

  DiagramItemType = DiagramItemType;
  DiagramActionType = DiagramActionType;

  UIDataEventDetailsButtonConfig = UIDataEventDetailsButtonConfig;
  DiagramItemDeleteButtonConfig = DiagramItemDeleteButtonConfig;
  AddWorkflowButtonConfig = AddWorkflowButtonConfig;
  AddUiStepFunctionButtonConfig = AddUiStepFunctionButtonConfig;

  isDataEventDetailModalVisible = false;
  showUIDataStoreSelectionForUIStepFunctions = true;
  showUIDataStoreSelectionForUIDataEvents = true;

  loading = false;
  isDataTransformationsLoading = true;

  PreDTButtonConfig = PreDTButtonConfig;
  isPreDataTransformationModalVisible = false;
  preSourceJsonSchema;
  preTargetJsonSchema;
  preDataTransformationId: string;

  PostDTButtonConfig = PostDTButtonConfig;
  isPostDataTransformationModalVisible = false;
  postSourceJsonSchema;
  postTargetJsonSchema;
  postDataTransformationId: string;

  selectedFilters;
  selectedTabIndex = 0;
  allNodes;
  commentsFilterSelectConfig = {
    settings: {
      mode: SelectMode.Multiple
    },
    placeholder: 'All Comments'
  };

  constructor(
    private store: Store<any>,
    private diagramTreeService: DiagramTreeService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.subscribeToData();
    this.setCommentsFilterOptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.activeItem) {
      this.selectedFilters = [this.activeItem?.item.id];
      this.commentsFilterChanges(this.selectedFilters);
    }
    this.handleActiveItemChange(changes.activeItem);
    this.handleUIStepFunctionsChange(changes.uiStepFunctions);
    this.handleUIDataEventsChange(changes.uiDataEvents?.currentValue);
  }

  private handleActiveItemChange(activeItemChange: SimpleChange): void {
    if (activeItemChange) {
      this.setIdByActiveItemType();
    }
  }

  private handleUIStepFunctionsChange(uiStepFunctionsChange: SimpleChange): void {
    if (uiStepFunctionsChange && this.lastCreatedWorkflowItem) {
      this.handleWorkflowItemCreation(
        this.uiStepFunctions,
        DiagramItemType.UIStepFunction,
        DiagramItemType.UIStepFunctionWithRelations
      );
    }
  }

  private handleUIDataEventsChange(uiDataEventsChange: any): void {
    if (uiDataEventsChange && this.lastCreatedWorkflowItem) {
      this.handleWorkflowItemCreation(this.uiDataEvents, DiagramItemType.UIDataEvent);
    }
  }

  private handleWorkflowItemCreation(
    items: any[],
    type: DiagramItemType,
    typeWithRelations?: DiagramItemType
  ): void {
    const createdItem = items.find(
      item =>
        item.name === this.lastCreatedWorkflowItem.name &&
        item.uiDataStoreId === this.lastCreatedWorkflowItem.uiDataStoreId
    );

    if (createdItem) {
      this.store.dispatch(SetActiveDiagramItem({
        payload: {
          activeItem: {
            item: createdItem,
            type: typeWithRelations && createdItem.endpointId || createdItem.uiDataStoreSubscriptions?.length
              ? typeWithRelations
              : type
          }
        }
      }));
      this.lastCreatedWorkflowItem = undefined;
    }
  }

  subscribeToData() {
    this.subscriptions = [
      this.subscribeToDataTransformationsLoading(),
      this.subscribeToPreDataTransformationData(),
      this.subscribeToPostDataTransformationData(),
      this.subscribeToActiveItem()
    ];
  }

  subscribeToActiveItem() {
    return this.store.select(createSelector(
      state => <WorkflowItemInDiagram>state['diagram']?.activeItem,
      <any>getDataSchemasWithDetailsSelector,
      (
        activeItem: WorkflowItemInDiagram,
        dataSchemas: DataSchemaWithRelations[]
      ) => {
        if (activeItem?.type && [DiagramItemType.UIDataEvent, DiagramItemType.UIScheduledDataEvent].includes(activeItem?.type)) {
          const activeDiagramItem = cloneDeep(activeItem);
          if (activeDiagramItem?.item?.payload) {
            activeDiagramItem.item.payload.fields = activeItem.item?.payload?.fields?.map(field => {
              const fieldType = dataSchemas.find(schema => schema.id === field.typeId);
              return { ...field, type: fieldType };
            }) ?? [];
          }
          return activeDiagramItem;
        }
        return activeItem;
      })).subscribe(activeItem => {
        this.activeItem = activeItem;
      });
  }

  subscribeToDataTransformationsLoading() {
    return this.store.select(state => state.uiWorkflowStepFunction?.dataTransformationsLoading).subscribe((isLoading: boolean) => {
      this.isDataTransformationsLoading = isLoading;
    });
  }

  subscribeToPreDataTransformationData() {
    return this.store.select(state => state.uiWorkflowStepFunction?.preDataTransformationData)
      .subscribe((preDTData: PreDataTransformationData) => {
        this.preDataTransformationId = preDTData?.preDataTransformationId;
        this.preSourceJsonSchema = preDTData?.preSourceJsonSchema;
        this.preTargetJsonSchema = preDTData?.preTargetJsonSchema;
      });
  }

  subscribeToPostDataTransformationData() {
    return this.store.select(state => state.uiWorkflowStepFunction?.postDataTransformationData)
      .subscribe((postDTData: PostDataTransformationData) => {
        this.postDataTransformationId = postDTData?.postDataTransformationId;
        this.postSourceJsonSchema = postDTData?.postSourceJsonSchema;
        this.postTargetJsonSchema = postDTData?.postTargetJsonSchema;
      });
  }

  setIdByActiveItemType() {
    const activeItem = this.diagramTreeService.setActiveItemItemData(this.activeItem);

    const setCommonProperties = (type: DiagramItemType): void => {
      this.diagramSettingsMode = {
        activeItemType: type,
        activeAction: DiagramActionType.Edit
      };
    };

    switch (this.activeItem?.type) {
      case DiagramItemType.UIDataEvent:
      case DiagramItemType.UIStepFunction:
      case DiagramItemType.UIStepFunctionWithRelations:
      case DiagramItemType.UIScheduledDataEvent:
        setCommonProperties(this.activeItem.type);
        this.uiDataEventId = activeItem.item.id;
        if (
          this.activeItem.type === DiagramItemType.UIStepFunction ||
          this.activeItem.type === DiagramItemType.UIStepFunctionWithRelations) {
          this.uiStepFunctionId = activeItem.item.id;
        } else {
          this.uiStepFunctionId = null;
        }
        break;

      default:
        this.uiStepFunctionId = null;
        this.uiDataEventId = null;
        break;
    }
  }

  onSeeDataEventDetailsClick() {
    this.toggleDataEventDetailModalVisibility();
  }

  toggleDataEventDetailModalVisibility() {
    this.isDataEventDetailModalVisible = !this.isDataEventDetailModalVisible;
  }

  onConfirmDelete() {
    if (this.activeItem.type === DiagramItemType.UIDataEvent) {
      this.store.dispatch(new DeleteUIDataEvent({ uiDataEvent: this.activeItem.item }));
    } else if (this.activeItem.type === DiagramItemType.UIStepFunction || DiagramItemType.UIStepFunctionWithRelations) {
      const uiWorkflowStepFunction = this.activeItem.item as UiWorkflowStepFunctionWithRelations;
      this.store.dispatch(new DeleteUIWorkflowStepFunction({ uiWorkflowStepFunction }));
    }
    this.store.dispatch(SetActiveDiagramItem({ payload: { activeItem: null } }));
  }

  onWorkflowItemCreate(data: CreatedWorkflowItem) {
    this.lastCreatedWorkflowItem = data;
  }

  handlePreDataTransformationModalVisibility(isVisible: boolean) {
    this.isPreDataTransformationModalVisible = isVisible;
  }

  handlePostDataTransformationModalVisibility(isVisible: boolean) {
    this.isPostDataTransformationModalVisible = isVisible;
  }

  onPreDTButtonClick(activeItem: WorkflowItemInDiagram) {
    const { item } = activeItem;
    if (item.type === UIWorkflowItemTypeValues.PreDefinedTemplate) {
      this.notificationService.createNotification('warning', 'Warning', 'Data transformation cannot be added to step functions with the type Predefined Template.');
    } else {
      if (activeItem.type === DiagramItemType.UIStepFunction || DiagramItemType.UIStepFunctionWithRelations) {
        if (item.endpointId
          || item.workflowServiceId
          || item.workflowStepFunctionId
          || (!item.mode && item.publishedEventsOnSuccess?.length)) {
          const uiWorkflowStepFunction = this.activeItem.item as UiWorkflowStepFunctionWithRelations;
          this.store.dispatch(new GetPreDataTransformationData({ uiStepFunction: uiWorkflowStepFunction }));
          this.handlePreDataTransformationModalVisibility(true);
        } else {
          this.notificationService.createNotification(
            'warning',
            'Warning',
            'UI Step Function must contains one of the following to be able to add Pre Data Transformation; Published Event On Success, Workflow Service, Endpoint or Workflow step function'
          );
        }
      }
    }
  }

  onPostDTButtonClick(activeItem: WorkflowItemInDiagram) {
    const { item } = activeItem;
    if (item.type === UIWorkflowItemTypeValues.PreDefinedTemplate) {
      this.notificationService.createNotification('warning', 'Warning', 'Data transformation cannot be added to step functions with the type Predefined Template.');
    } else {
      if (activeItem.type === DiagramItemType.UIStepFunction || DiagramItemType.UIStepFunctionWithRelations) {
        if ((item.endpointId
          || item.workflowServiceId
          || item.workflowStepFunctionId)
          && item.publishedEventsOnSuccess?.length) {
          const uiWorkflowStepFunction = this.activeItem.item as UiWorkflowStepFunctionWithRelations;
          this.store.dispatch(new GetPostDataTransformationData({ uiStepFunction: uiWorkflowStepFunction }));
          this.handlePostDataTransformationModalVisibility(true);
        } else {
          this.notificationService.createNotification(
            'warning',
            'Warning',
            'Data transformation cannot be added to step functions without Published Event on Success.'
          );
        }
      }
    }
  }

  onCreateComment(comment) {
    this.store.dispatch(CreateUIDiagramComment({ comment }));
  }

  onEditComment(comment: any) {
    this.store.dispatch(UpdateDiagramComment({ comment, commentId: comment.id }));
  }

  onDeleteComment(comment) {
    const diagramCommentId = this.diagramComments?.find(diagramComment => diagramComment.commentId === comment.id)?.id;
    this.store.dispatch(DeleteDiagramComment({ commentId: comment.id, diagrmaCommentId: diagramCommentId }));
  }

  onAddWorkflow() {
    this.addWorkflow.emit();
  }

  closeSettingsPanel() {
    this.formPanelVisibility = false;
    this.store.dispatch(SetActiveDiagramItem({ payload: { activeItem: null } }));
    this.formPanelVisibilityChange.emit(this.formPanelVisibility);
  }

  onAddUiStepFunctionToDataEvent() {
    this.addUiStepFunctionToDataEvent.emit();
  }

  onAddDataEventToUiStepFunction() {
    this.addDataEventToUiStepFunction.emit();
  }

  formPanelSelectedTabIndexChange(index: number) {
    this.formPanelTabIndex = index;
    this.formPanelTabIndexChange.emit(this.formPanelTabIndex);
  }

  setCommentsFilterOptions() {
    const allEvents = this.uiDataEvents?.map(event => ({
      key: event.name,
      value: event.id
    }));

    const allStepFunctions = this.uiStepFunctions?.map(stepFunction => ({
      key: stepFunction.name,
      value: stepFunction.id
    }));

    this.allNodes = [...allEvents, ...allStepFunctions];
  }

  commentsFilterChanges(commentsIds) {
    let selectedItemsComments: DiagramCommentWithRelations[] = [];
    let diagramComments: DiagramCommentWithRelations[] = [];

    if (commentsIds?.length) {
      selectedItemsComments = this.diagramComments?.filter(diagramComment => commentsIds.includes(diagramComment.relatedEntityId));
      this.comments = selectedItemsComments?.map(activeItemComment => activeItemComment.comment);
    } else {
      diagramComments = this.diagramComments?.filter(diagramComment => diagramComment?.comment?.category === CommentCategory.UIDataEvent || diagramComment?.comment?.category === CommentCategory.UIWorkflowStepFunction);
      this.comments = diagramComments?.map(diagramComment => diagramComment.comment);
    }
  }
}
