<rappider-internal-title-toolbar [mainTitle]="mainTitle"
                                 [options]="title"
                                 [displayToolbar]="displayToolbar"
                                 [displayToolbarBackButton]="displayToolbarBackButton"></rappider-internal-title-toolbar>
<section>
  <div class="toolbar-container">
    <rappider-button [text]="addPageButtonConfig.text"
                     [icon]="addPageButtonConfig.icon"
                     (click)="onClickCreatePage()"></rappider-button>
    <div class="toolbar-container-right">
      <rappider-button [text]="'Expand' | translate"
                       [icon]="{name:'fa-regular fa-list-tree', type: 'FONT_AWESOME'}"
                       (click)="onExpandCollapsePages(true)"></rappider-button>
      <rappider-button [text]="'Collapse' | translate"
                       [icon]="{name:'fa-regular fa-list', type: 'FONT_AWESOME'}"
                       (click)="onExpandCollapsePages(false)"></rappider-button>
    </div>
  </div>
</section>

<section>
  <nz-tabset nzType="card"
             [(nzSelectedIndex)]="selectedTabIndex"
             (nzSelectChange)="onTabChange($event)">
    <nz-tab *ngFor="let tab of pageListTabs"
            [nzTitle]="tab.name">

      <!-- Filter and Search Area -->
      <div class="filter-and-search-area">
        <div class="search-area">
          <rappider-input-group class="input-group"
                                [(ngModel)]="searchWord"
                                [suffixIcon]="rappiderInputConfig.suffixIcon"
                                [textbox]="rappiderInputConfig.textbox"
                                (ngModelChange)="onSearchAndCategoriesFilter()">
          </rappider-input-group>
        </div>
        <nz-select *ngIf="displayedCategories.length > 0"
                   [(ngModel)]="selectedCategoryNames"
                   (ngModelChange)="onSearchAndCategoriesFilter()"
                   class="filter-columns-select"
                   nzMode="multiple"
                   nzAllowClear
                   [nzMaxTagCount]="0"
                   [nzPlaceHolder]="colSelectPlaceHolder"
                   [nzMaxTagPlaceholder]="colSelectPlaceHolder">
          <nz-option *ngFor="let column of displayedCategories"
                     [nzLabel]="column.title | translate"
                     [nzValue]="column.id"></nz-option>
        </nz-select>
        <ng-template #colSelectPlaceHolder
                     let-selectedList>
          <div class="filter-columns-placeholder">
            <div>
              <i class="far fa-line-columns"></i>
            </div>
            <div>
              &nbsp;{{'Filter Category'|translate}}
            </div>
          </div>
        </ng-template>
      </div>
      <!-- END OF Filter and Search Area -->

      <!-- Table Area -->
      <nz-table #expandTable
                [nzData]="displayedModules"
                class="page-list"
                [nzFrontPagination]="false">
        <thead>
          <tr>
            <th class="page-title">{{ 'SHARED.TITLE' | translate }}</th>
            <th class="wide-columns">{{ 'SHARED.PATH' | translate }}</th>
            <th class="narrow-columns">{{ 'SHARED.AUTHENTICATION_REQUIRED' | translate }}</th>
            <th class="description-header">{{ 'SHARED.DESCRIPTION' | translate }}</th>
            <th class="narrow-columns">{{ 'SHARED.ACTIVE' | translate }}</th>
            <th class="wide-columns">{{'SHARED.CATEGORY'|translate}}</th>
            <th class="wide-columns">{{ 'SHARED.LAST_ACTIVITY' | translate }}</th>
            <th class="narrow-columns"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of expandTable.data">
            <ng-container *ngFor="let item of mapOfExpandedPageListData[data.key]">
              <tr *ngIf="(item.parent && item.parent.expand) || !item.parent">
                <td [nzIndentSize]="item.level! * 20"
                    [nzShowExpand]="!!item.children"
                    [(nzExpand)]="item.expand"
                    (nzExpandChange)="collapse(mapOfExpandedPageListData[data.key], item, $event)">
                  <i *ngIf="item.parent"
                     [class]="item.isHomepage ? 'fa-regular fa-house' : 'fa-regular fa-file'"></i>
                  &nbsp;
                  <a *ngIf="item.parent; else moduleNameTemplate"
                     (click)="item?.developmentStatus === PageDevelopmentStatus.InReview ? pageStatusModalVisibilityChange() : onClickPageTitle(item.id)">
                    <span nz-tooltip
                          nzTooltipTitle="{{item.title}}"
                          class="item-title-link">{{item.title}}</span>
                  </a>
                  <ng-template #moduleNameTemplate>
                    <ng-container *ngIf="item.id === defaultModuleId">
                      <i class="fa-regular fa-house"></i>&nbsp;
                    </ng-container>
                    <a (click)="onClickModuleTitle(item.id)">{{ item.title }} {{ 'Module' | translate }}</a>
                  </ng-template>

                </td>
                <td>{{ item.slug }}</td>
                <td>
                  <i *ngIf="item.parent"
                     [class]="!item.isPublic ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"></i>
                </td>
                <td class="page-description">
                  <span nz-tooltip
                        nzTooltipPlacement="left"
                        [nzTooltipTitle]="item.description">{{ item.description }}</span>
                </td>
                <td>
                  <i *ngIf="item.parent"
                     [class]="item.isActive ? 'fa-regular fa-check' : 'fa-regular fa-xmark'"></i>
                </td>
                <!-- module level is 0, page level is 1 -->
                <td *ngIf="item.level === 0">
                  <!-- <rappider-tag *ngIf="item?.category?.title"
                                [text]="{text: item?.category?.title}"></rappider-tag> -->
                </td>
                <td *ngIf="item.level === 1">
                  <ng-container *ngFor="let category of item.categories">
                    <rappider-tag [text]="{text: category.title}"></rappider-tag>
                  </ng-container>
                </td>
                <td>
                  <span *ngIf="item.lastActivityBy">by {{ item.lastActivityBy }} </span>
                  <span *ngIf="item.lastActivityDate">on {{ item.lastActivityDate | date:'M/d/yy, h:mm a'}}</span>
                </td>
                <td>
                  <div class="item-actions-area">
                    <!-- Dropdown Menu -->
                    <ng-template [ngIf]="item.id !== defaultModuleId">
                      <rappider-dropdown-menu *ngIf="item.level === 0"
                                              [icon]="pageListModuleDropdownMenuConfig.icon"
                                              [items]="getModuleDropdownMenuItems(item)"
                                              (menuItemClick)="onModuleDropdownMenuItemClick($event, item)">
                      </rappider-dropdown-menu>
                      <rappider-dropdown-menu *ngIf="item.parent"
                                              class="item-actions-dropdown"
                                              [icon]="pageListDropdownMenuConfig.icon"
                                              [items]="getDropdownMenuItems(item)"
                                              (menuItemClick)="onDropdownMenuItemClick($event, item)">
                      </rappider-dropdown-menu>
                    </ng-template>
                  </div>

                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>
      <!-- END OF Table Area -->

      <!-- Loading Area -->
      <div *ngIf="isModulesLoading || pageLoading"
           class="loading">
        <span nz-icon
              nzType="loading"
              nzTheme="outline"></span>
        <span> {{'We are fetching the data, please wait...' | translate}}</span>
      </div>
      <!-- END OF Loading Area -->

    </nz-tab>
  </nz-tabset>
</section>

<!-- Pafe Status Modal -->
<rappider-modal [(visible)]="pageStatusModalVisibility"
                [title]="pageStatusModalConfig.title"
                [footer]="pageStatusModalConfig.footer"
                (okClick)="pageStatusModalVisibilityChange()">
  <h6>We are currently reviewing this page. Once the review is complete and the page is fully prepared, we will
    promptly notify you.</h6>
</rappider-modal>

<!-- Default Module Modal -->
<rappider-modal [(visible)]="defaultModuleModalVisibility"
                [okDisabled]="defaultModuleModalOkDisabled"
                (cancelClick)="onCancelModal()"
                (okClick)="onChangeDefaultModuleModal()">
  <rappider-alert [type]="defaultModuleAlertConfig.type"
                  [title]="defaultModuleAlertConfig.title"
                  [showIcon]="defaultModuleAlertConfig.showIcon"
                  [marginSettings]="defaultModuleAlertConfig.marginSettings">
  </rappider-alert>
  <rappider-select [(ngModel)]="selectedPageId"
                   [options]="selectDefaultModuleOptions"
                   [placeholder]="'Select Homepage'"
                   (valueChange)="onChangeDefaultModuleAndSetHomePage($event)"
                   [cssStyle]="{ marginTop: 'var(--margin-3x)'}"
                   [ngClass]="{'danger-border': !selectedPageId}"></rappider-select>

</rappider-modal>
