<div class="right-sidebar">
  <div *ngIf="visibility"
       [@sidebarToggleAnimation]
       class="sidebar-menu">

    <nz-tabset nzType="card"
               [nzSelectedIndex]="selectedTabIndex"
               (nzSelectedIndexChange)="selectedTabIndexChange($event)">
      <!-- 1 - Pages TAB -->
      <nz-tab [nzTitle]="pagesTabIconTemplate">
        <ng-template #pagesTabIconTemplate>
          <ng-container *ngTemplateOutlet="tabIcon;context:{
            icon: 'fa-files fa-light',
            title: selectedTabIndex === 0 ? 'Project' : '',
            tooltip: 'Project' | translate,
            tooltipPlacement: 'top'
          }"></ng-container>
        </ng-template>
        <!-- Tab Content Starts -->

        <section class="settings-tab-title-bar">
          <nz-button-group class="settings-navigation-buttons">
            <button nz-button
                    nz-tooltip="Expand All"
                    (click)="updateCollapsePanelVisibilities('expand-all')">
              <i class="fa-light fa-arrow-down-from-line"></i></button>
            <button nz-button
                    nz-tooltip="Collapse All"
                    (click)="updateCollapsePanelVisibilities('collapse-all')">
              <i class="fa-light fa-arrow-up-from-line"></i></button>
            <button nz-button
                    nz-tooltip="Pages"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.Pages])">
              <i class="fa-light fa-files"></i></button>
            <button nz-button
                    nz-tooltip="Layouts"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.Layouts])">
              <i class="fa-light fa-table-layout"></i></button>
          </nz-button-group>
        </section>

        <div class="settings-inner-scroll-container">
          <nz-collapse class="page-and-tree-settings">

            <nz-collapse-panel nzHeader="Pages"
                               [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.Pages)"
                               class="input-section-panel">
              <!-- Pages -->
              <rappider-page-menu (pageSelected)="onPageSelected($event)"></rappider-page-menu>
            </nz-collapse-panel>
            <nz-collapse-panel nzHeader="Layouts"
                               [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.Layouts)"
                               class="input-section-panel">
              <!-- Layouts -->
              <rappider-layout-menu [page]="page"></rappider-layout-menu>
            </nz-collapse-panel>

          </nz-collapse>
        </div>
        <!-- Tab Content Ends -->
      </nz-tab>

      <!-- 2 - Page Settings TAB -->
      <nz-tab [nzTitle]="pageSettingsTabIconTemplate">
        <ng-template #pageSettingsTabIconTemplate>
          <ng-container *ngTemplateOutlet="tabIcon;context:{
            icon: 'fa-file-lines fa-regular',
            title: selectedTabIndex === 1 ? 'Page' : '',
            tooltip: 'Page' | translate,
            tooltipPlacement: 'top'
          }"></ng-container>
        </ng-template>
        <!-- Tab Content Starts -->
        <section class="settings-tab-title-bar">
          <nz-button-group class="settings-navigation-buttons">
            <button nz-button
                    nz-tooltip="Expand All"
                    (click)="updateCollapsePanelVisibilities('expand-all')">
              <i class="fa-light fa-arrow-down-from-line"></i></button>
            <button nz-button
                    nz-tooltip="Collapse All"
                    (click)="updateCollapsePanelVisibilities('collapse-all')">
              <i class="fa-light fa-arrow-up-from-line"></i></button>
            <button nz-button
                    nz-tooltip="Component Tree"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.ComponentTree])">
              <i class="fa-light fa-list-tree"></i></button>
            <button nz-button
                    nz-tooltip="Page Variables"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.PageVariables])">
              <i class="fa-light fa-file-lines"></i></button>
            <button nz-button
                    nz-tooltip="Page Lifecycle"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.PageLifecycle])">
              <i class="fa-circle-bolt fa-light fa-sharp"></i></button>
            <button nz-button
                    nz-tooltip="Custom Page Styles"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.CustomPageStyles])">
              <i class="fa-light fa-palette"></i></button>
            <button nz-button
                    nz-tooltip="Navigate to Other Pages"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.Pages])">
              <i class="fa-light fa-files"></i></button>

          </nz-button-group>
        </section>

        <div class="settings-inner-scroll-container">
          <nz-collapse class="page-and-tree-settings">

            <!-- Component Tree -->
            <nz-collapse-panel nzHeader="Component Tree"
                               [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.ComponentTree)"
                               class="input-section-panel">
              <rappider-content-tree [contentTree]="contentTree"
                                     [activeContentTreeItem]="activeContentTreeItem"
                                     (containerDeleteModalVisibility)="onContainerDeleteModalVisibility($event)"
                                     (selectedNodeChanged)="onContentTreeSelectedTreeNodeChange($event)"></rappider-content-tree>
            </nz-collapse-panel>

            <!-- Page Variables -->
            <nz-collapse-panel nzHeader="Page Variables"
                               [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.PageVariables)"
                               class="input-section-panel">
              <rappider-page-variables (cancel)="handlePageVariablesModalVisibility(false)"></rappider-page-variables>
            </nz-collapse-panel>

            <!-- Page Lifecycle -->
            <nz-collapse-panel nzHeader="Page Lifecycle"
                               [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.PageLifecycle)"
                               class="input-section-panel">

              <rappider-page-lifecyle [activePage]="page"
                                      [uiDataStores]="uiDataStores"
                                      [uiDataEvents]="uiDataEvents"></rappider-page-lifecyle>

            </nz-collapse-panel>

            <!-- Custom Page Styles -->
            <nz-collapse-panel nzHeader="Custom Page Styles"
                               [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.CustomPageStyles)"
                               class="input-section-panel">
              <rappider-page-style-customization [page]="page"></rappider-page-style-customization>
            </nz-collapse-panel>


            <!-- Pages -->
            <nz-collapse-panel nzHeader="Navigate to Other Pages"
                               [nzActive]="(!allPanelsActive && !visiblePanelKeys?.length) ? false : allPanelsActive ? true : visiblePanelKeys.includes(ContentEditorSettingsSection.Pages)"
                               class="input-section-panel">
              <rappider-page-menu (pageSelected)="onPageSelected($event)"></rappider-page-menu>

            </nz-collapse-panel>
          </nz-collapse>
        </div>

        <!-- Tab Content Ends -->
      </nz-tab>

      <!-- 3 - Component Settings TAB | Default Selected -->
      <nz-tab [nzTitle]="componentSettingsTabIconTemplate">
        <ng-template #componentSettingsTabIconTemplate>
          <ng-container *ngTemplateOutlet="tabIcon;context:{
            icon: 'fa fa-regular fa-puzzle-piece-simple',
            title: selectedTabIndex === 2 ? 'Component' : '',
            tooltip: 'Component' | translate,
            tooltipPlacement: 'top'
          }"></ng-container>
        </ng-template>
        <!-- Tab Content Starts -->
        <section class="settings-tab-title-bar">
          <nz-button-group *ngIf="activeContentTreeItem?.type === ContentEditorContentType.Component"
                           class="settings-navigation-buttons">
            <button nz-button
                    nz-tooltip="Expand All"
                    (click)="updateCollapsePanelVisibilities('expand-all')">
              <i class="fa-light fa-arrow-down-from-line"></i></button>
            <button nz-button
                    nz-tooltip="Collapse All"
                    (click)="updateCollapsePanelVisibilities('collapse-all')">
              <i class="fa-light fa-arrow-up-from-line"></i></button>
            <button nz-button
                    nz-tooltip="Component Settings"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.ComponentSettings])">
              <i class="fa-regular fa-gear"></i></button>
            <button nz-button
                    nz-tooltip="Pre-Defined Setting"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.InputVariations])">
              <i class="fa-regular fa-rectangle-vertical-history"></i></button>
            <button nz-button
                    nz-tooltip="Css Classes"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.CssClasses])">
              <i class="fa-regular fa-palette"></i></button>
            <button nz-button
                    nz-tooltip="Component Outputs"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.ComponentOutputs])">
              <i class="fa-light fal fa-inbox-out"></i></button>
            <button nz-button
                    nz-tooltip="Component Comments"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.ComponentComments])">
              <i class="fa-light fa-comments"></i></button>
            <button nz-button
                    nz-tooltip="Component Tree"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.ComponentTree])">
              <i class="fa-light fa-list-tree"></i></button>

          </nz-button-group>

          <nz-button-group *ngIf="activeContentTreeItem?.type === ContentEditorContentType.Container"
                           class="settings-navigation-buttons">
            <button nz-button
                    nz-tooltip="Expand All"
                    (click)="updateCollapsePanelVisibilities('expand-all')">
              <i class="fa-light fa-arrow-down-from-line"></i></button>
            <button nz-button
                    nz-tooltip="Collapse All"
                    (click)="updateCollapsePanelVisibilities('collapse-all')">
              <i class="fa-light fa-arrow-up-from-line"></i></button>
            <button nz-button
                    nz-tooltip="Display Mode"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.ContainerDisplayMode])">
              <i class="fa-regular fa-grid-horizontal"></i></button>
            <button nz-button
                    nz-tooltip="Css Classes"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.CssClasses])">
              <i class="fa-regular fa-palette"></i></button>
            <button nz-button
                    nz-tooltip="Container Comments"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.ContainerComments])">
              <i class="fa-light fa-comments"></i></button>
            <button nz-button
                    nz-tooltip="Component Tree"
                    (click)="updateCollapsePanelVisibilities('',[ContentEditorSettingsSection.ComponentTree])">
              <i class="fa-light fa-list-tree"></i></button>

          </nz-button-group>
        </section>

        <!-- ---------------------------------------- -->
        <!-- Setting vertical Split Area Starts -->
        <div class="settings-inner-scroll-container">

          <ng-container *ngIf="!activeContentTreeItem">
            <section>
              <h6>
                Please select a container or component to view its settings and details here.
              </h6>
            </section>
          </ng-container>

          <ng-container *ngIf="activeContentTreeItem?.type === ContentEditorContentType.Component">
            <rappider-content-editor-component-settings #componentSettingsComponentRef
                                                        [activeContentTreeItem]="activeContentTreeItem"
                                                        [contentTree]="contentTree"
                                                        [page]="page"
                                                        [activePerson]="activePerson"
                                                        [comments]="comments"
                                                        [pageComments]="pageComments"
                                                        [commentsLoading]="commentsLoading"
                                                        [projectPeopleData]="peopleData"
                                                        [visiblePanelKeys]="visiblePanelKeys"
                                                        [allPanelsActive]="allPanelsActive"
                                                        [activeCommentId]="activeCommentId"
                                                        (labelMenuItemClick)="onComponentSettingsLabelMenuItemClick($event)"
                                                        (seeAllCommentsClick)="onSeeAllCommentsButtonClick()">
            </rappider-content-editor-component-settings>
          </ng-container>

          <ng-container *ngIf="activeContentTreeItem?.type === ContentEditorContentType.Container">
            <rappider-container-settings #containerSettingsComponentRef
                                         [contentTree]="contentTree"
                                         [pageContainerNode]="activeContentTreeItem"
                                         [page]="page"
                                         [activePerson]="activePerson"
                                         [comments]="comments"
                                         [pageComments]="pageComments"
                                         [commentsLoading]="commentsLoading"
                                         [visiblePanelKeys]="visiblePanelKeys"
                                         [allPanelsActive]="allPanelsActive"
                                         [projectPeopleData]="peopleData"
                                         [activeCommentId]="activeCommentId"
                                         (dropdownMenuItemClick)="onDropdownMenuItemClick($event)"
                                         (seeAllCommentsClick)="onSeeAllCommentsButtonClick()">
            </rappider-container-settings>
          </ng-container>
          <nz-collapse class="other-components-tree">

            <!-- Component Tree -->
            <nz-collapse-panel nzHeader="NAVIGATE TO OTHER COMPONENTS"
                               [nzActive]="allPanelsActive || visiblePanelKeys.includes(ContentEditorSettingsSection.ComponentTree)"
                               class="input-section-panel">
              <rappider-content-tree [contentTree]="contentTree"
                                     [activeContentTreeItem]="activeContentTreeItem"
                                     (containerDeleteModalVisibility)="onContainerDeleteModalVisibility($event)"
                                     (selectedNodeChanged)="onContentTreeSelectedTreeNodeChange($event)"></rappider-content-tree>
            </nz-collapse-panel>
          </nz-collapse>

        </div>
        <!-- Tab Content Ends -->
      </nz-tab>

      <!-- 4 - Comments TAB -->
      <nz-tab [nzTitle]="commentsTabIconTemplate">
        <ng-template #commentsTabIconTemplate>
          <ng-container *ngTemplateOutlet="tabIcon;context:{
            icon: 'fa-comments fa-light',
            title: selectedTabIndex === 3 ? 'Comments' : '',
            tooltip: 'Comments' | translate,
            tooltipPlacement: 'top'
          }"></ng-container>
        </ng-template>
        <!-- Tab Content Starts -->
        <div class="settings-inner-scroll-container">
          <rappider-comment-wrapper [comments]="comments"
                                    [activePerson]="activePerson"
                                    [peopleData]="peopleData"
                                    [isLoading]="commentsLoading"
                                    [scrollingCommentId]="activeCommentId"
                                    [isDisplayingAllComments]="isDisplayingAllComments"
                                    (clickCard)="onClickCommentCard($event)"
                                    (createComment)="onCreateComment($event)"
                                    (editComment)="onEditComment($event)"
                                    (deleteComment)="onDeleteComment($event)"></rappider-comment-wrapper>
        </div>
        <!-- Tab Content Ends -->
      </nz-tab>

    </nz-tabset>

  </div>

  <!-- Menu Items -->
  <div class="right-sidebar-menu-items">
    <rappider-menu [isCollapsed]="true"
                   [config]="RIGHT_SIDEBAR_MENU_CONFIG"
                   (menuItemClick)="onMenuItemClick($event)"></rappider-menu>
    <rappider-menu [isCollapsed]="true"
                   [config]="RIGHT_SIDEBAR_BOTTOM_MENU_CONFIG"
                   (menuItemClick)="onBottomMenuItemClick($event)"></rappider-menu>
  </div>
</div>

<!-- Components and Snippets Modal -->
<rappider-modal [(visible)]="componentsAndSnippetsModalVisibility"
                [footer]="null"
                [fullScreen]="true"
                [closeIconVisibility]="false"
                (visibleChange)="onComponentsAndSnippetsModalVisibility($event)">
  <div class="close-button-wrapper">
    <rappider-button class="close-button"
                    [icon]="closeButtonIcon"
                    [size]="closeButtonIcon.size"
                    [customColorSettings]="closeButtonIcon.customColorSettings"
                    [borderSettings]="closeButtonIcon.borderSettings"
                    (click)="onComponentsAndSnippetsModalVisibility(false)"></rappider-button>
  </div>
  <rappider-cards class="content-editor-right-sidebar-menu-cards-component-wrapper" [ngClass]="{'modal-wrapper-dark': preferredTheme === 'dark','modal-wrapper-light': preferredTheme === 'light'}"
                  [items]="cardsConfig?.items"
                  [itemCountPerRow]="cardsConfig?.itemCountPerRow"
                  [showTitleOnImage]="cardsConfig?.showTitleOnImage"
                  [showDescriptionOnImage]="cardsConfig?.showDescriptionOnImage"
                  [selectConfig]="cardsConfig?.selectConfig"
                  [paginationConfig]="cardsConfig?.paginationConfig"
                  [showCheckMarkOnCard]="cardsConfig?.showCheckMarkOnCard"
                  [isDynamicPagination]="cardsConfig?.isDynamicPagination"
                  [menuConfig]="cardsConfig?.menuConfig"
                  [buttons]="cardsConfig?.buttons"
                  (pageIndexChange)="onPageIndexChange($event)"
                  (searchTextChange)="onSearchTextChange($event)"
                  (tagFilterChange)="onTagFilterChange($event)"
                  (avatarButtonClick)="onAvatarButtonClick($event)"
                  (cardDetail)="onSnippetSelect($event)"
                  (menuItemClick)="onFilterMenuItemClick($event)"
                  (topButtonsClick)="onTopButtonsClick($event)"></rappider-cards>
</rappider-modal>

<!-- Global Style Modal -->
<rappider-modal *ngIf="globalStyleModalVisibility"
                [(visible)]="globalStyleModalVisibility"
                title="Update Global Style"
                [fullScreen]="true"
                [footer]="null"
                (cancelClick)="handleGlobalStyleModalVisibility(false)">
  <rappider-project-css-style-customization *ngIf="globalStyleModalVisibility"
                                            [titlebarVisibility]="false"></rappider-project-css-style-customization>
</rappider-modal>

<!-- Theme Modal -->
<rappider-modal *ngIf="themeModalVisibility"
                [(visible)]="themeModalVisibility"
                title="Update Theme"
                [fullScreen]="true"
                [footer]="null"
                (cancelClick)="handleThemeModalVisibility(false)">
  <div class="project-theme-modal-content">
    <rappider-project-theme *ngIf="themeModalVisibility"
                            [previewVisibility]="false"></rappider-project-theme>
  </div>
</rappider-modal>

<!-- Template Selector Component -->
<rappider-modal *ngIf="templatesModalVisibility"
                [(visible)]="templatesModalVisibility"
                title="Add Template To Page"
                [fullScreen]="true"
                [footer]="templateModalFooterConfig"
                (cancelClick)="handleTemplatesModalVisibility(false)">
  <rappider-page-templates [isModalOpen]="true"
                           [showCheckMarkOnCard]="true"
                           (cardClick)="onTemplatePageSelect($event)"></rappider-page-templates>
</rappider-modal>

<!-- Page Variables Modal -->
<rappider-modal *ngIf="pageVariablesModalVisibility"
                [(visible)]="pageVariablesModalVisibility"
                title="Page Variables"
                [fullScreen]="true"
                [footer]="null"
                (cancelClick)="handlePageVariablesModalVisibility(false)">
  <rappider-page-variables (cancel)="handlePageVariablesModalVisibility(false)"></rappider-page-variables>
</rappider-modal>





<!-- TAB ICON TEMPLATE -->
<ng-template #tabIcon
             let-icon="icon"
             let-title="title"
             let-tooltip="tooltip">
  <div class="tab-icon d-flex"
       [nzTooltipTitle]="tooltip"
       nzTooltipPlacement="bottom"
       nz-tooltip>
    <i [ngClass]="icon"></i>
    <p *ngIf="title">{{ title }}</p>
  </div>
</ng-template>
<!-- / TAB ICON TEMPLATE -->
